import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import {
  Button,
  Icon,
  RadioGroup,
  Radio,
} from 'rhinostyle';
import { UserHelpers } from '../../helpers';
import {
  HIPAA,
  MARKETING,
  RHINOPAY,
} from '../../constants/AppConstants';
import {
  TYPE_RHINOPAY_CONSENT_PENDING,
  TYPE_RHINOPAY_CONSENT_GRANTED,
  TYPE_RHINOPAY_CONSENT_DECLINED,
  TYPE_MARKETING_CONSENT_PENDING,
  TYPE_MARKETING_CONSENT_GRANTED,
  TYPE_MARKETING_CONSENT_DECLINED,
  TYPE_HIPAA_CONSENT_PENDING,
  TYPE_HIPAA_CONSENT_GRANTED,
  TYPE_HIPAA_CONSENT_DECLINED,
} from '../../constants/Types';
import {
  CONTACT_HIPAA_CONSENT_EDIT,
  CONTACT_MARKETING_CONSENT_EDIT,
  CONTACT_RHINOPAY_CONSENT_EDIT,
} from '../../constants/UserPermissionsConstants';

class CommunicationConsent extends Component {
  state = {
    activeConsentType: HIPAA,
  };

  _handleTypeChange = (type) => {
    this.setState({
      activeConsentType: type,
    });
  }

  renderRadioOptions = () => {
    const { activeUser, users } = this.props;
    const hipaaStatusTrustee = users[activeUser?.hipaaStatus?.trusteeId] ?? null;
    const rhinopayConsentStatusTrustee = users[activeUser?.rhinopayConsentStatus?.trusteeId] ?? null;
    const marketingConsentStatusTrustee = users[activeUser?.marketingConsentStatus?.trusteeId] ?? null;

    const showSignedHipaaText = activeUser && !(!hipaaStatusTrustee && activeUser.hipaaStatus.typeId === TYPE_HIPAA_CONSENT_PENDING);
    const showSignedRhinopayConsentText = activeUser && !(!rhinopayConsentStatusTrustee && activeUser.rhinopayConsentStatus.typeId === TYPE_RHINOPAY_CONSENT_PENDING);
    const showSignedMarketingConsentText = activeUser && !(!marketingConsentStatusTrustee && activeUser.marketingConsentStatus.typeId === TYPE_MARKETING_CONSENT_PENDING);

    const disableHipaaConsentEditing = !UserHelpers.userHasAllOfPermissions([CONTACT_HIPAA_CONSENT_EDIT]);
    const disableMarketingEditing = !UserHelpers.userHasAllOfPermissions([CONTACT_MARKETING_CONSENT_EDIT]);
    const disableRhinopayEditing = !UserHelpers.userHasAllOfPermissions([CONTACT_RHINOPAY_CONSENT_EDIT]);

    if (this.state.activeConsentType === MARKETING) {
      return (
        <div>
          <RadioGroup
            blockGroup
            label="Marketing consent status"
            name="hipaaStatusCover"
            onChange={(id) => this.props.handleChange('marketingConsentStatusTypeId', id)}
            selectedValue={this.props.marketingConsentStatusTypeId}
          >
            <Radio
              disabled={disableMarketingEditing}
              value={TYPE_MARKETING_CONSENT_PENDING}
              label={(
                <>
                  <span className="form__block-group__label">{UserHelpers.formatMarketingStatus(TYPE_MARKETING_CONSENT_PENDING)}</span>
                  <span className="form__block-group__desc">{UserHelpers.getMarketingDescription(TYPE_MARKETING_CONSENT_PENDING)}</span>
                </>
              )}
            />
            <Radio
              disabled={disableMarketingEditing}
              value={TYPE_MARKETING_CONSENT_GRANTED}
              label={(
                <>
                  <span className="form__block-group__label">{UserHelpers.formatMarketingStatus(TYPE_MARKETING_CONSENT_GRANTED)}</span>
                  <span className="form__block-group__desc">{UserHelpers.getMarketingDescription(TYPE_MARKETING_CONSENT_GRANTED)}</span>
                </>
              )}
            />
            <Radio
              disabled={disableMarketingEditing}
              value={TYPE_MARKETING_CONSENT_DECLINED}
              label={(
                <>
                  <span className="form__block-group__label">{UserHelpers.formatMarketingStatus(TYPE_MARKETING_CONSENT_DECLINED)}</span>
                  <span className="form__block-group__desc">{UserHelpers.getMarketingDescription(TYPE_MARKETING_CONSENT_DECLINED)}</span>
                </>
              )}
            />
          </RadioGroup>
          {showSignedMarketingConsentText && (
            <p className="u-text-small u-text-muted u-text-italic u-text-right">
              {UserHelpers.fetchSignedTextMarketing(marketingConsentStatusTrustee, activeUser.marketingConsentStatus.lastUpdatedAt)}
            </p>
          )}
        </div>
      );
    } if (this.state.activeConsentType === RHINOPAY) {
      return (
        <div>
          <RadioGroup
            blockGroup
            label="RhinoPay consent status"
            name="rhinopayStatusCover"
            onChange={(id) => this.props.handleChange('rhinopayConsentStatusTypeId', id)}
            selectedValue={this.props.rhinopayConsentStatusTypeId}
          >
            <Radio
              disabled={disableRhinopayEditing}
              value={TYPE_RHINOPAY_CONSENT_PENDING}
              label={(
                <>
                  <span className="form__block-group__label">{UserHelpers.formatRhinoPayStatus(TYPE_RHINOPAY_CONSENT_PENDING)}</span>
                  <span className="form__block-group__desc">{UserHelpers.getRhinoPayDescription(TYPE_RHINOPAY_CONSENT_PENDING)}</span>
                </>
              )}
            />
            <Radio
              disabled={disableRhinopayEditing}
              value={TYPE_RHINOPAY_CONSENT_GRANTED}
              label={(
                <>
                  <span className="form__block-group__label">{UserHelpers.formatRhinoPayStatus(TYPE_RHINOPAY_CONSENT_GRANTED)}</span>
                  <span className="form__block-group__desc">{UserHelpers.getRhinoPayDescription(TYPE_RHINOPAY_CONSENT_GRANTED)}</span>
                </>
              )}
            />
            <Radio
              disabled={disableRhinopayEditing}
              value={TYPE_RHINOPAY_CONSENT_DECLINED}
              label={(
                <>
                  <span className="form__block-group__label">{UserHelpers.formatRhinoPayStatus(TYPE_RHINOPAY_CONSENT_DECLINED)}</span>
                  <span className="form__block-group__desc">{UserHelpers.getRhinoPayDescription(TYPE_RHINOPAY_CONSENT_DECLINED)}</span>
                </>
              )}
            />
          </RadioGroup>
          {showSignedRhinopayConsentText && activeUser.hipaaStatus.lastUpdatedAt && (
            <p className="u-text-small u-text-muted u-text-italic u-text-right">
              {UserHelpers.fetchSignedText(rhinopayConsentStatusTrustee, activeUser.rhinopayConsentStatus.lastUpdatedAt)}
            </p>
          )}
        </div>
      );
    } return (
      <div>
        <RadioGroup
          blockGroup
          label="HIPAA consent status"
          name="hipaaStatusCover"
          onChange={(id) => this.props.handleChange('hipaaStatusTypeId', id)}
          selectedValue={this.props.hipaaStatusTypeId}
        >
          <Radio
            disabled={disableHipaaConsentEditing}
            value={TYPE_HIPAA_CONSENT_PENDING}
            label={(
              <>
                <span className="form__block-group__label">{UserHelpers.formatHipaaStatus(TYPE_HIPAA_CONSENT_PENDING)}</span>
                <span className="form__block-group__desc">{UserHelpers.getHipaaDescription(TYPE_HIPAA_CONSENT_PENDING)}</span>
              </>
            )}
          />
          <Radio
            disabled={disableHipaaConsentEditing}
            value={TYPE_HIPAA_CONSENT_GRANTED}
            label={(
              <>
                <span className="form__block-group__label">{UserHelpers.formatHipaaStatus(TYPE_HIPAA_CONSENT_GRANTED)}</span>
                <span className="form__block-group__desc">{UserHelpers.getHipaaDescription(TYPE_HIPAA_CONSENT_GRANTED)}</span>
              </>
            )}
          />
          <Radio
            disabled={disableHipaaConsentEditing}
            value={TYPE_HIPAA_CONSENT_DECLINED}
            label={(
              <>
                <span className="form__block-group__label">{UserHelpers.formatHipaaStatus(TYPE_HIPAA_CONSENT_DECLINED)}</span>
                <span className="form__block-group__desc">{UserHelpers.getHipaaDescription(TYPE_HIPAA_CONSENT_DECLINED)}</span>
              </>
            )}
          />
        </RadioGroup>
        {showSignedHipaaText && activeUser.hipaaStatus.lastUpdatedAt && (
          <p className="u-text-small u-text-muted u-text-italic u-text-right">{UserHelpers.fetchSignedText(hipaaStatusTrustee, activeUser.hipaaStatus.lastUpdatedAt)}</p>
        )}
      </div>
    );
  }

  render() {
    const {
      hipaaStatusTypeId,
      isRhinopayEnabled,
      rhinopayConsentStatusTypeId,
      marketingConsentStatusTypeId,
    } = this.props;

    const hipaaIconClasses = cx('consent-status__icon u-m-r-small', {
      'is-granted': hipaaStatusTypeId === TYPE_HIPAA_CONSENT_GRANTED,
      'is-denied': hipaaStatusTypeId === TYPE_HIPAA_CONSENT_DECLINED,
      'is-unknown': hipaaStatusTypeId === TYPE_HIPAA_CONSENT_PENDING,
    });

    const rhinopayIconClasses = cx('consent-status__icon u-m-r-small ', {
      'is-denied': rhinopayConsentStatusTypeId === TYPE_RHINOPAY_CONSENT_DECLINED,
      'is-unknown': rhinopayConsentStatusTypeId === TYPE_RHINOPAY_CONSENT_PENDING,
      'is-granted': rhinopayConsentStatusTypeId === TYPE_RHINOPAY_CONSENT_GRANTED,
    });

    const marketingIconClasses = cx('consent-status__icon u-m-r-small is-marketing', {
      'is-denied': marketingConsentStatusTypeId === TYPE_MARKETING_CONSENT_DECLINED,
      'is-unknown': marketingConsentStatusTypeId === TYPE_MARKETING_CONSENT_PENDING,
      'is-granted': marketingConsentStatusTypeId === TYPE_MARKETING_CONSENT_GRANTED,
    });

    return (
      <div className="box box__consent">
        <div className="box__consent__title-wrapper">
          <div className="box__title">Communication Consent</div>
        </div>
        <div className="box__consent__subtitle-wrapper">
          <Button type="link" active={this.state.activeConsentType === HIPAA} className="button__consent" onClick={() => this._handleTypeChange(HIPAA)}>
            <div className="box__consent__subtitle"><Icon bump="up" icon="hipaa" className={hipaaIconClasses} />HIPAA</div>
          </Button>
          {isRhinopayEnabled && (
            <Button type="link" active={this.state.activeConsentType === RHINOPAY} className="button__consent" onClick={() => this._handleTypeChange(RHINOPAY)}>
              <div className="box__consent__subtitle"><Icon bump="up" icon="rhinopay" className={rhinopayIconClasses} />RhinoPay</div>
            </Button>
          )}
          <Button type="link" active={this.state.activeConsentType === MARKETING} className="button__consent" onClick={() => this._handleTypeChange(MARKETING)}>
            <div className="box__consent__subtitle"><Icon bump="up" icon="marketing" className={marketingIconClasses} />Marketing</div>
          </Button>
        </div>
        {this.renderRadioOptions()}
      </div>
    );
  }
}

CommunicationConsent.propTypes = {
  handleChange: PropTypes.func,
  hipaaStatusTypeId: PropTypes.number,
  isRhinopayEnabled: PropTypes.bool,
  marketingConsentStatusTypeId: PropTypes.number,
  rhinopayConsentStatusTypeId: PropTypes.number,
  activeUser: PropTypes.object,
  users: PropTypes.object,
};

export default CommunicationConsent;
