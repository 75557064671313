import React from 'react';

// Keeping the svg here until we decide on an icon for rhinostyle
const AccessibeButton = () => (
  <div
    className="accessibe__button"
    data-acsb-custom-trigger="true"
    role="button"
  >
    <svg className="accessibe__icon" xmlns="http://www.w3.org/2000/svg" version="1.1" fill="#fff" stroke="none" x="0px" y="0px" viewBox="0 0 100 131.3" xmlSpace="preserve">
      <path d="M71.6,131.3c1,0,2.1-0.3,3.1-0.8c3.9-1.8,5.5-6.2,3.6-10.1c0,0-14.3-32.7-16.9-44.7c-1-4.2-1.6-15.3-1.8-20.5 c0-1.8,1-3.4,2.6-3.9l32-9.6c3.9-1,6.2-5.5,5.2-9.4c-1-3.9-5.5-6.2-9.4-5.2c0,0-29.6,9.6-40.3,9.6c-10.4,0-39.8-9.4-39.8-9.4 c-3.9-1-8.3,0.8-9.6,4.7c-1.3,4.2,1,8.6,5.2,9.6l32,9.6c1.6,0.5,2.9,2.1,2.6,3.9c-0.3,5.2-0.8,16.4-1.8,20.5 c-2.6,12-16.9,44.7-16.9,44.7c-1.8,3.9,0,8.3,3.6,10.1c1,0.5,2.1,0.8,3.1,0.8c2.9,0,5.7-1.6,6.8-4.4l15.3-31.2l14.6,31.4 C66.1,129.7,68.7,131.3,71.6,131.3z" />
      <circle cx="50.3" cy="14.6" r="14.6" />
    </svg>
  </div>
);

export default AccessibeButton;
