import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getCurrentVideo } from '../selectors/rhinovideoSelectors';
import { getMemberCall } from '../selectors/rhinocallSelector';
import { formatTimer } from '../helpers/DateHelpers';

const RhinovideoTimer = (props) => {
  const { created, type } = props;
  const [timer, setTimer] = useState('');
  const intervalRef = useRef();

  useEffect(() => {
    if (created) {
      intervalRef.current = setInterval(() => setTimer(formatTimer(created)), 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [created]);

  const containerClasses = cx('', {
    'rhinovideo__timer-container': type === 'video',
    'rhinocall__timer-container u-text-white': type === 'call',
  });

  const timerClasses = cx('', {
    rhinovideo__timer: type === 'video',
    rhinocall__timer: type === 'call',
  });

  return (
    <div className={containerClasses}>
      <div className={timerClasses}>{timer}</div>
    </div>
  );
};

RhinovideoTimer.propTypes = {
  created: PropTypes.string,
  type: PropTypes.string,
};

const mapStateToProps = (state) => {
  const currentVideo = getCurrentVideo(state);
  const memberCall = getMemberCall(state);
  const type = memberCall ? 'call' : 'video';
  return {
    created: type === 'call' ? memberCall.startTime : currentVideo.created,
    type,
  };
};

export default connect(mapStateToProps)(RhinovideoTimer);
