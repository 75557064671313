import React from 'react';
import {
  Dropdown,
  CheckboxGroup,
  Checkbox,
} from 'rhinostyle';

const MoreDropdown = ({
  disabled,
  dropdownClass,
  showOptions,
  setShowOptions,
  dropdownOptions,
}) => (
  <Dropdown
    autoFocusInput={false}
    label="More"
    title="More"
    name="showOptions"
    disabled={disabled}
    type="input"
    className={dropdownClass}
    disableScroll
    wide
    dataFeatureTag="more-dropdown-toggle"
  >
    <div className="dropdown__menu__container">
      <CheckboxGroup className="dropdown__menu--checkbox">
        {dropdownOptions.map(({ name, label }) => (
          <Checkbox
            key={name}
            label={<span className="u-p-l-small">{label}</span>}
            className="u-p-t-small"
            name={name}
            onChange={() => setShowOptions((current) => ({ ...current, [name]: !current[name] }))}
            isChecked={showOptions[name]}
            dataFeatureTag={`more-dropdown-${name}`}
          />
        ))}
      </CheckboxGroup>
    </div>
  </Dropdown>
);

export default MoreDropdown;
