import Quill from 'quill';
import { isInternetExplorer } from '../helpers/BrowserHelpers';

const Embed = Quill.import('blots/embed');

class VariableBlot extends Embed {
  static create(data) {
    let { variable } = data;
    const { visibleVariable } = data;
    // Replace text contents of variable to hide squigglies
    const regexSquiggles = /{(.*)?}/g;
    variable =
      visibleVariable ? visibleVariable.replace(regexSquiggles, '<i>{</i><div>$1</div><i>}</i>') : variable.replace(regexSquiggles, '<i>{</i><div>$1</div><i>}</i>');
    const regexUnderscores = /_/g;
    variable = variable.replace(regexUnderscores, '</div><b>_</b><div>');
    // IE has a known bug with nested HTML elements inside a contenteditable parent.
    // Solution is to set the nested HTML element to _also_ be content editable but only in IE
    // https://stackoverflow.com/questions/7522848/contenteditable-false-inside-contenteditable-true-block-is-still-editable-in-ie8/7522910#7522910
    const node = document.createElement('span');
    node.setAttribute('contenteditable', !!isInternetExplorer());
    node.setAttribute('onmousedown', 'return false;');
    node.setAttribute('onselectstart', 'return false;');
    node.setAttribute('data-cypress', data.value);
    node.innerHTML = variable;
    node.classList.add('ql-variable');
    return VariableBlot.setDataValues(node, data);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }
}

VariableBlot.blotName = 'variable';
VariableBlot.tagName = 'span';
VariableBlot.className = 'ql-variable ';

Quill.register(VariableBlot);

export default Quill;
