import { createSelector } from '@reduxjs/toolkit';
import { TYPE_TEMPLATE_TEAM, TYPE_TEMPLATE_CONTACT } from '../constants/Types';

const getTemplateIds = (state) => state.messageTemplate.messageTemplateIds;
const getTemplates = (state) => state.messageTemplate.messageTemplates;

export const getContactTemplateIds = createSelector(
  [getTemplateIds, getTemplates],
  (templateIds, templates) =>
    templateIds.filter((t) => (templates[t].typeId === TYPE_TEMPLATE_CONTACT && !templates[t].favorited)),
);

export const getTeamTemplateIds = createSelector(
  [getTemplateIds, getTemplates],
  (templateIds, templates) =>
    templateIds.filter((templateId) => (templates[templateId].typeId === TYPE_TEMPLATE_TEAM && !templates[templateId].favorited)),
);

export const getSystemTemplateIds = createSelector(
  [getTemplateIds, getTemplates],
  (templateIds, templates) =>
    templateIds.filter((t) => (templates[t].systemTemplateId && !templates[t].favorited)),
);

export const getFavoriteTemplateIds = createSelector(
  [getTemplateIds, getTemplates],
  (templateIds, templates) =>
    templateIds.filter((t) => templates[t].favorited),
);
