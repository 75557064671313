import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const RhinovideoScreenShareStream = (props) => {
  const { screenShareTrack, participants } = props;
  const screenShareRef = useRef(null);
  const participant = participants[screenShareTrack.sid];
  useEffect(() => {
    if (participant.screenShareTrack?.isTrackEnabled) {
      screenShareRef.current.srcObject = new MediaStream([participant.screenShareTrack.track?.mediaStreamTrack]);
    }
  }, [participant]);

  return (
    <div className="rhinovideo__participant is-pinned">
      <video
        id="rhinovideo__participant-stream__screenshare"
        className="rhinovideo__participant-stream"
        autoPlay
        muted
        playsInline
        ref={screenShareRef}
      />
      {participant.screenShareTrack && !participant.screenShareTrack.isTrackEnabled && <span className="rhinovideo__screenshare-paused">Screenshare Paused</span>}
    </div>
  );
};

RhinovideoScreenShareStream.propTypes = {
  screenShareTrack: PropTypes.object.isRequired,
  participants: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { rhinovideo } = state;

  return {
    participants: rhinovideo.participants,
  };
};
export default connect(mapStateToProps)(RhinovideoScreenShareStream);
