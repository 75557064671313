import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLoggedInUserPermissionNames } from '../selectors/userSelectors';
import LibraryFormPanel from '../components/LibraryFormPanel';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { deleteFormTemplate, updateFormTemplates, cloneRhinoform, fetchFormTemplates } from '../reducers/formReducer';

class LibraryFormPanelContainer extends Component {
  state = {
    showDeleteFormModal: false,
    isPdfViewerOpen: false,

  }

  handleToggleModal = () => {
    this.setState({ showDeleteFormModal: !this.state.showDeleteFormModal });
  }

  handleDeleteForm = (formId) => {
    this.props.deleteFormTemplate(formId, this.props.selectedFormFilter)
      .then(() => {
        this.handleToggleModal();
        this.props.history.replace('/settings/organization/library/forms');
        this.props.filterUpdateHandler(this.props.selectedFormFilter);
      });
  }

  handlePublishForm = (formId) => {
    const payload = {
      isPublished: true,
    };
    this.props.updateFormTemplates(formId, payload, this.props.selectedFormFilter).then(() => {
      this.props.filterUpdateHandler(this.props.selectedFormFilter);
    });
  }

  handleFormConfiguration = () => {
    this.props.history.replace(`/settings/organization/library/forms/edit/${this.props.activeFormTemplate.formId}`);
  }

  togglePdfViewer = () => {
    this.setState({ isPdfViewerOpen: !this.state.isPdfViewerOpen });
  }

  handleCloneForm = (formId) => {
    const payload = {
      formId,
      edit: true,
    };
    this.props.cloneRhinoform(payload).then(() => {
      this.props.fetchFormTemplates(this.props.selectedFormFilter);
    });
  }

  render() {
    const props = {
      handleTogglePanel: this.props.handleTogglePanel,
      activeFormTemplate: this.props.activeFormTemplate,
      showDeleteFormModal: this.state.showDeleteFormModal,
      isFormTemplateDeleting: this.props.isFormTemplateDeleting,
      loggedInUserPermissions: this.props.loggedInUserPermissions,
      handleToggleModal: this.handleToggleModal,
      handleDeleteForm: this.handleDeleteForm,
      handlePublishForm: this.handlePublishForm,
      isFormTemplateUpdating: this.props.isFormTemplateUpdating,
      handleFormConfiguration: this.handleFormConfiguration,
      togglePdfViewer: this.togglePdfViewer,
      isPdfViewerOpen: this.state.isPdfViewerOpen,
      handleCloneForm: this.handleCloneForm,
      currentOrganization: this.props.currentOrganization,
      offices: this.props.offices,
    };
    return (
      <LibraryFormPanel {...props} />
    );
  }
}

LibraryFormPanelContainer.propTypes = {
  handleTogglePanel: PropTypes.func.isRequired,
  activeFormTemplate: PropTypes.object.isRequired,
  selectedFormFilter: PropTypes.string.isRequired,
  filterUpdateHandler: PropTypes.func.isRequired,
  deleteFormTemplate: PropTypes.func.isRequired,
  isFormTemplateDeleting: PropTypes.bool.isRequired,
  isFormTemplateUpdating: PropTypes.bool.isRequired,
  loggedInUserPermissions: PropTypes.array.isRequired,
  updateFormTemplates: PropTypes.func.isRequired,
  cloneRhinoform: PropTypes.func.isRequired,
  fetchFormTemplates: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  offices: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { form, office } = state;
  return {
    isFormTemplateDeleting: form.isFormTemplateDeleting,
    isFormTemplateUpdating: form.isFormTemplateUpdating,
    loggedInUserPermissions: getLoggedInUserPermissionNames(state),
    formId: form.form.formId,
    currentOrganization: getCurrentOrg(state),
    offices: office.offices,
  };
};

const actions = {
  deleteFormTemplate,
  updateFormTemplates,
  cloneRhinoform,
  fetchFormTemplates,
};

export default connect(mapStateToProps, actions)(withRouter(LibraryFormPanelContainer));
