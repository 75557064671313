import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGroupById } from '../reducers/groupReducer';
import { getGroupById } from '../selectors/routingSelectors';

const useGroup = (itemId) => {
  const dispatch = useDispatch();
  const groupId = Number(itemId) || null;
  const group = useSelector((state) => getGroupById(state, { groupId }));

  useEffect(() => {
    if (groupId) {
      dispatch(fetchGroupById(groupId));
    }
  }, [groupId]);

  return group;
};

export default useGroup;
