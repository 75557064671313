import React, { useState } from 'react';
import {
  Button,
  Close,
  Scrollbars,
  UtilityInlineGrid,
  UtilityList,
} from 'rhinostyle';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLoggedInUserPermissionNames } from '../selectors/userSelectors';
import { PRESCRIPTION_NOTIFICATION_EDIT } from '../constants/UserPermissionsConstants';
import { usePrescriptionCampaign } from '../hooks';
import { replaceVariablesWithSampleText } from '../helpers/TemplateHelpers';
import { PRESCRIPTION_TEMPLATE_TRUNCATE_LENGTH, CAMPAIGN_SELECT_LIST_SIZE } from '../constants/AppConstants';
import {
  TYPE_PRESCRIPTION_EVENT_REMINDER,
  TYPE_PRESCRIPTION_CAMPAIGN_INITAL_TYPE,
  TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_PRE,
  TYPE_EVENT_DATE_CREATED_AT,
} from '../constants/Types';
import { PRESCRIPTION_MESSAGE_VARIABLE_OPTIONS } from '../constants/VariableMessageConstants';
import { cloneDeep } from '../helpers/DataHelpers';
import SingleAttachmentPreview from './SingleAttachmentPreview';
import OptOut from './OptOut';
import { DateHelpers } from '../helpers';
import { sortMessagesByDeliveryHours, templateContainsConsent } from '../helpers/PrescriptionHelpers';

const PrescriptionCampaignSummaryPanel = () => {
  const { prescriptionCampaign = {} } = usePrescriptionCampaign();
  const loggedInUserPermissions = useSelector((state) => getLoggedInUserPermissionNames(state));
  const [textTruncated, setTextTruncated] = useState(prescriptionCampaign?.template?.length > PRESCRIPTION_TEMPLATE_TRUNCATE_LENGTH);
  const [refillTextTruncated, setRefillTextTruncated] = useState(prescriptionCampaign?.refillTemplate?.length > PRESCRIPTION_TEMPLATE_TRUNCATE_LENGTH);
  const [payorsTruncated, setPayorsTruncated] = useState(prescriptionCampaign?.payors?.length > CAMPAIGN_SELECT_LIST_SIZE);

  const history = useHistory();
  const prescriptionVariableOptions = cloneDeep(PRESCRIPTION_MESSAGE_VARIABLE_OPTIONS);

  const renderTemplateText = (campaignMessage) => {
    // const template = refill ? prescriptionCampaign.refillTemplate : prescriptionCampaign.template;
    // const isTextTruncated = refill ? refillTextTruncated : textTruncated;
    const text = replaceVariablesWithSampleText(prescriptionVariableOptions, campaignMessage.template);
    const isTextTruncated = true;
    return isTextTruncated ? text.substring(0, PRESCRIPTION_TEMPLATE_TRUNCATE_LENGTH) : text;
  };
  const renderAttachment = (attachment, key) => (
    <SingleAttachmentPreview
      attachment={attachment}
      index={key}
      key={attachment.attachmentUrl}
    />
  );
  const renderAttachments = () => {
    let attachments = [];
    attachments = prescriptionCampaign?.attachments.filter((a) => a.appointmentEventTypeId === TYPE_PRESCRIPTION_EVENT_REMINDER);
    if (attachments.length > 0) {
      return (
        <div className="summary-panel__section__content u-m-t">
          <UtilityList space className="u-m-b-0">
            {attachments.map(renderAttachment)}
          </UtilityList>
        </div>
      );
    }
    return null;
  };

  const renderCampaignMessage = (campaignMessage) => {
    if (campaignMessage) {
      const deliveryHoursFormatted = DateHelpers.convertHoursToDays(campaignMessage.deliveryHours, true);
      let deliveryDirection = 'on';
      if (campaignMessage.deliveryHours > 0) {
        deliveryDirection = (campaignMessage.deliveryHoursDirection === TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_PRE) ?
          'before' : 'after';
      }
      const deliveryDate = (campaignMessage.deliveryHoursType === TYPE_EVENT_DATE_CREATED_AT) ?
        'initial fill date' : 'refill date';
      const formattedType = campaignMessage.type === TYPE_PRESCRIPTION_CAMPAIGN_INITAL_TYPE ? 'New Prescription Notification' : 'Prescription Refill Notification';
      const formattedDelivery = `Deliver ${deliveryHoursFormatted} ${deliveryDirection} ${deliveryDate}.`;
      const formattedRequiresExempt = templateContainsConsent(campaignMessage.template) ? 'Consent Required' : 'Consent Not Required';
      const formattedStopOnReply = campaignMessage.stopOnReply ? 'Stop Sending On Reply' : '';
      const reminderDeliveryText = (
        <>
          {formattedDelivery}<br />{formattedRequiresExempt}<br />{formattedStopOnReply}
        </>
      );
      return (
        <div>
          <div className="summary-panel__section">
            <div className="summary-panel__section__label">{formattedType}</div>
            <div className="summary-panel__section__content">
              <UtilityList space className="u-m-b-0 prescription-campaigns__template">
                <div>{reminderDeliveryText}</div>
                {/* {renderTemplateText(campaignMessage)}
                {textTruncated && (
                  <Button
                    type="reset"
                    className="profile__forms__expand u-text-primary"
                    onClick={() => setTextTruncated(false)}
                  >
                    View more...
                  </Button>
                )}
                <OptOut className="u-text-gray" /> */}
                {/* {renderAttachments()} */}
              </UtilityList>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title" data-cypress="summaryTitle">Summary</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => {
                history.push('/settings/organization/prescription-notifications');
              }}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__title u-m-t">
                {prescriptionCampaign?.name}
              </div>
              <div className="summary-panel__intro__actions">
                {loggedInUserPermissions.includes(PRESCRIPTION_NOTIFICATION_EDIT) && (
                  <UtilityInlineGrid align="middle">
                    <Button
                      className="u-m-t u-m-b"
                      size="small"
                      type="primary"
                      outlined
                      onClick={() => history.push(`/settings/organization/prescription-notifications/edit/${prescriptionCampaign?.id}`)}
                      data-feature-tag="routing-manager_summary-route"
                    >
                      Edit Prescription Notification
                    </Button>
                  </UtilityInlineGrid>
                )}
              </div>
            </div>
            <>
              {prescriptionCampaign?.payors?.length > 0 && (
                <div className="summary-panel__section">
                  <div className="summary-panel__section__label">Payors</div>
                  <div className="summary-panel__section__content">
                    <UtilityList space className="u-m-b-0">
                      {prescriptionCampaign.payors.map((payor) => <div className="u-m-b-small" key={payor.id}>{payor.name}</div>)}
                      {payorsTruncated && (
                        <Button
                          type="reset"
                          className="profile__forms__expand u-text-primary"
                          onClick={() => setPayorsTruncated(false)}
                        >
                          View more...
                        </Button>
                      )}
                    </UtilityList>
                  </div>
                </div>
              )}
            </>
            {prescriptionCampaign.messages?.filter((campaignMessage) => !campaignMessage.deleted).toSorted(sortMessagesByDeliveryHours).map(renderCampaignMessage)}
            {prescriptionCampaign.enabled && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Initial Fill Notification Message</div>
                <div className="summary-panel__section__content">
                  <UtilityList space className="u-m-b-0 prescription-campaigns__template">
                    {renderTemplateText()}
                    {textTruncated && (
                      <Button
                        type="reset"
                        className="profile__forms__expand u-text-primary"
                        onClick={() => setTextTruncated(false)}
                      >
                        View more...
                      </Button>
                    )}
                    <OptOut className="u-text-gray" />
                    {renderAttachments()}
                  </UtilityList>
                </div>
              </div>
            )}
            {prescriptionCampaign.refillTemplateEnabled && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Refill Notification Message</div>
                <div className="summary-panel__section__content">
                  <UtilityList space className="u-m-b-0 prescription-campaigns__template">
                    {renderTemplateText(true)}
                    {refillTextTruncated && (
                      <Button
                        type="reset"
                        className="profile__forms__expand u-text-primary"
                        onClick={() => setRefillTextTruncated(false)}
                      >
                        View more...
                      </Button>
                    )}
                    <OptOut className="u-text-gray" />
                  </UtilityList>
                </div>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default PrescriptionCampaignSummaryPanel;
