import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import cx from 'classnames';
import {
  Scrollbars,
} from 'rhinostyle';
import {
  UserHelpers,
} from '../helpers';
import { useThreadEvents, usePrevious } from '../hooks';
import { getActiveUser } from '../selectors/userSelectors';
import { setMessageInputHeightChange, toggleLightboxClosed } from '../reducers/threadReducer';
import LoadMore from './LoadMore';
import ThreadEvent from './threadEvents/ThreadEvent';
import ChatThreadEvent from './ChatThreadEvent';
import { QUERY_STATUS_FULFILLED } from '../constants/v3';
import { BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD, EVENT_SIZE } from '../constants/AppConstants';
import LightboxContainer from '../containers/LightboxContainer';
import { sortEventsByTimestamp } from '../helpers/EventHelpers';

const ThreadScrollWrapper = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const convoBodyRef = useRef();

  const [threadPage, setThreadPage] = useState({
    newest: location?.state?.pageNo || 0,
    oldest: location?.state?.pageNo || 0,
    current: location?.state?.pageNo || 0,
  });
  const topMessageRef = useRef(location.state?.eventId || null);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [loading, setLoading] = useState(true);
  const query = useThreadEvents(threadPage.current);
  const scrollContainer = convoBodyRef?.current?.container?.firstChild;
  const previousScrollHeight = usePrevious(scrollContainer?.clientHeight);
  const previousScrollTop = usePrevious(scrollContainer?.clientHeight);
  const { status, isLoading, threadLoading, data } = query;
  const { ids, entities, hasMorePages } = data || {};

  const {
    threadSelectedChannelIds,
    isInboxThread,
    activeUser,
    selectedEventIds,
    isSelectionPreviewModeEnabled,
    lightboxIsOpen,
    messageInputHeightChange,
  } = props;

  function toggleLightbox() {
    dispatch(toggleLightboxClosed());
  }
  const eventIds = isSelectionPreviewModeEnabled ? sortEventsByTimestamp(selectedEventIds, entities) : ids;

  useEffect(() => {
    if (!isLoading && ((isInboxThread && status === QUERY_STATUS_FULFILLED) || (!isInboxThread && eventIds?.length > 0))) {
      setLoading(false);
    }
  }, [isLoading, status, isInboxThread]);

  useEffect(() => {
    if (!loading) {
      scrollToBottom();
    }
  }, [loading]);

  useEffect(() => {
    if (messageInputHeightChange) {
      dispatch(setMessageInputHeightChange(false));
      if (scrollContainer) {
        const diff = scrollContainer?.clientHeight - previousScrollHeight;
        scrollContainer.scrollTop -= diff;
      }
    }
  }, [messageInputHeightChange, previousScrollHeight, previousScrollTop]);

  useEffect(() => {
    if (hasScrolled && !topMessageRef.current) {
      scrollToBottom();
    }
  }, [hasScrolled]);

  function resetThread() {
    history.push({
      state: {},
    });
  }

  useEffect(() => {
    setThreadPage({
      newest: location?.state?.pageNo || 0,
      oldest: location?.state?.pageNo || 0,
      current: location?.state?.pageNo || 0,
    });
  }, [location.state?.pageNo]);

  // Handles scrolling after filtering channels
  useEffect(() => {
    if (threadSelectedChannelIds?.length > 0) {
      setThreadPage({
        newest: location?.state?.pageNo || 0,
        oldest: location?.state?.pageNo || 0,
        current: location?.state?.pageNo || 0,
      });
      resetThread();
    }
  }, [threadSelectedChannelIds]);

  useEffect(() => {
    if (threadPage.newest > 0 && !threadLoading && eventIds?.length > 0 && (
      eventIds?.length < BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD ||
      (location.state?.eventId && eventIds?.length <= EVENT_SIZE &&
        eventIds?.findIndex((id) => id === location.state?.eventId) > EVENT_SIZE - BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD))
    ) {
      topMessageRef.current = location?.state.eventId;
      setThreadPage((currentThreadPage) => ({
        ...currentThreadPage,
        newest: currentThreadPage.newest - 1,
        current: currentThreadPage.newest - 1,
      }));
    }
  }, [threadLoading, eventIds]);

  async function fetchOlderPage() {
    if (hasMorePages) {
      const topMessage = eventIds[0];
      setThreadPage((currentThreadPage) => ({
        ...currentThreadPage,
        oldest: currentThreadPage.oldest + 1,
        current: currentThreadPage.oldest + 1,
      }));
      topMessageRef.current = topMessage;
    }
  }

  async function fetchNewerPage() {
    if (threadPage.newest > 0 && !threadLoading) {
      topMessageRef.current = null;
      setThreadPage((currentThreadPage) => ({
        ...currentThreadPage,
        newest: currentThreadPage.newest - 1,
        current: currentThreadPage.newest - 1,
      }));
    }
  }

  function scrollToBottom() {
    if (scrollContainer && !topMessageRef.current) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
    setHasScrolled(true);
  }

  const scrollContainerClasses = cx('convo__body', {
    'convo__body--inbox': isInboxThread,
  });

  function shouldScrollToBottom(eventId, key) {
    return (!threadLoading && !topMessageRef.current && threadPage.newest === 0 && key === eventIds.length - 1);
  }

  function shouldScrollToTop(eventId) {
    return hasScrolled && !threadLoading && !isLoading && topMessageRef.current === eventId &&
      (!isInboxThread || (isInboxThread && threadPage.current === query.originalArgs?.pageNo));
  }

  const renderThreadEvent = (event, key) => {
    if (isInboxThread) {
      return (
        <ThreadEvent
          event={entities[event]}
          eventId={event}
          key={entities[event]?.optimisticId || entities[event]?.id}
          isChannelInfoVisible
          scrollToBottom={shouldScrollToBottom(event, key)}
          scrollToTop={shouldScrollToTop(event)}
        />
      );
    } return (
      <ChatThreadEvent
        eventId={event}
        key={event}
        scrollToBottom={shouldScrollToBottom(event, key)}
        scrollToTop={shouldScrollToTop(event)}
      />
    );
  };
  const renderEmptyConvoMessageInbox = () => (
    <div className="convo__body__intro">
      <div className="convo__body__intro__headline">
        This is where your team&apos;s interactions with {activeUser ? UserHelpers.formatName(activeUser) : null} will be permanently stored.
      </div>
      <div className="convo__body__intro__subheadline">
        Make sure to request their consent before using texting or social messaging to send ePHI.
      </div>
    </div>
  );
  return (
    <>
      <Scrollbars
        autoHide
        className={scrollContainerClasses}
        ref={convoBodyRef}
        data-cypress="convoBody"
      >
        {!isSelectionPreviewModeEnabled && hasMorePages && hasScrolled && (
          <LoadMore
            handleLoadMore={fetchOlderPage}
            isLoading={threadLoading || false}
          />
        )}
        {eventIds?.length > 0 ? eventIds?.map(renderThreadEvent) : renderEmptyConvoMessageInbox()}
        {!isSelectionPreviewModeEnabled && hasScrolled && (
          <LoadMore
            handleLoadMore={fetchNewerPage}
            isLoading={threadLoading}
            disabled={threadPage.newest === 0}
          />
        )}
      </Scrollbars>
      {isInboxThread && lightboxIsOpen && (<LightboxContainer threadType="inbox" eventIds={eventIds} events={entities} toggleLightbox={toggleLightbox} />)}
    </>
  );
};

ThreadScrollWrapper.propTypes = {
  threadSelectedChannelIds: PropTypes.array,
  isInboxThread: PropTypes.bool,
  activeUser: PropTypes.object,
  selectedEventIds: PropTypes.array,
  isSelectionPreviewModeEnabled: PropTypes.bool,
  lightboxIsOpen: PropTypes.bool,
  messageInputHeightChange: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const {
    thread,
    savedContent,
  } = state;
  const { isInboxThread } = props;
  return {
    threadSelectedChannelIds: isInboxThread ? state.inbox.threadSelectedChannelIds : [],
    activeUser: getActiveUser(state, !isInboxThread),
    sendingMessage: thread.sendingMessage,
    selectedEventIds: savedContent.selectedEventIds,
    isSelectionPreviewModeEnabled: savedContent.isSelectionPreviewModeEnabled,
    lightboxIsOpen: thread.lightboxIsOpen,
    messageInputHeightChange: thread.messageInputHeightChange,
  };
};

export default connect(mapStateToProps)(ThreadScrollWrapper);
