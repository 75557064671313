import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import NotificationService from '../services/NotificationService';

import { InboxActionTypes, UserActionTypes, AuthActionTypes } from '../constants';

import * as SavedContentActionTypes from '../constants/SavedContentActionTypes';
import { setError, setFormInProgress } from './uiReducer';
import { organization, channel } from '../actions/NormalizrSchema';

import * as ChannelReducer from './channelReducer';
import * as StateReducer from './stateReducer';
import { getBilling } from './billingReducer';

// SLICE
const organizationSlice = createSlice({
  name: 'ORG',
  initialState: {
    organizations: {},
    organizationIds: [],
    loading: false,
    orgSearchLoading: false,
    orgSearchIds: [],
    searchInputActive: false,
    isOrganizationFormInProgress: false,
    parentCompanyFormInProgress: false,
  },
  reducers: {
    receiveOrganizations: receiveOrganizationsData,
    receiveOrganization: receiveOrganizationData,
    receiveOrganizationProfileView: receiveOrganizationData,
    receiveOrganizationPreferencesView: receiveOrganizationData,
    receiveOrgsSearch: (state, action) =>
      ({
        ...state,
        organizations: {
          ...state.organizations,
          ...action.payload.organizations,
        },
        orgSearchIds: [...action.payload.organizationIds],
        orgSearchLoading: false,
        searchInputActive: true,
      }),
    requestOrgData: (state) =>
      ({
        ...state,
        loading: true,
        isOrganizationFormInProgress: true,
      }),
    requestSearchData: (state) =>
      ({
        ...state,
        orgSearchLoading: true,
      }),
    clearOrgSearch: (state) =>
      ({
        ...state,
        orgSearchIds: [],
        orgSearchLoading: false,
        searchInputActive: false,
      }),
    setOrganizationFormInProgress: organizationFormProgress,
    receiveUpdateOrganization: receiveOrganizationData,
    receiveCreateOrganization: receiveOrganizationData,
    receiveParentCompanies: (state, action) =>
      ({
        ...state,
        parentCompanies: action.payload.data,
        parentCompanyFormInProgress: false,
      }),
    requestParentCompany: (state) =>
      ({
        ...state,
        parentCompanyFormInProgress: true,
      }),
  },
  extraReducers: {

    [AuthActionTypes.setUser]: receiveOrganizationsData,

    [InboxActionTypes.receiveInboxThreadView]: receiveOrganizationsData,

    [UserActionTypes.receiveMyUsers]: receiveOrganizationsData,
    [UserActionTypes.receiveUsers]: receiveOrganizationsData,
    [UserActionTypes.receiveUsersSearch]: receiveOrganizationsData,
    [UserActionTypes.receiveContactUsers]: receiveOrganizationsData,
    [UserActionTypes.receiveConnectedPartySearch]: receiveOrganizationsData,
    [UserActionTypes.receiveUser]: receiveOrganizationsData,
    [UserActionTypes.receiveCreateUser]: receiveOrganizationsData,
    [UserActionTypes.receiveUpdateUser]: receiveOrganizationsData,
    [UserActionTypes.receiveContactList]: receiveOrganizationsData,

    [SavedContentActionTypes.receiveEventsForSavedContent]: receiveOrganizationsData,
  },
});

export default organizationSlice.reducer;

// ACTIONS
export const {
  requestOrgData,
  receiveOrganization,
  receiveOrganizationProfileView,
  receiveOrganizationPreferencesView,
  receiveOrganizations,
  receiveUpdateOrganization,
  receiveCreateOrganization,
  requestSearchData,
  receiveOrgsSearch,
  clearOrgSearch,
  setOrganizationFormInProgress,
  receiveParentCompanies,
  requestParentCompany,
} = organizationSlice.actions;

// REDUCER HELPERS

function receiveOrganizationsData(state, action) {
  return {
    ...state,
    organizations: {
      ...state.organizations,
      ...action.payload.organizations,
    },
    organizationIds: [...new Set([...state.organizationIds, ...action.payload.organizationIds])],
    loading: false,
  };
}

function receiveOrganizationData(state, action) {
  return {
    ...state,
    organizations: {
      ...state.organizations,
      [action.payload.organizationId]: action.payload.organization[action.payload.organizationId],
    },
    organizationIds: [...new Set([...state.organizationIds, action.payload.organizationId])],
    loading: false,
    isOrganizationFormInProgress: false,
  };
}

function organizationFormProgress(state, action) {
  return {
    ...state,
    isOrganizationFormInProgress: action.payload,
  };
}

// THUNKS -- ASYNC ACTION CREATORS

export function fetchOrganizationProfileView() {
  return (dispatch) => {
    dispatch(requestOrgData());
    const apiRequests = [getOrganization(), StateReducer.fetchAllStates(), getBilling()];

    return Promise.all(apiRequests)
      .then((responses) => {
        const [orgResponse, statesResponse, billingResponse] = responses;
        const normalizedOrganization = normalize(orgResponse.data, organization);

        dispatch(receiveOrganizationProfileView(getOrganizationProfileViewPayload(normalizedOrganization, billingResponse, statesResponse)));
      })
      .catch((err) => {
        console.error(err.response || err);
        dispatch(setError(err.response || err));
      });
  };
}

export function fetchParentOrganizations() {
  return (dispatch) => {
    dispatch(requestOrgData());

    return axios.get('/organization/parentCompanies')
      .then((response) => {
        dispatch(receiveParentCompanies(response));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function createParentOrganizations(payload) {
  return (dispatch) => {
    dispatch(requestParentCompany());

    return axios.post('/organization/parentCompanies', payload)
      .then((response) => {
        dispatch(receiveParentCompanies(response));
        return response.data;
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function fetchOrganizationFromAutomatedMessages() {
  return (dispatch) => {
    dispatch(requestOrgData());

    return axios.get('/organization/automatedMessages')
      .then((response) => {
        const normalized = normalize(response.data, organization);
        dispatch(receiveOrganization(getOrganizationPayload(normalized)));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function fetchOrganizationPreferencesView() {
  return (dispatch) => {
    dispatch(requestOrgData());

    return axios.all([getOrganization(), ChannelReducer.getChannels()])
      .then(axios.spread((organizationResponse, channelsResponse) => {
        const normalizedOrganization = normalize(organizationResponse.data, organization);
        const normalizedChannels = normalize(channelsResponse.data, [channel]);

        dispatch(receiveOrganizationPreferencesView(getOrganizationPreferencesViewPayload(normalizedOrganization, normalizedChannels)));
      }))
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function updateOrganizationFromAutomatedMessages(payload) {
  return (dispatch) => {
    dispatch(setFormInProgress(true));

    return axios.patch('/organization/automatedMessages', payload)
      .then((response) => {
        const normalized = normalize(response.data, organization);

        NotificationService('updateOrganization', response);
        dispatch(receiveUpdateOrganization({ organization: normalized.entities.organizations, organizationId: normalized.result }));

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);

        NotificationService('updateOrganization', err.response);

        dispatch(setError(err.response || err));
      });
  };
}

export function updateOrganizationFromProfile(rhinoapiPayload) {
  return (dispatch) => {
    dispatch(setOrganizationFormInProgress(true));
    return axios.patch('/organization/profile', rhinoapiPayload)
      .then((updateOrgResponse) => {
        const normalizedOrgResponse = normalize(updateOrgResponse.data, organization);
        dispatch(setError(null));
        NotificationService('updateOrganization', updateOrgResponse);
        return dispatch(receiveUpdateOrganization(getUpdateOrganizationPayload(normalizedOrgResponse)));
      })
      .catch((err) => {
        console.error(err.response || err);
        NotificationService('updateOrganization', err.response);
        dispatch(setOrganizationFormInProgress(false));
        dispatch(setError(err.response || err));
      });
  };
}

export function updateOrganizationFromPreferences(payload) {
  return (dispatch) => {
    dispatch(setFormInProgress(true));

    return axios.patch('/organization/preferences', payload)
      .then((response) => {
        const normalized = normalize(response.data, organization);

        NotificationService('updateOrganization', response);
        dispatch(receiveUpdateOrganization({ organization: normalized.entities.organizations, organizationId: normalized.result }));

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);

        NotificationService('updateOrganization', err.response);

        dispatch(setError(err.response || err));
      });
  };
}

export function createOrganization(payload) {
  return (dispatch) =>
    axios.post('/organization', payload)
      .then((response) => {
        const normalized = normalize(response.data, organization);

        NotificationService('createOrganization', response);

        dispatch(receiveCreateOrganization({ organization: normalized.entities.organizations, organizationId: normalized.result }));

        dispatch(setError(null));

        return response.data;
      })
      .catch((err) => dispatch(handleOrganizationError(err, 'createOrganization')));
}

export function createOrganizationWithCC(payload) {
  return (dispatch) =>
    axios.post('/organization/cc', payload)
      .then((response) => {
        const normalized = normalize(response.data, organization);

        NotificationService('createOrganization', response);

        dispatch(receiveCreateOrganization({ organization: normalized.entities.organizations, organizationId: normalized.result }));

        dispatch(setError(null));

        return response.data;
      })
      .catch((err) => dispatch(handleOrganizationError(err, 'createOrganization')));
}

let cancelOrgSearch;
export const resetOrgSearch = () => (dispatch) => {
  if (cancelOrgSearch) cancelOrgSearch();
  dispatch(clearOrgSearch());
};

export function fetchOrgSearch(search) {
  if (cancelOrgSearch) cancelOrgSearch();
  const encodedSearch = encodeURIComponent(search);

  return (dispatch) => {
    dispatch(requestSearchData());

    return axios.get(`/organization/search?q=${encodedSearch}`, {
      cancelToken: new axios.CancelToken(((cancelFunction) => {
        cancelOrgSearch = cancelFunction;
      })),
    })
      .then((response) => {
        const normalized = normalize(response.data, [organization]);

        dispatch(receiveOrgsSearch({ organizationIds: normalized.result }));
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          cancelOrgSearch = null;
          console.error(err.response || err);
          dispatch(setError(err.response || err));
        }
      });
  };
}

export function createCustomer(payload) {
  return new Promise((resolve, reject) => axios.post('/organization/customer', payload)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err.response.data)));
}

export function addCreditCard(payload) {
  return new Promise((resolve, reject) => (
    axios.post(process.env.REACT_APP_FUSEBILL_CREDIT_URL, payload, { withCredentials: false, headers: { 'content-type': 'application/x-www-form-urlencoded' } })
  )
    .then((res) => resolve(res))
    .catch((err) => newFunction(reject, err)));

  function newFunction(reject, err) {
    return reject({ message: err.response.data.Errors[0].Value });
  }
}

// helper functions

function getOrganizationPayload(normalizedOrganization) {
  return {
    organization: normalizedOrganization.entities.organizations,
    organizationId: normalizedOrganization.result,
  };
}

function getOrganization() {
  return axios.get('/organization/profile')
    .catch((err) => console.error(err.response || err));
}

function getUpdateOrganizationPayload(normalizedOrganizationData) {
  return {
    organization: {
      ...normalizedOrganizationData.entities.organizations,
    },
    organizationId: normalizedOrganizationData.result,
    isOrganizationFormInProgress: false,
  };
}

function getOrganizationProfileViewPayload(normalizedOrganizationData, billingData, statesData) {
  return {
    organization: {
      ...normalizedOrganizationData.entities.organizations,
    },
    organizationId: normalizedOrganizationData.result,
    billing: billingData.data,
    states: statesData.data,
  };
}

function getOrganizationPreferencesViewPayload(normalizedOrganization, normalizedChannels) {
  return {
    ...getOrganizationPayload(normalizedOrganization),
    ...ChannelReducer.getChannelsPayload(normalizedChannels),
  };
}

function handleOrganizationError(err, notificationType = 'updateOrganization') {
  return (dispatch) => {
    console.error(err.response || err);
    const errorResponse = err.response || err;
    if (errorResponse.status) {
      NotificationService(notificationType, errorResponse);
    }

    dispatch(setError(err.response || err));
  };
}
