import moment from 'moment-timezone';
import { UPCOMING_APPOINTMENT_OPTIONS, PAST_APPOINTMENT_OPTIONS } from '../constants/AppointmentManagerConstants';

const sixDays = 6;

export function formatApptManagerStartDate(convertedTimeZoneDate) {
  return convertedTimeZoneDate.format('MM/DD/YY[\n]hh:mm a z');
}

export function formatAppointmentTime(convertedTimeZoneDate, dateFormat = 'ddd, MMM D, YYYY', timeFormat = 'h:mm a z') {
  return `${dateFormat ? convertedTimeZoneDate.format(dateFormat) : ''} ${timeFormat ? 'at' : ''} ${timeFormat ? convertedTimeZoneDate.format(timeFormat) : ''}`;
}

export function calculatePastDateRange(duration) {
  let endDate = moment();
  let startDate = moment();
  startDate = duration === 0 ? moment().startOf('day') : moment().startOf('day').subtract(duration, 'days');
  if (duration === 1) {
    endDate = moment().subtract(duration, 'days').endOf('day');
  }
  return { startDate, endDate };
}

export function calculateFutureDateRange(duration) {
  let endDate = moment();
  let startDate = moment();
  endDate = duration === 0 ? moment().endOf('day') : moment().add(duration, 'days').endOf('day');
  if (duration === 1) {
    startDate = moment().startOf('day').add(duration, 'days');
  }
  return { startDate, endDate };
}

export function getDefaultDates(isUpcoming) {
  if (isUpcoming) {
    return calculateFutureDateRange(sixDays);
  } return calculatePastDateRange(sixDays);
}

function getDateDuration(activeKey, isUpcoming) {
  const datePickerValues = isUpcoming ? UPCOMING_APPOINTMENT_OPTIONS : PAST_APPOINTMENT_OPTIONS;
  return datePickerValues.find((item) => item.id === activeKey)?.duration;
}

export function getValueFromQueryString(values, queryStringValues, defaultValues) {
  const updatedValues = {
    startDate: undefined,
    endDate: undefined,
  };
  if (values.activeKey || values.activeTab) {
    const isUpcoming = values.activeTab === 1;
    const duration = getDateDuration(values.activeKey || defaultValues.activeKey, isUpcoming);
    if (duration !== undefined) {
      // If duration is defined, calculate based on current date
      const { startDate, endDate } = isUpcoming ?
        calculateFutureDateRange(duration) :
        calculatePastDateRange(duration);
      updatedValues.startDate = startDate;
      updatedValues.endDate = endDate;
      // Otherwise, use hardcoded values
    } else if (queryStringValues.startDate && queryStringValues.endDate) {
      updatedValues.endDate = moment(queryStringValues.endDate);
      updatedValues.startDate = moment(queryStringValues.startDate);
    }
  }
  return updatedValues;
}

export function getQueryStringFromValue(newValues, defaultValues) {
  const queryStringParams = {};
  if (newValues.activeKey && newValues.activeKey !== defaultValues.activeKey) {
    queryStringParams.activeKey = newValues.activeKey;
    if (newValues.activeKey === 5) {
      queryStringParams.startDate = newValues.startDate.format('YYYY-MM-DD');
      queryStringParams.endDate = newValues.endDate.format('YYYY-MM-DD');
    }
  }
  return queryStringParams;
}
