import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import {
  Icon,
} from 'rhinostyle';

import {
  SavedContentHelpers,
} from '../../helpers';

import PdfViewerContainer from '../../containers/PdfViewerContainer';
import { getFormById, authenticatePage } from '../../reducers/formReducer';

const RhinoformSendEvent = (props) => {
  const dispatch = useDispatch();
  const [isPdfViewerOpen, openPdfViewer] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [contactDetails, setContactDetails] = useState(null);
  const [templateFormId, setTemplateFormId] = useState('');
  const { formTemplate, event: infoEvent } = props;

  const {
    fromUser = {},
    fromUserId,
    formStatus,
    formId,
    formattedTimestamp,
  } = infoEvent;
  const fromUserName = `${fromUser?.firstName || ''} ${fromUser?.lastName || ''}`;

  function handlePdfViewer() {
    setTemplateFormId(formId);
    openPdfViewer(true);
    if (formTemplate[formId]) {
      setAttachmentUrl(formTemplate[formId].formInternalData);
      setContactDetails(fromUserId);
      return;
    }
    dispatch(getFormById(formId));
    authenticatePage();
  }

  useEffect(() => {
    if (formTemplate[templateFormId]) {
      setAttachmentUrl(formTemplate[templateFormId].formInternalData);
    }
  }, [formTemplate]);

  return (
    <>
      <div
        className="convo__event__title"
        data-cypress="formSentEvent"
      >
        <Icon bump="up" icon="checkmark" className="convo__event__icon convo__event__icon--complete" />
        {formStatus === 'Completed' && (<>Form <strong>Completed</strong></>)}
        {formStatus === 'Sent' && (<>Form <strong>Sent</strong> by {fromUserName}.</>)}
        {((formStatus === 'Generated') || (formStatus === 'Created')) && (<>Form <strong>Generated</strong> by {fromUserName}.</>)}
      </div>
      <div className="convo__event__subtext">
        {formattedTimestamp} &nbsp;
        {formStatus === 'Completed' && (
          <span className="u-text-body u-text-underline convo__event__subtext__link" onClick={handlePdfViewer}>
            Completed Form
          </span>
        )}
      </div>
      <PdfViewerContainer
        open={!!(isPdfViewerOpen && attachmentUrl)}
        attachmentUrl={SavedContentHelpers.getAttachmentUrl(attachmentUrl, contactDetails)}
        fileName={attachmentUrl}
        handlePdfViewerClose={() => openPdfViewer(!isPdfViewerOpen)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    form,
  } = state;
  return {
    formTemplate: form.formS3Url,
  };
};

RhinoformSendEvent.propTypes = {
  formTemplate: PropTypes.object,
  event: PropTypes.object,
};
export default connect(mapStateToProps)(RhinoformSendEvent);
