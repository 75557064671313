import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Papa from 'papaparse';
import CSVImport from '../components/CSVImport';
import { UploadActions } from '../actions';
import * as UserReducer from '../reducers/userReducer';
import { Types } from '../constants';
import {
  IMPORT_TYPE_USERS,
  IMPORT_TYPE_CONNECTED_PARTIES,
  IMPORT_TYPE_APPOINTMENTS,
  IMPORT_STEP_CONFIRMATION,
  IMPORT_STEP_PREVIEW_FILE,
  IMPORT_STEP_UPLOAD_FILE,
} from '../constants/CSVImportConstants';
import { getCurrentOrg } from '../selectors/organizationSelectors';

class CSVImportContainer extends React.Component {
  state = this.initialState;

  get initialState() {
    return {
      importStep: IMPORT_STEP_UPLOAD_FILE,
      importCount: 0,
      invalidCount: 0,
      invalidRecords: [],
      errors: {},
      isLoading: false,
      contactType: Types.TYPE_PATIENT,
      exampleData: {},
      CSVData: '',
      importType: null,
      CSVPreview: {},
      CSVPreviewConnectedParties: [],
    };
  }

  get doesCSVIncludeUserType() {
    return Object.hasOwnProperty.call(this.state.CSVPreview, 'userType');
  }

  componentDidMount() {
    const { currentUser, location } = this.props;
    if (location.pathname === '/contacts/import/appointments' && !currentUser.isCcr) {
      this.props.history.push('/contacts');
    }
    this.setImportType();
  }

  componentDidUpdate() {
    if (!this.state.importType) {
      this.setImportType();
    }
  }

  setImportType = () => {
    if (this.props.location.pathname.includes(IMPORT_TYPE_USERS)) {
      this.setState({ importType: IMPORT_TYPE_USERS });
    } else if (this.props.location.pathname.includes(IMPORT_TYPE_CONNECTED_PARTIES)) {
      this.setState({ importType: IMPORT_TYPE_CONNECTED_PARTIES });
    } else if (this.props.location.pathname.includes(IMPORT_TYPE_APPOINTMENTS)) {
      this.setState({ importType: IMPORT_TYPE_APPOINTMENTS });
    }
  }

  handleContactTypeSelect = (value) => {
    this.setState({ contactType: value });
  }

  handleImportClick = () => {
    const errors = this.handleErrors();
    const errorCount = Object.keys(errors).length;

    if (errorCount > 0) {
      this.setState({ errors });
    } else {
      this.setState({ importStep: IMPORT_STEP_CONFIRMATION, isLoading: true });

      const payload = {
        userTypeId: this.state.importType === IMPORT_TYPE_USERS && !this.doesCSVIncludeUserType ? this.state.contactType : null,
        contacts: this.state.CSVData,
        importType: this.state.importType,
      };

      this.props.importCSV(payload)
        .then((response) => {
          const { importCount, invalidCount, invalidRecords } = response.data;
          this.setState({ importStep: IMPORT_STEP_CONFIRMATION, errors: {}, isLoading: false, importCount, invalidCount, invalidRecords });
        })
        .catch(() => {
          const serverError = {};
          serverError.importError = { message: 'Unable to import data from file.  This is most likely due to invalid format.' };
          this.setState({ importStep: IMPORT_STEP_PREVIEW_FILE, errors: serverError });
        });
    }
  }

  handleCancelCSVUploadClick = () => {
    this.setState(this.initialState);
  }

  handleImportFile = (event) => {
    const reader = new FileReader();
    const file = event.target.files[0];

    if (file) {
      reader.addEventListener('load', () => {
        file.text().then((CSVData) => this.setState({ CSVData }));
        // parse the file here (it's fast) so that we don't get all the way to feeder before we discover that we have garbage data
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          preview: this.state.importType === IMPORT_TYPE_USERS ? 1 : 5, // Only need 1 user as preview for import, but 5 listings for CPs.
          complete: (results) => {
            this.setState({
              CSVPreview: (this.state.importType === IMPORT_TYPE_USERS || this.state.importType === IMPORT_TYPE_APPOINTMENTS) &&
                results?.data?.length ? results.data[0] : {},
              CSVPreviewConnectedParties: this.state.importType === IMPORT_TYPE_CONNECTED_PARTIES ? results.data : [],
              importStep: IMPORT_STEP_PREVIEW_FILE,
            });
          },
        });
      }, false);

      reader.readAsDataURL(file);
    }
  }

  handleInvalidDownload = () => {
    const csv = Papa.unparse(this.state.invalidRecords);
    const exportFilename = 'invalid.csv';
    const CSVData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    // IE11 & Edge
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(CSVData, exportFilename);
    } else {
      // In FF link must be added to DOM to be clicked
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(CSVData);
      link.setAttribute('download', exportFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  handleErrors = () => {
    const errors = {};

    return errors;
  }

  render() {
    const props = {
      importStep: this.state.importStep,
      handleCancelCSVUploadClick: this.handleCancelCSVUploadClick,
      handleImportClick: this.handleImportClick,
      handleContactTypeSelect: this.handleContactTypeSelect,
      errorList: this.state.errors,
      contactType: this.state.contactType,
      CSVData: this.state.CSVData,
      isLoading: this.state.isLoading,
      importCount: this.state.importCount,
      importType: this.state.importType,
      invalidCount: this.state.invalidCount,
      handleInvalidDownload: this.handleInvalidDownload,
      handleImportFile: this.handleImportFile,
      doesCSVIncludeUserType: this.doesCSVIncludeUserType,
      CSVPreview: this.state.CSVPreview,
      CSVPreviewConnectedParties: this.state.CSVPreviewConnectedParties,
    };

    return (
      <CSVImport {...props} />
    );
  }
}

CSVImportContainer.propTypes = {
  importCSV: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentOrg: getCurrentOrg(state),
});

const actions = {
  fetchUsers: UserReducer.fetchUsers,
  importCSV: UploadActions.importCSV,
};

export default connect(mapStateToProps, actions)(CSVImportContainer);
