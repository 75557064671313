import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  UtilityInlineGrid,
  UtilityList,
  Scrollbars,
} from 'rhinostyle';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { getLoggedInUserPermissionNames } from '../selectors/userSelectors';
import { AUTOMATED_MESSAGE_EDIT } from '../constants/UserPermissionsConstants';
import { replaceVariablesWithSampleText, replaceNewLinesToLineBreaks } from '../helpers/TemplateHelpers';
import { APPOINTMENT_REMINDERS_VARIABLE_OPTIONS, OFFICE_LOCATION, OFFICE_NAME, APPOINTMENT_TYPE } from '../constants/VariableMessageConstants';
import { NOTE_TRUNCATE_LENGTH, CAMPAIGN_SELECT_LIST_SIZE } from '../constants/AppConstants';
import { cloneDeep, compareObjectByKey } from '../helpers/DataHelpers';
import SingleAttachmentPreview from './SingleAttachmentPreview';
import {
  TYPE_APPOINTMENT_EVENT_SCHEDULED,
} from '../constants/Types';
import OptOut from './OptOut';
import AppointmentCampaignSummaryReminders from './AppointmentCampaignSummaryReminders';

const AppointmentCampaignSummaryPanel = (props) => {
  const history = useHistory();
  const {
    appointmentCampaign,
    handleTogglePanel,
    loggedInUserPermissions,
    currentOrganization,
    appointmentTypes,
  } = props;
  const [truncateScheduledText, setTruncateScheduledText] = useState(appointmentCampaign?.appointmentScheduledTemplate?.length > NOTE_TRUNCATE_LENGTH);
  const [truncateAppointmentTypes, setTruncateAppointmentTypes] = useState(appointmentCampaign?.appointmentTypes.length > CAMPAIGN_SELECT_LIST_SIZE);
  const [truncateOffices, setTruncateOffices] = useState(appointmentCampaign?.offices?.length > CAMPAIGN_SELECT_LIST_SIZE);
  const getAppointmentVariableOptions = (options) => {
    const optionsCloned = cloneDeep(options);
    if (currentOrganization?.offices?.length > 0) optionsCloned.splice(-2, 0, OFFICE_NAME, OFFICE_LOCATION);
    optionsCloned.splice(3, 0, APPOINTMENT_TYPE);
    return optionsCloned;
  };

  useEffect(() => {
    setTruncateAppointmentTypes(appointmentCampaign?.appointmentTypes.length > CAMPAIGN_SELECT_LIST_SIZE);
    setTruncateScheduledText(appointmentCampaign?.appointmentScheduledTemplate?.length > NOTE_TRUNCATE_LENGTH);
  }, [appointmentCampaign?.id]);

  const renderTemplateText = (text, truncateText) => {
    const formattedText = replaceNewLinesToLineBreaks(text);
    return truncateText ?
      replaceVariablesWithSampleText(getAppointmentVariableOptions(APPOINTMENT_REMINDERS_VARIABLE_OPTIONS), formattedText).substring(0, NOTE_TRUNCATE_LENGTH) :
      replaceVariablesWithSampleText(getAppointmentVariableOptions(APPOINTMENT_REMINDERS_VARIABLE_OPTIONS), formattedText);
  };

  const renderTypeText = (appointmentTypeId) => {
    const appointmentType = appointmentTypes[appointmentTypeId];
    if (appointmentType?.alias) {
      return appointmentType.alias;
    } if (appointmentType?.name) {
      return appointmentType.name;
    } return '';
  };

  const sortAppointmentTypes = ([...campaignAppointmentTypes]) => {
    const sortedAppointmentTypes = campaignAppointmentTypes.sort((a, b) => compareObjectByKey(appointmentTypes[a], appointmentTypes[b], 'alias'));
    return truncateAppointmentTypes ? sortedAppointmentTypes.slice(0, CAMPAIGN_SELECT_LIST_SIZE) : sortedAppointmentTypes;
  };

  const renderAttachment = (attachment, key) => (
    <SingleAttachmentPreview
      attachment={attachment}
      index={key}
      key={attachment.attachmentUrl}
    />
  );

  const renderAttachments = (type) => {
    const filteredAttachments = appointmentCampaign?.attachments.filter((attachment) => attachment.appointmentEventTypeId === type);
    return filteredAttachments?.length > 0 ? (
      <div className="summary-panel__section__content u-m-t">
        <UtilityList space className="u-m-b-0">
          {filteredAttachments.map(renderAttachment)}
        </UtilityList>
      </div>
    ) : null;
  };

  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title" data-cypress="summaryTitle">Summary</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => handleTogglePanel(false)}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__title u-m-t">
                {appointmentCampaign?.name}
              </div>
              <div className="summary-panel__intro__actions">
                {loggedInUserPermissions.includes(AUTOMATED_MESSAGE_EDIT) && (
                  <UtilityInlineGrid align="middle">
                    <Button
                      className="u-m-t u-m-b"
                      size="small"
                      type="primary"
                      outlined
                      onClick={() => history.push(`/settings/organization/appointment-reminders/edit/${appointmentCampaign?.id}`)}
                      data-cypress="appointmentCampaignEdit"
                    >
                      Edit Message
                    </Button>
                  </UtilityInlineGrid>
                )}
              </div>
            </div>
            {appointmentCampaign?.appointmentTypes.length > 0 && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Appointment Types</div>
                <div className="summary-panel__section__content">
                  <UtilityList space className="u-m-b-0">
                    {sortAppointmentTypes(appointmentCampaign?.appointmentTypes).map((type) => <div className="u-m-b-small" key={type}>{renderTypeText(type)}</div>)}
                    {truncateAppointmentTypes && (
                      <Button
                        type="reset"
                        className="profile__forms__expand u-text-primary"
                        onClick={() => setTruncateAppointmentTypes(false)}
                      >
                        View more...
                      </Button>
                    )}
                  </UtilityList>
                </div>
              </div>
            )}
            {appointmentCampaign?.offices.length > 0 && (
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Offices</div>
              <div className="summary-panel__section__content">
                <UtilityList space className="u-m-b-0">
                  {appointmentCampaign.offices.map((office) => <div className="u-m-b-small" key={office.id}>{office.name}</div>)}
                  {truncateOffices && (
                  <Button
                    type="reset"
                    className="profile__forms__expand u-text-primary"
                    onClick={() => setTruncateOffices(false)}
                  >
                    View more...
                  </Button>
                  )}
                </UtilityList>
              </div>
            </div>
            )}
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Appointment Created</div>
              {appointmentCampaign?.appointmentScheduledEnabled && (
                <div className="summary-panel__section__content">
                  <UtilityList space className="u-m-b-0">
                    {renderTemplateText(appointmentCampaign?.appointmentScheduledTemplate, truncateScheduledText)}
                    {!truncateScheduledText && <OptOut className="u-text-gray" />}
                  </UtilityList>
                  {truncateScheduledText && (
                    <Button
                      type="reset"
                      className="profile__forms__expand u-text-primary"
                      onClick={() => setTruncateScheduledText(false)}
                    >
                      View more...
                    </Button>
                  )}
                  {renderAttachments(TYPE_APPOINTMENT_EVENT_SCHEDULED)}
                </div>
              )}
            </div>
            <AppointmentCampaignSummaryReminders
              appointmentCampaign={appointmentCampaign}
              variableOptions={getAppointmentVariableOptions(APPOINTMENT_REMINDERS_VARIABLE_OPTIONS)}
            />
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentOrganization: getCurrentOrg(state),
  loggedInUserPermissions: getLoggedInUserPermissionNames(state),
  appointmentTypes: state.appointmentTypes.appointmentTypes,
});

AppointmentCampaignSummaryPanel.propTypes = {
  currentOrganization: PropTypes.object,
  loggedInUserPermissions: PropTypes.array,
  appointmentCampaign: PropTypes.object,
  handleTogglePanel: PropTypes.func,
  appointmentTypes: PropTypes.object,
};

export default connect(mapStateToProps)(AppointmentCampaignSummaryPanel);
