import PropTypes from 'prop-types';
import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Message, Avatar } from 'rhinostyle';
import { AppConstants } from '../constants';
import { DataHelpers } from '../helpers';
import PdfViewerContainer from '../containers/PdfViewerContainer';
import { toggleLightboxOpen } from '../reducers/threadReducer';
import { handleDownloadClick } from '../helpers/PdfViewerHelpers';

const IMAGE_CLASS_OPTIONS = {
  loaded: 'is-loaded',
  error: 'has-error',
  default: '',
};

const Attachment = (props) => {
  const dispatch = useDispatch();
  const {
    attachment,
    incoming,
    isNote,
    toggleLightbox,
  } = props;
  const [pdfViewerContainerOpen, setPdfViewerContainerOpen] = useState(false);
  const [imageLinkClass, setImageLinkClass] = useState(IMAGE_CLASS_OPTIONS.default);
  const isNativeApp = useSelector((state) => state.nativeApp.isNativeApp);

  const showPdfViewer = () => {
    setPdfViewerContainerOpen(true);
  };

  const handlePdfViewerClose = () => {
    setPdfViewerContainerOpen(false);
  };

  const handleImageLoad = () => {
    setImageLinkClass(IMAGE_CLASS_OPTIONS.loaded);
  };

  const handleImageError = () => {
    setImageLinkClass(IMAGE_CLASS_OPTIONS.error);
  };

  const filename = attachment.attachmentUrl;
  const isShareLinkWithThumbnail = !!attachment?.shortLink;
  const shareLinkUrl = attachment?.shortLink?.longUrl;
  const url = `${AppConstants.FILE_BASE_URL}${filename}`;
  const imageThumbnailUrl = `${url}?size=thumb`;
  const fileType = attachment.type || DataHelpers.getFileMimeType(url);
  const originalFilename = attachment.name;
  const direction = incoming ? 'inbound' : 'outbound';

  const getMessageType = () => {
    if (isNote) return 'note';
    else return incoming ? 'default' : 'primary';
  };

  const messageClass = cx('convo__item__body__msg__attachments__file', {
    'u-p-a-0': isNote,
  });
  const attachmentIconClass = cx('convo__item__attachment-icon', {
    'is-note': isNote,
  });
  const attachmentTextClass = cx('convo__item__body__msg__attachments__file__text', {
    'is-note': isNote,
  });

  // Is the file type not one that we have the abiilty to preview?
  // Don't render it.
  if (!AppConstants.SUPPORTED_FILE_TYPES_FOR_PREVIEW.includes(fileType)) {
    return null;
  }
  const linkClass = `convo__item__body__msg__attachments__link ${imageLinkClass}`;

  if (isShareLinkWithThumbnail) {
    return (
      <a className={linkClass} href={shareLinkUrl} target="_blank" rel="noreferrer">
        <img onLoad={handleImageLoad} onError={handleImageError} src={imageThumbnailUrl} alt="" />
      </a>
    );
  }

  if (fileType === AppConstants.MIME_TYPE_APPLICATION_PDF) {
    return (
      <Message className={messageClass} type={getMessageType()} direction={direction}>
        <Button
          reset
          onClick={showPdfViewer}
        >
          <Icon icon="attachment" className={attachmentIconClass} />&nbsp;
          <span className={attachmentTextClass}>
            {originalFilename}
          </span>
        </Button>
        <PdfViewerContainer
          open={pdfViewerContainerOpen}
          attachmentUrl={url}
          fileName={originalFilename}
          handlePdfViewerClose={handlePdfViewerClose}
        />
      </Message>
    );
  }
  if (fileType === AppConstants.VCARD_FILE_TYPE) {
    const vCardClass = cx('vcard', {
      'vcard-inbound': direction === 'inbound',
      'vcard-outbound': direction === 'outbound',
    });
    return (
      <div className="u-flex u-flex-direction-row u-flex-justify-end">
        <div className={vCardClass} onClick={() => handleDownloadClick(url, filename, isNativeApp)}>
          {attachment?.name}
          <Avatar size="small" className="vcard-avatar" name={attachment?.name} />
          <Icon className="vcard-icon" icon="caret-right" />
        </div>
      </div>
    );
  }
  function handleToggleLightbox() {
    if (toggleLightbox && typeof toggleLightbox === 'function') {
      toggleLightbox(true, filename);
    } else {
      dispatch(toggleLightboxOpen(filename));
    }
  }

  return (
    <Button
      reset
      onClick={handleToggleLightbox}
      className={linkClass}
    >
      <img onLoad={handleImageLoad} onError={handleImageError} src={imageThumbnailUrl} alt="" />
    </Button>
  );
};

Attachment.propTypes = {
  attachment: PropTypes.object,
  incoming: PropTypes.bool,
  isNote: PropTypes.bool,
  toggleLightbox: PropTypes.func,
};

export default Attachment;
