import PropTypes from 'prop-types';
import React from 'react';

const AppointmentNotificationEvent = (props) => {
  const {
    formattedActiveUserName,
    formattedTimestamp,
  } = props.event;

  return (
    <>
      <div className="convo__event__title">
        Message <strong>sent</strong> for {formattedActiveUserName}
      </div>
      <div className="convo__event__subtext">{formattedTimestamp}</div>
    </>
  );
};

AppointmentNotificationEvent.propTypes = {
  event: PropTypes.object,
};
export default AppointmentNotificationEvent;
