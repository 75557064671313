import { createSlice } from '@reduxjs/toolkit';

const nativeAppSlice = createSlice({
  name: 'NATIVEAPP',
  initialState: {
    touchIdSupported: false,
    touchIdEnabled: false,
    isNativeApp: false,
  },
  reducers: {
    receiveUpdateTouchId: (state, action) => ({
      ...state,
      touchIdSupported: action.payload.touchIdSupported,
      touchIdEnabled: action.payload.touchIdEnabled,
      isNativeApp: true,
    }),
  },
});

export default nativeAppSlice.reducer;

// ACTIONS
export const { receiveUpdateTouchId } = nativeAppSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

export function updateTouchId(payload) {
  return (dispatch) => {
    dispatch(receiveUpdateTouchId(payload));
  };
}
