import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  Icon,
  Tooltip,
} from 'rhinostyle';
import { UserHelpers } from '../helpers';
import {
  getActiveChatGroup,
  getActiveChatThreadId,
  getChatName,
} from '../selectors/chatSelectors';
import { getActiveUser } from '../selectors/userSelectors';
import { closeChatConversation } from '../reducers/chatReducer';
import { toggleProfile } from '../reducers/threadReducer';

const ChatThreadHeader = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { activeUser, activeGroup, activeChatThreadId, chatName } = props;
  const handleCloseConversation = () => {
    const payload = { threadIds: [activeChatThreadId], conversationActionType: 'closeConversation' };
    dispatch(closeChatConversation(payload)).then(() => {
      history.push('/chat');
    });
  };

  const handleCloseChatThread = () => {
    if (location.state?.mentionEventId) {
      history.push('/inbox/mentions');
    } else if (location.state) {
      const {
        chatCurrentPageNumber,
        selectedInboxThreadIds,
        chatPageNumber,
        chatTotalPageCount,
        isChatsCheckboxChecked,
        chatsLabelValueAssociated,
        chatsCheckboxClassName,
        selectedThreadsIdCount,
        chatBulkActionsList,
        selectedInboxThreads,
        currentThreadId,
      } = location.state;
      history.push({
        pathname: '/chat',
        state: {
          chatCurrentPageNumber,
          chatPageNumber,
          selectedInboxThreadIds,
          chatTotalPageCount,
          isChatsCheckboxChecked,
          chatsLabelValueAssociated,
          chatsCheckboxClassName,
          selectedThreadsIdCount,
          chatBulkActionsList,
          selectedInboxThreads,
          currentThreadId,
        },
      });
    } else {
      history.push({
        pathname: '/chat',
      });
    }
  };

  return (
    <div className="convo__header">
      {(activeUser || activeGroup) && (
      <Button
        reset
        className="u-text-muted convo__header__back-button u-m-r"
        onClick={handleCloseChatThread}
      >
        <Icon icon="arrow-left" />
      </Button>
      )}
      <div className="convo__header__title u-flex-1">
        <div className="convo__header__title__name">
          <span className="u-text-overflow">{chatName}</span>
        </div>
        <div className="convo__header__title__deets__chat-thread">
          <span className="u-text-overflow">
            {activeUser ? UserHelpers.formatTypes(activeUser) : null}
            {activeUser && activeUser.externalIds ? <span>&nbsp;(#{UserHelpers.getExternalId(activeUser)})</span> : null}
          </span>
        </div>
      </div>
      {!activeGroup?.name && activeChatThreadId && (
      <div className="convo__header__close-conversation">
        <Tooltip placement="bottom" content={<span className="close-conversation-text">Close Team Message</span>}>
          <div
            className="convo__header__actions__button"
            onClick={handleCloseConversation}
          >
            <Icon icon="close-conversation" />
          </div>
        </Tooltip>
      </div>
      )}
      <div className="convo__header__actions">
        <Button
          className="convo__header__actions__button"
          onClick={() => dispatch(toggleProfile())}
          reset
        >
          <Icon icon="user" />
        </Button>
      </div>
    </div>
  );
};
ChatThreadHeader.propTypes = {
  activeUser: PropTypes.object,
  activeGroup: PropTypes.object,
  activeChatThreadId: PropTypes.number,
  chatName: PropTypes.string,
};
const mapStateToProps = (state) => ({
  activePanel: state.thread.activePanel,
  activeUser: getActiveUser(state),
  activeGroup: getActiveChatGroup(state),
  activeChatThreadId: getActiveChatThreadId(state),
  chatName: getChatName(state),
});
export default connect(mapStateToProps)(ChatThreadHeader);
