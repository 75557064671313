import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormLabel, Icon, Input, RhinoSwitch, Radio, RadioGroup, UtilityInlineGrid } from 'rhinostyle';
import ExpandableBox from './ExpandableBox';
import { DateHelpers } from '../helpers';
import VariableMessage from './VariableMessage';
import {
  TYPE_PRESCRIPTION_EVENT_REMINDER,
  TYPE_PRESCRIPTION_CAMPAIGN_INITAL_TYPE,
  TYPE_PRESCRIPTION_CAMPAIGN_REFILL_TYPE,
  TYPE_EVENT_DATE_CREATED_AT,
  TYPE_EVENT_DATE_PRESCRIPTION_REFILL,
  TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_PRE,
  TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_POST,
} from '../constants/Types';
import { PRESCRIPTION_NOTIFICATION_EDIT } from '../constants/UserPermissionsConstants';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { templateContainsConsent } from '../helpers/PrescriptionHelpers';
import { PRESCRIPTION_MESSAGE_VARIABLE_OPTIONS } from '../constants/VariableMessageConstants';

const PrescriptionCampaignFormMessage = ({
  handleChange,
  handleRemoveCampaignMessage,
  errors,
  campaignMessage: {
    deliveryHours,
    deliveryHoursDirection,
    deliveryHoursType,
    key,
    template,
    stopOnReply,
    type,
  },
}) => {
  const [isDeleteRequested, setIsDeleteRequested] = useState(false);
  // form constants
  const deliveryLogicLabelText = 'Do NOT send this message if consent has been granted in a previous message.';
  const expanderTitleText = 'Prescription Notification Message';
  // field names
  const deliveryHoursDirectionFieldName = `${key}_deliveryHoursDirection`;
  const deliveryHoursFieldName = `${key}_deliveryHours`;
  const deliveryHoursTypeFieldName = `${key}_deliveryHoursType`;
  const typeFieldName = `${key}_type`;
  const stopOnReplyFieldName = `${key}_stopOnReply`;
  const templateFieldName = `${key}_template`;
  // derived values
  const isNewPrescription = type === TYPE_PRESCRIPTION_CAMPAIGN_INITAL_TYPE;
  const isCreateDateDelivery = deliveryHoursType === TYPE_EVENT_DATE_CREATED_AT;
  const isPreDelivery = deliveryHoursDirection === TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_PRE;
  // enforce that new prescriptions only support create date and post delivery
  if (isNewPrescription) {
    if (deliveryHoursDirection !== TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_POST) {
      handleChange(key, 'deliveryHoursDirection', TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_POST);
    }
    if (deliveryHoursType !== TYPE_EVENT_DATE_CREATED_AT) {
      handleChange(key, 'deliveryHoursType', TYPE_EVENT_DATE_CREATED_AT);
    }
  } else if (isCreateDateDelivery) {
    if (deliveryHoursDirection !== TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_POST) {
      handleChange(key, 'deliveryHoursDirection', TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_POST);
    }
  }
  const subTitle = isNewPrescription ? 'Message will be delivered to patient for new prescriptions.' : 'Message will be delivered to patient for prescription refills.';
  const deliveryHoursFormatted = DateHelpers.convertHoursToDays(deliveryHours, true);
  // collapsed header message
  const formattedType = isNewPrescription ? 'New Prescription' : 'Prescription Refill';
  const formattedDelivery = `${deliveryHoursFormatted} ${isPreDelivery ? 'Pre Delivery' : 'Post Delivery'}`;
  const formattedRequiresExempt = templateContainsConsent(template) ? 'Consent Required' : 'Consent Exempt';
  const reminderDeliveryText = (
    <>
      {formattedType} | {formattedDelivery} | {formattedRequiresExempt}
    </>
  );
  const renderDeleteOption = () => {
    const isDeleteButtonVisible = !isDeleteRequested;
    if (isDeleteRequested) {
      return (
        <div className="item-status__confirm">
          <div className="item-status__confirm__text">
            <Icon bump="up" icon="trash" />&nbsp;Are you sure you want to delete this notification message?
          </div>
          <UtilityInlineGrid className="u-p-b" size="regular" align="middle">
            <Button
              size="small"
              type="link-muted"
              onClick={() => setIsDeleteRequested(false)}
            >
              Cancel
            </Button>
            <Button
              size="small"
              type="danger"
              onClick={() => handleRemoveCampaignMessage(key)}
              data-cypress={`confirmDelete-${key}`}
            >
              Yes, delete notification message
            </Button>
          </UtilityInlineGrid>
        </div>
      );
    }
    return (
      <div className="item-status u-p-b">
        {isDeleteButtonVisible && userHasAnyOfPermissions([PRESCRIPTION_NOTIFICATION_EDIT]) && (
          <div className="item-status__left">
            <Button
              reset
              onClick={() => setIsDeleteRequested(true)}
              className="item-status__delete-icon"
              title="Delete Notification Message"
            >
              <Icon bump="down" size="large" icon="trash" />
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <ExpandableBox
      title={expanderTitleText}
      subtitle={subTitle}
      description={reminderDeliveryText}
      dataFeatureTag={`${key}-prescription-message-expand`}
    >
      <>
        <FormLabel
          id={`render${key}PrePostSettings`}
          className="variable-message__label u-m-t u-m-b-small"
          required
        >Message Type
        </FormLabel>
        <RadioGroup
          name={typeFieldName}
          onChange={(value) => handleChange(key, 'type', value)}
          inline
          selectedValue={type}
          className="u-p-b"
        >
          <Radio
            value={TYPE_PRESCRIPTION_CAMPAIGN_INITAL_TYPE}
            label="New Prescription"
            dataCypress="newPrescriptionMessage"
          />
          <Radio
            value={TYPE_PRESCRIPTION_CAMPAIGN_REFILL_TYPE}
            label="Prescription Refill"
            dataCypress="refillPrescriptionMessage"
          />
        </RadioGroup>
        <hr />
        <FormLabel
          id={`render${key}Settings`}
          className="variable-message__label u-m-t u-m-b-small"
          required
        >
          Message Timing
        </FormLabel>
        Deliver Based On Prescription
        {isNewPrescription && (<div className="u-text-small u-text-italic u-text-muted">New Prescription messages can only be delivered based on create on date.</div>)}
        <RadioGroup
          name={deliveryHoursTypeFieldName}
          onChange={(value) => handleChange(key, 'deliveryHoursType', value)}
          inline
          selectedValue={deliveryHoursType}
          className="u-p-b"
        >
          <Radio
            disabled={isNewPrescription}
            value={TYPE_EVENT_DATE_CREATED_AT}
            label="Created on Date"
            dataCypress="createDatePrescriptionMessage"
          />
          <Radio
            disabled={isNewPrescription}
            value={TYPE_EVENT_DATE_PRESCRIPTION_REFILL}
            label="Refill Date"
            dataCypress="refillDatePrescriptionMessage"
          />
        </RadioGroup>
        Pre or Post Delivery Message
        {isNewPrescription && (<div className="u-text-small u-text-italic u-text-muted">New Prescription messages can only be post delivery.</div>)}
        {!isNewPrescription && isCreateDateDelivery && (<div className="u-text-small u-text-italic u-text-muted">Messages based on Created on Date can only be post delivery.</div>)}
        <RadioGroup
          name={deliveryHoursDirectionFieldName}
          onChange={(value) => handleChange(key, 'deliveryHoursDirection', value)}
          inline
          selectedValue={deliveryHoursDirection}
          className="u-p-b"
        >
          <Radio
            disabled={isNewPrescription || isCreateDateDelivery}
            value={TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_PRE}
            label="Pre Delivery Message"
            dataCypress="preDeliveryPrescriptionMessage"
          />
          <Radio
            disabled={isNewPrescription || isCreateDateDelivery}
            value={TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_POST}
            label="Post Delivery Message"
            dataCypress="postDeliveryPrescriptionMessage"
          />
        </RadioGroup>
        <span>Delivery Hours</span>
        <br />
        <Input
          name={deliveryHoursFieldName}
          initialValue={deliveryHours.toString()}
          format={{
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0,
          }}
          className="am__hours-input u-m-r u-m-b u-inline-block"
          onChange={(field, rawValue, value) => handleChange(key, 'deliveryHours', Number(value))}
          required
          dataFeatureTag={deliveryHoursFieldName}
          validationMessage={errors[deliveryHoursFieldName]}
        />
        <strong data-cypress={`${deliveryHoursFieldName}Formatted`}>
          {deliveryHoursFormatted}{isPreDelivery ? ' Before ' : ' After '}{isCreateDateDelivery ? ' Created on Date' : ' Refill Date' }
        </strong>
        <hr />
        <RhinoSwitch
          name={stopOnReplyFieldName}
          isChecked={stopOnReply}
          onChange={(field, value) => handleChange(key, 'stopOnReply', value)}
          label={deliveryLogicLabelText}
          dataFeatureTag={`edit-prescription-logic_toggle-${key}`}
        />
        <VariableMessage
          composeLabel="Message"
          name={templateFieldName}
          initialValue={template}
          handleTemplateChange={(fieldName, value) => handleChange(key, 'template', value)}
          variableOptions={PRESCRIPTION_MESSAGE_VARIABLE_OPTIONS}
          template={template}
          required
          hideAttachments
          validationMessage={errors[templateFieldName]}
          preventFocusOnLoad
          prescriptionEventTypeId={TYPE_PRESCRIPTION_EVENT_REMINDER}
        />
        {renderDeleteOption()}
      </>
    </ExpandableBox>
  );
};

PrescriptionCampaignFormMessage.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleRemoveCampaignMessage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  campaignMessage: PropTypes.shape({
    key: PropTypes.string,
    id: PropTypes.number,
    template: PropTypes.string,
    deliveryHoursDirection: PropTypes.number,
    deliveryHours: PropTypes.number,
    deliveryHoursType: PropTypes.number,
    type: PropTypes.number,
    name: PropTypes.string,
    stopOnReply: PropTypes.bool,
  }).isRequired,
};

export default PrescriptionCampaignFormMessage;
