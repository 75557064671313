import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NotificationActions } from 'rhinostyle';
import axios from 'axios';

import { updatePayment, updateCreditCard } from '../reducers/billingReducer';
import * as OrganizationReducer from '../reducers/organizationReducer';
import OrganizationBillingPayment from '../components/OrganizationBillingPayment';
import { ValidationHelpers, DateHelpers } from '../helpers';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { AppConstants } from '../constants';
import { getLoggedInUser } from '../selectors/userSelectors';

class OrganizationBillingPaymentContainer extends Component {
  state = {
    formInProgress: false,
    errors: {},
    selectedPaymentMethod: AppConstants.PAYMENT_METHODS.CREDIT_CARD,
    firstName: '',
    lastName: '',
    paymentModalOpen: false,
    activeBankAccountTypeId: 1,
    ipAddress: '',
    isChanged: false,
  };

  componentDidMount() {
    const script = document.createElement('script');
    script.src = AppConstants.RISK_JS_URL;
    script.async = true;
    script.id = 'WePayScript';
    document.body.appendChild(script);

    this.getForeignDeviceData();

    if (this.props.payment) {
      this.setState({
        firstName: this.props.payment.firstName || '',
        lastName: this.props.payment.lastName || '',
      }, () => { this.initialState = this.state; });
      this.selectMethod();
    }
  }

  componentWillUnmount() {
    let elem;
    (elem = document.getElementById('WePayScript')).parentNode.removeChild(elem);
  }

  getForeignDeviceData = () =>
    axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`, { withCredentials: false })
      .then((response) => this.setState({ ipAddress: response.data.ip }))
      .catch((err) => {
        console.error(err);
      });

  cleaveCreditCardZip = null;
  cleaveCreditCardNumber = null;

  selectMethod = () => {
    if (this.props.payment.payment) {
      if (this.props.payment.creditCard) {
        this.setState({ selectedPaymentMethod: AppConstants.PAYMENT_METHODS.CREDIT_CARD });
      } else {
        this.setState({
          selectedPaymentMethod: AppConstants.PAYMENT_METHODS.BANK,
          activeBankAccountTypeId: this.props.payment.payment.bankAccountType === 'CHQ' ? 1 : 2,
        });
      }
    }
  }

  handleChange = (name, value) => {
    if (name === 'selectedPaymentMethod') {
      this.resetPayment();
    }
    this.setState({ [name]: value });
  }

  handleCleaveInit = (name, cleave) => {
    this[name] = cleave;
  }

  handleOnReverseComplete = () => {
    this.resetPayment();
    if (this.state.isChanged) {
      this.setState({ isChanged: false });
    } else {
      this.setState({
        ...this.initialState,
        firstName: this.props.payment.firstName,
        lastName: this.props.payment.lastName,
      });
    }
  }

  handleToggle = (key) => {
    this.setState({ [key]: !this.state[key] });
    if (this.props.payment.creditCard) {
      this.setState({ selectedPaymentMethod: AppConstants.PAYMENT_METHODS.CREDIT_CARD });
    } else if (this.props.payment.payment) {
      this.setState({
        selectedPaymentMethod: AppConstants.PAYMENT_METHODS.BANK,
        activeBankAccountTypeId: this.props.payment.payment.bankAccountType === 'CHQ' ? 1 : 2,
      });
    }
  }

  scrollToFirstError = () => {
    const firstError = Object.keys(this.state.errors)[0];
    ValidationHelpers.scrollToFormElement(firstError, this.pageContainer);
  }

  formatPayment = (p) => ({
    firstName: p.firstName,
    lastName: p.lastName,
    selectedPaymentMethod: p.selectedPaymentMethod,
    creditCard: p.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD,
    reCaptchaToken: p.reCaptchaToken,
    payment: {
      ccNumber: p.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD ? p.creditCardNumber : undefined,
      ccExpMonth: p.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD ? p.creditCardExpMonth : undefined,
      ccExpYear: p.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD ? DateHelpers.formatYear(p.creditCardExpYear) : undefined,
      ccCvv: p.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD ? p.creditCardVerificationValue : undefined,
      bankName: p.selectedPaymentMethod !== AppConstants.PAYMENT_METHODS.CREDIT_CARD ? p.bankName : undefined,
      bankAccNum: p.selectedPaymentMethod !== AppConstants.PAYMENT_METHODS.CREDIT_CARD ? p.bankAccountNumber : undefined,
      bankRouteNum: p.selectedPaymentMethod !== AppConstants.PAYMENT_METHODS.CREDIT_CARD ? p.bankRoutingNumber : undefined,
      bankAccountType: p.activeBankAccountTypeId === 1 ? 'CHQ' : 'SAV',
      creditCardZip: p.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD ? p.creditCardZip : undefined,
    },
  })

  resetPayment = () => {
    this.setState({
      creditCardNumber: '',
      creditCardExpMonth: -1,
      creditCardExpYear: -1,
      creditCardVerificationValue: '',
      bankName: '',
      bankAccountNumber: '',
      bankRoutingNumber: '',
      bankAccountType: 1,
      creditCardZip: '',
    }, () => {
      if (this.state.selectedPaymentMethod === 'credit') {
        if (this.state.cleaveCreditCardZip) this.state.cleaveCreditCardZip.setRawValue('');
        if (this.state.cleaveCreditCardNumber) this.state.cleaveCreditCardNumber.setRawValue('');
      }
    });
  }

  formatCreditCard(cc, prop) {
    global.WePay.risk.generate_risk_token();
    return `CustomerID=${prop.billing.billManagerCustId}` +
      `&PublicAPIKey=${process.env.REACT_APP_FUSEBILL_PUBLIC_KEY}` +
      `&ID=${prop.payment.billManagerPaymentId}` +
      `&CardNumber=${cc.payment.ccNumber}` +
      `&FirstName=${cc.firstName}` +
      `&LastName=${cc.lastName}` +
      `&ExpirationYear=${cc.payment.ccExpYear}` +
      `&ExpirationMonth=${cc.payment.ccExpMonth}` +
      `&Cvv=${cc.payment.ccCvv}` +
      '&makeDefault=true' +
      `&Email=${this.props.contactEmail}` +
      `&postalZip=${cc.payment.creditCardZip}` +
      `&clientIp=${this.state.ipAddress}` +
      `&RiskToken=${global.WePay.risk.get_risk_token()}` +
      `&recaptcha=${cc.reCaptchaToken}`;
  }

  formatBillingCc(billManagerPaymentId, props, p) {
    return {
      billManagerPaymentId,
      firstName: p.firstName,
      lastName: p.lastName,
      creditCard: p.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD,
      billManagerCustId: props.billing.billManagerCustId,
    };
  }

  handleSubmit = () => {
    const isSelectedPaymentMethodIsCreditCard = this.state.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD;
    const reCaptchaToken = isSelectedPaymentMethodIsCreditCard ? window?.grecaptcha?.getResponse() : null;
    const formattedPayment = this.formatPayment({ ...this.state, reCaptchaToken });
    const errors = ValidationService(ValidationShapers.shapeMemberBilling({ ...formattedPayment }));
    const errorCount = Object.keys(errors).length;

    if (errorCount > 0) {
      this.setState({ errors });
    } else if (formattedPayment.creditCard && !ValidationHelpers.validateExpirationDate(formattedPayment.payment.ccExpYear, formattedPayment.payment.ccExpMonth)) {
      errors.ccExpMonth = 'Credit card cannot be expired.';
      this.setState({ errors });
    } else if (formattedPayment.creditCard) {
      this.setState({ formInProgress: true });
      OrganizationReducer.addCreditCard(this.formatCreditCard(formattedPayment, this.props))
        .then((res) => {
          this.props.updateCreditCard(this.formatBillingCc(res.data.id, this.props, formattedPayment))
            .then(() => {
              const newState = { formInProgress: false, disabled: true, errors: {}, isChanged: true };
              if (this.props.error) {
                newState.errors = ValidationHelpers.handleServerError(this.props.error.data);
              } else {
                newState.updateView = false;
                newState.paymentModalOpen = false;
              }
              this.setState(newState);
              this.resetPayment();
            });
        })
        .catch((err) => {
          this.setState({ formInProgress: false, disabled: true });
          NotificationActions.addNotification({
            body: err.message,
            icon: 'warning',
            type: 'danger',
          });
        });
    } else if (!formattedPayment.creditCard) {
      this.setState({ formInProgress: true });
      this.props.updatePayment(formattedPayment)
        .then(() => {
          const newState = { formInProgress: false, disabled: true, errors: {}, isChanged: true };

          if (this.props.error) {
            newState.errors = ValidationHelpers.handleServerError(this.props.error.data);
          } else {
            newState.updateView = false;
            newState.paymentModalOpen = false;
          }
          this.setState(newState);
          this.resetPayment();
        });
    }
  }

  render() {
    const props = {
      errors: this.state.errors,
      payment: this.props.payment.payment,
      handleCleaveInit: this.handleCleaveInit,
      handleChange: this.handleChange,
      handleCancel: this.handleCancel,
      handleOnReverseComplete: this.handleOnReverseComplete,
      handleToggle: this.handleToggle,
      formInProgress: this.state.formInProgress,
      handleSubmit: this.handleSubmit,
      selectedPaymentMethod: this.state.selectedPaymentMethod,
      startBillingDate: this.state.startBillingDate,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      paymentModalOpen: this.state.paymentModalOpen,
      bankAccountTypes: AppConstants.bankAccountTypes,
      activeBankAccountTypeId: this.state.activeBankAccountTypeId,
      bankRoutingNumber: this.state.bankRoutingNumber,
      bankName: this.state.bankName,
      bankAccountNumber: this.state.bankAccountNumber,
      creditCardNumber: this.state.creditCardNumber,
      creditCardExpMonth: this.state.creditCardExpMonth,
      creditCardExpYear: this.state.creditCardExpYear,
      creditCardVerificationValue: this.state.creditCardVerificationValue,
      creditCardZip: this.state.creditCardZip,
      userIsCcr: this.props.user.isCcr,
      isValidCaptcha: this.props.isValidCaptcha,
      errorMessage: this.props.errorMessage,
    };

    return (<OrganizationBillingPayment {...props} />);
  }
}

OrganizationBillingPaymentContainer.propTypes = {
  error: PropTypes.object,
  updatePayment: PropTypes.func.isRequired,
  payment: PropTypes.object.isRequired,
  updateCreditCard: PropTypes.func,
  contactEmail: PropTypes.string.isRequired,
  user: PropTypes.object,
  errorMessage: PropTypes.string.isRequired,
  isValidCaptcha: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { ui, billing } = state;

  return {
    error: ui.error,
    payment: billing.payment,
    contactEmail: billing.billing.contactEmail,
    user: getLoggedInUser(state),
    isValidCaptcha: billing.isValidCaptcha,
    errorMessage: billing.errorMessage,
  };
};

const actions = {
  updatePayment,
  updateCreditCard,
};

export default connect(mapStateToProps, actions)(OrganizationBillingPaymentContainer);
