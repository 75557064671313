import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../helpers/AxiosHelper';

export const prescriptionReportSlice = createApi({
  reducerPath: 'prescriptionReports',
  baseQuery: axiosBaseQuery({
    baseURL: process.env.REACT_APP_V3_BASE_URL,
  }),
  tagTypes: ['PrescriptionReports'],
  endpoints: (builder) => ({
    getRunReport: builder.mutation({
      query: (props) => {
        const {
          fromDate,
          toDate,
          reportName,
          reportType,
        } = props;
        let url = `/reporting/${reportName}?fileType=${reportType}`;
        url = encodeURI(url);
        return {
          url,
          method: 'GET',
          ...fromDate && toDate && {
            params: { fromDate, toDate },
          },
        };
      },
    }),
  }),
});

export const {
  useGetRunReportMutation,
} = prescriptionReportSlice;
