import { UtilitySystem } from 'rhinostyle';

/**
 * Update height of node to match current viewport size
 * @param  {node} node
 * @return {void}
 */
function _updateViewportHeight(viewportHeight, node) {
  const viewportValue = node.getAttribute('data-vh');
  const calculatedHeight = `${Math.round((viewportHeight * viewportValue) / 100)}px`;

  // Set Node as already being calculated
  node.setAttribute('data-vh-calculated', '');

  // Inline height and min-height for node
  node.style.height = calculatedHeight; // eslint-disable-line
  node.style.minHeight = calculatedHeight; // eslint-disable-line
}

/**
 * Main function for calculating current viewport height
 * @param  {Boolean} [resize=false] If we are inside of the resize listener
 * @return {void}
 */
export default function calculateViewportHeight(resize = false) {
  const zoom = document.getElementById('app')?.style?.zoom;
  const $viewportNodes = document.querySelectorAll(resize ? '[data-vh]' : '[data-vh]:not([data-vh-calculated])');
  const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  const heightAdjustedForZoom = zoom && Number(zoom) >= 1 ? viewportHeight / Number(zoom) : viewportHeight;
  // Only run through if we return any valid nodes
  if ($viewportNodes?.length > 0) {
    // needed for address bar autohide on iOS safari after device rotation.
    window.scrollTo(0, 1);
    UtilitySystem.forEach($viewportNodes, (index, value) => {
      _updateViewportHeight(heightAdjustedForZoom, value);
    });
  }
}
