import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'rhinostyle';

const MultiOrg = (props) => {
  const {
    resetUser,
  } = props;

  return (
    <Button
      reset
      className="app-nav__multi-org"
      onClick={resetUser}
    >
      Select Organization
    </Button>
  );
};

MultiOrg.propTypes = {
  resetUser: PropTypes.func,
};

export default MultiOrg;
