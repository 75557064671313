import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Avatar,
  Icon,
} from 'rhinostyle';
import { PhoneHelpers, UserHelpers, BrowserHelpers } from '../helpers';
import RhinocallActionButtons from './RhinocallActionButtons';
import { getActiveCall, getCallContactInfo } from '../selectors/rhinocallSelector';
import { CALL_STATUS_ACTIVE } from '../constants/RhinocallConstants';
import { AVATAR_BASE_URL } from '../constants/AppConstants';

const RhinocallConnected = (props) => {
  const {
    contact,
    activeCall,
  } = props;
  const isConnected = activeCall?.status === CALL_STATUS_ACTIVE;

  const renderContactInfo = () => (
    <div className="rhinocall__contact-info_container u-text-white">
      <Avatar
        image={contact?.profileImageUrl ? `${AVATAR_BASE_URL}${contact.profileImageUrl}` : ''}
        name={UserHelpers.formatAvatarName(contact?.firstName, contact?.lastName)}
        type="default"
        className="rhinocall__avatar"
      />
      <div className="rhinocall__contact-name">{UserHelpers.formatName(contact)}</div>
      <div className="rhinocall__contact-phone">{PhoneHelpers.formatPhone(activeCall?.to || activeCall?.toNumber)}</div>
    </div>
  );

  const renderCallStatus = () => {
    const connectedStatus = BrowserHelpers.isMobile() ? 'Connecting/Connected' : 'Connected';
    return (
      <div
        className={`rhinocall__call-status ${isConnected ? 'u-bg-success' : 'u-bg-warning'}`}
        data-cypress="callStatus"
      >
        <Icon
          size="large"
          icon={isConnected ? 'phone' : 'connecting'}
          className="rhinocall__call-status-icon"
        />
        {isConnected ? connectedStatus : 'Calling'}
      </div>
    );
  };

  return (
    <>
      {renderContactInfo()}
      {renderCallStatus()}
      <RhinocallActionButtons />
    </>
  );
};

RhinocallConnected.propTypes = {
  contact: PropTypes.object,
  activeCall: PropTypes.object,
};

const mapStateToProps = (state) => ({
  contact: getCallContactInfo(state),
  activeCall: getActiveCall(state),
});

export default connect(mapStateToProps)(RhinocallConnected);
