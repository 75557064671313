import React from 'react';
import { Date, NavTabs, NavTabsItem, Select, Table } from 'rhinostyle';
import cx from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {
  TYPE_INTEGRATION_CLOUD_9,
  TYPE_INTEGRATION_DOLPHIN,
  TYPE_INTEGRATION_EPIC,
  TYPE_INTEGRATION_FOCUS_ORTHO,
  TYPE_INTEGRATION_MI7,
  TYPE_INTEGRATION_NEXTECH,
  TYPE_INTEGRATION_OPEN_DENTAL,
  TYPE_INTEGRATION_PROGNOCIS,
  TYPE_INTEGRATION_SIKKA,
  TYPE_INTEGRATION_TOPS,
} from '../constants/Types';
import { convertDateInTimeZone } from '../helpers/DateHelpers';
import PageLoader from './PageLoader';

const IntegrationAnalytics = (props) => {
  const {
    offices,
    activeTab,
    integrationPartnerTypeId,
    updateActiveTab,
    handleChange,
    defaultDateApi,
    selectedDateApi,
    selectedDateFeeder,
    selectedDateLiner,
    apiData,
    linerData,
    feederData,
    selectedFeederJobIndex,
    showExpandedPatientIssues,
    showExpandedOtherIssues,
    showApiTimestamp,
    hasDataApi,
    hasDataFeeder,
    hasDataLiner,
    isTabLoading,
  } = props;

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': false,
  });

  const renderEmptyStateMessage = () => (
    <div className="integration-analytics__message__empty-state">
      Sorry! There&apos;s nothing to show.
      <p>
        Once data is available for this section, it will appear here.
      </p>
    </div>
  );

  const renderDashboardTitle = (typeId) => {
    switch (typeId) {
      case TYPE_INTEGRATION_CLOUD_9:
        return 'Cloud 9';
      case TYPE_INTEGRATION_EPIC:
        return 'Epic';
      case TYPE_INTEGRATION_FOCUS_ORTHO:
        return 'FocusOrtho';
      case TYPE_INTEGRATION_MI7:
        return 'MI7';
      case TYPE_INTEGRATION_NEXTECH:
        return 'Nextech';
      case TYPE_INTEGRATION_OPEN_DENTAL:
        return 'Open Dental';
      case TYPE_INTEGRATION_SIKKA:
        return 'Sikka';
      case TYPE_INTEGRATION_TOPS:
        return 'Tops';
      case TYPE_INTEGRATION_DOLPHIN:
        return 'Dolphin';
      case TYPE_INTEGRATION_PROGNOCIS:
        return 'PrognoCIS';
      default:
        return '';
    }
  };

  const renderDatePicker = (tab, selectedDate, defaultDate) =>
    (
      <div className="integration-analytics__datepicker">
        <Date
          disabled={false}
          name="process-date"
          maxDate={moment()}
          selected={selectedDate || defaultDate}
          onChange={(date) => {
            handleChange(`selectedDate${tab}`, date);
          }}
          explanationMessage=""
        />
      </div>
    );

  // ====== Feeder tab components ======

  const renderFeederAppointmentsDataTable = (data) => {
    // used in the event that there was no appointment data available, but still jobs processed on that date
    const emptyStateData = {
      appointments: [{
        processed: 0,
        appointmentErrors: 0,
        skipped: 0,
      }],
    };
    const hasAppointmentData = data.length > 0 && data[selectedFeederJobIndex].appointments.length > 0;
    const selectedFeederData = hasAppointmentData ? data[selectedFeederJobIndex] : emptyStateData;
    return (
      <div className="integration-analytics__table__csv-table-parent-div">
        <span className="integration-analytics__table__table-name">Appointments</span>
        <Table className="integration-analytics__table">
          <thead>
            <tr>
              <th>Details</th>
              <th>Total</th>
              <th>Processed</th>
              <th>Error</th>
              <th>Skipped</th>
            </tr>
          </thead>
          <tbody>
            {selectedFeederData.appointments.map((apptData, index) => {
              let officeName;
              if (apptData.officeId && offices[apptData.officeId]) officeName = offices[apptData.officeId].name;
              else officeName = '(No office available)';
              const key = `feeder-appt-data-${index}`;
              const { processed, appointmentErrors, skipped } = apptData;
              return (
                <tr key={key}>
                  <td className="integration-analytics__table__details-column">{officeName}</td>
                  <td className="integration-analytics__table__data-column">{processed + appointmentErrors + skipped}</td>
                  <td className="integration-analytics__table__data-column">{processed}</td>
                  <td className="integration-analytics__table__data-column">{appointmentErrors}</td>
                  <td className="integration-analytics__table__data-column">{skipped}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderFeederContactsDataTable = (data) => {
    const selectedFeederData = data[selectedFeederJobIndex];
    const { patients, connectedParties } = selectedFeederData;
    const patientsProcessed = !selectedFeederData.isCSV ? patients.processed : 0;
    const patientErrors = !selectedFeederData.isCSV ? patients.patientErrors : 0;
    const patientsSkipped = !selectedFeederData.isCSV ? patients.skipped : 0;
    const cpProcessed = !selectedFeederData.isCSV ? connectedParties.processed : 0;
    const cpErrors = !selectedFeederData.isCSV ? connectedParties.connectedPartyErrors : 0;
    const cpSkipped = !selectedFeederData.isCSV ? connectedParties.skipped : 0;
    return (
      <div>
        <span className="integration-analytics__table__table-name">Contacts</span>
        <Table className="integration-analytics__table">
          <thead>
            <tr>
              <th>Details</th>
              <th>Total</th>
              <th>Processed</th>
              <th>Error</th>
              <th>Skipped</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="integration-analytics__table__details-column">Patient</td>
              <td className="integration-analytics__table__data-column">{patientsProcessed + patientErrors + patientsSkipped}</td>
              <td className="integration-analytics__table__data-column">{patientsProcessed}</td>
              <td className="integration-analytics__table__data-column">{patientErrors}</td>
              <td className="integration-analytics__table__data-column">{patientsSkipped}</td>
            </tr>
            <tr>
              <td className="integration-analytics__table__details-column">Other</td>
              <td className="integration-analytics__table__data-column">{cpProcessed + cpErrors + cpSkipped}</td>
              <td className="integration-analytics__table__data-column">{cpProcessed}</td>
              <td className="integration-analytics__table__data-column">{cpErrors}</td>
              <td className="integration-analytics__table__data-column">{cpSkipped}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  const renderFeederCSVContactsDataTable = (data) => {
    const selectedFeederData = data[selectedFeederJobIndex];
    const { patients, connectedParties } = selectedFeederData;
    const patientsProcessed = selectedFeederData.isCSV ? patients.processed : 0;
    const patientErrors = selectedFeederData.isCSV ? patients.patientErrors : 0;
    const patientsSkipped = selectedFeederData.isCSV ? patients.skipped : 0;
    const otherProcessed = selectedFeederData.isCSV ? connectedParties.processed : 0;
    const otherErrors = selectedFeederData.isCSV ? connectedParties.connectedPartyErrors : 0;
    const otherSkipped = selectedFeederData.isCSV ? connectedParties.skipped : 0;
    return (
      <div>
        <span className="integration-analytics__table__table-name">CSV Contacts</span>
        <Table className="integration-analytics__table">
          <thead>
            <tr>
              <th>Details</th>
              <th>Total</th>
              <th>Processed</th>
              <th>Error</th>
              <th>Skipped</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="integration-analytics__table__details-column">Patient</td>
              <td className="integration-analytics__table__data-column">{patientsProcessed + patientErrors + patientsSkipped}</td>
              <td className="integration-analytics__table__data-column">{patientsProcessed}</td>
              <td className="integration-analytics__table__data-column">{patientErrors}</td>
              <td className="integration-analytics__table__data-column">{patientsSkipped}</td>
            </tr>
            <tr>
              <td className="integration-analytics__table__details-column">Other</td>
              <td className="integration-analytics__table__data-column">{otherProcessed + otherErrors + otherSkipped}</td>
              <td className="integration-analytics__table__data-column">{otherProcessed}</td>
              <td className="integration-analytics__table__data-column">{otherErrors}</td>
              <td className="integration-analytics__table__data-column">{otherSkipped}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  const renderFeederTimestamp = (index) => {
    let formattedStartTimestamp = 'No start time available.';
    let formattedEndTimestamp = 'No end time available.';
    if (feederData[index]) {
      formattedStartTimestamp = convertDateInTimeZone(moment.utc(feederData[index].timeStart), 'America/New_York').format('LLL');
      formattedEndTimestamp = convertDateInTimeZone(moment.utc(feederData[index].timeEnd), 'America/New_York').format('LLL');
    }
    return (
      <div className="integration-analytics__timestamp">
        <span className="u-text-muted">PROCESS TIMESTAMP</span>
        <div className="integration-analytics__timestamp-text">
          <span className="integration-analytics__timestamp-text-item"><strong>Started: </strong>{formattedStartTimestamp}</span>
          <span className="integration-analytics__timestamp-text-item"><strong>Ended: </strong>{formattedEndTimestamp}</span>
        </div>
      </div>
    );
  };

  const renderFeederJobSelectForm = (data) => {
    const selectOpts = data.map((feederJob, id) => {
      const formattedJobStartTime = convertDateInTimeZone(moment(feederJob.timeStart), 'America/New_York').format('LT');
      return { id, value: formattedJobStartTime };
    });
    return (
      <div className="integration-analytics__datepicker">
        <Select
          selected={selectedFeederJobIndex}
          onSelect={(name, value) => {
            // the Number() is necessary due to an issue with the Select component treating zeroes as strings
            handleChange(name, Number(value));
          }}
          name="selectedFeederJobIndex"
          options={selectOpts}
        />
      </div>
    );
  };

  // ====== Liner tab components ======
  const renderLinerAppointmentsDataTable = (data) => {
    const appointmentCount = data.appointmentCount || 0;
    const appointmentErrors = data.appointmentErrors || 0;
    return (
      <div className="integration-analytics__table__appointments-table-parent-div">
        <span className="integration-analytics__table__table-name">Appointments</span>
        <Table className="integration-analytics__table">
          <thead>
            <tr>
              <th>Details</th>
              <th>Total</th>
              <th>Processed</th>
              <th>Error</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="integration-analytics__table__details-column">Count</td>
              <td className="integration-analytics__table__data-column">{appointmentCount + appointmentErrors}</td>
              <td className="integration-analytics__table__data-column">{appointmentCount}</td>
              <td className="integration-analytics__table__data-column">{appointmentErrors}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  const renderLinerContactsDataTable = (data) => {
    const patientCount = data.patientCount || 0;
    const patientErrorCount = data.patientErrorCount || 0;
    const otherCount = data.otherCount || 0;
    const otherErrorCount = data.othererrorCount || 0;
    return (
      <div>
        <span><strong>Contacts</strong></span>
        <Table className="integration-analytics__table">
          <thead>
            <tr>
              <th>Details</th>
              <th>Total</th>
              <th>Processed</th>
              <th>Error</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="integration-analytics__table__details-column">Patient</td>
              <td className="integration-analytics__table__data-column">{patientCount + patientErrorCount}</td>
              <td className="integration-analytics__table__data-column">{patientCount}</td>
              <td className="integration-analytics__table__data-column">{patientErrorCount}</td>
            </tr>
            <tr>
              <td className="integration-analytics__table__details-column">Other</td>
              <td className="integration-analytics__table__data-column">{otherCount + otherErrorCount}</td>
              <td className="integration-analytics__table__data-column">{otherCount}</td>
              <td className="integration-analytics__table__data-column">{otherErrorCount}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  // ======= API tab components ========
  const renderApiAppointmentsDataTable = (data) => {
    const emptyStateArr = [{
      officeId: 0,
      totalAppointmentCount: 0,
      unconfirmedAppointmentCount: 0,
      confirmedAppointmentCount: 0,
      cancelledAppointmentCount: 0,
    }];
    const appointmentCounts = data.appointmentCounts.length ? data.appointmentCounts : emptyStateArr;
    return (
      <div className="integration-analytics__table__appointments-table-parent-div">
        <span className="integration-analytics__table__table-name">Appointments</span>
        <Table className="integration-analytics__table">
          <thead>
            <tr>
              <th>Details</th>
              <th>Total</th>
              <th>Unconfirmed</th>
              <th>Confirmed</th>
              <th>Cancelled</th>
            </tr>
          </thead>
          <tbody>
            {appointmentCounts.map((apptData, index) => {
              const { officeId, totalAppointmentCount, unconfirmedAppointmentCount, confirmedAppointmentCount, cancelledAppointmentCount } = apptData;
              // const officeName = officeId ? offices[officeId].name : '(No office available)';
              let officeName;
              if (apptData.officeId && offices[officeId]) officeName = offices[officeId].name;
              else officeName = '(No office available)';
              const key = `api-appt-data-${index}`;
              return (
                <tr key={key}>
                  <td className="integration-analytics__table__details-column">{officeName}</td>
                  <td className="integration-analytics__table__data-column">{totalAppointmentCount}</td>
                  <td className="integration-analytics__table__data-column">{unconfirmedAppointmentCount}</td>
                  <td className="integration-analytics__table__data-column">{confirmedAppointmentCount}</td>
                  <td className="integration-analytics__table__data-column">{cancelledAppointmentCount}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderExpandedPatientIssues = () => {
    const { patientNullFirstAndLastCount, patientNullBirthdayCount, patientMatchingFirstAndLastCount, patientMatchingFirstLastAndBirthdayCount } = apiData;
    return (
      <tr>
        <td colSpan={5} className="integration-analytics__table__data-cell">
          <div className="u-bg-gray-lightest integration-analytics__api-contact-detail-box">
            <div className="u-text-muted integration-analytics__api-contact-detail-content__header">DATA ISSUES</div>
            <div className="integration-analytics__api-contact-detail-box-content">
              <div className="integration-analytics__api-contact-detail-content__item">NULL First and Last Name: {patientNullFirstAndLastCount || 0}</div>
              <div className="integration-analytics__api-contact-detail-content__item">NULL Birthday: {patientNullBirthdayCount || 0}</div>
              <div className="integration-analytics__api-contact-detail-content__item">Pairs with Matching First/Last Name: {patientMatchingFirstAndLastCount || 0}</div>
              <div className="integration-analytics__api-contact-detail-content__item">
                Pairs with Matching First/Last Name and Birthday: {patientMatchingFirstLastAndBirthdayCount || 0}
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const renderExpandedOtherIssues = () => {
    const { otherNullFirstAndLastCount, otherNullBirthdayCount, otherMatchingFirstAndLastCount, otherMatchingFirstLastAndBirthdayCount } = apiData;
    return (
      <tr>
        <td colSpan={5} className="integration-analytics__table__data-cell">
          <div className="u-bg-gray-lightest integration-analytics__api-contact-detail-box">
            <div className="u-text-muted integration-analytics__api-contact-detail-content__header">DATA ISSUES</div>
            <div className="integration-analytics__api-contact-detail-box-content">
              <div className="integration-analytics__api-contact-detail-content__item">NULL First and Last Name: {otherNullFirstAndLastCount || 0}</div>
              <div className="integration-analytics__api-contact-detail-content__item">NULL Birthday: {otherNullBirthdayCount || 0}</div>
              <div className="integration-analytics__api-contact-detail-content__item">Pairs with Matching First/Last Name: {otherMatchingFirstAndLastCount || 0}</div>
              <div className="integration-analytics__api-contact-detail-content__item">
                Pairs with Matching First/Last Name and Birthday: {otherMatchingFirstLastAndBirthdayCount || 0}
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const renderApiContactsDataTables = (data) => {
    const {
      patientNullBirthdayCount,
      patientNullFirstAndLastCount,
      patientMatchingFirstAndLastCount,
      patientMatchingFirstLastAndBirthdayCount,
      otherNullFirstAndLastCount,
      otherNullBirthdayCount,
      otherMatchingFirstAndLastCount,
      otherMatchingFirstLastAndBirthdayCount,
      totalPatientCount,
      integratedPatientCount,
      nonIntegratedOtherCount,
      totalOtherCount,
      nonIntegratedPatientCount,
      integratedOtherCount,
    } = data;

    const patientIssueCount = patientNullFirstAndLastCount
    + patientNullBirthdayCount
    + patientMatchingFirstAndLastCount
    + patientMatchingFirstLastAndBirthdayCount;

    const otherIssueCount = otherNullFirstAndLastCount
    + otherNullBirthdayCount
    + otherMatchingFirstAndLastCount
    + otherMatchingFirstLastAndBirthdayCount;

    return (
      <div>
        <span className="integration-analytics__table__table-name">Contacts</span>
        <Table className="integration-analytics__table">
          <thead>
            <tr>
              <th>Details</th>
              <th>Total</th>
              <th>Integrated</th>
              <th>Non-integrated</th>
              <th>Issues</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="integration-analytics__table__details-column">Patient</td>
              <td className="integration-analytics__table__data-column">{totalPatientCount || 0}</td>
              <td className="integration-analytics__table__data-column">{integratedPatientCount || 0}</td>
              <td className="integration-analytics__table__data-column">{nonIntegratedPatientCount || 0}</td>
              <td className="integration-analytics__table__data-column">
                <div onClick={() => handleChange('showExpandedPatientIssues', !showExpandedPatientIssues)} className="u-link">
                  {patientIssueCount || 0}
                </div>
              </td>
            </tr>
            {showExpandedPatientIssues && renderExpandedPatientIssues()}
            <tr>
              <td className="integration-analytics__table__details-column">Other</td>
              <td className="integration-analytics__table__data-column">{totalOtherCount || 0}</td>
              <td className="integration-analytics__table__data-column">{integratedOtherCount || 0}</td>
              <td className="integration-analytics__table__data-column">{nonIntegratedOtherCount || 0}</td>
              <td className="integration-analytics__table__data-column">
                <div onClick={() => handleChange('showExpandedOtherIssues', !showExpandedOtherIssues)} className="u-link">
                  {otherIssueCount || 0}
                </div>
              </td>
            </tr>
            {showExpandedOtherIssues && renderExpandedOtherIssues()}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderApiTimestamp = () => {
    const formattedTimestamp = convertDateInTimeZone(selectedDateApi, 'America/New_York').format('LLL');
    return (
      <div className="integration-analytics__timestamp">
        <span className="u-text-muted">PROCESS TIMESTAMP</span>
        <div><strong>Started: </strong>{`${formattedTimestamp}, EST`}</div>
      </div>
    );
  };

  const renderAnalytics = () => (
    <div className="box">
      <div className="analytics__title">
        Dashboard - {renderDashboardTitle(integrationPartnerTypeId)}
      </div>
      <div className="analytics__date-range convo__tabs">
        <NavTabs
          activeKey={activeTab}
          onSelect={updateActiveTab}
        >
          <NavTabsItem id={1}>Feeder</NavTabsItem>
          <NavTabsItem id={2}>Liner</NavTabsItem>
          <NavTabsItem id={3}>API</NavTabsItem>
        </NavTabs>
      </div>
      <div>
        <div>
          {/* Feeder Tab */}
          {activeTab === 1 && (
          <div>
            <div className="integration-analytics__date-time-picker">
              {renderDatePicker('Feeder', selectedDateFeeder)}
              {feederData && feederData.length > 0 && renderFeederJobSelectForm(feederData)}
            </div>
            {!hasDataFeeder && renderEmptyStateMessage()}
            {hasDataFeeder && renderFeederTimestamp(selectedFeederJobIndex)}
            {hasDataFeeder && renderFeederAppointmentsDataTable(feederData)}
            {hasDataFeeder && renderFeederContactsDataTable(feederData)}
            {hasDataFeeder && renderFeederCSVContactsDataTable(feederData)}
          </div>
          )}
          {/* Liner Tab */}
          {activeTab === 2 && (
          <div>
            <div className="integration-analytics__date-time-picker">
              {renderDatePicker('Liner', selectedDateLiner)}
            </div>
            {!hasDataLiner && renderEmptyStateMessage()}
            {hasDataLiner && renderLinerAppointmentsDataTable(linerData)}
            {hasDataLiner && renderLinerContactsDataTable(linerData)}
          </div>
          )}
          {/* API Tab */}
          {activeTab === 3 && (
          <div>
            <div className="integration-analytics__date-time-picker">
              {renderDatePicker('Api', selectedDateApi, defaultDateApi)}
            </div>
            {!hasDataApi && renderEmptyStateMessage()}
            {hasDataApi && showApiTimestamp && renderApiTimestamp()}
            {hasDataApi && renderApiAppointmentsDataTable(apiData)}
            {hasDataApi && renderApiContactsDataTables(apiData)}
          </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="app-page__container analytics">
      <div className={appPanelClasses}>
        <div className="app-page__container__inner">
          <div className="analytics__container">
            <div className="app-page__header">
              <div className="app-page__header__title analytics__header">Integration Analytics</div>
            </div>
            {isTabLoading ? <PageLoader /> : renderAnalytics()}
          </div>
        </div>
      </div>
    </div>
  );
};

IntegrationAnalytics.propTypes = {
  offices: PropTypes.object,
  activeTab: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  updateActiveTab: PropTypes.func.isRequired,
  selectedDateApi: PropTypes.object,
  selectedDateLiner: PropTypes.object,
  selectedDateFeeder: PropTypes.object,
  integrationPartnerTypeId: PropTypes.number,
  apiData: PropTypes.object,
  feederData: PropTypes.array,
  selectedFeederJobIndex: PropTypes.number,
  linerData: PropTypes.object,
  showExpandedOtherIssues: PropTypes.bool,
  showExpandedPatientIssues: PropTypes.bool,
  showApiTimestamp: PropTypes.bool,
  defaultDateApi: PropTypes.object,
  hasDataApi: PropTypes.bool,
  hasDataFeeder: PropTypes.bool,
  hasDataLiner: PropTypes.bool,
  isTabLoading: PropTypes.bool,
};

export default IntegrationAnalytics;
