export const AUDIT_LOGS_OPERATIONS = {
  ADD: 'Add',
  DELETE: 'Delete',
  EDIT: 'Edit',
};

export const AUDIT_LOG_HEADERS_CONFIG = {
  date: {
    default: true,
    direction: -1,
  },
  user: {
    default: false,
    direction: 1,
  },
  contactName: {
    default: false,
    direction: 1,
  },
  category: {
    default: false,
    direction: 1,
  },
  action: {
    default: false,
    direction: 1,
  },
};

export const AUDIT_LOGS_TYPES = {
  PERMISSIONS: 'permissions',
  ROLES: 'roles',
  CONNECTED_PARTIES: 'connectedParties',
  USERS: 'users',
  TAGS: 'tags',
  GROUPS: 'groups',
  EMAILS: 'emails',
  PHONES: 'phones',
  CHANNELS: 'channels',
  ATTACHMENTS: 'attachments',
  TYPE_ID: 'typeId',
  INTEGRATION_TYPE_ID: 'integrationPartnerTypeId',
  SUFFIX_ID: 'suffixId',
  PREFIX_ID: 'prefixId',
  TIME_ZONE_ID: 'timeZoneId',
  STATE: 'state',
  CLEAN_STATE: 'cleanState',
  FORWARDING: 'forwarding',
  COPY_CONTENT: 'copyContent',
  OFFICES: 'offices',
  ACTIVE_AT: 'startBillingDate',
  BIRTHDAY: 'birthday',
  FROM: 'from',
  TO: 'to',
  PROFILE_IMAGE_URL: 'profileImageUrl',
  ORGANIZATION_LOGO_URL: 'organizationLogoUrl',
  ROUTE: 'route',
  APPOINTMENT_TYPES: 'appointmentTypes',
  TOGROUP: 'toGroup',
  TOMEMBER: 'toMember',
};

export const AUDIT_LOGS_CATEGORIES = {
  ROLE: 'Roles',
  CHANNEL: 'Channel',
  GROUP: 'Group',
  TAG: 'Tag',
  ORGANIZATION: 'Organization',
  OFFICE_LOCATION: 'Office Location',
  CONVERSATION: 'Conversation',
  CONTACT: 'Contact',
  MEMBER: 'Member',
  TEMPLATE: 'Template',
  OUT_OF_OFFICE: 'Out of Office',
  ORG_PROFILE: 'Org Profile',
  ORG_PREFERENCES: 'Org Preferences',
  CONVERSATION_ACTIONS: 'Conversation Actions',
  TEMPLATE_ACTION: 'Template Action',
  APPOINTMENT_MANAGER: 'Appt Manager',
  RHINOPAY_MANAGER: 'RhinoPay Manager',
  RHINOBLAST: 'RhinoBlast',
  LANGUAGE_TRANSLATION: 'Language Translation',
  RHINO_VIDEO: 'RhinoVideo',
  RHINO_CALL: 'RhinoCall',
  RHINOFORM: 'RhinoForms',
  APPOINTMENT_REMINDER: 'Appt Reminders',
  ROUTING_MANAGER: 'Routing Manager',
  PRESCRIPTION_NOTIFICATION: 'Prescription Notification',
};

export const AUDIT_LOG_MAPPED_KEYS = ['typeId', 'integrationPartnerTypeId', 'prefixId', 'suffixId', 'timeZoneId', 'state', 'cleanState', 'startBillingDate', 'birthday',
  'from', 'to', 'profileImageUrl', 'organizationLogoUrl'];

export const AUDIT_LOG_CATEGORIES_INCLUDED_KEYS = {
  Organization: ['businessEmail', 'contactEmail', 'contactName', 'activeAt', 'name', 'businessPhone', 'contactPhone', 'businessAddress',
    'zip', 'street1', 'street2', 'state', 'city', 'billing', 'billManagerCustId', 'contactFirstName', 'contactLastName', 'contactPhone',
    'couponCode', 'salesRef', 'planName', 'parentCompany', 'startBillingDate', 'installAmount'],
  Member: ['firstName', 'middleName', 'lastName', 'preferredName', 'prefixId', 'suffixId', 'businessTitle', 'loginEmail', 'roles', 'tags', 'groups',
    'profileImageUrl'],
  Group: ['name', 'purpose', 'tags', 'users', 'businessHours', 'timeZoneId', 'observesDst', 'afterHours', 'autoResponse'],
  Contact: ['externalIds', 'emrId', 'typeId', 'firstName', 'middleName', 'lastName', 'preferredName', 'prefixId', 'suffixId', 'birthday', 'sex', 'phones',
    'emails', 'note', 'noteIsImportant', 'forwarding', 'hipaaStatus', 'rhinopayConsentStatus', 'marketingConsentStatus', 'tags', 'connectedParties', 'profileImageUrl'],
  Channel: ['typeId', 'details', 'phone', 'forwardingPhone', 'value', 'name', 'purpose', 'timeZoneId', 'observesDst', 'tags', 'route', 'name', 'type'],
  'Office Location': ['name', 'vCard', 'vCardName', 'vCardEmail', 'vCardAddress', 'address', 'cleanStreet1', 'cleanZip', 'cleanStreet2', 'cleanCity', 'cleanState', 'isRhinocallEnabled'],
  'Out of Office': ['title', 'message', 'from', 'to', 'channels'],
  Template: ['subject', 'message', 'attachments', 'typeId'],
  Tag: ['name', 'typeId'],
  Roles: ['name', 'users', 'description', 'permissions'],
  'Routing Manager': ['toMember', 'routingBehavior', 'textClassificationTypes', 'toGroup'],
  'Prescription Notification': ['default', 'template', 'payors'],
  RhinoForms: ['autoSendToEHR'],
  'Appt Reminders':
    ['automatedMessages', 'offices', 'appointmentReminder1Template', 'appointmentScheduledEnabled', 'appointmentScheduledTemplate', 'appointmentReminder1Enabled',
      'appointmentReminder1DeliveryHours', 'channel', 'name', 'appointmentTypes', 'appointmentReminder2Enabled', 'appointmentReminder2DeliveryHours', 'appointmentReminder2Template',
      'appointmentReminder3Enabled', 'appointmentReminder3DeliveryHours', 'appointmentReminder3Template', 'appointmentReminder4Enabled', 'appointmentReminder4DeliveryHours', 'appointmentReminder4Template'],
};

export const AUDIT_LOG_RELEVANT_FIELD_NAME = {
  observesDst: 'Daylight saving time observed',
  afterHours: 'Availability Hours',
  autoResponse: 'Auto Response',
  cleanStreet1: 'Address 1',
  street1: 'Address 1',
  street2: 'Address 2',
  cleanZip: 'Zip',
  cleanStreet2: 'Address 2',
  cleanCity: 'City',
  cleanState: 'State',
  tag: 'Tag Category',
  businessEmail: 'Business Email',
  contactEmail: 'Contact Email',
  businessAddress: 'Business Address',
  businessTitle: 'Job Title',
  contactName: 'Contact Name',
  startBillingDate: 'Activation Date',
  businessPhone: 'Business Phone',
  contactPhone: 'Contact Phone',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  preferredName: 'Preferred Name',
  loginEmail: 'Email Address',
  noteIsImportant: 'Note Is Important',
  hipaaStatus: 'Hipaa Status',
  rhinopayConsentStatus: 'Rhinopay Consent Status',
  marketingConsentStatus: 'Markenting Consent Status',
  allowReroute: 'Allow Forwarding',
  buttonBackground: 'Button Background',
  buttonText: 'Button Text',
  titleBackground: 'Title Background',
  titleText: 'Title Text',
  formTitle: 'Form Title',
  titleSubtext: 'Title Subtext',
  phonePlaceholder: 'Phone Placeholder',
  phoneHelpText: 'Phone Help Text',
  messagePlaceholder: 'Message Placeholder',
  submitButton: 'Submit Button',
  calltoActionButton: 'Call to Action Button',
  confirmationText: 'Confirmation Text',
  isWebWidgetEnabled: 'Add Web Form',
  lastUpdated: 'Last Updated',
  allowClosingByAssignee: 'Allow Closing by Assignee',
  allowAutoConversationAssignment: 'Allow Conversation Assignment',
  areChannelFiltersEnabled: 'Patient Conversation Thread Filtering',
  allowMessageTemplateEditingByAll: 'Message Templates',
  sessionTimeoutMinutes: 'System timeout period (minutes)',
  soundOn: 'Global Sounds',
  appointmentScheduledEnabled: 'Appointment Scheduled',
  appointmentScheduledTemplate: 'Appointment Scheduled Template',
  appointmentReminder1Enabled: 'Appointment Reminders',
  appointmentReminder1DeliveryHours: 'Appointment Reminders Delivery Hours',
  appointmentReminder1Template: 'Appointment Reminders Template',
  appointmentReminder2Enabled: 'Appointment Reminders',
  appointmentReminder2DeliveryHours: 'Appointment Reminders Delivery Hours',
  appointmentReminder2Template: 'Appointment Reminders 2 Template',
  appointmentReminder3Enabled: 'Appointment Reminders',
  appointmentReminder3DeliveryHours: 'Appointment Reminders Delivery Hours',
  appointmentReminder3Template: 'Appointment Reminders 3 Template',
  appointmentReminder4Enabled: 'Appointment Reminders',
  appointmentReminder4DeliveryHours: 'Appointment Reminders Delivery Hours',
  appointmentReminder4Template: 'Appointment Reminders 4 Template',
  lastActiveAt: 'Last Active At',
  integrationPartner: 'Integration Partner',
  billManagerCustId: 'Bill Manager Customer Id',
  contactFirstName: 'Contact First Name',
  contactLastName: 'Contact Last Name',
  couponCode: 'Coupon Code',
  salesRef: 'Sales Rep',
  profileImageUrl: 'Profile Pic',
  organizationLogoUrl: 'Logo Pic',
  businessPhone__added: 'Business Phone',
  contactPhone__added: 'Contact Phone',
  connectedParties: 'Connected Parties',
  description: 'Role Purpose',
  template: 'Template Type',
  isBulkMessagingEnabled: 'Rhinoblast',
  integration: 'Appointment Reminder',
  isAppointmentManagerEnabled: 'Appointment Manager',
  isMessageTranslationEnabled: 'Language Translation',
  isMessageTranslationFeatureEnabled: 'Language Translation',
  isEnabled: 'RhinoPay',
  isACHEnabled: 'RhinoPay ACH',
  emrId: 'Contact ID',
  videoDateAndDuration: 'Date and Time',
  isAutomatedMessageToConnectedPartiesEnabled: 'Enable Appointment Messages to Connected Parties',
  canSendAppointmentToConnectedParties: 'Appointment Reminders to Connected Parties',
  isRhinocallEnabled: 'Enable Outbound RhinoCalls',
  autoSendToEHR: 'Send to EHR/PMS',
  isRoutingManagerEnabled: 'Routing Manager',
  routingBehavior: 'Routing Method',
  textClassificationTypes: 'Categories',
  VCardName: 'Display Name',
  VCardEmail: 'Email',
  default: 'Default Message',
};

export const AUDIT_LOG_INCLUDED_KEYS_FOR_EDIT = {
  'Org Preferences': ['allowClosingByAssignee', 'allowAutoConversationAssignment', 'areChannelFiltersEnabled', 'sessionTimeoutMinutes', 'soundNotification', 'soundOn', 'defaultChannel', 'name',
    'isMessageTranslationEnabled', 'canSendAppointmentToConnectedParties'],
  Member: ['firstName', 'middleName', 'lastName', 'preferredName', 'prefixId', 'suffixId', 'businessTitle', 'loginEmail', 'roles', 'tags', 'groups',
    'profileImageUrl', 'timeZoneId', 'businessHours', 'afterHours', 'autoResponse', 'observesDst'],
  Group: ['name', 'purpose', 'tags', 'users', 'businessHours', 'timeZoneId', 'observesDst', 'afterHours', 'autoResponse', 'typeId'],
  Contact: ['externalIds', 'emrId', 'typeId', 'firstName', 'middleName', 'lastName', 'preferredName', 'prefixId', 'suffixId', 'birthday', 'sex',
    'phones', 'emails', 'note', 'noteIsImportant', 'forwarding', 'hipaaStatus', 'rhinopayConsentStatus', 'marketingConsentStatus', 'tags',
    'connectedParties', 'profileImageUrl', 'user', 'copyContent', 'copyContent__added'],
  Channel: ['typeId', 'details', 'phone', 'forwardingPhone', 'value', 'name', 'purpose', 'timeZoneId', 'observesDst', 'tags', 'route', 'name', 'type',
    'businessHours', 'autoResponse', 'allowReroute', 'widgetConfig', 'color', 'buttonText', 'buttonBackground', 'titleBackground',
    'titleText', 'phoneHelpText', 'submitButton', 'titleSubtext', 'phonePlaceholder', 'confirmationText', 'formTitle', 'calltoActionButton', 'messagePlaceholder',
    'isWebWidgetEnabled', 'embedForm', 'text', 'position', 'afterHours', 'isRhinocallEnabled'],
  'Office Location': ['name', 'vCard', 'vCardName', 'vCardEmail', 'vCardAddress', 'address', 'cleanStreet1', 'cleanZip', 'cleanStreet2', 'cleanCity', 'cleanState'],
  'Out of Office': ['title', 'message', 'from', 'to', 'channels'],
  Template: ['subject', 'message', 'attachments', 'typeId'],
  Tag: ['name', 'typeId'],
  'Appt Reminders':
    ['automatedMessages', 'offices', 'appointmentReminder1Template', 'appointmentScheduledEnabled', 'appointmentScheduledTemplate', 'appointmentReminder1Enabled',
      'appointmentReminder1DeliveryHours', 'channel', 'name', 'appointmentTypes', 'appointmentReminder2Enabled', 'appointmentReminder2DeliveryHours', 'appointmentReminder2Template',
      'appointmentReminder3Enabled', 'appointmentReminder3DeliveryHours', 'appointmentReminder3Template', 'appointmentReminder4Enabled', 'appointmentReminder4DeliveryHours', 'appointmentReminder4Template'],
  Billing: ['zip', 'city', 'contactEmail', 'contactFirstName', 'street1', 'contactLastName', 'street2', 'state', 'contactPhone', 'billManagerPaymentId'],
  'Org Profile': ['businessEmail', 'contactEmail', 'contactName', 'activeAt', 'name', 'businessPhone', 'contactPhone', 'businessAddress',
    'zip', 'street1', 'street2', 'state', 'city', 'billing', 'billManagerCustId', 'contactFirstName', 'contactLastName', 'contactPhone',
    'businessPhone__added', 'contactPhone__added', 'integration', 'isBulkMessagingEnabled', 'isAppointmentManagerEnabled', 'isRoutingManagerEnabled',
    'integrationPartnerTypeId', 'organizationLogoUrl', 'isMessageTranslationFeatureEnabled', 'isEnabled', 'isACHEnabled', 'isAutomatedMessageToConnectedPartiesEnabled'],
  Roles: ['name', 'users', 'description', 'permissions'],
  RhinoForms: ['autoSendToEHR'],
  'Routing Manager': ['toMember', 'routingBehavior', 'textClassificationTypes', 'toGroup'],
  'Prescription Notification': ['default', 'template', 'payors'],
};

export const DEFAULT_DATE_FILTER = 3;

export const AUDIT_LOG_UPDATED_FIELD = {
  Business_Hours: 'businessHours',
  Permissions: 'permissions',
  offices: 'offices',
};

// KEYS_TREATED_AS_NUMBERS is an array of keys in which 1 and 0 is not treated as boolean
export const KEYS_TREATED_AS_NUMBERS = ['Appointment Reminders Delivery Hours'];
