import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const SecureNavigation = (props) => {
  const {
    changeOrg,
    channels,
    organizationIds,
    organizations,
  } = props;

  const renderChannel = (orgId, userId) => (key, idx) => {
    const channel = channels[key];

    return (
      <li key={idx} className="app-navigation__nav__sub">
        <Link // eslint-disable-line jsx-a11y/anchor-is-valid
          onClick={() => changeOrg(orgId, userId, key)}
          id={`nav-secure-org-${orgId}-channel-${channel.id}`}
          className="app-navigation__nav__button"
        >
          <span className="app-navigation__nav__button__text" data-cypress="rhinosecureChannel">{channel.name}</span>
          {channel.unread > 0 && <span className="app-navigation__nav__button__badge">{channel.unread}</span>}
        </Link>
      </li>
    );
  };

  const renderOrganization = (key, idx) => {
    const organization = organizations[key];

    return (
      <ul className="app-navigation__nav" key={idx}>
        <li className="app-navigation__nav__header">
          <span className="app-navigation__nav__button__text">{organization.name}</span>
        </li>
        {organization.channels && organization.channels.map(renderChannel(organization.id, organization.userId))}
        <li key={idx} className="app-navigation__nav__sub">
          <Link // eslint-disable-line jsx-a11y/anchor-is-valid
            onClick={() => changeOrg(organization.id, organization.userId)}
            className="app-navigation__nav__button"
            id={`nav-secure-org-${organization.id}-channel-all`}
          >
            <span className="app-navigation__nav__button__text" data-cypress="allMessages">All Messages</span>
          </Link>
        </li>
      </ul>
    );
  };

  return (
    <nav id="app-navigation" className="app-navigation app-navigation--secure">
      <div className="app-navigation__container">
        <div className="app-navigation__top">
          <div className="app-navigation__container__scroll">
            {organizationIds.map(renderOrganization)}
          </div>
        </div>
      </div>
    </nav>
  );
};

SecureNavigation.propTypes = {
  changeOrg: PropTypes.func.isRequired,
  channels: PropTypes.object.isRequired,
  organizationIds: PropTypes.array.isRequired,
  organizations: PropTypes.object.isRequired,
};

export default SecureNavigation;
