import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Radio,
  RadioGroup,
} from 'rhinostyle';

import { Types } from '../constants';
import { UserHelpers } from '../helpers';
import { useUpdateUserAppointmentsMutation } from '../services/appointmentService';
import { useInboxParams } from '../hooks';

const AppointmentStatusRadioGroup = ({ appointment, currentStatus }) => {
  const { userId } = useInboxParams();
  const [appointmentStatus, setAppointmentStatus] = useState(appointment.appointmentStatusTypeId || Types.TYPE_APPOINTMENT_UNCONFIRMED);
  const [updateUserAppointment, query] = useUpdateUserAppointmentsMutation();

  useEffect(() => {
    setAppointmentStatus(appointment.appointmentStatusTypeId);
  }, [appointment.appointmentStatusTypeId]);

  const handleChange = (newValue) => {
    setAppointmentStatus(newValue);
  };

  function handleUpdateAppointmentStatus() {
    const data = {
      id: appointment.id,
      appointmentStatusTypeId: appointmentStatus,
      appointmentStatusUpdatedByTypeId: Types.TYPE_APPOINTMENT_STATUS_UPDATED_BY_MEMBER,
      externalId: appointment.externalId,
    };
    updateUserAppointment({ userId, data });
  }

  const renderConfirmationButton = () => (
    <Button
      className="hipaa-status__confirm"
      size="small"
      type="primary"
      onClick={handleUpdateAppointmentStatus}
      loading={query.isLoading}
    >
      Confirm Status Change
    </Button>
  );

  const renderStatusValues = () => {
    const statusValues = [Types.TYPE_APPOINTMENT_CONFIRMED, Types.TYPE_APPOINTMENT_CANCELLED];
    if (currentStatus === Types.TYPE_APPOINTMENT_UNCONFIRMED) statusValues.unshift(Types.TYPE_APPOINTMENT_UNCONFIRMED);

    return (
      statusValues.map((appointmentStatusTypeId) => (
        <Radio
          key={appointmentStatusTypeId}
          value={appointmentStatusTypeId}
          label={UserHelpers.formatAppointmentStatus(appointmentStatusTypeId)}
        />
      ))
    );
  };

  return (
    <div className="summary-panel__section__content" data-cypress="appointmentStatusRadioGroup">
      <div className="hipaa-status__options">
        <RadioGroup
          inline
          name={`appointmentStatus__${appointment.id}`}
          onChange={(newValue) => handleChange(newValue)}
          selectedValue={appointmentStatus}
        >
          {renderStatusValues()}
        </RadioGroup>
        {currentStatus !== appointmentStatus
              && renderConfirmationButton()}
      </div>
    </div>
  );
};

AppointmentStatusRadioGroup.propTypes = {
  appointment: PropTypes.object.isRequired,
  currentStatus: PropTypes.number.isRequired,
};

export default AppointmentStatusRadioGroup;
