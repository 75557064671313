import React from 'react';
import MimeTypes from 'mime-types';
import isEqual from 'lodash.isequal';

export function getObjectKeys(object) {
  return object ? Object.keys(object).map(Number) : [];
}

export function hasData(object) {
  return object && Object.keys(object).length > 0;
}

export function doesObjContainValues(obj) {
  return Object.keys(obj)
    .some((key) => obj[key] > 0 || obj[key]?.length > 0);
}

export function convertObjToArr(obj) {
  const newArray = [];

  Object.keys(obj).map((key) => newArray.push(obj[key]));

  return newArray;
}

export function cloneDeep(obj) {
  // Handle non-object types and null
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Create a new object or array to hold the cloned properties
  const clone = Array.isArray(obj) ? [] : {};

  // Iterate over each property in the object
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // Check if the property is a direct property of the object (not inherited)
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // Recursively clone nested objects and arrays
      clone[key] = cloneDeep(obj[key]);
    }
  }

  return clone;
}

export function parseIfJson(value) {
  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
}

/**
 * Returns a copy of an object with specified keys removed.
 * Warning! This method does not perform a deep copy of obj.
 *
 * @param {object} obj Object to filter
 * @param {string[]} filterKeys An array of keys to remove from obj
 * @returns {object} A Copy of obj with filterKeys removed
 */
export function filterObjByKeys(obj, filterKeys) {
  return Object.keys(obj)
    .filter((key) => !filterKeys.includes(key))
    .reduce((acc, key) => ({
      ...acc,
      [key]: obj[key],
    }), {});
}

/**
 * Returns a copy of an array with duplicates removed
 *
 * @param {arr} array array objects to filter
 * @param {string} key key in object to filter
 */
export function filterArrayDuplicatesByKey(arr, key = 'id') {
  return arr.reduce((unique, o) => {
    if (!unique.some((obj) => obj[key] === o[key])) {
      unique.push(o);
    }
    return unique;
  }, []);
}

export function updateObjectWithoutMutating(obj, compareValue, updateKeyName) {
  const object = {};
  Object.keys(obj).forEach((key) => {
    object[key] = Object.assign({}, obj[key]); //eslint-disable-line
    if (compareValue === obj[key].id) {
      object[key][updateKeyName] = !object[key][updateKeyName];
    }
  });

  return object;
}

export function getObjKey(obj, index) {
  // returns the specified index of an object
  return obj[Object.keys(obj)[index]];
}

export const concat = (x, y) => x.concat(y);

export function merge(a, e) {
  const acc = { ...a };

  Object.keys(e).forEach((key) => {
    if (Array.isArray(e[key])) {
      acc[key] = acc[key] || [];
      acc[key] = acc[key].concat(e[key]);
    } else if (typeof e[key] === 'object') {
      acc[key] = merge(acc[key] || {}, e[key]);
    } else {
      acc[key] = e[key];
    }
  });

  return acc;
}

/**
 * Copies the values of all enumerable own properties from two source objects to a new target object.
 * Source objects are applied from left to right.
 * Subsequent sources overwrite assignments of previous sources.
 * @param  {[type]} o source object
 * @param  {[type]} n source object 2
 * @return {[type]} A new object from two source objects
 */
export const mergeShallow = (source1, source2) =>
  Object.keys(source2).reduce((acc, currVal) => {
    acc[currVal] = { ...acc[currVal], ...source2[currVal] };
    return acc;
  }, { ...source1 });
export function removeProperty(property) {
  return (obj) => {
    const clone = { ...obj };
    delete clone[property];
    return clone;
  };
}

export function exists(item) {
  if (typeof item !== 'undefined' && item !== null && !Number.isNaN(item)) {
    return true;
  }
  return false;
}

export function getProp(o, k, defaultVal) {
  if (!exists(o)) return defaultVal;
  const keys = Array.isArray(k) ? k : k.split('.');
  const object = o[keys[0]];
  if (object && keys.length > 1) {
    return getProp(object, keys.slice(1), defaultVal);
  }
  return object === undefined ? defaultVal : object;
}

export function getFileMimeType(filename) {
  if (filename) {
    const extension = filename.split('.').pop().toLowerCase();

    if (/(quicktime)/.test(extension)) return 'video/quicktime';
    return MimeTypes.lookup(extension);
  }
  return null;
}

// Taken from https://www.npmjs.com/package/uuid
export function generateUUID(a) {
  return a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, generateUUID); // eslint-disable-line
}

export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb'];
  const power = parseInt(Math.floor(Math.log(bytes, 10) / Math.log(1024, 10), 10), 10);

  if (bytes === 0) return null;

  return `${Math.round(bytes / (1000 ** power), 2)} ${sizes[power]}`;
}

// Is this the right way to format an address?
// 123 Daniel Island Dr. Suite 200 Charleston, SC 29403
export function formatAddress(address) {
  if (!hasData(address)) {
    return null;
  }

  const formatStreet = (cleanStreet1, cleanStreet2) => (cleanStreet2 ? `${cleanStreet1} ${cleanStreet2}` : cleanStreet1);

  return (
    <>
      {formatStreet(address.cleanStreet1, address.cleanStreet2)} <br />
      {address.cleanCity}, {address.cleanState} {address.cleanZip}
    </>
  );
}

export function sortBy(object, key) {
  return (a, b) => {
    const valueA = object[a][key]?.toLowerCase();
    const valueB = object[b][key]?.toLowerCase();

    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;

    return 0;
  };
}

export function sortStringValues(valueA, valueB) {
  if (valueA.toLowerCase() < valueB.toLowerCase()) return -1;
  if (valueA.toLowerCase() > valueB.toLowerCase()) return 1;
  return 0;
}

/**
 * @description can be passed into array.sort() to alphabetically sort an array of objects by a specified key
 * @param {string} key object key to sort by
 * @return {number}
 */
export function alphabeticalByKey(key) {
  return (a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    const isNumber = (v) => (+v).toString() === v;
    const aPart = valueA.match(/\d+|\D+/g);
    const bPart = valueB.match(/\d+|\D+/g);

    let i = 0; const len = Math.min(aPart.length, bPart.length);

    while (i < len && aPart[i] === bPart[i]) { i += 1; }
    if (i === len) {
      return aPart.length - bPart.length;
    }
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    }
    return aPart[i].localeCompare(bPart[i]);
  };
}

/**
 * This is a comparator function, can be passed into array.sort function for sorting of array of objects
 * @param {object} object will be a user object with firstname and lastname
 * @return {number} 0, 1, or -1
 */
export function sortComparatorUsingUserLastNameFirstName(object) {
  return (a, b) => {
    const firstNameA = object[a]?.firstName || '';
    const firstNameB = object[b]?.firstName || '';
    const lastNameA = object[a]?.lastName || '';
    const lastNameB = object[b]?.lastName || '';
    const lastAndFirstNameA = `${lastNameA} ${firstNameA}`;
    const lastAndFirstNameB = `${lastNameB} ${firstNameB}`;

    return lastAndFirstNameA.localeCompare(lastAndFirstNameB, 'en', { sensitivity: 'base' });
  };
}

/**
 * @param {object[]} array array of Objects with some key like 'id'
 * @param {string} key is name of key in object, value of object[key] will be used as key in new Object
 * like if 'id' is key then value of array[0][id] will be used as key name in new object
 * @returns {object} an obj converted from array of objects with some key heading towards all objects separately
 */
export function arrayToObj(array, key) {
  const newObject = {};
  array.map((item) => {
    newObject[item[key]] = item;
    return item;
  });

  return newObject;
}

export const distinctObjectsFromArray = (array, key) => {
  const distinctValues = Array.from(new Set(array.map((item) => item[key])));
  return distinctValues.map((val) => array.find((item) => (item[key] === val)));
};

export function compareObjectByKey(a, b, key) {
  const valueA = a[key]?.toLowerCase() || '';
  const valueB = b[key]?.toLowerCase() || '';
  return (valueA).localeCompare(valueB);
}

export const getDefaultSortKey = (headers) => Object.keys(headers).find((key) => headers[key].default === true);

export const mergeArrays = (array1, array2) => {
  const ids = new Set(array2.map((e) => e.id));
  return [...new Set([...array1.filter((d) => !ids.has(d.id)), ...array2])];
};

/**
 * @param array, array of ids
 * @param id single id, will be added or removed to array based on whether it is already in it
 * @returns an array that either adds
 */
export const updateArrayById = (array, id) => {
  const updatedArray = cloneDeep(array);
  const idIndex = array.indexOf(id);
  if (idIndex < 0) {
    updatedArray.push(id);
  } else {
    updatedArray.splice(idIndex, 1);
  }
  return updatedArray;
};

export const areArraysEqualWhenSorted = (array1, array2) => isEqual([...array1].sort(), [...array2].sort());

export function isObject(value) {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}
