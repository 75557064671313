/**
   * Transform `{}` into styled component
   * @param  {string} text
   * @return {node}
   */
function transformVariables(text) {
  let value = text;
  // Replace text contents of variable to hide squigglies
  const regexCurlyBrackets = /{(.*)?}/g;
  value = value.replace(regexCurlyBrackets, '$1');

  // Replace text contents of variable to hide underscore
  const regexUnderscores = /_/g;
  value = value.replace(regexUnderscores, ' ');

  // Build variable UI
  const $variable = document.createElement('span');
  // Disable spell-checker
  $variable.setAttribute('spellcheck', false);
  // Do not allow the variable to be edited
  // $variable.setAttribute('contenteditable', false);
  $variable.classList.add('transform-variables');
  $variable.innerHTML = value;
  return $variable.outerHTML;
}

function getVariables(array) { return array.map((el) => el.variable.toLowerCase()); }

export function replaceVariablesByStyledComponent(variables, template) {
  // Split `initialValue` to target variables
  let formattedTemplate = '';
  // Inflate message string to strip out handlebars and add HTML
  template.split(/({.*?})/).forEach((el) => {
    const flatVariables = getVariables(variables);
    const variableIndex = flatVariables.indexOf(el.toLowerCase());
    if (variableIndex > -1) {
      formattedTemplate += transformVariables(variables[variableIndex].variable);
    } else {
      formattedTemplate += el;
    }
  });
  return formattedTemplate;
}

export function replaceVariablesWithSampleText(variables, template) {
  let formattedTemplate = '';
  template.split(/({.*?})/).forEach((el) => {
    const flatVariables = getVariables(variables);
    const variableIndex = flatVariables.indexOf(el.toLowerCase());
    if (variableIndex > -1) {
      formattedTemplate += variables[variableIndex].variableValue;
    } else {
      formattedTemplate += el;
    }
  });
  return formattedTemplate;
}

export function replaceNewLinesToLineBreaks(text) {
  const regexNewLines = /(\n)/g;
  return (text || '').replace(regexNewLines, '<br />');
}

export function handleEmojiSelection(emojiObject, quillObj) {
  const quill = quillObj.current || quillObj;
  const { native } = emojiObject;
  const variable = {
    value: native,
    variable: native,
    variableValue: native,
  };
  const range = quill.getSelection(true); // force focus;
  if (range?.length === 0) {
    const textLength = quill.getLength();
    quill.insertEmbed(range.index, 'variable', variable, 'user');
    if (range.index + 1 >= textLength) {
      quill.insertText(range.index + 1, ' ', 'user');
    }
    quill.setSelection(range.index + 1, 'user');
  }
}
