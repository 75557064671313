import PropTypes from 'prop-types';
import React from 'react';
import { SystemAlert } from 'rhinostyle';
import { connect, useDispatch } from 'react-redux';
import { systemAlertSelectors } from '../selectors';
import StorageService, { createCookie, readCookie } from '../services/StorageService';
import * as UIReducer from '../reducers/uiReducer';

const SystemAlertMessage = (props) => {
  const dispatch = useDispatch();
  const {
    currentUserId,
    showSystemAlert,
    systemAlert,
    types,
  } = props;

  const handleDismiss = () => {
    createCookie(`rhinogram-${currentUserId}-systemAlert`, systemAlert.id, 100);
    dispatch(UIReducer.setSystemAlert(false));
  };

  const isSystemAlertVisible = showSystemAlert
    && systemAlert
    && Number(readCookie(`rhinogram-${currentUserId}-systemAlert`)) !== systemAlert.id
    && systemAlert.active && !StorageService.readEntry('multiOrgs') && !StorageService.readEntry('patientExperience');

  const alertType = types[systemAlert?.alertTypeId]?.value;
  const alertTypeValue = alertType === 'urgent' ? 'danger' : alertType;

  return (
    <>
      {isSystemAlertVisible && (
        <SystemAlert type={alertTypeValue} onDismiss={handleDismiss} url={systemAlert.url} urlText={systemAlert.urlText}>{systemAlert.message}</SystemAlert>
      )}
    </>
  );
};

SystemAlertMessage.propTypes = {
  currentUserId: PropTypes.number,
  showSystemAlert: PropTypes.bool.isRequired,
  systemAlert: PropTypes.object,
  types: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { type, auth, ui } = state;

  return {
    systemAlert: systemAlertSelectors.getActiveSystemAlert(state),
    showSystemAlert: ui.showSystemAlert,
    types: type.types,
    currentUserId: auth.currentUser,
  };
};

export default connect(mapStateToProps)(SystemAlertMessage);
