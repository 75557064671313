import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import {
  Button,
  Icon,
  Resource,
  ResourceIntro,
  ResourceBottom,
} from 'rhinostyle';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment-timezone';

import UserBadges from './UserBadges';
import { UserHelpers, DateHelpers } from '../helpers';
import { AppConstants, Types } from '../constants';

const ConnectedPartyItem = (props) => {
  const {
    connection,
    handleConnectedPartyTransition,
    type,
    isNoteTruncated,
    isNoteAboveTruncateLength,
    isRhinopayEnabled,
    toggleNoteTruncate,
    activeUser,
    connectedPartyType,
  } = props;
  const user = {
    birthday: connection.userBirthday,
    hipaaStatus: connection.hipaaStatus,
    firstName: connection.userFirstName,
    lastName: connection.userLastName,
    suffixId: connection.userSuffixId,
    prefixId: connection.userPrefixId,
    preferredName: connection.userPreferredName,
    rhinopayConsentStatus: connection.rhinopayConsentStatus,
    marketingConsentStatus: connection.marketingConsentStatus,
    types: connection.userTypes,
    typeId: connection.userTypeId,
    id: connection.userId,
    profileImageUrl: connection.profileImageUrl && `${AppConstants.AVATAR_BASE_URL}${connection.profileImageUrl}`,
    integrated: connection.integrated,
    updatedByTypeId: connection.updatedByTypeId,
    updatedByTimestamp: connection.updatedByTimestamp,
  };

  const getNote = () => {
    const { note } = connection;

    let html = note.replace(/(\n|\r)+/g, '<br />');
    if (isNoteTruncated) {
      const viewMoreText = '...View More';
      html = `${html.substr(0, AppConstants.PROFILE_CONNECTED_PARTY_NOTE_TRUNCATE_LENGTH - viewMoreText.length)}... `;
    } else {
      html = `${html} `;
    }
    return html;
  };

  // Only show important note when marked important
  const connectedPartyNoteClasses = cx('connected-party__bottom-note', {
    'connected-party__bottom-note--important': connection.noteIsImportant,
  });
  const timeZone = moment.tz.guess();
  return (
    <Resource wrapperClassName="u-overflow-hidden" onClick={() => handleConnectedPartyTransition(user.id)}>
      <ResourceIntro
        avatar={{
          image: user.profileImageUrl,
          name: UserHelpers.formatAvatarName(user.firstName, user.lastName),
          type: user.types, // @benbruning type not being returned
        }}
        title={UserHelpers.formatName(user)}
        titleSub={type.value}
      >
        <UserBadges user={user} isRhinopayEnabled={isRhinopayEnabled} connectedPartyType={connectedPartyType} />
      </ResourceIntro>
      {activeUser && activeUser.integrated && user.integrated && user.updatedByTimestamp && user.updatedByTypeId === Types.TYPE_UPDATED_BY_MEMBER && (
        <ResourceBottom>
          <div className="u-text-small u-text-italic u-text-muted">
            {`Relationship updated in Rhinogram ${moment(DateHelpers.convertDateInTimeZone(user.updatedByTimestamp, timeZone)).format('MM-DD-YYYY [at] h:mma')}`}
          </div>
        </ResourceBottom>
      )}
      {connection.note && (
        <ResourceBottom className={connectedPartyNoteClasses}>
          <div className="connected-party__bottom-note__content">
            <Icon icon="note" bump="down" />
            {isNoteAboveTruncateLength ? (
              <div>{/* Need a wrapping div since it's a flex child */}
                {ReactHtmlParser(getNote())}
                <Button className="u-text-primary" reset onClick={toggleNoteTruncate}>View {isNoteTruncated ? 'More' : 'Less'}</Button>
              </div>
            ) : (
              <div>{ReactHtmlParser(getNote())}</div>
            )}
          </div>
        </ResourceBottom>
      )}
    </Resource>
  );
};

ConnectedPartyItem.propTypes = {
  handleConnectedPartyTransition: PropTypes.func,
  type: PropTypes.object,
  isNoteTruncated: PropTypes.bool,
  isNoteAboveTruncateLength: PropTypes.bool,
  toggleNoteTruncate: PropTypes.func.isRequired,
  isRhinopayEnabled: PropTypes.bool,
  activeUser: PropTypes.object,
  connection: PropTypes.object,
  connectedPartyType: PropTypes.object,
};

ConnectedPartyItem.defaultProps = {
  isRhinopayEnabled: false,
};

export default ConnectedPartyItem;
