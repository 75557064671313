import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import {
  Button,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Resource,
  ResourceGroup,
  ResourceIntro,
  ResourceRight,
  RhinoSwitch,
  Select,
} from 'rhinostyle';

import { ORGANIZATION_PREFERENCES_EDIT } from '../constants/UserPermissionsConstants';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { formatAddress } from '../helpers/DataHelpers';

const OrganizationPreferences = (props) => {
  const {
    allowAutoConversationAssignment,
    areChannelFiltersEnabled,
    allowClosingByAssignee,
    isCreatedWithinHoursOfStartDateEnabled,
    areOfficesVisible,
    canSendAppointmentToConnectedParties,
    channelIds,
    channels,
    currentOrganization,
    defaultChannelId,
    errors,
    formInProgress,
    handleChange,
    handleFormChanges,
    handleOfficeChannelSelect,
    handleSubmit,
    hasAppointmentCampaigns,
    isAutomatedMessageToConnectedPartiesEnabled,
    isMessageTranslationEnabled,
    isMessageTranslationFeatureEnabled,
    offices,
    pageContainerRef,
    selectedChannel,
    sessionTimeoutMinutes,
    soundOn,
    timeZone,
  } = props;

  const automatedMessagesEnabled = currentOrganization.supportsAppointmentReminders || currentOrganization.arePrescriptionCampaignsEnabled;
  const automatedMessageType = currentOrganization.arePrescriptionCampaignsEnabled ? 'prescription notification' : 'appointment reminder';
  const campaignType = currentOrganization.arePrescriptionCampaignsEnabled ? 'Prescription' : 'Appointment';

  const getFriendlyTimeout = (timeoutMinutes) => {
    if (!timeoutMinutes || timeoutMinutes < 60) return '';

    const minutes = timeoutMinutes % 60;
    const hours = (timeoutMinutes - minutes) / 60;

    const minutesLabel = minutes > 1 ? 'minutes' : 'minute';
    const hoursLabel = hours > 1 ? 'hours' : 'hour';

    return `${hours && hours >= 1 ? `${hours} ${hoursLabel}` : ''} ${minutes && minutes >= 1 ? `${minutes} ${minutesLabel}` : ''}`;
  };

  const renderTag = (tag, index) => tag && (
    <span key={index}>
      {!!index && ', '}
      #{tag.name}
    </span>
  );

  const renderChannel = (channel) => {
    const isSelected = defaultChannelId === channel.id;

    return (
      <Resource key={channel.id} selected={isSelected} onClick={() => handleChange('defaultChannelId', channel.id)}>
        <ResourceIntro
          icon={{ icon: channel.icon }}
          title={channel.name}
          titleSub={channel.phone}
        >
          {channel.tags.map(renderTag)}
        </ResourceIntro>
        <ResourceRight>{channel.route}</ResourceRight>
      </Resource>
    );
  };

  const channelList = () => {
    const channelsList = channelIds.map((c) => ({ id: channels[c].id, value: channels[c].name }));
    channelsList.unshift({ id: -1, value: ' --' });
    return channelsList;
  };

  const renderOffice = (office, idx) => (
    <div className="am__offices__row" key={idx}>
      <div className="am__offices__office">
        <div className="am__offices__office__name">{office.name}</div>
        <p className="am__offices__office__address">{formatAddress(office.address)}</p>
      </div>
      <div className="am__offices__channel">
        <Select
          onSelect={handleOfficeChannelSelect}
          options={channelList()}
          selected={offices[office.id]}
          name={`${office.id}`} // needs to be string for Rhinostyle Select
          className="automated-messages__table__channel-select"
        />
      </div>
    </div>
  );

  const defaultChannelClass = cx({
    am__default: areOfficesVisible,
  });

  return (
    <div className="app-page__container" ref={pageContainerRef}>
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title">Organization Preferences</div>
        </div>
        <form onChange={handleFormChanges}>
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">Secure Notifications</div>
              <div className="box__subtitle">
                Designate a default channel for secure notifications.
              </div>
            </div>
            <div className="search__group">
              <div className="search__sub">All Channels</div>
              {channelIds.length > 0 ? (
                <ResourceGroup interfaceMode="radio">
                  {channelIds.map((channelId) => renderChannel(channels[channelId]))}
                </ResourceGroup>
              ) :
                'No channels'}
            </div>
          </div>
          {automatedMessagesEnabled && (
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">Outgoing Channel {!areOfficesVisible && <span className="form__asterisk">*</span>}</div>
                <div className="box__subtitle">
                  {areOfficesVisible ?
                    `Select a channel for each office so that ${automatedMessageType}s are sent from the appropriate phone number.` :
                    'Choose the channel from which all automated messages will be sent.'}
                </div>
              </div>
              {areOfficesVisible && (
                <div className="am__offices">
                  <div className="am__offices__header">
                    <div className="am__offices__office">Offices</div>
                    <div className="am__offices__channel">Channel</div>
                  </div>
                  <div className="am__offices__body">
                    {currentOrganization.offices && currentOrganization.offices.map(renderOffice)}
                  </div>
                </div>
              )}
              <div className={defaultChannelClass}>
                {areOfficesVisible && (
                  <>
                    <label // eslint-disable-line jsx-a11y/label-has-for
                      htmlFor={null}
                    >
                      Default Channel <span className="form__asterisk">*</span>
                    </label>
                    <p className="u-text-small u-m-t-0">
                      Select a default {automatedMessageType}s channel.&nbsp;
                      This channel will be used when an office isn’t available in the {automatedMessageType} and/or when an office isn’t associated with a channel.
                    </p>
                  </>
                )}
                <div className="row">
                  <div className="column-5@small">
                    <Select
                      onSelect={handleChange}
                      options={channelList()}
                      selected={selectedChannel}
                      validationMessage={errors.selectedChannel}
                      name="selectedChannel"
                      dataCypress="selectReminderChannel"
                    />
                  </div>
                  <div className="column-7@small">
                    <div className="am__validation-bump">
                      {timeZone}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">Global Sounds</div>
              <div className="box__subtitle">
                Enable or disable sounds for your entire organization.
              </div>
            </div>
            <div className="block-preference">
              <RhinoSwitch
                name="soundOn"
                isChecked={soundOn}
                onChange={handleChange}
                label="Enable Sounds"
              />
            </div>
          </div>
          <div className="box box--preference">
            <div className="box__title-wrapper">
              <div className="box__title">Organization System Timeout</div>
              <div className="box__subtitle">
                A user session is a period in which someone is signed in the Rhinogram web or mobile app.&nbsp;
                For security reasons, we end this session after a period of inactivity (no navigation, closing the browser or the mobile app, etc.),&nbsp;
                and the member has to enter their credentials again.
              </div>
            </div>
            <div className="session-timeout__wrapper">
              <div className="session-timeout__label-wrapper">
                <label // eslint-disable-line jsx-a11y/label-has-for
                  htmlFor="sessionTimeoutMinutes"
                >
                  System timeout period (minutes)
                </label>
              </div>
              <div className="session-timeout__input-wrapper">
                <Input
                  initialValue={sessionTimeoutMinutes}
                  required
                  onChange={handleChange}
                  format={{
                    numeral: true,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'thousand',
                    numeralDecimalScale: 0,
                  }}
                  name="sessionTimeoutMinutes"
                  explanationMessage={getFriendlyTimeout(sessionTimeoutMinutes)}
                  validationMessage={errors.sessionTimeoutMinutes}
                />
              </div>
            </div>
          </div>
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">MESSAGING OPTIONS</div>
            </div>
            {(isAutomatedMessageToConnectedPartiesEnabled && (hasAppointmentCampaigns || currentOrganization.arePrescriptionCampaignsEnabled)) && (
              <div className="block-preference">
                <div className="block-preference__left">
                  <h4 className="block-preference__name">{campaignType} and RhinoBlast Messages to Connected Parties</h4>
                  <div className="block-preference__desc">
                    Toggle this setting ON if you would like {campaignType} and Rhinoblast messages to be sent to the Connected Parties of a
                    Patient if the patient has no phone number on their profile.
                  </div>
                </div>
                <div className="block-preference__right">
                  <RhinoSwitch
                    name="canSendAppointmentToConnectedParties"
                    isChecked={canSendAppointmentToConnectedParties}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            <div className="appointment-reminder__wrapper">
              <label>Appointment Reminder Message Settings</label>
              <p className="block-preference__desc">
                When an appointment is created after all appointment reminder messages are scheduled.
              </p>

              <RadioGroup
                onChange={(value) => handleChange('isCreatedWithinHoursOfStartDateEnabled', value)}
                selectedValue={`${isCreatedWithinHoursOfStartDateEnabled}`}
                name="isCreatedWithinHoursOfStartDateEnabled"
                blockGroup
              >
                <Radio className="block-preference__desc_unmuted" value="true" label="Send Only Appointment Created Message, No Appointment Reminder Messages" />
                <Radio className="block-preference__desc_unmuted" value="false" label="Send All, Appointment Created Message, and Appointment Reminder Message" />
              </RadioGroup>
            </div>
            <div className="block-preference">
              <div className="block-preference__left">
                <h4 className="block-preference__name">Closing Conversation Options</h4>
                <div className="block-preference__desc">
                  Toggle this setting ON if you would like the &quot;Assignment Complete&quot; action to close the conversation&nbsp;
                  instead of returning the conversation to the original destination.
                </div>
              </div>
              <div className="block-preference__right">
                <RhinoSwitch
                  name="allowClosingByAssignee"
                  isChecked={allowClosingByAssignee}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="block-preference">
              <div className="block-preference__left">
                <h4 className="block-preference__name">Conversation Assignment</h4>
                <div className="block-preference__desc">
                  Toggle this setting ON if you would like the conversations that are initiated from a group to be automatically
                  assigned to that group.
                </div>
              </div>
              <div className="block-preference__right">
                <RhinoSwitch
                  name="allowAutoConversationAssignment"
                  isChecked={allowAutoConversationAssignment}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="block-preference">
              <div className="block-preference__left">
                <h4 className="block-preference__name">Patient Conversation Thread Filtering</h4>
                <div className="block-preference__desc">
                  <strong>Toggle OFF</strong>: displays only conversations sent from the current channels for that group. <br />
                  <strong>Toggle ON</strong>: displays conversations sent from all channels.
                </div>
              </div>
              <div className="block-preference__right">
                <RhinoSwitch
                  name="areChannelFiltersEnabled"
                  isChecked={areChannelFiltersEnabled}
                  onChange={handleChange}
                />
              </div>
            </div>
            {(isMessageTranslationFeatureEnabled) && (
              <div className="block-preference">
                <div className="block-preference__left">
                  <h4 className="block-preference__name">Message Translation</h4>
                  <div className="block-preference__desc">
                    Allow the option to translate incoming and outgoing messages.
                  </div>
                </div>
                <div className="block-preference__right">
                  <RhinoSwitch
                    name="isMessageTranslationEnabled"
                    isChecked={isMessageTranslationEnabled}
                    onChange={handleChange}
                    dataFeatureTag="messageTranslation"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="u-m-t-large u-text-right">
            <Button
              loading={formInProgress}
              onClick={handleSubmit}
              type="primary"
              disabled={!userHasAnyOfPermissions([ORGANIZATION_PREFERENCES_EDIT])}
              data-cypress="updatePreference"
            >
              {!userHasAnyOfPermissions([ORGANIZATION_PREFERENCES_EDIT]) && <><Icon icon="lock" />&nbsp;</>}
              Update Preferences
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

OrganizationPreferences.propTypes = {
  allowAutoConversationAssignment: PropTypes.bool.isRequired,
  areChannelFiltersEnabled: PropTypes.bool.isRequired,
  allowClosingByAssignee: PropTypes.bool.isRequired,
  areOfficesVisible: PropTypes.bool,
  canSendAppointmentToConnectedParties: PropTypes.bool.isRequired,
  channelIds: PropTypes.array.isRequired,
  channels: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object,
  defaultChannelId: PropTypes.number,
  errors: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  handleOfficeChannelSelect: PropTypes.func,
  handleSubmit: PropTypes.func,
  hasAppointmentCampaigns: PropTypes.bool.isRequired,
  isAutomatedMessageToConnectedPartiesEnabled: PropTypes.bool,
  isCreatedWithinHoursOfStartDateEnabled: PropTypes.bool,
  isMessageTranslationEnabled: PropTypes.bool.isRequired,
  isMessageTranslationFeatureEnabled: PropTypes.bool,
  offices: PropTypes.object,
  pageContainerRef: PropTypes.func.isRequired,
  selectedChannel: PropTypes.number,
  sessionTimeoutMinutes: PropTypes.string.isRequired,
  soundOn: PropTypes.bool,
  timeZone: PropTypes.string,
};

export default OrganizationPreferences;
