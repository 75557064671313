import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Icon,
  Input,
  Select,
} from 'rhinostyle';

class Email extends Component {
  state = {
    id: this.props.email.id || null,
    typeId: this.props.email.typeId || 4,
    value: this.props.email.value,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.email.value && this.props.email.value) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        id: this.props.email.id,
        typeId: this.props.email.typeId,
        value: this.props.email.value,
      });
    }
  }

  _handleEmailChange = () => {
    if (this.props.handleChange) {
      this.props.handleChange(this.state, this.props.idx);
    }
  }

  _handleChange = (name, value) => {
    this.setState({ value }, () => {
      this._handleEmailChange();
    });
  }

  _handleSelect = (name, typeId) => {
    this.setState({ typeId }, () => {
      this._handleEmailChange();
    });
  }

  render() {
    const {
      count,
      currentUser,
      createCP,
      handleRemoveContactData,
      idx,
      types,
      validationMessage,
      numEmails,
      integrated,
      isRemoveEmailDisabled,
    } = this.props;

    const label = createCP ? 'Email Address' : `Email Address ${count}`;

    const typeOpts = types ? types.slice() : [];

    return (
      <div className="form__group">
        <div className="row">
          <div className="column-7@xsmall column-6@small">
            <Input
              disabled={integrated && !currentUser.isCcr}
              validationMessage={validationMessage}
              type="email"
              initialValue={this.state.value}
              name={`userEmails-${idx}`}
              onChange={this._handleChange}
              label={label}
            />
          </div>
          <div className="column-4@xsmall column-3@small">
            <Select
              name="emailType"
              disabled={(integrated && !currentUser.isCcr) || isRemoveEmailDisabled}
              onSelect={this._handleSelect}
              options={typeOpts}
              selected={this.state.typeId}
              label="Email Type"
            />
          </div>
          {numEmails > 1 && (!integrated || currentUser.isCcr) && (
            <div className="column-1@xsmall column-2@small edit-profile__close-button__column">
              <Button
                reset
                disabled={isRemoveEmailDisabled}
                onClick={() => handleRemoveContactData(idx, 'userEmails')}
                size="small"
                className="u-text-danger edit-profile__close-button__icon"
              >
                <Icon icon="close" />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Email.propTypes = {
  currentUser: PropTypes.object,
  integrated: PropTypes.bool,
  count: PropTypes.number,
  createCP: PropTypes.bool,
  email: PropTypes.object,
  handleChange: PropTypes.func,
  handleRemoveContactData: PropTypes.func,
  idx: PropTypes.number,
  isRemoveEmailDisabled: PropTypes.bool,
  types: PropTypes.array,
  validationMessage: PropTypes.string,
  numEmails: PropTypes.number,
};

export default Email;
