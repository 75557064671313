import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Bucket,
  BucketBody,
  UtilityListItem,
  Button,
} from 'rhinostyle';
import * as DateHelpers from '../helpers/DateHelpers';
import * as UserHelpers from '../helpers/UserHelpers';
import { getAttachmentUrl } from '../helpers/SavedContentHelpers';
import PdfViewerContainer from '../containers/PdfViewerContainer';
import { setActiveSavedContentItemId } from '../reducers/savedContentReducer';
import { setActivePanel } from '../reducers/threadReducer';
import { fetchUser } from '../reducers/userReducer';
import { useContactForm } from '../hooks';

const FormLibraryItemDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { savedContentItemId, activeUserId } = props;
  const savedContentItems = useSelector((state) => state.savedContent.savedContentItems);
  const form = savedContentItems[savedContentItemId];
  const users = useSelector((state) => state.user.users);
  const activeUser = users[activeUserId];
  const [isFormPdfViewerOpen, setIsFormPdfViewerOpen] = useState(false);
  const activeSavedContentItem = savedContentItems[savedContentItemId];
  const { contactForm: originalForm } = useContactForm({ formInternalData: activeSavedContentItem.savedContentPdfLink });
  const savedContentCreatedByUser = originalForm ? users[activeSavedContentItem.contactId] : users[activeSavedContentItem.creator];
  const togglePdfViewer = () => {
    setIsFormPdfViewerOpen(!isFormPdfViewerOpen);
  };

  const openSavedContentItemId = async () => {
    if (location.pathname.includes('inbox')) {
      dispatch(setActiveSavedContentItemId(savedContentItemId));
      dispatch(setActivePanel('savedContent'));
    } else {
      await dispatch(fetchUser(activeUserId));
      history.push({ pathname: `/inbox/all/user/${activeUserId}`, state: { savedContentItemId } });
    }
  };

  return (
    <>
      <UtilityListItem className="contact-library__saved-content__item-details__wrapper">
        <Bucket>
          <BucketBody>
            <Button
              reset
              onClick={openSavedContentItemId}
            >
              <div>
                <div className="contact-library__saved-content__item-details__title">{form.title}</div>
                <div className="u-text-small u-m-b-small u-text-body">e-Sign Form</div>
                <div className="contact-library__saved-content__item-details__timestamp">
                  {
              `${!originalForm ? `Copied on ${UserHelpers.formatName(activeUser)}'s profile` : 'Completed'}
              ${DateHelpers.formatTimestamp(activeSavedContentItem.createdAt, 'ddd, MMM D, Y, h:mm a', true)}
              by ${UserHelpers.formatName(originalForm ? activeUser : savedContentCreatedByUser)}`
            }
                </div>
              </div>
            </Button>
          </BucketBody>
        </Bucket>
      </UtilityListItem>
      <PdfViewerContainer
        open={isFormPdfViewerOpen}
        attachmentUrl={getAttachmentUrl(form.savedContentPdfLink, form.contactId)}
        fileName={form.title}
        handlePdfViewerClose={togglePdfViewer}
      />
    </>
  );
};

FormLibraryItemDetails.propTypes = {
  savedContentItemId: PropTypes.number.isRequired,
  activeUserId: PropTypes.number.isRequired,
};

export default FormLibraryItemDetails;
