import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Button } from 'rhinostyle';

import rhinoLogoReversedHorizontal from '../assets/images/rhinogram-logo-horizontal-reversed.svg';

const SignUpHeader = (props) => {
  const {
    className,
    showLogout,
    officeName,
    handleLogout,
  } = props;

  const classes = cx('signup__header', className);

  const renderLogout = () => {
    if (showLogout) {
      return (<div className="signup__header__branding__logout"><Button type="outline-reversed" size="small" onClick={handleLogout}>Logout</Button></div>);
    }
    return false;
  };

  const renderOffice = () => {
    if (officeName) {
      return (<div className="signup__header__office">{officeName}</div>);
    }
    return false;
  };

  return (
    <div className={classes}>
      <div className="signup__header__branding">
        <img className="signup__header__branding__logo" src={rhinoLogoReversedHorizontal} alt="" />
        {renderLogout()}
      </div>
      {renderOffice()}
    </div>
  );
};

SignUpHeader.propTypes = {
  className: PropTypes.string,
  officeName: PropTypes.string,
  showLogout: PropTypes.bool,
  handleLogout: PropTypes.func,
};

SignUpHeader.defaultProps = {
  officeName: null,
  showLogout: false,
};

export default SignUpHeader;
