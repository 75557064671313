import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as UIReducer from '../reducers/uiReducer';
import * as AuthReducer from '../reducers/authReducer';
import Signup from '../components/Signup';

import { LocationHelpers, UserHelpers } from '../helpers';
import { PASSWORD_STRENGTH_VALIDATION_MESSAGE } from '../constants/AppConstants';

const requiredText = 'This field is required';

class SignupContainer extends Component {
  state = {
    loginEmail: '',
    errors: {},
    officeName: '',
    officePhone: '',
    password: '',
    passwordConfirmation: '',
    formInProgress: false,
  };

  componentDidMount() {
    document.title = 'Rhinogram | Sign Up';
    const params = LocationHelpers.getQueryParams(decodeURIComponent(window.location.search));

    this.setState(params);
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  /**
   * Runs through supplied values and validates them
   * @return {object} Error list
   */
  handleValidation = () => {
    const returnVal = {};
    const { password, passwordConfirmation } = this.state;

    // Email
    if (this.state.loginEmail === '') {
      returnVal.loginEmail = requiredText;
    } else if (!this.state.loginEmail.match(/.+?@.+?\..+?/i)) {
      returnVal.loginEmail = 'Invalid email address';
    }

    // Password
    if (password === '') {
      returnVal.password = requiredText;
    } else if (!UserHelpers.isSecurePassword(password)) {
      returnVal.password = PASSWORD_STRENGTH_VALIDATION_MESSAGE;
    }

    // Only run password confirm check if the password field is valid
    if (!returnVal.password) {
      // Confirm password
      if (passwordConfirmation === '' || (passwordConfirmation !== password)) {
        returnVal.passwordConfirmation = 'This field should match the password field';
      }
    }

    return returnVal;
  }

  handleServerValidation = (error) => {
    const errors = {};

    errors[error.property] = error.message;

    this.setState({ errors });
  }

  handleSignup = () => {
    // Clear existing errors
    this.props.setError(null);

    const errors = this.handleValidation();

    if (Object.keys(errors).length) {
      this.setState({ errors });
    } else {
      this.setState({ formInProgress: true });

      this.props.signup(
        this.state.loginEmail,
        this.state.password,
        this.state.orgId,
        this.state.userId,
        this.state.hash,
      )
        .then(() => {
          if (this.props.error) {
            this.handleServerValidation(this.props.error.data);
            this.setState({ formInProgress: false });
          } else {
            this.props.login(this.state.loginEmail, this.state.password, true)
              .then((nextPath) => {
                if (nextPath) {
                  this.props.history.push(nextPath);
                }
              });
          }
        });
    }
  }

  render() {
    const props = {
      errors: this.state.errors,
      handleChange: this.handleChange,
      handleSignup: this.handleSignup,
      officeName: this.state.officeName,
      officePhone: this.state.officePhone,
      formInProgress: this.state.formInProgress,
    };

    return (<Signup {...props} />);
  }
}

SignupContainer.propTypes = {
  error: PropTypes.object,
  login: PropTypes.func,
  signup: PropTypes.func,
  setError: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { ui } = state;

  return {
    error: ui.error,
  };
};

const actions = {
  login: AuthReducer.login,
  signup: AuthReducer.signup,
  setError: UIReducer.setError,
};

export default connect(mapStateToProps, actions)(SignupContainer);
