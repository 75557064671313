import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import UpdatePasswordForm from '../components/UpdatePasswordForm';
import { ValidationHelpers } from '../helpers';
import * as UserReducer from '../reducers/userReducer';

class UpdatePasswordFormContainer extends Component {
  state = {
    disableSave: true,
    errors: {},
    formInProgress: false,
    newPassword: '',
    newPasswordConfirm: '',
    renderPasswordInfo: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.renderPasswordInfo !== this.state.renderPasswordInfo && this.props.onFormToggle) {
      this.props.onFormToggle(this.state.renderPasswordInfo);
    }
  }

  getPayload = (data) => ({
    newPass: data.newPassword,
  });

  handleChange = (name, value) => {
    this.setState({
      disableSave: false,
      [name]: value,
    });
  }

  handleToggle = (name) => {
    this.setState({ [name]: !this.state[name] });
  }

  handleSubmit = () => {
    this.setState({ disableSave: true });

    let errors = ValidationHelpers.verifyPassword(this.state.newPassword, this.state.newPasswordConfirm);

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ formInProgress: true });
      this.props.updateUserPassword(this.getPayload(this.state))
        .then(() => {
          if (this.props.error) {
            errors = ValidationHelpers.handleServerError(this.props.error.data);
            this.setState({ errors, formInProgress: false });
          } else {
            this.setState({ errors: {}, formInProgress: false, renderPasswordInfo: false });
          }
        });
    }
  };

  render() {
    const props = {
      disableSave: this.state.disableSave,
      errors: this.state.errors,
      handleChange: this.handleChange,
      handleSubmit: this.handleSubmit,
      handleToggle: this.handleToggle,
      formInProgress: this.state.formInProgress,
      renderPasswordInfo: this.state.renderPasswordInfo,
    };

    return <UpdatePasswordForm {...props} />;
  }
}

UpdatePasswordFormContainer.propTypes = {
  error: PropTypes.object,
  updateUserPassword: PropTypes.func.isRequired,
  onFormToggle: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { ui } = state;

  return {
    error: ui.error,
  };
};

const actions = {
  updateUser: UserReducer.updateUser,
  updateUserPassword: UserReducer.updateUserPassword,
};
export default connect(mapStateToProps, actions)(UpdatePasswordFormContainer);
