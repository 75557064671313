import Quill from 'quill';
import { isInternetExplorer } from '../helpers/BrowserHelpers';
import { replaceZeroWidthWhiteSpace } from '../helpers/StringHelpers';

const Embed = Quill.import('blots/embed');

class MentionBlot extends Embed {
  static create(data) {
    let mention = data;
    // Replace text contents of variable to hide squigglies
    const regexSquiggles = /{(.*)?}/g;
    mention = mention.replace(regexSquiggles, '<i>{</i><div>$1</div><i>}</i>');
    const regexUnderscores = /_/g;
    mention = mention.replace(regexUnderscores, '</div><b>_</b><div>');
    mention = replaceZeroWidthWhiteSpace(mention);
    // IE has a known bug with nested HTML elements inside a contenteditable parent.
    // Solution is to set the nested HTML element to _also_ be content editable but only in IE
    // https://stackoverflow.com/questions/7522848/contenteditable-false-inside-contenteditable-true-block-is-still-editable-in-ie8/7522910#7522910
    const node = document.createElement('span');
    if (isInternetExplorer()) node.setAttribute('contenteditable', true);
    node.setAttribute('readonly', true);
    node.setAttribute('onmousedown', 'return false;');
    node.setAttribute('onselectstart', 'return false;');
    node.setAttribute('data-cypress', 'mention-variable');
    node.innerHTML = mention;
    node.classList.add('mention__variable');
    return MentionBlot.setDataValues(node, data);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }
}

MentionBlot.blotName = 'mention';
MentionBlot.tagName = 'span';
MentionBlot.className = 'mention__variable';

Quill.register(MentionBlot);

export default Quill;
