import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import {
  Icon,
  Avatar,
} from 'rhinostyle';

import HtmlMessage from '../HtmlMessage';
import Attachment from '../Attachment';
import { AppConstants, Types } from '../../constants';
import {
  DateHelpers,
  DataHelpers,
  ErrorCodeHelpers,
  PhoneHelpers,
  UserHelpers,
} from '../../helpers';
import {
  TYPE_FACEBOOK,
  TYPE_INSTAGRAM,
  TYPE_SMS,
} from '../../constants/Types';
import { MAX_FACEBOOK_FILE_SIZE, MAX_MMS_FILE_SIZE } from '../../constants/AppConstants';
import LinkedAttachments from '../LinkedAttachments';
import LinkedForms from '../LinkedForms';
import { getActiveUser, getLoggedInUser, getLoggedInUserOrganization } from '../../selectors/userSelectors';
import { translateEventText } from '../../reducers/inboxReducer';
import { isAndroid } from '../../helpers/BrowserHelpers';
import { useEventPhone } from '../../hooks';
import { MessageStatus } from '../../constants/v3';

const {
  formatAvatarName,
  formatFacebookName,
  formatInstagramName,
} = UserHelpers;
const {
  TYPE_PATIENT,
  TYPE_USER_OTHER,
} = Types;
const MessageEvent = (props) => {
  const {
    event: messageEvent,
    isSecureThread,
    currentUser,
    isNativeApp,
    searchText,
    userOrganization,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const isChannelInfoVisible = !searchText;
  const [isTranslated, setIsTranslated] = useState(false);

  const {
    timestamp,
    translatedText,
    isMessageFailed,
    isIncoming,
    channel,
    externalErrorCode,
    fromUser = {},
    language,
    text,
    activeUser,
    fromExternalId,
    from,
    forms,
    attachments,
    id,
    fromWebForm,
    isMessageRead,
    isSecureEvent,
    isMessageEvent,
    isNoteEvent,
    isNetworkError,
    formattedFromUserName,
    status,
  } = messageEvent;

  const eventPhone = useEventPhone(messageEvent);
  const isSMSChannel = channel?.typeId === TYPE_SMS;
  const isFacebookChannel = channel?.typeId === TYPE_FACEBOOK;
  const isInstagramChannel = channel?.typeId === TYPE_INSTAGRAM;
  const externalEndpoint = getExternalEndpointValue();
  const { isMessageTranslationEnabled, isMessageTranslationFeatureEnabled } = userOrganization;
  const isMessageTranslationEnabledForOrg = isMessageTranslationEnabled && isMessageTranslationFeatureEnabled;
  const isMessageReadStatusAvailable = !isSecureThread && !isIncoming && isSecureEvent;
  const isNonTextableError = ErrorCodeHelpers.isNonTextableErrorCode(externalErrorCode);
  const messageEventChannelTypeId = isMessageEvent ? channel?.typeId : '';
  const formattedTimestamp = DateHelpers.formatTimestamp(timestamp);
  async function handleTranslateText() {
    if (!isTranslated) {
      if (!translatedText) {
        await dispatch(translateEventText({
          languageCode: language,
          text,
          targetLanguage: 'en',
          id,
        }));
      }
      setIsTranslated(true);
    } else {
      setIsTranslated(false);
    }
  }

  const profileImageUrl = fromUser?.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${fromUser.profileImageUrl}` : '';

  const isMessageFooterTooltipVisible = (
    externalEndpoint &&
    !isIncoming &&
    currentUser.typeId !== TYPE_PATIENT &&
    messageEventChannelTypeId !== Types.TYPE_FACEBOOK &&
    messageEventChannelTypeId !== Types.TYPE_INSTAGRAM
  );
  const renderMessageFooterTooltipPhone = () => {
    const phoneDetail = `${externalEndpoint}
      ${DataHelpers.hasData(eventPhone) ? `(${PhoneHelpers.formatTypes(eventPhone)})` : ''}`;
    return (
      <p className="convo__item__tooltip__description">
        {isMessageFailed && (
        <span className="u-p-r-small">
          <Icon icon="close" className="convo__item__tooltip__failed-icon" />
        </span>
        )}
        {phoneDetail}
      </p>
    );
  };
  const dataCypressValue = cx('', {
    inboundMessage: isIncoming && !isNoteEvent,
    outboundMessage: !isIncoming && !isNoteEvent,
    noteMessage: isNoteEvent,
  });

  const getChannelIcon = () => {
    if (isSecureEvent) {
      return 'lock';
    }
    if (isFacebookChannel) {
      return 'facebook';
    }
    if (isInstagramChannel) {
      return 'instagram';
    }
    return 'chat';
  };

  function getExternalEndpointValue() {
    if (from) {
      return PhoneHelpers.formatPhone(from);
    }
    if (isInstagramChannel) {
      return formatInstagramName(activeUser, fromExternalId);
    }

    if (isFacebookChannel) {
      return formatFacebookName(activeUser, fromExternalId);
    }
    if (isSMSChannel && eventPhone?.value) {
      return PhoneHelpers.formatPhone(eventPhone.value);
    }
    return null;
  }

  const renderExternalEndpointInfo = () => {
    const iconType = getChannelIcon();
    if (fromWebForm) {
      return <span>(Web Form)&nbsp;{externalEndpoint}&nbsp;&nbsp;</span>;
    }
    return (
      <span>
        <Icon icon={iconType} />&nbsp;{externalEndpoint}&nbsp;&nbsp;
      </span>
    );
  };

  const renderChannelNameInfo = () => {
    const iconType = getChannelIcon();
    return (
      <span data-cypress="inbox-message-channel-name">
        <Icon icon={iconType} />&nbsp;{channel?.name}&nbsp;&nbsp;
      </span>
    );
  };

  const renderChannelInfo = () => {
    let channelInfo = '';
    if (isNoteEvent) {
      channelInfo = <span><Icon icon="note" bump="up" />&nbsp;Note&nbsp;&nbsp;</span>;
    } else {
      // incoming message is from a channel and outgoing message is from a number OR from a secure channel in contact (patient) case.
      const channelInfoForPatientView = ((isIncoming && externalEndpoint) || (!isIncoming && !externalEndpoint)) ?
        renderChannelNameInfo()
        : renderExternalEndpointInfo();
        // incoming message is from a number and outgoing message is from a channel in member case.
      const channelInfoForMemberView = isIncoming && externalEndpoint ?
        renderExternalEndpointInfo()
        : renderChannelNameInfo();
      channelInfo = (currentUser.typeId === TYPE_PATIENT || currentUser.typeId === TYPE_USER_OTHER) ? channelInfoForPatientView : channelInfoForMemberView;
    }

    return channelInfo;
  };

  const renderMessageFooterTooltipContent = () => (
    <div className="convo__item__tooltip__wrapper">
      <div className="convo__item__tooltip">
        <h6 className="convo__item__tooltip__title">
          OUTGOING PHONE NUMBER
        </h6>
        {renderMessageFooterTooltipPhone()}
      </div>
    </div>
  );

  const messageFooterClasses = cx('convo__item__footer__message', {
    'u-text-accent': status === MessageStatus.Unsent,
  });
  const renderMessageFooter = () => (
    <div className={messageFooterClasses}>
      {isChannelInfoVisible && renderChannelInfo()}
      <span title={DateHelpers.fullTimestamp(timestamp)}>{formattedTimestamp}</span>
      {isMessageFailed && isNetworkError && (
      <span>
        <strong className="u-text-danger" data-cypress="messageNotSent">
              &nbsp; Unable to send, message will be removed
        </strong>
      </span>
      )}
      {/* This block should be shown when the message is read. */}
      {(isMessageReadStatusAvailable && isMessageRead) ? (
        <span className="u-text-success u-m-l-small" data-cypress="seen">
          <Icon icon="checkmark" />&nbsp;Seen
        </span>
      ) : null}
      {/* This block should be shown when a message does not send correctly. */}
      {isMessageFailed && !isNoteEvent && !isIncoming && !isNonTextableError && !isNetworkError && (
      <strong className="u-text-danger">
            &nbsp; Failed
      </strong>
      )}
      {/* This error block is shown only for non-textable errors */}
      {isMessageFailed && !isNoteEvent && !isIncoming && isNonTextableError && (
      <span>
        <strong className="u-text-danger">
              &nbsp; Failed Non-textable Number
        </strong>
      </span>
      )}
      {isMessageFooterTooltipVisible && renderMessageFooterTooltipContent()}
    </div>
  );
  const renderAttachment = (attachment, key, incoming) => {
    // if file is too large to be sent in a thread, a sharelink will be sent via API instead, so don't render
    if (incoming && attachment.bytes > MAX_MMS_FILE_SIZE && isSMSChannel) {
      return null;
    } else
    if (incoming && attachment.bytes > MAX_FACEBOOK_FILE_SIZE && isFacebookChannel) {
      return null;
    } else {
      return (
        <Attachment attachment={attachment} key={key} incoming={incoming} isNote={isNoteEvent} />
      );
    }
  };
  const renderAttachments = () => {
    const isNativeAndroid = isNativeApp && isAndroid();
    const eventContainsText = isTranslated && translatedText ? translatedText : text;
    if (isNoteEvent) {
      return (
        <div>
          <LinkedAttachments isNativeAndroid={isNativeAndroid} event={messageEvent} breakBeforeFirstAttachment={eventContainsText} />
          {attachments?.length > 0 &&
          <div className="convo__item__body__msg__attachments">{attachments?.map((a, k) => renderAttachment(a, k, isIncoming))}</div>}
        </div>
      );
    } else {
      return (
        <>
          {forms?.length > 0 && (
          <LinkedForms
            event={messageEvent}
            breakBeforeFirstAttachment={eventContainsText}
          />
          )}
          <LinkedAttachments isNativeAndroid={isNativeAndroid} event={messageEvent} breakBeforeFirstAttachment={eventContainsText} />
        </>
      );
    }
  };
  function handleClick() {
    const scrollEvent = document.getElementById(`js-convo__item-${messageEvent.id}`);
    if (scrollEvent) {
      scrollEvent.scrollIntoView();
    } else {
      history.push({
        state: { eventId: messageEvent.id, pageNo: messageEvent.pageNo },
      });
    }
  }

  const avatarName = messageEvent.isAutomatedCampaignEvent ? { firstName: 'Rhino', lastName: 'System' } : fromUser;

  return (
    <div onClick={() => searchText && handleClick()}>
      <div className="convo__item__header">{formattedFromUserName || '  '}</div>
      <div className="convo__item__body" data-cypress={dataCypressValue}>
        <div className="convo__item__body__avatar">
          <Avatar
            image={profileImageUrl}
            name={formatAvatarName(avatarName?.firstName, avatarName?.lastName)}
            size="small"
            type={!isIncoming ? 'member' : 'default'}
            className="avatar-blue"
          />
        </div>
        <div>
          <HtmlMessage
            event={messageEvent}
            searchText={searchText}
            isNoteEvent={isNoteEvent}
            isTranslated={isTranslated}
            appendedElement={renderAttachments()}
            isRightToLeft={!!(!isTranslated && language?.isRightToLeft)}
          />
          {!isNoteEvent && attachments?.length > 0 &&
          <div className="convo__item__body__msg__attachments">{attachments?.map((a, k) => renderAttachment(a, k, isIncoming))}</div>}
        </div>
      </div>
      <div className="convo__item__footer">
        {renderMessageFooter()}
        <div className="translation-button">
          {language && isMessageTranslationEnabledForOrg && (
          <span
            className="convo__item__translation u-text-left"
            onClick={handleTranslateText}
          >
            {isTranslated && translatedText ? `See Original (${language?.name})` : 'See Translation'}
          </span>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const {
    form,
    nativeApp,
    savedContent,
    thread,
  } = state;
  return {
    activeUser: getActiveUser(state),
    currentUser: getLoggedInUser(state),
    formS3Url: form.formS3Url,
    formTemplate: form.formS3Url,
    isContentSelected: savedContent.selectedEventIds.includes(props.eventId),
    isNativeApp: nativeApp.isNativeApp,
    userOrganization: getLoggedInUserOrganization(state),
    messageInputHeightChange: thread.messageInputHeightChange,
  };
};

MessageEvent.propTypes = {
  currentUser: PropTypes.object.isRequired,
  isNativeApp: PropTypes.bool,
  isSecureThread: PropTypes.bool,
  searchText: PropTypes.string,
  userOrganization: PropTypes.object.isRequired,
  event: PropTypes.object,
};
export default connect(mapStateToProps)(MessageEvent);
