import { formatUserLabel } from './UserHelpers';
import { fullTimestamp } from './DateHelpers';
import { SAVED_CONTENT_FILE_BASE_URL } from '../constants/AppConstants';

export function getAuditInformation(timestamp, contact) {
  const contactName = contact ? formatUserLabel(contact) : '';
  return `Saved ${fullTimestamp(timestamp, 'ddd, MMM D, Y, h:mm a')} by ${contactName}`;
}

export function getCreatorFromSavedContentEvent(savedContentEvent, users) {
  if (!savedContentEvent?.createdByUserId || !users) return undefined;
  return users[savedContentEvent.createdByUserId];
}

export function getAttachmentUrl(fileName, contactId) {
  return `${SAVED_CONTENT_FILE_BASE_URL}${fileName}${contactId ? `?contactId=${contactId}` : ''}`;
}
