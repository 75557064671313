import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as InboxReducer from '../reducers/inboxReducer';
import { updateArrayById, areArraysEqualWhenSorted } from '../helpers/DataHelpers';

const useThreadFilters = () => {
  const dispatch = useDispatch();
  const {
    threadFilteredChannelIds, // Default channel filter for inbox
    threadSelectedChannelIds, // Applied channel filter different than default
    threadActiveChannelIds, // Active channels available for filtering
  } = useSelector((state) => state.inbox);

  const viewingChannelIds = threadSelectedChannelIds.length > 0
    ? threadSelectedChannelIds
    : threadFilteredChannelIds;

  const [selectedChannelIds, setSelectedChannelIds] = useState(viewingChannelIds);

  // Selected filter different than viewing filter
  const filtersEnabled = selectedChannelIds.length > 0 &&
  !areArraysEqualWhenSorted(selectedChannelIds, viewingChannelIds);

  // Selected filter different than default filter
  const filtersApplied = selectedChannelIds.length > 0 &&
  !areArraysEqualWhenSorted(selectedChannelIds, threadFilteredChannelIds);

  const handleApplyThreadFilterClick = () => {
    dispatch(InboxReducer.handleApplyThreadFilters(selectedChannelIds));
  };

  const handleClearThreadFilterClick = () => {
    setSelectedChannelIds(threadFilteredChannelIds);
    dispatch(InboxReducer.handleApplyThreadFilters(threadFilteredChannelIds));
  };

  const handleSelectChannelId = (id) => {
    setSelectedChannelIds(updateArrayById(selectedChannelIds, id));
  };

  return {
    filtersApplied,
    filtersEnabled,
    handleApplyThreadFilterClick,
    handleClearThreadFilterClick,
    handleSelectChannelId,
    selectedChannelIds,
    threadActiveChannelIds,
    viewingChannelIds,
  };
};

export default useThreadFilters;
