import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
} from 'rhinostyle';

const SmartTableHeader = (props) => {
  const {
    sortKey,
    className,
    isCaretDisabled = false,
  } = props;
  const [headers, updateHeaders] = useState(props.headers);
  const activeCaretClassName = 'u-text-body';

  const headerHandler = (headerClicked) => {
    const oldDirection = headers[headerClicked].direction;
    const lastActiveHeader = Object.keys(headers).find((header) => (headers[header].default === true));

    Object.keys(headers).forEach((key) => {
      headers[key].direction = -1;
      headers[key].default = false;
    });

    headers[headerClicked].default = true;
    if (lastActiveHeader === headerClicked) {
      headers[headerClicked].direction = oldDirection * -1;
    } else {
      headers[headerClicked].direction = 1;
    }
    return headers;
  };

  const customHeaderSort = () => {
    if (isCaretDisabled) return;
    const newHeaders = headerHandler(sortKey);
    updateHeaders(newHeaders);
  };

  const caretUpClass = !isCaretDisabled && headers[sortKey].default && headers[sortKey].direction === 1 ? activeCaretClassName : ''; // ascending
  const caretDownClass = !isCaretDisabled && headers[sortKey].default && headers[sortKey].direction === -1 ? activeCaretClassName : ''; // descending

  return (
    <div className={`u-flex u-wrap-text u-p-a-small ${className || ''}`} data-check={sortKey} onClick={customHeaderSort}>
      {props.headerName}
      {!isCaretDisabled && (
        <span className="u-flex u-flex-direction-column">
          <Icon icon="caret-up" className={caretUpClass} />
          <Icon icon="caret-down" className={caretDownClass} />
        </span>
      )}
    </div>
  );
};

SmartTableHeader.propTypes = {
  headerName: PropTypes.string,
  sortKey: PropTypes.string,
  headers: PropTypes.object,
  isCaretDisabled: PropTypes.bool,
  className: PropTypes.string,
};

export default SmartTableHeader;
