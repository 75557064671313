import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import cx from 'classnames';
import {
  UtilitySystem,
  Button,
  Dropdown,
  DropdownMenuItem,
  Modal,
  Icon,
  Input,
  Scrollbars,
  ResourceGroup,
  Resource,
  ResourceIntro,
  LoaderPulse,
} from 'rhinostyle';
import { getLoggedInUser } from '../selectors/userSelectors';
import * as UserReducer from '../reducers/userReducer';
import * as GroupReducer from '../reducers/groupReducer';
import StorageService from '../services/StorageService';
import { MaskingHelpers, SearchHelpers } from '../helpers';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { getActiveSystemAlert } from '../selectors/systemAlertSelectors';
import { Types, AppConstants } from '../constants';
import UserSearchResult from './UserSearchResult';
import { CONTACT_CREATE, CONVERSATION_CONTACT_MOBILE } from '../constants/UserPermissionsConstants';
import { userHasAnyOfPermissions, userHasAllOfPermissions } from '../helpers/UserHelpers';
import { isMobile } from '../helpers/BrowserHelpers';

class GlobalUserSearch extends Component {
  state = {
    activeFilterParam: 'name',
    searchText: '',
    // This will store a reference to our Cleave formated input -- In order to hard reset it to '' where necessary.
    // https://github.com/nosir/cleave.js/blob/master/doc/reactjs-component-usage.md#how-to-update-raw-value
    searchTextCleave: null,
    searchFocus: false,
    inputFormat: null,
    isMobileSearchModalOpen: false,
    showMobileSearchModal: false,
  };
  multiUserInputRef = React.createRef();
  contactRef = React.createRef();

  componentDidMount() {
    this.handleSearch = debounce(this.handleSearch, 300);
    window.addEventListener('resize', this.updateDimensions);
    this.setStoredFilterParam();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showGlobalSearchModalWrapper && !this.props.showGlobalSearchModalWrapper && this.state.searchText.length > 0) {
      this.handleFormReset();
    }
    if (this.props.showGlobalSearchModalWrapper && prevProps.location.pathname !== this.props.location.pathname) {
      this.props.handleToggleGlobalSearchModalWrapper();
    }
  }

  updateDimensions = () => {
    if (window.innerWidth >= AppConstants.BREAKPOINT_SMALL && this.state.isMobileSearchModalOpen) {
      this.handleCloseModal();
    }
  }

  onSearchTextCleaveInit = (cleave) => {
    this.setState({ searchTextCleave: cleave });
  }

  resetCleaveInputValue = () => {
    this.state.searchTextCleave.setRawValue('');
  }

  setInputFormat = () => {
    const { activeFilterParam } = this.state;

    this.setState({
      inputFormat: null,
    }, () => {
      let inputFormat = null;

      if (activeFilterParam === 'dob') {
        inputFormat = UtilitySystem.dateFormat;
      } else if (activeFilterParam === 'phone') {
        inputFormat = MaskingHelpers.phone;
      }

      this.setState({
        inputFormat,
        searchFocus: true,
      });
    });
  }

  setStoredFilterParam = () => {
    const storedFilterParam = StorageService.readEntry(this.localStorageFilterParam);
    const paramsToSearch = SearchHelpers.filterContactParams;

    if (storedFilterParam && paramsToSearch.filterParams.includes(storedFilterParam)) {
      this.setState({
        activeFilterParam: storedFilterParam,
      }, () => {
        this.setInputFormat();
      });
    }
  }

  localStorageFilterParam = `rhinogram-${this.props.user.id}-searchFilterType`;

  handleFilterChange = (newFilter) => {
    const { activeFilterParam: prevFilter } = this.state;

    if ((prevFilter === 'dob' || prevFilter === 'phone') && (newFilter !== 'dob' && newFilter !== 'phone')) {
      this.setState({ searchTextCleave: null });
    }

    this.setState({
      activeFilterParam: newFilter,
      searchText: '',
    }, () => {
      this.props.clearUserSearch();
      this.setInputFormat();

      this.setState({
        searchFocus: true,
        searchText: '',
      });

      StorageService.createEntry(this.localStorageFilterParam, newFilter);
    });
  }

  handleSearch = (id, searchValue, searchValueFormatted) => {
    const { userSearchIds, fetchUserSearch, clearUserSearch, scope } = this.props;
    const { activeFilterParam: searchType } = this.state;
    const searchText = (searchType === 'dob' || searchType === 'phone') ? searchValueFormatted : searchValue;
    const source = 'globalSearch';
    if (searchText && searchText.length > 2) {
      fetchUserSearch({ searchValue: searchText, scope, type: searchType, source });
    } else if (userSearchIds.length) {
      clearUserSearch();
    }

    this.setState({ searchText });
  };

  handleSearchSelect = (userId) => {
    const user = this.props.users[userId];
    this.props.handleToggleGlobalSearchModalWrapper();
    this.handleFormReset();
    if (user.typeId === Types.TYPE_MEMBER) {
      this.props.history.push(`/settings/organization/members/${userId}`);
    } else {
      this.props.history.push(`/inbox/all/user/${userId}`);
    }
  }

  handleFormReset = () => {
    if (this.state.searchTextCleave) this.resetCleaveInputValue();
    const { activeFilterParam } = this.state;
    let inputFormat = null;
    this.setState({
      inputFormat,
      searchText: '',
      searchTextCleave: null,
    }, () => {
      if (activeFilterParam === 'dob') {
        inputFormat = UtilitySystem.dateFormat;
      } else if (activeFilterParam === 'phone') {
        inputFormat = MaskingHelpers.phone;
      }
      this.setState({
        inputFormat,
      });
    });

    this.handleCloseModal();
    this.props.clearUserSearch();
  }

  handleCreateContact = () => {
    this.handleFormReset();
    this.props.handleToggleGlobalSearchModalWrapper();
    const { searchText, activeFilterParam } = this.state;
    this.props.history.push({
      pathname: '/contacts/create',
      state: {
        searchText,
        activeFilterParam,
      },
    });
  }

  handleCloseModal = () => {
    if (this.state.showMobileSearchModal) {
      this.handleToggleMobileSearchModal();
    }
  }

  handleToggleMobileSearchModal = () => {
    this.setState((prevState) => ({
      showMobileSearchModal: !prevState.showMobileSearchModal,
      ...prevState.showMobileSearchModal === false && {
        isMobileSearchModalOpen: true,
      },
    }));
  }

  handleReverseComplete = () => {
    this.setState({ isMobileSearchModalOpen: false });
  }

  onScroll = () => {
    if (!this.contactRef.current) return;
    const scrollContainer = this.contactRef.current.container.firstChild;
    const totalScroll = scrollContainer.scrollTop + scrollContainer.clientHeight;
    const { fetchUserSearch, scope, searchSize, page } = this.props;
    const { activeFilterParam: searchType, searchText } = this.state;
    const source = 'globalSearch';
    if (totalScroll === scrollContainer.scrollHeight) {
      this.scrollPosition = 'bottom';

      fetchUserSearch({ searchValue: searchText, scope, type: searchType, source, page: page + searchSize });
    }

    if ((totalScroll !== scrollContainer.scrollHeight) && (scrollContainer.scrollTop !== 0)) {
      this.scrollPosition = 'middle';
    }

    if (scrollContainer.scrollTop === 0) {
      this.scrollPosition = 'top';
    }
  }

  renderSearchHelp = () => {
    if (this.state.searchText.length > 2 && this.props.userSearchLoading) {
      return <div className="u-text-center"><LoaderPulse type="secondary" /></div>;
    }
    return null;
  };

  renderCreateContact = () => {
    const createContactClasses = cx('search__create-contact', {
      'search__create-contact__no-results': !this.props.userSearchIds.length,
    });
    return (
      <div className={createContactClasses}>
        <Resource className="u-text-primary" onClick={this.handleCreateContact} data-cypress="createNewContact">
          <ResourceIntro icon={{ icon: 'add' }} title="Create New Contact" />
        </Resource>
      </div>
    );
  }

  renderFilterOption = (id) => (
    <DropdownMenuItem key={id} id={id} label={SearchHelpers.getFilterLabelInformation(id).label} />
  );

  handleCloseModal = () => {
    if (this.state.isMobileSearchModalOpen) {
      this.handleToggleMobileSearchModal();
    }
  }

  handleFocus = (isDesktop) => {
    if (!this.props.showGlobalSearchModalWrapper && isDesktop) {
      this.props.handleToggleGlobalSearchModalWrapper();
    }
  }

  renderSearchInput = (isDesktop) => {
    const inputType = this.state.activeFilterParam === 'phone' ? 'tel' : 'text';
    const showCreateContact = isMobile() ? userHasAllOfPermissions([CONTACT_CREATE, CONVERSATION_CONTACT_MOBILE]) :
      userHasAnyOfPermissions([CONTACT_CREATE]);
    const searchResultWrapperClasses = cx('resource-group__scroll absolute', {
      'resource-group__scroll__no-permission': !showCreateContact,
    });
    return (
      <>
        <Input
          placeholder={this.state.searchFocus ? SearchHelpers.getFilterLabelInformation(this.state.activeFilterParam).placeholder : 'Search Users'}
          className="search__input"
          onChange={this.handleSearch}
          initialValue={this.state.searchText}
          size="large"
          name={this.props.scope}
          format={this.state.inputFormat}
          addon="both"
          type={inputType}
          autoComplete="off"
          onInit={this.onSearchTextCleaveInit}
          onFocus={() => this.handleFocus(isDesktop)}
        >
          {this.state.isMobileSearchModalOpen ? (
            <Button
              onClick={this.handleFormReset}
              reset
            >
              <Icon icon="arrow-left" />
            </Button>
          ) : (
            <Icon icon="search" />
          )}
          <Dropdown
            size="large"
            hideCaret
            wide
            activeKey={this.state.activeFilterParam}
            type="input"
            onSelect={this.handleFilterChange}
            title="Search by"
            icon="filter"
            className="u-text-muted u-text-large"
            position="right"
          >
            {SearchHelpers.filterContactParams.filterParams.map(this.renderFilterOption)}
          </Dropdown>
        </Input>
        {this.props.userSearchSource === 'globalSearch' && (this.props.showGlobalSearchModalWrapper || this.state.isMobileSearchModalOpen) && (
          <div className="search-result-wrapper">
            {this.props.userSearchIds.length > 0 ? (
              <Scrollbars className={searchResultWrapperClasses} onScroll={this.onScroll} ref={this.contactRef} autoHeight data-cypress="searchResult">
                <ResourceGroup>
                  {this.props.userSearchIds.map((userId) => (
                    <UserSearchResult
                      userId={userId}
                      key={userId}
                      handleClick={this.handleSearchSelect}
                      scope={this.props.scope}
                      activeFilterParam={this.state.activeFilterParam}
                      searchText={this.state.searchText}
                    />
                  ))}
                </ResourceGroup>
              </Scrollbars>
            ) : this.renderSearchHelp()}
            {showCreateContact && this.renderCreateContact()}
          </div>
        )}
      </>
    );
  }

  renderSearchModalMobile = () => (
    <Modal
      open={this.state.showMobileSearchModal}
      onReverseComplete={this.handleReverseComplete}
    >
      <div
        className="global-search-header__mobile"
        style={{ marginTop: this.props.systemAlert ? this.props.headerOffsetTop : 0 }}
      >
        {this.renderSearchInput()}
      </div>
    </Modal>
  )

  render() {
    return (
      <>
        <Button
          className="global-search-header__mobile__icon app-header__dropdown__trigger"
          onClick={this.handleToggleMobileSearchModal}
          iconOnly
          reset
        >
          <Icon icon="search" className="app-header__icon" />
        </Button>
        {this.renderSearchModalMobile()}
        <div className="global-search-header__desktop">
          {this.renderSearchInput(true)}
        </div>
      </>
    );
  }
}

GlobalUserSearch.propTypes = {
  clearUserSearch: PropTypes.func.isRequired,
  user: PropTypes.object,
  users: PropTypes.object,
  fetchUserSearch: PropTypes.func.isRequired,
  scope: PropTypes.oneOf(['global', 'nonMembers', 'members']).isRequired,
  page: PropTypes.number,
  searchSize: PropTypes.number,
  userSearchIds: PropTypes.array.isRequired,
  userSearchLoading: PropTypes.bool.isRequired,
  systemAlert: PropTypes.object,
  userSearchSource: PropTypes.string,
  handleToggleGlobalSearchModalWrapper: PropTypes.func,
  showGlobalSearchModalWrapper: PropTypes.bool,
  location: PropTypes.object,
  headerOffsetTop: PropTypes.number,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { user, tag, ui, auth } = state;
  return {
    tags: tag.tags,
    user: getLoggedInUser(state),
    users: user.users,
    uiError: ui.error,
    userSearchIds: user.userSearchIds,
    userSearchLoading: user.userSearchLoading,
    userSearchSource: user.userSearchSource,
    currentOrganization: getCurrentOrg(state),
    contactList: user.contactList,
    contactListLoading: user.contactListLoading,
    systemAlert: getActiveSystemAlert(state),
    currentUserId: auth.currentUser,
    page: user.page,
    searchSize: user.searchSize,
  };
};

const actions = {
  clearUserSearch: UserReducer.clearUserSearch,
  fetchUserSearch: UserReducer.fetchUserSearch,
  fetchUsers: UserReducer.fetchUsers,
  createGroup: GroupReducer.createGroup,
  createContactList: UserReducer.createContactList,
  updateContactList: UserReducer.updateContactList,
  fetchContactList: UserReducer.fetchContactList,
  // updateExistingContactList: UserReducer.updateExistingContactList,
};

export default connect(mapStateToProps, actions)(GlobalUserSearch);
