import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { createCreditCardPaymentToken } from '../reducers/payReducer';
import RhinopayPayment from '../components/RhinopayPayment';
import * as AppConstants from '../constants/AppConstants';
import { StringHelpers } from '../helpers';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import * as RhinopayService from '../services/RhinopayService';
import { removeSpecialCharacters } from '../helpers/StringHelpers';

class RhinopayPaymentContainer extends Component {
  state = {
    bankRoutingNumber: '',
    bankAccountNumber: '',
    cardNumber: '',
    checkNumber: '',
    creditCardType: AppConstants.RHINOPAY_CREDIT_CARD_TYPE_UNKNOWN,
    cvv: '',
    errors: {},
    expirationDate: '',
    isFormSubmissionInProgress: false,
    isPaymentRequestLoading: true,
    isPaymentMade: false,
    isLinkExpired: false,
    nameOnCard: '',
    nameOnCheck: '',
    organizationName: '',
    patientFullName: '',
    patientId: '',
    paymentMethod: 'credit/debit',
    street: '',
    totalPaymentRequestAmount: 0,
    zipcode: '',
    merchantToken: '',
    isAchEnabled: false,
    paymentType: '',
    merchantUUID: '',
    accountType: -1,
  };

  // Ref we will pass down as prop to Rhinopay.jsx in order to reference the form element.
  // The Integrity Pay (IBX) API 'createToken' method requires we send a form element as opposed to a standard data payload.
  creditCardFormRef = React.createRef()
  checkFormRef = React.createRef()

  componentDidMount() {
    document.title = 'Rhinogram | Rhinopay';
    const paymentRequestString = removeSpecialCharacters(this.props.match.params.paymentRequestCode);
    RhinopayService.getPaymentRequestByCode(paymentRequestString)
      .then((response) => {
        if (!response) {
          this.setState({ isLinkExpired: true, isPaymentRequestLoading: false });
        } else {
          const {
            requestAmount,
            userId,
            isPaid,
            firstName,
            lastName,
            orgName,
            uuid,
            merchantToken,
            isACHEnabled,
            paymentType,
            merchantUUID,
          } = response.data;

          this.setState({
            isPaymentRequestLoading: false,
            totalPaymentRequestAmount: requestAmount,
            patientId: userId.toString(),
            isPaymentMade: !!isPaid,
            patientFullName: `${firstName} ${lastName}`,
            organizationName: orgName,
            paymentRequestUUID: uuid,
            merchantToken,
            isAchEnabled: !!isACHEnabled,
            paymentType,
            merchantUUID,
          });
        }
      })
      .catch(() => {
        this.setState({ isLinkExpired: true, isPaymentRequestLoading: false });
      });
  }

  handleChange = (name, value) => {
    if (name === 'paymentMethod') {
      this.resetPayment();
    }
    this.setState({ [name]: value });
  }

  resetPayment = () => {
    this.setState({
      nameOnCard: '',
      cardNumber: '',
      expirationDate: '',
      cvv: '',
      street: '',
      zipcode: '',
      checkNumber: '',
      nameOnCheck: '',
      bankAccountNumber: '',
      bankRoutingNumber: '',
      errors: {},
      accountType: -1,
    });
  }

  breezePaymentSubmission = () => {
    RhinopayService.breezeAuthentication(this.state.merchantUUID)
      .then((response) => {
        let data =
          `password=${response.data.temporaryPassword}&` +
          'contextType=proxynization&' +
          `accountNumber=${this.state.cardNumber}`;
        if (this.state.paymentMethod === 'check') {
          data =
          `password=${response.data.temporaryPassword}&` +
          'contextType=proxynization&' +
          `accountNumber=${this.state.bankAccountNumber}`;
        }
        return RhinopayService.breezeProxynizationAPI(data);
      })
      .then((response) => {
        if (this.state.paymentMethod === 'credit/debit') {
          return this.creditCardSubmissionForBreeze(response.accountNumber);
        } else {
          return this.checkSubmissionForBreeze(response.accountNumber);
        }
      })
      .then((response) => {
        if (response.data.responseCode !== AppConstants.BREEZE_RESPONSE_CODE || response.responseType === AppConstants.BREEZE_EXCEPTION) {
          this.setState({
            isFormSubmissionInProgress: false,
            errors: { paymentError: AppConstants.RHINOPAY_ERROR_MESSAGE },
          });
        } else {
          this.setState({
            isFormSubmissionInProgress: false,
            isPaymentMade: true,
            errors: { paymentError: '' },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          isFormSubmissionInProgress: false,
          errors: { paymentError: error?.error?.message || AppConstants.RHINOPAY_ERROR_MESSAGE },
        });
      });
  }

  creditCardSubmissionForBreeze = (accountNumber) => {
    const data = {
      amount: Math.round(this.state.totalPaymentRequestAmount * 100), // converting amount in cents
      accountNumber: `*${accountNumber}`,
      patientNumber: this.state.patientId,
      paymentRequestUUID: this.state.paymentRequestUUID,
      holderName: this.state.nameOnCard,
      cardExpiryDate: this.state.expirationDate,
    };
    return RhinopayService.postCreditCardPayment(data);
  }

  checkSubmissionForBreeze = (accountNumber) => {
    const data = {
      amount: Math.round(this.state.totalPaymentRequestAmount * 100),
      accountNumber: `*${accountNumber}`,
      patientNumber: this.state.patientId,
      paymentRequestUUID: this.state.paymentRequestUUID,
      holderName: this.state.nameOnCheck,
      routingNumber: this.state.bankRoutingNumber,
      accountType: this.accountTypeOptions.find((option) => option.id === this.state.accountType).code,
    };
    return RhinopayService.postCheckPayment(data);
  }

  creditCardSubmissionForPayroc = (form) => {
    createCreditCardPaymentToken(form)
      .then((response) => {
        const data = {
          amount: this.state.totalPaymentRequestAmount,
          cardToken: response.token,
          patientNumber: this.state.patientId,
          paymentRequestUUID: this.state.paymentRequestUUID,
        };
        return RhinopayService.postCreditCardPayment(data);
      })
      .then((response) => {
        if (!response.data.ProcessCreditCardResult) {
          this.setState({
            isFormSubmissionInProgress: false,
            errors: { paymentError: AppConstants.RHINOPAY_ERROR_MESSAGE },
          });
        } else {
          const isPaymentMade = response.data.ProcessCreditCardResult.Result === 0; // result of 0 means the payment was successful
          this.setState({
            isFormSubmissionInProgress: false,
            isPaymentMade,
            errors: { paymentError: !isPaymentMade ? AppConstants.RHINOPAY_ERROR_MESSAGE : '' },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          isFormSubmissionInProgress: false,
          errors: { paymentError: error?.error?.message || AppConstants.RHINOPAY_ERROR_MESSAGE },
        });
      });
  }

  accountTypeOptions = [
    { id: -1, value: 'Select a type', code: '' },
    { id: 1, value: 'Checking', code: AppConstants.BREEZE_CHECKING_ACCOUNT_TYPE },
    { id: 2, value: 'Savings', code: AppConstants.BREEZE_SAVING_ACCOUNT_TYPE },
  ]

  handleSubmitCreditCardForm = () => {
    if (this.isFormSubmissionInProgress) return; // Do not submit if there is a current submission in progress.

    const errors = this.handleValidation();
    this.setState({ errors });

    // Set errors and exit method if any validation fails.
    if (Object.keys(errors).length) return;

    this.setState({ isFormSubmissionInProgress: true });

    // When pulling the ref we want to clone the node rather than use the direct reference so we can modify values as needed.
    const form = this.creditCardFormRef.current.cloneNode(true);

    // Due to formatting of Credit Card input, we need to trim all whitespace from value before submitting.
    // IBX Service will error out if a CC number is submitted with whitespace between digit blocks.
    const creditCardNumber = form.elements.cardNumber.value;
    form.elements.cardNumber.value = StringHelpers.removeAllWhiteSpace(creditCardNumber);
    // IBX Service will not allow any inputs with 'name' attribute.
    // We wrap nodeList in square brackets to make it an iterable.
    [...form.elements].forEach((element) => element.removeAttribute('name'));

    if (this.state.paymentType === AppConstants.BREEZE_PAYMENT_TYPE) {
      this.breezePaymentSubmission();
    } else {
      this.creditCardSubmissionForPayroc(form);
    }
  };

  checkSubmissionForPayroc = () => {
    const data = {
      amount: this.state.totalPaymentRequestAmount,
      transitNum: this.state.bankRoutingNumber,
      accountNum: this.state.bankAccountNumber,
      nameOnCheck: this.state.nameOnCheck,
      checkNum: this.state.checkNumber,
      patientNumber: this.state.patientId,
      paymentRequestUUID: this.state.paymentRequestUUID,
    };
    RhinopayService.postCheckPayment(data)
      .then((response) => {
        if (!response.data.ProcessCheckResult) {
          this.setState({
            isFormSubmissionInProgress: false,
            errors: { paymentError: AppConstants.RHINOPAY_ERROR_MESSAGE },
          });
        } else {
          const isPaymentMade = response.data.ProcessCheckResult.Result === 0; // result of 0 means the payment was successful
          this.setState({
            isFormSubmissionInProgress: false,
            isPaymentMade,
            errors: { paymentError: !isPaymentMade ? AppConstants.RHINOPAY_ERROR_MESSAGE : '' },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          isFormSubmissionInProgress: false,
          errors: { paymentError: error?.error?.message || AppConstants.RHINOPAY_ERROR_MESSAGE },
        });
      });
  }

  handleSubmitCheckForm = () => {
    if (this.isFormSubmissionInProgress) return;
    const errors = this.handleValidation();
    this.setState({ errors });
    // Set errors and exit method if any validation fails.
    if (Object.keys(errors).length) return;

    this.setState({ isFormSubmissionInProgress: true });

    if (this.state.paymentType === AppConstants.BREEZE_PAYMENT_TYPE) {
      this.breezePaymentSubmission();
    } else {
      this.checkSubmissionForPayroc();
    }
  }

  /**
   * Runs through supplied values and validates them
   * @return {object} Error list with object key matching field name.
   */
  handleValidation = () => {
    let returnVal = {};
    const isPayingWithCard = this.state.paymentMethod === 'credit/debit';
    if (this.state.paymentType === AppConstants.BREEZE_PAYMENT_TYPE) {
      returnVal = isPayingWithCard
        ? ValidationService(ValidationShapers.shapeRhinopayCreditCardPaymentForBreeze(this.state))
        : ValidationService(ValidationShapers.shapeRhinopayCheckPaymentForBreeze(this.state));
    } else {
      returnVal = isPayingWithCard
        ? ValidationService(ValidationShapers.shapeRhinopayCreditCardPayment(this.state))
        : ValidationService(ValidationShapers.shapeRhinopayCheckPayment(this.state));
    }

    if (isPayingWithCard && !this.validateCreditCard(this.state.cardNumber)) {
      returnVal.cardNumber = 'Not a valid credit card number';
    }

    return returnVal;
  }

  // This validator is specific to this container and different from the existing CC validator due to its use of Cleave to format the input.
  validateCreditCard = (cardNumber) => {
    // Remove spaces. CC Number comes with spaces due to input display formatting.
    const cardNumberNoSpaces = StringHelpers.removeAllWhiteSpace(cardNumber);

    // Check that min length is met.
    if (cardNumberNoSpaces.length < AppConstants.CREDIT_CARD_LENGTH) return false;

    // @TODO -- These last two checks have been disabled for the time being
    // because it is throwing errors on good CCs. Needs further investigation.

    // Check that credit card matches major industry identifier (Known CC Provider).
    // if (this.state.creditCardType === AppConstants.RHINOPAY_CREDIT_CARD_TYPE_UNKNOWN) return false;

    // Verify Card Number Checksum with Luhn Algorithm.
    // return StringHelpers.luhnAlgorithm(cardNumberNoSpaces);

    return true;
  };

  handleSetCardType = (cardType) => {
    this.setState({ creditCardType: cardType });
  }

  render() {
    const props = {
      handleSubmitCreditCardForm: this.handleSubmitCreditCardForm,
      handleSubmitCheckForm: this.handleSubmitCheckForm,
      creditCardFormRef: this.creditCardFormRef,
      checkFormRef: this.checkFormRef,
      validateCreditCard: this.validateCreditCard,
      handleChange: this.handleChange,
      handleSetCardType: this.handleSetCardType,
      accountTypeOptions: this.accountTypeOptions,
      ...this.state,
    };

    return (<RhinopayPayment {...props} />);
  }
}

RhinopayPaymentContainer.propTypes = {
  match: PropTypes.object,
};

export default RhinopayPaymentContainer;
