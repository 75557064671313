import PropTypes from 'prop-types';
import React from 'react';
import {
  Input,
  Button,
  LoaderCircle,
  RadioGroup,
  Radio,
  Select,
} from 'rhinostyle';

import rhinopay from '../assets/images/rhinopay.svg';
import { MaskingHelpers, NumberHelpers } from '../helpers';
import EmptyMessage from './EmptyMessage';
import * as AppConstants from '../constants/AppConstants';

const RhinopayPayment = (props) => {
  const {
    creditCardFormRef,
    checkFormRef,
    errors,
    handleSetCardType,
    isAchEnabled,
    isFormSubmissionInProgress,
    isPaymentMade,
    isLinkExpired,
    isPaymentRequestLoading,
    handleSubmitCreditCardForm,
    handleSubmitCheckForm,
    handleChange,
    merchantToken,
    organizationName,
    patientFullName,
    patientId,
    paymentMethod,
    totalPaymentRequestAmount,
    paymentType,
    nameOnCard,
    cardNumber,
    expirationDate,
    cvv,
    street,
    bankRoutingNumber,
    bankAccountNumber,
    nameOnCheck,
    zipcode,
    checkNumber,
    accountType,
    accountTypeOptions,
  } = props;

  const errorList = {
    nameOnCard: errors.nameOnCard || '',
    cardNumber: errors.cardNumber || '',
    expirationDate: errors.expirationDate || '',
    cvv: errors.cvv || '',
    street: errors.street || '',
    zipcode: errors.zipcode || '',
    bankAccountNumber: errors.bankAccountNumber || '',
    bankRoutingNumber: errors.bankRoutingNumber || '',
    checkNumber: errors.checkNumber || '',
    nameOnCheck: errors.nameOnCheck || '',
    paymentError: errors.paymentError || '',
    accountType: errors.accountType || '',
  };

  const renderCreditCardForm = () => {
    if (paymentType === AppConstants.BREEZE_PAYMENT_TYPE) {
      return (
        <form id="cc-form" className="form__group" ref={creditCardFormRef}>
          <div className="form__group">
            <div className="row">
              <div className="column-6@xsmall">
                <Input
                  validationMessage={errorList.nameOnCard}
                  type="text"
                  name="nameOnCard"
                  customHTMLAttributes={{ 'data-ibx': 'nameOnCard' }}
                  label="Name on Card"
                  required
                  onChange={handleChange}
                  initialValue={nameOnCard}
                  dataFeatureTag="nameOnCard"
                />
              </div>
            </div>
          </div>
          <div className="form__group">
            <div className="row">
              <div className="column-6@xsmall">
                <Input
                  validationMessage={errorList.cardNumber}
                  type="text"
                  name="cardNumber"
                  format={{ creditCard: true, onCreditCardTypeChanged: handleSetCardType }}
                  customHTMLAttributes={{ 'data-ibx': 'cardNumber' }}
                  label="Card Number"
                  required
                  onChange={handleChange}
                  initialValue={cardNumber}
                  dataFeatureTag="cardNumber"
                />
              </div>
              <div className="column-3@xsmall">
                <Input
                  validationMessage={errorList.expirationDate}
                  type="text"
                  format={{ date: true, datePattern: ['m', 'y'] }}
                  placeholder="MM/YY"
                  name="expirationDate"
                  customHTMLAttributes={{ 'data-ibx': 'expirationDate' }}
                  label="Expiration"
                  required
                  onChange={handleChange}
                  initialValue={expirationDate}
                  dataFeatureTag="cardExpiration"
                />
              </div>
            </div>
          </div>
          {/* Hidden Inputs with additional data for IBX Payment Processor */}
          <input
            type="hidden"
            value={merchantToken}
            data-ibx="merchantToken"
          />
          <input
            type="hidden"
            value={totalPaymentRequestAmount}
            data-ibx="amount"
          />
          {/* End hidden inputs */}
          <div className="u-text-danger u-m-t">{errorList.paymentError}</div>
          <div className="form__group u-m-t-large u-text-right">
            <Button
              size="large"
              loading={isFormSubmissionInProgress}
              type="primary"
              onClick={handleSubmitCreditCardForm}
              data-cypress="submitCardPayment"
            >
              Submit Payment
            </Button>
          </div>
        </form>
      );
    } else {
      return (
        <form id="cc-form" className="form__group" ref={creditCardFormRef}>
          <div className="form__group">
            <div className="row">
              <div className="column-6@xsmall">
                <Input
                  validationMessage={errorList.nameOnCard}
                  type="text"
                  name="nameOnCard"
                  customHTMLAttributes={{ 'data-ibx': 'nameOnCard' }}
                  label="Name on Card"
                  required
                  onChange={handleChange}
                  initialValue={nameOnCard}
                  dataFeatureTag="nameOnCard"
                />
              </div>
            </div>
          </div>
          <div className="form__group">
            <div className="row">
              <div className="column-6@xsmall">
                <Input
                  validationMessage={errorList.cardNumber}
                  type="text"
                  name="cardNumber"
                  format={{ creditCard: true, onCreditCardTypeChanged: handleSetCardType }}
                  customHTMLAttributes={{ 'data-ibx': 'cardNumber' }}
                  label="Card Number"
                  required
                  onChange={handleChange}
                  initialValue={cardNumber}
                  dataFeatureTag="cardNumber"
                />
              </div>
              <div className="column-3@xsmall">
                <Input
                  validationMessage={errorList.expirationDate}
                  type="text"
                  format={{ date: true, datePattern: ['m', 'y'] }}
                  placeholder="MM/YY"
                  name="expirationDate"
                  customHTMLAttributes={{ 'data-ibx': 'expirationDate' }}
                  label="Expiration"
                  required
                  onChange={handleChange}
                  initialValue={expirationDate}
                  dataFeatureTag="cardExpiration"
                />
              </div>
              <div className="column-3@xsmall">
                <Input
                  validationMessage={errorList.cvv}
                  format={{ numericOnly: true }}
                  type="text"
                  name="cvv"
                  customHTMLAttributes={{ 'data-ibx': 'cvv' }}
                  label="CVV"
                  required
                  onChange={handleChange}
                  initialValue={cvv}
                  dataFeatureTag="cardCvv"
                />
              </div>
            </div>
          </div>
          <div className="form__group">
            <div className="row">
              <div className="column-8@xsmall">
                <Input
                  validationMessage={errorList.street}
                  type="text"
                  name="street"
                  customHTMLAttributes={{ 'data-ibx': 'street' }}
                  label="Billing Street"
                  onChange={handleChange}
                  required
                  initialValue={street}
                  dataFeatureTag="cardBillingAddress"
                />
              </div>
              <div className="column-4@xsmall">
                <Input
                  validationMessage={errorList.zipcode}
                  format={MaskingHelpers.zipCode}
                  name="zipcode"
                  customHTMLAttributes={{ 'data-ibx': 'zipcode' }}
                  label="Zip Code"
                  onChange={handleChange}
                  required
                  initialValue={zipcode}
                  dataFeatureTag="cardZip"
                />
              </div>
            </div>
          </div>
          {/* Hidden Inputs with additional data for IBX Payment Processor */}
          <input
            type="hidden"
            value={merchantToken}
            data-ibx="merchantToken"
          />
          <input
            type="hidden"
            value={totalPaymentRequestAmount}
            data-ibx="amount"
          />
          {/* End hidden inputs */}
          <div className="u-text-danger u-m-t">{errorList.paymentError}</div>
          <div className="form__group u-m-t-large u-text-right">
            <Button
              size="large"
              loading={isFormSubmissionInProgress}
              type="primary"
              onClick={handleSubmitCreditCardForm}
              data-cypress="submitCardPayment"
            >
              Submit Payment
            </Button>
          </div>
        </form>
      );
    }
  };

  const checkNumberField = () => (
    <div className="column-6@xsmall">
      <Input
        validationMessage={errorList.checkNumber}
        type="text"
        name="checkNumber"
        customHTMLAttributes={{ 'data-ibx': 'checkNumber' }}
        label="Check Number"
        required
        onChange={handleChange}
        initialValue={checkNumber}
      />
    </div>
  );

  const renderCheckForm = () => (
    <form id="check-form" className="form__group" ref={checkFormRef}>
      <div className="form__group">
        <div className="row">
          {paymentType === AppConstants.BREEZE_PAYMENT_TYPE && (
            <div className="column-4@xsmall">
              <Select
                onSelect={handleChange}
                name="accountType"
                selected={accountType}
                validationMessage={errorList.accountType}
                label="Account Type"
                required
                options={accountTypeOptions}
              />
            </div>
          )}
          <div className="column-6@xsmall">
            <Input
              validationMessage={errorList.nameOnCheck}
              type="text"
              name="nameOnCheck"
              customHTMLAttributes={{ 'data-ibx': 'nameOnCheck' }}
              label={paymentType === AppConstants.BREEZE_PAYMENT_TYPE ? 'Name on Account' : 'Name on Check'}
              required
              onChange={handleChange}
              initialValue={nameOnCheck}
            />
          </div>
          {paymentType !== AppConstants.BREEZE_PAYMENT_TYPE ? checkNumberField() : null}
        </div>
      </div>
      <div className="form__group">
        <div className="row">
          <div className="column-6@xsmall">
            <Input
              validationMessage={errorList.bankAccountNumber}
              type="text"
              name="bankAccountNumber"
              customHTMLAttributes={{ 'data-ibx': 'bankAccountNumber' }}
              label="Account Number"
              required
              onChange={handleChange}
              initialValue={bankAccountNumber}
            />
          </div>
          <div className="column-6@xsmall">
            <Input
              validationMessage={errorList.bankRoutingNumber}
              type="text"
              name="bankRoutingNumber"
              customHTMLAttributes={{ 'data-ibx': 'bankRoutingNumber' }}
              label="Routing Number"
              required
              onChange={handleChange}
              initialValue={bankRoutingNumber}
            />
          </div>
        </div>
      </div>
      <div className="u-text-danger u-m-t">{errorList.paymentError}</div>
      <div className="form__group u-m-t-large u-text-right">
        <Button size="large" loading={isFormSubmissionInProgress} type="primary" onClick={handleSubmitCheckForm}>
          Submit Payment
        </Button>
      </div>
    </form>
  );

  return (
    <div className="app-reactroot">
      <div className="rhinopay" data-vh="100">
        <div className="rhinopay__content">
          <div className="rhinopay__content__container">
            <div className="box">
              <div className="box__title-wrapper u-m-b-small">
                <img src={rhinopay} alt="RhinoPay" className="rhinopay__logo-img" />
              </div>
              {isPaymentRequestLoading ? (
                <div className="u-text-center u-m-a-large">
                  <LoaderCircle size="large" className="u-text-primary" />
                </div>
              ) : (
                <>
                  {isLinkExpired && (
                    <EmptyMessage section="Rhinopay Link Expired" />
                  )}
                  {isPaymentMade && !isLinkExpired && (
                    <EmptyMessage section="Rhinopay Success" dataCypressAttr="paymentMade" />
                  )}
                  {!isPaymentMade && !isLinkExpired && (
                    <>
                      <div className="box__title-wrapper u-m-t">
                        <div className="box__title">Payment Request from {organizationName}</div>
                      </div>
                      <div className="form__group">
                        <p className="u-m-b-0">{patientFullName} (#{patientId})</p>
                        <p className="u-m-t-small">Amount due: ${NumberHelpers.formatNumberToCurrencyString(totalPaymentRequestAmount)}</p>
                        {isAchEnabled && (
                          <div className="row">
                            <div className="column-6@xsmall">
                              <RadioGroup
                                inline
                                name="paymentMethod"
                                label="Payment Method"
                                onChange={(value) => handleChange('paymentMethod', value)}
                                selectedValue="credit/debit"
                              >
                                <Radio value="credit/debit" label="Credit/Debit" />
                                <Radio value="check" label={paymentType === AppConstants.BREEZE_PAYMENT_TYPE ? 'ACH' : 'Check'} />
                              </RadioGroup>
                            </div>
                          </div>
                        )}
                      </div>
                      {paymentMethod === 'credit/debit' ? renderCreditCardForm() : renderCheckForm()}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="rhinopay__footer">
          <div className="rhinopay__footer__branding">
            Powered by <a href="https://www.rhinogram.com" target="_blank" rel="noopener noreferrer" className="u-text-underline">Rhinogram</a>
            {paymentType === AppConstants.BREEZE_PAYMENT_TYPE &&
            <span> and <a href="https://www.paywithbreeze.com/rhinogram" target="_blank" rel="noopener noreferrer" className="u-text-underline">BreezePay</a></span>}
          </div>
        </div>
      </div>
    </div>
  );
};

RhinopayPayment.propTypes = {
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSetCardType: PropTypes.func.isRequired,
  handleSubmitCreditCardForm: PropTypes.func.isRequired,
  handleSubmitCheckForm: PropTypes.func.isRequired,
  isAchEnabled: PropTypes.bool.isRequired,
  isFormSubmissionInProgress: PropTypes.bool.isRequired,
  isPaymentRequestLoading: PropTypes.bool.isRequired,
  merchantToken: PropTypes.string.isRequired,
  organizationName: PropTypes.string.isRequired,
  patientFullName: PropTypes.string.isRequired,
  paymentMethod: PropTypes.oneOf(['credit/debit', 'check']).isRequired,
  totalPaymentRequestAmount: PropTypes.number.isRequired,
  patientId: PropTypes.string.isRequired,
  creditCardFormRef: PropTypes.instanceOf(Object).isRequired,
  checkFormRef: PropTypes.instanceOf(Object).isRequired,
  isPaymentMade: PropTypes.bool.isRequired,
  isLinkExpired: PropTypes.bool.isRequired,
  paymentType: PropTypes.string.isRequired,
  nameOnCard: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  cvv: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  bankRoutingNumber: PropTypes.string.isRequired,
  bankAccountNumber: PropTypes.string.isRequired,
  nameOnCheck: PropTypes.string.isRequired,
  zipcode: PropTypes.string.isRequired,
  checkNumber: PropTypes.string.isRequired,
  accountType: PropTypes.number.isRequired,
  accountTypeOptions: PropTypes.array.isRequired,
};

export default RhinopayPayment;
