import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Icon, Button } from 'rhinostyle';
import { setParticipantsPanelOpen } from '../reducers/rhinovideoReducer';

const RhinovideoToggleMicrophoneButton = (props) => {
  const { isParticipantsPanelOpen } = props;
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(setParticipantsPanelOpen(!isParticipantsPanelOpen));
  };

  return (
    <div>
      <Button
        type="link"
        size="large"
        iconOnly
        title="Participants"
        onClick={handleToggle}
        className={`rhinovideo__action-bar__button ${isParticipantsPanelOpen ? 'is-panel-open' : ''}`}
      >
        <Icon className="rhinovideo__action-bar__participants-button" icon="user-group" />
      </Button>
      <div className="rhinovideo__action-bar__label">
        <span>Participants</span>
      </div>
    </div>
  );
};

RhinovideoToggleMicrophoneButton.propTypes = {
  isParticipantsPanelOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { rhinovideo } = state;

  return ({
    isParticipantsPanelOpen: rhinovideo.isParticipantsPanelOpen,
  });
};

export default connect(mapStateToProps)(RhinovideoToggleMicrophoneButton);
