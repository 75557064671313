import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { getObjectKeys, mergeShallow } from '../helpers/DataHelpers';

import { setError } from './uiReducer';
import NotificationService from '../services/NotificationService';
import { APPOINTMENTS_PAGE_SIZE } from '../constants/AppConstants';
import { appointmentCampaign } from '../actions/NormalizrSchema';

const appointmentRemindersSlice = createSlice({
  name: 'APPOINTMENTREMINDERS',
  initialState: {
    appointmentCampaigns: {},
    appointmentCampaignIds: [],
    loading: true,
  },
  reducers: {
    receiveAppointmentCampaigns: (state, action) => ({
      ...state,
      loading: false,
      appointmentCampaigns: mergeShallow(state.appointmentCampaigns, action.payload.appointmentCampaigns),
      appointmentCampaignIds: action.payload.pageNumber === 0 ?
        action.payload.appointmentCampaignIds : [...new Set([...state.appointmentCampaignIds, ...action.payload.appointmentCampaignIds])],
      pageNumber: action.payload.pageNumber,
    }),
    receiveAppointmentCampaign: (state, action) => ({
      ...state,
      appointmentCampaigns: {
        ...state.appointmentCampaigns,
        [action.payload.appointmentCampaignId]: action.payload.appointmentCampaign[action.payload.appointmentCampaignId],
      },
      appointmentCampaignIds: [...new Set([...state.appointmentCampaignIds, action.payload.appointmentCampaignId])],
    }),
    requestData: (state) => ({
      ...state,
      loading: true,
    }),
    receiveError: (state) => ({
      ...state,
      loading: false,
    }),
  },
});

export default appointmentRemindersSlice.reducer;

// ACTIONS
export const { receiveAppointmentCampaign, receiveAppointmentCampaigns, requestData, receiveError } = appointmentRemindersSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

export function fetchAppointmentCampaigns({ orgId, pageNumber = 0, pageSize = APPOINTMENTS_PAGE_SIZE, templateId }) {
  return (dispatch) => {
    dispatch(requestData());
    return getAppointmentCampaigns({ orgId, pageNumber, pageSize, templateId })
      .then((response) => {
        const normalizedAppointmentCampaigns = normalize(response.data, [appointmentCampaign]);
        dispatch(receiveAppointmentCampaigns(getAppointmentCampaignsPayload(normalizedAppointmentCampaigns, pageNumber)));
      }).catch((err) => {
        dispatch(receiveError());
        dispatch(setError(err));
      });
  };
}

export function createAppointmentCampaign(orgId, payload) {
  return (dispatch) => postAppointmentCampaign(orgId, payload)
    .then((response) => {
      const normalizedAppointmentCampaign = normalize(response.data, appointmentCampaign);
      dispatch(receiveAppointmentCampaign(getAppointmentCampaignPayload(normalizedAppointmentCampaign)));
      NotificationService('createAppointmentCampaign', response);
      return response;
    }).catch((err) => {
      const messages = err?.response?.data?.message;
      if (Array.isArray(messages)) {
        messages.forEach((message) => {
          const errorData = {
            ...err.response,
            data: {
              ...err.response?.data,
              message,
            },
          };
          NotificationService('createAppointmentCampaign', errorData, null, true);
        });
      } else {
        NotificationService('createAppointmentCampaign', err.response, null, true);
      }
      dispatch(receiveError());
      return err.response;
    });
}

export function updateAppointmentCampaign(orgId, payload) {
  return (dispatch) => patchAppointmentCampaign(orgId, payload)
    .then((response) => {
      NotificationService('updateAppointmentCampaign', response);
      return response;
    }).catch((err) => {
      const messages = err?.response?.data?.message;
      if (Array.isArray(messages)) {
        messages.forEach((message) => {
          const errorData = {
            ...err.response,
            data: {
              ...err.response?.data,
              message,
            },
          };
          NotificationService('updateAppointmentCampaign', errorData, null, true);
        });
      } else {
        NotificationService('updateAppointmentCampaign', err.response, null, true);
      }
      dispatch(receiveError());
      return err.response;
    });
}

export function deleteAppointmentCampaign(orgId, templateId) {
  return (dispatch) => deleteAppointmentCampaignById(orgId, templateId)
    .then((response) => {
      NotificationService('deleteAppointmentCampaign', response);
      return response;
    }).catch((err) => {
      NotificationService('deleteAppointmentCampaign', err.response);
      dispatch(receiveError());
      dispatch(setError(err));
    });
}

function getAppointmentCampaigns({ orgId, pageSize, templateId, pageNumber }) {
  return axios.get(`/appointment/${orgId}/templates?page=${pageNumber}&pageSize=${pageSize}`, {
    params: {
      orgId,
      id: templateId,
    },
  });
}

function postAppointmentCampaign(orgId, payload) {
  return axios.post(`/appointment/${orgId}/templates`, payload);
}

function patchAppointmentCampaign(orgId, payload) {
  return axios.patch(`/appointment/${orgId}/templates/${payload.id}`, payload);
}
function deleteAppointmentCampaignById(orgId, templateId) {
  return axios.delete(`/appointment/${orgId}/templates/${templateId}`);
}

function getAppointmentCampaignPayload(normalizedAppointmentCampaign) {
  return {
    appointmentCampaign: normalizedAppointmentCampaign.entities.appointmentCampaigns,
    appointmentCampaignId: normalizedAppointmentCampaign.result,
    appointmentTypeIds: getObjectKeys(normalizedAppointmentCampaign.entities.appointmentTypes),
    appointmentTypes: {
      ...normalizedAppointmentCampaign.entities.appointmentTypes,
    },
  };
}

function getAppointmentCampaignsPayload(normalizedAppointmentCampaigns, pageNumber) {
  return {
    appointmentCampaigns: {
      ...normalizedAppointmentCampaigns.entities.appointmentCampaigns,
    },
    appointmentCampaignIds: normalizedAppointmentCampaigns.result,
    appointmentTypeIds: getObjectKeys(normalizedAppointmentCampaigns.entities.appointmentTypes),
    appointmentTypes: {
      ...normalizedAppointmentCampaigns.entities.appointmentTypes,
    },
    pageNumber,
  };
}
