import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  ResourceGroup,
  Button,
  Scrollbars,
  Icon,
} from 'rhinostyle';
import cx from 'classnames';

import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import NotificationService from '../services/NotificationService';
import EmptyMessage from './EmptyMessage';
import { getLoggedInUserPermissionNames } from '../selectors/userSelectors';
import { ROUTING_MANAGER_EDIT } from '../constants/UserPermissionsConstants';
import PageLoader from './PageLoader';
import RoutingManagerSummaryPanel from './RoutingManagerSummaryPanel';
import { useGetRoutesQuery } from '../services/routingService';
import TextClassificationRoute from './TextClassificationRoute';
import { QUERY_STATUS_FULFILLED } from '../constants/v3';

const RoutingManager = (props) => {
  const params = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const {
    currentOrganizationId,
    loggedInUserPermissions,
  } = props;
  const query = useGetRoutesQuery({ organizationId: currentOrganizationId });
  const { data: categoryRoutes, error, status } = query;

  useEffect(() => {
    if (status === QUERY_STATUS_FULFILLED) {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (error) {
      NotificationService('fetchRoutes', error);
    }
  }, [error]);

  const handleCreateClick = () => {
    history.push('/managers/routing-manager/create');
  };

  const renderRoutes = () => (
    <ResourceGroup>
      {categoryRoutes?.textclassificationroutes.map((categoryRoute) => (
        <TextClassificationRoute key={`routingCategory-${categoryRoute.id}`} textClassificationRouteId={categoryRoute.id} />
      ))}
    </ResourceGroup>
  );

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': params.categoryRouteId,
  });

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="list-panel__body" data-feature-tag="routingCategoryList">
              <Scrollbars className="library-scroll__container">
                <div className="list-panel">
                  <div className="app-page__header">
                    <div className="app-page__header__title">
                      Routing Manager
                    </div>
                    {loggedInUserPermissions.includes(ROUTING_MANAGER_EDIT) && (
                      <div className="app-page__header__action">
                        <Button
                          onClick={handleCreateClick}
                          iconOnly
                          type="secondary"
                          title="Create Route"
                          data-feature-tag="routing-manager_create-route"
                        >
                          <Icon icon="add" />
                        </Button>
                      </div>
                    )}
                  </div>
                  {categoryRoutes?.textclassificationroutes?.length > 0 ? renderRoutes() : <EmptyMessage section="Routing Manager" />}
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
        {params.categoryRouteId && (
          <RoutingManagerSummaryPanel
            textClassificationRouteId={Number(params.categoryRouteId)}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { routing } = state;
  return {
    currentOrganizationId: state.auth.currentOrg,
    loggedInUserPermissions: getLoggedInUserPermissionNames(state),
    categories: routing.categories, // Move these out
  };
};

RoutingManager.propTypes = {
  currentOrganizationId: PropTypes.number,
  loggedInUserPermissions: PropTypes.array,
};

export default connect(mapStateToProps)(RoutingManager);
