import React from 'react';
import { Button, Alert } from 'rhinostyle';
import AuthService from '../services/AuthService';
import PageLoader from './PageLoader';
import { LocationHelpers } from '../helpers';
import StorageService from '../services/StorageService';
import rhinoLogoReversed from '../assets/images/rhinogram-logo-reversed.svg';

const MASTER = 'app';
const STAGING = 'staging';
const DEVELOP = 'app';

class AuthLogin extends React.Component {
  state = {
    isSSO: false,
    subdomain: '',
    isLoading: true,
    isError: false,
    errorMessage: '',
  };

  componentDidMount() {
    const params = LocationHelpers.getQueryParams(window.location.search);

    // check the subdomain of the URL used to reach the site to check if it is indicating a custom
    // identity provider should be used like <idp name>.rhinogram.com. Ignore the subdomains we already use
    // by default such as app staging and dev which are used in prod staging and develop respectively
    let subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : '';
    if (subdomain === MASTER || subdomain === STAGING || subdomain === DEVELOP) {
      subdomain = '';
    }
    this.setState({ subdomain });

    // the default IDP being set
    StorageService.createEntry('auth-connection', process.env.REACT_APP_AUTH0_CONNECTION);
    StorageService.createEntry('isSSO', false);

    if (!StorageService.readEntry('isSSO')) {
      // if not sso they just redirect to auth0 login page
      this.setState({ isSSO: false });
      this.handleLogin();
    } else {
      // if sso render a button to start the redirect process
      // eslint-disable-next-line no-lonely-if
      if (!params.logout_message) {
        this.setState({ isSSO: true, isLoading: false, subdomain });
      } else {
        this.setState({ isSSO: true, isLoading: false, subdomain, isError: true, errorMessage: params.logout_message });
      }
    }
  }

  handleLogin = () => {
    // if logout_message is set, pass to oAuth for handling logout errors
    const params = LocationHelpers.getQueryParams(window.location.search);
    sessionStorage.setItem('redirect_url', params.path);

    // set the state to loading to show spinner then kick off the redirect to sso login
    this.setState({ isLoading: true });
    AuthService.login(params.logout_message);
  };

  render() {
    if (!this.state.isSSO || this.state.isLoading) {
      return (
        <div className="app-login-wrapper">
          <div className="app-login" data-vh="100">
            <PageLoader />
          </div>
        </div>
      );
    } else if (this.state.isSSO && !this.state.isLoading) {
      return (
        <div className="app-login-wrapper">
          <div className="app-login" data-vh="100">
            <div className="login-redirect__container">
              <div className="app-login__logo">
                <img src={rhinoLogoReversed} alt="Rhinogram" />
              </div>
              <div className="login-redirect__header__title">Rhinogram {this.state.subdomain} Single Sign On Login</div>
              <div>Hi there, you can access Rhinogram with your {this.state.subdomain} credentials. Click below to login.</div>
              <Button
                onClick={this.handleLogin}
                className="u-m-t-large"
                type="outline-reversed"
              >
                Log In Here
              </Button>
              {this.state.isError && (
                <Alert solid type="danger" className="u-m-t-large u-text-center">Error: {this.state.errorMessage}</Alert>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="app-login-wrapper">
          <div className="app-login" data-vh="100">
            <PageLoader />
          </div>
        </div>
      );
    }
  }
}

export default AuthLogin;
