import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { LocationHelpers, BulkActionHelpers } from '../helpers';
import InboxMention from '../components/InboxMention';
import * as UIReducer from '../reducers/uiReducer';
import * as InboxReducer from '../reducers/inboxReducer';
import * as ChannelReducer from '../reducers/channelReducer';
import { userSelectors, inboxSelectors, organizationSelectors } from '../selectors';
import { THREAD_SIZE, conversationTypeList } from '../constants/AppConstants';
import { userHasAnyOfPermissions, hasAnyInboxViewPermissions } from '../helpers/UserHelpers';
import { CHANNEL_VIEW } from '../constants/UserPermissionsConstants';

class InboxContainer extends Component {
  scrollPosition = 'top';  //eslint-disable-line
  static initialState = {
    activeKey: 0,
    checkboxClassName: '',
    formInProgress: false,
    isChecked: false,
    labelValueAssociated: '',
    pageItemIndexFrom: 0,
    pageItemIndexTo: 0,
    selectedIdCount: 0,
    selectedInboxMentionIds: [],
  }
  state = {
    ...InboxContainer.initialState,
    bulkActionsList: [],
    bulkSelectionList: [],
    currentPageNumber: 1,
    nextDisabled: false,
    pageLoading: true,
    pageNumber: 0,
    previousDisabled: true,
    totalInboxCount: 0,
    totalPageCount: 0,
  }

  componentDidMount() {
    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
    const locationState = this.props.location && this.props.location.state;
    const selectedIds = locationState?.selectedInboxMentionIds || [];

    if (locationState && locationState.pageNumber >= 0 && locationState.currentPageNumber) {
      this.setState({
        currentPageNumber: locationState.currentPageNumber,
        pageNumber: locationState.pageNumber,
        previousDisabled: (locationState.pageNumber === 0),
        totalPageCount: locationState.totalPageCount,
        nextDisabled: (locationState.pageNumber + 1 >= locationState.totalPageCount),
        selectedInboxMentionIds: [...selectedIds],
        isChecked: locationState.isChecked,
        labelValueAssociated: locationState.labelValueAssociated,
        checkboxClassName: locationState.checkboxClassName,
        selectedIdCount: locationState.selectedIdCount,
        bulkActionsList: locationState.bulkActionsList,
      });
    }
    options.pageNumber = locationState && locationState.pageNumber;
    const bulkSelectionList = BulkActionHelpers.fetchBulkSelectionList(options);
    const updateState = { bulkSelectionList };
    if (!this.props.location.state) {
      updateState.isChecked = false;
      updateState.selectedInboxMentionIds = [];
      updateState.labelValueAssociated = '';
    }
    this.setState(updateState, () => {
      if (this.props.location.state) {
        this.props.history.replace({ ...this.props.location, state: null });
      }
    });

    this.props.resetEventData();
    this.props.requestInboxView();

    if (hasAnyInboxViewPermissions()) this.props.fetchInboxSections();
    if (userHasAnyOfPermissions([CHANNEL_VIEW])) this.props.fetchChannels();
    this.props.fetchInbox(options);
  }

  getToIndexForInboxIds() {
    const ids = this.props.mentionIds;
    let toIndex = this.props.inboxPageNo * THREAD_SIZE + ids.length;
    if ((this.props.inboxPageNo * THREAD_SIZE + ids.length) > THREAD_SIZE && this.props.inboxPageNo === 0) {
      toIndex = THREAD_SIZE;
    }
    return toIndex;
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.mentionIds.length !== this.props.mentionIds.length) && this.props.mentionIds.length) {
      this.scrollTo();
    }

    if (this.props.pageLoading !== prevProps.pageLoading) {
      this.setState({ pageLoading: this.props.pageLoading }); // eslint-disable-line react/no-did-update-set-state
    }

    if (this.props.mentionIds.length && this.props.mentionIds.length !== prevProps.mentionIds.length) {
      const toIndex = this.getToIndexForInboxIds();
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        pageItemIndexFrom: 1 + this.props.inboxPageNo * THREAD_SIZE,
        pageItemIndexTo: toIndex,
      });
    }

    if (this.props.inboxSections?.length) {
      const mentionsInbox = this.props.inboxSections.find((inboxSection) => inboxSection.type === 'mentions');
      const totalCount = mentionsInbox?.totalCount;
      const totalPages = totalCount && Math.ceil(totalCount / THREAD_SIZE);
      if (totalCount !== this.state.totalInboxCount || this.props.inboxLoading !== prevProps.inboxLoading) {
        this.setState((state, props) => ({ // eslint-disable-line react/no-did-update-set-state
          totalInboxCount: totalCount,
          totalPageCount: totalPages,
          previousDisabled: props.inboxLoading || (state.pageNumber === 0),
          nextDisabled: props.inboxLoading || (state.pageNumber + 1 >= totalPages),
          pageItemIndexFrom: 1 + this.props.inboxPageNo * THREAD_SIZE,
          pageItemIndexTo: this.getToIndexForInboxIds(),
        }));
      }
    }
  }

  onSelect = (item) => {
    const mentions = Object.values(this.props.eventMentions);

    let { selectedInboxMentionIds } = this.state;
    const { mentionIds, match, location } = this.props;
    const options = LocationHelpers.getInboxOptions(match.params, location);

    let selectedIds = [];
    if (item.label === conversationTypeList[0].label) {
      selectedIds = mentionIds;
    } else if (item.label === conversationTypeList[2].label) {
      selectedIds = mentions.filter((mention) => mention?.isRead).map((result) => result.id);
    } else if (item.label === conversationTypeList[3].label) {
      selectedIds = mentions.filter((mention) => !mention?.isRead).map((result) => result.id);
    }

    let noneThreadIds = [];
    if (item.label === conversationTypeList[1].label) {
      noneThreadIds = mentionIds.filter((id) => selectedInboxMentionIds.includes(id));
      selectedInboxMentionIds = selectedInboxMentionIds.filter((id) => !noneThreadIds.includes(id));
    } else {
      selectedInboxMentionIds = selectedInboxMentionIds.filter((id) => !mentionIds.includes(id));
      const newSelectedList = selectedIds.filter((id) => !selectedInboxMentionIds.includes(id));
      selectedInboxMentionIds = [...selectedInboxMentionIds, ...newSelectedList];
    }

    let { bulkActionsList, activeKey, selectedIdCount, labelValueAssociated, checkboxClassName } = this.state;
    let { isChecked } = item;

    const { isCloseMentionsForAllMembersEnabled } = this.props.currentOrg;
    bulkActionsList = BulkActionHelpers.fetchBulkActions(item.label, options, false, isCloseMentionsForAllMembersEnabled, selectedInboxMentionIds);
    selectedIdCount = selectedIds.length;
    labelValueAssociated = `${selectedInboxMentionIds.length} Selected`;
    checkboxClassName = (selectedIdCount !== mentions.length) ? 'partially-checked' : '';
    activeKey = item.id;

    if (selectedIdCount === 0) {
      checkboxClassName = '';
      isChecked = false;
      activeKey = 0;
      labelValueAssociated = selectedInboxMentionIds.length > 0 ? `${selectedInboxMentionIds.length} Selected` : '';
    }

    this.setState({
      isChecked,
      bulkActionsList,
      selectedInboxMentionIds,
      selectedIdCount,
      labelValueAssociated,
      checkboxClassName,
      activeKey,
    });
  }

  onChange = () => {
    const {
      isChecked,
      selectedInboxMentionIds,
    } = this.state;

    let newSelectedMentionIds = [];

    const { isCloseMentionsForAllMembersEnabled } = this.props.currentOrg;

    if (isChecked) {
      const { mentionIds } = this.props;
      const currentSelectedList = mentionIds.filter((item) => selectedInboxMentionIds.includes(item));
      newSelectedMentionIds = selectedInboxMentionIds.filter((mentionId) => !currentSelectedList.includes(mentionId));

      const dropdownCheckLabel = newSelectedMentionIds.length > 0 ? `${newSelectedMentionIds.length} Selected` : '';
      let { bulkActionsList } = this.state;

      const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
      bulkActionsList = BulkActionHelpers.fetchBulkActions('All', options, false, isCloseMentionsForAllMembersEnabled, newSelectedMentionIds);
      this.setState({
        activeKey: 0,
        bulkActionsList,
        checkboxClassName: '',
        labelValueAssociated: dropdownCheckLabel,
        selectedIdCount: 0,
        selectedInboxMentionIds: newSelectedMentionIds,
      });
    } else {
      const { mentionIds } = this.props;
      newSelectedMentionIds = [...new Set([...mentionIds, ...selectedInboxMentionIds])];
      let { bulkActionsList } = this.state;
      const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
      bulkActionsList = BulkActionHelpers.fetchBulkActions('All', options, false, isCloseMentionsForAllMembersEnabled, newSelectedMentionIds);

      this.setState({
        labelValueAssociated: `${newSelectedMentionIds.length} Selected`,
        selectedInboxMentionIds: newSelectedMentionIds,
        selectedIdCount: newSelectedMentionIds.length,
        bulkActionsList,
        activeKey: 1,
      });
    }
    this.setState((prevState) => ({ isChecked: !prevState.isChecked }));
  }

  calculateParameters = (pageNumber, currentPageNumber, totalPageCount, totalInboxCount, selectedMentionIds) => {
    const newTotalInboxCount = totalInboxCount - selectedMentionIds.length;
    const newTotalPageCount = newTotalInboxCount && Math.ceil(newTotalInboxCount / THREAD_SIZE);
    let newPageNumber = 0;
    let newCurrentPageNumber = 0;

    if (newTotalInboxCount < totalInboxCount) {
      const updatedPageNumber = pageNumber - (totalPageCount - newTotalPageCount);

      newPageNumber = updatedPageNumber > 0 ? updatedPageNumber : 0;
      newCurrentPageNumber = newPageNumber + 1;
    } else {
      newPageNumber = pageNumber > 1 ? pageNumber - 1 : 0;
      newCurrentPageNumber = currentPageNumber > 2 ? currentPageNumber - 1 : 1;
    }
    return {
      newTotalInboxCount,
      newTotalPageCount,
      newPageNumber,
      newCurrentPageNumber,
    };
  }

  handleCheckboxOnMentionItem = (mention) => {
    const mentionId = mention.id;
    const selectedMentionIds = this.state.selectedInboxMentionIds;
    const { mentionIds } = this.props;
    let { bulkActionsList } = this.state;
    const { eventMentions } = this.props;
    let { selectedIdCount, checkboxClassName } = this.state;

    if (!selectedMentionIds.includes(mentionId)) {
      selectedIdCount += 1;
      selectedMentionIds.push(mentionId);
    } else {
      selectedIdCount -= 1;
      const mentionIndex = selectedMentionIds.indexOf(mentionId);
      selectedMentionIds.splice(mentionIndex, 1);
    }

    if (selectedIdCount === 0) {
      this.setState({
        isChecked: false,
        labelValueAssociated: selectedMentionIds.length > 0 ? `${selectedMentionIds.length} Selected` : '',
        bulkActionsList: selectedMentionIds.length > 0 ? this.state.bulkActionsList : [],
        checkboxClassName: '',
        activeKey: 0,
      });
    } else {
      const currentPageSelectedThreads = selectedMentionIds.filter((id) => mentionIds.includes(id));
      checkboxClassName = currentPageSelectedThreads.length !== eventMentions.length && currentPageSelectedThreads.length > 0 ? 'partially-checked' : '';
      const checkboxLabel = selectedMentionIds.length > 0 ? `${selectedMentionIds.length} Selected` : '';

      if (!bulkActionsList.length) {
        const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
        const { isBulkMessagingEnabled, isCloseMentionsForAllMembersEnabled } = this.props.currentOrg;
        bulkActionsList = BulkActionHelpers.fetchBulkActions('All', options, isBulkMessagingEnabled, isCloseMentionsForAllMembersEnabled, selectedMentionIds);
      }

      this.setState({
        labelValueAssociated: checkboxLabel,
        isChecked: currentPageSelectedThreads.length > 0,
        bulkActionsList,
        checkboxClassName,
      });
    }

    this.setState({
      selectedIdCount,
      selectedInboxMentionIds: selectedMentionIds,
    });
  }

  reloadContainer = (pageNumber, currentPageNumber, totalPageCount, totalInboxCount) => {
    this.setState({
      ...InboxContainer.initialState,
      selectedInboxMentionIds: [],
      pageNumber: pageNumber > -1 ? pageNumber : 0,
      currentPageNumber: currentPageNumber > 0 ? currentPageNumber : 1,
      totalPageCount,
      totalInboxCount,
      previousDisabled: (pageNumber === 0),
      nextDisabled: (pageNumber + 1 >= this.state.totalPageCount),
      bulkActionsList: [],
    });

    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
    options.pageNumber = pageNumber > -1 ? pageNumber : 0;
  }

  onSelectAction = (action) => {
    const { pageNumber, currentPageNumber, totalPageCount, totalInboxCount, selectedInboxMentionIds } = this.state;
    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
    let payload = {};

    if (action.code === 'closeMention' || action.code === 'closeMentionForAllMembers') {
      payload = {
        eventIds: selectedInboxMentionIds,
        mention: {
          deleted: true,
        },
        updateForAllMembers: action.code === 'closeMentionForAllMembers',
      };
      this.props.closeMentionBulkAction(payload, action.code, options).then(() => {
        this.props.fetchInboxSections();
        this.reloadContainer(pageNumber, currentPageNumber, totalPageCount, totalInboxCount);
      });
    } else if (action.code === 'markAsRead' || action.code === 'markAsUnread') {
      const readStatus = action.code === 'markAsRead';
      const actionType = readStatus ? 'updateBulkRead' : 'updateBulkUnRead';
      payload = {
        eventIds: selectedInboxMentionIds,
        mention: {
          isRead: readStatus,
        },
      };
      options.pageNumber = pageNumber > -1 ? pageNumber : 0;
      this.props.updateBulkToggleRead(payload, actionType, options).then(() => {
        this.props.fetchInboxSections();
        this.reloadContainer(pageNumber, currentPageNumber, totalPageCount, totalInboxCount);
      });
    }
  }

  getSelectedInboxOption = (object) => Object.keys(object).find((key) => object[key]);

  onScroll = () => {
    const scrollContainer = this.inboxBodyRef.container.firstChild;
    const totalScroll = scrollContainer.scrollTop + scrollContainer.clientHeight;

    if (totalScroll === scrollContainer.scrollHeight) {
      this.scrollPosition = 'bottom';
    }

    if ((totalScroll !== scrollContainer.scrollHeight) && (scrollContainer.scrollTop !== 0)) {
      this.scrollPosition = 'middle';
    }

    if (scrollContainer.scrollTop === 0) {
      this.scrollPosition = 'top';
    }
  }

  scrollTo = () => {
    if (!this.inboxBodyRef) return;
    const scrollContainer = this.inboxBodyRef.container.firstChild;

    if (this.scrollPosition === 'top') {
      scrollContainer.scrollTop = 0;
    }
  }

  handlePagination = (direction) => {
    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);

    if (direction === 'previous') {
      if (this.state.pageNumber >= 1) {
        options.pageNumber = this.state.pageNumber - 1;
        this.setState((prevState) => ({ pageNumber: prevState.pageNumber - 1, currentPageNumber: prevState.currentPageNumber - 1, activeKey: 0 }));
        this.props.fetchInbox(options);
      }
    } else {
      if (this.state.pageNumber + 1 < this.state.totalPageCount) { // eslint-disable-line no-lonely-if
        options.pageNumber = this.state.pageNumber + 1;
        this.setState((prevState) => ({ pageNumber: prevState.pageNumber + 1, currentPageNumber: prevState.currentPageNumber + 1, activeKey: 0 }));
        this.props.fetchInbox(options);
      }
    }
    this.setState({
      previousDisabled: (options.pageNumber === 0),
      nextDisabled: (options.pageNumber + 1 >= this.state.totalPageCount),
    });
  }

  handleInboxItemClick = (userId, eventId) => {
    const {
      currentPageNumber,
      pageNumber,
      totalPageCount,
      isChecked,
      labelValueAssociated,
      checkboxClassName,
      selectedIdCount,
      bulkActionsList,
    } = this.state;
    this.props.history.push({
      pathname: `${this.props.location.pathname}/user/${userId}`,
      state: {
        currentPageNumber,
        pageNumber,
        totalPageCount,
        isChecked,
        labelValueAssociated,
        checkboxClassName,
        selectedIdCount,
        bulkActionsList,
        currentEventId: eventId,
      },
    });
  }

  handleEventMentionClick = (id, base, route, eventId, pageNo) => {
    let url;
    if (this.props.isLimitedProvider && base.includes('inbox')) {
      url = `/axium/inbox/${route}/${id}`;
    } else {
      url = `/${base}/${route}/${id}`;
    }
    this.props.history.replace({
      pathname: url,
      state: { mentionEventId: eventId, pageNo, eventId },
    });
  }

  handleUserSearchScopedAction = (searchText, activeFilterParam) => {
    this.props.history.push({
      pathname: '/contacts/create',
      state: {
        searchText,
        activeFilterParam,
      },
    });
  }

  render() {
    const props = {
      activeKey: this.state.activeKey,
      bulkActionsList: this.state.bulkActionsList,
      bulkSelectionList: this.state.bulkSelectionList,
      checkboxClassName: this.state.checkboxClassName,
      currentPageNumber: this.state.currentPageNumber,
      currentUserId: this.props.currentUser.id,
      eventMentions: this.props.eventMentions,
      handleCheckboxOnMentionItem: this.handleCheckboxOnMentionItem,
      handleEventMentionClick: this.handleEventMentionClick,
      handleInboxItemClick: this.handleInboxItemClick,
      handlePagination: this.handlePagination,
      handleUserSearchScopedAction: this.handleUserSearchScopedAction,
      inboxBodyRef: (inboxBodyRef) => (this.inboxBodyRef = inboxBodyRef),
      inboxLoading: this.props.inboxLoading,
      isCcr: this.props.currentUser.isCcr,
      isChecked: this.state.isChecked,
      labelValueAssociated: this.state.labelValueAssociated,
      mentionIds: this.props.mentionIds,
      nextDisabled: this.state.nextDisabled,
      olderInboxEventMentionIds: this.props.olderInboxEventMentionIds,
      onChange: this.onChange,
      onScroll: this.onScroll,
      onSelect: this.onSelect,
      onSelectAction: this.onSelectAction,
      pageItemIndexFrom: this.state.pageItemIndexFrom,
      pageItemIndexTo: this.state.pageItemIndexTo,
      pageLoading: this.state.pageLoading,
      previousDisabled: this.state.previousDisabled,
      selectedInboxMentionIds: this.state.selectedInboxMentionIds,
      todayInboxEventMentionIds: this.props.todayInboxEventMentionIds,
      totalInboxCount: this.state.totalInboxCount,
      totalPageCount: this.state.totalPageCount,
      users: this.props.users,
      yesterdayInboxEventMentionIds: this.props.yesterdayInboxEventMentionIds,
    };

    return (<InboxMention {...props} />);
  }
}

InboxContainer.propTypes = {
  closeMentionBulkAction: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
  direct: PropTypes.bool,
  eventMentions: PropTypes.object,
  events: PropTypes.object,
  fetchChannels: PropTypes.func,
  fetchInbox: PropTypes.func.isRequired,
  fetchInboxSections: PropTypes.func.isRequired,
  history: PropTypes.object,
  inboxContext: PropTypes.string.isRequired,
  inboxEventIds: PropTypes.array,
  inboxLoading: PropTypes.bool.isRequired,
  inboxName: PropTypes.string,
  inboxPageNo: PropTypes.number.isRequired,
  inboxSections: PropTypes.array,
  location: PropTypes.object,
  mentionIds: PropTypes.array,
  mentions: PropTypes.bool,
  pageLoading: PropTypes.bool,
  requestInboxView: PropTypes.func,
  resetEventData: PropTypes.func,
  types: PropTypes.object.isRequired,
  updateBulkToggleRead: PropTypes.func,
  users: PropTypes.object.isRequired,
  currentOrg: PropTypes.object,
  yesterdayInboxEventMentionIds: PropTypes.array,
  todayInboxEventMentionIds: PropTypes.array,
  olderInboxEventMentionIds: PropTypes.array,
  isLimitedProvider: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { inbox, phone, type, ui, user } = state;
  return {
    currentOrg: organizationSelectors.getCurrentOrg(state),
    currentUser: userSelectors.getLoggedInUser(state),
    direct: inbox.direct,
    eventMentions: inbox.eventMentions,
    events: inbox.events,
    following: inbox.following,
    inboxChatSections: ui?.inboxSections?.chat ? ui.inboxSections.chat : inbox.inboxSections,
    inboxContext: inboxSelectors.getInboxContext(state),
    inboxEventIds: inboxSelectors.getFilteredInboxEventIds(state),
    inboxLoading: inbox.inboxLoading,
    inboxName: inboxSelectors.getInboxName(state),
    inboxPageNo: inbox.inboxPageNo,
    inboxSections: ui?.inboxSections?.inbox ? ui.inboxSections.inbox : inbox.inboxSections,
    mentionIds: inbox.mentionIds,
    mentions: inbox.mentions,
    olderInboxEventMentionIds: inboxSelectors.getInboxEventMentionsOlder(state),
    pageLoading: inbox.pageLoading,
    phones: phone.phones,
    todayInboxEventMentionIds: inboxSelectors.getInboxEventMentionsToday(state),
    types: type.types,
    users: user.users,
    yesterdayInboxEventMentionIds: inboxSelectors.getInboxEventMentionsYesterday(state),
    isLimitedProvider: userSelectors.userHasLimitedProviderRole(state),
  };
};

const actions = {
  fetchChannels: ChannelReducer.fetchChannels,
  fetchInbox: InboxReducer.fetchInbox,
  fetchInboxSections: UIReducer.fetchInboxSections,
  resetEventData: InboxReducer.resetEventData,
  requestInboxView: InboxReducer.requestInboxView,
  updateBulkToggleRead: InboxReducer.updateBulkToggleRead,
  closeMentionBulkAction: InboxReducer.closeMentionBulkAction,
};

export default connect(mapStateToProps, actions)(InboxContainer);
