const RHINOPAY_MANAGER_HEADERS_CONFIG = {
  createdAt: {
    default: true,
    direction: -1,
  },
  amount: {
    default: false,
    direction: 1,
  },
  requestSent: {
    default: false,
    direction: 1,
  },
  status: {
    default: false,
    direction: 1,
  },
  paidDate: {
    default: false,
    direction: 1,
  },
  contact: {
    default: false,
    direction: 1,
  },
};
export default RHINOPAY_MANAGER_HEADERS_CONFIG;
