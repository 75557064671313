import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  SmartTable,
  UtilityInlineGrid,
  DateRangeDropdown,
  NavTabs, NavTabsItem,
  DropdownMultiSelectAdvanced,
  TabsContent,
  TabContentPane,
  Scrollbars,
  ModalBody,
  ModalHeader,
  Modal,
  Resource,
  ResourceIntro,
  ResourceRight,
  ResourceGroup,
  LoaderCircle,
} from 'rhinostyle';
import { formattedDateDifference, convertUtcStringToLocalMoment, formatTimestamp } from '../helpers/DateHelpers';
import { sortStringValues } from '../helpers/DataHelpers';
import PageLoader from './PageLoader';
import SmartTableHeader from './SmartTableHeader';
import { DEFAULT_ANALYTICS_PAGE_SIZE, BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD } from '../constants/AppConstants';
import { AppConstants } from '../constants';
import { AttachmentHelpers, UserHelpers } from '../helpers';
import { NAVIGATION_TABS } from '../constants/AnalyticsConstants';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { CONVERSATION_CLOSE_DEFAULT, CONVERSATION_CLOSE_MEMBER, CONVERSATION_MEMBER_ASSIGNMENT_EDIT } from '../constants/UserPermissionsConstants';
import ContactAssignPanel from './ContactAssignPanel';

const ConversationActivity = (props) => {
  const {
    activeKey,
    activityHeading,
    activitySubHeading,
    currentPage,
    currentPageItemRange,
    conversationActivities,
    endDate,
    filterToggle,
    filterByPractice,
    filterByPatient,
    hasPagination,
    handlePagination,
    handleTabs,
    selectedKey,
    minDate,
    maxDate,
    isNextDisabled,
    isClosedActivitiesLoading,
    isMedicaidActivitiesLoading,
    isOpenActivitiesLoading,
    isPreviousDisabled,
    navigateToConversation,
    startDate,
    compareObjectByKey,
    selectDate,
    datePickerDropdownLabels,
    datePickerDropdownLabelsForMedicaid,
    calculateDateRange,
    handleUpdateSelectedIds,
    isPageLoading,
    members,
    selectedMembers,
    selectedMemberIds,
    handleClearSelectedMembers,
    fetchFilterData,
    membersIds,
    getContacts,
    selectedContacts,
    selectedContactIds,
    handleClearSelectedContacts,
    contactsIds,
    minMedicaidDate,
    maxMedicaidDate,
    sortTable,
    selectDateForMedicaid,
    selectCampaignsDate,
    campaignsStartDate,
    campaignsEndDate,
    isDateSelected,
    calculateDateRangeForMedicaid,
    clearAllFilters,
    medicaidStartDate,
    medicaidEndDate,
    tableMinRows,
    openConversationHeadersConfig,
    closeConversationHeadersConfig,
    medicaidConversationHeadersConfig,
    activeKeyForMedicaid,
    activeKeyForCampaigns,
    isModalOpen,
    campaignsFailedContacts,
    handleModalToggle,
    handleContactRoutes,
    isCampaignsFailedContactsLoading,
    handleCloseConversation,
    handleAssignmentUpdated,
  } = props;

  const userHasGroupClosePermission = userHasAnyOfPermissions([CONVERSATION_CLOSE_DEFAULT]);
  const userHasMemberClosePermission = userHasAnyOfPermissions([CONVERSATION_CLOSE_MEMBER]);
  const userHasMemberReassignPermission = userHasAnyOfPermissions([CONVERSATION_MEMBER_ASSIGNMENT_EDIT]);
  const [activeAssignment, setActiveAssignment] = useState(null);
  const handleCloseContactAssignModal = () => setActiveAssignment(null);

  function handleAssignmentComplete() {
    setActiveAssignment(null);
    handleAssignmentUpdated();
  }

  const renderFilter = () => (
    <div className="filter__wrapper">
      <div className="filter__header">Filter Last Messaged By: </div>
      <Button
        size="small"
        type="link"
        onClick={() => filterToggle('contact')}
        className={`${filterByPatient ? 'analytic-filter__button--inactive' : ''} analytic-filter__button`}
        data-cypress={filterByPatient ? 'contact-filter--inactive' : 'contact-filter--active'}
      >
        Contact({conversationActivities.contactCount})
      </Button>
      <Button
        size="small"
        type="link"
        onClick={() => filterToggle('practice')}
        className={`${filterByPractice ? 'analytic-filter__button--inactive' : ''} analytic-filter__button`}
        data-cypress={filterByPractice ? 'practice-filter--inactive' : 'practice-filter--active'}
      >
        Practice({conversationActivities.practiceCount})
      </Button>
    </div>
  );

  const renderFilterTitle = (selectedList, name, initialLabel) => {
    let label = initialLabel;
    if (selectedList.length > 0) {
      label = `${selectedList.length} ${name} Selected`;
    }
    return label;
  };

  const renderDateRange = () => (
    <div className="analytics__date-range u-flex">
      <DateRangeDropdown
        position="bottom-center"
        name="Analytics Date Picker"
        startDate={selectedKey === NAVIGATION_TABS.closed ? startDate : campaignsStartDate}
        endDate={selectedKey === NAVIGATION_TABS.closed ? endDate : campaignsEndDate}
        selectDate={selectedKey === NAVIGATION_TABS.closed ? selectDate : selectCampaignsDate}
        minDate={minDate}
        maxDate={maxDate}
        activeKey={selectedKey === NAVIGATION_TABS.closed ? activeKey : activeKeyForCampaigns}
        dropdownMenuItems={datePickerDropdownLabels}
        selectDateRange={calculateDateRange}
        isCustomDate
      />
    </div>
  );

  const renderTableHeader = () => {
    if (selectedKey === 1) {
      return renderFilter();
    } else {
      return renderDateRange();
    }
  };

  const isAnyFilterSelected = (isDateSelected || selectedMemberIds.length > 0 || selectedContactIds.length > 0);
  const dropdownClass = (Object.keys(selectedContacts).length > 0 && Object.keys(selectedMembers).length > 0) ?
    'analytics__contact-dropdown--small' : 'analytics__contact-dropdown';
  const renderMedicaidView = (
    <div className="analytics__medicaid">
      <UtilityInlineGrid>
        <DateRangeDropdown
          position="center"
          name="Analytics Date Picker"
          startDate={medicaidStartDate}
          endDate={medicaidEndDate}
          selectDate={selectDateForMedicaid}
          minDate={minMedicaidDate}
          maxDate={maxMedicaidDate}
          activeKey={activeKeyForMedicaid}
          dropdownMenuItems={datePickerDropdownLabelsForMedicaid}
          selectDateRange={calculateDateRangeForMedicaid}
          isCustomDate
        />
        <DropdownMultiSelectAdvanced
          handleUpdateSelectedIds={handleUpdateSelectedIds}
          itemSearchLoading={isPageLoading}
          avatarBaseUrl={AppConstants.AVATAR_BASE_URL}
          items={UserHelpers.formatMemberNames(members)}
          selectedItems={selectedMembers}
          selectedItemsIds={selectedMemberIds}
          handleClearAllSelectedItems={handleClearSelectedMembers}
          fetchAllItems={fetchFilterData}
          itemsIds={membersIds}
          dropdownLabel={renderFilterTitle(selectedMemberIds, 'Members', 'Member(s)')}
          filterName="Members"
          type="member"
        />
        <DropdownMultiSelectAdvanced
          handleUpdateSelectedIds={handleUpdateSelectedIds}
          itemSearchLoading={isPageLoading}
          avatarBaseUrl={AppConstants.AVATAR_BASE_URL}
          items={getContacts}
          selectedItems={selectedContacts}
          selectedItemsIds={selectedContactIds}
          handleClearAllSelectedItems={handleClearSelectedContacts}
          fetchAllItems={fetchFilterData}
          itemsIds={contactsIds}
          dropdownLabel={renderFilterTitle(selectedContactIds, 'Contacts', 'Contact(s)')}
          filterName="Contacts"
          type="member"
          className="analytics__medicaid-filter"
          dropDownClass={dropdownClass}
        />
      </UtilityInlineGrid>
    </div>
  );

  // Compares current and next value from react table
  const tableDataComparator = (current, next) => {
    if (current === next) {
      return 0;
    }
    return current < next ? 1 : -1;
  };

  async function handleComplete(conversation) {
    const notificationType = conversation.assignment?.direct ? 'closeConversation' : 'completeAssignment';
    const payload = {
      patientUserId: conversation.id,
      conversationActionType: notificationType,
      assignmentId: conversation.assignment?.assignmentId,
      assigned: !conversation.assignment?.direct,
    };
    if (conversation.assignment.toUserGroupId) payload.groupId = Number(conversation.assignment.toUserGroupId);
    await handleCloseConversation(payload);
  }

  const renderCloseConversationButton = (conversation) => {
    if ((userHasGroupClosePermission && conversation.assignment?.toUserGroupId) || (userHasMemberClosePermission && conversation.assignment?.toUserId)) {
      return (
        <Button className="u-text-primary" reset type="link" title="Close Conversation" onClick={() => handleComplete(conversation)}>
          <Icon
            icon="close-convo"
            size="large"
          />
        </Button>
      );
    }
    return null;
  };

  const renderAssignButton = (conversation) => {
    if ((userHasGroupClosePermission && conversation.assignment?.toUserGroupId) || (userHasMemberReassignPermission && conversation.assignment?.toUserId)) {
      return (
        <Button
          className="u-text-primary"
          reset
          type="link"
          title="Assign Conversation"
          onClick={() => {
            setActiveAssignment({
              ...conversation.assignment,
              patientUserId: conversation.id,
            });
          }}
        >
          <Icon
            icon="assign"
            size="large"
          />
        </Button>
      );
    }
    return null;
  };

  const getLastMessageText = (message) => {
    const isOutboundMessage = message.lastMessageBy === 'Practice';
    const messageClasses = cx('open-conversation__message', {
      'msg--default': !isOutboundMessage,
      'open-conversation__message--outbound': isOutboundMessage,
      'msg--attachment': message.attachments?.length > 0,
    });

    if (message.lastMessageText) {
      return <div className={messageClasses}>{message.lastMessageText}</div>;
    }

    if (message.attachments?.length > 0) {
      return (
        <>
          Attachment: <span className={messageClasses}>{AttachmentHelpers.getAttDisplayName(message.attachments[0])}</span>
          <Icon icon="attachment" className="u-m-l-small" />
        </>
      );
    }

    if (message.forms?.length > 0) {
      return <>Attachment: Form</>;
    }

    return null;
  };

  const openConversationColumns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Time Open"
          sortKey="conversationStartTime"
          sortDirection={openConversationHeadersConfig.conversationStartTime.direction}
          headers={openConversationHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      width: 130,
      accessor: 'conversationStartTime',
      Cell: (row) => (<div>{formattedDateDifference(row.value)}</div>),
      fixed: 'left',
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Last Message"
          sortKey="lastConversationTime"
          sortDirection={openConversationHeadersConfig.lastConversationTime.direction}
          headers={openConversationHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'lastConversationTime',
      minWidth: 200,
      className: 'u-flex u-flex-direction-row u-flex-align-content-center',
      Cell: (row) => {
        const lastMessageText = getLastMessageText(row.original);
        return (
          <>
            <div data-cypress={`${row.original.lastMessageBy}-message`}>
              <span>{row.original.lastMessageSender || row.original.lastMessageBy}
                <Icon icon="arrow-right" className="inbox__item__body__arrow-right u-m-l-small u-m-a-small" />
              </span>
              <div className="u-text-muted u-text-xsmall">{formatTimestamp(row.value, 'ddd, MMM D, h:mm a')}</div>
            </div>
            <div className="u-m-l-xsmall">
              {lastMessageText}
            </div>
          </>
        );
      },
    },
    {
      id: 'assignment',
      Header: () => (
        <SmartTableHeader
          headerName="Assignment"
          sortKey="assignment"
          sortDirection={openConversationHeadersConfig.assignment.direction}
          headers={openConversationHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'assignment',
      className: 'u-flex-align-content-center',
      Cell: (row) => (
        <div>{(row.value.to.firstName && row.value.to.lastName) ? UserHelpers.formatMemberNameWithPrefixAndSuffix(row.value.to) : row.value.to}
          <span className="u-text-muted">{` - ${row.value.by}`}</span>
        </div>
      ),
    },
    {
      id: 'contact',
      Header: () => (
        <SmartTableHeader
          headerName="Contact"
          sortKey="contact"
          sortDirection={openConversationHeadersConfig.contact.direction}
          headers={openConversationHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'contact',
      Cell: (row) => (
        <Button type="link" size="small" className="analytics__button-link" onClick={() => navigateToConversation(row.original.id)}>
          {`${row.value || row.original.phone}`}
        </Button>
      ),
    },
    {
      id: 'closeConversation',
      Header: () => '',
      sortable: false,
      accessor: 'assignment',
      headerStyle: {
        padding: 0,
      },
      maxWidth: 50,
      Cell: (row) => renderCloseConversationButton(row.original),
    },
    {
      id: 'closeConversation',
      Header: () => '',
      sortable: false,
      accessor: 'assignment',
      headerStyle: {
        padding: 0,
      },
      maxWidth: 50,
      Cell: (row) => renderAssignButton(row.original),
    },
  ];

  const closedConversationColumns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Time Open"
          sortKey="timeOpen"
          sortDirection={closeConversationHeadersConfig.timeOpen.direction}
          headers={closeConversationHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'timeOpen',
      Cell: (row) => (<div>{formattedDateDifference(row.original.timeOpen, row.original.closedTime)}</div>),
      fixed: 'left',
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Date Closed"
          sortKey="closedTime"
          sortDirection={closeConversationHeadersConfig.closedTime.direction}
          headers={closeConversationHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'closedTime',
      Cell: (row) => (<div>{formattedDateDifference(row.value)}</div>),
      sortMethod: (current, next) => tableDataComparator(current, next),
    },
    {
      id: 'closedBy',
      Header: () => (
        <SmartTableHeader
          headerName="Closed By"
          sortKey="closedBy"
          sortDirection={closeConversationHeadersConfig.closedBy.direction}
          headers={closeConversationHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'closedBy',
      Cell: (row) => (<div>{UserHelpers.formatMemberNameWithPrefixAndSuffix(row.value.by)}<span className="u-text-muted">{` - ${row.value.to}`}</span></div>),
      sortMethod: (current, next) => compareObjectByKey(current, next, 'by'),
    },
    {
      id: 'closedContact',
      Header: () => (
        <SmartTableHeader
          headerName="Contact"
          sortKey="closedContact"
          sortDirection={closeConversationHeadersConfig.closedContact.direction}
          headers={closeConversationHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'contact',
      Cell: (row) => (
        <Button type="link" size="small" className="analytics__button-link" onClick={() => navigateToConversation(row.original.id)}>
          {`${row.value || row.original.phone}`}
        </Button>
      ),
      sortMethod: (current, next) => sortStringValues(current, next),
    },
  ];

  const medicaidConversationColumns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Outbound Message"
          sortKey="timestamp"
          sortDirection={medicaidConversationHeadersConfig.timestamp.direction}
          headers={medicaidConversationHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'timestamp',
      Cell: (row) => (<div data-cypress="mu-message-timestamp">{convertUtcStringToLocalMoment(row.value).format('MM/DD/YY hh:mm a')}</div>),
      fixed: 'left',
    },
    {
      id: 'member',
      Header: () => (
        <SmartTableHeader
          headerName="Member"
          sortKey="member"
          sortDirection={medicaidConversationHeadersConfig.member.direction}
          headers={medicaidConversationHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'member',
      Cell: (row) => (<div>{UserHelpers.formatMemberNameWithPrefixAndSuffix(row.original)}</div>),
    },
    {
      id: 'messageSentTo',
      Header: () => (
        <SmartTableHeader
          headerName="Message Sent To"
          sortKey="messageSentTo"
          sortDirection={medicaidConversationHeadersConfig.messageSentTo.direction}
          headers={medicaidConversationHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'messageSentTo',
      Cell: (row) => (<div>{row.value || row.row._original.number}</div>),
    },
    {
      id: 'medicaidContact',
      Header: () => (
        <SmartTableHeader
          headerName="Contact"
          sortKey="medicaidContact"
          sortDirection={medicaidConversationHeadersConfig.medicaidContact.direction}
          headers={medicaidConversationHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'contact',
      Cell: (row) => (
        <Button type="link" size="small" className="analytics__button-link" onClick={() => navigateToConversation(row.row._original.contactId)}>
          {`${row.value || row.row._original.number}`}
        </Button>
      ),
    },
  ];

  const renderSmartTable = (columns, sort) => (
    conversationActivities.conversations?.length > 0 ? (
      <SmartTable
        data={conversationActivities.conversations}
        showPagination={hasPagination}
        onFetchData={sort}
        minRows={tableMinRows}
        sticky
        defaultPageSize={DEFAULT_ANALYTICS_PAGE_SIZE}
        columns={columns}
        sortable
        manual={false}
        striped
      />
    ) : (
      <div className="convo__empty">
        {conversationActivities.conversations}
        Sorry! There&apos;s nothing to show.
        <p>Once data is available for this section, it will appear here.</p>
      </div>
    )
  );

  const renderPaginationGrid = (previousCaretClass, nextCaretClass) => {
    const totalPages = Math.ceil(conversationActivities.total / DEFAULT_ANALYTICS_PAGE_SIZE);
    return (
      <UtilityInlineGrid className="u-text-small" align="right">
        <span>{currentPageItemRange(currentPage, conversationActivities.conversations.length)} of {conversationActivities.total} Items</span>
        <div className="convo__pagination__buttons">
          <Button
            type="link"
            iconOnly
            disabled={isPreviousDisabled}
            onClick={() => handlePagination('previous', selectedKey)}
            className={previousCaretClass}
          >
            <Icon icon="caret-left" />
          </Button>
          <span><strong>{currentPage}</strong> of {totalPages}</span>
          <Button
            type="link"
            iconOnly
            disabled={isNextDisabled}
            onClick={() => handlePagination('next', selectedKey)}
            className={nextCaretClass}
          >
            <Icon icon="caret-right" />
          </Button>
        </div>
      </UtilityInlineGrid>
    );
  };

  const shouldDisplayBottomPagination = (totalItems) => {
    if (totalItems > DEFAULT_ANALYTICS_PAGE_SIZE &&
      conversationActivities.conversations.length >= BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD) {
      return true;
    }
    return false;
  };

  const renderOpenAndConversationGrid = (previousCaretClass, nextCaretClass, column, id) => {
    const toggleClasses = cx('fix-width', {
      'convo__toggle--closed-convo': NAVIGATION_TABS.closed === id,
      'convo__header--left': NAVIGATION_TABS.closed !== id,
    });

    return (
      <div className="analytics__open-conversation">
        <div className="row convo__toggle">
          <div className={toggleClasses}>
            <div className="convo__open-conversation">
              <h3 className="convo__conversation-count" data-cypress="total-convo-count">{activityHeading}</h3>
              <span className="convo__conversation-info">{activitySubHeading}</span>
            </div>
          </div>
          <div className="column-12@xsmall column-5@medium convo__header__toggle">
            {renderTableHeader()}
          </div>
          {conversationActivities.total > DEFAULT_ANALYTICS_PAGE_SIZE && (
            <div className="column-12@xsmall column-3@medium">
              {renderPaginationGrid(previousCaretClass, nextCaretClass)}
            </div>
          )}
        </div>
        <div className="row" data-check={selectedKey}>
          {selectedKey === id && renderSmartTable(column, sortTable)}

        </div>
        {shouldDisplayBottomPagination(conversationActivities.total) && (
          <div className="row">
            <div className="column-12@xsmall column-12@medium convo__bottom__pagination">
              {renderPaginationGrid(previousCaretClass, nextCaretClass)}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderMedicaidConversationGrid = (previousCaretClass, nextCaretClass) => (
    <div>
      <div className="row convo__toggle">
        <div className="analytics__filter-label">FILTER BY:
          <span className="analytics__filter-clear">
            {isAnyFilterSelected && (
              <Button type="link" className="clear-all__button" onClick={clearAllFilters}>Clear All &nbsp;<Icon icon="close" /></Button>
            )}
          </span>
        </div>
        <div className="column-12@small column-9@medium convo__medicaid-view u-p-l-0"> {renderMedicaidView}</div>
        {conversationActivities.total > DEFAULT_ANALYTICS_PAGE_SIZE && (
          <div className="column-12@xsmall column-3@medium convo__medicaid-pagination u-p-l-0">
            {isMedicaidActivitiesLoading ? '' : renderPaginationGrid(previousCaretClass, nextCaretClass)}
          </div>
        )}
      </div>
      {isMedicaidActivitiesLoading ? <PageLoader /> : (
        <div>
          <div className="row">
            {renderSmartTable(medicaidConversationColumns, sortTable)}
          </div>
          {shouldDisplayBottomPagination(conversationActivities.total) && (
            <div className="row">
              <div className="column-12@xsmall column-12@medium convo__bottom__pagination">
                {isMedicaidActivitiesLoading ? '' : renderPaginationGrid(previousCaretClass, nextCaretClass)}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  const renderGrid = () => {
    const previousCaretClass = isPreviousDisabled ? 'pagination__caret--disabled' : '';
    const nextCaretClass = isNextDisabled ? 'pagination__caret--disabled' : '';
    return (
      <div>
        <div className="analytics__date-range convo__tabs nav-tabs__wrapper">
          <NavTabs activeKey={selectedKey} onSelect={handleTabs}>
            <NavTabsItem id={NAVIGATION_TABS.open} dataFeatureTag="conversation-activity_open">Open</NavTabsItem>
            <NavTabsItem id={NAVIGATION_TABS.closed} dataFeatureTag="conversation-activity_closed">Closed</NavTabsItem>
            <NavTabsItem id={NAVIGATION_TABS.medicaid} dataFeatureTag="conversation-activity_medicaid">MU (Medicaid)</NavTabsItem>
          </NavTabs>
        </div>
        <TabsContent activeKey={selectedKey}>
          <TabContentPane id={NAVIGATION_TABS.open}>
            {isOpenActivitiesLoading ? <PageLoader /> :
              renderOpenAndConversationGrid(previousCaretClass, nextCaretClass, openConversationColumns, NAVIGATION_TABS.open)}
          </TabContentPane>
          <TabContentPane id={NAVIGATION_TABS.closed}>
            {isClosedActivitiesLoading ? <PageLoader /> :
              renderOpenAndConversationGrid(previousCaretClass, nextCaretClass, closedConversationColumns, NAVIGATION_TABS.closed)}
          </TabContentPane>
          <TabContentPane id={NAVIGATION_TABS.medicaid} className="analytics__medicaid-tab">
            {renderMedicaidConversationGrid(previousCaretClass, nextCaretClass)}
          </TabContentPane>
        </TabsContent>
      </div>
    );
  };

  const renderRhinoBlastFailedMessagesModal = () => (
    <Modal open={isModalOpen} modalClass="broadcast-modal__container" modalDialogClass="broadcast-modal">
      <ModalHeader
        onClose={handleModalToggle}
        title="Failed Messages"
      />
      <div className="app-page__header broadcast-modal__header">
        <div className="broadcast-modal__header__title">Contacts</div>
      </div>
      <div className="u-p-b-large">
        {!isCampaignsFailedContactsLoading ? (
          <ModalBody>
            <ResourceRight>
              <p className="u-p-b-small">({campaignsFailedContacts?.totalFailedCount} Messages Failed)</p>
            </ResourceRight>
            <Scrollbars autoHeight autoHeightMax={500}>
              <ResourceGroup className="resource-group__scroll">
                {campaignsFailedContacts?.failedBulkMessageInfo?.length && campaignsFailedContacts.failedBulkMessageInfo?.map((contact, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Resource className="u-flex-align-items-center" key={`${contact.receiverName}_${index}`} onClick={() => handleContactRoutes(contact.recipientId)}>
                    <ResourceIntro
                      avatar={{
                        image: contact?.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${contact.profileImageUrl}` : null,
                        name: UserHelpers?.formatAvatarName(contact.firstName, contact.lastName),
                        type: 'default',
                        showOnlineStatus: false,
                      }}
                      title={UserHelpers.formatUserLabel(contact) || contact.contactNumber}
                    />
                    <ResourceRight>
                      <p className="u-text-danger">{`${contact.failed} of ${contact.total} Failed`}</p>
                    </ResourceRight>
                  </Resource>
                ))}
              </ResourceGroup>
            </Scrollbars>
          </ModalBody>
        ) : <div className="u-flex u-flex-justify-center"><LoaderCircle className="u-text-primary" /></div>}
      </div>
    </Modal>
  );

  return (
    <Scrollbars>
      <div className="app-page__container analytics">
        <div className="app-page__container__inner analytics__container">
          <div className="app-page__header">
            <div className="app-page__header__title analytics__header">Conversation Activity</div>
          </div>
          <div className="analytics__wrapper">
            {renderGrid()}
            {renderRhinoBlastFailedMessagesModal()}
          </div>
          <Modal open={!!activeAssignment}>
            <ModalBody>
              <div className="bulk-actions__assign__modal">
                <ContactAssignPanel
                  handleCloseModal={handleCloseContactAssignModal}
                  assignFocus
                  followStatus={false}
                  isModalOpen={!!activeAssignment}
                  bulkActiveFromChannelId={activeAssignment?.channelId}
                  currentAssignment={activeAssignment}
                  handleAssignmentComplete={handleAssignmentComplete}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </Scrollbars>
  );
};

ConversationActivity.propTypes = {
  activeKey: PropTypes.number,
  activityHeading: PropTypes.string,
  activitySubHeading: PropTypes.string,
  conversationActivities: PropTypes.object,
  currentPage: PropTypes.number.isRequired,
  currentPageItemRange: PropTypes.func.isRequired,
  filterToggle: PropTypes.func.isRequired,
  filterByPractice: PropTypes.bool.isRequired,
  filterByPatient: PropTypes.bool.isRequired,
  hasPagination: PropTypes.bool,
  handlePagination: PropTypes.func.isRequired,
  endDate: PropTypes.object.isRequired,
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
  isOpenActivitiesLoading: PropTypes.bool.isRequired,
  isClosedActivitiesLoading: PropTypes.bool.isRequired,
  isMedicaidActivitiesLoading: PropTypes.bool.isRequired,
  isPreviousDisabled: PropTypes.bool.isRequired,
  navigateToConversation: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  compareObjectByKey: PropTypes.func,
  selectDate: PropTypes.func.isRequired,
  datePickerDropdownLabels: PropTypes.array.isRequired,
  datePickerDropdownLabelsForMedicaid: PropTypes.array.isRequired,
  calculateDateRange: PropTypes.func.isRequired,
  handleTabs: PropTypes.func.isRequired,
  selectedKey: PropTypes.number.isRequired,
  handleUpdateSelectedIds: PropTypes.func.isRequired,
  sortTable: PropTypes.func.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  members: PropTypes.object.isRequired,
  selectedMembers: PropTypes.object.isRequired,
  selectedMemberIds: PropTypes.array.isRequired,
  handleClearSelectedMembers: PropTypes.func.isRequired,
  fetchFilterData: PropTypes.func.isRequired,
  membersIds: PropTypes.array.isRequired,
  getContacts: PropTypes.object.isRequired,
  selectedContacts: PropTypes.object.isRequired,
  selectedContactIds: PropTypes.array.isRequired,
  handleClearSelectedContacts: PropTypes.func.isRequired,
  contactsIds: PropTypes.array.isRequired,
  minMedicaidDate: PropTypes.object.isRequired,
  maxMedicaidDate: PropTypes.object.isRequired,
  selectDateForMedicaid: PropTypes.func.isRequired,
  selectCampaignsDate: PropTypes.func.isRequired,
  calculateDateRangeForMedicaid: PropTypes.func.isRequired,
  isDateSelected: PropTypes.bool.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
  medicaidStartDate: PropTypes.object.isRequired,
  medicaidEndDate: PropTypes.object.isRequired,
  campaignsEndDate: PropTypes.object.isRequired,
  campaignsStartDate: PropTypes.object.isRequired,
  tableMinRows: PropTypes.number.isRequired,
  openConversationHeadersConfig: PropTypes.object.isRequired,
  closeConversationHeadersConfig: PropTypes.object.isRequired,
  medicaidConversationHeadersConfig: PropTypes.object.isRequired,
  activeKeyForMedicaid: PropTypes.number,
  activeKeyForCampaigns: PropTypes.number,
  isModalOpen: PropTypes.bool.isRequired,
  campaignsFailedContacts: PropTypes.object,
  handleModalToggle: PropTypes.func.isRequired,
  handleContactRoutes: PropTypes.func.isRequired,
  isCampaignsFailedContactsLoading: PropTypes.bool,
  handleCloseConversation: PropTypes.func.isRequired,
  handleAssignmentUpdated: PropTypes.func.isRequired,
};

export default ConversationActivity;
