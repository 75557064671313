import { createSelector } from '@reduxjs/toolkit';

const getAppointmentCampaigns = (state) => state.appointmentCampaign.appointmentCampaigns;
const getAppointmentCampaignIds = (state) => state.appointmentCampaign.appointmentCampaignIds;
const getAppointmentTypeId = (state, props) => props.appointmentTypeId;
const getItemId = (state, props) => props.itemId;
const getFieldName = (state, props) => props.fieldName;
const getAppointmentCampaignId = (state, props) => props.appointmentCampaignId;

export const getDefaultCampaignId = createSelector(
  [getAppointmentCampaigns, getAppointmentCampaignIds],
  (appointmentCampaigns, appointmentCampaignIds) =>
    appointmentCampaignIds
      .find((id) => appointmentCampaigns[id].default),
);

export const getAppointmentCampaignByAppointmentType = createSelector(
  [getAppointmentCampaigns, getAppointmentCampaignIds, getAppointmentTypeId, getDefaultCampaignId],
  (appointmentCampaigns, appointmentCampaignIds, appointmentTypeId, defaultCampaignId) => {
    const campaignId = appointmentTypeId ?
      appointmentCampaignIds.find((id) => appointmentCampaigns[id].appointmentTypes?.includes(appointmentTypeId))
      : defaultCampaignId;
    return campaignId ? appointmentCampaigns[campaignId] : {};
  },
);

export const getInUseCampaign = createSelector(
  [
    getAppointmentCampaigns,
    getAppointmentCampaignIds,
    getItemId,
    getFieldName,
    getAppointmentCampaignId,
  ],
  (appointmentCampaigns, appointmentCampaignIds, itemId, fieldName, appointmentCampaignId) => {
    const inUseCampaignId = appointmentCampaignIds.find(
      (campaignId) =>
        campaignId !== Number(appointmentCampaignId) &&
        appointmentCampaigns[campaignId][fieldName]?.includes(itemId),
    );

    return inUseCampaignId ? appointmentCampaigns[inUseCampaignId] : null;
  },
);
