import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import {
  Alert,
  Button,
  DateRangeDropdown,
  Dropdown,
  DropdownMenuItem,
  DropdownMultiSelectAdvanced,
  Icon,
  Scrollbars,
  SmartTable,
  UtilityInlineGrid,
} from 'rhinostyle';

import PageLoader from './PageLoader';
import EmptyMessage from './EmptyMessage';
import { daysDict } from '../constants/BusinessHoursConstants';
import { exists } from '../helpers/DataHelpers';
import { AppConstants, AuditLogConstants } from '../constants';
import SmartTableHeader from './SmartTableHeader';
import { UserHelpers } from '../helpers';

const AuditLog = (props) => {
  const {
    actions,
    activeKey,
    auditLogData,
    calculateDateRange,
    categories,
    clearAllFilters,
    contacts,
    contactsIds,
    datePickerDropdownLabels,
    endDate,
    expandAll,
    expandButtonText,
    expanded,
    expandRow,
    fetchFilterData,
    handleChange,
    handleClearSelectedContacts,
    handleClearSelectedMembers,
    handlePagination,
    handleSort,
    handleUpdateSelectedIds,
    isAuditLogLoading,
    isDateSelected,
    isNextDisabled,
    isPreviousDisabled,
    maxDate,
    members,
    membersIds,
    minDate,
    pageItemIndexFrom,
    pageItemIndexTo,
    selectDate,
    selectedAction,
    selectedCategory,
    selectedContactIds,
    selectedContacts,
    selectedMemberIds,
    selectedMembers,
    startDate,
    auditLogHeadersConfig,
  } = props;

  const dataCypress = expandButtonText === 'Expand All' ? 'expandAll' : 'collapseAll';

  const renderExpandAllButton = (rows) => (
    <Button
      className="expand-all__button"
      type="link"
      data-cypress={dataCypress}
      onClick={() => expandAll(rows)}
    >
      {expandButtonText}
    </Button>
  );

  const smartTableColumns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Date"
          sortKey="date"
          sortDirection={auditLogHeadersConfig.date.direction}
          headers={auditLogHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'date',
      fixed: 'left',
    }, {
      Header: () => (
        <SmartTableHeader
          headerName="Member"
          sortKey="user"
          sortDirection={auditLogHeadersConfig.user.direction}
          headers={auditLogHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'user',
    }, {
      Header: () => (
        <SmartTableHeader
          headerName="Contact"
          sortKey="contactName"
          sortDirection={auditLogHeadersConfig.contactName.direction}
          headers={auditLogHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'contactName',
    }, {
      Header: () => (
        <SmartTableHeader
          headerName="Category"
          sortKey="category"
          sortDirection={auditLogHeadersConfig.category.direction}
          headers={auditLogHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'category',
    }, {
      Header: () => (
        <SmartTableHeader
          headerName="Action"
          sortKey="action"
          sortDirection={auditLogHeadersConfig.action.direction}
          headers={auditLogHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'action',
    }, {
      Header: (rows) => renderExpandAllButton(rows),
      expander: true,
      width: 100,
      headerClassName: 'expand-header',
      className: 'expand-column',
      Expander: (row) => (
        <div>
          {row.isExpanded
            ? <Button data-cypress="hideDetails" type="link" onClick={() => expandRow(row)}>Hide Details</Button>
            : <Button data-cypress="details" type="link" onClick={() => expandRow(row)}>Details</Button>}
        </div>
      ),
    },
  ];

  const renderTextForBoolean = (updatedItem, fieldType) => {
    const field = fieldType === 'old' ? updatedItem.old : updatedItem.new;
    if (typeof field === 'boolean') {
      return field ? 'Enabled' : 'Disabled';
    } else if ((typeof field === 'number' && (field === 0 || field === 1)) && (!AuditLogConstants.KEYS_TREATED_AS_NUMBERS.includes(updatedItem.name))) {
      return field === 1 ? 'Enabled' : 'Disabled';
    } else {
      return field;
    }
  };

  const renderOfficeDetails = (updatedItem, action, uniqueKey) => {
    const className = action !== AuditLogConstants.AUDIT_LOGS_OPERATIONS.ADD ? 'field-value__new' : '';
    const fieldValueNew = updatedItem.new;
    const fieldValueOld = updatedItem.old;
    return (
      <Fragment key={uniqueKey}>
        {updatedItem.officeName && <div>{updatedItem.officeName} -</div>}
        {fieldValueNew && <div className={className}>{fieldValueNew}</div>}
        {fieldValueOld && <div className="field-value__old">{fieldValueOld}</div>}
      </Fragment>
    );
  };

  const renderPermissionDetails = (updatedItem, action, itemIndex) => {
    const className = action !== AuditLogConstants.AUDIT_LOGS_OPERATIONS.ADD ? 'field-value__new' : '';
    const newFields = updatedItem.newActions && updatedItem.newActions.length && updatedItem.newActions.join(', ');
    const oldFields = updatedItem.oldActions && updatedItem.oldActions.length && updatedItem.oldActions.join(', ');
    const isCommaVisible = newFields && oldFields;
    return (
      <Fragment key={`${updatedItem.permissionName}${itemIndex}`}>
        {updatedItem.permissionName && <div>{updatedItem.permissionName} -</div>}
        {newFields && <div className={className}>{newFields}{isCommaVisible && ', '}</div>}
        {oldFields && <div className="field-value__old">{oldFields}</div>}
      </Fragment>
    );
  };

  const renderLogItemDetails = (updatedItem, action, uniqueKey, itemIndex, totalItem) => {
    const newClassName = action !== AuditLogConstants.AUDIT_LOGS_OPERATIONS.ADD ? 'field-value__new' : '';
    const isCommaVisible = itemIndex < (totalItem - 1);
    const isNewFieldAvailable = typeof updatedItem.new === 'boolean' || (exists(updatedItem.new) && typeof updatedItem.new !== 'object' && updatedItem.new.length !== 0);
    const isOldFieldAvailable = exists(updatedItem.old) && typeof updatedItem.old !== 'object' && updatedItem.old.length !== 0;
    const isSubActionAvailable = exists(updatedItem.subAction) && updatedItem.subAction.length > 0;
    return (
      <Fragment key={uniqueKey}>
        {isSubActionAvailable && <div>{updatedItem.subAction}</div>}
        {isNewFieldAvailable && <div className={newClassName}>{renderTextForBoolean(updatedItem, 'new')}{isCommaVisible && ', '}</div>}
        {isOldFieldAvailable && <div className="field-value__old">{renderTextForBoolean(updatedItem, 'old')}{isCommaVisible && ', '}</div>}
      </Fragment>
    );
  };

  const mapOpenCloseHours = (isOpen) => (isOpen ? 'Open All Day' : 'Closed All Day');

  const renderExpectedBusinessHours = (hours) => hours && moment(hours, 'HH:mm:ss').format('h:mm a');

  const renderDaysBusinessHour = (dayWiseData, className) => {
    if (exists(dayWiseData) && !dayWiseData.open) {
      return <div className={className}>{mapOpenCloseHours(dayWiseData.open)}</div>;
    } else if (dayWiseData.from === '00:00:00' && dayWiseData.to === '23:59:59') {
      return <div className={className}>{mapOpenCloseHours(dayWiseData.open)}</div>;
    } else {
      return (
        <>
          <div>From:</div>
          <div className={className}>{renderExpectedBusinessHours(dayWiseData.from)}</div>
          <div>To:</div>
          <div className={className}>{renderExpectedBusinessHours(dayWiseData.to)}</div>
        </>
      );
    }
  };

  const renderFromToBusinessHours = (dayWiseData, keyName, className = '') => (
    <>
      <div>From:</div>
      {dayWiseData.from && <div className={className}>{renderExpectedBusinessHours(dayWiseData.from[keyName])}</div>}
      <div>To:</div>
      {dayWiseData.to && <div className={className}>{renderExpectedBusinessHours(dayWiseData.to[keyName])}</div>}
    </>
  );

  const renderBusinessHoursForEdit = (dayWiseData, newClassName) => {
    if (exists(dayWiseData.open)) {
      return (
        <>
          {!dayWiseData.open.__new ? (
            <div className="field-value__new">{mapOpenCloseHours(dayWiseData.open.__new)}</div>
          ) : (
            renderFromToBusinessHours(dayWiseData, '__new', newClassName)
          )}
          {!dayWiseData.open.__old ? (
            <div className="field-value__old">{mapOpenCloseHours(dayWiseData.open.__old)}</div>
          ) : (
            renderFromToBusinessHours(dayWiseData, '__old', 'field-value__old')
          )}
        </>
      );
    } else if (exists(dayWiseData.old) || exists(dayWiseData.new)) {
      return (
        <UtilityInlineGrid className="business-hours__details">
          {exists(dayWiseData) && exists(dayWiseData.new) && renderDaysBusinessHour(dayWiseData.new, newClassName)}
          {exists(dayWiseData) && exists(dayWiseData.old) && renderDaysBusinessHour(dayWiseData.old, 'field-value__old')}
        </UtilityInlineGrid>
      );
    } else {
      return (
        <UtilityInlineGrid className="business-hours__details">
          {dayWiseData.from && (
            <>
              <div>From:</div>
              <div className="field-value__new">{renderExpectedBusinessHours(dayWiseData.from.__new)}</div>
              <div className="field-value__old">{renderExpectedBusinessHours(dayWiseData.from.__old)}</div>
            </>
          )}
          {dayWiseData.to && (
            <>
              <div>To:</div>
              <div className="field-value__new">{renderExpectedBusinessHours(dayWiseData.to.__new)}</div>
              <div className="field-value__old">{renderExpectedBusinessHours(dayWiseData.to.__old)}</div>
            </>
          )}
        </UtilityInlineGrid>
      );
    }
  };

  const renderBusinessHoursDetails = (dayWiseData, action) => {
    const newClassName = action !== AuditLogConstants.AUDIT_LOGS_OPERATIONS.ADD ? 'field-value__new' : '';
    if (dayWiseData.operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.ADD) {
      return (
        <UtilityInlineGrid className="business-hours__details">
          {exists(dayWiseData) && exists(dayWiseData.new) && renderDaysBusinessHour(dayWiseData.new, newClassName)}
          {exists(dayWiseData) && exists(dayWiseData.old) && renderDaysBusinessHour(dayWiseData.old, 'field-value__old')}
        </UtilityInlineGrid>
      );
    } else if (dayWiseData.operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.DELETE) {
      return (
        <UtilityInlineGrid className="business-hours__details">
          {exists(dayWiseData) && exists(dayWiseData.new) && renderDaysBusinessHour(dayWiseData.new, newClassName)}
          {exists(dayWiseData) && exists(dayWiseData.old) && renderDaysBusinessHour(dayWiseData.old, 'field-value__old')}
        </UtilityInlineGrid>
      );
    } else if (dayWiseData.operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT) {
      return renderBusinessHoursForEdit(dayWiseData, newClassName);
    } else {
      return null;
    }
  };

  const renderLogDetails = (updatedItem, action, createdAt, updatedItemIndex, category) => {
    const isDataArrayAvailable = exists(updatedItem.data) && updatedItem.data.length;
    const isNewFieldAvailable = typeof updatedItem.new === 'boolean' || (updatedItem.new && typeof updatedItem.new !== 'object');
    const isOldFieldAvailable = exists(updatedItem.old) && typeof updatedItem.old !== 'object';
    const isSubActionAvailable = exists(updatedItem.subAction) && updatedItem.subAction.length > 0;
    const isKeyNameVisible = isDataArrayAvailable || isNewFieldAvailable || isOldFieldAvailable || isSubActionAvailable;
    if (updatedItem.name === AuditLogConstants.AUDIT_LOG_UPDATED_FIELD.Business_Hours) {
      return (
        <Fragment key={`${updatedItem.name}_${createdAt}`}>
          {updatedItem.data && updatedItem.data.length && updatedItem.data.map((dayWiseData, index) => {
            const day = daysDict[dayWiseData.day].title;
            return (
              <UtilityInlineGrid key={`${createdAt}_${day}`} className="expand-row__detail">
                {index === 0 ? (
                  <div className="expand-row__span column-alignment__key">Availability Hours:</div>
                ) : (
                  <div className="expand-row__span column-alignment__key" />
                )}
                <div>{day}:</div>
                {renderBusinessHoursDetails(dayWiseData, action)}
              </UtilityInlineGrid>
            );
          })}
        </Fragment>
      );
    } else if (updatedItem.name === AuditLogConstants.AUDIT_LOG_UPDATED_FIELD.offices && updatedItem.data && updatedItem.data.length) {
      return (
        updatedItem.data.map((item, itemIndex) => {
          const uniqueKey = `${updatedItem.name}_${createdAt}_${itemIndex}`;
          const isOfficeDataAvailable = (exists(item.old) && item.old.length) || (exists(item.new) && item.new.length);
          return isOfficeDataAvailable && (
            <UtilityInlineGrid key={`${item.officeName}_${createdAt}`} className="expand-row__detail u-text-capitalize">
              {itemIndex === 0 ? (
                <div className="expand-row__span column-alignment__key">{`${updatedItem.name}`}:</div>
              ) : (
                <div className="expand-row__span column-alignment__key" />
              )}
              {renderOfficeDetails(item, action, uniqueKey)}
            </UtilityInlineGrid>
          );
        })
      );
    } else if (updatedItem.name === AuditLogConstants.AUDIT_LOG_UPDATED_FIELD.Permissions) {
      return (
        updatedItem.data && updatedItem.data.length && (
          updatedItem.data.map((item, itemIndex) => {
            const isPermissionDataAvailable = (exists(item.oldActions) && item.oldActions.length) || (exists(item.newActions) && item.newActions.length);
            return isPermissionDataAvailable && (
              <UtilityInlineGrid key={`${item.permissionName}_${createdAt}`} className="expand-row__detail u-text-capitalize">
                {itemIndex === 0 ? (
                  <div className="expand-row__span column-alignment__key">Permissions:</div>
                ) : (
                  <div className="expand-row__span column-alignment__key" />
                )}
                {renderPermissionDetails(item, action, itemIndex)}
              </UtilityInlineGrid>
            );
          })
        )
      );
    } else if (category === AuditLogConstants.AUDIT_LOGS_CATEGORIES.RHINOBLAST) {
      return (
        updatedItem.data && updatedItem.data.length && (
          <>
            {updatedItem.data.map((item) => item && (
              <Fragment key={item.id}>
                <UtilityInlineGrid className="expand-row__detail">
                  <div className="expand-row__span column-alignment__key"><strong>Contact:</strong></div>
                  <div><strong>{item.firstName} {item.lastName}</strong></div>
                </UtilityInlineGrid>
                <UtilityInlineGrid className="expand-row__detail">
                  <div className="expand-row__span column-alignment__key">Phone Number</div>
                  <div>
                    {item.phones && item.phones.length && item.phones.map((phone, index) => {
                      const isCommaVisible = index < item.phones.length - 1;
                      return <Fragment key={phone.number}>{phone.number}{isCommaVisible && ', '}</Fragment>;
                    })}
                  </div>
                </UtilityInlineGrid>
              </Fragment>
            ))}
          </>
        )
      );
    } else {
      return (
        isKeyNameVisible && (
          <UtilityInlineGrid key={`${updatedItem.name}_${createdAt}_${updatedItemIndex}`} className="expand-row__detail">
            {updatedItem.name && (updatedItem.parentKey) ? (
              <div className="expand-row__span column-alignment__key">{`${updatedItem.parentKey} ${updatedItem.name}`}: </div>
            ) : (
              <div className="expand-row__span column-alignment__key">{updatedItem.name}:</div>
            )}
            {updatedItem.data && updatedItem.data.length ?
              (updatedItem.data.map((item, itemIndex) => {
                const uniqueKey = `${updatedItem.name}_${createdAt}_${itemIndex}`;
                return renderLogItemDetails(item, action, uniqueKey, itemIndex, updatedItem.data.length);
              })
              ) : (
                renderLogItemDetails(updatedItem, action)
              )}
          </UtilityInlineGrid>
        )
      );
    }
  };

  const renderStaticData = (staticData) => (
    <>
      <span className="expand-row__detail">Operation Details</span>
      <UtilityInlineGrid className="expand-row__detail">
        {staticData.staticFieldName && <div className="expand-row__span column-alignment__key"><strong>{staticData.staticFieldName}:</strong></div>}
        {staticData.fieldValue && <div className="expand-row__span"><strong>{staticData.fieldValue}</strong></div>}
      </UtilityInlineGrid>
    </>
  );

  const renderRowDetails = (row) => {
    if (row.original) {
      const { action, logDetails, isFirstTimeEdit, createdAt, category } = row.original;
      const updatedPaymentMethod = category === 'Billing' ? logDetails.find((log) => log.name === 'billManagerPaymentId') : null;
      const addedPaymentMethod = category === 'Billing' ? logDetails.find((log) => log.name === 'billManagerPaymentId__added') : null;
      const isMessageVisible = isFirstTimeEdit || exists(updatedPaymentMethod) || exists(addedPaymentMethod);
      const messageForPaymentMethod = exists(addedPaymentMethod) ? 'Payment Method Added' : 'Payment Method modified';
      const messageToShow = isFirstTimeEdit ? 'No Data Found' : messageForPaymentMethod;
      return (
        <div className="expand-row u-list">
          <Scrollbars autoHeight autoHeightMax={300}>
            {action === AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT && isMessageVisible ? (
              <span>{messageToShow}</span>
            ) : (
              <>
                {renderStaticData(row.original)}
                {(action !== 'View' && action !== 'Delete') &&
                logDetails && logDetails.map((updatedItem, updatedItemIndex) => renderLogDetails(updatedItem, action, createdAt, updatedItemIndex, category))}
              </>
            )}
          </Scrollbars>
        </div>
      );
    } else return null;
  };

  const renderFilterTitle = (selectedList, name, initialLabel) => {
    let label = initialLabel;
    if (selectedList.length > 0) {
      label = `${selectedList.length} ${name} Selected`;
    }
    return label;
  };

  const renderList = (item) => (
    <DropdownMenuItem
      key={item.id}
      id={item.id}
      label={item.value ? item.value : null}
      labelDesc={item.description ? item.description : null}
    />
  );

  const renderPaginationControls = () => {
    const previousCaretClass = isPreviousDisabled ? 'pagination__caret--disabled' : '';
    const nextCaretClass = isNextDisabled ? 'pagination__caret--disabled' : '';
    return (
      <div className="u-text-right u-text-small audit-log__pagination">
        <span className="inbox-pagination__count">{`${pageItemIndexFrom}-${pageItemIndexTo} Items`}</span>
        <Button
          type="link"
          size="small"
          iconOnly
          disabled={isPreviousDisabled}
          onClick={() => handlePagination('previous')}
          className={previousCaretClass}
        >
          <Icon icon="caret-left" />
        </Button>
        <Button
          type="link"
          iconOnly
          size="small"
          disabled={isNextDisabled}
          onClick={() => handlePagination('next')}
          className={nextCaretClass}
        >
          <Icon icon="caret-right" />
        </Button>
      </div>
    );
  };

  const isAnyFilterSelected = (isDateSelected || selectedMemberIds.length > 0 || selectedContactIds.length > 0 || selectedCategory || selectedAction);

  const renderAuditData = () => {
    if (isAuditLogLoading) {
      return <PageLoader />;
    } else if (auditLogData.length === 0) {
      return (
        <div className="audit-log__nologs_wrapper">
          <EmptyMessage section="Audit Log" />
        </div>
      );
    } else {
      return (
        <>
          <SmartTable
            showPagination={false}
            data={auditLogData}
            columns={smartTableColumns}
            onFetchData={handleSort}
            minRows={0}
            sortable
            manual
            striped
            sticky
            SubComponent={renderRowDetails}
            expanded={expanded}
            defaultPageSize={auditLogData.length}
          />
          {auditLogData.length > 0 && renderPaginationControls()}
        </>
      );
    }
  };

  const isPaginationControlsVisible = auditLogData.length > 0 && pageItemIndexFrom > 0;
  const classes = 'multi-select__dropdown__height';
  const dropdownClass = (Object.keys(selectedContacts).length > 0 && Object.keys(selectedMembers).length > 0) ? 'contact_dropdown-small' : 'contact_dropdown';
  const isCategorySelected = selectedCategory.length > 0;
  const isActionSelected = selectedAction.length > 0;
  return (
    <div className="app-page__container">
      <div className="app-panels">
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="app-page__header u-flex-direction-column">
              <div className="app-page__header__title audit-log__title">Audit Log</div>
              <Alert type="info" className="u-m-y u-flex-align-self-center u-p-x-large">Please allow some time for most recent activity to display.</Alert>
            </div>
            <div className="box responsive-table audit-log__grid">
              <div className="audit-log__filter-wrapper">
                <div className="audit-log__filter-label">
                  FILTER LOG BY:
                  {isAnyFilterSelected && (
                    <Button data-cypress="clearAll" type="link" className="clear-all__button" onClick={clearAllFilters}>Clear All &nbsp;<Icon icon="close" /></Button>
                  )}
                </div>
                <div className="row">
                  <div>
                    <UtilityInlineGrid>
                      <DateRangeDropdown
                        name="Analytics Date Picker"
                        startDate={startDate}
                        endDate={endDate}
                        selectDate={selectDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        activeKey={activeKey}
                        position="center"
                        dropdownMenuItems={datePickerDropdownLabels}
                        selectDateRange={calculateDateRange}
                        dataCypress="datePicker"
                        isCustomDate
                      />
                      <DropdownMultiSelectAdvanced
                        handleUpdateSelectedIds={handleUpdateSelectedIds}
                        itemSearchLoading={isAuditLogLoading}
                        avatarBaseUrl={AppConstants.AVATAR_BASE_URL}
                        items={UserHelpers.formatMemberNames(members)}
                        selectedItems={selectedMembers}
                        selectedItemsIds={selectedMemberIds}
                        handleClearAllSelectedItems={handleClearSelectedMembers}
                        fetchAllItems={fetchFilterData}
                        itemsIds={membersIds}
                        dropdownLabel={renderFilterTitle(selectedMemberIds, 'Members', 'Member(s)')}
                        filterName="Members"
                        type="member"
                        className={classes}
                        dataCypress="memberFilter"
                      />
                      <DropdownMultiSelectAdvanced
                        handleUpdateSelectedIds={handleUpdateSelectedIds}
                        itemSearchLoading={isAuditLogLoading}
                        avatarBaseUrl={AppConstants.AVATAR_BASE_URL}
                        items={contacts}
                        selectedItems={selectedContacts}
                        selectedItemsIds={selectedContactIds}
                        handleClearAllSelectedItems={handleClearSelectedContacts}
                        fetchAllItems={fetchFilterData}
                        itemsIds={contactsIds}
                        dropdownLabel={renderFilterTitle(selectedContactIds, 'Contacts', 'Contact(s)')}
                        filterName="Contacts"
                        type="member"
                        className={classes}
                        dropDownClass={dropdownClass}
                        dataCypress="contactFilter"
                      />
                      <Dropdown
                        position="bottom"
                        activeKey={selectedCategory}
                        label="Category"
                        type={isCategorySelected ? 'primary' : 'input'}
                        outlined={!!isCategorySelected}
                        name="auditCategories"
                        onSelect={(value) => handleChange('auditCategories', value)}
                        dataCypress="category"
                      >
                        {categories.map(renderList)}
                      </Dropdown>
                      <Dropdown
                        position="bottom"
                        activeKey={selectedAction}
                        label="Action"
                        type={isActionSelected ? 'primary' : 'input'}
                        outlined={!!isActionSelected}
                        name="auditActions"
                        onSelect={(value) => handleChange('auditActions', value)}
                        dataCypress="action"
                      >
                        {actions.map(renderList)}
                      </Dropdown>
                    </UtilityInlineGrid>
                  </div>
                  <div className="audit-log__pagination-wrapper">
                    {isPaginationControlsVisible && renderPaginationControls()}
                  </div>
                </div>
              </div>
              {renderAuditData()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AuditLog.propTypes = {
  actions: PropTypes.array.isRequired,
  activeKey: PropTypes.number.isRequired,
  auditLogData: PropTypes.array.isRequired,
  calculateDateRange: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
  contacts: PropTypes.object.isRequired,
  contactsIds: PropTypes.array.isRequired,
  datePickerDropdownLabels: PropTypes.array.isRequired,
  endDate: PropTypes.object.isRequired,
  expandAll: PropTypes.func,
  expandButtonText: PropTypes.string,
  expanded: PropTypes.object,
  expandRow: PropTypes.func,
  fetchFilterData: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClearSelectedContacts: PropTypes.func.isRequired,
  handleClearSelectedMembers: PropTypes.func.isRequired,
  handlePagination: PropTypes.func,
  handleSort: PropTypes.func.isRequired,
  handleUpdateSelectedIds: PropTypes.func.isRequired,
  isAuditLogLoading: PropTypes.bool.isRequired,
  isDateSelected: PropTypes.bool.isRequired,
  isNextDisabled: PropTypes.bool,
  isPreviousDisabled: PropTypes.bool,
  maxDate: PropTypes.object.isRequired,
  members: PropTypes.object.isRequired,
  membersIds: PropTypes.array.isRequired,
  minDate: PropTypes.object.isRequired,
  pageItemIndexFrom: PropTypes.number,
  pageItemIndexTo: PropTypes.number,
  selectDate: PropTypes.func.isRequired,
  selectedAction: PropTypes.string.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  selectedContactIds: PropTypes.array.isRequired,
  selectedContacts: PropTypes.object.isRequired,
  selectedMemberIds: PropTypes.array.isRequired,
  selectedMembers: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  auditLogHeadersConfig: PropTypes.object.isRequired,
};

export default AuditLog;
