import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ResourceIntro,
  Resource,
  ResourceGroup,
  ResourceRight,
  Scrollbars,
  Icon,
} from 'rhinostyle';
import cx from 'classnames';

import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import * as OrganizationReducer from '../reducers/organizationReducer';
import { fetchAppointmentTypes } from '../reducers/appointmentTypesReducer';
import { fetchAppointmentCampaigns } from '../reducers/appointmentCampaignReducer';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { getLoggedInUserPermissionNames } from '../selectors/userSelectors';
import { AUTOMATED_MESSAGE_EDIT } from '../constants/UserPermissionsConstants';
import AppointmentCampaignSummaryPanel from './AppointmentCampaignSummaryPanel';
import EmptyMessage from './EmptyMessage';
import PageLoader from './PageLoader';
import LazyLoader from './LazyLoader';

const AppointmentCampaigns = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const scrollContainerRef = useRef();
  const [pageNumber, setPageNumber] = useState(0);

  const {
    currentOrganization,
    loggedInUserPermissions,
    appointmentCampaigns,
    loading,
    appointmentCampaignIds,
  } = props;

  useEffect(() => {
    dispatch(fetchAppointmentTypes(currentOrganization.id));
    dispatch(fetchAppointmentCampaigns({ orgId: currentOrganization.id, pageNumber }));
    dispatch(OrganizationReducer.fetchOrganizationFromAutomatedMessages());
  }, []);

  const handleTogglePanel = (appointmentCampaignId) => {
    if (appointmentCampaignId && Number(params.appointmentCampaignId) !== appointmentCampaignId) {
      history.push(`/settings/organization/appointment-reminders/${appointmentCampaignId}`);
    } else {
      history.push('/settings/organization/appointment-reminders');
    }
  };

  const handleCreateClick = () => {
    if (!currentOrganization.defaultApptReminderChannelId) {
      history.push('/settings/organization/appointment-reminders/default-channel');
    } else {
      history.push('/settings/organization/appointment-reminders/create');
    }
  };

  const onScroll = () => {
    const scrollContainer = scrollContainerRef.current.container.firstChild;
    const totalScroll = scrollContainer.scrollTop + scrollContainer.clientHeight;
    if (totalScroll === scrollContainer.scrollHeight) {
      setPageNumber((current) => current + 1);
      dispatch(fetchAppointmentCampaigns({ orgId: currentOrganization.id, pageNumber }));
    }
  };

  const renderAppointmentCampaigns = () => (
    <ResourceGroup>
      {appointmentCampaignIds.map((id) => {
        const appointmentCamaign = appointmentCampaigns[id];
        return (
          <Resource key={`appointmentCampaign-${id}`} data-feature-tag="appointment-reminders_summary-route" onClick={() => handleTogglePanel(id)}>
            <ResourceIntro
              title={appointmentCamaign.name}
              titleSub={appointmentCamaign.default ? <span className="u-text-accent">Default</span> : null}
            />
            {(appointmentCamaign.appointmentTypes?.length > 0 || appointmentCamaign.offices.length > 0) && (
            <ResourceRight>

              {appointmentCamaign.appointmentTypes?.length > 0 && `AppointmentTypes (${appointmentCamaign.appointmentTypes.length})`}
              <div>
                {appointmentCamaign.offices.length > 0 && ` Offices (${appointmentCamaign.offices.length})`}
              </div>
            </ResourceRight>
            )}
          </Resource>
        );
      })}
    </ResourceGroup>
  );

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': params.appointmentCampaignId,
  });
  if (pageNumber === 0 && loading) {
    return <PageLoader />;
  }
  return (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="list-panel__body" data-feature-tag="appointmentReminderList">
              <Scrollbars className="library-scroll__container" ref={scrollContainerRef} onScroll={onScroll}>

                <div className="list-panel">

                  <div className="app-page__header">
                    <div className="app-page__header__title">
                      Appointment Reminders
                    </div>
                    {loggedInUserPermissions.includes(AUTOMATED_MESSAGE_EDIT) && (
                    <div className="app-page__header__action">
                      <Button
                        onClick={handleCreateClick}
                        iconOnly
                        type="secondary"
                        title="Create Group"
                        data-feature-tag="appointmentCampaignAdd"
                      >
                        <Icon icon="add" />
                      </Button>
                    </div>
                    )}
                  </div>
                  {appointmentCampaignIds?.length > 0 ? renderAppointmentCampaigns() : <EmptyMessage section="Appointment Reminders" />}
                </div>
              </Scrollbars>

            </div>
          </div>
        </div>
        <LazyLoader loading={loading} />
        {params.appointmentCampaignId && (
        <AppointmentCampaignSummaryPanel
          appointmentCampaign={appointmentCampaigns[Number(params.appointmentCampaignId)]}
          handleTogglePanel={handleTogglePanel}
        />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const currentOrganization = getCurrentOrg(state);
  const { appointmentCampaign } = state;
  return {
    currentOrganization,
    loggedInUserPermissions: getLoggedInUserPermissionNames(state),
    appointmentCampaigns: appointmentCampaign.appointmentCampaigns,
    appointmentCampaignIds: appointmentCampaign.appointmentCampaignIds,
    loading: appointmentCampaign.loading,
  };
};

AppointmentCampaigns.propTypes = {
  currentOrganization: PropTypes.object,
  loggedInUserPermissions: PropTypes.array,
  appointmentCampaignIds: PropTypes.array,
  appointmentCampaigns: PropTypes.object,
  loading: PropTypes.bool,
};

export default connect(mapStateToProps)(AppointmentCampaigns);
