import React, { useEffect, useState } from 'react';
import { Input, Radio, RadioGroup, Icon, Modal, Button, ModalHeader, ModalFooter, UtilityInlineGrid } from 'rhinostyle';
import { connect, useDispatch } from 'react-redux';
import { getLoggedInUser } from '../selectors/userSelectors';
import TagsGroup from './TagsGroup';
import { TAG_EDIT } from '../constants/UserPermissionsConstants';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { MODAL_OPTIONS } from '../constants/AppConstants';
import { toggleModal } from '../reducers/uiReducer';
import { updateArrayById } from '../helpers/DataHelpers';
import { updateBulkUserTags } from '../reducers/userReducer';
import { createTag } from '../reducers/tagReducer';
import { TYPE_TAG_CUSTOM, TYPE_TAG_DEPARTMENTS, TYPE_TAG_LOCATIONS, TYPE_TAG_ROLES } from '../constants/Types';
import { ValidationService, ValidationShapers } from '../services/ValidationService';

const BulkTagModal = (props) => {
  const dispatch = useDispatch();
  const {
    error,
    clearAllFilters,
    loading,
    selectedUserIds,
    tags,
    tagIds,
    filteredTagIds,
    isBulkTagModalOpen,
  } = props;
  const initialTagOptions = { name: '', typeId: '', errors: false };

  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [createTagActive, setCreateTagActive] = useState(false);
  const [createTagOptions, setCreateTagOptions] = useState(initialTagOptions);
  const [createTagLoading, setCreateTagLoading] = useState(false);

  useEffect(() => {
    if (isBulkTagModalOpen) {
      setSelectedTagIds(filteredTagIds);
      setCreateTagOptions(initialTagOptions);
      setCreateTagActive(false);
    }
  }, [isBulkTagModalOpen]);

  useEffect(() => {
    if (createTagLoading) {
      setErrors(false);
      handleCreateTag();
    }
  }, [createTagLoading]);

  useEffect(() => {
    if (!createTagOptions?.errors && error?.property === 'name') {
      setErrors({ name: error.message });
    }
  }, [error]);

  function handleSelectTag(e, id) {
    setSelectedTagIds((current) => updateArrayById(current, id));
  }

  function closeModal() {
    dispatch(toggleModal(MODAL_OPTIONS.bulkTags));
  }

  function setErrors(value) {
    setCreateTagOptions((current) => ({ ...current, errors: value }));
  }

  async function handleCreateTag() {
    const validationErrors = ValidationService(ValidationShapers.shapeCreateTag(createTagOptions));
    const errorCount = Object.keys(validationErrors).length;
    if (errorCount > 0) {
      setErrors(validationErrors);
    } else {
      const newTag = await dispatch(createTag(createTagOptions));
      if (newTag) {
        handleSelectTag(null, newTag.tagId);
        setCreateTagOptions(initialTagOptions);
      }
    }
    setCreateTagLoading(false);
  }

  function handleChange(name, value) {
    setCreateTagOptions((current) => ({ ...current, [name]: value }));
  }

  const updateTags = () => {
    const { tagsToAdd, tagsToRemove } = getTagUpdates();
    dispatch(updateBulkUserTags({
      tagsToAdd, tagsToRemove, userIds: selectedUserIds,
    })).then(() => {
      closeModal();
      clearAllFilters();
    });
  };

  function getTagUpdates() {
    // Add any newly selected tags
    const tagsToAdd = selectedTagIds.filter((id) => !filteredTagIds.includes(id));
    // Remove any filtered tags that have been unselected
    const tagsToRemove = filteredTagIds.filter((id) => !selectedTagIds.includes(id));
    return { tagsToAdd, tagsToRemove };
  }

  const renderTagCreate = () => (
    <>
      <Input
        label=""
        placeholder="New Tag Name"
        name="name"
        required
        validationMessage={createTagOptions?.errors?.name}
        initialValue={createTagOptions.name}
        onChange={handleChange}
        className="tag__modal__input"

      />
      <RadioGroup
        label="Tag Category"
        name="typeId"
        onChange={(value) => handleChange('typeId', value)}
        selectedValue={createTagOptions.typeId}
        inline
        className="u-p-b"
      >
        <Radio value={TYPE_TAG_LOCATIONS} label="Location" dataCypress="locationTag" />
        <Radio value={TYPE_TAG_DEPARTMENTS} label="Department" dataCypress="departmentTag" />
        <Radio value={TYPE_TAG_ROLES} label="Role" dataCypress="roleTag" />
        <Radio value={TYPE_TAG_CUSTOM} label="Custom" dataCypress="customTag" />
        <Button
          onClick={() => setCreateTagLoading(true)}
          type="secondary"
          title="Create Tag"
          className="u-m-l-large"
          size="small"
          loading={createTagLoading}
          disabled={!(createTagOptions.name && createTagOptions.typeId) || loading}
        >
          Add New Tag
        </Button>
      </RadioGroup>
    </>
  );

  return (
    <Modal
      open={isBulkTagModalOpen}
      modalClass="broadcast-modal__container broadcast-modal__container--tags"
      modalDialogClass="broadcast-modal"
    >
      <ModalHeader
        onClose={closeModal}
        title={`${filteredTagIds.length ? 'Manage' : 'Add'} Tags`}
        titleSub="Improve search and filtering by including tags"
      />
      <div className="u-p-l-large">
        <div className="tag__modal__create u-text-secondary u-p-b">
          <Button
            onClick={() => setCreateTagActive((current) => !current)}
            iconOnly
            type="secondary"
            title="Create Tag"
            className="u-m-r-small"
          >
            <Icon icon={createTagActive ? 'close' : 'add'} size="small" />
          </Button>
          ADD NEW TAG
        </div>
        {createTagActive && (renderTagCreate())}
      </div>
      <TagsGroup
        activeTagIds={selectedTagIds}
        tags={tags}
        tagIds={tagIds}
        title=""
        isCreateLinkHidden
        hasInlineCreate
        onClick={handleSelectTag}
        tagsDisabled={!userHasAnyOfPermissions([TAG_EDIT])}
      />
      <ModalFooter>
        <UtilityInlineGrid align="right">
          <Button
            type="link"
            className="u-p-l-0"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={updateTags}
            loading={loading}
            disabled={(!getTagUpdates().tagsToRemove.length && !getTagUpdates().tagsToAdd.length) || createTagLoading}
            className="button button--primary"
          >
            {filteredTagIds.length ? 'Update' : 'Apply'} Tags
          </Button>
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { channel, form, tag, ui, user } = state;
  return {
    channels: channel.channels,
    user: getLoggedInUser(state),
    isRhinoformEnabled: !!form.org?.isRhinoformEnabled,
    tags: tag.tags,
    tagIds: tag.tagIds,
    isBulkTagModalOpen: ui.isBulkTagModalOpen,
    loading: user.loading,
    error: ui.error?.data,
  };
};

export default connect(mapStateToProps)(BulkTagModal);
