import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button } from 'rhinostyle';
import { TYPE_PATIENT, TYPE_USER_OTHER, TYPE_UNKNOWN } from '../constants/Types';
import { getLoggedInUser } from '../selectors/userSelectors';
import PdfViewerContainer from '../containers/PdfViewerContainer';
import { getAttachmentUrl } from '../helpers/SavedContentHelpers';
import { getFormTemplateById, getFormById } from '../reducers/formReducer';
import { useContactForms } from '../hooks';

const LinkedForms = (props) => {
  const dispatch = useDispatch();
  const [isFormPdfViewerOpen, setIsFormPdfViewerOpen] = useState(false);
  const [activeForm, setActiveForm] = useState(null);
  const { event, breakBeforeFirstAttachment, currentUser, otherUserForms, formS3Url } = props;
  const isPatient = [TYPE_PATIENT, TYPE_USER_OTHER, TYPE_UNKNOWN].includes(currentUser.typeId) && !currentUser.isCcr;
  const eventForms = event.modelVersion === '3.0' ? event.forms : event.rhinoFormContent;
  const contactForms = useContactForms(isPatient ? currentUser.id : null);
  // Handles opening animation
  useEffect(() => {
    if (activeForm) {
      setIsFormPdfViewerOpen(true);
    }
  }, [activeForm]);

  // Handles closing animation
  useEffect(() => {
    if (!isFormPdfViewerOpen) {
      setActiveForm(null);
    }
  }, [isFormPdfViewerOpen]);

  function shapeForm(form) {
    return {
      formId: form.formId || form.url,
      title: form.title || form.name,
    };
  }

  function handlePdfViewerClose() {
    setIsFormPdfViewerOpen(false);
  }

  async function togglePdfViewer(form) {
    if (form?.formId) {
      let activeUserForm = contactForms.find(((formTemplate) => formTemplate._id === form.formId));
      if (activeUserForm) {
        await dispatch(getFormTemplateById(activeUserForm.formTemplateId));
      } else {
        // Handles when form message is sent through Rhinoblast to contact who does not own phone
        await dispatch(getFormById(form?.formId));
        activeUserForm = otherUserForms[form.formId];
      }
      setActiveForm(activeUserForm);
    } else {
      handlePdfViewerClose();
    }
  }

  const renderPatientView = (form) => {
    const patientForm = contactForms?.find((userForm) => userForm._id === form.formId);
    if (patientForm?.formUrl) {
      return (
        <a href={patientForm.formUrl} className="u-text-underline" target="_blank" rel="noopener noreferrer">{patientForm.formUrl}</a>
      );
    } return <span>Deleted Form</span>;
  };

  const renderMemberView = (form) => (
    <>
      {activeForm && (
        <PdfViewerContainer
          open={isFormPdfViewerOpen}
          attachmentUrl={getAttachmentUrl(formS3Url[activeForm?.formTemplateId]?.formS3Url, formS3Url[activeForm?.formTemplateId]?.createdBy)}
          fileName={form?.title}
          handlePdfViewerClose={handlePdfViewerClose}
        />
      )}
      <Button className="u-text-underline" reset onClick={() => togglePdfViewer(form)}>{form.title}</Button>
    </>
  );
  return (
    <>
      {breakBeforeFirstAttachment && (<br />)}
      {eventForms.map((eventForm) => {
        const form = shapeForm(eventForm);
        return (
          <div key={form.formId}>
            {isPatient ? renderPatientView(form) : renderMemberView(form)}
          </div>
        );
      })}
    </>
  );
};
const mapStateToProps = (state) => ({
  currentUser: getLoggedInUser(state),
  formS3Url: state.form.formS3Url,
  otherUserForms: state.form.otherUserForms,
});
export default connect(mapStateToProps)(LinkedForms);
