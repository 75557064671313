import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ChannelReducer from '../reducers/channelReducer';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getActiveChannelIds } from '../selectors/channelSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import OrganizationChannels from '../components/OrganizationChannels';
import { PhoneHelpers, ChannelHelpers, DataHelpers } from '../helpers';

import PageLoader from '../components/PageLoader';

class OrganizationChannelsContainer extends Component {
  state = {
    activeChannelId: null,
    panelOpen: !!this.props.match.params.channelId,
    formInProgress: false,
    pageLoading: true,
  };

  componentDidMount() {
    this.props.fetchChannelsView();
  }

  componentDidUpdate(prevProps) {
    const incomingChannelId = this.props.match.params.channelId;
    const { activeChannelId } = this.state;
    const newActiveChannelId = Number(incomingChannelId);

    if (incomingChannelId && incomingChannelId !== activeChannelId && activeChannelId !== newActiveChannelId) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        activeChannelId: newActiveChannelId,
        panelOpen: !!newActiveChannelId,
      });
    }

    if (activeChannelId && !newActiveChannelId) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        activeChannelId: null,
        panelOpen: !!this.props.match.params.channelId,
      });
    }

    if (prevProps.channelsLoading !== this.props.channelsLoading) {
      this.setState({ pageLoading: this.props.channelsLoading }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  handleEditClick = () => {
    const channelId = this.state.activeChannelId;

    this.props.history.push(`/settings/organization/channels/edit/${channelId}`);
  }

  handleTogglePanel = (channelId) => {
    const shouldClose = this.state.activeChannelId === channelId;
    if (shouldClose) {
      this.props.history.push('/settings/organization/channels');
    } else if (this.state.activeChannelId !== channelId) {
      this.props.history.push(`/settings/organization/channels/${channelId}`);
    }
  }

  allChannels = () => ({
    ...this.shapeChannels(this.props.channelIds, this.props.channels),
  });

  sortedChannelIds = () => [
    ...this.props.channelIds,
  ];

  shapeChannels(channelIds, channels) {
    return channelIds.reduce((acc, channelId) => {
      const channel = this.shapeChannelForRender(channels[channelId]);

      return { ...acc, [channel.id]: channel };
    }, {});
  }

  shapeChannelForRender(channel) {
    const { typeId } = channel;

    const shapedChannel = {
      afterHours: channel.afterHours,
      businessHours: channel.businessHours ? channel.businessHours : [],
      forwarding: channel.allowReroute ? 'Enabled' : 'Disabled',
      icon: ChannelHelpers.getChannelIcon(typeId),
      id: channel.id,
      isDefaultSecureNotificationsChannel: channel.id === this.props.currentOrganization.defaultChannelId,
      name: channel.name,
      purpose: channel.purpose,
      route: ChannelHelpers.formatRoute(channel.route),
      tags: channel.tags ? channel.tags.map((id) => this.props.tags[id]) : [],
      timeZone: this.props.timeZones[channel.timeZoneId],
      isRhinocallEnabled: channel.isRhinocallEnabled ? 'Enabled' : null,
    };

    if (channel.details && channel.details.phone && DataHelpers.hasData(this.props.phones)) {
      shapedChannel.phone = PhoneHelpers.formatPhone(this.props.phones[channel.details.phone].value);
    }

    return shapedChannel;
  }

  render() {
    if (this.state.pageLoading) {
      return <PageLoader />;
    }

    const props = {
      activeChannelId: this.state.activeChannelId,
      channelIds: this.sortedChannelIds(),
      channels: this.allChannels(),
      formInProgress: this.state.formInProgress,
      handleEditClick: this.handleEditClick,
      handleTogglePanel: this.handleTogglePanel,
      panelOpen: this.state.panelOpen,
      userIsCcr: this.props.user.isCcr,
    };

    return <OrganizationChannels {...props} />;
  }
}

OrganizationChannelsContainer.propTypes = {
  channelIds: PropTypes.array.isRequired,
  channels: PropTypes.object.isRequired,
  channelsLoading: PropTypes.bool,
  currentOrganization: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  fetchChannelsView: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  phones: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  timeZones: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { channel, phone, type, timeZone, tag } = state;

  return {
    channelIds: getActiveChannelIds(state),
    channels: channel.channels,
    channelsLoading: channel.loading,
    currentOrganization: getCurrentOrg(state),
    user: getLoggedInUser(state),
    phones: phone.phones,
    tags: tag.tags,
    timeZones: timeZone.timeZones,
    types: type.types,
  };
};

const actions = {
  fetchChannelsView: ChannelReducer.fetchChannelsView,
};

export default connect(mapStateToProps, actions)(OrganizationChannelsContainer);
