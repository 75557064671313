import PropTypes from 'prop-types';
import React from 'react';
import { Close } from 'rhinostyle';

const Overlay = (props) => <div className="app-overlay" onClick={props.closeNavigation}><Close className="app-page__header__action__close app-overlay__close" /></div>;

Overlay.propTypes = {
  closeNavigation: PropTypes.func,
};

export default Overlay;
