import PropTypes from 'prop-types';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Alert, Button, Table } from 'rhinostyle';

import rhinoLogoHorizontal from '../assets/images/rhinogram-logo-horizontal.svg';
import { StringHelpers, DataHelpers } from '../helpers';

const Diagnostics = (props) => {
  const { userData, deviceItems, emailSending, emailSent, emailError, handleShareWithSupport } = props;

  // Normalizes some labels coming from state
  const transformLabel = (label) => {
    let transformedLabel = label;

    switch (transformedLabel) {
      case 'user_id':
        transformedLabel = 'User ID';
        break;
      case 'organization_id':
        transformedLabel = 'Organization ID';
        break;
      case 'ip':
        transformedLabel = 'IP Address';
        break;
      case 'loc':
        transformedLabel = 'Geocoordinates';
        break;
      case 'org':
        transformedLabel = 'Internet Provider';
        break;
      case 'postal':
        transformedLabel = 'Zip Code';
        break;
      case 'os':
        transformedLabel = 'Operating System';
        break;
      case 'screenResolution':
        transformedLabel = 'Screen Resolution';
        break;
      case 'cookiesEnabled':
        transformedLabel = 'Cookies Enabled';
        break;
      default:
        break;
    }

    return transformedLabel;
  };

  // Render individual item
  const renderItem = (item) => (
    <tr key={item[0]}>
      <td>
        <strong>{StringHelpers.capitalize(transformLabel(item[0]))}</strong>
      </td>
      <td>{item[1]}</td>
    </tr>
  );

  const renderData = () => (
    <>
      {DataHelpers.hasData(userData) && (
        <div className="box">
          <div className="box__title-wrapper">
            <div className="box__title">User Information</div>
          </div>
          <Table>
            <tbody>{Object.entries(userData).map((item) => renderItem(item))}</tbody>
          </Table>
        </div>
      )}
      <div className="box">
        <div className="box__title-wrapper">
          <div className="box__title">Device Information</div>
        </div>
        <Table>
          <tbody>{Object.entries(deviceItems).map((item) => renderItem(item))}</tbody>
        </Table>
      </div>
    </>
  );

  const renderFooter = () => {
    if (emailSent) {
      return (
        <Alert className="u-m-t-large" type="success">
          Your email has been sent successfully. We will follow up with you shortly.
        </Alert>
      );
    } else if (emailError) {
      return (
        <Alert className="u-m-t-large" type="error">
          There was an error sending your request. Please reach us at{' '}
          <a href="mailto:success@rhinogram.com">success@rhinogram.com</a>
        </Alert>
      );
    }

    return (
      <div className="box">
        <div className="box__title-wrapper">
          <div className="box__title">Support</div>
          <div className="box__subtitle">
            Click the button below to share this information with a Customer Success Representative.
          </div>
        </div>
        <div className="u-text-center">
          <Button
            size="small"
            type="primary"
            outlined
            loading={emailSending}
            onClick={() => handleShareWithSupport(ReactDOMServer.renderToStaticMarkup(renderData()))}
          >
            Share with CSR
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="app-page__standalone">
      <div className="app-page__container__inner" style={{ height: '100vh' }}>
        <div className="u-text-center u-m-b-large">
          <a className="u-inline-block" href="/">
            <img src={rhinoLogoHorizontal} alt="Rhinogram" className="app-logo__standalone" />
            <div className="u-text-muted u-m-t-small u-text-small">Back to Rhinogram</div>
          </a>
        </div>
        <div className="app-page__header">
          <div className="app-page__header__title">Diagnostics</div>
        </div>
        {renderData()}
        {renderFooter()}
      </div>
    </div>
  );
};

Diagnostics.propTypes = {
  userData: PropTypes.object.isRequired,
  deviceItems: PropTypes.object.isRequired,
  handleShareWithSupport: PropTypes.func.isRequired,
  emailSending: PropTypes.bool.isRequired,
  emailSent: PropTypes.bool.isRequired,
  emailError: PropTypes.bool.isRequired,
};

export default Diagnostics;
