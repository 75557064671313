import { createSelector } from '@reduxjs/toolkit';

const getSavedList = (state) => state.user.contactList;

export const getKeyedSavedList = createSelector(
  [getSavedList],
  (savedList) => {
    const keyedObj = {};
    savedList.forEach((list) => {
      keyedObj[list.id] = { ...list, title: list.name };
    });
    return keyedObj;
  },
);

export const getSavedListIds = createSelector(
  [getSavedList],
  (savedList) => savedList?.map((list) => list.id),
);
