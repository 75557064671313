export const TYPE_APPOINTMENT_STATUS_UNCONFIRMED = 81;
export const TYPE_APPOINTMENT_STATUS_CONFIRMED = 82;
export const TYPE_APPOINTMENT_STATUS_CANCELLED = 83;
export const PAST_APPOINTMENT_OPTIONS = [{
  id: 1,
  label: 'Today',
  duration: 0,
}, {
  id: 2,
  label: 'Yesterday',
  duration: 1,
}, {
  id: 3,
  label: 'Past 7 Days',
  duration: 6,
}, {
  id: 4,
  label: 'Past 30 Days',
  duration: 29,
}, {
  id: 5,
  label: 'Custom',
}];

export const UPCOMING_APPOINTMENT_OPTIONS = [{
  id: 1,
  label: 'Today',
  duration: 0,
}, {
  id: 2,
  label: 'Tomorrow',
  duration: 1,
}, {
  id: 3,
  label: 'Next 7 Days',
  duration: 6,
}, {
  id: 4,
  label: 'Next 30 Days',
  duration: 29,
}, {
  id: 5,
  label: 'Custom',
}];

export const NAVIGATION_TABS = {
  upcomingAppointment: 1,
  pastAppointment: 2,
};
