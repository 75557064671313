import { useState, useEffect, useMemo } from 'react';
import { cloneDeep } from '../helpers/DataHelpers';

const useDropdownMultiSelect = (props) => {
  const {
    itemIds = [],
    items = {},
    name,
    titleField = 'name',
    updateFilters,
    shapeLabel,
    defaultSortDirection = 'asc',
  } = props;

  const [filteredIds, setFilteredIds] = useState(itemIds || []);
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);

  const shapeListItems = useMemo(() => {
    const itemsCopy = cloneDeep(items);
    if (itemIds.length > 0) {
      itemIds.forEach((itemId) => {
        itemsCopy[itemId] = {
          ...itemsCopy[itemId],
          title: itemsCopy[itemId]?.[titleField],
          label: shapeLabel ? shapeLabel(itemsCopy[itemId]?.[titleField]) : itemsCopy[itemId]?.[titleField],
        };
      });
    }
    return itemsCopy;
  }, [items, itemIds, titleField, shapeLabel]);

  const sortItems = (ids, direction) => [...ids].sort((a, b) => {
    const comparison = items[a][titleField].toLowerCase().localeCompare(items[b][titleField].toLowerCase());
    return direction === 'asc' ? comparison : -comparison;
  });

  const handleSort = (direction) => {
    let newSortDirection = direction === 'default' ? defaultSortDirection : sortDirection;
    if (direction !== 'default') {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
      setSortDirection(newSortDirection);
    }
    setFilteredIds((prevFilteredIds) => sortItems(prevFilteredIds, newSortDirection));
  };

  useEffect(() => {
    if (filteredIds !== itemIds) {
      setFilteredIds([...itemIds]);
    }
    handleSort('default');
  }, [itemIds]);

  const filterItems = (searchText) => {
    if (searchText?.length > 2) {
      const filtered = itemIds.filter((id) => items[id]?.[titleField].toLowerCase().includes(searchText.toLowerCase()));
      setFilteredIds(sortItems(filtered, sortDirection));
    } else {
      setFilteredIds(sortItems(itemIds, sortDirection));
    }
  };

  const handleUpdateSelectedIds = (ids) => {
    const validIds = ids.filter((id) => items[id]);
    updateFilters(name, validIds);
  };

  const handleClearSelectedIds = () => {
    updateFilters(name, []);
  };

  return {
    filteredIds,
    filterItems,
    sortDirection,
    handleUpdateSelectedIds,
    handleClearSelectedIds,
    handleSort,
    shapedItems: shapeListItems,
  };
};

export default useDropdownMultiSelect;
