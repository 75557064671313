import React, { useState } from 'react';
import { Button, Close } from 'rhinostyle';
import cx from 'classnames';

const FileInformationTable = ({
  text = true,
  facebook = true,
  secure = true,
  typeClass,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const tableClasses = cx('table template-form__table template-form__table', {
    [`template-form__table--${typeClass}`]: typeClass,
  });
  return (
    <table className={tableClasses}>
      <tbody>
        <tr className="template-form__table__header">
          <td>Message Type And File Delivery</td>
          <td>
            {isOpen ? (
              <Close
                onClick={() => setIsOpen(false)}
              />
            ) : (
              <Button
                onClick={() => setIsOpen(true)}
                type="link"
              >Details
              </Button>
            )}
          </td>
        </tr>
        {isOpen && (
          <>
            {text && (
            <>
              <tr>
                <td>
                  <strong className="template-form__table__data-category">Texting</strong>
                </td>
                <td />
              </tr>
              <tr>
                <td className="table__data--active template-form__table__data-description">PNG, JPEG, JPG, BMP, and GIF files up to 1MB</td>
                <td className="table__data--active">Attachment</td>
              </tr>
              <tr>
                <td className="table__data--active template-form__table__data-description">File sizes 1MB to 50MB, and all types including PDFs and videos</td>
                <td className="table__data--active">Share Link</td>
              </tr>
            </>
            )}
            {secure && (
            <>
              <tr>
                <td>
                  <strong className="template-form__table__data-category">RhinoSecure and Team Messages</strong>
                </td>
                <td />
              </tr>
              <tr>
                <td className="table__data--active template-form__table__data-description">PNG, JPEG, JPG, BMP, and GIF files up to 50MB</td>
                <td className="table__data--active">Attachment</td>
              </tr>
              <tr>
                <td className="table__data--active template-form__table__data-description">All other file types up to 50MB</td>
                <td className="table__data--active">Share Link</td>
              </tr>
            </>
            )}
            {facebook && (
            <>
              <tr>
                <td>
                  <strong className="template-form__table__data-category">Facebook Messenger</strong>
                </td>
                <td />
              </tr>
              <tr>
                <td className="table__data--active template-form__table__data-description">PNG, JPEG, JPG, BMP, and GIF files up to 25MB</td>
                <td className="table__data--active">Attachment</td>
              </tr>
              <tr>
                <td className="table__data--active template-form__table__data-description">All other file types up to 50MB</td>
                <td className="table__data--active">Share Link</td>
              </tr>
            </>
            )}
          </>
        )}
      </tbody>
    </table>
  );
};

export default FileInformationTable;
