import axios from 'axios';
import { createAction } from '@reduxjs/toolkit';
import NotificationService from '../services/NotificationService';
import * as UIActionTypes from '../constants/UIActionTypes';
import { getInboxContext } from '../selectors/inboxSelectors';
import threadService from '../services/threadService';

const setError = createAction(UIActionTypes.setError);

export function createAssignmentMember(payload, notify) {
  return (dispatch) =>
    axios.post('/assignment/assignMember', payload)
      .then((response) => {
        dispatch(threadService.util.invalidateTags(['Thread']));
        if (notify) {
          NotificationService('updateAssignment', response);
        }
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        if (notify) {
          NotificationService('updateAssignment', err.response);
        }
      });
}

export function createAssignmentGroup(payload, notify) {
  return (dispatch) =>
    axios.post('/assignment/assignGroup', payload)
      .then((response) => {
        dispatch(threadService.util.invalidateTags(['Thread']));
        if (notify) {
          NotificationService('updateAssignment', response);
        }
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        if (notify) {
          NotificationService('updateAssignment', err.response);
        }
      });
}

export function createAssignmentSelf(payload, notify) {
  return (dispatch) =>
    axios.post('/assignment/assignSelf', payload)
      .then((response) => {
        dispatch(threadService.util.invalidateTags(['Thread']));
        if (notify) {
          NotificationService('updateAssignment', response);
        }
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        if (notify) {
          NotificationService('updateAssignment', err.response);
        }
      });
}

export function closeAssignment(payload) {
  const route = payload.assigned ? 'closeAssigned' : 'closeDefault';
  return (dispatch) =>
    axios.post(`/assignment/${route}`, payload)
      .then((response) => {
        dispatch(threadService.util.invalidateTags(['Thread']));
        NotificationService(payload.conversationActionType, response);
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        NotificationService('updateAssignment', err.response);
      });
}

export function shapeInboxContentForCompletion(options, groupId) {
  return (dispatch, getState) => {
    const inboxContext = getInboxContext(getState());
    const payload = {
      ...options,
    };
    if (inboxContext === 'assigned') {
      payload.assigned = true;
    } else if (inboxContext === 'group') {
      payload.groupId = Number(groupId);
    } else if (inboxContext === 'direct') {
      payload.direct = true;
    }
    return dispatch(closeAssignment(payload));
  };
}
