import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormLabel,
  Input,
  UtilitySystem,
} from 'rhinostyle';
import { PRESCRIPTION_NOTIFICATION_EDIT, PRESCRIPTION_NOTIFICATION_DELETE } from '../constants/UserPermissionsConstants';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { ValidationHelpers } from '../helpers';
import { processCampaignAttachments } from '../helpers/AttachmentHelpers';
import BackButton from './BackButton';
import PageLoader from './PageLoader';
import FormActionButtons from './FormActionButtons';
import {
  useFetchPrescriptionCampaign,
} from '../hooks';
import {
  useDeletePrescriptionCampaignMutation,
  useUpdatePrescriptionCampaignMutation,
  useCreatePrescriptionCampaignMutation,
} from '../services/prescriptionService';
import NotificationService from '../services/NotificationService';
import PayorsSearchSelect from './PayorsSearchSelect';
import { fetchOffices } from '../reducers/officeReducer';
import { fetchFormTemplates } from '../reducers/formReducer';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import PrescriptionCampaignFormMessages from './PrescriptionCampaignFormMessages';
import FileUpload from './FileUpload';
import { PRESCRIPTION_MESSAGE_TEMPLATE } from '../constants/VariableMessageConstants';
import {
  TYPE_EVENT_DATE_CREATED_AT,
  TYPE_PRESCRIPTION_CAMPAIGN_INITAL_TYPE,
  TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_POST,
  TYPE_PRESCRIPTION_EVENT_REMINDER,
} from '../constants/Types';
import { sortMessagesByDeliveryHours } from '../helpers/PrescriptionHelpers';
import { cloneDeep } from '../helpers/DataHelpers';

const PrescriptionCampaignForm = () => {
  const history = useHistory();
  const params = useParams();
  const pageContainer = useRef();
  const dispatch = useDispatch();
  const [createCampaign] = useCreatePrescriptionCampaignMutation();
  const [updateCampaign] = useUpdatePrescriptionCampaignMutation();
  const [deleteCampaign] = useDeletePrescriptionCampaignMutation();
  const { isLoading, data: prescriptionCampaign } = useFetchPrescriptionCampaign();
  const organizationId = useSelector((state) => state.auth.currentOrg);
  const isRhinoformEnabled = useSelector((state) => !!state.form.org?.isRhinoformEnabled);
  const currentOrganization = useSelector((state) => getCurrentOrg(state));
  const { prescriptionCampaignId } = params;
  const defaultCampaign = {
    attachments: [],
    default: false,
    enabled: true,
    name: '',
    payors: [],
    payorSearch: '',
    messages: [],
  };
  const [formInputs, setFormInputs] = useState(defaultCampaign);
  const [errors, setErrors] = useState({});
  const [formInProgress, setFormInProgress] = useState(false);

  useEffect(() => {
    if (isRhinoformEnabled) {
      dispatch(fetchFormTemplates());
    }
    if (currentOrganization?.isOfficeVCardEnabled) {
      dispatch(fetchOffices());
    }
  }, []);

  useEffect(() => {
    if (!isLoading && prescriptionCampaign) {
      const sortedMessages = prescriptionCampaign.messages?.toSorted(sortMessagesByDeliveryHours) || [];
      const loadedCampaign = {
        attachments: prescriptionCampaign.attachments || [],
        default: !!prescriptionCampaign.default || false,
        enabled: !!prescriptionCampaign.enabled || true,
        name: prescriptionCampaign.name || '',
        payors: prescriptionCampaign.payors?.map((payor) => payor.id) || [],
        payorSearch: '',
        messages: sortedMessages.map((campaignMessage) => ({
          key: UtilitySystem.generateUUID(),
          campaignId: Number(campaignMessage.campaignId),
          deleted: !!campaignMessage.deleted,
          deliveryHours: Number(campaignMessage.deliveryHours),
          deliveryHoursDirection: Number(campaignMessage.deliveryHoursDirection),
          deliveryHoursType: Number(campaignMessage.deliveryHoursType),
          id: Number(campaignMessage.id),
          stopOnReply: !!campaignMessage.stopOnReply,
          template: campaignMessage.template,
          type: Number(campaignMessage.type),
        })) || [],
      };
      setFormInputs(loadedCampaign);
    }
  }, [prescriptionCampaign, isLoading]);

  if (isLoading) {
    return <PageLoader />;
  }

  const handleAttachmentsChange = (fieldName, value) => {
    const { attachments } = formInputs;
    const newAttachments = value.filter((v) => {
      const existingAttachment = attachments.find((a) => (a.attachmentUrl === v.attachmentUrl && a.appointmentEventTypeId === v.appointmentEventTypeId));
      return !existingAttachment;
    });
    if (newAttachments.length === 0) {
      const shapedAttachments = value.map((attachment) => ({
        ...attachment, appointmentEventTypeId: TYPE_PRESCRIPTION_EVENT_REMINDER,
      }));
      setFormInputs((current) => ({ ...current, attachments: shapedAttachments }));
    } else {
      const shapedAttachments = newAttachments.map((attachment) => ({
        ...attachment, appointmentEventTypeId: TYPE_PRESCRIPTION_EVENT_REMINDER,
      }));
      setFormInputs((current) => ({ ...current, attachments: [...attachments, ...shapedAttachments] }));
    }
  };

  const handleAddCampaignMessage = () => {
    const { messages } = formInputs;
    const messageInputs = [...messages];
    messageInputs.push({
      key: UtilitySystem.generateUUID(),
      campaignId: prescriptionCampaignId,
      deleted: false,
      deliveryHours: 0,
      deliveryHoursDirection: TYPE_PRESCRIPTION_CAMPAIGN_DELIVERY_DIRECTION_POST,
      deliveryHoursType: TYPE_EVENT_DATE_CREATED_AT,
      id: null,
      stopOnReply: false,
      template: PRESCRIPTION_MESSAGE_TEMPLATE,
      type: TYPE_PRESCRIPTION_CAMPAIGN_INITAL_TYPE,
    });
    setFormInputs((current) => ({ ...current, messages: messageInputs }));
  };

  const handleRemoveCampaignMessage = (campaignMessageKey) => {
    const { messages } = formInputs;
    const messageInputs = [...messages];
    const messageIndex = messages.findIndex((message) => message.key === campaignMessageKey);
    const campaignMessage = messageInputs[messageIndex];
    const clone = cloneDeep(campaignMessage);
    clone.deleted = true;
    messageInputs.splice(messageIndex, 1, clone);
    setFormInputs((current) => ({ ...current, messages: messageInputs }));
  };

  const handleCampaignMessageChange = (campaignMessageKey, field, value) => {
    const { messages } = formInputs;
    const messageInputs = [...messages];
    const messageIndex = messages.findIndex((message) => message.key === campaignMessageKey);
    messageInputs[messageIndex][field] = value;
    if (field !== 'template') {
      setFormInputs((current) => ({ ...current, messages: messageInputs }));
    }
  };

  const handleChange = (fieldName, value) => {
    setFormInputs((current) => ({ ...current, [fieldName]: value }));
  };

  const handleSubmit = async () => {
    setErrors({});
    const saveErrors = ValidationService(ValidationShapers.shapePrescriptionMessage({ ...formInputs }));
    const errorCount = Object.keys(saveErrors).length;
    if (errorCount > 0) {
      setErrors(saveErrors);
      ValidationHelpers.handleValidationErrors(errors, pageContainer.current);
    } else {
      setFormInProgress(true);
      const payload = {
        ...prescriptionCampaignId && {
          id: Number(prescriptionCampaignId),
        },
        ...formInputs,
        attachments: processCampaignAttachments(formInputs.attachments),
      };
      const response = prescriptionCampaignId ?
        await updateCampaign({ data: payload, organizationId, prescriptionCampaignId }) :
        await createCampaign({ data: payload, organizationId });
      setFormInProgress(false);
      const type = !prescriptionCampaignId ? 'create' : 'update';
      if (response?.data) {
        NotificationService(`${type}PrescriptionCampaign`, { status: 200 });
        history.push(`/settings/organization/prescription-notifications/${prescriptionCampaignId || response.data.id}`);
      } else {
        NotificationService(`${type}PrescriptionCampaign`, response?.error);
      }
    }
  };

  async function handleConfirmDeleteRequest() {
    const response = await deleteCampaign({ organizationId, prescriptionCampaignId });
    if (response?.data) {
      NotificationService('deletePrescriptionCampaign', { status: 200 });
      history.push('/settings/organization/prescription-notifications');
    } else {
      NotificationService('deletePrescriptionCampaign', response?.error);
    }
  }

  return (
    <div className="app-page__container" ref={pageContainer}>
      <div className="app-page__container__inner appointment-reminder-form">
        <div className="app-page__header">
          <div className="app-page__header__title">
            <BackButton navigateTo="/settings/organization/prescription-notifications" />
            {`${prescriptionCampaignId ? 'Edit' : 'Create'} Prescription Notification`}
          </div>
        </div>
        <div className="box">
          <div className="box__title-wrapper">
            <div className="box__title">Prescription Notification Details</div>
          </div>
          <div className="appointment-reminder-form__box-wrapper">
            <FormLabel
              id="name"
              className="variable-message__label u-m-t u-m-b-small"
              required
            >
              Name
            </FormLabel>
            <Input
              name="name"
              initialValue={formInputs.name}
              validationMessage={errors?.name}
              className="u-m-b"
              onChange={handleChange}
              explanationMessage="Prescription notification name must be unique."
              dataFeatureTag="prescriptionCampaignName"
              required
            />
          </div>
        </div>
        <PayorsSearchSelect
          selectedPayors={formInputs.payors}
          handleChange={handleChange}
          prescriptionCampaignId={prescriptionCampaign?.id}
          validationMessage={errors?.payors}
        />
        <div className="box">
          <FileUpload
            appointmentEventTypeId={TYPE_PRESCRIPTION_EVENT_REMINDER}
            attachments={formInputs.attachments}
            handleChange={handleAttachmentsChange}
          />
        </div>
        <PrescriptionCampaignFormMessages
          campaignFormInputs={formInputs}
          handleChange={handleCampaignMessageChange}
          handleAddCampaignMessage={handleAddCampaignMessage}
          handleRemoveCampaignMessage={handleRemoveCampaignMessage}
          errors={errors}
          isLoading={isLoading}
        />
        <div className="u-m-t-large">
          <FormActionButtons
            formInProgress={formInProgress}
            handleConfirmDeleteRequest={handleConfirmDeleteRequest}
            handleSubmit={handleSubmit}
            name="Notification"
            shortName="Notification"
            deletePermissions={[PRESCRIPTION_NOTIFICATION_DELETE]}
            editPermissions={[PRESCRIPTION_NOTIFICATION_EDIT]}
            dataFeatureTag="prescription-notification_"
            editMode={!!prescriptionCampaignId}
          />
        </div>
      </div>
    </div>
  );
};

export default PrescriptionCampaignForm;
