import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';

import PropTypes from 'prop-types';
import {
  Icon,
  Input,
  LoaderPulse,
  Tooltip,
} from 'rhinostyle';
import GroupList from './GroupList';
import { fetchGroupsAndPopulateSearchIds, fetchGroupSearch } from '../reducers/groupReducer';

const GroupSearch = (props) => {
  const {
    defaultRouteGroupId,
    groupSearchIds,
    groupSearchLoading,
    interfaceMode,
    selectedGroupId,
    selectedGroupIds,
    showTooltip,
    totalCount,
    excludeChatGroups,
    handleSearchSelect,
    excludedGroupIds,
    isHidden,
    prepopulate,
    pageNo,
  } = props;
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [searchFocus, setSearchFocus] = useState(false);
  const preSelectedForwardingGroupId = selectedGroupId;

  useEffect(() => {
    if (prepopulate) {
      handleFetchGroups();
    }
  }, [prepopulate]);

  useEffect(() => {
    if (isHidden) {
      handleClearSearch();
    } else {
      setSearchFocus(true);
    }
  }, [isHidden]);

  const debounceSearch = useRef(
    debounce(handleSearch, 300),
  );

  function handleFetchGroups(pageNumber = 0) {
    dispatch(fetchGroupsAndPopulateSearchIds(pageNumber, excludeChatGroups));
  }

  function handleClearSearch() {
    handleFetchGroups();
    setSearchText('');
    setSearchFocus(false);
  }

  function handleLoadMore() {
    if (searchText?.length > 2) {
      dispatch(fetchGroupSearch(searchText, excludeChatGroups, pageNo + 1));
    } else {
      handleFetchGroups(pageNo + 1);
    }
  }

  function handleSearch(name, value) {
    if (value && value.length > 2) {
      dispatch(fetchGroupSearch(value, excludeChatGroups));
    } else if (value.length === 0) {
      handleFetchGroups();
    }
    setSearchText(value);
  }

  function filteredGroupSearchIds() {
    const filteredGroups = groupSearchIds.filter((id) => !excludedGroupIds.includes(id) && id !== preSelectedForwardingGroupId);
    if (preSelectedForwardingGroupId > 0) {
      filteredGroups.unshift(preSelectedForwardingGroupId);
    }
    return filteredGroups;
  }

  function getTotalCount() {
    return totalCount - (groupSearchIds.length - filteredGroupSearchIds().length);
  }

  const renderSearchHelp = () => {
    if ((searchText.length === 0 || searchText.length > 2) && groupSearchLoading) {
      return (
        <div className="u-text-center">
          <LoaderPulse type="secondary" />
        </div>
      );
    } else if (searchText.length > 2 && !groupSearchIds.length && !groupSearchLoading) {
      return <div className="search__no-results">No results</div>;
    }
    return null;
  };

  if (isHidden) return null;

  return (
    <div className="search__group">
      <Input
        addon="left"
        className="search__input"
        initialValue={searchText}
        name="search"
        onChange={debounceSearch.current}
        placeholder="Search Groups"
        size="large"
        focus={searchFocus}
        autoComplete="off"
      >
        <Icon icon="search" />
      </Input>
      {showTooltip && (
        <div className="search__sub">
          All Groups &nbsp;
          <Tooltip content="Selecting a group below will copy the current members into your selected members for the current group.">
            <Icon icon="question-circle" />
          </Tooltip>
        </div>
      )}
      {groupSearchIds.length > 0 ? (
        <GroupList
          className="resource-group__scroll"
          autoHeight="large"
          interfaceMode={interfaceMode}
          handleSelect={handleSearchSelect}
          groupIds={filteredGroupSearchIds()}
          selectedGroupIds={[selectedGroupId] || selectedGroupIds}
          defaultRouteGroupId={defaultRouteGroupId}
          handleLoadMore={handleLoadMore}
          totalCount={getTotalCount()}
        />
      ) :
        renderSearchHelp()}
    </div>
  );
};

GroupSearch.propTypes = {
  defaultRouteGroupId: PropTypes.number,
  groupSearchIds: PropTypes.array.isRequired,
  groupSearchLoading: PropTypes.bool.isRequired,
  handleSearchSelect: PropTypes.func.isRequired,
  interfaceMode: PropTypes.string,
  selectedGroupId: PropTypes.number,
  selectedGroupIds: PropTypes.array,
  showTooltip: PropTypes.bool,
  totalCount: PropTypes.number,
  excludeChatGroups: PropTypes.bool,
  isHidden: PropTypes.bool,
  prepopulate: PropTypes.bool,
  excludedGroupIds: PropTypes.array,
  pageNo: PropTypes.number,

};

GroupSearch.defaultProps = {
  excludedGroupIds: [],
  prepopulate: false,
  selectedGroupId: -1,
  selectedGroupIds: [],
  showTooltip: true,
  excludeChatGroups: false,
  isHidden: false,
};

const mapStateToProps = (state) => {
  const { group } = state;

  return {
    groupSearchIds: group.groupSearchIds,
    groupSearchLoading: group.groupSearchLoading,
    totalCount: group.totalSearchCount,
    pageNo: group.searchPageNo,
  };
};

export default connect(mapStateToProps)(GroupSearch);
