import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import NotificationService from '../services/NotificationService';

import { setError } from './uiReducer';

// SLICE
const systemAlertSlice = createSlice({
  name: 'SYSTEMALERT',
  initialState: {
    loading: false,
    systemAlert: {},
  },
  reducers: {
    receiveSystemAlert: (state, action) =>
      ({
        ...state,
        systemAlert: action.payload.systemAlert,
        loading: false,
      }),
    requestData: (state) =>
      ({
        ...state,
        loading: true,
      }),
  },
});

export default systemAlertSlice.reducer;

// ACTIONS
const { receiveSystemAlert, requestData } = systemAlertSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

export function fetchSystemAlert() {
  return (dispatch) => {
    dispatch(requestData());
    return axios.get('/systemalerts')
      .then((response) => {
        dispatch(receiveSystemAlert({ systemAlert: response.data || {} }));
        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function updateSystemAlert(payload) {
  return (dispatch) =>
    axios.put('/systemalerts', payload)
      .then((response) => {
        dispatch(receiveSystemAlert({ systemAlert: response.data }));

        NotificationService('updateSystemAlert', response);

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);

        NotificationService('updateSystemAlert', err.response);

        dispatch(setError(err.response || err));
      });
}

export function receiveWebSocketEventSystemAlert(message) {
  return (dispatch) => {
    dispatch(receiveSystemAlert({ systemAlert: message }));
  };
}
