import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  UtilityInlineGrid,
  Scrollbars,
  SmartTable,
  Checkbox,
} from 'rhinostyle';
import { fetchOffices } from '../reducers/officeReducer';
import EmptyMessage from './EmptyMessage';
import { AppConstants } from '../constants';
import SmartTableHeader from './SmartTableHeader';
import { cloneDeep, getDefaultSortKey, compareObjectByKey } from '../helpers/DataHelpers';
import { MODAL_OPTIONS } from '../constants/ThreadConstants';
import { toggleModal, addVCardAttachment } from '../reducers/threadReducer';
import { VCARD_LIST_HEADERS_CONFIG } from '../constants/VCardConstants';

// In future enhancement we will use this Modal to to list all the VCards while sending the message.
const VCardModal = (props) => {
  const {
    closeModal,
    open,
    handleVCardAppend,
    currentOrganization,
    isInboxThread,
    selectedVCards,
  } = props;
  const threadModalOpen = useSelector((state) => state.thread[MODAL_OPTIONS.vCardModal]);
  const isOpen = isInboxThread ? threadModalOpen : open;
  const dispatch = useDispatch();
  const offices = useSelector((state) => state.office.offices);
  const officeIds = useSelector((state) => state.office.officeIds);
  const pageLoading = useSelector((state) => state.office.pageLoading);
  const [selectedVisitingCards, setselectedVisitingCards] = useState(selectedVCards || []);
  const [headersConfig, setHeadersConfig] = useState(cloneDeep(VCARD_LIST_HEADERS_CONFIG));
  const [sortKey, setSortKey] = useState(getDefaultSortKey(VCARD_LIST_HEADERS_CONFIG));
  const [sortAscending, setSortAscending] = useState(true);

  const setDefaultSort = () => {
    Object.keys(headersConfig).forEach((key) => {
      headersConfig[key].default = VCARD_LIST_HEADERS_CONFIG[key].default;
      headersConfig[key].direction = VCARD_LIST_HEADERS_CONFIG[key].direction;
    });
    setHeadersConfig(headersConfig);
  };

  // effects
  useEffect(() => {
    dispatch(fetchOffices());
  }, []);

  useEffect(() => {
    setselectedVisitingCards(selectedVCards);
  }, [selectedVCards]);

  const handleCloseModal = () => {
    if (isInboxThread) {
      dispatch(toggleModal(MODAL_OPTIONS.vCardModal));
    } else {
      closeModal();
    }
  };

  const getSortedVCards = () => {
    const vCards = [];
    officeIds?.forEach((officeId) => {
      if (offices[officeId]?.vCards?.length > 0) {
        vCards.push(offices[officeId].vCards);
      }
    });
    const sortedVCards = vCards.flat()?.sort((a, b) => compareObjectByKey(a, b, sortKey)) || [];
    if (headersConfig[sortKey].direction === -1) {
      sortedVCards.reverse();
    }
    return sortedVCards;
  };

  const setSortValues = (key) => {
    if (key === sortKey) {
      setSortAscending(!sortAscending);
    }
    setSortKey(key);
  };

  const handleAttachVCard = () => {
    if (isInboxThread) {
      dispatch(addVCardAttachment(selectedVisitingCards));
    } else {
      handleVCardAppend(selectedVisitingCards);
    }
  };

  const isVCardSelected = (vCard) => selectedVisitingCards?.some((vc) => (vc.attachmentUrl === vCard.id));

  const isSelectAll = () => {
    const vCardList = getSortedVCards();
    return vCardList.length === selectedVisitingCards.length;
  };

  const handleSelectAll = () => {
    const vCardList = getSortedVCards();
    if (vCardList.length !== selectedVisitingCards.length) {
      const vCardAttachments = [];
      officeIds?.forEach((officeId) => {
        const attachments = offices[officeId]?.vCards?.map((vCard) => ({
          attachmentUrl: vCard.id,
          name: vCard?.vCardName,
          type: AppConstants.VCARD_FILE_TYPE,
          key: `${currentOrganization.id}/${vCard.vCardUrl}`,
          bytes: 0,
        }));
        vCardAttachments.push(attachments);
      });
      setselectedVisitingCards(vCardAttachments.flat());
    } else {
      setselectedVisitingCards([]);
    }
  };

  const handleSelectVCard = (vCard) => {
    if (isVCardSelected(vCard)) {
      setselectedVisitingCards((current) => current.filter((vc) => vc.attachmentUrl !== vCard.id));
    } else {
      setselectedVisitingCards((current) => [...current, {
        attachmentUrl: vCard?.id,
        name: vCard?.vCardName,
        type: AppConstants.VCARD_FILE_TYPE,
        key: `${currentOrganization.id}/${vCard.vCardUrl}`,
        bytes: 0,
      },
      ]);
    }
  };

  const vCardColumns = [
    {
      Header: () => (
        <span className="template-model-checkbox">
          <Checkbox
            isChecked={isSelectAll()}
            onChange={() => handleSelectAll()}
            label="checkbox"
          />
        </span>
      ),
      accessor: 'id',
      sortable: false,
      fixed: 'left',
      width: 40,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex u-flex-justify-center',
      Cell: (row) => (
        <span className="template-model-checkbox" key={row.value}>
          <Checkbox
            name={row.original.vCardName}
            isChecked={isVCardSelected(row.original)}
            onChange={() => handleSelectVCard(row.original)}
            label="checkbox"
            key={row.value}
          />
        </span>
      ),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Display Name"
          sortKey="vCardName"
          sortDirection={headersConfig.vCardName.direction}
          headers={headersConfig}
        />
      ),
      accessor: 'vCardName',
      Cell: (row) => (<div>{row.value}</div>),
      sortMethod: () => setSortValues('vCardName'),
    },
  ];

  const renderList = () => {
    const emptyTemplate = 'VCards';
    const list = getSortedVCards();
    const listing = list?.length > 0 ? (
      <Scrollbars className="list-panel__body__scroll" data-cypress={emptyTemplate}>
        <SmartTable
          data={getSortedVCards()}
          showPagination={false}
          pageSize={list?.length}
          sticky
          columns={vCardColumns}
          sortable
          manual={false}
          striped
        />
      </Scrollbars>
    ) : (!pageLoading && (
      <div className="form-list__noforms_wrapper">
        <EmptyMessage section={emptyTemplate} />
      </div>
    ));
    return listing;
  };

  const resetState = () => {
    setSortAscending(true);
    setDefaultSort();
    setSortKey(getDefaultSortKey(VCARD_LIST_HEADERS_CONFIG));
  };

  return (
    <Modal modalClass="form-modal" onReverseComplete={resetState} open={isOpen}>
      <ModalHeader onClose={handleCloseModal} title="VCards" />
      <ModalBody>
        {renderList()}
      </ModalBody>
      <ModalFooter>
        <UtilityInlineGrid align="right">
          <Button type="link" onClick={handleCloseModal}>Cancel</Button>
          <Button type="primary" onClick={handleAttachVCard}>Attach VCard(s)</Button>
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );
};

VCardModal.propTypes = {
  closeModal: PropTypes.func,
  open: PropTypes.bool,
  handleVCardAppend: PropTypes.func,
  isInboxThread: PropTypes.bool,
  currentOrganization: PropTypes.object,
  selectedVCards: PropTypes.array,
};

export default VCardModal;
