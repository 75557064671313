import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  useLazyGetPayorsQuery,
  useGetPayorsQuery,
} from '../services/prescriptionService';

const usePayors = ({ sortBy = 'name', sortOrder = 'asc', pageNo = 0, nameSearch = '' }) => {
  const organizationId = useSelector((state) => state.auth.currentOrg);
  const [fetchPayors, { isFetching }] = useLazyGetPayorsQuery();
  const payors = useGetPayorsQuery({ sortBy, sortOrder, pageNo: 0, organizationId, nameSearch }, {
    selectFromResult: (result) => result });

  useEffect(() => {
    if (pageNo > 0) {
      fetchPayorsPage({ sortBy, sortOrder, pageNo: 1, organizationId });
    }
  }, [pageNo]);

  async function fetchPayorsPage() {
    await fetchPayors({ pageNo, organizationId, nameSearch });
  }

  return { ...payors, isPageFetching: isFetching };
};

export default usePayors;
