import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Tags = (props) => (
  <>
    {props.tagIds.map((id, key) => {
      const tag = props.tags[id];
      if (!tag) return null;
      return (
        <span key={id}>
          {!!key && ', '}
          #{tag.name}
        </span>
      );
    })}
  </>
);

Tags.propTypes = {
  tags: PropTypes.object.isRequired,
  tagIds: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { tag } = state;

  return {
    tags: tag.tags,
  };
};

export default connect(mapStateToProps)(Tags);
