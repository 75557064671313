import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import { sortBy } from '../helpers/DataHelpers';

import { setError } from './uiReducer';
import * as InboxActionTypes from '../constants/InboxActionTypes';
import { language } from '../actions/NormalizrSchema';

// SLICE
const languageSlice = createSlice({
  name: 'LANGUAGE',
  initialState: {
    languages: {},
    languageIds: [],
  },
  reducers: {
    receiveLanguages: (state, action) => ({
      ...state,
      languages: {
        ...state.languages,
        ...action.payload.languages,
      },
      languageIds: [...new Set([...state.languageIds, ...action.payload.languageIds])],
    }),
  },
  extraReducers: {
    [InboxActionTypes.receiveRecommendedLanguageId]: sortLanguageIds,
  },
});

export default languageSlice.reducer;

// ACTIONS
export const { receiveLanguages } = languageSlice.actions;

// REDUCER HELPERS

function sortLanguageIds(state, action) {
  const { languages } = state;
  const recommendedLanguage = action.payload;
  const sortedLanguageIds = Object.keys(languages).filter((languageId) => recommendedLanguage !== Number(languageId));

  sortedLanguageIds.sort(sortBy(languages, 'name'));
  return {
    ...state,
    languageIds: sortedLanguageIds,
  };
}

// THUNKS -- ASYNC ACTION CREATORS

export function fetchSupportedLanguages() {
  return (dispatch) =>
    axios.get('languages?enabled=true')
      .then((response) => {
        const normalized = normalize(response.data, [language]);
        dispatch(receiveLanguages({ languages: normalized.entities.languages, languageIds: normalized.result }));
      })
      .catch((err) => {
        dispatch(setError(err.response || err));
      });
}
