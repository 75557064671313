import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import {
  DropdownMenuItem,
  Dropdown,
  RadioGroup,
  Radio,
  Date,
  DropdownMenuDivider,
  DropdownMenuItemWild,
} from 'rhinostyle';
import moment from 'moment-timezone';
import { CONSENT_DATE_OPTIONS, CONSENT_DATE_DIRECTION } from '../constants/ContactsConstants';
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_M_D_YY } from '../constants/DateConstants';

const ConsentDateDropdown = (props) => {
  const {
    disabled,
    name,
    handleChange,
    label,
    value: {
      range: dateRange,
      date: selectedDate,
    },
  } = props;
  const [dateKey, setDateKey] = useState(0);
  const now = moment.utc();
  const datePickerRef = useRef(null);
  const dropdownRef = useRef(null);
  const pastMaxDate = moment().subtract(5, 'years');
  const dateRangeName = `${name}.range`;
  const dateName = `${name}.date`;

  function handleSelectCustomDate(updatedDate) {
    setDateKey(4);
    handleChange(name, {
      date: moment.utc(updatedDate).format(DATE_FORMAT_DEFAULT),
    });
    dropdownRef?.current?.handleCloseDropdown();
  }

  function handleSelectDateKey(option) {
    setDateKey(option.id);
    if (option?.duration) {
      handleChange(name, {
        date: moment.utc().subtract(1, option.duration).format(DATE_FORMAT_DEFAULT),
      });
    }
  }

  function handleSelectRange(direction) {
    handleChange(name, {
      range: direction,
    });
  }

  useEffect(() => {
    if (!selectedDate) {
      setDateKey(0);
    }
  }, [selectedDate]);

  function renderDateOption(value) {
    return (
      <DropdownMenuItem
        key={value.id}
        id={value.id}
        label={value.label}
        onClick={() => handleSelectDateKey(value)}
      />
    );
  }

  function getLabel(key) {
    const selectedRange = CONSENT_DATE_OPTIONS[key];
    const dateRangeLabel = CONSENT_DATE_DIRECTION[dateRange]?.label;
    if (selectedRange?.duration) {
      return `${dateRangeLabel} ${selectedRange?.label}`;
    } if (key) {
      return `${dateRangeLabel} ${moment(selectedDate).format(DATE_FORMAT_M_D_YY)}`;
    } return label;
  }
  return (
    <div className="daterange__dropdown">
      <Dropdown
        label={getLabel(dateKey)}
        type={selectedDate ? 'primary' : 'input'}
        outlined={!!selectedDate}
        className="filter-page__dropdown filter-page__dropdown--date-range"
        disabled={disabled}
        name="dateKey"
        blur
        autoFocusInput={false}
        position="left"
        lockLabel
        activeKey={dateKey}
        ref={dropdownRef}
        wide
        dataFeatureTag={`${name}-date-dropdown`}
      >
        <DropdownMenuItemWild>
          <RadioGroup
            inline
            name={dateRangeName}
            onChange={handleSelectRange}
            selectedValue={dateRange}
          >
            {Object.entries(CONSENT_DATE_DIRECTION).map(([key, value]) => (
              <Radio value={key} label={value.label} key={key} dataCypress={`${name}-date-${value.label}`} dataFeatureTag={`${name}-date-${value.label}`} />
            ))}
          </RadioGroup>
        </DropdownMenuItemWild>
        {Object.values(CONSENT_DATE_OPTIONS).map(renderDateOption)}
        <DropdownMenuDivider />
        <DropdownMenuItemWild key={4}>
          <Date
            name={dateName}
            selected={selectedDate ? moment(selectedDate) : null}
            onChange={handleSelectCustomDate}
            placeholderText="Click to select date"
            shouldCloseOnSelect
            explanationMessage=""
            minDate={pastMaxDate}
            maxDate={now}
            ref={datePickerRef}
            format={DATE_FORMAT_DEFAULT}
            data-feature-tag={`${name}-date-input`}
          />
        </DropdownMenuItemWild>
      </Dropdown>
    </div>
  );
};

ConsentDateDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
};

export default ConsentDateDropdown;
