import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import { setError } from './uiReducer';
import { prefix } from '../actions/NormalizrSchema';

// SLICE
const prefixSlice = createSlice({
  name: 'PREFIX',
  initialState: {
    prefixes: {},
    prefixIds: [],
  },
  reducers: {
    receivePrefixes: (state, action) => ({
      ...state,
      prefixes: {
        ...state.prefixes,
        ...action.payload.prefixes,
      },
      prefixIds: [...state.prefixIds, ...action.payload.prefixIds],
    }),
  },
});

export default prefixSlice.reducer;

// ACTIONS
export const { receivePrefixes } = prefixSlice.actions;

export function fetchPrefixes() {
  return (dispatch) =>
    axios.get('/prefixes')
      .then((response) => {
        const normalized = normalize(response.data, [prefix]);

        dispatch(receivePrefixes({ prefixes: normalized.entities.prefixes, prefixIds: normalized.result }));
      })
      .catch((err) => {
        console.error(err.response);

        dispatch(setError(err.response));
      });
}
