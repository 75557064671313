import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Icon, Tooltip, UtilityListItem, UtilityList } from 'rhinostyle';
import { UserHelpers } from '../helpers';
import {
  TYPE_RHINOPAY_CONSENT_DECLINED,
  TYPE_RHINOPAY_CONSENT_PENDING,
  TYPE_RHINOPAY_CONSENT_GRANTED,
  TYPE_MARKETING_CONSENT_DECLINED,
  TYPE_MARKETING_CONSENT_PENDING,
  TYPE_MARKETING_CONSENT_GRANTED,
  TYPE_HIPAA_CONSENT_PENDING,
  TYPE_HIPAA_CONSENT_GRANTED,
  TYPE_HIPAA_CONSENT_DECLINED,
  TYPE_CONNECTED_PARTY_PRIMARY,
  TYPE_CONNECTED_PARTY_SECONDARY,
} from '../constants/Types';
import { NOT_SENT, SENT_TO_ALL, SENT_TO_SOME } from '../constants/VCardConstants';

const UserBadges = (props) => {
  const { user, isRhinopayEnabled, isOfficeVCardEnabled, connectedPartyType } = props;
  if (!UserHelpers.isContact(user)) return null;
  const hipaaIconClasses = cx('consent-status__icon', {
    'is-denied': user.hipaaStatus.typeId === TYPE_HIPAA_CONSENT_DECLINED,
    'is-unknown': user.hipaaStatus.typeId === TYPE_HIPAA_CONSENT_PENDING,
    'is-granted': user.hipaaStatus.typeId === TYPE_HIPAA_CONSENT_GRANTED,
  });

  const rhinopayIconClasses = cx('consent-status__icon', {
    'is-denied': user.rhinopayConsentStatus.typeId === TYPE_RHINOPAY_CONSENT_DECLINED,
    'is-unknown': user.rhinopayConsentStatus.typeId === TYPE_RHINOPAY_CONSENT_PENDING,
    'is-granted': user.rhinopayConsentStatus.typeId === TYPE_RHINOPAY_CONSENT_GRANTED,
  });

  const marketingIconClasses = cx('consent-status__icon is-marketing', {
    'is-unknown': user.marketingConsentStatus.typeId === TYPE_MARKETING_CONSENT_PENDING,
    'is-denied': user.marketingConsentStatus?.typeId === TYPE_MARKETING_CONSENT_DECLINED,
    'is-granted': user.marketingConsentStatus?.typeId === TYPE_MARKETING_CONSENT_GRANTED,
  });

  const connectedPartyClasses = cx('consent-status__icon', {
    'is-primary': connectedPartyType?.id === TYPE_CONNECTED_PARTY_PRIMARY,
    'is-secondary': connectedPartyType?.id === TYPE_CONNECTED_PARTY_SECONDARY,
  });

  const renderVCards = (vCard) => (
    <UtilityList space className="u-m-b-0">
      <UtilityListItem key={vCard.id}>
        {vCard.name}
      </UtilityListItem>
    </UtilityList>
  );

  const { vCards, vCardStatus } = user?.vCard ?? {};

  const vCardIconClasses = cx('consent-status__icon', {
    'is-unknown': vCardStatus === SENT_TO_SOME,
    'is-granted': vCardStatus === SENT_TO_ALL,
  });

  return (
    <span className="user-badges">
      {user && <Icon className={hipaaIconClasses} icon="hipaa" data-feature-tag="user-badge-hipaa" />}
      {user && isRhinopayEnabled && (<Icon className={rhinopayIconClasses} icon="rhinopay" data-feature-tag="user-badge-rhinopay" />)}
      {user && (<Icon className={marketingIconClasses} icon="marketing" data-feature-tag="user-badge-marketing" />)}
      {user && UserHelpers.isMinor(user) && <Icon className="u-text-accent" icon="minor" data-feature-tag="user-badge-minor" />}
      {user && isOfficeVCardEnabled && vCardStatus && vCardStatus !== NOT_SENT && (
        <Tooltip placement="top" content={vCards ? vCards?.map(renderVCards) : ''}>
          <Icon className={vCardIconClasses} icon="vcard" data-feature-tag="user-badge-vcard" />
        </Tooltip>
      )}
      <span className={connectedPartyClasses}>{connectedPartyType && connectedPartyType.value}</span>
    </span>
  );
};

UserBadges.defaultProps = {
  user: {},
};

UserBadges.propTypes = {
  user: PropTypes.object,
  isRhinopayEnabled: PropTypes.bool.isRequired,
  isOfficeVCardEnabled: PropTypes.bool,
  connectedPartyType: PropTypes.object,
};

export default UserBadges;
