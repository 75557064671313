import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  Date,
  Input,
  Radio,
  RadioGroup,
  Select,
  Textarea,
  Checkbox,
  LoaderCircle,
  CheckboxGroup,
} from 'rhinostyle';
import EmojiPickerDropdown from './EmojiPickerDropdown';

import RhinogramsOrgSearch from './RhinogramsOrgSearch';
import PageLoader from './PageLoader';
import PhoneGroup from './ContactProfile/PhoneGroup';
import EmailGroup from './ContactProfile/EmailGroup';
import { TYPE_PATIENT, TYPE_USER_OTHER, TYPE_ORGANIZATION } from '../constants/Types';
import { CONTACT_ORGANIZATION_CREATE, CONTACT_CREATE } from '../constants/UserPermissionsConstants';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';

const ContactFormGeneralInfo = (props) => {
  const textareaRef = useRef();
  const {
    birthday,
    clearUserSearch,
    currentUser,
    emailTypes,
    errors,
    displayId,
    firstName,
    handleBirthdayChange,
    handleChange,
    handleRhinogramChange,
    handleReceiveContactData,
    handleRemoveContactData,
    handleSearch,
    handlePhoneSearchIdx,
    handleViewProfileTransition,
    integrated,
    lastName,
    middleName,
    note,
    noteIsImportant,
    pageLoading,
    phones,
    phoneTypes,
    preferredName,
    prefixId,
    prefixOpts,
    searchText,
    sex,
    suffixId,
    suffixOpts,
    typeId,
    userEmails,
    userId,
    userPhones,
    userRhinograms,
    phoneSearchLoading,
    rhinogramChannelsLoading,
    rhinogramChannelOpts,
    phoneUserSearchIds,
    users,
    phoneSearchIdx,
    isLockedByAnotherUser,
    editMode,
    isEditIntegratedContactsAllowed,
  } = props;
  const disableEdit = integrated && !currentUser.isCcr;
  if (pageLoading) {
    return <PageLoader />;
  }

  const renderRadioButtons = () => {
    const radioButtons = [];

    if (userHasAnyOfPermissions([CONTACT_CREATE])) {
      radioButtons.push(<Radio
        key={TYPE_PATIENT}
        value={TYPE_PATIENT}
        label={<span className="form__block-group__label">Patient</span>}
        dataCypress="patientContactType"
      />);
      radioButtons.push(<Radio
        key={TYPE_USER_OTHER}
        value={TYPE_USER_OTHER}
        label={<span className="form__block-group__label">Other</span>}
        dataCypress="otherContactType"
      />);
    }

    if (userHasAnyOfPermissions([CONTACT_ORGANIZATION_CREATE])) {
      radioButtons.push(<Radio
        key={TYPE_ORGANIZATION}
        value={TYPE_ORGANIZATION}
        label={<span className="form__block-group__label">Organization</span>}
        dataCypress="organizationContactType"
      />);
    }
    return radioButtons;
  };
  return (
    <>
      <RadioGroup
        label="Contact Type"
        inline
        name="typeId"
        onChange={(id) => handleChange('typeId', id)}
        selectedValue={typeId}
        required
        validationMessage={errors.typeId}
      >
        {renderRadioButtons()}
      </RadioGroup>
      {(typeId !== TYPE_ORGANIZATION) && (
      <>
        <div className="form__group">
          <div className="row">
            <div className="column-4@small">
              <Input
                disabled={disableEdit}
                initialValue={firstName}
                label="First Name"
                name="firstName"
                onChange={handleChange}
                required
                validationMessage={errors.firstName}
              />
            </div>
            <div className="column-4@small">
              <Input
                disabled={disableEdit}
                initialValue={middleName}
                label="Middle Name"
                name="middleName"
                onChange={handleChange}
              />
            </div>
            <div className="column-4@small">
              <Input
                disabled={disableEdit}
                initialValue={lastName}
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                required
                validationMessage={errors.lastName}
              />
            </div>
            <div className="column-4@small">
              <Input
                disabled={disableEdit}
                initialValue={preferredName}
                label="Preferred Name"
                name="preferredName"
                onChange={handleChange}
                validationMessage={errors.preferredName}
              />
            </div>
            <div className="column-3@xsmall">
              <Select onSelect={handleChange} disabled={disableEdit || isLockedByAnotherUser} options={prefixOpts} selected={prefixId} name="prefixId" label="Prefix" />
            </div>
            <div className="column-3@xsmall">
              <Select onSelect={handleChange} disabled={disableEdit || isLockedByAnotherUser} options={suffixOpts} selected={suffixId} name="suffixId" label="Suffix" />
            </div>
          </div>
        </div>
        <div className="form__group">
          <div className="row">
            <div className="column-7@small">
              <Date
                disabled={disableEdit || isLockedByAnotherUser}
                id="birthday"
                label="Birthday"
                name="birthday"
                placeholderText="Select a date"
                maxDate={moment()}
                required={typeId === TYPE_PATIENT}
                selected={birthday}
                validationMessage={errors.birthday}
                onChange={(date) => {
                  if (date) {
                    handleBirthdayChange(date);
                  }
                }}
                onChangeRaw={() => handleChange('birthday', null)}
              />
            </div>
          </div>
        </div>
        <div className="form__group">
          <div className="row">
            <div className="column-6@xsmall column-6@small">
              <Input
                disabled={integrated}
                initialValue={displayId}
                label="ID Number"
                name="displayId"
                onChange={handleChange}
                validationMessage={errors.externalId}
              />
            </div>
            <div className="column-3@small">
              <RadioGroup name="sex" onChange={(id) => handleChange('sex', id)} selectedValue={sex} inline label="Gender">
                <Radio disabled={disableEdit} value="male" label="Male" />
                <Radio disabled={disableEdit} value="female" label="Female" />
              </RadioGroup>
            </div>
          </div>
        </div>

        <PhoneGroup
          activeUser={{ firstName, lastName }}
          currentUser={currentUser}
          errorList={errors}
          handleRemoveContactData={handleRemoveContactData}
          handleViewProfileTransition={handleViewProfileTransition}
          integrated={integrated}
          receiveContactData={handleReceiveContactData}
          types={phoneTypes}
          userId={userId}
          userPhones={userPhones}
          loading={phoneSearchLoading}
          handleSearch={handleSearch}
          handlePhoneSearchIdx={handlePhoneSearchIdx}
          searchText={searchText}
          phones={phones}
          phoneSearchIdx={phoneSearchIdx}
          phoneUserSearchIds={phoneUserSearchIds}
          clearUserSearch={clearUserSearch}
          users={users}
          isAddAnotherPhoneDisabled={isLockedByAnotherUser}
          isViewOtherPhoneOwnersDisabled={isLockedByAnotherUser}
          isRemovePhoneDisabled={isLockedByAnotherUser}
          isEditIntegratedContactsAllowed={isEditIntegratedContactsAllowed}
        />

        <EmailGroup
          errorList={errors}
          currentUser={currentUser}
          handleRemoveContactData={handleRemoveContactData}
          integrated={integrated}
          receiveContactData={handleReceiveContactData}
          types={emailTypes}
          userEmails={userEmails}
          isAddAnotherEmailDisabled={isLockedByAnotherUser}
          isRemoveEmailDisabled={isLockedByAnotherUser}
        />
      </>
      )}
      {(typeId === TYPE_ORGANIZATION) && (
        <div className="form__group">
          <div className="search__group">
            <RhinogramsOrgSearch editMode={editMode} handleRhinogramChange={handleRhinogramChange} />
          </div>
          {userRhinograms[0].toOrganizationId && (
            <div className="row">
              <div className="column-8@small">
                <Input
                  disabled={disableEdit}
                  initialValue={firstName}
                  label="Display Name"
                  name="firstName"
                  onChange={handleChange}
                  required
                  validationMessage={errors.firstName}
                />
              </div>
              <div className="column-4@small u-position-relative">
                {rhinogramChannelsLoading && <LoaderCircle size="small" className="u-select__loader-circle" />}
                <Select
                  required
                  onSelect={handleRhinogramChange}
                  disabled={rhinogramChannelsLoading}
                  initialValue={userRhinograms[0].toChannelId || rhinogramChannelOpts[0]?.id}
                  options={rhinogramChannelOpts}
                  selected={userRhinograms[0].toChannelId || rhinogramChannelOpts[0]?.id}
                  name="toChannelId"
                  label="Channel"
                  validationMessage={errors.toChannelId}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <Textarea
        explanationMessage="This note will be displayed in the profile summary."
        initialValue={note}
        label="Note"
        name="note"
        onChange={handleChange}
        rows={3}
        textareaRef={textareaRef}
        emojiSupport
      />
      <div className="u-flex u-flex-direction-row u-flex-align-items-center u-m-t-small u-m-b">
        <EmojiPickerDropdown
          inputName="note"
          inputValue={note}
          inputRef={textareaRef}
          handleInputChange={handleChange}
          dropdownPosition="top"
        />
      </div>
      <CheckboxGroup>
        <Checkbox
          name="noteIsImportant"
          onChange={handleChange}
          isChecked={noteIsImportant}
          label="Mark note as important"
        />
      </CheckboxGroup>
    </>
  );
};

ContactFormGeneralInfo.propTypes = {
  birthday: PropTypes.object,
  clearUserSearch: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
  rhinogramChannelsLoading: PropTypes.bool.isRequired,
  rhinogramChannelOpts: PropTypes.array.isRequired,
  editMode: PropTypes.string.isRequired,
  emailTypes: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  externalId: PropTypes.string,
  displayId: PropTypes.string,
  firstName: PropTypes.string,
  handleBirthdayChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRhinogramChange: PropTypes.func.isRequired,
  handleReceiveContactData: PropTypes.func,
  handleRemoveContactData: PropTypes.func,
  handleSearch: PropTypes.func,
  handlePhoneSearchIdx: PropTypes.func,
  handleViewProfileTransition: PropTypes.func.isRequired,
  integrated: PropTypes.bool.isRequired,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  note: PropTypes.string,
  noteIsImportant: PropTypes.bool,
  pageLoading: PropTypes.bool.isRequired,
  phones: PropTypes.object,
  phoneTypes: PropTypes.array.isRequired,
  preferredName: PropTypes.string,
  prefixId: PropTypes.number,
  prefixOpts: PropTypes.array.isRequired,
  searchText: PropTypes.string,
  sex: PropTypes.string,
  suffixId: PropTypes.number,
  suffixOpts: PropTypes.array.isRequired,
  typeId: PropTypes.number.isRequired,
  userEmails: PropTypes.object.isRequired,
  userId: PropTypes.number,
  userPhones: PropTypes.object.isRequired,
  userRhinograms: PropTypes.object.isRequired,
  phoneSearchLoading: PropTypes.bool,
  phoneSearchIdx: PropTypes.number,
  phoneUserSearchIds: PropTypes.array,
  users: PropTypes.object,
  isLockedByAnotherUser: PropTypes.bool,
  isEditIntegratedContactsAllowed: PropTypes.bool,
};

export default ContactFormGeneralInfo;
