import {
  useGetCampaignQuery,
} from '../services/campaignService';

const useCampaign = ({ bulkMessageId }) => {
  const results = useGetCampaignQuery({ bulkMessageId });
  return results;
};

export default useCampaign;
