import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserById } from '../reducers/userReducer';
import { fetchGroupById } from '../reducers/groupReducer';
import { getGroupById, getUserById } from '../selectors/routingSelectors';
import { useGetRoutesQuery } from '../services/routingService';

const useTextClassificationRoute = ({
  textClassificationRouteId,
  isForm,
}) => {
  const organizationId = useSelector((state) => state.auth?.currentOrg);
  const selectedRoute = useGetRoutesQuery({ organizationId }, {
    selectFromResult: (result) => result && result?.data?.textclassificationroutes?.find((route) => route.id === Number(textClassificationRouteId)),
  });
  const dispatch = useDispatch();
  const { toMemberId, toGroupId } = selectedRoute;
  const toMember = useSelector((state) => getUserById(state, { userId: toMemberId }));
  const toGroup = useSelector((state) => getGroupById(state, { groupId: toGroupId }));

  useEffect(() => {
    if (!isForm && toMemberId) {
      dispatch(fetchUserById(toMemberId));
    }
  }, [toMemberId, isForm]);

  useEffect(() => {
    if (!isForm && toGroupId) {
      dispatch(fetchGroupById(toGroupId));
    }
  }, [toGroupId, isForm]);

  function getToName() {
    if (toMember) {
      return `${toMember.firstName || ''}${toMember.lastName ? ` ${toMember.lastName}` : ''}`;
    } if (toGroup) {
      return toGroup.name;
    } return '';
  }

  function getRouteName() {
    const joinString = selectedRoute.textClassificationTypes?.length === 2 ? ' and ' : ', ';
    const formatted = selectedRoute.textClassificationTypes.join(joinString);
    return `${formatted.slice(0, 30)}${formatted.length > 30 ? '...' : ''}`;
  }

  function getFullRouteName() {
    const joinString = selectedRoute.textClassificationTypes?.length === 2 ? ' and ' : ', ';
    const formatted = selectedRoute.textClassificationTypes.join(joinString);
    return formatted;
  }

  function getToType() {
    if (toMember) {
      return 'Member';
    }
    if (toGroup) {
      return 'Group';
    } return '';
  }

  return {
    textClassificationRoute: {
      ...selectedRoute,
      routeNameFormatted: getRouteName(),
      fullRouteNameFormatted: getFullRouteName(),
      toNameFormatted: getToName(),
      toType: getToType(),
    },
    toMember,
    toGroup,
  };
};

export default useTextClassificationRoute;
