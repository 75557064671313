import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import {
  DropdownMenuItem,
  Dropdown,
  RadioGroup,
  Radio,
  Date,
  DropdownMenuDivider,
  DropdownMenuItemWild,
} from 'rhinostyle';
import moment from 'moment-timezone';
import { BIRTHDAY_DATE_DIRECTION, DATE_DIRECTION_OPTIONS } from '../constants/ContactsConstants';
import { fetchMonths } from '../helpers/BirthdayHelpers';
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_MM, DATE_FORMAT_MMMM, DATE_FORMAT_M_D_YY } from '../constants/DateConstants';

const ContactBirthdayDropdown = ({
  disabled,
  name,
  handleChange,
  label,
  dataFeatureTag,
  dropdownClass,
  value: {
    date: selectedDate,
    range: dateDirection,
  },
}) => {
  const now = moment();
  const datePickerRef = useRef(null);
  const dropdownRef = useRef(null);
  const monthOnly = dateDirection === DATE_DIRECTION_OPTIONS.MONTH;
  const dropdownStyle = selectedDate ? 'primary' : 'input';
  const months = fetchMonths();
  const dateDirectionName = `${name}.range`;
  const dateName = `${name}.date`;

  const handleSelectMonth = (updatedDate) => {
    handleChange(name, { date: moment().month(updatedDate).format(DATE_FORMAT_MM) });
    dropdownRef?.current?.handleCloseDropdown();
  };

  const handleSelectDateDirection = (direction) => {
    const changeObj = { range: direction };
    if ((monthOnly && direction !== 'month') || (!monthOnly && direction === 'month')) {
      changeObj.date = null;
    }
    handleChange(name, changeObj);
  };

  function handleSelectDate(updatedDate) {
    handleChange(name, { date: moment(updatedDate).format(DATE_FORMAT_DEFAULT) });
    dropdownRef?.current?.handleCloseDropdown();
  }

  function getLabel() {
    if (selectedDate) {
      const birthdayFormat = monthOnly ? DATE_FORMAT_MMMM : DATE_FORMAT_M_D_YY;
      const dateDirectionLabel = BIRTHDAY_DATE_DIRECTION[dateDirection]?.filterLabel || '';
      return `${label}: ${dateDirectionLabel} ${moment(selectedDate).format(birthdayFormat)}`;
    } return label;
  }

  const monthPicker = () => (
    <Dropdown
      label="Select a month"
      type={dropdownStyle}
      outlined={!!selectedDate}
      className={dropdownClass}
      disabled={disabled}
      name={dateName}
      blur
      autoFocusInput={false}
      position="left"
    >
      {months.map((month) => (
        <DropdownMenuItem
          key={month.value}
          id={month.value}
          label={month.value}
          dataCypress={`birthday-month-${month.value}`}
          onClick={() => handleSelectMonth(month.value)}
        />
      ))}
    </Dropdown>
  );

  const renderDateSelect = () => {
    if (monthOnly) {
      return monthPicker();
    } return (
      <>
        <div className="date-range__label u-m-b-small">
          Custom Date
        </div>
        <Date
          selected={selectedDate ? moment(selectedDate) : null}
          onChange={handleSelectDate}
          name={dateName}
          ref={datePickerRef}
          shouldCloseOnSelect
          placeholderText="Click to select date"
          format={DATE_FORMAT_DEFAULT}
          maxDate={now}
        />
      </>
    );
  };

  return (
    <div className="daterange__dropdown">
      <Dropdown
        label={getLabel()}
        type={dropdownStyle}
        outlined={!!selectedDate}
        className="filter-page__dropdown filter-page__dropdown--date-range"
        disabled={disabled}
        name={selectedDate}
        blur
        autoFocusInput={false}
        position="left"
        ref={dropdownRef}
        dataFeatureTag={dataFeatureTag}
      >
        <DropdownMenuItemWild>
          <RadioGroup
            inline
            name={dateDirectionName}
            onChange={handleSelectDateDirection}
            selectedValue={dateDirection}
          >
            {Object.entries(BIRTHDAY_DATE_DIRECTION).map(([key, value]) => (
              <Radio value={key} label={value.label} key={key} dataCypress={`birthday-type-${key}`} />
            ))}
          </RadioGroup>
        </DropdownMenuItemWild>
        <DropdownMenuDivider />
        <DropdownMenuItemWild>
          {renderDateSelect()}
        </DropdownMenuItemWild>
      </Dropdown>
    </div>
  );
};

ContactBirthdayDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  dataFeatureTag: PropTypes.string,
  dropdownClass: PropTypes.string.isRequired,
  value: PropTypes.shape({
    date: PropTypes.string,
    range: PropTypes.string,
  }).isRequired,
};

export default ContactBirthdayDropdown;
