import React from 'react';
import { useSelector } from 'react-redux';
import AppointmentCampaignMultiSelect from './AppointementCampaignMultiSelect';

const CampaignOffices = ({ selectedOffices, error, handleChange }) => {
  const offices = useSelector((state) => state.office.mappedOffices);
  const officeIds = useSelector((state) => state.office.mappedOfficeIds);

  return officeIds?.length > 0 ? (
    <AppointmentCampaignMultiSelect
      name="offices"
      title="Offices"
      handleChange={handleChange}
      allItems={offices}
      allIds={officeIds}
      selected={selectedOffices}
      shortName="Offices"
      validationMessage={error}
    />
  ) : null;
};

export default CampaignOffices;
