import { TYPE_IMAGE_JPG, TYPE_IMAGE_JPEG, TYPE_IMAGE_PNG, TYPE_IMAGE_BMP, TYPE_IMAGE_GIF } from '../constants/Types';

export function updateFavicon(hasUnread) {
  const link = document.querySelector('#js-favicon');
  const favIcon = hasUnread ? `${process.env.PUBLIC_URL}/favicon-notify.png` : `${process.env.PUBLIC_URL}/favicon.png`;
  link.type = 'image/png';
  link.rel = 'icon';
  link.href = favIcon;
  document.getElementsByTagName('head')[0].appendChild(link);
}

export function isImageType(imageType) {
  return [TYPE_IMAGE_JPG, TYPE_IMAGE_JPEG, TYPE_IMAGE_PNG, TYPE_IMAGE_BMP, TYPE_IMAGE_GIF].includes(imageType);
}

export function isImageMimeType(mimeType) {
  return ['image/jpeg', 'image/gif', 'image/bmp', 'image/png'].includes(mimeType);
}
