import React from 'react';
import { useSelector } from 'react-redux';
import {
  Icon,
  CheckboxGroup,
  Checkbox,
  Button,
} from 'rhinostyle';
import { useThreadFilters } from '../hooks';

const ThreadFilterHeader = () => {
  const channels = useSelector((state) => state.channel.channels);
  const {
    filtersEnabled,
    handleApplyThreadFilterClick,
    handleSelectChannelId,
    selectedChannelIds,
    threadActiveChannelIds,
    viewingChannelIds,
  } = useThreadFilters();

  function renderChannel(channelId) {
    return (
      <Checkbox
        isChecked={selectedChannelIds.includes(channelId)}
        onChange={handleSelectChannelId}
        name={channelId}
        label={channels[channelId]?.name || ''}
        key={channelId}
        dataFeatureTag={`thread-filter__checkbox-${channelId}`}
      />
    );
  }

  function renderChannelName(channelId, idx) {
    return (
      <span key={channelId} className={idx > 0 ? 'convo-filter__channel-name' : ''}>
        {channels[channelId]?.name}
      </span>
    );
  }
  return (
    <div className="convo-filter">
      <div className="convo-filter--closed u-text-primary">
        <div className="convo-filter__title">
          <Icon icon="chat-group" className="u-m-r-small" size="large" />
          <div> Conversation Thread Display:
            <span
              data-feature-tag="thread-filter__channels"
              className="u-text-muted u-text-italic u-p-l-small"
            >
              {viewingChannelIds.map(renderChannelName)}
            </span>
          </div>
        </div>
      </div>
      <div className="convo-filter--open">
        <div
          className="convo-filter__title"
          data-feature-tag="thread-filter__title"
        >
          <Icon icon="chat-group" className="u-m-r-small" size="large" />
          <div> Conversation Thread Displaying messages from the following channels</div>
        </div>
        <div className="convo-filter__select">
          <CheckboxGroup inline>
            {threadActiveChannelIds.map(renderChannel)}
          </CheckboxGroup>
        </div>
        <div className="u-flex u-flex-direction-row u-flex-justify-end u-flex-row">
          <Button
            type="primary"
            onClick={handleApplyThreadFilterClick}
            size="small"
            className="u-flex-align-self-end u-p-l-large u-p-r-large"
            data-feature-tag="thread-filter__apply"
            disabled={!filtersEnabled}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThreadFilterHeader;
