import { createSelector } from '@reduxjs/toolkit';
import { PhoneHelpers, UserHelpers } from '../helpers';
import { Types } from '../constants';
import { DRAFT_STORAGE_KEYS } from '../constants/AppConstants';
import { TYPE_INTEGRATION_AXIUM } from '../constants/Types';

const getUsers = (state) => state.user.users;
const getUserIds = (state) => state.user.userIds;
const getTypeFilter = (state) => state.user.typeFilter;
const getTagFilter = (state) => state.user.tagFilter;
const getLocationFilter = (state) => state.user.locationFilter;
const getInboxUserId = (state) => state.inbox.userId;
const getChatUserId = (state) => state.chat.userId;
const getUserUserId = (state) => state.user.userId;
const getOrganizations = (state) => state.organization.organizations;
const getPhones = (state) => state.phone.phones;
const getTypes = (state) => state.type.types;
const getTypeIds = (state) => state.type.typeIds;
const getLoggedInUserId = (state) => state.auth.currentUser;
const getIsChat = (state, isChat) => isChat === true;
const getActiveConvoTab = (state) => state.thread.activeConvoTab;
const getChatGroup = (state) => state.chat.groupId;
const getCurrentOrg = (state) => state.auth.currentOrg;
const getMemberUserIds = (state) => state.user.memberUserIds;
const getMentionUserId = (state, props) => props.userId;
const getKeyType = (state, keyType) => keyType || DRAFT_STORAGE_KEYS.default;
const getFrom = (state, from) => from;
const getPhoneId = (state, phoneId) => phoneId;

export const getLoggedInUser = createSelector(
  [getUsers, getLoggedInUserId],
  (users, loggedInUserId) => {
    if (users && loggedInUserId) {
      return users[loggedInUserId];
    }

    return null;
  },
);

export const getLoggedInUserPermissionNames = createSelector(
  [getUsers, getLoggedInUserId],
  (users, loggedInUserId) => {
    if (users && loggedInUserId) {
      return users[loggedInUserId].permissions.map((permission) => permission?.name);
    } else {
      return [];
    }
  },
);

export const getActiveMember = createSelector(
  [getUsers, getChatUserId],
  (users, chatUserId) => {
    if (users && chatUserId) {
      return users[chatUserId];
    }
    return null;
  },
);

export const getActiveUser = createSelector(
  [getUsers, getUserUserId, getIsChat, getActiveMember],
  (users, userId, isChat, activeMember) => {
    if (isChat) {
      return activeMember;
    }
    if (users && userId) {
      return users[userId];
    }
    return null;
  },
);

export const getLoggedInUserOrganization = createSelector(
  [getLoggedInUser, getOrganizations],
  (user, organizations) => {
    let userOrgInfo = {};

    if (user && organizations) {
      userOrgInfo = organizations[user.organization];
    }
    return userOrgInfo;
  },
);

export const getVisibleContacts = createSelector(
  [getUserIds, getUsers, getTypeFilter, getTagFilter, getLocationFilter, getTypes],
  (userIds, users, typeFilter, tagFilter, locationFilter) => {
    if (!typeFilter && !tagFilter && !locationFilter) {
      // We want to make sure not to return members in the getVisibleContacts selector.
      // There are cases where a member could potentially exist in our redux.userIds.
      return userIds.filter((id) => users[id].typeId !== Types.TYPE_MEMBER);
    }

    let filteredUsers = userIds;

    if (typeFilter && typeFilter > -1) {
      filteredUsers = filteredUsers.filter((id) => users[id].typeId === typeFilter);
    }

    return filteredUsers;
  },
);

export const getUserFilterTypes = createSelector(
  [getTypes, getTypeIds],
  (types, typeIds) => {
    let filterTypes = [];

    if (types && typeIds) {
      filterTypes = typeIds.filter((id) => types[id].class === 'user');
    }

    return filterTypes;
  },
);

export const getUserMemberStatus = createSelector(
  [getActiveUser], (user) => UserHelpers.isMember(user),
);

export const getUserPatientStatus = createSelector(
  [getActiveUser], (user) => UserHelpers.isPatient(user) || UserHelpers.isOther(user),
);

export const getActiveMemberIds = createSelector(
  [getMemberUserIds, getUsers],
  (userIds, users) =>
    userIds
      .filter((id) => users[id].typeId === Types.TYPE_MEMBER && users[id].active),
);

export const getInActiveMemberIds = createSelector(
  [getMemberUserIds, getUsers],
  (userIds, users) =>
    userIds
      .filter((id) => users[id].typeId === Types.TYPE_MEMBER && !users[id].active),
);

export const getInboxUser = createSelector(
  [getUsers, getInboxUserId],
  (users, inboxUserId) => users[inboxUserId],
);

export const getEventPhoneByValue = createSelector(
  [getActiveUser, getPhones, getFrom], (activeUser, phones, from) => {
    if (!activeUser || !phones || !from) return null;
    return activeUser.phones?.map((p) => phones[p]).find((phone) => phone.value === from);
  },
);

export const getDoesActiveUserOwnPhoneNumber = createSelector(
  [getActiveUser, getPhones], (activeUser, phones) => activeUser?.phones?.some((p) => Number(phones[p]?.ownerId) === Number(activeUser.id)),
);
export const getDoesActiveUserHavePhone = createSelector(
  [getActiveUser, getPhones], (activeUser, phones) => !!activeUser?.phones?.filter((phone) => PhoneHelpers.isPhoneNumberValid(phones[phone]?.value)).length,
);

export const doesActiveUserHaveFacebook = createSelector(
  [getInboxUser], (activeUser) => activeUser?.facebooks?.length > 0,
);

export const doesActiveUserHaveInstagram = createSelector(
  [getInboxUser], (activeUser) => activeUser?.instagrams?.length > 0,
);
export const doesActiveUserHaveRhinogram = createSelector(
  [getInboxUser], (activeUser) => activeUser?.rhinograms?.length > 0,
);

export const canActiveUserReceiveMessages = createSelector(
  [getActiveUser, getDoesActiveUserOwnPhoneNumber, doesActiveUserHaveRhinogram, doesActiveUserHaveFacebook, doesActiveUserHaveInstagram],
  (activeUser, ownsPhone, hasFacebook, hasRhinogram, hasInstagram) => ownsPhone || hasFacebook || hasRhinogram || hasInstagram,
);

export const getMentionableUser = createSelector(
  [getUsers, getMentionUserId],
  (users, mentionUserId) => {
    if (mentionUserId) {
      return users[mentionUserId];
    }
    return null;
  },
);

export const getIsRhinopayConsentGranted = createSelector(
  [getInboxUser],
  (activeUser) => activeUser && (activeUser.typeId === Types.TYPE_PATIENT || activeUser.typeId === Types.TYPE_USER_OTHER)
    && activeUser.rhinopayConsentStatus
    && activeUser.rhinopayConsentStatus.typeId === Types.TYPE_RHINOPAY_CONSENT_GRANTED,
);

export const getRhinopayConsentStatus = createSelector(
  [getInboxUser],
  (activeUser) => {
    if (activeUser && (activeUser.typeId === Types.TYPE_PATIENT || activeUser.typeId === Types.TYPE_USER_OTHER)
    && activeUser.rhinopayConsentStatus) {
      return activeUser.rhinopayConsentStatus.typeId;
    }
    return null;
  },
);

export const getStorageKey = createSelector(
  [getActiveConvoTab,
    getLoggedInUserId,
    getChatGroup,
    getUserUserId,
    getCurrentOrg,
    getKeyType],
  (activeConvoTab,
    currentUser,
    groupId,
    userId,
    currentOrg,
    keyType) => `${keyType === DRAFT_STORAGE_KEYS.optimisticEvents ? '' : activeConvoTab}${keyType}-${activeConvoTab === 'groupChat' ? groupId : userId}-${currentUser}${!!currentOrg && `-${currentOrg}`}`,
);

export const userHasLimitedProviderRole = createSelector(
  [getLoggedInUser, getLoggedInUserOrganization],
  (loggedInUser, currentOrg) => {
    if (loggedInUser && currentOrg) {
      return currentOrg.integrationPartnerTypeId === TYPE_INTEGRATION_AXIUM
      && UserHelpers.hasLimitedProviderRole(loggedInUser);
    }
    return false;
  },
);

export const getPhoneById = createSelector(
  [getPhones, getPhoneId],
  (phones, phoneId) => phones[phoneId],
);
