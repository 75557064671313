import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Icon,
  Input,
  Button,
  Dropdown,
  DropdownMenuItem,
  Scrollbars,
  SmartTable,
  NavTabs,
  NavTabsItem,
} from 'rhinostyle';
import ReactHtmlParser from 'react-html-parser';
import SmartTableHeader from './SmartTableHeader';
import LibraryFormPanelContainer from '../containers/LibraryFormPanelContainer';
import PageLoader from './PageLoader';
import EmptyMessage from './EmptyMessage';
import MessageTemplatePanel from './MessageTemplatePanel';
import { numericDateFormatter } from '../helpers/DateHelpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { NAVIGATION_TABS } from '../constants/LibraryConstants';
import { FORM_VIEW, FORM_EDIT, TEMPLATE_MESSAGE_VIEW, TEMPLATE_MESSAGE_CREATE } from '../constants/UserPermissionsConstants';
import { iconTypeSignature, iconTypeAttachment, iconTypeVCard, VCARD_FILE_TYPE } from '../constants/AppConstants';
import { replaceVariablesByStyledComponent } from '../helpers/TemplateHelpers';
import { TEMPLATE_MESSAGE_VARIABLE_OPTIONS } from '../constants/VariableMessageConstants';
import { replaceWhiteSpaceWithSpace, highlightSearchedText } from '../helpers/StringHelpers';

const OrganizationLibrary = (props) => {
  const {
    activeItemId,
    count,
    createRhinoForm,
    filterOptions,
    focusSearchInput,
    formS3Url,
    formTemplates,
    formTemplatesHeadersConfig,
    getFormS3Url,
    handleCreateRequest,
    handleFavorite,
    handleSearch,
    handleSort,
    handleTabs,
    handleTogglePanel,
    handleUpdateFormTemplateFilterId,
    hasPagination,
    isRhinoformEnabled,
    loggedInUserPermissions,
    messageTemplates,
    pageLoading,
    panelOpen,
    searchText,
    selectedFilterId,
    selectedKey,
    templatesListHeadersConfig,
    templatesPageLoading,
    totalTemplates,
    types,
  } = props;

  const renderFavoriteIcon = (itemId, isSelected) => {
    const icon = isSelected ? 'heart-filled' : 'heart';
    const iconClass = isSelected ? 'heart-filled-icon' : 'heart-icon';
    return (
      <Icon
        icon={icon}
        data-cypress="markFavorite"
        className={iconClass}
        onClick={(e) => handleFavorite(isSelected, itemId, e)}
      />
    );
  };

  const formTemplatesColumns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Title"
          sortKey="title"
          sortDirection={formTemplatesHeadersConfig.title.direction}
          headers={formTemplatesHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'title',
      Cell: (row) => (
        <div>
          <div className={!row.original.isPublished ? 'title-text' : ''}>
            {searchText.length > 2 ? highlightSearchedText(row.original.title, searchText) : row.original.title}
          </div>
          {!row.original.isPublished && <p className="title-draft-text">Draft</p>}
        </div>
      ),
      fixed: 'left',
      sortMethod: () => handleSort('title'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Updated"
          sortKey="updatedAt"
          sortDirection={formTemplatesHeadersConfig.updatedAt.direction}
          headers={formTemplatesHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'updatedDate',
      Cell: (row) => (
        <div>{`${numericDateFormatter(row.original.updatedAt)}`}</div>
      ),
      sortMethod: () => handleSort('updatedAt'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="ID"
          sortKey="sourceId"
          sortDirection={formTemplatesHeadersConfig.sourceId.direction}
          headers={formTemplatesHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'sourceId',
      Cell: (row) => <div>{row.original.sourceId}</div>,
      sortMethod: () => handleSort('sourceId'),
    },
    {
      sortable: false,
      Cell: (row) => {
        const { formId, isFavorite } = row.original;
        return (
          <div className="u-flex u-flex-justify-end">
            {renderFavoriteIcon(formId, isFavorite)}
          </div>
        );
      },
    },
  ];

  const renderAttachment = (template) => {
    const iconTypes = template.attachments.map((attachment) => {
      if (types[attachment.attachmentTypeId]?.value === 'form') return iconTypeSignature;
      if (types[attachment.attachmentTypeId]?.value === VCARD_FILE_TYPE) return iconTypeVCard;
      return iconTypeAttachment;
    });
    const distinctIconTypes = [...new Set(iconTypes)];
    return distinctIconTypes.map((icon) => <Icon key={icon} icon={icon} size="medium" className="u-text-muted listing-icon" />);
  };

  const renderTemplateType = (type) => {
    const templateTypes = {
      contact: 'Contact',
      team: 'Team',
      system: 'System',
    };
    return templateTypes[type];
  };

  const renderTemplateText = (text) => {
    const formattedText = replaceWhiteSpaceWithSpace(text);
    return replaceVariablesByStyledComponent(TEMPLATE_MESSAGE_VARIABLE_OPTIONS, formattedText);
  };

  const templatesListColumns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Title"
          sortKey="subject"
          sortDirection={templatesListHeadersConfig.subject.direction}
          headers={templatesListHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      maxWidth: 300,
      accessor: 'subject',
      Cell: (row) => (<div>{searchText.length > 2 ? highlightSearchedText(row.original.subject, searchText) : row.original.subject}</div>),
      fixed: 'left',
      sortMethod: () => handleSort('subject'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Body"
          sortKey="message"
          sortDirection={templatesListHeadersConfig.message.direction}
          headers={templatesListHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'message',
      Cell: (row) => (
        <div>
          {searchText.length > 2 ? (
            highlightSearchedText(renderTemplateText(row.original.message), searchText)
          ) : (
            ReactHtmlParser(renderTemplateText(row.original.message))
          )}
        </div>
      ),
      fixed: 'left',
      sortMethod: () => handleSort('message'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Type"
          sortKey="type"
          sortDirection={templatesListHeadersConfig.type.direction}
          headers={templatesListHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      maxWidth: 100,
      accessor: 'type',
      Cell: (row) => (<div>{renderTemplateType(row.original.type)}</div>),
      sortMethod: () => handleSort('type'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Files"
          isCaretDisabled
          sortKey="attachment"
          sortDirection={templatesListHeadersConfig.attachment.direction}
          headers={templatesListHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
        justifyContent: 'center',
        display: 'flex',
      },
      accessor: 'attachment',
      maxWidth: 80,
      sortable: false,
      Cell: (row) => (<div className="u-flex u-flex-justify-center">{row.original.attachments.length ? renderAttachment(row.original) : null}</div>),
    },
    {
      sortable: false,
      maxWidth: 80,
      Cell: (row) => {
        const { id, favorited } = row.original;
        return (
          <div className="u-flex u-flex-justify-center">
            {renderFavoriteIcon(id, favorited)}
          </div>
        );
      },
    },
  ];

  const activeFormTemplate = formTemplates.filter((form) => form.formId === activeItemId)[0];
  const selectedFormIndex = formTemplates.findIndex((form) => form.formId === activeItemId);
  const activeMessageTemplate = selectedKey === NAVIGATION_TABS.templates ? messageTemplates.find((template) => template.id === activeItemId) : null;
  const selectedTemplateIndex = messageTemplates.findIndex((template) => template.id === activeItemId);
  const selectedFormOrTemplateIdKey = selectedKey === NAVIGATION_TABS.forms ? 'formId' : 'id';
  const selectedIndex = selectedKey === NAVIGATION_TABS.forms ? selectedFormIndex : selectedTemplateIndex;

  const rowProps = (state, rowInfo) => ({
    onClick: () => {
      handleTogglePanel(rowInfo?.original[selectedFormOrTemplateIdKey]);
    },
    style: {
      cursor: 'pointer',
    },
    className: rowInfo.index === selectedIndex ? 'selected-form-template' : 'form-row',
  });

  const renderList = () => {
    let emptyTemplate = 'Templates';
    let columns = templatesListColumns;
    let list = messageTemplates;
    if (selectedKey === NAVIGATION_TABS.forms) {
      emptyTemplate = 'Form Templates';
      columns = formTemplatesColumns;
      list = formTemplates;
    }
    const listing = list.length > 0 ? (
      <SmartTable
        data={list}
        getTrProps={rowProps}
        showPagination={hasPagination}
        pageSize={list?.length}
        sticky
        columns={columns}
        sortable
        manual={false}
        striped
      />
    ) : (!templatesPageLoading && !pageLoading && (
      <div className="form-list__noforms_wrapper">
        <EmptyMessage section={emptyTemplate} />
      </div>
    ));
    return listing;
  };

  const renderFilterItem = (item, key) => (
    <DropdownMenuItem
      key={key}
      id={item.id}
      label={item.label}
      onClick={() => handleUpdateFormTemplateFilterId(item.id)}
    />
  );

  const renderLibraryContainer = (tab) => (
    <>
      <div className="library__wrapper--header" data-cypress={tab === 'Forms' ? 'lists' : null}>
        <h3
          className="form-heading"
          data-cypress={tab === 'Forms' ? 'totalForms' : null}
        >Total {tab} ({selectedKey === NAVIGATION_TABS.forms ? count : totalTemplates})
        </h3>
        <div className="search-filter-wrapper u-flex">
          <Dropdown
            activeKey={selectedFilterId}
            wide
            reset
            icon="filter"
            className="app-page__header__filter__button"
            label="Select Filter"
            hideCaret
            title="Filter forms"
            dataCypress={tab === 'Forms' ? 'formFilter' : null}
          >
            {filterOptions.map(renderFilterItem)}
          </Dropdown>
          <Input
            placeholder={`Search ${NAVIGATION_TABS.templates === selectedKey ? 'Templates' : 'Forms'}`}
            className="search__input"
            name="Search Form"
            initialValue={searchText}
            onChange={handleSearch}
            size="large"
            addon="left"
            type="text"
            autoComplete="off"
            focus={focusSearchInput}
            dataFeatureTag={tab === 'Forms' ? 'searchForms' : null}
          >
            <Icon icon="search" />
          </Input>
        </div>
        <div className="add-btn">
          {selectedKey === NAVIGATION_TABS.forms && userHasAnyOfPermissions([FORM_EDIT]) && (
            <Button
              onClick={createRhinoForm}
              iconOnly
              type="secondary"
              title="Create Form"
              data-cypress="createForm"
            >
              <Icon icon="add" />
            </Button>
          )}
          {selectedKey === NAVIGATION_TABS.templates && userHasAnyOfPermissions([TEMPLATE_MESSAGE_CREATE]) && (
            <Button
              onClick={handleCreateRequest}
              iconOnly
              type="secondary"
              title="Create Template"
              data-cypress="createTemplate"
            >
              <Icon icon="add" />
            </Button>
          )}
        </div>
      </div>
      {(pageLoading) ?
        <PageLoader /> :
        renderList()}
    </>
  );

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': panelOpen,
  });

  const libraryClass = selectedKey === NAVIGATION_TABS.templates ? 'library__wrapper library__wrapper-templates' : 'library__wrapper';
  const dataCypress = selectedKey === NAVIGATION_TABS.templates ? 'Templates' : 'lists';
  return (
    <div className="app-page library-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel__body">
            <Scrollbars className="library-scroll__container">
              <div className="library__container list-panel">
                <div className="app-page__header">
                  <div className="app-page__header__title library__title">
                    Organization Library:
                    <div className="convo__tabs nav-tabs__wrapper">
                      <NavTabs activeKey={selectedKey} onSelect={handleTabs}>
                        {userHasAnyOfPermissions([TEMPLATE_MESSAGE_VIEW]) ? (
                          <NavTabsItem id={NAVIGATION_TABS.templates}>Templates</NavTabsItem>
                        ) : ''}
                        {isRhinoformEnabled && loggedInUserPermissions.includes(FORM_VIEW) ? (
                          <NavTabsItem id={NAVIGATION_TABS.forms} dataFeatureTag="forms">Forms</NavTabsItem>
                        ) : ''}
                      </NavTabs>
                    </div>
                  </div>
                </div>
                <div className={libraryClass} data-cypress={dataCypress}>
                  {selectedKey === NAVIGATION_TABS.forms ? renderLibraryContainer('Forms') : renderLibraryContainer('Templates')}
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
        {activeFormTemplate && panelOpen && (
        <LibraryFormPanelContainer
          filterUpdateHandler={handleUpdateFormTemplateFilterId}
          activeFormTemplate={activeFormTemplate}
          handleTogglePanel={handleTogglePanel}
          selectedFormFilter={selectedFilterId}
        />
        )}
        {activeMessageTemplate && panelOpen && (
          <MessageTemplatePanel
            activeMessageTemplate={activeMessageTemplate}
            handleTogglePanel={handleTogglePanel}
            getFormS3Url={getFormS3Url}
            formS3Url={formS3Url}
          />
        )}
      </div>
    </div>
  );
};

OrganizationLibrary.propTypes = {
  selectedKey: PropTypes.number.isRequired,
  handleTabs: PropTypes.func.isRequired,
  selectedFilterId: PropTypes.string.isRequired,
  createRhinoForm: PropTypes.func,
  filterOptions: PropTypes.array.isRequired,
  panelOpen: PropTypes.bool.isRequired,
  formTemplates: PropTypes.array,
  messageTemplates: PropTypes.array,
  count: PropTypes.number,
  totalTemplates: PropTypes.number,
  pageLoading: PropTypes.bool,
  templatesPageLoading: PropTypes.bool,
  isRhinoformEnabled: PropTypes.bool.isRequired,
  formTemplatesHeadersConfig: PropTypes.object.isRequired,
  templatesListHeadersConfig: PropTypes.object.isRequired,
  hasPagination: PropTypes.bool.isRequired,
  handleUpdateFormTemplateFilterId: PropTypes.func.isRequired,
  handleFavorite: PropTypes.func.isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  activeItemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  searchText: PropTypes.string,
  focusSearchInput: PropTypes.bool,
  handleSort: PropTypes.func.isRequired,
  handleCreateRequest: PropTypes.func.isRequired,
  loggedInUserPermissions: PropTypes.array.isRequired,
  getFormS3Url: PropTypes.func.isRequired,
  formS3Url: PropTypes.object,
  types: PropTypes.object,
};

export default OrganizationLibrary;
