import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import {
  Icon,
  Avatar,
  Checkbox,
  CheckboxGroup,
} from 'rhinostyle';

import HtmlMessage from './HtmlMessage';
import Attachment from './Attachment';
import { AppConstants, Types } from '../constants';
import {
  DataHelpers,
  DateHelpers,
  ErrorCodeHelpers,
  PhoneHelpers,
  SavedContentHelpers,
  UserHelpers,
} from '../helpers';
import {
  TYPE_APPOINTMENT_NOTIFICATION,
  TYPE_FACEBOOK,
  TYPE_FORM_COMPLETED,
  TYPE_FORM_CREATED,
  TYPE_FORM_SENT,
  TYPE_HIPAA_CONSENT_DECLINED,
  TYPE_HIPAA_CONSENT_GRANTED,
  TYPE_HIPAA_CONSENT_PENDING,
  TYPE_MARKETING_CONSENT_DECLINED,
  TYPE_MARKETING_CONSENT_GRANTED,
  TYPE_MARKETING_CONSENT_PENDING,
  TYPE_RHINOPAY_CONSENT_DECLINED,
  TYPE_RHINOPAY_CONSENT_GRANTED,
  TYPE_RHINOPAY_CONSENT_PENDING,
  TYPE_SMS,
  TYPE_USER_LIBRARY_SAVED_CONTENT,
} from '../constants/Types';
import { MAX_FACEBOOK_FILE_SIZE, MAX_MMS_FILE_SIZE } from '../constants/AppConstants';
import { formattedConnectionResultTypes } from '../constants/RhinocallConstants';
import LinkedAttachments from './LinkedAttachments';
import PdfViewerContainer from '../containers/PdfViewerContainer';
import LinkedForms from './LinkedForms';
import { getFormById } from '../reducers/formReducer';
import { getActiveUser, getLoggedInUser, getLoggedInUserOrganization } from '../selectors/userSelectors';
import { setSelectedEventIds } from '../reducers/savedContentReducer';
import { translateEventText } from '../reducers/inboxReducer';
import { isAndroid } from '../helpers/BrowserHelpers';
import { getEventById } from '../selectors/inboxSelectors';

const ThreadEvent = (props) => {
  const {
    activeUser,
    channels,
    currentUser,
    event,
    eventLanguage,
    eventSender,
    formTemplate,
    isChannelInfoVisible,
    isContentSelected,
    isNativeApp,
    isSecureThread,
    isSelectionModeEnabled,
    isSelectionPreviewModeEnabled,
    searchJumpEventId,
    searchText,
    toggleLightbox,
    types,
    userOrganization,
    users,
  } = props;
  const eventRef = useRef();
  const { isMessageTranslationEnabled, isSavedContentEnabled, isMessageTranslationFeatureEnabled } = userOrganization;
  const isMessageTranslationEnabledForOrg = isMessageTranslationEnabled && isMessageTranslationFeatureEnabled;
  const isSupportedContent = event.attachments.some((attachment) => AppConstants.NON_SUPPORTED_CONTENT.includes(attachment.attachmentUrl.split('.').pop()));
  const isAvailableForSelection = isSelectionModeEnabled && !isSupportedContent;
  const dispatch = useDispatch();
  const [isPdfViewerOpen, openPdfViewer] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [contactDetails, setContactDetails] = useState(0);
  const [templateFormId, setTemplateFormId] = useState('');
  const [isTranslated, setIsTranslated] = useState(false);

  async function handleTranslateText() {
    if (!isTranslated) {
      if (!event.details.translatedText) {
        await dispatch(translateEventText({
          languageCode: eventLanguage,
          text: event.details.text,
          targetLanguage: 'en',
          id: event.id,
        }));
      }
      setIsTranslated(true);
    } else {
      setIsTranslated(false);
    }
  }

  function handlePdfViewer(formId, contactId) {
    setTemplateFormId(formId);
    openPdfViewer(true);
    if (formTemplate[formId]) {
      setAttachmentUrl(formTemplate[formId].formInternalData);
      setContactDetails(contactId);
      return;
    }
    dispatch(getFormById(formId));
  }

  function handleFormViewer(formUrl, contactId) {
    openPdfViewer(true);
    setAttachmentUrl(formUrl);
    setContactDetails(contactId);
  }

  useEffect(() => {
    if (formTemplate[templateFormId]) {
      setAttachmentUrl(formTemplate[templateFormId].formInternalData);
    }
  }, [formTemplate]);

  const {
    formatAvatarName,
    formatHipaaStatus,
    formatRhinoPayStatus,
    formatFacebookName,
    formatName,
    formatMarketingStatus,
  } = UserHelpers;

  const {
    TYPE_EVENT_STATUS_ERROR,
    TYPE_EVENT_STATUS_DELIVERED,
    TYPE_EVENT_STATUS_CONFIRMED_ERROR,
    TYPE_PATIENT,
    TYPE_USER_OTHER,
  } = Types;
  if (!event) return null;

  const tempFormattedName = isSecureThread && event.incoming ? formatName(eventSender, false, true) : formatName(eventSender);
  const formattedName = (tempFormattedName === 'Rhino System' || event.sender === AppConstants.SYSTEM_USER_ID) ? userOrganization.name : tempFormattedName;
  const profileImageUrl = eventSender.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${eventSender.profileImageUrl}` : '';

  const valueOfEventType = types[event.typeId].value;
  const isMessageEvent = valueOfEventType === 'message'
    || valueOfEventType === 'appointment reminder'; // Note this does NOT cover Rhinosecure messages. See 'isSecureEvent'.
  const isAppointmentNotificationEvent = event.typeId === TYPE_APPOINTMENT_NOTIFICATION;
  const isNoteEvent = valueOfEventType === 'note';
  const isHipaaEvent = valueOfEventType === 'hipaa consent';
  const isSavedContentEvent = valueOfEventType === 'saved content';
  const isSentSavedContentEvent = valueOfEventType === 'saved content sent';
  const isRhinopayEvent = valueOfEventType === 'rhinopay consent';
  const isMarketingEvent = valueOfEventType === 'marketing consent';
  const isAssignmentEvent = valueOfEventType === 'assignment';
  const isSecureEvent = valueOfEventType === 'secure message';
  const isVideoChatEvent = valueOfEventType === 'video chat';
  const isMergeEvent = valueOfEventType === 'merge contact';
  const isCopyContentEvent = valueOfEventType === 'copy content';
  const isRhinoformSendEvent = valueOfEventType === 'rhinoform';
  const isCallEvent = valueOfEventType === 'call';
  const sentViaSMS = channels[event.channels[0]]?.typeId === TYPE_SMS;
  const sentViaFacebook = channels[event.channels[0]]?.typeId === TYPE_FACEBOOK;
  const isDefaultMessage = (
    !isSavedContentEvent &&
    !isHipaaEvent &&
    !isAssignmentEvent &&
    !isRhinopayEvent &&
    !isMarketingEvent &&
    !isVideoChatEvent &&
    !isSentSavedContentEvent &&
    !isMergeEvent &&
    !isCopyContentEvent &&
    !isRhinoformSendEvent &&
    !isCallEvent &&
    !isAppointmentNotificationEvent
  );
  const isMessageReadStatusAvailable = !isSecureThread && !event.incoming && isSecureEvent;
  const isMessageRead = event.isRead;
  const isMessageFailed = event.statusTypeId === TYPE_EVENT_STATUS_ERROR || event.statusTypeId === TYPE_EVENT_STATUS_CONFIRMED_ERROR;
  const isNonTextableError = ErrorCodeHelpers.isNonTextableErrorCode(event.error);
  const messageEventChannelTypeId = isMessageEvent ? channels[event.channels[0]]?.typeId : '';

  const isMessageFooterTooltipVisible = (
    DataHelpers.hasData(event.externalEndpoint) &&
    !event.incoming &&
    currentUser.typeId !== TYPE_PATIENT &&
    messageEventChannelTypeId !== Types.TYPE_FACEBOOK
  );

  const convoEventWrapperClasses = cx('convo__event__wrapper', {
    'convo__event__wrapper--inverted': isAvailableForSelection && !isSelectionPreviewModeEnabled && event.incoming,
  });

  const eventClasses = cx('convo__item', {
    'convo__item--active-search-result': event.id === searchJumpEventId,
    'convo__item--selection-mode-enabled': isAvailableForSelection,
    'convo__item--inbound': event.incoming,
    'convo__item--outbound': !event.incoming,
  });

  const eventCheckboxClasses = cx('convo__item__checkbox', {
    'convo__item__checkbox--left': isAvailableForSelection && !isSelectionPreviewModeEnabled && event.incoming,
    'convo__item__checkbox--right': isAvailableForSelection && !isSelectionPreviewModeEnabled && !event.incoming,
  });

  const dataCypressValue = cx('', {
    inboundMessage: event.incoming && !isNoteEvent,
    outboundMessage: !event.incoming && !isNoteEvent,
    noteMessage: isNoteEvent,
  });

  const getChannelIcon = (channelType) => {
    let iconType = null;

    switch (channelType) {
      case 'email':
        iconType = 'email';
        break;
      case 'secure':
        iconType = 'lock';
        break;
      case 'facebook':
        iconType = 'facebook';
        break;
      case 'instagram':
        iconType = 'instagram';
        break;
      default:
        iconType = 'chat';
    }

    return iconType;
  };

  const handleSelectContent = (id) => {
    dispatch(setSelectedEventIds(id));
  };

  const getSavedContentText = (savedContentEvent) => {
    switch (savedContentEvent.eventStatusTypeId || savedContentEvent.statusTypeId) {
      case TYPE_EVENT_STATUS_DELIVERED:
        return (
          <div className="convo__event__title">
            <Icon bump="up" icon="checkmark" className="u-text-secondary" />
            &nbsp;&nbsp;Message Selection <strong>Successfully Sent</strong> by {formatName(eventSender)}
          </div>
        );
      case TYPE_EVENT_STATUS_ERROR:
        return (
          <div className="convo__event__title">
            <Icon bump="up" icon="alert-triangle" className="u-text-danger" />
            &nbsp;&nbsp;Message Selection <strong>Failed to Send</strong> (Sent By {formatName(eventSender)})
          </div>
        );
      default:
        return (
          <div className="convo__event__title">
            &nbsp;&nbsp; Message Selection <strong>Sending to EHR</strong> (Sent by {formatName(eventSender)})
          </div>
        );
    }
  };

  const getCompletedFormText = (savedContentEvent) => {
    switch (savedContentEvent.eventStatusTypeId || savedContentEvent.statusTypeId) {
      case TYPE_EVENT_STATUS_DELIVERED:
        return (
          <div className="convo__event__title">
            <Icon bump="up" icon="checkmark" className="u-text-secondary" />
            &nbsp;&nbsp;Completed Form <strong>Successfully Sent</strong> by {formatName(eventSender)}
          </div>
        );
      case TYPE_EVENT_STATUS_ERROR:
        return (
          <div className="convo__event__title">
            <Icon bump="up" icon="alert-triangle" className="u-text-danger" />
            &nbsp;&nbsp;Completed Form <strong>Failed to Send</strong> (Sent By {formatName(eventSender)})
          </div>
        );
      default:
        return (
          <div className="convo__event__title">
            &nbsp;&nbsp; Completed Form <strong>Sending to EHR</strong> (Sent by {formatName(eventSender)})
          </div>
        );
    }
  };

  const getExternalEndpointValue = (incomingEvent) => {
    let endpointValue = null;

    switch (types[incomingEvent.typeId]?.class) {
      case 'phone':
        endpointValue = PhoneHelpers.formatPhone(incomingEvent.value);
        break;
      case 'facebook':
        endpointValue = formatFacebookName(activeUser, incomingEvent.value);
        break;
      default:
        endpointValue = incomingEvent.value;
    }

    return endpointValue;
  };

  const renderExternalEndpointInfo = (eventObject, key) => {
    if (!eventObject.externalEndpoint) return false;

    const iconType = getChannelIcon(types[eventObject.externalEndpoint.typeId]?.class);
    if (eventObject.details.fromWebForm) {
      return <span key={key}>(Web Form)&nbsp;{getExternalEndpointValue(eventObject.externalEndpoint)}&nbsp;&nbsp;</span>;
    }
    return (
      <span key={key}>
        <Icon icon={iconType} key={key} />&nbsp;{getExternalEndpointValue(eventObject.externalEndpoint)}&nbsp;&nbsp;
      </span>
    );
  };

  const renderChannelNameInfo = (eventChannels) => {
    const [channelId] = eventChannels; // Despite 'eventChannels' being an array, there is only ever 1 channel, so we just get the first element.
    const channel = channels[channelId];
    const iconType = getChannelIcon(types[channel?.typeId]?.value);
    return (
      <span data-cypress="inbox-message-channel-name">
        <Icon icon={iconType} />&nbsp;{channel?.name}&nbsp;&nbsp;
      </span>
    );
  };

  const renderChannelInfo = (eventChannels) => {
    let channelInfo = '';
    if (isNoteEvent) {
      channelInfo = <span><Icon icon="note" bump="up" />&nbsp;Note&nbsp;&nbsp;</span>;
    } else {
      // incoming message is from a channel and outgoing message is from a number OR from a secure channel in contact (patient) case.
      const channelInfoForPatientView = ((event.incoming && event.externalEndpoint) || (!event.incoming && !event.externalEndpoint)) ?
        renderChannelNameInfo(eventChannels)
        : renderExternalEndpointInfo(event);
      // incoming message is from a number and outgoing message is from a channel in member case.
      const channelInfoForMemberView = (event.incoming && event.externalEndpoint) ?
        renderExternalEndpointInfo(event)
        : renderChannelNameInfo(eventChannels);
      channelInfo = (currentUser.typeId === TYPE_PATIENT || currentUser.typeId === TYPE_USER_OTHER) ? channelInfoForPatientView : channelInfoForMemberView;
    }

    return channelInfo;
  };

  const renderAttachment = (attachment, key, incoming) => {
    // if file is too large to be sent in a thread, a sharelink will be sent via API instead, so don't render
    if (incoming && attachment.bytes > MAX_MMS_FILE_SIZE && sentViaSMS) {
      return null;
    } else
    if (incoming && attachment.bytes > MAX_FACEBOOK_FILE_SIZE && sentViaFacebook) {
      return null;
    } else {
      return (
        <Attachment attachment={attachment} key={key} incoming={incoming} toggleLightbox={toggleLightbox} />
      );
    }
  };

  const renderSelectContentCheckbox = () => isAvailableForSelection && !isSelectionPreviewModeEnabled && (
    <div className={eventCheckboxClasses}>
      <CheckboxGroup>
        <Checkbox
          onChange={() => handleSelectContent(event.id)}
          isChecked={isContentSelected}
          name={`selectEvent${event.id}`}
          label="&#8203;"
        />
      </CheckboxGroup>
    </div>
  );

  const renderAppointmentNotificationEvent = () => {
    const formattedUserName = formatName(users[event.user]);

    return (
      <div className={convoEventWrapperClasses}>
        <div
          className="convo__event"
          id={`js-convo__item-${event.id}`}
          key={event.id}
          onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
        >
          <div className="convo__event__title">
            Message <strong>sent</strong> for {formattedUserName}
          </div>
          <div className="convo__event__subtext">{DateHelpers.formatTimestamp(event.timestamp)}</div>
        </div>
        {renderSelectContentCheckbox()}
      </div>
    );
  };

  const renderHipaaEvent = () => {
    const hipaaStatus = formatHipaaStatus(event.details.hipaaStatusTypeId);
    const hipaaIconClasses = cx('convo__event__icon hipaa-status__icon', {
      'is-denied': event.details.hipaaStatusTypeId === TYPE_HIPAA_CONSENT_DECLINED,
      'is-unknown': event.details.hipaaStatusTypeId === TYPE_HIPAA_CONSENT_PENDING,
      'is-granted': event.details.hipaaStatusTypeId === TYPE_HIPAA_CONSENT_GRANTED,
    });
    const sender = users[event.sender];

    return (
      <div className={convoEventWrapperClasses}>
        <div
          className="convo__event"
          id={`js-convo__item-${event.id}`}
          key={event.id}
          onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
        >
          <div className="convo__event__title">
            <Icon bump="up" icon="hipaa" className={hipaaIconClasses} />
            Consent Marked <strong>{hipaaStatus}</strong> by {sender.firstName} {sender.lastName}
          </div>
          <div className="convo__event__subtext">{DateHelpers.formatTimestamp(event.timestamp)}</div>
        </div>
        {renderSelectContentCheckbox()}
      </div>
    );
  };

  const renderCopyContent = () => {
    const sender = users[event.sender];
    const isFrom = event.details.from === 1;
    return (
      <div className={convoEventWrapperClasses}>
        <div
          className="convo__event"
          id={`js-convo__item-${event.id}`}
          key={event.id}
          onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
        >
          <div className="convo__event__title">
            File {event.details.title} was
            copied {isFrom ? 'from' : 'to'} {event.details.contact.firstName} {event.details.contact.lastName} by {sender.firstName} {sender.lastName}
          </div>
          <div className="convo__event__subtext">
            {event.details.deleted === 1 ? (
              <>
                <span>{DateHelpers.formatTimestamp(event.lastUpdatedAt)}</span>
                <span className="u-text-danger">&nbsp;(deleted)</span>
              </>
            ) :
              <span>{DateHelpers.formatTimestamp(event.timestamp)}</span>}
          </div>
        </div>
        {renderSelectContentCheckbox()}
      </div>
    );
  };

  const convoEventClass = cx('convo__event', {
    'convo__event--selection-mode': isAvailableForSelection && !isSelectionPreviewModeEnabled,
  });

  const renderSavedContentEvent = () => (
    <div className={convoEventWrapperClasses}>
      <div
        className={convoEventClass}
        id={`js-convo__item-${event.id}`}
        key={event.id}
        onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
      >
        <div className="convo__event__title">
          <Icon bump="up" icon="checkmark" className="u-text-secondary" />
          &nbsp;&nbsp;Message Selection <strong>Saved</strong> by {formatName(eventSender)}
        </div>
        <div className="convo__event__subtext">
          {DateHelpers.formatTimestamp(event.timestamp)}
          &nbsp;
          {
            isSavedContentEnabled && (event.details.deleted === 0 ?
              (
                <Link // eslint-disable-line jsx-a11y/anchor-is-valid
                  id={`savedContent=${event.details.savedContentItemId}`}
                  to={`/saved-content/${event.details.savedContentItemId}`}
                  className="u-text-body u-text-underline"
                >Saved Details
                </Link>
              ) : <span className="u-text-danger">(deleted)</span>
            )
          }
        </div>
      </div>
      {renderSelectContentCheckbox()}
    </div>
  );

  const renderSentSavedContentEvent = () => (
    <div className={convoEventWrapperClasses}>
      <div
        className="convo__event"
        id={`js-convo__item-${event.id}`}
        key={event.id}
        onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
      >
        {event.details.libraryTypeId === TYPE_USER_LIBRARY_SAVED_CONTENT ? getSavedContentText(event) : getCompletedFormText(event)}
        <div className="convo__event__subtext">
          {DateHelpers.formatTimestamp(event.timestamp)}
          &nbsp;
          {
            isSavedContentEnabled && (event.details.deleted === 0 ? // eslint-disable-line  no-nested-ternary
              (
                (event.details.libraryTypeId === TYPE_USER_LIBRARY_SAVED_CONTENT ?
                  (
                    <Link // eslint-disable-line jsx-a11y/anchor-is-valid
                      id={`savedContent=${event.details.savedContentItemId}`}
                      to={`/saved-content/${event.details.savedContentItemId}`}
                      className="u-text-body u-text-underline"
                    >Saved Details
                    </Link>
                  ) : (
                    <span className="u-text-body u-text-underline convo__event__subtext__link" onClick={() => handleFormViewer(event?.details?.savedContentPdfLink, event?.sender.id)}>
                      Completed Form
                    </span>
                  )
                )
              ) : <span className="u-text-danger">(deleted)</span>
            )
          }
        </div>
      </div>
      {renderSelectContentCheckbox()}
    </div>
  );

  const renderRhinopayEvent = () => {
    const rhinopayConsentStatus = formatRhinoPayStatus(event.details.rhinopayConsentStatusTypeId);

    const rhinopayIconClasses = cx('convo__event__icon', {
      'is-denied': event.details.rhinopayConsentStatusTypeId === TYPE_RHINOPAY_CONSENT_DECLINED,
      'is-unknown': event.details.rhinopayConsentStatusTypeId === TYPE_RHINOPAY_CONSENT_PENDING,
      'is-granted': event.details.rhinopayConsentStatusTypeId === TYPE_RHINOPAY_CONSENT_GRANTED,
    });
    const sender = users[event.sender];
    return (
      <div className={convoEventWrapperClasses}>
        <div
          className="convo__event"
          id={`js-convo__item-${event.id}`}
          key={event.id}
          onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
        >
          <div className="convo__event__title">
            <Icon bump="up" icon="rhinopay" className={rhinopayIconClasses} />
            Consent Marked <strong>{rhinopayConsentStatus}</strong> by {sender.firstName} {sender.lastName}
          </div>
          <div className="convo__event__subtext">{DateHelpers.formatTimestamp(event.timestamp)}</div>
        </div>
        {renderSelectContentCheckbox()}
      </div>
    );
  };

  const renderVideoChatEvent = () => {
    const formattedUserName = formatName(users[event.user]);
    const sender = users[event.sender];
    const timestamp = DateHelpers.formatTimestamp(event.details.createdAt);
    const duration = DateHelpers.findDurationFromMilliseconds(event.details.durationMs);
    return (
      <div className={convoEventWrapperClasses}>
        <div
          className="convo__event"
          id={`js-convo__item-${event.id}`}
          key={event.id}
          onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
        >
          <div className="convo__event__title">
            <Icon bump="up" icon="video" className="convo__event__icon convo__event__icon--video" />
            {sender.firstName} {sender.lastName}&apos;s RhinoVideo session with {formattedUserName} ended.
          </div>
          <div className="convo__event__subtext">
            {`${timestamp} (${duration})`}
          </div>
        </div>
        {renderSelectContentCheckbox()}
      </div>
    );
  };

  const renderCallStatus = (formattedUserName) => {
    if (!event.details.connectionResultTypeId) {
      return ` with ${formattedUserName} ended.`;
    }
    const callStatus = formattedConnectionResultTypes[event.details.connectionResultTypeId];
    return <>: <span className="u-font-weight-bold">{callStatus.description}</span> {callStatus.appendedString} {formattedUserName}</>;
  };

  const renderCallEvent = () => {
    const formattedUserName = formatName(users[event.user]);
    const sender = users[event.sender];
    const timestamp = DateHelpers.formatTimestamp(event.details.createdAt);
    const duration = DateHelpers.findDurationFromMilliseconds(event.details.durationMs);
    return (
      <div className={convoEventWrapperClasses}>
        <div
          className="convo__event"
          id={`js-convo__item-${event.id}`}
          key={event.id}
          onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
        >
          <div
            className="convo__event__title"
            data-cypress="callCompletedEvent"
          >
            <Icon bump="up" icon="phone" className="convo__event__icon" />
            {sender.firstName} {sender.lastName}&apos;s call{renderCallStatus(formattedUserName)}
          </div>
          <div className="convo__event__subtext">
            {`${timestamp} ${duration && `(${duration})`}`}
          </div>
        </div>
        {renderSelectContentCheckbox()}
      </div>
    );
  };

  const renderRhinoformSendEvent = () => {
    const sender = users[event.sender];
    const { formId, formTypeId } = event.details || {};
    return (
      <div className={convoEventWrapperClasses}>
        <div
          className="convo__event"
          id={`js-convo__item-${event.id}`}
          key={event.id}
          onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
        >
          <div className="convo__event__title" data-cypress="formSentEvent">
            <Icon bump="up" icon="checkmark" className="convo__event__icon convo__event__icon--complete" />
            {formTypeId === TYPE_FORM_COMPLETED && (<>Form <strong>Completed</strong></>)}
            {formTypeId === TYPE_FORM_SENT && (<>Form <strong>Sent</strong> by {sender.firstName} {sender.lastName}.</>)}
            {formTypeId === TYPE_FORM_CREATED && (<>Form <strong>Generated</strong> by {sender.firstName} {sender.lastName}.</>)}
          </div>
          <div className="convo__event__subtext">
            {DateHelpers.formatTimestamp(event.timestamp)} &nbsp;
            {formTypeId === TYPE_FORM_COMPLETED && (
              <span className="u-text-body u-text-underline convo__event__subtext__link" onClick={() => handlePdfViewer(formId, sender.id)}>
                Completed Form
              </span>
            )}
          </div>
        </div>
        {renderSelectContentCheckbox()}
      </div>
    );
  };

  const renderMarketingEvent = () => {
    const marketingConsentStatus = formatMarketingStatus(event.details.marketingConsentStatusTypeId);

    const marketingIconClasses = cx('convo__event__icon', {
      'is-denied': event.details.marketingConsentStatusTypeId === TYPE_MARKETING_CONSENT_DECLINED,
      'is-unknown': event.details.marketingConsentStatusTypeId === TYPE_MARKETING_CONSENT_PENDING,
      'is-granted': event.details.marketingConsentStatusTypeId === TYPE_MARKETING_CONSENT_GRANTED,
    });
    const sender = users[event.sender];

    return (
      <div className={convoEventWrapperClasses}>
        <div
          className="convo__event"
          id={`js-convo__item-${event.id}`}
          key={event.id}
          onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
        >
          <div className="convo__event__title">
            <Icon bump="up" icon="marketing" className={marketingIconClasses} />
            Consent Marked <strong>{marketingConsentStatus}</strong> by {sender.firstName} {sender.lastName}
          </div>
          <div className="convo__event__subtext">{DateHelpers.formatTimestamp(event.timestamp)}</div>
        </div>
        {renderSelectContentCheckbox()}
      </div>
    );
  };

  const renderAssignmentEvent = () => {
    if (event.details.isMarkedComplete) {
      const eventText = event.details.to ?
        <>Assignment Marked <strong>Complete</strong></> : <>Conversation <strong>Closed</strong></>;
      const assignedBy = event.details.assignedBy === 'Rhino System' ? userOrganization.name : event.details.assignedBy;
      return (
        <div className={convoEventWrapperClasses}>
          <div
            className="convo__event"
            id={`js-convo__item-${event.id}`}
            key={event.id}
            onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
          >
            <div className="convo__event__title">
              <Icon bump="up" icon="checkmark" className="convo__event__icon convo__event__icon--complete" />
              {eventText} by {assignedBy}
            </div>
            <div className="convo__event__subtext">
              {DateHelpers.formatTimestamp(event.timestamp)}
            </div>
          </div>
          {renderSelectContentCheckbox()}
        </div>
      );
    }

    return (
      <div className={convoEventWrapperClasses}>
        <div
          className="convo__event"
          id={`js-convo__item-${event.id}`}
          key={event.id}
          onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
        >
          <div className="convo__event__title">
            <Icon bump="up" icon="assign" className="convo__event__icon convo__event__icon--assign" />
            Assigned to <strong>{event.details.to}</strong> by {event.details.assignedBy}
          </div>
          <div className="convo__event__subtext">
            (from {event.details.from})&nbsp;&nbsp; {DateHelpers.formatTimestamp(event.timestamp)}
          </div>
        </div>
        {renderSelectContentCheckbox()}
      </div>
    );
  };

  const renderMergeEvent = () => {
    const eventText = <>Contact <strong>Merged</strong> into</>;
    const mergedBy = formatName(eventSender);
    const { firstName: masterFirstName, lastName: masterLastName } = users[event.user];
    const { firstName, lastName } = event.details.nonMaster;
    const masterContact = masterFirstName && masterLastName ? `${masterFirstName} ${masterLastName}` : (masterFirstName || masterLastName);
    const isNonMasterNameAvailable = firstName || lastName;
    const nonMasterContact = firstName && lastName ? `${firstName} ${lastName}` : (firstName || lastName);
    const nonMaster = <>{nonMasterContact} <strong>Merged</strong> into </>;
    return (
      <div className={convoEventWrapperClasses}>
        <div
          className="convo__event"
          id={`js-convo__item-${event.id}`}
          key={event.id}
          onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
        >
          <div className="convo__event__title">
            <Icon bump="up" icon="checkmark" className="convo__event__icon convo__event__icon--complete" />
            {isNonMasterNameAvailable ? nonMaster : eventText} {masterContact}&#39;s profile by {mergedBy}
          </div>
          <div className="convo__event__subtext">
            {DateHelpers.formatTimestamp(event.timestamp)}
          </div>
        </div>
        {renderSelectContentCheckbox()}
      </div>
    );
  };

  const renderMessageFooterTooltipPhone = () => {
    const phoneDetail = `${PhoneHelpers.formatPhone(event.externalEndpoint.value)}
    (${PhoneHelpers.formatTypes(event.externalEndpoint)})`;
    return (
      <p className="convo__item__tooltip__description">
        {isMessageFailed && (
          <span className="u-p-r-small">
            <Icon icon="close" className="convo__item__tooltip__failed-icon" />
          </span>
        )}
        {phoneDetail}
      </p>
    );
  };

  const renderMessageFooterTooltipContent = () => (
    <div className="convo__item__tooltip__wrapper">
      <div className="convo__item__tooltip">
        <h6 className="convo__item__tooltip__title">
          OUTGOING PHONE NUMBER
        </h6>
        {renderMessageFooterTooltipPhone()}
      </div>
    </div>
  );

  const renderMessageFooter = () => (
    <div className="convo__item__footer__message">
      {isChannelInfoVisible && renderChannelInfo(event.channels)}
      <span title={DateHelpers.fullTimestamp(event.timestamp)}>{DateHelpers.formatTimestamp(event.timestamp)}</span>
      {/* This block should be shown when the message is read. */}
      {(isMessageReadStatusAvailable && isMessageRead) ? (
        <span className="u-text-success u-m-l-small" data-cypress="seen">
          <Icon icon="checkmark" />&nbsp;Seen
        </span>
      ) : null}
      {/* This block should be shown when a message does not send correctly. */}
      {isMessageFailed && !isNoteEvent && !event.incoming && !isNonTextableError && (
        <strong className="u-text-danger">
          &nbsp; Failed
        </strong>
      )}
      {/* This error block is shown only for non-textable errors */}
      {isMessageFailed && !isNoteEvent && !event.incoming && isNonTextableError && (
        <span>
          <strong className="u-text-danger">
            &nbsp; Failed Non-textable Number
          </strong>
        </span>
      )}
      {isMessageFooterTooltipVisible && renderMessageFooterTooltipContent()}
    </div>
  );

  const renderAttachments = () => {
    const isNativeAndroid = isNativeApp && isAndroid();
    const eventContainsText = isTranslated && event.details.translatedText ? event.details.translatedText : event.details?.text;
    if (isNoteEvent) {
      return (
        <div>
          <LinkedAttachments isNativeAndroid={isNativeAndroid} event={event} breakBeforeFirstAttachment={eventContainsText} />
          {event.attachments.length > 0 &&
            <div className="convo__item__body__msg__attachments">{event.attachments.map((a, k) => renderAttachment(a, k, event.incoming))}</div>}
        </div>
      );
    } else {
      return (
        <>
          {event.rhinoFormContent?.length > 0 && (
            <LinkedForms
              event={event}
              breakBeforeFirstAttachment={eventContainsText}
            />
          )}
          <LinkedAttachments isNativeAndroid={isNativeAndroid} event={event} breakBeforeFirstAttachment={eventContainsText} />
        </>
      );
    }
  };

  const renderMessageEvent = () => (
    <div className={convoEventWrapperClasses}>
      <div
        onClick={() => isAvailableForSelection && !isSelectionPreviewModeEnabled && handleSelectContent(event.id)}
        className={eventClasses}
        id={`js-convo__item-${event.id}`}
        key={event.id}
      >
        <div>
          <div className="convo__item__header">{formattedName}</div>
          <div className="convo__item__body" data-cypress={dataCypressValue}>
            <div className="convo__item__body__avatar">
              <Avatar
                image={profileImageUrl}
                name={formatAvatarName(eventSender.firstName, eventSender.lastName)}
                size="small"
                type={!event.incoming ? 'member' : 'default'}
              />
            </div>
            <div>
              <HtmlMessage
                isSecureThread={isSecureThread}
                event={event}
                searchText={searchText}
                isNoteEvent={isNoteEvent}
                isTranslated={isTranslated}
                appendedElement={renderAttachments()}
                isRightToLeft={!!(!isTranslated && eventLanguage?.isRightToLeft)}
              />
              {!isNoteEvent && event.attachments.length > 0 &&
                <div className="convo__item__body__msg__attachments">{event.attachments.map((a, k) => renderAttachment(a, k, event.incoming))}</div>}
            </div>
          </div>
          <div className="convo__item__footer">
            {renderMessageFooter()}
            <div className="translation-button">
              {(eventLanguage && isMessageTranslationEnabledForOrg) && (
                <span
                  className="convo__item__translation u-text-left"
                  onClick={handleTranslateText}
                >
                  {(isTranslated && event.details.translatedText) ? `See Original (${eventLanguage?.name})` : 'See Translation'}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {renderSelectContentCheckbox()}
    </div>
  );

  return (
    <div ref={eventRef}>
      {isAssignmentEvent && renderAssignmentEvent()}
      {isHipaaEvent && renderHipaaEvent()}
      {isCopyContentEvent && renderCopyContent()}
      {isSavedContentEvent && renderSavedContentEvent()}
      {isSentSavedContentEvent && renderSentSavedContentEvent()}
      {isMergeEvent && renderMergeEvent()}
      {isRhinopayEvent && renderRhinopayEvent()}
      {isMarketingEvent && renderMarketingEvent()}
      {isDefaultMessage && renderMessageEvent()}
      {isVideoChatEvent && renderVideoChatEvent()}
      {isCallEvent && renderCallEvent()}
      {isRhinoformSendEvent && renderRhinoformSendEvent()}
      {isAppointmentNotificationEvent && renderAppointmentNotificationEvent()}
      <PdfViewerContainer
        open={!!(isPdfViewerOpen && attachmentUrl)}
        attachmentUrl={SavedContentHelpers.getAttachmentUrl(attachmentUrl, contactDetails)}
        fileName={attachmentUrl}
        handlePdfViewerClose={() => openPdfViewer(!isPdfViewerOpen)}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const {
    channel,
    form,
    language,
    nativeApp,
    savedContent,
    type,
    user,
  } = state;
  const event = getEventById(state, props);
  return {
    activeUser: getActiveUser(state),
    channels: channel.channels,
    currentUser: getLoggedInUser(state),
    event,
    eventLanguage: event && language.languages?.[event.languageId],
    eventSender: user.users[event.sender],
    formS3Url: form.formS3Url,
    formTemplate: form.formS3Url,
    isContentSelected: savedContent.selectedEventIds.includes(props.eventId),
    isNativeApp: nativeApp.isNativeApp,
    isSelectionModeEnabled: savedContent.isSelectionModeEnabled,
    isSelectionPreviewModeEnabled: savedContent.isSelectionPreviewModeEnabled,
    types: type.types,
    userOrganization: getLoggedInUserOrganization(state),
    users: user.users,
  };
};

ThreadEvent.propTypes = {
  activeUser: PropTypes.object,
  channels: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  eventLanguage: PropTypes.object,
  eventSender: PropTypes.object,
  formTemplate: PropTypes.object,
  isChannelInfoVisible: PropTypes.bool,
  isContentSelected: PropTypes.bool,
  isNativeApp: PropTypes.bool,
  isSecureThread: PropTypes.bool,
  isSelectionModeEnabled: PropTypes.bool,
  isSelectionPreviewModeEnabled: PropTypes.bool,
  searchJumpEventId: PropTypes.number,
  searchText: PropTypes.string,
  toggleLightbox: PropTypes.func,
  types: PropTypes.object.isRequired,
  userOrganization: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
};
export default connect(mapStateToProps)(ThreadEvent);
