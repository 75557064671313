import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Icon,
} from 'rhinostyle';
import {
  UserHelpers,
  DataHelpers,
  StringHelpers,
} from '../helpers';
import {
  TEAM_CONVERSATION_EDIT,
} from '../constants/UserPermissionsConstants';

import MessageComposeActions from './MessageComposeActions';
import {
  getActiveChatGroup,
} from '../selectors/chatSelectors';
import MentionComposeArea from './MentionComposeArea';
import * as ThreadReducer from '../reducers/threadReducer';
import {
  getLoggedInUser,
  getActiveUser,
} from '../selectors/userSelectors';
import ThreadTypingWrapper from './ThreadTypingWrapper';
import MessageAttachments from './MessageAttachments';
import * as ChatReducer from '../reducers/chatReducer';
import { useStorageService } from '../hooks';
import EmojiPickerDropdown from './EmojiPickerDropdown';
import { DRAFT_STORAGE_KEYS } from '../constants/AppConstants';

const ChatThreadCompose = (props) => {
  const {
    activeGroup,
    activeUser,
    currentUser,
    fileUploadPostProcessing,
    attachments,
    templateToBeAdded,
    activeUpload,
    mentionableUserIds,
    sharelinkFiles,
    sendingMessage,
  } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const [messageText, setMessageText] = useState('');
  const [showMentionPanel, setShowMentionPanel] = useState(false);
  const [draftMentions, setDraftMentions] = useState([]);
  const [emojiToAdd, setEmojiToAdd] = useState(null);

  useStorageService(draftMentions, [], DRAFT_STORAGE_KEYS.mentions, setDraftMentions);
  useStorageService(messageText, '', DRAFT_STORAGE_KEYS.default, setMessageText);
  useStorageService(attachments, [], DRAFT_STORAGE_KEYS.attachments, handleSetFiles);
  useStorageService(sharelinkFiles, [], DRAFT_STORAGE_KEYS.sharelinkFiles, handleSetFiles);
  function handleSetFiles(value, fileType) {
    dispatch(ThreadReducer.setFiles({ fileType, value }));
  }
  function handleMessageTextChange(id, value) {
    setMessageText(value);
    if ((messageText.lastIndexOf('@') > value.lastIndexOf('@')) && !value.includes('{groupId') && !value.includes('{userId')) {
      setShowMentionPanel(false);
    }
  }

  function handleUpdateMentions(variable) {
    setDraftMentions((current) => (
      current.some((mention) => mention.id === variable.id && variable.type === mention.type)
        ? current
        : [...current, variable]
    ));
  }

  const handleEmojiClick = (emojiObject) => setEmojiToAdd(emojiObject);

  const clearEmoji = () => setEmojiToAdd(null);

  useEffect(() => {
    if (sendingMessage) {
      const optimisticId = DataHelpers.generateUUID(); // optimistic updates
      const { mentionPayload, message } = StringHelpers.processMentionsInMessage(messageText, draftMentions);
      const payload = {
        optimisticId,
        attachments,
        text: message,
        pageNo: 0,
        ...mentionPayload,
        ...params.groupId && {
          groupId: Number(params.groupId),
        },
        ...params.userId && {
          userId: Number(params.userId),
        },
        sender: currentUser.id,
      };

      // if sharelinks need to be sent, concat with the messageText
      if (sharelinkFiles.length > 0) {
        sharelinkFiles.forEach((file) => {
          payload.text += `${payload.text.length === 0 ? '' : '\n'}${file.sharelink}`;
        });
      }
      const tempEvent = { ...payload, eventMentions: draftMentions };
      dispatch(ChatReducer.createTempChatEvent(tempEvent));
      dispatch(ChatReducer.createChatEvent(payload)).then(() => {
        setMessageText('');
        setDraftMentions([]);
      });
    }
  }, [sendingMessage]);

  const handleSendMessage = () => {
    dispatch(ThreadReducer.setSendingMessageLoading());
  };

  useEffect(() => {
    if (templateToBeAdded) {
      dispatch(ThreadReducer.setTemplateToBeAdded(''));
    }
  }, [templateToBeAdded]);

  function handleMessageInputHeightChange() {
    dispatch(ThreadReducer.setMessageInputHeightChange(true));
  }

  const getPlaceholderName = () => {
    if (activeGroup) {
      return activeGroup.isDynamic ? 'Everyone' : activeGroup.name;
    } if (activeUser) {
      return UserHelpers.formatName(activeUser, true);
    }
    return '';
  };

  const renderRestrictedMessage = () => (
    <Alert title="Messaging Restricted" className="convo__footer__alert">
      Contact your organization administrator to get the necessary permissions to send messages.
    </Alert>
  );

  const renderDeactivatedUserInfo = () => (
    <div className="convo__footer">
      <Alert title="This member is deactivated" className="convo__footer__alert">An administrator can reactivate this account at any time.</Alert>
    </div>
  );

  if (activeUser && !activeUser.active) {
    return renderDeactivatedUserInfo();
  } else if (!UserHelpers.userHasAnyOfPermissions([TEAM_CONVERSATION_EDIT])) {
    return renderRestrictedMessage();
  } else if (activeGroup || activeUser?.id !== currentUser.id) {
    // Prevent user from manually navigating to /chat/user/:currentUser.id and attempt to chat with themself
    const isFileUploadPostProcessing = attachments.some((attachment) => fileUploadPostProcessing.includes(attachment.key));
    const disableSend = currentUser.isCcr || activeUpload || isFileUploadPostProcessing || (!messageText && !attachments.length);

    return (
      <>
        <div className="convo__footer">
          <ThreadTypingWrapper messageText={messageText} threadType="chat" />
          <div className="convo__message__container convo__message__container--no-nav convo__message__container--mentions">
            <div
              className="convo__message"
            >
              <MentionComposeArea
                mentionUserIds={mentionableUserIds}
                onInput={handleMessageTextChange}
                sendingMessage={sendingMessage}
                showMentionPanel={showMentionPanel}
                templateToBeAdded={templateToBeAdded}
                toggleMentionPanel={setShowMentionPanel}
                placeholder={`Message ${getPlaceholderName()}`}
                handleFocus={handleMessageInputHeightChange}
                handleUpdateMentions={handleUpdateMentions}
                draftMentions={draftMentions}
                emoji={emojiToAdd}
                clearEmoji={clearEmoji}
              />
            </div>
            <Button
              reset
              className="convo__message__send"
              onClick={handleSendMessage}
              loading={sendingMessage}
              disabled={disableSend}
              title="Send message"
              data-cypress="sendChatMessageBtn"
            >
              <Icon icon="send" />
            </Button>
          </div>
          <MessageAttachments />
        </div>
        <div className="convo__message__add-actions--chat">
          <EmojiPickerDropdown
            wrapperClassName="emoji-picker__dropdown__wrapper--convo-thread u-m-l-small u-m-r-small"
            dropdownPosition="top"
            onEmojiSelect={handleEmojiClick}
          />
          <MessageComposeActions />
        </div>
      </>
    );
  }

  return null;
};

ChatThreadCompose.propTypes = {
  activeGroup: PropTypes.object,
  activeUser: PropTypes.object,
  activeUpload: PropTypes.bool,
  attachments: PropTypes.array,
  currentUser: PropTypes.object,
  fileUploadPostProcessing: PropTypes.array.isRequired,
  templateToBeAdded: PropTypes.string,
  mentionableUserIds: PropTypes.array,
  sharelinkFiles: PropTypes.array,
  sendingMessage: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {
    thread,
    ui,
  } = state;
  return {
    activeGroup: getActiveChatGroup(state),
    activeUpload: thread.activeUpload,
    activeUser: getActiveUser(state),
    attachments: thread.attachments,
    currentUser: getLoggedInUser(state),
    fileUploadPostProcessing: ui.fileUploadPostProcessing,
    templateToBeAdded: thread.templateToBeAdded,
    sharelinkFiles: thread.sharelinkFiles,
    sendingMessage: thread.sendingMessage,
  };
};

export default connect(mapStateToProps)(ChatThreadCompose);
