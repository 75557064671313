import { useParams } from 'react-router-dom';

const useInboxParams = () => {
  const { groupId, userId } = useParams();
  return {
    groupId: Number(groupId) || null,
    userId: Number(userId) || null,
  };
};

export default useInboxParams;
