import React from 'react';
import PropTypes from 'prop-types';
import { UtilityInlineGrid, Alert, Button } from 'rhinostyle';
import moment from 'moment-timezone';
import PDFViewerContainer from '../containers/PdfViewerContainer';

import { AppConstants } from '../constants';
import BillingUsageMeter from './BillingUsageMeter';
import { DateHelpers, DataHelpers } from '../helpers';

const OrganizationBillingUsage = (props) => {
  const {
    planName,
    planCode,
    nextPeriodStartDate,
    customFields,
  } = props.subscription;
  const {
    organization,
    handlePdfClick,
    pdfOpen,
    handlePdfViewerClose,
    draftInvoiceId,
    products,
  } = props;
  const standardPlanCode = process.env.REACT_APP_FUSEBILL_STANDARD_PLAN_CODE;
  const isLegacyPlan = DataHelpers.exists(customFields) && customFields.find((category) => category.key === AppConstants.PLAN_LEGACY);

  const url = `${process.env.REACT_APP_API_BASE_URL}/billing/draftInvoices/pdf/${draftInvoiceId}`;
  const filename = `${draftInvoiceId}.pdf`;
  const yesterdayDate = DateHelpers.formatTimestamp(moment().subtract(1, 'day'), 'MM/DD/YYYY');
  const nextBillDate = DateHelpers.fullTimestamp(nextPeriodStartDate, 'MM/DD/YYYY');
  const isMessageProductAvailable = products && products.find((product) => product.productCode === AppConstants.MESSAGE_PRODUCT);
  const renderProducts = (product) => {
    if (product.productCategory && product.productCategory === AppConstants.VARIABLE_QUANTITY) {
      return (
        <div key={product.productName}>
          <strong id={`billing__${product.productCode}__planCount`}>{product.limit === Infinity ? 'Unlimited' : product.limit}</strong>
          <div className="u-text-small">{product.productName}</div>
        </div>
      );
    }
    return null;
  };

  const renderBillingMeter = (product) => {
    if (product.productCategory && product.productCategory === AppConstants.VARIABLE_QUANTITY) {
      const limit = product.productCode === AppConstants.MESSAGE_PRODUCT ? product.limit : null;
      return (
        <BillingUsageMeter key={`${product.productName}_billing_meter`} usage={product.quantity} limit={limit} label={product.productName} />
      );
    }
    return null;
  };

  const renderPlanCost = () => {
    const standardProduct = products && products.find((product) => product.productCategory === AppConstants.FIXED_QUANTITY);
    return standardProduct ? standardProduct.monthlyRecurringRevenue : 0;
  };

  return (
    <div className="box">
      <div className="box__title-wrapper">
        <div className="box__title">Plan Details</div>
      </div>
      <div>
        <h4 id="billing__planName">{planName}
          <small className="u-text-small u-font-weight-normal">(${renderPlanCost()} a month)</small>
        </h4>
        <UtilityInlineGrid className="u-text-center" size="large">
          {products && products.map(renderProducts)}
          {planCode && planCode === standardPlanCode && (
            <div>
              <strong id="billing__integrationCount">{organization.integration ? 1 : 0}</strong>
              <div className="u-text-small">Integration</div>
            </div>
          )}
        </UtilityInlineGrid>
        {isLegacyPlan && (
          <div>
            <h4 className="u-m-t-large">Current Usage</h4>
            <UtilityInlineGrid size="large">
              {products && products.map(renderBillingMeter)}
            </UtilityInlineGrid>
            {isMessageProductAvailable && <Alert className="u-m-t u-text-center" type="info">Message count reflects usage as of 11:00 PM EST on {yesterdayDate} </Alert>}
            {draftInvoiceId && (
              <div>
                <UtilityInlineGrid className="u-flex u-flex-justify-between u-m-t-large">
                  <h4 className="u-m-t-small">Estimated Bill <small className="u-font-weight-normal">(next bill {nextBillDate})</small></h4>
                  <Button reset className="u-text-primary" onClick={() => handlePdfClick(true)}>View PDF</Button>
                  <PDFViewerContainer
                    open={pdfOpen}
                    attachmentUrl={url}
                    fileName={filename}
                    handlePdfViewerClose={handlePdfViewerClose}
                  />
                </UtilityInlineGrid>
                <p className="u-text-small u-text-muted">
                  Note: Charges are made on a monthly basis at the end of each billing period.&nbsp;
                  The recurring charge date is based on the &quot;next billing date&quot; set during org creation. Prices are subject to change.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

OrganizationBillingUsage.propTypes = {
  subscription: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  handlePdfClick: PropTypes.func.isRequired,
  pdfOpen: PropTypes.bool.isRequired,
  handlePdfViewerClose: PropTypes.func.isRequired,
  draftInvoiceId: PropTypes.number,
  products: PropTypes.array.isRequired,
};

export default OrganizationBillingUsage;
