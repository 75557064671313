import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SecureUserProfile from '../components/SecureUserProfile';
import * as UserReducer from '../reducers/userReducer';
import { getLoggedInUser } from '../selectors/userSelectors';
import StorageService from '../services/StorageService';

class SecureUserProfileContainer extends React.Component {
  state = {
    loginEmail: this.props.currentUser.loginEmail,
  };

  componentDidMount() {
    if (!StorageService.readEntry('patientExperience')) this.props.history.push('/inbox');
  }

  render() {
    const props = {
      loginEmail: this.state.loginEmail,
    };

    return (
      <SecureUserProfile {...props} />
    );
  }
}

SecureUserProfileContainer.propTypes = {
  currentUser: PropTypes.object.isRequired,
  error: PropTypes.object,
  updateUser: PropTypes.func.isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  const { ui, organization } = state;

  return {
    organizations: organization.organizations,
    error: ui.error,
    currentUser: getLoggedInUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUser: bindActionCreators(UserReducer.updateUser, dispatch),
    updateUserPassword: bindActionCreators(UserReducer.updateUserPassword, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecureUserProfileContainer);
