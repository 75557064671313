import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { UtilitySystem } from 'rhinostyle';

import { getLoggedInUser } from '../selectors/userSelectors';
import * as UserReducer from '../reducers/userReducer';
import * as GroupReducer from '../reducers/groupReducer';
import UserSearchModal from '../components/UserSearchModal';
import StorageService from '../services/StorageService';
import { MaskingHelpers, SearchHelpers } from '../helpers';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { Types } from '../constants';

class UserSearchModalContainer extends React.Component {
  state = {
    activeFilterParam: 'name',
    searchText: '',
    // This will store a reference to our Cleave formated input -- In order to hard reset it to '' where necessary.
    // https://github.com/nosir/cleave.js/blob/master/doc/reactjs-component-usage.md#how-to-update-raw-value
    searchTextCleave: null,
    open: false,
    searchFocus: false,
    formInProgress: false,
    page: 1,
    masterUser: {},
    inputFormat: null,
    isMultiMemberSearchEnabled: false,
    isMultiContactSearchEnabled: false,
    selectedUserIds: [...new Set([])],
    isBulkMessageModalOpen: false,
    isSaveContactListActionsVisible: false,
    isUpdateContactListActionsVisible: false,
    contactListName: '',
    selectedSavedList: null,
    contactListError: null,
    isContactListUpdateDisabled: true,
    isListPropsUpdatedFromParent: false,
    isContactListSaveInProgress: false,
    mergeContactModalHeaderTitle: 'Merge with Existing Contact',
  };

  multiUserInputRef = React.createRef();

  componentDidMount() {
    this.handleSearch = debounce(this.handleSearch, 300);
  }

  static getDerivedStateFromProps(props, state) {
    const updatedState = {
      isContactListUpdateDisabled: true,
    };

    if (state.selectedSavedList) {
      updatedState.selectedSavedList = props.contactList.find((list) => list.id === state.selectedSavedList.id);
      const contactListUsers = updatedState.selectedSavedList.users;
      const contactListUserIds = contactListUsers.map((user) => user.id);

      if (state.selectedUserIds.length !== contactListUserIds.length) {
        updatedState.isContactListUpdateDisabled = false;
      } else {
        // for same length
        contactListUserIds.forEach((id) => {
          if (!state.selectedUserIds.includes(id)) {
            updatedState.isContactListUpdateDisabled = false;
          }
        });
      }
    }

    const {
      isRenderedFromContactListPanel,
      isUserSearchModalOpen,
      isBulkMessageModalOpen,
      selectedSavedList,
    } = props;

    const isAnyModalOpen = (isUserSearchModalOpen || isBulkMessageModalOpen);

    if (isRenderedFromContactListPanel && !state.isListPropsUpdatedFromParent && Object.keys(selectedSavedList).length && isAnyModalOpen) {
      updatedState.isBulkMessageModalOpen = isBulkMessageModalOpen;
      updatedState.open = isUserSearchModalOpen;
      updatedState.selectedSavedList = selectedSavedList;
      updatedState.selectedUserIds = selectedSavedList.users ? selectedSavedList.users.map((user) => user.id) : [];
      updatedState.isMultiContactSearchEnabled = true;
      updatedState.isUpdateContactListActionsVisible = true;
      updatedState.isListPropsUpdatedFromParent = true;
    }

    return updatedState;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.open && this.state.open) {
      this.props.clearUserSearch();
    }
  }

  onChangeHandler = (name, value) => {
    this.setState({ [name]: value });
  }

  get dynamicGroupChatPayload() {
    return {
      isDynamic: true,
      userIds: [...this.state.selectedUserIds, this.props.user.id],
      typeId: Types.TYPE_GROUP_CHAT,
    };
  }

  onSearchTextCleaveInit = (cleave) => {
    this.setState({ searchTextCleave: cleave });
  }

  resetCleaveInputValue = () => {
    this.state.searchTextCleave.setRawValue('');
  }

  getScopedProps = () => {
    const { scope } = this.props;
    const modalHeaderTitleSub = 'Please acknowledge the following before confirming merge:';
    const baseProps = {
      buttonProps: {
        type: 'secondary',
        reset: false,
        iconOnly: true,
        title: 'Add New Contact',
      },
      buttonText: '',
      buttonIcon: 'add',
      scopedActionText: 'Add New Contact',
    };

    if (scope === 'global') {
      return {
        ...baseProps,
        ...SearchHelpers.filterContactParams,
        modalHeaderTitle: this.state.isMultiContactSearchEnabled ? 'Search Contacts' : 'Search Contacts and Members',
        buttonIcon: 'search',
        buttonText: 'Search users',
        buttonProps: {
          type: 'default',
          reset: true,
          className: 'app-header__dropdown__trigger',
          iconOnly: true,
          title: 'Search users',
        },
      };
    } else if (scope === 'nonMembers' || scope === 'patientsAndOthers') {
      if (this.props.mergeUsers) {
        return {
          ...baseProps,
          ...SearchHelpers.filterContactParams,
          modalHeaderTitle: this.state.mergeContactModalHeaderTitle,
          modalHeaderTitleSub,
          scopedActionText: '',
          buttonText: 'Merge Contact',
          buttonProps: {
            iconOnly: false,
            size: 'small',
            type: 'link',
            className: 'u-text-body',
            'data-cypress': 'mergeContact',
          },
          masterUser: this.state.masterUser,
          slaveUser: this.props.slaveUser,
          handleFormConfirm: this.handleAddToExisitingContact,
        };
      }
      return {
        ...baseProps,
        ...SearchHelpers.filterContactParams,
        modalHeaderTitle: 'Search Contacts',
      };
    } else if (scope === 'members') {
      return {
        ...baseProps,
        ...SearchHelpers.filterMemberParams,
        modalHeaderTitle: 'Search Members',
        scopedActionText: '',
      };
    }

    return baseProps;
  }

  setInputFormat = () => {
    const { activeFilterParam } = this.state;

    this.setState({
      inputFormat: null,
    }, () => {
      let inputFormat = null;

      if (activeFilterParam === 'dob') {
        inputFormat = UtilitySystem.dateFormat;
      } else if (activeFilterParam === 'phone') {
        inputFormat = MaskingHelpers.phone;
      }

      this.setState({
        inputFormat,
      });
    });
  }

  setStoredFilterParam = () => {
    const { scope } = this.props;
    const storedFilterParam = StorageService.readEntry(this.localStorageFilterParam);

    const paramsToSearch = (scope && scope === 'members') ? SearchHelpers.filterMemberParams : SearchHelpers.filterContactParams;

    if (storedFilterParam && paramsToSearch.filterParams.includes(storedFilterParam)) {
      this.setState({
        activeFilterParam: storedFilterParam,
      }, () => {
        this.setInputFormat();
      });
    }
  }

  localStorageFilterParam = `rhinogram-${this.props.user.id}-searchFilterType`;

  handleFilterChange = (newFilter) => {
    const { activeFilterParam: prevFilter } = this.state;

    if ((prevFilter === 'dob' || prevFilter === 'phone') && (newFilter !== 'dob' && newFilter !== 'phone')) {
      this.setState({ searchTextCleave: null });
    }

    this.setState({
      activeFilterParam: newFilter,
      searchText: '',
      searchFocus: false,
    }, () => {
      this.props.clearUserSearch();
      this.setInputFormat();

      this.setState({
        searchFocus: true,
        searchText: '',
      });

      StorageService.createEntry(this.localStorageFilterParam, newFilter);
    });
  }

  handleSearch = (id, searchValue, searchValueFormatted) => {
    const { userSearchIds, fetchUserSearch, clearUserSearch, scope, slaveUser } = this.props;
    const { activeFilterParam: searchType } = this.state;
    const searchText = (searchType === 'dob' || searchType === 'phone') ? searchValueFormatted : searchValue;
    const searchScope = this.state.isMultiContactSearchEnabled ? 'nonMembers' : scope;
    const source = 'modalSearch';
    if (searchText && searchText.length > 2) {
      fetchUserSearch({ searchValue: searchText, scope: searchScope, type: searchType, source, idsToExclude: [slaveUser?.id] });
    } else if (userSearchIds.length) {
      clearUserSearch();
    }

    this.setState({ searchText });
  };

  onScroll = () => {
    const scrollContainer = this.userSearchModalContainerRef.container.firstChild;
    const totalScroll = scrollContainer.scrollTop + scrollContainer.clientHeight;
    const { fetchUserSearch, searchSize, page, slaveUser, scope } = this.props;
    const { activeFilterParam, searchText, isMultiContactSearchEnabled } = this.state;
    const source = 'modalSearch';
    const searchScope = isMultiContactSearchEnabled ? 'nonMembers' : scope;

    if (totalScroll === scrollContainer.scrollHeight) {
      this.scrollPosition = 'bottom';

      fetchUserSearch({
        searchValue: searchText,
        scope: searchScope,
        type: activeFilterParam,
        source,
        page: page + searchSize,
        idsToExclude: [slaveUser?.id],
      });
    }

    if ((totalScroll !== scrollContainer.scrollHeight) && (scrollContainer.scrollTop !== 0)) {
      this.scrollPosition = 'middle';
    }

    if (scrollContainer.scrollTop === 0) {
      this.scrollPosition = 'top';
    }
  }

  resetToInitialState = ({
    isSaveContactListActionsVisible: false,
    contactListName: '',
    contactListError: null,
  })

  handleToggle = (name, value) => {
    let stateToUpdate = {};
    let updatedModalToggleState = !this.state[name];
    if (value === false && name === 'open') {
      updatedModalToggleState = false;
    }
    if (updatedModalToggleState === false) {
      // set to initial state
      stateToUpdate = {
        ...this.resetToInitialState,
        isUpdateContactListActionsVisible: false,
        selectedSavedList: null,
        isContactListUpdateDisabled: true,
      };
    }
    if (name === 'open' && !updatedModalToggleState && !this.state.isBulkMessageModalOpen) {
      stateToUpdate.isListPropsUpdatedFromParent = false;
      if (this.props.isRenderedFromContactListPanel) {
        this.props.handleResetInitialState();
      }
    }
    stateToUpdate[name] = updatedModalToggleState;
    this.setState(stateToUpdate);
  }

  handleOpenCloseBulkMessageModal = () => {
    this.setState({ isBulkMessageModalOpen: !this.state.isBulkMessageModalOpen }, () => {
      if (!this.state.isBulkMessageModalOpen && this.props.handleResetInitialState) this.props.handleResetInitialState();
      if (!this.state.open && !this.state.isBulkMessageModalOpen) {
        this.setState({ isListPropsUpdatedFromParent: false });
        if (this.props.isRenderedFromContactListPanel) {
          this.props.handleResetInitialState();
        }
      }
    });
  }

  removeSelectedUserFromBulkMessageModal = (user) => {
    this.handleRemoveUserFromSelectedIds(user.id);
  }

  clearSelectedContacts = () => {
    this.handleRemoveAllUsersFromSelectedIds();
  }

  handleOpenCloseSaveContactListActions = (isSaveContactListActionsVisible) => {
    let toUpdateState = {};
    if (isSaveContactListActionsVisible === false) {
      toUpdateState = { ...this.resetToInitialState };
    } else {
      toUpdateState = { isSaveContactListActionsVisible };
    }
    this.setState(toUpdateState);
  }

  handleOpenCloseUpdateContactListActions = (list) => {
    this.setState({
      isUpdateContactListActionsVisible: true,
      isMultiContactSearchEnabled: true,
      selectedSavedList: list,
      selectedUserIds: [...new Set([])],
    }, () => {
      list.users.forEach((user) => {
        this.handleSearchSelect(user.id);
      });
    });
  }

  handleSaveSelectedContactList = (shouldSendMessage = false) => {
    this.setState({
      isContactListSaveInProgress: !shouldSendMessage,
    });
    this.props.createContactList({
      name: this.state.contactListName,
      userIds: this.state.selectedUserIds,
    }).then((newContactList) => {
      if (shouldSendMessage) {
        this.handleOpenCloseBulkMessageModal();
      }
      this.setState({
        isSaveContactListActionsVisible: false,
        contactListError: null,
        isContactListSaveInProgress: false,
        contactListName: '',
      });
      this.handleOpenCloseUpdateContactListActions(newContactList);
    }).catch(() => {
      // handle the contactList Error
      if (this.props.uiError &&
        this.props.uiError.data.property === 'name' &&
        this.props.uiError.data.message) {
        this.setState({
          contactListError: this.props.uiError.data.message,
          isContactListSaveInProgress: false,
        });
      }
    });
  }

  handleUpdateContactList = (contactListId) => {
    this.props.updateContactList(contactListId, {
      name: this.state.selectedSavedList.name,
      userIds: this.state.selectedUserIds,
    });
  }

  openModal = () => {
    this.setState({ open: true });
  }

  handleCloseUserSearchModal = () => {
    this.handleToggle('open', false);
  }

  handleStart = () => {
    this.setStoredFilterParam();
  }

  handleOnComplete = () => {
    this.setState({
      searchFocus: true,
    });
  }

  handleReverseComplete = (cb = () => {}) => {
    this.setState({
      activeFilterParam: 'name',
      searchText: '',
      open: false,
      searchFocus: false,
      formInProgress: false,
      page: 1,
      selectedUserIds: [],
      isMultiMemberSearchEnabled: false,
      isMultiContactSearchEnabled: false,
      mergeContactModalHeaderTitle: 'Merge with Existing Contact',
    });

    // I was trying to find a way to put this dispatch into a lifecycle method,
    // but ran into some weird issues where the searchIds would disappear before the modal closed causing unwanted UX.
    this.props.clearUserSearch();

    cb();
  }

  handleSearchSelect = (selectedUserId) => {
    if (this.state.isMultiMemberSearchEnabled || this.state.isMultiContactSearchEnabled) {
      this.setState((prevState) => ({
        selectedUserIds: [...new Set([...prevState.selectedUserIds, selectedUserId])],
        searchText: '',
        searchFocus: true,
      }));

      this.multiUserInputRef.current.input.focus();

      if (this.state.searchTextCleave) this.resetCleaveInputValue();
      this.props.clearUserSearch();
    } else if (this.props.mergeUsers) {
      if (this.state.page === 1) {
        this.setState({
          page: 2,
          masterUser: this.props.users[selectedUserId],
          mergeContactModalHeaderTitle: 'Confirm Merge',
        });
      }
    } else {
      // Need to be able to close out the modal as well as call the handleSearchSelect prop.
      // Doing it in an locally scoped function allows us to handle the opening and closing of the modal from within this container and not outside of it's scope.
      this.handleReverseComplete();

      this.props.handleSearchSelect(selectedUserId);
    }
  }

  handleAddToExisitingContact = (slaveUserId, masterUserId) => {
    this.setState({ formInProgress: true });

    this.props.handleFormConfirm(slaveUserId, masterUserId)
      .then(() => {
        this.props.clearUserSearch();
        // Master user may not have access to current group, so navigate to 'all' inbox
        this.props.history.push(`/inbox/all/user/${masterUserId}`);
      });
  }

  handleScopedAction = () => {
    const { searchText, activeFilterParam } = this.state;

    this.handleReverseComplete(() => {
      this.props.handleScopedAction(searchText, activeFilterParam);
    });
  }

  handleFormCancel = () => {
    this.setState({
      searchText: '',
      page: 1,
      mergeContactModalHeaderTitle: 'Merge with Existing Contact',
    });

    this.props.clearUserSearch();
  }

  handleToggleMultiSearch = (name, value) => {
    /* when enabling/disabling a multi-search, the scope of the search is effectively changed, so clear the input and all props/state storing values
    for previous searches */
    this.handleFormCancel();
    this.handleRemoveAllUsersFromSelectedIds();
    this.setState({ [name]: value, searchTextCleave: null });
  }

  handleRemoveUserFromSelectedIds = (removedUserId) => {
    const isOnlyOneUserSelected = this.state.selectedUserIds.length === 1;
    this.setState((prevState) => (
      { selectedUserIds: prevState.selectedUserIds.filter((selectedUserId) => selectedUserId !== parseInt(removedUserId, 10)),
        selectedSavedList: isOnlyOneUserSelected ? null : prevState.selectedSavedList,
        isUpdateContactListActionsVisible: !isOnlyOneUserSelected,
      }
    ));
  }

  handleRemoveAllUsersFromSelectedIds = () => {
    this.setState({
      selectedUserIds: [],
      selectedSavedList: null,
      isUpdateContactListActionsVisible: false,
    });
  }

  handleCreateDynamicChatGroup = () => {
    this.setState({ formInProgress: true });

    GroupReducer.getDynamicGroupByUserIds([...this.state.selectedUserIds, this.props.user.id])
      .then((response) => {
        if (!response.data) {
          this.props.createGroup(this.dynamicGroupChatPayload)
            .then((group) => {
              this.props.history.push(`/chat/group/${group.id}`);
            })
            .catch(() => {
              this.setState({ formInProgress: false });
            });
        } else {
          this.props.history.push(`/chat/group/${response.data.id}`);
        }
      });
  }

  render() {
    const props = {
      ...this.getScopedProps(),
      activeFilterParam: this.state.activeFilterParam,
      handleFormCancel: this.handleFormCancel,
      emails: this.props.emails,
      formInProgress: this.state.formInProgress,
      handleChangeUserSearchScope: this.props.handleChangeUserSearchScope,
      handleCreateDynamicChatGroup: this.handleCreateDynamicChatGroup,
      handleFilterChange: this.handleFilterChange,
      handleOnComplete: this.handleOnComplete,
      handleRemoveUserFromSelectedIds: this.handleRemoveUserFromSelectedIds,
      handleRemoveAllUsersFromSelectedIds: this.handleRemoveAllUsersFromSelectedIds,
      handleReverseComplete: this.handleReverseComplete,
      handleScopedAction: this.handleScopedAction,
      handleSearch: this.handleSearch,
      handleStart: this.handleStart,
      handleSearchSelect: this.handleSearchSelect,
      handleToggle: this.handleToggle,
      handleToggleMultiSearch: this.handleToggleMultiSearch,
      inputFormat: this.state.inputFormat,
      isMultiMemberSearchEnabled: this.state.isMultiMemberSearchEnabled,
      isMultiContactSearchEnabled: this.state.isMultiContactSearchEnabled,
      mergeUsers: this.props.mergeUsers,
      multiUserInputRef: this.multiUserInputRef,
      open: this.state.open,
      onSearchTextCleaveInit: this.onSearchTextCleaveInit,
      page: this.state.page,
      phones: this.props.phones,
      scope: this.props.scope,
      searchFocus: this.state.searchFocus,
      searchText: this.state.searchText,
      selectedUserIds: this.state.selectedUserIds,
      tags: this.props.tags,
      userSearchIds: this.props.userSearchIds,
      userSearchLoading: this.props.userSearchLoading,
      users: this.props.users,
      isBulkMessageModalOpen: this.state.isBulkMessageModalOpen,
      removeSelectedUserFromBulkMessageModal: this.removeSelectedUserFromBulkMessageModal,
      handleOpenCloseBulkMessageModal: this.handleOpenCloseBulkMessageModal,
      isBulkMessagingEnabled: this.props.currentOrganization.isBulkMessagingEnabled,
      isSaveContactListActionsVisible: this.state.isSaveContactListActionsVisible,
      onChangeHandler: this.onChangeHandler,
      contactListName: this.state.contactListName,
      handleOpenCloseSaveContactListActions: this.handleOpenCloseSaveContactListActions,
      handleSaveSelectedContactList: this.handleSaveSelectedContactList,
      contactListError: this.state.contactListError,
      contactList: this.props.contactList,
      handleOpenCloseUpdateContactListActions: this.handleOpenCloseUpdateContactListActions,
      isUpdateContactListActionsVisible: this.state.isUpdateContactListActionsVisible,
      selectedSavedList: this.state.selectedSavedList,
      isContactListUpdateDisabled: this.state.isContactListUpdateDisabled,
      handleUpdateContactList: this.handleUpdateContactList,
      clearSelectedContacts: this.clearSelectedContacts,
      handleCloseUserSearchModal: this.handleCloseUserSearchModal,
      isRenderedFromContactListPanel: this.props.isRenderedFromContactListPanel,
      openModal: this.openModal,
      isContactListSaveInProgress: this.state.isContactListSaveInProgress,
      contactListLoading: this.props.contactListLoading,
      userSearchModalContainerRef: (userSearchModalContainerRef) => (this.userSearchModalContainerRef = userSearchModalContainerRef),
      onScroll: this.onScroll,
    };
    return (
      <UserSearchModal {...props} />
    );
  }
}

UserSearchModalContainer.propTypes = {
  clearUserSearch: PropTypes.func.isRequired,
  createContactList: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  emails: PropTypes.object.isRequired,
  user: PropTypes.object,
  excludeCurrentUser: PropTypes.bool,
  fetchUserSearch: PropTypes.func.isRequired,
  handleFormConfirm: PropTypes.func,
  handleScopedAction: PropTypes.func,
  mergeUsers: PropTypes.bool,
  phones: PropTypes.object.isRequired,
  scope: PropTypes.oneOf(['global', 'nonMembers', 'members', 'patientsAndOthers']).isRequired,
  slaveUser: PropTypes.object,
  tags: PropTypes.object.isRequired,
  userSearchIds: PropTypes.array.isRequired,
  userSearchLoading: PropTypes.bool.isRequired,
  users: PropTypes.object.isRequired,
  contactList: PropTypes.array.isRequired,
  uiError: PropTypes.object,
  handleChangeUserSearchScope: PropTypes.func,
  currentOrganization: PropTypes.object,
  fetchContactList: PropTypes.func,
  updateContactList: PropTypes.func,
  handleSearchSelect: PropTypes.func,
  isRenderedFromContactListPanel: PropTypes.bool,
  isBulkMessageModalOpen: PropTypes.bool,
  isUserSearchModalOpen: PropTypes.bool,
  handleResetInitialState: PropTypes.func,
  selectedSavedList: PropTypes.object,
  contactListLoading: PropTypes.bool.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  searchSize: PropTypes.number,
  page: PropTypes.number,
};

UserSearchModalContainer.defaultProps = {
  excludeCurrentUser: false,
  handleFormConfirm: () => {},
  mergeUsers: false,
  slaveUser: {},
  isRenderedFromContactListPanel: false,
  isBulkMessageModalOpen: false,
  isUserSearchModalOpen: false,
};

const mapStateToProps = (state) => {
  const { email, user, tag, phone, ui } = state;

  return {
    emails: email.emails,
    tags: tag.tags,
    user: getLoggedInUser(state),
    users: user.users,
    uiError: ui.error,
    phones: phone.phones,
    userSearchIds: user.userSearchIds,
    userSearchLoading: user.userSearchLoading,
    currentOrganization: getCurrentOrg(state),
    contactList: user.contactList,
    contactListLoading: user.contactListLoading,
    page: user.page,
    searchSize: user.searchSize,
  };
};

const actions = {
  clearUserSearch: UserReducer.clearUserSearch,
  fetchUserSearch: UserReducer.fetchUserSearch,
  fetchUsers: UserReducer.fetchUsers,
  createGroup: GroupReducer.createGroup,
  createContactList: UserReducer.createContactList,
  updateContactList: UserReducer.updateContactList,
  fetchContactList: UserReducer.fetchContactList,
  // updateExistingContactList: UserReducer.updateExistingContactList,
};

export default connect(mapStateToProps, actions)(UserSearchModalContainer);
