import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  RadioGroup,
  Radio,
  Select,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UtilityInlineGrid,
  UtilityList,
  UtilityListItem,
} from 'rhinostyle';

import { fetchMonths, fetchYearsForExpiration } from '../helpers/BirthdayHelpers';
import { MaskingHelpers } from '../helpers';
import { AppConstants } from '../constants';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { BILLING_EDIT } from '../constants/UserPermissionsConstants';
import Recaptcha from '../widget/components/Recaptcha';

const OrganizationBillingPayment = (props) => {
  const {
    payment,
    paymentModalOpen,
    errors,
    formInProgress,
    handleChange,
    handleOnReverseComplete,
    handleToggle,
    handleSubmit,
    selectedPaymentMethod,
    firstName,
    lastName,
    bankAccountTypes,
    activeBankAccountTypeId,
    bankRoutingNumber,
    bankName,
    bankAccountNumber,
    creditCardNumber,
    creditCardExpMonth,
    creditCardExpYear,
    creditCardVerificationValue,
    creditCardZip,
    handleCleaveInit,
    userIsCcr,
    isValidCaptcha,
    errorMessage,
  } = props;

  const validationMessages = {
    firstName: errors.firstName || errors['p.firstName'],
    lastName: errors.lastName || errors['p.lastName'],
    creditCardNumber: errors.creditCardNumber || errors['p.ccNumber'],
    creditCardVerificationValue: errors.creditCardVerificationValue || errors['p.creditCardVerificationValue'],
    creditCardExpYear: errors.creditCardExpYear || errors['p.ccExpYear'],
    creditCardExpMonth: errors.creditCardExpMonth || errors['p.ccExpMonth'],
    bankName: errors.bankName || errors['p.bankName'],
    bankAccountNumber: errors.bankAccountNumber || errors['p.bankAccNum'],
    bankRoutingNumber: errors.bankRoutingNumber || errors['p.bankRouteNum'],
    creditCardZip: errors.creditCardZip || errors['p.creditCardZip'],
  };

  const renderBankName = () => (
    <UtilityListItem>
      Bank Name: {payment.bankName}
    </UtilityListItem>
  );

  const renderPaymentInfo = () => {
    if (payment && payment.bankAccNum) {
      return (
        <UtilityList space>
          <UtilityListItem className="u-font-weight-bold">
            <span id="billing__paymentMethod__name">{firstName} {lastName}</span>
          </UtilityListItem>
          {payment.bankName ? renderBankName() : ''}
          <UtilityListItem>Routing Number: {payment.bankRouteNum}</UtilityListItem>
          <UtilityListItem>Account Number: {payment.bankAccNum}</UtilityListItem>
        </UtilityList>
      );
    }
    if (payment && payment.ccNumber) {
      return (
        <UtilityList space>
          <UtilityListItem className="u-font-weight-bold">
            <span id="billing__paymentMethod__name">{firstName} {lastName}</span>
          </UtilityListItem>
          <UtilityListItem>Credit Card Number: {payment.ccNumber}</UtilityListItem>
          <UtilityListItem>Expiration Date: {payment.ccExpMonth}/{payment.ccExpYear}</UtilityListItem>
        </UtilityList>
      );
    }
    return null;
  };

  const monthOpts = fetchMonths();
  monthOpts.unshift({ value: '--', id: -1 });

  const yearOpts = fetchYearsForExpiration();
  yearOpts.unshift({ value: '--', id: -1 });

  const renderBank = () => (
    <div className="form__group">
      <div className="row">
        <div className="column-4@small">
          <Input
            onChange={handleChange}
            validationMessage={validationMessages.bankName}
            name="bankName"
            label="Bank Name"
            initialValue={bankName}
            required
          />
        </div>
        <div className="column-4@small">
          <Input
            onChange={handleChange}
            name="bankAccountNumber"
            label="Bank Account Number"
            initialValue={bankAccountNumber}
            validationMessage={validationMessages.bankAccountNumber}
            required
          />
        </div>
        <div className="column-4@small">
          <Input
            onChange={handleChange}
            name="bankRoutingNumber"
            label="Routing Number"
            initialValue={bankRoutingNumber}
            validationMessage={validationMessages.bankRoutingNumber}
            required
          />
        </div>
        <div className="column-4@small">
          <Select
            onSelect={handleChange}
            label="Bank Account Type"
            name="activeBankAccountTypeId"
            options={bankAccountTypes}
            selected={activeBankAccountTypeId}
          />
        </div>
      </div>
    </div>
  );

  const renderCredit = () => (
    <div className="form__group">
      <Input
        format={MaskingHelpers.creditCard}
        onChange={handleChange}
        name="creditCardNumber"
        label="Card Number"
        type="text"
        onInit={(cleave) => handleCleaveInit('cleaveCreditCardNumber', cleave)}
        initialValue={creditCardNumber}
        validationMessage={validationMessages.creditCardNumber}
        required
      />
      <div className="form__group">
        <div className="row">
          <div className="column-4@small">
            <Select
              onSelect={handleChange}
              label="Expiration Month"
              options={monthOpts}
              name="creditCardExpMonth"
              selected={creditCardExpMonth}
              validationMessage={validationMessages.creditCardExpMonth}
              required
            />
          </div>
          <div className="column-4@small">
            <Select
              onSelect={handleChange}
              label="Expiration Year"
              options={yearOpts}
              name="creditCardExpYear"
              selected={creditCardExpYear}
              required
              validationMessage={validationMessages.creditCardExpYear}
            />
          </div>
          <div className="column-4@small">
            <Input
              onChange={handleChange}
              name="creditCardVerificationValue"
              label="CVV"
              initialValue={creditCardVerificationValue}
              validationMessage={validationMessages.creditCardVerificationValue}
              required
            />
          </div>
          <div className="column-4@small">
            <Input
              format={MaskingHelpers.zipCode}
              onChange={(creditCardZipName, rawValue, value) => handleChange(creditCardZipName, value)}
              name="creditCardZip"
              label="Zip"
              initialValue={creditCardZip}
              onInit={(cleave) => handleCleaveInit('cleaveCreditCardZip', cleave)}
              validationMessage={validationMessages.creditCardZip}
              required
            />
          </div>
        </div>
      </div>
      <div className="u-m-t-large">
        <Recaptcha
          id="recaptcha"
          className="g-recaptcha"
          data-sitekey={AppConstants.FUSEBILL_RECAPTCHA_SITE_KEY}
          validationMessage={!isValidCaptcha ? errorMessage : (errors.reCaptcha)}
        />
      </div>
    </div>
  );

  const paymentModal = () => (
    <Modal onReverseComplete={handleOnReverseComplete} open={paymentModalOpen}>
      <ModalHeader
        onClose={() => handleToggle('paymentModalOpen')}
        title="Billing Payment Method"
      />
      <ModalBody>
        <RadioGroup inline onChange={(value) => handleChange('selectedPaymentMethod', value)} selectedValue={selectedPaymentMethod} name="paymentMethod">
          <Radio value={AppConstants.PAYMENT_METHODS.CREDIT_CARD} label="Credit Card" />
          <Radio value={AppConstants.PAYMENT_METHODS.BANK} label="Bank Account" />
        </RadioGroup>
        <div className="form__group">
          <div className="row">
            <div className="column-6@small">
              <Input
                initialValue={firstName}
                onChange={handleChange}
                validationMessage={validationMessages.firstName}
                name="firstName"
                label="First Name"
                required
              />
            </div>
            <div className="column-6@small">
              <Input
                initialValue={lastName}
                onChange={handleChange}
                validationMessage={validationMessages.lastName}
                name="lastName"
                label="Last Name"
                required
              />
            </div>
          </div>
        </div>
        {selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD ? renderCredit() : renderBank()}
      </ModalBody>
      <ModalFooter>
        <UtilityInlineGrid align="between">
          <Button title="Cancel updating billing payment method" onClick={() => handleToggle('paymentModalOpen')}>
            Cancel
          </Button>
          <Button data-cypress="savePaymentMethod" loading={formInProgress} onClick={handleSubmit} type="primary">Save Payment Method</Button>
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );

  return (
    <div id="billing__paymentMethod" className="box">
      <div className="box__title-wrapper">
        <div className="box__title">Payment Method</div>
      </div>
      {payment ?
        renderPaymentInfo() :
        <p>No payment method has been added for this organization.</p>}
      {!userIsCcr && userHasAnyOfPermissions([BILLING_EDIT]) && (
        <Button
          reset
          className="u-text-primary"
          data-cypress={payment ? 'changePaymentMethod' : 'addPaymentMethod'}
          onClick={() => handleToggle('paymentModalOpen')}
        >
            {payment ? 'Change' : 'Add'} Payment Method
        </Button>
      )}
      {paymentModal()}
    </div>
  );
};

OrganizationBillingPayment.propTypes = {
  payment: PropTypes.object,
  errors: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleCleaveInit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleOnReverseComplete: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  selectedPaymentMethod: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  bankAccountTypes: PropTypes.array,
  activeBankAccountTypeId: PropTypes.number,
  paymentModalOpen: PropTypes.bool.isRequired,
  bankRoutingNumber: PropTypes.string,
  bankName: PropTypes.string,
  bankAccountNumber: PropTypes.string,
  creditCardNumber: PropTypes.string,
  creditCardExpMonth: PropTypes.number,
  creditCardExpYear: PropTypes.number,
  creditCardVerificationValue: PropTypes.string,
  creditCardZip: PropTypes.string,
  userIsCcr: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isValidCaptcha: PropTypes.bool.isRequired,
};

export default OrganizationBillingPayment;
