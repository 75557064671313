import threadService from '../services/threadService';
import { useThreadOptions } from '.';

const useLastEvent = () => {
  const threadOptions = useThreadOptions();
  const lastEvent = threadService.endpoints.getThread.useQueryState(threadOptions, {
    selectFromResult: (result) => {
      let mostRecentEvent;
      if (result?.data) {
        mostRecentEvent = result.data.entities[result.data?.ids.slice(-1)[0]];
      }
      return { mostRecentEvent };
    },
  });
  return lastEvent;
};

export default useLastEvent;
