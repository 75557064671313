// Libraries
import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import cx from 'classnames';
import {
  Select,
  Alert,
  Button,
} from 'rhinostyle';
import { useHistory } from 'react-router-dom';

// Components
import unsavedChanges from './UnsavedChangesHOC';

// Helpers
import { formatAddress } from '../helpers/DataHelpers';
import { formatTimeZone } from '../helpers/DateHelpers';

// Selectors
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { getAutomatedMessageChannelIds } from '../selectors/channelSelectors';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { ValidationHelpers } from '../helpers';
import { fetchOrganizationPreferencesView, updateOrganizationFromPreferences } from '../reducers/organizationReducer';
import PageLoader from './PageLoader';

const AppointmentCampaignChannelForm = (props) => {
  // Declarations
  const {
    handleFormChanges,
    currentOrganization,
    channels,
    channelIds,
    resetFormChanges,
    timeZones,
    formInProgress,
  } = props;
  const dispatch = useDispatch();
  const pageContainerRef = useRef(null);
  const history = useHistory();

  // State
  const [areOfficesVisible, setAreOfficesVisible] = useState(false);
  const [offices, setOffices] = useState({});
  const [selectedChannel, setSelectedChannel] = useState();
  const [timeZone, setTimeZone] = useState();
  const [errors, setErrors] = useState({});

  // Effects
  useEffect(() => {
    dispatch(fetchOrganizationPreferencesView());
  }, [dispatch]);

  useEffect(() => {
    if (currentOrganization.defaultApptReminderChannelId) history.push('/settings/organization/appointment-reminders/create');
  }, [currentOrganization.defaultApptReminderChannelId]);

  useEffect(() => {
    setAreOfficesVisible(currentOrganization.offices?.length > 0);
    if (currentOrganization.offices?.length > 0) {
      const orgOffices = currentOrganization.offices?.reduce((acc, office) => {
        acc[office.id] = (channelIds.includes(office.channelId)) ? office.channelId : -1;
        return acc;
      }, {});

      setOffices(orgOffices);
    }
  }, [currentOrganization.offices]);

  // Handlers
  const handleOfficeChannelSelect = (id, value) => {
    const orgOffices = { ...offices };
    orgOffices[id] = value;
    setOffices(orgOffices);
  };

  const handleChange = (name, value) => {
    const channelTimeZone = getTimeZone(value);
    setSelectedChannel(value);
    setTimeZone(channelTimeZone);
    handleFormChanges();
  };

  const handleSubmit = () => {
    const validationErrors = ValidationService(ValidationShapers.shapeAppointmentCampaignDefaultChannel(getPayload()));
    const errorCount = Object.keys(validationErrors).length;
    if (errorCount > 0) {
      setErrors(validationErrors);
      ValidationHelpers.handleValidationErrors(validationErrors, pageContainerRef.current);
    } else {
      const payload = getPayload();
      dispatch(updateOrganizationFromPreferences(payload));
      setErrors({});
      resetFormChanges();
    }
  };

  // Functions
  function channelList() {
    const channelsList = channelIds.map((c) => ({ id: channels[c].id, value: channels[c].name }));
    channelsList.unshift({ id: -1, value: ' --' });
    return channelsList;
  }

  function getTimeZone(channelId) {
    return (channelId && channelIds.includes(channelId)
      ? formatTimeZone(timeZones[channels[channelId].timeZoneId])
      : '');
  }

  function getPayload() {
    const orgOffices = Object.keys(offices).reduce((acc, currVal) => {
      const channelId = offices[currVal] > 0 ? offices[currVal] : null;
      const office = { id: Number(currVal), channelId };
      return acc.concat(office);
    }, []);

    return {
      defaultApptReminderChannelId: selectedChannel,
      offices: orgOffices,
    };
  }

  // Renderers
  const renderOffice = (office, idx) => (
    <div className="am__offices__row" key={idx}>
      <div className="am__offices__office">
        <div className="am__offices__office__name">{office.name}</div>
        <p className="am__offices__office__address">{formatAddress(office.address)}</p>
      </div>
      <div className="am__offices__channel">
        <Select
          onSelect={handleOfficeChannelSelect}
          options={channelList()}
          selected={offices[office.id]}
          name={`${office.id}`} // needs to be string for Rhinostyle Select
          className="automated-messages__table__channel-select"
        />
      </div>
    </div>
  );

  // Component
  const defaultChannelClass = cx({
    am__default: areOfficesVisible,
  });

  if (currentOrganization.loading) {
    return <PageLoader />;
  }

  return (
    <div className="app-page__container" ref={pageContainerRef}>
      <div className="app-page__container__inner">
        <div className="app-page__header u-flex-direction-column">
          <div className="app-page__header__title appointment-reminder-form__title">Appointment Reminder Channels</div>
          <Alert type="info" className="u-m-y u-flex-align-self-center u-p-x-large">These settings can be updated later in your Organization Preferences.</Alert>
        </div>
        <form onChange={handleFormChanges}>
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">Outgoing Channel {!areOfficesVisible && <span className="form__asterisk">*</span>}</div>
              <div className="box__subtitle">
                {areOfficesVisible ?
                  'Select a channel for each office so that appointment reminders are sent from the appropriate phone number.' :
                  'Choose the channel from which all automated messages will be sent.'}
              </div>
            </div>
            {areOfficesVisible && (
            <div className="am__offices">
              <div className="am__offices__header">
                <div className="am__offices__office">Offices</div>
                <div className="am__offices__channel">Channel</div>
              </div>
              <div className="am__offices__body">
                {currentOrganization.offices && currentOrganization.offices.map(renderOffice)}
              </div>
            </div>
            )}
            <div className={defaultChannelClass}>
              {areOfficesVisible && (
              <>
                <label // eslint-disable-line jsx-a11y/label-has-for
                  htmlFor={null}
                >
                  Default Channel <span className="form__asterisk">*</span>
                </label>
                <p className="u-text-small u-m-t-0">
                  Select a default appointment reminder channel.&nbsp;
                  This channel will be used when an office isn’t available in the appointment reminder and/or when an office isn’t associated with a channel.
                </p>
              </>
              )}
              <div className="row">
                <div className="column-5@small">
                  <Select
                    onSelect={handleChange}
                    options={channelList()}
                    selected={selectedChannel}
                    validationMessage={errors.selectedChannel}
                    name="selectedChannel"
                    dataCypress="selectReminderChannel"
                  />
                </div>
                <div className="column-7@small">
                  <div className="am__validation-bump">
                    {timeZone}
                  </div>
                </div>
              </div>
              <div className="u-m-t-large u-text-right">
                <Button
                  loading={formInProgress}
                  onClick={handleSubmit}
                  type="primary"
                  data-cypress="updatePreference"
                >
                  Save and Continue
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

AppointmentCampaignChannelForm.propTypes = {
  handleFormChanges: PropTypes.func.isRequired,
  resetFormChanges: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  channelIds: PropTypes.array.isRequired,
  channels: PropTypes.object.isRequired,
  timeZones: PropTypes.array.isRequired,
  formInProgress: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { channel, timeZone, ui } = state;
  const currentOrganization = getCurrentOrg(state);

  return {
    channelIds: getAutomatedMessageChannelIds(state),
    channels: channel.channels,
    currentOrganization,
    formInProgress: ui.formInProgress,
    timeZones: timeZone.timeZones,
  };
};

export default connect(mapStateToProps)(unsavedChanges(AppointmentCampaignChannelForm));
