import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import {
  Button,
  Icon,
  Scrollbars,
  ResourceGroup,
  Resource,
  ResourceIntro,
  ResourceBody,
  ResourceRight,
} from 'rhinostyle';
import OrganizationChannelsPanel from './OrganizationChannelsPanel';
import EmptyMessage from './EmptyMessage';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { CHANNEL_SMS_CREATE, CHANNEL_SECURE_CREATE, CHANNEL_RHINOGRAM_CREATE, CHANNEL_FACEBOOK_CREATE } from '../constants/UserPermissionsConstants';

const OrganizationChannels = (props) => {
  const history = useHistory();
  const {
    activeChannelId,
    channelIds,
    channels,
    formInProgress,
    handleTogglePanel,
    handleEditClick,
    panelOpen,
    userIsCcr,
  } = props;

  const hasChannels = channelIds.length > 0;

  const renderTag = (tag, index) => (
    <span key={index}>
      {!!index && ', '}
      #{tag.name}
    </span>
  );

  const renderChannel = (channelId) => {
    const channel = channels[channelId];
    const isActive = activeChannelId === channelId;

    return (
      <Resource active={isActive} key={channelId} onClick={() => handleTogglePanel(channelId)} data-cypress={channel.name}>
        <ResourceIntro
          icon={{ icon: channel.icon, bump: 'down' }}
          title={channel.name}
          titleSub={channel.phone}
        >
          {channel.isDefaultSecureNotificationsChannel && <span className="u-text-secondary">Default secure notifications</span>}
        </ResourceIntro>
        {channel.tags.length > 0 && (
          <ResourceBody className="u-text-muted">
            {channel.tags.map(renderTag)}
          </ResourceBody>
        )}
        <ResourceRight>{channel.route}</ResourceRight>
      </Resource>
    );
  };

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': panelOpen,
  });

  return (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="app-page__header">
              <div className="app-page__header__title">Channels</div>
              {userHasAnyOfPermissions([CHANNEL_SMS_CREATE, CHANNEL_FACEBOOK_CREATE, CHANNEL_RHINOGRAM_CREATE, CHANNEL_SECURE_CREATE]) && (
                <div className="app-page__header__action">
                  <Button
                    type="secondary"
                    onClick={() => history.push('/settings/organization/channels/create')}
                    iconOnly
                    title="Create Channel"
                  >
                    <Icon icon="add" />
                  </Button>
                </div>
              )}
            </div>
            <div className="list-panel__body" data-cypress="channels">
              {hasChannels ? (
                <Scrollbars className="list-panel__body__scroll">
                  <ResourceGroup>{channelIds.map(renderChannel)}</ResourceGroup>
                </Scrollbars>
              ) :
                <EmptyMessage section="Channels" />}
            </div>
          </div>
        </div>
        { panelOpen
          && channels[activeChannelId]
          && (
            <OrganizationChannelsPanel
              channel={channels[activeChannelId]}
              formInProgress={formInProgress}
              handleTogglePanel={handleTogglePanel}
              handleEditClick={handleEditClick}
              userIsCcr={userIsCcr}
            />
          )}
      </div>
    </div>
  );
};

OrganizationChannels.propTypes = {
  activeChannelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  channelIds: PropTypes.array.isRequired,
  channels: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  panelOpen: PropTypes.bool.isRequired,
  userIsCcr: PropTypes.bool.isRequired,
};

export default OrganizationChannels;
