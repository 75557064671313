import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Bucket,
  BucketBody,
  UtilityListItem,
  Button,
} from 'rhinostyle';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import * as DateHelpers from '../helpers/DateHelpers';
import * as UserHelpers from '../helpers/UserHelpers';
import { AppConstants } from '../constants';

const SavedContentItemDetails = (props) => {
  const { savedContentItemId } = props;

  const savedContentItem = useSelector((state) => state.savedContent.savedContentItems[savedContentItemId]);
  const creator = useSelector((state) => state.user.users[savedContentItem.creator]);
  const isCopiedContentLabelVisible = savedContentItem?.lastCopyContentEvent?.from === 1;
  const lastCopiedByUser = savedContentItem?.lastCopyContentEvent?.createdByUser;
  const lastCopiedByUserOnTime = savedContentItem?.lastCopyContentEvent?.createdAt;
  const savedContentItemCreator = isCopiedContentLabelVisible ? lastCopiedByUser : creator;
  const actionTime = isCopiedContentLabelVisible ? lastCopiedByUserOnTime : savedContentItem.createdAt;
  const isNoteAboveTruncateLength = savedContentItem?.note?.length > AppConstants.NOTE_TRUNCATE_LENGTH;

  const getNote = () => {
    const { note } = savedContentItem;

    let html = note.replace(/(\n|\r)+/g, '<br />');
    if (isNoteAboveTruncateLength) {
      const viewMoreText = '...View More';
      html = `${html.substr(0, AppConstants.NOTE_TRUNCATE_LENGTH - viewMoreText.length)}... `;
    } else {
      html = `${html} `;
    }

    return html;
  };

  return (
    <UtilityListItem className="contact-library__saved-content__item-details__wrapper">
      <Bucket>
        <BucketBody>
          <Link to={`/saved-content/${savedContentItem.id}`}>
            <div className="contact-library__saved-content__item-details__title">{savedContentItem.title}</div>
            {savedContentItem.note && (
              <div className="contact-library__saved-content__item-details__note">
                {isNoteAboveTruncateLength ? (
                  <>
                    {ReactHtmlParser(getNote())}
                    <Button className="u-text-primary" reset>
                      View More
                    </Button>
                  </>
                ) :
                  ReactHtmlParser(getNote())}
              </div>
            )}
            <div className="contact-library__saved-content__item-details__timestamp">
              {isCopiedContentLabelVisible ? 'Copied' : 'Saved'}&nbsp;
              {DateHelpers.formatTimestamp(actionTime)} by&nbsp;
              {UserHelpers.formatName(savedContentItemCreator)}
            </div>
          </Link>
        </BucketBody>
      </Bucket>
    </UtilityListItem>
  );
};

SavedContentItemDetails.propTypes = {
  savedContentItemId: PropTypes.number.isRequired,
};

export default SavedContentItemDetails;
