import { isAndroid } from './BrowserHelpers';

// eslint-disable-next-line import/prefer-default-export
export function handleDownloadClick(attachmentUrl, fileName, isNativeApp, isLightbox) {
  const response = !isLightbox ? window.confirm( // eslint-disable-line no-alert
    'While this file is encrypted during transmission, once downloaded, you are responsible for implementing appropriate safeguards to protect the data, including encryption or deletion to the extent required by applicable law.', // eslint-disable-line max-len
  ) : true;

  if (response === true) {
    const link = document.createElement('a');

    link.setAttribute('href', attachmentUrl);
    const isAndroidApp = isNativeApp && isAndroid();
    if (!isAndroidApp) {
      link.setAttribute('target', '_blank');
    }
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
