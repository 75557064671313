import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  DateRangeDropdown,
} from 'rhinostyle';

import { APPOINTMENT_STATUS_CLASS_ID } from '../constants/AppConstants';
import AppointmentManagerMultiSelect from './AppointmentManagerMultiSelect';
import {
  UPCOMING_APPOINTMENT_OPTIONS,
  PAST_APPOINTMENT_OPTIONS,
} from '../constants/AppointmentManagerConstants';
import { calculatePastDateRange, calculateFutureDateRange } from '../helpers/AppointmentHelpers';

const AppointmentManagerFilters = (props) => {
  const {
    appointmentIntegrationStatus,
    appointmentIntegrationStatusIds,
    appointmentStatusTypeIds,
    appointmentStatusTypes,
    appointmentTypeIds,
    appointmentTypes,
    disabled,
    filters,
    handleFilterChange,
    officeIds,
    offices,
    providerIds,
    providers,
  } = props;
  const pastMaxDate = moment().subtract(1, 'year').subtract(1, 'days').startOf('day');
  const upcomingMaxDate = moment().add(1, 'year').subtract(1, 'days');
  const now = moment();

  async function selectDate(date) {
    let { startDate, endDate } = date;
    if (date.activeKey === 5) {
      startDate = moment(startDate).startOf('day');
      endDate = moment(endDate).endOf('day');
    }
    handleFilterChange({ activeKey: date.activeKey, startDate, endDate });
  }

  const renderDateView = () => (
    <div className="filter-page__dropdown" data-cypress="datePicker">
      {filters.activeTab === 1 ? (
        <DateRangeDropdown
          position="left"
          name="Appointment Date Picker"
          startDate={filters.startDate}
          selectDate={selectDate}
          selectDateRange={calculateFutureDateRange}
          dropdownMenuItems={UPCOMING_APPOINTMENT_OPTIONS}
          dateLabelFormat="MMM D, YYYY"
          endDate={filters.endDate}
          minDate={now}
          maxDate={upcomingMaxDate}
          activeKey={filters.activeKey}
          isCustomDate
          disabled={disabled}
          key="upcoming"
        />
      ) : (
        <DateRangeDropdown
          position="left"
          name="Appointment Date Picker"
          startDate={moment(filters.startDate)}
          selectDate={selectDate}
          selectDateRange={calculatePastDateRange}
          dateLabelFormat="MMM D, YYYY"
          dropdownMenuItems={PAST_APPOINTMENT_OPTIONS}
          endDate={moment(filters.endDate)}
          minDate={pastMaxDate}
          maxDate={now}
          activeKey={filters.activeKey}
          isCustomDate
          disabled={disabled}
          key="past"
        />
      )}
    </div>
  );

  return (
    <>
      {renderDateView()}
      {officeIds?.length > 0 && (
        <AppointmentManagerMultiSelect
          className="appointment-locations__dropdown"
          disabled={disabled}
          filterName="Locations"
          itemIds={officeIds}
          items={offices}
          label="Location"
          name="officeIds"
          selectedIds={filters.officeIds}
          title="Location"
          updateFilters={handleFilterChange}
        />
      )}
      {appointmentTypeIds?.length > 0 && (
        <AppointmentManagerMultiSelect
          className="appointment-types__dropdown"
          disabled={disabled}
          filterName="Locations"
          itemIds={appointmentTypeIds}
          items={appointmentTypes}
          label="Type"
          name="appointmentTypeIds"
          selectedIds={filters.appointmentTypeIds}
          title="Type"
          titleField="alias"
          updateFilters={handleFilterChange}
        />
      )}
      {providerIds?.length > 0 && (
        <AppointmentManagerMultiSelect
          className="appointment-providerIds__dropdown"
          disabled={disabled}
          filterName="Providers"
          itemIds={providerIds}
          items={providers}
          label="Provider"
          name="providerIds"
          selectedIds={filters.providerIds}
          title="Provider"
          titleField="alias"
          updateFilters={handleFilterChange}
        />
      )}
      {appointmentStatusTypeIds?.length > 0 && (
        <AppointmentManagerMultiSelect
          className="appointment-status__dropdown"
          disabled={disabled}
          filterName="appointmentStatusTypeIds"
          itemIds={appointmentStatusTypeIds}
          items={appointmentStatusTypes}
          label="Status"
          name="appointmentStatusTypeIds"
          selectedIds={filters.appointmentStatusTypeIds}
          title="Status"
          titleField="value"
          updateFilters={handleFilterChange}
        />
      )}
      {appointmentIntegrationStatusIds?.length > 0 && (
        <AppointmentManagerMultiSelect
          className="appointment-pmsStatus__dropdown"
          disabled={disabled}
          filterName="integrationStatusTypeIds"
          itemIds={appointmentIntegrationStatusIds}
          items={appointmentIntegrationStatus}
          label="Status"
          name="integrationStatusTypeIds"
          selectedIds={filters.integrationStatusTypeIds}
          title="PMS/EHR Status"
          titleField="name"
          updateFilters={handleFilterChange}
        />
      )}
    </>
  );
};

AppointmentManagerFilters.propTypes = {
  appointmentIntegrationStatus: PropTypes.object,
  appointmentIntegrationStatusIds: PropTypes.array,
  appointmentStatusTypeIds: PropTypes.array,
  appointmentStatusTypes: PropTypes.object,
  appointmentTypeIds: PropTypes.array,
  appointmentTypes: PropTypes.object,
  disabled: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func,
  officeIds: PropTypes.array.isRequired,
  offices: PropTypes.object.isRequired,
  providerIds: PropTypes.array,
  providers: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { appointment, type, office, appointmentTypes, provider } = state;
  const appointmentStatusTypes = {};
  const appointmentStatusTypeIds = [];
  Object.values(type.types).forEach((t) => {
    if (t.classId === APPOINTMENT_STATUS_CLASS_ID) {
      appointmentStatusTypes[t.id] = {
        ...t,
      };
      appointmentStatusTypeIds.push(t.id);
    }
  });
  return {
    appointmentIntegrationStatus: appointment.appointmentIntegrationStatus,
    appointmentIntegrationStatusIds: appointment.appointmentIntegrationStatusIds,
    appointmentStatusTypeIds,
    appointmentStatusTypes,
    appointmentTypeIds: appointmentTypes.appointmentTypeIds,
    appointmentTypes: appointmentTypes.appointmentTypes,
    officeIds: office.mappedOfficeIds,
    offices: office.mappedOffices,
    providerIds: provider.providerIds,
    providers: provider.providers,
  };
};

export default connect(mapStateToProps)(AppointmentManagerFilters);
