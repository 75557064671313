/**
 * Provides access to the current rhinovideo chat room as well as other helper
 * functions to build media constraints and device iteration.
 */

import { isMobile, isAndroid, isIos, isVP8Supported } from '../helpers/BrowserHelpers';

const VIDEO_MAX_TRACKS = 10;
const VIDEO_MAX_TRACKS_MOBILE = 5;
const MEDIA_DEVICE_AUDIO = 'audioinput';
const MEDIA_DEVICE_VIDEO = 'videoinput';
const VIDEO_CONSTRAINTS = {
  height: { min: 480, max: 720 },
  width: { min: 640, max: 1280 },
  frameRate: 24,
};
const VIDEO_CONSTRAINTS_MOBILE = {
  height: 480,
  width: 640,
  frameRate: 24,
};

let room;

export function getRoom() {
  return room;
}

export function setRoom(newRoom) {
  room = newRoom;
}

export function disconnectRoom() {
  if (room) {
    room.disconnect();
    room = null;
    if (isIos()) {
      disconnectAudio();
      localStorage.removeItem('rhinovideo_participant_sid');
    }
  } else throw new Error('No active room');
}

let audioContext;
let gainNode;

export function getAudioContext(volumeGain) {
  if (!audioContext) {
    audioContext = new (window.AudioContext || window.webkitAudioContext)();
  }
  if (!gainNode) {
    gainNode = audioContext.createGain();
    gainNode.gain.value = volumeGain;
    gainNode.connect(audioContext.destination);
  }
  return { audioContext, gainNode };
}

export async function disconnectAudio() {
  if (audioContext) {
    await audioContext.close();
    audioContext = null;
  }
}

// Enable audio by default, but don't ask for video if the user is a member
export function getConstraints(conferenceAlias, isMember) {
  return {
    audio: {
      optional: [
        { googNoiseSuppression: true },
        { googEchoCancellation: true },
        { googAutoGainControl: true },
        { googExperimentalEchoCancellation: true },
        { autoGainControl: true },
        { echoCancellation: true },
      ],
    },
    video: isVideoSourceAvailable(isMember) && !isMember && getVideoConstraints(),
    name: conferenceAlias,
    bandwidthProfile: {
      video: {
        mode: 'collaboration',
        maxTracks: isMobile() ? VIDEO_MAX_TRACKS_MOBILE : VIDEO_MAX_TRACKS,
        dominantSpeakerPriority: 'standard',
        ...isMobile() && {
          maxSubscriptionBitrate: 2500000,
        },
        ...!isMobile() && {
          renderDimensions: {
            high: { height: 1080, width: 1920 },
            standard: { height: 720, width: 1280 },
            low: { height: 176, width: 144 },
          },
        },
      },
    },
    dominantSpeaker: true,
    preferredVideoCodecs: isVP8Supported() ? [{ codec: 'VP8', simulcast: true }] : [{ codec: 'H264' }],
    networkQuality: { local: 1, remote: 1 },
  };
}

export function getVideoSources(isMember) {
  return getMediaDevicesByType(MEDIA_DEVICE_VIDEO, isMember);
}
export function getAudioSources(isMember) {
  return getMediaDevicesByType(MEDIA_DEVICE_AUDIO, isMember);
}

function getMediaDevicesByType(type, isMember) {
  // getUserMedia is required to grab the full device list from iPhone
  if (isMobile() && !isAndroid()) {
    return navigator.mediaDevices.getUserMedia({ video: !isMember, audio: true })
      .then(() => navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const devicesByType = devices.reduce((acc, device) => {
            acc[device.kind] = [...acc[device.kind] ?? [], device];
            return acc;
          }, {});
          return devicesByType[type];
        }));
  } else {
    return navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const devicesByType = devices.reduce((acc, device) => {
          acc[device.kind] = [...acc[device.kind] ?? [], device];
          return acc;
        }, {});
        return devicesByType[type];
      });
  }
}

function getVideoConstraints() {
  return isMobile() ? VIDEO_CONSTRAINTS_MOBILE : VIDEO_CONSTRAINTS;
}

function isVideoSourceAvailable(isMember) {
  return getVideoSources(isMember)
    .then((videoSources) => videoSources.length === 0 || (videoSources.length === 1 && videoSources[0].deviceId === ''));
}
