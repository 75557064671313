import React from 'react';
import { useSelector } from 'react-redux';
import { DropdownMultiSelectAdvanced } from 'rhinostyle';
import { useDropdownMultiSelect } from '../hooks';
import { getNonDynamicGroups } from '../selectors/groupSelectors';

const GroupsDropdown = ({
  disabled,
  value: selectedIds,
  handleChange,
}) => {
  const groups = useSelector((state) => state.group.groups);
  const groupIds = useSelector(getNonDynamicGroups);

  const {
    shapedItems,
    filteredIds,
    sortDirection,
    filterItems,
    handleUpdateSelectedIds,
    handleClearSelectedIds,
    handleSort,
  } = useDropdownMultiSelect({
    name: 'groups',
    items: groups,
    itemIds: groupIds,
    updateFilters: handleChange,
    titleField: 'name',
  });

  function getDropdownLabel() {
    if (selectedIds.length > 0 && groupIds?.length > 0) {
      return selectedIds.map((groupId) => `${groups[groupId]?.name || ''}`).join(', ');
    }
    return 'Groups';
  }

  return (
    <DropdownMultiSelectAdvanced
      itemSearchLoading={!groupIds.length}
      handleUpdateSelectedIds={handleUpdateSelectedIds}
      items={shapedItems}
      selectedItems={shapedItems}
      selectedItemsIds={selectedIds}
      handleClearAllSelectedItems={handleClearSelectedIds}
      itemsIds={filteredIds}
      fetchAllItems={filterItems}
      handleSelectAllItems={handleUpdateSelectedIds}
      showSelectAll
      dropdownLabel={getDropdownLabel()}
      interfaceLeft
      filterName="Groups"
      disabled={disabled}
      blockGroup={false}
      dropDownItemClass="u-p-t-small"
      dropDownClass="filter-page__dropdown filter-page__dropdown--wide"
      wide
      sortable
      dataCypress="dropdownMultiSelect-Groups"
      handleSort={handleSort}
      sortDirection={sortDirection}
    />
  );
};

export default GroupsDropdown;
