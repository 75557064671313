import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';
import { setError } from './uiReducer';
import { provider } from '../actions/NormalizrSchema';
import { getObjectKeys } from '../helpers/DataHelpers';
import NotificationService from '../services/NotificationService';
// SLICE
const providerSlice = createSlice({
  name: 'PROVIDER',
  initialState: {
    providers: {},
    providerIds: [],
    loading: false,
  },
  reducers: {
    receiveProviders: (state, action) => ({
      ...state,
      providers: {
        ...action.payload.providers,
      },
      providerIds: action.payload.providerIds,
      loading: false,
    }),
    requestData: (state) => ({
      ...state,
      loading: true,
    }),
    receiveError: (state) => ({
      ...state,
      loading: false,
    }),
  },
});

export default providerSlice.reducer;

// ACTIONS
export const { receiveProviders, requestData, receiveError } = providerSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS
export function fetchProviders(orgId) {
  return (dispatch) => {
    dispatch(requestData());
    return axios.get(`/providers/${orgId}?pageSize=500`)
      .then(((response) => {
        const normalizedProviders = normalize(response.data, [provider]);
        dispatch(receiveProviders(getProviderPayload(normalizedProviders)));
      }))
      .catch((err) => {
        dispatch(receiveError());
        dispatch(setError(err));
      });
  };
}

export function updateProviders(orgId, payload) {
  return (dispatch) =>
    axios.patch(`/providers/${orgId}`, payload)
      .then((response) => {
        NotificationService('updateProvider', response);
        fetchProviders(orgId);
      })
      .catch((error) => {
        NotificationService('updateProvider', error.response);
        dispatch(setError(error.response || error));
        console.error(error);
      });
}

function getProviderPayload(normalizedProviders) {
  return {
    providers: {
      ...normalizedProviders.entities.provider,
    },
    providerIds: getObjectKeys(normalizedProviders.entities.provider),
  };
}
