import { useSelector } from 'react-redux';
import { getLanguageById } from '../selectors/routingSelectors';
import { getEventById } from '../selectors/inboxSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import {
  TYPE_FACEBOOK,
  TYPE_SMS,
  TYPE_ORGANIZATION,
} from '../constants/Types';
import { useUser, useChannel, useActiveUser } from '.';
import { formatTimestamp } from '../helpers/DateHelpers';
import { formatName } from '../helpers/UserHelpers';
import { shapeEventToV3 } from '../helpers/EventHelpers';

const useThreadEventShaper = ({
  eventId,
  event: threadEvent,
  searchText,
}) => {
  const searchEvent = useSelector((state) => getEventById(state, { eventId: searchText ? eventId : null }));
  const organization = useSelector(getCurrentOrg);
  const event = searchText?.length > 0 ? searchEvent : threadEvent;
  const shapedEvent = shapeEventToV3(event);
  const activeUser = useActiveUser(shapedEvent.eventUserId);
  const {
    languageId,
    patientId,
    senderId,
    timestamp,
    isSecureThread,
    nonMaster,
    channelId,
    isV3Model,
    isIncoming,
    isConversationOpened,
  } = shapedEvent;

  const fromUser = useUser(senderId);
  const patient = useUser(patientId);
  const nonMasterUser = useUser(nonMaster);
  const channel = useChannel(channelId);
  const language = useSelector((state) => getLanguageById(state, { languageId }));
  const from = getFrom();
  const fromExternalId = getFromExternalId();
  const formattedTimestamp = formatTimestamp(timestamp);
  const formattedFromUserName = getFromUserName();
  const formattedActiveUserName = formatName(activeUser, false, false, from);
  const isSenderTypeOrganization = event.fromUser?.typeId === TYPE_ORGANIZATION;
  const isPatientInitiatedMessage = organization.isRoutingManagerEnabled && !isSecureThread && isConversationOpened && isIncoming && !isSenderTypeOrganization;
  const fromWebForm = !!event.fromWebForm || !!event.isFromWebForm;

  function getFrom() {
    // TODO: Create distinct model for each event type
    if (isV3Model || shapedEvent.isAssignmentEvent) {
      return shapedEvent.from;
    }
    if (shapedEvent.isCopyContentEvent) {
      return shapedEvent.from;
    }
    if (channel?.typeId === TYPE_SMS) {
      return shapedEvent.externalEndpoint?.value;
    } return null;
  }

  function getFromUserName() {
    if (shapedEvent.isAutomatedCampaignEvent || fromUser?.isSystemUser) {
      return organization.name;
    }
    const isEndUser = isSecureThread && isIncoming;
    const fromPhone = !shapedEvent.isCopyContentEvent && from;
    return formatName(senderId && fromUser, false, isEndUser, fromPhone);
  }

  function getFromExternalId() {
    if (isV3Model) {
      return shapedEvent.fromExternalId;
    } if (channel?.typeId === TYPE_FACEBOOK) {
      return shapedEvent.externalEndpoint?.value;
    } return null;
  }

  return {
    ...shapedEvent,
    fromUser,
    channel,
    language,
    patient,
    from,
    fromExternalId,
    formattedTimestamp,
    formattedFromUserName,
    formattedActiveUserName,
    nonMasterUser,
    activeUser,
    isPatientInitiatedMessage,
    fromWebForm,
  };
};

export default useThreadEventShaper;
