import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Button } from 'rhinostyle';
import { toggleScreenShare } from '../reducers/rhinovideoReducer';

const RhinovideoScreenShareButton = (props) => {
  const dispatch = useDispatch();
  const { isScreenShareEnabled, isButtonDisabled } = props;

  const handleToggle = () => dispatch(toggleScreenShare());

  return (
    <div>
      <Button
        type="link"
        size="large"
        iconOnly
        title={isScreenShareEnabled ? 'Stop Share' : 'Start Share'}
        onClick={handleToggle}
        className={`rhinovideo__action-bar__button ${isScreenShareEnabled ? 'is-screenshare-enabled' : ''}`}
        disabled={isButtonDisabled}
      >
        <Icon icon={isScreenShareEnabled ? 'video-stop' : 'desktop'} />
      </Button>
      <div className="rhinovideo__action-bar__label">
        <span>{isScreenShareEnabled ? 'Stop Share' : 'Share'}</span>
      </div>
    </div>
  );
};

RhinovideoScreenShareButton.propTypes = {
  isScreenShareEnabled: PropTypes.bool.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { rhinovideo } = state;

  return ({
    isScreenShareEnabled: rhinovideo.isScreenShareEnabled,
    isButtonDisabled: rhinovideo.isButtonDisabled,
  });
};

export default connect(mapStateToProps)(RhinovideoScreenShareButton);
