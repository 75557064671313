import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import * as UserReducer from '../reducers/userReducer';
import * as RhinogramReducer from '../reducers/rhinogramReducer';
import { fetchMerchant } from '../reducers/payReducer';
import * as UIReducer from '../reducers/uiReducer';
import * as UploadActions from '../actions/UploadActions';
import ContactForm from '../components/ContactForm';
import unsavedChanges from '../components/UnsavedChangesHOC';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { UserHelpers, DataHelpers, PhoneHelpers, ValidationHelpers } from '../helpers';
import { Types, AppConstants } from '../constants';
import { typeSelectors, userSelectors } from '../selectors';
import { capitalizeProp } from '../helpers/StringHelpers';
import { CONTACT_CREATE, INTEGRATED_CONTACTS_EDIT } from '../constants/UserPermissionsConstants';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';

const { REACT_APP_AVATAR_BUCKET: AVATAR_BUCKET } = process.env;

class ContactFormContainer extends Component {
  state = {
    activeUserConnectionKeys: [],
    addNewConnectedPartyContactModalOpen: false,
    automatedMessages: true,
    birthday: null,
    errors: {},
    externalId: '',
    displayId: '',
    firstName: '',
    hipaaStatusId: null,
    hipaaStatusTypeId: Types.TYPE_HIPAA_CONSENT_PENDING,
    id: -1,
    integrated: false,
    isForwardingEnabled: false,
    isRhinopayEnabled: false,
    lastIntegrationUpdate: '',
    lastName: '',
    middleName: '',
    marketingConsentStatusId: null,
    marketingConsentStatusTypeId: Types.TYPE_MARKETING_CONSENT_PENDING,
    mode: 'create',
    newOwnerId: null,
    note: '',
    noteIsImportant: false,
    organization: {},
    pageLoading: true,
    preferredName: '',
    prefixId: -1,
    profileImageUrl: '',
    searchText: '',
    phoneSearchIdx: -1,
    rhinopayConsentStatusId: null,
    rhinopayConsentStatusTypeId: Types.TYPE_RHINOPAY_CONSENT_PENDING,
    roles: [],
    selectedForwardingGroupId: -1,
    selectedForwardingMemberId: -1,
    selectedTagIds: [],
    sex: '',
    showConnectedPartySearch: false,
    showCreateUserConnectionForm: false,
    suffixId: -1,
    typeId: userHasAnyOfPermissions([CONTACT_CREATE]) ? Types.TYPE_PATIENT : Types.TYPE_ORGANIZATION,
    uploadAvatarModalOpen: false,
    userConnections: {},
    userEmails: { 0: { value: '' } },
    userPhones: { 0: { value: '' } },
    userRhinograms: { 0: { toChannelId: '', toOrganizationId: '' } },
    viewGroups: false,
  };

  componentDidMount() {
    this.props.clearUserSearch();
    this.props.fetchMerchant(this.props.user.organization);

    if (this.props.match.params.userId) {
      this.props.fetchContactEditFormView(this.props.match.params.userId, AppConstants.USER_RECORD_TYPE_ID);
    } else {
      this.getPrefilledData(this.props.location.state);
      this.props.fetchContactCreateFormView();
    }
    window.addEventListener('beforeunload', this.handleUnlock); // listener if user closes window/browser on desktop
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageLoading !== prevProps.pageLoading && this.props.pageLoading === false) {
      const activeUser = this.props.users[this.props.match.params.userId];
      const updateMode = activeUser && this.state.id === -1;
      if (updateMode) {
        this.setInitialState(activeUser);
      } else {
        this.setState({ pageLoading: false });
      }
    }

    if (this.props.pay.merchant.isRhinopayEnabled && !this.state.isRhinopayEnabled) {
      this.setState({ isRhinopayEnabled: true }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  setInitialState(activeUser) {
    const {
      automatedMessages,
      birthday,
      connectedParties,
      emails,
      externalIds,
      firstName,
      forwarding,
      hipaaStatus,
      id,
      integrated,
      lastIntegrationUpdate,
      lastName,
      middleName,
      note,
      noteIsImportant,
      organization,
      phones,
      rhinograms,
      preferredName,
      prefixId,
      profileImageUrl,
      rhinopayConsentStatus,
      marketingConsentStatus,
      roles,
      sex,
      suffixId,
      tags,
      typeId,
    } = activeUser;

    const reduceUserPropertyArrayToObject = (dict) => (acc, currVal, idx) => {
      acc[idx] = this.props[dict][currVal];

      return acc;
    };
    const isForwardingEnabled = this.state.isForwardingEnabled ||
        (
          (forwarding && forwarding.userId !== -1) ||
          (forwarding && forwarding.groupId !== -1)
        );
    this.setState({ // eslint-disable-line react/no-did-update-set-state
      automatedMessages,
      birthday: birthday ? moment(birthday) : null,
      externalId: this.getExternalId(externalIds),
      displayId: this.getDisplayId(externalIds),
      firstName,
      hipaaStatusId: hipaaStatus ? hipaaStatus.id : null,
      hipaaStatusTypeId: hipaaStatus ? hipaaStatus.typeId : Types.TYPE_HIPAA_CONSENT_PENDING,
      id,
      integrated,
      isForwardingEnabled,
      lastIntegrationUpdate,
      lastName,
      marketingConsentStatusId: marketingConsentStatus?.id ?? null,
      marketingConsentStatusTypeId: marketingConsentStatus?.typeId ?? Types.TYPE_MARKETING_CONSENT_PENDING,
      middleName,
      mode: 'update',
      note,
      noteIsImportant,
      organization: this.props.organizations[organization],
      preferredName,
      prefixId: prefixId || -1,
      profileImageUrl,
      rhinopayConsentStatusId: rhinopayConsentStatus ? rhinopayConsentStatus.id : null,
      rhinopayConsentStatusTypeId: rhinopayConsentStatus ? rhinopayConsentStatus.typeId : Types.TYPE_RHINOPAY_CONSENT_PENDING,
      roles,
      rhinograms: this.props.rhinograms,
      selectedForwardingMemberId: forwarding && forwarding.userId ? forwarding.userId : -1,
      selectedForwardingGroupId: forwarding && forwarding.groupId ? forwarding.groupId : -1,
      selectedTagIds: tags,
      sex,
      suffixId: suffixId || -1,
      typeId,
      userConnections: connectedParties.reduce(reduceUserPropertyArrayToObject('connectedParties'), {}),
      userEmails: emails.reduce(reduceUserPropertyArrayToObject('emails'), this.state.userEmails),
      userPhones: phones.reduce(reduceUserPropertyArrayToObject('phones'), this.state.userPhones),
      userRhinograms: rhinograms.reduce(reduceUserPropertyArrayToObject('rhinograms'), this.state.userRhinograms),
      viewGroups: !!forwarding && !!forwarding.groupId,
      pageLoading: false,
    }, () => {
      if (typeId === Types.TYPE_ORGANIZATION) {
        this.props.fetchRhinogramChannels(this.state.userRhinograms[0].toOrganizationId);
      }
    });
  }

  componentWillUnmount() {
    this.handleUnlock();
  }

  handleUnlock = () => {
    if (!this.props.logoutInProgress) {
      const isLockedByAnotherUser = this.props?.recordLock?.locked && !this.props.recordLock.lockAcquired;
      if (!isLockedByAnotherUser && this.state.mode !== 'create') { // if you own the lock, only remove it when you leave the page
        this.props.removeLock({ typeId: AppConstants.USER_RECORD_TYPE_ID, recordId: this.props.match.params.userId });
      }
    }
    window.removeEventListener('beforeunload', this.handleUnlock);
  }

  get payload() {
    const reduceUserPhonesFromObject = (acc, currVal) => {
      const phone = { ...this.state.userPhones[currVal] };

      if (!phone.markedToDelete && phone.value) {
        phone.value = PhoneHelpers.normalizePhone(phone.value);
        return acc.concat(phone);
      }

      return acc;
    };

    const reduceUserRhinogramsFromObject = (acc, currVal) => {
      const rhinogram = { ...this.state.userRhinograms[currVal] };
      // eslint-disable-next-line radix
      rhinogram.toChannelId = parseInt(rhinogram.toChannelId);
      // eslint-disable-next-line radix
      rhinogram.toOrganizationId = parseInt(rhinogram.toOrganizationId);
      if (rhinogram) {
        return acc.concat(rhinogram);
      }

      return acc;
    };

    const reduceUserEmailsFromObject = (acc, currVal) => {
      const email = { ...this.state.userEmails[currVal] };

      if (!email.markedToDelete && email.value) {
        return acc.concat(email);
      }

      return acc;
    };

    // Send null to clear forwarding
    const forwarding = this.state.selectedForwardingMemberId !== -1 || this.state.selectedForwardingGroupId !== -1 ?
      {
        userId: this.state.selectedForwardingMemberId !== -1 ? this.state.selectedForwardingMemberId : null,
        groupId: this.state.selectedForwardingGroupId !== -1 ? this.state.selectedForwardingGroupId : null,
      } : null;

    // Birthday is cleared if invalid, so translate to proper format if we have valid moment object
    const birthday = this.state.birthday ? moment(this.state.birthday).format('YYYY-MM-DD') : '';

    const payload = {
      automatedMessages: this.state.automatedMessages,
      birthday,
      connectedTo: this.shapeConnections(this.state.userConnections),
      emails: Object.keys(this.state.userEmails).reduce(reduceUserEmailsFromObject, []),
      externalIds: this.shapeExternalId(),
      firstName: this.state.firstName,
      hipaaStatus: this.getHipaaStatus(),
      id: this.state.id,
      lastName: this.state.lastName,
      middleName: this.state.middleName,
      note: this.state.note,
      noteIsImportant: this.state.noteIsImportant,
      phones: Object.keys(this.state.userPhones).reduce(reduceUserPhonesFromObject, []),
      rhinograms: Object.keys(this.state.userRhinograms).reduce(reduceUserRhinogramsFromObject, []),
      preferredName: this.state.preferredName,
      prefixId: this.state.prefixId,
      profileImageUrl: this.state.profileImageUrl,
      rhinopayConsentStatus: this.getRhinopayConsentStatus(),
      marketingConsentStatus: this.getMarketingConsentStatus(),
      roles: this.state.roles,
      sex: this.state.sex,
      suffixId: this.state.suffixId,
      tagIds: this.state.selectedTagIds,
      typeId: this.state.typeId,
      forwarding,
    };

    if (payload.suffixId === -1) payload.suffixId = '';
    if (payload.prefixId === -1) payload.prefixId = '';

    // If typeId is unknown, remove to force them to select
    if (payload.typeId === Types.TYPE_UNKNOWN) delete payload.typeId;

    // If typeId is not organization, remove the null rhinogram payload
    if (payload.typeId !== Types.TYPE_ORGANIZATION) delete payload.rhinograms;
    return payload;
  }

  getHipaaStatus = () => {
    if (this.state.mode === 'update') {
      return {
        id: this.state.hipaaStatusId,
        trusteeId: this.props.user.id,
        typeId: this.state.hipaaStatusTypeId,
        changed: this.props.users[this.props.match.params.userId].hipaaStatus ?
          this.props.users[this.props.match.params.userId].hipaaStatus.typeId !== this.state.hipaaStatusTypeId : false,
      };
    }

    return {
      trusteeId: this.state.hipaaStatusTypeId !== Types.TYPE_HIPAA_CONSENT_PENDING ? this.props.user.id : null,
      typeId: this.state.hipaaStatusTypeId,
      changed: this.state.hipaaStatusTypeId !== Types.TYPE_HIPAA_CONSENT_PENDING,
    };
  }

  getRhinopayConsentStatus = () => {
    if (this.state.mode === 'update') {
      return {
        id: this.state.rhinopayConsentStatusId,
        trusteeId: this.props.user.id,
        typeId: this.state.rhinopayConsentStatusTypeId,
        changed: this.props.users[this.props.match.params.userId].rhinopayConsentStatus ?
          this.props.users[this.props.match.params.userId].rhinopayConsentStatus.typeId !== this.state.rhinopayConsentStatusTypeId : false,
      };
    }

    return {
      trusteeId: this.state.rhinopayConsentStatusTypeId !== Types.TYPE_RHINOPAY_CONSENT_PENDING ? this.props.user.id : null,
      typeId: this.state.rhinopayConsentStatusTypeId,
      changed: this.state.rhinopayConsentStatusTypeId !== Types.TYPE_RHINOPAY_CONSENT_PENDING,
    };
  }

  getMarketingConsentStatus = () => {
    if (this.state.mode === 'update') {
      return {
        id: this.state.marketingConsentStatusId,
        trusteeId: this.props.user.id,
        typeId: this.state.marketingConsentStatusTypeId,
        changed: this.props.users[this.props.match.params.userId].marketingConsentStatus ?
          this.props.users[this.props.match.params.userId].marketingConsentStatus.typeId !== this.state.marketingConsentStatusTypeId : false,
      };
    }

    return {
      trusteeId: this.state.marketingConsentStatusTypeId !== Types.TYPE_MARKETING_CONSENT_PENDING ? this.props.user.id : null,
      typeId: this.state.marketingConsentStatusTypeId,
      changed: this.state.marketingConsentStatusTypeId !== Types.TYPE_MARKETING_CONSENT_PENDING,
    };
  }

  getPrefilledData = ({ searchText = '', activeFilterParam = '' } = {}) => {
    if (!searchText.length || !activeFilterParam.length) return;

    let newState = {};

    if (activeFilterParam === 'phone') {
      newState.userPhones = {
        0: {
          value: searchText,
          typeId: Types.TYPE_CELL,
        },
      };
    } else if (activeFilterParam === 'name') {
      const splitSearch = searchText.split(' ');
      const [firstName, lastName] = splitSearch;

      newState = { firstName, lastName };
    } else if (activeFilterParam === 'dob') {
      const dob = moment(searchText, 'MM/DD/YYYY');

      newState = { birthday: dob };
    } else if (activeFilterParam === 'id') {
      newState = { externalId: searchText };
    } else if (activeFilterParam === 'email') {
      newState.userEmails = {
        0: {
          value: searchText,
          typeId: Types.TYPE_EMAIL_HOME,
        },
      };
    }

    this.setState(newState);
  }

  getExternalId = (externalIds) => {
    if (!externalIds) return '';
    if (externalIds.displayId) {
      return externalIds.displayId;
    } else if (externalIds.emrId) {
      return externalIds.emrId;
    }
    return '';
  }

  getDisplayId = (externalIds) => {
    if (!externalIds) return '';
    if (externalIds.displayId) {
      return externalIds.displayId;
    } else if (externalIds.emrId) {
      return externalIds.emrId;
    }
    return '';
  }

  shapeExternalId = () => {
    const activeUser = this.props.users[this.props.match.params.userId];
    if (!activeUser?.integrated) return { displayId: this.state.displayId };
    return '';
  }

  handleBirthdayChange = (date) => {
    this.setState({
      birthday: date,
    });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  handleRhinogramChange = (name, value) => {
    if (name === 'toOrganizationId') {
      // fetch org RGchannels
      this.props.fetchRhinogramChannels(value);
    }
    this.setState({ userRhinograms: { 0: { ...this.state.userRhinograms[0], [name]: value } } });
  }

  // @blakeguilloud come up with a better naming convention for these functions..
  // ContactData makes it sound like it has to do w/ patient data vs phone / email data.
  handleReceiveContactData = (key, data, dict) => {
    const newState = {
      [dict]: JSON.parse(JSON.stringify(this.state[dict])),
    };

    newState[dict][key] = data;

    this.setState(newState);
  }

  handleRemoveContactData = (key, dict) => {
    const contactData = { ...this.state[dict] };
    const contactKey = { ...contactData[key] };

    contactKey.markedToDelete = true;

    contactData[key] = contactKey;

    this.setState({ [dict]: contactData }, () => this.props.handleFormChanges());
  }

  handleUserConnectionChange = (name, value, key) => {
    this.setState((prevState) => ({
      userConnections: {
        ...prevState.userConnections,
        [key]: {
          ...prevState.userConnections[key],
          [name]: value,
        },
      } }));
  }

  handleCreateCPClick = () => {
    this.setState({
      addNewConnectedPartyContactModalOpen: true,
    });
  }

  closeModal = () => {
    this.setState({
      addNewConnectedPartyContactModalOpen: false,
      showConnectedPartySearch: false,
    });
  };

  shapeConnections = (connections) => {
    const newShape = {};

    Object.keys(connections).map((key) => {
      const connection = connections[key];

      newShape[key] = {
        note: connection.note,
        toUserId: connection.userId,
        connectionTypeId: connection.connectionTypeId,
      };

      return newShape;
    });

    return DataHelpers.convertObjToArr(newShape);
  }

  handleUserConnectionSearchSelect = (id, addUserToActiveConnectionKeys = true, note = '', connectionTypeId = Types.TYPE_PARENT_STEPPARENT) => {
    const userConnectionIds = Object.keys(this.state.userConnections).map((key) => this.state.userConnections[key].userId);
    const removeAction = userConnectionIds.includes(id);

    if (removeAction) {
      const userConnectionKey = Object.keys(this.state.userConnections).find((key) => this.state.userConnections[key].userId === id);

      this.handleRemoveUserConnection(userConnectionKey);
    } else {
      const user = this.props.users[id];

      const obj = {
        userId: user.id,
        userFirstName: user.firstName,
        userLastName: user.lastName,
        userProfileImageUrl: user.profileImageUrl,
        userPrefix: user.prefixId,
        userSuffix: user.suffixId,
        connectionTypeId,
        note,
      };

      const userConnections = { ...this.state.userConnections };

      userConnections[id] = obj;

      let activeUserConnectionKeys = [...this.state.activeUserConnectionKeys];

      if (addUserToActiveConnectionKeys) {
        activeUserConnectionKeys = activeUserConnectionKeys.concat(obj.userId.toString());
      }

      this.setState({
        userConnections,
        activeUserConnectionKeys,
      });
    }

    this.props.handleFormChanges();
  }

  handleRemoveUserConnection = (key) => {
    const userConnections = { ...this.state.userConnections };

    delete userConnections[key];

    this.setState({ userConnections }, () => this.props.handleFormChanges());
  }

  handleUpdateOwnership = (phonesArray) => phonesArray.map((p) => {
    const newPhone = DataHelpers.cloneDeep(p);
    if (newPhone.newOwnerId) {
      newPhone.ownerId = newPhone.newOwnerId;
    }
    return newPhone;
  });

  handleSearch = (value) => {
    if (value && value.length === 14) {
      this.props.fetchPhoneSearch(value, 'nonMembers', 'phone');
    } else if (this.props.phoneUserSearchIds.length) {
      this.props.clearUserSearch();
    }

    this.setState({ searchText: value });
  }

  handlePhoneSearchIdx = (idx) => {
    this.setState({ phoneSearchIdx: idx, searchText: '' });
  }

  handleSubmit = () => {
    const { payload } = this;
    const { state: { integrated } } = this;
    const errors = ValidationService(ValidationShapers.shapeContactProfile(payload, integrated));
    const errorCount = Object.keys(errors).length;
    if (payload.phones) {
      payload.phones = this.handleUpdateOwnership(payload.phones);
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
      });
    } else if (this.state.mode === 'update') {
      if (payload.phones && payload.phones > 0) this.handleUpdateOwnership(payload.phones);
      this.props.updateUser(payload.id, payload)
        .then(() => {
          if (this.props.error && this.props.error.data) {
            const errorData = DataHelpers.cloneDeep(this.props.error.data);
            if (this.props.error.data.property) {
              errorData.property = this.props.error.data.property;
            }
            this.setState({
              errors: ValidationHelpers.handleServerError(errorData),
            }, () => {
              ValidationHelpers.handleValidationErrors(this.state.errors, this.pageContainer);
            });
          } else {
            this.setState({ errors: {} });
            this.props.resetFormChanges();
            this.handleFormSuccess(this.state.id);
          }
        });
    } else {
      this.props.createUser(payload)
        .then((response) => {
          if (this.props.error && this.props.error.data) {
            const errorData = DataHelpers.cloneDeep(this.props.error.data);
            if (this.props.error.data.property) {
              errorData.property = this.props.error.data.property;
            }
            this.setState({
              errors: ValidationHelpers.handleServerError(errorData),
            }, () => {
              ValidationHelpers.handleValidationErrors(this.state.errors, this.pageContainer);
            });
          } else {
            this.props.resetFormChanges();
            this.handleFormSuccess(response.payload.userId);
          }
        });
    }
  }

  handleFormSuccess(userId) {
    const { history } = this.props;
    const { mode } = this.state;

    if (history.canGoBack && mode !== 'create') {
      const previousRoute = history.historyUrls[history.historyUrls.length - 2];
      history.push(previousRoute);
    } else {
      history.push(`/inbox/all/user/${userId}`);
    }
  }

  handleViewProfileTransition = async (userId) => {
    await this.props.fetchUser(userId);
    this.props.history.push(`/contacts/${userId}`);
  }

  handleToggle = (name) => {
    this.setState({ [name]: !this.state[name] });
  }

  handleForwardingToggle = () => {
    this.setState({
      isForwardingEnabled: !this.state.isForwardingEnabled,
      selectedForwardingGroupId: -1,
      selectedForwardingMemberId: -1,
    });
  }

  handleUpdateSelectedIds = (name, id) => {
    let selectedIds = [...this.state[name]];

    const addAction = !selectedIds.includes(id);

    if (addAction) {
      selectedIds = selectedIds.concat(id);
    } else {
      selectedIds = selectedIds.filter((selectedId) => selectedId !== id);
    }

    this.setState({ [name]: selectedIds }, () => this.props.handleFormChanges());
  }

  handleUpdateSelectedId = (name, id) => {
    const newState = {
      selectedForwardingMemberId: -1,
      selectedForwardingGroupId: -1,
      [name]: id,
    };

    this.setState(newState, () => this.props.handleFormChanges());
  }

  handleUploadAvatar = (image, sourceFilename) => {
    UploadActions.uploadFileFromDataUrl(image, AVATAR_BUCKET, sourceFilename)
      .then((response) => {
        this.setState({
          profileImageUrl: response.file,
        });
      })
      .finally(() => {
        this.setState({
          uploadAvatarModalOpen: false,
        });
      });
  }

  render() {
    const props = {
      actionTitle: this.state.mode === 'update' ? 'Update Contact' : 'Create Contact',
      actionType: this.state.mode === 'update' ? 'primary' : 'secondary',
      activeUserConnectionKeys: this.state.activeUserConnectionKeys,
      addNewConnectedPartyContactModalOpen: this.state.addNewConnectedPartyContactModalOpen,
      automatedMessages: this.state.automatedMessages,
      avatarName: UserHelpers.formatAvatarName(this.state.firstName, this.state.lastName),
      birthday: this.state.birthday,
      closeModal: this.closeModal,
      connectedPartyTypes: this.props.connectedPartyTypes,
      editMode: this.state.mode,
      emailTypes: this.props.emailTypes.map(capitalizeProp('value')),
      errors: this.state.errors,
      externalId: this.state.externalId,
      displayId: this.state.displayId,
      firstName: this.state.firstName,
      formInProgress: this.props.formInProgress,
      handleBirthdayChange: this.handleBirthdayChange,
      handleChange: this.handleChange,
      handleRhinogramChange: this.handleRhinogramChange,
      handleFormChanges: this.props.handleFormChanges,
      handleCreateCPClick: this.handleCreateCPClick,
      handleForwardingToggle: this.handleForwardingToggle,
      handleReceiveContactData: this.handleReceiveContactData,
      handleRemoveContactData: this.handleRemoveContactData,
      handleRemoveUserConnection: this.handleRemoveUserConnection,
      handleSearch: this.handleSearch,
      handlePhoneSearchIdx: this.handlePhoneSearchIdx,
      handleSubmit: this.handleSubmit,
      handleToggle: this.handleToggle,
      handleUpdateSelectedId: this.handleUpdateSelectedId,
      handleUpdateSelectedIds: this.handleUpdateSelectedIds,
      handleUploadAvatar: this.handleUploadAvatar,
      handleUserConnectionChange: this.handleUserConnectionChange,
      handleUserConnectionSearchSelect: this.handleUserConnectionSearchSelect,
      handleViewProfileTransition: this.handleViewProfileTransition,
      hipaaStatusTypeId: this.state.hipaaStatusTypeId,
      integrated: this.state.integrated,
      isForwardingEnabled: this.state.isForwardingEnabled || (this.state.selectedForwardingMemberId !== -1 || this.state.selectedForwardingGroupId !== -1),
      isRhinopayEnabled: this.state.isRhinopayEnabled,
      lastIntegrationUpdate: this.state.lastIntegrationUpdate,
      lastName: this.state.lastName,
      marketingConsentStatusTypeId: this.state.marketingConsentStatusTypeId,
      middleName: this.state.middleName,
      newOwnerId: this.state.newOwnerId,
      note: this.state.note,
      noteIsImportant: this.state.noteIsImportant,
      organization: this.state.organization,
      pageContainerRef: (pageContainer) => (this.pageContainer = pageContainer),
      pageLoading: this.state.pageLoading,
      pageTitle: this.state.mode === 'update' ? 'Edit Contact' : 'Create Contact',
      phoneTypes: this.props.phoneTypes.map(capitalizeProp('value')),
      preferredName: this.state.preferredName,
      prefixId: this.state.prefixId,
      prefixOpts: [{ id: -1, value: '--' }].concat(this.props.prefixIds.map((id) => this.props.prefixes[id])),
      profileImageUrl: this.state.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${this.state.profileImageUrl}` : '',
      rhinopayConsentStatusTypeId: this.state.rhinopayConsentStatusTypeId,
      searchText: this.state.searchText,
      phones: this.props.phones,
      rhinograms: this.props.rhinograms,
      rhinogramChannelsLoading: this.props.rhinogramChannelsLoading,
      rhinogramChannelOpts: [{ id: -1, value: '--' }].concat(
        this.props.rhinogramChannelIds.map((id) => ({ id, value: this.props.rhinogramChannels[id].name })),
      ),
      phoneSearchIdx: this.state.phoneSearchIdx,
      selectedForwardingGroupId: this.state.selectedForwardingGroupId,
      selectedForwardingMemberId: this.state.selectedForwardingMemberId,
      selectedTagIds: this.state.selectedTagIds,
      sex: this.state.sex,
      showConnectedPartySearch: this.state.showConnectedPartySearch,
      showCreateUserConnectionForm: this.state.showCreateUserConnectionForm,
      suffixId: this.state.suffixId,
      suffixOpts: [{ id: -1, value: '--' }].concat(this.props.suffixIds.map((id) => this.props.suffixes[id])),
      tagIds: this.props.tagIds,
      tags: this.props.tags,
      typeId: this.state.typeId,
      uploadAvatarModalOpen: this.state.uploadAvatarModalOpen,
      userConnections: this.state.userConnections,
      userEmails: this.state.userEmails,
      userId: this.state.id,
      userPhones: this.state.userPhones,
      userRhinograms: this.state.userRhinograms,
      userRhinogramOpts: [{ id: -1, value: '--' }].concat(this.props.prefixIds.map((id) => this.props.prefixes[id])),
      phoneSearchLoading: this.props.phoneSearchLoading,
      phoneUserSearchIds: this.props.phoneUserSearchIds,
      viewGroups: this.state.viewGroups,
      clearUserSearch: this.props.clearUserSearch,
      currentUser: this.props.user,
      users: this.props.users,
      recordLock: this.props.recordLock,
      isLockedByAnotherUser: this.props.recordLock && this.props.recordLock.locked && !this.props.recordLock.lockAcquired && this.state.mode !== 'create',
      isEditIntegratedContactsAllowed: this.state.organization?.isEditIntegratedContactEnabled && userHasAnyOfPermissions([INTEGRATED_CONTACTS_EDIT]),
    };

    return <ContactForm {...props} />;
  }
}

ContactFormContainer.propTypes = {
  connectedParties: PropTypes.object.isRequired,
  connectedPartyTypes: PropTypes.array.isRequired,
  fetchRhinogramChannels: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  emailTypes: PropTypes.array.isRequired,
  fetchContactCreateFormView: PropTypes.func.isRequired,
  fetchContactEditFormView: PropTypes.func.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func,
  handlePhoneSearchIdx: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  organizations: PropTypes.object.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  phones: PropTypes.object.isRequired,
  rhinograms: PropTypes.object.isRequired,
  rhinogramChannels: PropTypes.object.isRequired,
  rhinogramChannelsLoading: PropTypes.bool.isRequired,
  phoneTypes: PropTypes.array.isRequired,
  prefixes: PropTypes.object.isRequired,
  prefixIds: PropTypes.array.isRequired,
  searchText: PropTypes.string,
  suffixes: PropTypes.object.isRequired,
  suffixIds: PropTypes.array.isRequired,
  tagIds: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  error: PropTypes.object,
  handleFormChanges: PropTypes.func.isRequired,
  resetFormChanges: PropTypes.func.isRequired,
  phoneSearchLoading: PropTypes.bool,
  phoneUserSearchIds: PropTypes.array,
  clearUserSearch: PropTypes.func,
  removeLock: PropTypes.func,
  recordLock: PropTypes.object,
  isLockedByAnotherUser: PropTypes.bool,
  fetchMerchant: PropTypes.func,
  fetchUser: PropTypes.func,
  pay: PropTypes.object,
  fetchPhoneSearch: PropTypes.func,
  logoutInProgress: PropTypes.bool,
  rhinogramChannelIds: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { auth, connectedParty, email, tag, ui, user, prefix, phone, rhinogram, suffix, organization, pay } = state;
  const props = {
    connectedParties: connectedParty.connectedParties,
    connectedPartyTypes: typeSelectors.getConnectedPartyTypes(state),
    emails: email.emails,
    emailTypes: typeSelectors.getEmailTypes(state),
    error: ui.error,
    formInProgress: ui.formInProgress,
    logoutInProgress: auth.logoutInProgress,
    organizations: organization.organizations,
    pageLoading: user.contactFormLoading,
    pay,
    phones: phone.phones,
    rhinograms: rhinogram.rhinograms,
    rhinogramChannels: rhinogram.rhinogramChannels,
    rhinogramChannelIds: rhinogram.rhinogramChannelIds,
    rhinogramChannelsLoading: rhinogram.rhinogramChannelsLoading,
    phoneSearchLoading: user.phoneSearchLoading,
    phoneTypes: typeSelectors.getPhoneTypes(state),
    phoneUserSearchIds: user.phoneUserSearchIds,
    prefixes: prefix.prefixes,
    prefixIds: prefix.prefixIds,
    recordLock: ui.recordLock,
    suffixes: suffix.suffixes,
    suffixIds: suffix.suffixIds,
    tagIds: tag.tagIds,
    tags: tag.tags,
    user: userSelectors.getLoggedInUser(state),
    users: user.users,
  };
  return props;
};

const actions = {
  fetchRhinogramChannels: RhinogramReducer.fetchRhinogramChannels,
  createUser: UserReducer.createUser,
  fetchUser: UserReducer.fetchUser,
  fetchContactCreateFormView: UserReducer.fetchContactCreateFormView,
  fetchContactEditFormView: UserReducer.fetchContactEditFormView,
  updateUser: UserReducer.updateUser,
  fetchPhoneSearch: UserReducer.fetchPhoneSearch,
  clearUserSearch: UserReducer.clearUserSearch,
  removeLock: UIReducer.removeLock,
  fetchMerchant,
};

export default connect(mapStateToProps, actions)(unsavedChanges(ContactFormContainer));
