import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getContactFilters } from '../helpers/ContactHelpers';
import MoreDropdown from './MoreDropdown';

const ContactFilters = (props) => {
  const {
    disabled,
    handleChange,
    filters,
  } = props;
  const dropdownClass = 'filter-page__dropdown';
  const filterComponents = getContactFilters();
  const hiddenFilters = filterComponents.filter((item) => item.hide);
  const [showOptions, setShowOptions] = useState(hiddenFilters.map((item) => ({ [item.name]: false })));

  const renderFilters = (filter, index) => {
    const { name, label, component: Component, wide, hide } = filter;
    const content = [];

    if (hide && !showOptions[name]) {
      return null;
    } else {
      content.push(
        <Component
          key={name}
          value={filters[name]}
          name={name}
          label={label}
          handleChange={handleChange}
          disabled={disabled}
          dropdownClass={dropdownClass}
          wide={wide}
          dataFeatureTag={`contact-search-${name}`}
        />,
      );
    }

    if (index === 3) {
      content.push(
        <MoreDropdown
          key="moreDropdown"
          disabled={disabled}
          dropdownClass={dropdownClass}
          showOptions={showOptions}
          setShowOptions={setShowOptions}
          dropdownOptions={hiddenFilters}
        />,
      );
    }
    return content;
  };
  return (filterComponents.map(renderFilters));
};

ContactFilters.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default ContactFilters;
