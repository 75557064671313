import { useSelector } from 'react-redux';
import { getEventPhoneByValue, getPhoneById } from '../selectors/userSelectors';

const useEventPhone = (props) => {
  const phone = useSelector((state) => {
    if (props.from) {
      return getEventPhoneByValue(state, props.from);
    } else if (props.phoneId) {
      return getPhoneById(state, props.phoneId);
    }
    return {};
  });
  return phone;
};

export default useEventPhone;
