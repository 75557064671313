import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import {
  Button,
  Dropdown,
  DropdownMenuHeader,
  DropdownMenuItem,
  Icon,
  Avatar,
  UtilitySystem,
} from 'rhinostyle';
import HelpDropdown from './HelpDropdown';
import GlobalUserSearch from './GlobalUserSearch';
import StorageService from '../services/StorageService';
import rhinoLogoReversed from '../assets/images/rhinogram-logo-reversed.svg';
import { UserHelpers } from '../helpers';
import { AVATAR_BASE_URL, MINIMUM_SYSTEM_ALERT_HEIGHT } from '../constants/AppConstants';
import { CONTACT_VIEW, MEMBER_VIEW, CONVERSATION_CONTACT_MOBILE } from '../constants/UserPermissionsConstants';
import { isMobile } from '../helpers/BrowserHelpers';

const Header = (props) => {
  const location = useLocation();
  const history = useHistory();
  const {
    activeSettingsPath,
    currentUser,
    handleLogout,
    toggleNavigation,
    totalUnreadCount,
    logoutInProgress,
    handleToggleGlobalSearchModalWrapper,
    showGlobalSearchModalWrapper,
    headerRef,
  } = props;

  const { userHasAnyOfPermissions, userHasAllOfPermissions } = UserHelpers;

  const renderHeader = () => {
    // Keep this because of https://github.com/rhinogram/rhinofront/issues/2315
    if (!currentUser) return null;
    const formattedName = UserHelpers.formatAvatarName(currentUser.firstName, currentUser.lastName);
    const profileImageUrl = currentUser && currentUser.profileImageUrl ? `${AVATAR_BASE_URL}${currentUser.profileImageUrl}` : '';
    const headerOffsetTop = headerRef?.current?.offsetTop ?? MINIMUM_SYSTEM_ALERT_HEIGHT;
    const today = new Date();
    const currentHour = today.getHours();
    let greeting;
    let name;

    if (currentHour < 12) {
      greeting = 'Good morning';
    } else if (currentHour < 18) {
      greeting = 'Good afternoon';
    } else {
      greeting = 'Good evening';
    }

    if (currentUser.preferredName) {
      name = `, ${currentUser.preferredName}`;
    } else if (currentUser.firstName) {
      name = `, ${currentUser.firstName}`;
    }

    greeting = `${greeting}${name}!`;
    const globalSearchPermission = isMobile() ? (userHasAllOfPermissions([CONTACT_VIEW, CONVERSATION_CONTACT_MOBILE]) ||
    userHasAnyOfPermissions([MEMBER_VIEW])) : userHasAnyOfPermissions([CONTACT_VIEW, MEMBER_VIEW]);
    return (
      <>
        <div>
          {globalSearchPermission && (
            <GlobalUserSearch
              scope="global"
              handleToggleGlobalSearchModalWrapper={handleToggleGlobalSearchModalWrapper}
              showGlobalSearchModalWrapper={showGlobalSearchModalWrapper}
              headerOffsetTop={headerOffsetTop}
              history={history}
              location={location}
            />
          )}
        </div>
        <div className="app-header__logo"><img src={rhinoLogoReversed} alt="Rhinogram" /></div>
        <div className="app-header__right">
          {StorageService.readEntry('patientExperience') ? (
            <>
              <div className="app-header__dropdown">
                <NavLink // eslint-disable-line jsx-a11y/anchor-is-valid
                  to="/secure/profile"
                  title="My Profile"
                  className="app-header__member"
                  activeClassName={UtilitySystem.config.classes.active}
                >
                  <Avatar
                    image={profileImageUrl}
                    name={UserHelpers.formatAvatarName(currentUser.firstName, currentUser.lastName)}
                    type="default"
                    className="app-header__avatar"
                  />
                </NavLink>
              </div>
              <Button
                loading={logoutInProgress}
                title="Log Out"
                type="link"
                onClick={handleLogout}
                className="app-header__icon"
                data-cypress="logoutButton"
              >
                <Icon icon="log-out" />
              </Button>
            </>
          ) : (
            <>
              {!currentUser.isCcr && (
              <div title="My Profile" className="app-header__member">
                <span className="u-text-small u-text-muted">{greeting}</span>
                <Dropdown
                  activeKey={activeSettingsPath}
                  avatar={{ image: profileImageUrl, name: formattedName, type: 'member' }}
                  position="center"
                  reset
                  className="button__text-wrapper"
                  lockLabel
                  hideCaret
                  wide
                >
                  <DropdownMenuHeader label="My Settings" />
                  <DropdownMenuItem id="/settings/profile" label="Profile" onClick={() => history.push('/settings/profile')} />
                  <DropdownMenuItem id="/settings/preferences" label="Preferences" onClick={() => history.push('/settings/preferences')} />
                </Dropdown>
              </div>
              )}
              <Button
                loading={logoutInProgress}
                title="Log Out"
                type="link"
                onClick={handleLogout}
                className="app-header__icon"
                data-cypress="logoutButton"
              >
                <Icon icon="log-out" />
              </Button>
              {!currentUser?.isCcr && (
              <div className="app-header__dropdown">
                <HelpDropdown
                  position="right"
                  className="app-header__icon"
                  reset
                />
              </div>
              )}
            </>
          )}
        </div>
      </>
    );
  };

  const renderCcrHeader = () => (
    <div className="app-header__right">
      <Button
        loading={logoutInProgress}
        title="Log Out"
        type="link"
        onClick={handleLogout}
        className="app-header__icon"
        data-cypress="logoutButton"
      >
        <Icon icon="log-out" />
      </Button>
    </div>
  );

  const shouldDisplayCcrHeader = () => {
    const ccrHeaderPaths = ['/selectorg', '/accountsetup', '/systemalert'];
    return ccrHeaderPaths.indexOf(window.location.pathname) > -1;
  };

  return (
    <>
      <header ref={headerRef} className="app-header" style={{ zIndex: 1021 }}>
        <div id="app-header__menu" className="app-header__menu" onClick={toggleNavigation}>
          {totalUnreadCount ? <div className="app-header__menu__badge">{totalUnreadCount}</div> : null}
          <div className="app-header__menu__bar" />
          <div className="app-header__menu__bar" />
          <div className="app-header__menu__bar" />
        </div>
        {shouldDisplayCcrHeader() ? renderCcrHeader() : renderHeader()}
      </header>
      {showGlobalSearchModalWrapper && (
        <div className="global-search__modal__wrapper">
          <div onClick={handleToggleGlobalSearchModalWrapper} className="modal" />
        </div>
      )}
    </>
  );
};

Header.propTypes = {
  activeSettingsPath: PropTypes.string,
  currentUser: PropTypes.object,
  handleLogout: PropTypes.func,
  toggleNavigation: PropTypes.func,
  totalUnreadCount: PropTypes.number,
  logoutInProgress: PropTypes.bool,
  handleToggleGlobalSearchModalWrapper: PropTypes.func.isRequired,
  showGlobalSearchModalWrapper: PropTypes.bool.isRequired,
  headerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default Header;
