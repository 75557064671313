import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { UtilitySystem } from 'rhinostyle';
import cx from 'classnames';

import GroupSortMenu from './GroupSortMenu';
import MultiOrgContainer from '../containers/MultiOrgContainer';
import { hasAnyInboxViewPermissions, isCCR, hasContactThreadViewPermissions, hasTeamThreadViewPermissions } from '../helpers/UserHelpers';
import * as GroupHelpers from '../helpers/GroupHelpers';
import { sortGroupsByPref, getMentionBadgeCount, isBadgeCountVisible } from '../helpers/UIHelpers';
import { Types, AppConstants } from '../constants';

const Navigation = (props) => {
  const history = useHistory();
  const {
    assignedSection,
    chatSection,
    currentUser,
    followingSection,
    handleNavigationClick,
    inboxSection,
    organizations,
    mentionsSection,
    myUsersRaw,
    selectedInboxSubMenuNav,
    selectedParentNav,
    selectedSubMenuNav,
  } = props;
  const renderCcrNav = () => (
    <div className="app-navigation__container">
      <div className="app-navigation__top">
        <div className="app-navigation__container__scroll">
          <ul className="app-navigation__nav">
            <li className="app-navigation__nav__header">
              <span className="app-navigation__nav__button__text">System Tools</span>
            </li>
            <li className="app-navigation__nav__sub">
              <NavLink // eslint-disable-line jsx-a11y/anchor-is-valid
                id="nav-select-org"
                to="/selectorg"
                onClick={handleNavigationClick}
                className="app-navigation__nav__button"
                activeClassName={UtilitySystem.config.classes.active}
                data-cypress="organizations"
              >
                <span className="app-navigation__nav__button__text">Organizations</span>
              </NavLink>
            </li>
            {isCCR(myUsersRaw[0]) && (
            <li className="app-navigation__nav__sub">
              <NavLink // eslint-disable-line jsx-a11y/anchor-is-valid
                id="nav-account-setup"
                to="/accountsetup"
                onClick={handleNavigationClick}
                className="app-navigation__nav__button"
                activeClassName={UtilitySystem.config.classes.active}
                data-cypress="accountSetup"
              >
                <span className="app-navigation__nav__button__text">Account Setup</span>
              </NavLink>
            </li>
            )}
            {isCCR(myUsersRaw[0]) && (
            <li className="app-navigation__nav__sub">
              <NavLink // eslint-disable-line jsx-a11y/anchor-is-valid
                id="nav-system-alert"
                to="/systemalert"
                onClick={handleNavigationClick}
                className="app-navigation__nav__button"
                activeClassName={UtilitySystem.config.classes.active}
                data-cypress="systemAlert"
              >
                <span className="app-navigation__nav__button__text">System Alert</span>
              </NavLink>
            </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );

  const renderGroup = (group, key, section) => {
    let link = '';
    let id = '';
    let className = '';
    let dataCypress = '';

    if (section === 'inbox') {
      link = group.type === 'direct' ? '/inbox/direct' : `/inbox${group.userGroupId ? `/group/${group.userGroupId}` : ''}`;

      if (group.type === 'direct') {
        id = 'nav-inbox-direct';
        const viewDirectPatientGroup = selectedParentNav === 'inbox' && selectedInboxSubMenuNav === 'direct';
        className = cx('app-navigation__nav__button', {
          [UtilitySystem.config.classes.active]: viewDirectPatientGroup,
        });
        dataCypress = 'nav-inbox-direct';
      }
      if (group.type === 'assigned') {
        id = 'nav-inbox-assigned';
        dataCypress = 'nav-inbox-assigned';
      } else if (group.userGroupId) {
        id = `nav-inbox-${group.userGroupId}`;
        const viewPatientGroup = selectedParentNav === 'inbox' && selectedInboxSubMenuNav === 'group' && group.userGroupId === Number(selectedSubMenuNav);
        className = cx('app-navigation__nav__button', {
          [UtilitySystem.config.classes.active]: viewPatientGroup,
        });
        dataCypress = `nav-inbox-${group.userGroupId}`;
      }
    } else if (section === 'chat') {
      link = group.type === 'direct' ? '/chat' : `/chat${group.userGroupId ? `/group/${group.userGroupId}` : ''}`;

      if (group.type === 'direct') {
        id = 'nav-chat';
        const viewDirectTeamGroup = selectedParentNav === 'chat' && selectedInboxSubMenuNav === 'user';
        className = cx('app-navigation__nav__button', {
          [UtilitySystem.config.classes.active]: viewDirectTeamGroup,
        });
        dataCypress = 'nav-inbox-direct-chat';
      } else if (group.userGroupId) {
        id = `nav-chat-${group.userGroupId}`;
        className = 'app-navigation__nav__button';
        dataCypress = `nav-inbox-chat-${group.userGroupId}`;
      }
    }

    return (
      <li key={key} className="app-navigation__nav__sub">
        <NavLink // eslint-disable-line jsx-a11y/anchor-is-valid
          id={id}
          data-cypress={dataCypress}
          to={link}
          exact
          onClick={handleNavigationClick}
          className={className}
          activeClassName={UtilitySystem.config.classes.active}
        >
          <span className="app-navigation__nav__button__text">{group.displayName}</span>
          {section === 'chat' && group.type === 'direct' ? (
            ((group.unreadUserIds?.length > 0) || (group.unreadGroupIds?.length > 0)) &&
            isBadgeCountVisible(section, group) && (
              <span className="app-navigation__nav__button__badge" data-cypress="badge-count">
                {[...group.unreadUserIds, ...group.unreadGroupIds].length}
              </span>
            )
          ) : (
            group.unreadUserIds?.length > 0 && isBadgeCountVisible(section, group) && (
              <span className="app-navigation__nav__button__badge" data-cypress="badge-count">{group.unreadUserIds.length}</span>
            )
          )}
        </NavLink>
      </li>
    );
  };

  const shouldDisplayCcrNav = () => {
    const ccrNavPaths = ['/selectorg', '/accountsetup', '/systemalert'];
    return ccrNavPaths.indexOf(window.location.pathname) > -1;
  };

  const renderMemberInboxNav = () => {
    const mentionBadgeCount = getMentionBadgeCount(mentionsSection);
    return (
      <ul className="app-navigation__nav">
        <li className="app-navigation__nav__header">
          <span className="app-navigation__nav__header__text">INBOX</span>
        </li>
        <div className="app-navigation__list-item__divider" />
        {hasAnyInboxViewPermissions() && (
          <li className="app-navigation__nav__header">
            <span className="app-navigation__nav__button__text">My Tasks</span>
          </li>
        )}
        {hasContactThreadViewPermissions() && (
          <>
            <li className="app-navigation__nav__sub">
              <NavLink // eslint-disable-line jsx-a11y/anchor-is-valid
                id="nav-inbox"
                data-cypress="nav-inbox-assigned"
                to="/inbox"
                exact
                onClick={() => handleNavigationClick('asigned-to-me')}
                activeClassName={UtilitySystem.config.classes.active}
                className={`app-navigation__nav__button ${selectedParentNav === 'inbox' &&
               selectedInboxSubMenuNav === 'user' ? UtilitySystem.config.classes.active : ''}`}
              >
                <span className="app-navigation__nav__button__text">Assigned to Me</span>
                {assignedSection?.unreadUserIds?.length > 0 && isBadgeCountVisible('inbox', assignedSection) && (
                <span className="app-navigation__nav__button__badge" data-cypress="badge-count">
                  {assignedSection.unreadUserIds.length}
                </span>
                )}
              </NavLink>
            </li>
            <li className="app-navigation__nav__sub">
              <NavLink // eslint-disable-line jsx-a11y/anchor-is-valid
                id="nav-inbox-mentions"
                to="/inbox/mentions"
                data-cypress="mentionsInboxLink"
                onClick={() => handleNavigationClick('mentions')}
                className={`app-navigation__nav__button ${selectedInboxSubMenuNav === 'mentions' ? UtilitySystem.config.classes.active : ''}`}
              >
                <span className="app-navigation__nav__button__text">@ Mentions</span>
                {mentionBadgeCount > 0 &&
                <span className="app-navigation__nav__button__badge" data-cypress="badge-count">{mentionBadgeCount}</span>}
              </NavLink>
            </li>
            <li className="app-navigation__nav__sub">
              <NavLink // eslint-disable-line jsx-a11y/anchor-is-valid
                id="nav-inbox-following"
                data-cypress="nav-inbox-following"
                to="/inbox/following"
                onClick={() => handleNavigationClick('following')}
                activeClassName={UtilitySystem.config.classes.active}
                className={`app-navigation__nav__button ${selectedInboxSubMenuNav === 'following' ? UtilitySystem.config.classes.active : ''}`}
              >
                <span className="app-navigation__nav__button__text">Following</span>
                {followingSection?.unreadUserIds?.length > 0 && isBadgeCountVisible('inbox', followingSection) && (
                <span className="app-navigation__nav__button__badge" data-cypress="badge-count">
                  {followingSection.unreadUserIds.length}
                </span>
                )}
              </NavLink>
            </li>
          </>
        )}
      </ul>
    );
  };

  return (
    <nav id="app-navigation" className="app-navigation">
      {shouldDisplayCcrNav() && renderCcrNav()}
      {currentUser && !shouldDisplayCcrNav() && (
        <div className="app-navigation__container">
          <div className="app-navigation__top">
            {!isCCR(currentUser) && (renderMemberInboxNav())}
            {hasContactThreadViewPermissions() && (
            <ul className="app-navigation__nav">
              <li className="app-navigation__nav__header">
                <span className="app-navigation__nav__button__text">
                  {inboxSection.length ? GroupHelpers.formatGroupType(Types.TYPE_GROUP_INBOX) : AppConstants.INTERNAL_ROLES[1]} Messages
                </span>
                {!isCCR(currentUser) && <GroupSortMenu section="inbox" />}
              </li>
                {sortGroupsByPref(inboxSection, 'inbox').map((g, idx) => renderGroup(g, idx, 'inbox'))}
            </ul>
            )}
            {!isCCR(currentUser) && hasTeamThreadViewPermissions() && (
            <ul className="app-navigation__nav">
              <li className="app-navigation__nav__header">
                <span className="app-navigation__nav__button__text">{GroupHelpers.formatGroupType(Types.TYPE_GROUP_CHAT)} Messages</span>
                {!isCCR(currentUser) && <GroupSortMenu section="chat" />}
              </li>
              {sortGroupsByPref(chatSection, 'chat').map((g, idx) => renderGroup(g, idx, 'chat'))}
            </ul>
            )}
          </div>
          <div className="app-navigation__bottom">
            {currentUser.organization && (
            <div className="app-navigation__org">
              {organizations[currentUser.organization].name}
              <span>Organization{isCCR(currentUser) && ` (#${organizations[currentUser.organization].id})`}</span>
            </div>
            )}
            <MultiOrgContainer history={history} />
          </div>
        </div>
      )}
    </nav>
  );
};

Navigation.propTypes = {
  assignedSection: PropTypes.object,
  chatSection: PropTypes.array,
  currentUser: PropTypes.object,
  myUsersRaw: PropTypes.array.isRequired,
  followingSection: PropTypes.object,
  handleNavigationClick: PropTypes.func,
  inboxSection: PropTypes.array,
  organizations: PropTypes.object,
  mentionsSection: PropTypes.object,
  selectedInboxSubMenuNav: PropTypes.string,
  selectedParentNav: PropTypes.string,
  selectedSubMenuNav: PropTypes.string,
};

export default Navigation;
