import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { updateArrayById, isObject } from '../helpers/DataHelpers';
import { CONTACT_FILTERS, CONTACT_FILTERS_INITIAL_STATE } from '../constants/ContactsConstants';
import { BULK_TAG_MAX_CONTACTS } from '../constants/AppConstants';
import { useLazyGetSearchQuery, useGetSearchQuery } from '../services/searchService';
import { getRhinoblastMaxPageSize } from '../selectors/organizationSelectors';

const useContactFilters = (shouldSkip = false) => {
  const contactPageSize = useSelector(getRhinoblastMaxPageSize);
  const initialState = { ...CONTACT_FILTERS_INITIAL_STATE, pageSize: contactPageSize };
  const [filters, setFilters] = useState(initialState);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const searchPayload = formatRequestData();
  const searchResult = useGetSearchQuery(searchPayload, { skip: shouldSkip });
  const { data: searchData, isLoading } = searchResult;
  const [fetchAllContacts, { isFetching }] = useLazyGetSearchQuery();

  async function fetchAllResults(nextPageNumber, searchAfter) {
    const result = await fetchAllContacts(formatRequestData({ pageNo: nextPageNumber,
      fetchAllRecords: true,
      size: BULK_TAG_MAX_CONTACTS,
      pageSize: BULK_TAG_MAX_CONTACTS,
      ...searchAfter?.length > 0 && {
        searchAfter: JSON.stringify(searchAfter),
      } }), true);
    return result;
  }

  function formatRequestData(changeObj = {}) {
    const {
      birthday: {
        range: birthdayRange,
        date: birthdayDate,
      },
      hipaa: {
        range: hipaaRange,
        date: hipaaDate,
      },
      hipaaStatus,
      idsToExclude,
      includeInactiveAndDeleted,
      marketing: {
        range: marketingRange,
        date: marketingDate,
      },
      marketingStatus,
      pageNo,
      pageSize = contactPageSize,
      tagIds = [],
      vCard: {
        id: vCardId,
        status: vCardStatus,
      },
      name,
      phone,
      userType,
    } = filters;
    const params = {
      birthdayRange: birthdayDate ? birthdayRange : null,
      birthdayDate,
      hipaaRange: hipaaDate ? hipaaRange : null,
      hipaaDate,
      hipaaStatus,
      idsToExclude,
      includeInactiveAndDeleted,
      marketingRange: marketingDate ? marketingRange : null,
      marketingDate,
      marketingStatus,
      name: name?.length > 1 ? name : null,
      phone,
      pageSize,
      pageNo,
      tagIds: tagIds.length > 0 ? tagIds : null,
      userType,
      vCardId: vCardId || null,
      vCardStatus: vCardId ? vCardStatus : null,
    };
    const payload = {
      pageSize: contactPageSize,
      size: contactPageSize,
      pageNo: 0,
      countTotalRecords: true,
      scope: 'allContacts',
      ...params,
      ...changeObj,
    };
    return payload;
  }

  useEffect(() => {
    setFiltersApplied(!isEqual(filters, initialState));
  }, [filters]);

  const clearAllFilters = () => {
    setFilters(initialState);
    setFiltersApplied(false);
  };

  const handleSetFilters = (changeObj) => {
    setFilters((current) => ({
      ...current,
      ...changeObj,
    }));
  };

  const handleChange = (name, value) => {
    if (!name) {
      return;
    }

    let updateValue = null;
    if ([CONTACT_FILTERS.Id, CONTACT_FILTERS.Name].includes(name)) {
      handleSetFilters({ [name]: value });
      updateValue = value.length > 2 ? value : '';
      if (value.length < 2 && filters[name].length < 2) {
        return;
      }
    } else if (name === CONTACT_FILTERS.TagIds) {
      if (Array.isArray(value)) {
        updateValue = value;
      } else {
        updateValue = updateArrayById(filters[name], value);
      }
      handleSetFilters({ [name]: updateValue });
    } else {
      const currentObj = filters[name];
      const valueIsObject = isObject(value);
      updateValue = isObject(value) ? {
        ...currentObj,
        ...value,
      } : value;
      handleSetFilters({ [name]: updateValue });
      if (valueIsObject && ((currentObj.range && !updateValue.date) || (currentObj.status && !updateValue.id))) {
        return;
      }
    }
    setFiltersApplied(true);
  };

  return { fetchAllResults, filters, filtersApplied, handleSetFilters, clearAllFilters, handleChange, searchData, isFetching, isLoading };
};

export default useContactFilters;
