import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import BackButtonContainer from '../containers/BackButtonContainer';
import UpdatePasswordFormContainer from '../containers/UpdatePasswordFormContainer';

const SecureUserProfile = (props) => {
  const history = useHistory();
  const location = useLocation();
  const {
    loginEmail,
  } = props;

  return (
    <div className="app-page__container">
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title">
            <BackButtonContainer history={history} location={location} />
            My Profile
          </div>
        </div>

        <div className="box">
          <div className="box__title-wrapper">
            <div className="box__title">Login Information</div>
          </div>
          <div className="form__group">
            <div className="row">
              <div className="column-7@xsmall">
                <p className="u-font-weight-bold">Email Address</p>
                <p className="u-text-normal">{loginEmail}</p>
              </div>
            </div>
          </div>
          <UpdatePasswordFormContainer />
        </div>
      </div>
    </div>
  );
};

SecureUserProfile.propTypes = {
  loginEmail: PropTypes.string,
};

export default SecureUserProfile;
