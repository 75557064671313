import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import * as queryString from 'query-string';
import RhinoPayManager from '../components/RhinoPayManager';
import { organizationSelectors } from '../selectors';
import { fetchRhinoPayRequests } from '../reducers/payReducer';
import { RHINOPAY_REQUESTS_PAGE_SIZE } from '../constants/AppConstants';
import PageLoader from '../components/PageLoader';
import { cloneDeep } from '../helpers/DataHelpers';
import RhinopayManagerConstants from '../constants/RhinopayManagerConstants';

class RhinoPayManagerContainer extends Component {
  state = {
    isValidDate: true,
    isNextDisabled: false,
    isPreviousDisabled: true,
    pageItemIndexTo: RHINOPAY_REQUESTS_PAGE_SIZE,
    pageItemIndexFrom: 1,
    pageNo: 0,
    totalPageCount: 0,
    pageSize: RHINOPAY_REQUESTS_PAGE_SIZE,
    activeKey: 3,
    startDate: moment().subtract(6, 'days'),
    endDate: moment(),
    minDate: moment().subtract(1, 'year').add(1, 'days'),
    maxDate: moment(),
    datePickerDropdownLabels: [{ id: 1, label: 'Today', duration: 0 }, { id: 2, label: 'Yesterday', duration: 1 }, { id: 3, label: 'Last 7 Days', duration: 6 },
      { id: 4, label: 'Last 30 Days', duration: 29 }, { id: 5, label: 'Last 90 Days', duration: 89 },
      { id: 6, label: 'Last 12 Months', duration: 'year' }, { id: 7, label: 'Custom Date' }],
    statuses: [{ id: 'Sent', value: 'Sent' }, { id: 'Paid', value: 'Paid' }, { id: 'Cancelled', value: 'Cancelled' }],
    selectedStatus: '',
    isDateSelected: false,
    smartTableHeadersConfig: cloneDeep(RhinopayManagerConstants),
    sortBy: 'createdAt',
    sortOrder: 'desc',
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const organizationId = this.props.activeOrg && this.props.activeOrg.id;
    const pageNo = query.pageNo ? Number(query.pageNo) : this.state.pageNo;
    const activeKey = query.activeKey ? Number(query.activeKey) : this.state.activeKey;
    const pageSize = query.pageSize ? Number(query.pageSize) : this.state.pageSize;
    const startDate = query.from ? moment(query.from) : this.state.startDate;
    const endDate = query.to ? moment(query.to) : this.state.endDate;
    const selectedStatus = query.status ? query.status : this.state.selectedStatus;
    const sortBy = query.sortBy ? query.sortBy : this.state.sortBy;
    const sortOrder = query.sortOrder ? query.sortOrder : this.state.sortOrder;
    const headerConstants = cloneDeep(RhinopayManagerConstants);
    const sortByValue = this.getSortByValue(sortBy);
    const sortOrderValue = sortOrder === 'asc' ? 1 : -1;

    if (sortBy !== 'createdAt') {
      headerConstants.createdAt.default = false;
      headerConstants.createdAt.direction = 1;
    }
    headerConstants[sortByValue].default = true;
    headerConstants[sortByValue].direction = sortOrderValue;

    this.setState({
      pageNo,
      pageSize,
      activeKey,
      startDate,
      endDate,
      selectedStatus,
      sortBy,
      sortOrder,
      smartTableHeadersConfig: headerConstants,
    });
    this.props.fetchRhinoPayRequests({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      status: selectedStatus,
      pageNo,
      pageSize,
      sortBy,
      sortOrder }, organizationId);
  }

  getSortByValue = (key) => {
    if (key === 'requestAmount') {
      return 'amount';
    } else {
      return key;
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.totalRhinoPayRequests > 0) {
      const pageItemIndexTo = prevState.pageNo * prevState.pageSize + nextProps.rhinoPayRequests.length;
      const totalPageCount = Math.ceil(nextProps.totalRhinoPayRequests / prevState.pageSize);

      return {
        pageItemIndexTo,
        pageItemIndexFrom: 1 + prevState.pageNo * prevState.pageSize,
        isPreviousDisabled: prevState.pageNo === 0,
        isNextDisabled: prevState.pageNo + 1 >= totalPageCount,
        totalPageCount,
      };
    }
    return null;
  }

  handleChange = (status) => {
    const organizationId = this.props.activeOrg && this.props.activeOrg.id;
    const { pageSize, activeKey, startDate, endDate, sortBy, sortOrder } = this.state;
    const filters = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      status,
      pageNo: 0,
      pageSize,
      sortBy,
      sortOrder,
    };
    const queryParams = `from=${filters.startDate}&to=${filters.endDate}&pageNo=0&pageSize=${pageSize}&activeKey=${activeKey}&status=${status}&sortBy=${filters.sortBy}&sortOrder=${filters.sortOrder}`; // eslint-disable-line max-len

    this.setState({
      selectedStatus: status,
      pageNo: 0,
    });
    this.props.fetchRhinoPayRequests(filters, organizationId);
    this.props.history.push(`/managers/payment-requests?${queryParams}`);
  }

  selectDate = (date) => {
    const organizationId = this.props.activeOrg && this.props.activeOrg.id;
    const { pageSize, selectedStatus, sortBy, sortOrder } = this.state;
    const { startDate, endDate, activeKey } = date;
    const filters = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      status: selectedStatus,
      pageNo: 0,
      pageSize,
      sortBy,
      sortOrder,
    };
    const queryParams = `from=${filters.startDate}&to=${filters.endDate}&pageNo=0&pageSize=${pageSize}&activeKey=${activeKey}&status=${selectedStatus}&sortBy=${filters.sortBy}&sortOrder=${filters.sortOrder}`; // eslint-disable-line max-len

    if (date.startDate > date.endDate) {
      this.setState({ isValidDate: false });
    } else {
      this.setState({
        activeKey: date.activeKey,
        isValidDate: true,
        startDate: date.startDate,
        endDate: date.endDate,
        isDateSelected: true,
        pageNo: 0,
      });
      this.props.history.push(`/managers/payment-requests?${queryParams}`);
      this.props.fetchRhinoPayRequests(filters, organizationId);
    }
  };

  calculateDateRange = (duration, selectedLabelIndex) => {
    let fromDate = moment().subtract(duration, 'days');
    let toDate = moment();

    if (selectedLabelIndex === 2) {
      toDate = moment().subtract(1, 'days');
    }
    if (duration === 'year') {
      fromDate = moment().subtract(1, 'years').add(1, 'days');
    }
    return { startDate: fromDate, endDate: toDate };
  }

  clearAllFilters = () => {
    const organizationId = this.props.activeOrg && this.props.activeOrg.id;
    const startDate = moment().subtract(6, 'days');
    const endDate = moment();
    this.setState({
      isDateSelected: false,
      activeKey: 3,
      startDate,
      endDate,
      selectedStatus: '',
      pageNo: 0,
      sortBy: 'createdAt',
      sortOrder: 'desc',
      smartTableHeadersConfig: cloneDeep(RhinopayManagerConstants),
    });
    const filters = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      status: '',
      pageNo: 0,
      pageSize: RHINOPAY_REQUESTS_PAGE_SIZE,
      sortBy: 'createdAt',
      sortOrder: 'desc',
    };
    const queryParams = `from=${filters.startDate}&to=${filters.endDate}&pageNo=0&pageSize=${this.state.pageSize}&activeKey=${3}&status=${filters.status}&sortBy=${filters.sortBy}&sortOrder=${filters.sortOrder}`; // eslint-disable-line max-len
    this.props.history.push(`/managers/payment-requests?${queryParams}`);
    this.props.fetchRhinoPayRequests(filters, organizationId);
  }

  navigateToConversation = (userId) => {
    this.props.history.push(`/inbox/all/user/${userId}`);
  };

  handlePagination = (direction) => {
    const organizationId = this.props.activeOrg && this.props.activeOrg.id;
    let pageNumber = this.state.pageNo;
    const { pageSize, activeKey, selectedStatus, sortBy, sortOrder } = this.state;
    const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
    const endDate = moment(this.state.endDate).format('YYYY-MM-DD');

    if (direction === 'previous') {
      if (pageNumber >= 1) {
        pageNumber -= 1;
        this.setState({ pageNo: pageNumber });
      }
    } else {
      if (pageNumber + 1 < this.state.totalPageCount) { // eslint-disable-line no-lonely-if
        pageNumber += 1;
        this.setState({ pageNo: pageNumber });
      }
    }

    const queryParams = `from=${startDate}&to=${endDate}&pageNo=${pageNumber}&pageSize=${pageSize}&activeKey=${activeKey}&status=${selectedStatus}&sortBy=${sortBy}&sortOrder=${sortOrder}`; // eslint-disable-line max-len
    this.setState({
      isPreviousDisabled: pageNumber === 0,
      isNextDisabled: pageNumber + 1 >= this.state.totalPageCount,
    });
    this.props.history.push(`/managers/payment-requests?${queryParams}`);
    this.props.fetchRhinoPayRequests({
      pageNo: pageNumber,
      status: selectedStatus,
      startDate,
      endDate,
      pageSize,
      sortBy,
      sortOrder,
    }, organizationId);
  }

  sortTable = (state) => {
    const organizationId = this.props.activeOrg && this.props.activeOrg.id;
    const { sorted } = state;
    const { pageSize, activeKey, startDate, endDate, selectedStatus } = this.state;

    if (sorted && sorted.length > 0) {
      let sortBy = sorted[0].id;

      let sortOrder;
      if (sortBy === 'amount') {
        sortBy = 'requestAmount';
      }
      if (this.state.sortBy !== sortBy) {
        sortOrder = 'asc';
      } else {
        sortOrder = this.state.sortOrder === 'asc' ? 'desc' : 'asc';
      }
      const filters = {
        pageNo: 0,
        status: selectedStatus,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        pageSize,
        activeKey,
        sortBy,
        sortOrder,
      };
      const queryParams = `from=${filters.startDate}&to=${filters.endDate}&pageNo=0&pageSize=${filters.pageSize}&activeKey=${filters.activeKey}&status=${filters.status}&sortBy=${filters.sortBy}&sortOrder=${filters.sortOrder}`; // eslint-disable-line max-len
      this.props.history.push(`/managers/payment-requests?${queryParams}`);
      this.props.fetchRhinoPayRequests(filters, organizationId);
      this.setState({
        sortBy,
        sortOrder,
        pageNo: 0,
      });
    }
  }

  render() {
    if (this.props.isRhinoPayManagerLoading) {
      return <PageLoader />;
    }

    const props = {
      startDate: moment(this.state.startDate),
      endDate: moment(this.state.endDate),
      minDate: this.state.minDate,
      maxDate: this.state.maxDate,
      activeKey: this.state.activeKey,
      datePickerDropdownLabels: this.state.datePickerDropdownLabels,
      isValidDate: this.state.isValidDate,
      selectDate: this.selectDate,
      calculateDateRange: this.calculateDateRange,
      statuses: this.state.statuses,
      selectedStatus: this.state.selectedStatus,
      handleChange: this.handleChange,
      isDateSelected: this.state.isDateSelected,
      clearAllFilters: this.clearAllFilters,
      navigateToConversation: this.navigateToConversation,
      pageItemIndexTo: this.state.pageItemIndexTo,
      pageItemIndexFrom: this.state.pageItemIndexFrom,
      pageNo: this.state.pageNo,
      isNextDisabled: this.state.isNextDisabled,
      isPreviousDisabled: this.state.isPreviousDisabled,
      handlePagination: this.handlePagination,
      totalPageCount: this.state.totalPageCount,
      rhinoPayRequests: this.props.rhinoPayRequests,
      totalRhinoPayRequests: this.props.totalRhinoPayRequests,
      smartTableHeadersConfig: this.state.smartTableHeadersConfig,
      sortTable: this.sortTable,
    };
    return (
      <RhinoPayManager {...props} />
    );
  }
}

RhinoPayManagerContainer.propTypes = {
  totalRhinoPayRequests: PropTypes.number.isRequired,
  rhinoPayRequests: PropTypes.array.isRequired,
  activeOrg: PropTypes.object.isRequired,
  isRhinoPayManagerLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  fetchRhinoPayRequests: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  activeOrg: organizationSelectors.getCurrentOrg(state),
  rhinoPayRequests: state.pay.rhinoPayRequests,
  totalRhinoPayRequests: state.pay.totalRhinoPayRequests,
  isRhinoPayManagerLoading: state.pay.isRhinoPayManagerLoading,
});

const actions = {
  fetchRhinoPayRequests,
};

export default connect(mapStateToProps, actions)(RhinoPayManagerContainer);
