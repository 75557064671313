import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as AuthReducer from '../reducers/authReducer';
import { userSelectors } from '../selectors';
import StorageService from '../services/StorageService';
import MultiOrg from '../components/MultiOrg';

class MultiOrgContainer extends Component {
  resetUser = () => {
    this.props.unsetUserAndOrg();
    this.props.history.push('/selectorg');
  }

  render() {
    const showMultiOrg = StorageService.readEntry('multiOrgs')
      && !StorageService.readEntry('patientExperience')
      && window.location.pathname !== '/selectorg'
      && this.props.currentUser;

    const props = {
      resetUser: this.resetUser,
    };

    return showMultiOrg ? <MultiOrg {...props} /> : null;
  }
}

MultiOrgContainer.propTypes = {
  currentUser: PropTypes.object,
  unsetUserAndOrg: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: userSelectors.getLoggedInUser(state),
});

const actions = {
  unsetUserAndOrg: AuthReducer.unsetUserAndOrg,
};

export default connect(mapStateToProps, actions)(MultiOrgContainer);
