import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  Scrollbars,
  UtilityInlineGrid,
  ResourceGroup,
  Resource,
  ResourceIntro,
  ResourceRight,
} from 'rhinostyle';

import { RoleHelpers, UserHelpers } from '../helpers';
import { AppConstants } from '../constants';
import { ROLE_EDIT } from '../constants/UserPermissionsConstants';
import { sortComparatorUsingUserLastNameFirstName } from '../helpers/DataHelpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';

const renderMember = (id, key, users, handleMemberClick) => {
  const user = users[id];
  const { onlineStatus } = user; // This property is being set explicitly in the WebSocketService and is not part of the User response object
  const profileImageUrl = user.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${user.profileImageUrl}` : '';
  const avatar = {
    name: UserHelpers.formatAvatarName(user.firstName, user.lastName),
    type: 'member',
    image: profileImageUrl,
    showOnlineStatus: true,
    onlineStatus,
  };

  return (
    <Resource key={key} onClick={() => handleMemberClick(user.id)}>
      <ResourceIntro title={UserHelpers.formatMemberNameWithPrefixAndSuffix(user)} avatar={avatar} />
      {!user.active && <ResourceRight><span className="u-text-accent">Deactivated</span></ResourceRight>}
    </Resource>
  );
};

class OrganizationRolesPanel extends Component {
  state = {
    viewAllMembers: false,
    viewAllPermissions: false,
  }

  componentDidUpdate(prevProps) {
    const activeRoleChanged = this.props.activeRole !== prevProps.activeRole;

    if (activeRoleChanged && this.state.viewAllPermissions === true) {
      this.setState({ viewAllPermissions: false }); // eslint-disable-line
    }
  }

  render() {
    const {
      activeRole,
      handleEditClick,
      handleMemberClick,
      handleTogglePanel,
      isCcr,
      users,
    } = this.props;

    let roleIds = [...activeRole.users].sort(sortComparatorUsingUserLastNameFirstName(this.props.users));
    roleIds = this.state.viewAllMembers ? roleIds : roleIds.slice(0, 6);

    // eslint-disable-next-line max-len
    const formattedPermissions = Object.values(activeRole.permissions).map((permission) => (RoleHelpers.formatPermission(permission, this.props))).flat();
    const permissions = this.state.viewAllPermissions ? formattedPermissions : formattedPermissions.slice(0, 6);
    // Provider Role is not editable for member only CCR can edit this role.
    const isEditable = (!isCcr && activeRole.name === 'Provider') ? false : true; // eslint-disable-line no-unneeded-ternary

    return (
      <div className="summary-panel__wrapper">
        <div className="summary-panel">
          <div className="app-page__header">
            <div className="app-page__header__title">Summary</div>
            <div className="app-page__header__action">
              <Close
                className="app-page__header__action__close"
                onClick={() => handleTogglePanel(activeRole.id, activeRole.systemRole)}
              />
            </div>
          </div>
          <Scrollbars className="summary-panel__body">
            <div className="summary-panel__content">
              <div className="summary-panel__intro-wrapper">
                <div className="summary-panel__intro__title">
                  {activeRole.name}
                </div>
                <div className="summary-panel__intro__desc">{activeRole.description}</div>
                <div className="summary-panel__intro__actions">
                  <UtilityInlineGrid align="middle">
                    {userHasAnyOfPermissions([ROLE_EDIT]) && isEditable && (
                      <Button size="small" type="primary" onClick={() => handleEditClick(activeRole.id, activeRole.systemRole)} outlined>
                        Edit Role
                      </Button>
                    )}
                  </UtilityInlineGrid>
                </div>
              </div>
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">PERMISSIONS</div>
                <div className="summary-panel__section__content">
                  <ul className="u-list u-m-b-0 u-list--space">
                    {permissions}
                  </ul>
                </div>
              </div>
              {formattedPermissions.length > 6 && !this.state.viewAllPermissions && (
                <div className="u-m-b-small u-text-right">
                  <Button reset className="u-text-primary u-text-small" onClick={() => this.setState({ viewAllPermissions: true })}>
                    View All Permissions
                  </Button>
                </div>
              )}
              {activeRole.users && activeRole.users.length > 0 && (
                <div className="summary-panel__section summary-panel__section--full">
                  <div className="summary-panel__section__label">Members ({activeRole.users.length})</div>

                  <div className="summary-panel__section__content">
                    <ResourceGroup>
                      {roleIds.map((user, key) => renderMember(user, key, users, handleMemberClick))}
                    </ResourceGroup>
                  </div>
                  {activeRole.users.length > 6 && !this.state.viewAllMembers && (
                    <div className="u-m-t-small u-text-right">
                      <Button reset className="u-text-primary u-text-small" onClick={() => this.setState({ viewAllMembers: true })}>
                        View All Members
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

OrganizationRolesPanel.propTypes = {
  activeRole: PropTypes.object.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleMemberClick: PropTypes.func.isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
  isCcr: PropTypes.bool,
  users: PropTypes.object.isRequired,
};

export default OrganizationRolesPanel;
