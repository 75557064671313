import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Checkbox,
  CheckboxGroup,
  Alert,
  Tooltip,
} from 'rhinostyle';
import { categories } from '../constants/RoutingCategories';
import { useGetRoutesQuery } from '../services/routingService';

const RoutingCategories = (props) => {
  const {
    selectedCategoryIds,
    handleSelect,
    validationMessage,
    currentOrganizationId,
  } = props;
  const params = useParams();
  const { categoryRouteId } = params;
  const { data: categoryRoutes } = useGetRoutesQuery({ organizationId: currentOrganizationId });

  function getRouteName(selectedRoute, slice) {
    const joinString = selectedRoute?.textClassificationTypes?.length === 2 ? ' and ' : ', ';
    const formatted = selectedRoute?.textClassificationTypes.join(joinString);
    if (slice) {
      return `${formatted.slice(0, slice)}...`;
    }
    return formatted;
  }

  function renderCategoryCheckbox(category) {
    const usedCategory = categoryRoutes?.textclassificationroutes?.find((route) => {
      if (route.id === Number(categoryRouteId)) {
        return undefined;
      }
      return route.textClassificationTypes.includes(category.id);
    });
    const inUse = !!usedCategory;
    return (
      <div className="column-12 column-6@small checkbox-item" key={category.id}>
        <Checkbox
          name={category.name}
          onChange={() => handleSelect(category.id)}
          isChecked={selectedCategoryIds.includes(category.id)}
          key={category.id}
          disabled={inUse}
          dataFeatureTag={category.name}
          label={(
            <div className="form__block-group__label">{category.name}
            </div>
      )}
        />
        {inUse && (
        <div
          data-cypress={`${category.name}-in-use`}
          className="u-text-accent u-text-small checkbox-item__route"
        >
          <Tooltip content={getRouteName(usedCategory)}>
            <span>In use: {getRouteName(usedCategory, 20)}</span>
          </Tooltip>
        </div>
        )}
      </div>
    );
  }
  return (
    <div className="box form__group" name="selectedCategoryIds">
      <div className="box__title-wrapper">
        <div className="box__title">Categories</div>
        <div className="box__subtitle">The following categories will be used to route incoming messages</div>
      </div>
      <CheckboxGroup
        unstyled
        name="selectedCategoryIds"
        className="checkbox-columns"
      >
        {categories.map((category) => renderCategoryCheckbox(category))}
      </CheckboxGroup>
      {validationMessage && (
        <Alert type="danger" className="u-m-t">
          {validationMessage}
        </Alert>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentOrganizationId: state.auth.currentOrg,
});

RoutingCategories.propTypes = {
  selectedCategoryIds: PropTypes.array,
  handleSelect: PropTypes.func,
  validationMessage: PropTypes.string,
  currentOrganizationId: PropTypes.number,
};

export default connect(mapStateToProps)(RoutingCategories);
