import moment from 'moment-timezone';
import { MetaTypeService } from 'rhinotilities/lib/core/services/MetaTypeService';
import { MessageAttachmentType } from 'rhinotilities/lib/core/enums/MessageAttachmentType';
import {
  TYPE_APPOINTMENT_NOTIFICATION,
  TYPE_EVENT_MESSAGE,
  TYPE_EVENT_NOTE,
  TYPE_EVENT_SECURE,
  TYPE_EVENT_HIPAA_CONSENT,
  TYPE_EVENT_ASSIGNMENT,
  TYPE_EVENT_RHINOPAY_CONSENT,
  TYPE_EVENT_MARKETING_CONSENT,
  TYPE_VIDEO_CHAT_COMPLETED,
  TYPE_SAVED_CONTENT_SENT,
  TYPE_EVENT_FORM,
  TYPE_CALL_COMPLETED,
  TYPE_MERGE_CONTACT,
  TYPE_COPY_CONTENT,
  TYPE_EVENT_SAVED_CONTENT,
  TYPE_EVENT_APPOINTMENT_REMINDER,
  TYPE_EVENT_STATUS_DELIVERED,
  TYPE_EVENT_PRESCRIPTION_REMINDER,
  TYPE_EVENT_HIPAA_REQUEST,
  TYPE_EVENT_HIPAA_MANUAL_REQUEST,
} from '../constants/Types';
import { isStatusCodeError } from './ErrorCodeHelpers';
import { MessageStatus, EventModelType } from '../constants/v3';
import { formattedConnectionResultTypes } from '../constants/RhinocallConstants';
import { FORMS_STATUS_OPTIONS } from '../constants/RhinoformConstants';
import * as UserHelpers from './UserHelpers';
import { cloneDeep } from './DataHelpers';

export const getEventModelType = (event) => {
  const { typeId, modelType } = event;
  const isPrescriptionReminderEvent = modelType === EventModelType.PrescriptionReminder || typeId === TYPE_EVENT_PRESCRIPTION_REMINDER;
  const isHipaaRequestEvent = modelType === EventModelType.HipaaRequest || typeId === TYPE_EVENT_HIPAA_REQUEST;
  const isHipaaManualRequestEvent = modelType === EventModelType.HipaaManualRequest || typeId === TYPE_EVENT_HIPAA_MANUAL_REQUEST;
  const isAutomatedCampaignEvent = isHipaaRequestEvent ||
  isPrescriptionReminderEvent ||
  modelType === EventModelType.AppointmentReminder ||
  typeId === TYPE_EVENT_APPOINTMENT_REMINDER;
  const isStoryMention = event.attachments && shapeEventAttachments(event.attachments)?.some((attachment) => attachment.type === MessageAttachmentType.STORY_MENTION);
  const eventTypes = {
    isMessageEvent: (modelType === EventModelType.Message || typeId === TYPE_EVENT_MESSAGE || isAutomatedCampaignEvent || isHipaaManualRequestEvent) && !isStoryMention,
    isAppointmentNotificationEvent: modelType === EventModelType.AppointmentReminderNotification || typeId === TYPE_APPOINTMENT_NOTIFICATION,
    isNoteEvent: modelType === EventModelType.Note || typeId === TYPE_EVENT_NOTE,
    isHipaaEvent: modelType === EventModelType.HipaaConsent || typeId === TYPE_EVENT_HIPAA_CONSENT,
    isSavedContentEvent: modelType === EventModelType.SavedContent || typeId === TYPE_EVENT_SAVED_CONTENT,
    isSentSavedContentEvent: modelType === EventModelType.SavedContentSent || typeId === TYPE_SAVED_CONTENT_SENT,
    isRhinopayEvent: modelType === EventModelType.RhinopayConsent || typeId === TYPE_EVENT_RHINOPAY_CONSENT,
    isMarketingEvent: modelType === EventModelType.MarketingConsent || typeId === TYPE_EVENT_MARKETING_CONSENT,
    isAssignmentEvent: modelType === EventModelType.AssignmentEvent || typeId === TYPE_EVENT_ASSIGNMENT,
    isSecureEvent: modelType === EventModelType.SecureMessage || typeId === TYPE_EVENT_SECURE,
    isVideoChatEvent: modelType === EventModelType.VideoCall || typeId === TYPE_VIDEO_CHAT_COMPLETED,
    isMergeEvent: modelType === EventModelType.PatientMerge || typeId === TYPE_MERGE_CONTACT,
    isCopyContentEvent: modelType === EventModelType.CopyContent || typeId === TYPE_COPY_CONTENT,
    isRhinoformSendEvent: modelType === EventModelType.Rhinoform || typeId === TYPE_EVENT_FORM,
    isCallEvent: modelType === EventModelType.AudioCall || typeId === TYPE_CALL_COMPLETED,
    isStoryMention,
    isAutomatedCampaignEvent,
    isPrescriptionReminderEvent,
    isHipaaRequestEvent,
  };
  return Object.fromEntries(Object.entries(eventTypes).filter(([, value]) => value));
};

export function isSecureEvent(event) {
  if (!event) return false;
  return event.typeId === TYPE_EVENT_SECURE || event.modelType === EventModelType.SecureMessage;
}

export function isMessageEvent(event) {
  if (!event) return false;
  return event.typeId === TYPE_EVENT_MESSAGE || event.modelType === EventModelType.Message;
}

export function shapeEventAttachments(attachments = []) {
  if (Array.isArray(attachments) && attachments.length > 0) {
    return attachments.map((attachment) => ({
      ...attachment,
      shortLinkId: attachment.attachment,
      name: attachment.name || attachment.fileName,
      attachmentUrl: attachment.url || attachment.attachmentUrl,
      type: attachment.messageAttachmentType || attachment.type,
    }));
  } return [];
}

function getChannelId(event) {
  if (event.channelId) return event.channelId;
  const channel = event.channels?.[0];
  return channel?.id || channel;
}

function fromV2Event(v2Event) {
  const {
    optimisticId,
    id,
    sender,
    user,
    details,
    attachments,
    rhinoFormContent,
    incoming,
    status,
    statusTypeId,
    fromUserId,
    assignedByMemberId,
    memberId,
    error,
  } = v2Event;

  const isTempEvent = optimisticId === id;
  const senderId = sender?.id || sender;
  const recipient = user?.id || user;
  const event = {
    ...v2Event,
    ...details ?? {},
    attachments: shapeEventAttachments(attachments),
    channelId: getChannelId(v2Event),
    duration: details?.durationMs,
    eventUserId: incoming ? senderId : recipient,
    externalErrorCode: error,
    forms: rhinoFormContent,
    fromUserId: fromUserId || assignedByMemberId || memberId || senderId,
    isIncoming: !!incoming,
    isMessageFailed: status === MessageStatus.NetworkError || isStatusCodeError(statusTypeId),
    isMessageRead: v2Event.isRead,
    isNetworkError: isTempEvent && status === MessageStatus.NetworkError,
    isTempEvent: optimisticId === id,
    recipient,
    savedContentId: details?.savedContentItemId,
    senderId,
    status: status || MetaTypeService.toMessageStatus(statusTypeId),
  };
  return event;
}

function fromV3Event(v3Event) {
  const {
    attachments,
    duration,
    direction,
    fromUserId,
    toUserId,
    externalErrorCode,
    forms,
    status,
    read,
    optimisticId,
    id,
    modelVersion,
    savedContentId,
    assignedByMemberId,
    memberId,
  } = v3Event;
  const event = {
    ...v3Event,
    attachments: shapeEventAttachments(attachments),
    channelId: getChannelId(v3Event),
    duration,
    eventUserId: direction === 'In' ? fromUserId : toUserId,
    externalErrorCode,
    forms,
    isIncoming: direction === 'In',
    isMessageFailed: [MessageStatus.ConfirmedError, MessageStatus.Error, MessageStatus.NetworkError].includes(status),
    isMessageRead: read,
    isNetworkError: optimisticId === id && status === MessageStatus.NetworkError,
    isTempEvent: optimisticId === id,
    isV3Model: modelVersion === '3.0',
    recipient: toUserId,
    savedContentId,
    senderId: fromUserId || assignedByMemberId || memberId,
  };
  return event;
}

// TODO: Eventually shape these message types using typescript
export function shapeEventToV3(event) {
  if (!event) return null;
  const isV3Model = event.modelVersion === '3.0';
  const shapedEvent = isV3Model ? fromV3Event(event) : fromV2Event(event);
  const eventType = getEventModelType(event);
  if (eventType.isCallEvent) {
    const callStatus = shapedEvent.connectionResultTypeId
      ? formattedConnectionResultTypes[shapedEvent.connectionResultTypeId]?.description
      : shapedEvent.connectionResultType;
    shapedEvent.callStatus = callStatus;
  }

  if (eventType.isRhinoformSendEvent) {
    const formStatus = shapedEvent.formTypeId ? FORMS_STATUS_OPTIONS[shapedEvent.formTypeId] : shapedEvent.formStatus;
    shapedEvent.formStatus = formStatus;
  }

  if (eventType.isRhinopayEvent) {
    const consentStatus = shapedEvent.rhinopayConsentStatusTypeId ? UserHelpers.formatRhinoPayStatus(shapedEvent.rhinopayConsentStatusTypeId) : shapedEvent.consentStatus;
    shapedEvent.consentStatus = consentStatus;
  }
  if (eventType.isMarketingEvent) {
    const consentStatus = shapedEvent.marketingConsentStatusTypeId
      ? UserHelpers.formatMarketingStatus(shapedEvent.marketingConsentStatusTypeId)
      : shapedEvent.consentStatus;
    shapedEvent.consentStatus = consentStatus;
  }
  if (eventType.isHipaaEvent) {
    const consentStatus = shapedEvent.hipaaStatusTypeId ? UserHelpers.formatHipaaStatus(shapedEvent.hipaaStatusTypeId) : shapedEvent.consentStatus;
    shapedEvent.consentStatus = consentStatus;
  }

  if (eventType.SavedContentSent) {
    const isDelivered = isV3Model ? shapedEvent.status === MessageStatus.Delivered : shapedEvent.statusTypeId === TYPE_EVENT_STATUS_DELIVERED;
    shapedEvent.isDelivered = isDelivered;
  }
  return {
    ...shapedEvent,
    ...eventType,
  };
}

export function sortEventsByTimestamp(eventIds, events) {
  return cloneDeep(eventIds).sort((a, b) => moment(events[a].timestamp).diff(moment(events[b].timestamp)));
}
