import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Button } from 'rhinostyle';

import { getIsHost } from '../selectors/rhinovideoSelectors';
import { participantHangup, hostHangup } from '../reducers/rhinovideoReducer';

const RhinovideoHangupButton = (props) => {
  const { isHost } = props;
  const dispatch = useDispatch();

  const handleLeave = async () => {
    if (isHost) {
      dispatch(hostHangup());
    } else {
      dispatch(participantHangup());
    }
  };

  return (
    <div>
      <Button
        type="link"
        size="large"
        iconOnly
        title="End Call"
        onClick={handleLeave}
        className="rhinovideo__action-bar__hangup-button"
      >
        <Icon size="large" bump="down" icon="call-end" />
      </Button>
      <div className="rhinovideo__action-bar__label">
        <span>End</span>
      </div>
    </div>
  );
};

RhinovideoHangupButton.propTypes = {
  isHost: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const isHost = getIsHost(state);

  return {
    isHost,
  };
};

export default connect(mapStateToProps)(RhinovideoHangupButton);
