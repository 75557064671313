import React from 'react';
import { LoaderCircle } from 'rhinostyle';

const PageLoader = () => (
  <div className="app-page-loader">
    <LoaderCircle className="u-text-primary" />
  </div>
);

export default PageLoader;
