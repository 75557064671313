import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../helpers/AxiosHelper';
import { DEFAULT_PAGE_SIZE } from '../constants/AppConstants';
// Define our single API slice object
export const routingSlice = createApi({
  reducerPath: 'routing',
  baseQuery: axiosBaseQuery({
    baseURL: process.env.REACT_APP_V3_BASE_URL,
  }),
  tagTypes: ['CategoryRoutes'],
  endpoints: (builder) => ({
    getRoutes: builder.query({
      query: ({
        organizationId,
        page = 0,
        pageSize = DEFAULT_PAGE_SIZE,
        toMemberIds = [],
        toGroupIds = [],
        routingBehaviorTypes = [],
      }) => {
        let url = `/organizations/${organizationId}/text-classification-routes?page=${page}&pageSize=${pageSize}`;
        if (toMemberIds.length > 0) {
          url += `&toMemberIds=${toMemberIds}`;
        }
        if (toGroupIds.length > 0) {
          url += `&toGroupIds=${toGroupIds}`;
        }
        if (routingBehaviorTypes.length > 0) {
          url += `&routingBehaviorTypes=${routingBehaviorTypes}`;
        }
        return { url, method: 'get' };
      },
      providesTags: ['CategoryRoutes'],
    }),
    getRoute: builder.query({
      query: ({
        textClassificationRouteIds,
        organizationId,
      }) => {
        const url = `/organizations/${organizationId}/text-classification-routes?textClassificationRouteIds=${encodeURIComponent(textClassificationRouteIds)}`;
        return { url, method: 'GET' };
      },
      providesTags: ['CategoryRoutes'],
    }),
    updateRoute: builder.mutation({
      query: ({
        data,
        textClassificationRouteId,
        organizationId,
      }) => {
        const url = `/organizations/${organizationId}/text-classification-routes/${textClassificationRouteId}`;
        return { url, method: 'PUT', data };
      },
      invalidatesTags: ['CategoryRoutes'],
    }),
    createRoute: builder.mutation({
      query: ({
        data,
        organizationId,
      }) => {
        const url = `/organizations/${organizationId}/text-classification-routes`;
        return { url, method: 'POST', data };
      },
      invalidatesTags: ['CategoryRoutes'],
    }),
    deleteRoute: builder.mutation({
      query: ({
        textClassificationRouteId,
        organizationId,
      }) => {
        const url = `/organizations/${organizationId}/text-classification-routes/${textClassificationRouteId}`;
        return { url, method: 'DELETE' };
      },
      invalidatesTags: ['CategoryRoutes'],
    }),
  }),
});

export const { useGetRoutesQuery, useGetRouteQuery, useUpdateRouteMutation, useCreateRouteMutation, useDeleteRouteMutation } = routingSlice;
