import React from 'react';
import OrganizationBillingUsageContainer from '../containers/OrganizationBillingUsageContainer';
import OrganizationBillingContactContainer from '../containers/OrganizationBillingContactContainer';
import OrganizationBillingPaymentContainer from '../containers/OrganizationBillingPaymentContainer';
import OrganizationBillingHistoryContainer from '../containers/OrganizationBillingHistoryContainer';

const OrganizationBilling = (props) => (
  <div className="app-page__container">
    <div className="app-page__container__inner">
      <div className="app-page__header">
        <div className="app-page__header__title">Billing &amp; Plan</div>
      </div>
      <OrganizationBillingUsageContainer {...props} />
      <div className="u-m-t-large">
        <div className="row row--large-gutter row--deep-match">
          <div className="column-6@medium">
            <OrganizationBillingContactContainer {...props} />
          </div>
          <div className="column-6@medium">
            <OrganizationBillingPaymentContainer {...props} />
          </div>
        </div>
      </div>
      <div className="u-m-t-large">
        <OrganizationBillingHistoryContainer {...props} />
      </div>
    </div>
  </div>
);

export default OrganizationBilling;
