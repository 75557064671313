import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import {
  Resource,
  ResourceIntro,
  ResourceGroup,
  Input,
  Icon,
  LoaderPulse,
} from 'rhinostyle';
import debounce from 'lodash.debounce';

import { fetchOrgsWithRhinogramChannels, fetchRhinogramOrgById, fetchRhinogramsOrgSearch } from '../reducers/rhinogramReducer';

const RhinogramsOrgSearch = (props) => {
  const {
    rhinogramOrgs,
    rhinogramOrgIds,
    rhinogramOrgId,
    rhinogramOrgsLoading,
    handleRhinogramChange,
    editMode,
    searchInputActive,
  } = props;

  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const debounceHandleFetchOrgSearch = useCallback(debounce(handleFetchOrgSearch, 300), []);

  useEffect(() => {
    if (editMode !== 'update') dispatch(fetchOrgsWithRhinogramChannels());
  }, []);

  useEffect(() => {
    if (rhinogramOrgId) dispatch(fetchRhinogramOrgById(rhinogramOrgId));
  }, [rhinogramOrgId]);

  function handleFetchOrgSearch(id, text) {
    if (text) {
      dispatch(fetchRhinogramsOrgSearch(text));
    } else {
      dispatch(fetchOrgsWithRhinogramChannels());
    }
    setSearchText(text);
  }

  const handleSelectOrg = (orgId) => {
    handleRhinogramChange('toOrganizationId', orgId);
  };

  const renderOrg = (org) => {
    const sub = `#${org.id}`;
    const title = org.name;

    return (
      <Resource selected={rhinogramOrgId === org.id} key={org.id} onClick={() => handleSelectOrg(org.id)} dataCypress={`ORGANIZATION_${org.id}`}>
        <ResourceIntro title={title} titleSub={sub} />
      </Resource>
    );
  };

  const handleIfNoResults = () => {
    if (searchInputActive) {
      return <span className="u-text-muted">No organizations found</span>;
    }
    const descending = rhinogramOrgIds.reduceRight((a, b) => a.concat(b), []);

    return <ResourceGroup className="u-m-b" interfaceMode="radio">{descending.map((id) => renderOrg(rhinogramOrgs[id]))}</ResourceGroup>;
  };

  const renderResults = () => (rhinogramOrgIds?.length > 0 ? (
    <ResourceGroup className="u-m-b" interfaceMode="radio">{rhinogramOrgIds.map((id) => renderOrg(rhinogramOrgs[id]))}</ResourceGroup>
  ) : (
    handleIfNoResults()
  ));

  return (
    <>
      {editMode !== 'update' && (
        <Input
          placeholder="Search organizations"
          addon="left"
          initialValue={searchText}
          onChange={debounceHandleFetchOrgSearch}
          size="large"
          name="search"
          className="search__input"
          autoComplete="off"
        >
          <Icon icon="search" />
        </Input>
      )}
      {rhinogramOrgsLoading ? (
        <div className="u-text-center">
          <LoaderPulse type="secondary" />
        </div>
      ) : (
        renderResults()
      )}
    </>
  );
};

RhinogramsOrgSearch.propTypes = {
  rhinogramOrgs: PropTypes.object,
  rhinogramOrgIds: PropTypes.array,
  rhinogramOrgId: PropTypes.number,
  rhinogramOrgsLoading: PropTypes.bool,
  handleRhinogramChange: PropTypes.func.isRequired,
  searchInputActive: PropTypes.bool,
  editMode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { organization, rhinogram } = state;
  return {
    rhinogramOrgId: rhinogram.rhinogramOrgId,
    rhinogramOrgIds: rhinogram.rhinogramOrgIds,
    rhinogramOrgs: rhinogram.rhinogramOrgs,
    rhinogramOrgsLoading: rhinogram.rhinogramOrgsLoading,
    searchInputActive: organization.searchInputActive,
  };
};

export default connect(mapStateToProps)(RhinogramsOrgSearch);
