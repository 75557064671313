import { useState, useEffect } from 'react';

export default function useOnScreen(ref, shouldObserve) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = shouldObserve && new IntersectionObserver(
    ([entry]) => {
      setIntersecting(entry.isIntersecting);
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0,

      /* required options */
      trackVisibility: true,
      delay: 100, // minimum 100
    },
  );

  useEffect(() => {
    if (observer) {
      if (!shouldObserve) {
        observer.disconnect();
      } else {
        observer.observe(ref?.current);
      }
    }
  }, [shouldObserve]);

  useEffect(() => () => {
    if (observer) {
      observer?.disconnect();
    }
  }, []);

  return isIntersecting;
}
