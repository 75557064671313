import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AppConstants } from '../constants';
import * as UserReducer from '../reducers/userReducer';
import ConnectedPartyItem from '../components/ConnectedPartyItem';
import { getActiveUser, userHasLimitedProviderRole } from '../selectors/userSelectors';

class ConnectedPartyItemContainer extends PureComponent {
  state = {
    isNoteTruncated: this.props.connection.note && this.props.connection.note.length > AppConstants.PROFILE_CONNECTED_PARTY_NOTE_TRUNCATE_LENGTH,
    isNoteAboveTruncateLength: this.props.connection.note && this.props.connection.note.length > AppConstants.PROFILE_CONNECTED_PARTY_NOTE_TRUNCATE_LENGTH,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.connection.note !== this.props.connection.note) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        isNoteTruncated: this.props.connection.note && this.props.connection.note.length > AppConstants.PROFILE_CONNECTED_PARTY_NOTE_TRUNCATE_LENGTH,
        isNoteAboveTruncateLength: this.props.connection.note && this.props.connection.note.length > AppConstants.PROFILE_CONNECTED_PARTY_NOTE_TRUNCATE_LENGTH,
      });
    }
  }

  toggleNoteTruncate = (e) => {
    // Prevent click from bleeding out to parent resource
    e.stopPropagation();

    this.setState({
      isNoteTruncated: !this.state.isNoteTruncated,
    });
  }

  handleConnectedPartyTransition = async (userId) => {
    const isContactPage = window.location.href.includes('contacts');

    await this.props.fetchUser(userId);

    if (isContactPage) {
      this.props.history.push(`/contacts/${userId}`);
    } else if (this.props.isLimitedProvider) {
      this.props.history.push(`/axium/inbox/user/${userId}`);
    } else {
      const { pathname } = this.props.history.location;
      const inboxLocation = pathname.substring(0, pathname.lastIndexOf('/'));

      this.props.history.push(`${inboxLocation}/${userId}`);
    }
  };

  render() {
    const props = {
      connection: this.props.connection,
      handleConnectedPartyTransition: this.handleConnectedPartyTransition,
      type: this.props.type,
      connectedPartyType: this.props.connectedPartyType,
      toggleNoteTruncate: this.toggleNoteTruncate,
      isNoteTruncated: this.state.isNoteTruncated,
      isNoteAboveTruncateLength: this.state.isNoteAboveTruncateLength,
      isRhinopayEnabled: this.props.isRhinopayEnabled,
      activeUser: this.props.activeUser,
    };
    return (
      <ConnectedPartyItem {...props} />
    );
  }
}

ConnectedPartyItemContainer.propTypes = {
  connection: PropTypes.object,
  profileOpen: PropTypes.bool,
  type: PropTypes.object,
  fetchUser: PropTypes.func,
  activeUser: PropTypes.object,
  isRhinopayEnabled: PropTypes.bool,
  history: PropTypes.object,
  connectedPartyType: PropTypes.object,
  isLimitedProvider: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { pay } = state;

  return {
    isRhinopayEnabled: pay.merchant.isRhinopayEnabled,
    activeUser: getActiveUser(state),
    isLimitedProvider: userHasLimitedProviderRole(state),
  };
};

const actions = {
  fetchUser: UserReducer.fetchUser,
};

export default connect(mapStateToProps, actions)(ConnectedPartyItemContainer);
