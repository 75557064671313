import React, { useState } from 'react';
import { Collapse, Icon } from 'rhinostyle';

const SummaryPanelSection = (props) => {
  const { label, children, isLoading, open = false, dataCypress } = props;
  const [isOpen, setIsOpen] = useState(open);
  if (!isLoading && !children) return null;
  return (
    <div className="summary-panel__section summary-panel__section--full">
      <div className="summary-panel__section__label">
        {label}
        <div className="summary-panel__section__label__action">
          <Icon
            icon={isOpen ? 'caret-up' : 'caret-down'}
            className="u-text-muted"
            onClick={() => setIsOpen((current) => !current)}
            data-cypress={dataCypress}
          />
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        {children}
      </Collapse>
    </div>
  );
};

export default SummaryPanelSection;
