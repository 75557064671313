import React from 'react';
import { Icon } from 'rhinostyle';
import { useInboxParams } from '../../hooks';
import { ASSIGNMENT_METHOD_AUTOMATIC } from '../../constants/AssignmentConstants';

const TextDivider = () => <span className="u-text-divider">|</span>;

const AssignmentEvent = ({ event }) => {
  const {
    suggestedGroupId,
    suggestedMemberId,
    suggestedTextClassificationType,
    assignedTextClassificationType,
    groupId,
    isComplete,
    formattedFromUserName,
    from,
    to,
    userId: toUserId,
    formattedTimestamp,
    assignmentMethod,
    isMarkedComplete,
  } = event;
  const { userId } = useInboxParams();

  const hasSuggestedRoute = suggestedTextClassificationType || suggestedGroupId || suggestedMemberId;
  const isAutoRouted = assignmentMethod === ASSIGNMENT_METHOD_AUTOMATIC;

  function isConfirmed() {
    if (assignedTextClassificationType === suggestedTextClassificationType) {
      if (groupId) {
        return groupId === suggestedGroupId;
      }
      if (userId) {
        return toUserId === suggestedMemberId;
      }
    }
    return false;
  }

  if (isAutoRouted) {
    return (
      <>
        <div className="convo__event__title" data-feature-tag="autoAssignmentEvent">
          <Icon bump="up" icon="assign" className="convo__event__icon convo__event__icon--assign" />
          Auto Routed
          <TextDivider />
          Category: <span className="u-font-weight-bold"> {assignedTextClassificationType}</span>
          <TextDivider />
          Assignment: <span className="u-font-weight-bold"> {to}</span>
        </div>
        <div><span className="convo__event__subtext u-m-l-small">{formattedTimestamp}</span></div>
      </>
    );
  } else {
    if (hasSuggestedRoute) {
      if (isConfirmed()) {
        return (
          <>
            <div className="convo__event__title">
              <Icon bump="up" icon="assign" className="convo__event__icon convo__event__icon--assign" />
              Confirmed Suggested Assign
              <TextDivider />
              Category:
              <span className="u-font-weight-bold"> {assignedTextClassificationType}</span>
              <TextDivider />
              Assign: <span className="u-font-weight-bold"> {to}</span>
            </div>
            <div>
              by {formattedFromUserName}
              <span className="convo__event__subtext u-m-l-small">{formattedTimestamp}</span>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="convo__event__title" data-feature-tag="manualAssignmentEvent">
            <Icon bump="up" icon="assign" className="convo__event__icon convo__event__icon--assign" />
            Manual Assigned to <strong>{to}</strong> by {formattedFromUserName}
          </div>
          <div className="convo__event__subtext">
            (from {from})&nbsp;&nbsp; {formattedTimestamp}
          </div>
        </>
      );
    }
    if (isComplete || isMarkedComplete) {
      const eventText = to ?
        <>Assignment Marked <strong>Complete</strong></> : <>Conversation <strong>Closed</strong></>;
      return (
        <>
          <div className="convo__event__title">
            <Icon bump="up" icon="checkmark" className="convo__event__icon convo__event__icon--complete" />
            {eventText} by {formattedFromUserName}
          </div>
          <div className="convo__event__subtext">
            {formattedTimestamp}
          </div>
        </>
      );
    }

    return (
      <>
        <div className="convo__event__title">
          <Icon bump="up" icon="assign" className="convo__event__icon convo__event__icon--assign" />
          Assigned to <strong>{to}</strong> by {formattedFromUserName}
        </div>
        <div className="convo__event__subtext">
          (from {from})&nbsp;&nbsp; {formattedTimestamp}
        </div>
      </>
    );
  }
};

export default AssignmentEvent;
