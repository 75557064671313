import React, { Component } from 'react';
import PropTypes from 'prop-types';

import OrganizationBillingUsage from '../components/OrganizationBillingUsage';
import { AddonHelpers } from '../helpers';

class OrganizationBillingUsageContainer extends Component { //eslint-disable-line
  constructor(props) {
    super(props);
    this.state = {
      pdfOpen: false,
      products: [],
    };
  }

  // eslint-disable-next-line camelcase
  componentDidMount() {
    const { subscriptionProducts } = this.props.subscription;
    const products = AddonHelpers.getSubscriptionProducts(subscriptionProducts);
    this.setState({ products });
  }

  handlePdfClick = () => {
    this.setState({
      pdfOpen: true,
    });
  }

  handlePdfViewerClose = () => {
    this.setState({
      pdfOpen: false,
    });
  }

  render() {
    const props = {
      ...this.props,
      handlePdfClick: this.handlePdfClick,
      pdfOpen: this.state.pdfOpen,
      handlePdfViewerClose: this.handlePdfViewerClose,
      draftInvoiceId: this.props.draftInvoiceId,
      products: this.state.products,
    };
    return (<OrganizationBillingUsage {...props} />);
  }
}

OrganizationBillingUsageContainer.propTypes = {
  subscription: PropTypes.object,
  amount: PropTypes.number,
  draftInvoiceId: PropTypes.number,
};

export default OrganizationBillingUsageContainer;
