import moment from 'moment-timezone';

import { exists } from './DataHelpers';
import { findDurationFromMilliseconds, formatTimestamp } from './DateHelpers';
import { capitalize, replaceUnderscoresWithSpaces } from './StringHelpers';
import { AuditLogConstants } from '../constants';

export function mapTypeIdWithValue(typeId, types) {
  const isTypesAvailable = types && Object.values(types).length;

  if (isTypesAvailable) return Object.values(types).find((type) => type.id === typeId);
  else return {};
}

export function mapStateCodeWithValue(stateCode, states) {
  const isStatesAvailable = states && Object.values(states).length;

  if (isStatesAvailable) return Object.values(states).find((state) => state.code === stateCode);
  else return {};
}

// shapes permission object's data and returns new actions, old actions and permission name
function shapePermissions(patch, key, operation) {
  const permissions = [];
  patch[key].forEach((permission) => {
    const hasPermission = Array.isArray(permission) && permission.length;
    // Check for permission added or removed in existing role
    if (hasPermission) {
      const formattedPermissionName = permission[1] && replaceUnderscoresWithSpaces(permission[1].name).toLowerCase();
      const isAddOperation = permission.find((item) => item === '+');
      const isDeleteOperation = permission.find((item) => item === '-');
      if (exists(formattedPermissionName)) {
        if (isAddOperation) {
          permissions.push({
            newActions: formattedPermissionName.slice(formattedPermissionName.lastIndexOf(' ') + 1),
            permissionName: formattedPermissionName.slice('0', formattedPermissionName.lastIndexOf(' ')),
          });
        } else if (isDeleteOperation) {
          permissions.push({
            oldActions: formattedPermissionName.slice(formattedPermissionName.lastIndexOf(' ') + 1),
            permissionName: formattedPermissionName.slice('0', formattedPermissionName.lastIndexOf(' ')),
          });
        }
      } // Check if a new role is added
    } else if (operation === 'Add') {
      const formattedPermissionName = permission && replaceUnderscoresWithSpaces(permission.name).toLowerCase();
      if (exists(formattedPermissionName)) {
        permissions.push({
          newActions: formattedPermissionName.slice(formattedPermissionName.lastIndexOf(' ') + 1),
          permissionName: formattedPermissionName.slice('0', formattedPermissionName.lastIndexOf(' ')),
        });
      }
    }
  });
  const formattedPermissionsObject = {};
  permissions.forEach((item) => {
    if (!exists(formattedPermissionsObject[item.permissionName])) {
      formattedPermissionsObject[item.permissionName] = { permissionName: item.permissionName };
    }
    if (item.newActions) {
      if (formattedPermissionsObject[item.permissionName].newActions) {
        formattedPermissionsObject[item.permissionName].newActions.push(item.newActions);
      } else {
        formattedPermissionsObject[item.permissionName].newActions = [];
        formattedPermissionsObject[item.permissionName].newActions.push(item.newActions);
      }
    }
    if (item.oldActions) {
      if (formattedPermissionsObject[item.permissionName].oldActions) {
        formattedPermissionsObject[item.permissionName].oldActions.push(item.oldActions);
      } else {
        formattedPermissionsObject[item.permissionName].oldActions = [];
        formattedPermissionsObject[item.permissionName].oldActions.push(item.oldActions);
      }
    }
  });

  return Object.values(formattedPermissionsObject);
}

function shapeBusinessHoursData(patch, currentData) {
  const arrayData = [];
  let day = -1;

  patch.forEach((element) => {
    // Business hour array contains '+' when user enable availability hours check
    if (Array.isArray(element) && element.length && element[0] === '+') {
      const availableDayIndex = arrayData.findIndex((data) => data.day === element[1].day);

      if (availableDayIndex > -1) {
        arrayData[availableDayIndex] = { ...arrayData[availableDayIndex], new: element[1] };
      } else {
        day += 1;
        arrayData.push({ operation: AuditLogConstants.AUDIT_LOGS_OPERATIONS.ADD, new: element[1], day: element[1].day });
      } // Business hour array contains '-' when user change close/open all day to From and to and vise versa
    } else if (Array.isArray(element) && element.length && element[0] === '-') {
      const availableDayIndex = arrayData.findIndex((data) => data.day === element[1].day);

      if (availableDayIndex > -1) {
        arrayData[availableDayIndex] = { ...arrayData[availableDayIndex], old: element[1] };
      } else {
        day += 1;
        arrayData.push({ operation: AuditLogConstants.AUDIT_LOGS_OPERATIONS.DELETE, old: element[1], day: element[1].day });
      } // Business hour array contains '~' when user change from and to values
    } else if (Array.isArray(element) && element.length && element[0] === '~') {
      if (exists(element[1]) && typeof element[1].day === 'object' && currentData && currentData.businessHours) {
        const currentBusinessHoursNew = currentData.businessHours.find((item) => item.day === element[1].day.__new);
        const currentBusinessHoursOld = { ...currentBusinessHoursNew };
        currentBusinessHoursOld.day = element[1].day.__old;
        const newDayIndex = arrayData.findIndex((data) => data.day === element[1].day.__new);
        const oldDayIndex = arrayData.findIndex((data) => data.day === element[1].day.__old);

        if (newDayIndex > -1) {
          arrayData[newDayIndex] = { ...arrayData[newDayIndex], new: currentBusinessHoursNew };
        } else {
          arrayData.push({ operation: AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT, new: currentBusinessHoursNew, day: element[1].day.__new });
        }
        if (oldDayIndex > -1) {
          arrayData[oldDayIndex] = { ...arrayData[oldDayIndex], old: currentBusinessHoursOld };
        } else {
          arrayData.push({ operation: AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT, old: currentBusinessHoursOld, day: element[1].day.__old });
        }
      } else {
        day += 1;
        arrayData.push({ operation: AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT, ...element[1], day });
      }
    } else day += 1;
  });

  arrayData.map((dayData, index) => {
    if (exists(dayData.new) && exists(dayData.old) && JSON.stringify(dayData.new) === JSON.stringify(dayData.old)) {
      arrayData.splice(index, 1);
    }
    return dayData;
  });
  return arrayData;
}

function handleDifferentKeyTypes(keyName, object, types, operation, currentData, index) {
  let displayValue = '';

  switch (keyName) {
    case AuditLogConstants.AUDIT_LOGS_TYPES.ROLES: {
      // Role name is getting updated.
      if (operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT) {
        let oldName = '';
        let newName = '';

        const { name } = object;
        oldName = exists(name) && `${name.__old}`;
        newName = exists(name) && `${name.__new}`;

        if (oldName || newName) {
          displayValue = {
            old: oldName || '',
            new: newName || '',
          };
        }
      } else {
        displayValue = `${object.name}`;
      }
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.CONNECTED_PARTIES: {
      // connection parties are updated.
      if (operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT && exists(object) && object.connectionTypeId) {
        const oldConnectionType = mapTypeIdWithValue(object.connectionTypeId.__old, types);
        const newConnectionType = mapTypeIdWithValue(object.connectionTypeId.__new, types);
        const { userFirstName, userLastName } = object;
        const oldUserName = `${(exists(userFirstName) && userFirstName.__old) || ''} ${(exists(userLastName) && userLastName.__old) || ''}`;
        const newUserName = `${(exists(userFirstName) && userFirstName.__new) || ''} ${(exists(userLastName) && userLastName.__new) || ''}`;
        const oldConnectionValue = `${(exists(oldConnectionType) && exists(oldConnectionType.value) && oldConnectionType.value) || ''}`;
        const newConnectionValue = `${(exists(newConnectionType) && exists(newConnectionType.value) && newConnectionType.value) || ''}`;

        displayValue = {
          old: `${oldUserName} ${oldConnectionValue}`,
          new: `${newUserName} ${newConnectionValue}`,
        };
      } else { // new connection party is added or existing one is deleted.
        const connectionType = exists(object.connectionTypeId) && mapTypeIdWithValue(object.connectionTypeId, types);

        displayValue = `${object.userFirstName} ${object.userLastName} (${(exists(connectionType) && connectionType.value) || ''})`;
      }
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.APPOINTMENT_TYPES: {
      displayValue = `${object.alias}`;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.USERS: {
      // contact name is getting updated.
      if (operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT) {
        let oldType = '';
        let newType = '';

        const { firstName, lastName } = object;
        oldType = exists(firstName) && `${firstName.__old}`;
        oldType = exists(lastName) && `${oldType || ''} ${lastName.__old}`;
        newType = exists(firstName) && `${firstName.__new}`;
        newType = exists(lastName) && `${newType || ''} ${lastName.__new}`;

        if (oldType || newType) {
          displayValue = {
            old: oldType || '',
            new: newType || '',
          };
        }
      } else { // a new user is getting added or existing user is getting deleted.
        displayValue = `${object.firstName} ${object.lastName}`;
      }
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.TAGS: {
      // tag name and type is being updated.
      if (operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT) {
        let oldType = '';
        let newType = '';

        if (object.typeId) {
          oldType = mapTypeIdWithValue(object.typeId.__old, types);
          newType = mapTypeIdWithValue(object.typeId.__new, types);
        }
        displayValue = {
          old: `${(object.name && object.name.__old) || ''} ${oldType && oldType.value}`,
          new: `${(object.name && object.name.__new) || ''} ${newType && newType.value}`,
        };
      } else { // new tag is added or exisiting tag is deleted.
        const mappedTypeValue = object.typeId && mapTypeIdWithValue(object.typeId, types);

        displayValue = `${object.name} ${mappedTypeValue && mappedTypeValue.value}`;
      }
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.GROUPS: {
      if (operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT) {
        const groupName = object.name;
        if (exists(groupName)) {
          displayValue = {
            old: `${(groupName && groupName.__old) || ''}`,
            new: `${(groupName && groupName.__new) || ''}`,
          };
        }
      } else {
        displayValue = object.name;
      }
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.CHANNELS: {
      displayValue = object.name;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.EMAILS:
    case AuditLogConstants.AUDIT_LOGS_TYPES.PHONES: {
      // phone or email information is updated.
      if (operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT) {
        let oldType = '';
        let newType = '';

        if (object.typeId) {
          oldType = mapTypeIdWithValue(object.typeId.__old, types);
          newType = mapTypeIdWithValue(object.typeId.__new, types);
        } else if (object.id) {
          const referredKeyArray = keyName === AuditLogConstants.AUDIT_LOGS_TYPES.EMAILS ? currentData.emails : currentData.phones;
          const newAddedObject = referredKeyArray && referredKeyArray.find((item) => object.id && item.id === object.id.__new);

          newType = newAddedObject && mapTypeIdWithValue(newAddedObject.typeId, types);
          oldType = newType;
        }
        if (!(object.minimal__added || object.otherUsers__deleted)) {
          displayValue = {
            old: `${(oldType?.value) || ''} ${(object.value?.__old) || ''} ${(object.label?.__old) || ''}`,
            new: `${(newType?.value) || ''} ${(object.value?.__new) || ''} ${(object.label?.__new) || ''}`,
          };
        }
      } else { // phone or email is added or deleted.
        const mappedTypeValue = object.typeId && mapTypeIdWithValue(object.typeId, types);
        const phoneLabel = object.label ? `, ${object.label}` : '';
        displayValue = `${mappedTypeValue?.value}, ${object.value}${phoneLabel}`;
      }
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.ATTACHMENTS: {
      // attachments is updated.
      if (operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT) {
        displayValue = {
          old: (object.name && object.name.__old) || '',
          new: (object.name && object.name.__new) || '',
        };
      } else { // attachments are added or deleted.
        displayValue = object.name;
      }
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.OFFICES: {
      const offices = [];
      // office channel is updated.
      if (operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT && exists(object.channel)) {
        const officeChannel = object.channel;

        offices.push({
          officeName: currentData.offices[index].name,
          old: (officeChannel.__old && officeChannel.__old.name) || (officeChannel.name && officeChannel.name.__old) || '',
          new: (officeChannel.__new && officeChannel.__new.name) || (officeChannel.name && officeChannel.name.__new) || '',
        });
        displayValue = offices;
      } else { // new office channel is added or existing office channel is deleted.
        offices.push((exists(object.channel) && object.channel.name) || '');
        displayValue = offices;
      }
      break;
    }
    default:
      displayValue = object;
      break;
  }

  return displayValue;
}

// This function is used to map Ids with appropriate values
function shapeIdWithValues(keyName, patchObject, types, suffixes, prefixes, timeZones, states) {
  let newMappedObject = {};
  let oldMappedObject = {};
  let name = '';
  let oldValue = '';
  let newValue = '';
  let subAction = '';

  switch (keyName) {
    case AuditLogConstants.AUDIT_LOGS_TYPES.TYPE_ID:
    case AuditLogConstants.AUDIT_LOGS_TYPES.INTEGRATION_TYPE_ID: {
      newMappedObject = mapTypeIdWithValue(patchObject.__new, types);
      oldMappedObject = mapTypeIdWithValue(patchObject.__old, types);
      name = (newMappedObject && newMappedObject.class) || (oldMappedObject && oldMappedObject.class);
      oldValue = oldMappedObject && oldMappedObject.value;
      newValue = newMappedObject && newMappedObject.value;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.SUFFIX_ID: {
      newMappedObject = mapTypeIdWithValue(patchObject.__new, suffixes);
      oldMappedObject = mapTypeIdWithValue(patchObject.__old, suffixes);
      name = 'Suffix';
      newValue = newMappedObject && newMappedObject.value;
      oldValue = oldMappedObject && oldMappedObject.value;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.PREFIX_ID: {
      newMappedObject = mapTypeIdWithValue(patchObject.__new, prefixes);
      oldMappedObject = mapTypeIdWithValue(patchObject.__old, prefixes);
      name = 'Prefix';
      newValue = newMappedObject && newMappedObject.value;
      oldValue = oldMappedObject && oldMappedObject.value;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.TIME_ZONE_ID: {
      newMappedObject = mapTypeIdWithValue(patchObject.__new, timeZones);
      oldMappedObject = mapTypeIdWithValue(patchObject.__old, timeZones);
      name = 'Time Zone';
      newValue = newMappedObject && `${newMappedObject.name} Time (UTC ${newMappedObject.utcOffset}) - ${newMappedObject.location}`;
      oldValue = oldMappedObject && `${oldMappedObject.name} Time (UTC ${oldMappedObject.utcOffset}) - ${oldMappedObject.location}`;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.STATE:
    case AuditLogConstants.AUDIT_LOGS_TYPES.CLEAN_STATE: {
      newMappedObject = mapStateCodeWithValue(patchObject.__new, states);
      oldMappedObject = mapStateCodeWithValue(patchObject.__old, states);
      name = 'State';
      newValue = newMappedObject && newMappedObject.value;
      oldValue = oldMappedObject && oldMappedObject.value;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.ACTIVE_AT:
    case AuditLogConstants.AUDIT_LOGS_TYPES.BIRTHDAY: {
      name = keyName;
      newValue = moment(patchObject.__new).format('MM/DD/YYYY');
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.FROM:
    case AuditLogConstants.AUDIT_LOGS_TYPES.TO: {
      newValue = moment(patchObject.__new).format('MM/DD/YYYY h:mm a');
      oldValue = moment(patchObject.__old).format('MM/DD/YYYY h:mm a');
      name = keyName;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.PROFILE_IMAGE_URL:
    case AuditLogConstants.AUDIT_LOGS_TYPES.ORGANIZATION_LOGO_URL: {
      subAction = !exists(patchObject.__old) ? 'Added' : 'Updated';
      name = keyName;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_TYPES.ROUTE: {
      oldValue = (patchObject.name && patchObject.name.__old) || '';
      newValue = (patchObject.name && patchObject.name.__new) || '';
      name = 'Channel Route';
      break;
    }
    default:
      break;
  }

  return {
    name,
    oldValue,
    newValue,
    subAction,
  };
}

// When user edit any category, We got that in Patch object from Audit Log API.
// Patch object has new and old values and we can get those values in  multilevel objects as well
// This function is used to separate all the properties those have been changed within the patch object

function shapeMultiLevelPatchRecursively(patch, logDetail, types, suffixes, prefixes, timeZones, states, currentData, includedKeysForEdit) {
  Object.keys(patch).forEach((key) => {
    // Check if Patch property is an object
    if (exists(patch[key]) && typeof patch[key] === 'object' && (!includedKeysForEdit || (includedKeysForEdit.length && includedKeysForEdit.includes(key)))) {
      // Checking for existing properties in logDetail
      const isDetailAvailable = logDetail.length && logDetail.find((item) => item.name === key && (item.new === patch[key].___new || item.old === patch[key].___old));

      if (key === AuditLogConstants.AUDIT_LOGS_TYPES.FORWARDING) {
        const forwardingData = shapeForwardingObject(patch[key], AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT);

        if (exists(forwardingData)) logDetail.push({ name: forwardingData.name, old: forwardingData.old, new: forwardingData.new });
        // check if patch[key] contains new and old value and pushed them to logDetails array
      } else if (key === AuditLogConstants.AUDIT_LOGS_TYPES.TOGROUP || key === AuditLogConstants.AUDIT_LOGS_TYPES.TOMEMBER) {
        const routingPath = shapeRoutingPath(key, patch, AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT);
        if (exists(routingPath)) logDetail.push({ name: routingPath.name, old: routingPath.old, new: routingPath.new });
      } else if (key === AuditLogConstants.AUDIT_LOGS_TYPES.ROUTE) {
        const { name, oldValue, newValue } = shapeIdWithValues(key, patch[key]);
        logDetail.push({ name, new: newValue, old: oldValue });
      } else if (!isDetailAvailable && (exists(patch[key].__new) || exists(patch[key].__old))) {
        // Display user readable values for typeIds
        if (AuditLogConstants.AUDIT_LOG_MAPPED_KEYS.includes(key)) {
          const { name, oldValue, newValue, subAction } = shapeIdWithValues(key, patch[key], types, suffixes, prefixes, timeZones, states);

          logDetail.push({ name, new: newValue, old: oldValue, subAction });
        } else logDetail.push({ name: key, new: patch[key].__new, old: patch[key].__old });
        // Check if patch[key] is an Array. We got array in case of roles, users, groups etc.
      } else if (Array.isArray(patch[key]) && patch[key].length) {
        if (key === 'businessHours') {
          logDetail.push({ name: key, data: shapeBusinessHoursData(patch[key], currentData) });
        } else if (key === 'permissions') {
          logDetail.push({ name: key, data: shapePermissions(patch, key) });
        } else {
          const arrayData = [];

          patch[key].forEach((element, index) => {
            // Check if user added a value like: added tag, users, roles etc.
            if (Array.isArray(element) && element.length && element.find((item) => item === '+')) {
              const updatedValues = element[1] && handleDifferentKeyTypes(key, element[1], types);

              if (exists(updatedValues)) {
                arrayData.push({
                  new: element[1] && handleDifferentKeyTypes(key, element[1], types, index),
                });
              } // Check if user removed a value like: removed tag, users, roles etc.
            } else if (Array.isArray(element) && element.length && element.find((item) => item === '-')) {
              const updatedValues = element[1] && handleDifferentKeyTypes(key, element[1], types, index);
              if (exists(updatedValues)) {
                arrayData.push({
                  old: element[1] && handleDifferentKeyTypes(key, element[1], types),
                });
              } // Check if user updated a value like updated phone, email type and values
            } else if (Array.isArray(element) && element.length && element.find((item) => item === '~')) {
              const updatedValues = element[1] && handleDifferentKeyTypes(key, element[1], types, AuditLogConstants.AUDIT_LOGS_OPERATIONS.EDIT, currentData, index);

              if (exists(updatedValues) && (exists(updatedValues.old) || exists(updatedValues.new))) {
                arrayData.push({
                  new: updatedValues.new,
                });
                arrayData.push({
                  old: updatedValues.old,
                });
              } else if (key === 'offices') {
                const office = {
                  officeName: updatedValues[0].officeName,
                  new: updatedValues[0].new,
                  old: updatedValues[0].old,
                };
                arrayData.push(office);
              }
            }
          });
          logDetail.push({ name: key, data: arrayData });
        }
      } else shapeMultiLevelPatchRecursively(patch[key], logDetail, types, suffixes, prefixes, timeZones, states, currentData, includedKeysForEdit);
    }
  });
}

function shapeForwardingObject(currentData, operation) {
  let forwardingName = '';

  if (operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.ADD) {
    const isForwardingGroup = exists(currentData.toUserGroup);
    const referredObject = isForwardingGroup ? currentData.toUserGroup : currentData.toUser;

    forwardingName = (referredObject && referredObject.name) || (referredObject && referredObject.firstName && `${referredObject.firstName} ${referredObject.lastName}`);
    return {
      name: isForwardingGroup ? 'Forwarding Group' : 'Forwarding Member',
      new: forwardingName,
    };
  } else {
    const isToggleGroupToMember = exists(currentData.toUser__added);
    const isToggleMemberToGroup = exists(currentData.toUserGroup__added);

    let oldForwarding = '';
    let newForwarding = '';

    if (isToggleGroupToMember || isToggleMemberToGroup) {
      const addedGroupOrMember = isToggleGroupToMember ? currentData.toUser__added : currentData.toUserGroup__added;
      const oldForwardingObject = isToggleGroupToMember ? currentData.toUserGroup__deleted : currentData.toUser__deleted;
      oldForwarding = (isToggleGroupToMember && oldForwardingObject) ? oldForwardingObject.name : `${oldForwardingObject.firstName} ${oldForwardingObject.lastName}`;
      oldForwarding = `${oldForwarding}${isToggleGroupToMember ? '(Group)' : '(Member)'}`;
      newForwarding = (isToggleGroupToMember && addedGroupOrMember) ? `${addedGroupOrMember.firstName} ${addedGroupOrMember.lastName}` : addedGroupOrMember.name;
      newForwarding = `${newForwarding}${isToggleGroupToMember ? '(Member)' : '(Group)'}`;
      forwardingName = 'Forwarding';
    } else if (exists(currentData.userId)) {
      const firstNameObject = currentData.toUser && currentData.toUser.firstName;
      const lastNameObject = currentData.toUser && currentData.toUser.lastName;

      oldForwarding = (firstNameObject && lastNameObject && `${firstNameObject.__old} ${lastNameObject.__old}`) || '';
      newForwarding = (firstNameObject && lastNameObject && `${firstNameObject.__new} ${lastNameObject.__new}`) || '';
      forwardingName = 'Forwarding Member';
    } else if (exists(currentData.groupId)) {
      const nameObject = currentData.toUserGroup && currentData.toUserGroup.name;

      oldForwarding = (nameObject && nameObject.__old) || '';
      newForwarding = (nameObject && nameObject.__new) || '';
      forwardingName = 'Forwarding Group';
    } else if (currentData.__new) {
      const isMemberForwarding = exists(currentData.__new) && exists(currentData.__new.toUser);
      const forwardingObject = isMemberForwarding ? currentData.__new.toUser : currentData.__new && currentData.__new.toUserGroup;

      newForwarding = isMemberForwarding ? (forwardingObject && `${forwardingObject.firstName} ${forwardingObject.lastName}`) : forwardingObject && forwardingObject.name;
      forwardingName = isMemberForwarding ? 'Forwarding Member' : 'Forwarding Group';
    } else if (currentData.__old) {
      const isMemberForwarding = exists(currentData.__old) && exists(currentData.__old.toUser);
      const forwardingObject = isMemberForwarding ? currentData.__old.toUser : currentData.__old && currentData.__old.toUserGroup;

      oldForwarding = isMemberForwarding ? (forwardingObject && `${forwardingObject.firstName} ${forwardingObject.lastName}`) : forwardingObject && forwardingObject.name;
      forwardingName = isMemberForwarding ? 'Forwarding Member' : 'Forwarding Group';
    }

    return {
      name: forwardingName,
      old: oldForwarding || '',
      new: newForwarding || '',
    };
  }
}

function shapeRoutingPath(key, data, operation) {
  const currentData = data[key];
  let routingPathName = '';
  if (operation === AuditLogConstants.AUDIT_LOGS_OPERATIONS.ADD) {
    if (key === 'toMember') {
      routingPathName = `${currentData.userFirstName} ${currentData.userLastName} (Member)`;
    } else {
      routingPathName = `${currentData.name} (Group)`;
    }
    return {
      name: 'Routing Path',
      new: routingPathName,
    };
  } else {
    const isToMember = exists(data?.toMember__added);
    const isToGroup = exists(data?.toGroup__added);

    let oldPath = '';
    let newPath = '';

    if (isToGroup) {
      newPath = `${currentData?.name} (Group)`;
      oldPath = `${currentData?.userFirstName} ${currentData?.userLastName} (Member)`;
    } else if (isToMember) {
      oldPath = `${currentData?.name} (Group)`;
      newPath = `${currentData?.userFirstName} ${currentData?.userLastName} (Member)`;
    } else {
      oldPath = `${currentData?.userFirstName?.__old} ${currentData?.userLastName?.__old} (Member)`;
      newPath = `${currentData?.userFirstName?.__new} ${currentData?.userLastName?.__new} (Member)`;
    }
    return {
      name: 'Routing Path',
      old: oldPath || '',
      new: newPath || '',
    };
  }
}

// This function is used to shape Added data in a particular category
function shapeCreatedData(currentData, logDetail, types, suffixes, prefixes, timeZones, states, categoriesIncludedKeys, category, parentKey = '') {
  if (exists(currentData)) {
    Object.keys(currentData).forEach((key) => {
      if (exists(currentData[key]) && (!categoriesIncludedKeys || (categoriesIncludedKeys.length && categoriesIncludedKeys.includes(key)))) {
        if (typeof currentData[key] !== 'object') {
          if (AuditLogConstants.AUDIT_LOG_MAPPED_KEYS.includes(key)) {
            const patchObject = { __new: currentData[key] };
            const { name, newValue, subAction } = shapeIdWithValues(key, patchObject, types, suffixes, prefixes, timeZones, states);
            // is that we need for other keys like billing
            if (parentKey.includes('billing')) {
              logDetail.push({ name, new: newValue, subAction, parentKey: capitalize('billing') });
            } else {
              logDetail.push({ name, new: newValue, subAction });
            }
          } else if (parentKey.includes('billing')) {
            // is that we need for other keys like billing
            logDetail.push({ name: key, new: currentData[key], parentKey: capitalize('billing') });
          } else {
            logDetail.push({ name: key, new: currentData[key] });
          }
        } else if (Array.isArray(currentData[key]) && currentData[key].length) {
          let arrayData = [];
          let auditlogDetails = currentData;
          if (category === AuditLogConstants.AUDIT_LOGS_CATEGORIES.GROUP && auditlogDetails.businessHours && !auditlogDetails.businessHours.length) {
            auditlogDetails = {
              ...auditlogDetails,
              observesDst: null,
            };
          }

          if (category === AuditLogConstants.AUDIT_LOGS_CATEGORIES.RHINOBLAST) {
            const { user } = auditlogDetails[key][0];
            arrayData.push(user);
          } else {
            auditlogDetails[key].forEach((item) => {
              if (key === 'businessHours') {
                arrayData.push({ new: item, day: item.day, operation: AuditLogConstants.AUDIT_LOGS_OPERATIONS.ADD });
              } else if (key === 'permissions') {
                arrayData = shapePermissions(auditlogDetails, key, AuditLogConstants.AUDIT_LOGS_OPERATIONS.ADD);
              } else {
                const addedValue = item && handleDifferentKeyTypes(key, item, types);

                if (exists(addedValue)) {
                  arrayData.push({ new: addedValue });
                }
              }
            });
          }

          logDetail.push({ name: key, data: arrayData });
        } else if (key === AuditLogConstants.AUDIT_LOGS_TYPES.FORWARDING) {
          const forwardingData = shapeForwardingObject(currentData[key], AuditLogConstants.AUDIT_LOGS_OPERATIONS.ADD);

          logDetail.push({ name: forwardingData && forwardingData.name, new: forwardingData && forwardingData.new });
        } else if (key === AuditLogConstants.AUDIT_LOGS_TYPES.TOGROUP || key === AuditLogConstants.AUDIT_LOGS_TYPES.TOMEMBER) {
          const routingPath = shapeRoutingPath(key, currentData, AuditLogConstants.AUDIT_LOGS_OPERATIONS.ADD);
          logDetail.push({ name: routingPath && routingPath.name, new: routingPath && routingPath.new });
        } else {
          const newParentKey = `${parentKey}|${key}`;
          shapeCreatedData(currentData[key], logDetail, types, suffixes, prefixes, timeZones, states, categoriesIncludedKeys, category, newParentKey);
        }
      }
    });
  }
}

// This function is used to get the contact information.
// If name is available, then name is returned else contact number is returned.
function getContactInformation(currentData) {
  const firstName = currentData?.firstName ?? '';
  const lastName = currentData?.lastName ?? '';
  const phones = currentData?.phones ?? [];

  const isNameAvailable = firstName || lastName;
  const contactPhoneNumber = (phones && phones[0]) && (phones[0].value || phones[0].number || '');

  return isNameAvailable ? `${firstName} ${lastName}` : `${contactPhoneNumber ?? ''}`;
}

// This function is used to shape sub actions that fall in Conversation Action, Template Action and Channel Provision.
function shapeAuditLogSubActions(auditLog, logDetail) {
  if (auditLog.auditSubAction === 'Assigned') {
    const assignmentDetails = auditLog.currentData && auditLog.currentData[0] && auditLog.currentData[0].details;
    const assignedTo = `${assignmentDetails.to}(${exists(assignmentDetails.toGroupId) ? 'Group' : 'Member'})`;

    logDetail.push({ name: auditLog.category, subAction: auditLog.auditSubAction });
    logDetail.push({ name: 'Assigned To', old: assignmentDetails.from, new: assignedTo });
  } else if (auditLog.auditSubAction === 'ContentSaved') {
    let contactInformation;
    if (auditLog.auditAction === 'Download') {
      contactInformation = getContactInformation(auditLog.currentData.user);
    } else {
      contactInformation = getContactInformation(auditLog.currentData.event.user);
    }
    const auditAction = auditLog.auditAction === 'Download' ? 'PDF Downloaded from' : 'Saved from';
    const auditActionLabel = auditLog.auditAction === 'Download' ? 'Downloaded Content' : 'Saved Content';
    logDetail.push({ name: auditActionLabel, subAction: `${auditAction} ${contactInformation}'s profile` });
  } else if (auditLog.auditSubAction === 'CopyContent') {
    const copiedTo = getContactInformation(auditLog.currentData);
    const copiedFrom = getContactInformation(auditLog.currentData.copyContent.user);
    const auditAction = 'Copied Content';
    const subAction = `Copied Content from ${copiedFrom} to ${copiedTo}`;

    logDetail.push({ name: auditAction, subAction });
  } else if (auditLog.auditSubAction === 'mergedUser') {
    let masterContactInformation = '';
    const mergedContact = auditLog.currentData && auditLog.currentData.slaveUser;
    if (exists(auditLog.currentData)) {
      const { firstName, lastName } = auditLog.currentData;
      let { phones } = auditLog.currentData;
      const isMasterContactNameAvailable = exists(firstName) || exists(lastName);
      if (exists(mergedContact)) {
        const { phones: mergedContactPhones } = mergedContact;
        if (exists(mergedContactPhones) && exists(mergedContactPhones[0]) && mergedContactPhones[0].number) {
          phones = phones.filter((phone) => phone.number !== mergedContactPhones[0].number);
        }
      }
      const masterContactPhoneNumber = exists(phones) && exists(phones[0]) && phones[0].number;
      masterContactInformation = isMasterContactNameAvailable ? `${exists(firstName) ? firstName : ''} ${exists(lastName) ? lastName : ''}` : masterContactPhoneNumber;
    }

    if (exists(mergedContact)) {
      const mergedContactInformation = getContactInformation(mergedContact);
      logDetail.push({ name: 'Contact', subAction: `${mergedContactInformation} merged with ${exists(masterContactInformation) ? masterContactInformation : ''}` });
    }
  } else {
    logDetail.push({ name: auditLog.category, subAction: auditLog.auditSubAction });
  }
}

export function shapeAuditLogDetails(auditLog, types, suffixes, prefixes, timeZones, states) {
  const logDetail = [];
  if (exists(auditLog) && auditLog.auditAction === 'Add') {
    const categoriesIncludedKeys = AuditLogConstants.AUDIT_LOG_CATEGORIES_INCLUDED_KEYS[auditLog.category];

    shapeCreatedData(auditLog.currentData, logDetail, types, suffixes, prefixes, timeZones, states, categoriesIncludedKeys, auditLog.category);

    if (exists(auditLog.auditSubAction) && auditLog.category === AuditLogConstants.AUDIT_LOGS_CATEGORIES.CHANNEL) {
      logDetail.push({ name: 'Status', new: auditLog.auditSubAction });
    }
  } else if (exists(auditLog.auditSubAction)) {
    shapeAuditLogSubActions(auditLog, logDetail);
  } else if (exists(auditLog) && exists(auditLog.patch)) {
    if (exists(auditLog.patch.__new) || exists(auditLog.patch.__old)) {
      logDetail.push({ new: auditLog.patch.__new, old: auditLog.patch.__old });
    } else if (exists(auditLog.patch.billManagerPaymentId__added)) {
      logDetail.push({ name: 'billManagerPaymentId__added', new: auditLog.patch.billManagerPaymentId__added, old: null });
    } else {
      const includedKeysForEdit = AuditLogConstants.AUDIT_LOG_INCLUDED_KEYS_FOR_EDIT[auditLog.category];

      shapeMultiLevelPatchRecursively(auditLog.patch, logDetail, types, suffixes, prefixes, timeZones, states, auditLog.currentData, includedKeysForEdit);
    }
  }

  if (auditLog.category === 'RhinoVideo') {
    const dateAndDurationString = `${formatTimestamp(auditLog.currentData.created)} (${findDurationFromMilliseconds(auditLog.currentData.durationMs)})`;
    logDetail.push({ name: 'videoDateAndDuration', new: dateAndDurationString });
  }
  if (auditLog.category === 'RhinoCall') {
    const dateAndDurationString = `${formatTimestamp(auditLog.currentData.createdAt)} (${findDurationFromMilliseconds(auditLog.currentData.durationMs)})`;
    logDetail.push({ name: 'videoDateAndDuration', new: dateAndDurationString });
  }

  return logDetail;
}

// This function is used to return static field name and values on based of different categories
export function shapeDataBasedOnCategory(auditLog) {
  let staticFieldName = '';
  let fieldValue = '';
  let userNameObject = {};
  let userData = {};

  switch (auditLog.category) {
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.ROLE: {
      staticFieldName = 'Role Name';
      fieldValue = (auditLog.currentData && auditLog.currentData.name) || (auditLog.patch && auditLog.patch.__old && auditLog.patch.__old.name) || '';
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.CHANNEL:
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.GROUP:
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.TAG:
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.OFFICE_LOCATION: {
      staticFieldName = `${auditLog.category} Name`;
      fieldValue = (auditLog.currentData && auditLog.currentData.name) || (auditLog.patch && auditLog.patch.__old && auditLog.patch.__old.name) || '';
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.ORGANIZATION:
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.ORG_PREFERENCES:
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.ORG_PROFILE: {
      staticFieldName = `${AuditLogConstants.AUDIT_LOGS_CATEGORIES.ORGANIZATION} Name`;
      fieldValue = (auditLog.currentData && auditLog.currentData.name) || (auditLog.patch && auditLog.patch.__old && auditLog.patch.__old.name) || '';
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.CONVERSATION:
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.CONVERSATION_ACTIONS: {
      staticFieldName = 'Contact';

      if (exists(auditLog.currentData) && exists(auditLog.currentData.user)) {
        userData = auditLog.currentData.user;
      } else if (exists(auditLog.currentData) && exists(auditLog.currentData[0]) && exists(auditLog.currentData[0].user)) {
        userData = auditLog.currentData[0].user;
      } else if (exists(auditLog.currentData) && exists(auditLog.currentData.users)) {
        userData = auditLog?.currentData?.users[0];
      }
      fieldValue = exists(userData) && getContactInformation(userData);
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.CONTACT:
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.MEMBER: {
      staticFieldName = auditLog.category === AuditLogConstants.AUDIT_LOGS_CATEGORIES.MEMBER ? `${auditLog.category} Name` : auditLog.category;
      userNameObject = (exists(auditLog.currentData) && exists(auditLog.currentData.user)) ? auditLog.currentData.user : auditLog.currentData;
      fieldValue = exists(auditLog.currentData) && getContactInformation(userNameObject);
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.TEMPLATE:
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.TEMPLATE_ACTION: {
      staticFieldName = 'Template Title';
      fieldValue = (auditLog.currentData && auditLog.currentData.subject) || (auditLog.patch && auditLog.patch.__old && auditLog.patch.__old.subject) || '';
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.OUT_OF_OFFICE: {
      staticFieldName = 'OOO event';
      fieldValue = (auditLog.currentData && auditLog.currentData.title) || (auditLog.patch && auditLog.patch.__old && auditLog.patch.__old.title) || '';
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.APPOINTMENT_MANAGER: {
      staticFieldName = 'Contact';
      const contactDetails = exists(auditLog.currentData) && exists(auditLog.currentData.contactDetails) ? auditLog.currentData.contactDetails : auditLog.currentData;
      fieldValue = exists(auditLog.currentData) && getContactInformation(contactDetails);
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.RHINOPAY_MANAGER: {
      staticFieldName = 'Contact';
      const contactDetails = exists(auditLog.currentData) && exists(auditLog.currentData.contact) ? auditLog.currentData.contact : auditLog.currentData;
      fieldValue = exists(auditLog.currentData) && getContactInformation(contactDetails);
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.RHINOBLAST: {
      staticFieldName = 'Message';
      const messageDetails = exists(auditLog.currentData) && exists(auditLog.currentData.messageText) ? auditLog.currentData.messageText : '';
      fieldValue = exists(auditLog.currentData) && messageDetails;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.RHINO_VIDEO: {
      staticFieldName = 'Contact';
      const contactDetails = exists(auditLog.contact) ? auditLog.contact : null;
      fieldValue = exists(auditLog.currentData) && getContactInformation(contactDetails);
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.RHINO_CALL: {
      staticFieldName = 'Contact';
      const contactDetails = exists(auditLog.contact) ? auditLog.contact : null;
      fieldValue = exists(auditLog.currentData) && getContactInformation(contactDetails);
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.RHINOFORM: {
      staticFieldName = 'Title';
      const messageDetails = auditLog.currentData?.title || '';
      fieldValue = messageDetails;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.APPOINTMENT_REMINDER: {
      staticFieldName = 'Name';
      const messageDetails = auditLog.currentData?.name || '';
      fieldValue = messageDetails;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.ROUTING_MANAGER: {
      staticFieldName = 'Name';
      const categories = auditLog.currentData?.textClassificationTypes.join(',') || '';
      fieldValue = categories;
      break;
    }
    case AuditLogConstants.AUDIT_LOGS_CATEGORIES.PRESCRIPTION_NOTIFICATION: {
      staticFieldName = 'Name';
      const categories = auditLog.currentData?.name || '';
      fieldValue = categories;
      break;
    }
    default: {
      staticFieldName = '';
      fieldValue = '';
      break;
    }
  }

  return {
    staticFieldName,
    fieldValue,
  };
}

export function shapeBackendFieldToRelevantField(logDetails) {
  logDetails.forEach((log) => {
    if (exists(AuditLogConstants.AUDIT_LOG_RELEVANT_FIELD_NAME[log.name])) {
      log.name = AuditLogConstants.AUDIT_LOG_RELEVANT_FIELD_NAME[log.name]; // eslint-disable-line no-param-reassign
    }
  });

  return logDetails;
}
