import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
} from 'rhinostyle';

import TagModalContainer from '../containers/TagModalContainer';
import PageLoader from './PageLoader';
import TagsGroup from './TagsGroup';
import { TAG_CREATE, TAG_EDIT } from '../constants/UserPermissionsConstants';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';

const OrganizationTags = (props) => {
  const {
    closeModal,
    handleCreateRequest,
    isModalOpen,
    tagIds,
    tags,
    tagsLoading,
  } = props;

  if (tagsLoading) {
    return <PageLoader />;
  }

  return (
    <div className="app-page__container">
      <div className="app-page__container__inner">
        {userHasAnyOfPermissions([TAG_CREATE]) && (
          <TagModalContainer
            isOpen={isModalOpen}
            closeModal={closeModal}
            mode="create"
          />
        )}
        <div className="app-page__header">
          <div className="app-page__header__title">Tags</div>
          {userHasAnyOfPermissions([TAG_CREATE]) && (
            <div className="app-page__header__action">
              <Button
                onClick={handleCreateRequest}
                iconOnly
                type="secondary"
                title="Create Tag"
              >
                <Icon icon="add" />
              </Button>
            </div>
          )}
        </div>

        <TagsGroup
          tags={tags}
          tagIds={tagIds}
          title="Manage Tags"
          isCreateLinkHidden
          tagsDisabled={!userHasAnyOfPermissions([TAG_EDIT])}
        />
      </div>
    </div>
  );
};

OrganizationTags.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleCreateRequest: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  tagIds: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  tagsLoading: PropTypes.bool.isRequired,
};

export default OrganizationTags;
