import { createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import ReactDOM from 'react-dom';

import { DataHelpers, AttachmentHelpers, UIHelpers } from '../helpers';
import * as UserSelectors from '../selectors/userSelectors';
import WebSocketService from '../services/WebSocketService';
import {
  MODAL_OPTIONS,
  PANEL_OPTIONS,
}
  from '../constants/ThreadConstants';
import { TYPE_ATTACHMENT_FORM, TYPE_FACEBOOK, TYPE_VCARD } from '../constants/Types';
import { VCARD_FILE_TYPE } from '../constants/AppConstants';
import * as UploadActions from '../actions/UploadActions';
import * as ChatActionTypes from '../constants/ChatActionTypes';
import * as InboxActionTypes from '../constants/InboxActionTypes';

// SLICE
const initialState = {
  activeConvoTab: 'patient',
  activePanel: PANEL_OPTIONS.profile,
  activeUpload: false,
  attachments: [],
  isMobileThreadHeaderEnabled: false,
  [MODAL_OPTIONS.rhinopay]: false,
  [MODAL_OPTIONS.videoLimit]: false,
  [MODAL_OPTIONS.browserNotSupported]: false,
  [MODAL_OPTIONS.formLibrary]: false,
  [MODAL_OPTIONS.audioAccess]: false,
  [MODAL_OPTIONS.downloadApp]: false,
  lightboxIsOpen: false,
  mobileProfileOpen: false,
  organizationMessageTemplateModalOpen: false,
  profileOpen: true,
  sendingSecure: false,
  sharelinkFiles: [],
  showMentionPanel: false,
  fileProgress: '0% Uploaded',
  messageForms: [],
  isFormPdfViewerOpen: false,
  activeLibraryTab: null,
  templateToBeAdded: '',
  messageInputHeightChange: false,
  sendingMessage: false,
  isFormLibraryModalOpen: false,
  isVCardModalOpen: false,
};
const threadSlice = createSlice({
  name: 'THREAD',
  initialState,
  reducers: {
    setActiveConvoTab: receiveActiveConvoTab,
    setActivePanel: (state, action) => ({
      ...state,
      activePanel: action.payload,
    }),
    setActiveUpload: (state, action) => ({
      ...state,
      activeUpload: action.payload,
    }),
    setFileProgress: (state, action) => ({
      ...state,
      fileProgress: action.payload,
    }),
    setTemplateToBeAdded: (state, action) => ({
      ...state,
      templateToBeAdded: action.payload,
    }),
    togglePdfViewer: (state) => ({
      ...state,
      isFormPdfViewerOpen: !state.isFormPdfViewerOpen,
    }),
    toggleOrganizationMessageTemplateModal: (state) => ({
      ...state,
      organizationMessageTemplateModalOpen: !state.organizationMessageTemplateModalOpen,
    }),
    setProfileClosed: (state) => ({
      ...state,
      profileOpen: false,
    }),
    setProfileOpen: (state) => ({
      ...state,
      profileOpen: true,
    }),
    setMobileProfileClosed: (state) => ({
      ...state,
      mobileProfileOpen: false,
    }),
    toggleProfile: (state) => ({
      ...state,
      profileOpen: !state.profileOpen,
      mobileProfileOpen: !state.mobileProfileOpen,
    }),
    setIsMobileThreadHeaderEnabled: (state) => ({
      ...state,
      isMobileThreadHeaderEnabled: !state.isMobileThreadHeaderEnabled,
    }),
    handleCloseSavedContentPanel: closeSavedContentPanel,
    toggleLightboxOpen: (state, action) => ({
      ...state,
      lightboxIsOpen: true,
      lightboxAttachmentUrl: action.payload,
    }),
    toggleLightboxClosed: (state) => ({
      ...state,
      lightboxIsOpen: false,
      lightboxAttachmentUrl: null,
    }),
    toggleModal: (state, action) => ({
      ...state,
      [action.payload]: !state[action.payload],
    }),
    removeAttachment: (state, action) => ({
      ...state,
      attachments: state.attachments.filter((attachment) => attachment.attachmentUrl !== action.payload.attachmentUrl),
    }),
    removeMessageForm: (state, action) => ({
      ...state,
      messageForms: state.messageForms.filter((messageForm) => messageForm.formId !== action.payload.formId),
    }),
    removeSharelinkFile: (state, action) => ({
      ...state,
      sharelinkFiles: state.sharelinkFiles.filter((sharelinkFile) => sharelinkFile.attachmentUrl !== action.payload.attachmentUrl),
    }),
    addMessageForm: (state, action) => {
      const exists = state.messageForms.findIndex((form) => form.formId === action.payload.formId) > -1;
      if (!exists) {
        state.messageForms.push(action.payload);
      }
      state.isFormLibraryModalOpen = false;
    },
    addVCardAttachment: (state, action) => {
      const nonVCardAttachments = state.attachments.filter((attachment) => attachment.type !== VCARD_FILE_TYPE);
      state.attachments = [...nonVCardAttachments, ...action.payload];
      state.isVCardModalOpen = false;
    },
    addAttachment: (state, action) => {
      const exists = state.attachments.findIndex((file) => file.attachmentUrl === action.payload.attachmentUrl) > -1;
      if (!exists) {
        state.attachments.push(action.payload);
      }
    },
    addSharelinkFile: (state, action) => {
      const exists = state.sharelinkFiles.findIndex((file) => file.attachmentUrl === action.payload.attachmentUrl) > -1;
      if (!exists) {
        state.sharelinkFiles.push(action.payload);
      }
    },
    resetMessageAttachments: (state) => ({
      ...state,
      messageForms: [],
      attachments: [],
      sharelinkFiles: [],
      activeUpload: false,
    }),
    setMessageInputHeightChange: (state, action) => ({
      ...state,
      messageInputHeightChange: action.payload,
    }),
    setSendingMessageLoading: (state) => ({
      ...state,
      sendingMessage: true,
    }),
    setFiles: (state, action) => {
      state[action.payload.fileType] = action.payload.value;
    },
  },
  extraReducers: {
    [ChatActionTypes.receiveCreateTempChatEvent]: setSendingMessageComplete,
    [InboxActionTypes.receiveCreateTempEvent]: setSendingMessageComplete,
    [InboxActionTypes.requestInitialThreadView]: resetThreadDataMutation,
    [InboxActionTypes.receiveInboxThreadView]: receiveThreadConvoTab,
    [ChatActionTypes.receiveChatThreadGroupView]: receiveActiveConvoTab,
    [ChatActionTypes.receiveChatThreadUserView]: receiveActiveConvoTab,
  },
});

export default threadSlice.reducer;

// ACTIONS
export const {
  setActiveConvoTab,
  setField,
  togglePdfViewer,
  updateThreadState,
  setFieldValue,
  setActivePanel,
  setScrollContext,
  toggleOrganizationMessageTemplateModal,
  toggleProfile,
  handleProfileState,
  handleCloseSavedContentPanel,
  setIsMobileThreadHeaderEnabled,
  setProfileClosed,
  setMobileProfileClosed,
  setProfileOpen,
  toggleLightboxOpen,
  toggleModal,
  removeSharelinkFile,
  removeAttachment,
  removeMessageForm,
  addMessageForm,
  addVCardAttachment,
  resetMessageAttachments,
  updateProfile,
  setTemplateToBeAdded,
  setActiveUpload,
  setFileProgress,
  addAttachment,
  addSharelinkFile,
  clearComposeArea,
  setMessageInputHeightChange,
  toggleLightboxClosed,
  setSendingMessageLoading,
  setFiles,
} = threadSlice.actions;

function closeSavedContentPanel(state, action) {
  return {
    ...state,
    profileOpen: UIHelpers.panelBreakpointMin() ? true : !state.profileOpen,
    mobileProfileOpen: UIHelpers.panelBreakpointMin() ? false : !state.mobileProfileOpen,
    activePanel: action.payload === PANEL_OPTIONS.forms ? PANEL_OPTIONS.library : PANEL_OPTIONS.profile,
    ...action.payload === PANEL_OPTIONS.forms && {
      activeLibraryTab: action.payload,
    },
  };
}
function setSendingMessageComplete(state) {
  return {
    ...state,
    sendingMessage: false,
    messageForms: [],
    attachments: [],
    sharelinkFiles: [],
  };
}

function resetThreadDataMutation(state, action) {
  return {
    ...state,
    ...initialState,
    ...action.payload.locationActivePanel && {
      activePanel: action.payload.locationActivePanel,
    },
  };
}

function receiveThreadConvoTab(state) {
  // TODO move
  const activeConvoTab = 'patient';
  state.activeConvoTab = activeConvoTab;
  state.messageForms = [];
  state.attachments = [];
  state.sharelinkFiles = [];
}

export function handleUpdateWebsocketSubscriptions({ subscribe, unsubscribe, options }) { // WebSocket Subs
  const { threadType, currentUserId } = options;
  const removeUserSubscription = unsubscribe?.userId && unsubscribe.userId !== subscribe?.userId;
  const removeGroupSubscription = unsubscribe?.groupId && unsubscribe.groupId !== subscribe?.groupId;
  if (WebSocketService.subscriptions) {
    let currentSubscriptions = [...WebSocketService.subscriptions]; // Initialize with current subs.
    if (unsubscribe) {
      const userPayload = { subscribingToUserId: unsubscribe?.userId, ...threadType === 'chat' && { publishingToUserId: currentUserId } };
      const subscriptionsToRemove = [
        WebSocketService.shapeSubscriptionForTypingEvents({
          ...removeUserSubscription && { ...userPayload },
          ...removeGroupSubscription && { subscribingToGroupId: unsubscribe?.groupId },
          typingUserId: currentUserId,
          threadType,
        }),
        WebSocketService.shapeSubscriptionForViewingThread({
          ...removeUserSubscription && { ...userPayload },
          ...removeGroupSubscription && { subscribingToGroupId: unsubscribe?.groupId },
          threadType,
        }),
      ];
      subscriptionsToRemove.forEach((subscription) => {
        currentSubscriptions = [...currentSubscriptions.filter((sub) => !isEqual(sub, subscription))];
      });
    }
    const userPayload = { subscribingToUserId: subscribe?.userId, ...threadType === 'chat' && { publishingToUserId: currentUserId } };
    const subscriptionsToAdd = subscribe ? [
      WebSocketService.shapeSubscriptionForTypingEvents({
        ...subscribe?.userId && { ...userPayload },
        ...subscribe?.groupId && { subscribingToGroupId: subscribe?.groupId },
        typingUserId: currentUserId,
        threadType,
      }),
      WebSocketService.shapeSubscriptionForViewingThread({
        ...subscribe?.userId && { ...userPayload },
        ...subscribe?.groupId && { subscribingToGroupId: subscribe?.groupId },
        threadType,
      }),
    ] : [];

    // Ensure we're not adding duplicates.
    const newSubscriptions = subscriptionsToAdd?.filter((newSubscription) => !currentSubscriptions.some((oldSubscription) => isEqual(newSubscription, oldSubscription)));
    WebSocketService.updateSubscriptions([...currentSubscriptions, ...newSubscriptions]);
  }
}

function toggleMobileHeaderDropdown() {
  // Only run this on mobile devices
  if (UIHelpers.panelBreakpointMin()) return;

  // Grab reference to dropdown
  const $dropdown = ReactDOM.findDOMNode(this.mobileHeaderDropdown);

  if ($dropdown) {
    const $dropdownToggle = $dropdown.querySelector('.dropdown__toggle');

    $dropdownToggle.click();
  }
}

// To be used in Chat Thread as well
export function panelWatch(load = false, resize = false) {
  return (dispatch, getState) => {
    const { profileOpen, mobileProfileOpen, isMobileThreadHeaderEnabled } = getState().thread;
    // For larger screens
    if (UIHelpers.panelBreakpointMin()) {
      // Open full screen profile
      if (!profileOpen) {
        dispatch(setProfileOpen());
      }
      // Close mobile profile
      if (mobileProfileOpen) {
        dispatch(setMobileProfileClosed());
      }
      // Close mobile dropdown
      if (isMobileThreadHeaderEnabled) {
        toggleMobileHeaderDropdown();
      }
      // For smaller screens
    } else if (UIHelpers.panelBreakpointMax()) {
      if (load) {
        // Close full screen profile
        dispatch(setProfileClosed());
      } else if (resize) {
        if (profileOpen && !mobileProfileOpen) {
          dispatch(setProfileClosed());
        }
      }
    }
    return true;
  };
}

export function handleRemoveFile(attachment) {
  return (dispatch) => {
    const { isSharelink, formId } = attachment;
    if (isSharelink) {
      dispatch(removeSharelinkFile(attachment));
    } else if (formId) {
      dispatch(removeMessageForm(attachment));
    } else {
      dispatch(removeAttachment(attachment));
    }
  };
}

export function handleAddFile(attachment) {
  return (dispatch) => {
    if (attachment) {
      const { isSharelink, formId } = attachment;
      if (isSharelink) {
        dispatch(addSharelinkFile(attachment));
      } else if (formId) {
        dispatch(addMessageForm(attachment));
      } else {
        dispatch(addAttachment(attachment));
      }
    }
    dispatch(setActiveUpload(false));
  };
}

export function handleTemplateDataAppend(
  templateText,
  templateAttachments,
  templateType,
) {
  return async (dispatch, getState) => {
    const currentState = getState();
    const { channel: { channels }, inbox: { activeFromChannelId }, thread: { activeConvoTab } } = currentState;
    const userOrganization = UserSelectors.getLoggedInUserOrganization(currentState);
    const isFacebook = templateType !== 'teamTemplate' && activeConvoTab === 'patient' && channels[activeFromChannelId]?.typeId === TYPE_FACEBOOK;
    dispatch(setTemplateToBeAdded(templateText));
    const filesToBecomeSharelinks = [];
    // separate attachments from files that need to become sharelinks
    for (let i = 0; i < templateAttachments.length; i++) {
      const attachment = templateAttachments[i];
      if (attachment.attachmentTypeId === TYPE_ATTACHMENT_FORM) {
        dispatch(addMessageForm({
          formId: attachment.attachmentUrl,
          title: attachment.name,
        }));
      } else if (attachment.attachmentTypeId === TYPE_VCARD) {
        dispatch(addAttachment({
          attachmentUrl: attachment.attachmentUrl,
          type: VCARD_FILE_TYPE,
          name: attachment.name,
          bytes: parseInt(attachment.bytes, 10),
        }));
      } else if (AttachmentHelpers.shouldAttachmentBeSharelink({ attachment, standardFileSize: activeConvoTab === 'secure', isFacebook })) {
        filesToBecomeSharelinks.push({
          attachmentUrl: attachment.attachmentUrl,
          name: attachment.name,
          type: DataHelpers.getFileMimeType(attachment.name),
          isSharelink: true,
        });
      } else {
        dispatch(addAttachment({
          attachmentUrl: attachment.attachmentUrl,
          type: DataHelpers.getFileMimeType(attachment.name),
          name: attachment.name,
          bytes: parseInt(attachment.bytes, 10),
        }));
      }
    }

    // create sharelinks if needed
    await Promise.all(filesToBecomeSharelinks.map(async (file) => {
      const partialUrl = `${process.env.REACT_APP_PUBLIC_TEMPLATE_FILES_DOMAIN}/${userOrganization.id}/${file.attachmentUrl}`;
      const { data: sharelink } = await UploadActions.createSharelink(partialUrl);
      const updatedSharelinkData = {
        ...file,
        sharelink: sharelink.url,
      };
      return dispatch(addSharelinkFile(updatedSharelinkData));
    }));
  };
}

function receiveActiveConvoTab(state, action) {
  state.activeConvoTab = action.payload.activeConvoTab;
  state.messageForms = [];
  state.attachments = [];
  state.sharelinkFiles = [];
  state.activeUpload = false;
  state.templateToBeAdded = '';
}
