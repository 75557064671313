import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Button } from 'rhinostyle';
import { toggleCamera } from '../reducers/rhinovideoReducer';

const RhinovideoToggleCameraButton = (props) => {
  const dispatch = useDispatch();
  const { isCameraEnabled, isButtonDisabled } = props;

  const handleToggle = () => dispatch(toggleCamera());

  return (
    <div>
      <Button
        type="link"
        size="large"
        iconOnly
        title={isCameraEnabled ? 'Video Off' : 'Video On'}
        onClick={handleToggle}
        className="rhinovideo__action-bar__button"
        disabled={isButtonDisabled}
      >
        <Icon size="large" icon={isCameraEnabled ? 'video' : 'video-off'} />
      </Button>
      <div className="rhinovideo__action-bar__label">
        <span>Video</span>
      </div>
    </div>
  );
};

RhinovideoToggleCameraButton.propTypes = {
  isCameraEnabled: PropTypes.bool.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { rhinovideo } = state;

  return ({
    isCameraEnabled: rhinovideo.isCameraEnabled,
    isButtonDisabled: rhinovideo.isButtonDisabled,
  });
};

export default connect(mapStateToProps)(RhinovideoToggleCameraButton);
