import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from 'rhinostyle';
import PropTypes from 'prop-types';
import { getInUseCampaign } from '../selectors/appointmentCampaignSelectors';

const AppointmentCampaignCheckbox = ({ itemId, isChecked, handleCheck, itemName, appointmentCampaignId, fieldName, currentOrganization }) => {
  const inUseCampaign = useSelector((state) => getInUseCampaign(state, { itemId, appointmentCampaignId, fieldName }));
  return (
    <Checkbox
      name={itemName}
      onChange={() => handleCheck(itemId)}
      isChecked={isChecked}
      key={itemId}
      dataFeatureTag={itemName}
      label={(
        <div className="form__block-group__label">{itemName}
          {(inUseCampaign && !currentOrganization.enableCampaignAppointmentTypeAndOffice) && (
            <span
              data-cypress={`${itemName}InUse`}
              className="appointment-reminder-form__types-accent u-text-small u-text-accent u-m-r u-font-weight-normal"
            > {`In use: ${inUseCampaign.name}`}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default AppointmentCampaignCheckbox;

AppointmentCampaignCheckbox.propTypes = {
  itemId: PropTypes.number.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleCheck: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  appointmentCampaignId: PropTypes.number,
  fieldName: PropTypes.string.isRequired,
  currentOrganization: PropTypes.object,
};
