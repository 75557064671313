import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import cx from 'classnames';
import {
  Avatar,
  Button,
  Close,
  Dropdown,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  ResourceGroup,
  Scrollbars,
  SlidingRadio,
  Tooltip,
  UtilityInlineGrid,
  UtilityList,
  UtilityListItem,
  DropdownSearchSelect,
  SortAZ,
} from 'rhinostyle';
import moment from 'moment-timezone';
import ReactHtmlParser from 'react-html-parser';
import * as UserReducer from '../reducers/userReducer';
import * as InboxReducer from '../reducers/inboxReducer';
import * as OfficeReducer from '../reducers/officeReducer';
import * as ThreadReducer from '../reducers/threadReducer';
import { cancelPaymentRequest, fetchMerchant, fetchPaymentRequestsByUser, updatePaymentRequest } from '../reducers/payReducer';
import { fetchAppointmentTypes } from '../reducers/appointmentTypesReducer';
import { sendFormById, fetchFormTemplates } from '../reducers/formReducer';
import { fetchAppointmentStatuses } from '../reducers/appointmentReducer';
import { organizationSelectors, userSelectors, inboxSelectors } from '../selectors';
import { fetchProviders } from '../reducers/providerReducer';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { Types } from '../constants';
import { PANEL_OPTIONS, MODAL_OPTIONS } from '../constants/ThreadConstants';
import {
  AVATAR_BASE_URL,
  NOTE_TRUNCATE_LENGTH,
} from '../constants/AppConstants';
import {
  THREAD_VIEW,
  CONTACT_EDIT,
  CONTACT_CREATE,
  CONTACT_DELETE,
  CONTACT_VIEW,
  CONVERSATION_CONTACT_MOBILE,
  CONTACT_ORGANIZATION_EDIT,
  PRESCRIPTION_DATA_VIEW,
  CONTACT_HIPAA_CONSENT_EDIT,
  CONTACT_MARKETING_CONSENT_EDIT,
  CONTACT_RHINOPAY_CONSENT_EDIT,
} from '../constants/UserPermissionsConstants';
import {
  TYPE_RHINOPAY_CONSENT_DECLINED,
  TYPE_RHINOPAY_CONSENT_PENDING,
  TYPE_RHINOPAY_CONSENT_GRANTED,
  TYPE_MARKETING_CONSENT_DECLINED,
  TYPE_MARKETING_CONSENT_PENDING,
  TYPE_MARKETING_CONSENT_GRANTED,
  TYPE_HIPAA_CONSENT_PENDING,
  TYPE_HIPAA_CONSENT_GRANTED,
  TYPE_HIPAA_CONSENT_DECLINED,
  TYPE_ORGANIZATION,
} from '../constants/Types';
import {
  DateHelpers,
  PhoneHelpers,
  DataHelpers,
  UserHelpers,
  StringHelpers,
  NumberHelpers,
} from '../helpers';
import * as PayHelpers from '../helpers/PayHelpers';
import { userHasAnyOfPermissions, userHasAllOfPermissions } from '../helpers/UserHelpers';
import { isMobile } from '../helpers/BrowserHelpers';
import UserSearchModalContainer from '../containers/UserSearchModalContainer';
import ConnectedPartyItemContainer from '../containers/ConnectedPartyItemContainer';
import CancelPaymentRequest from './CancelPaymentRequest';
import UserBadges from './UserBadges';
import ContactSummaryAppointmentsSection from './ContactSummaryAppointmentsSection';
import ContactProfileForms from './ContactProfileForms';
import SummaryPanelSection from './SummaryPanelSection';
import {
  useActiveUser,
  useThreadOptions,
} from '../hooks';
import ContactPrescriptions from './ContactPrescriptions';
import { syncTags } from '../helpers/TagHelpers';
import { userHasLimitedProviderRole } from '../selectors/userSelectors';

const ContactProfilePanel = (props) => {
  const {
    activeOrg,
    appointmentIntegrationStatusIds,
    appointmentTypeIds,
    canSendRhinopayRequests,
    connectedParties,
    doesActiveUserHaveFacebook,
    doesActiveUserHaveInstagram,
    doesActiveUserHavePhone,
    doesActiveUserHaveRhinogram,
    doesActiveUserOwnPhoneNumber,
    doesLoggedInUserHaveFromChannel,
    doesLoggedInUserHaveSecureFromChannel,
    emails,
    facebooks,
    instagrams,
    isActivePanel,
    isRhinoformEnabled,
    isRhinopayEnabled,
    isSecureInbox,
    merchant,
    officeIds,
    paymentRequests,
    phones,
    providerIds,
    rhinograms,
    setOpenContactLibraryPanel,
    tags,
    types,
    users,
    formTemplates,
    formTemplateIds,
    isFormSending,
    loading,
    isLimitedProvider,
  } = props;
  const activeContact = useActiveUser();
  const activeUser = activeContact || props.activeUser;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const threadOptions = useThreadOptions();
  const contactPanelRef = useRef();
  const currentUser = useSelector((state) => userSelectors.getLoggedInUser(state));
  const { isCcr } = currentUser;
  const isSavedContentEnabled = !!activeOrg?.isSavedContentEnabled;
  const isOfficeVCardEnabled = !!activeOrg?.isOfficeVCardEnabled;
  const supportsAppointments = !!activeOrg?.integrationPartnerTypeId && !activeOrg?.isPrescriptionManagerEnabled;
  const supportsPrescriptions = activeOrg?.arePrescriptionNotificationsEnabled || activeOrg?.isPrescriptionManagerEnabled;
  const [deleteContactInProgress, setDeleteContactInProgress] = useState(false);
  const [deleteContactModalOpen, setDeleteContactModalOpen] = useState(false);
  const [consentUpdateInProgress, setConsentUpdateInProgress] = useState(false);
  const [ownershipChangeInProgress, setOwnershipChangeInProgress] = useState(false);
  const [viewOtherPhoneOwnersId, setViewOtherPhoneOwnersId] = useState(-1);
  const [newOwnerId, setNewOwnerId] = useState(null);
  const [isPaymentRequestResending, setIsPaymentRequestResending] = useState(false);
  const [isCancelRequestDialogBoxOpen, setIsCancelRequestDialogBoxOpen] = useState(false);
  const [isAppointmentsSectionOpen, setIsAppointmentsSectionOpen] = useState(false);
  const [isFormSectionOpen, setIsFormSectionOpen] = useState(false);
  const [hipaaStatusTypeId, setHipaaStatusTypeId] = useState(activeUser.hipaaStatus?.typeId ?? null);
  const [rhinopayConsentStatusTypeId, setRhinopayConsentStatusTypeId] = useState(activeUser.rhinopayConsentStatus?.typeId ?? null);
  const [marketingConsentStatusTypeId, setMarketingConsentStatusTypeId] = useState(activeUser.marketingConsentStatus?.typeId ?? null);
  const [isProfileNoteTruncated, setIsProfileNoteTruncated] = useState(activeUser.note && activeUser.note.length > NOTE_TRUNCATE_LENGTH);
  const [isProfileNoteAboveTruncateLength, setIsProfileNoteAboveTruncateLength] = useState(activeUser.note && activeUser.note.length > NOTE_TRUNCATE_LENGTH);
  const [selectedFormTemplateId, setFormTemplateId] = useState(null);
  const [selectedFormTemplate, setFormTemplate] = useState(null);
  const [formSearchLoading, setFormSearchLoading] = useState(false);
  const [formLabel, setFormLabel] = useState('Select Form');
  const [filteredFormTemplates, setFilteredFormTemplates] = useState(formTemplates);
  const [filteredFormTemplateIds, setFilteredFormTemplateIds] = useState(formTemplateIds);
  const [tagIds, setTagIds] = useState([]);
  const [sort, setSortDirection] = useState('desc');

  const isContactPage = location.pathname.includes('contacts');
  const isInboxPage = location.pathname.includes('inbox');

  const profileImageUrl = activeUser.profileImageUrl ? `${AVATAR_BASE_URL}${activeUser.profileImageUrl}` : '';

  const textableTooltipText = 'If you believe this number is textable, send a message, and it will be updated as textable if delivered.';

  const setInitialState = () => {
    setDeleteContactInProgress(false);
    setDeleteContactModalOpen(false);
    setConsentUpdateInProgress(false);
    setOwnershipChangeInProgress(false);
    setViewOtherPhoneOwnersId(-1);
    setIsProfileNoteTruncated(true);
    setNewOwnerId(null);
    setIsPaymentRequestResending(false);
    setIsCancelRequestDialogBoxOpen(false);
    setIsAppointmentsSectionOpen(false);
    setFormTemplateId(null);
    setFormTemplate(null);
    setFormLabel('Select Form');
    setFilteredFormTemplates(formTemplates);
    setFilteredFormTemplateIds(formTemplateIds);
    setSortDirection('desc');
  };

  useEffect(() => {
    if (!appointmentTypeIds.length) {
      dispatch(fetchAppointmentTypes(activeOrg.id));
    }
    if (!officeIds.length) {
      dispatch(OfficeReducer.fetchOffices());
    }
    if (!providerIds.length) {
      dispatch(fetchProviders(activeOrg.id));
    }
    if (!appointmentIntegrationStatusIds.length) {
      dispatch(fetchAppointmentStatuses(activeOrg.id));
    }
    if (isRhinoformEnabled && isContactPage) {
      dispatch(fetchFormTemplates('all', true));
    }

    if (!Object.keys(merchant).length && (isMobile() ? userHasAllOfPermissions([CONTACT_EDIT, CONVERSATION_CONTACT_MOBILE]) :
      userHasAnyOfPermissions([CONTACT_EDIT]))) {
      dispatch(fetchMerchant(activeOrg.id));
    }
  }, []);

  function compareTagName(a, b) {
    return (a[1].name.toLowerCase().localeCompare(b[1].name.toLowerCase()));
  }

  const handleSort = (direction) => {
    let newSortDirection = sort === 'asc' ? 'desc' : 'asc';
    // On panel load always display tags in alphabetical order
    if (direction === 'asc') {
      newSortDirection = 'desc';
    }
    setSortDirection(newSortDirection);

    let sorted;
    if (newSortDirection === 'asc') {
      sorted = Object.entries(tags).sort(compareTagName).reverse();
    } else {
      sorted = Object.entries(tags).sort(compareTagName);
    }
    const getIdColumn = sorted.map((el) => parseInt(el[0], 10));
    let filtered;
    if (typeof activeUser.tags[0] === 'object') {
      filtered = getIdColumn.filter((el) => activeUser.tags.map((e) => e.id).includes(el));
    } else {
      filtered = getIdColumn.filter((el) => activeUser.tags.includes(el));
    }
    setTagIds(filtered);
  };

  useEffect(() => {
    setFormTemplateId(null);
    setFormTemplate(null);
    setFormLabel('Select Form');
    if (isContactPage && !activeUser.organization) {
      dispatch(UserReducer.fetchUser(activeUser.id));
    } else if (activeUser?.organization) {
      dispatch(UserReducer.setActiveUser(activeUser.id));
    }
    if (activeUser.tags.length > 0) {
      if (typeof activeUser.tags[0] === 'object') {
        setTagIds(activeUser.tags.map((el) => el.id));
      } else {
        setTagIds(activeUser.tags);
      }
      handleSort('asc');
    }
  }, [activeUser]);

  useEffect(() => {
    if (activeUser.id) {
      setInitialState();
      if (isActivePanel) {
        // Scroll to top of profile if user changes
        const scrollContainer = contactPanelRef?.current.container.firstChild;
        scrollContainer.scrollTop = 0;
      }
      setViewOtherPhoneOwnersId(-1);
      setIsProfileNoteTruncated(activeUser.note && activeUser.note.length > NOTE_TRUNCATE_LENGTH);
      setIsProfileNoteAboveTruncateLength(activeUser.note && activeUser.note.length > NOTE_TRUNCATE_LENGTH);
      if (activeUser && (isMobile() ? userHasAllOfPermissions([CONTACT_EDIT, CONVERSATION_CONTACT_MOBILE]) :
        userHasAnyOfPermissions([CONTACT_EDIT]))) {
        dispatch(fetchPaymentRequestsByUser(activeUser.id));
      }
    }
  }, [activeUser?.id]);

  useEffect(() => {
    if (activeUser.hipaaStatus) {
      setHipaaStatusTypeId(activeUser.hipaaStatus.typeId);
    }
  }, [activeUser?.hipaaStatus]);

  useEffect(() => {
    if (activeUser.rhinopayConsentStatus) {
      setRhinopayConsentStatusTypeId(activeUser.rhinopayConsentStatus.typeId);
    }
  }, [activeUser?.rhinopayConsentStatus]);

  useEffect(() => {
    if (activeUser.marketingConsentStatus) {
      setMarketingConsentStatusTypeId(activeUser.marketingConsentStatus.typeId);
    }
  }, [activeUser?.marketingConsentStatus]);

  useEffect(() => {
    setFilteredFormTemplates(formTemplates);
    setFilteredFormTemplateIds(formTemplateIds);
  }, [formTemplates, formTemplateIds]);

  const handleConsentChangeConfirm = () => {
    setConsentUpdateInProgress(true);
    const data = {
      hipaaStatus: {
        trustee: currentUser.id,
        userId: activeUser.id,
        typeId: hipaaStatusTypeId,
        hasChanged: activeUser.hipaaStatus?.typeId !== hipaaStatusTypeId,
      },
      rhinopayConsentStatus: {
        trustee: currentUser.id,
        userId: activeUser.id,
        typeId: rhinopayConsentStatusTypeId,
        hasChanged: activeUser.rhinopayConsentStatus?.typeId !== rhinopayConsentStatusTypeId,
      },
      marketingConsentStatus: {
        trustee: currentUser.id,
        userId: activeUser.id,
        typeId: marketingConsentStatusTypeId,
        hasChanged: activeUser.marketingConsentStatus?.typeId !== marketingConsentStatusTypeId,
      },
    };
    dispatch(InboxReducer.updateConsentStatus(data))
      .then(() => {
        setConsentUpdateInProgress(false);
      });
  };

  const handleAddToExisting = (slaveId, masterId) => dispatch(UserReducer.mergeUsers(slaveId, masterId));

  const handleCloseProfileClick = () => {
    if (isContactPage) {
      history.push('/contacts');
    } else if (isInboxPage) {
      dispatch(ThreadReducer.toggleProfile());
    }
  };

  const handleUpdateFormSelectedId = (selectedId, selectedItem) => {
    setFormTemplateId(selectedId);
    setFormTemplate(selectedItem);
    setFormLabel(selectedItem?.title);
  };

  const fetchAllItems = (searchText) => {
    setFormSearchLoading(true);
    let searchedItems = {};
    if (searchText.length > 0) {
      // eslint-disable-next-line array-callback-return
      Object.keys(formTemplates).map((k) => {
        if ((formTemplates[k].title.toLowerCase()).includes(searchText.toLowerCase())) {
          searchedItems[k] = formTemplates[k];
        }
      });
    } else {
      searchedItems = formTemplates;
    }
    setFilteredFormTemplates(searchedItems);
    setFilteredFormTemplateIds(Object.keys(searchedItems));
    setFormSearchLoading(false);
  };

  const handleDeleteContact = (id) => {
    setDeleteContactInProgress(true);
    dispatch(UserReducer.deleteUser(id))
      .then(() => {
        setDeleteContactInProgress(false);
        setDeleteContactModalOpen(false);
      });
  };

  const handleEditProfileClick = () => {
    history.push(`/contacts/edit/${activeUser.id}`);
  };

  const handleGoToConversation = () => {
    history.push(`/inbox/all/user/${activeUser.id}`);
  };

  const handleOwnershipChange = (userObj, phoneToUpdate) => {
    const userPhones = userObj.phones.map((phoneId) => {
      const phone = phones[phoneId];

      return {
        id: phone.id,
        typeId: phone.typeId,
        value: phone.value,
        otherUsers: phone.otherUsers,
        ownerId: newOwnerId != null && phone.id === phoneToUpdate.id ? newOwnerId : phone.ownerId,
      };
    });

    const payload = {
      id: userObj.id,
      typeId: userObj.typeId,
      phones: userPhones,
    };

    setOwnershipChangeInProgress(true);
    dispatch(UserReducer.updateUser(userObj.id, payload))
      .then(() => {
        setOwnershipChangeInProgress(false);
        setViewOtherPhoneOwnersId(-1);
      });
  };

  const handleToggleRhinopayModal = () => {
    dispatch(ThreadReducer.toggleModal(MODAL_OPTIONS.rhinopay));
  };

  const paymentRequestPayload = { userId: activeUser.id };

  const handleResendPaymentRequest = () => {
    setIsPaymentRequestResending(true);

    dispatch(updatePaymentRequest(paymentRequestPayload))
      .then(() => {
        const paymentRequestMessageText = PayHelpers.generatePaymentRequestMessageText(paymentRequests[activeUser.id], activeOrg);
        dispatch(InboxReducer.handleSendMessage({ messageText: paymentRequestMessageText, threadOptions }));
        setIsPaymentRequestResending(false);
      });
  };

  const handleCancelPaymentRequest = async () => {
    const paymentRequestId = paymentRequests[activeUser.id].id;
    await dispatch(cancelPaymentRequest(paymentRequestId, activeUser.id));
  };

  const handleGenerateForm = () => {
    const payload = {
      patientId: activeUser.id,
      senderId: currentUser.id,
    };
    dispatch(sendFormById(selectedFormTemplateId, payload, true));
  };

  const toggleCancelRequestDialogBox = () => setIsCancelRequestDialogBoxOpen(!isCancelRequestDialogBoxOpen);

  const handleUserTransition = async (userId) => {
    const inboxLocation = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
    await dispatch(UserReducer.fetchUser(userId));

    if (isContactPage) {
      history.push(`/contacts/${userId}`);
    } else if (isInboxPage) {
      history.push(`${inboxLocation}/${userId}`);
    }
  };

  const onReverseComplete = () => {
    if (deleteContactInProgress) {
      const navigateToContactsPage = location.pathname.includes('all') || location.pathname.includes('contacts');
      const newLocation = navigateToContactsPage ? '/contacts' : location.pathname.substring(0, location.pathname.indexOf('/user'));
      history.push(newLocation);
    } else {
      setInitialState();
    }
  };

  const toggleProfileNoteTruncate = () => setIsProfileNoteTruncated(!isProfileNoteTruncated);

  const openCloseAppointmentsSection = () => setIsAppointmentsSectionOpen(!isAppointmentsSectionOpen);

  const phoneOwnershipLabel = (userObj, owner) => (
    <>
      <div className="profile__data__phone__box__label">
        {UserHelpers.formatNameOrUnknown(userObj)}{owner && <span className="u-text-muted"> (Current Owner)</span>}
      </div>
      {!isLimitedProvider && userObj.userId && (
        <Button reset className="u-text-primary" onClick={() => handleUserTransition(userObj.userId)}>
          View Profile
        </Button>
      )}
    </>
  );

  const renderPhoneOtherUsers = (ownerId) => (otherUser, idx) => {
    const otherUserIsOwner = otherUser.userId === ownerId;

    return (
      <Radio
        key={idx}
        value={otherUser.userId}
        label={phoneOwnershipLabel(otherUser, otherUserIsOwner)}
      />
    );
  };

  const renderPhone = (id, key) => {
    const phone = phones[id] || id || {};
    // Used to build an array of other users that do not include duplicates (i.e. if a user has the number attached to their profile twice)
    const uniqueOtherUsers = phone.otherUsers
      && phone.otherUsers.filter((u, index, otherUsers) => (
        index === otherUsers.findIndex((item) => item.userId === u.userId)
        && u.userId !== activeUser.id
      ));
    const hasOtherUsers = uniqueOtherUsers && !!uniqueOtherUsers.length;
    const userIsOwner = activeUser.id === phone.ownerId;
    const showOtherUsers = viewOtherPhoneOwnersId === id;
    const associatedWithOtherContactsText = showOtherUsers ? 'Close' : 'View';
    const associatedWithOtherContactsFunction = () => setViewOtherPhoneOwnersId(showOtherUsers ? -1 : id);

    return (
      <UtilityListItem key={key}>
        {PhoneHelpers.formatPhone(phone.value)}
        &nbsp;
        <span className="u-text-muted u-text-small">({PhoneHelpers.formatTypes(phone)})</span>
        &nbsp;
        {phone.hasOptedOut && (
          <>
            <span className="profile__data__opted-out-label">
              Opted Out
            </span>
            &nbsp;
          </>
        )}
        &nbsp;
        {!phone.isTextable && (
          <>
            <span className="u-text-accent u-text-small">
              Non-textable &nbsp;
              <Tooltip placement="bottom" content={textableTooltipText} type="dark">
                <Icon bump="up" icon="info-circle" className="u-text-primary" />
              </Tooltip>
            </span>
            &nbsp;
          </>
        )}
        {!PhoneHelpers.isPhoneNumberValid(phone.value) && <div className="u-text-muted u-text-small u-text-italic">Not a valid 10 digit number</div>}
        {hasOtherUsers && !userIsOwner && <div className="u-text-accent u-text-small">Not the Owner</div>}
        {hasOtherUsers && (
          <>
            <div className="u-text-small u-text-muted u-m-b-small">
              Associated with other contacts |&nbsp;
              <Button
                reset
                className="u-text-primary"
                title="Edit profile to change ownership."
                onClick={associatedWithOtherContactsFunction}
              >
                {associatedWithOtherContactsText}
              </Button>
            </div>
            {showOtherUsers && (
              <div className="profile__data__phone__box">
                <FormLabel
                  id="update_phone_ownership_box"
                  className="profile__data__phone__label"
                >
                  Update Phone Ownership
                </FormLabel>
                <RadioGroup
                  blockGroup
                  name="phoneOwnership"
                  onChange={(userId) => setNewOwnerId(userId)}
                  selectedValue={phone.ownerId}
                >
                  <Radio
                    key={activeUser.id}
                    value={activeUser.id}
                    label={phoneOwnershipLabel(activeUser, userIsOwner)}
                  />
                  {uniqueOtherUsers.map(renderPhoneOtherUsers(phone.ownerId))}
                </RadioGroup>
                {/* Only show this 👇 footer area if the radio is changed from the default */}
                {/* Add loading prop to button while updating */}
                {true && (
                  <div className="profile__data__phone__box__footer">
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => handleOwnershipChange(activeUser, phone)}
                      loading={ownershipChangeInProgress}
                    >
                      Update Ownership
                    </Button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </UtilityListItem>
    );
  };

  const renderEmail = (id, key) => {
    const e = emails[id] || id;

    return (
      <UtilityListItem key={key}>{e.value}</UtilityListItem>
    );
  };

  const renderFacebook = (id, key) => {
    const f = facebooks[id] || id;

    return (
      <UtilityListItem key={key}>
        {f ? UserHelpers.formatFacebookName(activeUser, f.value) : ''} <span className="u-text-muted u-text-small">(Facebook)</span>
      </UtilityListItem>
    );
  };

  const renderAge = (birthday) => {
    const age = moment().diff(birthday, 'years', false);
    const ageLabel = age < 18 ? `${age} (Minor)` : age;
    const ageClasses = cx('', {
      'is-minor': age < 18,
    });
    return (
      <span className={ageClasses}>Age: {ageLabel} </span>
    );
  };

  const renderInstagram = (id, key) => {
    const insta = instagrams[id] || id;

    return (
      <UtilityListItem key={key}>
        {insta ? UserHelpers.formatInstagramName(activeUser, insta) : ''} <span className="u-text-muted u-text-small">(Instagram)</span>
      </UtilityListItem>
    );
  };
  const renderRhinogram = (id, key) => {
    const r = rhinograms[id] || id;
    return (
      <UtilityListItem key={key}>
        {r ? UserHelpers.formatRhinogramName(activeUser, r) : ''} <span className="u-text-muted u-text-small">(Rhinogram)</span>
      </UtilityListItem>
    );
  };

  const renderConnectedParty = (id, key) => {
    const cp = connectedParties[id] || id;

    if (!cp) return null;

    const ct = types[cp.connectionTypeId];
    const cpt = types[cp.connectedPartyTypeId];

    return <ConnectedPartyItemContainer profileOpen key={key} connection={cp} type={ct} connectedPartyType={cpt} history={history} />;
  };
  const hipaaStatuses = [TYPE_HIPAA_CONSENT_DECLINED, TYPE_HIPAA_CONSENT_PENDING, TYPE_HIPAA_CONSENT_GRANTED];
  const rhinopayConsentStatuses = [TYPE_RHINOPAY_CONSENT_DECLINED, TYPE_RHINOPAY_CONSENT_PENDING, TYPE_RHINOPAY_CONSENT_GRANTED];
  const marketingConsentStatuses = [TYPE_MARKETING_CONSENT_DECLINED, TYPE_MARKETING_CONSENT_PENDING, TYPE_MARKETING_CONSENT_GRANTED];
  const consentStatusColorClasses = ['danger', 'warning', 'secondary'];
  const renderHipaaSlidingRadioOptions = () => (
    hipaaStatuses.map((typeId, index) => ({
      label: UserHelpers.formatHipaaStatus(typeId),
      value: typeId,
      type: consentStatusColorClasses[index],
    }))
  );

  const renderRhinoPaySlidingRadioOptions = () => (
    rhinopayConsentStatuses.map((typeId, index) => ({
      label: UserHelpers.formatRhinoPayStatus(typeId),
      value: typeId,
      type: consentStatusColorClasses[index],
    }))
  );

  const renderMarketingSlidingRadioOptions = () => (
    marketingConsentStatuses.map((typeId, index) => ({
      label: UserHelpers.formatMarketingStatus(typeId),
      value: typeId,
      type: consentStatusColorClasses[index],
    }))
  );

  const renderForwarding = () => {
    if (!activeUser.forwarding) return null;

    const renderForwardingToUser = (userObj) => (
      <>Set to {UserHelpers.formatName(userObj)} <span className="u-text-small u-text-muted">(Member)</span></>
    );

    const renderForwardingToUserGroup = (group) => (
      <>Set to {group.name} <span className="u-text-small u-text-muted">(Group)</span></>
    );

    return (
      <SummaryPanelSection
        label="Forwarding"
        dataCypress="forwardingToggle"
      >
        <div className="summary-panel__section__content summary-panel__section__content--indented">
          <span className="u-block">
            {activeUser.forwarding.userId ? renderForwardingToUser(activeUser.forwarding.toUser) : renderForwardingToUserGroup(activeUser.forwarding.toUserGroup)}
          </span>
        </div>
      </SummaryPanelSection>
    );
  };

  const renderConsent = () => {
    const hipaaStatusTrustee = users[activeUser.hipaaStatus?.trusteeId];
    const rhinopayConsentStatusTrustee = users[activeUser.rhinopayConsentStatus.trusteeId];
    const marketingConsentStatusTrustee = users[activeUser.marketingConsentStatus.trusteeId];
    // This is for migrated contacts marked as `Unknown` at the time of the HIPAA feature and/or RhinoPay feature being turned on
    // If a contact does not have a trusteeId for a rhinopay or hipaa consent status, and that status is marked as "unknown" then do not try to render the signed text
    const showSignedHipaaText = !(!hipaaStatusTrustee && activeUser.hipaaStatus?.typeId === TYPE_HIPAA_CONSENT_PENDING);
    const showSignedRhinopayConsentText = !(!rhinopayConsentStatusTrustee && activeUser.rhinopayConsentStatus?.typeId === TYPE_RHINOPAY_CONSENT_PENDING);
    const showSignedMarketingConsentText = !(!marketingConsentStatusTrustee && activeUser.marketingConsentStatus?.typeId === TYPE_MARKETING_CONSENT_PENDING);

    const hipaaIconClasses = cx('consent-status__icon u-m-r-small', {
      'is-denied': activeUser.hipaaStatus?.typeId === TYPE_HIPAA_CONSENT_DECLINED,
      'is-unknown': activeUser.hipaaStatus?.typeId === TYPE_HIPAA_CONSENT_PENDING,
      'is-granted': activeUser.hipaaStatus?.typeId === TYPE_HIPAA_CONSENT_GRANTED,
    });

    const rhinopayIconClasses = cx('consent-status__icon u-m-r-small u-inline-flex ', {
      'is-denied': activeUser.rhinopayConsentStatus?.typeId === TYPE_RHINOPAY_CONSENT_DECLINED,
      'is-unknown': activeUser.rhinopayConsentStatus?.typeId === TYPE_RHINOPAY_CONSENT_PENDING,
      'is-granted': activeUser.rhinopayConsentStatus?.typeId === TYPE_RHINOPAY_CONSENT_GRANTED,
    });

    const marketingIconClasses = cx('consent-status__icon u-m-r-small u-inline-flex ', {
      'is-denied': activeUser.marketingConsentStatus?.typeId === TYPE_MARKETING_CONSENT_DECLINED,
      'is-unknown': activeUser.marketingConsentStatus?.typeId === TYPE_MARKETING_CONSENT_PENDING,
      'is-granted': activeUser.marketingConsentStatus?.typeId === TYPE_MARKETING_CONSENT_GRANTED,
    });
    return (
      <SummaryPanelSection
        label="Communication Consent"
        dataCypress="consentToggle"
      >
        <div className="summary-panel__section__content summary-panel__section__content--full summary-panel__consent">
          <div className="sliding-radio__container">
            <div className="u-flex u-flex-wrap">
              <div className="summary-panel__slidingradio__label">
                <Icon bump="up" size="large" icon="hipaa" className={hipaaIconClasses} />
                <div className="u-inline-block">HIPAA</div>
              </div>
              <div className="u-m-b u-flex-1">
                <SlidingRadio
                  selectedValue={hipaaStatusTypeId}
                  name="hipaaStatus"
                  disabled={isCcr || !userHasAllOfPermissions([CONTACT_HIPAA_CONSENT_EDIT])}
                  slidingRadioContainerClass="u-text-small flex justify-between"
                  options={renderHipaaSlidingRadioOptions()}
                  onChange={(id) => setHipaaStatusTypeId(id)}
                />
              </div>
            </div>
            <span className="form__block-group__desc">
              {UserHelpers.getHipaaDescription(hipaaStatusTypeId)}
            </span>
            {showSignedHipaaText && activeUser.hipaaStatus?.lastUpdatedAt && (
              <p className="u-text-small u-text-muted u-text-italic">{UserHelpers.fetchSignedText(hipaaStatusTrustee, activeUser.hipaaStatus?.lastUpdatedAt)}</p>
            )}
          </div>
          {isRhinopayEnabled && (
            <div className="sliding-radio__container">
              <div className="u-flex u-flex-wrap">
                <div className="summary-panel__slidingradio__label">
                  <Icon bump="up" size="large" icon="rhinopay" className={rhinopayIconClasses} />
                  <div className="u-inline-block">RhinoPay</div>
                </div>
                <div className="u-m-b u-flex-1">
                  <SlidingRadio
                    selectedValue={rhinopayConsentStatusTypeId}
                    name="rhinopayConsentStatus"
                    disabled={isCcr || !userHasAllOfPermissions([CONTACT_RHINOPAY_CONSENT_EDIT])}
                    slidingRadioContainerClass="u-text-small flex justify-between"
                    options={renderRhinoPaySlidingRadioOptions()}
                    onChange={(id) => setRhinopayConsentStatusTypeId(id)}
                  />
                </div>
              </div>
              <span className="form__block-group__desc u-m-b-small">
                {UserHelpers.getRhinoPayDescription(rhinopayConsentStatusTypeId)}
              </span>
              {showSignedRhinopayConsentText && activeUser.hipaaStatus?.lastUpdatedAt && (
                <p className="u-text-small u-text-muted u-text-italic">
                  {UserHelpers.fetchSignedText(rhinopayConsentStatusTrustee, activeUser.rhinopayConsentStatus.lastUpdatedAt)}
                </p>
              )}
            </div>
          )}
          <div className="sliding-radio__container">
            <div className="u-flex u-flex-wrap">
              <div className="summary-panel__slidingradio__label">
                <Icon bump="up" size="large" icon="marketing" className={marketingIconClasses} />
                <div className="u-inline-block">Marketing</div>
              </div>
              <div className="u-m-b u-flex-1">
                <SlidingRadio
                  selectedValue={marketingConsentStatusTypeId}
                  name="marketingConsentStatus"
                  disabled={isCcr || !userHasAllOfPermissions([CONTACT_MARKETING_CONSENT_EDIT])}
                  slidingRadioContainerClass="u-text-small flex justify-between"
                  options={renderMarketingSlidingRadioOptions()}
                  onChange={(id) => setMarketingConsentStatusTypeId(id)}
                />
              </div>
            </div>
            <span className="form__block-group__desc u-m-b-small">
              {UserHelpers.getMarketingDescription(marketingConsentStatusTypeId)}
            </span>
            {showSignedMarketingConsentText && (
              <p className="u-text-small u-text-muted u-text-italic">
                {UserHelpers.fetchSignedTextMarketing(marketingConsentStatusTrustee, activeUser.marketingConsentStatus.lastUpdatedAt)}
              </p>
            )}
          </div>
          <>
            {(hipaaStatusTypeId !== activeUser.hipaaStatus?.typeId ||
              rhinopayConsentStatusTypeId !== activeUser.rhinopayConsentStatus?.typeId ||
              marketingConsentStatusTypeId !== activeUser.marketingConsentStatus?.typeId) && (
                <div className="sliding-radio__content">
                  <div className="hipaa-status__confirm">
                    <Button
                      size="small"
                      type="primary"
                      onClick={handleConsentChangeConfirm}
                      loading={consentUpdateInProgress}
                      data-cypress="updatePreference"
                    >
                      Update Preference
                    </Button>
                  </div>
                </div>
            )}
          </>
        </div>
      </SummaryPanelSection>
    );
  };

  const renderPatientBalanceAndPaymentRequests = () => {
    let requestActionTimestamp;
    let paymentStatus = '';
    const paymentSentStatus = 'Sent';
    const isRhinopayConsentGranted = activeUser.rhinopayConsentStatus?.typeId === TYPE_RHINOPAY_CONSENT_GRANTED;
    const userPaymentRequest = paymentRequests[activeUser.id];

    if (userPaymentRequest) {
      if (userPaymentRequest.isPaid === 1) {
        requestActionTimestamp = userPaymentRequest.requestPaidTimestamp;
        paymentStatus = 'Paid';
      } else if (userPaymentRequest.isPaid === 0 && userPaymentRequest.deleted === 0) {
        requestActionTimestamp = userPaymentRequest.requestSentTimestamp;
        paymentStatus = 'Sent';
      } else if (userPaymentRequest.isPaid === 0 && userPaymentRequest.deleted === 1) {
        requestActionTimestamp = userPaymentRequest.lastUpdatedAt;
        paymentStatus = 'Cancelled';
      }
    }

    const doesActiveUserHavePhoneOrFacebookOrRhinogram =
    doesActiveUserHaveFacebook || doesActiveUserHavePhone || doesActiveUserHaveRhinogram || doesActiveUserHaveInstagram;

    let tooltipText = '';
    if (isCcr) {
      tooltipText = 'Customer care representatives cannot send payment requests.';
    } else if (!isRhinopayEnabled) {
      tooltipText = 'Your organization must first purchase RhinoPay services to use this feature. Contact our Customer Success team for more information.';
    } else if (!isInboxPage) {
      tooltipText = 'Go to the Conversation thread to send a payment request.';
    } else if (!userHasAnyOfPermissions([CONTACT_EDIT])) {
      tooltipText = 'Only members with edit access to Contacts and Conversations can use this feature. See your administrator for more details.';
    } else if (!doesActiveUserHavePhoneOrFacebookOrRhinogram) {
      tooltipText = 'There is no phone number or Social account associated to this contact.';
    } else if (doesActiveUserHavePhone && !doesActiveUserOwnPhoneNumber) {
      tooltipText = 'Payment requests can only be sent to an owner of a phone number.';
    } else if (!isRhinopayConsentGranted) {
      tooltipText = 'Contact has not given RhinoPay consent. Only contacts who have granted consent for payment communication can use this feature.';
    } else if (!isSecureInbox && !doesLoggedInUserHaveFromChannel) {
      tooltipText = 'You have no SMS or Social channels available. Create one or switch to a RhinoSecure channel.';
    } else if (isSecureInbox && !doesLoggedInUserHaveSecureFromChannel) {
      tooltipText = 'You have no RhinoSecure channels available. Create one or switch to an SMS or Social channel.';
    }

    return (
      <SummaryPanelSection label="Balance Due" dataCypress="payToggle">
        <div className="summary-panel__section__content summary-panel__section__content--indented">
          <span className="u-block">Refer to your Financial Records</span>
          <div>
            <Button
              disabled={!canSendRhinopayRequests}
              reset
              type="link"
              size="small"
              className="u-text-primary"
              onClick={() => handleToggleRhinopayModal()}
            >
              Request New Payment
            </Button>
            {!canSendRhinopayRequests && (
              <>
                &nbsp;
                <Tooltip placement="top" content={tooltipText}>
                  <Icon icon="info-circle" className="u-text-muted" />
                </Tooltip>
              </>
            )}
          </div>
          {userPaymentRequest && (
            <div key={userPaymentRequest.id} className="u-m-t-small">
              <span className="u-block">
                {/* eslint-disable-next-line max-len */}
                Request&nbsp;{paymentStatus}&nbsp;${NumberHelpers.formatNumberToCurrencyString(userPaymentRequest.requestAmount)}
                &nbsp;
                <span className="u-text-muted u-text-small">
                  ({DateHelpers.formatTimestamp(requestActionTimestamp, 'M/D/YY')})
                </span>
              </span>
              {paymentStatus === paymentSentStatus && (
                <div>
                  <Button
                    disabled={!canSendRhinopayRequests}
                    reset
                    loading={isPaymentRequestResending}
                    type="link"
                    size="small"
                    className="u-text-primary"
                    onClick={() => handleResendPaymentRequest()}
                  >
                    Resend Previous Request
                  </Button>
                  <span>&nbsp;|&nbsp;</span>
                  <Button
                    disabled={!canSendRhinopayRequests}
                    reset
                    type="link"
                    size="small"
                    onClick={toggleCancelRequestDialogBox}
                  >
                    Cancel Request
                  </Button>
                </div>
              )}
            </div>
          )}
          <CancelPaymentRequest
            handleCancelPaymentRequest={handleCancelPaymentRequest}
            toggleCancelRequestDialogBox={toggleCancelRequestDialogBox}
            isCancelRequestDialogBoxOpen={isCancelRequestDialogBoxOpen}
          />
        </div>
      </SummaryPanelSection>
    );
  };

  const renderFormTemplates = () => (
    <div className="dropdown-search-select">
      <DropdownSearchSelect
        items={filteredFormTemplates}
        itemsIds={filteredFormTemplateIds}
        selectedItem={selectedFormTemplate}
        selectedItemId={selectedFormTemplateId}
        dataCypress="selectIndividualForm"
        filterName="Search Form"
        dropdownLabel={formLabel}
        handleUpdateSelectedId={handleUpdateFormSelectedId}
        fetchAllItems={fetchAllItems}
        itemSearchLoading={formSearchLoading}
        className="multi-select__dropdown--fixed-height"
      />
    </div>
  );

  const renderContactForms = () => (
    <div className="summary-panel__section summary-panel__section--full" style={{ paddingBottom: '1.6rem' }}>
      <div className="summary-panel__section__label">
        Form Activity
        <div className="summary-panel__section__label__action">
          <Icon
            icon={isFormSectionOpen ? 'caret-up' : 'caret-down'}
            className="u-text-muted"
            onClick={() => setIsFormSectionOpen(!isFormSectionOpen)}
            data-cypress="formActivity"
          />
        </div>
      </div>
      {isFormSectionOpen && (
        <div>
          {renderFormTemplates()}
          <div className="u-flex u-flex-justify-end">
            <Button
              loading={isFormSending}
              disabled={!selectedFormTemplateId}
              onClick={() => handleGenerateForm()}
              type="secondary"
            >
              Generate Form
            </Button>
          </div>
          <div className="summary-panel__section__content summary-panel__section__content--indented u-m-t-small">
            <ContactProfileForms />
          </div>
        </div>
      )}
    </div>
  );

  const renderTag = (tagId, key) => {
    const tag = tags[tagId];

    return (
      <UtilityListItem key={key}>
        #{tag?.name}
      </UtilityListItem>
    );
  };

  const renderActionButtons = () => (
    <UtilityInlineGrid align="middle">
      {(isContactPage && (isMobile() ?
        userHasAllOfPermissions([THREAD_VIEW, CONVERSATION_CONTACT_MOBILE]) :
        userHasAnyOfPermissions([THREAD_VIEW]))) && (
          <Button
            onClick={() => handleGoToConversation()}
            size="small"
            type="primary"
            data-cypress="goToConversation"
          >
            Go to Conversation
          </Button>
      )}
      {(UserHelpers.isUnknown(activeUser) &&
        (isMobile() ? userHasAllOfPermissions([CONTACT_CREATE, CONVERSATION_CONTACT_MOBILE]) :
          userHasAnyOfPermissions([CONTACT_CREATE]))) && (
          <>
            <Button
              onClick={handleEditProfileClick}
              size="small"
              type="secondary"
            >
              Create New Contact
            </Button>
          </>
      )}
      {(!UserHelpers.isUnknown(activeUser)
        && (activeUser.typeId === Types.TYPE_PATIENT || activeUser.typeId === Types.TYPE_USER_OTHER || activeUser.typeId === Types.TYPE_ORGANIZATION)
        && (isMobile() ? userHasAllOfPermissions([CONVERSATION_CONTACT_MOBILE, CONTACT_EDIT]) :
          userHasAnyOfPermissions([CONTACT_EDIT])))
        && (activeUser.typeId === Types.TYPE_ORGANIZATION ? userHasAnyOfPermissions([CONTACT_ORGANIZATION_EDIT]) : true)
        && !isLimitedProvider
        && (
          <Button
            onClick={handleEditProfileClick}
            size="small"
            type="primary"
            outlined
            data-cypress="editProfile"
          >
            Edit Profile
          </Button>
        )}
      {(!loading && !activeUser.integrated && activeUser.typeId !== TYPE_ORGANIZATION
        && (isMobile() ? userHasAllOfPermissions([CONVERSATION_CONTACT_MOBILE, CONTACT_CREATE]) :
          userHasAnyOfPermissions([CONTACT_CREATE])))
        && (
          <Dropdown
            title="Merge Contacts"
            icon="dots-horizontal"
            type="outline"
            hideCaret
            position="right"
            className="summary-panel__intro__actions__more-button"
          >
            <UserSearchModalContainer
              handleFormConfirm={handleAddToExisting}
              handleSearchSelect={handleUserTransition}
              mergeUsers
              scope="patientsAndOthers"
              slaveUser={activeUser}
              location={location}
              history={history}
            />
          </Dropdown>
        )}
    </UtilityInlineGrid>
  );

  const deleteContactModal = () => (
    <Modal onReverseComplete={onReverseComplete} open={deleteContactModalOpen}>
      <ModalHeader
        onClose={() => setDeleteContactModalOpen(!deleteContactModalOpen)}
        title="Delete Contact"
      />
      <ModalBody>
        Are you sure you want to delete {UserHelpers.formatName(activeUser)} from your organization&apos;s contacts?
      </ModalBody>
      <ModalFooter>
        <UtilityInlineGrid align="between">
          <Button onClick={() => setDeleteContactModalOpen(!deleteContactModalOpen)}>
            Cancel
          </Button>
          <Button loading={deleteContactInProgress} onClick={() => handleDeleteContact(activeUser.id)} type="danger" data-cypress="confirmDelete">
            Delete Contact
          </Button>
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );

  const getProfileNote = () => {
    const { note } = activeUser;

    let html = note.replace(/(\n|\r)+/g, '<br />');
    if (isProfileNoteTruncated) {
      const viewMoreText = '...View More';
      html = `${html.substr(0, NOTE_TRUNCATE_LENGTH - viewMoreText.length)}... `;
    } else {
      html = `${html} `;
    }

    return html;
  };

  // Only show `x` button on desktop on the contacts page since they can toggle that profile panel
  const summaryPanelWrapperClasses = cx('summary-panel__wrapper', {
    'app-panels--hide-icons-desktop': isInboxPage,
  });

  // Only show important note when marked important
  const summaryPanelNoteClasses = cx('summary-panel__note', {
    'summary-panel__note--important': activeUser.noteIsImportant,
  });

  const renderSummaryPanel = () => (
    <div className="summary-panel">
      <div className="app-page__header">
        <div className="app-page__header__title">Summary</div>
        <div className="app-page__header__action">
          <Close
            className="app-page__header__action__close"
            onClick={handleCloseProfileClick}
          />
        </div>
      </div>
      <Scrollbars className="summary-panel__body" ref={contactPanelRef}>
        <div className="summary-panel__content">
          <div className="summary-panel__intro-wrapper">
            <div className="summary-panel__intro__avatar">
              <Avatar
                image={profileImageUrl}
                name={UserHelpers.formatAvatarName(activeUser.firstName, activeUser.lastName)}
                size="large"
              />
            </div>
            <div className="summary-panel__intro__title">
              {UserHelpers.formatName(activeUser)}
              {activeUser.typeId !== Types.TYPE_ORGANIZATION && (
                <UserBadges
                  user={activeUser}
                  isRhinopayEnabled={!!isRhinopayEnabled}
                  isOfficeVCardEnabled={!!isOfficeVCardEnabled}
                />
              )}
            </div>
            <div className="summary-panel__intro__sub">
              {UserHelpers.formatTypes(activeUser)}{UserHelpers.getExternalId(activeUser) ? ` (#${UserHelpers.getExternalId(activeUser)})` : null}
            </div>
            <div className="summary-panel__intro__actions" data-cypress="actionButton">
              {!isLimitedProvider && renderActionButtons()}
            </div>
            {activeUser.note && (
              <div className={summaryPanelNoteClasses}>
                {isProfileNoteAboveTruncateLength ? (
                  <>
                    {ReactHtmlParser(getProfileNote())}
                    <Button className="u-text-primary" reset onClick={toggleProfileNoteTruncate}>View {isProfileNoteTruncated ? 'More' : 'Less'}</Button>
                  </>
                ) :
                  ReactHtmlParser(getProfileNote())}
              </div>
            )}
          </div>
          <SummaryPanelSection
            open
            label="Profile Details"
            dataCypress="profileToggle"
          >
            <div className="summary-panel__section__content summary-panel__section__content--indented">
              <UtilityList space className="u-m-b-0 u-p-t">
                {
                  activeUser.phones?.length > 0
                  && DataHelpers.hasData(phones)
                  && activeUser.phones.map(renderPhone)
                }
                {
                  activeUser.emails?.length > 0
                  && DataHelpers.hasData(emails)
                  && activeUser.emails.map(renderEmail)
                }
                {activeUser.sex && (
                  <UtilityListItem>
                    {StringHelpers.capitalize(activeUser.sex)}
                  </UtilityListItem>
                )}
                {activeUser.birthday && (
                  <UtilityListItem>
                    {moment(activeUser.birthday).utc().format('MM/DD/YYYY')} | {renderAge(activeUser.birthday)}
                  </UtilityListItem>
                )}
                {activeUser.facebooks?.length > 0 && DataHelpers.hasData(facebooks) && (
                  <UtilityList className="u-m-b-0">
                    {activeUser.facebooks.length > 0 && renderFacebook(activeUser.facebooks[0])}
                  </UtilityList>
                )}
                {activeUser.instagrams?.length > 0 && DataHelpers.hasData(instagrams) && (
                <UtilityList className="u-m-b-0">
                  {activeUser.instagrams.length > 0 && renderInstagram(activeUser.instagrams[0])}
                </UtilityList>
                )}
                {activeUser.rhinograms?.length > 0 && DataHelpers.hasData(rhinograms) && (
                  <UtilityList className="u-m-b-0">
                    {activeUser.rhinograms.length > 0 && renderRhinogram(activeUser.rhinograms[0])}
                  </UtilityList>
                )}
              </UtilityList>
            </div>
          </SummaryPanelSection>
          <SummaryPanelSection open label="Connected Parties" dataCypress="connectedPartyToggle" loading={loading}>
            {activeUser.connectedParties?.length > 0 && DataHelpers.hasData(connectedParties) && (
              <div className="summary-panel__section__content summary-panel__section__content--full">
                <ResourceGroup>
                  {activeUser.connectedParties.map(renderConnectedParty)}
                </ResourceGroup>
              </div>
            )}
          </SummaryPanelSection>
          {supportsAppointments && (
            <ContactSummaryAppointmentsSection
              activeUser={activeUser}
              isAppointmentsSectionOpen={isAppointmentsSectionOpen}
              openCloseAppointmentsSection={openCloseAppointmentsSection}
            />
          )}
          {!!(supportsPrescriptions && userHasAllOfPermissions([PRESCRIPTION_DATA_VIEW])) && (
          <ContactPrescriptions />
          )}
          {((activeUser.typeId === Types.TYPE_PATIENT || activeUser.typeId === Types.TYPE_USER_OTHER) &&
            (isMobile() ? userHasAllOfPermissions([CONTACT_EDIT, CONVERSATION_CONTACT_MOBILE]) :
              userHasAnyOfPermissions([CONTACT_EDIT]))) && renderConsent()}

          {renderForwarding()}

          {(activeUser.typeId === Types.TYPE_PATIENT || activeUser.typeId === Types.TYPE_USER_OTHER) && renderPatientBalanceAndPaymentRequests()}
          {(activeUser.typeId === Types.TYPE_PATIENT || activeUser.typeId === Types.TYPE_USER_OTHER || activeUser.typeId === Types.TYPE_UNKNOWN) &&
            isRhinoformEnabled && renderContactForms()}
          <SummaryPanelSection
            label="Tags"
            dataCypress="tagToggle"

          >
            {activeUser.tags.length > 0 && (
              <div className="u-inline-flex">
                <SortAZ
                  handleSort={handleSort}
                  sortDirection={sort}
                />
                <div className="summary-panel__section__content summary-panel__section__content">
                  <UtilityList space className="u-m-b-0">
                    {tagIds.map(renderTag)}
                  </UtilityList>
                </div>
              </div>
            )}
          </SummaryPanelSection>
          {(isMobile() ?
            userHasAllOfPermissions([THREAD_VIEW, CONVERSATION_CONTACT_MOBILE]) :
            userHasAnyOfPermissions([CONTACT_VIEW, THREAD_VIEW])) && setOpenContactLibraryPanel && isSavedContentEnabled && (
              <div className="summary-panel__section summary-panel__section--full">
                <div className="summary-panel__section__label">
                  Library
                  (
                  <Button
                    reset
                    className="summary-panel__section-label__view-library-button"
                    onClick={() => setOpenContactLibraryPanel(PANEL_OPTIONS.library)}
                  >
                    View
                  </Button>)
                </div>
              </div>
          )}
          {!loading && (isMobile() ? userHasAllOfPermissions([CONTACT_DELETE, CONVERSATION_CONTACT_MOBILE]) :
            userHasAnyOfPermissions([CONTACT_DELETE]))
            && !activeUser.hasThreadHistory && !activeUser.integrated && (
              <div className="summary-panel__end u-text-center">
                <Button
                  onClick={() => setDeleteContactModalOpen(!deleteContactModalOpen)}
                  reset
                  className="u-text-small u-text-danger"
                  data-cypress="deleteContact"
                >
                  Delete Contact
                </Button>
              </div>
          )}
        </div>
      </Scrollbars>
    </div>
  );

  if (isActivePanel) {
    return (
      <>
        {deleteContactModal()}
        <div className={summaryPanelWrapperClasses}>
          {renderSummaryPanel()}
        </div>
      </>
    );
  } else return null;
};

ContactProfilePanel.propTypes = {
  activeOrg: PropTypes.object,
  appointmentTypeIds: PropTypes.array,
  canSendRhinopayRequests: PropTypes.bool,
  connectedParties: PropTypes.object.isRequired,
  doesActiveUserHaveFacebook: PropTypes.bool,
  doesActiveUserHaveInstagram: PropTypes.bool,
  doesActiveUserHavePhone: PropTypes.bool,
  doesActiveUserHaveRhinogram: PropTypes.bool,
  doesActiveUserOwnPhoneNumber: PropTypes.bool,
  doesLoggedInUserHaveFromChannel: PropTypes.bool,
  doesLoggedInUserHaveSecureFromChannel: PropTypes.bool,
  emails: PropTypes.object.isRequired,
  facebooks: PropTypes.object.isRequired,
  instagrams: PropTypes.object.isRequired,
  isRhinoformEnabled: PropTypes.bool,
  isRhinopayEnabled: PropTypes.bool,
  isSecureInbox: PropTypes.bool,
  merchant: PropTypes.object.isRequired,
  officeIds: PropTypes.array,
  paymentRequests: PropTypes.object,
  phones: PropTypes.object.isRequired,
  rhinograms: PropTypes.object.isRequired,
  setOpenContactLibraryPanel: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  providerIds: PropTypes.array.isRequired,
  appointmentIntegrationStatusIds: PropTypes.array.isRequired,
  isActivePanel: PropTypes.bool,
  loading: PropTypes.bool,
  formTemplates: PropTypes.object,
  formTemplateIds: PropTypes.array,
  isFormSending: PropTypes.bool,
  activeUser: PropTypes.object,
  isLimitedProvider: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {
    appointment,
    appointmentTypes,
    connectedParty,
    email,
    facebook,
    form,
    office,
    pay,
    phone,
    provider,
    rhinogram,
    tag,
    thread,
    type,
    user,
    instagram,
  } = state;
  const formTemplates = {};
  const formTemplateIds = [];
  Object.values(form.formTemplates).forEach((f) => {
    if (f.isPublished) {
      formTemplates[f.formId] = {
        ...f,
      };
      formTemplateIds.push(f.formId);
    }
  });

  return {
    activeOrg: organizationSelectors.getCurrentOrg(state),
    appointmentIntegrationStatusIds: appointment.appointmentIntegrationStatusIds,
    appointmentTypeIds: appointmentTypes.appointmentTypeIds,
    appointmentUpdateInProgress: user.appointmentLoading,
    canSendRhinopayRequests: inboxSelectors.getCanSendRhinopayRequests(state),
    connectedParties: connectedParty.connectedParties,
    currentOrganization: getCurrentOrg(state),
    currentUser: userSelectors.getLoggedInUser(state),
    doesActiveUserHavePhone: userSelectors.getDoesActiveUserHavePhone(state),
    doesActiveUserHaveInstagram: userSelectors.doesActiveUserHaveInstagram(state),
    doesActiveUserOwnPhoneNumber: userSelectors.getDoesActiveUserOwnPhoneNumber(state),
    doesLoggedInUserHaveFromChannel: inboxSelectors.getDoesLoggedInUserHaveFromChannel(state),
    doesLoggedInUserHaveSecureFromChannel: !!inboxSelectors.getSecureThreadFromChannelOptions(state)?.length,
    emails: email.emails,
    facebooks: facebook.facebooks,
    instagrams: instagram.instagrams,
    isMember: userSelectors.getUserMemberStatus(state),
    isPatient: userSelectors.getUserPatientStatus(state),
    isRhinoformEnabled: !!form.org.isRhinoformEnabled,
    isRhinopayEnabled: pay.merchant.isRhinopayEnabled,
    isSecureInbox: thread.activeConvoTab === 'secure',
    loggedInUser: userSelectors.getLoggedInUser(state),
    merchant: pay.merchant,
    officeIds: office.officeIds,
    paymentRequests: pay.paymentRequests,
    phones: phone.phones,
    providerIds: provider.providerIds,
    rhinograms: rhinogram.rhinograms,
    tags: syncTags(tag.tags, tag.tagIds),
    types: type.types,
    users: user.users,
    loading: user.loading,
    formTemplates,
    formTemplateIds,
    isFormSending: form.isFormSending,
    isLimitedProvider: userHasLimitedProviderRole(state),
  };
};

export default connect(mapStateToProps)(ContactProfilePanel);
