import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import UAParser from 'ua-parser-js';

import Diagnostics from '../components/Diagnostics';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import * as OrganizationHelpers from '../helpers/OrganizationHelpers';
import * as EmailReducer from '../reducers/emailReducer';
import * as AppConstants from '../constants/AppConstants';
import { DataHelpers, UserHelpers } from '../helpers';

class DiagnosticsContainer extends Component {
  state = {
    userData: {},
    deviceItems: {},
    emailSending: false,
    emailSent: false,
    emailError: false,
  };

  componentDidMount() {
    document.title = 'Rhinogram | Diagnostics';

    this.getUserData();
    this.getDeviceInfo();
  }

  // Retrieve locally-stored user data
  getUserData = () => {
    if (!DataHelpers.hasData(this.props.currentUser)) return;

    const userData = {};

    userData.name = UserHelpers.formatName(this.props.currentUser);
    userData.email = this.props.currentUser.loginEmail || null;
    userData.user_id = this.props.currentUser.id;
    userData.organization = this.props.currentOrganization
      ? OrganizationHelpers.formatName(this.props.currentOrganization.name)
      : null;
    userData.organization_id = this.props.currentOrganization ? this.props.currentOrganization.id : null;
    userData.type = UserHelpers.formatTypes(this.props.currentUser);
    userData.active = this.props.currentUser.active ? 'True' : 'False';

    this.setState({ userData });
  };

  // Fetch data from ipinfo.io service
  getForeignDeviceData() {
    return axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`, { withCredentials: false });
  }

  // Get information about the device accessing this page
  getDeviceInfo = async () => {
    let deviceItems = {};

    try {
      const { data: foreignDeviceData } = await this.getForeignDeviceData();
      deviceItems = { ...foreignDeviceData };
    } catch (error) {
      console.error(error);
    }

    const uaParser = new UAParser();
    const browserInfo = uaParser.getResult();

    deviceItems.os = browserInfo.os ? `${browserInfo.os.name} ${browserInfo.os.version}` : null;
    deviceItems.browser = browserInfo.browser ? `${browserInfo.browser.name} ${browserInfo.browser.version}` : null;
    deviceItems.screenResolution = `${window.screen.availWidth}x${window.screen.availHeight}`;
    deviceItems.touch = Modernizr.touchevents ? 'True' : 'False'; // eslint-disable-line no-undef
    deviceItems.cookiesEnabled = navigator.cookieEnabled ? 'True' : 'False';
    deviceItems.language = navigator.language;
    deviceItems.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.setState({ deviceItems });
  };

  // Email support with diagnostics
  handleShareWithSupport = (data) => {
    const body = `<p>${this.state.userData.name} has sent you information about their system to help them troubleshoot an issue they are having with Rhinogram.</p>${data}`; // eslint-disable-line max-len

    this.setState(
      {
        emailSending: true,
      },
      () => {
        this.props
          .sendEmail({
            from: AppConstants.SUPPORT_EMAIL,
            to: [AppConstants.SUPPORT_EMAIL],
            subject: `Rhinogram Diagnostics: ${this.state.userData.name}`,
            body,
          })
          .then(() => {
            this.setState({
              emailSending: false,
              emailSent: true,
            });
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              emailSending: false,
              emailError: true,
            });
          });
      },
    );
  };

  render() {
    const props = {
      userData: this.state.userData,
      deviceItems: this.state.deviceItems,
      emailSending: this.state.emailSending,
      emailSent: this.state.emailSent,
      emailError: this.state.emailError,
      handleShareWithSupport: this.handleShareWithSupport,
    };

    return <Diagnostics {...props} />;
  }
}

DiagnosticsContainer.propTypes = {
  currentUser: PropTypes.object,
  currentOrganization: PropTypes.object,
  sendEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentOrganization: getCurrentOrg(state),
  currentUser: getLoggedInUser(state),
});

const actions = {
  sendEmail: EmailReducer.sendEmail,
};

export default connect(mapStateToProps, actions)(DiagnosticsContainer);
