import React from 'react';
import PropTypes from 'prop-types';
import { Resource, ResourceIntro } from 'rhinostyle';
import { connect } from 'react-redux';
import { UserHelpers } from '../helpers';
import { replaceSpacesWithUnderscores } from '../helpers/StringHelpers';
import { AppConstants } from '../constants';
import { getMentionableUser } from '../selectors/userSelectors';
import { getMentionableGroup } from '../selectors/groupSelectors';
import mentionGroupImage from '../assets/images/mention-group-avtar.svg';

const MentionItem = (props) => {
  const { handleMentionClick, group, user } = props;
  if (user) {
    const { onlineStatus, id, firstName, lastName } = user;
    const shapedMention = {
      id: user.id,
      displayName: replaceSpacesWithUnderscores(`@${firstName}_${lastName}`),
      pasteName: `@${firstName}_${lastName}`,
      searchName: `${firstName} ${lastName}`,
      userFirstName: firstName,
      userLastName: lastName,
      userId: id,
    };
    const profileImageUrl = user.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${user.profileImageUrl}` : null;
    return (
      <Resource key={id} onClick={() => handleMentionClick(shapedMention)} className="u-flex-direction-column u-text-overflow">
        <ResourceIntro
          avatar={{
            type: 'member',
            image: profileImageUrl,
            onlineStatus,
            showOnlineStatus: true,
            name: UserHelpers.formatAvatarName(user.firstName, user.lastName),
          }}
          title={UserHelpers.formatMemberNameWithPrefixAndSuffix(user)}
          titleSub="Member"
        />
      </Resource>
    );
  }
  const { id, name } = group;
  const shapedMention = {
    id,
    name,
    displayName: replaceSpacesWithUnderscores(`@${name}`),
    groupId: id,
    groupName: name,
  };
  return (
    <Resource key={id} onClick={() => handleMentionClick(shapedMention)} className="u-flex-direction-column u-text-overflow mention-group__item">
      <ResourceIntro
        avatar={{
          image: mentionGroupImage,
          name,
        }}
        title={name}
        titleSub="Group"
      />
    </Resource>
  );
};
MentionItem.propTypes = {
  user: PropTypes.object,
  group: PropTypes.object,
  handleMentionClick: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
  user: getMentionableUser(state, props),
  group: getMentionableGroup(state, props),
});
export default connect(mapStateToProps)(MentionItem);
