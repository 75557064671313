import axios from 'axios';
import { normalize } from 'normalizr';
import moment from 'moment-timezone';
import { NotificationActions } from 'rhinostyle';
import { createSlice } from '@reduxjs/toolkit';

import { AudioHelpers, DataHelpers } from '../helpers';

import { AppConstants } from '../constants';

import * as ChannelReducer from './channelReducer';
import { setError } from './uiReducer';
import { organization, event, channel } from '../actions/NormalizrSchema';

// SLICE
const secureSlice = createSlice({
  name: 'SECURE',
  initialState: {
    eventIds: [],
    events: {},
    channels: {},
    channelIds: [],
    channelId: null,
    organizationId: null,
    threadPageNo: 0,
    threadLoading: false,
    pageLoading: false,
    organizations: {},
    organizationIds: [],
  },
  reducers: {
    receivePatientMenu: (state, action) => ({
      ...state,
      organizations: {
        ...state.organzations,
        ...action.payload.organizations,
      },
      organizationIds: [...new Set([...state.organizationIds, ...action.payload.organizationIds])],
      channels: {
        ...state.channels,
        ...action.payload.channels,
      },
      channelIds: [...new Set([...state.channelIds, ...action.payload.channelIds])],
    }),
    requestSecureViewData: (state) => ({
      ...state,
      pageLoading: true,
    }),
    requestPatientThreadData: (state) => ({
      ...state,
      threadLoading: true,
    }),
    receiveWebSocketEvent: receiveEventData,
    receiveCreateEvent: receiveEventData,
    receiveCreateTempSecureEvent: receiveEventData,
    receivePatientThreadChannelView: receiveThreadData,
    receivePatientThreadAllView: receiveThreadData,
    receivePatientThread: receiveThreadData,
    resetEventData: (state) => ({
      ...state,
      events: {},
      eventIds: [],
      channelId: null,
      organizationId: null,
      threadPageNo: 0,
    }),
    setSecureContext: (state, action) => ({
      ...state,
      channelId: action.payload.channelId,
      organizationId: action.payload.organizationId,
    }),
  },
});

export default secureSlice.reducer;

// ACTIONS
export const {
  receivePatientMenu,
  requestSecureViewData,
  requestPatientThreadData,
  receiveWebSocketEvent,
  receiveCreateEvent,
  receiveCreateTempSecureEvent,
  receivePatientThreadChannelView,
  receivePatientThreadAllView,
  receivePatientThread,
  resetEventData,
  setSecureContext,
} = secureSlice.actions;

// REDUCER HELPERS

function receiveThreadData(state, action) {
  return {
    ...state,
    events: {
      ...state.events,
      ...action.payload.events,
    },
    eventIds: [...new Set([...action.payload.eventIds.reverse(), ...state.eventIds])],
    threadLoading: false,
    pageLoading: false,
    threadPageNo: action.payload.pageNo,
  };
}

const sortEventIdsByTimestampDesc = (events, eventIds) =>
  eventIds.sort((a, b) => moment(events[a].timestamp).diff(moment(events[b].timestamp)));

function receiveEventData(state, action) {
  // If payload has optimisticId, it was sent originally optimistically. We filter it out of the original list.
  const tempEventId = action.payload.event[action.payload.eventId].optimisticId;

  const optimisticEventIds = [...state.eventIds, action.payload.eventId].filter((i) => typeof i !== 'number' && tempEventId !== i);
  const realEventIds = [...state.eventIds, action.payload.eventId].filter((i) => typeof i === 'number');

  const mergedEvents = {
    ...state.events,
    [action.payload.eventId]: action.payload.event[action.payload.eventId],
  };
  return ({
    ...state,
    events: mergedEvents,
    eventIds: [...new Set([...sortEventIdsByTimestampDesc(mergedEvents, realEventIds), ...optimisticEventIds])],
  });
}

// THUNKS -- ASYNC ACTION CREATORS

export function fetchPatientThreadAllView({ userId }) {
  return (dispatch) => {
    dispatch(requestPatientThreadData());

    return axios.all([getPatientThread({ userId })])
      .then(axios.spread((threadResult) => {
        const normalizedThread = threadResult ? normalize(threadResult.data, [event]) : null;
        dispatch(receivePatientThreadAllView(preparePatientThreadAllViewPayload(normalizedThread)));
      }))
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function fetchPatientThreadChannelView({ userId, channelId, orgId }) {
  return (dispatch) => {
    dispatch(requestPatientThreadData());

    return axios.all([getPatientThread({ userId, channelId }), ChannelReducer.getChannelById({ channelId, patientUserId: userId, patientOrgId: orgId })])
      .then(axios.spread((threadResult, channelResult) => {
        const normalizedThread = threadResult ? normalize(threadResult.data, [event]) : null;
        const normalizedChannel = channelResult ? normalize(channelResult.data, channel) : null;

        dispatch(receivePatientThreadChannelView(preparePatientThreadChannelViewPayload(normalizedThread, normalizedChannel)));
      }))
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function fetchPatientThread({ userId, channelId, pageNo = 0, pageSize = AppConstants.EVENT_SIZE, sort = 'descending', minimal = true }) {
  return (dispatch) => {
    dispatch(requestPatientThreadData());

    return getPatientThread({ userId, channelId, pageNo, pageSize, sort, minimal })
      .then(({ data }) => {
        const normalized = normalize(data, [event]);

        let page = pageNo;

        if (pageNo > 0 && (normalized.result.length === 0 || normalized.result.length < pageSize)) {
          page = pageNo - 1;
        }

        dispatch(receivePatientThread(preparePatientThreadPayload(normalized, page)));
      });
  };
}

export function fetchPatientMenu() {
  return (dispatch) =>
    axios.get('/patientMenu')
      .then(({ data }) => {
        const normalized = normalize(data, [organization]);

        dispatch(receivePatientMenu(getPatientMenu(normalized)));
      });
}

export function createTempSecureEvent(payload) {
  return (dispatch) => {
    dispatch(receiveCreateTempSecureEvent(prepareTempSecureEventPayload(payload)));
  };
}

export function createSecureEvent(postEvent, eventType = 'secureMessages') {
  const url = `/events/${eventType}`;

  return (dispatch, getState) => {
    const currentState = getState();
    const soundOn = AudioHelpers.getSoundOnOff(currentState.organization.organizations, currentState.user.users[currentState.auth.currentUser]);

    return axios.post(url, postEvent)
      .then((response) => {
        const normalized = normalize(response.data, event);
        dispatch(receiveCreateEvent(prepareSecureEventPayload(normalized)));

        if (soundOn) AudioHelpers.playAudio('message-sent');
      })
      .catch((err) => {
        let body = '';
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        if (soundOn) AudioHelpers.playAudio('message-failed');

        if (err.response.data.message.includes('413')) {
          body = 'The file you selected is too large. The maximum file size is 600 KB.';
        } else {
          body = err.response.data.message;
        }

        NotificationActions.addNotification({
          body,
          type: 'danger',
        });
      });
  };
}

export function receiveWebSocketEventMessage(wsEvent) {
  return (dispatch, getState) => {
    const currentState = getState();
    const webSocketEvent = wsEvent.event;
    const webSocketContext = wsEvent.context;
    const { channels } = currentState.secure;
    const { channelId: webSocketChannelId, userId: webSocketUserId } = webSocketContext;

    // current patient context
    const { channelId } = currentState.secure;

    const sameChannel = (webSocketChannelId && webSocketChannelId === channelId); // is patient in a channel of the same context as the websocket event?
    // is patient in all channels and does the websocket event have a channelId (is it not a note)?
    const allChannels = window.location.pathname.includes('all') && webSocketChannelId;
    if (sameChannel) { // if thread open from same channel of incoming message
      updatePatientThreadReadStatus(webSocketUserId, channelId);
      webSocketEvent.read = true;
    } else if (webSocketChannelId) { // adjust badge
      const adjustedChannel = { ...channels[webSocketChannelId] };

      if (adjustedChannel) {
        adjustedChannel.unread = 1;

        const menuPayload = prepareWebSocketPatientMenu({
          entities: {
            organizations: currentState.secure.organizations,
            channels: {
              ...channels,
              [webSocketChannelId]: adjustedChannel,
            },
          },
        });

        dispatch(receivePatientMenu(menuPayload)); // update menu/badge count
      }
    }

    if (sameChannel || allChannels) { // add event to thread
      const normalized = normalize(webSocketEvent, event);
      dispatch(receiveWebSocketEvent(prepareWebSocketEventPayload(normalized)));
    }
  };
}

// AXIOS HELPERS

function getPatientThread({ userId, channelId, minimal = true, pageNo = 0, sort = 'descending', pageSize = AppConstants.EVENT_SIZE }) {
  let url = `/patientThread/${userId}/${channelId || 'fullThread'}?pageNo=${pageNo}&pageSize=${pageSize}&sort=${sort}`;
  if (!channelId) {
    url = `${url}&fullThread=true`;
  }

  if (minimal) url = `${url}&minimal=1`;
  return axios.get(url)
    .catch((err) => {
      console.error(err.response || err);
    });
}

function updatePatientThreadReadStatus(userId, channelId) {
  return axios.patch(`/patientThread/updateRead/${userId}/${channelId}`, { read: true })
    .catch((err) => {
      console.error(err.response || err);
    });
}

// PREPARE CALLBACKS -- PAYLOAD CUSTOMIZERS

function prepareSecureEventPayload(normalizedEvent) {
  return {
    users: {
      ...normalizedEvent.entities.senders,
      ...normalizedEvent.entities.users,
    },
    userIds: [...new Set([...DataHelpers.getObjectKeys(normalizedEvent.entities.senders), ...DataHelpers.getObjectKeys(normalizedEvent.entities.users)])],
    event: normalizedEvent.entities.events,
    eventId: normalizedEvent.result,
  };
}

function prepareTempSecureEventPayload(payload) {
  return {
    event: {
      [payload.optimisticId]: {
        attachments: payload.attachments || [],
        id: payload.optimisticId,
        incoming: false,
        read: true,
        sender: payload.sender,
        channels: [payload.secureChannelId],
        typeId: 28,
        user: payload.userId,
        details: {
          text: payload.text,
        },
      },
    },
    eventId: payload.optimisticId,
  };
}

function prepareWebSocketEventPayload(normalizedEvent) {
  return {
    users: {
      ...normalizedEvent.entities.senders,
      ...normalizedEvent.entities.users,
    },
    userIds: [...new Set([...DataHelpers.getObjectKeys(normalizedEvent.entities.senders), ...DataHelpers.getObjectKeys(normalizedEvent.entities.users)])],
    event: normalizedEvent.entities.events,
    eventId: normalizedEvent.result,
  };
}

function prepareWebSocketPatientMenu(normalizedMenu) {
  return {
    organizations: {
      ...normalizedMenu.entities.organizations,
    },
    organizationIds: DataHelpers.getObjectKeys(normalizedMenu.entities.organizations),
    channels: {
      ...normalizedMenu.entities.channels,
    },
    channelIds: DataHelpers.getObjectKeys(normalizedMenu.entities.channels),
  };
}

function getPatientMenu(normalizedMenu) {
  return {
    organizations: {
      ...normalizedMenu.entities.organizations,
    },
    organizationIds: normalizedMenu.result,
    channels: {
      ...normalizedMenu.entities.channels,
    },
    channelIds: DataHelpers.getObjectKeys(normalizedMenu.entities.channels),
  };
}

function preparePatientThreadPayload(normalizedThread, pageNo) {
  return {
    users: {
      ...normalizedThread.entities.senders,
      ...normalizedThread.entities.users,
    },
    userIds: [...new Set([...DataHelpers.getObjectKeys(normalizedThread.entities.senders), ...DataHelpers.getObjectKeys(normalizedThread.entities.users)])],
    events: {
      ...normalizedThread.entities.events,
    },
    eventIds: normalizedThread.result,
    pageNo,
  };
}

function preparePatientThreadAllViewPayload(normalizedThread) {
  return {
    users: {
      ...normalizedThread.entities.senders,
      ...normalizedThread.entities.users,
    },
    userIds: [...new Set([...DataHelpers.getObjectKeys(normalizedThread.entities.senders), ...DataHelpers.getObjectKeys(normalizedThread.entities.users)])],
    channels: {
      ...normalizedThread.entities.channels,
    },
    channelIds: DataHelpers.getObjectKeys(normalizedThread.entities.channels),
    events: {
      ...normalizedThread.entities.events,
    },
    eventIds: normalizedThread.result,
    pageNo: 0,
  };
}

function preparePatientThreadChannelViewPayload(normalizedThread, normalizedChannel) {
  return {
    users: {
      ...normalizedThread.entities.senders,
      ...normalizedThread.entities.users,
    },
    userIds: [...new Set([...DataHelpers.getObjectKeys(normalizedThread.entities.senders), ...DataHelpers.getObjectKeys(normalizedThread.entities.users)])],
    events: {
      ...normalizedThread.entities.events,
    },
    eventIds: normalizedThread.result,
    channels: {
      ...normalizedChannel.entities.channels,
    },
    channelIds: [normalizedChannel.result].flat(),
    pageNo: 0,
  };
}
