import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Icon,
  Resource,
  ResourceBody,
  ResourceIntro,
  ResourceRight,
} from 'rhinostyle';
import { getLoggedInUser } from '../selectors/userSelectors';
import { DateHelpers, AttachmentHelpers, UserHelpers } from '../helpers';
import { AppConstants } from '../constants';

// 'userOrGroupId' arg refers to either userId or groupId, and 'route' is used to specify whether the thread
// is a dynamic group chat or a direct chat to one user (obviously '/chat/user/7' wouldn't make sense for a group thread, for example)
const handleChatItemClick = (userOrGroupId, route, handleChatItemHandler, threadId) => {
  handleChatItemHandler(route, userOrGroupId, threadId);
};

const ChatInboxEvent = (props) => {
  const { eventId, handleChatItemHandler, selectedInboxThreadIds, handleCheckboxOnItem, isCcr } = props;
  const events = useSelector((state) => state.chat.events);
  const activeEvent = events[eventId];
  const { threadId } = activeEvent;
  const eventSender = useSelector((state) => state.user.users[activeEvent.sender]);
  const loggedInUser = useSelector((state) => getLoggedInUser(state));
  const groups = useSelector((state) => state.group.groups);
  const users = useSelector((state) => state.user.users);
  const userIds = useSelector((state) => state.user.userIds);

  // 1-on-1 Direct Chat
  const eventRecipient = useSelector((state) => state.user.users[activeEvent.user]);
  const unread = !activeEvent.read;
  const profileImageUrl = eventRecipient && eventRecipient.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${eventRecipient.profileImageUrl}` : '';
  const formattedSenderName = loggedInUser.id === eventSender.id ? 'You' : UserHelpers.formatName(eventSender);
  const formattedUserName = UserHelpers.formatName(eventRecipient);

  // Dynamic Group/Team Chat
  const activeGroup = activeEvent.group ? groups[activeEvent.group] : null;
  const isDynamicGroupThread = activeGroup;
  const groupUserIds = activeGroup ? userIds.filter((userId) => users[userId].groups?.includes(activeGroup.id)) : null;
  const groupUserIdsExcludingLoggedInUser = groupUserIds?.filter((userId) => userId !== loggedInUser.id);
  // 'Foreground' because this user is the first image in the MultiAvatar display of the group chat.
  const groupForegroundUser = groupUserIdsExcludingLoggedInUser ? users[groupUserIdsExcludingLoggedInUser[0]] : null;
  // 'Background' because this user is the second image in the MultiAvatar display of the group chat.
  const groupBackgroundUser = groupUserIdsExcludingLoggedInUser ? users[groupUserIdsExcludingLoggedInUser[1]] : null;
  const foregroundImageUrl = groupForegroundUser?.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${groupForegroundUser?.profileImageUrl}` : '';
  const backgroundImageUrl = groupBackgroundUser?.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${groupBackgroundUser?.profileImageUrl}` : '';
  const foregroundName = UserHelpers.formatAvatarName(groupForegroundUser?.firstName, groupForegroundUser?.lastName) || '';
  const backgroundName = UserHelpers.formatAvatarName(groupBackgroundUser?.firstName, groupBackgroundUser?.lastName) || '';
  const inbound = activeEvent.sender !== loggedInUser.id;

  const formattedNamesDynamicThread = (threadUserIds) => {
    const threadUsers = threadUserIds.map((threadUserId) => users[threadUserId]);

    if (threadUsers.length > 2) {
      const names = threadUsers.map((user) => UserHelpers.formatName(user)).join(', ');
      return names;
    }

    return `${UserHelpers.formatName(threadUsers[0])} and ${UserHelpers.formatName(threadUsers[1])}`;
  };

  const isChecked = selectedInboxThreadIds.includes(threadId);
  let checkboxProperty = null;
  if (!isCcr) {
    checkboxProperty = {
      checkbox: {
        name: 'inboxThread',
        label: ' ',
        isChecked,
        onChange: (event) => handleCheckboxOnItem(threadId, event),
        hasAvatar: true,
      },
    };
  }

  const renderChatEventText = () => {
    const classes = cx('inbox__event__message', {
      'msg--default': inbound,
      'msg--primary--outbound': !inbound,
    });
    return (
      <span className={classes}>
        {UserHelpers.formatMentionString(activeEvent.details.text, activeEvent.eventMentions)}
      </span>
    );
  };

  const renderEventAttachment = () => {
    const classes = cx('inbox__event__message', 'msg--attachment', {
      'msg--default': inbound,
      'msg--primary--outbound': !inbound,
    });
    return (
      <span>
        Attachment: <span className={classes}> {AttachmentHelpers.getAttDisplayName(activeEvent.attachments[0])} </span>
        <Icon icon="attachment" className="inbox__item__attachment-icon" />
      </span>
    );
  };

  return (
    <Resource
      className="inbox__item"
      id={`js-inbox__chat-item-${eventRecipient ? eventRecipient.id : activeGroup.id}`}
      unread={unread}
      onClick={() => handleChatItemClick((isDynamicGroupThread ? activeGroup.id : eventRecipient.id), (activeGroup ? 'group' : 'user'), handleChatItemHandler, threadId)}
    >
      {!isDynamicGroupThread && (
      <ResourceIntro
        avatar={{
          image: profileImageUrl,
          name: UserHelpers.formatAvatarName(eventRecipient.firstName, eventRecipient.lastName),
          type: 'member',
          showOnlineStatus: true,
          onlineStatus: eventRecipient?.onlineStatus,
        }}
        {...checkboxProperty}
        title={formattedUserName}
      />
      )}
      {isDynamicGroupThread && (
      <ResourceIntro
        multiAvatar={{
          foregroundImageUrl,
          backgroundImageUrl,
          foregroundName,
          backgroundName,
        }}
        {...checkboxProperty}
        title={groupUserIds ? formattedNamesDynamicThread(groupUserIdsExcludingLoggedInUser) : ''}
      />
      )}
      <ResourceBody className="inbox__item__body--flex">
        {!activeEvent.incoming && (
          <span>{formattedSenderName}&nbsp;<Icon icon="arrow-right" className="icon--bump-up inbox__chat-item__body__arrow-right" />&nbsp;</span>
        )}
        {activeEvent.details.text && renderChatEventText()}
        {activeEvent.details.text && activeEvent.attachments.length > 0 ? <Icon icon="attachment" className="inbox__item__attachment-icon" /> : null}
        {!activeEvent.details.text && activeEvent.attachments.length > 0 && renderEventAttachment()}
      </ResourceBody>
      <ResourceRight>
        <span title={DateHelpers.fullTimestamp(activeEvent.timestamp)}>
          {DateHelpers.formatTimestamp(activeEvent.timestamp)}
        </span>
      </ResourceRight>
    </Resource>
  );
};

ChatInboxEvent.propTypes = {
  eventId: PropTypes.number,
  handleOnItemClick: PropTypes.func,
  isCcr: PropTypes.bool,
  handleCheckboxOnItem: PropTypes.func,
  selectedInboxThreadIds: PropTypes.array,
  handleChatItemHandler: PropTypes.func,
};

export default ChatInboxEvent;
