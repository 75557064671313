import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import isEqual from 'lodash.isequal';
import { NotificationActions } from 'rhinostyle';
import unsavedChanges from '../components/UnsavedChangesHOC';
import { fetchRoleFormView, fetchNewRoleView, updateRole, createRole, destroyRole } from '../reducers/roleReducer';
import * as AuditLogReducer from '../reducers/auditLogReducer';
import { fetchTags } from '../reducers/tagReducer';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { ValidationHelpers, DataHelpers, ScrollHelpers, RoleHelpers } from '../helpers';
import { organizationSelectors } from '../selectors';

import PageLoader from '../components/PageLoader';
import OrganizationRolesForm from '../components/OrganizationRolesForm';
import * as UserPermissionsConstants from '../constants/UserPermissionsConstants';

const contactsPermission = [UserPermissionsConstants.CONTACT_VIEW, UserPermissionsConstants.CONTACT_EDIT, UserPermissionsConstants.CONTACT_DELETE];
const teamPermission = [UserPermissionsConstants.TEAM_THREAD_VIEW, UserPermissionsConstants.TEAM_CONVERSATION_EDIT];
const { RHINOFORMS_ADMIN } = UserPermissionsConstants;

class OrganizationRolesFormContainer extends React.Component {
  state = {
    currentRole: {},
    disabledUserIds: [],
    errors: {},
    formInProgress: false,
    viewGroups: false,
    selectedUserIds: [],
    selectedGroupIds: [],
    selectedPermissions: [],
    showDeleteRoleModal: false,
    isDeleteConfirmationVisible: false,
    mode: this.props.location.pathname.includes('edit') ? 'edit' : 'create',
    editRoleId: Number(this.props.match.params.roleId),
    viewAllMembers: false,
    pageLoading: true,
    isSystemRole: false,
    showRhinoformSeatsValidationModal: false,
  };

  componentDidMount() {
    if (this.state.mode === 'edit') {
      this.props.fetchRoleFormView(this.state.editRoleId, this.props.location.pathname.includes('system'));
    } else {
      this.props.fetchNewRoleView();
    }
    this.props.fetchTags();
    this.props.fetchFilterData('', 'members');
  }

  static getDerivedStateFromProps(props, state) {
    const selectedPermissionsCloned = state.selectedPermissions && DataHelpers.cloneDeep(state.selectedPermissions);
    return {
      isMobilePermissionVisibleforContact: contactsPermission.some((permission) => selectedPermissionsCloned.includes(permission)),
      isMobilePermissionVisibleforTeam: teamPermission.some((permission) => selectedPermissionsCloned.includes(permission)),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const roleHasUpdated = prevProps.formInProgress !== this.props.formInProgress;
    const currentRoleHasLoaded = this.props.currentRole && !isEqual(prevState.currentRole, this.props.currentRole);

    if (prevState.pageLoading !== this.props.pageLoading) {
      this.setState({ pageLoading: this.props.pageLoading }); // eslint-disable-line react/no-did-update-set-state
    }

    if (roleHasUpdated) {
      this.setState({ formInProgress: this.props.formInProgress }); // eslint-disable-line react/no-did-update-set-state
    }
    if (currentRoleHasLoaded) {
      const { users } = this.props;
      const selectedUserIds = this.props.currentRole.users;
      // disable unselecting a user if they only have 1 role
      const disabledUserIds = selectedUserIds.filter((id) => users[id] && users[id].roles && users[id].roles.length === 1);

      this.setState({ // eslint-disable-line react/no-did-update-set-state
        name: this.props.currentRole.name,
        description: this.props.currentRole.description,
        disabledUserIds,
        permissions: this.props.currentRole.permissions,
        selectedUserIds,
        selectedPermissions: this.props.currentRole.selectedPermissions,
        isSystemRole: this.props.currentRole.systemRole,
        currentRole: this.props.currentRole,
      });
    }

    if (!prevState.isDeleteConfirmationVisible && this.state.isDeleteConfirmationVisible) {
      ScrollHelpers.scrollToBottom(this.pageContainer);
    }
  }

  handleConfirmDeleteRequest = () => {
    const { editRoleId, isSystemRole, currentRole } = this.state;
    const hadUsers = currentRole.users.length > 0;
    this.props.resetFormChanges();
    this.props.destroyRole(editRoleId, isSystemRole, hadUsers, this.props.currentUserId)
      .then(() => {
        this.props.history.push('/settings/organization/roles');
      });
  };

  get roleFormPayload() {
    return {
      name: this.state.name,
      description: this.state.description,
      permissions: this.state.selectedPermissions,
      users: this.state.selectedUserIds.map((id) => ({ id })),
    };
  }

  handleSubmit = () => {
    const errors = ValidationService(ValidationShapers.shapeRole({ ...this.state }));
    if (Object.keys(errors).length > 0) {
      this.setState({ errors }, () => {
        ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
      });
      return;
    }

    this.setState({ formInProgress: true, errors: {} });

    if (this.state.mode === 'edit') {
      this.props.updateRole(this.roleFormPayload, this.state.editRoleId, this.state.isSystemRole, this.props.currentUserId)
        .then(() => {
          if (this.props.error) this.handleSubmitError();
          else this.handleSubmitSuccess();
        });
    } else {
      this.props.createRole(this.roleFormPayload, this.props.currentUserId)
        .then(() => {
          if (this.props.error) this.handleSubmitError();
          else this.handleSubmitSuccess();
        });
    }
  }

  handleSubmitError() {
    const errors = ValidationService(ValidationShapers.shapeRole({ ...this.state }));
    errors.name = this.props.error.data.message;

    this.setState({ errors }, () => {
      ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
    });
  }

  handleSubmitSuccess() {
    this.props.resetFormChanges();
    this.props.history.push('/settings/organization/roles');
  }

  handleToggle = (name) => {
    this.setState({ [name]: !this.state[name] });
  }

  updateonFirstClick = (currentPermission, permissionName) => {
    // eslint-disable-next-line no-unused-expressions
    currentPermission.messageActions?.map((permission) => {
      if (permission.name === permissionName) {
        // eslint-disable-next-line no-param-reassign
        permission.mobile[0].value = true;
      }
      return permission;
    });
    return currentPermission;
  };

  handleCheckboxClick = (permissions, value) => {
    let currentPermission = DataHelpers.cloneDeep(this.state.permissions);
    let selectedPermissionsCloned = DataHelpers.cloneDeep(this.state.selectedPermissions);

    // check existing permission
    const hasContactPermission = contactsPermission.some((permission) => selectedPermissionsCloned.includes(permission));
    const hasTeamPermission = teamPermission.some((permission) => selectedPermissionsCloned.includes(permission));

    // for first clicked checkbox(team and Contacts)
    const hasContactPermissionClicked = permissions.some((permission) => contactsPermission.includes(permission));
    const hasTeamPermissionClicked = permissions.some((permission) => teamPermission.includes(permission));

    // adding and removing Permission(based on checkbox)
    if (!value && permissions.every((permission) => selectedPermissionsCloned.includes(permission))) {
      selectedPermissionsCloned = selectedPermissionsCloned.filter((permission) => !permissions.includes(permission));
    } else if (value && !permissions.every((permission) => selectedPermissionsCloned.includes(permission))) {
      selectedPermissionsCloned = selectedPermissionsCloned.concat(permissions);
    }

    // updating forcefully first Mobile access Toggle
    if (!hasContactPermission && hasContactPermissionClicked) {
      currentPermission = this.updateonFirstClick(currentPermission, 'Contacts & Patient Conversations');
      if (!selectedPermissionsCloned.includes(UserPermissionsConstants.CONVERSATION_CONTACT_MOBILE)) {
        selectedPermissionsCloned = selectedPermissionsCloned.concat(UserPermissionsConstants.CONVERSATION_CONTACT_MOBILE);
      }
    } else if (hasTeamPermissionClicked && !hasTeamPermission) {
      currentPermission = this.updateonFirstClick(currentPermission, 'Team Conversations');
      if (!selectedPermissionsCloned.includes(UserPermissionsConstants.CONVERSATION_TEAM_MOBILE)) {
        selectedPermissionsCloned = selectedPermissionsCloned.concat(UserPermissionsConstants.CONVERSATION_TEAM_MOBILE);
      }
    }

    // removing permission on uncheck of checkbox(can't use above const as selectedPermissionsCloned has been updated).
    if (!value) {
      if (!contactsPermission.some((permission) => selectedPermissionsCloned.includes(permission)) && hasContactPermissionClicked) {
        selectedPermissionsCloned = selectedPermissionsCloned.filter((permission) => permission !== UserPermissionsConstants.CONVERSATION_CONTACT_MOBILE);
      } else if (!teamPermission.some((permission) => selectedPermissionsCloned.includes(permission)) && hasTeamPermissionClicked) {
        selectedPermissionsCloned = selectedPermissionsCloned.filter((permission) => permission !== UserPermissionsConstants.CONVERSATION_TEAM_MOBILE);
      }
    }

    this.setState({
      selectedPermissions: selectedPermissionsCloned,
      permissions: currentPermission,
    });
  }

  handlePermissionChange = (permissions, value) => {
    let selectedPermissionsCloned = DataHelpers.cloneDeep(this.state.selectedPermissions);
    if (value) {
      selectedPermissionsCloned = selectedPermissionsCloned.concat(permissions);
    } else if (!value && selectedPermissionsCloned.includes(permissions)) {
      selectedPermissionsCloned = selectedPermissionsCloned.filter((permission) => !permissions.includes(permission));
    }
    this.setState({
      selectedPermissions: selectedPermissionsCloned,
    });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  isModalDisplay = () => {
    this.setState({
      showRhinoformSeatsValidationModal: true,
    });
  }

  handleToggleModal = () => {
    this.setState({
      showRhinoformSeatsValidationModal: false,
    });
  }

  activeUsersIds = (selectedIds) => {
    const selectedUserIds = selectedIds || this.state.selectedUserIds;
    return selectedUserIds.filter((userId) => this.props.users[userId].active === true);
  }

  handleUpdateSelectedIds = (name, id) => {
    let selectedIds = [...this.state[name]];
    const addAction = !selectedIds.includes(id);
    const groupId = this.props.groups[id];
    let shouldRhinoformSeatsValidationModalDisplay = false;
    let totalMembers;

    if (addAction) {
      selectedIds = selectedIds.concat(id);
      if (name === 'selectedUserIds' && this.props.currentRole.name === RHINOFORMS_ADMIN && this.activeUsersIds(selectedIds).length > this.props.seatsLimit) {
        selectedIds = selectedIds.filter((selectedId) => selectedId !== id);
        this.isModalDisplay();
      }
    } else if (name !== 'selectedGroupIds') {
      selectedIds = selectedIds.filter((selectedId) => selectedId !== id);
    }
    const newState = { [name]: selectedIds };
    if (name === 'selectedGroupIds') {
      totalMembers = new Set([...groupId.users, ...this.state.selectedUserIds]);
      if (this.props.currentRole.name === RHINOFORMS_ADMIN) {
        if (this.activeUsersIds([...totalMembers]).length > this.props.seatsLimit) {
          shouldRhinoformSeatsValidationModalDisplay = true;
          const newMembersIds = groupId.users.filter((item) => !this.state.selectedUserIds.includes(item));
          totalMembers = [...totalMembers].filter((item) => !newMembersIds.includes(item));
          this.isModalDisplay();
        }
        totalMembers = this.activeUsersIds([...totalMembers]);
      }
      newState.selectedUserIds = [
        ...totalMembers,
      ];
    }

    this.setState(newState, () => {
      if (name === 'selectedGroupIds' && !shouldRhinoformSeatsValidationModalDisplay) {
        NotificationActions.addNotification({
          body: `Imported ${this.props.currentRole.name === RHINOFORMS_ADMIN ? this.activeUsersIds(groupId.users).length : groupId.users.length} member${groupId.users.length !== 1 ? 's' : ''} from ${groupId.name} group successfully`, // eslint-disable-line max-len
          type: 'success',
        });
      }
    });

    this.props.handleFormChanges();
  }

  getTitles = () => {
    if (this.state.mode === 'edit') {
      return {
        actionTitle: 'Update Role',
        pageTitle: 'Edit Role',
        actionType: 'primary',
      };
    }
    return {
      actionTitle: 'Create Role',
      pageTitle: 'Create Role',
      actionType: 'secondary',
    };
  }

  render() {
    if (this.state.pageLoading) {
      return <PageLoader />;
    }
    const props = {
      ...this.getTitles(),
      activeOrg: this.props.activeOrg,
      activeUsersIds: this.activeUsersIds,
      currentRole: this.state.currentRole,
      description: this.state.description,
      disabledUserIds: this.state.disabledUserIds,
      errors: this.state.errors,
      formInProgress: this.props.formInProgress,
      handleChange: this.handleChange,
      handleCheckboxClick: this.handleCheckboxClick,
      handleConfirmDeleteRequest: this.handleConfirmDeleteRequest,
      handleFormChanges: this.props.handleFormChanges,
      handlePermissionChange: this.handlePermissionChange,
      handleSubmit: this.handleSubmit,
      handleToggle: this.handleToggle,
      handleToggleModal: this.handleToggleModal,
      handleUpdateSelectedIds: this.handleUpdateSelectedIds,
      isDeleteConfirmationVisible: this.state.isDeleteConfirmationVisible,
      isInEditMode: this.state.mode === 'edit',
      isMobilePermissionVisibleforContact: this.state.isMobilePermissionVisibleforContact,
      isMobilePermissionVisibleforTeam: this.state.isMobilePermissionVisibleforTeam,
      isSystemRole: this.state.isSystemRole,
      name: this.state.name,
      pageContainerRef: (pageContainer) => (this.pageContainer = pageContainer),
      pageLoading: this.state.pageLoading,
      permissions: this.state.permissions,
      seatsLimit: this.props.seatsLimit,
      selectedUserIds: [...this.state.selectedUserIds].sort(DataHelpers.sortComparatorUsingUserLastNameFirstName(this.props.users)),
      showDeleteRoleModal: this.state.showDeleteRoleModal,
      showRhinoformSeatsValidationModal: this.state.showRhinoformSeatsValidationModal,
      users: this.props.users,
      viewAllMembers: this.state.viewAllMembers,
      viewGroups: this.state.viewGroups,
    };

    return (
      <OrganizationRolesForm {...props} />
    );
  }
}

OrganizationRolesFormContainer.propTypes = {
  activeOrg: PropTypes.object.isRequired,
  createRole: PropTypes.func,
  currentRole: PropTypes.object,
  currentUserId: PropTypes.number,
  destroyRole: PropTypes.func.isRequired,
  error: PropTypes.object,
  fetchFilterData: PropTypes.func,
  fetchNewRoleView: PropTypes.func.isRequired,
  fetchRoleFormView: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
  formInProgress: PropTypes.bool,
  groups: PropTypes.object,
  handleFormChanges: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  pageLoading: PropTypes.bool,
  resetFormChanges: PropTypes.func.isRequired,
  seatsLimit: PropTypes.number,
  updateRole: PropTypes.func,
  users: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { group, user, role, auth, ui, form } = state;
  return ({
    activeOrg: organizationSelectors.getCurrentOrg(state),
    currentRole: RoleHelpers.shapeRolePermissions(role.currentRole)[0],
    currentUserId: auth.currentUser,
    error: ui.error,
    formInProgress: role.formInProgress,
    groups: group.groups,
    pageLoading: role.rolesLoading,
    seatsLimit: form.org.seatsLimit || 1,
    users: user.users,
  });
};

const actions = {
  fetchRoleFormView,
  fetchNewRoleView,
  updateRole,
  createRole,
  destroyRole,
  fetchTags,
  fetchFilterData: AuditLogReducer.fetchFilterData,
};

export default connect(mapStateToProps, actions)(unsavedChanges(OrganizationRolesFormContainer));
