import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';
import { setError } from './uiReducer';
import { appointmentType } from '../actions/NormalizrSchema';
import NotificationService from '../services/NotificationService';
import { getObjectKeys, mergeShallow } from '../helpers/DataHelpers';
import * as AppointmentCampaignActionTypes from '../constants/AppointmentCampaignActionTypes';
// SLICE
const appointmentTypeSlice = createSlice({
  name: 'APPOINTMENT_TYPES',
  initialState: {
    appointmentTypes: {},
    appointmentTypeIds: [],
    loading: true,
  },
  reducers: {
    receiveAppointmentTypes: (state, action) => ({
      ...state,
      appointmentTypes: {
        ...action.payload.appointmentTypes,
      },
      appointmentTypeIds: action.payload.appointmentTypeIds,
      loading: false,
    }),
    requestData: (state) => ({
      ...state,
      loading: true,
    }),
    receiveError: (state) => ({
      ...state,
      loading: false,
    }),
  },
  extraReducers: {
    [AppointmentCampaignActionTypes.receiveAppointmentCampaigns]: receiveAppointmentTypesData,
    [AppointmentCampaignActionTypes.receiveAppointmentCampaign]: receiveAppointmentTypesData,
  },
});

function receiveAppointmentTypesData(state, action) {
  return {
    ...state,
    appointmentTypes: mergeShallow(state.appointmentTypes, action.payload.appointmentTypes),
    appointmentTypeIds: [...new Set([...state.appointmentTypeIds, ...action.payload.appointmentTypeIds])],
  };
}

export default appointmentTypeSlice.reducer;

// ACTIONS
export const { receiveAppointmentTypes, requestData, receiveError } = appointmentTypeSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS
export function fetchAppointmentTypes(orgId) {
  return (dispatch) => {
    dispatch(requestData());
    return axios.get(`/appointment/${orgId}/types`)
      .then(((response) => {
        const normalizedAppointmentTypes = normalize(response.data.appointmentTypes, [appointmentType]);
        dispatch(receiveAppointmentTypes(getAppointmentTypesPayload(normalizedAppointmentTypes)));
      }))
      .catch((err) => {
        dispatch(receiveError());
        dispatch(setError(err));
      });
  };
}

export function updateAppointmentTypes(orgId, payload) {
  return (dispatch) =>
    axios.patch(`/appointment/${orgId}/types`, payload)
      .then((response) => {
        NotificationService('updateAppointmentType', response);
        fetchAppointmentTypes();
      })
      .catch((error) => {
        NotificationService('updateAppointmentType', error.response);
        dispatch(setError(error.response || error));
        console.error(error);
      });
}

function getAppointmentTypesPayload(normalizedAppointmentTypes) {
  return {
    appointmentTypes: {
      ...normalizedAppointmentTypes.entities.appointmentTypes,
    },
    appointmentTypeIds: getObjectKeys(normalizedAppointmentTypes.entities.appointmentTypes),
  };
}
