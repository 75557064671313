import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UtilityInlineGrid,
} from 'rhinostyle';

class CancelPaymentRequest extends Component {
  state = {
    isLoading: false,
  };

  closeModal = () => {
    this.setState(() => ({ isLoading: false }));
    this.props.toggleCancelRequestDialogBox();
  }

  onSubmit = async () => {
    this.setState(() => ({ isLoading: true }));
    await this.props.handleCancelPaymentRequest();
    this.closeModal();
  }

  render() {
    return (
      <Modal open={this.props.isCancelRequestDialogBoxOpen}>
        <ModalHeader
          onClose={this.closeModal}
          title="CANCEL PAYMENT REQUEST"
        />
        <ModalBody>
          <div className="u-text-center">
            <h4>Are you sure you want to cancel this payment request?</h4>
          </div>
        </ModalBody>
        <ModalFooter>
          <UtilityInlineGrid align="between">
            <Button type="input" onClick={this.closeModal}>Do Not Cancel</Button>
            <Button
              type="danger"
              loading={this.state.isLoading}
              onClick={this.onSubmit}
              className="button button--secondary"
            >
              Yes, Cancel Request
            </Button>
          </UtilityInlineGrid>
        </ModalFooter>
      </Modal>
    );
  }
}

CancelPaymentRequest.propTypes = {
  handleCancelPaymentRequest: PropTypes.func,
  toggleCancelRequestDialogBox: PropTypes.func,
  isCancelRequestDialogBoxOpen: PropTypes.bool.isRequired,
};

export default CancelPaymentRequest;
