import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  RadioGroup,
  Radio,
} from 'rhinostyle';
import RoutingCategories from './RoutingCategories';
import BackButton from './BackButton';
import PageLoader from './PageLoader';
import MemberGroupSearch from './MemberGroupSearch';
import FormActionButtons from './FormActionButtons';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { ROUTING_MANAGER_DELETE, ROUTING_MANAGER_EDIT } from '../constants/UserPermissionsConstants';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { ValidationHelpers } from '../helpers';
import unsavedChanges from './UnsavedChangesHOC';
import { resetFormChanges } from '../reducers/uiReducer';
import { useCreateRouteMutation, useUpdateRouteMutation, useDeleteRouteMutation, useGetRoutesQuery } from '../services/routingService';
import NotificationService from '../services/NotificationService';
import { DEFAULT_PAGE_SIZE } from '../constants/AppConstants';

const CategoryRouteForm = (props) => {
  const {
    currentOrganization,
    handleFormChanges,
  } = props;
  const params = useParams();
  const history = useHistory();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [formInProgress, setFormInProgress] = useState(false);
  const [routingInputs, setRoutingInputs] = useState({
    toGroupId: null,
    toMemberId: null,
    selectedCategoryIds: [],
    routingBehavior: 'Suggested',
  });
  const [errors, setErrors] = useState({});
  const [createRoute] = useCreateRouteMutation();
  const [updateRoute] = useUpdateRouteMutation();
  const [deleteRoute] = useDeleteRouteMutation();
  const categoryRouteId = params?.categoryRouteId;
  const query = useGetRoutesQuery({ organizationId: currentOrganization.id });
  const { data: categoryRoutes, isLoading: pageLoading } = query;
  useEffect(() => {
    if (!pageLoading && params.categoryRouteId) {
      const activeCategoryRoute = categoryRoutes?.textclassificationroutes.find((route) => route.id === Number(params.categoryRouteId));
      if (activeCategoryRoute) {
        setRoutingInputs({
          toMemberId: activeCategoryRoute.toMemberId,
          toGroupId: activeCategoryRoute.toGroupId,
          selectedCategoryIds: activeCategoryRoute.textClassificationTypes || [],
          routingBehavior: activeCategoryRoute.routingBehavior,
        });
      }
    }
  }, [params.categoryRouteId, pageLoading]);

  function handleSetSelectedCategoryIds(categoryId) {
    setRoutingInputs((current) => ({
      ...current,
      selectedCategoryIds: current.selectedCategoryIds.includes(categoryId) ?
        current.selectedCategoryIds.filter((id) => id !== categoryId) :
        [...current.selectedCategoryIds, categoryId],
    }));
  }

  function handleSelectMember(id) {
    handleChange('toMemberId', id);
  }

  function handleSelectGroup(id) {
    handleChange('toGroupId', id);
  }

  function handleChange(fieldName, value) {
    const payload = { [fieldName]: value };
    if (fieldName === 'routingBehavior' && value === 'Suggested') {
      payload.routingResponseEnabled = false;
    }
    if (fieldName === 'toMemberId') {
      payload.toGroupId = null;
    }
    if (fieldName === 'toGroupId') {
      payload.toMemberId = null;
    }
    setRoutingInputs({ ...routingInputs, ...payload });
    handleFormChanges();
  }

  async function handleSubmit() {
    setFormInProgress(true);
    const payload = {
      routingBehavior: routingInputs.routingBehavior,
      textClassificationTypes: routingInputs.selectedCategoryIds,
      toGroupId: routingInputs.toGroupId,
      toMemberId: routingInputs.toMemberId,
    };
    const validationErrors = ValidationService(ValidationShapers.shapeCategoryRouting(payload));
    const errorCount = Object.keys(validationErrors).length;
    if (errorCount > 0) {
      setFormInProgress(false);
      setErrors(validationErrors);
      ValidationHelpers.handleValidationErrors(validationErrors, formRef.current);
    } else {
      if (params.categoryRouteId) {
        updateRoute({ data: payload, textClassificationRouteId: params.categoryRouteId, organizationId: currentOrganization.id })
          .unwrap()
          .then(() => {
            setFormInProgress(false);
            NotificationService('updateCategoryRoute', { status: 200 });
            history.push(`/managers/routing-manager/${params.categoryRouteId}`);
          })
          .catch((error) => {
            setFormInProgress(false);
            NotificationService('updateCategoryRoute', error);
          });
      } else {
        createRoute({ data: payload,
          organizationId: currentOrganization.id,
          page: 0,
          pageSize: DEFAULT_PAGE_SIZE,
          toMemberIds: [],
          toGroupIds: [],
          routingBehaviorTypes: [],
        })
          .unwrap()
          .then((response) => {
            setFormInProgress(false);
            const { textclassificationroute } = response;
            NotificationService('createCategoryRoute', { status: 200 });
            history.push(`/managers/routing-manager/${textclassificationroute.id}`);
          })
          .catch((error) => {
            setFormInProgress(false);
            NotificationService('createCategoryRoute', error);
          });
      }
      setErrors({});
      dispatch(resetFormChanges());
    }
  }
  // Todo : not the part of MVP but we will use this in future
  // function getContactTemplateVariableOptions(options) {
  //   const optionsCloned = cloneDeep(options);
  //   if (currentOrganization) {
  //     optionsCloned.filter((obj) => {
  //       const filterObj = obj;
  //       if (obj.value === 'Organization Name') {
  //         filterObj.variableValue = currentOrganization.name;
  //       }
  //       return filterObj;
  //     });
  //   }
  //   return optionsCloned;
  // }

  const handleConfirmDeleteRequest = async () => {
    setFormInProgress(true);
    const deleted = await deleteRoute({ organizationId: currentOrganization.id, textClassificationRouteId: params.categoryRouteId });
    setFormInProgress(false);
    if (deleted) {
      NotificationService('deleteCategoryRoute', { status: 200 });
      history.push('/managers/routing-manager');
    }
  };

  const routingLabel = (routingType) => (
    <div className="u-p-l-small">
      <div className="u-font-weight-bold">{routingType} Routing </div>
      <div className="u-text-muted u-text-small">
        {routingType === 'Auto' ? 'Messages will automatically be routed to the suggested group or member.' :
          'Messages will be suggested and member will need to confirm or change suggested route.'}
      </div>
    </div>
  );

  if (pageLoading) return <PageLoader />;

  return (
    <div className="app-page__container" ref={formRef}>
      <div className="app-page__container__inner">
        <div className="app-page__header u-m-b-large">
          <div className="app-page__header__title">
            <BackButton navigateTo="/managers/routing-manager" />
            {`${categoryRouteId ? 'Edit' : 'Create'} Category Routing`}
          </div>
        </div>
        <RoutingCategories
          selectedCategoryIds={routingInputs.selectedCategoryIds}
          handleSelect={handleSetSelectedCategoryIds}
          validationMessage={errors.textClassificationTypes}
        />
        <div className="box form__group">
          <div className="box__title-wrapper">
            <div className="box__title">Routing Method</div>
          </div>
          <RadioGroup
            name="routingBehavior"
            onChange={(value) => handleChange('routingBehavior', value)}
            selectedValue={routingInputs.routingBehavior}
            validationMessage={errors.routingBehavior}
          >
            <Radio value="Automatic" label={routingLabel('Auto')} />
            <Radio value="Suggested" label={routingLabel('Suggested')} />
          </RadioGroup>
        </div>
        <MemberGroupSearch
          title="Routing Path"
          titleSub="Messages categorized as the following will be routed here"
          handleSelectGroup={handleSelectGroup}
          handleSelectMember={handleSelectMember}
          name="routingPath"
          validationMessage={errors.toGroupId}
          selectedMemberId={routingInputs.toMemberId}
          selectedGroupId={routingInputs.toGroupId}
          viewGroupsInitial={routingInputs.toGroupId > 0}
        />
        {// Todo : not the part of MVP but we will use this in future
        /* <div className="box">
          <div className="box__title-wrapper">
            <div className="box__title">Routing Response Message </div>
            <div className="box__subtitle">Message sent to patient when an incoming message is routed</div>
          </div>
          <RhinoSwitch
            name="routingResponseEnabled"
            disabled={routingInputs.routingBehavior === 'suggested'}
            isChecked={routingInputs.routingResponseEnabled}
            onChange={handleChange}
            label={<>Enable Routing Response Message{renderComingSoon()}</>}
            validationMessage={errors.routingResponseEnabled}
          />
          <CategoryVariableMessage
            className="u-m-t-large"
            name="routingResponse"
            handleTemplateChange={handleChange}
            composeLabel="Message"
            variableOptions={getContactTemplateVariableOptions(TEMPLATE_MESSAGE_VARIABLE_OPTIONS)}
            template={routingInputs.routingResponse}
            required={routingInputs.routingResponseEnabled}
            showCategories
            defaultSelectedCategory="Demographics"
            placeholder="Enter Message Here"
            attachmentsEnabled
            attachments={routingInputs.attachments}
          />
        </div> */}
        <div className="u-m-t-large">
          <FormActionButtons
            formInProgress={formInProgress}
            handleConfirmDeleteRequest={handleConfirmDeleteRequest}
            handleSubmit={handleSubmit}
            name="Category Route"
            deletePermissions={[ROUTING_MANAGER_DELETE]}
            editPermissions={[ROUTING_MANAGER_EDIT]}
            editMode={!!params.categoryRouteId}
            dataFeatureTag="routing-manager_"
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentOrganization: getCurrentOrg(state),
});

CategoryRouteForm.propTypes = {
  currentOrganization: PropTypes.object,
  handleFormChanges: PropTypes.func,
};

export default connect(mapStateToProps)(unsavedChanges(CategoryRouteForm));
