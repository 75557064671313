import React from 'react';
import PropTypes from 'prop-types';
import {
  UtilityInlineGrid,
  Dropdown,
  DateRangeDropdown,
  DropdownMenuItem,
  SmartTable,
  Tooltip,
  Icon,
  Button,
} from 'rhinostyle';
import { convertUtcStringToLocalMoment } from '../helpers/DateHelpers';
import { formatUserLabel } from '../helpers/UserHelpers';
import EmptyMessage from './EmptyMessage';
import { RHINOPAY_REQUESTS_PAGE_SIZE, BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD } from '../constants/AppConstants';
import SmartTableHeader from './SmartTableHeader';
import { formatNumberToCurrencyString } from '../helpers/NumberHelpers';

const RhinoPayManager = (props) => {
  const {
    activeKey,
    startDate,
    endDate,
    minDate,
    maxDate,
    datePickerDropdownLabels,
    selectDate,
    calculateDateRange,
    statuses,
    selectedStatus,
    handleChange,
    isDateSelected,
    clearAllFilters,
    navigateToConversation,
    isPreviousDisabled,
    isNextDisabled,
    pageItemIndexFrom,
    pageItemIndexTo,
    totalPageCount,
    handlePagination,
    totalRhinoPayRequests,
    rhinoPayRequests,
    pageNo,
    smartTableHeadersConfig,
    sortTable,
  } = props;

  const smartTableColumns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Date"
          sortKey="createdAt"
          headers={smartTableHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'createdAt',
      fixed: 'left',
      minWidth: 170,
      Cell: (row) => (<div>{convertUtcStringToLocalMoment(row.value).format('MM/DD/YY hh:mm a')}</div>),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Amount"
          sortKey="amount"
          headers={smartTableHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'amount',
      minWidth: 110,
      Cell: (row) => (<div>${formatNumberToCurrencyString(row.value)}</div>),
    },
    {
      Header: () => 'Note',
      headerStyle: {
        padding: 9,
      },
      accessor: 'note',
      className: 'u-text-center',
      minWidth: 65,
      sortable: false,
      Cell: (row) => (
        row.value && (
          <Tooltip placement="top" content={row.value}>
            <Icon icon="note" />
          </Tooltip>
        )
      ),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Request Sent"
          sortKey="requestSent"
          headers={smartTableHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'requestSent',
      minWidth: 140,
      headerClassName: 'u-text-center',
      className: 'u-text-center',
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Status"
          sortKey="status"
          headers={smartTableHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'status',
      minWidth: 110,
      Cell: (row) => (
        <div className="payment-request">
          <span className={`payment-request__circle payment-request__circle--${(row.value).toLowerCase()}`} />
          <span className={`payment-request__status-label--${(row.value).toLowerCase()}`}>{row.value}</span>
        </div>
      ),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Date Paid"
          sortKey="paidDate"
          headers={smartTableHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'paidDate',
      minWidth: 170,
      Cell: (row) => (row.value && <div>{convertUtcStringToLocalMoment(row.value).format('MM/DD/YY hh:mm a')}</div>),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Contact"
          sortKey="contact"
          headers={smartTableHeadersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'contact',
      className: 'u-p-a-0',
      minWidth: 225,
      Cell: (row) => (
        <Button type="link" size="small" className="payment-request__button-link" onClick={() => navigateToConversation(row.value.id)}>
          {row.value && formatUserLabel(row.value)}
        </Button>
      ),
    },
  ];

  const isAnyFilterSelected = (isDateSelected || selectedStatus.length > 0);
  const isStatusSelected = selectedStatus.length > 0;

  const renderFilters = () => (
    <div className="rhinopay-wrapper__filter">
      <div className="rhinopay-wrapper__filter-label">FILTER BY:
        {isAnyFilterSelected && (
          <Button type="link" className="u-text-small" onClick={clearAllFilters}>Clear All &nbsp;<Icon icon="close" /></Button>
        )}
      </div>
      <UtilityInlineGrid>
        <DateRangeDropdown
          name="RhinoPay Date Picker"
          startDate={startDate}
          endDate={endDate}
          selectDate={selectDate}
          minDate={minDate}
          maxDate={maxDate}
          activeKey={activeKey}
          position="center"
          dropdownMenuItems={datePickerDropdownLabels}
          selectDateRange={calculateDateRange}
          dataCypress="rhinopayManagerDatePicker"
          isCustomDate
        />
        <Dropdown
          position="bottom"
          activeKey={selectedStatus}
          label="Status"
          type={isStatusSelected ? 'primary' : 'input'}
          outlined={!!isStatusSelected}
          name="statuses"
          onSelect={(value) => handleChange(value)}
        >
          {statuses.map((status) => (
            <DropdownMenuItem
              key={status.id}
              id={status.id}
              label={status.value ? status.value : null}
              labelDesc={status.description ? status.description : null}
              dataCypress={`rhinoPayManager-${status.description}`}
            />
          ))}
        </Dropdown>
      </UtilityInlineGrid>
    </div>
  );

  const renderPaymentRequests = () => (
    <div className="u-p-t" data-cypress="rhinoPayRequests">
      {totalRhinoPayRequests > 0 ? (
        <SmartTable
          showPagination={false}
          data={rhinoPayRequests}
          onFetchData={sortTable}
          columns={smartTableColumns}
          minRows={0}
          sortable
          manual={false}
          striped
          sticky
          defaultPageSize={totalRhinoPayRequests}
        />
      ) : (
        <EmptyMessage section="RhinoPay Manager" />
      )}
    </div>
  );

  const isBottomPaginationControlsVisible = (totalItems) =>
    (totalItems > RHINOPAY_REQUESTS_PAGE_SIZE &&
      rhinoPayRequests.length >= BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD);

  const renderPaginationControls = () => {
    const previousCaretClass = isPreviousDisabled ? 'pagination__caret--disabled' : '';
    const nextCaretClass = isNextDisabled ? 'pagination__caret--disabled' : '';
    return (
      <div className="u-text-right u-text-small">
        <span>{`${pageItemIndexFrom}-${pageItemIndexTo} of ${totalRhinoPayRequests} Items`}</span>
        <Button
          type="link"
          size="small"
          iconOnly
          disabled={isPreviousDisabled}
          onClick={() => handlePagination('previous')}
          className={previousCaretClass}
        >
          <Icon icon="caret-left" />
        </Button>
        <span><span className="u-font-weight-bold">{pageNo + 1}</span>{` of ${totalPageCount}`}</span>
        <Button
          type="link"
          iconOnly
          size="small"
          disabled={isNextDisabled}
          onClick={() => handlePagination('next')}
          className={nextCaretClass}
        >
          <Icon icon="caret-right" />
        </Button>
      </div>
    );
  };

  const renderRhinoPayDashboard = () => (
    <>
      <div className="row u-p-t">
        <h4 className="column-6@medium column-6@small u-m-b-0">Total Payment Requests ({totalRhinoPayRequests})</h4>
        <div className="column-6@medium column-6@small u-p-t-small">
          { totalRhinoPayRequests > RHINOPAY_REQUESTS_PAGE_SIZE && renderPaginationControls()}
        </div>
      </div>
      {renderFilters()}
      {renderPaymentRequests()}
      {isBottomPaginationControlsVisible(totalRhinoPayRequests) && (
        <div className="row">
          <div className="column-6@small column-4@medium column-offset-8@small column-offset-8@medium">
            {renderPaginationControls()}
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="app-page__container">
      <div className="u-m-t-large u-text-center">
        <h3>RhinoPay Manager</h3>
      </div>
      <div className="rhinopay-wrapper">
        {renderRhinoPayDashboard()}
      </div>
    </div>
  );
};

RhinoPayManager.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  activeKey: PropTypes.number.isRequired,
  datePickerDropdownLabels: PropTypes.array.isRequired,
  selectDate: PropTypes.func.isRequired,
  calculateDateRange: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isDateSelected: PropTypes.bool,
  clearAllFilters: PropTypes.func.isRequired,
  navigateToConversation: PropTypes.func.isRequired,
  isPreviousDisabled: PropTypes.bool.isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
  pageItemIndexFrom: PropTypes.number.isRequired,
  pageItemIndexTo: PropTypes.number.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  handlePagination: PropTypes.func.isRequired,
  pageNo: PropTypes.number.isRequired,
  totalRhinoPayRequests: PropTypes.number.isRequired,
  rhinoPayRequests: PropTypes.array.isRequired,
  smartTableHeadersConfig: PropTypes.object.isRequired,
  sortTable: PropTypes.func.isRequired,
};

export default RhinoPayManager;
