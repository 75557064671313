import { createSelector } from '@reduxjs/toolkit';

const getAppointmentTypeIds = (state) => state.appointmentTypes.appointmentTypeIds;
const getAppointmentTypes = (state) => state.appointmentTypes.appointmentTypes;

export const getActiveAppointmentTypeIds = createSelector( // eslint-disable-line
  [getAppointmentTypes, getAppointmentTypeIds],
  (appointmentTypes, appointmentTypeIds) =>
    appointmentTypeIds
      .filter((id) => !appointmentTypes[id].ignored),
);
