import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import OrganizationSearch from '../components/OrganizationSearch';

import * as OrganizationReducer from '../reducers/organizationReducer';

class OrganizationSearchContainer extends Component {
  state = {
    searchText: '',
  }

  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 300);
  }

  handleSearch = (id, searchText) => {
    if (searchText) {
      this.props.fetchOrgSearch(searchText);
    } else {
      this.props.resetOrgSearch();
    }

    this.setState({ searchText });
  };

  render() {
    const props = {
      handleSearch: this.handleSearch,
      searchText: this.state.searchText,
    };

    return (
      <OrganizationSearch {...props} />
    );
  }
}

OrganizationSearchContainer.propTypes = {
  resetOrgSearch: PropTypes.func.isRequired,
  fetchOrgSearch: PropTypes.func.isRequired,
};

const actions = {
  resetOrgSearch: OrganizationReducer.resetOrgSearch,
  fetchOrgSearch: OrganizationReducer.fetchOrgSearch,
};

export default connect(null, actions)(OrganizationSearchContainer);
