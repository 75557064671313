import Mark from 'mark.js';
import ReactHtmlParser from 'react-html-parser';
import { AppointmentHelpers } from '.';
import {
  TYPE_MENTION_ALL,
  TYPE_MENTION_GROUP,
  TYPE_MENTION_USER,
} from '../constants/Types';

export function capitalize(word) {
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
}

export function capitalizeProp(prop) {
  return (item) => {
    const itemClone = { ...item };

    itemClone[prop] = capitalize(itemClone[prop]);

    return itemClone;
  };
}

export function formatFilename(filename) {
  const extension = filename.split('.').pop();
  const name = filename.split('.').slice(0, -1).join('.');
  let retVal = filename;
  if (name.length > 10) {
    retVal = `${name.substring(0, 10)}...${extension}`;
  }

  return retVal;
}

export function titleCase(word) {
  return word.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function highlightSearchedText(text, searchString) {
  const element = document.createElement('span');
  element.innerHTML = text;
  const instance = new Mark(element);
  instance.mark(searchString, {
    element: 'strong',
    separateWordSearch: false,
    diacritics: false,
  });
  return ReactHtmlParser(element.innerHTML);
}

export function removeAllWhiteSpace(string) {
  // This Regex matches all spaces, tabs, and new lines.
  return string.replace(/\s/g, '');
}

export function replaceUnderscoresWithSpaces(stringData) {
  return stringData.replace(/_/g, ' ');
}

export function replaceSpacesWithUnderscores(stringData) {
  return stringData.replace(/\s/g, '_');
}

export function formatCamelCaseToSpaces(camelCaseString) {
  return camelCaseString.split(/(?=[A-Z])/).join(' ');
}

export function replaceWhiteSpaceWithSpace(string) {
  return string.replace(/\s+/g, ' ');
}

export function replaceZeroWidthWhiteSpace(string) {
  return string.replace(/[\u200B-\u200D\uFEFF]/g, '');
}

export function removeSpecialCharacters(string) {
  return string.replace(/[^a-zA-Z0-9]/g, '');
}

export function luhnAlgorithm(cardNumberNoSpaces) {
  const arr = cardNumberNoSpaces
    .split('')
    .reverse()
    .map((x) => parseInt(x, 10));

  const lastDigit = arr.splice(0, 1)[0];
  let sum = arr.reduce((acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9), 0);
  sum += lastDigit;

  return sum % 10 === 0;
}

/**
 * @function removePeriodsFromString
 * Remove all periods from a string.
 *
 * EXAMPLES:
 * '1.11'           --> "111"
 * '1.00'           --> "100"
 * 'hello.world.c'  --> "helloworldc"
 *
 * @param {String} string String to be processed.
 * @returns {String} original string with all periods ('.') removed.
 */
export const removePeriodsFromString = (string) => string.replace(/\./g, '');

const getPreferredNameDisplayValue = (activeUser) => {
  let { preferredName } = activeUser;
  const { firstName } = activeUser;
  // if prefered name is not present and first name present then use first name.
  if (!preferredName && firstName) {
    preferredName = firstName;
  }
  // if prefered name is not present and first name also not present then blank.
  if (!preferredName && !firstName) {
    preferredName = '';
  }
  return preferredName;
};

export const replaceInboxVariablesByValue = (message, options) => {
  let formattedMessage = message;
  const { activeUser, currentUser, userOrganization, appointment, providerName } = options;
  const { firstName, lastName } = activeUser;
  const appointmentDateTime = appointment ? AppointmentHelpers.formatAppointmentTime(appointment.formattedAppointmentTime, 'dddd, MMM Do, YYYY', 'h:mm a z') : '';
  const appointmentDate = appointment ? AppointmentHelpers.formatAppointmentTime(appointment.formattedAppointmentTime, 'dddd, MMM Do, YYYY', null) : '';
  const appointmentTime = appointment ? AppointmentHelpers.formatAppointmentTime(appointment.formattedAppointmentTime, null, 'h:mm a z') : '';
  formattedMessage = formattedMessage.replace(/{First_Name}/gi, firstName ?? '');
  formattedMessage = formattedMessage.replace(/{Last_Name}/gi, lastName ?? '');
  formattedMessage = formattedMessage.replace(/{Preferred_Name}/gi, getPreferredNameDisplayValue(activeUser));
  formattedMessage = formattedMessage.replace(/{Appointment_Date_Time}/gi, appointmentDateTime);
  formattedMessage = formattedMessage.replace(/{Appointment_Date}/gi, appointmentDate);
  formattedMessage = formattedMessage.replace(/{Appointment_Time}/gi, appointmentTime);
  formattedMessage = formattedMessage.replace(/{Organization_Name}/gi, userOrganization.name);
  formattedMessage = formattedMessage.replace(/{Sending_Member_First_Name}/gi, currentUser.firstName);
  formattedMessage = formattedMessage.replace(/{Sending_Member_Last_Name}/gi, currentUser.lastName);
  formattedMessage = formattedMessage.replace(/{Provider_Name}/gi, providerName);
  return formattedMessage;
};

export const getMentionPayload = (messageText) => {
  const payload = {
    mentionUserIds: [],
    mentionGroupIds: [],
    mentionTypeId: TYPE_MENTION_USER,
  };
  const splitMessage = messageText.split(' ');
  splitMessage.forEach((el) => {
    if (el.includes('{userId-') && el.match(/{userId-(.*)}/).pop()) {
      // const mentionedUserId = el.match(/{userId-(.*)}/).pop();
      // if (mentionableUserIds.includes(Number(mentionedUserId))) {
      //   payload.mentionUserIds = [...new Set([...payload.mentionUserIds, Number(mentionedUserId)])];
      //   payload.mentionTypeId = TYPE_MENTION_USER;
      // }
      return { userId: el.match(/{userId-(.*)}/).pop() };
    } else if (el.includes('{groupId-') && el.match(/{groupId-(.*)}/).pop()) {
      return { groupId: el.match(/{groupId-(.*)}/).pop() };
      // const mentionedUserId = el.match(/{groupId-(.*)}/).pop();
      // payload.mentionGroupIds = [...new Set([...payload.mentionGroupIds, Number(mentionedUserId)])];
      // payload.mentionTypeId = TYPE_MENTION_GROUP;
    } else if (el.trim() === '{@All}') {
      return 'All';
      // payload.mentionTypeId = TYPE_MENTION_ALL;
    } return null;
  });
  return payload;
};

const userMention = /{userId-(.*)}/;
const groupMention = /{groupId-(.*)}/;

export function getHasMention(string) {
  return string.match(userMention)
  || string.match(groupMention)
  || string.trim() === '{All}'
  || string.trim() === '{@All}';
}

export const getMentionedIdFromString = (string) => {
  if (string.includes('{userId-') && string.match(userMention).pop()) {
    return { userId: string.match(userMention).pop() };
  } else if (string.includes('{groupId-') && string.match(groupMention).pop()) {
    return { groupId: string.match(groupMention).pop() };
  } else if (string.trim() === '{All}' || string.trim() === '{@All}') {
    return 'All';
  }
  return null;
};

export const processMentionsInMessage = (messageText, draftMentions) => {
  let message = messageText;
  const mentionPayload = {
    mentionUserIds: [],
    mentionGroupIds: [],
    mentionTypeId: TYPE_MENTION_USER,
  };
  draftMentions.forEach((mention) => {
    const { displayName, groupId, userId } = mention;
    const adjustedDisplayName = handleSpecialChars(displayName);
    const findUserNameRegex = new RegExp(adjustedDisplayName, 'gm');
    if (displayName && findUserNameRegex.test(message)) {
      if (groupId) {
        mentionPayload.mentionGroupIds.push(groupId);
        mentionPayload.mentionTypeId = TYPE_MENTION_GROUP;
        message = replaceMentionsWithValue(displayName, message, `{groupId-${groupId}}`);
      } else if (userId) {
        mentionPayload.mentionUserIds.push(userId);
        message = replaceMentionsWithValue(displayName, message, `{userId-${userId}}`);
      }
    }
  });
  if (message.includes('@All')) {
    message = replaceMentionsWithValue('@All', message, '{@All}');
    mentionPayload.mentionTypeId = TYPE_MENTION_ALL;
  }
  return { message, mentionPayload };
};

function replaceMentionsWithValue(searchTerm, srcString, replacement) {
  const adjustedDisplayName = handleSpecialChars(searchTerm);
  const findMentionRegex = new RegExp(`(^|\\s)${adjustedDisplayName}(?=\\s|$)`, 'm');
  let foundMention = findMentionRegex.exec(srcString);
  let newString = srcString;
  while (foundMention) {
    let foundIndex = foundMention.index;
    if (new RegExp('\\s').test(newString[foundIndex])) foundIndex += 1;
    newString =
      newString.substr(0, foundIndex) +
      replacement +
      newString.substr(Math.min(foundIndex + searchTerm.length, newString.length));
    foundMention = findMentionRegex.exec(newString);
  }
  return newString;
}

// adjusts the strings so they don't break regular expressions used here
function handleSpecialChars(string) {
  let newString = string;
  newString = newString
    .replace(/\[/g, '\\[')
    .replace(/\]/g, '\\]')
    .replace(/\(/g, '\\(')
    .replace(/\)/g, '\\)')
    .replace(/\|/g, '\\|')
    .replace(/\$/g, '\\$')
    .replace(/\^/g, '\\^')
    .replace(/\\'/g, "\\'")
    .replace(/\"/g, '\\"') // eslint-disable-line no-useless-escape
    .replace(/\;/g, '\\;') // eslint-disable-line no-useless-escape
    .replace(/\:/g, '\\:') // eslint-disable-line no-useless-escape
    .replace(/\?/g, '\\?')
    .replace(/\//g, '\\/')
    .replace(/\>/g, '\\>') // eslint-disable-line no-useless-escape
    .replace(/\</g, '\\<') // eslint-disable-line no-useless-escape
    .replace(/\./g, '\\.')
    .replace(/\,/g, '\\,'); // eslint-disable-line no-useless-escape
  return newString;
}
