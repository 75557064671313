import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGetThreadQuery, useLazyGetThreadQuery } from '../services/threadService';
import { useThreadOptions } from '.';
import { getFilteredChatThreadEventIds } from '../selectors/chatSelectors';
import { fetchChatThread } from '../reducers/chatReducer';
import { setOptimisticEvents } from '../reducers/inboxReducer';
import { EVENT_SIZE } from '../constants/AppConstants';
import { getStorageKey } from '../selectors/userSelectors';
import StorageService from '../services/StorageService';

const useThreadEvents = (pageNo) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isInboxThread = location.pathname.includes('inbox');
  const [hasMorePages, setHasMorePages] = useState(true);
  const storageKey = useSelector((state) => getStorageKey(state, 'optimisticEvents'));
  const eventIds = useSelector((state) => getFilteredChatThreadEventIds(state));
  const chatThreadLoading = useSelector((state) => state.chat.threadLoading);
  const optimisticEvents = useSelector((state) => state.inbox.optimisticEvents);
  const chatPageLoading = useSelector((state) => state.chat.pageLoading);
  const threadOptions = useThreadOptions();
  const [fetchThread, { isFetching }] = useLazyGetThreadQuery();
  // // Load first page and subscribe to the aggregated data
  const pageEvents = useGetThreadQuery(threadOptions, { skip: !isInboxThread || !threadOptions.userId,
    selectFromResult: (result) => result });
  // Load additional pages as needed
  useEffect(() => {
    if (isInboxThread && pageNo >= 0 && pageNo !== threadOptions.initialPageNo) {
      fetchThreadPage(pageNo);
    }
  }, [pageNo]);

  useEffect(() => {
    if (pageNo >= 0 && pageNo !== threadOptions.initialPageNo && !isInboxThread) {
      fetchChatPage();
    }
  }, [threadOptions.initialPageNo, pageNo, isInboxThread]);

  async function fetchThreadPage(page) {
    const options = { ...threadOptions, pageNo: page };
    await fetchThread(options);
  }

  async function fetchChatPage() {
    const hasMorePagesChat = await dispatch(fetchChatThread({ ...threadOptions, pageNo: pageNo || 0 })) === EVENT_SIZE;
    setHasMorePages(hasMorePagesChat);
  }

  useEffect(() => {
    if (threadOptions.userId && pageEvents?.data?.ids?.length) {
      const storedEvents = StorageService.readEntry(storageKey);
      if (storedEvents?.length) {
        const filteredEvents = filterCreatedEvents(storedEvents);
        dispatch(setOptimisticEvents(filteredEvents));
      }
    }
  }, [pageEvents?.data?.ids]);

  function filterCreatedEvents(savedEvents) {
    return savedEvents.filter((event) => !(pageEvents.data?.ids.find((id) => pageEvents.data.entities[id]?.optimisticId === event.optimisticId)));
  }

  function mergeEvents(queryEvents) {
    if (queryEvents?.data && optimisticEvents?.length) {
      const tempEvents = optimisticEvents.reduce((accumulator, task) => ({ ...accumulator, [task.optimisticId]: task }), {});
      return {
        data: {
          ...queryEvents.data,
          ids: [...queryEvents.data?.ids, ...Object.keys(tempEvents)],
          entities: { ...queryEvents.data?.entities, ...tempEvents },
        },
      };
    } return {};
  }

  if (isInboxThread) {
    return { ...pageEvents, ...mergeEvents(pageEvents), threadLoading: isFetching || pageEvents.isFetching };
  } return { isLoading: chatPageLoading, threadLoading: chatThreadLoading, data: { ids: eventIds, hasMorePages } };
};

export default useThreadEvents;
