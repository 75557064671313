import React from 'react';
import { Link } from 'react-router-dom';
import {
  Resource,
  ResourceBody,
  ResourceIntro,
  ResourceGroup,
  Scrollbars,
  UtilitySystem,
} from 'rhinostyle';
import PropTypes from 'prop-types';

import { ChannelHelpers, PhoneHelpers } from '../helpers';

import Tags from './Tags';

const ChannelRoutesReadOnly = (props) => {
  const {
    channels,
    mode,
    selectedChannelIds,
    phones,
  } = props;

  const renderChannel = (id, idx) => {
    const channel = channels[id];

    // If channel has a phone number, format it
    const phone = channel.details.phone ? PhoneHelpers.formatPhone(phones[channel.details.phone].value) : null;

    return (
      <Resource key={idx} disabled data-cypress={channel.name}>
        <ResourceIntro icon={{ icon: ChannelHelpers.getChannelIcon(channel.typeId) }} title={channel.name} titleSub={phone} />
        {channel.tags?.length > 0 && <ResourceBody className="u-text-muted"><Tags tagIds={channel.tags} /></ResourceBody>}
      </Resource>
    );
  };
  const renderViewSelectedChannels = () => (
    selectedChannelIds.length > 0 ? (
      <Scrollbars autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.small}>
        <ResourceGroup>
          {selectedChannelIds.map(renderChannel)}
        </ResourceGroup>
      </Scrollbars>
    ) :
      <p className="u-text-muted">No Channel Routes for this {mode}</p>
  );

  return (
    <div className="box">
      <div className="box__title-wrapper">
        <div className="box__title">Inbound message channel routes</div>
      </div>
      {renderViewSelectedChannels()}
      <p className="u-text-small">Channel routes can be updated from the&nbsp;
        <Link // eslint-disable-line jsx-a11y/anchor-is-valid
          className="u-text-underline"
          to="/settings/organization/channels"
        >
          Channels
        </Link> page.
      </p>
    </div>
  );
};

ChannelRoutesReadOnly.propTypes = {
  channels: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  phones: PropTypes.object.isRequired,
  selectedChannelIds: PropTypes.array.isRequired,
};

export default ChannelRoutesReadOnly;
