import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Button } from 'rhinostyle';
import { handleHangup } from '../reducers/rhinocallReducer';

const RhinocallHangupButton = () => {
  const isHangingUp = useSelector((state) => state.rhinocall.isHangingUp);
  const dispatch = useDispatch();

  const handleToggleHangup = () => {
    dispatch(handleHangup());
  };

  return (
    <div>
      <Button
        type="link"
        size="large"
        iconOnly
        title="End Call"
        onClick={handleToggleHangup}
        className="rhinocall__action-bar__hangup-button"
        data-cypress="endCall"
        loading={isHangingUp}
      >
        <Icon size="large" bump="down" icon="call-end" />
      </Button>
      <div className="rhinocall__action-bar__label">
        <span>End</span>
      </div>
    </div>
  );
};

export default RhinocallHangupButton;
