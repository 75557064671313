import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { UtilitySystem } from 'rhinostyle';

import * as UserHelpers from '../helpers/UserHelpers';
import MultiOrgContainer from '../containers/MultiOrgContainer';

const ContactsMenu = (props) => {
  const history = useHistory();
  const {
    handleNavigationClick,
    organizations,
    currentUser,
    isBulkMessagingEnabled,
    hasBulkMessagingPermission,
  } = props;
  // ccr's can always see the saved lists menu option
  // non-ccr's can see them if the feature is enabled and they have permissions
  const isSavedListsVisible = (UserHelpers.isCCR(currentUser) && isBulkMessagingEnabled) || (isBulkMessagingEnabled && hasBulkMessagingPermission);

  const menuOptions = [
    {
      title: 'Individual',
      id: 'nav-contacts',
      to: '/contacts',
      dataCypress: 'individualOption',
      isVisible: true,
    },
    {
      title: 'Saved Lists',
      id: 'nav-contact-list',
      to: '/contact-list',
      dataCypress: 'savedListsOption',
      isVisible: isSavedListsVisible,
    },
  ];

  const renderMenuListItems = () =>
    menuOptions
      .filter((option) => option.isVisible)
      .map((option) => (
        <li key={option.id} className="app-navigation__nav__sub">
          <NavLink
            id={option.id}
            to={option.to}
            data-cypress={option.dataCypress}
            onClick={handleNavigationClick}
            className="app-navigation__nav__button"
            activeClassName={UtilitySystem.config.classes.active}
          >
            <span>{option.title}</span>
          </NavLink>
        </li>
      ));

  return (
    <nav id="app-navigation" className="app-navigation">
      <div className="app-navigation__container">
        <div className="app-navigation__top">
          <div className="app-navigation__container__scroll">
            <ul className="app-navigation__nav">
              <li className="app-navigation__nav__header">
                <span className="app-navigation__nav__button__text">Contacts</span>
              </li>
              <div className="app-navigation__list-item__divider" />
              {renderMenuListItems()}
            </ul>
          </div>
        </div>
        <div className="app-navigation__bottom">
          {currentUser.organization && (
          <div className="app-navigation__org">
            {organizations[currentUser.organization].name}
            <span>Organization{UserHelpers.isCCR(currentUser) && ` (#${organizations[currentUser.organization].id})`}</span>
          </div>
          )}
          <MultiOrgContainer history={history} />
        </div>
      </div>
    </nav>
  );
};

ContactsMenu.propTypes = {
  handleNavigationClick: PropTypes.func,
  organizations: PropTypes.object,
  currentUser: PropTypes.object,
  isBulkMessagingEnabled: PropTypes.bool.isRequired,
  hasBulkMessagingPermission: PropTypes.bool.isRequired,
};

export default ContactsMenu;
