import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Button } from 'rhinostyle';
import { setMicEnabled } from '../services/BandwidthWebrtcService';

const RhinocallToggleMicrophoneButton = (props) => {
  const { isAudioConnected } = props;
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(true);

  const handleToggle = () => {
    setIsMicrophoneEnabled((current) => !current);
  };

  useEffect(() => {
    setMicEnabled(isMicrophoneEnabled);
  }, [isMicrophoneEnabled]);

  return (
    <div>
      <Button
        type="link"
        size="large"
        iconOnly
        title={isMicrophoneEnabled ? 'Mute' : 'Unmute'}
        onClick={handleToggle}
        className="rhinocall__action-bar__button"
        disabled={!isAudioConnected}
      >
        <Icon className="rhinocall__action-bar__microphone-button" icon={isMicrophoneEnabled ? 'microphone' : 'microphone-off'} />
      </Button>
      <div className="rhinocall__action-bar__label">
        <span>Mic</span>
      </div>
    </div>
  );
};

RhinocallToggleMicrophoneButton.propTypes = {
  isAudioConnected: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAudioConnected: state.rhinocall.isAudioConnected,
});

export default connect(mapStateToProps)(RhinocallToggleMicrophoneButton);
