import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { mergeShallow, exists, cloneDeep, getObjectKeys } from '../helpers/DataHelpers';

import * as RoleActionTypes from '../constants/RoleActionTypes';
import * as OrganizationActionTypes from '../constants/OrganizationActionTypes';
import * as ChatActionTypes from '../constants/ChatActionTypes';
import * as SecureActionTypes from '../constants/SecureActionTypes';
import * as GroupActionTypes from '../constants/GroupActionTypes';
import * as FormActionTypes from '../constants/FormActionTypes';
import {
  setError,
  createLock,
  fetchInboxSections,
  setModalFormInProgress,
  setFormInProgress,
  toggleEmailModal,
  hideSessionTimeoutModal,
} from './uiReducer';
import * as UserHelpers from '../helpers/UserHelpers';
import * as RoleHelpers from '../helpers/RoleHelpers';
import * as SearchHelpers from '../helpers/SearchHelpers';
import NotificationService from '../services/NotificationService';
import OutboundPostMessageService from '../services/OutboundPostMessageService';
import { user, group, tag, channel, role } from '../actions/NormalizrSchema';
import * as TagReducer from './tagReducer';
import * as GroupReducer from './groupReducer';
import * as ChannelReducer from './channelReducer';
import { getRoles } from './roleReducer';
import StorageService from '../services/StorageService';
import { InboxActionTypes, AuthActionTypes, SavedContentActionTypes, Types, UserPermissionsConstants } from '../constants';
import { CONTACT_RESULT_SIZE, SEARCH_SIZE } from '../constants/AppConstants';
import { PhoneHelpers } from '../helpers';
import threadService from '../services/threadService';
import searchService from '../services/searchService';

const { THREAD_VIEW } = UserPermissionsConstants;

// REDUCER

export const fetchUserById = createAsyncThunk(
  'users/fetchUser',
  async (userId) => {
    const response = await axios.get(`/users/${userId}`);
    return response.data;
  },
  {
    condition: (userId, { getState }) => {
      const { user: { users } } = getState();
      const fetchStatus = users[userId];
      return !exists(fetchStatus?.id);
    },
  },
);

const userSlice = createSlice({
  name: 'USER',
  initialState: {
    appointmentLoading: false,
    connectedPartySearchIds: [],
    connectedPartySearchLoading: false,
    contactSearchIds: [],
    loading: false,
    locationFilter: null,
    memberSearchIds: [],
    memberSearchLoading: false,
    modalPhoneSearchIds: [],
    modalPhoneSearchLoading: false,
    myUserIds: [],
    myUsers: {},
    myUsersRaw: [],
    pageLoading: true,
    pageNo: 0,
    paymentRequests: [],
    phoneSearchLoading: false,
    phoneUserSearchIds: [],
    tagFilter: null,
    typeFilter: null,
    userId: null,
    userIds: [],
    memberUserIds: [],
    contactList: [],
    userSearchIds: [],
    userSearchLoading: false,
    users: null,
    userSearchSource: null,
    totalUserCount: 0,
    totalActiveMemberCount: 0,
    totalMemberCount: 0,
    allMembersIds: [],
    allMembers: {},
    totalContacts: 0,
    contactListLoading: false,
    showGlobalSearchModalWrapper: false,
    searchSize: SEARCH_SIZE,
    page: 0,
    contactUserIds: [],
    contactUsersLoading: false,
    contactPage: 0,
    contactFormLoading: false,
    mentionUserIds: [],
  },
  reducers: {
    receiveContactCreateFormView: (state) => ({ ...state, loading: false, pageLoading: false, contactFormLoading: false }),
    receiveContactEditFormView: receiveUsersData,
    receiveMembersView: receiveUsersData,
    receiveMyUsersRaw: receiveMyUsersRawData,
    receiveMyUsers: receiveMyUsersData,
    receiveUsers: receiveUsersData,
    receiveCurrentUser: receiveUsersData,
    receiveUser: receiveUserData,
    receiveCreateUser: receiveCreateUserData,
    receiveUpdateUser: receiveUpdateUserData,
    receiveSelectedUser: receiveSelectedUserData,
    removeContactList: removeContactListData,
    receiveContactUsers: receiveContactUsersData,
    receiveMembersList: receiveMembersData,
    receiveMembersFiltersView: receiveMembersFiltersData,
    receiveUsersStatus: (state, action) => ({
      ...state,
      users: mergeShallow(state.users, action.payload),
    }),
    receiveUsersSearch: (state, action) =>
      ({
        ...state,
        users: mergeShallow(state.users, action.payload.users),
        userSearchIds: action.payload.page === 0 ? [...action.payload.userIds] : [...new Set([...state.userSearchIds, ...action.payload.userIds])],
        userSearchLoading: false,
        userSearchSource: action.payload.userSearchSource,
        showGlobalSearchModalWrapper: true,
        page: action.payload.page,
      }),
    receiveMembersSearch: (state, action) =>
      ({
        ...state,
        users: mergeShallow(state.users, action.payload.users),
        memberSearchIds: [...action.payload.userIds],
        memberSearchLoading: false,
      }),
    receiveMembers: (state, action) =>
      ({
        ...state,
        users: mergeShallow(state.users, action.payload.users),
        memberSearchIds: [...new Set([...state.memberSearchIds, ...action.payload.userIds])],
        loading: false,
        pageNo: action.payload.pageNo !== undefined ? action.payload.pageNo : state.pageNo,
      }),
    receiveUsersPhonesSearch: (state, action) =>
      ({
        ...state,
        users: mergeShallow(state.users, action.payload.users),
        phoneUserSearchIds: [...action.payload.userIds],
        phoneSearchLoading: false,
      }),
    receiveUsersMentionSearch: (state, action) =>
      ({
        ...state,
        users: mergeShallow(state.users, action.payload.users),
        mentionUserIds: [...action.payload.userIds],
        phoneSearchLoading: false,
      }),
    receiveUsersModalPhonesSearch: (state, action) =>
      ({
        ...state,
        users: mergeShallow(state.users, action.payload.users),
        modalPhoneSearchIds: [...action.payload.userIds],
        modalPhoneSearchLoading: false,
      }),
    receiveConnectedPartySearch: (state, action) =>
      ({
        ...state,
        users: mergeShallow(state.users, action.payload.users),
        connectedPartySearchIds: action.payload.page === 0 ? [...action.payload.userIds] : [...new Set([...state.connectedPartySearchIds, ...action.payload.userIds])],
        connectedPartySearchLoading: false,
        page: action.payload.page,
      }),
    requestData: (state) =>
      ({
        ...state,
        loading: true, // tracks loading of users list
      }),
    requestMembers: (state) =>
      ({
        ...state,
        membersLoading: true, // tracks loading of users list
      }),
    receiveData: (state) =>
      ({
        ...state,
        loading: false, // tracks loading of users list
      }),
    requestPageData: (state) =>
      ({
        ...state,
        pageLoading: true, // tracks loading of full page
      }),
    requestContactListData: (state) =>
      ({
        ...state,
        contactListLoading: true, // tracks loading of contact list
      }),
    requestMembersSearchData: (state) =>
      ({
        ...state,
        memberSearchLoading: true,
      }),
    requestConnectedPartySearchData: (state) =>
      ({
        ...state,
        connectedPartySearchLoading: true,
      }),
    requestSearchData: (state, action) =>
      ({
        ...state,
        userSearchLoading: true,
        userSearchSource: action.payload,
      }),
    requestContactFormData: (state) =>
      ({
        ...state,
        contactFormLoading: true, // tracks loading of full page
      }),
    setUserSearchError: (state) =>
      ({
        ...state,
        userSearchLoading: false,
      }),
    requestPhoneSearchData: (state) =>
      ({
        ...state,
        phoneSearchLoading: true,
      }),
    requestModalPhoneSearchData: (state) =>
      ({
        ...state,
        modalPhoneSearchLoading: true,
      }),
    setAppointmentLoading: (state, action) => ({ ...state, appointmentLoading: action.payload }),
    setTypeFilter: (state, action) =>
      ({
        ...state,
        typeFilter: action.payload,
      }),
    setTagFilter: (state, action) =>
      ({
        ...state,
        tagFilter: action.payload,
      }),
    setLocationFilter: (state, action) =>
      ({
        ...state,
        locationFilter: action.payload,
      }),
    clearUser: (state) =>
      ({
        ...state,
        userIds: [],
        // userId: null,
        typeFilter: null,
        pageNo: 0,
      }),
    clearUsers: (state) =>
      ({
        ...state,
        userIds: [],
        pageNo: 0,
      }),
    clearMembers: (state) =>
      ({
        ...state,
        memberUserIds: [],
        memberPageNo: 0,
      }),
    clearUserSearchResults: (state) =>
      ({
        ...state,
        phoneUserSearchIds: [],
        modalPhoneSearchIds: [],
        phoneSearchLoading: false,
        modalPhoneSearchLoading: false,
        userSearchIds: [],
        userSearchLoading: false,
        userSearchSource: null,
        memberSearchIds: [],
        memberSearchLoading: false,
        connectedPartySearchIds: [],
        connectedPartySearchLoading: false,
        showGlobalSearchModalWrapper: false,
        page: 0,
      }),
    clearContactUsers: (state) =>
      ({
        ...state,
        contactUsersLoading: false,
        contactUserIds: [],
        page: 0,
        pageNo: 0,
        totalUserCount: 0,
      }),
    receiveError: (state) =>
      ({
        ...state,
        pageLoading: false,
        loading: false,
      }),
    setUserMentionIds: (state, action) => {
      state.mentionUserIds = action.payload;
    },
    removeUser: (state, action) => {
      const users = cloneDeep(state.users);
      const { reciprocalConnectedParties } = action.payload;
      if (reciprocalConnectedParties && reciprocalConnectedParties.length) {
        reciprocalConnectedParties.forEach((rcp) => {
          users[rcp.fromUserId].connectedParties = users[rcp.fromUserId].connectedParties.filter((id) => id !== rcp.id);
        });
      }
      return ({
        ...state,
        userIds: [...state.userIds.filter((u) => u !== action.payload.userId)],
        totalUserCount: state.totalUserCount - 1,
        users,
        loading: false,
      });
    },
    receiveContactListUsers: (state, action) =>
      ({
        ...state,
        users: action.payload.users ? mergeShallow(state.users, action.payload.users) : state.users,
        userIds: action.payload.userIds ? [...new Set([...state.userIds, ...action.payload.userIds])] : state.userIds,
        totalUserCount: action.payload.totalUserCount ? action.payload.totalUserCount : state.totalUserCount,
        loading: false,
      }),
    setActiveUser: (state, action) =>
      ({
        ...state,
        userId: action.payload,
      }),
    receiveUserForms: (state, action) =>
      ({
        ...state,
        users: action.payload.users ? mergeShallow(state.users, action.payload.users) : state.users,
      }),
    receiveContactList: receiveUsersData,
    requestContactUsersData: (state) => {
      state.contactUsersLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GroupActionTypes.receiveGroups, receiveUsersData)
      .addCase(GroupActionTypes.receiveGroupView, receiveUsersData)
      .addCase(ChatActionTypes.receiveChatThread, receiveUsersData)
      .addCase(ChatActionTypes.receiveChatThreadUserView, receiveUsersData)
      .addCase(ChatActionTypes.receiveChatThreadGroupView, receiveUsersData)
      .addCase(ChatActionTypes.receiveChatInbox, receiveUsersData)
      .addCase(ChatActionTypes.receiveCreateChatEvent, receiveUsersData)
      .addCase(ChatActionTypes.receiveWebSocketEvent, receiveUsersData)

      .addCase(InboxActionTypes.receiveInboxThreadView, receiveUsersData)
      .addCase(InboxActionTypes.receiveSecureMessageThread, receiveUsersData)
      .addCase(InboxActionTypes.receiveInboxThread, receiveUsersData)
      .addCase(InboxActionTypes.receiveInboxThreadSearch, receiveUsersData)
      .addCase(InboxActionTypes.receiveInbox, receiveUsersData)
      .addCase(InboxActionTypes.receiveCreateEvent, receiveUsersData)
      .addCase(InboxActionTypes.receiveWebSocketEvent, receiveUsersData)
      .addCase(InboxActionTypes.receiveWebSocketMention, receiveUsersData)
      .addCase(InboxActionTypes.receiveEventMentions, receiveUsersData)
      .addCase(InboxActionTypes.setInboxContext, receiveActiveUserId)
      .addCase(InboxActionTypes.requestInitialThreadView, receiveActiveUserId)
      .addCase(ChatActionTypes.setChatContext, receiveActiveUserId)

      .addCase(SecureActionTypes.receiveWebSocketEvent, receiveUsersData)
      .addCase(SecureActionTypes.receivePatientThread, receiveUsersData)
      .addCase(SecureActionTypes.receivePatientThreadChannelView, receiveUsersData)
      .addCase(SecureActionTypes.receivePatientThreadAllView, receiveUsersData)

      .addCase(OrganizationActionTypes.receiveOrganization, receiveUsersData)

      .addCase(RoleActionTypes.receiveRole, receiveUsersData)
      .addCase(RoleActionTypes.receiveCustomRoles, receiveUsersData)
      .addCase(RoleActionTypes.receiveSystemRoles, receiveUsersData)

      .addCase(AuthActionTypes.setUser, (state, action) =>
        ({
          ...state,
          users: {
            ...state.users,
            [action.payload.userId]: action.payload.user[action.payload.userId],
          },
          loading: false,
        }))
      .addCase(SavedContentActionTypes.receiveEventsForSavedContent, (state, action) =>
        ({
          ...state,
          users: action.payload.users ? mergeShallow(state.users, action.payload.users) : state.users,
          userIds: [...new Set([...state.userIds, ...action.payload.userIds])],
        }))
      .addCase(FormActionTypes.receiveContactForms, (state, action) =>
        ({
          ...state,
          users: action.payload.users ? mergeShallow(state.users, action.payload.users) : state.users,
          userIds: [...new Set([...state.userIds, ...action.payload.userIds])],
        }))
      .addCase(fetchUserById.fulfilled, (state, action) => {
        const normalized = normalize(action.payload, user);
        return receiveUserData(state, { payload: getUserPayload(normalized) });
      })
      .addCase(fetchUserById.pending, (state, action) => {
        const userId = action.meta.arg;
        return receiveUserData(state, { payload: { userId, user: { [userId]: { status: 'pending', id: userId } } } });
      });
  },
});

export default userSlice.reducer;

// ACTIONS

export const {
  receiveMyUsersRaw,
  receiveMyUsers,
  receiveUsers,
  receiveContactUsers,
  receiveContactListUsers,
  receiveUser,
  receiveSelectedUser,
  receiveUsersSearch,
  receiveUsersStatus,
  receiveUsersPhonesSearch,
  receiveUsersModalPhonesSearch,
  receiveMembersSearch,
  receiveMembers,
  requestMembersSearchData,
  receiveConnectedPartySearch,
  requestConnectedPartySearchData,
  requestData,
  requestPageData,
  requestSearchData,
  requestPhoneSearchData,
  requestModalPhoneSearchData,
  setActiveUser,
  setTypeFilter,
  setTagFilter,
  setAppointmentLoading,
  setLocationFilter,
  receiveCreateUser,
  receiveUpdateUser,
  clearUser,
  clearUsers,
  clearUserSearchResults,
  setUserSearchError,
  removeUser,
  receiveMembersView,
  receiveContactCreateFormView,
  receiveContactEditFormView,
  receiveContactList,
  removeContactList,
  requestContactListData,
  receiveCurrentUser,
  receiveUserForms,
  requestContactFormData,
  requestContactUsersData,
  clearContactUsers,
  requestContactPageDate,
  receiveMembersList,
  clearMembers,
  receiveError,
  receiveUsersMentionSearch,
  setUserMentionIds,
  receiveData,
  requestMembers,
  receiveMembersFiltersView,
} = userSlice.actions;

// REDUCER HELPERS

function receiveMyUsersRawData(state, action) {
  return {
    ...state,
    myUsersRaw: action.payload,
  };
}
function receiveMyUsersData(state, action) {
  return {
    ...state,
    myUsers: {
      ...state.myUsers,
      ...action.payload.myUsers,
    },
    myUserIds: action.payload.myUserIds,
  };
}

function receiveUsersData(state, action) {
  const userIds = action.payload.userIds || [];
  return {
    ...state,
    users: action.payload.users ? mergeShallow(state.users, action.payload.users) : state.users, // merging due to partial/full user object scenario
    userIds: action.payload.pageLoaded ? userIds : [...new Set([...state.userIds, ...userIds])],
    totalUserCount: exists(action.payload.totalUserCount) ? action.payload.totalUserCount : state.totalUserCount,
    totalMemberCount: exists(action.payload.totalMemberCount) ? action.payload.totalMemberCount : state.totalMemberCount,
    totalActiveMemberCount: action.payload.totalActiveMemberCount || state.totalActiveMemberCount,
    contactList: action.payload.contactList !== undefined ? [...action.payload.contactList] : state.contactList,
    loading: false,
    pageNo: action.payload.pageNo !== undefined ? action.payload.pageNo : state.pageNo,
    contactListLoading: false,
    ...action.payload.contactForm && {
      contactFormLoading: false,
    },
    ...action.payload.pageLoaded && {
      pageLoading: false,
    },
  };
}

function receiveMembersFiltersData(state) {
  return {
    ...state,
    loading: false,
    membersLoading: false,
  };
}

function receiveMembersData(state, action) {
  const userIds = action.payload.userIds || [];
  return {
    ...state,
    users: action.payload.users ? mergeShallow(state.users, action.payload.users) : state.users, // merging due to partial/full user object scenario
    memberUserIds: action.payload.pageLoaded ? userIds : [...new Set([...state.memberUserIds, ...userIds])],
    totalMemberCount: exists(action.payload.totalUserCount) ? action.payload.totalUserCount : state.totalMemberCount,
    totalActiveMemberCount: action.payload.totalActiveMemberCount || state.totalActiveMemberCount,
    contactList: action.payload.contactList !== undefined ? [...action.payload.contactList] : state.contactList,
    loading: false,
    memberPageNo: action.payload.pageNo !== undefined ? action.payload.pageNo : state.memberPageNo,
    memberPageLoading: false,
  };
}

function receiveContactUsersData(state, action) {
  return {
    ...state,
    users: mergeShallow(state.users, action.payload.users),
    contactUsersLoading: false,
    contactPage: action.payload.page,
    contactUserIds: [...action.payload.userIds],
    totalUserCount: action.payload.totalUserCount,
    pageNo: action.payload.pageNo,
    pageLoading: false,
  };
}

function receiveSelectedUserData(state, action) {
  return {
    ...state,
    users: {
      ...state.users,
      [action.payload.userId]: action.payload.user[action.payload.userId],
    },
    loading: false,
  };
}

function receiveActiveUserId(state, action) {
  return {
    ...state,
    userId: action.payload.userId,
  };
}

function receiveUserData(state, action) {
  const userDataInPayload = action.payload.user[action.payload.userId];
  const userDataInStore = state.users[action.payload.userId];
  const updatedUserData = userDataInStore ? {
    ...userDataInStore,
    ...userDataInPayload,
  } : userDataInPayload;
  return {
    ...state,
    users: {
      ...state.users,
      [action.payload.userId]: updatedUserData,
    },
    userIds: [...new Set([action.payload.userId, ...state.userIds])],
    loading: false,
    ...action.payload.pageLoad && {
      pageLoading: false,
    },
  };
}

function receiveUpdateUserData(state, action) {
  return {
    ...state,
    users: {
      ...state.users,
      [action.payload.userId]: action.payload.user[action.payload.userId],
    },
    totalActiveMemberCount: (exists(action.payload.active) && action.payload.active) ? state.totalActiveMemberCount + 1 : state.totalActiveMemberCount,
    loading: false,
  };
}

function receiveCreateUserData(state, action) {
  return {
    ...state,
    users: {
      ...state.users,
      [action.payload.userId]: action.payload.user[action.payload.userId],
    },
    userIds: [...new Set([action.payload.userId, ...state.userIds])],
    loading: false,
  };
}

function removeContactListData(state, action) {
  return {
    ...state,
    contactList: [...state.contactList.filter((contactList) => contactList.id !== action.payload.contactListId)],
    loading: false,
  };
}

// THUNKS -- ASYNC ACTION CREATORS

let cancelUserSearch;
export const clearUserSearch = () => (dispatch) => {
  if (cancelUserSearch) cancelUserSearch();
  dispatch(clearUserSearchResults());
};

let cancelContactSearch;
export const clearContactSearch = () => (dispatch) => {
  if (cancelContactSearch) cancelContactSearch();
  dispatch(clearContactUsers());
};

export function updateContactList(contactListId, payload) {
  return (dispatch, getState) => {
    dispatch(setError(null));
    return axios.put(`/contactList/${contactListId}`, payload)
      .then((response) => {
        const contactLists = getState().user.contactList;
        const updatedContactList = contactLists.map((list) => {
          const contactList = cloneDeep(list);
          if (contactList.id === response.data.id) {
            contactList.users = response.data.users;
          }
          return contactList;
        });
        const totalUsers = updatedContactList.reduce((acc, list) => [...acc, ...list.users], []);
        const normalizedUser = normalize(totalUsers, [user]);
        const totalUserCount = totalUsers.length;

        dispatch(receiveContactList(getContactListViewPayload(normalizedUser, null, totalUserCount, updatedContactList)));
        NotificationService('updateContactList', response);
        return response.data;
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
}

export function getUsers(userType) {
  return axios.get(`/users?pageNo=0&pageSize=${CONTACT_RESULT_SIZE}&userTypeId=${userType}&sort=ascending&active=true`)
    .catch((err) => console.error(err.response || err));
}

export function createContactList(payload) {
  return (dispatch, getState) => {
    dispatch(setError(null));
    return axios.post('/contactList', payload)
      .then((response) => {
        const { contactList } = getState().user;
        const updatedContactList = [...contactList, response.data];
        const totalUsers = updatedContactList.reduce((acc, list) => [...acc, ...list.users], []);
        const normalizedUser = normalize(totalUsers, [user]);
        const totalUserCount = totalUsers.length;

        dispatch(receiveContactList(getContactListViewPayload(normalizedUser, null, totalUserCount, updatedContactList)));
        NotificationService('createContactList', response);
        return response.data;
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
}

export function fetchContactCreateFormView() {
  return (dispatch) => {
    dispatch(requestContactFormData());

    return axios.all([TagReducer.getTags()])
      .then(axios.spread((tagsResponse) => {
        const normalizedTags = normalize(tagsResponse.data, [tag]);

        dispatch(receiveContactCreateFormView(getContactCreateFormView(normalizedTags)));
      }))
      .catch((err) => dispatch(handleError(err)));
  };
}

export function fetchContactEditFormView(contactId, typeId) {
  return (dispatch) => {
    dispatch(requestContactFormData());

    return axios.all([TagReducer.getTags(), getUserById(contactId), createLock({ typeId, recordId: contactId })])
      .then(axios.spread((tagsResponse, userResponse, lockResponse) => {
        const normalizedTags = normalize(tagsResponse.data, [tag]);
        const normalizedUser = normalize(userResponse.data, user);
        const recordLock = lockResponse.data;

        dispatch(receiveContactEditFormView(getContactEditFormView(normalizedTags, normalizedUser, recordLock)));
      }))
      .catch((err) => dispatch(handleError(err)));
  };
}

export function getUserById(userId) {
  return axios.get(`/users/${userId}`)
    .catch(console.log); // eslint-disable-line no-console
}

export function fetchMemberProfileFormView(userId) {
  return (dispatch) => {
    dispatch(requestData());
    return axios.all([GroupReducer.getGroups(), TagReducer.getTags(), ChannelReducer.getChannels(), getProfileMember(userId), getRoles()])
      .then(axios.spread((groupsResponse, tagsResponse, channelsResponse, userResponse, rolesResponse) => {
        const normalizedGroup = normalize(groupsResponse.data.results, [group]);
        const normalizedTags = normalize(tagsResponse.data, [tag]);
        const normalizedChannels = normalize(channelsResponse.data, [channel]);
        const normalizedUsers = normalize([userResponse.data], [user]);

        const { systemRoles, customRoles } = RoleHelpers.groupRolesByType(rolesResponse.data);
        const normalizedRoles = {
          normalizedSystemRoles: normalize(systemRoles, [role]),
          normalizedCustomRoles: normalize(customRoles, [role]),
        };
        dispatch(receiveMembersView(getMembersView(normalizedGroup, normalizedTags, normalizedChannels, normalizedUsers, normalizedRoles)));
      }))
      .catch((err) => dispatch(handleError(err)));
  };
}

export function fetchNewMemberProfileFormView() {
  return (dispatch) => {
    dispatch(requestData());
    return axios.all([GroupReducer.getGroups(), TagReducer.getTags(), ChannelReducer.getChannels(), getRoles(), getUsers(Types.TYPE_MEMBER)])
      .then(axios.spread((groupsResponse, tagsResponse, channelsResponse, rolesResponse, userResponse) => {
        const normalizedGroup = normalize(groupsResponse.data.results, [group]);
        const normalizedTags = normalize(tagsResponse.data, [tag]);
        const normalizedChannels = normalize(channelsResponse.data, [channel]);
        const normalizedUser = normalize(userResponse.data.results, [user]);
        const totalUserCount = userResponse.data.count;

        const { systemRoles, customRoles } = RoleHelpers.groupRolesByType(rolesResponse.data);
        const normalizedRoles = {
          normalizedSystemRoles: normalize(systemRoles, [role]),
          normalizedCustomRoles: normalize(customRoles, [role]),
        };
        dispatch(receiveMembersView(getNewMembersView(normalizedGroup, normalizedTags, normalizedChannels, normalizedRoles, normalizedUser, totalUserCount)));
      }))
      .catch((err) => dispatch(handleError(err)));
  };
}

export function fetchMembersView() {
  return (dispatch) => {
    dispatch(requestMembers());
    return axios.all([GroupReducer.getGroups(), TagReducer.getTags(), getRoles()])
      .then(axios.spread((groupsResponse, tagsResponse, rolesResponse) => {
        const normalizedGroup = normalize(groupsResponse.data.results, [group]);
        const normalizedTags = normalize(tagsResponse.data, [tag]);

        const { systemRoles, customRoles } = RoleHelpers.groupRolesByType(rolesResponse.data);
        const normalizedRoles = {
          normalizedSystemRoles: normalize(systemRoles, [role]),
          normalizedCustomRoles: normalize(customRoles, [role]),
        };
        dispatch(receiveMembersFiltersView(getMembersFiltersView(normalizedGroup, normalizedTags, normalizedRoles)));
      }))
      .catch((err) => dispatch(handleError(err)));
  };
}

function getProfileMember(id) {
  if (!id) throw new Error('Missing "id" parameter in "getProfileMember".');
  return axios.get(`/users/${id}`).catch((err) => console.error(err));
}

export function createUser(payload, formInProgress = true, isModal) {
  return (dispatch) => {
    if (isModal) {
      dispatch(setModalFormInProgress(formInProgress));
    } else {
      dispatch(setFormInProgress(formInProgress));
    }

    return axios.post('/users', payload)
      .then((response) => {
        const normalized = normalize(response.data, user);

        if (UserHelpers.isMember(response.data)) {
          NotificationService('createMember', response);
          dispatch(searchService.util.invalidateTags(['search']));
        } else {
          NotificationService('createContact', response);
        }
        dispatch(setError(null));
        return dispatch(receiveCreateUser(getUserPayload(normalized)));
      })
      .catch((err) => {
        const { property = null } = (err.response && err.response.data) ? err.response.data : {};
        if (!exists(property)) {
          NotificationService('createUser', err.response);
        }
        dispatch(setError(err.response || err));
      });
  };
}

export function shapeUsersPresence(message) {
  return (dispatch) => {
    dispatch(receiveUsersStatus({
      [message.userId]: {
        onlineStatus: message.status,
      },
    }));
  };
}

export function fetchUsers(pageNo = 0, userType, sort = 'ascending', pageSize = CONTACT_RESULT_SIZE, active = true) {
  const url = userType ?
    `/users?pageNo=${pageNo}&pageSize=${pageSize}&sort=${sort}&active=${active}&userTypeId=${userType}` :
    `/users?pageNo=${pageNo}&pageSize=${pageSize}&sort=${sort}&active=${active}`;

  return (dispatch) => {
    if (cancelContactSearch) cancelContactSearch();
    dispatch(requestData());
    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data.results, [user]);
        const totalUserCount = response.data.count;
        const payload = {
          ...getUsersPayload(normalized, pageNo || 0, totalUserCount),
          pageLoaded: true,
        };

        dispatch(receiveUsers(payload));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function fetchMembers(pageNo = 0, active = true) {
  const url = `/users?pageNo=${pageNo}&pageSize=${CONTACT_RESULT_SIZE}&sort=ascending&active=${active}&userTypeId=${Types.TYPE_MEMBER}`;
  return (dispatch) => {
    if (pageNo === 0) {
      dispatch(requestData());
      dispatch(clearMembers());
    }

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data.results, [user]);
        const totalUserCount = response.data.count;
        const totalActiveMemberCount = active ? response.data.count : undefined;
        if (normalized.result) {
          const page = pageNo;

          dispatch(receiveMembersList(getUsersPayload(normalized, page || 0, totalUserCount, totalActiveMemberCount)));
        }
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function fetchUser(userId, extendSession) {
  return (dispatch) => {
    dispatch(requestData());
    return axios.get(`/users/${userId}`)
      .then((response) => {
        const normalized = normalize(response.data, user);

        dispatch(receiveUser(getUserPayload(normalized)));

        dispatch(setError(null));
        if (extendSession) {
          dispatch(hideSessionTimeoutModal());
        }
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

function getMembersSearch(payload = {}) {
  const { search = '', scope, page = 0, searchSize = SEARCH_SIZE, excludeCurrentUser = 0, active = true } = payload;
  const url = SearchHelpers.getSearchUrl({ search, scope, excludeCurrentUser, page, size: searchSize, type: 'name', includeInactiveAndDeleted: !active });
  return axios.get(url, {
    ...!axios?.defaults?.headers?.common?.Authorization && {
      headers: { Authorization: 'legacy' }, // both values needed for ApiGateway
    },
  });
}

export function fetchMemberSearch(payload) {
  return (dispatch) => {
    dispatch(requestMembersSearchData());
    getMembersSearch(payload).then(({ data }) => {
      const normalized = normalize(data.users, [user]);
      dispatch(receiveMembersSearch(getUsersPayload(normalized)));
    })
      .catch((err) => dispatch(handleError(err)));
  };
}

export function fetchUserMentionSearch(payload) {
  return (dispatch) => {
    getMembersSearch(payload).then(({ data }) => {
      const normalized = normalize(data.users, [user]);
      dispatch(receiveUsersMentionSearch(getUsersPayload(normalized)));
    })
      .catch((err) => dispatch(handleError(err)));
  };
}

export function fetchActiveMembers(pageNo = 0) {
  return (dispatch) => {
    dispatch(requestData());
    const url = `/users?pageNo=${pageNo}&pageSize=${CONTACT_RESULT_SIZE}&sort=ascending&userTypeId=${Types.TYPE_MEMBER}&active=true`;
    return axios.get(url)
      .then(({ data }) => {
        const normalized = normalize(data.results, [user]);
        if (normalized.result) {
          let page = pageNo;
          if (normalized.result.length === 0 || normalized.result.length < CONTACT_RESULT_SIZE) {
            page = pageNo - 1;
          }
          dispatch(receiveMembers(getUsersPayload(normalized, page || 0)));
        }
      })
      .catch((err) => dispatch(handleError(err)));
  };
}

export function fetchConnectedPartySearch(search, scope, excludeCurrentUser = 0, userId, selectedUserIds, page = 0, searchSize = SEARCH_SIZE) {
  const idsToExclude = [];
  // (in the context of adding connected parties) userId represents the user being edited, while selectedUserIds represent the existing connected parties on the coverUser
  if (userId) {
    idsToExclude.push(userId);
    // the if statement below is nested because, if no userId exists, then by Rhinogram law neither will selectedUserIds
    if (selectedUserIds) {
      idsToExclude.push(...selectedUserIds);
    }
  }

  const url = SearchHelpers.getSearchUrl({ search, scope, excludeCurrentUser, idsToExclude, page, size: searchSize, type: 'name' });

  return (dispatch) => {
    dispatch(requestConnectedPartySearchData());
    return axios.get(url, {
      ...!axios?.defaults?.headers?.common?.Authorization && {
        headers: { Authorization: 'legacy' }, // both values needed for ApiGateway
      },
    })
      .then((response) => {
        const normalized = normalize(response.data.users, [user]);

        dispatch(receiveConnectedPartySearch(getUsersPayload(normalized, null, response.data.totalUserCount, null, null, page)));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function getContacts(payload) {
  const url = SearchHelpers.getSearchUrl(payload);
  return axios.get(url, {
    ...!axios?.defaults?.headers?.common?.Authorization && {
      headers: { Authorization: 'legacy' }, // both values needed for ApiGateway
    },
  });
}

export function getMemberSearch(payload) {
  const url = SearchHelpers.getSearchUrl(payload);
  return axios.get(url, {
    ...!axios?.defaults?.headers?.common?.Authorization && {
      headers: { Authorization: 'legacy' }, // both values needed for ApiGateway
    },
  });
}

export function fetchContactSearch(payload, pageNo = 0) {
  return (dispatch) => {
    if (cancelContactSearch) cancelContactSearch();
    dispatch(requestContactUsersData());
    const { source, page = 0 } = payload;
    const url = SearchHelpers.getSearchUrl(payload);
    return axios.get(url, {
      ...!axios?.defaults?.headers?.common?.Authorization && {
        headers: { Authorization: 'legacy' }, // both values needed for ApiGateway
      },
      cancelToken: new axios.CancelToken(((cancelFunction) => {
        cancelContactSearch = cancelFunction;
      })),
    })
      .then((response) => {
        const normalized = normalize(response.data.users, [user]);
        dispatch(receiveContactUsers(getUsersPayload(normalized, pageNo, response.data.totalCount, null, source, page)));
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          cancelContactSearch = null;
          console.error(err.response || err);
          dispatch(setError(err.response || err));
          dispatch(setUserSearchError());
        }
      });
  };
}

export function fetchUserSearch(payload) {
  const { searchValue, scope, type, source, idsToExclude, page = 0, size = SEARCH_SIZE } = payload;
  const url = SearchHelpers.getSearchUrl({ search: searchValue, scope, type, idsToExclude, page, size });

  return (dispatch) => {
    if (cancelUserSearch) cancelUserSearch();
    dispatch(requestSearchData(source));

    return axios.get(url, {
      ...!axios?.defaults?.headers?.common?.Authorization && {
        headers: { Authorization: 'legacy' }, // both values needed for ApiGateway
      },
      cancelToken: new axios.CancelToken(((cancelFunction) => {
        cancelUserSearch = cancelFunction;
      })),
    })
      .then((response) => {
        const normalized = normalize(response.data.users, [user]);
        dispatch(receiveUsersSearch(getUsersPayload(normalized, null, null, null, source, page)));
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          cancelUserSearch = null;
          console.error(err.response || err);
          dispatch(setError(err.response || err));
          dispatch(setUserSearchError());
        }
      });
  };
}

export function fetchPhoneSearch(search, scope, type, isModal, page = 0, size = SEARCH_SIZE) {
  const url = SearchHelpers.getSearchUrl({ search, scope, type, page, size });
  return (dispatch) => {
    if (isModal) {
      dispatch(requestModalPhoneSearchData());
    } else {
      dispatch(requestPhoneSearchData());
    }
    return axios.get(url, {
      ...!axios?.defaults?.headers?.common?.Authorization && {
        headers: { Authorization: 'legacy' }, // both values needed for ApiGateway
      },
    })
      .then((response) => {
        const normalized = normalize(response.data.users, [user]);
        if (isModal) {
          dispatch(receiveUsersModalPhonesSearch(getUsersPayload(normalized)));
        } else {
          dispatch(receiveUsersPhonesSearch(getUsersPayload(normalized)));
        }
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function mergeUsers(slaveId, masterId) {
  return (dispatch) => {
    dispatch(requestData());
    return axios.get(`/users/mergeUsers/${slaveId}/${masterId}`)
      .then((response) => {
        const normalized = normalize(response.data, user);

        dispatch(receiveUser(getUserPayload(normalized)));

        dispatch(removeUser({ userId: slaveId }));

        NotificationService('mergeUser', response);

        dispatch(setError(null));
        // update cached data
        dispatch(threadService.util.invalidateTags(['Thread', 'UserForms']));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        NotificationService('mergeUser', err.response);
      });
  };
}

export function deleteUser(userId) {
  return (dispatch) =>
    axios.delete(`/users/${userId}`)
      .then((response) => {
        const { reciprocalConnectedParties } = response.data;

        dispatch(removeUser({ userId, reciprocalConnectedParties }));

        const statusCode = {
          status: response.status,
        };

        NotificationService('deleteContact', statusCode);

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        NotificationService('deleteContact', err.response);
      });
}

export function sendInviteToUser(userId, payload) {
  return async (dispatch) => {
    try {
      const response = await axios.patch(`/users/${userId}/sendInvite`, payload);
      const normalized = normalize(response.data, user);
      const normalizedUser = getUserPayload(normalized);
      const statusCode = {
        status: response.status,
      };
      dispatch(receiveUpdateUser({ ...normalizedUser }));
      NotificationService('sendInvite', statusCode);
      dispatch(setError(null));
    } catch (err) {
      dispatch(setError(err.response || err));
      NotificationService('sendInvite', err.response);
    }
  };
}

export function updateUser(userId, payload, showNotification = true, currentUserId, userHadThreadView) {
  const isPatientExperience = StorageService.readEntry('patientExperience');
  const orgId = StorageService.readEntry('org');
  return (dispatch) => {
    dispatch(toggleEmailModal(false));
    dispatch(setFormInProgress(true));

    let url = `/users/${userId}`;

    if (isPatientExperience) {
      url = `${url}?patientUserId=${userId}&patientOrgId=${orgId}`;
    }

    return axios.patch(url, payload)
      .then((response) => {
        const normalized = normalize(response.data, user);
        const normalizedUser = getUserPayload(normalized);
        const { active } = payload;

        if (currentUserHasNewThreadViewPermission(currentUserId, userId, userHadThreadView, response)) {
          dispatch(fetchInboxSections())
            .then(() => {
              dispatch(receiveUpdateUser({ ...normalizedUser, active }));
            });
        } else {
          dispatch(receiveUpdateUser({ ...normalizedUser, active }));
        }

        if (showNotification) {
          if (UserHelpers.isMember(response.data)) {
            NotificationService('updateMember', response);
            dispatch(searchService.util.invalidateTags(['search']));
          } else {
            NotificationService('updateContact', response);
          }
        }
        dispatch(setError(null));
        return undefined;
      })
      .catch((err) => {
        const { property = null } = (err.response && err.response.data) ? err.response.data : {};
        if (!exists(property)) {
          NotificationService('updateUser', err.response);
        }
        dispatch(setError(err.response || err));
      });
  };
}

export function updateUserEmail(userId, payload) {
  return (dispatch) => {
    const url = `/users/${userId}`;

    return axios.patch(url, payload)
      .then(() => {
        dispatch(setError(null));
      })
      .catch((err) => {
        dispatch(setError(err.response || err));
      });
  };
}

export function updateUserAppointment(userId, payload) {
  return (dispatch) => {
    dispatch(setAppointmentLoading(true));

    return axios.patch(`/users/${userId}/appointment`, payload)
      .then((response) => {
        const { appointments } = response.data;
        const normalized = normalize(response.data, user);
        dispatch(receiveUpdateUser(getUserPayload(normalized)));
        dispatch(setError(null));
        NotificationService('updateAppointment', response);
        return appointments;
      })
      .catch((err) => {
        const { property = null } = (err.response && err.response.data) ? err.response.data : {};
        if (!exists(property)) {
          NotificationService('updateAppointment', err.response);
        }
        dispatch(setError(err.response || err));
        dispatch(setAppointmentLoading(false));
      });
  };
}

export function updateUserPassword(payload) {
  return (dispatch) =>
    axios.patch('/users/changePassword', payload)
      .then((response) => {
        const normalized = normalize(response.data, user);
        dispatch(receiveUpdateUser(getUserPayload(normalized)));
        NotificationService('updateUserPassword', response);
        notifyNative(response.data.loginEmail, payload.newPass);
        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
}

function notifyNative(email, password) {
  OutboundPostMessageService.postMessage({
    type: 'passwordUpdated',
    data: { email, password },
  });
}

export function updateUserPreferences(userId, payload) {
  return (dispatch) =>
    axios.put(`/users/${userId}/preferences`, payload)
      .then((response) => {
        const normalized = normalize(response.data, user);

        dispatch(receiveUpdateUser(getUserPayload(normalized)));

        NotificationService('updateUserPreferences', response);

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);

        NotificationService('updateUserPreferences', err.response);

        dispatch(setError(err.response || err));
      });
}

export function updateBulkUserTags(payload) {
  return (dispatch) => {
    dispatch(requestData());
    return axios.post('/bulkactions/user-tags', payload)
      .then((response) => {
        dispatch(receiveData());
        NotificationService('updateUserTags', response, response.data.length);
      })
      .catch((err) => {
        console.error(err.response || err);
        dispatch(setError(err.response || err));
        dispatch(receiveError());
        NotificationService('updateUserTags', err.response);
      });
  };
}

/*
 * Helper Methods
 */
function handleError(err) {
  return (dispatch) => {
    const error = err.response || err;

    console.error(error);

    dispatch(setError(error));
  };
}

function getContactEditFormView(normalizedTags, normalizedUser, recordLock) {
  return {
    tags: {
      ...normalizedTags.entities.tags,
    },
    tagIds: normalizedTags.result,
    connectedParties: {
      ...normalizedUser.entities.connectedParties,
    },
    connectedPartyIds: getObjectKeys(normalizedUser.entities.connectedParties),
    emails: {
      ...normalizedUser.entities.emails,
    },
    emailIds: getObjectKeys(normalizedUser.entities.emails),
    rhinograms: {
      ...normalizedUser.entities.rhinograms,
    },
    rhinogramIds: getObjectKeys(normalizedUser.entities.rhinograms),
    phones: {
      ...normalizedUser.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedUser.entities.phones),
    users: {
      ...normalizedUser.entities.users,
    },
    userIds: [normalizedUser.result].flat(),
    recordLock,
    contactForm: true,
  };
}

function getContactCreateFormView(normalizedTags) {
  return {
    tags: {
      ...normalizedTags.entities.tags,
    },
    tagIds: getObjectKeys(normalizedTags.entities.tags),
  };
}

function getMembersFiltersView(normalizedGroups, normalizedTags, normalizedRoles) {
  return {
    groups: {
      ...normalizedGroups.entities.groups,
    },
    groupIds: getObjectKeys(normalizedGroups.entities.groups),
    tags: {
      ...normalizedTags.entities.tags,
    },
    tagIds: getObjectKeys(normalizedTags.entities.tags),
    systemRoles: {
      ...normalizedRoles.normalizedSystemRoles.entities.roles,
    },
    customRoles: {
      ...normalizedRoles.normalizedCustomRoles.entities.roles,
    },
    roleIds: getObjectKeys({ ...normalizedRoles.normalizedCustomRoles.entities.roles, ...normalizedRoles.normalizedSystemRoles.entities.roles }),
    membersLoaded: true,
  };
}

function getMembersView(normalizedGroups, normalizedTags, normalizedChannels, normalizedUsers, normalizedRoles) {
  return {
    users: {
      ...normalizedUsers.entities.users,
    },
    userIds: normalizedUsers.result,
    phones: {
      ...normalizedChannels.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedChannels.entities.phones),
    groups: {
      ...normalizedGroups.entities.groups,
    },
    groupIds: getObjectKeys(normalizedGroups.entities.groups),
    channels: {
      ...normalizedChannels.entities.channels,
    },
    channelIds: getObjectKeys(normalizedChannels.entities.channels),
    tags: {
      ...normalizedTags.entities.tags,
    },
    tagIds: getObjectKeys(normalizedTags.entities.tags),
    organizations: {
      ...normalizedUsers.entities.organizations,
    },
    organizationIds: getObjectKeys(normalizedUsers.entities.organizations),
    systemRoles: {
      ...normalizedRoles.normalizedSystemRoles.entities.roles,
    },
    customRoles: {
      ...normalizedRoles.normalizedCustomRoles.entities.roles,
    },
    roleIds: getObjectKeys({ ...normalizedRoles.normalizedCustomRoles.entities.roles, ...normalizedRoles.normalizedSystemRoles.entities.roles }),
  };
}

function getNewMembersView(normalizedGroups, normalizedTags, normalizedChannels, normalizedRoles, normalizedUser, totalUserCount) {
  return {
    users: {
      ...normalizedUser.entities.users,
    },
    userIds: normalizedUser.result,
    totalUserCount,
    connectedPartyIds: getObjectKeys(normalizedUser.entities.connectedParties),
    emails: {
      ...normalizedUser.entities.emails,
    },
    emailIds: getObjectKeys(normalizedUser.entities.emails),
    facebooks: {
      ...normalizedUser.entities.facebooks,
    },
    facebookIds: getObjectKeys(normalizedUser.entities.facebooks),
    instagrams: {
      ...normalizedUser.entities.instagrams,
    },
    instagramIds: getObjectKeys(normalizedUser.entities.instagrams),
    rhinograms: {
      ...normalizedUser.entities.rhinograms,
    },
    rhinogramIds: getObjectKeys(normalizedUser.entities.rhinograms),
    organizations: {
      ...normalizedUser.entities.organizations,
    },
    organizationIds: getObjectKeys(normalizedUser.entities.organizations),
    phones: {
      ...normalizedChannels.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedChannels.entities.phones),
    groups: {
      ...normalizedGroups.entities.groups,
    },
    groupIds: getObjectKeys(normalizedGroups.entities.groups),
    channels: {
      ...normalizedChannels.entities.channels,
    },
    channelIds: getObjectKeys(normalizedChannels.entities.channels),
    tags: {
      ...normalizedTags.entities.tags,
    },
    tagIds: getObjectKeys(normalizedTags.entities.tags),
    systemRoles: {
      ...normalizedRoles.normalizedSystemRoles.entities.roles,
    },
    customRoles: {
      ...normalizedRoles.normalizedCustomRoles.entities.roles,
    },
    roleIds: getObjectKeys({ ...normalizedRoles.normalizedCustomRoles.entities.roles, ...normalizedRoles.normalizedSystemRoles.entities.roles }),
  };
}

export function getUser(userId, orgId = null, isPatientExperience = false) {
  if (isPatientExperience) {
    return axios.get(`/users/${userId}?patientUserId=${userId}&patientOrgId=${orgId}`);
  }

  return axios.get(`/users/${userId}`);
}

export function getMyUsersPayload(normalizedUser) {
  return {
    myUsers: {
      ...normalizedUser.entities.users,
    },
    myUserIds: normalizedUser.result,
    connectedParties: {
      ...normalizedUser.entities.connectedParties,
    },
    connectedPartyIds: getObjectKeys(normalizedUser.entities.connectedParties),
    emails: {
      ...normalizedUser.entities.emails,
    },
    emailIds: getObjectKeys(normalizedUser.entities.emails),
    phones: {
      ...normalizedUser.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedUser.entities.phones),
    facebooks: {
      ...normalizedUser.entities.facebooks,
    },
    facebookIds: getObjectKeys(normalizedUser.entities.facebooks),
    instagrams: {
      ...normalizedUser.entities.instagrams,
    },
    instagramIds: getObjectKeys(normalizedUser.entities.instagrams),
    rhinograms: {
      ...normalizedUser.entities.rhinograms,
    },
    rhinogramIds: getObjectKeys(normalizedUser.entities.rhinograms),
    groups: {
      ...normalizedUser.entities.groups,
    },
    groupIds: getObjectKeys(normalizedUser.entities.groups),
    channels: {
      ...normalizedUser.entities.channels,
    },
    channelIds: getObjectKeys(normalizedUser.entities.channels),
    tags: {
      ...normalizedUser.entities.tags,
    },
    tagIds: getObjectKeys(normalizedUser.entities.tags),
    organizations: {
      ...normalizedUser.entities.organizations,
    },
    organizationIds: getObjectKeys(normalizedUser.entities.organizations),
    roles: {
      ...normalizedUser.entities.roles,
    },
    roleIds: getObjectKeys(normalizedUser.entities.roles),
  };
}

export function getUserPayload(normalizedUser) {
  return {
    user: normalizedUser.entities.users,
    userId: normalizedUser.result,
    connectedParties: {
      ...normalizedUser.entities.connectedParties,
    },
    connectedPartyIds: getObjectKeys(normalizedUser.entities.connectedParties),
    emails: {
      ...normalizedUser.entities.emails,
    },
    emailIds: getObjectKeys(normalizedUser.entities.emails),
    phones: {
      ...normalizedUser.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedUser.entities.phones),
    facebooks: {
      ...normalizedUser.entities.facebooks,
    },
    facebookIds: getObjectKeys(normalizedUser.entities.facebooks),
    instagrams: {
      ...normalizedUser.entities.instagrams,
    },
    instagramIds: getObjectKeys(normalizedUser.entities.instagrams),
    rhinograms: {
      ...normalizedUser.entities.rhinograms,
    },
    rhinogramIds: getObjectKeys(normalizedUser.entities.rhinograms),
    groups: {
      ...normalizedUser.entities.groups,
    },
    groupIds: getObjectKeys(normalizedUser.entities.groups),
    channels: {
      ...normalizedUser.entities.channels,
    },
    channelIds: getObjectKeys(normalizedUser.entities.channels),
    tags: {
      ...normalizedUser.entities.tags,
    },
    tagIds: getObjectKeys(normalizedUser.entities.tags),
    organizations: {
      ...normalizedUser.entities.organizations,
    },
    organizationIds: getObjectKeys(normalizedUser.entities.organizations),
    roles: {
      ...normalizedUser.entities.roles,
    },
    roleIds: getObjectKeys(normalizedUser.entities.roles),
  };
}

function normalizePhoneNumbers(phones) {
  const clone = cloneDeep(phones || {});
  Object.keys(clone).forEach((key) => {
    clone[key].value = PhoneHelpers.normalizePhone(clone[key].value);
  });
  return clone;
}

export function getUsersPayload(normalizedUser, pageNo, totalUserCount, totalActiveMemberCount, userSearchSource, page) {
  return {
    users: {
      ...normalizedUser.entities.users,
    },
    userSearchSource,
    totalUserCount,
    totalActiveMemberCount,
    userIds: normalizedUser.result,
    connectedParties: {
      ...normalizedUser.entities.connectedParties,
    },
    connectedPartyIds: getObjectKeys(normalizedUser.entities.connectedParties),
    emails: {
      ...normalizedUser.entities.emails,
    },
    emailIds: getObjectKeys(normalizedUser.entities.emails),
    phones: normalizePhoneNumbers(normalizedUser.entities.phones),
    phoneIds: getObjectKeys(normalizedUser.entities.phones),
    facebooks: {
      ...normalizedUser.entities.facebooks,
    },
    facebookIds: getObjectKeys(normalizedUser.entities.facebooks),
    instagrams: {
      ...normalizedUser.entities.instagrams,
    },
    instagramIds: getObjectKeys(normalizedUser.entities.instagrams),
    rhinograms: {
      ...normalizedUser.entities.rhinograms,
    },
    rhinogramIds: getObjectKeys(normalizedUser.entities.rhinograms),
    groups: {
      ...normalizedUser.entities.groups,
    },
    groupIds: getObjectKeys(normalizedUser.entities.groups),
    channels: {
      ...normalizedUser.entities.channels,
    },
    channelIds: getObjectKeys(normalizedUser.entities.channels),
    tags: {
      ...normalizedUser.entities.tags,
    },
    tagIds: getObjectKeys(normalizedUser.entities.tags),
    organizations: {
      ...normalizedUser.entities.organizations,
    },
    organizationIds: getObjectKeys(normalizedUser.entities.organizations),
    roles: {
      ...normalizedUser.entities.roles,
    },
    roleIds: getObjectKeys(normalizedUser.entities.roles),
    pageNo,
    page,
  };
}

export function getContactListViewPayload(normalizedUser, normalizedChannel, totalUserCount, contactList) {
  return {
    users: {
      ...normalizedUser.entities.users,
    },
    totalUserCount,
    userIds: normalizedUser.result,
    connectedParties: {
      ...normalizedUser.entities.connectedParties,
    },
    connectedPartyIds: getObjectKeys(normalizedUser.entities.connectedParties),
    emails: {
      ...normalizedUser.entities.emails,
    },
    emailIds: getObjectKeys(normalizedUser.entities.emails),
    phones: {
      ...normalizedUser.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedUser.entities.phones),
    facebooks: {
      ...normalizedUser.entities.facebooks,
    },
    facebookIds: getObjectKeys(normalizedUser.entities.facebooks),
    instagrams: {
      ...normalizedUser.entities.instagrams,
    },
    instagramIds: getObjectKeys(normalizedUser.entities.instagrams),
    rhinograms: {
      ...normalizedUser.entities.rhinograms,
    },
    rhinogramIds: getObjectKeys(normalizedUser.entities.rhinograms),
    groups: {
      ...normalizedUser.entities.groups,
    },
    groupIds: getObjectKeys(normalizedUser.entities.groups),
    channels: normalizedChannel ? {
      ...normalizedUser.entities.channels,
      ...normalizedChannel.entities.channels,
    } : {
      ...normalizedUser.entities.channels,
    },
    channelIds: normalizedChannel ? [
      ...new Set([
        ...getObjectKeys(normalizedUser.entities.channels),
        ...getObjectKeys(normalizedChannel.entities.channels)]),
    ] : [
      ...new Set([...getObjectKeys(normalizedUser.entities.channels)]),
    ],
    tags: {
      ...normalizedUser.entities.tags,
    },
    tagIds: getObjectKeys(normalizedUser.entities.tags),
    organizations: {
      ...normalizedUser.entities.organizations,
    },
    organizationIds: getObjectKeys(normalizedUser.entities.organizations),
    roles: {
      ...normalizedUser.entities.roles,
    },
    roleIds: getObjectKeys(normalizedUser.entities.roles),
    contactList,
    pageLoaded: true,
  };
}

export function getContactsPagePayload(normalizedChannel, normalizedTags) {
  return {
    channels: normalizedChannel ? {
      ...normalizedChannel.entities.channels,
    } : {
    },
    channelIds: normalizedChannel ? [
      ...new Set([
        ...getObjectKeys(normalizedChannel.entities.channels)]),
    ] : [],
    pageLoaded: true,
    tags: {
      ...normalizedTags.entities.tags,
    },
    tagIds: normalizedTags.result,
  };
}

export function getContactsViewPayload(normalizedUser, normalizedChannel, totalUserCount, pageNo, userSearchSource, page) {
  return {
    users: {
      ...normalizedUser.entities.users,
    },
    totalUserCount,
    userSearchSource,
    userIds: normalizedUser.result,
    pageNo,
    page,
    connectedParties: {
      ...normalizedUser.entities.connectedParties,
    },
    connectedPartyIds: getObjectKeys(normalizedUser.entities.connectedParties),
    emails: {
      ...normalizedUser.entities.emails,
    },
    emailIds: getObjectKeys(normalizedUser.entities.emails),
    phones: {
      ...normalizedUser.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedUser.entities.phones),
    facebooks: {
      ...normalizedUser.entities.facebooks,
    },
    facebookIds: getObjectKeys(normalizedUser.entities.facebooks),
    instagrams: {
      ...normalizedUser.entities.instagrams,
    },
    instagramIds: getObjectKeys(normalizedUser.entities.instagrams),
    rhinograms: {
      ...normalizedUser.entities.rhinograms,
    },
    rhinogramIds: getObjectKeys(normalizedUser.entities.rhinograms),
    groups: {
      ...normalizedUser.entities.groups,
    },
    groupIds: getObjectKeys(normalizedUser.entities.groups),
    channels: normalizedChannel ? {
      ...normalizedUser.entities.channels,
      ...normalizedChannel.entities.channels,
    } : {
      ...normalizedUser.entities.channels,
    },
    channelIds: normalizedChannel ? [
      ...new Set([
        ...getObjectKeys(normalizedUser.entities.channels),
        ...getObjectKeys(normalizedChannel.entities.channels)]),
    ] : [
      ...new Set([...getObjectKeys(normalizedUser.entities.channels)]),
    ],
    tags: {
      ...normalizedUser.entities.tags,
    },
    tagIds: getObjectKeys(normalizedUser.entities.tags),
    organizations: {
      ...normalizedUser.entities.organizations,
    },
    organizationIds: getObjectKeys(normalizedUser.entities.organizations),
    roles: {
      ...normalizedUser.entities.roles,
    },
    roleIds: getObjectKeys(normalizedUser.entities.roles),
    pageLoaded: true,
  };
}

function currentUserHasNewThreadViewPermission(currentUserId, userId, userHadThreadView, response) {
  // We are covering an edgecase that asks:
  // 1) Is the current user the user that we are editing (updatingCurrentUser)
  // 2) Was the user previously missing the THREAD_VIEW permission (!userHadThreadView)
  // 3) Does the user now have the THREAD_VIEW permission as a result of the update (userNowHasThreadView)
  const updatingCurrentUser = currentUserId === userId;
  const userPermissions = response.data?.permissions || [];
  const permissionNames = userPermissions.map((permission) => permission.name);
  const userNowHasThreadView = permissionNames.includes(THREAD_VIEW);
  // If all of these evaluate to true, then we want to call fetchInboxSections(), because that means it was NOT fetched on initial load.
  return updatingCurrentUser && !userHadThreadView && userNowHasThreadView;
}

export function fetchContactList(isModalOpen) {
  return (dispatch) => {
    if (isModalOpen) {
      dispatch(requestContactListData());
    } else {
      dispatch(requestPageData());
    }
    const contactListRequests = [getContactList(), ChannelReducer.getChannels()];
    return axios.all(contactListRequests)
      .then(axios.spread((contactListResponse, channelResponse) => {
        const contactLists = contactListResponse.data;
        const totalUsers = contactLists.reduce((acc, list) => [...acc, ...list.users], []);
        const normalizedUser = normalize(totalUsers, [user]);
        const totalUserCount = totalUsers.length;
        const normalizedChannel = normalize(channelResponse.data, [channel]);

        dispatch(receiveContactList(getContactListViewPayload(normalizedUser, normalizedChannel, totalUserCount, contactLists)));
      }))
      .catch((err) => dispatch(handleError(err)));
  };
}

export function getContactList() {
  return axios.get('/contactList')
    .catch((err) => console.error(err.response || err));
}

export function deleteContactList(contactListId) {
  return (dispatch) =>
    axios.delete(`/contactList/${contactListId}`)
      .then((response) => {
        dispatch(removeContactList({ contactListId }));
        NotificationService('deleteContactList', response);
        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);
        dispatch(setError(err.response || err));
        NotificationService('deleteContactList', err.response);
      });
}
