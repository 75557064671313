import React from 'react';
import { Icon } from 'rhinostyle';
import { callConnectionResults } from '../../constants/RhinocallConstants';
import {
  findDurationFromMilliseconds,
} from '../../helpers/DateHelpers';

const CallEvent = ({ event }) => {
  const {
    formattedTimestamp,
    formattedActiveUserName,
    fromUser,
    duration,
    callStatus,
  } = event;

  const renderCallStatus = (formattedUserName) => {
    if (!callStatus) {
      return ` with ${formattedUserName} ended.`;
    }
    let appendedString = 'from';
    if (callStatus === callConnectionResults.Connected) {
      appendedString = 'with';
    }
    if (callStatus === callConnectionResults.LeftVoiceMessage) {
      appendedString = 'for';
    }
    return <>: <span className="u-font-weight-bold">{callStatus}</span> {appendedString} {formattedUserName}</>;
  };

  return (
    <>
      <div
        className="convo__event__title"
        data-cypress="callCompletedEvent"
      >
        <Icon bump="up" icon="phone" className="convo__event__icon" />
        {fromUser?.firstName || ''} {fromUser?.lastName || ''}&apos;s call{renderCallStatus(formattedActiveUserName)}
      </div>
      <div className="convo__event__subtext">
        {`${formattedTimestamp} ${duration && `(${findDurationFromMilliseconds(duration)})`}`}
      </div>
    </>
  );
};

export default CallEvent;
