import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroup, Checkbox, Select } from 'rhinostyle';
import { formatTimeZone } from '../helpers/DateHelpers';

const TimeZoneSelect = (props) => {
  const {
    selectedTimeZoneId,
    timeZones,
    observesDst,
    handleChange,
    error,
  } = props;

  const timeZoneOptions = () =>
    [{ id: -1, value: '--' },
      ...Object.keys(timeZones).map((key) => ({
        id: timeZones[key].id,
        value: formatTimeZone(timeZones[key]),
      })),
    ];

  return (
    <>
      <Select
        name="timeZoneId"
        label="Time Zone"
        onSelect={handleChange}
        options={timeZoneOptions()}
        selected={selectedTimeZoneId || ''}
        validationMessage={error}
        required
      />
      <CheckboxGroup>
        <Checkbox
          name="observesDst"
          onChange={handleChange}
          isChecked={observesDst}
          label="Daylight saving time observed"
        />
      </CheckboxGroup>
    </>
  );
};

TimeZoneSelect.propTypes = {
  error: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  observesDst: PropTypes.bool.isRequired,
  timeZones: PropTypes.object.isRequired,
  selectedTimeZoneId: PropTypes.number,
};

export default TimeZoneSelect;
