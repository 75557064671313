import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from 'rhinostyle';
import cx from 'classnames';
import { getMemberCallStatusData } from '../selectors/rhinocallSelector';
import { CALL_STATUS_ACTIVE, CALL_STATUS_INITIATED, CALL_STATUS_COMPLETED } from '../constants/RhinocallConstants';

const RhinocallStatus = ({ memberCallData }) => {
  const history = useHistory();
  const location = useLocation();
  const { contactId, status } = memberCallData;
  const callStatusClass = cx('app-navigation__sidebar__button__video', {
    'u-bg-success': status === CALL_STATUS_ACTIVE,
    'u-bg-warning': status === CALL_STATUS_INITIATED,
  });

  const handleCallStatusClick = () => {
    if (contactId && !location.pathname.includes(`/user/${contactId}`)) {
      history.push(`/inbox/all/user/${contactId}`);
    }
  };

  if (status && status !== CALL_STATUS_COMPLETED) {
    return (
      <>
        <div
          data-cypress="callSidebarButton"
          onClick={handleCallStatusClick}
          className={callStatusClass}
        >
          <Icon icon="phone" />
        </div>
        <div
          className="app-navigation__sidebar__button__video-status"
          data-cypress="callSidebarStatus"
        >
          {status === CALL_STATUS_ACTIVE ? 'Connected' : 'Waiting...'}
        </div>
      </>
    );
  }
  return null;
};

RhinocallStatus.propTypes = {
  memberCallData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  memberCallData: getMemberCallStatusData(state),
});

export default connect(mapStateToProps)(RhinocallStatus);
