import { createSelector } from '@reduxjs/toolkit';

const getGroups = (state) => state.group.groups;
const getUsers = (state) => state.user.users;
const getChannels = (state) => state.channel.channels;
const getLanguages = (state) => state.language.languages;
const getTypes = (state) => state.type.types;

const getUserId = (state, props) => props?.userId;
const getGroupId = (state, props) => props?.groupId;
const getChannelId = (state, props) => props.channelId;
const getLanguageId = (state, props) => props?.languageId;
const getTypeId = (state, props) => props?.typeId;

export const getGroupById = createSelector(
  [getGroups, getGroupId],
  (groups, groupId) => groups[groupId] || null,
);

export const getUserById = createSelector(
  [getUsers, getUserId],
  (users, userId) => users[userId] || null,
);

export const getChannelById = createSelector(
  [getChannels, getChannelId],
  (channels, channelId) => channels[channelId],
);

export const getLanguageById = createSelector(
  [getLanguages, getLanguageId],
  (languages, languageId) => languages[languageId] || null,
);

export const getTypeValue = createSelector(
  [getTypes, getTypeId],
  (types, typeId) => types[typeId]?.value || null,
);
