import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UtilitySystem, LoaderPulse } from 'rhinostyle';
import cx from 'classnames';
import * as ThreadReducer from '../reducers/threadReducer';
import WebSocketService from '../services/WebSocketService';
import { getUsersTyping } from '../selectors/uiSelectors';
import { getLoggedInUser } from '../selectors/userSelectors';
import { usePrevious } from '../hooks';

const ThreadTypingWrapper = (props) => {
  const { messageText } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const stopTypingTimeout = useRef();
  const usersTyping = useSelector((state) => getUsersTyping(state, location));
  const currentUser = useSelector((state) => getLoggedInUser(state));
  const [isTyping, setIsTyping] = useState(false);
  const groupId = Number(params.groupId) || null;
  const userId = Number(params.userId) || null;
  const previousParams = usePrevious({ groupId, userId });

  const currentUserId = currentUser.id;
  const threadType = location.pathname.includes('inbox') ? 'inbox' : 'chat';

  useEffect(() => {
    const options = {
      currentUserId,
      threadType,
    };
    if (userId || groupId) {
      ThreadReducer.handleUpdateWebsocketSubscriptions({ subscribe: { userId, groupId }, options, unsubscribe: previousParams });
      clearTyping(previousParams);
    }
    return () => {
      clearTyping();
      ThreadReducer.handleUpdateWebsocketSubscriptions({ unsubscribe: { userId, groupId }, options });
    };
  }, [userId, groupId]);

  useEffect(() => {
    const isInputEmpty = !messageText.length;
    if (!isInputEmpty && !isTyping) {
      setIsTyping(true);
      setTypingState(true);
    } else if (isInputEmpty && isTyping === true) {
      clearTyping();
    }
  }, [messageText]);

  useEffect(() => {
    if (stopTypingTimeout.current) {
      clearTimeout(stopTypingTimeout.current);
    }
    stopTypingTimeout.current = setTimeout(() => {
      setIsTyping(false);
      setTypingState(false);
      stopTypingTimeout.current = undefined;
    }, WebSocketService.typingTimeout);

    return () => {
      clearTimeout(stopTypingTimeout.current);
    };
  }, [isTyping]);

  useEffect(() => {
    dispatch(ThreadReducer.panelWatch(true));
    let resizeListener = getResizeLister;
    // TO DO: add ability to actually remove these listeners from rhinostyle
    UtilitySystem.optimizedResize.add(() => {
      resizeListener(false, true);
    });
    return () => {
      resizeListener = clearListener;
    };
  }, []);

  function clearTyping(options) {
    setIsTyping(false);
    setTypingState(false, options);
    clearTimeout(stopTypingTimeout.current);
  }

  function setTypingState(typingState, options) {
    const threadUserId = options?.userId || userId;
    const threadGroupId = options?.groupId || groupId;
    const typingUser = { firstName: currentUser.firstName, lastName: currentUser.lastName };
    const userPayload = threadType === 'chat' ? { subscribingToUserId: currentUserId, publishingToUserId: threadUserId } : { subscribingToUserId: threadUserId };
    WebSocketService.sendUpdatedTypingState(WebSocketService.shapeOutgoingTypingEvent({
      typingUserId: currentUserId,
      ...threadUserId && { ...userPayload },
      ...threadGroupId && { subscribingToGroupId: threadGroupId },
      threadType,
      isTyping: typingState,
      typingUser,
    }));
  }

  function getResizeLister() {
    return dispatch(ThreadReducer.panelWatch(false, true));
  }

  function clearListener() {
    return null;
  }

  const convoTypingClasses = cx('convo__typing-indicator', {
    [UtilitySystem.config.classes.active]: usersTyping && usersTyping.length,
  });
  return (
    <div className={convoTypingClasses}>
      {usersTyping?.length > 0 && (
      <div className="convo__typing-indicator__info" data-cypress="inboxTypingIndicator">
        <LoaderPulse className="u-m-r-small" type="secondary" pause={!(usersTyping && usersTyping.length)} />
        {usersTyping.map((u) => u.typingUserName).join(', ')} {usersTyping.length > 1 ? 'are' : 'is' } typing
      </div>
      )}
    </div>
  );
};

ThreadTypingWrapper.propTypes = {
  messageText: PropTypes.string,
};

export default ThreadTypingWrapper;
