import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useOnScreen, useUpdateMentionRead } from '.';
import { isSafari } from '../helpers/BrowserHelpers';

const useEventScroll = ({ eventId, eventRef, scrollToBottom, scrollToTop, unreadMention }) => {
  const location = useLocation();
  const inView = useOnScreen(eventRef, scrollToBottom || unreadMention);
  const { handleMarkMentionRead } = useUpdateMentionRead();
  const isSafariBrowser = isSafari();

  useEffect(() => {
    if (scrollToBottom && eventRef?.current) {
      eventRef.current.scrollIntoView();
    }
  }, [scrollToBottom, !!eventRef?.current]);

  useEffect(() => {
    if (inView && unreadMention) {
      handleMarkMentionRead(unreadMention);
    }
  }, [inView, unreadMention]);

  useEffect(() => {
    if (scrollToTop && eventRef.current) {
      const scrollBehavior = isSafariBrowser ? { behavior: 'smooth', block: 'start' } : true;
      eventRef.current.scrollIntoView(scrollBehavior);
    }
  }, [scrollToTop, !!eventRef?.current]);

  useEffect(() => {
    if (location.state?.eventId === eventId) {
      eventRef.current.scrollIntoView();
    }
  }, [location.state?.eventId]);
};

export default useEventScroll;
