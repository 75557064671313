import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LoaderCircle } from 'rhinostyle';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmptyMessage from './EmptyMessage';
import { isWebRTCSupported } from '../helpers/BrowserHelpers';
import { getLoggedInUser } from '../selectors/userSelectors';
import { LocationHelpers } from '../helpers';
import { createVideoConference } from '../reducers/rhinovideoReducer';
import { userHasVideoConferences } from '../helpers/UserHelpers';

const RhinovideoPreconfig = (props) => {
  const {
    consumerToken,
    currentUser,
    notFound,
    activeFromChannelId,
    activeUserId,
    phone,
  } = props;
  // State Hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  useEffect(() => {
    (async function useEffectAsync() {
      document.title = 'Rhinogram | Rhinovideo';
      if (!isWebRTCSupported()) {
        setError('WebRTCNotSupported');
      } else if (userHasVideoConferences()) {
        setError('VideoInProgress');
      } else {
        const hasAudioPermission = await navigator.mediaDevices.getUserMedia({ audio: true })
          .then(() => true)
          .catch(() => false);
        if (hasAudioPermission) {
          await dispatch(createVideoConference({
            createdBy: currentUser.id,
            memberId: currentUser.id,
            userId: activeUserId,
            orgId: currentUser.organizationId,
            channelId: activeFromChannelId,
            destination: phone,
            consumerToken,
          }))
            .then(({ videoId }) => {
              history.push(`/video/${videoId}`);
            })
            .catch((err) => {
              if (err.message === 'Video In Progress') {
                setError('VideoInProgress');
              } else if (err.message === 'Video Limit Reached') {
                setError('VideoLimitReached');
              } else {
                setError('VideoConnectionError');
              }
            });
        } else {
          setError('AudioRequired');
        }
      }
    }());
  }, []);

  useEffect(() => {
    if (notFound) history.push('/notfound');
  }, [notFound]);

  if (error === 'AudioRequired') {
    return (
      <EmptyMessage className="u-m-a-large" showFooter section="RhinoVideo Audio Declined" />
    );
  }

  if (error === 'VideoLimitReached') {
    return (
      <EmptyMessage className="u-m-a-large" showFooter section="RhinoVideo Limit Reached" />
    );
  }

  if (error === 'VideoConnectionError') {
    return (
      <EmptyMessage className="u-m-a-large" showFooter section="RhinoVideo Connection Error" />
    );
  }

  if (error === 'WebRTCNotSupported') {
    return (
      <EmptyMessage className="u-m-a-large" showFooter section="RhinoVideo Browser Not Supported" />
    );
  }

  if (error === 'VideoInProgress') {
    return (
      <EmptyMessage className="u-m-a-large" showFooter section="RhinoVideo In Progress" />
    );
  }

  return (
    <div className="app-page-loader">
      <LoaderCircle className="u-text-primary" />
    </div>
  );
};

RhinovideoPreconfig.propTypes = {
  consumerToken: PropTypes.string,
  currentUser: PropTypes.object,
  notFound: PropTypes.bool,
  activeFromChannelId: PropTypes.number,
  phone: PropTypes.string,
  activeUserId: PropTypes.number,
};

const mapStateToProps = (state) => {
  const currentUser = getLoggedInUser(state);
  const { activeUserId,
    activeFromChannelId,
    phone,
  } = LocationHelpers.getQueryParams(window.location.search);
  return {
    consumerToken: state.rhinovideo.vendor.twilio?.consumerToken,
    currentUser,
    notFound: state.rhinovideo.notFound,
    activeFromChannelId: Number(activeFromChannelId),
    activeUserId: Number(activeUserId),
    phone,
  };
};

export default connect(mapStateToProps)(RhinovideoPreconfig);
