import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Dropdown,
  DropdownMenuItem,
  Modal,
  ModalBody,
  ModalHeader,
  Icon,
  Input,
  Scrollbars,
  ResourceGroup,
  LoaderPulse,
  PillsInput,
  UtilitySystem,
  ModalFooter,
  UtilityInlineGrid,
  Resource,
  ResourceIntro,
} from 'rhinostyle';

import { getLoggedInUser } from '../selectors/userSelectors';
import { CONTACT_CREATE, CONVERSATION_CONTACT_MOBILE } from '../constants/UserPermissionsConstants';
import { PhoneHelpers, SearchHelpers } from '../helpers';
import { userHasAnyOfPermissions, userHasAllOfPermissions } from '../helpers/UserHelpers';
import BulkMessageModal from './BulkMessageModal';
import UserSearchResult from './UserSearchResult';
import { isMobile } from '../helpers/BrowserHelpers';
import { getRhinoblastMaxPageSize } from '../selectors/organizationSelectors';

const UserSearchModal = (props) => {
  const location = useLocation();
  const {
    activeFilterParam,
    buttonIcon,
    buttonProps,
    buttonText,
    filterParams,
    formInProgress,
    handleCreateDynamicChatGroup,
    handleFilterChange,
    handleFormCancel,
    handleFormConfirm,
    handleOnComplete,
    handleRemoveUserFromSelectedIds,
    handleReverseComplete,
    handleScopedAction,
    handleSearch,
    handleSearchSelect,
    handleStart,
    handleToggle,
    handleToggleMultiSearch,
    inputFormat,
    isMultiMemberSearchEnabled,
    isMultiContactSearchEnabled,
    masterUser,
    mergeUsers,
    modalHeaderTitle,
    multiUserInputRef,
    onSearchTextCleaveInit,
    open,
    page,
    phones,
    scope,
    scopedActionText,
    searchFocus,
    searchText,
    selectedUserIds,
    slaveUser,
    userSearchIds,
    userSearchLoading,
    users,
    isBulkMessageModalOpen,
    removeSelectedUserFromBulkMessageModal,
    handleOpenCloseBulkMessageModal,
    isBulkMessagingEnabled,
    handleOpenCloseSaveContactListActions,
    isSaveContactListActionsVisible,
    handleSaveSelectedContactList,
    onChangeHandler,
    contactListName,
    contactListError,
    isUpdateContactListActionsVisible,
    selectedSavedList,
    isContactListUpdateDisabled,
    handleUpdateContactList,
    clearSelectedContacts,
    handleCloseUserSearchModal,
    isRenderedFromContactListPanel,
    isContactListSaveInProgress,
    modalHeaderTitleSub,
    userSearchModalContainerRef,
    onScroll,
  } = props;
  const maxRhinoblastCount = useSelector(getRhinoblastMaxPageSize);
  const createContactPermission = isMobile() ? userHasAllOfPermissions([CONTACT_CREATE, CONVERSATION_CONTACT_MOBILE]) : userHasAnyOfPermissions([CONTACT_CREATE]);
  const havePermissionForBulkMessage = isBulkMessagingEnabled && userHasAllOfPermissions(['BULK_MESSAGING_EDIT', 'THREAD_MESSAGE_CREATE']);
  const showCreateContact = scopedActionText && scope !== 'members' && createContactPermission;

  const isSavedListSelected = !!selectedSavedList;
  const selectedContacts = selectedUserIds.map((userId) => {
    const user = users[userId];
    // this handles phonenumber-only Unknowns (as opposed to Facebook Unknowns with names)
    if (!user.firstName && !user.lastName) {
      return {
        id: userId.toString(),
        label: `${PhoneHelpers.formatPhone(phones[user.phones[0]].value)}`,
      };
    }
    return {
      id: userId.toString(),
      label: `${user.firstName} ${user.lastName}`,
    };
  });

  const selectedMessageContacts = selectedUserIds.map((userId) => {
    const user = users[userId];
    // this handles phonenumber-only Unknowns (as opposed to Facebook Unknowns with names)
    const associatedPhones = user.phones && user.phones.map((phone) => phones[phone]);
    return {
      id: userId.toString(),
      phones: associatedPhones,
      tags: user.tags,
      profileImageUrl: user.profileImageUrl,
      firstName: user.firstName,
      lastName: user.lastName,
      appointments: user.appointments,
    };
  });
  const loggedInUser = useSelector((state) => getLoggedInUser(state));

  const renderCreateContact = () => (
    <Resource className="u-text-primary" onClick={handleScopedAction} dataCypress="createNewContact">
      <ResourceIntro icon={{ icon: 'add' }} title="Create New Contact" />
    </Resource>
  );

  const renderSearchHelp = () => {
    if (searchText.length > 2 && userSearchLoading) {
      return <div className="u-text-center"><LoaderPulse type="secondary" /></div>;
    } else if (searchText.length > 2 && !userSearchIds.length && !userSearchLoading) {
      if (showCreateContact) {
        return (
          <ResourceGroup>
            {renderCreateContact()}
          </ResourceGroup>
        );
      } return <div className="search__no-results" data-cypress="noSearchResult">No results</div>;
    }

    return null;
  };

  const fetchUserContactInfo = (user) => {
    const returnVal = (user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}'s` : PhoneHelpers.formatPhone(phones[user.phones[0]].value);
    return returnVal;
  };

  const renderFilterOption = (id) => (
    <DropdownMenuItem key={id} id={id} label={SearchHelpers.getFilterLabelInformation(id).label} />
  );

  const renderModalFooterActions = () =>
    (isSaveContactListActionsVisible ? (
      <>
        <div className="row u-m-t-large">
          <div className="column-7@medium column-12@small column-12@xsmall">
            <Input
              type="text"
              onChange={onChangeHandler}
              value={contactListName}
              name="contactListName"
            />
            {contactListError && (
              <div className="u-m-t-small">
                <span className="u-text-danger">{contactListError}</span>
              </div>
            )}
          </div>
          <div className="column-5@medium column-6@small column-6@xsmall">
            <Button
              className="u-m-r"
              loading={isContactListSaveInProgress}
              type="secondary"
              disabled={(contactListName.length === 0)}
              onClick={() => handleSaveSelectedContactList(false)}
            >
              Save
            </Button>
            <Button
              type="secondary"
              disabled={(contactListName.length === 0) || isContactListSaveInProgress}
              outlined={(contactListName.length !== 0)}
              onClick={() => handleSaveSelectedContactList(true)}
            >
              Save & Message
            </Button>
          </div>
        </div>
        <div className="u-m-t-small u-text-center">
          <Button className="u-text-muted" type="link" onClick={() => handleOpenCloseSaveContactListActions(false)}>Cancel</Button>
        </div>
      </>
    ) : (
      <div className="row u-m-t-large">
        <div className="u-text-left column-6">
          {(!isUpdateContactListActionsVisible || !isSavedListSelected) ? (
            <Button
              loading={formInProgress}
              type="link"
              onClick={() => handleOpenCloseSaveContactListActions(true)}
            >
              Save Contact Selection
            </Button>
          ) : (
            <div>
              <div className=" u-text-muted">
                List: {selectedSavedList.name} ({selectedSavedList.users.length})
              </div>
              <div>
                <Button
                  className="u-p-a-0 u-p-r-small"
                  type={isContactListUpdateDisabled ? 'link-muted' : 'link'}
                  onClick={isContactListUpdateDisabled ? null : () => handleUpdateContactList(selectedSavedList.id)}
                  disabled={isContactListUpdateDisabled}
                >
                  Update
                </Button>|
                <Button
                  className="u-p-a-0 u-p-l-small"
                  type="link"
                  onClick={() => handleOpenCloseSaveContactListActions(true)}
                >
                  Save As
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="u-text-right column-6">
          <Button
            loading={formInProgress}
            type="primary"
            onClick={handleOpenCloseBulkMessageModal}
          >
            Message Contacts
          </Button>
        </div>
      </div>
    ));

  const renderModalBody = () => {
    const addonPosition = ['id', 'tags'].includes(activeFilterParam) ? 'left' : null;
    const addonValue = ['id', 'tags'].includes(activeFilterParam) ? '#' : null;
    const inputType = activeFilterParam === 'phone' ? 'tel' : 'text';
    switch (page) {
      case 1:
        return (
          <ModalBody>
            <div className="search__group">
              {selectedContacts.length >= maxRhinoblastCount && (
                <div className="search__max-contact u-text-center">
                  Maximum selection reached. No more than {maxRhinoblastCount} Contacts can be selected at a time.
                </div>
              )}
              <div className="global-search__wrapper">
                <Dropdown
                  size="large"
                  activeKey={activeFilterParam}
                  wide
                  type="input"
                  onSelect={handleFilterChange}
                  title="Search by"
                  className="global-search__filter"
                >
                  {filterParams.map(renderFilterOption)}
                </Dropdown>
                {(isMultiContactSearchEnabled || isMultiMemberSearchEnabled) ? (
                  <>
                    <PillsInput
                      pills={selectedContacts}
                      pillCloseIconClassName="u-text-muted"
                      onPillCloseIconClick={handleRemoveUserFromSelectedIds}
                      inputProps={{
                        name: scope,
                        initialValue: searchText,
                        naked: true,
                        size: 'large',
                        format: inputFormat,
                        onChange: handleSearch,
                        onInit: onSearchTextCleaveInit,
                        placeholder: SearchHelpers.getFilterLabelInformation(activeFilterParam).placeholder,
                        focus: searchFocus,
                        ref: multiUserInputRef,
                        className: 'global-search__pill-input__input--large',
                        autoComplete: 'off',
                      }}
                      className="search__modal__pills-input"
                    />
                    {isMultiMemberSearchEnabled && (
                    <span className="u-p-l-small">
                      <Button
                        size="large"
                        type="secondary"
                        disabled={selectedUserIds.length < 2}
                        onClick={handleCreateDynamicChatGroup}
                        loading={formInProgress}
                        data-cypress="userSearchSubmit"
                      >
                        Go
                      </Button>
                    </span>
                    )}
                  </>
                ) : (
                  <Input
                    placeholder={SearchHelpers.getFilterLabelInformation(activeFilterParam).placeholder}
                    className="search__input"
                    onChange={handleSearch}
                    initialValue={searchText}
                    size="large"
                    name={scope}
                    focus={searchFocus}
                    format={inputFormat}
                    addon={addonPosition}
                    type={inputType}
                    autoComplete="off"
                    dataFeatureTag="searchUsersInput"
                  >
                    {addonValue}
                  </Input>
                )}
              </div>
              {userSearchIds.length > 0 ? (
                <Scrollbars
                  className="resource-group__scroll"
                  autoHeight
                  autoHeightMax={UtilitySystem.config.resourceSizes.large}
                  data-cypress="searchList"
                  onScroll={onScroll}
                  ref={userSearchModalContainerRef}
                >
                  <ResourceGroup>
                    {userSearchIds.map((userId) => (
                      <UserSearchResult
                        userId={userId}
                        key={userId}
                        handleClick={handleSearchSelect}
                        scope={scope}
                        activeFilterParam={activeFilterParam}
                        searchText={searchText}
                        selectedContacts={selectedContacts}
                        disabled={(userId === loggedInUser.id && isMultiMemberSearchEnabled) || selectedContacts.length >= maxRhinoblastCount}
                      />
                    ))}
                    {showCreateContact && renderCreateContact()}
                  </ResourceGroup>
                </Scrollbars>
              ) :
                renderSearchHelp()}
              {scope === 'members' && (
                <div className="u-m-t-small">
                  <UtilityInlineGrid align="right">
                    <CheckboxGroup inline>
                      <Checkbox
                        className="u-text-small"
                        onChange={handleToggleMultiSearch}
                        isChecked={isMultiMemberSearchEnabled}
                        name="isMultiMemberSearchEnabled"
                        label="Select Multiple Members"
                      />
                    </CheckboxGroup>
                  </UtilityInlineGrid>
                </div>
              )}
              {havePermissionForBulkMessage && isMultiContactSearchEnabled && (
                <div className="search__count u-text-right">{selectedUserIds.length} of {maxRhinoblastCount} allowed</div>
              )}
              {isMultiContactSearchEnabled && selectedContacts.length > 0 && renderModalFooterActions()}
            </div>
          </ModalBody>
        );
      case 2:
        // mergeUsers is the only view with mutilple pages, but we could add this feature to more userSearch modals in the future.
        if (mergeUsers) {
          return (
            <ModalBody>
              <ol className="u-list--numbers">
                <li className="u-text-danger">A merge cannot be undone.</li>
                <li>All of <strong>{fetchUserContactInfo(slaveUser)}</strong> profile details, will merge into&nbsp;
                  <strong>{`${masterUser.firstName} ${masterUser.lastName}`}&apos;s</strong> profile.
                </li>
                <li>Timeline activity for <strong>{fetchUserContactInfo(slaveUser)}</strong> will be preserved on the&nbsp;
                  <strong>{`${masterUser.firstName} ${masterUser.lastName}`}&apos;s</strong> timeline.
                </li>
              </ol>
              <p>
                <strong>Read more:</strong>
                <a
                  href="https://rhinogram.freshdesk.com/support/solutions/articles/36000247694-merged-contacts"
                  target="_blank"
                  rel="noopener noreferrer"
                >What happen when I merge contacts?
                </a>
              </p>
            </ModalBody>
          );
        }

        return null;
      default:
        return null;
    }
  };

  const renderModalFooter = () => {
    switch (page) {
      case 2:
        if (mergeUsers) {
          return (
            <ModalFooter>
              <UtilityInlineGrid align="between">
                <Button onClick={handleFormCancel} type="primary" outlined>
                  Cancel
                </Button>
                <Button loading={formInProgress} onClick={() => handleFormConfirm(slaveUser.id, masterUser.id)} type="primary" data-cypress="acknowledgeMerge">
                  Acknowledge and Merge
                </Button>
              </UtilityInlineGrid>
            </ModalFooter>
          );
        }

        return null;
      default:
        return null;
    }
  };
  return (
    <>
      {!isRenderedFromContactListPanel && (
        <Button
          onClick={() => handleToggle('open')}
          {...buttonProps}
          data-cypress={buttonProps['data-cypress'] || buttonProps.title}
        >
          {buttonProps.iconOnly && <Icon icon={buttonIcon} />}{buttonText && <span className="button__text-content">{buttonText}</span>}
        </Button>
      )}
      <Modal modalDialogClass="global-search" onStart={handleStart} onComplete={handleOnComplete} onReverseComplete={handleReverseComplete} open={open}>
        <ModalHeader
          onClose={() => handleToggle('open')}
          title={modalHeaderTitle}
          titleSub={page === 2 ? modalHeaderTitleSub : ''}
        />
        {renderModalBody()}
        {renderModalFooter()}
      </Modal>
      <BulkMessageModal
        contacts={selectedMessageContacts}
        isModalOpen={isBulkMessageModalOpen}
        handleToggle={handleOpenCloseBulkMessageModal}
        handleChange={removeSelectedUserFromBulkMessageModal}
        clearSelectedContacts={clearSelectedContacts}
        handleCloseUserSearchModal={handleCloseUserSearchModal}
        location={location}
      />

    </>
  );
};

UserSearchModal.propTypes = {
  activeFilterParam: PropTypes.string.isRequired,
  buttonIcon: PropTypes.string.isRequired,
  buttonProps: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  emails: PropTypes.object.isRequired,
  filterParams: PropTypes.array.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleCreateDynamicChatGroup: PropTypes.func,
  handleFilterChange: PropTypes.func.isRequired,
  handleFormCancel: PropTypes.func.isRequired,
  handleFormConfirm: PropTypes.func,
  handleToggleMultiSearch: PropTypes.func,
  handleOnComplete: PropTypes.func.isRequired,
  handleRemoveUserFromSelectedIds: PropTypes.func,
  handleRemoveAllUsersFromSelectedIds: PropTypes.func,
  handleReverseComplete: PropTypes.func.isRequired,
  handleScopedAction: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleSearchSelect: PropTypes.func.isRequired,
  handleStart: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  inputFormat: PropTypes.object,
  isMultiMemberSearchEnabled: PropTypes.bool,
  isMultiContactSearchEnabled: PropTypes.bool,
  masterUser: PropTypes.object,
  mergeUsers: PropTypes.bool.isRequired,
  modalHeaderTitle: PropTypes.string.isRequired,
  multiUserInputRef: PropTypes.object.isRequired,
  onSearchTextCleaveInit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  phones: PropTypes.object.isRequired,
  scope: PropTypes.string.isRequired,
  scopedActionText: PropTypes.string.isRequired,
  searchFocus: PropTypes.bool.isRequired,
  searchText: PropTypes.string.isRequired,
  selectedUserIds: PropTypes.array,
  slaveUser: PropTypes.object,
  userSearchIds: PropTypes.array.isRequired,
  userSearchLoading: PropTypes.bool.isRequired,
  users: PropTypes.object.isRequired,
  isBulkMessageModalOpen: PropTypes.bool,
  removeSelectedUserFromBulkMessageModal: PropTypes.func,
  handleOpenCloseBulkMessageModal: PropTypes.func,
  isBulkMessagingEnabled: PropTypes.bool,
  contactListError: PropTypes.any,
  isSaveContactListActionsVisible: PropTypes.bool.isRequired,
  handleSaveSelectedContactList: PropTypes.func.isRequired,
  contactListName: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  handleOpenCloseSaveContactListActions: PropTypes.func.isRequired,
  handleOpenCloseUpdateContactListActions: PropTypes.func.isRequired,
  isUpdateContactListActionsVisible: PropTypes.bool.isRequired,
  isContactListUpdateDisabled: PropTypes.bool.isRequired,
  handleUpdateContactList: PropTypes.func.isRequired,
  clearSelectedContacts: PropTypes.func,
  handleCloseUserSearchModal: PropTypes.func,
  contactList: PropTypes.array,
  selectedSavedList: PropTypes.object,
  isRenderedFromContactListPanel: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  isContactListSaveInProgress: PropTypes.bool.isRequired,
  contactListLoading: PropTypes.bool,
  modalHeaderTitleSub: PropTypes.string,
  userSearchModalContainerRef: PropTypes.func,
  onScroll: PropTypes.func,
};

UserSearchModal.defaultProps = {
  slaveUser: {},
  masterUser: {},
  handleFormConfirm: () => {},
  modalHeaderTitleSub: '',
};

export default UserSearchModal;
