import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import { channel, appointmentStatus } from '../actions/NormalizrSchema';
import * as ChannelReducer from './channelReducer';
import { setError } from './uiReducer';
import * as DataHelpers from '../helpers/DataHelpers';
import { DEFAULT_PAGE_SIZE } from '../constants/AppConstants';

// SLICE
const appointmentSlice = createSlice({
  name: 'APPOINTMENT',
  initialState: {
    appointments: [],
    appointmentCampaigns: [],
    appointmentIntegrationStatusIds: [],
    appointmentIntegrationStatus: {},
    providerIds: [],
    providers: {},
    totalAppointmentCount: 0,
    totalPages: 0,
    lastUpdatedDate: '',
    activeKey: 3,
    loading: true,
    integrationStatusloading: false,
    providerLoading: false,
    pageLoading: true,
  },
  reducers: {
    receiveAppointments: (state, action) => ({
      ...state,
      totalAppointmentCount: action.payload.appointments.totalUpcomingApptCount,
      appointments: [...action.payload.appointments.appointments],
      totalPages: action.payload.totalPageCount,
      lastUpdatedDate: action.payload.appointments.lastUpdatedAppointment ?
        action.payload.appointments.lastUpdatedAppointment.lastUpdatedAt : '',
      loading: false,
      pageLoading: false,
    }),
    receiveAppointmentStatuses: (state, action) => ({
      ...state,
      appointmentIntegrationStatus: {
        ...action.payload.appointmentIntegrationStatus,
      },
      appointmentIntegrationStatusIds: action.payload.appointmentIntegrationStatusIds,
      integrationStatusloading: false,
    }),
    requestAppointmentStatuses: (state) => ({
      ...state,
      integrationStatusloading: true,
    }),
    receiveProviders: (state, action) => ({
      ...state,
      providers: {
        ...action.payload.providers,
      },
      providerIds: action.payload.providers.map((obj) => obj.id),
      providerLoading: false,
    }),
    requestProviders: (state) => ({
      ...state,
      providerLoading: true,
    }),
    requestData: (state, action) => ({
      ...state,
      loading: true,
      ...action.payload && {
        pageLoading: true,
      },
    }),
    receiveError: (state) => ({
      ...state,
      loading: false,
      integrationStatusloading: false,
    }),
  },
});

export default appointmentSlice.reducer;

// ACTIONS
export const {
  receiveAppointments,
  requestData,
  receiveError,
  receiveAppointmentStatuses,
  requestAppointmentStatuses,
  requestProviders,
  receiveProviders,
} = appointmentSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

export function fetchAppointments({
  fromDate,
  toDate,
  pageNumber,
  pageSize,
  sort,
  officeIds = null,
  appointmentTypeIds = null,
  integrationStatusTypeIds = null,
  appointmentStatusTypeIds = null,
  providerIds = null,
  initialRequest }) {
  return (dispatch) => {
    dispatch(requestData(initialRequest));
    const getAppointmentsViewRequests = [getAppointments(
      fromDate,
      toDate,
      pageNumber,
      pageSize,
      sort,
      officeIds,
      appointmentTypeIds,
      integrationStatusTypeIds,
      providerIds,
      appointmentStatusTypeIds,
    ),
    ChannelReducer.getChannels()];
    return axios.all(getAppointmentsViewRequests)
      .then(axios.spread((appointments, channels) => {
        const totalPageCount = getTotalPageCount(appointments.data, pageSize);
        const normalizedChannel = normalize(channels.data, [channel]);
        dispatch(receiveAppointments(prepareAppointmentsListViewPayload(appointments.data, totalPageCount, normalizedChannel)));
      })).catch((err) => {
        dispatch(receiveError());
        dispatch(setError(err));
      });
  };
}

export function fetchAppointmentStatuses(orgId) {
  return (dispatch) => {
    dispatch(requestAppointmentStatuses());
    return axios.get(`/appointment/${orgId}/statuses`)
      .then(((response) => {
        const normalizedAppointmentStatuses = normalize(response.data, [appointmentStatus]);
        dispatch(receiveAppointmentStatuses(getAppointmentStatusesPayload(normalizedAppointmentStatuses)));
      }))
      .catch((err) => {
        dispatch(receiveError());
        dispatch(setError(err));
      });
  };
}

export function fetchProviders() {
  return (dispatch) => {
    dispatch(requestProviders());
    return axios.get('/appointment/providers')
      .then(((response) => {
        dispatch(receiveProviders(response?.data));
      }))
      .catch((err) => {
        dispatch(receiveError());
        dispatch(setError(err));
      });
  };
}

function getAppointments(fromDate, toDate, pageNo, pageSize, sort, officeIds, appointmentTypeIds, integrationStatusTypeIds, providerIds, appointmentStatusTypeIds) {
  const params = {
    from: fromDate,
    to: toDate,
    pageNo,
    pageSize,
    sort,
    officeIds,
  };
  if (appointmentTypeIds?.length > 0) {
    params.appointmentTypeIds = JSON.stringify(appointmentTypeIds);
  }
  if (integrationStatusTypeIds?.length > 0) {
    params.integrationStatusTypeIds = JSON.stringify(integrationStatusTypeIds);
  }
  if (providerIds?.length > 0) {
    params.providerIds = JSON.stringify(providerIds);
  }
  if (appointmentStatusTypeIds?.length > 0) {
    params.appointmentStatusTypeIds = JSON.stringify(appointmentStatusTypeIds);
  }

  return axios.get('/appointment/appointmentManager', {
    params,
  }).catch((err) => console.error(err.response || err));
}

function getTotalPageCount(data, pageSize) {
  const { totalUpcomingApptCount } = data;
  const totalPages = Math.ceil(totalUpcomingApptCount / pageSize);
  return totalPages;
}

export function getAppointmentsByUserId({ orgId, userId, type }) {
  const params = {
    isUpcoming: type === 'upcoming',
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  return axios.get(`/appointment/${orgId}/user/${userId}`, {
    params,
  }).catch((err) => console.error(err.response || err));
}

// PREPARE CALLBACKS -- PAYLOAD CUSTOMIZERS

export function prepareAppointmentsListViewPayload(appointments, totalPageCount, normalizedChannel) {
  return {
    appointments: {
      ...appointments,
    },
    totalPageCount,
    channels: {
      ...normalizedChannel.entities.channels,
    },
    channelIds: [...new Set([...DataHelpers.getObjectKeys(normalizedChannel.entities.channels)])],
  };
}

function getAppointmentStatusesPayload(normalizedAppointmentStatuses) {
  return {
    appointmentIntegrationStatus: {
      ...normalizedAppointmentStatuses.entities.appointmentStatus,
    },
    appointmentIntegrationStatusIds: DataHelpers.getObjectKeys(normalizedAppointmentStatuses.entities.appointmentStatus),
  };
}
