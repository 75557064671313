import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
  DateRangeDropdown,
  Input,
  Icon,
  Button,
} from 'rhinostyle';
import ReportModal from './ReportModal';
import {
  PAST_APPOINTMENT_OPTIONS,
} from '../constants/AppointmentManagerConstants';
import { calculatePastDateRange } from '../helpers/AppointmentHelpers';
import { getPrescriptionConsentTypes } from '../selectors/typeSelectors';
import PayorsSearchSelect from './PayorsSearchSelect';
import AppointmentManagerMultiSelect from './AppointmentManagerMultiSelect';

const PrescriptionFilters = (props) => {
  const {
    handleChange,
    filters,
  } = props;

  const consentStatusTypeIds = useSelector((state) => getPrescriptionConsentTypes(state));
  const types = useSelector((state) => state.type.types);
  const pastMaxDate = moment().subtract(1, 'year').subtract(1, 'days').startOf('day');
  const now = moment();
  const [isOpen, setIsOpen] = useState(false);

  async function selectDate(date) {
    let { startDate, endDate } = date;
    if (date.activeKey === 5) {
      startDate = moment(startDate).startOf('day');
      endDate = moment(endDate).endOf('day');
    }
    handleChange({ activeKey: date.activeKey, startDate, endDate });
  }

  function handleChangeValue(name, value) {
    handleChange({ [name]: value });
  }

  function handleToggleModal(value) {
    setIsOpen(value);
  }

  const renderDateView = () => (
    <div className="filter-page__dropdown" data-cypress="datePicker">
      <DateRangeDropdown
        position="left"
        name="Prescription Date Picker"
        startDate={moment(filters.startDate)}
        selectDate={selectDate}
        selectDateRange={calculatePastDateRange}
        dateLabelFormat="MMM D, YYYY"
        dropdownMenuItems={PAST_APPOINTMENT_OPTIONS}
        endDate={moment(filters.endDate)}
        minDate={pastMaxDate}
        maxDate={now}
        activeKey={filters.activeKey}
        isCustomDate
        key="past"
      />
    </div>
  );

  const runAndDownloadReport = () => (
    <>
      <Button
        type="link"
        className="u-font-weight-bold"
        onClick={() => handleToggleModal(true)}
      >
        <Icon size="large" className="u-m-r-small" icon="run-report" />GENERATE REPORT
      </Button>
    </>
  );

  return (
    <>
      <div className="u-flex u-flex-direction-row u-flex-align-items-center">
        {renderDateView()}
        <PayorsSearchSelect
          selectedPayors={filters.payors}
          handleChange={handleChangeValue}
          type="filter"
        />
        <AppointmentManagerMultiSelect
          filterName="consentStatusTypeIds"
          itemIds={consentStatusTypeIds}
          items={types}
          label="Consent"
          name="consentStatusTypeIds"
          selectedIds={filters.consentStatusTypeIds}
          title="Consent Status"
          updateFilters={handleChange}
          titleField="value"
        />
        <Input
          placeholder="Search prescription names"
          className="filter-page__input form__group button--input filter-page__input--wide"
          onChange={handleChangeValue}
          initialValue={filters.prescriptionSearch}
          value={filters.prescriptionSearch}
          name="prescriptionSearch"
          type="text"
          autoComplete="off"
          dataFeatureTag="prescription-search"
        />
        <Input
          placeholder="Search prescription numbers"
          className="filter-page__input form__group button--input filter-page__input--wide"
          onChange={handleChangeValue}
          initialValue={filters.prescriptionNumberSearch}
          value={filters.prescriptionNumberSearch}
          name="prescriptionNumberSearch"
          type="text"
          autoComplete="off"
          dataFeatureTag="prescription-number-search"
        />
      </div>
      {runAndDownloadReport()}
      <ReportModal isOpen={isOpen} handleToggleModal={handleToggleModal} />
    </>
  );
};

PrescriptionFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
};

export default PrescriptionFilters;
