import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getStorageKey } from '../selectors/userSelectors';
import { usePrevious } from '.';
import StorageService from '../services/StorageService';
import { DRAFT_STORAGE_KEYS } from '../constants/AppConstants';

const useStorageService = (value, defaultValue, keyType = DRAFT_STORAGE_KEYS.default, handleChange) => {
  const key = useSelector((state) => getStorageKey(state, keyType));
  const previousKey = usePrevious(key);

  function handleSetInitialValue() {
    let initial = StorageService.readEntry(key);
    if (typeof initial !== typeof defaultValue) {
      initial = defaultValue;
    }
    handleChange(initial || defaultValue, keyType);
  }

  // gets initial values from session storage
  useEffect(() => {
    handleSetInitialValue();
  }, [key]);

  // updates session storage when value changes
  useEffect(() => {
    if (key === previousKey) {
      StorageService.createEntry(key, value);
    }
  }, [value, key, previousKey]);
};

export default useStorageService;
