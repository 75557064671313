import React from 'react';
import PropTypes from 'prop-types';
import { Button, UtilityInlineGrid } from 'rhinostyle';
import * as RhinologService from '../services/RhinologService';
import calculateViewportHeight from '../helpers/ViewportHelpers';
import rhinoTime from '../assets/images/rhino-time.svg';
import { CUSTOM_ERROR_TYPES } from '../constants/AppConstants';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    errorMessage: '',
  }

  componentDidCatch(error, info) { // eslint-disable-line no-unused-vars
    // Display fallback UI
    const { type } = error;
    const errorMessage = CUSTOM_ERROR_TYPES.includes(type) ? error.message : 'Looks like something went wrong.';
    this.setState({ hasError: true, errorMessage }, () => {
      calculateViewportHeight();
    });

    // Log error to CloudWatch
    RhinologService.createErrorLog(error, info);
  }

  render() {
    const { errorMessage } = this.state;
    if (this.state.hasError) {
      return (
        <div className="app-system-message__wrapper">
          <div className="app-system-message" data-vh="100">
            <div className="app-system-message__container">
              <div className="app-system-message__image">
                <div className="responsive-svg" style={{ paddingBottom: '127.742663656884876%' }}>
                  <img src={rhinoTime} alt="Rhinogram" />
                </div>
              </div>
              <div className="app-system-message__headline">
                Oops!
              </div>
              <div className="app-system-message__copy">
                {
                  errorMessage
                }
              </div>

              <UtilityInlineGrid size="large" align="middle">
                <Button
                  type="outline-reversed"
                  url="/"
                >
                  Go home
                </Button>
                <Button
                  type="outline-reversed"
                  url="https://rhinogram.freshdesk.com/support/tickets/new"
                  target="_blank"
                  rel="noopen noreferrer"
                >
                  Submit an issue
                </Button>
              </UtilityInlineGrid>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
