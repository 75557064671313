import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Icon, Button } from 'rhinostyle';
import { setDisplay } from '../reducers/rhinocallReducer';

const RhinocallDialpadButton = (props) => {
  const { isAudioConnected } = props;
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(setDisplay('dialer'));
  };

  return (
    <div>
      <Button
        type="link"
        size="large"
        iconOnly
        title="Dialpad"
        onClick={handleButtonClick}
        className="rhinocall__action-bar__button"
        disabled={!isAudioConnected}
      >
        <Icon size="large" bump="down" icon="dialpad" />
      </Button>
      <div className="rhinocall__action-bar__label">
        <span>Dialpad</span>
      </div>
    </div>
  );
};

RhinocallDialpadButton.propTypes = {
  isAudioConnected: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAudioConnected: state.rhinocall.isAudioConnected,
});

export default connect(mapStateToProps)(RhinocallDialpadButton);
