import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import cx from 'classnames';

import {
  Button,
  Icon,
  Scrollbars,
  ResourceGroup,
  Resource,
  ResourceIntro,
  ResourceBody,
  ResourceRight,
} from 'rhinostyle';

import { DateHelpers } from '../helpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import OOOPanel from './OOOPanel';
import EmptyMessage from './EmptyMessage';
import { OUT_OF_OFFICE_CREATE } from '../constants/UserPermissionsConstants';

const OrganizationOutOfOffice = (props) => {
  const {
    activeOOOId,
    channels,
    handleCreateOOOClick,
    handleTogglePanel,
    outOfOfficeIds,
    outOfOffices,
    panelOpen,
  } = props;

  const renderOutOfOffice = (id, key) => {
    const o = outOfOffices[id];
    const isClosedAllDay = DateHelpers.isEndOfDay(moment(o.to));
    const dateFormat = isClosedAllDay ? 'MMM D' : 'MMM D, h:mm a';
    const active = activeOOOId === id;

    return (
      <Resource active={active} key={key} onClick={() => handleTogglePanel(id)} dataCypress={o.title}>
        <ResourceIntro title={o.title} />
        <ResourceBody>{o.message}</ResourceBody>
        <ResourceRight>
          {moment(o.from).format(dateFormat)} - {moment(o.to).format(dateFormat)}
        </ResourceRight>
      </Resource>
    );
  };

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': panelOpen,
  });

  const activeOOO = outOfOffices[activeOOOId];

  return (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="app-page__header">
              <div className="app-page__header__title">Out of Office</div>
              {userHasAnyOfPermissions([OUT_OF_OFFICE_CREATE]) && (
                <div className="app-page__header__action">
                  <Button
                    onClick={handleCreateOOOClick}
                    iconOnly
                    type="secondary"
                    title="Create Out of Office Event"
                  >
                    <Icon icon="add" />
                  </Button>
                </div>
              )}
            </div>
            <div className="list-panel__body">
              {outOfOfficeIds.length > 0 ? (
                <Scrollbars className="list-panel__body__scroll">
                  <ResourceGroup>
                    {outOfOfficeIds.map(renderOutOfOffice)}
                  </ResourceGroup>
                </Scrollbars>
              ) :
                <EmptyMessage section="Out of Office" />}
            </div>
          </div>
        </div>
        {panelOpen && activeOOO && (
          <OOOPanel
            activeOOO={activeOOO}
            channels={channels}
            handleTogglePanel={handleTogglePanel}
          />
        )}
      </div>
    </div>
  );
};

OrganizationOutOfOffice.propTypes = {
  activeOOOId: PropTypes.number,
  channels: PropTypes.object.isRequired,
  handleCreateOOOClick: PropTypes.func,
  handleTogglePanel: PropTypes.func.isRequired,
  outOfOfficeIds: PropTypes.array,
  outOfOffices: PropTypes.object,
  panelOpen: PropTypes.bool.isRequired,
};

export default OrganizationOutOfOffice;
