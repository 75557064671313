import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LocationHelpers } from '../helpers';
import * as AuthReducer from '../reducers/authReducer';
import * as UIReducer from '../reducers/uiReducer';
import ForgotPassword from '../components/ForgotPassword';
import { isMobile } from '../helpers/BrowserHelpers';

class ForgotPasswordContainer extends Component {
  state = {
    loginEmail: '',
  };

  componentDidMount() {
    document.title = `Rhinogram | ${LocationHelpers.getPageTitle(window.location.pathname)}`;

    this.loginFormAutoFocus();
  }

  handleBackToLoginClick = () => {
    this.props.setError(null);
    this.props.clearAuth();

    this.props.history.push('/login?legacy=true');
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleForgotPassword();
    }
  }

  handleForgotPassword = () => this.props.forgotPassword(this.state.loginEmail);

  loginFormAutoFocus() {
    if (this.loginEmail && !isMobile()) {
      this.loginEmail.focus();
    }
  }

  render() {
    const props = {
      forgotPasswordEmail: this.props.forgotPasswordEmail,
      handleBackToLoginClick: this.handleBackToLoginClick,
      handleChange: this.handleChange,
      handleKeyPress: this.handleKeyPress,
      handleForgotPassword: this.handleForgotPassword,
      loading: this.props.loading,
      loginEmail: this.state.loginEmail,
      loginEmailRef: (loginEmail) => (this.loginEmail = loginEmail),
      authError: this.props.authError,
    };

    return (
      <ForgotPassword {...props} />
    );
  }
}

ForgotPasswordContainer.propTypes = {
  forgotPasswordEmail: PropTypes.string,
  forgotPassword: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  setError: PropTypes.func,
  clearAuth: PropTypes.func,
  authError: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { auth, ui } = state;

  return {
    authError: ui.error,
    forgotPasswordEmail: auth.forgotPasswordEmail,
    loading: auth.loading,
  };
};

const actions = {
  forgotPassword: AuthReducer.forgotPassword,
  setError: UIReducer.setError,
  clearAuth: AuthReducer.clearAuth,
};

export default connect(mapStateToProps, actions)(ForgotPasswordContainer);
