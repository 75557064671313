import { NotificationActions } from 'rhinostyle';
import {
  MAX_FACEBOOK_FILE_SIZE,
  MAX_MMS_FILE_SIZE,
  MAX_STANDARD_FILE_SIZE,
  MIME_TYPE_APPLICATION_PDF,
  THUMBNAIL_FILE_EXTENSIONS,
  UPLOAD_FILE_TYPES,
  VCARD_FILE_TYPE,
} from '../constants/AppConstants';
import {
  TYPE_IMAGE_BMP,
  TYPE_IMAGE_GIF,
  TYPE_IMAGE_JPEG,
  TYPE_IMAGE_JPG,
  TYPE_IMAGE_PNG,
  TYPE_ATTACHMENT_FORM,
  TYPE_VIDEO_3GP,
  TYPE_VIDEO_MP4,
  TYPE_VIDEO_MPEG,
  TYPE_VIDEO_OGG,
  TYPE_VIDEO_QUICKTIME,
  TYPE_VIDEO_WEBM,
  TYPE_VIDEO_M4V,
  TYPE_VIDEO_ASF,
  TYPE_VIDEO_MSVIDEO,
  TYPE_VIDEO_WMV,
  TYPE_ATTACHMENT_PDF,
  TYPE_VCARD,
} from '../constants/Types';
import { UploadActions } from '../actions';
import WebSocketService from '../services/WebSocketService';

const imageAttachmentTypes = [TYPE_IMAGE_BMP, TYPE_IMAGE_GIF, TYPE_IMAGE_JPEG, TYPE_IMAGE_JPG, TYPE_IMAGE_PNG, TYPE_VCARD];
const videoAttachmentTypes = [
  TYPE_VIDEO_3GP,
  TYPE_VIDEO_MP4,
  TYPE_VIDEO_MPEG,
  TYPE_VIDEO_OGG,
  TYPE_VIDEO_QUICKTIME,
  TYPE_VIDEO_WEBM,
  TYPE_VIDEO_M4V,
  TYPE_VIDEO_ASF,
  TYPE_VIDEO_MSVIDEO,
  TYPE_VIDEO_WMV];

export function findNonViableAttachmentsForMMS(attachment) {
  const isPdf = (attachment.type.name || attachment.type) === MIME_TYPE_APPLICATION_PDF;
  const isTooLarge = attachment.bytes > MAX_MMS_FILE_SIZE;

  return isTooLarge || isPdf;
}

export function processAttachments(attachments) {
  return attachments.map((attachment) => ({
    type: attachment.type.name ? attachment.type.name : attachment.type,
    bytes: attachment.bytes,
    attachmentUrl: attachment.attachmentUrl.toString(),
    name: attachment.name,
  }));
}

export function processCampaignAttachments(attachments) {
  return attachments.map((attachment) => ({
    type: attachment.type.name ? attachment.type.name : attachment.type,
    bytes: attachment.bytes || null,
    attachmentUrl: attachment.attachmentUrl.toString(),
    name: attachment.name,
    appointmentEventTypeId: attachment.appointmentEventTypeId,
    appointmentCampaignId: attachment.appointmentCampaignId,
  }));
}

export function getIsForm(attachment) {
  return (attachment.type === 'form' || attachment.type?.name === 'form' || attachment.attachmentTypeId === TYPE_ATTACHMENT_FORM);
}

export function getIsVCard(attachment) {
  return (attachment.type === VCARD_FILE_TYPE || attachment.type?.name === VCARD_FILE_TYPE || attachment.attachmentTypeId === TYPE_VCARD);
}

export async function handleAddFile(
  {
    file,
    currentAttachments = [],
    standard = true,
    secureMode = false,
    facebookChannelSelected = false,
    bucket = process.env.REACT_APP_STANDARD_BUCKET,
    progressCallback = null,
    customOptions = {},
    orgId,
  },
  callback,
) {
  let s3Bucket = bucket;
  const filename = file?.name;
  const pdfSupported = secureMode || facebookChannelSelected || standard;
  const maxNonstandardFileSize = facebookChannelSelected ? MAX_FACEBOOK_FILE_SIZE : MAX_MMS_FILE_SIZE;
  const maxSize = standard ? MAX_STANDARD_FILE_SIZE : maxNonstandardFileSize;
  // template attachments have no file size limits (large ones are turned into sharelinks)
  if (!file.type || file.type === '') {
    rejectUpload('The file you selected is not valid. Please select a valid file.');
  } else if ((file?.size >= maxSize) && pdfSupported) {
    let sizeLimit;
    if (facebookChannelSelected) {
      sizeLimit = '25 MB';
    } else if (standard) {
      sizeLimit = '5 GB';
    } else {
      sizeLimit = '50 MB';
    }
    rejectUpload(`The file you selected is too large. The maximum file size is ${sizeLimit}`);
  } else {
    try {
      const isShareLink = !pdfSupported && shouldFileBeSharelink({ file, standardFileSize: false, facebookChannelSelected });
      if (isShareLink) s3Bucket = process.env.REACT_APP_PUBLIC_TEMPLATES_BUCKET;
      const attachment = await UploadActions.uploadFile(file, s3Bucket, filename, progressCallback);
      if (isShareLink) {
        const partialUrl = `${process.env.REACT_APP_PUBLIC_TEMPLATE_FILES_DOMAIN}/${orgId}/${attachment.file}`;
        const { data: sharelink } = await UploadActions.createSharelink(partialUrl);
        if (THUMBNAIL_FILE_EXTENSIONS.includes(attachment.fileType)) {
          const baseFileName = getFileName(attachment.key).split('.')[0];
          const extension = getFileExtension(attachment.key);
          const fileName = `${baseFileName}.${extension}`;
          const destinationKey = `${orgId}/${fileName}`;
          currentAttachments.push({
            key: destinationKey,
            attachmentUrl: fileName,
            type: attachment.fileType,
            name: file.name,
            bytes: attachment.bytes,
            isPostProcessingNeeded: process.env.REACT_APP_ENVIRONMENT === 'local' ? false : isThumbnailGenerated(attachment.fileType),
            shortLinkId: sharelink.id,
            ...customOptions,
          });
        } else {
          const updatedSharelinkData = {
            key: attachment.key,
            attachmentUrl: attachment.file,
            bytes: attachment.bytes,
            name: file.name,
            type: attachment.fileType,
            isPostProcessingNeeded: false,
            sharelink: sharelink.url,
            isSharelink: true,
            ...customOptions,
          };
          currentAttachments.push(updatedSharelinkData);
        }
      } else {
        currentAttachments.push({
          key: attachment.key,
          attachmentUrl: attachment.file,
          type: attachment.fileType,
          name: file.name,
          bytes: attachment.bytes,
          isPostProcessingNeeded: process.env.REACT_APP_ENVIRONMENT === 'local' ? false : isThumbnailGenerated(attachment.fileType),
          ...customOptions,
        });
      }
      // Subscribe to thumbnail generation
      WebSocketService.updateSubscriptions([
        ...WebSocketService.subscriptions,
        WebSocketService.shapeSubscriptionForFileUploadPostProcessingEvents({ bucket: s3Bucket, key: attachment.key }),
      ]);
    } catch (err) {
      NotificationActions.addNotification({
        body: err?.response?.data?.message,
        type: 'danger',
      });
    } finally {
      callback({ attachments: currentAttachments });
    }
  }

  function rejectUpload(body) {
    NotificationActions.addNotification({
      body,
      type: 'danger',
    });

    callback({ attachments: currentAttachments });
  }
}

export function fileTypeMustBeSharelink(attachment) {
  if (attachment?.type?.id) {
    return !imageAttachmentTypes.includes(attachment?.type?.id);
  }
  return attachment?.fileType ? !UPLOAD_FILE_TYPES.includes(attachment?.fileType) : !UPLOAD_FILE_TYPES.includes(attachment?.type);
}

export function shouldAttachmentBeSharelink({ attachment, standardFileSize = true, isFacebook = false }) {
  const maxNonStandardFileSize = isFacebook ? MAX_FACEBOOK_FILE_SIZE : MAX_MMS_FILE_SIZE;
  return (!standardFileSize && attachment.bytes > maxNonStandardFileSize) || fileTypeMustBeSharelink(attachment);
}

export function shouldFileBeSharelink({ file, standardFileSize = true, isFacebook = false }) {
  const maxNonStandardFileSize = isFacebook ? MAX_FACEBOOK_FILE_SIZE : MAX_MMS_FILE_SIZE;
  return (!standardFileSize && file.size > maxNonStandardFileSize) || fileTypeMustBeSharelink(file);
}

function getFileName(fileKey) {
  return fileKey.split('/').pop();
}

function getFileExtension(fileKey) {
  return getFileName(fileKey).split('.').pop();
}

function isThumbnailGenerated(extension) {
  return THUMBNAIL_FILE_EXTENSIONS.includes(extension);
}

export function getAttDisplayName(att = {}) {
  if (att.attachmentTypeId === TYPE_VCARD) {
    return 'VCard';
  } if (att.attachmentTypeId === TYPE_ATTACHMENT_PDF) {
    return 'PDF';
  } if (imageAttachmentTypes.includes(att.attachmentTypeId)) {
    return 'Image';
  } if (videoAttachmentTypes.includes(att.attachmentTypeId)) {
    return 'Video';
  }
  return 'File';
}

export function filterAttachments(attachments, attachmentType) {
  return attachments.filter((attachment) => (attachment.type === attachmentType));
}
