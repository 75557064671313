import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  UtilityList,
} from 'rhinostyle';

import { handleAddFile, getIsForm, getIsVCard } from '../helpers/AttachmentHelpers';
import FileInformationTable from './FileInformationTable';
import { FORM_VIEW } from '../constants/UserPermissionsConstants';
import { getLoggedInUserPermissionNames } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import SingleAttachmentPreview from './SingleAttachmentPreview';
import FormLibraryModal from './FormLibraryModal';

const FileUpload = (props) => {
  const {
    attachments,
    isRhinoformEnabled,
    fileProgress,
    appointmentEventTypeId,
    handleChange,
    hasRhinoformPermission,
    currentOrganization,
    customWarningMessage,
    source,
  } = props;
  const uploaderInputRef = useRef();

  const [fileProgressText, setFileProgressText] = useState('');
  const [activeUpload, setActiveUpload] = useState(false);
  const [formLibraryModalOpen, setFormLibraryModalOpen] = useState(false);
  const separator = currentOrganization.isOfficeVCardEnabled ? ',' : ' and';
  const addButtonLabel = `Add Files${isRhinoformEnabled ? `${separator} Forms` : ''}${currentOrganization.isOfficeVCardEnabled ? ' and VCards' : ''}`;
  const facebookInfo = source === 'templates';
  const secureInfo = source === 'templates';

  useEffect(() => {
    setFileProgressText(fileProgress);
  }, [fileProgress]);

  const handleUploadComplete = (attachmentObj) => {
    setActiveUpload(false);
    handleChange('attachments', [...attachmentObj.attachments, ...filterCampaignAttachments('form'), ...filterCampaignAttachments('text/x-vcard')]);
  };

  function getIsFormDisabled(form) {
    attachments.some((formTemplate) => formTemplate.attachmentUrl === form.formId && formTemplate.appointmentEventTypeId !== appointmentEventTypeId);
  }

  function filterCampaignAttachments(attachmentType, eventType) {
    return attachments.filter((attachment) => {
      const isAppointmentType = eventType && appointmentEventTypeId ? attachment.appointmentEventTypeId === appointmentEventTypeId : true;
      let isAttachmentType = true;
      if (attachmentType) {
        if (attachmentType === 'form') {
          isAttachmentType = getIsForm(attachment);
        } else if (attachmentType === 'text/x-vcard') {
          isAttachmentType = getIsVCard(attachment);
        } else {
          isAttachmentType = !getIsForm(attachment) && !getIsVCard(attachment);
        }
      }
      return isAppointmentType && isAttachmentType;
    });
  }

  const handleUpdateForms = (forms) => {
    setFormLibraryModalOpen(false);
    handleChange('attachments', [...filterCampaignAttachments('file'), ...forms, ...filterCampaignAttachments('text/x-vcard')]);
  };

  const handleUpdateVCards = (vCards) => {
    setFormLibraryModalOpen(false);
    handleChange('attachments', [...filterCampaignAttachments('file'), ...filterCampaignAttachments('form'), ...vCards]);
  };

  const handleAddDocument = (event) => {
    setActiveUpload(true);
    setFormLibraryModalOpen(false);
    const handleProgressDisplay = (progressText) => {
      setFileProgressText(progressText);
    };

    const uploadOpts = {
      currentAttachments: [],
      file: event.target.files[0],
      bucket: process.env.REACT_APP_PUBLIC_TEMPLATES_BUCKET,
      isTemplateUpload: true,
      progressCallback: handleProgressDisplay,
      ...appointmentEventTypeId && {
        customOptions: { appointmentEventTypeId },
      },
    };

    handleAddFile(uploadOpts, handleUploadComplete);
  };

  const handleRemoveAttachment = (key) => {
    handleChange('attachments', attachments.filter((attachment) => attachment.attachmentUrl !== key || (appointmentEventTypeId && attachment.appointmentEventTypeId !== appointmentEventTypeId)));
  };

  function handleUploadDocument() {
    if ((isRhinoformEnabled && hasRhinoformPermission) || currentOrganization.isOfficeVCardEnabled) {
      setFormLibraryModalOpen(true);
    } else {
      uploaderInputRef.current.click();
    }
  }

  const renderAttachment = (attachment, index) => (
    <SingleAttachmentPreview
      attachment={attachment}
      handleRemoveAttachment={() => handleRemoveAttachment(attachment.attachmentUrl)}
      index={index}
      key={attachment.attachmentUrl}
    />
  );

  return (
    <div className="u-m-t">
      <div className="u-text-center">
        <div className="box__title">Files</div>
        <div className="box__subtitle">Include a file to send with a message.</div>
        {customWarningMessage && (<div className="box__subtitle box__subtitle-warning">{customWarningMessage}</div>)}
      </div>
      {!!(activeUpload || attachments?.length) && (
      <UtilityList border className="u-m-t-0">
        {filterCampaignAttachments(null, true).map(renderAttachment)}
      </UtilityList>
      )}
      <input
        className="u-sr-only"
        onChange={handleAddDocument}
        onClick={() => { uploaderInputRef.current.value = null; }}
        ref={uploaderInputRef}
        type="file"
      />

      <Button
        size="small"
        loading={activeUpload}
        onClick={handleUploadDocument}
        type="primary"
        data-cypress="upload-document"
      >
        {addButtonLabel}
      </Button>
      <span className="u-text-small u-text-primary">{(activeUpload ? fileProgressText : '')}</span>
      <div className="u-m-t-large">
        <FileInformationTable facebook={facebookInfo} secure={secureInfo} typeClass="reminders" />
      </div>
      {(isRhinoformEnabled || currentOrganization.isOfficeVCardEnabled) && (
      <FormLibraryModal
        open={formLibraryModalOpen}
        closeModal={() => setFormLibraryModalOpen(false)}
        handleFormAppend={handleUpdateForms}
        handleVCardAppend={handleUpdateVCards}
        handleAddFile={handleAddDocument}
        isMultiSelect
        selectedForms={filterCampaignAttachments('form', appointmentEventTypeId)}
        selectedVCards={filterCampaignAttachments('text/x-vcard', appointmentEventTypeId)}
        getIsFormDisabled={getIsFormDisabled}
        appointmentEventTypeId={appointmentEventTypeId}
        source={source}
      />
      )}
    </div>
  );
};

FileUpload.propTypes = {
  attachments: PropTypes.array,
  appointmentEventTypeId: PropTypes.number,
  isRhinoformEnabled: PropTypes.bool,
  fileProgress: PropTypes.number,
  handleChange: PropTypes.func,
  hasRhinoformPermission: PropTypes.bool,
  currentOrganization: PropTypes.object,
  customWarningMessage: PropTypes.string,
  source: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isRhinoformEnabled: !!state.form.org?.isRhinoformEnabled,
  fileProgress: state.fileProgress,
  hasRhinoformPermission: !!getLoggedInUserPermissionNames(state)?.includes(FORM_VIEW),
  currentOrganization: getCurrentOrg(state),
});

const actions = {};

export default connect(mapStateToProps, actions)(FileUpload);
