import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useActiveUser = (activeUserId) => {
  const params = useParams();
  const userId = activeUserId || params?.userId;
  const activeUser = useSelector((state) => state.user.users[userId]);
  return activeUser;
};

export default useActiveUser;
