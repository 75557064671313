import { createSelector } from '@reduxjs/toolkit';
import { Types } from '../constants';

const getTypes = (state) => state.type.types;
const getTypeIds = (state) => state.type.typeIds;

export const getPhoneTypes = createSelector(
  [getTypes, getTypeIds],
  (types, typeIds) =>
    typeIds
      .filter((t) => types[t].classId === Types.TYPE_CLASS_PHONE)
      .map((t) => types[t]),
);

export const getEmailTypes = createSelector(
  [getTypes, getTypeIds],
  (types, typeIds) =>
    typeIds
      .filter((t) => types[t].classId === Types.TYPE_CLASS_EMAIL)
      .map((t) => types[t]),
);

export const getConnectedPartyTypes = createSelector(
  [getTypes, getTypeIds],
  (types, typeIds) =>
    typeIds
      .filter((t) => types[t].classId === Types.TYPE_CLASS_CONNECTION)
      .map((t) => types[t]),
);

export const getContactFilterTypes = createSelector(
  [getTypes, getTypeIds],
  (types, typeIds) =>
    typeIds
      .filter((t) =>
        types[t].classId === Types.TYPE_CLASS_USER &&
        t !== Types.TYPE_MEMBER &&
        t !== Types.TYPE_CONNECTED_PARTY),
);

export const getPrescriptionConsentTypes = createSelector(
  [getTypes, getTypeIds],
  (types, typeIds) =>
    typeIds
      .filter((t) =>
        types[t].class === 'prescriptionStatus'),
);
