/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

import DraggableContainer from './DraggableContainer';
import RhinocallPreconfig from './RhinocallPreconfig';
import RhinocallConnected from './RhinocallConnected';
import RhinocallDialer from './RhinocallDialer';
import RhinovideoTimer from './RhinovideoTimer';
import RhinocallStream from './RhinocallStream';
import RhinocallComplete from './RhinocallComplete';
import { getIsWebRTCCallConnected } from '../selectors/rhinocallSelector';
import { handleHangup, setIsRhinocallModalVisible, getActiveMemberCall } from '../reducers/rhinocallReducer';
import { isMobile } from '../helpers/BrowserHelpers';
import { RHINOCALL_FORWARD_PHONE } from '../constants/RhinocallConstants';

const RhinocallModal = (props) => {
  const location = useLocation();
  const telephoneRef = useRef(null);
  const dispatch = useDispatch();
  const {
    display,
    activeCallId,
    isWebRTCCallConnected,
    isCallConnecting,
  } = props;
  let hidden;
  let visibilityChange;

  useEffect(() => {
    function handleBeforeUnload() {
      if (!isMobile()) {
        dispatch(handleHangup());
      }
    }
    if (!isMobile()) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    dispatch(setIsRhinocallModalVisible(true));
    return () => {
      if (!isMobile()) {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    };
  }, []);

  useEffect(() => {
    if (isMobile() && !isCallConnecting && activeCallId) {
      telephoneRef.current.click();
      initiateListeners();
    }

    return () => {
      if (isMobile()) {
        document.removeEventListener(visibilityChange, handleVisibilityChange, false);
      }
    };
  }, [isCallConnecting]);

  function handleVisibilityChange() {
    if (!document[hidden]) {
      if (isMobile() && activeCallId) dispatch(getActiveMemberCall(activeCallId));
    }
  }

  function initiateListeners() {
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
  }

  const renderComponent = () => {
    if (display === 'dialer') {
      return <RhinocallDialer />;
    } if (display === 'complete') {
      return <RhinocallComplete />;
    } if (activeCallId || isWebRTCCallConnected) {
      return <RhinocallConnected />;
    } return <RhinocallPreconfig location={location} />;
  };

  const modalClasses = cx('rhinocall__modal', {
    'rhinocall__modal--active-call': activeCallId && display !== 'complete',
  });

  return (
    <DraggableContainer className="draggable-container__dock-sidebar">
      <>
        <div className={modalClasses} data-cypress="callModalContainer">
          {activeCallId && display !== 'complete' && (<RhinovideoTimer type="call" />)}
          {renderComponent()}
          {isMobile() ? (
            <a className="rhinocall__telephone" ref={telephoneRef} href={`tel:${RHINOCALL_FORWARD_PHONE}`}>call</a>
          ) : <RhinocallStream />}
        </div>
      </>
    </DraggableContainer>
  );
};

RhinocallModal.propTypes = {
  display: PropTypes.string.isRequired,
  activeCallId: PropTypes.string,
  isWebRTCCallConnected: PropTypes.bool,
  isCallConnecting: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { rhinocall } = state;
  return {
    display: rhinocall.display,
    activeCallId: rhinocall.callId,
    isWebRTCCallConnected: getIsWebRTCCallConnected(state),
    isCallConnecting: rhinocall.isCallConnecting,
  };
};

export default connect(mapStateToProps)(RhinocallModal);
