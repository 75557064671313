import axios from 'axios';

const FORM_BASE_URL = process.env.REACT_APP_FORM_BASE_URL;

export function getOrganization(organizationId) {
  return axios.get(`${FORM_BASE_URL}/org/${organizationId}`);
}

export function postOrganization(payload) {
  return axios.post(`${FORM_BASE_URL}/org/${payload.organizationId}`, payload);
}

export function patchOrganization(payload) {
  return axios.patch(`${FORM_BASE_URL}/org/${payload.organizationId}`, payload);
}

export function getAuthenticatedUser() {
  return axios.get(`${FORM_BASE_URL}/org/auth/`);
}

export function buildFormTemplate(payload, type, formData, config, isUpdate) {
  if (isUpdate) {
    return axios.post(`${FORM_BASE_URL}/form-templates/build`, payload);
  } else if (type === 2) {
    return axios.post(`${FORM_BASE_URL}/form-templates/build`, formData, config);
  }
  return axios.post(`${FORM_BASE_URL}/form-templates/build`, payload);
}

export function postPresignPDF(payload) {
  return axios.post(`${FORM_BASE_URL}/form-templates/upload`, payload);
}

export function deleteRhinoform(payload) {
  return axios.delete(`${FORM_BASE_URL}/forms/external/${payload.formExternalId}`, payload);
}

export function createRhinoform(payload) {
  return axios.post(`${FORM_BASE_URL}/form-templates`, payload);
}

export function getFormTemplates(isPublished) {
  return axios.get(`${FORM_BASE_URL}/form-templates?isPublished=${isPublished}`);
}

export function patchFormTemplates(formId, payload) {
  return axios.patch(`${FORM_BASE_URL}/form-templates/${formId}`, payload);
}

export function deleteFormTemplate(formId) {
  return axios.delete(`${FORM_BASE_URL}/form-templates/${formId}`);
}

export function cloneFormTemplate(formId, payload) {
  return axios.post(`${FORM_BASE_URL}/form-templates/${formId}/clone`, payload);
}

export function getFormTemplateById(formId) {
  return axios.get(`${FORM_BASE_URL}/form-templates/${formId}`);
}

export function sendFormById(formId, payload, created = false) {
  return axios.post(`${FORM_BASE_URL}/form-templates/${formId}/send?created=${created}`, payload);
}

export function getContactForms(userId) {
  return axios.get(`${FORM_BASE_URL}/forms?contactId=${userId}`);
}

export function getFormTitles() {
  return axios.get(`${FORM_BASE_URL}/forms/titles`);
}

export function getFormById(formId) {
  return axios.get(`${FORM_BASE_URL}/forms/${formId}`);
}

export function getPatientForms(params) {
  return axios.get(`${FORM_BASE_URL}/forms`, { params: { ...params } });
}

export function getAppointmentForms(appointmentHash) {
  return axios.get(`${FORM_BASE_URL}/forms?appointmentHash=${appointmentHash}`);
}
