export const categories = [
  { id: 'Appointment Schedule', name: 'Schedule Appointment' },
  { id: 'Appointment New Patient', name: 'Appointment - New Patients' },
  { id: 'Office Hours', name: 'Hours of Operations' },
  { id: 'Appointment Reschedule', name: 'Appointment - Reschedule' },
  { id: 'Appointment Upcoming', name: 'Appointment - Upcoming' },
  { id: 'Billing', name: 'Billing' },
  { id: 'Checkin', name: 'Check-In' },
  { id: 'Insurance', name: 'Insurance' },
  { id: 'Medical Covid', name: 'Medical - COVID' },
  { id: 'Medical Records', name: 'Medical - Records' },
  { id: 'Medical Refill', name: 'Medical - Re-Fills' },
  { id: 'Medical Triage', name: 'Medical - Triage' },
  { id: 'Referral', name: 'Referral' },
];

export const ROUTING_CATEGORY_NONE = 'None';
