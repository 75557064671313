import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createTag, destroyTag, replaceTag } from '../reducers/tagReducer';
import TagModal from '../components/TagModal';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { TYPE_TAG_LOCATIONS } from '../constants/Types';

class TagModalContainer extends Component {
  static initialState = {
    errors: {},
    formInProgress: false,
    isDeleteRequested: false,
    tagCategoryId: TYPE_TAG_LOCATIONS,
    tagId: -1,
    tagName: '',
  }

  state = TagModalContainer.initialState;

  componentDidUpdate() {
    if (Number.isInteger(this.props.tagId) && this.props.tagId !== this.state.tagId) {
      const { name, typeId } = this.props.tags[this.props.tagId];

      this.setState({ // eslint-disable-line react/no-did-update-set-state
        tagId: this.props.tagId,
        tagName: name,
        tagCategoryId: typeId,
      });
    }
  }

  resetState = () => {
    this.setState(TagModalContainer.initialState);
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  handleCategoryChange = (value) => {
    this.setState({ tagCategoryId: value });
  }

  handleDeleteRequest = () => {
    this.setState({ isDeleteRequested: true });
  };

  handleCancelDeleteRequest = () => {
    this.setState({ isDeleteRequested: false });
  };

  handleConfirmDeleteRequest = () => {
    this.setState({ formInProgress: true });

    this.props.destroyTag(this.state.tagId).then(() => {
      this.props.closeModal();
    });
  };

  handleSave = () => {
    const errors = ValidationService(ValidationShapers.shapeTag({ ...this.state }));
    const errorCount = Object.keys(errors).length;

    if (errorCount > 0) {
      this.setState({ errors });
    } else if (this.props.mode === 'create') {
      this.setState({ formInProgress: true, errors: {} });
      this.props.createTag({
        name: this.state.tagName,
        typeId: this.state.tagCategoryId,
      })
        .then(() => {
          if (this.props.error) {
            errors.tagName = this.props.error.data.message;

            this.setState({ errors, formInProgress: false });
          } else {
            this.props.closeModal();
          }
        });
    } else { // edit tag
      this.setState({ formInProgress: true, errors: {} });
      this.props.replaceTag(this.state.tagId, {
        name: this.state.tagName,
        typeId: this.state.tagCategoryId,
      })
        .then(() => {
          if (this.props.error) {
            errors.tagName = this.props.error.data.message;

            this.setState({ errors, formInProgress: false });
          } else {
            this.props.closeModal();
          }
        });
    }
  };

  render() {
    const props = {
      closeModal: this.props.closeModal,
      errors: this.state.errors,
      formInProgress: this.state.formInProgress,
      handleCancelDeleteRequest: this.handleCancelDeleteRequest,
      handleCategoryChange: this.handleCategoryChange,
      handleChange: this.handleChange,
      handleConfirmDeleteRequest: this.handleConfirmDeleteRequest,
      handleDeleteRequest: this.handleDeleteRequest,
      handleSave: this.handleSave,
      isDeleteRequested: this.state.isDeleteRequested,
      isOpen: this.props.isOpen,
      mode: this.props.mode,
      resetState: this.resetState,
      tagCategoryId: this.state.tagCategoryId,
      tagName: this.state.tagName,
    };

    return <TagModal {...props} />;
  }
}

TagModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  createTag: PropTypes.func.isRequired,
  destroyTag: PropTypes.func,
  error: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  replaceTag: PropTypes.func.isRequired,
  tagId: PropTypes.number,
  tags: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { ui, tag } = state;

  return {
    error: ui.error,
    tags: tag.tags,
  };
};

const actions = {
  createTag,
  destroyTag,
  replaceTag,
};

export default connect(mapStateToProps, actions)(TagModalContainer);
