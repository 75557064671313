import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DropdownMultiSelect } from 'rhinostyle';
import { shapeRolePermissions } from '../helpers/RoleHelpers';

const RolesDropdown = ({
  disabled,
  value: selectedIds,
  handleChange,
}) => {
  const roles = useSelector((state) => [...shapeRolePermissions(state.role.systemRoles), ...shapeRolePermissions(state.role.customRoles)]);
  const items = roles.reduce((acc, role) => {
    const roleType = role.systemRole ? 'system' : 'custom';
    const roleId = `${roleType}-${role.id}`;
    acc[roleId] = {
      ...role,
      title: role.name,
      label: role.name,
    };
    return acc;
  }, {});
  const itemIds = Object.keys(items);

  function getDropdownLabel() {
    if (selectedIds.length > 0) {
      return selectedIds.map((tagId) => `#${items[tagId].name}`).join(', ');
    }
    return 'Roles';
  }

  function handleSelectItem(name, value) {
    // remove from array if present, otherwise add
    const updatedRoles = selectedIds.includes(value) ? selectedIds.filter((id) => id !== value) : [...selectedIds, value];
    handleChange('roles', updatedRoles);
  }

  return itemIds?.length > 0 ? (

    <DropdownMultiSelect
      items={items}
      itemIds={itemIds}
      selectedItemIds={selectedIds}
      handleSelect={handleSelectItem}
      label={getDropdownLabel()}
      name="roles"
      dropdownClass="filter-page__dropdown filter-page__dropdown--wide"
      disabled={disabled}
    />
  ) : null;
};
RolesDropdown.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,

};
export default RolesDropdown;
