import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getIsHost } from '../selectors/rhinovideoSelectors';
import { isMobile, getIsWebkitAudioWorkaroundNeeded } from '../helpers/BrowserHelpers';
import RhinovideoHangupButton from './RhinovideoHangupButton';
import RhinovideoToggleCameraButton from './RhinovideoToggleCameraButton';
import RhinovideoToggleMicrophoneButton from './RhinovideoToggleMicrophoneButton';
import RhinovideoScreenShareButton from './RhinovideoScreenShareButton';
import RhinovideoPauseScreenShareButton from './RhinovideoPauseScreenShareButton';
import RhinovideoToggleParticipantsButton from './RhinovideoToggleParticipantsButton';
import RhinovideoRotateCameraButton from './RhinovideoRotateCameraButton';
import RhinovideoToggleVolumeButton from './RhinovideoToggleVolumeButton';

const RhinovideoActionButtons = (props) => {
  const {
    isHost,
    isScreenShareEnabled,
    availableVideoSources,
    isCameraEnabled,
  } = props;

  return (
    <div className="rhinovideo__action-bar__container">
      <div className="rhinovideo__action-bar">
        <RhinovideoToggleMicrophoneButton />
        {getIsWebkitAudioWorkaroundNeeded() && <RhinovideoToggleVolumeButton />}
        <RhinovideoToggleCameraButton />
        {availableVideoSources.length > 1 && isMobile() && isCameraEnabled && <RhinovideoRotateCameraButton />}
        {isHost && !isMobile() && <RhinovideoScreenShareButton />}
        {isScreenShareEnabled && <RhinovideoPauseScreenShareButton />}
        <RhinovideoToggleParticipantsButton />
        <RhinovideoHangupButton />
      </div>
    </div>
  );
};

RhinovideoActionButtons.propTypes = {
  isHost: PropTypes.bool.isRequired,
  isScreenShareEnabled: PropTypes.bool.isRequired,
  availableVideoSources: PropTypes.array.isRequired,
  isCameraEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { rhinovideo } = state;

  return {
    isHost: getIsHost(state),
    isScreenShareEnabled: rhinovideo.isScreenShareEnabled,
    availableVideoSources: rhinovideo.availableVideoSources,
    isCameraEnabled: rhinovideo.isCameraEnabled,
  };
};

export default connect(mapStateToProps)(RhinovideoActionButtons);
