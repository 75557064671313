import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  DateRangeDropdown,
} from 'rhinostyle';

import {
  PAST_APPOINTMENT_OPTIONS,
} from '../constants/AppointmentManagerConstants';
import { calculatePastDateRange } from '../helpers/AppointmentHelpers';
import SavedListMultiSelect from './SavedListMultiSelect';

const CampaignsFilters = (props) => {
  const {
    handleChange,
    filters,
  } = props;

  const pastMaxDate = moment().subtract(1, 'year').subtract(1, 'days').startOf('day');
  const now = moment();

  async function selectDate(date) {
    let { startDate, endDate } = date;
    if (date.activeKey === 5) {
      startDate = moment(startDate).startOf('day');
      endDate = moment(endDate).endOf('day');
    }
    handleChange({ activeKey: date.activeKey, startDate, endDate });
  }

  const renderDateView = () => (
    <div className="filter-page__dropdown" data-cypress="datePicker">
      <DateRangeDropdown
        position="left"
        name="Campaign Date Picker"
        startDate={moment(filters.startDate)}
        selectDate={selectDate}
        selectDateRange={calculatePastDateRange}
        dateLabelFormat="MMM D, YYYY"
        dropdownMenuItems={PAST_APPOINTMENT_OPTIONS}
        endDate={moment(filters.endDate)}
        minDate={pastMaxDate}
        maxDate={now}
        activeKey={filters.activeKey}
        isCustomDate
        key="past"
      />
    </div>
  );
  return (
    <>
      {renderDateView()}
      <SavedListMultiSelect
        selectedIds={filters.savedListIds}
        updateFilters={(savedListIds) => handleChange({ savedListIds })}
      />
    </>
  );
};

CampaignsFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
};

export default CampaignsFilters;
