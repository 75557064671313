import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Icon,
  Input,
  Dropdown,
  LoaderPulse,
  DropdownMenuItem,
  Scrollbars,
  ResourceGroup,
  Resource,
  ResourceIntro,
  UtilitySystem,
} from 'rhinostyle';

import { AVATAR_BASE_URL } from '../constants/AppConstants';
import UserSearchResult from './UserSearchResult';
import { SearchHelpers, DataHelpers, UserHelpers, MaskingHelpers } from '../helpers';
import * as UserReducer from '../reducers/userReducer';
import { TYPE_PATIENT, TYPE_USER_OTHER } from '../constants/Types';

const UserSearchWithConnectedParties = (props) => {
  const {
    connectedParties,
    clearUserSearch,
    fetchUserSearch,
    handleContactSelect,
    activeUser,
    disabled,
    contactErrors,
  } = props;

  const types = useSelector((state) => state.type.types);
  const userSearchIds = useSelector((state) => state.user.userSearchIds);
  const users = useSelector((state) => state.user.users);
  const [activeFilterParam, setActiveFilterParam] = useState('name');
  const [contactSearchValue, setContactSearchValue] = useState('');
  const [inputFormat, setInputFormat] = useState(null);
  const [usersLoading, setUserLoading] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [disableSearch, setDisableSearch] = useState(disabled);

  const activeUserConnectedParties = activeUser?.connectedParties?.map((cpId) => connectedParties[cpId]);
  const activePatientConnectedParties = activeUserConnectedParties?.filter((user) => user?.userTypeId === TYPE_PATIENT && user?.integrated === 1);
  const isActiveUserOtherType = activeUser?.typeId === TYPE_USER_OTHER;
  const integratedUserSearchIds = userSearchIds?.filter((userId) => users[userId].integrated);

  useEffect(() => {
    setDisableSearch(disabled);
  }, [disabled]);

  useEffect(() => {
    let format = null;
    if (activeFilterParam === 'dob') {
      format = UtilitySystem.dateFormat;
    } else if (activeFilterParam === 'phone') {
      format = MaskingHelpers.phone;
    }
    setInputFormat(format);
  }, [activeFilterParam, inputFormat]);

  const searchHandler = async (id, searchValue, searchValueFormatted) => {
    const searchText = (['dob', 'phone'].includes(activeFilterParam)) ? searchValueFormatted : searchValue;
    setContactSearchValue(searchText);
    setUserLoading(true);
    setSelectedContactId(null);
    if (searchText?.length > 2) {
      await fetchUserSearch({
        searchValue: searchText,
        scope: 'patients',
        type: activeFilterParam,
        source: 'globalSearch',
        idsToExclude: [activeUser.id],
      });
    } else {
      clearUserSearch();
    }
    setUserLoading(false);
  };

  const handleFilterChange = (filterBy) => {
    setActiveFilterParam(filterBy);
    setContactSearchValue('');
    setSelectedContactId(null);
    setInputFormat(null);
  };

  const renderFilterOption = (id) => (
    <DropdownMenuItem key={id} id={id} label={SearchHelpers.getFilterLabelInformation(id).label} />
  );

  const selectContactHandler = (firstName, lastName, contactId) => {
    if (selectedContactId === contactId) {
      setSelectedContactId(null);
      handleContactSelect(null);
    } else {
      setSelectedContactId(contactId);
      handleContactSelect(contactId);
    }
  };

  const renderConnectedParties = () => {
    if (!activePatientConnectedParties) {
      return null;
    }
    const distinctUsers = DataHelpers.distinctObjectsFromArray(activePatientConnectedParties, 'userId');

    if (distinctUsers.length === 0) {
      return null;
    }

    return (
      <Scrollbars className="resource-group__scroll" autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.small}>
        <ResourceGroup interfaceMode="radio">
          {distinctUsers.map((connectedPartyUser) => {
            const profileImageUrl = connectedPartyUser.userProfileImageUrl ? `${AVATAR_BASE_URL}${connectedPartyUser.userProfileImageUrl}` : '';
            const relation = types[connectedPartyUser.connectionTypeId].value;
            const { userFirstName, userLastName } = connectedPartyUser;
            return (
              <Resource
                disabled={disableSearch}
                id={connectedPartyUser.userId}
                selected={selectedContactId === connectedPartyUser.userId}
                key={connectedPartyUser.userId}
                onClick={() => selectContactHandler(userFirstName, userLastName, connectedPartyUser.userId)}
              >
                <ResourceIntro
                  avatar={{
                    image: profileImageUrl,
                    name: UserHelpers.formatAvatarName(userFirstName, userLastName),
                    type: 'member',
                    showOnlineStatus: false,
                  }}
                  title={`${userFirstName} ${userLastName}`}
                >
                  {relation}
                </ResourceIntro>
              </Resource>
            );
          })}
        </ResourceGroup>
      </Scrollbars>
    );
  };

  const renderSearchedContactList = () => {
    if (integratedUserSearchIds.length === 0) {
      return (
        <span>No Records Found</span>
      );
    }

    return (
      <Scrollbars
        className="resource-group__scroll"
        autoHeight
        autoHeightMax={UtilitySystem.config.resourceSizes.small}
      >
        <ResourceGroup interfaceMode="radio">
          {integratedUserSearchIds.map((userId) => {
            const handleClick = (id) => {
              const user = users[id];
              const { firstName, lastName } = user;
              selectContactHandler(firstName, lastName, user.id);
            };

            return (
              <UserSearchResult
                userId={userId}
                key={userId}
                handleClick={handleClick}
                isSelected={selectedContactId === userId}
                scope="patients"
                activeFilterParam={activeFilterParam}
                searchText={contactSearchValue}
                interfaceMode="radio"
                isRightResourceDetailsVisible={false}
              />
            );
          })}
        </ResourceGroup>
      </Scrollbars>
    );
  };

  return (
    <div className="">
      <h5 className="saved-content__h5">Patient{!disabled && isActiveUserOtherType && <span className="form__asterisk">*</span>}</h5>
      <Input
        placeholder={activeFilterParam !== 'name' ? SearchHelpers.getFilterLabelInformation(activeFilterParam).placeholder : 'Search Contact'}
        className="search__input"
        onChange={searchHandler}
        initialValue={contactSearchValue}
        addon="both"
        size="large"
        format={inputFormat}
        name="nonMembers"
        autoFocus
        autoComplete="off"
        disabled={disableSearch}
      >
        <Icon icon="search" />
        <Dropdown
          hideCaret
          wide
          activeKey={activeFilterParam}
          type="input"
          onSelect={handleFilterChange}
          title="Search by"
          icon="filter"
          className="filter-dropdown"
          position="top-center"
          disabled={disableSearch}
        >
          {SearchHelpers.filterContactParams.filterParams.map(renderFilterOption)}
        </Dropdown>
      </Input>
      {usersLoading ? (
        <div className="u-text-center">
          <LoaderPulse type="secondary" />
        </div>
      ) : (
        <div className="search-result-wrapper">
          {contactErrors?.selectedContactId && (<span className="u-text-small u-text-danger">{contactErrors?.selectedContactId}</span>)}
          {contactSearchValue?.length < 3 ? renderConnectedParties() : renderSearchedContactList()}
        </div>
      )}
    </div>
  );
};

UserSearchWithConnectedParties.propTypes = {
  fetchUserSearch: PropTypes.func,
  clearUserSearch: PropTypes.func,
  connectedParties: PropTypes.object,
  handleContactSelect: PropTypes.func,
  activeUser: PropTypes.object,
  disabled: PropTypes.bool,
  contactErrors: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { connectedParty } = state;

  return {
    connectedParties: connectedParty?.connectedParties,
  };
};

const actions = {
  clearUserSearch: UserReducer.clearUserSearch,
  fetchUserSearch: UserReducer.fetchUserSearch,
};

export default connect(mapStateToProps, actions)(UserSearchWithConnectedParties);
