import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars, ResourceGroup, UtilitySystem, Resource, ResourceIntro } from 'rhinostyle';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import MentionItem from './MentionItem';

const MentionPanel = (props) => {
  const { handleMentionClick, mentionGroupIds, mentionUserIds, isNoteMention, chatGroupUserIds } = props;
  return (
    <Scrollbars className="resource-group__scroll mention-panel" autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.large}>
      <ResourceGroup className="resource-group@small">
        {!isNoteMention && (
        <Resource onClick={() => handleMentionClick('all')} className="u-flex-direction-column u-text-overflow">
          <ResourceIntro
            title={`Mention All Members (${chatGroupUserIds.length})`}
            titleSub="@All"
          />
        </Resource>
        )}
        {mentionUserIds?.map((userId) => <MentionItem userId={userId} handleMentionClick={handleMentionClick} key={userId} />)}
        {isNoteMention && mentionGroupIds?.map((groupId) => <MentionItem groupId={groupId} handleMentionClick={handleMentionClick} key={groupId} />)}
      </ResourceGroup>
    </Scrollbars>
  );
};
MentionPanel.propTypes = {
  mentionGroupIds: PropTypes.array,
  mentionUserIds: PropTypes.array,
  handleMentionClick: PropTypes.func,
  isNoteMention: PropTypes.bool,
  chatGroupUserIds: PropTypes.array,
};

const mapStateToProps = (state) => ({
  mentionGroupIds: state.group.mentionGroupIds,
  mentionUserIds: state.user.mentionUserIds,
});
export default connect(mapStateToProps)(onClickOutside(MentionPanel));
