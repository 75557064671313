import React, { useState } from 'react';
import { Icon, Tooltip, Button } from 'rhinostyle';
import cx from 'classnames';
import PdfViewerContainer from '../containers/PdfViewerContainer';
import { getAttachmentUrl } from '../helpers/SavedContentHelpers';

const AppointmentFormStatus = ({ form = {} }) => {
  const { status, formInternalData, title, createdFor } = form;
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const iconClasses = cx('appointments__icon', {
    [`appointments__icon--${status?.toLowerCase()}`]: status,
    'appointments__icon--pending': !status,
  });

  function getIconName() {
    if (status === 'Completed') return 'checkmark';
    if (status === 'Expired') return 'close';
    return 'minus';
  }

  function renderIcon() {
    if (status === 'Sent') {
      return (
        <div className="appointments__circle
        appointments__circle--confirmed"
        />
      );
    }
    return (
      <Icon
        icon={getIconName()}
        className={iconClasses}
      />
    );
  }

  return (
    <div className="form__status">
      <>
        <Tooltip content={status || 'Not Sent'} placement="bottom">
          {renderIcon()}
        </Tooltip>
        {status === 'Completed' && (
        <Button className="u-text-primary" reset size="small" type="link" onClick={() => setPdfViewerOpen(true)}>
          <Icon
            icon="pdfFile"
            className="form__status__completed"
          />
        </Button>
        )}
        <PdfViewerContainer
          open={!!(pdfViewerOpen && formInternalData)}
          attachmentUrl={getAttachmentUrl(formInternalData, createdFor)}
          fileName={title}
          handlePdfViewerClose={() => setPdfViewerOpen((current) => !current)}
        />
      </>
    </div>
  );
};

export default AppointmentFormStatus;
