import PropTypes from 'prop-types';
import React from 'react';
import { ResourceGroup, Resource, ResourceIntro, ResourceRight, LoaderPulse } from 'rhinostyle';

import PageLoader from './PageLoader';
import { DateHelpers } from '../helpers';
import * as UserHelpers from '../helpers/UserHelpers';
import OrganizationSearchContainer from '../containers/OrganizationSearchContainer';

const SelectOrganization = (props) => {
  const {
    handleSelectOrg,
    myUsersRaw,
    orgSearchIds,
    orgSearchLoading,
    organizations,
    pageLoading,
    searchInputActive,
  } = props;

  const renderOrg = (org, userId) => {
    const sub = org.isActive ? `#${org.id}` : <div className="u-text-muted">#{org.id}<span className="u-m-l-small u-text-accent">Inactive</span></div>;
    const title = org.name;

    return (
      <Resource key={org.id} onClick={() => handleSelectOrg(org.id, userId)} dataCypress={`ORGANIZATION_${org.id}`}>
        <ResourceIntro title={title} titleSub={sub} />
        <ResourceRight>Created {DateHelpers.formatTimestamp(org.activeAt, 'MM/DD/YYYY')}</ResourceRight>
      </Resource>
    );
  };

  const getSearchedUsers = (orgId) => {
    const org = organizations[orgId];
    const user = myUsersRaw.find((u) => u.organization.id === orgId);

    return renderOrg(org, user.id);
  };

  const getMyUsers = (user) => {
    const org = user.organization;

    return renderOrg(org, user.id);
  };

  const handleIfNoResults = () => {
    if (searchInputActive) {
      return <span className="u-text-muted">No organizations found</span>;
    }
    const descending = myUsersRaw.reduceRight((a, b) => a.concat(b), []);

    return <ResourceGroup>{descending.map(getMyUsers)}</ResourceGroup>;
  };

  if (pageLoading) {
    return <PageLoader />;
  }

  const renderResults = () =>
    (orgSearchIds?.length > 0 ? (
      <ResourceGroup>{orgSearchIds.map(getSearchedUsers)}</ResourceGroup>
    ) : (
      handleIfNoResults()
    ));

  return (
    <div className="app-page__container">
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title" data-cypress="organizationTitle">
            Organizations
          </div>
        </div>
        <div className="box">
          <div className="box__title-wrapper">
            <div className="box__title">Select Organization</div>
            <div className="box__subtitle">Choose an organization below to login</div>
          </div>
          <div className="search__group">
            {UserHelpers.isCCR(myUsersRaw[0]) && (
              <OrganizationSearchContainer />
            )}
            {orgSearchLoading ? (
              <div className="u-text-center">
                <LoaderPulse type="secondary" />
              </div>
            ) : (
              renderResults()
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SelectOrganization.propTypes = {
  handleSelectOrg: PropTypes.func.isRequired,
  myUsersRaw: PropTypes.array.isRequired,
  orgSearchIds: PropTypes.array.isRequired,
  orgSearchLoading: PropTypes.bool.isRequired,
  organizations: PropTypes.object.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  searchInputActive: PropTypes.bool.isRequired,
};

export default SelectOrganization;
