import { createSelector } from '@reduxjs/toolkit';
import { RHINOBLAST_MAX_PAGE_SIZE, RHINOBLAST_DEFAULT_PAGE_SIZE } from '../constants/AppConstants';

const getOrgs = (state) => state.organization?.organizations;
const getCurrentOrgId = (state) => state.auth?.currentOrg;

export const getCurrentOrg = createSelector(
  [getOrgs, getCurrentOrgId], (orgs, currentOrgId) => {
    if (orgs && currentOrgId) return orgs[currentOrgId];

    return null;
  },
);

export const getRhinoblastMaxPageSize = createSelector(
  [getCurrentOrg], (currentOrg) => {
    if (currentOrg?.isBulkMessagingEnabled && currentOrg.isRhinoblastMaxEnabled) {
      return RHINOBLAST_MAX_PAGE_SIZE;
    }
    return RHINOBLAST_DEFAULT_PAGE_SIZE;
  },
);
