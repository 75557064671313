import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../helpers/AxiosHelper';
import * as SearchHelpers from '../helpers/SearchHelpers';

export const searchSlice = createApi({
  reducerPath: 'search',
  baseQuery: axiosBaseQuery({ baseURL: process.env.REACT_APP_SEARCH_BASE_URL }),
  tagTypes: ['search'],
  endpoints: (builder) => ({
    getSearch: builder.query({
      query: (payload) => {
        const page = payload.pageNo * payload.pageSize;
        const url = SearchHelpers.getSearchQueryString({ ...payload, page });

        return { url, method: 'get' };
      },
      providesTags: ['search'],
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetSearchQuery,
  useLazyGetSearchQuery,
  util: { updateQueryData },
} = searchSlice;

export default searchSlice;
