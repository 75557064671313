import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormLabel, UtilitySystem } from 'rhinostyle';
import { ChromePicker } from 'react-color';

// Once the widget work is pushed in; this should probably be moved over to RhinoStyle
class ColorPicker extends PureComponent {
  state = {
    showColorPicker: this.props.open,
  };

  id = `${this.props.name}-${UtilitySystem.generateUUID()}`;

  handleChange = (color) => {
    const { name, onChange } = this.props;

    onChange(name, color);
  }

  componentDidUpdate() {
    if (this.props.open !== this.state.showColorPicker) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        showColorPicker: this.props.open,
      });
    }
  }

  render() {
    const { showColorPicker } = this.state;
    const { color, label, handleToggleOpenClose } = this.props;
    const currentStyle = {
      background: color,
    };
    return (
      <div className="form__group">
        <FormLabel id={this.id}>{label}</FormLabel>
        <div className="color-picker__wrapper">
          <div
            className="color-picker__current"
            style={currentStyle}
            onClick={handleToggleOpenClose}
          />
          {showColorPicker && (
            <div className="color-picker__picker">
              <ChromePicker
                disableAlpha
                color={color}
                onChange={this.handleChange}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleToggleOpenClose: PropTypes.func.isRequired,
};

export default ColorPicker;
