import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserById } from '../reducers/userReducer';
import { getUserById } from '../selectors/routingSelectors';

const useUser = (itemId) => {
  const dispatch = useDispatch();
  const userId = Number(itemId) || null;
  const user = useSelector((state) => getUserById(state, { userId }));

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserById(userId));
    }
  }, [userId]);

  return user;
};

export default useUser;
