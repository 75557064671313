import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useInboxParams } from '.';
import { createAssignmentGroup, createAssignmentMember } from '../actions/AssignmentActions';

const useUpdateAssignment = (event) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const {
    suggestedGroupId,
    suggestedMemberId,
    suggestedTextClassificationType,
    following,
    assigned,
    messageEventId,
  } = event;
  const { userId, groupId } = useInboxParams();
  const [loading, setLoading] = useState(false);

  function shapeRequest() {
    return {
      patientUserId: userId,
      groupId,
      following,
      assigned,
      ...suggestedGroupId && {
        toUserGroupId: suggestedGroupId,
        suggestedGroupId,
      },
      ...suggestedMemberId && {
        toMemberId: suggestedMemberId,
        suggestedMemberId,
      },
      messageEventId,
      suggestedTextClassificationType,
      assignedTextClassificationType: suggestedTextClassificationType,
      assignmentMethod: 'Suggested Text Classifier',
    };
  }

  function updateNavigation() {
    if (isSameInbox()) {
      history.push({ state: {} });
    } else {
      const newLocation = location.pathname.substring(0, location.pathname.indexOf('/user'));
      history.push(newLocation);
    }
  }

  function handleAssign() {
    const shapedRequest = shapeRequest();
    if (suggestedGroupId) {
      return dispatch(createAssignmentGroup(shapedRequest, true));
    } return dispatch(createAssignmentMember(shapedRequest, true));
  }

  function isSameInbox() {
    const sameGroup = suggestedGroupId && suggestedGroupId === groupId;
    const sameMember = suggestedMemberId === currentUser && location.pathname.includes('direct');
    return sameGroup || sameMember;
  }

  async function handleClick() {
    setLoading(true);
    handleAssign().then(() => {
      setLoading(false);
      updateNavigation();
    });
  }
  return { loading, handleClick };
};

export default useUpdateAssignment;
