import store from 'store';

const localStorage = store.enabled;

export const createCookie = (name, value, days, domain) => {
  let expires = '';
  let cookieVal;
  let cookieDomain = '';

  if (typeof value === 'object') {
    cookieVal = JSON.stringify(value);
  } else {
    cookieVal = value;
  }

  if (days) {
    const date = new Date();

    // Set the expiration date in milliseconds
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

    expires = `; expires=${date.toGMTString()}`;
  }

  if (domain) cookieDomain = `; domain=${domain}`;

  document.cookie = `${name}=${cookieVal}${expires}${cookieDomain}; path=/`;
};

export const readCookie = (name) => {
  const nameStr = `${name}=`;
  const cookieVals = document.cookie.split(';');
  let returnVal;

  for (let i = 0; i < cookieVals.length; i += 1) {
    let c = cookieVals[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }

    if (c.includes(nameStr)) {
      returnVal = c.substring(nameStr.length, c.length);

      if (typeof returnVal === 'object') {
        returnVal = JSON.parse(returnVal);
      }
    }
  }

  return returnVal;
};

export const destroyCookie = (name, domain) => {
  createCookie(name, '', -1, domain);
};

const StorageService = {
  createEntry: (name, value) => {
    localStorage ? store.set(name, value) : createCookie(name, value); // eslint-disable-line no-unused-expressions
  },
  readEntry: (name) => (localStorage ? store.get(name) : readCookie(name)),
  destroyEntry: (name) => {
    localStorage ? store.remove(name) : destroyCookie(name); // eslint-disable-line no-unused-expressions
  },
  destroyAll: () => store.clearAll(),
  destroyAllExcept: (exceptionsArray) => {
    store.each((value, name) => {
      if (!exceptionsArray.includes(name)) {
        store.remove(name);
      }
    });
  },
};

export default StorageService;
