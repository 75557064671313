import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import {
  ResourceGroup,
  UtilitySystem,
} from 'rhinostyle';
import GroupListItem from './GroupItem';
import { fetchGroups } from '../reducers/groupReducer';
import { getNonDynamicGroups } from '../selectors/groupSelectors';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { GROUP_VIEW } from '../constants/UserPermissionsConstants';
import LazyLoadList from './LazyLoadList';

const GroupList = (props) => {
  const dispatch = useDispatch();
  const {
    groupIds,
    handleSelect,
    selectedGroupIds,
    pageNo,
    loading,
    totalCount,
    interfaceMode = null,
    className,
    autoHeight,
    viewSelected,
    handleLoadMore,
    defaultRouteGroupId,
  } = props;

  const loadMore = () => {
    if (handleLoadMore) {
      handleLoadMore();
    } else {
      dispatch(fetchGroups(pageNo + 1));
    }
  };

  const renderGroup = (id) => (
    <GroupListItem
      key={id}
      isSelected={selectedGroupIds?.includes(id)}
      isDefaultRoute={defaultRouteGroupId === id}
      groupId={id}
      handleSelect={handleSelect}
      interfaceMode={interfaceMode}
    />
  );

  const renderNoResults = () => 'No groups';
  return (
    <>
      {userHasAnyOfPermissions([GROUP_VIEW]) && groupIds.length ? (
        <LazyLoadList
          handleLoadMore={loadMore}
          loading={loading}
          className={className || ''}
          autoHeight={autoHeight}
          autoHeightMax={UtilitySystem.config.resourceSizes[autoHeight]}
          disabled={viewSelected || groupIds.length === totalCount}
        >
          <ResourceGroup interfaceMode={interfaceMode}>
            {groupIds.map(renderGroup)}
          </ResourceGroup>
        </LazyLoadList>
      ) : renderNoResults() }
    </>

  );
};

GroupList.propTypes = {
  selectedGroupIds: PropTypes.array,
  groupIds: PropTypes.array,
  handleSelect: PropTypes.func,
  pageNo: PropTypes.number,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
  interfaceMode: PropTypes.string,
  className: PropTypes.string,
  autoHeight: PropTypes.string,
  viewSelected: PropTypes.bool,
  handleLoadMore: PropTypes.func,
  defaultRouteGroupId: PropTypes.number,
};

const mapStateToProps = (state, props) => ({
  loading: state.group.groupPageLoading,
  pageNo: state.group.pageNo,
  totalCount: props.totalCount || state.group.totalCount,
  groupIds: getNonDynamicGroups(state, props),
});

export default connect(mapStateToProps)(GroupList);
