import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Button, Icon } from 'rhinostyle';

class BackButtonContainer extends Component {
  handleBackClick = () => {
    if (this.props.navigateTo) {
      this.props.history.push(this.props.navigateTo);
    } else if (this.props.history.canGoBack) {
      this.props.history.goBack();
    }
  }

  renderButton() {
    const { customClassName } = this.props;
    const backButtonClass = cx('u-m-r-small', {
      [customClassName]: customClassName !== '',
      'u-m-r-small': customClassName === '',
    });
    return (
      <Button
        reset
        className={backButtonClass}
        onClick={this.handleBackClick}
        title="Go back"
      >
        <Icon bump="down" icon="arrow-left" />
      </Button>
    );
  }

  render() {
    const visible = this.props.navigateTo || this.props.history.canGoBack;

    if (visible) return this.renderButton();

    return null;
  }
}

BackButtonContainer.propTypes = {
  navigateTo: PropTypes.string,
  customClassName: PropTypes.string,
  history: PropTypes.object,
};

export default connect(null, null)(BackButtonContainer);
