import React from 'react';
import PropTypes from 'prop-types';
import { Resource, ResourceIntro, ResourceGroup, Scrollbars, UtilitySystem } from 'rhinostyle';

const FacebookPageList = (props) => {
  const {
    availablePages,
    selectedPageId,
    handlePageSelect,
    hasFacebookConnectError,
  } = props;

  const renderPages = (item) => {
    const itemId = item.facebookPageId || item.pageId;
    const isSelected = selectedPageId === itemId;
    return (
      <Resource selected={isSelected} key={item.itemId} onClick={() => handlePageSelect(item)}>
        <ResourceIntro title={item.name} />
      </Resource>
    );
  };

  return (
    <div className="u-m-t">
      {availablePages && availablePages.length > 0 && !hasFacebookConnectError ? (
        <div className="row">
          <div className="column-8@small">
            <div className="search__sub">
              Available Pages
            </div>
            <Scrollbars autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.small} className="resource-group__scroll">
              <ResourceGroup interfaceMode="radio">
                {availablePages.map(renderPages)}
              </ResourceGroup>
            </Scrollbars>
          </div>
        </div>
      ) : <p className="u-text-muted">No Pages Found</p>}
    </div>
  );
};

FacebookPageList.propTypes = {
  availablePages: PropTypes.array.isRequired,
  handlePageSelect: PropTypes.func.isRequired,
  hasFacebookConnectError: PropTypes.string.isRequired,
  selectedPageId: PropTypes.string.isRequired,
};

export default FacebookPageList;
