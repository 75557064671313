import React, { useEffect, useState, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { LoaderCircle } from 'rhinostyle';

import { join } from '../reducers/rhinovideoReducer';
import calculateViewportHeight from '../helpers/ViewportHelpers';
import RhinovideoParticipants from './RhinovideoParticipants';
import RhinovideoActionButtons from './RhinovideoActionButtons';
import RhinovideoTimer from './RhinovideoTimer';
import RhinovideoParticipantsPanel from './RhinovideoParticipantsPanel';
import RhinovideoConferenceJoin from '../assets/audio/rhinovideo-conference-join.mp3';

const RhinovideoConference = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    conferenceAlias,
    consumerToken,
    conferenceJoined,
    userInfo,
    isMember,
    currentVideo,
    isParticipantsPanelOpen,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(join(consumerToken, conferenceAlias, userInfo, isMember));
  }, []);

  useEffect(() => {
    if (conferenceJoined && isLoading) {
      const audio = new Audio(RhinovideoConferenceJoin);
      audio.play().catch((err) => console.error('Auto Play Disabled', err));
      setIsLoading(false);
    }
  }, [currentVideo]);

  useEffect(() => {
    if (!isLoading) {
      calculateViewportHeight();
    }
  }, [isLoading]);

  return (
    <>
      <div className="rhinovideo__wrapper">
        <div className="rhinovideo__conference-container" data-vh="100">
          {conferenceJoined && <RhinovideoTimer type="video" />}
          {conferenceJoined && <RhinovideoActionButtons />}
          <RhinovideoParticipants />
        </div>
        {isParticipantsPanelOpen && <RhinovideoParticipantsPanel />}
        {!conferenceJoined && (
          <div className="app-page-loader rhinovideo__loader">
            <LoaderCircle className="u-text-primary" />
          </div>
        )}
      </div>
    </>
  );
};

RhinovideoConference.propTypes = {
  conferenceAlias: PropTypes.string.isRequired,
  consumerToken: PropTypes.string.isRequired,
  conferenceJoined: PropTypes.bool.isRequired,
  isMember: PropTypes.bool.isRequired,
  isParticipantsPanelOpen: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  currentVideo: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { rhinovideo } = state;

  return {
    conferenceJoined: rhinovideo.conferenceJoined,
    isParticipantsPanelOpen: rhinovideo.isParticipantsPanelOpen,
  };
};

export default connect(mapStateToProps)(RhinovideoConference);
