/* global FB */

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authFacebook } from '../reducers/facebookReducer';
import { authInstagram } from '../reducers/instagramReducer';

const useFacebookLogin = ({ channelType, selectedPageId, mode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();
  const availablePages = useSelector((state) => state[channelType]?.availablePages);
  const loading = useSelector((state) => state[channelType]?.loading);
  const organizationId = useSelector((state) => state.auth.currentOrg);
  function getPermissions() {
    const permissions = ['pages_show_list', 'pages_manage_metadata', 'pages_messaging'];
    if (channelType === 'instagram') {
      return [...permissions, 'instagram_basic', 'instagram_manage_messages'];
    }
    return [...permissions, 'business_management'];
  }

  const options = {
    scope: getPermissions(),
    return_scopes: true,
  };
  if (channelType === 'instagram' && mode === 'create') {
    options.extras = { setup: { channel: 'IG_API_ONBOARDING' } };
  }

  const handleLogin = () => {
    FB.login((response) => {
      setIsAuthenticated(response.status === 'connected');
      const payload = {
        organizationId,
        pageAccessToken: response.authResponse?.accessToken,
        ...mode === 'edit' && selectedPageId && {
          [`${channelType}PageId`]: selectedPageId,
        },
      };
      if (channelType === 'instagram') {
        dispatch(authInstagram(payload));
      } else {
        dispatch(authFacebook(payload));
      }
    }, options);
  };
  return { handleLogin, isAuthenticated, availablePages, loading };
};

export default useFacebookLogin;
