import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'rhinostyle';
import PrescriptionCampaignFormMessage from './PrescriptionCampaignFormMessage';

const PrescriptionCampaignFormMessages = ({
  campaignFormInputs,
  handleChange,
  handleAddCampaignMessage,
  handleRemoveCampaignMessage,
  errors,
}) => {
  const campaignMessages = campaignFormInputs?.messages.filter((campaignMessage) => !campaignMessage.deleted) || [];
  return (
    <div className="u-m-t-large">
      {campaignMessages.map((campaignMessage) => (
        <PrescriptionCampaignFormMessage
          key={campaignMessage.key}
          handleChange={handleChange}
          handleRemoveCampaignMessage={handleRemoveCampaignMessage}
          errors={errors}
          campaignMessage={campaignMessage}
        />
      ))}
      <div className="u-flex-row u-flex-align-items-center u-flex u-text-uppercase u-m-t-large">
        <Button
          onClick={handleAddCampaignMessage}
          iconOnly
          type="secondary"
          title="Create Group"
          className="u-border-radius u-m-r"
          data-feature-tag="prescriptionCampaignMessageAdd"
        >
          <Icon icon="add" />
        </Button>
        Create Another Notification Message
      </div>
    </div>
  );
};

PrescriptionCampaignFormMessages.propTypes = {
  campaignFormInputs: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleAddCampaignMessage: PropTypes.func.isRequired,
  handleRemoveCampaignMessage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default PrescriptionCampaignFormMessages;
