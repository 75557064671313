import { createSelector } from '@reduxjs/toolkit';
import { PhoneHelpers, UserHelpers } from '../helpers';
import { getActiveUser } from './userSelectors';
import { CALL_STATUS_ACTIVE, CALL_STATUS_COMPLETED } from '../constants/RhinocallConstants';
import { getRhinocallChannelIds } from './channelSelectors';
import { CALL_EDIT } from '../constants/UserPermissionsConstants';
import { isMobile } from '../helpers/BrowserHelpers';

const getChannels = (state) => state.channel.channels;
const getPhones = (state) => state.phone.phones;
const getActiveCalls = (state) => state.rhinocall.calls;
const getCallIds = (state) => state.rhinocall.activeCallIds;
const getCallId = (state) => state.rhinocall.callId;
const getUsers = (state) => state.user.users;
const getActiveFromChannelId = (state) => state.inbox.activeFromChannelId;
const getActiveUserId = (state) => state.inbox.userId;
const getLoggedInUserId = (state) => state.auth.currentUser;
const getAudioDevices = (state) => state.rhinocall.audioDevices;
const getIsCallEnabled = (state) => state.rhinocall?.org?.isCallEnabled;
const getIsWebRTCInitiated = (state) => state.rhinocall.isWebRTCInitiated;

export const getMemberCall = createSelector(
  [getActiveCalls, getCallIds, getCallId, getLoggedInUserId],
  (activeCalls, activeCallIds, callId, currentUserId) => {
    const activeCallId = callId || activeCallIds?.find((id) => activeCalls[id]?.userId === currentUserId &&
      activeCalls[id].status !== CALL_STATUS_COMPLETED && (activeCalls[id]._id || activeCalls[id].optimisticId));
    if (activeCallId) return activeCalls[activeCallId];
    return null;
  },
);

export const getActiveCall = createSelector(
  [getActiveCalls, getCallId],
  (activeCalls, callId) => activeCalls[callId],
);

export const getHasActiveMobileCall = createSelector(
  [getMemberCall],
  (memberCall) => isMobile() && !!memberCall?.memberNumber,
);

export const getIsWebRTCCallConnected = createSelector(
  [getMemberCall, getIsWebRTCInitiated],
  (memberCall, isWebRTCInitiated) => memberCall && isWebRTCInitiated,
);

export const getCallContactInfo = createSelector(
  [getActiveCall, getActiveUserId, getUsers],
  (memberCall, activeUserId, users) => {
    if (users && memberCall?.contactId) {
      return users[memberCall.contactId];
    } return {};
  },
);

export const getIsContactOnAnyCall = createSelector(
  [getActiveCalls, getCallIds, getActiveUserId],
  (activeCalls, activeCallIds, activeUserId) => {
    const callIndex = activeCallIds?.findIndex((id) => activeCalls[id]?.contactId === activeUserId &&
      activeCalls[id].status !== CALL_STATUS_COMPLETED);
    return callIndex > -1;
  },
);

export const getRhinocallToChannelOptions = createSelector(
  [getActiveUser, getPhones],
  (user, phones) => {
    if (!user?.phones?.length) return [];
    return user.phones.filter((id) => phones[id]?.ownerId === user.id && PhoneHelpers.isPhoneNumberValid(phones[id].value))
      .map((id) => ({
        id: `sms-${id}`,
        name: PhoneHelpers.formatPhone(phones[id].value),
        route: PhoneHelpers.formatTypes(phones[id]),
        value: `${PhoneHelpers.formatPhone(phones[id].value)} (${PhoneHelpers.formatTypes(phones[id])})`,
      }));
  },
);

export const getRhinocallFromChannelOptions = createSelector(
  [getChannels, getRhinocallChannelIds, getActiveFromChannelId],
  (channels, rhinocallChannelIds, activeFromChannelId) => {
    if (!rhinocallChannelIds.length) return [];
    const sortedChannelIds = (rhinocallChannelIds.includes(activeFromChannelId)) ? [...new Set([activeFromChannelId, ...rhinocallChannelIds])] : rhinocallChannelIds;
    return sortedChannelIds.map((id) => ({ id, value: `${channels[id].name} ${channels[id].route?.name ? `(Route: ${channels[id].route.name})` : ''}` }));
  },
);

export const getRhinocallAudioInputOptions = createSelector(
  [getAudioDevices],
  (audioDevices) => {
    const inputDevices = audioDevices.filter((device) => device.kind === 'audioinput');
    if (!inputDevices.length) return [];
    return inputDevices.map((device) => ({ id: device.deviceId, value: `${device.label}` || '' }));
  },
);

export const getRhinocallAudioOutputOptions = createSelector(
  [getAudioDevices],
  (audioDevices) => {
    const outputDevices = audioDevices.filter((device) => device.kind === 'audiooutput');
    if (!outputDevices.length) return [];
    return outputDevices.map((device) => ({ id: device.deviceId, value: `${device.label}` || '' }));
  },
);

export const getIsCallConnected = createSelector(
  [getMemberCall],
  (memberCall) => memberCall?.status === CALL_STATUS_ACTIVE || false,
);

export const getMemberCallStatusData = createSelector(
  [getMemberCall],
  (memberCall) => {
    if (memberCall) return { status: memberCall.status, contactId: memberCall.contactId };
    return {};
  },
);

export const getMemberCallContactId = createSelector(
  [getMemberCall],
  (memberCall) => memberCall?.contactId || null,
);

export const getIsMemberCallCreated = createSelector(
  [getMemberCall],
  (memberCall) => !!memberCall,
);

export const getIsRhinocallStartButtonEnabled = createSelector(
  [getIsCallEnabled, getRhinocallChannelIds, getActiveUser, getPhones],
  (isCallEnabled, rhinocallChannelIds, activeUser, phones) => {
    const userOwnsPhone = activeUser?.phones.some((id) => phones[id].ownerId === activeUser.id && !phones[id].hasOptedOut);
    return !!(isCallEnabled && UserHelpers.userHasAnyOfPermissions([CALL_EDIT]) && rhinocallChannelIds?.length && userOwnsPhone);
  },
);

export const getContactCallStatus = createSelector(
  [getMemberCall, getIsContactOnAnyCall, getActiveUserId],
  (memberCall, isContactOnAnyCall, activeUserId) => {
    if ((memberCall || isContactOnAnyCall) && memberCall?.contactId !== activeUserId) {
      return 'In Progress';
    } if (isContactOnAnyCall && memberCall?.contactId === activeUserId) {
      if (memberCall.status === CALL_STATUS_ACTIVE) {
        return 'Connected';
      } return 'Waiting...';
    } return null;
  },
);
