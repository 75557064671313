import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { UtilitySystem } from 'rhinostyle';
import { isMobile } from '../helpers/BrowserHelpers';
import { setIsDragging } from '../reducers/rhinocallReducer';

export default function DraggableContainer({ children, className }) {
  const dispatch = useDispatch();
  const isDragging = useSelector((state) => state.rhinocall.isDragging);
  const [originalPosition, setOriginalPosition] = useState({ x: 0, y: 0 });
  const [translate, setTranslate] = useState({ x: null, y: null });
  const [lastTranslate, setLastTranslate] = useState({ x: 0, y: 0 });
  const draggableRef = useRef();
  const boundsRef = useRef();
  const style = translate.x === null ? {} : { transform: `translate(${translate.x}px, ${translate.y}px)` };

  useEffect(() => {
    // calculate mobile position in px here so we can adjust on drag
    if (window.innerWidth < Number(UtilitySystem.config.breakpoints.small.replace('px', ''))) {
      const elementBounds = draggableRef.current.getBoundingClientRect();
      const transformX = (window.innerWidth - elementBounds.width) / 2;
      const transformY = (window.innerHeight - elementBounds.height) / 2;
      setTranslate({
        x: transformX,
        y: -transformY,
      });
      setLastTranslate({
        x: transformX,
        y: -transformY,
      });
    }
  }, []);

  useEffect(() => {
    const moveEvent = isMobile() ? 'touchmove' : 'mousemove';
    const releaseEvent = isMobile() ? 'touchend' : 'mouseup';
    window.addEventListener(moveEvent, handleMove);
    window.addEventListener(releaseEvent, handleRelease);
    window.addEventListener('resize', handleResizeScreen);

    // cleanup
    return () => {
      window.removeEventListener(moveEvent, handleMove);
      window.removeEventListener(releaseEvent, handleRelease);
      window.removeEventListener('resize', handleResizeScreen);
    };
  }, [isDragging, translate]);

  function handleResizeScreen() {
    setBounds();
    setTranslate((prevState) => ({
      x: getBoundsX(prevState.x),
      y: getBoundsY(prevState.y),
    }));
  }

  function setBounds() {
    const elementBounds = draggableRef.current.getBoundingClientRect();
    const boundsObj = {
      maximumTranslateRight: (translate.x ?? 0) + window.innerWidth - elementBounds.left - elementBounds.width,
      maximumTranslateTop: (translate.y ?? 0) - elementBounds.top,
      maximumTranslateLeft: (translate.x ?? 0) - elementBounds.left,
      maximumTranslateBottom: elementBounds.bottom - window.innerHeight - (translate.y ?? 0),
    };
    boundsRef.current = boundsObj;
  }

  function handleStart(event) {
    setBounds();
    const { touchX, touchY } = getPosition(event);
    setOriginalPosition({
      x: touchX,
      y: touchY,
    });
    dispatch(setIsDragging(true));
  }

  function handleMove(event) {
    if (!isDragging) {
      return;
    }
    const { touchX, touchY } = getPosition(event);
    setTranslate({
      x: getBoundsX(touchX - originalPosition.x + lastTranslate.x),
      y: getBoundsY(touchY - originalPosition.y + lastTranslate.y),
    });
  }

  function getBoundsX(translateX) {
    const currentBounds = boundsRef.current;
    if (translateX > currentBounds.maximumTranslateRight) {
      return currentBounds.maximumTranslateRight;
    }
    if (translateX < currentBounds.maximumTranslateLeft) {
      return currentBounds.maximumTranslateLeft;
    }
    return translateX;
  }

  function getBoundsY(translateY) {
    const currentBounds = boundsRef.current;
    if (translateY < currentBounds.maximumTranslateTop) {
      return currentBounds.maximumTranslateTop;
    }
    if (translateY > currentBounds.maximumTranslateBottom) {
      return currentBounds.maximumTranslateBottom;
    }
    return translateY;
  }

  function handleRelease() {
    setOriginalPosition({
      x: 0,
      y: 0,
    });
    setLastTranslate({
      x: translate.x,
      y: translate.y,
    });
    dispatch(setIsDragging(false));
  }

  function getPosition(event) {
    if (isMobile() && event.type.includes('touch')) {
      const fallbackEvent = event.type === 'touchmove' ? event.changedTouches?.[0] : event.targetTouches?.[0];
      const touch = event.touches[0] || fallbackEvent;
      return { touchX: touch.pageX, touchY: touch.pageY };
    } return { touchX: event.clientX, touchY: event.clientY };
  }

  return (
    <div
      className={`draggable-container ${className || ''}`}
    >
      <div
        className="draggable-child"
        style={style}
        ref={draggableRef}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
      >
        {children}
      </div>
    </div>
  );
}

DraggableContainer.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
};
