import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  RhinoSwitch,
  FormLabel,
  Input,
  Dropdown,
  DropdownMenuItem,
  RadioGroup,
  Radio,
} from 'rhinostyle';
import VariableMessage from '../components/VariableMessage';
import { AUTOMATED_MESSAGE_EDIT, AUTOMATED_MESSAGE_DELETE } from '../constants/UserPermissionsConstants';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { fetchOffices } from '../reducers/officeReducer';
import { fetchFormTemplates } from '../reducers/formReducer';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { DateHelpers, ValidationHelpers } from '../helpers';
import { processCampaignAttachments } from '../helpers/AttachmentHelpers';
import {
  HIPAA_CAMPAIGN_TEMPLATE,
  HIPAA_CAMPAIGN_VARIABLE_OPTIONS,
} from '../constants/VariableMessageConstants';
import {
  TYPE_HIPAA_CONSENT_DECLINED,
  TYPE_HIPAA_CONSENT_PENDING,
} from '../constants/Types';
import BackButton from '../components/BackButton';
import PageLoader from '../components/PageLoader';
import FormActionButtons from '../components/FormActionButtons';
import {
  useGetHipaaCampaignsQuery,
  useCreateHipaaCampaignMutation,
  useUpdateHipaaCampaignMutation,
  useDeleteHipaaCampaignMutation,
} from '../services/hipaaCampaignService';
import NotificationService from '../services/NotificationService';
import ExpandableBox from '../components/ExpandableBox';

const HipaaCampaignFormView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const pageContainer = useRef();
  const { id: organizationId, isOfficeVCardEnabled } = useSelector(getCurrentOrg);
  const isRhinoformEnabled = useSelector((state) => state.form.org?.isRhinoformEnabled);
  const { hipaaCampaignId } = params;
  const { data: hipaaCampaigns, isLoading: loading } = useGetHipaaCampaignsQuery({ organizationId });
  const hipaaCampaign = hipaaCampaigns?.find((campaign) => campaign.id === Number(hipaaCampaignId)) || null;
  const hipaaCampaignsUrl = '/settings/organization/hipaa-campaigns';
  const defaultCampaign = {
    enabled: !!hipaaCampaign?.enabled || true,
    name: hipaaCampaign?.name || '',
    default: !!hipaaCampaign?.default,
    attachments: hipaaCampaign?.attachments || [],
    toggledOnTimestamp: hipaaCampaign?.toggledOnTimestamp,
    resendDeliveryWindow: hipaaCampaign?.resendDeliveryWindow ? DateHelpers.convertHourStringToDays(hipaaCampaign.resendDeliveryWindow) : '30',
    hipaaStatusTypeId: hipaaCampaign?.hipaaStatusTypeId,
    template: hipaaCampaign?.template || HIPAA_CAMPAIGN_TEMPLATE,
  };
  const [campaignInputs, setCampaignInputs] = useState(defaultCampaign);
  const [errors, setErrors] = useState({});
  const [pageLoading, setPageLoading] = useState(hipaaCampaignId ? !hipaaCampaign : false);
  const [formInProgress, setFormInProgress] = useState(false);
  const [createCampaign] = useCreateHipaaCampaignMutation();
  const [deleteCampaign] = useDeleteHipaaCampaignMutation();
  const [updateCampaign] = useUpdateHipaaCampaignMutation();

  useEffect(() => {
    if (isRhinoformEnabled) {
      dispatch(fetchFormTemplates());
    }
    if (isOfficeVCardEnabled) {
      dispatch(fetchOffices());
    }
  }, [isRhinoformEnabled]);

  useEffect(() => {
    if (!loading && hipaaCampaign) {
      setCampaignInputs({
        enabled: !!hipaaCampaign.enabled || true,
        name: hipaaCampaign.name,
        default: !!hipaaCampaign.default,
        attachments: hipaaCampaign.attachments,
        toggledOnTimestamp: hipaaCampaign.toggledOnTimestamp,
        resendDeliveryWindow: DateHelpers.convertHourStringToDays(hipaaCampaign.resendDeliveryWindow),
        hipaaStatusTypeId: hipaaCampaign.hipaaStatusTypeId,
        template: hipaaCampaign.template || HIPAA_CAMPAIGN_TEMPLATE,
      });
      setPageLoading(false);
    }
  }, [hipaaCampaignId, loading]);

  const handleRouteChange = (campaignId) => {
    history.push(`${hipaaCampaignsUrl}${campaignId ? `/${campaignId}` : ''}`);
  };

  const handleChange = (fieldName, value) => {
    if (fieldName === 'attachments') {
      const { attachments } = campaignInputs;
      const newAttachments = value.filter((v) => {
        const existingAttachment = attachments.find((a) => (a.attachmentUrl === v.attachmentUrl));
        return !existingAttachment;
      });
      if (newAttachments.length === 0) {
        setCampaignInputs((current) => ({ ...current, attachments: value }));
      } else {
        setCampaignInputs((current) => ({ ...current, attachments: [...attachments, ...newAttachments] }));
      }
    } else {
      setCampaignInputs((current) => ({ ...current, [fieldName]: value }));
    }
  };

  const handleSubmit = async () => {
    setErrors({});
    const saveErrors = ValidationService(ValidationShapers.shapeHipaaCampaignMessage({ ...campaignInputs }));
    const errorCount = Object.keys(saveErrors).length;
    if (errorCount > 0) {
      setErrors(saveErrors);
      ValidationHelpers.handleValidationErrors(errors, pageContainer.current);
    } else {
      const notificationType = hipaaCampaignId ? 'updateHipaaCampaign' : 'createHipaaCampaign';
      setFormInProgress(true);
      const resendDeliveryWindow = DateHelpers.convertDaysToHours(campaignInputs.resendDeliveryWindow);
      const payload = {
        ...hipaaCampaignId && {
          id: Number(hipaaCampaignId),
        },
        ...campaignInputs,
        attachments: processCampaignAttachments(campaignInputs.attachments),
        resendDeliveryWindow,
      };
      const response = hipaaCampaignId ?
        await updateCampaign({ organizationId, data: payload, hipaaCampaignId }) :
        await createCampaign({ organizationId, data: payload });
      setFormInProgress(false);
      if (response?.data) {
        NotificationService(notificationType, { status: 200 });
        handleRouteChange(hipaaCampaignId || response.data.id);
      } else if (response.error) {
        NotificationService(notificationType, response.error);
      }
    }
  };

  async function handleConfirmDeleteRequest() {
    const deleteResponse = await deleteCampaign({ organizationId, hipaaCampaignId });
    if (deleteResponse?.data) {
      NotificationService('deleteHipaaCampaign', { status: 200 });
      handleRouteChange();
    } else {
      NotificationService('deleteHipaaCampaign', deleteResponse.error);
    }
  }

  const reminderDeliveryText = (
    <>
      <span className="u-font-weight-bold u-p-r-xsmall">Reminder Delivery Setting:</span>
      {`${campaignInputs?.resendDeliveryWindow} Days`}
    </>
  );

  if (pageLoading) {
    return <PageLoader />;
  }

  return (
    <div className="app-page__container" ref={pageContainer}>
      <div className="app-page__container__inner appointment-reminder-form">
        <div className="app-page__header">
          <div className="app-page__header__title">
            <BackButton navigateTo={hipaaCampaignsUrl} />
            {`${hipaaCampaignId ? 'Edit' : 'Create'} Campaign`}
          </div>
        </div>
        <div className="box">
          <div className="box__title-wrapper">
            <div className="box__title">Message Details</div>
          </div>
          <div className="appointment-reminder-form__box-wrapper">
            <FormLabel
              id="name"
              className="variable-message__label u-m-t u-m-b-small"
              required
            >
              Name
            </FormLabel>
            <Input
              name="name"
              initialValue={campaignInputs.name}
              validationMessage={errors?.name}
              className="u-m-b"
              onChange={handleChange}
              explanationMessage="Message name must be unique."
              dataFeatureTag="hipaaCampaignName"
            />
          </div>
        </div>
        <div className="box">
          <div className="box__title-wrapper">
            <div className="box__title">Campaign Type</div>
          </div>
          <RadioGroup
            blockGroup
            selectedValue="administrative"
          >
            <Radio
              className="hipaa-campaign-type"
              value="administrative"
              label={(
                <> Administrative
                  <span className="hipaa-campaign-type__name">HIPAA Consent Status</span>
                </>
                  )}
              disabled
            />
          </RadioGroup>

        </div>
        <ExpandableBox
          title="Campaign Details"
          subtitle="Text message reminders will be delivered to patients prior to or after their appointment."
          dataFeatureTag="hipaa-campaign-details-expand"
          description={reminderDeliveryText}
        >
          <div className="u-p-y-small">
            <hr className="u-p-b u-m-t-0" />
            <div className="box__title-wrapper">
              <div className="box__title">Candidates</div>
              <div className="box__subtitle">Who will be placed in the campaign</div>
              <div className="u-text-accent u-m-t-xsmall u-text-italic u-text-small">Please Note: HIPAA consent messages are only sent and applied to Phone Owners&apos; HIPAA consent status</div>
            </div>
            <RadioGroup
              name="listType"
              className="u-m-b"
              selectedValue="dynamic"
              inline
              disabled
            >
              <Radio value="static" label="Static List" disabled />
              <Radio value="dynamic" label="Dynamic List" disabled />
            </RadioGroup>
            <div className="u-flex u-flex-direction-row u-flex-grow u-flex-wrap">
              <div className="u-m-r-small">
                <FormLabel
                  id="When"
                  className="variable-message__label u-m-t u-m-b-small"
                  required
                >
                  When
                </FormLabel>
                <Dropdown
                  size="large"
                  wide
                  type="input"
                  label="HIPAA Consent"
                  className="hipaa-campaign-dropdown"
                  activeKey={campaignInputs.hipaaStatusTypeId}
                  disabled
                  hideCaret
                >
                  <DropdownMenuItem
                    label="HIPAA Consent"
                    id="HIPAA Consent"
                  />
                </Dropdown>
              </div>
              <div className="u-m-l-small">
                <FormLabel
                  id="Is"
                  className="variable-message__label u-m-t u-m-b-small"
                  required
                >
                  Is
                </FormLabel>

                <Dropdown
                  size="large"
                  wide
                  type="input"
                  name="hipaaStatusTypeId"
                  className="hipaa-campaign-dropdown u-text-left"
                  activeKey={campaignInputs.hipaaStatusTypeId}
                  hideCaret
                  onSelexct={(value) => handleChange('hipaaStatusTypeId', value)}
                >
                  <DropdownMenuItem
                    label="Unknown"
                    key={TYPE_HIPAA_CONSENT_PENDING}
                    value={TYPE_HIPAA_CONSENT_PENDING}
                    id={TYPE_HIPAA_CONSENT_PENDING}
                    onClick={() => handleChange('hipaaStatusTypeId', TYPE_HIPAA_CONSENT_PENDING)}
                  />
                  <DropdownMenuItem
                    label="Denied"
                    value={TYPE_HIPAA_CONSENT_DECLINED}
                    key={TYPE_HIPAA_CONSENT_DECLINED}
                    id={TYPE_HIPAA_CONSENT_DECLINED}
                    onClick={() => handleChange('hipaaStatusTypeId', TYPE_HIPAA_CONSENT_DECLINED)}
                  />
                </Dropdown>
              </div>
            </div>
            <hr className="u-m-y-large" />
            <div className="box__title-wrapper">
              <div className="box__title">Repeat Timing</div>
              <div className="box__subtitle">Establish when campaign message will be re-sent to candidates that have not replied</div>
            </div>
            <FormLabel
              id="renderHipaaCampignSettings"
              className="variable-message__label u-m-t u-m-b-small"
              required={campaignInputs.enabled}
            >
              Reminder Delivery Setting (Days)
            </FormLabel>
            <Input
              name="resendDeliveryWindow"
              initialValue={campaignInputs.resendDeliveryWindow.toString()}
              format={{
                numeral: true,
                numeralPositiveOnly: true,
                numeralDecimalScale: 0,
              }}
              className="am__hours-input u-m-r u-m-b u-inline-block"
              onChange={handleChange}
              required
              dataFeatureTag="resendDeliveryWindow"
              validationMessage={errors.resendDeliveryWindow}
            />
            <strong data-cypress="appointmentReminder1DeliveryHoursFormatted">
              days
            </strong>
            <hr className="u-m-y-large" />

            <div className="box__title-wrapper">
              <div className="box__title">Message</div>
              <div className="box__subtitle">Message to be sent based on above preferences</div>
            </div>
            <RhinoSwitch
              name="enabled"
              isChecked={campaignInputs.enabled}
              onChange={handleChange}
              label="Enable HIPAA Campaign"
              dataFeatureTag="edit-appointment-reminder_toggle-created-message"
            />
            {campaignInputs.enabled ? (
              <VariableMessage
                composeLabel="Message"
                className="u-m-t-large"
                handleTemplateChange={handleChange}
                variableOptions={HIPAA_CAMPAIGN_VARIABLE_OPTIONS}
                template={campaignInputs.template}
                required={campaignInputs.enabled}
                validationMessage={errors.template}
                name="template"
                data-cypress="hipaaCampaignTemplate"
                preventFocusOnLoad
                attachments={campaignInputs.attachments}
                initialValue={hipaaCampaign?.template || HIPAA_CAMPAIGN_TEMPLATE}
              />
            ) : null}
          </div>
        </ExpandableBox>
        <div className="u-m-t-large">
          <FormActionButtons
            formInProgress={formInProgress}
            handleConfirmDeleteRequest={handleConfirmDeleteRequest}
            handleSubmit={handleSubmit}
            name="HIPAA Campaign"
            shortName="Campaign"
            deletePermissions={[AUTOMATED_MESSAGE_DELETE]}
            editPermissions={[AUTOMATED_MESSAGE_EDIT]}
            dataFeatureTag="hipaa-campaigns_"
            editMode={!!hipaaCampaignId}
          />
        </div>
      </div>
    </div>
  );
};

export default HipaaCampaignFormView;
