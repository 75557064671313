import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import NotificationService from '../services/NotificationService';

import * as InboxActionTypes from '../constants/InboxActionTypes';
import * as UserActionTypes from '../constants/UserActionTypes';
import * as SavedContentActionTypes from '../constants/SavedContentActionTypes';
import { setError } from './uiReducer';

// SLICE
const facebookSlice = createSlice({
  name: 'FACEBOOK',
  initialState: {
    availablePages: [],
    facebooks: {},
    facebookIds: [],
    loading: false,
  },
  reducers: {
    receiveFacebooks: (state, action) =>
      ({
        ...state,
        facebooks: {
          ...state.facebooks,
          ...action.payload.facebooks,
        },
        facebookIds: [...state.facebookIds, ...action.payload.facebookIds],
      }),
    receiveFacebookPages: (state, action) =>
      ({
        ...state,
        availablePages: action.payload,
        loading: false,
      }),
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [InboxActionTypes.receiveInboxThreadView]: receiveFacebooksData,
    [InboxActionTypes.receiveWebSocketEvent]: receiveFacebooksData,

    [UserActionTypes.receiveUsers]: receiveFacebooksData,
    [UserActionTypes.receiveUsersSearch]: receiveFacebooksData,
    [UserActionTypes.receiveContactUsers]: receiveFacebooksData,
    [UserActionTypes.receiveConnectedPartySearch]: receiveFacebooksData,
    [UserActionTypes.receiveUser]: receiveFacebooksData,
    [UserActionTypes.receiveCreateUser]: receiveFacebooksData,
    [UserActionTypes.receiveUpdateUser]: receiveFacebooksData,
    [UserActionTypes.receiveContactList]: receiveFacebooksData,

    [SavedContentActionTypes.receiveEventsForSavedContent]: receiveFacebooksData,
  },
});
export default facebookSlice.reducer;

// ACTIONS
export const { receiveFacebooks, receiveFacebookPages, setLoading } = facebookSlice.actions;

// REDUCER HELPERS

function receiveFacebooksData(state, action) {
  return {
    ...state,
    facebooks: {
      ...state.facebooks,
      ...action.payload.facebooks,
    },
    facebookIds: [...state.facebookIds, ...action.payload.facebookIds],
  };
}

// THUNKS -- ASYNC ACTION CREATORS

export function authFacebook(payload) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return axios.post(`${process.env.REACT_APP_V3_BASE_URL}/facebook/authenticate`, payload)
      .then((response) => {
        dispatch(receiveFacebookPages(response.data?.objects));
        if (payload.facebookPageId) NotificationService('facebookAuth', response); // only show if re-authing

        return response.data?.objects;
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.error(err.response || err);

        // Custom message to get around FB's
        const customResponse = err.response || 'Something went wrong while re-authorizing this Facebook channel.';

        dispatch(setError(err.response || err));
        if (payload.facebookPageId) NotificationService('facebookAuth', customResponse); // only show toast error when re-authing
      });
  };
}
