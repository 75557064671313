import axios from 'axios';
import moment from 'moment-timezone';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';
import { NotificationActions } from 'rhinostyle';

import { AudioHelpers, DataHelpers, UIHelpers, UserHelpers } from '../helpers';
import OutboundPostMessageService from '../services/OutboundPostMessageService';
import NotificationService from '../services/NotificationService';
import { AppConstants } from '../constants';
import { event, group, user, eventMention } from '../actions/NormalizrSchema';
import { setError, receiveChatMentionReadStatus, fetchInboxSections, receiveInboxSections } from './uiReducer';
import * as InboxReducer from './inboxReducer';
import * as UserReducer from './userReducer';
import * as GroupReducer from './groupReducer';
import { MessageStatus } from '../constants/v3';
import { getNetworkErroMessage } from '../helpers/ErrorCodeHelpers';

const { THREAD_SIZE, EVENT_SIZE } = AppConstants;

// SLICE

const eventSlice = createSlice({
  name: 'CHAT',
  initialState: {
    pageLoading: false,
    inboxLoading: false,
    threadLoading: false,
    inboxPageNo: 0,
    threadPageNo: 0,
    threadSearchPageNo: 0,
    groupId: null,
    userId: null,
    events: {},
    eventIds: [],
    inboxEventIds: [],
    inboxThreadIds: [],
    eventMentions: {},
  },
  reducers: {
    resetEventData: (state) => ({
      ...state,
      userId: null,
      groupId: null,
      events: {},
      eventIds: [],
      inboxEventIds: [],
    }),
    receiveChatInbox: receiveInboxData,
    receiveChatThread: receiveThreadData,
    replaceChatThread: receiveThreadViewData,
    receiveChatThreadUserView: receiveThreadViewData,
    receiveChatThreadGroupView: receiveThreadViewData,
    receiveWebSocketEvent: receiveEventData,
    receiveCreateChatEvent: receiveEventData,
    receiveCreateTempChatEvent: receiveEventData,
    receiveWebSocketEventStatus: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        [action.payload.eventId]: action.payload.event,
      },
    }),
    requestChatViewData: (state) => ({
      ...state,
      pageLoading: true,
      threadLoading: true,
    }),
    requestChatInboxData: (state) => ({
      ...state,
      inboxLoading: true,
    }),
    requestChatThreadData: (state) => ({
      ...state,
      threadLoading: true,
    }),
    setChatContext: (state, action) => ({
      ...state,
      userId: action.payload.userId,
      groupId: action.payload.groupId,
    }),
    receiveEventStatus: (state, action) => {
      const currentEvent = state.events[action.payload.eventId];
      if (currentEvent) {
        currentEvent.status = action.payload.status;
      }
    },
  },
});

export default eventSlice.reducer;

// ACTIONS
export const {
  resetEventData,
  receiveChatInbox,
  receiveChatThread,
  receiveChatThreadUserView,
  receiveChatThreadGroupView,
  receiveWebSocketEvent,
  receiveCreateChatEvent,
  receiveCreateTempChatEvent,
  receiveWebSocketEventStatus,
  requestChatViewData,
  requestChatInboxData,
  requestChatThreadData,
  setChatContext,
  replaceChatThread,
  receiveEventStatus,
} = eventSlice.actions;

// REDUCER HELPERS

const sortInboxIdsByTimestampDesc = (events, inboxEventIds) =>
  inboxEventIds.sort((a, b) => moment(events[b].timestamp).diff(moment(events[a].timestamp)));

function receiveInboxData(state, action) {
  return {
    ...state,
    events: action.payload.events,
    inboxEventIds: sortInboxIdsByTimestampDesc(action.payload.events, action.payload.inboxEventIds),
    inboxThreadIds: action.payload.inboxThreadIds,
    inboxPageNo: action.payload.pageNo,
    pageLoading: false,
    inboxLoading: false,
  };
}

const sortEventIdsByTimestampDesc = (events, eventIds) =>
  eventIds.sort((a, b) => moment(events[a].timestamp).diff(moment(events[b].timestamp)));

function receiveThreadData(state, action) {
  const mergedEvents = {
    ...state.events,
    ...action.payload.events,
  };
  const mergedMentions = {
    ...state.eventMentions,
    ...action.payload.eventMentions,
  };

  const mergedEventIds = [...new Set([...action.payload.eventIds, ...state.eventIds])];
  return {
    ...state,
    events: mergedEvents,
    eventIds: sortEventIdsByTimestampDesc(mergedEvents, mergedEventIds),
    threadLoading: false,
    threadPageNo: action.payload.pageNo,
    eventMentions: mergedMentions,
  };
}

function receiveThreadViewData(state, action) {
  return {
    ...state,
    events: action.payload.events,
    eventIds: sortEventIdsByTimestampDesc(action.payload.events, action.payload.eventIds),
    pageLoading: false,
    threadLoading: false,
    threadPageNo: action.payload.pageNo,
    eventMentions: action.payload.eventMentions,
  };
}

function receiveEventData(state, action) {
  /**
   * Find the event currently in inboxEventIds that matches the thread of the incoming event.
   * This id is to be replaced with the incoming event id so we only get the latest event for each thread.
   * Can be found via a matching group or user.
  */
  const currentDirectThreadId = state.inboxEventIds.find((id) => (
    state.events[id]
    && action.payload.event[action.payload.eventId]
    && state.events[id].user
    && state.events[id].user === action.payload.event[action.payload.eventId].user
  ));

  const currentMultiMemberGroupThreadId = state.inboxEventIds.find((id) => (
    state.events[id]
    && action.payload.event[action.payload.eventId]
    && state.events[id].group
    && state.events[id].group === action.payload.event[action.payload.eventId].group
  ));

  const isEventFromNewThread = !currentMultiMemberGroupThreadId && !currentDirectThreadId;

  // If payload has optimisticId, it was sent originally optimistically. We filter it out of the original list.
  const tempEventId = action.payload.event[action.payload.eventId].optimisticId;

  const optimisticEventIds = [...state.eventIds, action.payload.eventId].filter((i) => typeof i !== 'number' && tempEventId !== i);
  const realEventIds = [...state.eventIds, action.payload.eventId].filter((i) => typeof i === 'number');

  const mergedEvents = {
    ...state.events,
    [action.payload.eventId]: action.payload.event[action.payload.eventId],
  };
  if (tempEventId) {
    delete mergedEvents[tempEventId];
  }

  return ({
    ...state,
    events: mergedEvents,
    eventIds: [...new Set([...sortEventIdsByTimestampDesc(mergedEvents, realEventIds), ...optimisticEventIds])],
    inboxEventIds: [...new Set([
      action.payload.eventId,
      ...state.inboxEventIds.filter((i) => isEventFromNewThread || (currentDirectThreadId && i !== currentDirectThreadId) || (currentMultiMemberGroupThreadId && i !== currentMultiMemberGroupThreadId)), // eslint-disable-line max-len
    ])],
  });
}

// THUNKS -- ASYNC ACTION CREATORS

export function fetchChatInbox({ pageNumber = 0, sort = 'descending' }) {
  const pageSize = THREAD_SIZE;
  const url = `/chat/inbox?pageNo=${pageNumber}&pageSize=${pageSize}&sort=${sort}&minimal=0`;

  return (dispatch) => {
    dispatch(requestChatInboxData());

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, [event]);
        if (normalized.result) {
          let page = pageNumber;

          if (pageNumber > 0 && normalized.result.length === 0) {
            page = pageNumber - 1;
          }
          dispatch(receiveChatInbox(getChatInboxPayload(normalized, page || 0)));
        }
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function fetchChatThread({ userId, groupId = 0, pageNo = 0, sort = 'descending', minimal = true, reset = false }) {
  const pageSize = EVENT_SIZE;
  let url = `/chat/thread?pageNo=${pageNo}&pageSize=${pageSize}&sort=${sort}`;

  if (groupId) {
    url = `${url}&groupId=${groupId}`;
  } else {
    url = `${url}&userId=${userId}`;
  }

  if (minimal) url = `${url}&minimal=1`;

  return (dispatch) => {
    dispatch(requestChatThreadData());

    return axios.get(url)
      .then((response) => {
        const normalized = normalize(response.data, [event]);
        if (normalized.result) {
          if (reset) {
            dispatch(replaceChatThread(getChatThreadPayload(normalized, pageNo || 0)));
          } else {
            dispatch(receiveChatThread(getChatThreadPayload(normalized, pageNo || 0)));
          }
        } return response?.data?.length || 0;
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function fetchCompleteChatThreadView(threadOptions) {
  return async (dispatch) => {
    dispatch(requestChatViewData());
    dispatch(resetEventData());
    const { userId, groupId } = threadOptions;
    dispatch(setChatContext({ userId, groupId }));
    if (userId) {
      await dispatch(fetchChatThreadUserView(threadOptions));
    } else if (groupId) {
      await dispatch(fetchChatThreadGroupView(threadOptions));
    }
    await dispatch(updateChatThreadReadStatus(userId, groupId));
    dispatch(adjustBadgeCount({ userId, groupId }));
  };
}

export function fetchChatThreadUserView({ userId, pageNo = 0 }) {
  return (dispatch) => axios.all([getChatThread({ userId, pageNo }), UserReducer.getUser(userId)])
    .then(axios.spread((threadResult, userResult) => {
      const normalizedThread = threadResult ? normalize(threadResult.data, [event]) : null;
      const normalizedUser = userResult ? normalize(userResult.data, user) : null;
      dispatch(receiveChatThreadUserView(getChatThreadUserViewPayload(normalizedThread, normalizedUser, userId)));
    }))
    .catch((err) => {
      console.error(err.response || err);

      dispatch(setError(err.response || err));
    });
}

export function fetchChatThreadGroupView({ groupId, pageNo = 0 }) {
  return (dispatch) => axios.all([getChatThread({ groupId, pageNo }), GroupReducer.getGroup(groupId)])
    .then(axios.spread((threadResult, groupResult) => {
      const normalizedThread = threadResult ? normalize(threadResult.data, [event]) : null;
      const normalizedGroup = groupResult ? normalize(groupResult.data, group) : null;
      dispatch(receiveChatThreadGroupView(getChatThreadGroupViewPayload(normalizedThread, normalizedGroup, groupId)));
    }))
    .catch((err) => {
      console.error(err.response || err);

      dispatch(setError(err.response || err));
    });
}

export function createTempChatEvent(postEvent) {
  return (dispatch) => {
    dispatch(receiveCreateTempChatEvent(getTempChatEventPayload(postEvent)));
  };
}

function getTempChatEventPayload(payload) {
  return {
    event: {
      [payload.optimisticId]: {
        attachments: payload.attachments || [],
        id: payload.optimisticId,
        incoming: false,
        read: true,
        group: payload.groupId,
        user: payload.userId,
        sender: payload.sender,
        typeId: 29,
        details: {
          text: payload.text,
        },
        eventMentions: payload.eventMentions || [],
      },
    },
    eventId: payload.optimisticId,
  };
}

export function createChatEvent(postEvent) {
  const url = '/chat/thread?minimal=1';
  return (dispatch, getState) => {
    const currentState = getState();
    const soundOn = AudioHelpers.getSoundOnOff(currentState.organization.organizations, currentState.user.users[currentState.auth.currentUser]);
    return axios.post(url, postEvent)
      .then((response) => {
        const normalized = normalize(response.data, event);
        dispatch(receiveCreateChatEvent(getChatEventPayload(normalized)));
        if (soundOn) AudioHelpers.playAudio('message-sent');
      })
      .catch((err) => {
        let body = '';
        console.error(err.response || err);
        const errorMessage = err?.response?.data?.message;

        dispatch(setError(err.response || err));

        if (soundOn) AudioHelpers.playAudio('message-failed');

        if (errorMessage?.includes('413')) {
          body = `The file you selected is too large. The maximum file size is ${AppConstants.MAX_STANDARD_FILE_SIZE_TEXT}.`;
        } else {
          body = getNetworkErroMessage(err) || errorMessage;
        }
        NotificationActions.addNotification({
          body,
          type: 'danger',
          persistNotification: true,
        });
        dispatch(receiveEventStatus({ eventId: postEvent.optimisticId, status: MessageStatus.NetworkError }));
      });
  };
}

export function adjustBadgeCount({ userId, groupId }) {
  return (dispatch, getState) => {
    const currentState = getState();

    // make a copy from state, so we can update without mutating
    const inboxSections = { inbox: [...currentState.ui.inboxSections.inbox], chat: [...currentState.ui.inboxSections.chat] };

    inboxSections.chat = inboxSections.chat.map((section) => {
      const adjustedSection = { ...section };

      if (section.type === 'group' && section.userGroupId === groupId) {
        adjustedSection.unreadUserIds = [];
        adjustedSection.unreadCount = 0;
      } else if (section.type === 'direct') {
        if (userId) {
          adjustedSection.unreadUserIds = adjustedSection.unreadUserIds.filter((unreadUserId) => unreadUserId !== userId);
        } else if (groupId) {
          adjustedSection.unreadGroupIds = adjustedSection.unreadGroupIds.filter((u) => u !== groupId);
        }
      }

      return adjustedSection;
    });

    dispatch(receiveInboxSections(inboxSections));

    // Update native app badge count
    const badgeCount = UIHelpers.getTotalUnreadCount();
    OutboundPostMessageService.postMessage({
      type: 'setBadgeCount',
      data: { badgeCount },
    });
  };
}

const getMentionFromWebSocketEvent = (mentions, userId) => {
  const newMention = mentions?.find((mention) => mention && mention.id && mention.userId === userId && !mention.isRead);
  return newMention;
};

export function receiveWebSocketEventMessage(wsEvent) {
  return (dispatch, getState) => {
    const currentState = getState();
    const pathName = window.location.pathname.substring(1).split('/');
    const webSocketEvent = wsEvent.data.event;
    const isIncoming = webSocketEvent.incoming;
    const webSocketContext = wsEvent.data.context;
    const soundOn = AudioHelpers.getSoundOnOff(currentState.organization.organizations, currentState.user.users[currentState.auth.currentUser]);
    // make a copy from state, so we can update without mutating
    const inboxSections = { inbox: [...currentState.ui.inboxSections.inbox], chat: [...currentState.ui.inboxSections.chat] };
    const { groupId: webSocketGroupId, userId: webSocketUserId } = webSocketContext;

    // current member context
    const { groupId, userId, inboxPageNo } = currentState.chat;
    const { currentUser } = currentState.auth;
    // is member in a thread of the same context as the websocket event?
    const sameThread = pathName.includes('chat') && ((webSocketUserId && webSocketUserId === userId) || (webSocketGroupId && webSocketGroupId === groupId));
    // is member in an inbox of the same context as the websocket event?
    const sameInbox = ((webSocketUserId || !!webSocketEvent?.group?.isDynamic) && pathName.includes('chat') && !pathName.includes('group'))
      || (webSocketGroupId && webSocketGroupId === groupId);
    const unreadMention = getMentionFromWebSocketEvent(webSocketEvent.eventMentions, currentUser);

    if (sameThread) { // if thread open from same user/group of incoming message
      dispatch(updateChatThreadReadStatus(userId, groupId));
      webSocketEvent.read = true;
    } else { // adjust badges
      inboxSections.chat = inboxSections.chat.map((section) => {
        const adjustedSection = { ...section };

        // Group Chat (Excluding dynamic groups)
        if (section.type === 'group' && section.userGroupId === webSocketGroupId) {
          if (adjustedSection.unreadUserIds && adjustedSection.unreadUserIds.length === 0 && !webSocketEvent.read) { // if unread event and no count, increase to 1
            adjustedSection.unreadUserIds = [1];
            adjustedSection.unreadCount = 1;
          } else if (adjustedSection.unreadUserIds && adjustedSection.unreadUserIds.length > 0 && webSocketEvent.read) { // if event is read reset count
            adjustedSection.unreadUserIds = [];
            adjustedSection.unreadCount = 0;
          }
          // Direct Chat (Which also includes dynamic groups)
        } else if (section.type === 'direct' && (!webSocketGroupId || !!webSocketEvent?.group?.isDynamic)) {
          if (adjustedSection.unreadUserIds && webSocketUserId && !adjustedSection.unreadUserIds.includes(webSocketUserId) && !webSocketEvent.read) {
            adjustedSection.unreadUserIds = webSocketUserId ? [...adjustedSection.unreadUserIds, webSocketUserId] : [...adjustedSection.unreadUserIds];
          } else if (adjustedSection.unreadGroupIds && webSocketGroupId && !adjustedSection.unreadGroupIds.includes(webSocketGroupId) && !webSocketEvent.read) {
            adjustedSection.unreadGroupIds = webSocketGroupId ? [...adjustedSection.unreadGroupIds, webSocketGroupId] : [...adjustedSection.unreadGroupIds];
          } else if ( // if event is read and userId is existing, remove from count
            adjustedSection.unreadUserIds
            && adjustedSection.unreadUserIds.includes(webSocketUserId)
            && webSocketEvent.read) {
            adjustedSection.unreadUserIds = adjustedSection.unreadUserIds.filter((u) => u !== webSocketUserId);
          } else if (
            adjustedSection.unreadGroupIds // if event is read and groupId is existing, remove from count
            && adjustedSection.unreadGroupIds.includes(webSocketGroupId)
            && webSocketEvent.read) {
            adjustedSection.unreadGroupIds = adjustedSection.unreadGroupIds.filter((u) => u !== webSocketGroupId);
          }
        }
        return adjustedSection;
      });
      // update mentions badge count if message contains mention
      if (unreadMention) {
        inboxSections.inbox = inboxSections.inbox.map((section) => {
          const adjustedSection = { ...section };
          if (adjustedSection.type === 'mentions') {
            const { unreadChatMentionIds } = adjustedSection;
            adjustedSection.unreadChatMentionIds = [...unreadChatMentionIds, unreadMention.id];
            adjustedSection.totalCount += 1;
          } return adjustedSection;
        });
        const normalized = normalize({ ...webSocketEvent, id: unreadMention.id }, eventMention);
        if (pathName.includes('mentions')) {
          // update mentions listview if user is on mentions page
          dispatch(InboxReducer.receiveWebSocketMention(getMentionsInboxPayload(normalized)));
        }
      }
    }

    if (sameInbox) {
      const normalized = normalize(webSocketEvent, event);
      if (inboxPageNo === 0) {
        dispatch(receiveWebSocketEvent(getWebSocketEventPayload(normalized)));
      }
    }

    dispatch(receiveInboxSections(inboxSections));

    // Update native app badge count
    const badgeCount = UIHelpers.getTotalUnreadCount();
    OutboundPostMessageService.postMessage({
      type: 'setBadgeCount',
      data: { badgeCount },
    });
    if (soundOn && isIncoming) {
      const options = {
        mention: unreadMention,
        direct: webSocketUserId,
        group: [webSocketGroupId],
        messageType: 'chat',
      };
      const playIncomingSound = UserHelpers.getIncomingSoundPreference(currentState, options);
      if (playIncomingSound) {
        AudioHelpers.playAudio('chat-received');
      }
    }
  };
}

export function receiveWebSocketEventStatusMessage(webSocketEvent) {
  return (dispatch, getState) => {
    const { data: { event: updatedEvent } } = webSocketEvent;
    const currentState = getState();
    const { events } = currentState.chat;
    const eventToUpdate = { ...events[updatedEvent.id] };

    if (eventToUpdate) {
      if (updatedEvent.isRead) eventToUpdate.isRead = updatedEvent.isRead;
      dispatch(receiveWebSocketEventStatus({ event: eventToUpdate, eventId: eventToUpdate.id }));
    }
  };
}

export const updateMentionReadStatus = (eventMentionId) => (dispatch) => {
  const url = '/chat/mentions/updateRead';
  return axios.patch(url, { eventMentionId, isRead: true }).then(({ data: updatedMention }) => {
    dispatch(receiveChatMentionReadStatus(updatedMention));
    dispatch(fetchInboxSections());
  })
    .catch((err) => {
      console.error(err.response || err); // eslint-disable-line no-console
    }).finally(() => eventMentionId);
};

// Helper Methods
function getChatThread({ userId = 0, groupId = 0, minimal = true, pageNo = 0, sort = 'descending', pageSize = EVENT_SIZE }) {
  let url = `/chat/thread?pageNo=${pageNo}&pageSize=${pageSize}&sort=${sort}`;
  if (userId) {
    url = `${url}&userId=${userId}`;
  } else if (groupId) {
    url = `${url}&groupId=${groupId}`;
  }

  if (minimal) url = `${url}&minimal=1`;

  return axios.get(url)
    .catch((err) => {
      console.error(err.response || err);
    });
}

export function updateChatThreadReadStatus(userId, groupId, read = true, threadIds, actionType, options) {
  let url = '/chat/thread/updateRead';

  if (userId) {
    url = `${url}?userId=${userId}`;
  } else if (groupId) {
    url = `${url}?groupId=${groupId}`;
  }

  return (dispatch) => axios.patch(url, { read, threadIds })
    .then((response) => {
      if (actionType) {
        dispatch(fetchChatInbox(options)).then(() => {
          const updatedInboxCount = (threadIds && threadIds.length) || 0;
          NotificationService(actionType, response, updatedInboxCount);
        });
      }
    })
    .catch((err) => {
      console.error(err.response || err);
    });
}

function getChatThreadGroupViewPayload(normalizedThread, normalizedGroup, groupId) {
  return {
    users: {
      ...normalizedThread.entities.senders,
      ...normalizedGroup.entities.users,
    },
    userIds: [...new Set([...DataHelpers.getObjectKeys(normalizedThread.entities.senders), ...DataHelpers.getObjectKeys(normalizedGroup.entities.users)])],
    events: {
      ...normalizedThread.entities.events,
    },
    eventMentions: { ...normalizedThread.entities.eventMentions },
    eventIds: normalizedThread.result,
    groups: {
      ...normalizedGroup.entities.groups,
    },
    groupIds: DataHelpers.getObjectKeys(normalizedGroup.entities.groups),
    channels: {
      ...normalizedGroup.entities.channels,
    },
    channelIds: DataHelpers.getObjectKeys(normalizedGroup.entities.channels),
    phones: {
      ...normalizedGroup.entities.phones,
    },
    phoneIds: DataHelpers.getObjectKeys(normalizedGroup.entities.phones),
    tags: {
      ...normalizedGroup.entities.tags,
    },
    tagIds: DataHelpers.getObjectKeys(normalizedGroup.entities.tags),
    pageNo: 0,
    activeConvoTab: 'groupChat',
    groupId,
  };
}

function getChatThreadUserViewPayload(normalizedThread, normalizedUser, userId) {
  return {
    users: {
      ...normalizedThread.entities.senders,
      ...normalizedThread.entities.users,
      ...normalizedUser.entities.users,
    },
    userIds: [
      ...new Set([
        ...DataHelpers.getObjectKeys(normalizedThread.entities.senders),
        ...DataHelpers.getObjectKeys(normalizedThread.entities.users),
        ...DataHelpers.getObjectKeys(normalizedUser.entities.users),
      ]),
    ],
    events: {
      ...normalizedThread.entities.events,
    },
    eventMentions: { ...normalizedThread.entities.eventMentions },
    eventIds: normalizedThread.result,
    groups: {
      ...normalizedUser.entities.groups,
    },
    groupIds: DataHelpers.getObjectKeys(normalizedUser.entities.groups),
    channels: {
      ...normalizedUser.entities.channels,
    },
    channelIds: DataHelpers.getObjectKeys(normalizedUser.entities.channels),
    tags: {
      ...normalizedUser.entities.tags,
    },
    tagIds: DataHelpers.getObjectKeys(normalizedUser.entities.tags),
    pageNo: 0,
    activeConvoTab: 'directChat',
    userId,
  };
}

function getChatThreadPayload(normalizedThread, pageNo) {
  return {
    users: {
      ...normalizedThread.entities.senders,
      ...normalizedThread.entities.users,
    },
    userIds: [...new Set([...DataHelpers.getObjectKeys(normalizedThread.entities.senders), ...DataHelpers.getObjectKeys(normalizedThread.entities.users)])],
    events: {
      ...normalizedThread.entities.events,
    },
    eventMentions: {
      ...normalizedThread.entities.eventMentions,
    },
    eventIds: normalizedThread.result,
    pageNo,
  };
}

function getChatInboxPayload(normalizedInbox, pageNo) {
  return {
    users: {
      ...normalizedInbox.entities.senders,
      ...normalizedInbox.entities.users,
    },
    userIds: [...new Set([...DataHelpers.getObjectKeys(normalizedInbox.entities.senders), ...DataHelpers.getObjectKeys(normalizedInbox.entities.users)])],
    events: {
      ...normalizedInbox.entities.events,
    },
    groups: { ...normalizedInbox.entities.groups },
    groupIds: [...new Set([...DataHelpers.getObjectKeys(normalizedInbox.entities.groups)])],
    inboxEventIds: normalizedInbox.result,
    pageNo,
    inboxThreadIds: DataHelpers.getObjectKeys(normalizedInbox.entities.events).map((eventId) => normalizedInbox.entities.events[eventId].threadId),
  };
}

function getChatEventPayload(normalizedEvent) {
  return {
    users: {
      ...normalizedEvent.entities.senders,
      ...normalizedEvent.entities.users,
    },
    userIds: [...new Set([...DataHelpers.getObjectKeys(normalizedEvent.entities.senders), ...DataHelpers.getObjectKeys(normalizedEvent.entities.users)])],
    groups: {
      ...normalizedEvent.entities.groups,
    },
    eventMentions: {
      ...normalizedEvent.entities.eventMentions,
    },
    groupIds: [...new Set([...DataHelpers.getObjectKeys(normalizedEvent.entities.groups)])],
    event: normalizedEvent.entities.events,
    eventId: normalizedEvent.result,
  };
}

function getWebSocketEventPayload(normalizedEvent) {
  return {
    users: {
      ...normalizedEvent.entities.users,
      ...normalizedEvent.entities.senders,
    },
    userIds: [...new Set([...DataHelpers.getObjectKeys(normalizedEvent.entities.users), ...DataHelpers.getObjectKeys(normalizedEvent.entities.senders)])],
    groups: {
      ...normalizedEvent.entities.groups,
    },
    groupIds: [...new Set([...DataHelpers.getObjectKeys(normalizedEvent.entities.groups)])],
    events: {
      ...normalizedEvent.entities.events,
    },
    eventMentions: {
      ...normalizedEvent.entities.eventMentions,
    },
    event: normalizedEvent.entities.events,
    eventId: normalizedEvent.result,
    inboxEventIds: [normalizedEvent.result],

  };
}

function getMentionsInboxPayload(normalizedEvent, pageNo) {
  return {
    users: {
      ...normalizedEvent.entities.senders,
      ...normalizedEvent.entities.users,
    },
    userIds: [...new Set([...DataHelpers.getObjectKeys(normalizedEvent.entities.senders), ...DataHelpers.getObjectKeys(normalizedEvent.entities.users)])],
    events: {
      ...normalizedEvent.entities.eventMention,
    },
    event: normalizedEvent.entities.events,
    eventId: normalizedEvent.result,
    inboxEventIds: [normalizedEvent.result],
    pageNo,
    eventMentions: {
      ...normalizedEvent.entities.eventMentions,
    },
    mentionIds: normalizedEvent.result,
  };
}

export function closeChatConversation(payload, options) {
  return (dispatch) =>
    axios.patch('/chat/closeConversation', payload)
      .then((response) => {
        if (payload.conversationActionType === 'closeBulkConversations') {
          dispatch(fetchChatInbox(options)).then(() => {
            const updatedInboxCount = (payload.threadIds?.length) || 0;
            NotificationService(payload.conversationActionType, response, updatedInboxCount);
          });
        } else {
          dispatch(fetchChatInbox({ pageNumber: 0 }));
          NotificationService(payload.conversationActionType, response);
        }
      })
      .catch((err) => {
        console.error(err.response || err);
        dispatch(setError(err.response || err));
        NotificationService('closeConversation', err.response);
      });
}
