import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Icon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UtilityInlineGrid,
} from 'rhinostyle';
import AvatarEditor from 'react-avatar-editor';

class UploadAvatarModal extends Component {
  static initialState = {
    scale: 20,
    disableSave: true,
    formInProgress: false,
    filename: null,
    rotate: 0,
  }

  state = {
    ...UploadAvatarModal.initialState,
    avatar: this.props.profileImageUrl,
  };

  // When image is selected via the file input
  selectImage = (event) => {
    const reader = new FileReader();
    const file = event.target.files[0];
    const filename = file ? file.name : null;

    reader.addEventListener('load', () => {
      this.setState({
        avatar: reader.result,
        disableSave: false,
        filename,
      });
    });

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  handleZoom = (event) => {
    const scale = event.target.value;

    this.setState({ scale });
  }

  handleRotate = () => {
    this.setState({
      rotate: this.state.rotate - 90,
    });
  }

  handleSave = () => {
    this.setState({ formInProgress: true });

    const croppedImg = this.editor.getImageScaledToCanvas().toDataURL();
    this.props.uploadAvatar(croppedImg, this.state.filename);
  }

  handleClose = () => {
    this.props.onClose();
  }

  handleReverseComplete = () => {
    this.setState(UploadAvatarModal.initialState);
  }

  render() {
    return (
      <Modal open={this.props.open} onReverseComplete={this.handleReverseComplete} size="small">
        <ModalHeader
          onClose={this.handleClose}
          title="Profile Photo"
        />
        <ModalBody>
          <div className="avatar-editor">
            <input
              className="avatar-editor__input"
              type="file"
              name="js-upload-avatar"
              id="js-upload-avatar"
              accept="image/*"
              onChange={this.selectImage}
            />
            <div className="avatar-editor__container">
              <AvatarEditor
                ref={(editor) => { this.editor = editor; }}
                scale={parseInt(this.state.scale, 10) / 10}
                border={1}
                color={[0, 0, 0, 0]}
                image={this.state.avatar}
                rotate={parseFloat(this.state.rotate)}
              />
              <label // eslint-disable-line jsx-a11y/label-has-for
                className="avatar-editor__container__upload"
                htmlFor="js-upload-avatar"
              >
                Upload Photo
              </label>
            </div>
            {this.state.avatar && (
              <div className="avatar-editor__controls">
                <Button reset className="avatar-editor__rotate" onClick={this.handleRotate}>
                  <Icon icon="rotate" />
                </Button>
                <input
                  value={this.state.scale}
                  type="range"
                  onChange={this.handleZoom}
                  className="rhinorange"
                />
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <UtilityInlineGrid align="right">
            <Button disabled={this.state.disableSave} loading={this.state.formInProgress} type="primary" onClick={this.handleSave}>
              Done
            </Button>
          </UtilityInlineGrid>
        </ModalFooter>
      </Modal>
    );
  }
}

UploadAvatarModal.propTypes = {
  open: PropTypes.bool.isRequired,
  uploadAvatar: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  profileImageUrl: PropTypes.string,
};

UploadAvatarModal.defaultProps = {
  profileImageUrl: '',
};

export default UploadAvatarModal;
