import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import cx from 'classnames';
import {
  Message,
  FormLabel,
  Button,
  UtilitySystem,
  FormValidationMessage,
} from 'rhinostyle';
import EmojiPickerDropdown from './EmojiPickerDropdown';

import Quill from '../configs/Quill.config.js';
import { SMS_MESSAGE_LENGTH_WARNING_VARIABLES, SMS_MESSAGE_LENGTH_MAX } from '../constants/AppConstants';
import { replaceNewLinesToLineBreaks, handleEmojiSelection } from '../helpers/TemplateHelpers';
import FileUpload from './FileUpload';
import { getIsForm, getIsVCard } from '../helpers/AttachmentHelpers';
import OptOut from './OptOut';

class VariableMessage extends React.Component {
  state = {
    templatePreview: '',
    templateString: '',
    initialValue: '',
    textFormatted: !this.props.preventFocusOnLoad,
    showCodifiedResponseWarning: false,
  };

  componentDidMount() {
    this.quillInit();
    this.setState({ textFormatted: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue && this.props.initialValue !== prevProps.initialValue) {
      this.setState({ // eslint-disable-line
        initialValue: this.props.initialValue,
      }, () => {
        this.handleReset();
      });
    }

    // Update the Quill editor's readOnly state
    if (prevProps.isReadOnly !== this.props.isReadOnly) {
      if (this.quill) {
        this.quill.enable(!this.props.isReadOnly);
      }
    }
  }

  quillInit = () => {
    const options = {
      modules: {
        toolbar: false,
      },
      readOnly: this.props.isReadOnly || false,
    };
    const variableMessageInputName = `variable-message-input-${this.id}`;

    this.quill = new Quill(`#${variableMessageInputName}`, options);
    this.quill.on('text-change', this.handlePreview);
    this.quill.on('focus', { preventScroll: true });
    this.quill.getSelection(true); // force focus
    this.handleTemplate();
    this.setState({ initialValue: this.props.template });
  }

  getVariables = (array) => array.map((el) => el.variable.toLowerCase());

  id = `${this.props.name}-${UtilitySystem.generateUUID()}`;

  handleTemplate = () => {
    const { variableOptions, template } = this.props;
    const flatVariables = this.getVariables(variableOptions);
    // Inflate template string to strip out handlebars and add HTML
    template.split(/({.*?})/).forEach((el) => {
      const range = this.quill.getSelection(true); // force focus and grab range from input
      const variableIndex = flatVariables.indexOf(el.toLowerCase());
      if (variableIndex > -1) {
        const textLength = this.quill.getLength();
        this.quill.insertEmbed(range.index, 'variable', variableOptions[variableIndex], 'user');
        if (range.index + 1 >= textLength) {
          this.quill.insertText(range.index + 1, ' ', 'user');
        }
        this.quill.setSelection(range.index + 1, 'user');
      } else {
        this.quill.insertText(range.index, el);
      }
    });
  }

  handleVariableSelection = (variable) => {
    const range = this.quill.getSelection(true); // force focus;
    if (range) {
      if (range.length === 0) {
        const textLength = this.quill.getLength();
        this.quill.insertEmbed(range.index, 'variable', variable, 'user');
        if (range.index + 1 >= textLength) {
          this.quill.insertText(range.index + 1, ' ', 'user');
        }
        this.quill.setSelection(range.index + 1, 'user');
      }

      if (variable.category === 'CodifiedResponse') {
        this.setState({ showCodifiedResponseWarning: true });
        if (this.props.onShowWarningMessage) {
          this.props.onShowWarningMessage(true);
        }
      } else {
        this.setState({ showCodifiedResponseWarning: false });
        if (this.props.onShowWarningMessage) {
          this.props.onShowWarningMessage(false);
        }
      }
    }
  }

  handlePreview = () => {
    const templateContents = this.quill.getContents();
    const templatePreview = templateContents.ops.map((el) => {
      if (typeof el.insert === 'object' && el.insert !== null) {
        return el.insert.variable.variableValue;
      }
      const formattedText = replaceNewLinesToLineBreaks(el.insert);
      return formattedText;
    }).join('').trim();

    this.setState({ templatePreview });
    this.handleTemplateChange();
  }

  handleTemplateChange = () => {
    // Sanitize template string to strip out HTML and just include handlebars
    const templateContents = this.quill.getContents();
    const templateString = templateContents.ops.map((el) => {
      if (typeof el.insert === 'object' && el.insert !== null) {
        return el.insert.variable.variable;
      }
      return el.insert;
    }).join('').trim();
    this.setState({ templateString });
    this.props.handleTemplateChange(this.props.name, templateString);
  }

  handleReset = () => {
    const { variableOptions } = this.props;
    const { initialValue } = this.state;
    const flatVariables = this.getVariables(variableOptions);
    this.quill.setContents([]);
    // Inflate template string to strip out handlebars and add HTML
    initialValue.split(/({.*?})/).forEach((el) => {
      const range = this.quill.getSelection(true);
      const variableIndex = flatVariables.indexOf(el.toLowerCase());
      if (variableIndex > -1) {
        this.quill.insertEmbed(range.index, 'variable', variableOptions[variableIndex], 'user');
        this.quill.setSelection(range.index + 1, 'user');
      } else {
        this.quill.insertText(range.index, el);
      }
    });
  }

  handleEmojiClick = (emojiObject) => handleEmojiSelection(emojiObject, this.quill);

  showReset = () => (this.state.initialValue !== this.props.template);
  renderToggleButtons = (category) => (
    this.props.variableOptions.filter((variable) => variable.id !== -1 && variable.category === category)
      .map((variable) => (
        <Button
          className="u-m-t-small variable-button"
          size="small"
          type="primary"
          outlined
          variable={variable}
          key={variable.value}
          onClick={() => this.handleVariableSelection(variable)}
          id={variable.value}
          data-cypress={variable.value}
        >
          {variable.value}
        </Button>
      ))
  );

  renderVCards() {
    const { attachments, appointmentEventTypeId } = this.props;
    if (!Array.isArray(attachments)) return null;
    const filteredVCards = attachments.filter((attachment) => attachment.appointmentEventTypeId === appointmentEventTypeId && getIsVCard(attachment));
    if (filteredVCards.length > 0) {
      return filteredVCards.map((vCard) => (
        <div key={vCard.attachmentUrl}>{vCard.name}</div>
      ));
    } return null;
  }

  renderFormUrls() {
    const { attachments, appointmentEventTypeId } = this.props;
    if (!Array.isArray(attachments)) return null;
    const filteredForms = attachments.filter((attachment) => attachment.appointmentEventTypeId === appointmentEventTypeId && getIsForm(attachment));
    if (filteredForms.length > 0) {
      return filteredForms.map((form) => (
        <div key={form.attachmentUrl}>{form.name}: <span className="u-text-underline">https://sign.rhinogram.com/testform</span></div>
      ));
    } return null;
  }

  warningMessage = (
    <div className="variable-message__warning">
      <span className="variable-message__snippet"><strong>ATTENTION: </strong></span>
      We see you are using codified responses.
      If you would like to generate an automated response to appointment confirmation and cancel,
      please select the <span className="variable-message__snippet"> <strong>Generate Automated Response </strong> Container below to enable. </span>
    </div>
  );

  render() {
    const classes = cx('form__group variable-message', this.props.className);
    const characterCounterClasses = cx('variable-message__character-count', {
      'variable-message__character-count--warning': this.state.templateString.length >= SMS_MESSAGE_LENGTH_MAX,
    });
    const variableMessageClasses = cx('variable-message form__group', {
      'variable-message--loading': !this.state.textFormatted,
    });
    const variableMessageInputName = `variable-message-input-${this.id}`;
    const variableMessagePreviewName = `variable-message-preview-${this.id}`;

    const composeContainerClasses = cx(
      'variable-message__compose__container u-flex-grow',
      { 'variable-message__compose__container--readonly': this.props.isReadOnly },
    );

    return (
      <div className={classes}>
        {!this.props.displayMessageAreaOnly && (
          <div className="variable-message__header">
            <FormLabel className="variable-message__label" id={variableMessageInputName} required={this.props.required}>{this.props.composeLabel}</FormLabel>
            {this.showReset() && (
              <div className="variable-message__reset">
                <Button reset className="u-text-muted u-text-small" onClick={this.handleReset}>Undo</Button>
              </div>
            )}
          </div>
        )}
        <div className={variableMessageClasses}>
          <div className="u-position-relative u-flex u-flex-direction-row">
            <div className="variable-message__add-emoji-container">
              <EmojiPickerDropdown dropdownPosition="top" onEmojiSelect={this.handleEmojiClick} />
            </div>
            <div className={composeContainerClasses}>
              <div
                name={this.props.name}
                placeholder={this.props.placeholder}
                id={variableMessageInputName}
              />
              {this.props.source !== 'templates' && <OptOut className="u-text-gray" />}
            </div>
          </div>
          <FormValidationMessage validationMessage={this.props.validationMessage} />
        </div>
        <div className="variable-message__footer">
          <div className="variable-message__explanation">Click to add/remove variables into your message:</div>
          <div className="variable-message__footer__variable__list">
            <div>
              {this.renderToggleButtons('Demographics')}
            </div>
            <div>
              {this.renderToggleButtons('Appointment')}
            </div>
            <div>
              {this.renderToggleButtons('Organization')}
            </div>
            <div>
              {this.renderToggleButtons('Member')}
            </div>
            <div>
              {this.renderToggleButtons('CodifiedResponse')}
            </div>
            <div>
              {this.renderToggleButtons('Prescription')}
            </div>
          </div>
        </div>
        {this.state.showCodifiedResponseWarning && this.warningMessage}
        <div title={SMS_MESSAGE_LENGTH_WARNING_VARIABLES} className={characterCounterClasses}>
          {this.state.templateString.length}
        </div>
        {this.props?.hideAttachments !== true ?
          (
            <FileUpload
              appointmentEventTypeId={this.props.appointmentEventTypeId}
              attachments={this.props.attachments}
              handleChange={this.props.handleTemplateChange}
              customWarningMessage={this.props.customWarningMessage}
              source={this.props.source}
            />
          )
          : null}
        <div className="variable-message__preview" data-cypress="variableMessagePreview">
          <FormLabel className="u-block" id={variableMessagePreviewName}>Message Preview</FormLabel>
          <Message type="primary" direction="outbound">
            {ReactHtmlParser(this.state.templatePreview)}
            {this.renderFormUrls()}
            {this.renderVCards()}
            {this.props.source !== 'templates' && <OptOut />}
          </Message>
        </div>
      </div>
    );
  }
}

VariableMessage.propTypes = {
  appointmentEventTypeId: PropTypes.number,
  attachments: PropTypes.array,
  className: PropTypes.string,
  composeLabel: PropTypes.string,
  customWarningMessage: PropTypes.string,
  displayMessageAreaOnly: PropTypes.bool,
  handleTemplateChange: PropTypes.func.isRequired,
  hideAttachments: PropTypes.bool,
  initialValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  preventFocusOnLoad: PropTypes.bool,
  required: PropTypes.bool,
  source: PropTypes.string,
  template: PropTypes.string,
  validationMessage: PropTypes.string,
  variableOptions: PropTypes.array.isRequired,
  isReadOnly: PropTypes.bool,
  onShowWarningMessage: PropTypes.func,
};

VariableMessage.defaultProps = {
  placeholder: '',
  isReadOnly: false,
};

const mapStateToProps = state => ({}); // eslint-disable-line

const actions = {};

export default connect(mapStateToProps, actions)(VariableMessage);
