import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import * as SecureReducer from '../reducers/secureReducer';
import SecureThreadFooter from '../components/SecureThreadFooter';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { AttachmentHelpers, DataHelpers } from '../helpers';
import WebSocketService from '../services/WebSocketService';
import { Types } from '../constants';

class SecureThreadFooterContainer extends Component {
  isTyping = false;
  stopTypingTimeout = undefined;

  state = {
    activeUpload: false,
    attachments: [],
    messageFocus: false,
    messageText: '',
    sendingMessage: false,
    fileProgress: '0% Uploaded',
  };

  componentDidMount() {
    this.handleMessageTextChange = debounce(this.handleMessageTextChange, 200);
  }

  setTypingState = (isTyping) => {
    WebSocketService.sendUpdatedTypingState(WebSocketService.shapeOutgoingTypingEvent({
      typingUser: this.props.currentUser,
      subscribingToUserId: this.props.currentUser.id,
      threadType: 'inbox',
      isTyping,
    }));
  }

  handleAddFile = (event) => {
    this.setState({ activeUpload: true });

    const handleProgressDisplay = (progressText) => {
      this.setState({ fileProgress: progressText });
    };

    const uploadOpts = {
      file: event.target.files[0],
      currentAttachments: [...this.state.attachments],
      progressCallback: handleProgressDisplay,
    };

    AttachmentHelpers.handleAddFile(uploadOpts, this.handleUploadComplete);
  }

  handleUploadComplete = (newState) => {
    this.setState({
      activeUpload: false,
      attachments: newState.attachments,
    });
  }

  handleMessageTextChange = (id, value) => {
    const { resetStopTypingTimeout } = this;

    const isInputEmpty = (!value.length);

    // If the input isn't empty, and isTyping is false, update the state
    if (!isInputEmpty) {
      // If the user wasn't typing before, and now the event has fired,
      // it means they are now typing
      if (this.isTyping === false) {
        this.isTyping = true;
        this.setTypingState(this.isTyping);
        // Start a 3 second countdown to see if they type within that window
        resetStopTypingTimeout();
      } else {
        // If the user typed another character, reset the timeout
        resetStopTypingTimeout();
      }
    } else {
      if (this.isTyping === true) { //eslint-disable-line
        // If the user was typing, but now the input is empty,
        // it means they've deleted everything and that triggered
        // an onChange event.  For this, we state they have stopped typing
        this.isTyping = false;
        this.setTypingState(this.isTyping);
        // Stop the timeout, if there is one running
        if (this.stopTypingTimeout) {
          clearTimeout(this.stopTypingTimeout);
          this.stopTypingTimeout = undefined;
        }
      }
    }

    this.setState({ messageText: value });
  }

  handleMessageTextClick = () => {
    this.setState({ messageFocus: true });
  }

  handleRemoveAttachment = (key) => {
    const files = this.state.attachments;

    files.splice(key, 1);
    this.setState({ attachments: files });
  }

  handleSendMessage = () => {
    const user = this.props.currentUser;
    const phoneId = this.props.currentUser.phones[0];
    const optimisticId = DataHelpers.generateUUID(); // used for optimistic updates
    const eventType = user.typeId === Types.TYPE_PATIENT ? 'patient/secureMessages' : 'secureMessages';

    const payload = {
      userId: user.id,
      text: this.state.messageText,
      secureChannelId: Number(this.props.match.params.channelId),
      attachments: this.state.attachments,
      notificationPhoneId: phoneId,
      sender: user.id,
      optimisticId,
    };

    this.setState({ sendingMessage: true }, () => {
      this.props.createTempSecureEvent(payload);
      this.setState({ messageText: '', attachments: [], sendingMessage: false, messageFocus: false }, () => {
        this.clearTyping();
        this.props.createSecureEvent(payload, eventType);
        this.setState({ messageFocus: true });
      });
    });
  }

  scrollToBottom = () => {
    const scrollContainer = document.getElementById('convo__body').firstChild;
    scrollContainer.scrollTop = scrollContainer.scrollHeight;
  }

  resetStopTypingTimeout = () => {
    const { stopTypingTimeout } = this;
    if (stopTypingTimeout) {
      clearTimeout(stopTypingTimeout);
    }
    this.stopTypingTimeout = setTimeout(() => {
      this.isTyping = false;
      this.setTypingState(this.isTyping);
      this.stopTypingTimeout = undefined;
    }, WebSocketService.typingTimeout);
  };

  clearTyping = () => {
    if (this.isTyping) {
      this.isTyping = false;
      this.setTypingState(this.isTyping);
      clearTimeout(this.stopTypingTimeout);
      this.stopTypingTimeout = undefined;
    }
  }

  render() {
    const props = {
      activeUpload: this.state.activeUpload,
      attachments: this.state.attachments,
      channel: this.props.channels[this.props.match.params.channelId],
      handleAddFile: this.handleAddFile,
      handleMessageTextChange: this.handleMessageTextChange,
      handleMessageTextClick: this.handleMessageTextClick,
      handleRemoveAttachment: this.handleRemoveAttachment,
      handleSendMessage: this.handleSendMessage,
      messageBoxRef: (messageBox) => (this.messageBox = messageBox),
      messageFocus: this.state.messageFocus,
      messageText: this.state.messageText,
      organization: this.props.currentOrganization,
      sendingMessage: this.state.sendingMessage,
      isSingleChannel: !!this.props.match.params.channelId,
      fileProgress: this.state.fileProgress,
    };

    return <SecureThreadFooter {...props} />;
  }
}

SecureThreadFooterContainer.propTypes = {
  createEvent: PropTypes.func,
  channels: PropTypes.object,
  currentUser: PropTypes.object,
  currentOrganization: PropTypes.object,
  createTempSecureEvent: PropTypes.func.isRequired,
  createSecureEvent: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  fileProgress: PropTypes.string,
};

function mapStateToProps(state) {
  const { channel } = state;

  return {
    channels: channel.channels,
    currentUser: getLoggedInUser(state),
    currentOrganization: getCurrentOrg(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createSecureEvent: bindActionCreators(SecureReducer.createSecureEvent, dispatch),
    createTempSecureEvent: bindActionCreators(SecureReducer.createTempSecureEvent, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecureThreadFooterContainer);
