import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import * as OrganizationActionTypes from '../constants/OrganizationActionTypes';

import * as OfficeActionTypes from '../constants/OfficeActionTypes';
import { setError } from './uiReducer';

// SLICE
const stateSlice = createSlice({
  name: 'STATES',
  initialState: {
    states: [],
  },
  reducers: {
    receiveStates: (state, action) => ({
      ...state,
      states: [...action.payload],
    }),
  },
  extraReducers: {
    [OrganizationActionTypes.receiveOrganizationProfileView]: (state, action) => ({
      ...state,
      states: [...action.payload.states],
    }),
    [OfficeActionTypes.receiveOfficesView]: (state, action) => ({
      ...state,
      states: [...action.payload.states],
    }),
  },
});

export default stateSlice.reducer;

// ACTION
export const { receiveStates } = stateSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

export function fetchStates() {
  return (dispatch) =>
    axios.get('/states')
      .then((response) => {
        response.data.unshift({ id: -1, value: '--' });
        dispatch(receiveStates(response.data));
      })
      .catch((err) => {
        console.error(err.response);

        dispatch(setError(err.response));
      });
}

export function fetchAllStates() {
  return axios.get('/states')
    .catch((err) => console.error(err));
}
