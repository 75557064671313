import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'rhinostyle';
import { toggleModal } from '../reducers/threadReducer';
import { MODAL_OPTIONS } from '../constants/ThreadConstants';

import EmptyMessage from './EmptyMessage';

const LimitReachedModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.thread[MODAL_OPTIONS.videoLimit]);
  const handleToggleModal = () => {
    dispatch(toggleModal(MODAL_OPTIONS.videoLimit));
  };
  return (
    <Modal open={isOpen}>
      <ModalHeader title="Rhinovideo Limit Reached" onClose={handleToggleModal} />
      <ModalBody>
        <EmptyMessage section="RhinoVideo Limit Reached" />
        <div className="u-text-center"><Button type="primary" onClick={handleToggleModal}>Close This Window</Button></div>
      </ModalBody>
    </Modal>
  );
};

export default LimitReachedModal;
