import { UtilitySystem } from 'rhinostyle';
import { TweenMax, ScrollToPlugin } from 'gsap/all';

// required declaration to prevent tree shaking https://greensock.com/forums/topic/19338-scrolltoplugin-not-working-in-production-build/
const plugins = [ScrollToPlugin]; // eslint-disable-line

export function scrollToBottom(node) {
  if (!node) return;

  TweenMax
    .to(node, 0.5, {
      scrollTo: {
        y: node.scrollHeight,
        autoKill: true,
      },
      ease: UtilitySystem.config.easing,
    });
}

/**
 * Scrolls and focuses on specific element
 * @param  Element and container
 * @return {void}
 */
export function scrollToElementByClassName(className, container) {
  const element = container.querySelector(className);
  if (element) {
    TweenMax
      .to(container, 0.5, {
        scrollTo: {
          y: element.offsetTop,
          autoKill: true,
        },
        ease: UtilitySystem.config.easing,
        onComplete() {
          element.focus();
        },
      });
  }
}

export function scrollToElementById(id, container) {
  const element = container.querySelector(id);
  if (element) {
    TweenMax
      .to(container, 0.5, {
        scrollTo: {
          y: element.offsetTop,
          autoKill: true,
        },
        ease: UtilitySystem.config.easing,
        onComplete() {
          element.focus();
        },
      });
  }
}

/**
 * Tells if item is scrolled into view
 * @param  Element and container
 * @return {void}
 */
export function isElementVisible(className, scrollContainer) {
  const element = scrollContainer.querySelector(className);
  if (!element) return false;
  const docViewTop = scrollContainer.scrollTop;
  const docViewBottom = docViewTop + scrollContainer.clientHeight;
  const elementMiddle = element.offsetTop + (element.scrollHeight / 2);
  return ((elementMiddle <= docViewBottom) && (elementMiddle >= docViewTop));
}
