import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NotificationActions } from 'rhinostyle';

import * as GroupReducer from '../reducers/groupReducer';
import OrganizationGroupForm from '../components/OrganizationGroupForm';
import unsavedChanges from '../components/UnsavedChangesHOC';
import { Types } from '../constants';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { getLoggedInUser, getLoggedInUserOrganization } from '../selectors/userSelectors';
import { ValidationHelpers } from '../helpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { GROUP_CHAT_EDIT, GROUP_INBOX_EDIT } from '../constants/UserPermissionsConstants';
import { sortComparatorUsingUserLastNameFirstName, exists } from '../helpers/DataHelpers';
import { getActiveGroup } from '../selectors/groupSelectors';

class OrganizationGroupFormContainer extends React.Component {
  state = {
    afterHoursEnabled: false,
    autoResponse: '',
    businessHours: [],
    deselectedUserIds: [],
    disabledTypeIds: [],
    errors: {},
    id: -1,
    mode: 'create',
    name: '',
    observesDst: true,
    pageLoading: true,
    purpose: '',
    routedChannels: [],
    selectedChannelIds: [],
    selectedGroupIds: [],
    selectedTagIds: [],
    selectedUserIds: [],
    timeZoneId: null,
    typeId: null,
    viewAllMembers: !this.props.match.params.groupId,
    viewGroups: false,
    isDeleteGroupModal: false,
    modalFormInProgress: false,
    defaultChannelEnabled: false,
    defaultChannelId: -1,
  };

  componentDidMount() {
    this.props.fetchGroupsFormView(this.props.match?.params?.groupId);
    if (this.props.activeGroup) {
      const { defaultChannelId } = this.props.activeGroup;
      this.setState({
        defaultChannelEnabled: !!defaultChannelId,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const updateMode = this.props.activeGroup && this.state.id === -1;
    const activeGroupLoaded = this.props.activeGroup && this.props.pageLoading !== prevProps.pageLoading;
    if (updateMode || activeGroupLoaded) {
      const {
        afterHours,
        autoResponse,
        businessHours,
        id,
        name,
        observesDst,
        purpose,
        routedChannels,
        tags,
        timeZoneId,
        typeId,
        users,
        defaultChannelId,
      } = this.props.activeGroup;
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        afterHoursEnabled: !!afterHours,
        autoResponse: autoResponse || '',
        businessHours: businessHours || this.state.businessHours,
        disabledTypeIds: this.getDisabledTypeIds(typeId),
        id,
        mode: 'update',
        name,
        observesDst: !!observesDst,
        purpose,
        selectedChannelIds: routedChannels || this.state.routedChannels,
        selectedTagIds: tags || this.state.selectedTagIds,
        selectedUserIds: users || this.state.selectedUserIds,
        timeZoneId,
        defaultChannelEnabled: !!defaultChannelId,
        defaultChannelId: defaultChannelId || -1,
        typeId,
        ...activeGroupLoaded && {
          pageLoading: this.props.pageLoading,
        },
      });
    } else if (this.props.pageLoading !== prevProps.pageLoading) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        pageLoading: this.props.pageLoading,
        disabledTypeIds: this.getDisabledTypeIds(),
      });
    }
  }

  get payload() {
    const payload = {
      afterHours: this.state.afterHoursEnabled,
      autoResponse: this.state.autoResponse,
      businessHours: this.state.businessHours.length === 7 ? this.state.businessHours : null,
      id: this.state.id,
      name: this.state.name,
      observesDst: this.state.observesDst,
      purpose: this.state.purpose,
      routedChannels: this.state.selectedChannelIds,
      tagIds: this.state.selectedTagIds,
      timeZoneId: this.state.timeZoneId,
      typeId: this.state.typeId,
      userIds: this.state.selectedUserIds,
      defaultChannelId: this.state.defaultChannelId,
    };

    if (payload.defaultChannelId === -1) payload.defaultChannelId = '';
    return payload;
  }

  getDisabledTypeIds = (typeId) => {
    const returnVal = [];

    switch (typeId) {
      case Types.TYPE_GROUP_INBOX:
        returnVal.push(Types.TYPE_GROUP_CHAT);
        break;
      case Types.TYPE_GROUP_CHAT:
        returnVal.push(Types.TYPE_GROUP_INBOX);
        break;
      case Types.TYPE_GROUP_INBOX_AND_CHAT:
        returnVal.push(Types.TYPE_GROUP_INBOX, Types.TYPE_GROUP_CHAT);
        break;
      default:
        break;
    }
    // Prevent updating group type if the user doesn't have proper permission.
    if (!userHasAnyOfPermissions([GROUP_INBOX_EDIT])) {
      returnVal.push(Types.TYPE_GROUP_INBOX, Types.TYPE_GROUP_INBOX_AND_CHAT);
    } else if (!userHasAnyOfPermissions([GROUP_CHAT_EDIT])) {
      returnVal.push(Types.TYPE_GROUP_CHAT, Types.TYPE_GROUP_INBOX_AND_CHAT);
    }

    return [...new Set([...returnVal])];
  };

  handleChange = (name, value) => {
    const valueToUpdate = {};
    valueToUpdate[name] = value;
    if (name === 'afterHoursEnabled' && !value && this.state.mode === 'update') {
      const { businessHours } = this.props.groups[this.state.id];
      valueToUpdate.businessHours = businessHours;
    }
    this.setState(valueToUpdate);
  }

  handleSubmit = () => {
    let errors = ValidationService(ValidationShapers.shapeGroup(this.payload));
    const errorCount = Object.keys(errors).length;

    if (errorCount > 0) {
      this.setState({ errors }, () => {
        ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
      });
    } else if (this.state.mode === 'update') {
      this.props.updateGroup(this.payload)
        .then(() => {
          if (this.props.error) {
            errors = ValidationHelpers.handleServerError(this.props.error.data);
            this.setState({ errors, formInProgress: false }, () => {
              ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
            });
          } else {
            this.props.resetFormChanges();
            this.setState({ errors: {}, formInProgress: false });
            this.props.history.push(`/settings/organization/groups/${this.payload.id}`);
          }
        });
    } else {
      this.props.createGroup(this.payload)
        .then((group) => {
          if (this.props.error) {
            errors = ValidationHelpers.handleServerError(this.props.error.data);
            this.setState({ errors, formInProgress: false }, () => {
              ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
            });
          } else {
            this.props.resetFormChanges();
            this.setState({ errors: {}, formInProgress: false });
            this.props.history.push(`/settings/organization/groups/${group.id}`);
          }
        });
    }
  }

  handleDeleteGroupConditions = (name) => {
    this.props.destroyGroupPreconditions(this.state.id)
      .then(() => this.handleToggle(name));
  }

  handleConfirmDeleteRequest = () => {
    const { id } = this.state;

    this.setState({ modalFormInProgress: true });
    this.props.destroyGroup(id).then((deletedGroup) => {
      if (!deletedGroup.preconditionsForGroupDelete || deletedGroup.preconditionsForGroupDelete.length === 0) {
        this.handleFormSuccess();
      }
    });
  };

  handleFormSuccess() {
    this.props.history.push('/settings/organization/groups');
  }

  handleModalReverseComplete = () => {
    this.setState({
      modalFormInProgress: false,
    });
  }

  handleToggle = (name) =>
    this.setState({ [name]: !this.state[name] });

  handleTypeChange = (typeId) =>
    this.setState({ typeId }, () => {
      // If typeId is equal to chat group, make sure we reset some data that they don't have access to that hey may have enabled previously
      if (typeId === Types.TYPE_GROUP_CHAT) {
        this.setState({
          afterHoursEnabled: false,
          autoResponse: '',
          businessHours: [],
          observesDst: true,
          selectedChannelIds: [],
          timeZoneId: null,
        });
      }
    });

  handleUpdateSelectedIds = (name, id) => {
    let selectedIds = [...this.state[name]];
    const addAction = !selectedIds.includes(id);
    const groupId = this.props.groups[id];

    if (addAction) {
      selectedIds = selectedIds.concat(id);
    } else if (name !== 'selectedGroupIds') {
      selectedIds = selectedIds.filter((selectedId) => selectedId !== id);
    }

    const newState = { [name]: selectedIds };

    if (name === 'selectedGroupIds') {
      newState.selectedUserIds = [
        ...new Set([...groupId.users, ...this.state.selectedUserIds]),
      ];
    }

    this.setState(newState, () => {
      if (name === 'selectedGroupIds') {
        // Send toast notification if we're inside of group form; importing members from a group
        NotificationActions.addNotification({
          body: `Imported ${this.props.groups[id].users.length} member${this.props.groups[id].users.length !== 1 ? 's' : ''} from ${this.props.groups[id].name} group successfully`, // eslint-disable-line max-len
          type: 'success',
        });
      }
    });

    this.props.handleFormChanges();
  }

  handleDefaultChannelChange = (name, value) => {
    if (!value && exists(this.props.groups[this.state.id])) {
      this.setState({ defaultChannelId: -1 });
    }
    this.setState({ [name]: value });
  }

  render() {
    const props = {
      actionType: this.state.mode === 'update' ? 'primary' : 'secondary',
      actionTitle: this.state.mode === 'update' ? 'Update Group' : 'Create Group',
      afterHoursEnabled: this.state.afterHoursEnabled,
      autoResponse: this.state.autoResponse,
      businessHours: this.state.businessHours,
      channelIds: this.props.channelIds,
      channels: this.props.channels,
      disabledTypeIds: this.state.disabledTypeIds,
      errors: this.state.errors,
      formInProgress: this.props.formInProgress,
      groupId: this.state.id,
      handleChange: this.handleChange,
      handleFormChanges: this.props.handleFormChanges,
      handleSubmit: this.handleSubmit,
      handleToggle: this.handleToggle,
      handleDeleteGroupConditions: this.handleDeleteGroupConditions,
      handleTypeChange: this.handleTypeChange,
      handleUpdateSelectedIds: this.handleUpdateSelectedIds,
      name: this.state.name,
      observesDst: this.state.observesDst,
      pageContainerRef: (pageContainer) => (this.pageContainer = pageContainer),
      pageLoading: this.state.pageLoading,
      pageTitle: this.state.mode === 'update' ? 'Edit Group' : 'Create Group',
      phones: this.props.phones,
      purpose: this.state.purpose,
      routedChannelsAreVisible: this.state.mode === 'update',
      selectedChannelIds: this.state.selectedChannelIds,
      selectedTagIds: this.state.selectedTagIds,
      selectedUserIds: [...this.state.selectedUserIds].sort(sortComparatorUsingUserLastNameFirstName(this.props.users)),
      tagIds: this.props.tagIds,
      tags: this.props.tags,
      timeZoneId: this.state.timeZoneId,
      timeZones: this.props.timeZones,
      typeId: this.state.typeId,
      users: this.props.users,
      viewAllMembers: this.state.viewAllMembers,
      viewGroups: this.state.viewGroups,
      mode: this.state.mode,
      isDeleteGroupModal: this.state.isDeleteGroupModal,
      deleteGroupPreConditions: this.props.deleteGroupPreConditions,
      handleConfirmDeleteRequest: this.handleConfirmDeleteRequest,
      destroyGroup: PropTypes.func.isRequired,
      modalFormInProgress: this.state.modalFormInProgress,
      handleModalReverseComplete: this.handleModalReverseComplete,
      defaultChannelEnabled: this.state.defaultChannelEnabled,
      defaultChannelId: this.state.defaultChannelId,
      handleDefaultChannelChange: this.handleDefaultChannelChange,
      userOrganization: this.props.userOrganization,
      channelOptions: [{ id: -1, value: '--' }].concat(this.props.channelIds.filter((id) => {
        const channel = this.props.channels[id];
        return !channel?.deleted && [Types.TYPE_SMS, Types.TYPE_TWILIO].includes(channel.typeId);
      }).map((id) => ({ id, value: this.props.channels[id]?.name }))),
    };

    return <OrganizationGroupForm {...props} />;
  }
}

OrganizationGroupFormContainer.propTypes = {
  channelIds: PropTypes.array.isRequired,
  channels: PropTypes.object.isRequired,
  createGroup: PropTypes.func.isRequired,
  fetchGroupsFormView: PropTypes.func.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  groupIds: PropTypes.array.isRequired,
  groups: PropTypes.object.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  phones: PropTypes.object.isRequired,
  tagIds: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  timeZones: PropTypes.object.isRequired,
  updateGroup: PropTypes.func.isRequired,
  destroyGroupPreconditions: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  error: PropTypes.object,
  handleFormChanges: PropTypes.func.isRequired,
  resetFormChanges: PropTypes.func.isRequired,
  deleteGroupPreConditions: PropTypes.object,
  destroyGroup: PropTypes.func,
  history: PropTypes.object,
  activeGroup: PropTypes.object,
  userOrganization: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  const { channel, group, phone, tag, timeZone, ui, user } = state;

  return {
    activeGroup: getActiveGroup(state, props.match?.params),
    channelIds: channel.channelIds,
    channels: channel.channels,
    error: ui.error,
    formInProgress: ui.formInProgress,
    groupIds: group.groupIds,
    groups: group.groups,
    pageLoading: group.pageLoading,
    phones: phone.phones,
    tagIds: tag.tagIds,
    tags: tag.tags,
    timeZones: timeZone.timeZones,
    user: getLoggedInUser(state),
    users: user.users,
    deleteGroupPreConditions: group.deleteGroupPreConditions,
    userOrganization: getLoggedInUserOrganization(state),
  };
};

const actions = {
  createGroup: GroupReducer.createGroup,
  fetchGroupsFormView: GroupReducer.fetchGroupsFormView,
  updateGroup: GroupReducer.updateGroup,
  destroyGroupPreconditions: GroupReducer.destroyGroupPreconditions,
  destroyGroup: GroupReducer.destroyGroup,
};

export default connect(mapStateToProps, actions)(unsavedChanges(OrganizationGroupFormContainer));
