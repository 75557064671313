import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../helpers/AxiosHelper';
import { APPOINTMENTS_PAGE_SIZE } from '../constants/AppConstants';

export const prescriptionSlice = createApi({
  reducerPath: 'prescriptions',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['PrescriptionCampaigns'],
  endpoints: (builder) => ({
    // Get all prescription campaigns
    getPrescriptionCampaigns: builder.query({
      query: ({ sortBy = 'name', sortOrder = 'asc', pageNo = 0, organizationId }) => {
        const url = `/prescription/${organizationId}/campaigns?&pageSize=${APPOINTMENTS_PAGE_SIZE}&sortOrder=${sortOrder}&page=${pageNo}&sortBy=${sortBy}`;
        return { url, method: 'get' };
      },
      providesTags: ['PrescriptionCampaigns'],
    }),
    // Get a single prescription campaign
    getPrescriptionCampaign: builder.query({
      query: ({ organizationId, prescriptionCampaignId }) => {
        const url = `/prescription/${organizationId}/campaigns/${prescriptionCampaignId}`;
        return { url, method: 'get' };
      },
      keepUnusedDataFor: 5,
      providesTags: ['PrescriptionCampaigns'],
    }),
    // Update a prescription campaign
    updatePrescriptionCampaign: builder.mutation({
      query: ({
        data,
        prescriptionCampaignId,
        organizationId,
      }) => {
        const url = `/prescription/${organizationId}/campaigns/${prescriptionCampaignId}`;
        return { url, method: 'PATCH', data };
      },
      invalidatesTags: ['PrescriptionCampaigns', 'Payors'],
    }),
    // Create a new prescription campaign
    createPrescriptionCampaign: builder.mutation({
      query: ({
        data,
        organizationId,
      }) => {
        const url = `/prescription/${organizationId}/campaigns`;
        return { url, method: 'POST', data };
      },
      invalidatesTags: ['PrescriptionCampaigns', 'Payors'],
    }),
    // Delete a prescription campaign
    deletePrescriptionCampaign: builder.mutation({
      query: ({
        prescriptionCampaignId,
        organizationId,
      }) => {
        const url = `/prescription/${organizationId}/campaigns/${prescriptionCampaignId}`;
        return { url, method: 'DELETE' };
      },
      invalidatesTags: ['PrescriptionCampaigns', 'Payors'],
    }),
    // Get payors
    getPayors: builder.query({
      query: ({
        organizationId,
        pageNo = 0,
        nameSearch,
      }) => {
        const url = `/prescription/${organizationId}/payors?pageSize=${APPOINTMENTS_PAGE_SIZE}&page=${pageNo}${nameSearch?.length > 2 ? `&q=${nameSearch}` : ''}`;
        return { url, method: 'GET' };
      },
      keepUnusedDataFor: 5,
      providesTags: ['Payors'],
      // omit pageNo argumen for lazy scroll
      serializeQueryArgs: ({ queryArgs: { organizationId, nameSearch } }) => ({
        organizationId,
        nameSearch,
      }),
      forceRefetch: (currentArg) =>
        currentArg.pageNo > 0,
      merge: (currentCacheData, responseData, _meta) => {
        if (_meta?.arg.pageNo > 0) {
          currentCacheData.results?.push(...responseData.results);
          return currentCacheData;
        }
        return responseData;
      },
    }),
    // Get prescriptions
    getPrescriptions: builder.query({
      query: (props) => {
        const {
          prescriptionSearch = '',
          prescriptionNumberSearch = '',
          payorIds = [],
          pageNo = 0,
          sortBy = 'createdAt',
          sortOrder = 'desc',
          organizationId,
          patientId,
          startDate,
          endDate,
          consentTypeIds,
          pageSize,
        } = props;
        let url = `/prescription/${organizationId}/groups?page=${pageNo}&pageSize=${pageSize}`;
        if (prescriptionSearch?.length > 0) url += `&prescriptionSearch=${encodeURIComponent(prescriptionSearch)}`;
        if (prescriptionNumberSearch?.length > 0) url += `&prescriptionNumberSearch=${encodeURIComponent(prescriptionNumberSearch)}`;
        if (sortBy) url += `&sortBy=${sortBy}`;
        if (sortOrder) url += `&sortOrder=${sortOrder}`;
        if (payorIds?.length > 0) url += `&payorIds=${JSON.stringify(payorIds)}`;
        if (consentTypeIds?.length > 0) url += `&consentTypeIds=${JSON.stringify(consentTypeIds)}`;
        if (patientId) url += `&patientId=${patientId}`;
        url = encodeURI(url);
        return {
          url,
          method: 'GET',
          ...startDate && endDate && {
            params: { startDate, endDate },
          },
        };
      },
    }),
  }),
});

export const {
  useGetPrescriptionCampaignsQuery,
  useGetPrescriptionCampaignQuery,
  useGetPrescriptionCampaignsQueryState,
  useDeletePrescriptionCampaignMutation,
  useUpdatePrescriptionCampaignMutation,
  useCreatePrescriptionCampaignMutation,
  useGetPayorsQuery,
  useGetPrescriptionsQuery,
  useLazyGetPayorsQuery,
} = prescriptionSlice;

export default prescriptionSlice;
