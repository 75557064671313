import React from 'react';
import { Button, Icon } from 'rhinostyle';
import cx from 'classnames';

import { BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD } from '../constants/AppConstants';

const PaginationControls = (props) => {
  const {
    handlePagination,
    disabled,
    pageNumber,
    totalCount,
    pageSize,
    isTop,
    isInbox,
  } = props;
  const totalPages = totalCount && Math.ceil(totalCount / pageSize);
  const isLastPage = pageNumber === totalPages - 1;
  const pageItemIndexTo = isLastPage ? totalCount : (pageNumber * pageSize) + pageSize;
  const pageItemIndexFrom = pageNumber * pageSize + 1;
  const isNextDisabled = disabled || pageNumber === totalPages - 1;
  const isPreviousDisabled = disabled || pageNumber === 0;
  const previousCaretClass = isPreviousDisabled ? 'pagination__caret--disabled' : '';
  const nextCaretClass = isNextDisabled ? 'pagination__caret--disabled' : '';

  const shouldDisplayPagination = isTop || (!isTop && totalCount > pageSize && pageItemIndexTo > BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD
  );

  const paginationClasses = cx('pagination__wrapper pagination__wrapper', {
    'pagination__wrapper--inbox': isInbox,
  });

  return shouldDisplayPagination && (
    <div className={paginationClasses}>
      <span className="u-m-r-small">{`${pageItemIndexFrom} - ${pageItemIndexTo} of ${totalCount} ${!isInbox ? `Item${totalCount === 1 ? '' : 's'}` : ''}    `}</span>
      <div className="pagination__controls">
        <Button
          type="link"
          size="small"
          iconOnly
          disabled={isPreviousDisabled}
          onClick={() => handlePagination('previous')}
          className={previousCaretClass}
          data-cypress="previousPage"
        >
          <Icon icon="caret-left" />
        </Button>
        <span><span className="u-font-weight-bold">{pageNumber + 1}</span>{` of ${totalPages}`}</span>
        <Button
          type="link"
          iconOnly
          size="small"
          disabled={isNextDisabled}
          onClick={() => handlePagination('next')}
          className={nextCaretClass}
          data-cypress="nextPage"
        >
          <Icon icon="caret-right" />
        </Button>
      </div>
    </div>
  );
};

export default PaginationControls;
