import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Dropdown } from 'rhinostyle';

const EmojiPickerDropdown = (props) => {
  const {
    dropdownPosition,
    handleInputChange,
    inputName,
    inputRef,
    inputSection,
    inputValue,
    onEmojiSelect,
    wrapperClassName,
  } = props;

  const [isPickerVisible, setIsPickerVisible] = useState(true);
  const inputValueRef = useRef(inputValue);
  const onReverseComplete = useRef(handleOnReverseComplete);

  function handleOnReverseComplete() {
    if (onReverseComplete?.current) {
      setIsPickerVisible(false);
      setIsPickerVisible(true);
    }
  }

  useEffect(() => () => {
    onReverseComplete.current = null;
  }, []);

  useEffect(() => {
    inputValueRef.current = inputValue;
  }, [inputValue]);

  function handleEmojiSelect(e) {
    if (onEmojiSelect) {
      onEmojiSelect(e);
    } else {
      const emoji = e.native;
      const cursorIndex = inputRef.current.selectionStart;
      // insert emoji at cursor index
      const updatedMessage = inputValueRef.current.slice(0, cursorIndex) + emoji + inputValueRef.current.slice(cursorIndex);
      if (inputSection) {
        handleInputChange(inputSection, inputName, updatedMessage);
      } else {
        handleInputChange(inputName, updatedMessage);
      }
      // update cursor position
      inputRef.current.focus();
      inputRef.current.setSelectionRange(
        cursorIndex + emoji.length,
        cursorIndex + emoji.length,
      );
    }
  }

  const renderPicker = () => {
    if (isPickerVisible) {
      return (
        <div className="emoji-picker">
          <Picker
            data={data}
            onEmojiSelect={handleEmojiSelect}
            theme="light"
            maxFrequentRows={1}
            autoFocus
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={wrapperClassName || 'emoji-picker__dropdown__wrapper'}>
      <Dropdown
        position={dropdownPosition || 'top'}
        title="Add emoji to message"
        icon="add-emoji"
        dataFeatureTag="messaging-add_emoji"
        disableScroll
        hideCaret
        wide
        reset
        onReverseComplete={handleOnReverseComplete}
      >
        {renderPicker()}
      </Dropdown>
    </div>
  );
};

EmojiPickerDropdown.propTypes = {
  dropdownPosition: PropTypes.string,
  handleInputChange: PropTypes.func,
  inputName: PropTypes.string,
  inputRef: PropTypes.object,
  inputValue: PropTypes.string,
  inputSection: PropTypes.string,
  onEmojiSelect: PropTypes.func,
  wrapperClassName: PropTypes.string,
};

export default EmojiPickerDropdown;
