import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as queryString from 'query-string';
import { useGetPrescriptionCampaignsQuery } from '../services/prescriptionService';

const usePrescriptionCampaign = () => {
  const params = useParams();
  const { search } = useLocation();
  const organizationId = useSelector((state) => state.auth.currentOrg);
  const { pageNo = 0, sortOrder = 'asc', sortBy = 'name' } = queryString.parse(search);

  // Set options for useGetPrescriptionCampaignsQuery hook
  const options = {
    sortOrder,
    sortBy,
    pageNo: Number(pageNo),
    organizationId,
  };

  return useGetPrescriptionCampaignsQuery(options, {
    skip: !params?.prescriptionCampaignId,
    selectFromResult: (result) => ({
      prescriptionCampaign: result?.data?.results?.find(
        (prescriptionCampaign) => prescriptionCampaign.id === Number(params?.prescriptionCampaignId),
      ),
    }),
  });
};

export default usePrescriptionCampaign;
