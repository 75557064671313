import React from 'react';
import PropTypes from 'prop-types';

import AppointmentCampaignSummaryReminder from './AppointmentCampaignSummaryReminder';
import { useSortAppointmentReminders } from '../hooks';

const AppointmentCampaignSummaryReminders = ({
  appointmentCampaign,
  variableOptions,
}) => {
  const { sortedReminders } = useSortAppointmentReminders(appointmentCampaign, false);
  return (
    <div className="u-m-t-large">
      <div>
        {sortedReminders.map((reminder, index) => (
          <AppointmentCampaignSummaryReminder
            key={reminder.name}
            reminder={reminder}
            isInitialReminder={index === 0}
            variableOptions={variableOptions}
            attachments={appointmentCampaign.attachments.filter((attachment) => attachment.appointmentEventTypeId === reminder.typeId)}
          />
        ))}
      </div>
    </div>
  );
};

AppointmentCampaignSummaryReminders.propTypes = {
  appointmentCampaign: PropTypes.object.isRequired,
  variableOptions: PropTypes.object,
};

export default AppointmentCampaignSummaryReminders;
