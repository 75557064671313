import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  Dropdown,
  DropdownMenuItem,
  FormLabel,
  LoaderCircle,
  Modal,
  ModalBody,
  ModalHeader,
  Icon,
  ModalFooter,
  UtilityInlineGrid,
  UtilityList,
  UtilityListItem,
} from 'rhinostyle';

import { JobType } from 'rhinotilities/lib/core/enums/JobType';
import { TEMPLATE_MESSAGE_VARIABLE_OPTIONS } from '../constants/VariableMessageConstants';
import { FORM_VIEW } from '../constants/UserPermissionsConstants';
import { AppConstants } from '../constants';
import CharacterCount from './CharacterCount';
import VariableInput from './VariableInput';
import FormLibraryModal from './FormLibraryModal';
import { UserHelpers, StringHelpers, DataHelpers, AttachmentHelpers } from '../helpers';
import OrganizationMessageTemplateModalContainer from '../containers/OrganizationMessageTemplateModalContainer';
import VCardModal from './VCardModal';
import store from '../store';
import { getLoggedInUser, getLoggedInUserOrganization } from '../selectors/userSelectors';
import { UploadActions } from '../actions';
import * as InboxReducer from '../reducers/inboxReducer';
import EmojiPickerDropdown from './EmojiPickerDropdown';
import { VCARD_FILE_TYPE } from '../constants/AppConstants';
import { TYPE_APPOINTMENT_CANCELLED } from '../constants/Types';
import { isPhoneChannelTypeId } from '../helpers/ChannelHelpers';
import { getIsVCard } from '../helpers/AttachmentHelpers';
import { fetchChannelsThunk } from '../reducers/channelReducer';

class BulkMessageModal extends React.Component {
  state = {
    attachments: [],
    activeUpload: false,
    message: '',
    sendingMessage: false,
    isPreviewDisplayed: false,
    organizationMessageTemplateModalOpen: false,
    vCardModalOpen: false,
    activeFromChannelId: -1,
    fromChannelOptions: [],
    formInProgress: false,
    channelValidationError: false,
    templateToAdd: '',
    sharelinkFiles: [],
    messageForms: [],
    formLibraryModalOpen: false,
    emojiToAdd: null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.isModalOpen && !prevProps.isModalOpen) {
      this.props.fetchChannels();
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const fromChannelOptions = BulkMessageModal.getRoutedChannelsOfMember(nextProps);
    return {
      fromChannelOptions,
    };
  }

  static getRoutedChannelsOfMember = (props) => {
    const channels = Object.keys(props.channels).map((i) => props.channels[i]);
    const fromChannelOptions = [];
    const fromChannels = channels.filter((channel) => { // eslint-disable-line no-unused-vars
      const { typeId, route, deleted } = channel;
      const { currentUser } = props;
      const formattedChannel = DataHelpers.cloneDeep(channel);
      if (route?.type === 'member' && route.userId === props.currentUser.id && !deleted && isPhoneChannelTypeId(typeId)) {
        formattedChannel.value = channel.name;
        fromChannelOptions.push(formattedChannel);
        return true;
      }
      if (route?.type === 'group' && currentUser?.groups?.includes(route.groupId) && !deleted && isPhoneChannelTypeId(typeId)) {
        formattedChannel.value = channel.name;
        fromChannelOptions.push(formattedChannel);
        return true;
      }
      return false;
    });
    return fromChannelOptions;
  }

  handleMessageTextChange = (message) => {
    this.setState({ message });
  }

  handleSendMessage = () => {
    if (this.state.activeFromChannelId === -1) {
      this.setState({ channelValidationError: true });
    } else {
      this.setState({ formInProgress: true, channelValidationError: false });
      this.handleSendBulkMessage();
    }
  }

  clearMessageTextAndChannel = () => {
    this.setState({ formInProgress: false, activeFromChannelId: -1, activeUpload: false, message: '', attachments: [], messageForms: [] });
  }

  getVariables = () => TEMPLATE_MESSAGE_VARIABLE_OPTIONS.map((el) => el.variable.toLowerCase());

  handleToggle = () => {
    this.clearMessageTextAndChannel();
    this.props.handleToggle();
  }

  handleAddFile = (event) => {
    this.setState({ activeUpload: true });
    const { userOrganization } = this.props;
    const uploadOpts = {
      file: event.target.files[0],
      currentAttachments: [...this.state.attachments],
      standard: false,
      orgId: userOrganization.id,
    };

    AttachmentHelpers.handleAddFile(uploadOpts, this.handleUploadComplete);
  }

  handleUploadComplete = (newState) => {
    const { attachments, sharelinkFiles } = this.state;
    const updateSharelinkFiles = [...sharelinkFiles];
    const updateAttachments = [...attachments];
    const newAttachment = newState.attachments
      .filter((key) => !Object.keys(attachments).includes(key))
      .pop();
    if (newAttachment?.isSharelink) {
      const exists = sharelinkFiles.findIndex((file) => file.attachmentUrl === newAttachment.attachmentUrl) > -1;
      if (!exists) {
        updateSharelinkFiles.push(newAttachment);
        this.setState({
          activeUpload: false,
          sharelinkFiles: updateSharelinkFiles,
        });
      }
    } else {
      updateAttachments.push(newAttachment);
      this.setState({
        activeUpload: false,
        attachments: updateAttachments,
      });
    }
  }

  handleRemoveAttachment = (index, attachment) => {
    const { attachments, sharelinkFiles, messageForms } = this.state;
    const { isSharelink, formId } = attachment;
    let files;
    if (isSharelink) {
      // attachments are listed first in this array
      const adjustedIndex = index - attachments.length;
      files = [...sharelinkFiles];
      files.splice(adjustedIndex, 1);
      this.setState({ sharelinkFiles: files });
    } else if (formId) {
      files = [...messageForms];
      files.splice(index, 1);
      this.setState({ messageForms: files });
    } else {
      files = [...attachments];
      files.splice(index, 1);
      this.setState({ attachments: files });
    }
  }

  handleTemplateDataAppend = async (templateToAdd, attachments) => {
    const shapedAttachments = [];
    const filesToBecomeSharelinks = [];
    const sharelinkFiles = [];
    const shapedForms = [];
    const { userOrganization } = this.props;
    const standardFileSize = false;
    const orgId = userOrganization.id;
    // separate attachments from files that need to become sharelinks
    for (let i = 0; i < attachments.length; i++) {
      const attachment = attachments[i];
      if (this.props.types[attachment.attachmentTypeId]?.value === 'form') {
        if (!this.state.messageForms.find((form) => form.formId === attachment.attachmentUrl)) {
          shapedForms.push({
            formId: attachment.attachmentUrl,
            title: attachment.name,
          });
        }
      } else if (this.props.types[attachment.attachmentTypeId]?.value === VCARD_FILE_TYPE) {
        shapedAttachments.push({
          attachmentUrl: attachment.attachmentUrl,
          type: attachment.type,
          name: attachment.name,
          bytes: parseInt(attachment.bytes, 10),
        });
      } else if (AttachmentHelpers.shouldAttachmentBeSharelink({ attachment, standardFileSize })) {
        filesToBecomeSharelinks.push({
          attachmentUrl: attachment.attachmentUrl,
          name: attachment.name,
          type: DataHelpers.getFileMimeType(attachment.name),
          isSharelink: true,
        });
      } else {
        shapedAttachments.push({
          attachmentUrl: attachment.attachmentUrl,
          type: DataHelpers.getFileMimeType(attachment.name),
          name: attachment.name,
          bytes: parseInt(attachment.bytes, 10),
        });
      }
    }

    // create sharelinks if needed
    await Promise.all(filesToBecomeSharelinks.map(async (file) => {
      const partialUrl = `${process.env.REACT_APP_PUBLIC_TEMPLATE_FILES_DOMAIN}/${orgId}/${file.attachmentUrl}`;
      const { data: sharelink } = await UploadActions.createSharelink(partialUrl);
      const shapedSharelinkData = {
        ...file,
        sharelink: sharelink.url,
      };
      return sharelinkFiles.push(shapedSharelinkData);
    }));

    // Handle template append for both patient and secure, then clear templateToAdd
    this.setState({
      templateToAdd,
      attachments: this.state.attachments.concat(shapedAttachments),
      sharelinkFiles: this.state.sharelinkFiles.concat(sharelinkFiles),
      messageForms: this.state.messageForms.concat(shapedForms),
    }, () => {
      this.setState({ templateToAdd: '' });
    });
  }

  handleUseTemplateClick = () => {
    this.setState({
      organizationMessageTemplateModalOpen: true,
    });
  }

  closeModal = () => {
    this.setState({
      organizationMessageTemplateModalOpen: false,
    });
  };

  toggleVCardModal = () => {
    this.setState((prevState) => ({
      vCardModalOpen: !prevState.vCardModalOpen,
    }));
  }

  handleEmojiClick = (emojiObject) => {
    this.setState({
      emojiToAdd: emojiObject,
    }, () => {
      this.setState({ emojiToAdd: null });
    });
  }

  handleFromChannelSelect = (channelId) => {
    this.setState({ activeFromChannelId: channelId, channelValidationError: false });
  };

  getActiveAppointments = (appointments) => appointments?.filter((appointment) => appointment.appointmentStatusTypeId !== TYPE_APPOINTMENT_CANCELLED) ?? [];;

  appendVCardMessageContext = (vCardIds, messageContext) => (
    messageContext.map((context) => ({
      ...context,
      vCardIds,
    })));

  appendContactListIdToMessageContext = (contactListId, messageContext) => (
    messageContext.map((context) => ({
      ...context,
      contactListId,
    })));

  getMessageContext = (contacts) => {
    if (this.props.type === 'appointments') {
      return contacts.map((contact) => ({ appointmentId: contact.appointmentId, toUserId: contact.id }));
    } return null;
  };

  handleModalClosed = () => {
    if (this.state.sendingMessage) {
      this.setState({ sendingMessage: false }, () => {
        this.clearMessageTextAndChannel();
        if (this.props.clearSelectedContacts) {
          this.props.clearSelectedContacts();
        }
      });
    }
  }

  handleFormAppend = (form) => {
    this.setState((prevState) => ({
      ...!this.state.messageForms.find((messageForm) => messageForm.formId === form.formId) && {
        messageForms: [...prevState.messageForms, form],
      },
      formLibraryModalOpen: false,
    }));
  };

  handleVCardAppend = (vCardAttachments) => {
    const { attachments } = this.state;
    const nonVCardAttachments = attachments.filter((attachment) => attachment.type !== VCARD_FILE_TYPE);
    this.setState({
      vCardModalOpen: false,
      attachments: [...nonVCardAttachments, ...vCardAttachments],
    });
  }

  toggleFormLibraryModal = () => {
    this.setState((prevState) => ({
      formLibraryModalOpen: !prevState.formLibraryModalOpen,
    }));
  }

  togglePreview = () => {
    this.setState((prevState) => ({ isPreviewDisplayed: !prevState.isPreviewDisplayed }));
  }

  messageContainsVariables = () => {
    const { message } = this.state;
    const flatVariables = this.getVariables();
    // Check if current message contains variables
    return message.split(/({.*?})/).some((el) => flatVariables.includes(el.toLowerCase()));
  }

  handleSendBulkMessage = async () => {
    let contactListId = null;
    const hasContactListCreateRoute = this.props?.location?.pathname.includes('/contact-list/create');
    const hasContactListEditRoute = this.props?.location?.pathname.includes('/contact-list');
    if (hasContactListCreateRoute) {
      contactListId = this.props.contactListId;
    } else if (hasContactListEditRoute) {
      contactListId = Number(this.props?.location?.pathname.split('/').pop());
    }
    const { sharelinkFiles, messageForms, attachments } = this.state;
    const { currentUser, userOrganization } = this.props;
    let messageContext;
    if (this.props.type === 'appointments') {
      messageContext = this.getMessageContext(this.props.contacts);
    } else {
      messageContext = this.props.contacts.map((recipient) => ({ toUserId: parseInt(recipient.id, 10) }));
    }

    const payload = {
      type: JobType.RhinoBlast,
      organizationId: userOrganization.id,
      channelId: this.state.activeFromChannelId,
      fromUserId: currentUser.id,
      text: this.state.message.trim(),
      context: messageContext,
      attachments: this.state.attachments,
    };

    // if sharelinks need to be sent, concat with the text
    if (sharelinkFiles.length > 0) {
      sharelinkFiles.forEach((file) => {
        payload.text += `${payload.text.length === 0 ? '' : '\n'}${file.sharelink}`;
      });
    }
    if (messageForms.length > 0) {
      payload.formTemplates = messageForms.map((form) => form.formId);
    }
    if (attachments.length > 0) {
      const vCardIds = [];
      for (let i = 0; i < attachments.length; i += 1) {
        if (getIsVCard(attachments[i])) {
          vCardIds.push(attachments[i].attachmentUrl);
        }
      }
      const filteredAttachments = attachments.filter((attachment) => !getIsVCard(attachment));
      payload.attachments = filteredAttachments;
      payload.context = this.appendVCardMessageContext(vCardIds, payload.context);
    }
    if (contactListId) {
      payload.context = this.appendContactListIdToMessageContext(contactListId, payload.context);
    }
    this.setState({ sendingMessage: true }, () => {
      // This will fire when the message contains an appointment variable & none of the selected users have appointments & is based on discussions with product
      if (this.props.contacts.length === 0) {
        this.props.handleToggle();
        if (DataHelpers.exists(this.props.handleCloseUserSearchModal)) {
          this.props.handleCloseUserSearchModal();
        }
      } else {
        this.props.createInboxEvent(payload)
          .then(() => {
            this.props.handleToggle();
            if (DataHelpers.exists(this.props.handleCloseUserSearchModal)) {
              this.props.handleCloseUserSearchModal();
            }
          });
      }
    });
  }

  renderAttachmentIcons = (attachment) => {
    let icon = 'attachment';
    if (getIsVCard(attachment)) {
      icon = 'vcard';
    }
    if (attachment.formId) {
      icon = 'signature';
    }
    return (<Icon icon={icon} />);
  }

  renderChatArea = () => {
    const attachmentsAndSharelinks = [...this.state.attachments, ...this.state.sharelinkFiles, ...this.state.messageForms];
    if (this.state.fromChannelOptions.length > 0) {
      return (
        <>
          <div className="u-flex">
            <OrganizationMessageTemplateModalContainer
              store={store}
              handleTemplateDataAppend={this.handleTemplateDataAppend}
              open={this.state.organizationMessageTemplateModalOpen}
              closeModal={this.closeModal}
              templateType="systemAndContact"
              bulkMessageMode
            />
            {this.props.isRhinoformEnabled && (
              <FormLibraryModal
                open={this.state.formLibraryModalOpen}
                closeModal={this.toggleFormLibraryModal}
                handleFormAppend={this.handleFormAppend}
                isBulkMessage
              />
            )}
            <div className="broadcast-modal__message">
              <VariableInput
                name="message"
                handleMessageChange={this.handleMessageTextChange}
                variableOptions={TEMPLATE_MESSAGE_VARIABLE_OPTIONS}
                message={this.state.message}
                isPreviewDisplayed={this.state.isPreviewDisplayed}
                template={this.state.templateToAdd}
                emoji={this.state.emojiToAdd}
                className="broadcast-modal__message-compose"
                placeholder="Message Selected Contacts"
              />
            </div>
          </div>
          {this.props.userOrganization.isOfficeVCardEnabled && (
            <VCardModal
              open={this.state.vCardModalOpen}
              closeModal={this.toggleVCardModal}
              handleVCardAppend={this.handleVCardAppend}
              currentOrganization={this.props.userOrganization}
              selectedVCards={AttachmentHelpers.filterAttachments(this.state.attachments, AppConstants.VCARD_FILE_TYPE)}
            />
          )}
          {(this.state.activeUpload || attachmentsAndSharelinks.length > 0) && (
            <div className="broadcast-modal__message__attachments">
              {attachmentsAndSharelinks.length > 0 && (
                <UtilityList className="u-m-b-0">
                  {attachmentsAndSharelinks.map((attachment, key) => (
                    <UtilityListItem className="u-text-small" key={attachment.formId ? attachment.formId : attachment.attachmentUrl}>
                      <Button
                        reset
                        className="u-text-primary"
                        onClick={() => this.handleRemoveAttachment(key, attachment)}
                      >
                        {this.renderAttachmentIcons(attachment)}
                        &nbsp; {StringHelpers.formatFilename(attachment.formId ? attachment.title : attachment.name)}&nbsp;&nbsp;
                        <span className="u-text-muted"><Icon size="small" icon="close" /></span>
                      </Button>
                    </UtilityListItem>
                  ))}
                </UtilityList>
              )}
              {this.state.activeUpload && <LoaderCircle size="xsmall" className="u-text-primary" />}
            </div>
          )}
        </>
      );
    } else {
      return (
        <Alert title="No Channels" className="broadcast-modal__alert">
          You need access to a channel to communicate via Rhinogram.
        </Alert>
      );
    }
  }

  renderMessageComposeActions = () => {
    const iconClass = 'convo__message__icon-button';
    return (
      <div className="u-flex u-flex-align-items-center">
        <EmojiPickerDropdown
          wrapperClassName="emoji-picker__dropdown__wrapper u-m-l-small u-m-r-small"
          dropdownPosition="botom"
          onEmojiSelect={this.handleEmojiClick}
        />
        <input
          className="u-sr-only"
          type="file"
          ref={(c) => (this.uploaderInput = c)}
          accept={AppConstants.UPLOAD_INTERNAL_FILE_TYPES}
          onClick={() => { this.uploaderInput.value = null; }}
          onChange={this.handleAddFile}
          data-feature-tag="attachFileButtonInputField"
        />
        <Button
          title="Use Template"
          className={iconClass}
          onClick={this.handleUseTemplateClick}
          reset
          data-feature-tag="bulk_messaging-use_template"
        >
          <Icon icon="chat" />
        </Button>
        <Button
          title="Attach Files"
          className={iconClass}
          onClick={() => this.uploaderInput.click()}
          reset
          data-feature-tag="bulk_messaging-add-file"
        >
          <Icon icon="attachment" />
        </Button>
        {this.props.userOrganization?.isOfficeVCardEnabled && (
          <Button
            title="Attach VCard"
            className={iconClass}
            onClick={this.toggleVCardModal}
            reset
            data-feature-tag="bulk_messaging-add-vCard"
          >
            <Icon className="vcard-icon-size" icon="vcard" />
          </Button>
        )}

        {this.props.isRhinoformEnabled && UserHelpers.userHasAnyOfPermissions([FORM_VIEW]) && (
          <Button
            title="Send Form"
            className={iconClass}
            onClick={this.toggleFormLibraryModal}
            reset
            data-feature-tag="bulk_messaging-add-form"
          >
            <Icon icon="signature" />
          </Button>
        )}
      </div>
    );
  }

  render() {
    const fromChannelName = this.state.activeFromChannelId !== -1
      ? this.state.fromChannelOptions.filter((channel) => channel.id === this.state.activeFromChannelId)[0].name
      : '';
    const hasChannelOptions = this.state.fromChannelOptions.length > 0;
    const confirmMessageDisabled = (!hasChannelOptions ||
      (this.state.message.trim().length === 0 && this.state.attachments.length === 0 &&
        this.state.sharelinkFiles.length === 0 && this.state.messageForms.length === 0) || this.state.activeUpload);
    const messageHeaderClass = `u-m-b-0 ${!hasChannelOptions ? 'header__disabled' : ''}`;
    const eyeIconClassName = this.state.isPreviewDisplayed ? 'u-text-primary u-text-right' : 'u-text-right';
    return (
      <>
        <Modal open={this.props.isModalOpen} modalClass="broadcast-modal__container" modalDialogClass="broadcast-modal" onReverseComplete={this.handleModalClosed}>
          <ModalHeader
            onClose={this.handleToggle}
            title="Message Contacts"
          />
          <div className="app-page__header broadcast-modal__header">
            {this.props.contacts.length > 0 ?
              <div className="broadcast-modal__header__title">Contacts</div>
              :
              <div className="broadcast-modal__header__title u-text-danger">No Contacts</div>}
          </div>
          {this.props.contacts.length === 0 ? (
            <div className="broadcast-modal__nocontacts">
              <ModalBody>
                <div className="u-text-center u-text-medium">To send a RhinoBlast message, one or more Contacts must be added</div>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="primary"
                  className="broadcast-modal__add-contacts-button u-text-small"
                  onClick={this.handleToggle}
                >Add Contacts
                </Button>
              </ModalFooter>
            </div>
          ) : (
            <ModalBody>
              <Button
                reset
                className="u-text-small u-text-primary"
                onClick={this.handleToggle}
                data-feature-tag="addMoreContacts"
              >Back to Contact Selection
              </Button>
              <div className="u-text-small broadcast-modal__contacts-count" data-feature-tag="upperContactCount">
                ({this.props.contacts.length} Contact{this.props.contacts.length !== 1 && 's'} Added)
              </div>
              <div className="bulk-warning-box">
                <p className="bulk-warning-text">Make sure the appropriate consent has been granted before sending a blast message.</p>
              </div>
              <div className="variable-message__header u-flex-justify-between">
                <FormLabel id="messagetext" className={messageHeaderClass} required>Message</FormLabel>
                {this.messageContainsVariables() && (
                  <Button reset className={eyeIconClassName} onClick={this.togglePreview}> <Icon size="medium" icon="preview" /> </Button>
                )}
              </div>
              <div className="broadcast-modal__convo-footer">
                {this.renderChatArea()}
              </div>
              <div className="convo__footer__actions--broadcast-modal">
                {this.renderMessageComposeActions()}
              </div>
              {hasChannelOptions && (
                <>
                  <div className="broadcast-modal__actions">
                    <div className="broadcast-modal__channels-dropdown column-8@medium">
                      <FormLabel id="fromchannel" required>From Channel:</FormLabel>
                    </div>
                    <div className="character_count column-4@medium">
                      {this.state.message.length > 0 && <CharacterCount length={this.state.message.length} />}
                    </div>
                  </div>
                  <div className="row">
                    <div className="broadcast-modal__channel">
                      <Dropdown
                        activeKey={this.state.activeFromChannelId}
                        position="top"
                        onSelect={(value) => this.handleFromChannelSelect(value)}
                        label={this.state.activeFromChannelId !== -1 ? fromChannelName : '--'}
                        wide
                        title="selectChannel"
                      >
                        {this.state.fromChannelOptions.map((p) => <DropdownMenuItem key={p.id} id={p.id} label={p.name} labelDesc={p.description} />)}
                      </Dropdown>
                      {this.state.channelValidationError && (
                        <p className="u-text-danger broadcast-modal__channel__message">Channel is Required</p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </ModalBody>
          )}
          {this.props.contacts.length > 0 && (
            <ModalFooter className="broadcast-modal__footer">
              <UtilityInlineGrid align="right">
                <div className="column-9@medium grid_class">&nbsp;</div>
                <div className="column-3@medium u-text-right button_grid_class">
                  <Button
                    disabled={confirmMessageDisabled}
                    loading={this.state.formInProgress}
                    onClick={() => this.handleSendMessage()}
                    type="secondary"
                    data-feature-tag="confirmMessage"
                  >
                    Confirm Message
                  </Button>
                  <p className="broadcast-modal__button-description" align="center" data-feature-tag="lowerContactCount">
                    This will message <span className="u-text-danger">({this.props.contacts.length})</span> contacts.
                  </p>
                </div>
              </UtilityInlineGrid>
            </ModalFooter>
          )}
        </Modal>
      </>
    );
  }
}

BulkMessageModal.propTypes = {
  contacts: PropTypes.array.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  userOrganization: PropTypes.object,
  createInboxEvent: PropTypes.func.isRequired,
  clearSelectedContacts: PropTypes.func,
  type: PropTypes.string,
  handleCloseUserSearchModal: PropTypes.func,
  isRhinoformEnabled: PropTypes.bool,
  types: PropTypes.object,
  contactListId: PropTypes.number,
  location: PropTypes.object,
  fetchChannels: PropTypes.func,
};

BulkMessageModal.defaultProps = {
  type: 'contacts',
};

const mapStateToProps = (state) => {
  const { channel, form, type, phone } = state;

  return {
    channels: channel.channels,
    currentUser: getLoggedInUser(state),
    userOrganization: getLoggedInUserOrganization(state),
    isRhinoformEnabled: !!form.org?.isRhinoformEnabled,
    types: type.types,
    phones: phone.phones,
  };
};

const actions = {
  createInboxEvent: InboxReducer.createInboxEvent,
  fetchChannels: fetchChannelsThunk,
};

export default connect(mapStateToProps, actions)(BulkMessageModal);
