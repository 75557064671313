import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { LocationHelpers, UserHelpers } from '../helpers';
import * as AuthReducer from '../reducers/authReducer';
import * as UIReducer from '../reducers/uiReducer';
import calculateViewportHeight from '../helpers/ViewportHelpers';
import { isMobile } from '../helpers/BrowserHelpers';
import Login from '../components/Login';
import { createCookie, readCookie, destroyCookie } from '../services/StorageService';
import OutboundPostMessageService from '../services/OutboundPostMessageService';
import { PASSWORD_STRENGTH_VALIDATION_MESSAGE } from '../constants/AppConstants';

class LegacyLoginContainer extends React.Component {
  state = this.initialState;

  get initialState() {
    return {
      loginEmail: readCookie('rhinogram-loginEmail') || '',
      saveLoginEmail: !!readCookie('rhinogram-loginEmail'),
      password: '',
      newPassword: '',
      confirmPassword: '',
      isUpdatePasswordInProgress: false,
    };
  }

  componentDidMount() {
    const params = LocationHelpers.getQueryParams(window.location.search);
    document.title = `Rhinogram | ${LocationHelpers.getPageTitle(window.location.pathname)}`;

    // change password page
    if (Object.prototype.hasOwnProperty.call(params, 'loginId') &&
        Object.prototype.hasOwnProperty.call(params, 'token')) {
      this.props.checkResetPassword(params.loginId, params.token);
    }

    this.setState(params, () => {
      this.loginFormAutoFocus();
      this.notifyNative();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authMode !== this.props.authMode) {
      this.setState(this.initialState, () => { // eslint-disable-line react/no-did-update-set-state
        if (this.password) this.password.value = '';
        if (this.newPassword) this.newPassword.value = '';
        if (this.confirmPassword) this.confirmPassword.value = '';

        switch (this.props.authMode) {
          case 'updatePassword':
            if (this.newPassword && !isMobile()) this.newPassword.focus();
            break;
          default: // login form
            this.loginEmail.value = this.state.loginEmail;
            this.loginFormAutoFocus();
        }

        calculateViewportHeight();
      });
    }
  }

  componentWillUnmount() {
    this.notifyNativeLoginExited();
  }

  loginFormAutoFocus() {
    if (this.loginEmail && !isMobile()) {
      if (this.loginEmail.value) this.password.focus();
      else this.loginEmail.focus();
    }
  }

  /**
   * React Native injects window.ReactNativeWebView when
   * the bridge is ready. Wait for it to be ready before
   * firing on the third attempt.
   */
  notifyNative = (notifyNativeAttempts = 0) => {
    const attempts = notifyNativeAttempts + 1;

    if (OutboundPostMessageService.isBridgeReady() || attempts === 3) {
      OutboundPostMessageService.postMessage({
        type: 'loginPageLoaded',
      });
    } else if (attempts < 3) {
      setTimeout(() => this.notifyNative(attempts), 500);
    }
  }

  notifyNativeLoginExited() {
    OutboundPostMessageService.postMessage({
      type: 'loginPageExited',
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  handleForgotPasswordClick = () => {
    this.props.history.push('/forgot-password');
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      switch (this.props.authMode) {
        case 'updatePassword':
          this.handleUpdatePassword();
          break;
        default:
          this.handleLogin();
      }
    }
  }

  handleChangeSaveLoginEmail = (name, value) => {
    this.setState({ [name]: value });
  };

  updateCookies() {
    const { loginEmail, saveLoginEmail } = this.state;

    if (saveLoginEmail) createCookie('rhinogram-loginEmail', loginEmail, 30);
    else destroyCookie('rhinogram-loginEmail');
  }

  handleLogin = () => {
    // clear existing errors on login form submission
    this.props.setError(null);
    this.props.login(this.state.loginEmail, this.state.password)
      .then((nextPath) => {
        if (nextPath) {
          this.props.history.push(nextPath);
        }
        this.updateCookies();
      });
  };

  handleUpdatePassword = () => {
    const { loginId, token, newPassword, confirmPassword } = this.state;

    if (!UserHelpers.isSecurePassword(newPassword)) {
      this.props.setError({ data: { message: PASSWORD_STRENGTH_VALIDATION_MESSAGE } });
      return;
    }

    if (newPassword !== confirmPassword) {
      this.props.setError({ data: { message: 'Passwords do not match' } });
      return;
    }

    this.setState({
      isUpdatePasswordInProgress: true,
    });
    this.props.updatePassword(loginId, newPassword, token)
      .then((nextPath) => {
        if (nextPath) {
          this.props.history.push(nextPath);
        }
        this.updateCookies();
      });
  }

  handleAuthMode = (mode) => {
    this.props.setError(null);
    this.props.setAuthMode(mode);

    if (mode === null) this.props.clearAuth();
  }

  render() {
    const props = {
      authMode: this.props.authMode,
      isUpdatePasswordInProgress: this.state.isUpdatePasswordInProgress,
      authError: this.props.authError,
      handleFocus: this.handleFocus,
      handleForgotPasswordClick: this.handleForgotPasswordClick,
      handleChange: this.handleChange,
      handleChangeSaveLoginEmail: this.handleChangeSaveLoginEmail,
      saveLoginEmail: this.state.saveLoginEmail,
      handleKeyPress: this.handleKeyPress,
      handleUpdatePassword: this.handleUpdatePassword,
      handleLogin: this.handleLogin,
      handleAuthMode: this.handleAuthMode,
      loading: this.props.loading,
      logoutType: this.props.location.state ? this.props.location.state.logoutType : '',
      loginEmail: this.state.loginEmail,
      password: this.state.password,
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
      loginEmailRef: (loginEmail) => (this.loginEmail = loginEmail),
      passwordRef: (password) => (this.password = password),
      newPasswordRef: (newPassword) => (this.newPassword = newPassword),
      confirmPasswordRef: (confirmPassword) => (this.confirmPassword = confirmPassword),
    };

    return (
      <Login {...props} />
    );
  }
}

LegacyLoginContainer.propTypes = {
  authError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  authMode: PropTypes.string,
  login: PropTypes.func,
  checkResetPassword: PropTypes.func,
  updatePassword: PropTypes.func,
  setError: PropTypes.func,
  setAuthMode: PropTypes.func,
  clearAuth: PropTypes.func,
  loading: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { auth, ui } = state;

  return {
    authError: ui.error,
    authMode: auth.authMode,
    loading: auth.loading,
  };
};

const actions = {
  login: AuthReducer.login,
  checkResetPassword: AuthReducer.checkResetPassword,
  updatePassword: AuthReducer.updatePassword,
  setError: UIReducer.setError,
  setAuthMode: AuthReducer.setAuthMode,
  clearAuth: AuthReducer.clearAuth,
};

export default connect(mapStateToProps, actions)(LegacyLoginContainer);
