import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Button } from 'rhinostyle';
import { PhoneHelpers } from '../helpers';

const SecureAppFooter = (props) => {
  const { className, officeName, officePhone } = props;
  const classes = cx('signup__footer', className);

  const renderOfficeName = () => {
    if (officeName) {
      return (<div className="signup__footer__office">{`The Office of ${officeName}`}</div>);
    }
    return false;
  };

  const renderOfficePhone = () => {
    if (officePhone) {
      const phoneLink = `tel:${PhoneHelpers.formatPhone(officePhone)}`;

      return (<div className="signup__footer__phone"><Button type="outline-reversed" url={phoneLink}>{PhoneHelpers.formatPhone(officePhone)}</Button></div>);
    }
    return false;
  };

  return (
    <div className={classes}>
      <div className="signup__footer__container">
        {renderOfficeName()}
        {renderOfficePhone()}
      </div>
      <div className="signup__footer__branding">
        Powered by <a href="https://www.rhinogram.com" target="_blank" rel="noopener noreferrer" className="u-text-underline">Rhinogram</a>
      </div>
    </div>
  );
};

SecureAppFooter.propTypes = {
  className: PropTypes.string,
  officeName: PropTypes.string,
  officePhone: PropTypes.string,
};

SecureAppFooter.defaultProps = {
  officeName: null,
  officePhone: null,
};

export default SecureAppFooter;
