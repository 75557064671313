import PropTypes from 'prop-types';
import React from 'react';
import {
  Icon,
} from 'rhinostyle';

import {
  DateHelpers,
} from '../../helpers';

const VideoEvent = (props) => {
  const {
    fromUser = {},
    duration,
    formattedActiveUserName,
    formattedTimestamp,
  } = props.event;
  const durationFormatted = DateHelpers.findDurationFromMilliseconds(duration);

  const fromUserName = `${fromUser?.firstName || ''} ${fromUser?.lastName || ''}`;

  return (
    <>
      <div className="convo__event__title">
        <Icon bump="up" icon="video" className="convo__event__icon convo__event__icon--video" />
        {fromUserName}&apos;s RhinoVideo session with {formattedActiveUserName} ended.
      </div>
      <div className="convo__event__subtext">
        {`${formattedTimestamp} (${durationFormatted})`}
      </div>
    </>
  );
};

VideoEvent.propTypes = {
  event: PropTypes.object,
};
export default VideoEvent;
