import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import {
  Icon,
  Avatar,
} from 'rhinostyle';
import LinkedAttachments from './LinkedAttachments';
import HtmlMessage from './HtmlMessage';
import {
  DateHelpers,
  UserHelpers,
} from '../helpers';
import { AppConstants } from '../constants';
import Attachment from './Attachment';
import { getChatEventById } from '../selectors/chatSelectors';
import { useEventScroll, useOnScreen } from '../hooks';
import { getUnreadChatMentionIds } from '../selectors/uiSelectors';
import { updateMentionReadStatus } from '../reducers/chatReducer';
import { MessageStatus } from '../constants/v3';

const ChatThreadEvent = (props) => {
  const { eventId, scrollToBottom, scrollToTop } = props;
  const dispatch = useDispatch();
  const eventRef = useRef(null);
  const event = useSelector((state) => getChatEventById(state, eventId));
  const sender = useSelector((state) => state.user.users[event.sender]);
  const [mentionReadStatusLoading, setMentionStatusLoading] = useState(false);
  const unreadMention = useSelector((state) => event.eventMentions?.find((mention) => getUnreadChatMentionIds(state).includes(mention.id)));
  const unreadMentionId = unreadMention?.id;
  const isUnreadMentionVisible = useOnScreen(eventRef, !!unreadMentionId);
  const isNetworkError = event.status === MessageStatus.NetworkError;

  useEventScroll({ eventId, scrollToBottom, scrollToTop, eventRef });

  useEffect(() => {
    if (unreadMentionId && isUnreadMentionVisible && !mentionReadStatusLoading) {
      handleMarkMentionRead();
    }
  }, [isUnreadMentionVisible]);

  async function handleMarkMentionRead() {
    setMentionStatusLoading(true);
    await dispatch(updateMentionReadStatus(unreadMentionId));
    setMentionStatusLoading(false);
  }

  const eventClasses = cx('convo__item', {
    'convo__item--inbound': event.incoming,
    'convo__item--outbound': !event.incoming,
  });

  const renderAttachment = (a, key) => (
    <Attachment attachment={a} key={key} incoming={event.incoming} />
  );
  if (!event) return null;

  const isRead = event.isRead && !event.incoming;
  const profileImageUrl = sender.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${sender.profileImageUrl}` : '';
  const nonPreviewableAttachments = (<LinkedAttachments event={event} breakBeforeFirstAttachment={event.details.text} />);
  const shapedEvent = { ...event, isNetworkError };
  return (
    <div className="convo__event__wrapper" key={eventId} ref={eventRef}>
      <div className={eventClasses} id={`js-convo__item-${eventId}`}>
        <div className="convo__item__header">{UserHelpers.formatName(sender)}</div>
        <div className="convo__item__body" data-cypress="chatMessage">
          <div className="convo__item__body__avatar">
            <Avatar image={profileImageUrl} name={UserHelpers.formatAvatarName(sender.firstName, sender.lastName)} size="small" type="member" />
          </div>
          {event.attachments.length > 0 &&
          <div className="convo__item__body__msg__attachments">{event.attachments.map((a, k) => renderAttachment(a, k))}</div>}

          <HtmlMessage event={shapedEvent} className="convo__item__body__msg" appendedElement={nonPreviewableAttachments} />

        </div>
        <div className="convo__item__footer">
          <span title={DateHelpers.fullTimestamp(event.timestamp)}>{DateHelpers.formatTimestamp(event.timestamp)}</span>
          {isRead && (
          <span className="u-flex u-flex-align-items-center u-text-success u-m-l-small" data-cypress="seen">
            <Icon icon="checkmark" />&nbsp;Seen
          </span>
          )}
          {isNetworkError && (
          <span>
            <strong className="u-text-danger" data-cypress="messageNotSent">
                  &nbsp; Unable to send, message will be removed
            </strong>
          </span>
          )}
        </div>
      </div>
    </div>
  );
};

ChatThreadEvent.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  scrollToBottom: PropTypes.bool,
  scrollToTop: PropTypes.bool,
};

export default ChatThreadEvent;
