import PropTypes from 'prop-types';
import React from 'react';
import {
  Input,
  Icon,
  Button,
} from 'rhinostyle';

import SignUpHeader from './SignUpHeader';
import SignUpFooter from './SignUpFooter';
import { PASSWORD_EXPLANATION_MESSAGE } from '../constants/AppConstants';

const Signup = (props) => {
  const {
    errors,
    handleChange,
    handleSignup,
    officeName,
    officePhone,
    formInProgress,
  } = props;

  const errorList = {
    loginEmail: errors.loginEmail ? errors.loginEmail : '',
    password: errors.password ? errors.password : '',
    passwordConfirmation: errors.passwordConfirmation ? errors.passwordConfirmation : '',
  };

  return (
    <div className="app-reactroot">
      <div className="signup" data-vh="100">
        <SignUpHeader />
        <div className="signup__content">
          <div className="signup__content__container">
            <h2 className="signup__intro-text">
              A secure message {officeName && <span>from <strong>The Office of {officeName}</strong></span>} is waiting for you.
            </h2>
            <div className="row row--deep-match">
              <div className="column-6@small">
                <div className="signup__registration">
                  <div className="signup__registration__inner">
                    <div className="box__title">Already Registered?</div>
                    <Button size="large" type="primary" url={`/auth${window.location.search}`} outlined>Login</Button>
                  </div>
                </div>
              </div>
              <div className="column-6@small">
                <div className="box">
                  <div className="box__title-wrapper">
                    <div className="box__title">First time here?</div>
                    <div className="box__subtitle">Register below to view the message</div>
                  </div>
                  <div className="form__group">
                    <div className="row">
                      <div className="column-12@xsmall">
                        <Input
                          validationMessage={errorList.loginEmail}
                          addon="left"
                          type="email"
                          name="loginEmail"
                          label="Email Address"
                          required
                          onChange={handleChange}
                        >
                          <Icon icon="email" />
                        </Input>
                      </div>
                    </div>
                  </div>
                  <div className="form__group">
                    <div className="row">
                      <div className="column-6@xsmall">
                        <Input
                          validationMessage={errorList.password}
                          addon="left"
                          type="password"
                          name="password"
                          label="Password"
                          onChange={handleChange}
                          explanationMessage={PASSWORD_EXPLANATION_MESSAGE}
                          required
                        >
                          <Icon icon="lock" />
                        </Input>
                      </div>
                      <div className="column-6@xsmall">
                        <Input
                          validationMessage={errorList.passwordConfirmation}
                          addon="left"
                          type="password"
                          name="passwordConfirmation"
                          label="Confirm Password"
                          required
                          onChange={handleChange}
                        >
                          <Icon icon="lock" />
                        </Input>
                      </div>
                    </div>
                  </div>
                  <div className="form__group u-text-center u-m-t-large">
                    <Button size="large" loading={formInProgress} type="secondary" onClick={handleSignup} data-cypress="viewMessage">
                      View Message
                    </Button>
                  </div>
                  <div className="form__group u-text-muted u-text-small">
                    <em>By clicking the button you are registering with&nbsp;
                      <a
                        href="https://rhinogram.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="u-text-muted u-text-underline"
                      >
                        Rhinogram
                      </a> and agreeing to the&nbsp;
                      <a
                        href="https://www.rhinogram.com/terms-of-service/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="u-text-muted u-text-underline"
                      >
                        Rhinogram Terms of Service
                      </a> and&nbsp;
                      <a
                        href="https://www.rhinogram.com/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="u-text-muted u-text-underline"
                      >
                        Privacy Policy
                      </a>.
                    </em>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SignUpFooter
          officeName={officeName}
          officePhone={officePhone}
        />
      </div>
    </div>
  );
};

Signup.propTypes = {
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleSignup: PropTypes.func,
  officeName: PropTypes.string,
  officePhone: PropTypes.string,
  formInProgress: PropTypes.bool.isRequired,
};

export default Signup;
