import { useSelector } from 'react-redux';
import { useGetAppointmentsQuery, useLazyGetAppointmentsQuery } from '../services/appointmentService';
import {
  convertUnixTimestampMsToUTCDateString,
} from '../helpers/DateHelpers';
import { getRhinoblastMaxPageSize } from '../selectors/organizationSelectors';

const useAppointments = (filters) => {
  const pageSize = useSelector(getRhinoblastMaxPageSize);
  const searchPayload = formatAppointmentRequestOptions(filters);
  const searchResult = useGetAppointmentsQuery(searchPayload);
  const { data: searchData, isLoading } = searchResult;
  const [fetchAllAppointments, { isFetching }] = useLazyGetAppointmentsQuery();
  const { appointments, lastUpdatedApppointment, totalUpcomingApptCount } = searchData || {};

  async function fetchAllResults(nextPageNumber) {
    const result = await fetchAllAppointments(formatAppointmentRequestOptions({ ...filters, pageNumber: nextPageNumber }), true);
    return result;
  }

  function formatAppointmentRequestOptions(filterObj) {
    const activeTab = filterObj?.activeTab || filters.activeTab;
    return {
      fromDate: convertUnixTimestampMsToUTCDateString(filterObj?.startDate ? filterObj.startDate : filters.startDate, filters.activeTab === 1),
      toDate: convertUnixTimestampMsToUTCDateString(filterObj?.endDate ? filterObj.endDate : filters.endDate, filters.activeTab === 1),
      pageNumber: filterObj?.pageNumber || 0,
      pageSize,
      sort: activeTab === 1 ? 'asc' : 'desc',
      officeIds: Array.isArray(filterObj?.officeIds) ?
        filterObj.officeIds : filters.officeIds,
      appointmentTypeIds: Array.isArray(filterObj?.appointmentTypeIds) ?
        filterObj.appointmentTypeIds :
        filters.appointmentTypeIds,
      providerIds: Array.isArray(filterObj?.providerIds) ?
        filterObj.providerIds : filters.providerIds,
      integrationStatusTypeIds: Array.isArray(filterObj?.integrationStatusTypeIds) ?
        filterObj.integrationStatusTypeIds : filters.integrationStatusTypeIds,
      appointmentStatusTypeIds: Array.isArray(filterObj?.appointmentStatusTypeIds) ?
        filterObj.appointmentStatusTypeIds : filters.appointmentStatusTypeIds,
    };
  }
  return {
    fetchAllResults,
    appointments,
    totalAppointmentCount: totalUpcomingApptCount,
    lastUpdatedDate: lastUpdatedApppointment?.lastUpdatedAt,
    isFetching,
    isLoading,
    pageSize,
  };
};

export default useAppointments;
