import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Button, Icon } from 'rhinostyle';
import { getVideoConfiguration, createVideoConference } from '../reducers/rhinovideoReducer';
import { ChannelHelpers, BrowserHelpers } from '../helpers';
import { openPopupWindow } from '../helpers/BrowserHelpers';
import { getIsMemberCallCreated } from '../selectors/rhinocallSelector';
import { MODAL_OPTIONS } from '../constants/ThreadConstants';
import { toggleModal } from '../reducers/threadReducer';
import { getActiveUser, getLoggedInUser, getLoggedInUserOrganization } from '../selectors/userSelectors';

const VideoStartButton = (props) => {
  const {
    isNativeApp,
    memberHasActiveCall,
    activeFromChannelId,
    activeToChannelId,
    activeUser,
    currentUser,
    phones,
    video,
    activeVideos,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const startVideo = async () => {
    if (isNativeApp) {
      try {
        setLoading(true);
        const configurationData = await dispatch(getVideoConfiguration());
        if (configurationData?.twilio?.consumerToken) {
          await handleStartNative(configurationData.twilio.consumerToken);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    } else {
      const communicationId = ChannelHelpers.getCommunicationId(activeToChannelId);
      const channelType = ChannelHelpers.getChannelClass(activeToChannelId);

      const params = {
        activeFromChannelId,
        activeToChannelId,
        activeUserId: activeUser.id,
        phone: channelType === 'sms' ? phones[communicationId].value : '',
      };

      const stringifiedParams = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
      openPopupWindow(`${process.env.REACT_APP_URL}/memberVideo/?${stringifiedParams}`, '', window, 800, 600);
    }
  };

  const isConnectedToConference = () => video.memberIds?.includes(currentUser.id);

  const memberHasActiveVideo = () => activeVideos?.filter((v) => v.memberIds.includes(currentUser.id))?.length > 0;

  async function handleStartNative(consumerToken) {
    const isMobileView = BrowserHelpers.isMobile();
    // if a member attempts to start a video from a mobile browser, prompt them to download the app
    // instead, since this currently won't work on a mobile browser
    if (isMobileView && !isNativeApp) {
      dispatch(toggleModal(MODAL_OPTIONS.downloadApp));
    } else if (!BrowserHelpers.isWebRTCSupported() && !isNativeApp) {
      dispatch(toggleModal(MODAL_OPTIONS.browserNotSupported));
    } else {
      const channelType = ChannelHelpers.getChannelClass(activeToChannelId);
      const communicationId = ChannelHelpers.getCommunicationId(activeToChannelId);
      await dispatch(createVideoConference({
        createdBy: currentUser.id,
        memberId: currentUser.id,
        userId: activeUser.id,
        orgId: currentUser.organizationId,
        channelId: activeFromChannelId,
        destination: (channelType === 'sms') ? phones[communicationId].value : null,
        currentUser,
        consumerToken,
      })).catch(() => dispatch(toggleModal(MODAL_OPTIONS.videoLimit)));
    }
  }

  if (!props.isEnabled) return '';

  let status = 'Meet Now';
  if (props.video) {
    status = 'Connected';
    if (!isConnectedToConference()) {
      status = 'In Progress';
    } else if (!props.video.participantCount || props.video.participantCount < 2) {
      status = 'Waiting...';
    }
  } else if (memberHasActiveVideo()) {
    status = 'In Progress';
  }

  return (
    <Button
      loading={isLoading}
      disabled={status !== 'Meet Now' || memberHasActiveCall}
      className={`convo__start-button ${status !== 'Meet Now' ? 'u-muted-outline' : ''}`}
      onClick={startVideo}
      size="small"
      type="outline"
    >
      <Icon size="large" icon={status === 'In Progress' ? 'video-off' : 'video'} />
      <span className="u-hidden-xsmall">&nbsp;{status}</span>
    </Button>
  );
};

VideoStartButton.propTypes = {
  activeVideos: PropTypes.array,
  currentUser: PropTypes.object,
  activeUser: PropTypes.object,
  video: PropTypes.object,
  isEnabled: PropTypes.bool,
  activeFromChannelId: PropTypes.number,
  activeToChannelId: PropTypes.string,
  phones: PropTypes.object,
  isNativeApp: PropTypes.bool.isRequired,
  memberHasActiveCall: PropTypes.bool,
};

const mapStateToProps = (state) => {
  let isEnabled = false;
  if (state.rhinovideo?.vendor) {
    isEnabled = Object.entries(state.rhinovideo.vendor).length > 0;
  }
  const activeVideos = state.rhinovideo.conferences.filter((v) => !v.durationMs);
  const activeUser = getActiveUser(state);

  // filter for the thread being viewed
  const video = activeVideos.filter((v) => v.route && v.route[activeUser.id])[0];

  return {
    activeUser,
    video,
    isEnabled,
    activeVideos,
    userOrganization: getLoggedInUserOrganization(state),
    isNativeApp: state.nativeApp.isNativeApp,
    currentUserId: state.auth.currentUser,
    memberHasActiveCall: getIsMemberCallCreated(state),
    phones: state.phone.phones,
    activeFromChannelId: state.inbox.activeFromChannelId,
    activeToChannelId: state.inbox.activeToChannelId,
    currentUser: getLoggedInUser(state),
  };
};

export default connect(mapStateToProps)(VideoStartButton);
