import { createApi } from '@reduxjs/toolkit/query/react';
import { getContactForms } from './RhinoformService';

const fetchForms = async (cb) => {
  const result = await cb;
  return { data: result?.data };
};

export const formSlice = createApi({
  reducerPath: 'forms',
  baseQuery: fetchForms,
  tagTypes: ['UserForms'],
  endpoints: (builder) => ({
    getUserForms: builder.query({
      query: ({ contactId }) => getContactForms(contactId),
      providesTags: ['UserForms'],
      keepUnusedDataFor: 5,
    }),

  }),
});

export const { useGetUserFormsQuery } = formSlice;

export default formSlice;
