import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import {
  Icon,
  Resource,
  ResourceBody,
  ResourceIntro,
  ResourceRight,
} from 'rhinostyle';
import { DateHelpers, UserHelpers } from '../helpers';
import { AppConstants, Types } from '../constants';
import { useGroup } from '../hooks';
import { getLoggedInUser } from '../selectors/userSelectors';

const InboxMentionEvent = (props) => {
  const {
    mentionEvent,
    users,
    handleEventMentionClick,
    handleCheckboxOnMentionItem,
    isChecked,
    currentUser,

  } = props;

  const { TYPE_PATIENT, TYPE_UNKNOWN, TYPE_USER_OTHER } = Types;
  // group will be an id for socket events
  const socketGroup = useGroup(mentionEvent.group);
  const sender = users[mentionEvent.sender];
  const patient = mentionEvent.eventPatientId && users[mentionEvent.eventPatientId];
  const patientProfileImageUrl = patient?.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${patient.profileImageUrl}` : '';
  const formattedPatientAvatarName = UserHelpers.formatAvatarName(patient?.firstName, patient?.lastName);
  const senderProfileImageUrl = sender?.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${sender.profileImageUrl}` : '';
  const formattedSenderAvatarName = UserHelpers.formatAvatarName(sender?.firstName, sender?.lastName);
  const onlineStatus = sender?.onlineStatus || null;
  const unread = !mentionEvent.isRead;
  const group = mentionEvent.group?.id ? mentionEvent.group : socketGroup;
  const groupUsers = group?.users || null;
  const isGroupThread = !!group;
  const stillInGroup = (!isGroupThread || (groupUsers?.findIndex((groupUser) => groupUser.id === currentUser.id) > -1));

  let navigationId = null;
  if (group) {
    navigationId = group.id;
  } else if (mentionEvent.eventId) {
    navigationId = mentionEvent.eventPatientId;
  } else if (mentionEvent.chatEventId) {
    navigationId = mentionEvent.users.find((emuser) => emuser !== currentUser.id);
  } else {
    navigationId = mentionEvent.sender;
  }

  const formatGroupName = () => {
    if (isGroupThread) {
      if (!group.isDynamic) {
        return 'Group';
      } return 'Multi-Direct';
    } return 'Direct';
  };

  const formatPatientEventName = (patientTypeId) => {
    switch (patientTypeId) {
      case TYPE_USER_OTHER:
        return 'Other';
      case TYPE_UNKNOWN:
        return 'Unknown';
      case TYPE_PATIENT:
      default:
        return 'Patient';
    }
  };
  const formattedPatientName = UserHelpers.formatName(patient);
  const formattedSenderName = UserHelpers.formatName(sender);
  let checkboxProperty = null;
  if (!currentUser.isCcr) {
    checkboxProperty = {
      checkbox: {
        name: 'inboxThread',
        label: ' ',
        isChecked,
        onChange: () => handleCheckboxOnMentionItem(mentionEvent),
        hasAvatar: true,
      },
    };
  }

  const classes = cx('inbox__event__message', {
    'msg--note': patient,
    'msg--default': !patient && mentionEvent.sender !== currentUser.id,
    'msg--primary--outbound': !patient && mentionEvent.sender === currentUser.id,
  });

  return (
    <Resource
      className="inbox__item"
      id={`js-inbox__item-${currentUser.id}`}
      unread={unread}
      onClick={() => (stillInGroup || mentionEvent.eventId || socketGroup) && handleEventMentionClick(
        navigationId,
        mentionEvent.eventId ? 'inbox/all' : 'chat',
        group ? 'group' : 'user',
        (mentionEvent.eventId || mentionEvent.chatEventId),
        mentionEvent.pageNo,
      )}
    >
      <ResourceIntro
        avatar={{
          image: patient ? patientProfileImageUrl : senderProfileImageUrl,
          name: patient ? formattedPatientAvatarName : formattedSenderAvatarName,
          type: patient ? 'default' : 'member',
          showOnlineStatus: !patient,
          onlineStatus,
        }}
        title={patient ? formattedPatientName : formattedSenderName}
        titleSub={patient ? formatPatientEventName(patient.typeId) : formatGroupName(isGroupThread, group)}
        {...checkboxProperty}
      />
      <ResourceBody className="inbox__item__body--flex">
        {patient && (
        <>
          <span>{formattedSenderName}&nbsp;<Icon icon="arrow-right" className="icon--bump-up inbox__chat-item__body__arrow-right" />&nbsp;</span>
          <br className="mention-line-break" />
        </>
        )}
        <span className={classes}>
          {mentionEvent.details.text && UserHelpers.formatMentionString(mentionEvent.details.text, mentionEvent.eventMentions)}
        </span>
        {mentionEvent.hasAttachments ? <Icon className="inbox__item__attachment-icon" icon="attachment" /> : null }
      </ResourceBody>
      <ResourceRight>
        <span title={DateHelpers.fullTimestamp(mentionEvent.timestamp)}>
          {DateHelpers.formatTimestamp(mentionEvent.timestamp)}
        </span>
      </ResourceRight>
    </Resource>
  );
};

InboxMentionEvent.propTypes = {
  mentionEvent: PropTypes.object,
  currentUser: PropTypes.object,
  isChecked: PropTypes.bool,
  handleCheckboxOnMentionItem: PropTypes.func,
  handleEventMentionClick: PropTypes.func,
  users: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: getLoggedInUser(state),
  users: state.user.users,
});

export default connect(mapStateToProps)(InboxMentionEvent);
