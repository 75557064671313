import { createSelector } from '@reduxjs/toolkit';
import { VIDEO_EDIT } from '../constants/UserPermissionsConstants';
import { getIsMessageSendBoxEnabled } from './inboxSelectors';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';

const getCurrentVideoId = (state) => state.rhinovideo.currentVideoId;
const getConferences = (state) => state.rhinovideo.conferences;
const getLoggedInUserId = (state) => state.auth.currentUser;
const getIsVideoEnabled = (state) => !!state.rhinovideo.vendor?.twilio?.consumerToken;

export const getCurrentVideo = createSelector(
  [getCurrentVideoId, getConferences],
  (currentVideoId, conferences) => {
    const currentVideo = conferences.find((conference) => conference.videoId === currentVideoId);
    return currentVideo || {};
  },
);

export const getIsHost = createSelector(
  [getCurrentVideo, getLoggedInUserId],
  (currentVideo, loggedInUserId) => currentVideo.hostId === loggedInUserId?.toString(),
);

export const getActiveMemberVideo = createSelector(
  [getConferences, getLoggedInUserId],
  (conferences, loggedInUserId) => conferences.filter((v) => !v.durationMs && v.memberIds.includes(loggedInUserId)),
);

export const getIsVideoStartButtonEnabled = createSelector(
  [getIsVideoEnabled, getIsMessageSendBoxEnabled],
  (isVideoEnabled, isMessageSendBoxEnabled) => !!(isMessageSendBoxEnabled && isVideoEnabled && userHasAnyOfPermissions([VIDEO_EDIT])),
);
