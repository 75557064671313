export const REPORTS = [{
  id: 1,
  key: 'OptumHUMANAReport',
  label: 'Optum/Humana Report',
}, {
  id: 2,
  key: 'CVSCaremarkESIReport',
  label: 'CVS/Caremark & ESI Report',
}];

export const REPORT_TYPES = [{
  id: 1,
  key: 'csv',
  label: 'CSV',
}, {
  id: 2,
  key: 'pdf',
  label: 'PDF',
}];

export const PAST_REPORT_OPTIONS = [{
  id: 1,
  label: 'Today',
  duration: 0,
}, {
  id: 2,
  label: 'Yesterday',
  duration: 1,
}, {
  id: 3,
  label: 'Past 7 Days',
  duration: 6,
}, {
  id: 4,
  label: 'Past 30 Days',
  duration: 29,
}, {
  id: 5,
  label: 'Custom',
}];
