import moment from 'moment-timezone';
import * as queryString from 'query-string';
import { cloneDeep, getDefaultSortKey, parseIfJson } from './DataHelpers';
import { FORMS_MANAGER_HEADERS_CONFIG, DATAPICKER_DROPDOWN_LABELS } from '../constants/RhinoformConstants';
import { calculateDateRange } from './DateHelpers';

export function normalizeForms(forms) {
  const formIds = forms.map((form) => form._id);
  const ret = {};
  for (let i = 0; i < forms.length; i += 1) {
    ret[formIds[i]] = forms[i];
  }
  return ret;
}

export function getHeadersFromQueryParams(query) {
  const headers = cloneDeep(FORMS_MANAGER_HEADERS_CONFIG);
  const defaultSortKey = getDefaultSortKey(headers);
  if (query?.sortBy && query.sortBy !== defaultSortKey) {
    headers.createdAt.default = false;
    headers.createdAt.direction = 1;
    headers[query.sortBy].default = true;
    if (query?.sortOrder) {
      headers[query.sortBy].direction = query?.sortOrder === 'asc' ? 1 : -1;
    }
  } else if (query?.sortOrder) {
    headers.createdAt.direction = query?.sortOrder === 'asc' ? 1 : -1;
  }
  return headers;
}

/**
 * Returns object with values from query string.
 *
 * @param {defaultValue} obj Object with default values
 * @param {query} string query string to be parsed
 * @returns {updatedValues} Object with updated values
 */

export function getValueFromQueryString(defaultValue, query) {
  const queryStringValues = queryString.parse(query);
  const updatedValues = { ...defaultValue };
  if (queryStringValues.activeKey) {
    const duration = getDateDuration(Number(queryStringValues.activeKey));
    if (duration !== undefined) {
      const { startDate, endDate } = calculateDateRange(duration, Number(queryStringValues.activeKey));
      updatedValues.startDate = startDate;
      updatedValues.endDate = endDate;
      updatedValues.activeKey = Number(queryStringValues.activeKey);
    } else if (queryStringValues.startDate && queryStringValues.endDate) {
      updatedValues.endDate = moment(queryStringValues.endDate);
      updatedValues.startDate = moment(queryStringValues.startDate);
      updatedValues.activeKey = Number(queryStringValues.activeKey);
    }
  }
  Object.keys(queryStringValues).forEach((key) => {
    if (!['startDate', 'endDate', 'activeKey'].includes(key)) {
      let updateValue;
      if (key === 'titles' && queryStringValues[key]?.length) {
        updateValue = parseIfJson(queryStringValues[key]);
      } else if (key === 'pageNumber') {
        updateValue = Number(queryStringValues[key]);
      } else {
        updateValue = validateQueryStringValue(queryStringValues[key], key);
      }
      if (updateValue) {
        updatedValues[key] = updateValue;
      }
    }
  });
  updatedValues.headersConfig = getHeadersFromQueryParams(queryStringValues);
  return updatedValues;
}

/**
 * Returns formatted query string params from object values.
 *
 * @param {newValues} obj Object with keys to update
 * @param {defaultValues} obj An object with default key values
 * @returns {queryStringParams} An object of formatted query string params
 */

export function getQueryStringFromValue(newValues, defaultValues) {
  const queryStringParams = {};
  if (newValues.activeKey && newValues.activeKey !== defaultValues.activeKey) {
    queryStringParams.activeKey = newValues.activeKey;
    if (getDateDuration(newValues.activeKey) === undefined) {
      queryStringParams.startDate = newValues.startDate.format('YYYY-MM-DD');
      queryStringParams.endDate = newValues.endDate.format('YYYY-MM-DD');
    }
  }
  Object.keys(newValues).forEach((key) => {
    if (!['startDate', 'endDate', 'activeKey', 'headersConfig'].includes(key)) {
      let updateValue;
      if (key === 'titles') {
        if (newValues[key]?.length) {
          updateValue = JSON.stringify(newValues[key]);
        }
      } else {
        updateValue = newValues[key];
      }
      if (updateValue !== null && updateValue !== defaultValues[key]) {
        queryStringParams[key] = updateValue;
      }
    }
  });
  return queryStringParams;
}

function getDateDuration(activeKey) {
  return DATAPICKER_DROPDOWN_LABELS.find((item) => item.id === activeKey)?.duration;
}

export function validateQueryStringValue(value, key) {
  if (key === 'status') {
    return ['Completed', 'Sent', 'Expired'].includes(value) ? value : null;
  } if (key === 'sortBy') {
    return FORMS_MANAGER_HEADERS_CONFIG[key] ? value : null;
  } return value;
}
