import React from 'react';
import {
  Alert,
  Button,
  Resource,
  ResourceBody,
  ResourceIntro,
  ResourceRight,
  ResourceGroup,
  Scrollbars,
  UtilitySystem,
} from 'rhinostyle';
import PropTypes from 'prop-types';

import { ChannelHelpers, PhoneHelpers } from '../helpers';
import Tags from './Tags';

const ChannelRoutes = (props) => {
  const {
    allowWarning,
    channelIds,
    channels,
    error,
    filterChannels,
    groupId,
    handleToggleViewAll,
    handleUpdateSelectedIds,
    selectedChannelIds,
    title,
    userId,
    phones,
    viewAllChannels,
  } = props;

  const nonRoutedChannelIds = channelIds.filter((id) => !channels[id].route || !channels[id].route.name);
  const routedChannelIds = channelIds.filter((id) => channels[id].route && channels[id].route.name);

  const renderChannelRoute = (channel, renderWarning) => {
    const channelRouteClass = renderWarning ? 'u-text-strikethrough' : '';

    if (!channel.route || !channel.route.name) {
      return 'No Route';
    }

    return <span className={channelRouteClass}>{channel.route.name} ({(channel.route.type)})</span>;
  };

  const renderChannel = (id, idx) => {
    const channel = channels[id];
    const selected = selectedChannelIds.includes(id);

    // If channel has a phone number, format it
    const phone = channel.details.phone ? PhoneHelpers.formatPhone(phones[channel.details.phone].value) : null;

    const matchUserId = channel.route && userId && userId === channel.route.userId;
    const matchGroupId = channel.route && groupId && groupId === channel.route.groupId;

    const renderWarning = allowWarning && selected && !matchUserId && !matchGroupId && channel.route;

    return (
      <Resource selected={selected} key={idx} onClick={() => handleUpdateSelectedIds('selectedChannelIds', id)}>
        <ResourceIntro icon={{ icon: ChannelHelpers.getChannelIcon(channel.typeId) }} title={channel.name} titleSub={phone} />
        {channel.tags?.length > 0 && <ResourceBody className="u-text-muted"><Tags tagIds={channel.tags} /></ResourceBody>}
        <ResourceRight>
          {renderChannelRoute(channel, renderWarning)}
          {renderWarning && <div className="u-text-accent">Existing route will be removed</div>}
        </ResourceRight>
      </Resource>
    );
  };

  const renderViewAllChannels = () => (
    filterChannels ? (
      <>
        <div className="search__group">
          <div className="search__sub">Channels Without A Route</div>
          {nonRoutedChannelIds.length > 0 ? (
            <Scrollbars className="resource-group__scroll" autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.small}>
              <ResourceGroup interfaceMode="checkbox">
                {nonRoutedChannelIds.map(renderChannel)}
              </ResourceGroup>
            </Scrollbars>
          ) :
            'No channels'}
        </div>
        <div className="search__group">
          <div className="search__sub">Channels With Existing Route</div>
          {routedChannelIds.length > 0 ? (
            <Scrollbars className="resource-group__scroll" autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.small}>
              <ResourceGroup interfaceMode="checkbox">
                {routedChannelIds.map(renderChannel)}
              </ResourceGroup>
            </Scrollbars>
          ) :
            'No channels'}
        </div>
      </>
    ) : (
      <div className="search__group">
        {channelIds.length > 0 ? (
          <Scrollbars className="resource-group__scroll" autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.small}>
            <ResourceGroup interfaceMode="checkbox">
              {channelIds.map(renderChannel)}
            </ResourceGroup>
          </Scrollbars>
        ) :
          'No channels'}
      </div>
    ));

  const renderViewSelectedChannels = () => (
    <div className="search__group">
      <div className="search__sub">Channels Added</div>
      {selectedChannelIds.length > 0 ? (
        <Scrollbars autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.small}>
          <ResourceGroup interfaceMode="checkbox">
            {selectedChannelIds.map(renderChannel)}
          </ResourceGroup>
        </Scrollbars>
      ) :
        'No Channels Added'}
    </div>
  );

  return (
    <div className="box">
      <div className="box__title-wrapper--flex">
        <div className="box__title__flex__trigger">
          <Button
            reset
            className="u-text-small u-text-primary"
            onClick={handleToggleViewAll}
          >{viewAllChannels ? 'Review' : 'Add More Channels'}
          </Button>
        </div>
        <div className="box__title__content-flex">
          <div className="box__title">{title}</div>
        </div>
        <div className="u-text-small box__title__flex__status">({selectedChannelIds.length} Channel{selectedChannelIds.length !== 1 && 's'} Added)</div>
      </div>
      {viewAllChannels ? renderViewAllChannels() : renderViewSelectedChannels()}
      {
        error && (
        <Alert type="danger" className="u-m-t">
          {error}
        </Alert>
        )
}
    </div>
  );
};

ChannelRoutes.propTypes = {
  allowWarning: PropTypes.bool,
  channelIds: PropTypes.array.isRequired,
  channels: PropTypes.object.isRequired,
  error: PropTypes.string,
  filterChannels: PropTypes.bool,
  groupId: PropTypes.number,
  handleToggleViewAll: PropTypes.func.isRequired,
  handleUpdateSelectedIds: PropTypes.func.isRequired,
  phones: PropTypes.object.isRequired,
  selectedChannelIds: PropTypes.array.isRequired,
  title: PropTypes.string,
  userId: PropTypes.number,
  viewAllChannels: PropTypes.bool.isRequired,
};

ChannelRoutes.defaultProps = {
  allowWarning: true,
  filterChannels: true,
  title: 'Channel Routes',
};

export default ChannelRoutes;
