import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import { suffix } from '../actions/NormalizrSchema';
import { setError } from './uiReducer';

// SLICE
const suffixSlice = createSlice({
  name: 'SUFFIX',
  initialState: {
    suffixes: {},
    suffixIds: [],
  },
  reducers: {
    receiveSuffixes: (state, action) =>
      ({
        ...state,
        suffixes: {
          ...state.suffixes,
          ...action.payload.suffixes,
        },
        suffixIds: [...state.suffixIds, ...action.payload.suffixIds],
      }),
  },
});

export default suffixSlice.reducer;

// ACTIONS
export const { receiveSuffixes } = suffixSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

export function fetchSuffixes() {
  return (dispatch) =>
    axios.get('/suffixes')
      .then((response) => {
        const normalized = normalize(response.data, [suffix]);

        dispatch(receiveSuffixes({ suffixes: normalized.entities.suffixes, suffixIds: normalized.result }));
      })
      .catch((err) => {
        console.error(err.response);

        dispatch(setError(err.response));
      });
}
