import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { Select } from 'rhinostyle';
import { businessHoursArray, fetchSelection, daysDict } from '../constants/BusinessHoursConstants';

export default class BusinessHours extends PureComponent {
  static DEFAULT_OPEN = '08:00:00';
  static DEFAULT_CLOSE = '18:00:00';
  static START_OF_DAY = '00:00:00';
  static END_OF_DAY = '23:59:59';
  static DEFAULT_BUSINESS_HOURS = [
    {
      day: 0,
      open: false,
    },
    {
      day: 1,
      open: true,
      from: BusinessHours.DEFAULT_OPEN,
      to: BusinessHours.DEFAULT_CLOSE,
    },
    {
      day: 2,
      open: true,
      from: BusinessHours.DEFAULT_OPEN,
      to: BusinessHours.DEFAULT_CLOSE,
    },
    {
      day: 3,
      open: true,
      from: BusinessHours.DEFAULT_OPEN,
      to: BusinessHours.DEFAULT_CLOSE,
    },
    {
      day: 4,
      open: true,
      from: BusinessHours.DEFAULT_OPEN,
      to: BusinessHours.DEFAULT_CLOSE,
    },
    {
      day: 5,
      open: true,
      from: BusinessHours.DEFAULT_OPEN,
      to: BusinessHours.DEFAULT_CLOSE,
    },
    {
      day: 6,
      open: false,
    },
  ];

  state = {
    businessHours:
      this.props.businessHours?.length > 0
        ? this.props.businessHours
        : BusinessHours.DEFAULT_BUSINESS_HOURS,
  };

  componentDidMount() {
    this.setState({
      businessHours: this.shapeBusinessHours(),
    }, this.receiveBusinessHours);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
      this.receiveBusinessHours();
    }
  }

  businessHoursOpenOptions() {
    return [{ id: -1, value: 'Open All Day' }, { id: -2, value: 'Closed All Day' }].concat(businessHoursArray);
  }

  businessHoursClosedOptions = () => businessHoursArray;

  receiveBusinessHours() {
    this.props.handleChange('businessHours', this.shapeBusinessHours());
  }

  shapeBusinessHours = (curBusinessHours = this.state.businessHours) => {
    const businessHours = [...curBusinessHours];

    for (let i = 0; i < 7; i++) {
      const day = { ...businessHours[i] };

      day.open = !!day.open;
    }

    return businessHours;
  };

  handleBusinessHourSelect = (name, value) => {
    const split = name.split('-');
    const target = split[0]; // 'from' or 'to'
    const dayIndex = parseInt(split[1], 10); // 0 for Sunday, 1 for Monday, [...]
    const hoursTarget = businessHoursArray.find((businessHour) => businessHour.id === value);
    const businessHours = [...this.state.businessHours];
    const day = { ...businessHours[dayIndex] };

    if (target === 'from') {
      if (value === -2) {
        // Closed all day
        day.open = false;
        day.from = null;
        day.to = null;
      } else if (value === -1) {
        // Open all day
        day.open = true;
        day.from = BusinessHours.START_OF_DAY;
        day.to = BusinessHours.END_OF_DAY;
      } else {
        day.open = true;
        day.from = hoursTarget.expectedValue;
        if (day.to == null || day.to === BusinessHours.END_OF_DAY) day.to = BusinessHours.DEFAULT_CLOSE;
        if (day.from > day.to) day.to = day.from;
      }
    } else if (target === 'to') {
      day.to = hoursTarget.expectedValue;
      if (day.to < day.from) day.from = day.to;
    } else return;

    businessHours[dayIndex] = day;

    this.setState({ businessHours });
  };

  renderBusinessHour = (item, idx) => {
    const day = daysDict[idx].title;
    let fromSelection;
    let toSelection;
    let toSelectionVisible = false;

    if (!item.open) {
      fromSelection = { id: -2 };
    } else if (item.from === BusinessHours.START_OF_DAY && item.to === BusinessHours.END_OF_DAY) {
      fromSelection = { id: -1 };
    } else {
      toSelectionVisible = true;
      fromSelection = fetchSelection(item.from || BusinessHours.DEFAULT_OPEN);
      toSelection = fetchSelection(item.to || BusinessHours.DEFAULT_CLOSE);
    }

    return (
      <div key={idx} className="form__group">
        <div className="row u-flex-align-items-center">
          <div className="column-3@small">
            <div>{`${day}:`}</div>
          </div>
          <div className="column-9@small">
            <div className="availability__wrapper">
              <Select
                onSelect={this.handleBusinessHourSelect}
                selected={fromSelection.id}
                name={`from-${idx}`}
                options={this.businessHoursOpenOptions()}
                className="availability__select"
              />
              {toSelectionVisible && (
                <>
                  <div className="u-p-x-small">to</div>
                  <Select
                    onSelect={this.handleBusinessHourSelect}
                    selected={toSelection.id}
                    name={`to-${idx}`}
                    options={this.businessHoursClosedOptions()}
                    className="availability__select"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="form__group">{this.state.businessHours.map(this.renderBusinessHour)}</div>
    );
  }
}

BusinessHours.propTypes = {
  businessHours: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
};
