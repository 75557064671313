import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Button,
  Close,
  Scrollbars,
  UtilityInlineGrid,
  ResourceGroup,
  Resource,
  ResourceIntro,
  ResourceRight,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,

} from 'rhinostyle';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fullTimestamp } from '../helpers/DateHelpers';
import { formatAvatarName, formatName } from '../helpers/UserHelpers';
import { useCampaign } from '../hooks';
import { AVATAR_BASE_URL } from '../constants/AppConstants';
import BulkMessageModal from './BulkMessageModal';
import { createContactList } from '../reducers/userReducer';

const CampaignSummaryPanel = (props) => {
  const {
    handleTogglePanel,
    activeCampaign,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [viewAllMembers, setViewAllMembers] = useState(false);
  const [isBulkMessageModalOpen, setIsBulkMessageModalOpen] = useState(false);
  const [isCreateListModalOpen, setIsCreateListModalOpen] = useState(false);
  const [isCreateListLoading, setIsCreateListLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [saveListName, setSaveListName] = useState('');
  const { bulkMessageId } = activeCampaign;
  const { data, isLoading } = useCampaign({ bulkMessageId });

  const formAttachments = activeCampaign?.formAttachmentTitle ? activeCampaign?.formAttachmentTitle.split(',') : [];
  const otherAttachments = activeCampaign?.fileAttachments ? activeCampaign?.fileAttachments.split(',') : [];

  const hasFailedContacts = activeCampaign.failed > 0;

  const renderAttachments = (attachment) => (<div>{attachment}</div>);

  const navigateToConversation = (userId) => {
    history.push(`/inbox/all/user/${userId}`);
  };

  const handleUpdateContactList = (id) => {
    history.push(`/contact-list/edit/${id}`);
  };

  const openBulkMessageModal = () => {
    setIsBulkMessageModalOpen(true);
  };

  const handleCreateContactListModal = () => {
    setValidationMessage('');
    setIsCreateListModalOpen(true);
  };

  const handleCloseCreateContactListModal = () => {
    setIsCreateListModalOpen(false);
  };

  const handleCreateSaveList = async () => {
    if (saveListName === '') {
      setValidationMessage('Saved List is required');
    } else {
      setIsCreateListLoading(true);
      const userIds = data.map((user) => user.id);
      await dispatch(createContactList({ name: saveListName, userIds }));
      setIsCreateListLoading(false);
      setIsCreateListModalOpen(false);
    }
  };

  const handleChange = (name, value) => {
    setSaveListName(value);
  };

  const renderContact = (contact, key) => {
    const profileImageUrl = contact && contact.profileImageUrl ? `${AVATAR_BASE_URL}${contact.profileImageUrl}` : '';
    let avatar = {};
    if (contact) {
      avatar = {
        name: formatAvatarName(contact.firstName, contact.lastName),
        type: 'default',
        image: profileImageUrl,
      };
    }

    return (
      <Resource className="u-text-left" key={key} onClick={() => navigateToConversation(contact?.id)}>
        <ResourceIntro
          title={formatName(contact)}
          avatar={avatar}
        />
        {hasFailedContacts && (
          <ResourceRight>
            <p className="u-text-danger">{`${contact.failed} of ${contact.total} Failed`}</p>
          </ResourceRight>
        )}
      </Resource>
    );
  };

  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title" data-cypress="summaryTitle">Summary</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => handleTogglePanel()}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__title u-m-t">
                {fullTimestamp(activeCampaign?.timestamp)}
              </div>
              <div className="summary-panel__intro__actions">
                <UtilityInlineGrid align="middle">
                  {!isLoading && (
                    <Button
                      size="small"
                      type="primary"
                      onClick={openBulkMessageModal}
                    >
                      Message Contacts
                    </Button>
                  )}
                  {activeCampaign?.contactListId ? (
                    <Button
                      size="small"
                      type="primary"
                      outlined
                      onClick={() => handleUpdateContactList(activeCampaign?.contactListId)}
                    >
                      Update List
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      type="primary"
                      outlined
                      onClick={handleCreateContactListModal}
                    >
                      Create Saved List
                    </Button>
                  )}

                </UtilityInlineGrid>
              </div>
            </div>
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Member</div>
              <div className="summary-panel__section__content">
                <div>{activeCampaign?.senderName}</div>
              </div>
            </div>
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Message</div>
              <div className="summary-panel__section__content">
                <div>{activeCampaign?.message}</div>
              </div>
            </div>
            {(formAttachments.length > 0 || otherAttachments.length > 0) && (
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Attachments</div>
              <div className="summary-panel__section__content">
                {formAttachments.length > 0 && (formAttachments.map(renderAttachments))}
                {otherAttachments.length > 0 && (otherAttachments.map(renderAttachments))}
              </div>
            </div>
            )}
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Saved List</div>
              <div className="summary-panel__section__content">
                <div>{`${activeCampaign?.contactListName}(${activeCampaign?.contactCount})`}</div>
              </div>
            </div>
            <div className="summary-panel__section summary-panel__section--full u-m-t">
              <div className="summary-panel__section__label u-text-left">Contacts ({data?.length})
                <ResourceRight>
                  <p className={hasFailedContacts ? 'u-text-danger' : 'u-text-small'}>
                    {`Failed(${activeCampaign?.failed})`}
                  </p>
                </ResourceRight>
              </div>
              <div className="summary-panel__section__content">
                <ResourceGroup>
                  {isLoading ? <Skeleton height="50px" count={activeCampaign?.contactCount < 6 ? activeCampaign?.contactCount : 6} /> : data.map(renderContact)}
                </ResourceGroup>
                {data && data.length > 6 && !viewAllMembers && (
                  <div className="u-m-t-small u-text-right">
                    <Button reset className="u-text-primary u-text-small" onClick={() => setViewAllMembers((current) => !current)}>
                      View All Members
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <BulkMessageModal
            contacts={data || []}
            isModalOpen={isBulkMessageModalOpen}
            handleToggle={() => (setIsBulkMessageModalOpen(false))}
            location={location}
            contactListId={activeCampaign?.contactListId}
          />
          <div>
            <Modal open={isCreateListModalOpen}>
              <ModalHeader onClose={handleCloseCreateContactListModal} title="Save Contact List" />
              <ModalBody>
                <div className="u-text-small savedList-modal__contacts-count">
                  ({data?.length} Contact{data?.length !== 1 && 's'} Added)
                </div>
                <Input name="saveListName" placeholder="Enter List Name" validationMessage={validationMessage} required onChange={handleChange} />
              </ModalBody>
              <ModalFooter>
                <UtilityInlineGrid align="between">
                  <Button type="default" onClick={handleCloseCreateContactListModal}>Cancel</Button>
                  <Button type="primary" loading={isCreateListLoading} onClick={handleCreateSaveList}>Save</Button>
                </UtilityInlineGrid>
              </ModalFooter>
            </Modal>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default CampaignSummaryPanel;
