import axios from 'axios';

export function getOrganization(orgId) {
  return axios.get(`${process.env.REACT_APP_VIDEO_BASE_URL}/org/${orgId}`);
}

export function postOrganization(payload) {
  return axios.post(`${process.env.REACT_APP_VIDEO_BASE_URL}/org/${payload.orgId}`, payload);
}

export function postVideoConference(options) {
  const videoConference = {
    createdBy: options.createdBy,
    userIds: [options.userId],
    memberIds: [],
    hostId: options.memberId.toString(),
    orgId: options.orgId,
    channelId: options.channelId,
    destination: options.destination,
  };
  return axios.post(`${process.env.REACT_APP_VIDEO_BASE_URL}/video/`, videoConference);
}

export function getVideos() {
  return axios.get(`${process.env.REACT_APP_VIDEO_BASE_URL}/videos?active=true`);
}

export function getVideo(videoId) {
  return axios.get(`${process.env.REACT_APP_VIDEO_BASE_URL}/contactVideo/${videoId}`);
}

export function updateVideo(videoId, data) {
  return axios.patch(`${process.env.REACT_APP_VIDEO_BASE_URL}/video/${videoId}`, data);
}

export function patchParticipantsInfo(data) {
  return axios.patch(`${process.env.REACT_APP_VIDEO_BASE_URL}/video/participantsInfo`, data);
}

export function patchParticipant(videoId, data) {
  return axios.patch(`${process.env.REACT_APP_VIDEO_BASE_URL}/video/${videoId}/participants/${data.sid}`, data);
}

export function patchContactParticipant(videoId, data) {
  return axios.patch(`${process.env.REACT_APP_VIDEO_BASE_URL}/video/participants/${data.sid}`, data);
}

export function getVideoSecrets() {
  return axios.get(`${process.env.REACT_APP_VIDEO_BASE_URL}/video`);
}

export function refreshVideoSecrets(refreshToken) {
  return axios.post(`${process.env.REACT_APP_VIDEO_BASE_URL}/video/refresh`, { refreshToken });
}

export function getContactVideoSecrets() {
  return axios.get(`${process.env.REACT_APP_VIDEO_BASE_URL}/contactVideo/`);
}
