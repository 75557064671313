import PropTypes from 'prop-types';
import React from 'react';
import { LoaderCircle } from 'rhinostyle';

const LazyLoader = (props) => {
  const {
    loading,
  } = props;

  const renderLoader = () => (
    <div className="app-lazy-loader__inner">
      <LoaderCircle size="small" className="u-text-primary" />
      Loading
    </div>
  );

  return (
    <div className="app-lazy-loader">
      {loading && renderLoader()}
    </div>
  );
};

LazyLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default LazyLoader;
