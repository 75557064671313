import moment from 'moment-timezone';
import { createSelector } from '@reduxjs/toolkit';
import { getActiveUser } from './userSelectors';
import { DateHelpers, AppointmentHelpers } from '../helpers';
import { Types } from '../constants';

const getAppointments = (state) => state.appointment.appointments;

// eslint-disable-next-line import/prefer-default-export
export const getActiveUserAppointments = createSelector(
  [getActiveUser],
  (activeUser) => {
    const observesDst = true;
    const timeZone = moment.tz.guess();
    return activeUser?.appointments ?
      activeUser.appointments.filter((appointment) => appointment.appointmentStatusTypeId !== Types.TYPE_APPOINTMENT_CANCELLED)
        .map((filteredAppointment) => ({
          startTime: AppointmentHelpers.formatAppointmentTime(DateHelpers.convertDateInTimeZone(filteredAppointment.startDate,
            filteredAppointment.timeZone ?? timeZone,
            filteredAppointment.observesDst ?? observesDst)),
          appointmentStatusTypeId: filteredAppointment.appointmentStatusTypeId,
          id: filteredAppointment.id,
          appointmentStatusTimestamp: DateHelpers.formatTimestamp(filteredAppointment.appointmentStatusTimestamp || filteredAppointment.lastUpdatedAt, 'MM/DD/YY h:mm a z'),
          externalId: filteredAppointment.externalId,
          formattedAppointmentTime: DateHelpers.convertDateInTimeZone(filteredAppointment.startDate,
            filteredAppointment.timeZone ?? timeZone,
            filteredAppointment.observesDst ?? observesDst),
          officeId: filteredAppointment.officeId,
          appointmentTypeId: filteredAppointment.appointmentTypeId,
        })) : [];
  },
);

// provide updated appointments with timezone, via default selected channel in appointment-reminders
export const getAppointmentsWithTimezone = createSelector(
  [getAppointments],
  (appointments) => {
    const observesDst = true;
    const timeZone = moment.tz.guess();

    return appointments.map((appointment) => ({
      ...appointment,
      appointmentStartDate: AppointmentHelpers.formatApptManagerStartDate(DateHelpers.convertDateInTimeZone(appointment.appointmentStartDate,
        appointment.timeZone ?? timeZone,
        appointment.observesDst ?? observesDst)),
    }));
  },
);
