import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, UtilityInlineGrid, Select } from 'rhinostyle';

import { MaskingHelpers, PhoneHelpers } from '../helpers';
import { AppConstants } from '../constants';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { BILLING_EDIT } from '../constants/UserPermissionsConstants';

const OrganizationBillingContact = (props) => {
  const {
    contactModalOpen,
    handleChange,
    handleToggle,
    handleCancel,
    handleOnReverseComplete,
    errors,
    handleCleaveInit,
    handleSubmit,
    formInProgress,
    contactPhone,
    contactEmail,
    contactFirstName,
    contactLastName,
    billing,
    states,
    street1,
    street2,
    city,
    state,
    zip,
    stateCode,
  } = props;

  const validationMessages = {
    contactFirstName: errors.firstName || errors['c.firstName'],
    contactLastName: errors.lastName || errors['c.lastName'],
    contactEmail: errors.contactEmail || errors['c.contactEmail'],
    contactPhone: errors.billingContactPhone || errors['c.billingContactPhone'],
    street1: errors.billingStreet1 || errors['p.billingStreet1'],
    city: errors.billingCity || errors['p.billingCity'],
    zip: errors.billingZip || errors['p.billingZip'],
    state: errors.billingState || errors['p.billingState'],
  };

  const contactModal = () => (
    <Modal onReverseComplete={handleOnReverseComplete} open={contactModalOpen}>
      <ModalHeader
        onClose={handleCancel}
        title="Billing Contact Details"
      />
      <ModalBody>
        <div className="form__group">
          <div className="row">
            <div className="column-6@small">
              <Input
                onChange={handleChange}
                name="contactFirstName"
                initialValue={contactFirstName}
                validationMessage={validationMessages.contactFirstName}
                label="First Name"
                required
              />
            </div>
            <div className="column-6@small">
              <Input
                onChange={handleChange}
                name="contactLastName"
                initialValue={contactLastName}
                validationMessage={validationMessages.contactLastName}
                label="Last Name"
                required
              />
            </div>
          </div>
        </div>
        <div className="form__group">
          <div className="row">
            <div className="column-6@small">
              <Input
                onChange={(contactPhoneName, rawValue, value) => handleChange(contactPhoneName, value)}
                onInit={(cleave) => handleCleaveInit('cleaveContactPhone', cleave)}
                name="contactPhone"
                initialValue={PhoneHelpers.formatPhone(contactPhone)}
                validationMessage={validationMessages.contactPhone}
                label="Phone Number"
                type="tel"
                placeholder={AppConstants.PHONE_PLACEHOLDER}
                format={MaskingHelpers.phone}
              />
            </div>
            <div className="column-6@small">
              <Input
                onChange={handleChange}
                name="contactEmail"
                initialValue={contactEmail}
                validationMessage={validationMessages.contactEmail}
                label="Email Address"
                type="email"
                explanationMessage="This is where the monthly invoices will be delivered"
                required
              />
            </div>
          </div>
        </div>
        <Input
          initialValue={street1}
          onChange={handleChange}
          validationMessage={validationMessages.street1}
          name="street1"
          label="Billing Address Line 1"
          required
        />
        <Input
          initialValue={street2}
          onChange={handleChange}
          name="street2"
          label="Billing Address Line 2"
        />
        <div className="form__group">
          <div className="row">
            <div className="column-5@small">
              <Input
                initialValue={city}
                onChange={handleChange}
                validationMessage={validationMessages.city}
                name="city"
                label="City"
                required
              />
            </div>
            <div className="column-4@small">
              <Select
                onSelect={handleChange}
                name="state"
                selected={state}
                validationMessage={validationMessages.state}
                label="State"
                required
                options={states}
              />
            </div>
            <div className="column-3@small">
              <Input
                initialValue={zip}
                format={MaskingHelpers.zipCode}
                onChange={(inputName, rawValue, value) => handleChange(inputName, value)}
                onInit={(cleave) => handleCleaveInit('cleaveZip', cleave)}
                validationMessage={validationMessages.zip}
                name="zip"
                label="Zip"
                required
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <UtilityInlineGrid align="between">
          <Button title="Cancel updating billing contact" onClick={handleCancel}>
            Cancel
          </Button>
          <Button data-cypress="saveBillingContact" loading={formInProgress} onClick={handleSubmit} type="primary">Save Billing Contact</Button>
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );

  const renderStreet2 = () => (
    <>
      {street2}<br />
    </>
  );

  const renderContact = () => (
    <>
      {PhoneHelpers.formatPhone(contactPhone)}<br />
    </>
  );

  return (
    <div className="box">
      <div className="box__title-wrapper">
        <div className="box__title">Contact Details</div>
      </div>
      {billing ? (
        <p id="billing__contact"><span className="u-font-weight-bold">{contactFirstName} {contactLastName}</span><br />
          {contactEmail}<br /> {contactPhone ? renderContact() : ''}
          {street1} <br />
          {street2 ? renderStreet2() : ''}{city}, {stateCode} {zip}
        </p>
      ) :
        <p>No billing contact has been added for this organization.</p>}
      {userHasAnyOfPermissions([BILLING_EDIT]) && (
        <Button
          data-cypress={billing ? 'updateBillingContact' : ''}
          reset
          className="u-text-primary"
          onClick={() => handleToggle('contactModalOpen')}
        >
          {billing ? 'Update' : 'Add'} Billing Contact
        </Button>
      )}
      {contactModal()}
    </div>
  );
};

OrganizationBillingContact.propTypes = {
  billing: PropTypes.object,
  contactModalOpen: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleCleaveInit: PropTypes.func.isRequired,
  handleOnReverseComplete: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  contactPhone: PropTypes.string.isRequired,
  contactEmail: PropTypes.string.isRequired,
  contactFirstName: PropTypes.string.isRequired,
  contactLastName: PropTypes.string.isRequired,
  street1: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.number.isRequired,
  zip: PropTypes.string.isRequired,
  street2: PropTypes.string.isRequired,
  states: PropTypes.array.isRequired,
  stateCode: PropTypes.string.isRequired,
};

export default OrganizationBillingContact;
