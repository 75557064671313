import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Alert,
  Avatar,
  Button,
  Close,
  Icon,
  RhinoSwitch,
  Select,
  ResourceGroup,
  UtilitySystem,
  Resource,
  ResourceIntro,
  ResourceBody,
  ResourceRight,
  UtilityInlineGrid,
  ResourceBottom,
} from 'rhinostyle';
import moment from 'moment-timezone';

import PageLoader from './PageLoader';
import ContactFormGeneralInfo from './ContactFormGeneralInfo';
import CommunicationConsent from './ContactProfile/CommunicationConsent';
import TagsGroup from './TagsGroup';
import UploadAvatarModal from './UploadAvatarModal';
import UserSearchContainer from '../containers/UserSearchContainer';
import GroupSearch from './GroupSearch';
import BackButtonContainer from '../containers/BackButtonContainer';
import { DateHelpers, UserHelpers } from '../helpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { convertDateInTimeZone } from '../helpers/DateHelpers';
import { AVATAR_BASE_URL } from '../constants/AppConstants';
import { CONTACT_EDIT, CONTACT_CREATE, CONTACT_ORGANIZATION_CREATE } from '../constants/UserPermissionsConstants';
import {
  TYPE_INTEGRATION_CLOUD_9,
  TYPE_INTEGRATION_DOLPHIN,
  TYPE_INTEGRATION_FOCUS_ORTHO,
  TYPE_INTEGRATION_MI7,
  TYPE_INTEGRATION_NEXTECH,
  TYPE_INTEGRATION_OPEN_DENTAL,
  TYPE_INTEGRATION_PROGNOCIS,
  TYPE_INTEGRATION_SIKKA,
  TYPE_PATIENT,
  TYPE_RHINOPAY_CONSENT_PENDING,
  TYPE_UPDATED_BY_MEMBER,
  TYPE_USER_OTHER,
} from '../constants/Types';
import NewConnectedPartyContactModalContainer from '../containers/NewConnectedPartyContactModalContainer';

const ContactForm = (props) => {
  const location = useLocation();
  const history = useHistory();
  const {
    actionTitle,
    actionType,
    activeUserConnectionKeys,
    addNewConnectedPartyContactModalOpen,
    automatedMessages,
    avatarName,
    closeModal,
    connectedPartyTypes,
    clearUserSearch,
    editMode,
    errors,
    emailTypes,
    firstName,
    formInProgress,
    handleFormChanges,
    handleChange,
    handleCreateCPClick,
    handleForwardingToggle,
    handleRemoveUserConnection,
    handleSubmit,
    handleToggle,
    handleUploadAvatar,
    handleUpdateSelectedId,
    handleUpdateSelectedIds,
    handleUserConnectionChange,
    handleUserConnectionSearchSelect,
    hipaaStatusTypeId,
    integrated,
    isForwardingEnabled,
    isRhinopayEnabled,
    lastName,
    lastIntegrationUpdate,
    marketingConsentStatusTypeId,
    organization,
    pageContainerRef,
    pageLoading,
    pageTitle,
    phoneTypes,
    prefixOpts,
    profileImageUrl,
    rhinopayConsentStatusTypeId,
    selectedForwardingGroupId,
    selectedForwardingMemberId,
    selectedTagIds,
    showConnectedPartySearch,
    suffixOpts,
    tagIds,
    tags,
    typeId,
    uploadAvatarModalOpen,
    userConnections,
    viewGroups,
    isLockedByAnotherUser,
    recordLock,
  } = props;
  const isAppointmentRemindersVisible = integrated &&
    [
      TYPE_INTEGRATION_CLOUD_9,
      TYPE_INTEGRATION_DOLPHIN,
      TYPE_INTEGRATION_FOCUS_ORTHO,
      TYPE_INTEGRATION_MI7,
      TYPE_INTEGRATION_NEXTECH,
      TYPE_INTEGRATION_OPEN_DENTAL,
      TYPE_INTEGRATION_PROGNOCIS,
      TYPE_INTEGRATION_SIKKA,
    ].includes(organization.integrationPartnerTypeId);

  const tabTriggerMemberClasses = `tabs__trigger ${!viewGroups ? UtilitySystem.config.classes.active : ''}`;
  const tabTriggerGroupsClasses = `tabs__trigger ${viewGroups ? UtilitySystem.config.classes.active : ''}`;
  const recordOwner = recordLock && recordLock.owner && recordLock.owner.firstName && recordLock.owner.lastName ?
    `${recordLock.owner.firstName} ${recordLock.owner.lastName}` : 'another member';
  const isSubmitDisabled =
    isLockedByAnotherUser
    || (editMode === 'update' && !userHasAnyOfPermissions([CONTACT_EDIT]))
    || (editMode === 'create' && !userHasAnyOfPermissions([CONTACT_CREATE, CONTACT_ORGANIZATION_CREATE]));

  const renderViewMembers = () => (
    <UserSearchContainer
      interfaceMode="radio"
      type="preloadedMembers"
      selectedUserId={selectedForwardingMemberId}
      handleSearchSelect={(id) => handleUpdateSelectedId('selectedForwardingMemberId', id)}
      isAssignmentSearch
    />
  );

  const renderViewGroups = () => (
    <GroupSearch
      handleSearchSelect={(id) => handleUpdateSelectedId('selectedForwardingGroupId', id)}
      selectedGroupId={selectedForwardingGroupId}
      interfaceMode="radio"
      prepopulate
      excludeChatGroups
    />
  );

  const renderConnectedParty = (key, idx) => {
    const connectedParty = userConnections[key];
    const isActive = activeUserConnectionKeys.includes(key);
    const timeZone = moment.tz.guess();
    const user = {
      birthday: connectedParty.userBirthday,
      hipaaStatus: connectedParty.hipaaStatus,
      firstName: connectedParty.userFirstName,
      lastName: connectedParty.userLastName,
      preferredName: connectedParty.userPreferredName,
      suffixId: connectedParty.userSuffixId,
      prefixId: connectedParty.userPrefixId,
      id: connectedParty.userId,
      profileImageUrl: connectedParty.profileImageUrl && `${AVATAR_BASE_URL}${connectedParty.profileImageUrl}`,
      note: connectedParty.note,
      connectionTypeId: connectedParty.connectionTypeId,
      updatedByTimestamp: connectedParty.updatedByTimestamp,
      updatedByTypeId: connectedParty.updatedByTypeId,
      integrated: connectedParty.integrated,
    };
    return (
      <Resource
        key={idx}
        active={isActive}
        className="profile__connected-party"
      >
        <ResourceIntro
          avatar={{
            image: user.profileImageUrl,
            name: UserHelpers.formatAvatarName(user.firstName, user.lastName),
          }}
          title={UserHelpers.formatName(user)}
        >
          {isActive && <span className="u-text-accent">Newly Created Contact</span>}
        </ResourceIntro>
        <ResourceBody>
          <Select
            options={connectedPartyTypes}
            selected={user.connectionTypeId}
            name="connectionTypeId"
            disabled={isLockedByAnotherUser}
            onSelect={(name, value) => {
              handleUserConnectionChange(name, value, key);
            }}
          />
        </ResourceBody>
        <ResourceRight noFlex>
          <Close
            title="Remove connected party"
            onClick={() => handleRemoveUserConnection(key)}
            disabled={isLockedByAnotherUser}
          />
        </ResourceRight>
        {integrated && user.integrated && user.updatedByTimestamp && user.updatedByTypeId === TYPE_UPDATED_BY_MEMBER && (
          <ResourceBottom>
            <div className="u-text-small u-text-center u-text-italic u-text-muted">
              {`Relationship updated in Rhinogram ${moment(convertDateInTimeZone(user.updatedByTimestamp, timeZone)).format('MM-DD-YYYY [at] h:mma')}`}
            </div>
          </ResourceBottom>
        )}
      </Resource>
    );
  };

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <div className="app-page__container" ref={pageContainerRef}>
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title">
            <BackButtonContainer history={history} location={location} />
            {pageTitle}
          </div>
        </div>
        <form onChange={handleFormChanges}>
          <fieldset disabled={isLockedByAnotherUser}>
            {isLockedByAnotherUser && (
              <Alert className="u-m-b u-text-center" type="danger" dataCypress="lockAlert">
                <Icon icon="alert-triangle" bump="up" />&nbsp;
                This contact is being edited by {recordOwner}. Please try again later.
              </Alert>
            )}
            {integrated && lastIntegrationUpdate &&
              <Alert className="u-m-b u-text-center" type="info">Contains data imported on {DateHelpers.fullTimestamp(lastIntegrationUpdate)}</Alert>}
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">General Information</div>
              </div>
              <div className="edit-profile__avatar-wrapper">
                <Avatar
                  type="default"
                  className="edit-profile__avatar"
                  size="xlarge"
                  image={profileImageUrl}
                  name={avatarName}
                />
                <Button reset className="u-text-primary" disabled={isLockedByAnotherUser} onClick={() => handleToggle('uploadAvatarModalOpen')}>
                  {profileImageUrl ? 'Update' : 'Add'} Photo
                </Button>
                <UploadAvatarModal
                  onClose={() => handleToggle('uploadAvatarModalOpen')}
                  open={uploadAvatarModalOpen}
                  uploadAvatar={handleUploadAvatar}
                  profileImageUrl={profileImageUrl}
                />
              </div>
              <ContactFormGeneralInfo {...props} />
            </div>

            {isAppointmentRemindersVisible && (
              <div className="box">
                <div className="box__title-wrapper">
                  <div className="box__title">Appointment Reminders</div>
                  <div className="box__subtitle">Text message reminders will be delivered to patients prior to their appointment.</div>
                </div>
                <RhinoSwitch
                  label="Allow Reminders"
                  isChecked={automatedMessages}
                  name="automatedMessages"
                  onChange={handleToggle}
                />
              </div>
            )}

            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">Forwarding</div>
                <div className="box__subtitle">Forward all communication from this user to a Group or Member by searching below.</div>
              </div>
              <RhinoSwitch
                label="Enable Forwarding"
                isChecked={isForwardingEnabled}
                name="forwarding"
                onChange={handleForwardingToggle}
              />
              {isForwardingEnabled && (
                <>
                  <div className="tabs__header u-m-t-large">
                    <Button reset className={tabTriggerMemberClasses} onClick={() => handleToggle('viewGroups')}>
                      Members
                    </Button>
                    &nbsp;|&nbsp;
                    <Button reset className={tabTriggerGroupsClasses} onClick={() => handleToggle('viewGroups')}>
                      Groups
                    </Button>
                  </div>
                  {viewGroups ? renderViewGroups() : renderViewMembers()}
                </>
              )}
            </div>
            {(typeId === TYPE_PATIENT || typeId === TYPE_USER_OTHER) && (
              <CommunicationConsent
                users={props.users}
                activeUser={props.users[props.userId]}
                handleChange={handleChange}
                hipaaStatusTypeId={hipaaStatusTypeId}
                isRhinopayEnabled={isRhinopayEnabled}
                marketingConsentStatusTypeId={marketingConsentStatusTypeId}
                rhinopayConsentStatusTypeId={rhinopayConsentStatusTypeId}
              />
            )}
            <TagsGroup
              activeTagIds={selectedTagIds}
              tags={tags}
              tagIds={tagIds}
              title="Tags"
              onClick={(e, id) => handleUpdateSelectedIds('selectedTagIds', id)}
              disabled={isLockedByAnotherUser}
            />

            <div id="connected-parties" className="box">
              <div className="box__title-wrapper">
                <div className="box__title">Connected Parties</div>
                <div className="box__subtitle">
                  Add connections between contacts in Rhinogram and make a note of any legal considerations.
                  For patients under 18, add their parent or guardian as a Connected Party.
                </div>
              </div>

              {Object.keys(userConnections).length > 0 && (
                <>
                  <div className="search__sub profile__connected-parties__label">
                    Relationship to {firstName || lastName ? `${firstName} ${lastName}` : 'Contact'}
                  </div>
                  <ResourceGroup>
                    {Object.keys(userConnections).map(renderConnectedParty)}
                  </ResourceGroup>
                </>
              )}

              {!showConnectedPartySearch && (
                <div className="u-text-center u-m-t-large">
                  <Button
                    size="small"
                    type="primary"
                    outlined
                    onClick={() => handleToggle('showConnectedPartySearch')}
                    disabled={isLockedByAnotherUser}
                  >
                    Add Connected Party
                  </Button>
                </div>
              )}

              <NewConnectedPartyContactModalContainer
                open={addNewConnectedPartyContactModalOpen}
                closeModal={closeModal}
                errorList={errors}
                phoneTypes={phoneTypes}
                emailTypes={emailTypes}
                handleSearchSelect={handleUserConnectionSearchSelect}
                suffixOpts={suffixOpts}
                prefixOpts={prefixOpts}
                rhinopayConsentStatusTypeId={TYPE_RHINOPAY_CONSENT_PENDING}
                clearUserSearch={clearUserSearch}
                isRhinopayEnabled={isRhinopayEnabled}
              />

              {showConnectedPartySearch && (
                <>
                  <div className="u-m-t-large u-m-b-small u-text-small">
                    <UtilityInlineGrid space="large" align="between">
                      <Button
                        reset
                        className="u-text-muted"
                        onClick={() => handleToggle('showConnectedPartySearch')}
                      >
                        Cancel
                      </Button>
                      <Button
                        reset
                        className="u-text-primary"
                        onClick={handleCreateCPClick}
                      >
                        Create New Contact
                      </Button>
                    </UtilityInlineGrid>
                  </div>
                  <UserSearchContainer
                    coverUserId={props.userId}
                    type="connectedParty"
                    selectedUserIds={Object.keys(userConnections).map((key) => userConnections[key].userId)}
                    handleSearchSelect={(id) => handleUserConnectionSearchSelect(id, false)}
                  />
                </>
              )}
            </div>

            <div className="u-m-t-large u-text-right">
              <Button
                loading={formInProgress}
                type={actionType}
                onClick={handleSubmit}
                data-cypress={actionTitle}
              >
                {isSubmitDisabled && <><Icon icon="lock" />&nbsp;</>}
                {actionTitle}
              </Button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

ContactForm.propTypes = {
  actionTitle: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  addNewConnectedPartyContactModalOpen: PropTypes.bool.isRequired,
  activeUserConnectionKeys: PropTypes.array.isRequired,
  automatedMessages: PropTypes.bool.isRequired,
  avatarName: PropTypes.string,
  birthday: PropTypes.object,
  clearUserSearch: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  connectedPartyTypes: PropTypes.array.isRequired,
  editMode: PropTypes.string.isRequired,
  emailTypes: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  externalId: PropTypes.string,
  displayId: PropTypes.string,
  firstName: PropTypes.string,
  formInProgress: PropTypes.bool.isRequired,
  handleBirthdayChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCreateCPClick: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  handleForwardingToggle: PropTypes.func.isRequired,
  handleReceiveContactData: PropTypes.func.isRequired,
  handleRemoveContactData: PropTypes.func.isRequired,
  handleRemoveUserConnection: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleUpdateSelectedId: PropTypes.func.isRequired,
  handleUpdateSelectedIds: PropTypes.func.isRequired,
  handleUploadAvatar: PropTypes.func.isRequired,
  handleUserConnectionChange: PropTypes.func.isRequired,
  handleUserConnectionSearchSelect: PropTypes.func.isRequired,
  handleViewProfileTransition: PropTypes.func.isRequired,
  hipaaStatusTypeId: PropTypes.number,
  integrated: PropTypes.bool.isRequired,
  isForwardingEnabled: PropTypes.bool.isRequired,
  isLockedByAnotherUser: PropTypes.bool,
  isRhinopayEnabled: PropTypes.bool.isRequired,
  lastIntegrationUpdate: PropTypes.string,
  lastName: PropTypes.string,
  marketingConsentStatusTypeId: PropTypes.number,
  middleName: PropTypes.string,
  note: PropTypes.string,
  noteIsImportant: PropTypes.bool,
  organization: PropTypes.object.isRequired,
  pageContainerRef: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  phoneTypes: PropTypes.array.isRequired,
  preferredName: PropTypes.string,
  prefixId: PropTypes.number,
  prefixOpts: PropTypes.array.isRequired,
  profileImageUrl: PropTypes.string,
  recordLock: PropTypes.object,
  rhinopayConsentStatusTypeId: PropTypes.number,
  rhinogramChannelOpts: PropTypes.array,
  rhinogramChannelsLoading: PropTypes.bool.isRequired,
  selectedForwardingGroupId: PropTypes.number.isRequired,
  selectedForwardingMemberId: PropTypes.number.isRequired,
  selectedTagIds: PropTypes.array.isRequired,
  sex: PropTypes.string,
  showConnectedPartySearch: PropTypes.bool.isRequired,
  suffixId: PropTypes.number,
  suffixOpts: PropTypes.array.isRequired,
  tagIds: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  typeId: PropTypes.number.isRequired,
  uploadAvatarModalOpen: PropTypes.bool.isRequired,
  userConnections: PropTypes.object.isRequired,
  userEmails: PropTypes.object.isRequired,
  userId: PropTypes.number,
  userPhones: PropTypes.object.isRequired,
  userRhinograms: PropTypes.object.isRequired,
  viewGroups: PropTypes.bool.isRequired,
  users: PropTypes.object,
};

export default ContactForm;
