import axios from 'axios';

class AppUpdateService {
  static DELAY = 60000; // 1 minute

  currentETag = null;
  availableETag = null;
  intervalID = null;
  currentBuildVersion = null;
  availableBuildVersion = null;

  enable() {
    this.disable(); // Prevent multiple intervals
    this.intervalID = setInterval(this.checkForUpdate, AppUpdateService.DELAY);
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      this.fetchAvailableBuildVersion();
    }
  }

  disable() {
    if (this.intervalID) clearInterval(this.intervalID);
  }

  isUpdateAvailable = () => (this.currentETag !== this.availableETag ||
       (this.availableBuildVersion && this.availableBuildVersion > this.currentBuildVersion)
  )

  fetchAvailableBuildVersion() {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        this.currentBuildVersion = process.env.BUILD_VERSION ? parseInt(process.env.BUILD_VERSION, 10) : null;
        this.availableBuildVersion = meta.BUILD_VERSION ? parseInt(meta.BUILD_VERSION, 10) : null;
        console.log(`Current build version: ${this.currentBuildVersion}`, `Available build version: ${this.availableBuildVersion}`); // eslint-disable-line no-console
      }).catch((e) => {
        console.error('Error fetching build version: ', e);
      });
  }

  fetchETag = () =>
    axios.head('/', {
      baseURL: `${window.location.protocol}//${window.location.host}`,
    })
      .then((response) => response.headers.etag);

  updateETags = (etag) => {
    if (!this.currentETag) this.currentETag = etag;
    this.availableETag = etag;
  }

  checkForUpdate = () => {
    this.fetchETag()
      .then(this.updateETags)
      .catch((e) => {
        console.error('Error checking for app update: ', e);
      });
  }
}

export default new AppUpdateService();
