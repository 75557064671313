import PropTypes from 'prop-types';
import React from 'react';
import { Chart, DateRangeDropdown } from 'rhinostyle';

import PageLoader from './PageLoader';
import { exists } from '../helpers/DataHelpers';

const MessagingAnalytics = (props) => {
  const {
    activeKey,
    contactChartData,
    endDate,
    messageChartData,
    minDate,
    maxDate,
    pageLoading,
    peakTimeChartData,
    responseTimeChartData,
    startDate,
    selectDate,
    datePickerDropdownLabels,
    calculateDateRange,
  } = props;

  const isContactChartDataAvailable =
    exists(contactChartData.data)
    && exists(contactChartData.data)
    && exists(contactChartData.data.datasets)
    && contactChartData.data.datasets.length > 0;
  const renderCharts = () => (
    <div className="row analytics__charts-container">
      <div className="column-6@medium">
        <Chart
          data={responseTimeChartData.data}
          options={responseTimeChartData.options}
          legend={responseTimeChartData.legend}
          type="line"
          title="Response Time"
          header={{ text: `Avg. ${responseTimeChartData.average} Mins.`, color: 'secondary' }}
          info="Time taken to respond to the initial incoming message."
          subHeader=""
        />
      </div>
      <div className="column-6@medium">
        <Chart
          data={messageChartData.data}
          options={messageChartData.options}
          legend={messageChartData.legend}
          type="bar"
          title="Total Messages"
          header={{ text: messageChartData.totalMessages, color: 'primary' }}
          info="Total number incoming, outgoing, and automated messages."
          subHeader={`(${messageChartData.average} avg.)`}
          onLegendClick={messageChartData.onLegendClick}
        />
      </div>
      <div className="column-6@medium">
        <Chart
          data={peakTimeChartData.data}
          options={peakTimeChartData.options}
          legend={peakTimeChartData.legend}
          type="line"
          title="Peak Message Time"
          info="Time of day when most incoming messages are received."
          header={{ text: `${peakTimeChartData.header}`, color: 'u-text-accent' }}
          subHeader=""
        />
      </div>
      <div className="column-6@medium doughnut__chart-wrapper">
        {isContactChartDataAvailable && (
          <div className="doughnut__chart-title u-text-muted">Source</div>
        )}
        <Chart
          width={contactChartData.width}
          height={contactChartData.height}
          data={contactChartData.data}
          options={contactChartData.options}
          type="doughnut"
          title="New Inbound Contacts"
          header={{ text: `${contactChartData.total} Total`, color: 'primary' }}
          info="Total amount of new unknown contacts created by incoming messages."
          subHeader={contactChartData.responseRate !== null ? `(${contactChartData.responseRate}% response rate)` : ''}
        />
      </div>
    </div>
  );

  return (
    <div className="app-page__container analytics">
      <div className="app-page__container__inner analytics__container">
        <div className="app-page__header">
          <div className="app-page__header__title analytics__header">Messaging Analytics</div>
        </div>
        <div className="analytics__wrapper">
          <div className="analytics__title">
            Overview
          </div>
          <div className="analytics__date-range">
            <DateRangeDropdown
              position="center"
              name="Analytics Date Picker"
              startDate={startDate}
              endDate={endDate}
              selectDate={selectDate}
              minDate={minDate}
              maxDate={maxDate}
              activeKey={activeKey}
              dropdownMenuItems={datePickerDropdownLabels}
              selectDateRange={calculateDateRange}
              isCustomDate
            />
          </div>
          {pageLoading ? <PageLoader /> : renderCharts()}
        </div>
      </div>
    </div>
  );
};

MessagingAnalytics.propTypes = {
  activeKey: PropTypes.number,
  contactChartData: PropTypes.object,
  endDate: PropTypes.object,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  messageChartData: PropTypes.object,
  peakTimeChartData: PropTypes.object,
  pageLoading: PropTypes.bool,
  responseTimeChartData: PropTypes.object,
  startDate: PropTypes.object,
  selectDate: PropTypes.func,
  datePickerDropdownLabels: PropTypes.array.isRequired,
  calculateDateRange: PropTypes.func.isRequired,
};

export default MessagingAnalytics;
