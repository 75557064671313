import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  UtilityList,
} from 'rhinostyle';
import { convertHoursToDays } from '../helpers/DateHelpers';
import SingleAttachmentPreview from './SingleAttachmentPreview';
import { replaceVariablesWithSampleText } from '../helpers/TemplateHelpers';
import { NOTE_TRUNCATE_LENGTH } from '../constants/AppConstants';
import OptOut from './OptOut';

const AppointmentCampaignSummaryReminder = (
  {
    reminder: {
      template,
      deliveryHours,
      enabled,
    },
    attachments,
    variableOptions,
  },
) => {
  const [truncateReminderText, setTruncateReminderText] = useState(template);

  const renderTemplateText = (text, truncateText) => (truncateText ?
    replaceVariablesWithSampleText(variableOptions, text).substring(0, NOTE_TRUNCATE_LENGTH) :
    replaceVariablesWithSampleText(variableOptions, text));

  const renderAttachment = (attachment, key) => (
    <SingleAttachmentPreview
      attachment={attachment}
      index={key}
      key={attachment.attachmentUrl}
    />
  );
  const renderAttachments = () => (attachments?.length > 0 ? (
    <div className="summary-panel__section__content u-m-t">
      <UtilityList space className="u-m-b-0">
        {attachments.map(renderAttachment)}
      </UtilityList>
    </div>
  ) : null);

  return (
    <div className="summary-panel__section">
      <div className="summary-panel__section__label">Appointment Reminder</div>
      {enabled && (
      <div className="summary-panel__section__content prescription-campaigns__template">
        <UtilityList space className="u-m-b-0">
          <div className="u-m-b-0 u-font-weight-bold">Reminder Delivery:</div>
          <div className="u-m-t-0 u-m-b-small">{convertHoursToDays(deliveryHours, true)}</div>
          {renderTemplateText(template, truncateReminderText)}
          {!truncateReminderText && <OptOut className="u-text-gray" />}
          {truncateReminderText && (
          <Button
            type="reset"
            className="profile__forms__expand u-text-primary"
            onClick={() => setTruncateReminderText(false)}
          >
            View more...
          </Button>
          )}
          {renderAttachments()}
        </UtilityList>
      </div>
      )}
    </div>
  );
};

AppointmentCampaignSummaryReminder.propTypes = {
  reminder: PropTypes.shape({
    template: PropTypes.string,
    deliveryHours: PropTypes.number,
    enabled: PropTypes.bool,
  }).isRequired,
  attachments: PropTypes.array,
  variableOptions: PropTypes.array,
};

export default AppointmentCampaignSummaryReminder;
