export function fetchMonths() {
  return [
    {
      id: 1,
      value: 'January',
    },
    {
      id: 2,
      value: 'February',
    },
    {
      id: 3,
      value: 'March',
    },
    {
      id: 4,
      value: 'April',
    },
    {
      id: 5,
      value: 'May',
    },
    {
      id: 6,
      value: 'June',
    },
    {
      id: 7,
      value: 'July',
    },
    {
      id: 8,
      value: 'August',
    },
    {
      id: 9,
      value: 'September',
    },
    {
      id: 10,
      value: 'October',
    },
    {
      id: 11,
      value: 'November',
    },
    {
      id: 12,
      value: 'December',
    },
  ];
}

export function fetchYears() {
  const returnVal = [];
  const currentYear = new Date().getFullYear();
  const maxYear = 120; // however many years we want to include for potential birthdays

  for (let i = currentYear - maxYear; i < currentYear + 1; i++) {
    const yearShape = {
      id: i,
      value: i,
    };

    returnVal.push(yearShape);
  }

  return returnVal.reverse();
}

export function fetchYearsForExpiration() {
  const currentYear = new Date().getFullYear();
  const yearCount = 120;

  return Array(yearCount).fill(currentYear).map((v, i) => ({ id: i + v, value: i + v }));
}

export function fetchDays(month, year) {
  const returnVal = [];
  let monthLength;

  switch (month) {
    case 2:
      monthLength = (year % 4) === 0 || !year || year === -1 ? 29 : 28;
      break;
    case 4:
    case 6:
    case 9:
    case 11:
      monthLength = 30;
      break;
    default:
      monthLength = 31;
  }

  for (let i = 1; i < monthLength + 1; i++) {
    const monthShape = {
      id: i,
      value: i,
    };

    returnVal.push(monthShape);
  }

  return returnVal;
}
