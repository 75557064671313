import {
  useGetCampaignsQuery,
} from '../services/campaignService';

const useCampaigns = ({ sortBy, sortOrder, pageNo, fromDate, toDate, savedListIds }) => {
  const results = useGetCampaignsQuery({ sortBy, sortOrder, pageNo, fromDate, toDate, savedListIds });
  return results;
};

export default useCampaigns;
