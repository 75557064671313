import { exists } from './DataHelpers';

export function getAddonFee(arg, props, productCode) { // eslint-disable-line import/prefer-default-export
  const { subscription } = props;
  const feeStructure = { isWarning: false, fee: 0 };
  let fee = { ...feeStructure };
  let isPriceOverride = false;
  if (props.billing && subscription && subscription.subscriptionProducts) {
    const product = subscription.subscriptionProducts
      .filter((products) => products.planProduct && products.planProduct.productCode === productCode)[0];
    let quantityRange;

    if (!exists(product)) {
      return fee;
    } else if (product && product.subscriptionProductPriceOverride) {
      isPriceOverride = true;
      quantityRange = product.subscriptionProductPriceOverride.priceRanges;
    } else {
      quantityRange = product.planProduct.orderToCashCycles[0].pricingModel.quantityRanges;
    }

    for (let i = 0; i < quantityRange.length; i++) {
      const isInfinite = (quantityRange[i].max === null || quantityRange[i].max === undefined);
      let { productCount } = props;
      if (!arg) {
        productCount += 1;
      }
      if ((productCount > quantityRange[i].min) && (isInfinite || productCount <= quantityRange[i].max)) {
        if (!isPriceOverride) {
          fee = { isWarning: !!quantityRange[i].prices[0].amount, fee: quantityRange[i].prices[0].amount };
          break;
        } else {
          fee = { isWarning: !!quantityRange[i].price, fee: quantityRange[i].price };
          break;
        }
      }
    }
    return fee;
  }
  return fee;
}

export const getSubscriptionProducts = (subscriptionProducts) => {
  const products = subscriptionProducts && subscriptionProducts.map((product) => {
    if (product) {
      let limit;
      let productCategory;
      const { planProduct, subscriptionProductPriceOverride, customFields } = product;
      const { orderToCashCycles, quantity, productName, productCode } = planProduct;
      const ranges = orderToCashCycles[0] && orderToCashCycles[0].pricingModel && orderToCashCycles[0].pricingModel.quantityRanges;
      if (subscriptionProductPriceOverride) {
        const { priceRanges } = subscriptionProductPriceOverride;
        limit = (priceRanges.length) ? priceRanges[0].max : Infinity;
      } else {
        limit = (ranges[0] && ranges[0].max) || Infinity;
      }
      if (customFields) productCategory = customFields[0] && customFields[0].key;
      return {
        ...product,
        limit,
        productName,
        productCategory,
        quantity,
        productCode,
      };
    }
    return {
      limit: 0,
      productName: null,
      productCategory: null,
      quantity: 0,
      productCode: null,
    };
  });
  return products;
};
