import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import {
  Button,
  Input,
  Select,
  RadioGroup,
  Radio,
  Date,
  Dropdown,
  DropdownMenuItem,
  RhinoSwitch,
  DropdownSearchSelect,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UtilityInlineGrid,
  Icon,
} from 'rhinostyle';

import { fetchMonths, fetchYearsForExpiration } from '../helpers/BirthdayHelpers';
import { MaskingHelpers } from '../helpers';
import { AppConstants } from '../constants';

const AccountSetup = (props) => {
  const {
    errors,
    formInProgress,
    handleChange,
    activeCoupon,
    handleRemoveCoupon,
    handleSubmit,
    handleToggle,
    pageContainerRef,
    selectedBillingOpt,
    selectedPaymentMethod,
    startBillingDate,
    salesReps,
    states,
    billingChecked,
    products,
    activeProduct,
    disabled,
    coupons,
    couponSelected,
    bankAccountTypes,
    activeBankAccntTypeId,
    state,
    salesRep,
    billingState,
    filteredParentCompanies,
    filteredParentCompanyIds,
    selectedParentCompanyId,
    selectedParentCompany,
    fetchAllItems,
    handleUpdateFormSelectedId,
    parentCompanySearchLoading,
    parentCompanyLabel,
    handleAddParentOrganization,
    isAddParentOrgModalOpen,
    companyName,
    handleCloseModal,
    saveParentCompany,
    parentCompanyFormInProgress,
    parentCompanyformErrors,
  } = props;

  const validationMessages = {
    name: errors.name || errors['org.name'],
    street1: errors.street1 || errors['org.street1'],
    city: errors.city || errors['org.city'],
    state: errors.state || errors['org.state'],
    zip: errors.zip || errors['org.zip'],
    firstName: errors.firstName || errors['org.firstName'],
    lastName: errors.lastName || errors['org.lastName'],
    billingStreet1: errors.billingStreet1 || errors['org.billingStreet1'],
    billingCity: errors.billingCity || errors['org.billingCity'],
    billingZip: errors.billingZip || errors['org.billingZip'],
    billingState: errors.billingState || errors['org.billingState'],
    billingContactFirstName: errors.billingContactFirstName || errors['org.billingContactFirstName'],
    billingContactLastName: errors.billingContactLastName || errors['org.billingContactLastName'],
    billingContactEmail: errors.billingContactEmail || errors['org.billingContactEmail'],
    billingContactPhone: errors.billingContactPhone || errors['org.billingContactPhone'],
    orgContactPhone: errors.contactPhone || errors['org.contactPhone'],
    orgContactEmail: errors.contactEmail || errors['org.contactEmail'],
    businessEmail: errors.businessEmail || errors['org.businessEmail'],
    businessPhone: errors.businessPhone || errors['org.businessPhone'],
    billManagerCustId: errors.billManagerCustId || errors['org.billManagerCustId'],
    creditCardNumber: errors.creditCardNumber || errors['org.creditCardNumber'],
    creditCardVerificationValue: errors.creditCardVerificationValue || errors['org.creditCardVerificationValue'],
    creditCardExpYear: errors.creditCardExpYear || errors['org.creditCardExpYear'],
    creditCardExpMonth: errors.creditCardExpMonth || errors['org.creditCardExpMonth'],
    bankName: errors.bankName || errors['org.bankName'],
    bankAccNum: errors.bankAccNum || errors['org.bankAccNum'],
    bankRouteNum: errors.bankRouteNum || errors['org.bankRouteNum'],
    salesRep: errors.salesRep || errors['org.salesRep'],
    installAmount: errors.installAmount || errors['org.installAmount'],
    creditCardZip: errors.creditCardZip || errors['org.creditCardZip'],
  };

  const monthOpts = fetchMonths();
  monthOpts.unshift({ value: '--', id: -1 });

  const yearOpts = fetchYearsForExpiration();
  yearOpts.unshift({ value: '--', id: -1 });

  // Defaulting the next billing date to a month from today
  const getMinDate = () => { // eslint-disable-line
    return moment();
  };

  const renderCoupons = (c) => <DropdownMenuItem key={c.id} id={c.id} label={c.code ? c.code : null} labelDesc={c.description ? c.description : null} />;

  const renderBank = () => (
    <div className="form__group">
      <div className="row">
        <div className="column-4@small">
          <Input
            onChange={handleChange}
            validationMessage={validationMessages.bankName}
            name="bankName"
            label="Bank Name"
            required
          />
        </div>
        <div className="column-4@small">
          <Input
            onChange={handleChange}
            name="bankAccNum"
            label="Bank Account Number"
            validationMessage={validationMessages.bankAccNum}
            required
          />
        </div>
        <div className="column-4@small">
          <Input
            onChange={handleChange}
            name="bankRouteNum"
            label="Routing Number"
            validationMessage={validationMessages.bankRouteNum}
            required
          />
        </div>
        <div className="column-4@small">
          <Select
            onSelect={handleChange}
            label="Bank Account Type"
            required
            name="bankAccountType"
            options={bankAccountTypes}
            selected={activeBankAccntTypeId}
          />
        </div>
      </div>
    </div>
  );

  const renderCredit = () => (
    <div className="form__group">
      <Input
        onChange={handleChange}
        format={MaskingHelpers.creditCard}
        name="ccNumber"
        label="Card Number"
        validationMessage={validationMessages.creditCardNumber}
        required
      />
      <div className="form__group">
        <div className="row">
          <div className="column-3@small">
            <Select
              onSelect={handleChange}
              label="Expiration Month"
              options={monthOpts}
              name="ccExpMonth"
              validationMessage={validationMessages.creditCardExpMonth}
              required
            />
          </div>
          <div className="column-3@small">
            <Select
              onSelect={handleChange}
              label="Expiration Year"
              options={yearOpts}
              name="ccExpYear"
              required
              validationMessage={validationMessages.creditCardExpYear}
            />
          </div>
          <div className="column-3@small">
            <Input
              onChange={handleChange}
              name="creditCardVerificationValue"
              label="CVV"
              validationMessage={validationMessages.creditCardVerificationValue}
              required
            />
          </div>
          <div className="column-3@small">
            <Input
              format={MaskingHelpers.zipCode}
              onChange={(creditCardZipName, rawValue, value) => handleChange(creditCardZipName, value)}
              name="creditCardZip"
              label="Zip"
              validationMessage={validationMessages.creditCardZip}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );

  let paymentSection;
  let paymentNamesSection;
  if (selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD || selectedPaymentMethod === AppConstants.PAYMENT_METHODS.BANK) {
    paymentNamesSection = (
      <div className="form__group">
        <div className="row">
          <div className="column-6@small">
            <Input
              onChange={handleChange}
              validationMessage={validationMessages.firstName}
              name="firstName"
              label="First Name"
              required
            />
          </div>
          <div className="column-6@small">
            <Input
              onChange={handleChange}
              validationMessage={validationMessages.lastName}
              name="lastName"
              label="Last Name"
              required
            />
          </div>
        </div>
      </div>
    );
  }

  if (selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD) {
    paymentSection = renderCredit();
  } else if (selectedPaymentMethod === AppConstants.PAYMENT_METHODS.BANK) {
    paymentSection = renderBank();
  }

  return (
    <div
      className="app-page__container"
      ref={pageContainerRef}
    >
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title" data-cypress="accountSetupTitle">Account Setup</div>
        </div>
        <div className="box">
          <div className="box__title-wrapper">
            <div className="box__title">Organization Information</div>
          </div>
          {!billingChecked && (
            <RadioGroup
              inline
              onChange={(value) => handleChange('selectedBillingOpt', value)}
              selectedValue={selectedBillingOpt}
              name="selectedBillingOpt"
            >
              <Radio value="existCust" label="Existing Billing Customer" />
              <Radio value="newCust" label="New Billing Customer" />
            </RadioGroup>
          )}
          {selectedBillingOpt === 'existCust'
            && !billingChecked
            && (
              <Input
                onChange={handleChange}
                validationMessage={validationMessages.billManagerCustId}
                name="billManagerCustId"
                label="Billing Customer ID"
                required
              />
            )}
          <div className="form__group">
            <div className="row">
              <div className="column-6@small">
                <Input
                  onChange={handleChange}
                  validationMessage={validationMessages.name}
                  name="name"
                  label="Organization Name"
                  required
                />
              </div>
              <div className="column-6@small">
                <label // eslint-disable-line jsx-a11y/label-has-for
                  className="u-block"
                >
                  Parent Company
                </label>
                <UtilityInlineGrid>
                  <div>
                    <DropdownSearchSelect
                      handleUpdateSelectedId={handleUpdateFormSelectedId}
                      itemSearchLoading={parentCompanySearchLoading}
                      items={filteredParentCompanies}
                      itemsIds={filteredParentCompanyIds}
                      selectedItem={selectedParentCompany}
                      selectedItemId={selectedParentCompanyId}
                      fetchAllItems={fetchAllItems}
                      dropdownLabel={parentCompanyLabel}
                      filterName="Parent Name"
                      type="member"
                      className="multi-select__dropdown--fixed-height"
                    />
                  </div>
                  <Button
                    onClick={handleAddParentOrganization}
                    iconOnly
                    type="secondary"
                    title="Create Company"
                    className="button__account-setup"
                  >
                    <Icon icon="add" />
                  </Button>
                </UtilityInlineGrid>
              </div>
            </div>
          </div>
          <Input
            onChange={handleChange}
            validationMessage={validationMessages.street1}
            name="street1"
            label="Address Line 1"
            required
          />
          <Input
            onChange={handleChange}
            name="street2"
            label="Address Line 2"
          />
          <div className="form__group">
            <div className="row">
              <div className="column-5@small">
                <Input
                  onChange={handleChange}
                  validationMessage={validationMessages.city}
                  name="city"
                  label="City"
                  required
                />
              </div>
              <div className="column-4@small">
                <Select
                  onSelect={handleChange}
                  selected={state}
                  validationMessage={validationMessages.state}
                  name="state"
                  label="State"
                  options={states}
                  required
                  dataCypress="orgState"
                />
              </div>
              <div className="column-3@small">
                <Input
                  format={MaskingHelpers.zipCode}
                  onChange={(zipName, rawValue, value) => handleChange(zipName, value)}
                  validationMessage={validationMessages.zip}
                  name="zip"
                  label="Zip"
                  required
                />
              </div>
            </div>
          </div>
          <div className="form__group">
            <div className="row">
              <div className="column-6@small">
                <Input
                  onChange={(businessPhoneName, rawValue, value) => handleChange(businessPhoneName, value)}
                  name="businessPhone"
                  validationMessage={validationMessages.businessPhone}
                  label="Organization Phone"
                  type="tel"
                  format={MaskingHelpers.phone}
                  placholder={AppConstants.PHONE_PLACEHOLDER}
                />
              </div>
              <div className="column-6@small">
                <Input
                  onChange={handleChange}
                  name="businessEmail"
                  validationMessage={validationMessages.businessEmail}
                  label="Organization Email"
                  type="email"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="box">
          <div className="box__title-wrapper">
            <div className="box__title">Organization Contact</div>
          </div>
          <Input onChange={handleChange} name="orgContactName" label="Name" />
          <div className="form__group">
            <div className="row">
              <div className="column-6@small">
                <Input
                  onChange={(orgContactPhoneName, rawValue, value) => handleChange(orgContactPhoneName, value)}
                  name="orgContactPhone"
                  validationMessage={validationMessages.orgContactPhone}
                  label="Phone Number"
                  type="tel"
                  format={MaskingHelpers.phone}
                  placeholder={AppConstants.PHONE_PLACEHOLDER}
                />
              </div>
              <div className="column-6@small">
                <Input onChange={handleChange} name="orgContactEmail" validationMessage={validationMessages.orgContactEmail} label="Email Address" type="email" />
              </div>
            </div>
          </div>
        </div>
        {selectedBillingOpt === 'newCust' && (
        <div className="box">
          <div className="box__title-wrapper">
            <div className="box__title">Billing</div>
          </div>
          <div className="block-preference">
            <div className="block-preference__left">
              <h4 className="block-preference__name">Create organization without billing</h4>
            </div>
            <div className="block-preference__right">
              <RhinoSwitch onClick={() => handleToggle('billingChecked')} name="billingChecked" />
            </div>
          </div>
        </div>
        )}
        {!billingChecked
          && selectedBillingOpt === 'newCust'
          && (
          <>
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">Sales</div>
              </div>
              <div className="form__group">
                <div className="row">
                  <div className="column-6@small">
                    <Select
                      onSelect={handleChange}
                      selected={salesRep}
                      options={salesReps}
                      label="Sales Rep"
                      name="salesRep"
                      validationMessage={validationMessages.salesRep}
                    />
                  </div>
                  <div className="column-6@small">
                    <Input
                      addon="left"
                      onChange={handleChange}
                      validationMessage={validationMessages.installAmount}
                      name="installAmount"
                      label="Install Fee"
                      explanationMessage="Enter in a dollar amount"
                    >
                      <span>$</span>
                    </Input>
                  </div>
                </div>
              </div>
              <div className="form__group">
                <div className="row">
                  <div className="column-6@small" data-cypress="plan">
                    <label // eslint-disable-line jsx-a11y/label-has-for
                      className="u-block"
                    >
                      Plans
                    </label>
                    <Dropdown
                      activeKey={activeProduct}
                      label="--"
                      block
                      type="input"
                      name="billingProductId"
                      onSelect={(value) => handleChange('billingProductId', value)}
                      wide
                    >
                      {products.map((p) => <DropdownMenuItem key={p.id} id={p.id} label={p.name} labelDesc={p.description} />)}
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">Payment</div>
              </div>
              {paymentNamesSection}
              {paymentSection}
              <div className="form__group">
                <div className="row">
                  <div className="column-4@small">
                    <Date
                      label="Subscription Activation Date"
                      dateFormat="MM/DD/YYYY"
                      name="startBillingDate"
                      selected={startBillingDate}
                      onChange={(date) => handleChange('startBillingDate', date)}
                      minDate={getMinDate()}
                    />
                  </div>
                </div>
              </div>

              <div className="box__title-wrapper">
                <div className="box__title">Billing Contact</div>
              </div>
              <div className="form__group">
                <div className="row">
                  <div className="column-6@small">
                    <Input
                      onChange={handleChange}
                      name="billingContactFirstName"
                      validationMessage={validationMessages.billingContactFirstName}
                      label="First Name"
                      required
                    />
                  </div>
                  <div className="column-6@small">
                    <Input
                      onChange={handleChange}
                      name="billingContactLastName"
                      validationMessage={validationMessages.billingContactLastName}
                      label="Last Name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form__group">
                <div className="row">
                  <div className="column-6@small">
                    <Input
                      onChange={handleChange}
                      name="billingContactPhone"
                      validationMessage={validationMessages.billingContactPhone}
                      label="Phone Number"
                      type="tel"
                      placeholder={AppConstants.PHONE_PLACEHOLDER}
                    />
                  </div>
                  <div className="column-6@small">
                    <Input
                      onChange={handleChange}
                      name="billingContactEmail"
                      validationMessage={validationMessages.billingContactEmail}
                      label="Email Address"
                      type="email"
                      explanationMessage="This is where the monthly invoices will be delivered"
                      required
                    />
                  </div>
                </div>
              </div>
              <Input
                onChange={handleChange}
                validationMessage={validationMessages.billingStreet1}
                name="billingStreet1"
                label="Billing Address Line 1"
                required
              />
              <Input
                onChange={handleChange}
                name="billingStreet2"
                label="Billing Address Line 2"
              />
              <div className="form__group">
                <div className="row">
                  <div className="column-5@small">
                    <Input
                      onChange={handleChange}
                      validationMessage={validationMessages.billingCity}
                      name="billingCity"
                      label="City"
                      required
                    />
                  </div>
                  <div className="column-4@small">
                    <Select
                      onSelect={handleChange}
                      selected={billingState}
                      validationMessage={validationMessages.billingState}
                      name="billingState"
                      label="State"
                      options={states}
                      required
                    />
                  </div>
                  <div className="column-3@small">
                    <Input
                      format={MaskingHelpers.zipCode}
                      onChange={(inputName, rawValue, value) => handleChange(inputName, value)}
                      validationMessage={validationMessages.billingZip}
                      name="billingZip"
                      label="Zip"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="box__title-wrapper">
                <div className="box__title">Coupon</div>
              </div>
              <div className="row">
                <div className="column-6@small">
                  <Dropdown
                    position="top"
                    activeKey={activeCoupon}
                    label="--"
                    block
                    type="input"
                    name="couponCode"
                    onSelect={(value) => handleChange('couponCode', value)}
                    wide
                  >
                    {coupons.map(renderCoupons)}
                  </Dropdown>
                  {couponSelected &&
                  <Button reset className="u-m-t-small u-text-primary u-text-small" onClick={handleRemoveCoupon}>Remove coupon</Button>}
                </div>
              </div>
            </div>
          </>
          )}

        <div className="u-m-t-large u-text-right" data-cypress="createOrganization">
          <Button loading={formInProgress} onClick={handleSubmit} disabled={disabled} type="secondary">Create Organization</Button>
        </div>
      </div>
      <div>
        <Modal open={isAddParentOrgModalOpen}>
          <ModalHeader onClose={handleCloseModal} title="CREATE NEW PARENT COMPANY" />
          <ModalBody>
            <Input label="Company Name" name="companyName" validationMessage={parentCompanyformErrors.companyName} initialValue={companyName} required onChange={handleChange} />
          </ModalBody>
          <ModalFooter>
            <UtilityInlineGrid align="between">
              <Button type="default" onClick={handleCloseModal}>Cancel</Button>
              <Button type="primary" loading={parentCompanyFormInProgress} onClick={saveParentCompany}>Submit</Button>
            </UtilityInlineGrid>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

AccountSetup.propTypes = {
  salesReps: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  startBillingDate: PropTypes.object,
  errors: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  activeCoupon: PropTypes.number,
  handleRemoveCoupon: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pageContainerRef: PropTypes.func.isRequired,
  selectedBillingOpt: PropTypes.string.isRequired,
  selectedPaymentMethod: PropTypes.string.isRequired,
  billingChecked: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  activeProduct: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  coupons: PropTypes.array.isRequired,
  couponSelected: PropTypes.bool.isRequired,
  bankAccountTypes: PropTypes.array.isRequired,
  activeBankAccntTypeId: PropTypes.number,
  state: PropTypes.string.isRequired,
  salesRep: PropTypes.number.isRequired,
  billingState: PropTypes.string.isRequired,
  filteredParentCompanies: PropTypes.object,
  filteredParentCompanyIds: PropTypes.array,
  selectedParentCompany: PropTypes.object,
  selectedParentCompanyId: PropTypes.number,
  fetchAllItems: PropTypes.func,
  handleUpdateFormSelectedId: PropTypes.func,
  parentCompanySearchLoading: PropTypes.bool,
  parentCompanyLabel: PropTypes.string,
  handleAddParentOrganization: PropTypes.func,
  isAddParentOrgModalOpen: PropTypes.bool,
  companyName: PropTypes.string,
  handleCloseModal: PropTypes.func,
  saveParentCompany: PropTypes.func,
  parentCompanyFormInProgress: PropTypes.bool,
  parentCompanyformErrors: PropTypes.object,
};

export default AccountSetup;
