const tealBlue = '#4abfe0';
const primaryBlue = '#0075c9';
const orangeBorder = '#9d5302';
const orange = '#f2811a';
const lightBlue = '#d2ebf8';
const lightBlueHover = '#89969c';
const primaryBlueHover = '#205b85';
const orangeHover = '#99612d';
const tealBlueHover = '#458090';
const orangeBorderHover = '#6f4a21';
const tealGreen = '#09c8b3';
const primaryGreen = 'rgba(168, 205, 96, 1)';

export const mixLineChartConfig = {
  data: {
    labels: [],
    datasets: [
      {
        label: 'Average',
        data: [],
        borderWidth: 0.8,
        fill: false,
        backgroundColor: '#6c6c6c',
        borderColor: '#6c6c6c',
        pointBorderColor: 'rgba(255, 255, 255, 1)',
        pointBackgroundColor: '#6c6c6c',
        pointRadius: 0,
        pointHoverRadius: 5,
        pointHitRadius: 30,
        pointBorderWidth: 1,
      },
      {
        label: '',
        data: [],
        borderWidth: 1,
        borderColor: primaryGreen,
        backgroundColor: 'rgba(168, 205, 96, 0.5)',
        pointBorderColor: primaryGreen,
        pointBackgroundColor: 'rgb(168, 205, 96, 0.5)',
        pointRadius: 0,
        pointHoverRadius: 5,
        pointHitRadius: 30,
        pointBorderWidth: 1,
      },
    ],
  },
  legend: {
    display: false,
  },
  options: {
    hover: {
      mode: 'index',
    },
    tooltips: {
      mode: 'index',
      callbacks: {},
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            fontColor: '#9F9F9F',
            maxRotation: 30,
            minRotation: 30,
          },
          gridLines: {
            drawOnChartArea: false,
            offsetGridLines: true,
          },
        }],
      yAxes: [{
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
          fontColor: '#9F9F9F',
        },
      }],
    },
  },
  width: 300,
  height: 150,
};

export const doughnutChartConfig = {
  data: {
    labels: [],
    datasets: [{
      data: [],
      cutoutPercentage: 70,
      backgroundColor: ['#0075c9', '#4abfe0', '#f2811a', '#d2ebf8'],
      hoverBackgroundColor: ['#0075c9', '#4abfe0', '#f2811a', '#d2ebf8'],
    }],
  },
  width: 150,
  height: 100,
  options: {
    cutoutPercentage: 80,
    legend: {
      display: false,
    },
  },
};

export const mixBarChartConfig = {
  data: {
    labels: [],
    datasets: [{
      label: '',
      type: 'line',
      data: [],
      fill: false,
      borderWidth: 0.8,
      borderColor: '#6c6c6c',
      backgroundColor: '#6c6c6c',
      pointBorderColor: 'rgb(255, 255, 255)',
      pointBackgroundColor: '#6c6c6c',
      pointRadius: 0,
      pointHoverRadius: 5,
      pointHitRadius: 30,
      pointBorderWidth: 1,
    }, {
      type: 'bar',
      label: '',
      data: [],
      borderWidth: 1,
      backgroundColor: 'rgba(210, 235, 248, 0.5)',
      borderColor: 'rgba(115, 171, 223, 1)',
      hoverBackgroundColor: 'rgba(115, 171, 223, 1)',
      hoverBorderColor: 'rgba(115, 171, 223, 1)',
    }],
  },
  legend: {
    display: false,
  },
  options: {
    responsive: true,
    tooltips: {
      mode: 'index',
      callbacks: {},
    },
    elements: {
      line: {
        fill: false,
      },
      point: {
        radius: 0,
      },
    },
    scales: {
      xAxes: [{
        ticks: {
          maxRotation: 30,
          minRotation: 30,
          autoSkip: false,
          fontColor: '#9F9F9F',
        },
        gridLines: {
          drawOnChartArea: false,
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Messages',
          fontColor: '#9F9F9F',
        },
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          autoSkip: true,
          beginAtZero: true,
          fontColor: '#9F9F9F',
        },
      }],
    },
  },
  width: 300,
  height: 300,
};

export const stackedBarChartConfig = {
  data: {
    labels: [],
    datasets: [
      {
        type: 'bar',
        label: '',
        data: [],
        borderWidth: 1,
        borderColor: orangeBorder,
        backgroundColor: orange,
        hoverBackgroundColor: orangeHover,
        hoverBorderColor: orangeBorderHover,
      }, {
        type: 'bar',
        label: '',
        data: [],
        borderWidth: 1,
        backgroundColor: lightBlue,
        borderColor: primaryBlue,
        hoverBackgroundColor: lightBlueHover,
        hoverBorderColor: primaryBlueHover,
      }, {
        type: 'bar',
        label: '',
        data: [],
        borderWidth: 1,
        backgroundColor: tealBlue,
        borderColor: primaryBlue,
        hoverBackgroundColor: tealBlueHover,
        hoverBorderColor: primaryBlueHover,
      },
    ],
  },
  legend: {
    display: true,
  },
  options: {
    responsive: true,
    tooltips: {
      mode: 'index',
      callbacks: {},
    },
    elements: {
      line: {
        fill: false,
      },
      point: {
        radius: 0,
      },
    },
    scales: {
      xAxes: [{
        ticks: {
          maxRotation: 30,
          minRotation: 30,
          autoSkip: false,
          fontColor: '#9F9F9F',
        },
        gridLines: {
          drawOnChartArea: false,
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Messages',
          fontColor: '#9F9F9F',
        },
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          autoSkip: true,
          beginAtZero: true,
          fontColor: '#9F9F9F',
        },
      }],
    },
    legend: {
      display: true,
      labels: {},
    },
  },
  width: 300,
  height: 300,
};

const barChartColorOptions = [primaryGreen, tealBlue, tealGreen];
export const barChartConfig = {
  data: {
    datasets: [
      {
        label: '',
        backgroundColor: barChartColorOptions,
        hoverBackgroundColor: barChartColorOptions,
        hoverBorderColor: barChartColorOptions,
        borderColor: barChartColorOptions,
        borderWidth: 1,
      },

    ],
  },
  options: {
    legend: {
      display: false, // This will hide the label at the top
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false, // Hides the grid lines for the x-axis
        },
      }],
      yAxes: [{
        display: false, // Hides the vertical axis line
        gridLines: {
          display: false, // Hides the grid lines for the y-axis
        },
      }],
    },
  },
};
