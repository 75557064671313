import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Alert, Button } from 'rhinostyle';

import LoginWrapper from './LoginWrapper';
import { PASSWORD_EXPLANATION_MESSAGE } from '../constants/AppConstants';

const ChangePassword = (props) => {
  const {
    password,
    confirmPassword,
    handleChange,
    handleKeyPress,
    handleSave,
    error,
  } = props;

  const passwordClasses = cx('app-login__floating-label__input form__control form__control--large', {
    'has-value': password,
  });

  const confirmPasswordClasses = cx('app-login__floating-label__input form__control form__control--large', {
    'has-value': confirmPassword,
  });

  return (
    <LoginWrapper>
      <p className="app-login__text u-m-b-large">
        Please type a new password below. Click &#8220;Save and Continue&#8221; to complete your password. {PASSWORD_EXPLANATION_MESSAGE}.
      </p>
      <div className="app-login__floating-label u-m-b-large">
        <input
          type="password"
          id="password"
          name="password"
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          className={passwordClasses}
        />

        <label // eslint-disable-line jsx-a11y/label-has-for
          htmlFor="password"
          className="app-login__floating-label__label"
        >
          new password
        </label>
      </div>

      <div className="app-login__floating-label u-m-b-large">
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          className={confirmPasswordClasses}
        />
        <label // eslint-disable-line jsx-a11y/label-has-for
          htmlFor="confirmPassword"
          className="app-login__floating-label__label"
        >
          confirm password
        </label>
      </div>
      <Button size="large" onClick={handleSave} type="outline-reversed" block>Save and Continue</Button>
      {error && error.data && <Alert solid className="u-m-t-large u-text-center" type="danger">{error.data.message}</Alert>}
    </LoginWrapper>
  );
};

ChangePassword.propTypes = {
  password: PropTypes.string.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  error: PropTypes.object,
  handleSave: PropTypes.func.isRequired,
};

export default ChangePassword;
