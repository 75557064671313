import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import {
  TYPE_APPOINTMENT_EVENT_REMINDER1,
  TYPE_APPOINTMENT_EVENT_REMINDER2,
  TYPE_APPOINTMENT_EVENT_REMINDER3,
  TYPE_APPOINTMENT_EVENT_REMINDER4,
} from '../constants/Types';

function useSortAppointmentReminders(appointmentCampaign, isLoading) {
  const areExtraRemindersEnabled = useSelector(getCurrentOrg)?.areExtraRemindersEnabled;

  const shapedReminders = {
    appointmentReminder1: {
      enabled: appointmentCampaign.appointmentReminder1Enabled,
      deliveryHours: parseInt(appointmentCampaign.appointmentReminder1DeliveryHours, 10),
      name: 'appointmentReminder1',
      typeId: TYPE_APPOINTMENT_EVENT_REMINDER1,
      template: appointmentCampaign.appointmentReminder1Template,
      sendAfterAppt: appointmentCampaign.appointmentReminder1SendAfterAppt,
      ehrStatus: appointmentCampaign.appointmentReminder1EhrStatus,
    },
    appointmentReminder2: {
      enabled: appointmentCampaign.appointmentReminder2Enabled,
      deliveryHours: parseInt(appointmentCampaign.appointmentReminder2DeliveryHours, 10),
      name: 'appointmentReminder2',
      typeId: TYPE_APPOINTMENT_EVENT_REMINDER2,
      template: appointmentCampaign.appointmentReminder2Template,
      sendAfterAppt: appointmentCampaign.appointmentReminder2SendAfterAppt,
      ehrStatus: appointmentCampaign.appointmentReminder2EhrStatus,
    },
    ...areExtraRemindersEnabled && {
      appointmentReminder3: {
        enabled: appointmentCampaign.appointmentReminder3Enabled,
        deliveryHours: parseInt(appointmentCampaign.appointmentReminder3DeliveryHours, 10),
        name: 'appointmentReminder3',
        typeId: TYPE_APPOINTMENT_EVENT_REMINDER3,
        template: appointmentCampaign.appointmentReminder3Template,
        sendAfterAppt: appointmentCampaign.appointmentReminder3SendAfterAppt,
        ehrStatus: appointmentCampaign.appointmentReminder3EhrStatus,
      },
      appointmentReminder4: {
        enabled: appointmentCampaign.appointmentReminder4Enabled,
        deliveryHours: parseInt(appointmentCampaign.appointmentReminder4DeliveryHours, 10),
        name: 'appointmentReminder4',
        typeId: TYPE_APPOINTMENT_EVENT_REMINDER4,
        template: appointmentCampaign.appointmentReminder4Template,
        sendAfterAppt: appointmentCampaign.appointmentReminder4SendAfterAppt,
        ehrStatus: appointmentCampaign.appointmentReminder4EhrStatus,
      },
    },
  };

  const [sortedReminders, setSortedReminders] = useState(Object.values(shapedReminders));

  useEffect(() => {
    // sort enabled reminders by delivery hours
    if (!isLoading) {
      const sorted = Object.values(shapedReminders).sort((a, b) => {
        if (a.enabled && !b.enabled) return -1;
        if (!a.enabled && b.enabled) return 1;

        if (a.enabled && b.enabled) {
          return b.deliveryHours - a.deliveryHours;
        }

        return 0;
      });
      setSortedReminders(sorted);
    }
  }, [isLoading, appointmentCampaign.id]);

  return { sortedReminders, shapedReminders };
}

export default useSortAppointmentReminders;
