import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { ChannelHelpers, DateHelpers, DataHelpers, PhoneHelpers, StringHelpers, UserHelpers, BrowserHelpers } from '../helpers';
import { getActiveUser, canActiveUserReceiveMessages, getLoggedInUser, getIsRhinopayConsentGranted } from './userSelectors';
import { getCurrentOrg } from './organizationSelectors';
import { Types, UserPermissionsConstants } from '../constants';

const getActiveSecureNotificationToChannelId = (state) => state.inbox.activeSecureNotificationToChannelId;
const getActiveToChannelId = (state) => state.inbox.activeToChannelId;
const getChannels = (state) => state.channel.channels;
const getEventIds = (state) => state.inbox.eventIds;
const getEventMentions = (state) => state.inbox.eventMentions;
const getEvents = (state) => state.inbox.events;
const getSecureEvents = (state) => state.secure.events;
const getFacebooks = (state) => state.facebook.facebooks;
const getRhinograms = (state) => state.rhinogram.rhinograms;
const getGroupId = (state) => state.inbox.groupId;
const getGroups = (state) => state.group.groups;
const getInboxAssigned = (state) => state.inbox.assigned;
const getInboxDirect = (state) => state.inbox.direct;
const getInboxEventIds = (state) => state.inbox.inboxEventIds;
const getInboxFollowing = (state) => state.inbox.following;
const getInboxGroupId = (state) => state.inbox.groupId;
const getInboxMentionIds = (state) => state.inbox.mentionIds;
const getInboxMentions = (state) => state.inbox.mentions;
const getInboxSections = (state) => state.ui.inboxSections;
const getPhones = (state) => state.phone.phones;
const getThreadFromChannelIds = (state) => state.inbox.threadFromChannelIds;
const getTypes = (state) => state.type.types;
const getUserId = (state) => state.inbox.userId;
const getThreadFilteredChannelIds = (state) => state.inbox.threadFilteredChannelIds;
const getSelectedThreadChannelIds = (state) => state.inbox.threadSelectedChannelIds;
const getIsRhinopayEnabled = (state) => state.pay.merchant;
const getIsSecureMode = (state) => state.thread.activeConvoTab === 'secure';
const getEventId = (state, props) => props.eventId;
const getIsSecureThread = (state, props) => props.isSecureThread;
const getUsers = (state) => state.user.users;
const getLanguages = (state) => state.language.languages;
const getInstagrams = (state) => state.instagram.instagrams;

export const getSelectedToChannelId = createSelector(
  getActiveToChannelId,
  (channelId) => channelId,
);

export const getFilteredMentionEventIds = createSelector(
  [getInboxEventIds, getEvents, getTypes],
  (inboxEventIds, events, types) => (
    DataHelpers.hasData(types) && DataHelpers.hasData(events) ? inboxEventIds.filter((id) => events[id] && types[events[id].typeId].value !== 'direct message') : []
  ),
);

export const getFilteredInboxEventIds = createSelector(
  [getInboxEventIds, getEvents, getTypes, getInboxMentions],
  (inboxEventIds, events, types, mentions) => (
    DataHelpers.hasData(types) && DataHelpers.hasData(events) ? inboxEventIds.filter((id) => events[id] && (
      mentions || types[events[id].typeId]?.value !== 'direct message' || events[id].modelType !== 'DirectMessage')) : []
  ),
);

export const getEventById = createSelector(
  [getIsSecureThread, getEvents, getSecureEvents, getEventId],
  (isSecureThread, events, secureEvents, eventId) => (isSecureThread ? secureEvents[eventId] : events[eventId]),
);

export const getEventSender = createSelector(
  [getEventById, getUsers],
  (event, users) => {
    if (event && users) return users[event?.sender];
    return null;
  },
);
export const getEventUser = createSelector(
  [getEventById, getUsers],
  (event, users) => {
    if (event && users) return users[event?.user];
    return null;
  },
);

export const getEventLanguage = createSelector(
  [getEventById, getLanguages],
  (event, languages) => languages?.[event?.languageId] || null,
);

export const getFilteredInboxThreadEventIds = createSelector(
  [getEventIds, getEvents, getTypes, getUserId],
  (eventIds, events, types, userId) => (
    DataHelpers.hasData(types) && DataHelpers.hasData(events) ?
      eventIds.filter((id) => events[id] && types[events[id].typeId].value !== 'direct message' && (userId && userId === events[id].user)) : []
  ),
);

export const getInboxEventsToday = createSelector(
  [getFilteredInboxEventIds, getEvents],
  (inboxEventIds, events) => inboxEventIds.filter(
    (id) => events[id] && moment().startOf('day').isSame(DateHelpers.convertUtcStringToLocalMoment(events[id].timestamp).startOf('day')),
  ),
);

export const getInboxEventsYesterday = createSelector(
  [getFilteredInboxEventIds, getEvents],
  (inboxEventIds, events) => (
    inboxEventIds.filter((id) => (
      events[id] && moment().subtract(1, 'day').startOf('day').isSame(DateHelpers.convertUtcStringToLocalMoment(events[id].timestamp).startOf('day'))
    ))
  ),
);

export const getInboxEventsOlder = createSelector(
  [getFilteredInboxEventIds, getEvents],
  (inboxEventIds, events) => (
    inboxEventIds.filter((id) => (
      events[id] && moment().subtract(1, 'day').startOf('day').isAfter(DateHelpers.convertUtcStringToLocalMoment(events[id].timestamp).startOf('day'))
    ))
  ),
);

export const getInboxEventMentionsToday = createSelector(
  [getInboxMentionIds, getEventMentions],
  (mentionIds, eventMentions) => mentionIds.filter(
    (id) => eventMentions[id] && moment().startOf('day').isSame(DateHelpers.convertUtcStringToLocalMoment(eventMentions[id].timestamp).startOf('day')),
  ),
);

export const getInboxEventMentionsYesterday = createSelector(
  [getInboxMentionIds, getEventMentions],
  (mentionIds, eventMentions) => (
    mentionIds.filter((id) => (
      eventMentions[id] && moment().subtract(1, 'day').startOf('day').isSame(DateHelpers.convertUtcStringToLocalMoment(eventMentions[id].timestamp).startOf('day'))
    ))
  ),
);

export const getInboxEventMentionsOlder = createSelector(
  [getInboxMentionIds, getEventMentions],
  (mentionIds, eventMentions) => (
    mentionIds.filter((id) => (
      eventMentions[id] && moment().subtract(1, 'day').startOf('day').isAfter(DateHelpers.convertUtcStringToLocalMoment(eventMentions[id].timestamp).startOf('day'))
    ))
  ),
);

export const getMostRecentEvent = createSelector(
  [getEvents, getEventIds, getUserId],
  (events, eventIds, userId) => {
    let mostRecentEvent;

    if (DataHelpers.hasData(events)) {
      const filteredEvents =
        eventIds.filter((id) => events[id] && events[id].user && events[id].user === userId);
      if (filteredEvents.length > 0) mostRecentEvent = events[filteredEvents[filteredEvents.length - 1]];
    }
    return mostRecentEvent;
  },
);

export const getMostRecentConversationLanguageId = createSelector(
  [getEvents, getEventIds, getTypes],
  (events, eventIds, types) => {
    const messageTypeEventIds =
    eventIds.filter((eventId) => (events[eventId] && types[events[eventId].typeId].value === 'message'));

    if (messageTypeEventIds.length === 0) return null;

    return events[messageTypeEventIds[messageTypeEventIds.length - 1]].languageId;
  },
);

export const getMostRecentMessageOrSecureOrNoteEvent = createSelector(
  [getEvents, getEventIds, getTypes, getUserId],
  (events, eventIds, types, userId) => {
    let mostRecentEvent;

    if (DataHelpers.hasData(events)) {
      const filteredEvents =
        eventIds.filter((id) => (
          events[id]
          && (types[events[id].typeId].value === 'message' || types[events[id].typeId].value === 'secure message' || types[events[id].typeId].value === 'note'))
          && events[id].user === userId);

      if (filteredEvents.length > 0) mostRecentEvent = events[filteredEvents[filteredEvents.length - 1]];
    }

    return mostRecentEvent;
  },
);

export const getMostRecentMessageEvent = createSelector(
  [getEvents, getEventIds, getTypes, getUserId],
  (events, eventIds, types, userId) => {
    let mostRecentEvent;

    if (DataHelpers.hasData(types) && DataHelpers.hasData(events)) {
      const filteredEvents =
        eventIds.filter((id) => events[id] && types[events[id].typeId].value === 'message' && events[id].user === userId);

      if (filteredEvents.length > 0) mostRecentEvent = events[filteredEvents[filteredEvents.length - 1]];
    }

    return mostRecentEvent;
  },
);

export const getMostRecentSecureEvent = createSelector(
  [getEvents, getEventIds, getTypes, getUserId],
  (events, eventIds, types, userId) => {
    let mostRecentEvent;

    if (DataHelpers.hasData(types) && DataHelpers.hasData(events)) {
      const filteredEvents =
        eventIds.filter((id) => events[id] && (types[events[id].typeId]?.value === 'secure message' || events[id].modelType === 'SecureMessage') && events[id].user === userId);

      if (filteredEvents.length > 0) mostRecentEvent = events[filteredEvents[filteredEvents.length - 1]];
    }

    return mostRecentEvent;
  },
);

export const getActiveGroup = createSelector(
  [getGroups, getGroupId],
  (groups, groupId) => {
    if (groups && groupId) {
      return groups[groupId];
    }

    return null;
  },
);

export const getInboxName = createSelector(
  [getInboxSections, getInboxDirect, getInboxFollowing, getInboxGroupId, getInboxMentions],
  (inboxSections, direct, following, groupId, mentions) => {
    let retVal = '';
    let group = null;

    if (groupId) {
      group = inboxSections?.inbox?.find((i) => i.userGroupId === groupId);
      retVal = group && group.displayName;
    } else if (!inboxSections?.inbox?.length) {
      retVal = 'Inbox';
    } else if (direct) {
      retVal = 'Direct';
    } else if (following) {
      retVal = 'Following';
    } else if (mentions) {
      retVal = 'Mentions';
    } else {
      retVal = 'Assigned';
    }

    return retVal;
  },
);

export const getInboxContext = createSelector(
  [getInboxGroupId, getInboxFollowing, getInboxDirect, getInboxAssigned, getInboxMentions],
  (groupId, inboxFollowing, direct, assigned, mentions) => {
    let inboxContext = '';

    if (inboxFollowing) {
      inboxContext = 'following';
    } else if (groupId) {
      inboxContext = 'group';
    } else if (direct) {
      inboxContext = 'direct';
    } else if (mentions) {
      inboxContext = 'mentions';
    } else if (assigned) {
      inboxContext = 'assigned';
    } else {
      inboxContext = 'all';
    }
    return inboxContext;
  },
);

export const getInboxOptionsInReducer = createSelector(
  [getInboxGroupId, getUserId],
  (inboxGroupId, inboxUserId) => {
    const groupId = Number(inboxGroupId) || null;
    const userId = Number(inboxUserId) || null;
    const pathName = window.location.pathname;
    const following = pathName.includes('following');
    const direct = pathName.includes('direct');
    const mentions = pathName.includes('mentions');
    const all = pathName.includes('all');
    const assigned = !groupId && !following && !direct && !all && !mentions;
    return { groupId, userId, following, direct, assigned, mentions, all };
  },
);

export const getInboxThreadOptions = createSelector(
  [getInboxGroupId, getInboxFollowing, getInboxDirect, getInboxAssigned, getInboxMentions, getUserId, getSelectedThreadChannelIds, getThreadFilteredChannelIds],
  (groupId, following, direct, assigned, mentions, userId, threadSelectedChannelIds, threadFilteredChannelIds) => {
    const channelIds = threadSelectedChannelIds?.length > 0 ? threadSelectedChannelIds : threadFilteredChannelIds;
    return { groupId, userId, following, direct, assigned, mentions, channelIds };
  },
);

export const getThreadFromChannelOptions = createSelector(
  [getChannels, getThreadFromChannelIds, getTypes, getActiveToChannelId, getFacebooks, getInstagrams],
  (channels, channelIds, types, activeToChannelId, facebooks, instagrams) => {
    const selectOpts = [{
      id: Types.TYPE_SMS,
      value: 'Phone Channels',
    }];
    let options = [];
    if (activeToChannelId) {
      const toChannelClass = ChannelHelpers.getChannelClass(activeToChannelId);
      const toChannelId = ChannelHelpers.getCommunicationId(activeToChannelId);
      const newPhoneNumberSMSChannels =
        toChannelClass === 'sms' && channelIds.filter((id) => channels[id].typeId === Types.TYPE_SMS && channels[id]?.details?.existingNumber === 0);
      const existingPhoneNumberSMSChannels =
        toChannelClass === 'sms' && channelIds.filter((id) => channels[id].typeId === Types.TYPE_SMS && channels[id]?.details?.existingNumber === 1);
      const twilioChannels = toChannelClass === 'sms' && channelIds.filter((id) => channels[id].typeId === Types.TYPE_TWILIO);
      const rhinogramChannels = toChannelClass === 'rhinogram' && channelIds.filter((id) => channels[id].typeId === Types.TYPE_RHINOGRAM);
      // need to filter to fb that matches the to fb channel since fb can only send with one to/from combo
      const facebookChannels = toChannelClass === 'facebook' && channelIds.filter((id) => (
        channels[id].typeId === Types.TYPE_FACEBOOK && facebooks[toChannelId].channelId === id
      ));

      const instagramChannels = toChannelClass === 'instagram' && channelIds.filter((id) => (
        channels[id].typeId === Types.TYPE_INSTAGRAM && instagrams[toChannelId].channelId === id
      ));
      if (twilioChannels.length > 0) {
        options = options.concat(twilioChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }

      if (existingPhoneNumberSMSChannels.length > 0) {
        options = options.concat(existingPhoneNumberSMSChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }

      // channel registered with new number
      if (newPhoneNumberSMSChannels.length > 0) {
        options = options.concat(newPhoneNumberSMSChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }

      if (facebookChannels.length > 0) {
        selectOpts[0].id = Types.TYPE_FACEBOOK;
        selectOpts[0].value = 'Facebook Channels';
        options = options.concat(facebookChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }

      if (instagramChannels.length > 0) {
        selectOpts[0].id = Types.TYPE_INSTAGRAM;
        selectOpts[0].value = 'Instagram Channels';
        options = options.concat(instagramChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }
      if (rhinogramChannels.length > 0) {
        selectOpts[0].id = Types.TYPE_RHINOGRAM;
        selectOpts[0].value = 'Rhinogram Channels';
        options = options.concat(rhinogramChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }
    }
    selectOpts[0].options = options;
    return selectOpts;
  },
);

export const getOtherThreadFromChannelOptions = createSelector(
  [getChannels, getThreadFromChannelIds, getTypes, getActiveToChannelId, getActiveSecureNotificationToChannelId, getIsSecureMode],
  (channels, channelIds, types, activeToChannelId, activeSecureNotificationToChannelId, isSecureMode) => {
    const selectOpts = [{
      id: Types.TYPE_SMS,
      value: 'Phone Channels',
    }];
    let options = [];
    const activeChannelId = isSecureMode ? activeSecureNotificationToChannelId : activeToChannelId;
    if (activeChannelId) {
      const toChannelClass = ChannelHelpers.getChannelClass(activeChannelId);
      const smsChannels = toChannelClass !== 'sms' && channelIds.filter((id) => channels[id].typeId === Types.TYPE_SMS);
      const twilioChannels = toChannelClass !== 'sms' && channelIds.filter((id) => channels[id].typeId === Types.TYPE_TWILIO);
      const facebookChannels = toChannelClass !== 'facebook' && channelIds.filter((id) => (
        channels[id].typeId === Types.TYPE_FACEBOOK
      ));
      const instagramChannels = toChannelClass !== 'instagram' && channelIds.filter((id) => (
        channels[id].typeId === Types.TYPE_INSTAGRAM
      ));
      const rhinogramChannels = toChannelClass === 'sms' && channelIds.filter((id) => (
        channels[id].typeId === Types.TYPE_RHINOGRAM
      ));

      if (smsChannels.length > 0) {
        options = options.concat(smsChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }

      if (twilioChannels.length > 0) {
        options = options.concat(twilioChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }

      if (facebookChannels.length > 0) {
        selectOpts[0].id = Types.TYPE_FACEBOOK;
        selectOpts[0].value = 'Facebook Channels';
        options = options.concat(facebookChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }

      if (instagramChannels.length > 0) {
        selectOpts[0].id = Types.TYPE_INSTAGRAM;
        selectOpts[0].value = 'Instagram Channels';
        options = options.concat(instagramChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }

      if (rhinogramChannels.length > 0) {
        selectOpts[0].id = Types.TYPE_RHINOGRAM;
        selectOpts[0].value = 'Rhinogram Channels';
        options = options.concat(rhinogramChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }
    }
    selectOpts[0].options = options;
    return selectOpts;
  },
);

export const getThreadToChannelOptions = createSelector(
  [getActiveUser, getPhones, getFacebooks, getRhinograms, getTypes, getChannels, getInstagrams],
  (user, phones, facebooks, rhinograms, types, channels, instagrams) => {
    let selectOpts = [];
    if (!user || (!user.phones && !user.facebooks && !user.rhinograms && !user.instagrams)) return selectOpts;

    if (user.phones && user.phones.length > 0) {
      selectOpts = selectOpts.concat({
        id: Types.TYPE_SMS,
        value: StringHelpers.capitalize(types[Types.TYPE_SMS].value.toUpperCase()),
        options:
          user.phones.filter((id) => phones[id]?.ownerId === user.id && PhoneHelpers.isPhoneNumberValid(phones[id].value))
            .map((id) => ({
              id: `sms-${id}`,
              name: PhoneHelpers.formatPhone(phones[id].value),
              route: PhoneHelpers.formatTypes(phones[id]),
              value: `${PhoneHelpers.formatPhone(phones[id].value)} (${PhoneHelpers.formatTypes(phones[id])})`,
              isPhone: true,
              isTextable: phones[id].isTextable,
            })),
      });
    }

    // If user has multiple facebooks, we want to include the channel name the facebook is tied to
    if (user.facebooks && user.facebooks.length > 0) {
      let facebookOptions = [];
      if (user.facebooks.length === 1) {
        facebookOptions = user.facebooks.map((id) => (
          {
            id: `facebook-${id}`,
            name: UserHelpers.formatFacebookName(user, facebooks[id].value),
            value: UserHelpers.formatFacebookName(user, facebooks[id].value),
            isPhone: false,
          }
        ));
      } else {
        facebookOptions = user.facebooks
          .filter((id) => channels[facebooks[id]?.channelId])
          .map((id) => (
            {
              id: `facebook-${id}`,
              name: UserHelpers.formatFacebookName(user, facebooks[id].value, channels[facebooks[id].channelId].name),
              value: UserHelpers.formatFacebookName(user, facebooks[id].value, channels[facebooks[id].channelId].name),
              isPhone: false,
            }
          ));
      }
      selectOpts = selectOpts.concat({
        id: Types.TYPE_FACEBOOK,
        value: StringHelpers.capitalize(types[Types.TYPE_FACEBOOK].value),
        options: facebookOptions,
      });
    }

    // If user has multiple facebooks, we want to include the channel name the facebook is tied to
    if (user.instagrams && user.instagrams.length > 0) {
      let instagramOptions = [];
      if (user.instagrams.length === 1) {
        instagramOptions = user.instagrams.map((id) => (
          {
            id: `instagram-${id}`,
            name: UserHelpers.formatInstagramName(user, instagrams[id]),
            value: UserHelpers.formatInstagramName(user, instagrams[id]),
            isPhone: false,
          }
        ));
      } else {
        instagramOptions = user.instagrams
          .filter((id) => channels[instagrams[id]?.channelId])
          .map((id) => (
            {
              id: `instagram-${id}`,
              name: UserHelpers.formatInstagramName(user, instagrams[id], channels[instagrams[id].channelId].name),
              value: UserHelpers.formatInstagramName(user, instagrams[id], channels[instagrams[id].channelId].name),
              isPhone: false,
            }
          ));
      }
      selectOpts = selectOpts.concat({
        id: Types.TYPE_INSTAGRAM,
        value: StringHelpers.capitalize(types[Types.TYPE_INSTAGRAM].value),
        options: instagramOptions,
      });
    }

    // If user has multiple rhinograms, we want to include the channel name the rhinogram is tied to
    if (user.rhinograms && user.rhinograms.length > 0) {
      let rhinogramOptions = [];
      if (user.rhinograms.length === 1) {
        rhinogramOptions = user.rhinograms.map((id) => (
          {
            id: `rhinogram-${id}`,
            name: UserHelpers.formatRhinogramName(user, rhinograms[id]),
            value: UserHelpers.formatRhinogramName(user, rhinograms[id]),
            isPhone: false,
          }
        ));
      } else {
        rhinogramOptions = user.rhinograms
          .filter((id) => channels[rhinograms[id]?.channelId])
          .map((id) => (
            {
              id: `rhinogram-${id}`,
              name: UserHelpers.formatRhinogramName(user, rhinograms[id], channels[rhinograms[id].channelId].name),
              value: UserHelpers.formatRhinogramName(user, rhinograms[id], channels[rhinograms[id].channelId].name),
              isPhone: false,
            }
          ));
      }

      selectOpts = selectOpts.concat({
        id: Types.TYPE_RHINOGRAM,
        value: StringHelpers.capitalize(types[Types.TYPE_RHINOGRAM].value),
        options: rhinogramOptions,
      });
    }

    return selectOpts;
  },
);

// Secure
export const getSecureThreadFromChannelOptions = createSelector(
  [getChannels, getThreadFromChannelIds, getTypes],
  (channels, channelIds) => {
    let selectOpts = [];

    const secureChannels = channelIds.filter((id) => channels[id].typeId === Types.TYPE_SECURE);

    if (secureChannels.length > 0) {
      selectOpts = secureChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` }));
    }

    return selectOpts;
  },
);

// same as non-secure for now.
export const getSecureThreadNotificationToChannelOptions = createSelector(
  [getActiveUser, getPhones],
  (user, phones) => {
    let selectOpts = [];

    if (!user || (!user.phones && !user.facebooks && !user.rhinograms)) return selectOpts;
    const userOwnedPhones = user.phones?.filter((id) => phones[id]?.ownerId === user.id);

    if (userOwnedPhones?.length > 0) {
      selectOpts = selectOpts.concat({
        id: Types.TYPE_SMS,
        value: 'Phone Channels',
        options:
          userOwnedPhones
            .map((id) => ({
              id: `sms-${id}`,
              value: `${PhoneHelpers.formatPhone(phones[id].value)} (${PhoneHelpers.formatTypes(phones[id])})`,
            })),
      });
    }
    return selectOpts;
  },
);

export const getSecureThreadNotificationFromChannelOptions = createSelector(
  [getChannels, getThreadFromChannelIds, getCurrentOrg, getTypes, getActiveSecureNotificationToChannelId],
  (channels, channelIds, organization, types, activeSecureNotificationToChannelId) => {
    const selectOpts = [{
      id: Types.TYPE_SMS,
      value: 'Phone Channels',
    }];
    let options = [];

    if (activeSecureNotificationToChannelId) {
      const toChannelClass = activeSecureNotificationToChannelId ? ChannelHelpers.getChannelClass(activeSecureNotificationToChannelId) : null;

      const smsChannels = toChannelClass === 'sms' && channelIds.filter((id) => channels[id].typeId === Types.TYPE_SMS);
      const twilioChannels = toChannelClass === 'sms' && channelIds.filter((id) => channels[id].typeId === Types.TYPE_TWILIO);

      if (smsChannels.length > 0) {
        options = options.concat(smsChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }

      if (twilioChannels.length > 0) {
        options = options.concat(twilioChannels.map((id) => ({ id, value: `${channels[id].name} (Route: ${channels[id].route.name})` })));
      }

      // Use org default channel, if no others are available.
      if (!selectOpts.length > 0 && channelIds.length > 0 && organization.defaultChannelId) {
        const defaultChannel = channels[organization.defaultChannelId];
        if (defaultChannel) options = options.concat({ id: defaultChannel.id, value: defaultChannel.name });
      }
    }
    selectOpts[0].options = options;
    return selectOpts;
  },
);

export const getDoesLoggedInUserHaveFromChannel = createSelector(
  [getThreadFromChannelOptions, getSecureThreadNotificationFromChannelOptions, getIsSecureMode],
  (threadFromChannelOptions, secureThreadNotificationFromChannelOptions, isSecure) => (isSecure ?
    secureThreadNotificationFromChannelOptions?.[0]?.options?.length > 0 : threadFromChannelOptions?.[0]?.options?.length > 0),
);

export const getEventEventMentions = createSelector(
  [canActiveUserReceiveMessages, getDoesLoggedInUserHaveFromChannel],
  (canUserReceiveMessages, doesLoggedInUserHaveFromChannel) => canUserReceiveMessages && doesLoggedInUserHaveFromChannel,
);

export const getIsMessageSendBoxEnabled = createSelector(
  [canActiveUserReceiveMessages, getDoesLoggedInUserHaveFromChannel],
  (canUserReceiveMessages, doesLoggedInUserHaveFromChannel) => canUserReceiveMessages && doesLoggedInUserHaveFromChannel,
);

export const getCanSendRhinopayRequests = createSelector(
  [getIsRhinopayEnabled, getIsRhinopayConsentGranted, getLoggedInUser, getIsSecureMode, getSecureThreadFromChannelOptions, getIsMessageSendBoxEnabled],
  (isRhinopayEnabled, isRhinopayConsentGranted, loggedInUser, secureMode, secureThreadFromChannelOptions, isMessageSendBoxEnabled) => {
    const canSendSecure = secureMode ? secureThreadFromChannelOptions?.length > 0 : true;
    return isRhinopayEnabled
  && !loggedInUser.isCcr
  && canActiveUserReceiveMessages
  && isMessageSendBoxEnabled
  && canSendSecure
  && (BrowserHelpers.isMobile() ?
    UserHelpers.userHasAllOfPermissions([UserPermissionsConstants.CONTACT_EDIT, UserPermissionsConstants.CONVERSATION_CONTACT_MOBILE]) :
    UserHelpers.userHasAnyOfPermissions([UserPermissionsConstants.CONTACT_EDIT]))
  && isRhinopayConsentGranted;
  },
);
