import axios from 'axios';
import { AppConstants } from '../constants';

const RhinopayAxios = axios.create({
  baseURL: process.env.REACT_APP_PAY_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Pragma: 'no-cache',
    Expires: -1,
    'Cache-Control': 'no-cache',
  },
  withCredentials: true,
});

export function getUserBalanceAndPaymentRequests(userId) {
  return RhinopayAxios.get(`/user/${userId}/balance`);
}

export function postCreditCardPayment(payload) {
  return RhinopayAxios.post('/payment/credit-card', payload);
}

export function postCheckPayment(payload) {
  return RhinopayAxios.post('/payment/check', payload);
}

export function getPaymentRequestByCode(code) {
  return RhinopayAxios.get(`/payment-request-front/${code}`);
}

export function postPaymentRequest(payload) {
  return RhinopayAxios.post('/payment-request', payload);
}

export function postResendPaymentRequest(payload) {
  return RhinopayAxios.post('/payment-request-sent', payload);
}

export function getMerchant(orgId) {
  return RhinopayAxios.get(`/merchant?orgId=${orgId}`);
}

// These 3 endpoints are only open to CCRs and will return additional info relating to the merchant.
export function getMerchantCcr(orgId) {
  return RhinopayAxios.get(`/ccr/merchant/${orgId}`);
}

export function patchMerchant(payload) {
  return RhinopayAxios.patch('/ccr/merchant', payload);
}

export function postMerchant(payload) {
  return RhinopayAxios.post('/ccr/merchant', payload);
}

export function deletePaymentRequest(paymentRequestId) {
  return RhinopayAxios.delete(`/payment-request/${paymentRequestId}`);
}

export function getRhinopayRequests(queryParams) {
  return RhinopayAxios.get(`/payment-requests?${queryParams}`);
}

export function breezeAuthentication(merchantUUID) {
  return RhinopayAxios.get(`/payment/authentication/${merchantUUID}`);
}

export function breezeProxynizationAPI(data) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', process.env.REACT_APP_BREEZE_PROXYNIZATION_API, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // receiving string as a response, converting it in JSON
        let temp = xhr.response
          .replace('()(', '{\'')
          .replace(');', '}')
          .replace(/=/g, "':")
          .replace(/, /g, ",'")
          .replace(/'/g, '"');
        temp = JSON.parse(temp);
        if (temp.responseCode === AppConstants.BREEZE_RESPONSE_CODE) {
          resolve(temp);
        } else {
          reject(xhr.response);
        }
      } else {
        reject(xhr.response);
      }
    };
    xhr.onerror = () => {
      reject(xhr.response);
    };
    xhr.send(data);
  });
}
