import { UtilitySystem } from 'rhinostyle';
import { TweenMax } from 'gsap/TweenMax';
import UAParser from 'ua-parser-js';
import 'gsap/ScrollToPlugin';
import ReactHtmlParser from 'react-html-parser';
import { replaceNewLinesToLineBreaks } from './TemplateHelpers';

export function handleServerError(error) {
  return {
    [error.property]: ReactHtmlParser(replaceNewLinesToLineBreaks(error.message)),
  };
}

export function handleServerErrorArray(error) {
  return {
    [error.property]: ReactHtmlParser(replaceNewLinesToLineBreaks(error.message.join('<br/><br/>'))),
  };
}

export function verifyPassword(newPass, newPassAgain) {
  const returnVal = {};

  if (newPass !== newPassAgain) {
    returnVal.newPassAgain = {};
    returnVal.newPassAgain.message = 'Passwords do not match';
  }

  if (newPassAgain === undefined) {
    returnVal.newPassAgain = {};
    returnVal.newPassAgain.message = 'Please verify password';
  }

  return returnVal;
}

export function handleValidationErrors(errors, pageContainer) {
  // Grab the first error
  const firstError = Object.keys(errors)[0];
  if (typeof errors[firstError] === 'object') {
    return handleValidationErrors(errors[firstError], pageContainer);
  }

  // Scroll to and focus on first error
  scrollToFormElement(firstError, pageContainer);

  // consistent return
  return false;
}

// function alternative to .closest to work on IE browser
function getClosestByClassName(element, className) {
  while (element && element !== document) { // eslint-disable-line no-param-reassign
    if (element.className.includes(className)) {
      return element;
    }
    const parentElement = element.parentNode;
    return parentElement;
  }
  return null;
}

/**
 * Scrolls and focuses on form element
 * @param  {string} name      Element [name]
 * @param  {string} container Element to base scrolling from
 * @return {void}
 */
export function scrollToFormElement(name, container) {
  const $element = container.querySelector(`[name="${name}"]`);

  // Check added to make it browser specific for IE browser
  const uaParser = new UAParser();
  const browser = uaParser.getBrowser();
  const $formGroup = browser.name === 'IE' ? getClosestByClassName($element, 'form__group') : $element && $element.closest('.form__group');

  if ($formGroup) {
    TweenMax
      .to(container, 0.5, {
        scrollTo: {
          y: $formGroup.offsetTop,
          autoKill: true,
        },
        ease: UtilitySystem.config.easing,
        onComplete() {
          $element.focus();
        },
      });
  }
}

export function validateExpirationDate(year, month) {
  if (year && month) {
    const currentYear = new Date().getFullYear();
    const thisMonth = (new Date().getMonth() + 1);
    const crntYear = parseInt(currentYear.toString().substr(2, 2), 10);
    if ((year <= crntYear) && (month < thisMonth)) {
      return false;
    }
    return true;
  }
  return false;
}
