import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import { setError } from './uiReducer';
import { type } from '../actions/NormalizrSchema';

// SLICE
const typeSlice = createSlice({
  name: 'TYPE',
  initialState: {
    types: {},
    typeIds: [],
  },
  reducers: {
    receiveTypes: (state, action) => ({
      ...state,
      types: {
        ...state.types,
        ...action.payload.types,
      },
      typeIds: [...new Set([...state.typeIds, ...action.payload.typeIds])],
    }),
  },
});

export default typeSlice.reducer;

// ACTIONS
export const { receiveTypes } = typeSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

export function fetchTypes() {
  return (dispatch) =>
    axios.get('/types')
      .then((response) => {
        const normalized = normalize(response.data, [type]);
        dispatch(receiveTypes({ types: normalized.entities.types, typeIds: normalized.result }));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
}
