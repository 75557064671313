import React, { Fragment } from 'react';
import {
  Icon,
  Resource,
  ResourceIntro,
  ResourceBody,
  ResourceRight,
} from 'rhinostyle';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment-timezone';
import Mark from 'mark.js';

import Tags from './Tags';
import { UserHelpers, PhoneHelpers } from '../helpers';
import { AppConstants, Types } from '../constants';

const UserSearchResult = (props) => {
  const { userId, activeFilterParam, handleClick, scope, searchText, disabled, isSelected, interfaceMode, isRightResourceDetailsVisible } = props;
  const users = useSelector((state) => state.user.users);
  const phones = useSelector((state) => state.phone.phones);
  const emails = useSelector((state) => state.email.emails);
  const user = users[userId];

  const renderConsentIcons = () => {
    const userHasAnyConsentGranted = (
      user.typeId !== Types.TYPE_MEMBER && (
        user.rhinopayConsentStatus?.typeId === Types.TYPE_RHINOPAY_CONSENT_GRANTED ||
      user.marketingConsentStatus?.typeId === Types.TYPE_MARKETING_CONSENT_GRANTED ||
      user.hipaaStatus?.typeId === Types.TYPE_HIPAA_CONSENT_GRANTED
      ));
    if (userHasAnyConsentGranted) {
      return (
        <div className="u-text-secondary u-text-large u-flex-align-items-start">
          {user.hipaaStatus?.typeId === Types.TYPE_HIPAA_CONSENT_GRANTED && <Icon bump="up" icon="hipaa" className="u-m-r-small" />}
          {user.rhinopayConsentStatus?.typeId === Types.TYPE_RHINOPAY_CONSENT_GRANTED && <Icon bump="up" icon="rhinopay" className="u-m-r-small" />}
          {user.marketingConsentStatus?.typeId === Types.TYPE_MARKETING_CONSENT_GRANTED && <Icon bump="up" icon="marketing" />}
        </div>
      );
    } return null;
  };

  const { onlineStatus } = user;
  const profileImageUrl = user.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${user.profileImageUrl}` : null;
  const avatarType = user.typeId === Types.TYPE_MEMBER ? 'member' : 'default';

  const highlightSearchTerm = (text) => {
    const el = document.createElement('span');
    el.innerHTML = text;

    const instance = new Mark(el);

    instance.mark(searchText, {
      element: 'strong',
      separateWordSearch: false,
      diacritics: false,
    });

    return ReactHtmlParser(el.innerHTML);
  };

  const birthday = user.birthday ? <div>{highlightSearchTerm(moment(user.birthday).format('MM/DD/YYYY'))}</div> : null;
  const getPhones = () => {
    if (activeFilterParam !== 'phone' || !user.phones.length) return false;

    return (
      <div>
        {user.phones.map((phone, index) => {
          const formattedPhone = PhoneHelpers.formatPhone(phones[user?.phones[index]]?.value);

          return (
            <Fragment key={phone}>
              {highlightSearchTerm(formattedPhone)}{user.phones.length !== index + 1 && ', '}
            </Fragment>
          );
        })}
      </div>
    );
  };
  const userSearchId = (activeFilterParam === 'id' && user.externalIds && UserHelpers.getExternalId(user)) ?
    <div>ID # {highlightSearchTerm(UserHelpers.getExternalId(user))}</div> : null;
  const getEmails = () => {
    if (activeFilterParam !== 'email') return false;
    let emailList;

    if (user.typeId === Types.TYPE_MEMBER) {
      emailList = [user.loginEmail];
    } else {
      emailList = user.emails.map((userEmail) => emails[userEmail].value);

      // Push loginEmail (if one is set)
      emailList = [...new Set([...emailList, user.loginEmail ? user.loginEmail : ''])];
    }

    if (!emailList.length) return false;

    return (
      <div>
        {emailList.map((email, index) => (
          <Fragment key={email}>
            {highlightSearchTerm(email)}{emailList.length !== index + 1 && ', '}
          </Fragment>
        ))}
      </div>
    );
  };

  const handleUserSelect = () => {
    handleClick(userId);
  };

  return (
    <Resource
      onClick={handleUserSelect}
      key={userId}
      disabled={disabled}
      selected={isSelected}
      interfaceMode={interfaceMode}
      dataCypress={`user-search-${user.id}`}
    >
      <ResourceIntro
        avatar={{
          image: profileImageUrl,
          name: UserHelpers.formatAvatarName(user.firstName, user.lastName),
          type: avatarType,
          showOnlineStatus: user.typeId === Types.TYPE_MEMBER,
          onlineStatus,
        }}
        title={highlightSearchTerm(user.typeId === Types.TYPE_MEMBER ? UserHelpers.formatMemberNameWithPrefixAndSuffix(user) : UserHelpers.formatName(user))}
        titleSub={renderConsentIcons(user)}
      >
        {birthday}
        {getPhones()}
        {userSearchId}
        {getEmails()}
      </ResourceIntro>
      {user.tags?.length > 0 && <ResourceBody className="u-text-muted"><Tags tagIds={user.tags} /></ResourceBody>}
      {isRightResourceDetailsVisible && scope !== 'members' && (
        <ResourceRight>
          {UserHelpers.formatTypes(user)}
        </ResourceRight>
      )}
    </Resource>
  );
};

UserSearchResult.propTypes = {
  handleClick: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  activeFilterParam: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  isRightResourceDetailsVisible: PropTypes.bool,
  interfaceMode: PropTypes.string,
};

UserSearchResult.defaultProps = {
  isRightResourceDetailsVisible: true,
};

export default UserSearchResult;
