import React from 'react';
import { Button, Icon, Alert } from 'rhinostyle';

import FacebookPageList from './FacebookPageList';
import { useFacebookLogin } from '../hooks';

const MetaChannelForm = (props) => {
  const { selectedPageId, mode, channelType = 'Facebook', handlePageSelect } = props;
  const icon = channelType.toLowerCase();
  const { handleLogin, isAuthenticated, availablePages, loading } = useFacebookLogin({ channelType: icon, selectedPageId, mode });
  const renderChannelLabel = () => (channelType === 'Facebook' ? (
    <label
      className="u-block u-m-b-small"
    >
      Connect your Facebook account
    </label>
  ) : (
    <>
      <label
        className="u-block u-m-b-small"
      >
        Connect your Instagram Business Page
      </label>
      <span className="u-text-muted u-text-small">No business page?
        <a href="https://rhinogram.freshdesk.com/support/home" target="_blank" rel="noopener noreferrer" className="u-p-xsmall">Learn More</a>
      </span>
    </>
  ));
  return (
    <>
      {!isAuthenticated && props.mode === 'create' && (
        <>
          {renderChannelLabel()}
          <ol className="u-list--numbers">
            <li>In your web browser settings: allow pop-ups for Rhinogram.</li>
            <li>On {channelType}: log in as admin of your business page.</li>
            <li>Then click the button below</li>
          </ol>
          <Button
            type="primary"
            size="small"
            onClick={handleLogin}
            loading={loading}
          >
            <Icon icon={icon} />&nbsp;{`Connect To ${channelType}`}
          </Button>
        </>
      )}
      {mode === 'create' && (
        <>
          {isAuthenticated
          && !loading
          && <FacebookPageList availablePages={availablePages} handlePageSelect={handlePageSelect} selectedPageId={selectedPageId} />}
          {props.errors.pageId && (
            <Alert type="danger" className="u-m-t">
              {props.errors.pageId}
            </Alert>
          )}
        </>
      )}
      {mode === 'edit' && (
        <Alert type="info">
          <h5>Re-authorize {channelType}</h5>
          <p className="u-text-small">To receive the latest update, click the button below to re-authorize your account with Rhinogram.</p>

          <Button
            size="small"
            loading={loading}
            type="primary"
            onClick={handleLogin}
          >
            Re-authorize {channelType}
          </Button>
          <p className="u-m-t-small u-text-small u-text-muted">You must be a{icon === 'instagram' ? 'n' : ''} {channelType} page admin to perform this action.</p>
        </Alert>
      )}
    </>
  );
};

export default MetaChannelForm;
