import React from 'react';
import { Button } from 'rhinostyle';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUser, useGroup, useUpdateAssignment } from '../../hooks';
import { toggleProfile, setActivePanel } from '../../reducers/threadReducer';
import { UIHelpers } from '../../helpers';
import { ROUTING_CATEGORY_NONE } from '../../constants/RoutingCategories';

const RouteEvent = ({ event }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { suggestedGroupId, suggestedMemberId, suggestedTextClassificationType } = event;
  const group = useGroup(suggestedGroupId);
  const user = useUser(suggestedMemberId);
  const { handleClick, loading } = useUpdateAssignment(event);

  const handlePanelClick = () => {
    if (UIHelpers.panelBreakpointMax()) {
      dispatch(toggleProfile());
    }
    history.push({ state: { routeEventId: event.id } });
    dispatch(setActivePanel('assign'));
  };

  if (suggestedTextClassificationType === ROUTING_CATEGORY_NONE) {
    return (
      <div className="u-p-t-small u-p-b-small">
        <div className="convo__event__title u-text-body">
          Sorry, we cannot identify a category for this message
        </div>
        <Button
          size="small"
          onClick={handlePanelClick}
          type="primary"
          outlined
          className="convo__event__button  u-m-t-small"
          disabled={loading}
          data-cypress="selectCategoryAssignment"
        >
          Select a Category and Assignment
        </Button>
      </div>
    );
  }

  const formattedName = group ? group.name : `${user.firstName} ${user.lastName}`;
  return (
    <div className="u-p-t-small u-p-b-small">
      <div className="convo__event__title u-text-body">
        <span className="u-text-small">Suggested Category: <span className="u-font-weight-bold">{suggestedTextClassificationType}</span></span> |
        <span className="u-text-small"> Assignment: <span className="u-font-weight-bold">{formattedName}</span></span>
      </div>
      <div className="convo__event__subtext">
        <Button
          size="small"
          onClick={handleClick}
          type="primary"
          className="convo__event__button u-m-t-small"
          loading={loading}
        >
          {`Correct | Assign to ${formattedName}`}
        </Button>
        <Button
          size="small"
          onClick={handlePanelClick}
          type="primary"
          outlined
          className="convo__event__button u-m-t-small"
          disabled={loading}
          data-cypress="selectCategoryAssignment"
        >
          Incorrect | Change Category or Assignment
        </Button>
      </div>
    </div>
  );
};
export default RouteEvent;
