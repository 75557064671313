/* eslint react/jsx-filename-extension:0 */
import 'what-input';
import React from 'react';
import axios from 'axios';
// Polyfill for Bandwidth
import 'core-js/features/global-this';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { UtilitySystem } from 'rhinostyle';
import ErrorBoundary from './components/ErrorBoundary';
import store from './store';
import AppUpdateService from './services/AppUpdateService';
import calculateViewportHeight from './helpers/ViewportHelpers';
import { isMobile } from './helpers/BrowserHelpers';
import App from './components/App';

require('./assets/styles/app.less');

/**
 * 'Pragma' and 'Expires' headers are necessary to prevent data caching from the same endpoint in IE11/Edge.
 * https://support.microsoft.com/en-us/help/234067/how-to-prevent-caching-in-internet-explorer
 */
const accessToken = localStorage.getItem('access_token');
if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.Pragma = 'no-cache';
axios.defaults.headers.Expires = -1;
axios.defaults.headers['Cache-Control'] = 'no-cache';
axios.defaults.withCredentials = true;

function renderApp() {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('app'),
  );
}

renderApp();

AppUpdateService.enable();

// Resize to listen to viewport changes
UtilitySystem.optimizedResize.add(() => {
  calculateViewportHeight(true);
});

// Check for `vector-effect: non-scaling-stroke` support
UtilitySystem.checkVectorEffectSupport();

if (isMobile()) {
  document.documentElement.classList.add('is-mobile');
}
