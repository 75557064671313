import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  DropdownMenuItem,
  Icon,
  Scrollbars,
  ResourceGroup,
  LoaderPulse,
  PillsInput,
  UtilitySystem,
} from 'rhinostyle';
import { useSelector } from 'react-redux';
import { PhoneHelpers, SearchHelpers } from '../helpers';
import UserSearchResult from './UserSearchResult';
import { getRhinoblastMaxPageSize } from '../selectors/organizationSelectors';

const ContactListUserSearch = (props) => {
  const {
    activeFilterParam,
    handleFilterChange,
    handleRemoveUserFromSelectedIds,
    handleRemoveAllUsersFromSelectedIds,
    handleSearch,
    handleSearchSelect,
    inputFormat,
    multiUserInputRef,
    onSearchTextCleaveInit,
    phones,
    scope,
    searchFocus,
    searchText,
    selectedUserIds,
    userSearchIds,
    userSearchLoading,
    userSearchSource,
    contactListRef,
    onScroll,
    users,
  } = props;

  const maxRhinoblastCount = useSelector(getRhinoblastMaxPageSize);

  const selectedContacts = selectedUserIds.map((userId) => {
    const user = users[userId];
    if (!user.firstName && !user.lastName) {
      return {
        id: userId.toString(),
        label: `${PhoneHelpers.formatPhone(phones[user.phones[0]].value)}`,
      };
    }
    return {
      id: userId.toString(),
      label: `${user.firstName} ${user.lastName}`,
    };
  });

  const renderSearchHelp = () => {
    if (searchText.length > 2 && userSearchLoading) {
      return <div className="u-text-center"><LoaderPulse type="secondary" /></div>;
    } else if (searchText.length > 2 && !userSearchIds.length && !userSearchLoading) {
      return <div className="search__no-results u-m-t">No results</div>;
    }
    return null;
  };

  const renderFilterOption = (id) => (<DropdownMenuItem key={id} id={id} label={SearchHelpers.getFilterLabelInformation(id).label} />);

  return (
    <div className="search__group">
      {props.selectedSavedList && (
      <div className="u-text-center u-text-uppercase search__group__title">
        {`LIST: ${props.selectedSavedList.name} (${props.selectedUserIds.length})`}
      </div>
      )}
      {!selectedContacts.length >= maxRhinoblastCount && (
      <div className="search__max-contact u-text-center">
        Maximum selection reached. No more than {maxRhinoblastCount} Contacts can be selected at a time.
      </div>
      )}
      <div className="contact-list-search__wrapper">
        <>
          <PillsInput
            pills={selectedContacts}
            validationMessage="hi"
            pillCloseIconClassName="u-text-muted"
            onPillCloseIconClick={handleRemoveUserFromSelectedIds}
            inputProps={{
              autoComplete: 'off',
              name: scope,
              initialValue: searchText,
              naked: true,
              size: 'large',
              format: inputFormat,
              onChange: handleSearch,
              onInit: onSearchTextCleaveInit,
              placeholder: SearchHelpers.getFilterLabelInformation(activeFilterParam).placeholder,
              focus: searchFocus,
              ref: multiUserInputRef,
              className: 'contact-list-search__pill-input__input--large',
            }}
            className="search__modal__pills-input"
          />
        </>
        <Dropdown
          size="large"
          activeKey={activeFilterParam}
          wide
          type="input"
          onSelect={handleFilterChange}
          title="Search by"
          icon="filter"
          className="contact-list-search__filter"
          hideCaret
          position="right"
        >
          {SearchHelpers.filterContactParams.filterParams?.map(renderFilterOption)}
        </Dropdown>
      </div>
      {userSearchSource === 'contactListSearch' && (
        <>
          {userSearchIds.length > 0 ? (
            <Scrollbars
              className="resource-group__scroll u-m-t-small contact-list-form__result-wrapper"
              autoHeight
              autoHeightMax={UtilitySystem.config.resourceSizes.large}
              onScroll={onScroll}
              ref={contactListRef}
            >
              <ResourceGroup className="border-tent">
                {userSearchIds.map((userId) => (
                  <UserSearchResult
                    userId={userId}
                    key={userId}
                    handleClick={handleSearchSelect}
                    scope={scope}
                    activeFilterParam={activeFilterParam}
                    searchText={searchText}
                    disabled={selectedContacts.length >= maxRhinoblastCount && !selectedUserIds.includes(userId)}
                    isSelected={selectedUserIds.includes(userId)}
                    interfaceMode="checkbox"
                  />
                ))}
              </ResourceGroup>
            </Scrollbars>
          ) : renderSearchHelp()}
        </>
      )}
      <div className="u-m-t-small search__modal__lower-body-content">
        <div className="search__modal__lower-body-content-left">
          {selectedContacts.length > 0 && (
            <div className="search__modal__clear-all-button">
              <Button
                reset
                onClick={handleRemoveAllUsersFromSelectedIds}
                size="small"
                className="u-text-primary search__modal__clear-all-button"
              >
                <Icon icon="close" size="small" className="search__modal__clear-all-button-icon" />
                Clear All
              </Button>
            </div>
          )}
        </div>
        <div className="search__count u-text-right">{selectedUserIds.length} of {maxRhinoblastCount} allowed</div>
      </div>
    </div>
  );
};
ContactListUserSearch.propTypes = {
  activeFilterParam: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleRemoveUserFromSelectedIds: PropTypes.func,
  handleRemoveAllUsersFromSelectedIds: PropTypes.func,
  handleSearch: PropTypes.func.isRequired,
  handleSearchSelect: PropTypes.func.isRequired,
  inputFormat: PropTypes.object,
  multiUserInputRef: PropTypes.object.isRequired,
  onSearchTextCleaveInit: PropTypes.func.isRequired,
  phones: PropTypes.object.isRequired,
  scope: PropTypes.string.isRequired,
  searchFocus: PropTypes.bool.isRequired,
  searchText: PropTypes.string.isRequired,
  selectedUserIds: PropTypes.array,
  userSearchIds: PropTypes.array.isRequired,
  userSearchLoading: PropTypes.bool.isRequired,
  userSearchSource: PropTypes.string,
  users: PropTypes.object.isRequired,
  selectedSavedList: PropTypes.object,
  contactListRef: PropTypes.func.isRequired,
  onScroll: PropTypes.func,
};

export default ContactListUserSearch;
