import React from 'react';
import {
  Checkbox,
} from 'rhinostyle';
import cx from 'classnames';

import useFetchPrescriptionCampaign from '../hooks/useFetchPrescriptionCampaign';

const PayorCheckbox = ({
  handleCheck,
  isChecked,
  inUse,
  payor,
  isFormView,
}) => {
  const checkboxClasses = cx('u-text-left', {
    'u-text-small': !isFormView,
  });
  const columnLeftClass = isFormView ? 'column-6' : 'column-8';
  const { data: prescriptionCampaign } = useFetchPrescriptionCampaign(isFormView ? payor.prescriptionCampaignId : null);
  return (
    <Checkbox
      name={payor.name || ''}
      onChange={() => handleCheck(payor.id)}
      isChecked={isChecked}
      dataFeatureTag={payor.name}
      interfaceLeft
      className={checkboxClasses}
      label={(
        <div className="u-m-l u-flex u-align-items-center">
          <div className={columnLeftClass}>{payor.name}</div>
          <div className="column-4">{payor.bin}</div>
          {isFormView && (
          <div className="column-2">
            {isFormView && inUse && prescriptionCampaign?.name && (
            <span
              data-cypress={`${payor.name}InUse`}
              className="appointment-reminder-form__types-accent u-text-xsmall u-text-accent u-font-weight-normal u-text-right"
            > {`In use: ${prescriptionCampaign.name || ''}`}
            </span>
            )}
          </div>
          )}

        </div>
            )}
    />
  );
};

export default PayorCheckbox;
