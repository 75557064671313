import PropTypes from 'prop-types';
import React from 'react';
import {
  DropdownCheckbox,
  Icon,
  ResourceGroup,
  Scrollbars,
  Dropdown,
  DropdownMenuItem,
  Button,
  UtilityInlineGrid,
} from 'rhinostyle';
import { BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD } from '../constants/AppConstants';
import LazyLoader from './LazyLoader';
import PageLoader from './PageLoader';
import EmptyMessage from './EmptyMessage';
import InboxMentionEvent from './InboxMentionEvent';

const InboxMention = (props) => {
  const {
    activeKey,
    bulkActionsList,
    bulkSelectionList,
    checkboxClassName,
    currentPageNumber,
    eventMentions,
    handleCheckboxOnMentionItem,
    handleEventMentionClick,
    handlePagination,
    inboxBodyRef,
    inboxLoading,
    isCcr,
    isChecked,
    labelValueAssociated,
    mentionIds,
    nextDisabled,
    olderInboxEventMentionIds,
    onChange,
    onScroll,
    onSelect,
    onSelectAction,
    pageItemIndexFrom,
    pageItemIndexTo,
    pageLoading,
    previousDisabled,
    selectedInboxMentionIds = [],
    todayInboxEventMentionIds,
    totalInboxCount,
    totalPageCount,
    yesterdayInboxEventMentionIds,
  } = props;

  const mentionsList = Object.values(eventMentions);

  const renderMentionEvent = (id, key) => {
    const em = { ...eventMentions[id] };
    return (
      <InboxMentionEvent
        mentionEvent={em}
        key={key}
        handleCheckboxOnMentionItem={handleCheckboxOnMentionItem}
        handleEventMentionClick={handleEventMentionClick}
        isChecked={selectedInboxMentionIds.includes(id)}
      />
    );
  };

  const renderMentionInboxList = (ids) => (
    <ResourceGroup topBorder>
      {ids.map(renderMentionEvent)}
    </ResourceGroup>
  );

  const isPaginationNavigationVisible = (separator) => {
    const todaysIds = todayInboxEventMentionIds;
    const yesterdayIds = yesterdayInboxEventMentionIds;

    if (separator === 'Today' && todaysIds.length) return true;
    else if (separator === 'Yesterday' && !todaysIds.length) return true;
    else if (separator === 'Older' && !todaysIds.length && !yesterdayIds.length) return true;
    return false;
  };

  const previousCaretClass = previousDisabled ? 'pagination__caret--disabled' : '';
  const nextCaretClass = nextDisabled ? 'pagination__caret--disabled' : '';

  const renderPaginationCarets = () => (
    <div>
      <Button
        type="link"
        iconOnly
        disabled={previousDisabled}
        onClick={() => handlePagination('previous')}
        className={previousCaretClass}
      >
        <Icon icon="caret-left" />
      </Button>
      <span><strong>{currentPageNumber}</strong>{` of ${totalPageCount}`}</span>
      <Button
        type="link"
        iconOnly
        disabled={nextDisabled}
        onClick={() => handlePagination('next')}
        className={nextCaretClass}
      >
        <Icon icon="caret-right" />
      </Button>
    </div>
  );

  const renderSeparator = (separator) => {
    if (isPaginationNavigationVisible(separator)) {
      return (
        <div className="inbox-pagination">
          <UtilityInlineGrid className="u-flex u-flex-justify-between u-text-small">
            <span className="inbox-pagination__count">{`${pageItemIndexFrom} - ${pageItemIndexTo} of ${totalInboxCount}`}</span>
            <div className="inbox-pagination__title">{separator}</div>
            {renderPaginationCarets()}
          </UtilityInlineGrid>
        </div>
      );
    }
    return (
      <UtilityInlineGrid className="u-flex u-flex-justify-center u-m-a u-text-small">
        <div className="u-text-muted">{separator}</div>
      </UtilityInlineGrid>
    );
  };

  const renderInboxHeader = () => {
    const titleClassName = mentionsList.length > 0 ? 'bulk-action__header__title' : '';
    return (
      <div className="app-page__header__title">
        <div className={titleClassName}>@ Mentions</div>
      </div>
    );
  };

  const renderSelectAction = () => (
    <Dropdown label="Select an Action" name="bulkActionArray" type="primary" position="right" noChangeLabel="Assign" outlined>
      {bulkActionsList.map((action) => (
        <DropdownMenuItem
          key={action.id}
          id={action.id}
          label={action.label}
          labelDesc={action?.description}
          disabled={action?.disabled}
          onClick={() => onSelectAction(action)}
        />
      ))}
    </Dropdown>
  );

  const bulkHeaderClassName = mentionsList.length > 0 ? 'app-page__header' : 'app-page__header no-bulk__header';
  const headerClassName = (mentionsList.length > 0 && selectedInboxMentionIds.length > 0) ? 'app-page__header bulk-select-header' : bulkHeaderClassName;

  const renderMentionBody = () => {
    if (pageLoading) {
      return <PageLoader />;
    }
    if (mentionIds?.length) {
      return (
        <Scrollbars className="list-panel__body__scroll list-panel__body__scroll--no-border" onScroll={onScroll} ref={inboxBodyRef}>

          {todayInboxEventMentionIds.length > 0 && (
          <div>
            {renderSeparator('Today')}
            {renderMentionInboxList(todayInboxEventMentionIds)}
          </div>
          )}

          {yesterdayInboxEventMentionIds.length > 0 && (
          <div>
            {renderSeparator('Yesterday')}
            {renderMentionInboxList(yesterdayInboxEventMentionIds)}
          </div>
          )}

          {olderInboxEventMentionIds.length > 0 && (
          <div>
            {renderSeparator('Older')}
            {renderMentionInboxList(olderInboxEventMentionIds)}
          </div>
          )}

          {mentionIds.length >= BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD && (
          <UtilityInlineGrid className="u-flex u-flex-justify-between u-m-t-small u-text-small">
            <span>{`${pageItemIndexFrom} - ${pageItemIndexTo} of ${totalInboxCount}`}</span>
            {renderPaginationCarets()}
          </UtilityInlineGrid>
          )}
        </Scrollbars>
      );
    } return <EmptyMessage section="Mentions" />;
  };
  return (
    <div className="app-page bulk-actions">
      <div className="app-panels">
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className={headerClassName}>
              {mentionsList.length > 0 && !isCcr && (
                <DropdownCheckbox
                  name="dropdownCheckbox"
                  type="checkbox"
                  isCheckbox
                  isChecked={isChecked}
                  onChange={onChange}
                  labelValueAssociated={labelValueAssociated}
                  showAssociatedLabel
                  checkboxClassName={checkboxClassName}
                  className="dropdown-chekbox"
                  activeKey={activeKey}
                >
                  {bulkSelectionList.map((item) => <DropdownMenuItem key={item.id} id={item.id} label={item.label} onClick={() => onSelect(item)} />)}
                </DropdownCheckbox>
              )}
              {!(selectedInboxMentionIds.length > 0) ? renderInboxHeader() : renderSelectAction()}
            </div>
            <div className="list-panel__body">
              {renderMentionBody()}
              <LazyLoader loading={!pageLoading && inboxLoading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

InboxMention.propTypes = {
  activeKey: PropTypes.number,
  bulkActionsList: PropTypes.array.isRequired,
  bulkSelectionList: PropTypes.array,
  checkboxClassName: PropTypes.string,
  currentPageNumber: PropTypes.number.isRequired,
  eventMentions: PropTypes.object,
  handleCheckboxOnMentionItem: PropTypes.func,
  handleEventMentionClick: PropTypes.func,
  handlePagination: PropTypes.func.isRequired,
  inboxBodyRef: PropTypes.func.isRequired,
  inboxLoading: PropTypes.bool,
  isCcr: PropTypes.bool,
  isChecked: PropTypes.bool.isRequired,
  labelValueAssociated: PropTypes.string.isRequired,
  mentionIds: PropTypes.array,
  nextDisabled: PropTypes.bool.isRequired,
  olderInboxEventMentionIds: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onScroll: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onSelectAction: PropTypes.func,
  pageItemIndexFrom: PropTypes.number.isRequired,
  pageItemIndexTo: PropTypes.number.isRequired,
  pageLoading: PropTypes.bool,
  previousDisabled: PropTypes.bool.isRequired,
  selectedInboxMentionIds: PropTypes.array,
  todayInboxEventMentionIds: PropTypes.array,
  totalInboxCount: PropTypes.number.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  yesterdayInboxEventMentionIds: PropTypes.array,
};

export default InboxMention;
