import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, UtilitySystem, Alert } from 'rhinostyle';
import GroupSearch from './GroupSearch';
import UserSearchContainer from '../containers/UserSearchContainer';

const MemberGroupSearch = (props) => {
  const {
    selectedMemberId,
    selectedGroupId,
    handleSelectGroup,
    handleSelectMember,
    required,
    title,
    titleSub,
    validationMessage,
    name,
    viewGroupsInitial,
  } = props;
  const [viewGroups, setViewGroups] = useState(!!viewGroupsInitial);

  useEffect(() => {
    if (selectedGroupId > 0 && !viewGroups) {
      setViewGroups(true);
    }
  }, [selectedGroupId]);

  const tabTriggerMemberClasses = `tabs__trigger ${!viewGroups ? UtilitySystem.config.classes.active : ''}`;
  const tabTriggerGroupsClasses = `tabs__trigger ${viewGroups ? UtilitySystem.config.classes.active : ''}`;
  return (
    <div className="box form__group" name={name}>
      <div className="box__title-wrapper">
        <div className="box__title">{title} {required && (<span className="u-text-danger">*</span>)}</div>
        <div className="box__subtitle">{titleSub}</div>
      </div>
      <div className="tabs__header u-m-t-large">
        <Button data-cypress="membersButton" reset className={tabTriggerMemberClasses} onClick={() => setViewGroups(false)}>
          Members
        </Button>
              &nbsp;|&nbsp;
        <Button data-cypress="groupsButton" reset className={tabTriggerGroupsClasses} onClick={() => setViewGroups(true)}>
          Groups
        </Button>
      </div>
      <>
        <UserSearchContainer
          interfaceMode="radio"
          type="preloadedMembers"
          selectedUserId={selectedMemberId}
          handleSearchSelect={handleSelectMember}
          isHidden={viewGroups}
        />
        <GroupSearch
          handleSearchSelect={handleSelectGroup}
          selectedGroupId={selectedGroupId}
          interfaceMode="radio"
          prepopulate
          excludeChatGroups
          showTooltip={false}
          isHidden={!viewGroups}
        />
      </>
      {validationMessage && (
        <Alert type="danger" className="u-m-t">
          {validationMessage}
        </Alert>
      )}
    </div>
  );
};

MemberGroupSearch.propTypes = {
  selectedMemberId: PropTypes.number,
  selectedGroupId: PropTypes.number,
  handleSelectGroup: PropTypes.func,
  handleSelectMember: PropTypes.func,
  required: PropTypes.bool,
  title: PropTypes.string,
  titleSub: PropTypes.string,
  validationMessage: PropTypes.string,
  name: PropTypes.string,
  viewGroupsInitial: PropTypes.bool,
};

export default MemberGroupSearch;
