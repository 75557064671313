import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchTags } from '../reducers/tagReducer';
import OrganizationTags from '../components/OrganizationTags';
import { userSelectors } from '../selectors';

class OrganizationTagsContainer extends Component {
  state = {
    isModalOpen: false,
  }

  componentDidMount() {
    this.props.fetchTags();
  }

  closeModal = () => {
    this.setState({ isModalOpen: false });
  }

  handleCreateRequest = () => {
    this.setState({ isModalOpen: true });
  }

  render() {
    const props = {
      closeModal: this.closeModal,
      currentUser: this.props.currentUser,
      handleCreateRequest: this.handleCreateRequest,
      isModalOpen: this.state.isModalOpen,
      tagIds: this.props.tagIds,
      tags: this.props.tags,
      tagsLoading: this.props.tagsLoading,
    };

    return (<OrganizationTags {...props} />);
  }
}

OrganizationTagsContainer.propTypes = {
  currentUser: PropTypes.object.isRequired,
  fetchTags: PropTypes.func.isRequired,
  tagIds: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  tagsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { tag } = state;

  return {
    currentUser: userSelectors.getLoggedInUser(state),
    tags: tag.tags,
    tagIds: tag.tagIds,
    tagsLoading: tag.loading,
  };
};

const actions = {
  fetchTags,
};

export default connect(mapStateToProps, actions)(OrganizationTagsContainer);
