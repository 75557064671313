import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DropdownMultiSelectAdvanced } from 'rhinostyle';
import { cloneDeep } from '../helpers/DataHelpers';
import { getKeyedFormTitles } from '../selectors/formSelectors';

function FormsMultiSelect(props) {
  const {
    formTitles,
    keyedFormTitles,
    updateFilters,
    selectedIds,
    disabled,
  } = props;
  const [formIds, setFormIds] = useState([]);

  useEffect(() => {
    if (formTitles) {
      setFormIds(formTitles);
    }
  }, [formTitles]);

  const fetchAllForms = (searchText) => {
    if (searchText.length > 2) {
      setFormIds(formTitles.filter((item) => item.toLowerCase().includes(searchText.toLowerCase())));
    } else {
      setFormIds(formTitles);
    }
  };

  const handleUpdateSelectedIds = (ids) => {
    const validIds = ids.filter((id) => keyedFormTitles[id]?.title);
    updateFilters(validIds);
  };

  const handleClearAllSelectedForms = () => {
    updateFilters([]);
  };

  const getLabel = () => {
    if (selectedIds.length > 0) {
      const validIds = selectedIds.filter((id) => keyedFormTitles[id]?.title);
      return `${validIds.length} ${validIds.length > 1 ? 'Forms' : 'Form'} Selected`;
    }
    return 'Form Name';
  };

  return (
    <DropdownMultiSelectAdvanced
      handleUpdateSelectedIds={handleUpdateSelectedIds}
      itemSearchLoading={false}
      items={keyedFormTitles}
      selectedItems={keyedFormTitles}
      selectedItemsIds={selectedIds}
      handleClearAllSelectedItems={handleClearAllSelectedForms}
      fetchAllItems={fetchAllForms}
      itemsIds={formIds}
      dropdownLabel={getLabel()}
      filterName="Form Name"
      type="forms"
      dropdownItemClass="interface-left"
      dropDownClass="multi-select__dropdown"
      dataCypress="formTitleFilter"
      interfaceLeft
      disabled={disabled}
    />
  );
}

FormsMultiSelect.propTypes = {
  formTitles: PropTypes.array,
  keyedFormTitles: PropTypes.object,
  updateFilters: PropTypes.func,
  selectedIds: PropTypes.array,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  formTitles: state.form.formTitles,
  keyedFormTitles: cloneDeep(getKeyedFormTitles(state)),
});

export default connect(mapStateToProps)(FormsMultiSelect);
