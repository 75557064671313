import React, { useState, useMemo, useEffect } from 'react';
import { Chart, DateRangeDropdown, LoaderCircle } from 'rhinostyle';
import moment from 'moment-timezone';
import { barChartConfig } from '../configs/Analytics.config';
import PageLoader from '../components/PageLoader';
import { useGetHipaaRequestAnalyticsQuery } from '../services/hipaaCampaignService';
import { calculateDateRange } from '../helpers/AnalyticsHelper';
import { DATEPICKER_DROPDOWN_LABELS } from '../constants/AnalyticsConstants';
import { useQueryParams } from '../hooks';

const CampaignAnalyticsView = () => {
  const minDate = moment().subtract(3, 'years');
  const maxDate = moment().subtract(1, 'days');
  const initialValue = {
    startDate: moment().subtract(30, 'days'),
    endDate: moment().subtract(1, 'days'),
    activeKey: 3,
  };
  const [loading, setLoading] = useState(true);

  function formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }
  const [filters, onSetFilters] = useQueryParams({ initialValue, datePickerValues: DATEPICKER_DROPDOWN_LABELS });

  const { data, isLoading, isFetching } = useGetHipaaRequestAnalyticsQuery({ to: formatDate(filters.endDate), from: formatDate(filters.startDate) });

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    }
  }, [isLoading]);

  const renderChartMemo = useMemo(() => {
    if (data) {
      const totalCount = Object.values(data).reduce((a, b) => a + b, 0);
      barChartConfig.data.datasets[0].data = Object.values(data);
      barChartConfig.data.labels = Object.keys(data);
      return (
        <div className="row analytics__charts-container">
          <div className="column-8@medium">
            <div className="campaign-analytics__box">
              {isFetching ? <LoaderCircle /> : (
                <Chart
                  data={barChartConfig.data}
                  options={barChartConfig.options}
                  labels={Object.keys(data)}
                  type="bar"
                  title="Total Sent"
                  subHeader=""
                  header={{ text: totalCount, color: 'primary' }}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }, [data, isFetching]);

  return (
    <div className="app-page__container analytics">
      <div className="app-page__container__inner analytics__container">
        <div className="app-page__header">
          <div className="app-page__header__title analytics__header">HIPAA Consent Campaign</div>
        </div>
        <div className="analytics__wrapper">
          <div className="analytics__title analytics__title--campaigns">
            Number of HIPAA Consent Texts Sent
          </div>
          <div className="analytics__date-range analytics__date-range--campaigns">
            <DateRangeDropdown
              position="left"
              name="Analytics Date Picker"
              startDate={filters.startDate}
              endDate={filters.endDate}
              selectDate={onSetFilters}
              minDate={minDate}
              maxDate={maxDate}
              activeKey={filters.activeKey}
              dropdownMenuItems={DATEPICKER_DROPDOWN_LABELS}
              selectDateRange={calculateDateRange}
              isCustomDate
              dropdownClass="u-text-left"

            />
          </div>
          {loading ? <PageLoader /> : renderChartMemo}
        </div>
      </div>
    </div>
  );
};

export default CampaignAnalyticsView;
