import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  Icon,
  Dropdown,
  DropdownMenuItem,
} from 'rhinostyle';

import { getIsHost, getCurrentVideo } from '../selectors/rhinovideoSelectors';
import {
  setParticipantsPanelOpen,
  updateVideoHostId,
  updateMutedParticipants,
  participantKick,
  setPinnedUserId,
} from '../reducers/rhinovideoReducer';

const RhinovideoParticipantsPanel = (props) => {
  const {
    participants,
    hostId,
    currentVideoId,
    isHost,
    participantsInfo,
    currentVideo,
    currentUserId,
    pinnedUserId,
  } = props;

  const dispatch = useDispatch();

  const handleMuteParticipant = (isMicrophoneEnabled, participant, participantName) => {
    dispatch(updateMutedParticipants(participant.sid, isMicrophoneEnabled, participantName));
  };

  const handlePinParticipant = (participant) => {
    const participantId = pinnedUserId !== participant.sid ? participant.sid : null;
    dispatch(setPinnedUserId(participantId));
  };

  const handleRemoveParticipant = (participant) => {
    dispatch(participantKick(participant));
  };

  const handleMakeHost = (participant, participantName) => {
    dispatch(updateVideoHostId(currentVideoId, participant.identity, participantName));
  };

  const anonymousParticipants = Object.values(participantsInfo).filter((p) => !p.name);

  const renderParticipant = (participant, key) => {
    const anonymousParticipantIndex = anonymousParticipants.findIndex((p) => p.sid === participant.sid);
    const participantInfo = participantsInfo[participant.sid];
    const participantName = participantInfo?.name || `Participant ${anonymousParticipantIndex + 1}`;
    const isMicrophoneEnabled = participant.audioTrack?.isTrackEnabled;
    const isMember = !!participant.memberId || currentVideo.memberIds.includes(Number(participant.identity));
    return (
      <div className="rhinovideo__participants-panel__item" key={key}>
        <div className="rhinovideo__participants-panel__info">
          <img className="rhinovideo__participants-panel__avatar" alt="Avatar" src={participantInfo?.avatarUrl} />
          <div className="rhinovideo__participants-panel__name">{`${participantName}${participant.identity === hostId ? ' (host)' : ''}`}</div>
        </div>
        <div className="rhinovideo__participants-panel__actions">
          <Button
            reset
            title={!isMicrophoneEnabled ? 'Mute' : 'Unmute'}
            onClick={() => handleMuteParticipant(isMicrophoneEnabled, participant, participantName)}
            className={`rhinovideo__participants-panel__actions-button ${isMicrophoneEnabled ? 'is-enabled' : ''}`}
            disabled={!isHost}
          >
            <Icon icon={isMicrophoneEnabled ? 'microphone' : 'microphone-off'} />
          </Button>
          <Dropdown
            className="rhinovideo__participants-panel__actions-dropdown"
            hideCaret
            icon="dots-vertical"
            position="right"
            reset
            title="Actions"
            wide
          >
            <DropdownMenuItem label={pinnedUserId === participant.sid ? 'Unpin Participant' : 'Pin Participant'} onClick={() => handlePinParticipant(participant)} />
            {isHost && isMember && participant.identity !== currentUserId && (
              <DropdownMenuItem label="Make Host" onClick={() => handleMakeHost(participant, participantName)} />
            )}
            {isHost && participant.identity !== currentUserId && <DropdownMenuItem label="Remove Participant" onClick={() => handleRemoveParticipant(participant)} />}
          </Dropdown>
        </div>
      </div>
    );
  };

  const participantsConnected = Object.values(participants).filter((participant) => participantsInfo[participant.sid]);

  return (
    <div className="rhinovideo__participants-panel__container">
      <div className="rhinovideo__participants-panel__header">
        <div className="rhinovideo__participants-panel__title">{`Participants (${participantsConnected.length})`}</div>
        <div className="app-page__header__action">
          <Close
            className="app-page__header__action__close"
            onClick={() => dispatch(setParticipantsPanelOpen(false))}
          />
        </div>
      </div>
      {participantsConnected.map(renderParticipant)}
    </div>
  );
};

RhinovideoParticipantsPanel.propTypes = {
  hostId: PropTypes.string.isRequired,
  currentUserId: PropTypes.string,
  pinnedUserId: PropTypes.string,
  isHost: PropTypes.bool.isRequired,
  currentVideoId: PropTypes.string.isRequired,
  participants: PropTypes.object.isRequired,
  participantsInfo: PropTypes.object.isRequired,
  currentVideo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const currentVideo = getCurrentVideo(state);
  const isHost = getIsHost(state);
  const { rhinovideo, auth } = state;

  return {
    currentVideo,
    participantsInfo: currentVideo.participantsInfo,
    participants: rhinovideo.participants,
    pinnedUserId: rhinovideo.pinnedUserId,
    hostId: currentVideo.hostId,
    isHost,
    currentVideoId: rhinovideo.currentVideoId,
    currentUserId: auth.currentUser?.toString(),
  };
};

export default connect(mapStateToProps)(RhinovideoParticipantsPanel);
