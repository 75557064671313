import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  UtilitySystem,
  FormValidationMessage,
} from 'rhinostyle';

import Quill from '../configs/Quill.config.js';

import { getLoggedInUser, getLoggedInUserOrganization } from '../selectors/userSelectors';
import { handleEmojiSelection } from '../helpers/TemplateHelpers.js';
import OptOut from './OptOut';

let quill;

const VariableInput = (props) => {
  const [messagePreview, setMessagePreview] = useState('');

  const id = `${props.name}-${UtilitySystem.generateUUID()}`;

  useEffect(() => {
    quillInit();
  }, []);

  function quillInit() {
    const options = {
      modules: {
        toolbar: false,
      },
      readOnly: false,
      placeholder: props.placeholder,
    };
    const variableMessageInputName = `variable-message-input-${id}`;

    quill = new Quill(`#${variableMessageInputName}`, options);
    quill.on('text-change', handlePreview);
  }

  useEffect(() => {
    if (props.template) {
      handleTemplate();
    }
    if (props.emoji) {
      handleEmojiSelection(props.emoji, quill);
    }
  }, [props.template, props.emoji]);

  const getVariables = (array) => array.map((el) => el.variable.toLowerCase());

  function handleTemplate() {
    const { variableOptions, template } = props;
    const flatVariables = getVariables(variableOptions);
    // Inflate message string to strip out handlebars and add HTML
    template.split(/({.*?})/).forEach((el) => {
      const range = quill.getSelection(true); // force focus and grab range from input
      const variableIndex = flatVariables.indexOf(el.toLowerCase());
      if (variableIndex > -1) {
        quill.insertEmbed(range.index, 'variable', variableOptions[variableIndex], 'user');
        quill.setSelection(range.index + 1, 'user');
      } else {
        quill.insertText(range.index, el);
      }
    });
  }

  function handlePreview() {
    const messageContents = quill.getContents();
    const newMessagePreview = messageContents.ops.map((el) => {
      if (typeof el.insert === 'object' && el.insert !== null) {
        const { currentUser, userOrganization } = props;
        const { variable } = el.insert.variable;
        let { variableValue } = el.insert.variable;

        if (variable === '{Organization_Name}') {
          variableValue = userOrganization.name;
        } else if (variable === '{Sending_Member_First_Name}') {
          variableValue = currentUser.firstName;
        } else if (variable === '{Sending_Member_Last_Name}') {
          variableValue = currentUser.lastName;
        }
        return variableValue;
      }
      return el.insert;
    }).join('').trim();

    setMessagePreview(newMessagePreview);
    handleMessageChange();
  }

  function handleMessageChange() {
    // Sanitize message string to strip out HTML and just include handlebars
    const messageContents = quill.getContents();
    const messageString = messageContents.ops.map((el) => {
      if (typeof el.insert === 'object' && el.insert !== null) {
        return el.insert.variable.variable;
      }
      return el.insert;
    }).join('').trim();
    props.handleMessageChange(messageString);
  }

  const classes = cx('form__group variable-message', props.className);
  const variableMessageInputName = `variable-message-input-${id}`;
  const optOutClass = messagePreview?.length > 0 ? 'u-text-gray' : 'is-hidden';
  return (
    <div className={classes}>
      <div className="form__group variable-message">
        <div className="u-position-relative">
          <div
            name={props.name}
            id={variableMessageInputName}
            className={`broadcast-modal__message-compose ${props.isPreviewDisplayed ? 'is-hidden' : ''}`}
          />
          <div className={`${!props.isPreviewDisplayed ? 'is-hidden' : ''}`}>{messagePreview}</div>
          <OptOut className={optOutClass} />
        </div>
        <FormValidationMessage validationMessage={props.validationMessage} />
      </div>
    </div>
  );
};

VariableInput.propTypes = {
  className: PropTypes.string,
  currentUser: PropTypes.object,
  emoji: PropTypes.object,
  handleMessageChange: PropTypes.func.isRequired,
  isPreviewDisplayed: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  template: PropTypes.string,
  userOrganization: PropTypes.object,
  validationMessage: PropTypes.string,
  variableOptions: PropTypes.array.isRequired,
};

VariableInput.defaultProps = {
  placeholder: '',
};

const mapStateToProps = (state) => ({
  currentUser: getLoggedInUser(state),
  userOrganization: getLoggedInUserOrganization(state),
});

const actions = {};

export default connect(mapStateToProps, actions)(VariableInput);
