import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import * as RhinopayService from '../services/RhinopayService';
import NotificationService from '../services/NotificationService';

import { setError } from './uiReducer';
import { paymentRequest } from '../actions/NormalizrSchema';
import { getStartAndEndOfDateUtc } from '../helpers/DateHelpers';

import { RHINOPAY_TYPE } from '../constants/AppConstants';

// SLICE
const paySlice = createSlice({
  name: 'PAY',
  initialState: {
    paymentRequests: {},
    merchant: {},
    isMerchantFormInProgress: false,
    isMerchantLoading: false,
    isRhinoPayManagerLoading: false,
    patientBalances: {},
    rhinoPayRequests: [],
    totalRhinoPayRequests: 0,
  },
  reducers: {
    receivePaymentRequests: (state, action) => ({
      ...state,
      paymentRequests: {
        ...state.paymentRequests,
        ...action.payload.paymentRequests,
      },
    }),
    receiveMerchantData: (state, action) => ({
      ...state,
      merchant: {
        ...state.merchant,
        ...action.payload.merchant,
      },
      isMerchantLoading: action.payload.isMerchantLoading,
      isMerchantFormInProgress: action.payload.isMerchantFormInProgress,
    }),
    receiveRhinopayRequests: (state, action) => ({
      ...state,
      isRhinoPayManagerLoading: false,
      rhinoPayRequests: action.payload.data,
      totalRhinoPayRequests: action.payload.totalRecords,
    }),
    requestMerchantData: (state) => ({
      ...state,
      isMerchantLoading: true,
    }),
    requestRhinopayRequestsData: (state) => ({
      ...state,
      isRhinoPayManagerLoading: true,
    }),
    setMerchantFormInProgress: (state) => ({
      ...state,
      isMerchantFormInProgress: true,
    }),
    receiveMerchantError: (state) => ({
      ...state,
      merchant: {},
      patientBalances: {},
      paymentRequests: {},
      isMerchantFormInProgress: false,
      isMerchantLoading: false,
    }),
    receiveRhinopayRequestsError: (state) => ({
      ...state,
      isRhinoPayManagerLoading: false,
      totalRhinoPayRequests: 0,
      rhinoPayRequests: [],
    }),
  },
});

export default paySlice.reducer;

// ACTIONS
export const {
  receivePaymentRequests,
  receiveMerchantData,
  receiveRhinopayRequests,
  requestMerchantData,
  requestRhinopayRequestsData,
  setMerchantFormInProgress,
  receiveMerchantError,
  receiveRhinopayRequestsError,
} = paySlice.actions;

// THUNKS -- ASYNC ACTION CREATORS
export function createPaymentRequest(payload) {
  return (dispatch) =>
    RhinopayService.postPaymentRequest(payload)
      .then(() => RhinopayService.getUserBalanceAndPaymentRequests(payload.userId))
      .then((response) => {
        const normalizedPaymentRequests = normalize(response.data.paymentRequests, [paymentRequest]);
        dispatch(receivePaymentRequests(preparePaymentRequestsPayload(normalizedPaymentRequests)));
        NotificationService('createPaymentRequest', response);
      })
      .catch((error) => {
        NotificationService('createPaymentRequest', error.response);
        dispatch(setError(error.response || error));
        console.error(error);
      });
}

export function updatePaymentRequest(payload) {
  return (dispatch) =>
    RhinopayService.postResendPaymentRequest(payload)
      .then(() => RhinopayService.getUserBalanceAndPaymentRequests(payload.userId))
      .then((response) => {
        const normalizedPaymentRequests = normalize(response.data.paymentRequests, [paymentRequest]);
        dispatch(receivePaymentRequests(preparePaymentRequestsPayload(normalizedPaymentRequests)));
        NotificationService('resendPaymentRequest', response);
      })
      .catch((error) => {
        NotificationService('resendPaymentRequest', error.response);
        dispatch(setError(error.response || error));
        console.error(error);
      });
}

export function cancelPaymentRequest(paymentRequestId, userId) {
  return (dispatch) =>
    RhinopayService.deletePaymentRequest(paymentRequestId)
      .then(() => RhinopayService.getUserBalanceAndPaymentRequests(userId))
      .then((response) => {
        const normalizedPaymentRequests = normalize(response.data.paymentRequests, [paymentRequest]);
        dispatch(receivePaymentRequests(preparePaymentRequestsPayload(normalizedPaymentRequests)));
        NotificationService('cancelPaymentRequest', response);
      })
      .catch((error) => {
        NotificationService('cancelPaymentRequest', error.response);
        dispatch(setError(error.response || error));
        console.error(error);
      });
}

export function createCreditCardPaymentToken(formObject) {
  return new Promise((resolve, reject) => {
    // This is the Integrity Pay (IBX) API 'createToken' method. IBX is a global variable
    IBX.createToken(formObject, (response) => { // eslint-disable-line no-undef
      if (response.error && response.error.code !== 0) {
        reject(response);
      }
      resolve(response);
    });
  });
}

export function fetchMerchant(orgId) {
  return (dispatch) => {
    dispatch(requestMerchantData());
    return RhinopayService.getMerchant(orgId)
      .then((response) => {
        dispatch(receiveMerchantData(prepareMerchantPayloadFromPayService(response)));
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveMerchantError());
      });
  };
}

// This endpoint is only open to CCRs and will return additional info relating to the merchant and add it to Redux store.
export function fetchMerchantCcr(orgId) {
  return (dispatch) => {
    dispatch(requestMerchantData());
    return RhinopayService.getMerchantCcr(orgId)
      .then((response) => {
        dispatch(receiveMerchantData(prepareMerchantPayloadFromPayService(response)));
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveMerchantError());
      });
  };
}

export function fetchPaymentRequestsByUser(userId) {
  return (dispatch) =>
    RhinopayService.getUserBalanceAndPaymentRequests(userId)
      .then((response) => {
        const normalizedPaymentRequests = normalize(response.data.paymentRequests, [paymentRequest]);
        dispatch(receivePaymentRequests(preparePaymentRequestsPayload(normalizedPaymentRequests)));
      })
      .catch((error) => {
        dispatch(setError(error.response || error));
      });
}

export function createMerchant(payload) {
  return (dispatch) => {
    dispatch(setMerchantFormInProgress());
    return RhinopayService.postMerchant(payload)
      .then((response) => {
        dispatch(receiveMerchantData(prepareMerchantPayloadFromPayService(response)));
        NotificationService('createMerchant', response);
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveMerchantError());
        NotificationService('createMerchant', err.response);
      });
  };
}

export function updateMerchant(payload) {
  return (dispatch) => {
    dispatch(setMerchantFormInProgress());
    return RhinopayService.patchMerchant(payload)
      .then((response) => {
        dispatch(receiveMerchantData(prepareMerchantPayloadFromPayService(response)));
        NotificationService('updateMerchant', response);
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveMerchantError());
        NotificationService('updateMerchant', err.response);
      });
  };
}

export function fetchRhinoPayRequests(filters, orgId) {
  return (dispatch) => {
    const { startDate, endDate, pageNo, pageSize, status, sortBy, sortOrder } = filters;
    const { startDateUtc, endDateUtc } = getStartAndEndOfDateUtc(startDate, endDate);
    // eslint-disable-next-line max-len
    const queryParams = `orgId=${orgId}&from=${startDateUtc}&to=${endDateUtc}&pageNo=${pageNo}&pageSize=${pageSize}&status=${status}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
    dispatch(requestRhinopayRequestsData());
    return RhinopayService.getRhinopayRequests(queryParams)
      .then((response) => {
        const rhinoPayRequests = response && response.data;
        dispatch(receiveRhinopayRequests(rhinoPayRequests));
      })
      .catch((err) => {
        console.error(err);
        dispatch(receiveRhinopayRequestsError());
      });
  };
}

// PREPARE CALLBACKS -- PAYLOAD CUSTOMIZERS
export function preparePaymentRequestsPayload(normalizedPaymentRequests) {
  return {
    paymentRequests: {
      ...normalizedPaymentRequests.entities.paymentRequests,
    },
  };
}

export function prepareMerchantPayloadFromPayService(merchant) {
  const merchantPaymentType = merchant.data.paymentType;
  const paymentType = (RHINOPAY_TYPE.find((pay) => pay.Key === merchantPaymentType)).id;
  return {
    merchant: {
      isRhinopayEnabled: !!merchant.data.isEnabled,
      paymentType,
      merchantId: merchant.data.merchantId,
      merchantToken: merchant.data.merchantToken,
      paymentApiUsername: merchant.data.apiUserName,
      paymentApiPassword: merchant.data.apiPassword,
      paymentGatewayId: merchant.data.gatewayId,
      isAchEnabled: !!merchant.data.isACHEnabled,
    },
    isMerchantLoading: false,
    isMerchantFormInProgress: false,
  };
}
