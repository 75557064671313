import React, { useState, useCallback } from 'react';
import {
  Icon,
  Button,
  Collapse,
  LoaderCircle,
  UtilityList,
} from 'rhinostyle';
import moment from 'moment-timezone';
import { PROFILE_SUMMARY_APPOINTMENTS_LIST_SIZE } from '../constants/AppConstants';
import { usePrescriptions, useInboxParams } from '../hooks';
import { formatConsentStatus } from '../helpers/PrescriptionHelpers';
import { updatedAtDateFormatter } from '../helpers/DateHelpers';

const ContactPrescriptions = () => {
  const { userId } = useInboxParams();
  const [isTabOpen, setIsTabOpen] = useState(false);

  const [displayLength, setDisplayLength] = useState(PROFILE_SUMMARY_APPOINTMENTS_LIST_SIZE);
  const { data, isLoading } = usePrescriptions({ patientId: userId });
  const { results, total } = data || {};

  function handleShowMore() {
    setDisplayLength((current) => current + PROFILE_SUMMARY_APPOINTMENTS_LIST_SIZE);
  }
  const renderPrescription = useCallback((prescriptionGroup) => {
    const {
      createdAt,
      consentStatusTypeId,
      name,
      payorNames,
      rxNumber,
      payorBins,
      isRefill,
      saleCompletedDate,
      id,
    } = prescriptionGroup;
    const currentStatus = formatConsentStatus(consentStatusTypeId);
    return (
      <div key={id} className="hipaa-status--summary">
        <div className="u-m-b-small">
          <div className="u-m-t-0">
            <strong>{updatedAtDateFormatter(createdAt)}</strong>
          </div>
        </div>
        <div data-cypress="name">
          Prescriptions: <strong>{name}</strong>
        </div>
        <div data-cypress="rxName">
          Prescription Numbers: <strong>{rxNumber}</strong>
        </div>
        <div data-cypress="payorNames">
          Payors: <strong>{payorNames}</strong>
        </div>
        <div data-cypress="payorBins">
          Bins: <strong>{payorBins}</strong>
        </div>
        <div data-cypress="fill Type">
          Fill Type: <strong>{ isRefill ? 'Refill' : 'New Fill' }</strong>
        </div>
        {
          moment(saleCompletedDate).isValid() ?
            (
              <div data-cypress="refillDate">
                Refill Date: <strong>{moment(saleCompletedDate).format('MM/DD/YYYY')}</strong>
              </div>
            ) : null
        }
        <div data-cypress="prescriptionConsentStatus">
          Consent: <strong>{currentStatus}</strong>
        </div>
      </div>
    );
  }, []);
  const renderLoader = useCallback(() => (
    <div className="summary-panel__appointments__loader">
      <LoaderCircle className="u-text-primary" />
    </div>
  ), []);

  const renderNoPrescriptions = useCallback(() => (
    <div
      className="u-text-italic u-p-t u-p-r u-p-b"
      data-cypress="noPrescriptionsMessage"
    >There are no prescriptions for this patient
    </div>
  ), []);

  const renderListView = () => (results?.length > 0 ? (
    <UtilityList space className="u-m-b-0 u-m-t">
      {results.slice(0, displayLength).map(renderPrescription)}
    </UtilityList>
  ) : renderNoPrescriptions());
  const isShowMoreButtonVisible = displayLength < total;

  return (
    <div className="summary-panel__section summary-panel__section--full">
      <div className="summary-panel__section__label">
        Prescriptions
        <div className="summary-panel__section__label__action">
          <Icon
            icon={isTabOpen ? 'caret-up' : 'caret-down'}
            className="u-text-muted"
            onClick={() => setIsTabOpen((current) => !current)}
            data-cypress="confirmationIcon"
          />
        </div>
      </div>
      <Collapse isOpen={isTabOpen}>
        <div className="summary-panel__section__content summary-panel__section__content--indented">
          <div className="profile-panel__nav-tabs-container" />
          {isLoading ? renderLoader() : renderListView()}
          {isShowMoreButtonVisible && (
            <div className="hipaa-status__show-more">
              <Button reset onClick={handleShowMore} loading={false}>Show More</Button>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default ContactPrescriptions;
