import { useSelector } from 'react-redux';
import {
  useGetPrescriptionCampaignsQuery,
} from '../services/prescriptionService';

const usePrescriptionCampaigns = ({ sortBy, sortOrder, pageNo }) => {
  const organizationId = useSelector((state) => state.auth.currentOrg);
  const results = useGetPrescriptionCampaignsQuery({ sortBy, sortOrder, pageNo, organizationId });
  return results;
};

export default usePrescriptionCampaigns;
