import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Resource,
  ResourceBody,
  ResourceIntro,
  ResourceRight,
} from 'rhinostyle';
import { GroupHelpers } from '../helpers';
import Tags from './Tags';
import { getGroupById } from '../selectors/groupSelectors';

const GroupItem = (props) => {
  const {
    groupId,
    group,
    isSelected,
    handleSelect,
    interfaceMode,
    isDefaultRoute,
  } = props;
  return (
    <Resource selected={interfaceMode && isSelected} key={groupId} onClick={() => handleSelect(groupId)} dataCypress={group.name} interfaceMode={interfaceMode}>
      <ResourceIntro title={group.name}>
        {isDefaultRoute && (
        <span className="u-text-accent">Default Route</span>
        )}
      </ResourceIntro>
      {group.tags?.length > 0 && <ResourceBody className="u-text-muted"><Tags tagIds={group.tags} /></ResourceBody>}
      <ResourceRight>
        {`${GroupHelpers.formatGroupType(group.typeId)} Group`}
      </ResourceRight>
    </Resource>
  );
};

GroupItem.propTypes = {
  group: PropTypes.object.isRequired,
  groupId: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
  interfaceMode: PropTypes.string,
  isSelected: PropTypes.bool,
  isDefaultRoute: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  group: getGroupById(state, props),
});

export default connect(mapStateToProps)(GroupItem);
