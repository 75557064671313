import PropTypes from 'prop-types';
import React from 'react';
import { getMemberFilters } from '../helpers/ContactHelpers';

const MemberFilters = (props) => {
  const {
    disabled,
    handleChange,
    filters,
  } = props;
  const filterComponents = getMemberFilters();

  const renderFilters = (filter) => {
    const { name, label, component: Component, wide } = filter;

    return (
      <Component
        key={name}
        value={filters[name]}
        name={name}
        label={label}
        handleChange={handleChange}
        disabled={disabled}
        dropdownClass="filter-page__dropdown"
        wide={wide}
        dataFeatureTag={`member-search-${name}`}
      />
    );
  };
  return (filterComponents.map(renderFilters));
};

MemberFilters.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default MemberFilters;
