import PropTypes from 'prop-types';
import cx from 'classnames';
import React from 'react';

class BillingUsageMeter extends React.Component { // eslint-disable-line react/prefer-stateless-function
  componentDidMount() {
    this.calculateMeterLength();
  }

  getLimitDifferential = () => {
    const { limit, usage } = this.props;

    // Don't return negative numbers
    // Used for percentages later
    return Math.max(limit - usage, 0);
  }

  // This is auto calculated based on path's shape
  // Putting it here statically so we don't run unecessary calculations on a value that will never change
  pathDashOffset = 282.78302001953125;

  calculateMeterLength = () => {
    const { limit } = this.props;

    // If we're not calculating a meter length, ignore
    if (!limit) return;

    // Calculate the percentage of the total length
    const to = this.pathDashOffset * (this.getLimitDifferential() / limit);
    // Trigger Layout in Safari hack https://jakearchibald.com/2013/animated-line-drawing-svg/
    if (this.path) {
      this.path.getBoundingClientRect();
      // Set the Offset
      this.path.style.strokeDashoffset = Math.max(0, to);
    }
  }

  render() {
    const { label, usage, limit } = this.props;
    const usagePercentage = (1 - ((this.getLimitDifferential()) / limit));
    const className = cx('billing__usage-meter', {
      'has-success': ((usagePercentage) > 0 && (usagePercentage) <= 0.333333),
      'has-warning': ((usagePercentage) > 0.333333 && (usagePercentage) <= 0.5),
      'has-danger': ((usagePercentage) > 0.5),
    });
    return (
      <div className="billing__usage-meter__wrapper">
        <svg
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          className={className}
        >
          <circle r="45" cx="50" cy="50" />
          <path
            ref={(path) => (this.path = path)}
            d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDashoffset={this.pathDashOffset}
            strokeDasharray={this.pathDashOffset}
          />
          <text
            ref={(text) => (this.text = text)}
            x="50"
            y="50"
            textAnchor="middle"
            dy="0.35em"
            fontSize="20"
            title={`${label} count`}
          >
            {usage}
          </text>
        </svg>
        <div className="u-text-center u-text-small u-text-muted">{label}</div>
      </div>
    );
  }
}

BillingUsageMeter.propTypes = {
  usage: PropTypes.number.isRequired,
  limit: PropTypes.number,
  label: PropTypes.string.isRequired,
};

export default BillingUsageMeter;
