//
// Contacts
//
export const CONTACT_VIEW = 'CONTACT_VIEW';
export const CONTACT_ORGANIZATION_CREATE = 'CONTACT_ORGANIZATION_CREATE';
export const CONTACT_ORGANIZATION_EDIT = 'CONTACT_ORGANIZATION_EDIT';
export const CONTACT_ORGANIZATION_DELETE = 'CONTACT_ORGANIZATION_DELETE';
export const CONTACT_CREATE = 'CONTACT_CREATE';
export const CONTACT_EDIT = 'CONTACT_EDIT';
export const CONTACT_DELETE = 'CONTACT_DELETE';
export const CONVERSATION_CONTACT_MOBILE = 'CONVERSATION_CONTACT_MOBILE';
export const INTEGRATED_CONTACTS_EDIT = 'INTEGRATED_CONTACTS_EDIT';
export const INTEGRATED_CONTACTS_EDITOR = 'Integrated Contact Editor';

//
// Groups
//
export const GROUP_VIEW = 'GROUP_VIEW';
export const GROUP_INBOX_EDIT = 'GROUP_INBOX_EDIT';
export const GROUP_CHAT_EDIT = 'GROUP_CHAT_EDIT';
export const GROUP_DELETE = 'GROUP_DELETE';

//
// Channels
//
export const CHANNEL_VIEW = 'CHANNEL_VIEW';
export const CHANNEL_FACEBOOK_CREATE = 'CHANNEL_FACEBOOK_CREATE';
export const CHANNEL_SMS_CREATE = 'CHANNEL_SMS_CREATE';
export const CHANNEL_SECURE_CREATE = 'CHANNEL_SECURE_CREATE';
export const CHANNEL_RHINOGRAM_CREATE = 'CHANNEL_RHINOGRAM_CREATE';
export const CHANNEL_EDIT = 'CHANNEL_EDIT';
export const CHANNEL_DELETE = 'CHANNEL_DELETE';

//
// Members
//
export const MEMBER_VIEW = 'MEMBER_VIEW';
export const MEMBER_EDIT = 'MEMBER_EDIT';
export const MEMBER_CREATE = 'MEMBER_CREATE';
export const MEMBER_PASSWORD_CREATE = 'MEMBER_PASSWORD_CREATE';
export const MEMBER_DELETE = 'MEMBER_DELETE';

//
// Tags
//
export const TAG_VIEW = 'TAG_VIEW';
export const TAG_CREATE = 'TAG_CREATE';
export const TAG_EDIT = 'TAG_EDIT';
export const TAG_DELETE = 'TAG_DELETE';

//
// Templates
//
export const TEMPLATE_MESSAGE_VIEW = 'TEMPLATE_MESSAGE_VIEW';
export const TEMPLATE_MESSAGE_CREATE = 'TEMPLATE_MESSAGE_CREATE';
export const TEMPLATE_MESSAGE_EDIT = 'TEMPLATE_MESSAGE_EDIT';
export const TEMPLATE_MESSAGE_DELETE = 'TEMPLATE_MESSAGE_DELETE';

//
// Out of Office / OOO
//
export const OUT_OF_OFFICE_VIEW = 'OUT_OF_OFFICE_VIEW';
export const OUT_OF_OFFICE_EDIT = 'OUT_OF_OFFICE_EDIT';
export const OUT_OF_OFFICE_CREATE = 'OUT_OF_OFFICE_CREATE';
export const OUT_OF_OFFICE_DELETE = 'OUT_OF_OFFICE_DELETE';

//
// Organization Preferences/Profile
//
export const ORGANIZATION_PROFILE_EDIT = 'ORGANIZATION_PROFILE_EDIT';
export const ORGANIZATION_PROFILE_VIEW = 'ORGANIZATION_PROFILE_VIEW';
export const ORGANIZATION_PREFERENCES_EDIT = 'ORGANIZATION_PREFERENCES_EDIT';
export const ORGANIZATION_PREFERENCES_VIEW = 'ORGANIZATION_PREFERENCES_VIEW';

//
// Threads
//
export const THREAD_VIEW = 'THREAD_VIEW';
export const THREAD_MESSAGE_CREATE = 'THREAD_MESSAGE_CREATE';
export const THREAD_NOTE_CREATE = 'THREAD_NOTE_CREATE';
export const THREAD_HIPAA_CREATE = 'THREAD_HIPAA_CREATE';
export const THREAD_TEMPLATE_EDIT = 'THREAD_TEMPLATE_EDIT';
export const THREAD_COMPLETE_EDIT = 'THREAD_COMPLETE_EDIT';
export const THREAD_FOLLOW_EDIT = 'THREAD_FOLLOW_EDIT';
export const THREAD_UNREAD_EDIT = 'THREAD_UNREAD_EDIT';

//
// TEAM THREAD
//
export const TEAM_THREAD_VIEW = 'TEAM_THREAD_VIEW';
export const TEAM_THREAD_UNREAD_EDIT = 'TEAM_THREAD_UNREAD_EDIT';
export const TEAM_CONVERSATION_EDIT = 'TEAM_CONVERSATION_EDIT';
export const CONVERSATION_TEAM_MOBILE = 'CONVERSATION_TEAM_MOBILE';

//
// Billing
//
export const BILLING_VIEW = 'BILLING_VIEW';
export const BILLING_EDIT = 'BILLING_EDIT';

//
// RAMP -> Rhinogram Account Management Portal (CCR Permissions)
//
export const RAMP_ACCOUNT_CREATE = 'RAMP_ACCOUNT_CREATE';
export const RAMP_ELIGIBILITY_VIEW = 'RAMP_ELIGIBILITY_VIEW';
export const RAMP_SYSTEM_ALERT_CREATE = 'RAMP_SYSTEM_ALERT_CREATE';
export const RAMP_ORGANIZATION_VIEW = 'RAMP_ORGANIZATION_VIEW';
export const RAMP_INTEGRATION_EDIT = 'RAMP_INTEGRATION_EDIT';
export const RAMP_PARTNER_CREATE = 'RAMP_PARTNER_CREATE';
export const RAMP_CSR_CREATE = 'RAMP_CSR_CREATE';
export const RAMP_CREATE = 'RAMP_CREATE';
export const RAMP_IMPORT_PATIENT_CREATE = 'RAMP_IMPORT_PATIENT_CREATE';

//
// Appointment Manager View
//
export const APPOINTMENT_MANAGER_VIEW = 'APPOINTMENT_MANAGER_VIEW';

//
// Prescription Manager View
//
export const PRESCRIPTION_MANAGER_VIEW = 'PRESCRIPTION_MANAGER_VIEW';

//
// Rhinopay View
//
export const RHINOPAY_MANAGER_VIEW = 'RHINOPAY_MANAGER_VIEW';

//
// RhinoBlast Edit
//
export const BULK_MESSAGING_EDIT = 'BULK_MESSAGING_EDIT';

//
// Appointment Reminders / Automated Messages
//
export const AUTOMATED_MESSAGE_VIEW = 'AUTOMATED_MESSAGE_VIEW';
export const AUTOMATED_MESSAGE_EDIT = 'AUTOMATED_MESSAGE_EDIT';
export const AUTOMATED_MESSAGE_DELETE = 'AUTOMATED_MESSAGE_DELETE';

//
// Prescription Notifications
//
export const PRESCRIPTION_NOTIFICATION_VIEW = 'PRESCRIPTION_NOTIFICATION_VIEW';
export const PRESCRIPTION_NOTIFICATION_EDIT = 'PRESCRIPTION_NOTIFICATION_EDIT';
export const PRESCRIPTION_NOTIFICATION_DELETE = 'PRESCRIPTION_NOTIFICATION_DELETE';

//
// Assignments
//
export const ASSIGNMENT_SELF_CREATE = 'ASSIGNMENT_SELF_CREATE';
export const ASSIGNMENT_GROUP_CREATE = 'ASSIGNMENT_GROUP_CREATE';
export const ASSIGNMENT_MEMBER_CREATE = 'ASSIGNMENT_MEMBER_CREATE';

//
// Conversations
//
export const CONVERSATION_ASSIGNMENT_EDIT = 'CONVERSATION_ASSIGNMENT_EDIT';
export const CONVERSATION_CLOSE_DEFAULT = 'CONVERSATION_CLOSE_DEFAULT';
export const CONVERSATION_CLOSE_MEMBER = 'CONVERSATION_CLOSE_MEMBER';
export const CONVERSATION_MEMBER_ASSIGNMENT_EDIT = 'CONVERSATION_MEMBER_ASSIGNMENT_EDIT';

//
// Roles / Permissions
//
export const ROLE_VIEW = 'ROLE_VIEW';
export const ROLE_CREATE = 'ROLE_CREATE';
export const ROLE_EDIT = 'ROLE_EDIT';
export const ROLE_DELETE = 'ROLE_DELETE';

//
// Analytics
//
export const ANALYTICS_VIEW = 'ANALYTICS_VIEW';

//
// Audit Log
//
export const AUDIT_LOG_VIEW = 'AUDIT_LOG_VIEW';

//
// Offices
//
export const OFFICE_VIEW = 'OFFICE_VIEW';
export const OFFICE_EDIT = 'OFFICE_EDIT';
export const OFFICE_CREATE = 'OFFICE_CREATE';
export const OFFICE_DELETE = 'OFFICE_DELETE';

// SELECT, SAVE & SEND CONTENT
export const CONTENT_SELECT_AND_SAVE_EDIT = 'CONTENT_SELECT_AND_SAVE_EDIT';
export const CONTENT_SELECT_AND_SAVE_DELETE = 'CONTENT_SELECT_AND_SAVE_DELETE';
// NOTE: THESE PERMISSIONS EXIST IN RHINOAPI AND DB. THEY ARE BEING HIDDEN TO USERS UNTIL THE FEATURES ARE RELEASED ON THE FRONT-END.
// export const CONTENT_SEND_TO_EHR = 'CONTENT_SEND_TO_EHR_EDIT';
// export const CONTENT_SHARE_WITH_MEMBERS = 'CONTENT_SHARE_WITH_MEMBERS_EDIT';

// EDIT CONSENT
export const CONTACT_HIPAA_CONSENT_EDIT = 'CONTACT_HIPAA_CONSENT_EDIT';
export const CONTACT_MARKETING_CONSENT_EDIT = 'CONTACT_MARKETING_CONSENT_EDIT';
export const CONTACT_RHINOPAY_CONSENT_EDIT = 'CONTACT_RHINOPAY_CONSENT_EDIT';

//
// Video
//
export const VIDEO_EDIT = 'VIDEO_EDIT';

//
// Call
//
export const CALL_EDIT = 'CALL_EDIT';

//
// RhinoForm
//
export const RHINOFORMS_ADMIN = 'RhinoForms Admin';
export const FORM_DELETE = 'FORM_DELETE';
export const FORM_EDIT = 'FORM_EDIT';
export const FORM_VIEW = 'FORM_VIEW';

//
// Appointment Data Types
//
export const APPOINTMENT_TYPE_VIEW = 'APPOINTMENT_TYPE_VIEW';
export const APPOINTMENT_TYPE_EDIT = 'APPOINTMENT_TYPE_EDIT';

//
// Prescription Data
//
export const PRESCRIPTION_DATA_VIEW = 'PRESCRIPTION_DATA_VIEW';
export const PRESCRIPTION_DATA_EDIT = 'PRESCRIPTION_DATA_EDIT';

//
// Routing Manager
//
export const ROUTING_MANAGER_VIEW = 'ROUTING_MANAGER_VIEW';
export const ROUTING_MANAGER_EDIT = 'ROUTING_MANAGER_EDIT';
export const ROUTING_MANAGER_DELETE = 'ROUTING_MANAGER_DELETE';

// Limited Provider
export const LIMITED_PROVIDER_ROLE = 'Limited Provider';
