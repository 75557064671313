import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, connect } from 'react-redux';
import {
  Button,
  Icon,
  LoaderCircle,
  UtilityListItem,
  UtilityList,
} from 'rhinostyle';
import * as ThreadReducer from '../reducers/threadReducer';
import { formatFilename } from '../helpers/StringHelpers';
import { VCARD_FILE_TYPE } from '../constants/AppConstants';

const MessageAttachments = (props) => {
  const dispatch = useDispatch();
  const {
    activeUpload,
    fileUploadPostProcessing,
    attachments,
    sharelinkFiles,
    messageForms,
    fileProgress,
  } = props;

  const allFiles = [...attachments, ...sharelinkFiles, ...messageForms];

  const renderAttachmentItem = (attachment) => {
    if (fileUploadPostProcessing.includes(attachment.key)) {
      return <><Icon icon="attachment" />&nbsp; Processing file...</>;
    }
    if (attachment.type === VCARD_FILE_TYPE) {
      return <><Icon icon="vcard" />&nbsp; {formatFilename(attachment.name)}&nbsp;&nbsp;</>;
    }
    if (attachment.formId) {
      return <><Icon icon="signature" />&nbsp; {formatFilename(attachment.title)}&nbsp;&nbsp;</>;
    }
    return <><Icon icon="attachment" />&nbsp; {formatFilename(attachment.name)}&nbsp;&nbsp;</>;
  };

  const renderAttachmentLink = (attachment, key) => (
    <UtilityListItem key={key} className="u-text-small">
      <Button
        reset
        className="u-text-primary"
        onClick={() => dispatch(ThreadReducer.handleRemoveFile(attachment))}
        key={attachment.key}
        data-cypress="message-attachment"
      >
        {renderAttachmentItem(attachment)}
        <span className="u-text-muted"><Icon size="small" icon="close" /></span>
      </Button>
    </UtilityListItem>
  );

  const renderLoader = () => (
    <div className="convo__message__compose__attachments__loader">
      <LoaderCircle size="xsmall" className="u-text-primary" />&nbsp;&nbsp;&nbsp;<span className="u-text-small u-text-primary">{ fileProgress }</span>
    </div>
  );
  if (activeUpload || allFiles.length > 0) {
    return (
      <div className="convo__message__compose__attachments">
        {allFiles.length > 0 && (
          <UtilityList className="u-m-b-0">
            {allFiles?.map(renderAttachmentLink)}
          </UtilityList>
        )}
        {activeUpload ? renderLoader() : null}
      </div>
    );
  } return null;
};

MessageAttachments.propTypes = {
  activeUpload: PropTypes.bool,
  fileUploadPostProcessing: PropTypes.array,
  attachments: PropTypes.array,
  sharelinkFiles: PropTypes.array,
  messageForms: PropTypes.array,
  fileProgress: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {
    activeUpload,
    attachments,
    sharelinkFiles,
    messageForms,
    fileProgress,
  } = state.thread;
  return {
    activeUpload,
    fileUploadPostProcessing: state.ui.fileUploadPostProcessing,
    attachments,
    sharelinkFiles,
    messageForms,
    fileProgress,
  };
};
export default connect(mapStateToProps)(MessageAttachments);
