import React from 'react';
import { Button, Icon } from 'rhinostyle';
import cx from 'classnames';

const FormActionButton = (props) => {
  const { icon, label, dataCypress, toggleButton, className } = props;
  const appPanelClasses = cx('rhinoform__action-button ', {
    'rhinoform__action-button--delete': icon === 'trash',
    [`${className}`]: className,
  });
  return (
    <div className={appPanelClasses}>
      <Button
        type="link"
        onClick={toggleButton}
        data-cypress={dataCypress}
        iconOnly
      >
        <Icon
          icon={icon}
          className="rhinoform__action-button__icon"
        />
      </Button>
      {label && (<div className="rhinoform__action-button__label">{label}</div>)}
    </div>
  );
};

export default FormActionButton;
