/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LoaderCircle } from 'rhinostyle';
import { getCurrentVideo } from '../selectors/rhinovideoSelectors';
import { rhinovideoAudioStreamId, rhinovideoVideoStreamId } from '../constants/RhinovideoConstants';
import { getAudioContext } from '../services/RhinovideoRoomService';
import { getIsWebkitAudioWorkaroundNeeded } from '../helpers/BrowserHelpers';

const RhinovideoStream = (props) => {
  const { participantSid, participants, pinnedStream, isHidden, participantsInfo } = props;
  const participantInfo = participantsInfo?.[participantSid];
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const participant = participants[participantSid];
  const isCameraEnabled = !participant.cameraInterrupted && participant.videoTrack?.isTrackEnabled;
  const showLoader = participant.streamLoading || !participantInfo;
  const isPinned = pinnedStream?.sid === participantSid;
  const isWebkitAudioWorkaroundNeeded = getIsWebkitAudioWorkaroundNeeded();

  useEffect(() => {
    if (participant.audioTrack && !participant.isCurrentUser) {
      if (!isWebkitAudioWorkaroundNeeded) {
        audioRef.current.srcObject = new MediaStream([participant.audioTrack.track.mediaStreamTrack]);
      } else {
        attachAudioTrack(participant.audioTrack.track.mediaStreamTrack);
      }
    }
  }, [participant.audioTrack]);

  function attachAudioTrack(mediaStreamTrack) {
    const { audioContext, gainNode } = getAudioContext();
    const sourceNode = audioContext.createMediaStreamSource(new MediaStream([mediaStreamTrack]));
    sourceNode.connect(gainNode);
  }

  return (
    <div className={`rhinovideo__participant ${isPinned ? 'is-pinned' : 'is-thumbnail'}  ${isHidden ? 'is-hidden' : ''}`}>
      <video
        id={`${rhinovideoVideoStreamId}${participant.sid}`}
        className={`rhinovideo__participant-stream ${showLoader || !isCameraEnabled ? 'u-opacity-none' : ''}`}
        autoPlay
        muted
        playsInline
        ref={videoRef}
      />
      <img className={`rhinovideo__participant-avatar ${showLoader || isCameraEnabled ? 'u-opacity-none' : ''}`} alt="" src={participantInfo?.avatarUrl} />
      <audio
        id={`${rhinovideoAudioStreamId}${participant.sid}`}
        muted={participant.isCurrentUser || isWebkitAudioWorkaroundNeeded ? 'muted' : ''}
        autoPlay
        ref={audioRef}
        track=""
        className="u-opacity-none"
      />
      {showLoader && <span className="rhinovideo__stream-loading"><LoaderCircle size={isPinned ? 'large' : 'small'} /></span>}
    </div>
  );
};

RhinovideoStream.propTypes = {
  participantSid: PropTypes.string.isRequired,
  participantsInfo: PropTypes.object,
  participants: PropTypes.object.isRequired,
  pinnedStream: PropTypes.object,
  isHidden: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const currentVideo = getCurrentVideo(state);

  return {
    participantsInfo: currentVideo.participantsInfo,
    participants: state.rhinovideo.participants,
    pinnedStream: state.rhinovideo.pinnedStream,
  };
};
export default connect(mapStateToProps)(RhinovideoStream);
