import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupById, getUserById } from '../../selectors/routingSelectors';
import { fetchUserById } from '../../reducers/userReducer';
import { fetchGroupById } from '../../reducers/groupReducer';

const Mention = (props) => {
  const dispatch = useDispatch();

  const { userId, groupId } = props;
  const user = useSelector((state) => getUserById(state, { userId }));
  const group = useSelector((state) => getGroupById(state, { groupId }));
  useEffect(() => {
    if (userId) {
      dispatch(fetchUserById(userId));
    }
  }, [userId]);

  useEffect(() => {
    if (groupId) {
      dispatch(fetchGroupById(groupId));
    }
  }, [groupId]);

  const formattedUserName = user ? `${user.firstName || ''} ${user.lastName || ''}` : null;
  const formattedGroupName = group ? `${group.name || ''}` : null;

  return (
    <span
      className="mention__variable-inline"
      spellCheck={false}
    >
      @{formattedUserName || formattedGroupName || 'All'}
    </span>

  );
};

export default Mention;
