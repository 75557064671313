import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { Alert, Button, CheckboxGroup, Checkbox } from 'rhinostyle';
import { LocationHelpers } from '../helpers';
import rhinoLogoReversed from '../assets/images/rhinogram-logo-reversed.svg';

import LoginWrapper from './LoginWrapper';
import { PASSWORD_EXPLANATION_MESSAGE } from '../constants/AppConstants';

const Login = (props) => {
  const history = useHistory();
  const {
    loginEmail,
    password,
    newPassword,
    confirmPassword,
    loginEmailRef,
    passwordRef,
    newPasswordRef,
    confirmPasswordRef,
    authMode,
    authError,
    loading,
    logoutType,
    handleForgotPasswordClick,
    handleUpdatePassword,
    handleChange,
    handleChangeSaveLoginEmail,
    saveLoginEmail,
    handleKeyPress,
    handleLogin,
    isUpdatePasswordInProgress,
  } = props;

  let content = null;
  const loginEmailClasses = cx('app-login__floating-label__input form__control form__control--large', {
    'has-value': loginEmail,
  });
  const { legacy } = LocationHelpers.getQueryParams(window.location.search);
  if (authMode === 'forgotPassword') {
    handleForgotPasswordClick();
  } else if (authMode === 'updatePassword') {
    const newPasswordClasses = cx('app-login__floating-label__input form__control form__control--large', {
      'has-value': newPassword,
    });

    const confirmPasswordClasses = cx('app-login__floating-label__input form__control form__control--large', {
      'has-value': confirmPassword,
    });

    content = (
      <>
        <p className="app-login__text u-m-b-large">
          Please type a new password below. Click &#8220;Save and Continue&#8221; to complete your password change. {PASSWORD_EXPLANATION_MESSAGE}.
        </p>
        <div className="app-login__floating-label u-m-b-large">
          <input
            id="newPassword"
            name="newPassword"
            type="password"
            className={newPasswordClasses}
            onKeyPress={handleKeyPress}
            onChange={handleChange}
            ref={newPasswordRef}
          />
          <label htmlFor="newPassword" className="app-login__floating-label__label">
            new password
          </label>
        </div>
        <div className="app-login__floating-label u-m-b-large">
          <input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            className={confirmPasswordClasses}
            onKeyPress={handleKeyPress}
            onChange={handleChange}
            ref={confirmPasswordRef}
          />
          <label htmlFor="confirmPassword" className="app-login__floating-label__label">
            confirm password
          </label>
        </div>
        <Button size="large" loading={isUpdatePasswordInProgress} onClick={handleUpdatePassword} type="outline-reversed" block>
          Save and Continue
        </Button>
        {authError && authError.data ? (
          <Alert solid className="u-m-t-large u-text-center" type="danger">
            {authError.data.message}
          </Alert>
        ) : null}
      </>
    );
  } else if (!legacy) {
    return (
      <div className="app-login-wrapper">
        <div className="app-login" data-vh="100">
          <div className="login-redirect__container">
            {authError && authError.data ? (
              <Alert solid type="danger" className="u-m-b-large u-text-center">
                Contact your topsOrtho administrator to restore your access to Rhinogram
              </Alert>
            ) : null}
            <div className="u-hidden">
              <input
                id="username"
                name="loginEmail"
                type="email"
                autoCapitalize="off"
                autoCorrect="off"
                onKeyPress={handleKeyPress}
                onChange={handleChange}
                ref={loginEmailRef}
                defaultValue={loginEmail}
              />
              <input id="password" name="password" type="password" onKeyPress={handleKeyPress} onChange={handleChange} ref={passwordRef} />
              <Button size="large" loading={loading} onClick={handleLogin} data-cypress="loginButton">
                Log In
              </Button>
            </div>
            <div className="app-login__logo">
              <img src={rhinoLogoReversed} alt="Rhinogram" />
            </div>
            <div className="login-redirect__header__title">Log In page moved</div>
            <div>Hi there, the login page has moved. Click below to login.</div>
            <div>Also, be sure to bookmark the new page for future reference.</div>
            <Button className="u-m-t-large" onClick={() => history.push('/auth')} type="outline-reversed">
              Log In Here
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    const passwordClasses = cx('app-login__floating-label__input form__control form__control--large', {
      'has-value': password,
    });

    content = (
      <>
        {logoutType ? (
          <Alert solid className="u-m-b-large u-text-center" type="danger">
            Your session has expired. Please log in.
          </Alert>
        ) : null}
        <div className="app-login__floating-label u-m-b-large">
          <input
            id="username"
            name="loginEmail"
            type="email"
            autoCapitalize="off"
            autoCorrect="off"
            className={loginEmailClasses}
            onKeyPress={handleKeyPress}
            onChange={handleChange}
            ref={loginEmailRef}
            defaultValue={loginEmail}
          />
          <label htmlFor="loginEmail" className="app-login__floating-label__label">
            email
          </label>
        </div>
        <div className="app-login__floating-label u-m-b-large">
          <input id="password" name="password" type="password" className={passwordClasses} onKeyPress={handleKeyPress} onChange={handleChange} ref={passwordRef} />
          <label htmlFor="password" className="app-login__floating-label__label">
            password
          </label>
        </div>

        <div className="u-m-b">
          <Button size="large" loading={loading} onClick={handleLogin} type="outline-reversed" data-cypress="loginButton" block>
            Log In
          </Button>
        </div>

        <div className="row">
          <div className="column-6">
            <CheckboxGroup inline>
              <Checkbox className="rhinobox--contrast" name="saveLoginEmail" onChange={handleChangeSaveLoginEmail} isChecked={saveLoginEmail} label="Remember me" />
            </CheckboxGroup>
          </div>
          <div className="column-6 u-text-right">
            <Button reset onClick={handleForgotPasswordClick} className="app-login__secondary-button">
              Forgot password?
            </Button>
          </div>
        </div>

        {authError && authError.data && (
          <Alert solid type="danger" className="u-m-t-large u-text-center">
            {authError.data.message}
          </Alert>
        )}
      </>
    );
  }

  return <LoginWrapper>{content}</LoginWrapper>;
};

Login.propTypes = {
  authMode: PropTypes.string,
  authError: PropTypes.object,
  handleKeyPress: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeSaveLoginEmail: PropTypes.func.isRequired,
  saveLoginEmail: PropTypes.bool.isRequired,
  handleLogin: PropTypes.func.isRequired,
  handleForgotPasswordClick: PropTypes.func.isRequired,
  handleUpdatePassword: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  logoutType: PropTypes.string.isRequired,
  loginEmail: PropTypes.string,
  password: PropTypes.string,
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  loginEmailRef: PropTypes.func.isRequired,
  passwordRef: PropTypes.func.isRequired,
  newPasswordRef: PropTypes.func.isRequired,
  confirmPasswordRef: PropTypes.func.isRequired,
  isUpdatePasswordInProgress: PropTypes.bool.isRequired,
};

export default Login;
