import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { UtilitySystem } from 'rhinostyle';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import MultiOrgContainer from '../containers/MultiOrgContainer';
import { userHasAnyOfPermissions, isCCR } from '../helpers/UserHelpers';

import {
  APPOINTMENT_MANAGER_VIEW,
  BULK_MESSAGING_EDIT,
  FORM_VIEW,
  PRESCRIPTION_MANAGER_VIEW,
  RHINOPAY_MANAGER_VIEW,
  ROUTING_MANAGER_VIEW,
} from '../constants/UserPermissionsConstants';

function ManagersMenu(props) {
  const {
    currentUser,
    isRhinopayEnabled,
    handleNavigationClick,
    organizations,
    settingsRef,
    history,
    isRhinoformEnabled,
  } = props;

  const isAppointmentManagerEnabled = !!props.currentOrganization?.isAppointmentManagerEnabled;
  const isRoutingManagerEnabled = !!props.currentOrganization?.isRoutingManagerEnabled;
  const isPrescriptionManagerEnabled = !!props.currentOrganization?.isPrescriptionManagerEnabled;
  const isBlastManagerVisible = !!props.currentOrganization?.isBulkMessagingEnabled;

  const menuOptions = [
    {
      title: 'Appointment Manager',
      id: 'nav-settings-appointment-manager',
      to: '/managers/appointment-manager',
      dataCypress: 'appointmentManagerOption',
      isVisible: userHasAnyOfPermissions([APPOINTMENT_MANAGER_VIEW]) && isAppointmentManagerEnabled,
    },
    {
      id: 'nav-settings-blast',
      title: 'Blast Manager',
      to: '/managers/blast-manager',
      dataCypress: 'blastManagerOption',
      isVisible: (userHasAnyOfPermissions([BULK_MESSAGING_EDIT]) || isCCR(currentUser)) && isBlastManagerVisible,
    },
    {
      title: 'RhinoForms Manager',
      id: 'nav-settings-rhinoforms-manager',
      to: '/managers/rhinoforms-manager',
      dataCypress: 'rhinoformsManagerOption',
      isVisible: userHasAnyOfPermissions([FORM_VIEW]) && isRhinoformEnabled,
    },
    {
      title: 'RhinoPay Manager',
      id: 'nav-settings-payment-requests',
      to: '/managers/payment-requests',
      dataCypress: 'rhinopayManagerOption',
      isVisible: userHasAnyOfPermissions([RHINOPAY_MANAGER_VIEW]) && isRhinopayEnabled,
    },
    {
      id: 'nav-settings-routing-manager',
      title: 'Routing Manager',
      to: '/managers/routing-manager',
      dataCypress: 'routingManagerOption',
      isVisible: userHasAnyOfPermissions([ROUTING_MANAGER_VIEW]) && isRoutingManagerEnabled,
    },
    {
      id: 'nav-settings-prescriptions',
      title: 'Prescription Manager',
      to: '/managers/prescriptions',
      dataCypress: 'prescriptionManagerOption',
      isVisible: userHasAnyOfPermissions([PRESCRIPTION_MANAGER_VIEW]) && isPrescriptionManagerEnabled,
    },
  ];

  const renderMenuListItems = () =>
    menuOptions
      .filter((option) => option.isVisible)
      .map((option) => (
        <li key={option.id} className="app-navigation__nav__sub">
          <NavLink
            id={option.id}
            to={option.to}
            data-cypress={option.dataCypress}
            onClick={handleNavigationClick}
            className="app-navigation__nav__button"
            activeClassName={UtilitySystem.config.classes.active}
          >
            <span>{option.title}</span>
          </NavLink>
        </li>
      ));

  return (
    <nav id="app-navigation" className="app-navigation">
      <div className="app-navigation__container">
        <div className="app-navigation__top">
          <ul className="app-navigation__nav" ref={settingsRef}>
            <li className="app-navigation__nav__header">
              <span className="app-navigation__nav__button__text">MANAGERS</span>
            </li>
            <div className="app-navigation__list-item__divider" />
            {renderMenuListItems()}
          </ul>
        </div>
        <div className="app-navigation__bottom">
          {currentUser?.organization && (
            <div className="app-navigation__org">
              {organizations[currentUser.organization].name}
              <span>Organization{isCCR(currentUser) && ` (#${organizations[currentUser.organization].id})`}</span>
            </div>
          )}
          <MultiOrgContainer history={history} />
        </div>
      </div>
    </nav>
  );
}
ManagersMenu.propTypes = {
  currentUser: PropTypes.object,
  currentOrganization: PropTypes.object,
  handleNavigationClick: PropTypes.func,
  isRhinopayEnabled: PropTypes.bool,
  organizations: PropTypes.object,
  settingsRef: PropTypes.object,
  history: PropTypes.object,
  isRhinoformEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { billing, pay, form } = state;
  return {
    currentUser: getLoggedInUser(state),
    currentOrganization: getCurrentOrg(state),
    billing: billing.billing,
    isRhinopayEnabled: pay.merchant.isRhinopayEnabled,
    isRhinoformEnabled: !!form.org?.isRhinoformEnabled,
  };
};

export default connect(mapStateToProps, {})(ManagersMenu);
