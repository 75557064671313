import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, Pill, UtilityInlineGrid } from 'rhinostyle';
import TagModalContainer from '../containers/TagModalContainer';
import {
  TYPE_TAG_CUSTOM,
  TYPE_TAG_DEPARTMENTS,
  TYPE_TAG_LOCATIONS,
  TYPE_TAG_ROLES,
} from '../constants/Types';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { TAG_CREATE } from '../constants/UserPermissionsConstants';
import { syncTags } from '../helpers/TagHelpers';

class TagsGroup extends Component {
  state = {
    isModalOpen: false,
    mode: '',
    tagId: -1,
  }

  closeModal = () => {
    this.setState({ isModalOpen: false });
  }

  handleCreateRequest = () => {
    this.setState({ isModalOpen: true, mode: 'create' });
  }

  handleEditRequest = (e, tagId) => {
    this.setState({
      isModalOpen: true,
      mode: 'edit',
      tagId,
    });
  };

  renderTags(name, tagIds) {
    return (
      <>
        <h4>{name}</h4>
        {tagIds.length > 0 ? (
          <UtilityInlineGrid size="regular" dataCypress={`${name}Grid`}>{tagIds.map(this.renderTag)}</UtilityInlineGrid>
        ) : (
          this.renderEmptyTag()
        )}
      </>
    );
  }

  renderTag = (id, key) => {
    const tag = this.props.tags[id];
    const label = `#${tag.name}`;
    const handleClick = (e) => {
      if (this.props.onClick) this.props.onClick(e, id);
      else this.handleEditRequest(e, id);
    };
    const type = this.props.activeTagIds.includes(id) ? 'primary' : 'default';

    return (
      <Pill
        disabled={this.props.tagsDisabled}
        hideClose
        key={key}
        label={label}
        onClick={handleClick}
        type={type}
        dataCypress={label}
      />
    );
  };

  renderEmptyTag = () => (
    <p className="u-text-muted u-text-small">No tags for this category.</p>
  );

  render() {
    const { tags, tagIds } = this.props;
    const syncedTags = syncTags(tags, tagIds);
    const sorted = Object.entries(syncedTags).sort((a, b) => a[1].name.toLowerCase().localeCompare(b[1].name.toLowerCase()));
    const getIdColumn = sorted.map((el) => parseInt(el[0], 10));
    const customTagIds = getIdColumn.filter((id) => syncedTags[id].typeId === TYPE_TAG_CUSTOM);
    const departmentTagIds = getIdColumn.filter((id) => syncedTags[id].typeId === TYPE_TAG_DEPARTMENTS);
    const locationTagIds = getIdColumn.filter((id) => syncedTags[id].typeId === TYPE_TAG_LOCATIONS);
    const roleTagIds = getIdColumn.filter((id) => syncedTags[id].typeId === TYPE_TAG_ROLES);

    return (
      <>
        <TagModalContainer
          isOpen={this.state.isModalOpen}
          closeModal={this.closeModal}
          mode={this.state.mode}
          tagId={this.state.tagId}
        />

        <div className="box">
          {this.props.title && (
          <div className="box__title-wrapper--flex">
            {userHasAnyOfPermissions([TAG_CREATE]) && !this.props.isCreateLinkHidden && (
              <div className="box__title__flex__trigger">
                <Button
                  reset
                  className="u-text-small u-text-primary"
                  data-cypress="createNewTag"
                  onClick={this.handleCreateRequest}
                >
                  Create New Tag
                </Button>
              </div>
            )}
            <div className="box__title__content-flex">
              <div className="box__title">{this.props.title}</div>
              <div className="box__subtitle">Improve search and filtering by including tags</div>
            </div>
          </div>
          )}
          {this.renderTags('Location', locationTagIds)}
          {this.renderTags('Department', departmentTagIds)}
          {this.renderTags('Role', roleTagIds)}
          {this.renderTags('Custom', customTagIds)}
        </div>
      </>
    );
  }
}

TagsGroup.defaultProps = {
  activeTagIds: [],
};

TagsGroup.propTypes = {
  activeTagIds: PropTypes.array,
  onClick: PropTypes.func,
  tagsDisabled: PropTypes.bool,
  tags: PropTypes.object.isRequired,
  tagIds: PropTypes.array,
  title: PropTypes.string,
  isCreateLinkHidden: PropTypes.bool,
};

export default TagsGroup;
