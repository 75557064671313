import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Button } from 'rhinostyle';

import { toggleScreenSharePause } from '../reducers/rhinovideoReducer';

const RhinovideoPauseScreenShareButton = (props) => {
  const { isScreenSharePaused, isButtonDisabled } = props;
  const dispatch = useDispatch();
  const handleToggle = () => {
    dispatch(toggleScreenSharePause());
  };

  return (
    <div>
      <Button
        type="link"
        size="large"
        iconOnly
        title={isScreenSharePaused ? 'Resume Share' : 'Pause Share'}
        onClick={handleToggle}
        className="rhinovideo__action-bar__button"
        disabled={isButtonDisabled}
      >
        <Icon icon={isScreenSharePaused ? 'video-resume' : 'video-pause'} />
      </Button>
      <div className="rhinovideo__action-bar__label">
        <span>{isScreenSharePaused ? 'Resume' : 'Pause'}</span>
      </div>
    </div>
  );
};

RhinovideoPauseScreenShareButton.propTypes = {
  isScreenSharePaused: PropTypes.bool.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { rhinovideo } = state;

  return ({
    isScreenSharePaused: rhinovideo.isScreenSharePaused,
    isButtonDisabled: rhinovideo.isButtonDisabled,
  });
};

export default connect(mapStateToProps)(RhinovideoPauseScreenShareButton);
