import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { useGetUserAppointmentsQuery } from '../services/appointmentService';
import { useInboxParams } from '.';
import { getLoggedInUserOrganization } from '../selectors/userSelectors';
import { DateHelpers, AppointmentHelpers } from '../helpers';

const useContactAppointments = (isUpcoming = true) => {
  const userOrganization = useSelector((state) => getLoggedInUserOrganization(state));
  const { userId } = useInboxParams();
  const query = useGetUserAppointmentsQuery({ userId, orgId: userOrganization.id, isUpcoming }, { skip: !userOrganization.integrationPartnerTypeId });
  const { isLoading, data } = query;
  const appointments = shapeUserAppointments(data?.results || []);
  return { isLoading, appointments, total: data?.total };

  function shapeUserAppointments(appointmentData) {
    const observesDst = true;
    const timeZone = moment.tz.guess();
    if (appointmentData.length > 0) {
      return appointmentData
        .map((appointment) => {
          const formattedAppointmentTime = DateHelpers.convertDateInTimeZone(
            appointment.startDate,
            appointment.timeZone ?? timeZone,
            appointment.observesDst ?? observesDst,
          );
          return {
            startTime: AppointmentHelpers.formatAppointmentTime(DateHelpers.convertDateInTimeZone(
              appointment.startDate,
              appointment.timeZone ?? timeZone,
              appointment.observesDst ?? observesDst,
            )),
            appointmentStatusTypeId: appointment.appointmentStatusTypeId,
            id: appointment.id,
            appointmentStatusTimestamp: DateHelpers.formatTimestamp(
              appointment.appointmentStatusTimestamp || appointment.lastUpdatedAt,
              'MM/DD/YY h:mm a z',
            ),
            externalId: appointment.externalId,
            formattedAppointmentTime,
            officeId: appointment.officeId,
            appointmentTypeId: appointment.appointmentTypeId,
            externalProviderId: appointment.externalProviderId,
            externalAppointmentStatusId: appointment.externalAppointmentStatusId,
          };
        });
    } else {
      return [];
    }
  }
};

export default useContactAppointments;
