import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Button, Icon } from 'rhinostyle';
import { isWebRTCSupported, isMobile } from '../helpers/BrowserHelpers';
import { setRhinocallPreconfig, checkAudioPermission, getLastMemberNumber } from '../reducers/rhinocallReducer';
import { getContactCallStatus } from '../selectors/rhinocallSelector';
import { getActiveMemberVideo } from '../selectors/rhinovideoSelectors';
import { toggleModal } from '../reducers/threadReducer';
import { fetchChannels } from '../reducers/channelReducer';
import { MODAL_OPTIONS } from '../constants/ThreadConstants';

const RhinocallStartButton = (props) => {
  const dispatch = useDispatch();
  const {
    contactCallStatus,
    memberHasActiveVideo,
    isHangingUp,
    isRhinocallModalVisible,
  } = props;

  const handleToggleRhinocallModal = async () => {
    if (isMobile()) {
      return dispatch(setRhinocallPreconfig());
    } if (!isWebRTCSupported()) {
      return toggleModal(MODAL_OPTIONS.browserNotSupported);
    } return dispatch(checkAudioPermission())
      .catch(() => toggleModal(MODAL_OPTIONS.audioAccess));
  };

  useEffect(() => {
    dispatch(fetchChannels());
    if (isMobile()) {
      dispatch(getLastMemberNumber());
    }
  }, []);

  return (
    <Button
      disabled={!!contactCallStatus || memberHasActiveVideo || isHangingUp || isRhinocallModalVisible}
      className={`convo__start-button ${contactCallStatus ? 'u-muted-outline' : ''}`}
      onClick={handleToggleRhinocallModal}
      size="small"
      type="outline"
      data-cypress="callStartButton"
    >
      <Icon size="large" icon="phone" />
      <span className="u-hidden-xsmall">&nbsp;{contactCallStatus || 'Call Now'}</span>
    </Button>
  );
};

RhinocallStartButton.propTypes = {
  contactCallStatus: PropTypes.string,
  memberHasActiveVideo: PropTypes.bool,
  isHangingUp: PropTypes.bool,
  isRhinocallModalVisible: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  contactCallStatus: getContactCallStatus(state),
  memberHasActiveVideo: getActiveMemberVideo(state)?.length > 0,
  isHangingUp: state.rhinocall.isHangingUp,
  isRhinocallModalVisible: state.rhinocall.isRhinocallModalVisible,
});

export default connect(mapStateToProps)(RhinocallStartButton);
