import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { UtilityList, UtilityListItem } from 'rhinostyle';
import { daysDict } from '../constants/BusinessHoursConstants';

const BusinessHoursGrouped = ({ businessHours, timeZone }) => {
  const groupBusinessHours = () => {
    const hoursGrouped = { open: {}, closed: [] };
    let businessHoursClone = [];

    // Shift Sunday to end
    // Prefer "Sat, Sun Closed All Day" over "Sun, Sat Closed All Day"
    if (businessHours.length > 0) {
      businessHoursClone = [...businessHours];
      businessHoursClone.push(businessHoursClone.shift());
    }

    businessHoursClone.forEach((day) => {
      const dayAbbreviated = moment(daysDict[day.day].title, 'dddd').format('ddd');

      if (day.open) {
        const key = day.from + day.to;

        if (!Object.prototype.hasOwnProperty.call(hoursGrouped.open, key)) {
          hoursGrouped.open[key] = [];
        }

        hoursGrouped.open[key].push(dayAbbreviated);
      } else {
        hoursGrouped.closed.push(dayAbbreviated);
      }
    });

    return hoursGrouped;
  };

  const groupedBusinessHours = groupBusinessHours();

  const renderBusinessHoursOpen = () =>
    Object.keys(groupedBusinessHours.open).map((hourGroup) => {
      const daysOpenList = groupedBusinessHours.open[hourGroup].join(', ');
      const hoursFrom = moment(hourGroup.substr(0, 8), 'HH:mm:ss').format('h:mm a');
      const hoursTo = moment(hourGroup.substr(8), 'HH:mm:ss').format('h:mm a');
      const timeZoneAbbrev = moment.tz(timeZone.internalName).format('z');

      return (
        <UtilityListItem data-cypress="daysOpenList" key={hourGroup}>
          {`${daysOpenList} ${hoursFrom}-${hoursTo} (${timeZoneAbbrev})`}
        </UtilityListItem>
      );
    });

  const renderBusinessHoursClosed = () => {
    let daysClosed = null;

    if (groupedBusinessHours.closed.length > 0) {
      const daysClosedList = groupedBusinessHours.closed.join(', ');

      daysClosed = (
        <UtilityListItem data-cypress="daysClosedList" key="closed">
          {`${daysClosedList} Closed All Day`}
        </UtilityListItem>
      );
    }

    return daysClosed;
  };

  return (
    <UtilityList space className="u-m-b-0">
      {renderBusinessHoursOpen()}
      {renderBusinessHoursClosed()}
    </UtilityList>
  );
};

BusinessHoursGrouped.propTypes = {
  businessHours: PropTypes.array.isRequired,
  timeZone: PropTypes.object.isRequired,
};

export default BusinessHoursGrouped;
