import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Icon,
} from 'rhinostyle';
import { AppConstants } from '../../constants';

import { getLoggedInUserOrganization } from '../../selectors/userSelectors';

const MergeEvent = (props) => {
  const { event, userOrganization } = props;

  const {
    fromUserId,
    nonMaster,
    formattedFromUserName: tempFormattedName,
    formattedActiveUserName,
    formattedTimestamp,
  } = event;
  const formattedFromUserName = (tempFormattedName === 'Rhino System' || fromUserId === AppConstants.SYSTEM_USER_ID) ? userOrganization.name : tempFormattedName;
  const eventText = <>Contact <strong>Merged</strong> into</>;
  const mergedBy = formattedFromUserName;
  const { firstName, lastName } = nonMaster;
  const masterContact = formattedActiveUserName;
  const isNonMasterNameAvailable = firstName || lastName;
  const nonMasterContact = firstName && lastName ? `${firstName} ${lastName}` : (firstName || lastName);
  const mergeText = <>{nonMasterContact} <strong>Merged</strong> into </>;
  return (
    <>
      <div className="convo__event__title">
        <Icon bump="up" icon="checkmark" className="convo__event__icon convo__event__icon--complete" />
        {isNonMasterNameAvailable ? mergeText : eventText} {masterContact}&#39;s profile by {mergedBy}
      </div>
      <div className="convo__event__subtext">
        {formattedTimestamp}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userOrganization: getLoggedInUserOrganization(state),
});

MergeEvent.propTypes = {
  userOrganization: PropTypes.object.isRequired,
  event: PropTypes.object,
};
export default connect(mapStateToProps)(MergeEvent);
