import axios from 'axios';
import moment from 'moment-timezone';
import { createSlice } from '@reduxjs/toolkit';

import { UIHelpers } from '../helpers';
import OutboundPostMessageService from '../services/OutboundPostMessageService';
import WebSocketService from '../services/WebSocketService';
import * as UserActionTypes from '../constants/UserActionTypes';
import { SESSION_TIMEOUT_STORAGE_KEY, MODAL_OPTIONS } from '../constants/AppConstants';

// SLICE

const uiSlice = createSlice({
  name: 'UI',
  initialState: {
    profileOpen: false,
    convoOpen: false,
    navigationOpen: false,
    usersTyping: [],
    inboxSections: {},
    error: null,
    formInProgress: false,
    modalFormInProgress: false,
    isSessionTimeoutModalVisible: false,
    isEmailModalOpen: false,
    recordLock: {},
    showSystemAlert: true,
    fileUploadPostProcessing: [],
    hasFormChanged: false,
    isNewFeatureTourVisible: {},
    [MODAL_OPTIONS.bulkTags]: false,
    [MODAL_OPTIONS.bulkMessage]: false,
  },
  reducers: {
    setProfileOpen: (state, action) =>
      ({
        ...state,
        profileOpen: action.payload,
      }),
    setConvoOpen: (state, action) =>
      ({
        ...state,
        convoOpen: action.payload,
      }),
    setNavigationOpen: (state, action) =>
      ({
        ...state,
        navigationOpen: action.payload,
      }),
    addTypingUser: (state, action) =>
      ({
        ...state,
        // fail safe so we don't end up with the same user multiple times or old typing states
        usersTyping: state.usersTyping.concat(action.payload).filter((u) => moment.utc().diff(moment.utc(u.date)) <= WebSocketService.typingTimeout),
      }),
    removeTypingUser: (state, action) =>
      ({
        ...state,
        usersTyping: state.usersTyping.filter((u) => (
          u.typingUserId !== action.payload.typingUserId
        )),
      }),
    setError: (state, action) =>
      ({
        ...state,
        error: action.payload,
        formInProgress: false,
        modalFormInProgress: false,
      }),
    receiveInboxSections: (state, action) =>
      ({
        ...state,
        inboxSections: action.payload,
      }),
    clearRecordLock: (state) =>
      ({
        ...state,
        recordLock: {},
      }),
    handleFormChanges: (state) => ({
      ...state,
      hasFormChanged: true,
    }),
    resetFormChanges: (state) => ({
      ...state,
      hasFormChanged: false,
    }),
    setSystemAlertVisibility: (state, action) =>
      ({
        ...state,
        showSystemAlert: action.payload,
      }),
    setFormInProgress: (state, action) => ({ ...state, formInProgress: action.payload }),
    setModalFormInProgress: (state, action) => ({ ...state, modalFormInProgress: action.payload }),
    setSessionTimeoutModalVisability: (state, action) => ({ ...state, isSessionTimeoutModalVisible: action.payload }),
    toggleEmailModal: (state, action) => ({ ...state, isEmailModalOpen: action.payload }),
    setFileUploadPostProcessingInProgress: (state, action) => ({
      ...state,
      fileUploadPostProcessing: [...state.fileUploadPostProcessing, action.payload.key],
    }),
    receiveWebSocketFileUploadPostProcessing: (state, action) => ({
      ...state,
      fileUploadPostProcessing: state.fileUploadPostProcessing.filter((key) => key !== action.payload.key),
    }),
    disableNewFeatureTourVisibility: (state, action) => ({
      ...state,
      isNewFeatureTourVisible: { ...state.isNewFeatureTourVisible, [action.payload]: false },
    }),
    enableNewFeatureTourVisibility: (state, action) => ({
      ...state,
      isNewFeatureTourVisible: { ...state.isNewFeatureTourVisible, [action.payload]: true },
    }),
    toggleBulkTagModal: (state, action) =>
      ({
        ...state,
        isBulkTagModalOpen: action.payload,
      }),
    toggleBulkMessageModal: (state, action) =>
      ({
        ...state,
        isBulkMessageModalOpen: action.payload,
      }),

    toggleModal: (state, action) => {
      state[action.payload] = !state[action.payload];
    },
    receiveChatMentionReadStatus: (state, action) => {
      const index = state.inboxSections?.inbox?.findIndex((inbox) => inbox.displayName === '@Mentions');
      const mentionInbox = index > -1 && state.inboxSections.inbox[index];
      if (mentionInbox && action.payload.isRead) {
        mentionInbox.unreadChatMentionIds = mentionInbox.unreadChatMentionIds.filter((mentionId) => mentionId !== action.payload.id);
      }
    },
  },
  extraReducers: {
    [UserActionTypes.receiveContactEditFormView]: (state, action) =>
      ({
        ...state,
        recordLock: action.payload.recordLock,
      }),
  },
});

export default uiSlice.reducer;

// ACTIONS

export const {
  addTypingUser,
  clearRecordLock,
  setSystemAlertVisibility,
  receiveInboxSections,
  removeTypingUser,
  setConvoOpen,
  setError,
  setFormInProgress,
  setModalFormInProgress,
  setNavigationOpen,
  setProfileOpen,
  setSessionTimeoutModalVisability,
  toggleEmailModal,
  receiveWebSocketFileUploadPostProcessing,
  setFileUploadPostProcessingInProgress,
  handleFormChanges,
  resetFormChanges,
  disableNewFeatureTourVisibility,
  enableNewFeatureTourVisibility,
  toggleBulkTagModal,
  toggleBulkMessageModal,
  toggleModal,
  receiveChatMentionReadStatus,
} = uiSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

export function fetchInboxSections() {
  return (dispatch) =>
    axios.get('/inboxSections')
      .then((response) => {
        dispatch(receiveInboxSections(response.data));

        // Update native app badge count
        const badgeCount = UIHelpers.getTotalUnreadCount();
        OutboundPostMessageService.postMessage({
          type: 'setBadgeCount',
          data: { badgeCount },
        });
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
}

export function showSessionTimeoutModal() {
  return (dispatch) => {
    dispatch(setSessionTimeoutModalVisability(true));
    localStorage.setItem(SESSION_TIMEOUT_STORAGE_KEY, true);
  };
}

export function hideSessionTimeoutModal() {
  return (dispatch) => {
    dispatch(setSessionTimeoutModalVisability(false));
    localStorage.removeItem(SESSION_TIMEOUT_STORAGE_KEY);
  };
}

export function setSystemAlert(payload) {
  return (dispatch) => dispatch(setSystemAlertVisibility(payload));
}

export function createLock(payload) {
  return axios.post('/writeControl/lock', payload)
    .catch((err) => console.error(err));
}

export function removeLock(payload) {
  return (dispatch) =>
    axios.post('/writeControl/unlock', payload)
      .then(() => {
        dispatch(clearRecordLock());
      })
      .catch((err) => {
        console.error(err.response);

        dispatch(setError(err.response));
      });
}

export const confirmFormChanges = () => (dispatch, getState) => {
  const { hasFormChanged } = getState().ui;
  let willLeave = true;

  if (hasFormChanged) {
    // eslint-disable-next-line no-alert
    willLeave = window.confirm('You\'ve made changes to this page that haven\'t been saved. Are you sure you want to discard them?');
  }
  if (willLeave === true) {
    dispatch(resetFormChanges());
  }
  return willLeave;
};
