import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { axiumLogin } from '../reducers/authReducer';
import PageLoader from '../components/PageLoader';
import rhinoLogoReversed from '../assets/images/rhinogram-logo-reversed.svg';

const AxiumLogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { axiumOrgId } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('Hi there, your user type requires you to login through axiUm.');

  useEffect(() => {
    handleLogin();
  }, []);

  async function handleLogin() {
    const queryParams = new URLSearchParams(location.search);
    const loginPayload = {
      iv: queryParams.get('iv'),
      encryptedPayload: queryParams.get('encryptedPayload'),
    };
    try {
      const { activePatientId, isLimitedProvider, errorMsg } = await dispatch(axiumLogin(axiumOrgId, loginPayload));
      if (errorMsg) {
        setErrorMessage(errorMsg?.data?.message);
      } else if (activePatientId) {
        if (isLimitedProvider) {
          history.push(`/axium/inbox/user/${activePatientId}`);
        } else {
          history.push(`/inbox/all/user/${activePatientId}`);
        }
      } else if (!isLimitedProvider) {
        history.push('/inbox');
      }
    } catch (error) {
      console.error('axiUm Login Error', error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <PageLoader />
    );
  }
  return (
    <div className="app-page__standalone u-bg-primary">
      <div className="axium-login__container">
        <div className="app-login__logo u-m-t-large">
          <img src={rhinoLogoReversed} alt="Rhinogram" />
        </div>
        <div className="axium-login__header">Account Access</div>
        <div>{errorMessage}</div>
        <div>Please return to axiUm to continue.</div>
      </div>
    </div>
  );
};

export default AxiumLogin;
