import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import OrganizationOfficeForm from '../components/OrganizationOfficeForm';
import unsavedChanges from '../components/UnsavedChangesHOC';
import * as OfficeReducer from '../reducers/officeReducer';
import { fetchChannels } from '../reducers/channelReducer';
import { ScrollHelpers, ValidationHelpers, DataHelpers, PhoneHelpers } from '../helpers';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import * as StateReducer from '../reducers/stateReducer';
import * as UploadActions from '../actions/UploadActions';
import { getAutomatedMessageChannelIds } from '../selectors/channelSelectors';

const { REACT_APP_AVATAR_BUCKET: AVATAR_BUCKET } = process.env;

class OrganizationOfficeFormContainer extends Component {
  state = {
    addressHasChanged: false,
    addressId: null,
    city: '',
    errors: {},
    formInProgress: false,
    id: -1,
    isDeleteConfirmationVisible: false,
    mode: 'create',
    name: '',
    pageLoading: !!this.props.match.params.officeId,
    state: -1,
    street1: '',
    street2: '',
    zip: '',
    vCards: [],
    isShowWarning: false,
  };

  componentDidMount() {
    let officeId;
    if (this.props.match.params.officeId) {
      officeId = Number(this.props.match.params.officeId);
    }
    if (this.props.states.length === 0) {
      this.props.fetchStates();
    }
    this.props.fetchOfficeView(officeId);
    this.props.fetchChannels();
  }

  componentDidUpdate(prevProps, prevState) {
    const activeOffice = this.props.offices[this.props.match.params.officeId];
    const updateMode = activeOffice && this.state.id === -1;

    if (updateMode) {
      const {
        id,
        name,
        address: {
          cleanCity: city,
          id: addressId,
          cleanState: state,
          cleanStreet1: street1,
          cleanStreet2: street2,
          cleanZip: zip,
        },
      } = activeOffice;

      if (activeOffice?.vCards) {
        const updatedVCards = activeOffice?.vCards.map((vc) => {
          const {
            cleanCity: vCardCity,
            cleanStreet1: vCardStreet1,
            cleanStreet2: vCardStreet2,
            cleanZip: vCardZip,
            cleanState: vCardState,
            id: vCardAddressId,
          } = vc?.vCardAddress || {};

          const vCard = {
            vCardName: vc.vCardName,
            vCardEmail: vc.vCardEmail,
            logoUrl: vc.logoUrl,
            isActive: false,
            isSameOfficeAddress: false,
            uploadAvatarModalOpen: false,
            vCardChanneIds: vc.vCardChanneIds,
            vCardCity,
            vCardState: this.props.states.find((s) => s.code === vCardState?.toUpperCase()) ? this.props.states.find((s) => s.code === vCardState?.toUpperCase()).id : -1,
            vCardStreet1,
            vCardStreet2,
            vCardZip,
            vCardAddressId,
          };
          return vCard;
        });
        this.setState({ vCards: updatedVCards, isShowWarning: activeOffice?.vCards.length > 0 });
      }

      this.setState({ // eslint-disable-line react/no-did-update-set-state
        addressId,
        city,
        id,
        mode: 'update',
        name,
        state: this.props.states.find((s) => s.code === state.toUpperCase()) ? this.props.states.find((s) => s.code === state.toUpperCase()).id : -1,
        street1,
        street2: street2 || '',
        zip,
      });
    }

    if (this.props.pageLoading !== prevProps.pageLoading) {
      this.setState({ pageLoading: this.props.pageLoading }); // eslint-disable-line react/no-did-update-set-state
    }

    if (!prevState.isDeleteConfirmationVisible && this.state.isDeleteConfirmationVisible) {
      ScrollHelpers.scrollToBottom(this.pageContainer);
    }
  }

  handleAddPhoto = (index) => {
    const vCards = [...this.state.vCards];
    vCards[index].uploadAvatarModalOpen = true;
    this.setState({ vCards });
  }

  handleCloseModal = (index) => {
    const vCards = [...this.state.vCards];
    vCards[index].uploadAvatarModalOpen = false;
    this.setState({ vCards });
  }

  handleDimissWarning = () => {
    this.setState({ isShowWarning: false });
  }

  handleToggleCollapse = (index) => {
    const vCards = [...this.state.vCards];
    const { isActive } = vCards[index];
    vCards[index].isActive = !isActive;
    this.setState({ vCards });
  }

  handleDeleteVCard = (index) => {
    const vCards = [...this.state.vCards];
    const newVCards = [...vCards];
    newVCards.splice(index, 1);
    this.setState({ vCards: newVCards });
  }

  handleCreateVCard = () => {
    const vCards = [...this.state.vCards];
    let hasValidationError = false;
    if (vCards.length > 0) {
      const vCard = vCards[vCards.length - 1];
      if (vCard.vCardName === '') {
        vCards[vCards.length - 1].vCardNameRequiredError = true;
        hasValidationError = true;
      }
      if (vCard.vCardChanneIds.length === 0) {
        vCards[vCards.length - 1].phoneValidationError = true;
        hasValidationError = true;
      }
      const filteredVCard = vCards.filter((v) => v.vCardName === vCard.vCardName);
      if (filteredVCard.length > 1) {
        vCards[vCards.length - 1].vCardUniqueNameRequiredError = true;
        hasValidationError = true;
      }
      this.setState({ vCards });
    }
    if (!hasValidationError) {
      const newVCard = {
        vCardName: '',
        vCardState: -1,
        vCardStreet1: '',
        vCardStreet2: '',
        vCardZip: '',
        vCardCity: '',
        vCardEmail: '',
        logoUrl: '',
        vCardChanneIds: [],
        vCardAddressId: null,
        isSameOfficeAddress: false,
        isActive: true,
        vCardNameRequiredError: false,
        phoneValidationError: false,
        vCardUniqueNameRequiredError: false,
        uploadAvatarModalOpen: false,
      };
      vCards.push(newVCard);
      this.setState({ vCards });
    }
  };

  handleUploadAvatar = (image, sourceFilename, index) =>
    UploadActions.uploadFileFromDataUrl(image, AVATAR_BUCKET, sourceFilename)
      .then((response) => {
        const vCards = [...this.state.vCards];
        vCards[index].logoUrl = response.file;
        this.setState({ vCards });
      })
      .finally(() => {
        const vCards = [...this.state.vCards];
        vCards[index].uploadAvatarModalOpen = false;
        this.setState({ vCards });
      });

  getVCardPayload = () => {
    const vCards = [...this.state.vCards];
    const vCardsPayload = [];
    vCards.forEach((vCard) => {
      const vCardPayload = {
        vCardName: vCard.vCardName,
        vCardEmail: vCard.vCardEmail,
        vCardChanneIds: vCard.vCardChanneIds,
        logoUrl: vCard.logoUrl,
        vCardAddress: {
          dirtyCity: vCard.vCardCity,
          dirtyState: this.props.states.find((s) => s.id === vCard.vCardState).code,
          dirtyStreet1: vCard.vCardStreet1,
          dirtyStreet2: vCard.vCardStreet2,
          dirtyZip: vCard.vCardZip,
        },
      };
      if (vCard.vCardAddressId) {
        vCardPayload.vCardAddress.id = vCard.vCardAddressId;
      }
      vCardsPayload.push(vCardPayload);
    });
    return vCardsPayload;
  }

  getOfficePayload = (isOfficeVCardEnabled = false) => {
    const officePayload = {
      name: this.state.name,
      address: {
        dirtyCity: this.state.city,
        dirtyState: this.props.states.find((s) => s.id === this.state.state).code,
        dirtyStreet1: this.state.street1,
        dirtyStreet2: this.state.street2,
        dirtyZip: this.state.zip,
      },
    };

    if (this.state.addressId) {
      officePayload.address.id = this.state.addressId;
    }

    if (isOfficeVCardEnabled) {
      officePayload.vCards = this.getVCardPayload();
    }

    return officePayload;
  }

  handleSameAddress = (checked, index) => {
    const { state, city, zip, street1, street2, vCards } = this.state;
    vCards[index].isSameOfficeAddress = checked;
    vCards[index].vCardStreet1 = checked ? street1 : '';
    vCards[index].vCardStreet2 = checked ? street2 : '';
    vCards[index].vCardCity = checked ? city : '';
    vCards[index].vCardState = checked ? state : -1;
    vCards[index].vCardZip = checked ? zip : '';
    this.setState({ vCards });
  }

  handleChange = (name, value) => {
    const addressHasChanged = (!this.state.addressHasChanged && ['state', 'zip', 'city', 'street1', 'street2'].includes(name)) || this.state.addressHasChanged;

    this.setState({
      [name]: value,
      addressHasChanged,
    });
  }

  handleVCardChange = (name, value, index) => {
    const vCards = [...this.state.vCards];
    vCards[index][name] = value;
    if (name === 'vCardName') {
      vCards[index].vCardNameRequiredError = false;
      vCards[index].vCardUniqueNameRequiredError = false;
    }
    this.setState({ vCards });
  }

  handleSelect = (name, value, index) => {
    const vCards = [...this.state.vCards];
    let channelIds = [...vCards[index].vCardChanneIds];
    vCards[index].phoneValidationError = false;
    if (channelIds.includes(value)) {
      channelIds = channelIds.filter((id) => id !== value);
    } else {
      channelIds.push(value);
    }
    vCards[index].vCardChanneIds = channelIds;
    this.setState({ vCards });
  }

  shapeChannels(channelIds, channels) {
    return channelIds.reduce((acc, channelId) => {
      const channel = this.shapeChannelForRender(channels[channelId]);

      return { ...acc, [channel.id]: channel };
    }, {});
  }

  shapeChannelForRender(channel) {
    return {
      id: channel.id,
      name: channel.name,
      phone: DataHelpers.hasData(channel.details) && this.props.phones[channel.details.phone] &&
        PhoneHelpers.formatPhone(this.props.phones[channel.details.phone].value),
    };
  }

  handleDestroyOffice = () => {
    this.setState({ formInProgress: true });

    this.props.destroyOffice(this.state.id)
      .then(() => {
        this.props.history.push('/settings/organization/offices');
      })
      .catch(() => {
        this.setState({ formInProgress: false });
      });
  }

  handleSubmit = () => {
    const { isOfficeVCardEnabled } = this.props.currentOrganization;
    let hasValidationError = false;
    const vCards = [...this.state.vCards];
    if (isOfficeVCardEnabled) {
      vCards.forEach((vCard, index) => {
        if (vCard.vCardName === '') {
          vCards[index].vCardNameRequiredError = true;
          hasValidationError = true;
        }
        if (vCard.vCardChanneIds.length === 0) {
          vCards[index].phoneValidationError = true;
          hasValidationError = true;
        }
      });
    }
    const payload = this.getOfficePayload(isOfficeVCardEnabled);
    const errors = ValidationService(ValidationShapers.shapeOffice(payload));
    const errorCount = Object.keys(errors).length;

    this.setState({ formInProgress: true });

    if (errorCount > 0 || hasValidationError) {
      this.setState({ errors, vCards, formInProgress: false }, () => {
        ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
      });
    } else if (this.state.mode === 'create') {
      this.props.createOffice(payload)
        .then((office) => {
          if (this.props.error) {
            this.setState({
              errors: ValidationHelpers.handleServerError(this.props.error.data),
              formInProgress: false,
            });
          } else {
            this.props.resetFormChanges();

            this.props.history.push(`/settings/organization/offices/${office.id}`);
          }
        });
    } else {
      // Remove the address prop from payload to minimize validations run against smarty
      if (!this.state.addressHasChanged) {
        delete payload.address;
      }

      this.props.updateOffice(payload, this.state.id)
        .then((office) => {
          if (this.props.error) {
            this.setState({
              errors: ValidationHelpers.handleServerError(this.props.error.data),
              formInProgress: false,
            });
          } else {
            this.props.resetFormChanges();

            this.props.history.push(`/settings/organization/offices/${office.id}`);
          }
        });
    }
  }

  render() {
    const props = {
      actionTitle: this.state.mode === 'update' ? 'Update Office' : 'Create Office',
      city: this.state.city,
      errors: this.state.errors,
      formInProgress: this.state.formInProgress,
      handleChange: this.handleChange,
      handleDestroyOffice: this.handleDestroyOffice,
      handleFormChanges: this.props.handleFormChanges,
      handleSubmit: this.handleSubmit,
      isDeleteButtonVisible: this.state.mode === 'update',
      isDeleteConfirmationVisible: this.state.isDeleteConfirmationVisible,
      name: this.state.name,
      pageContainerRef: (pageContainer) => (this.pageContainer = pageContainer),
      pageLoading: this.state.pageLoading,
      pageTitle: this.state.mode === 'update' ? 'Edit Office' : 'Create Office',
      saveBtnType: this.state.mode === 'create' ? 'secondary' : 'primary',
      state: this.state.state,
      vCardState: this.state.vCardState,
      stateOptions: this.props.states,
      street1: this.state.street1,
      street2: this.state.street2,
      zip: this.state.zip,
      isOfficeVCardEnabled: this.props.currentOrganization.isOfficeVCardEnabled,
      handleSameAddress: this.handleSameAddress,
      handleAddPhoto: this.handleAddPhoto,
      handleCloseModal: this.handleCloseModal,
      uploadAvatar: this.handleUploadAvatar,
      channels: this.shapeChannels(this.props.SMSChannelIds, this.props.channels),
      SMSChannelIds: this.props.SMSChannelIds,
      handleSelect: this.handleSelect,
      handleToggleCollapse: this.handleToggleCollapse,
      vCards: this.state.vCards,
      handleCreateVCard: this.handleCreateVCard,
      handleVCardChange: this.handleVCardChange,
      channelLoading: this.props.channelLoading,
      handleDeleteVCard: this.handleDeleteVCard,
      isShowWarning: this.state.isShowWarning,
      handleDimissWarning: this.handleDimissWarning,
    };

    return <OrganizationOfficeForm {...props} />;
  }
}

OrganizationOfficeFormContainer.propTypes = {
  createOffice: PropTypes.func.isRequired,
  destroyOffice: PropTypes.func.isRequired,
  error: PropTypes.object,
  fetchOfficeView: PropTypes.func.isRequired,
  offices: PropTypes.object.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  states: PropTypes.array.isRequired,
  updateOffice: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  resetFormChanges: PropTypes.func.isRequired,
  history: PropTypes.object,
  fetchStates: PropTypes.func,
  currentOrganization: PropTypes.object,
  channels: PropTypes.object,
  SMSChannelIds: PropTypes.array,
  fetchChannels: PropTypes.func,
  phones: PropTypes.object,
  channelLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { office, ui, states, channel, phone } = state;

  return {
    error: ui.error,
    offices: office.offices,
    states: states.states,
    pageLoading: office.pageLoading,
    currentOrganization: getCurrentOrg(state),
    SMSChannelIds: getAutomatedMessageChannelIds(state),
    channels: channel.channels,
    channelLoading: channel.loading,
    phones: phone.phones,
  };
};

const actions = {
  fetchOfficeView: OfficeReducer.fetchOfficeView,
  createOffice: OfficeReducer.createOffice,
  updateOffice: OfficeReducer.updateOffice,
  destroyOffice: OfficeReducer.destroyOffice,
  fetchStates: StateReducer.fetchStates,
  fetchChannels,
};

export default connect(mapStateToProps, actions)(unsavedChanges(OrganizationOfficeFormContainer));
