import { TweenMax, TimelineMax, Power0 } from 'gsap/TweenMax';
import Draggable from 'gsap/Draggable';
import { UtilitySystem } from 'rhinostyle';

import '../scripts/ThrowPropsPlugin.js';
import store from '../store';
import * as UIReducer from '../reducers/uiReducer';

const $body = document.querySelector('body');

class Panels {
  constructor() {
    // Used for `clearProps` call
    this.navSelectors = ['.app-navigation-sidebar', '.app-overlay', '.navigation-tour'];
    this.panelAnimationTiming = 0.5;
    this.navTiming = 0.25;
    this.$proxy = document.createElement('div');

    this.hasInit = false;
  }

  /**
   * Build timeline for nav opening on mobile
   * @return {TimelineMax}
   */
  buildNavTimeline() {
    this.navigation = document.querySelector('.app-navigation-sidebar');
    this.appWrapper = document.querySelector('.app-wrapper');

    this.mobileNavTimelineFunc = () => new TimelineMax({
      paused: true,
      onStart: () => {
        this.addNavBodyClass();
        this.setupDraggable();
      },
      onComplete: () => {
        store.dispatch(UIReducer.setNavigationOpen(true));
      },
      onReverseComplete: () => {
        this.removeNavBodyClass();
        this.killDraggable();

        store.dispatch(UIReducer.setNavigationOpen(false));
        // Make sure we clear props for all panels to avoid conflicts
        TweenMax.set(this.navSelectors, { clearProps: 'all' });
      },
    })
      .set('.app-overlay', {
        display: 'block',
      }, 'nav-mobile')
      .set('.navigation-tour', {
        display: 'block',
      }, 'nav-mobile')
      .to('.app-overlay', this.navTiming, {
        opacity: 1,
        ease: UtilitySystem.config.easing,
      }, 'nav-mobile')
      .to('.app-navigation-sidebar', this.navTiming, {
        x: 0,
        ease: Power0.easeNone,
      }, 'nav-mobile');

    // Attach to var
    this.mobileNavTimeline = this.mobileNavTimelineFunc();
  }

  setupDraggable() {
    this.navigationWidth = this.navigation.offsetWidth;

    Draggable.create(this.$proxy, {
      type: 'x',
      trigger: $body, // So we can start the drag from outside of the nav itself
      throwProps: true,
      dragClickables: true,
      overshootTolerance: 0,
      maxDuration: 1, // Speed up snap/throwProps if need-be
      bounds: this.navigation,
      onDrag: this.updateProgress.bind(this),
      onThrowUpdate: this.updateProgress.bind(this),
      onPress: this.updateProxy.bind(this),
      onRelease: this.checkThrowing.bind(this),
      snap: {
        x: this.snapX.bind(this),
      },
    });

    this.draggable = Draggable.get(this.$proxy);
  }

  /**
   * Removes Draggable instance
   * @return {void}
   */
  killDraggable() {
    // If Draggable has been initiated
    if (this.draggable) {
      this.draggable.kill();
    }
  }

  /**
   * Determine if menu is being "thrown" or "flicked"
   * @return {void}
   */
  checkThrowing() {
    if (!this.draggable.isThrowing) {
      this.mobileNavTimeline.resume();
    }
  }

  /**
   * Determine current offset of proxy in relation to site navigation
   * @return {void}
   */
  updateProxy() {
    this.mobileNavTimeline.pause();

    TweenMax.set(this.draggable.target, {
      x: this.navigationWidth * this.mobileNavTimeline.progress(),
    });

    this.draggable.update();
  }

  /**
   * Update timeline with current position of draggable element
   * @return {void}
   */
  updateProgress() {
    const progress = this.clamp(this.draggable.x / this.navigationWidth, 0, 1);

    this.mobileNavTimeline.progress(progress);
  }

  /**
   * Ensure "flick" closes navigation
   * @param  {integer} x
   * @return {integer}
   */
  snapX(x) {
    if (x <= (this.navigationWidth / 2)) {
      return 0;
    }

    return this.navigationWidth;
  }

  /**
   * Force value into a range
   * @param  {integer} value
   * @param  {integer} min
   * @param  {integer} max
   * @return {integer}
   */
  clamp(value, min, max) {
    return value < min ? min : (value > max ? max : value); // eslint-disable-line no-nested-ternary
  }

  /**
   * Toggle navigation based on screen-width
   * @return {void}
   */
  toggleNav() {
    if (this.mobileNavTimeline.progress() === 0) {
      this.mobileNavTimeline.play();
    } else {
      this.mobileNavTimeline.reverse();
    }
  }

  /**
   * Add class attached to body when nav visibility is changed
   * @return {void}
   */
  addNavBodyClass() {
    $body.classList.add('has-open-nav');

    // Focus on navigation
    if (this.navigation) this.navigation.focus();
  }

  /**
   * Remove class attached to body when nav visibility is changed
   * @return {void}
   */
  removeNavBodyClass() {
    $body.classList.remove('has-open-nav');

    // Re-focus on app-wrapper
    if (this.appWrapper) this.appWrapper.focus();
  }

  /**
   * Update nav UI based on "desktop-to-mobile" UI update
   * @return {void}
   */
  navDesktopToMobileCheck() {
    // Reset navigation timeline based on screen-width
    // If the mobile nav was open previously
    if (this.mobileNavTimeline.progress() === 1) {
      // Reset mobile timeline
      this.mobileNavTimeline.seek(0).kill();

      // Make sure we clear props for all nav selectors to avoid conflicts
      TweenMax.set(this.navSelectors, { clearProps: 'all' });

      this.removeNavBodyClass();
      this.killDraggable();

      // Reset for use later
      this.mobileNavTimeline = this.mobileNavTimelineFunc();
    }
  }

  /**
   * Builds scaffolding based on current window width
   * Runs onload and onresize
   * @return {void}
   */
  buildNavUI() {
    // From desktop to mobile
    if (!window.matchMedia(`(max-width: ${UtilitySystem.config.breakpoints.largeMax})`).matches) {
      this.navDesktopToMobileCheck();
    }
  }

  navInit() {
    this.buildNavTimeline();
    this.buildNavUI();

    // We can just run this without any init checks since it's only done once
    UtilitySystem.optimizedResize.add(() => {
      this.buildNavUI();
    });
  }
}

export default new Panels();
