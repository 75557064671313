import React from 'react';
import { organizationActionsTemplate, messageActionsTemplate, miscellaneousActionsTemplate } from '../constants/RoleTemplates';
import * as DataHelpers from './DataHelpers';

export function formatPermission(permission, props) {
  const mobilePermission = 'mobile access';
  const permissionsArr = permission.map((p) => {
    if (!p.visible || p.visible(props)) {
      return ({
        ...p,
        options: p.options.filter((o) => o.value && o.action && o.permissions.length > 0),
      });
    }
    return {};
  }).filter((perm) => perm?.options?.length > 0);

  return permissionsArr.map((per) => (
    // eslint-disable-next-line max-len
    <li key={per.name}>{`${per.name} (${per.options.map((p) => p.action).toString().split(',').join(', ')}${per?.mobile[0]?.value ? `, ${mobilePermission}` : ''} )`}</li> // eslint-disable-line react/jsx-filename-extension
  ));
}

export function shapeRolePermissions(roles) {
  if (roles) {
    return Object.values(roles).map((r) => shapeRole(r));
  } else return [];
}

export function shapeRole(roleObject) {
  const shapedRole = DataHelpers.cloneDeep(roleObject);
  const permissionNames = shapedRole.permissions.map((r) => r.name);
  const organizationActions = shapeAction(organizationActionsTemplate, permissionNames);
  const messageActions = shapeAction(messageActionsTemplate, permissionNames);
  const miscellaneousActions = shapeAction(miscellaneousActionsTemplate, permissionNames);

  shapedRole.permissions = { organizationActions, messageActions, miscellaneousActions };
  shapedRole.selectedPermissions = permissionNames;

  return shapedRole;
}

export function groupRolesByType(roles) {
  const systemRoles = roles.filter((r) => r.systemRole);
  const customRoles = roles.filter((r) => !r.systemRole);
  return {
    systemRoles,
    customRoles,
  };
}

function shapeAction(action, permissionNames) {
  return action.map((el) => ({
    ...el,
    options: el.options.map((opt) => ({
      ...opt,
      value: opt.permissions.every((permission) => permissionNames.includes(permission)),
    })),
    mobile: !el.mobile ? false : el.mobile.map((mob) => ({
      ...mob,
      value: !!permissionNames.includes(mob.permissions[0]),
    })),
  }));
}
