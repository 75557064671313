import axios from 'axios';
import { createAction } from '@reduxjs/toolkit';
import NotificationService from '../services/NotificationService';
import * as UIActionTypes from '../constants/UIActionTypes';
import { defaultInstance } from '../helpers/AxiosHelper';

const setError = createAction(UIActionTypes.setError);

function dataUrlToArrayBuffer(src) {
  const axiosInstance = defaultInstance({ responseType: 'arraybuffer' });

  return axiosInstance.get(src)
    .then((res) => ({
      contentType: res.headers['content-type'],
      dataArrayBuffer: res.data,
    }));
}

export async function uploadFileFromDataUrl(fileAsDataUrl, bucket, sourceFilename, progressCallback) {
  try {
    const { dataArrayBuffer } = await dataUrlToArrayBuffer(fileAsDataUrl);
    return await uploadFile(dataArrayBuffer, bucket, sourceFilename, progressCallback);
  } catch (error) {
    console.error(error.response || error);

    NotificationService('uploadFile');
    throw error;
  }
}

export async function uploadFile(file, bucket, sourceFilename, progressCallback) {
  try {
    const {
      data: { key, presignedUrl, filename, fileExtension, mimeType },
    } = await axios.post('/s3/upload/presigned-url', {
      bucket,
      sourceFilename,
    });

    // Upload directly to S3 via presigned URL
    const axiosInstance = defaultInstance();
    const options = {
      headers: {
        'Content-Type': mimeType,
      },
      onUploadProgress: (progressEvent) => {
        if (progressCallback) {
          if (Math.floor((progressEvent.loaded / progressEvent.total) * 100) < 100) {
            progressCallback(`${Math.floor((progressEvent.loaded / progressEvent.total) * 100)}% Uploaded`, key);
          } else if (Math.floor((progressEvent.loaded / progressEvent.total) * 100) === 100) {
            // This is for the case where axios says it is done uploading but the promise has not yet returned
            // looks like a bug in this version but once it is resolved this case should not be hit anymore
            progressCallback('Processing ....');
          }
        }
      },
    };

    await axiosInstance.put(presignedUrl, file, options);
    return {
      bucket,
      key,
      file: filename,
      extension: fileExtension,
      fileType: mimeType,
      bytes: file.size,
    };
  } catch (error) {
    console.error(error.response || error);

    NotificationService('uploadFile');
    throw error;
  }
}

export function importCSV(payload) {
  return (dispatch) =>
    axios.post('/import', payload)
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        NotificationService('importCSV', err.response);
      });
}

export function createSharelink(url) {
  return axios.post('/createShortlink', { partialUrl: url })
    .catch((err) => {
      console.error(err.response || err);

      NotificationService('createSharelink');
    });
}
