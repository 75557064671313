import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Input,
  Textarea,
  UtilityList,
  UtilityInlineGrid,
  RhinoSwitch,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'rhinostyle';

// Helpers
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { scrollToBottom } from '../helpers/ScrollHelpers';
import { AttachmentHelpers, ValidationHelpers } from '../helpers';
import { cloneDeep } from '../helpers/DataHelpers';

// Services
import { ValidationService, ValidationShapers } from '../services/ValidationService';

// Selectors
import { getLoggedInUserOrganization, getLoggedInUserPermissionNames } from '../selectors/userSelectors';

// Constants
import { TEMPLATE_MESSAGE_DELETE, FORM_VIEW } from '../constants/UserPermissionsConstants';
import { TEMPLATE_MESSAGE_VARIABLE_OPTIONS, TEMPLATE_DEFAULT_TEXT } from '../constants/VariableMessageConstants';
import { TYPE_TEMPLATE_CONTACT, TYPE_TEMPLATE_TEAM } from '../constants/Types';
import { VCARD_FILE_TYPE } from '../constants/AppConstants';

// Components
import PageLoader from './PageLoader';
import SingleAttachmentPreview from './SingleAttachmentPreview';
import BackButtonContainer from '../containers/BackButtonContainer';
import CharacterCount from './CharacterCount';
import VariableMessage from './VariableMessage';
import unsavedChanges from './UnsavedChangesHOC';

// Reducers
import * as MessageTemplateReducer from '../reducers/messageTemplateReducer';
import { getFormTemplateById } from '../reducers/formReducer';
import { fetchOffices } from '../reducers/officeReducer';

const { createMessageTemplate, destroyMessageTemplate, fetchMessageTemplateById, updateMessageTemplate } = MessageTemplateReducer;

const OrganizationMessageTemplateForm = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const pageContainerRef = useRef(null);
  const uploaderInputRef = useRef(null);

  // Props
  const {
    formInProgress,
    messageTemplates,
    userOrganization,
    fileProgress,
    resetFormChanges,
    handleFormChanges,
    match,
    formTemplates,
    formS3Url,
    isRhinoformEnabled,
    loggedInUserPermissions,
  } = props;

  // State
  const [activeUpload, setActiveUpload] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [contactTemplateMessage, setContactTemplateMessage] = useState('');
  const [contactTemplateMessageRaw, setContactTemplateMessageRaw] = useState('');
  const [errors, setErrors] = useState({});
  const [fileProgressText, setFileProgressText] = useState('0% Uploaded');
  const [id, setId] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRevertable, setIsRevertable] = useState(false);
  const [isSystemTemplate, setIsSystemTemplate] = useState(false);
  const [isTeamTemplate, setIsTeamTemplate] = useState(false);
  const [message, setMessage] = useState('');
  const [mode, setMode] = useState('create');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [subject, setSubject] = useState('');
  const [typeId, setTypeId] = useState(TYPE_TEMPLATE_CONTACT);
  const [pageLoading, setPageLoading] = useState(true);
  const [selectedVisitingCards, setSelectedVisitingCards] = useState([]);
  const [selectedFormTemplates, setSelectedFormTemplates] = useState([]);

  const isShowAddFiles = ((isRhinoformEnabled && loggedInUserPermissions.includes(FORM_VIEW)) || userOrganization?.isOfficeVCardEnabled) && !isTeamTemplate;
  const addFileButtonName = `Add Files${isRhinoformEnabled ? ', Forms' : ''}${userOrganization?.isOfficeVCardEnabled ? ', VCards' : ''}`;
  // Effects
  useEffect(() => {
    if (isRhinoformEnabled && match.params.messageTemplateId) {
      dispatch(fetchMessageTemplateById(match.params.messageTemplateId));
    }
    if (userOrganization?.isOfficeVCardEnabled && match.path.includes('templates')) {
      dispatch(fetchOffices());
    }
  }, []);

  useEffect(() => {
    const activeTemplate = messageTemplates.find((template) => template.id === parseInt(match.params.messageTemplateId, 10));
    const updateMode = activeTemplate && id === -1;
    if (updateMode && mode !== 'update') {
      const { systemTemplate, systemTemplateId } = activeTemplate;
      setContactTemplateMessage(activeTemplate.message);
      setAttachments(activeTemplate.attachments);
      setId(activeTemplate.id);
      setIsRevertable(!!(systemTemplate && activeTemplate.message !== systemTemplate.message));
      setIsSystemTemplate(!!systemTemplateId);
      setIsTeamTemplate(activeTemplate.typeId === TYPE_TEMPLATE_TEAM);
      setMessage(activeTemplate.message);
      setMode('update');
      setSubject(activeTemplate.subject);
      setTypeId(activeTemplate.typeId);
      setPageLoading(false);
    } else if (!activeTemplate && !match.params.messageTemplateId) {
      setPageLoading(false);
    }
  }, [props]);

  useEffect(() => {
    if (isTeamTemplate) {
      setMessage(message.replace(/\{[^{}]*\}/g, ''));
      if (attachments.length > 0) {
        const attachmentsWithoutFormsAndVCard = attachments.filter((attachment) => ((attachment.type.name !== 'form' && attachment.type !== 'form') && attachment.type !== VCARD_FILE_TYPE));
        setAttachments(attachmentsWithoutFormsAndVCard);
      }
    }
  }, [isTeamTemplate]);

  useEffect(() => {
    if (showDeleteConfirmation) scrollToBottom(pageContainerRef.current);
  }, [showDeleteConfirmation]);

  useEffect(() => {
    setFileProgressText(fileProgress);
  }, [fileProgress]);

  useEffect(() => {
    if (errors.length) ValidationHelpers.handleValidationErrors(errors, pageContainerRef.current);
  }, [errors]);

  // Handlers
  const handleRemoveAttachment = (key) => {
    const removedAttachment = attachments[key];
    if (AttachmentHelpers.getIsVCard(removedAttachment)) {
      setSelectedVisitingCards(selectedVisitingCards.filter((vc) => vc.attachmentUrl !== removedAttachment.attachmentUrl));
    }
    if (removedAttachment.type === 'form') {
      setSelectedFormTemplates(selectedFormTemplates.filter((form) => form.attachmentUrl !== removedAttachment.attachmentUrl));
    }
    setAttachments(attachments.filter((attachment, idx) => key !== idx));
    handleFormChanges();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setTypeId(TYPE_TEMPLATE_CONTACT);
    setIsTeamTemplate(false);
  };

  const handleConfirmTypeTemplate = () => {
    setTypeId(TYPE_TEMPLATE_TEAM);
    setIsTeamTemplate(true);
    setIsModalOpen(false);
  };

  const handleRevertSystemTemplate = () => {
    const activeTemplate = messageTemplates.find((template) => template.id === parseInt(match.params.messageTemplateId, 10));
    setMessage(activeTemplate.systemTemplate.message);
  };

  const handleDestroyTemplate = (templateId) => {
    dispatch(destroyMessageTemplate(templateId))
      .then(() => {
        history.push('/settings/organization/library/templates');
      });
  };

  const handleSubmit = () => {
    const payload = getPayload();

    const validationErrors = ValidationService(ValidationShapers.shapeMessageTemplate(payload));
    const errorCount = Object.keys(validationErrors).length;

    if (errorCount > 0) {
      setErrors(validationErrors);
    } else if (mode === 'update') {
      dispatch(updateMessageTemplate(payload.id, payload))
        .then(() => {
          resetFormChanges();
          history.push(`/settings/organization/library/templates/${payload.id}`);
        });
    } else {
      dispatch(createMessageTemplate(payload))
        .then((template) => {
          resetFormChanges();
          history.push(`/settings/organization/library/templates/${template.id}`);
        });
    }
  };

  const handleTypeTemplate = (name, value) => {
    if (value && name === 'isTeamTemplate') {
      setIsModalOpen(true);
      setIsTeamTemplate(true);
      setContactTemplateMessage(contactTemplateMessageRaw);
    } else {
      setTypeId(TYPE_TEMPLATE_CONTACT);
      setIsTeamTemplate(false);
    }
    setAttachments([]);
  };

  const handleVariableMessageChange = (name, rawMessage) => {
    if (name === 'attachments') {
      const newAttachments = rawMessage.filter((v) => {
        const existingAttachment = attachments.find((a) => (a.attachmentUrl === v.attachmentUrl));
        return !existingAttachment;
      });
      if (newAttachments.length === 0) {
        setAttachments(rawMessage);
      } else {
        setAttachments([...attachments, ...newAttachments]);
      }
    } else {
      setContactTemplateMessageRaw(rawMessage);
    }
  };
  const handleUploadComplete = (newState) => {
    setActiveUpload(false);
    setAttachments(newState.attachments);
  };

  const handleAddFile = (event) => {
    setActiveUpload(true);
    const handleProgressDisplay = (progressText) => {
      setFileProgressText(progressText);
    };

    const uploadOpts = {
      currentAttachments: [...attachments],
      file: event.target.files[0],
      bucket: process.env.REACT_APP_PUBLIC_TEMPLATES_BUCKET,
      isTemplateUpload: true,
      progressCallback: handleProgressDisplay,
      supportAllFileTypes: true,
    };

    AttachmentHelpers.handleAddFile(uploadOpts, handleUploadComplete);
    handleFormChanges();
  };

  // Getters

  function getContactTemplateVariableOptions(options) {
    const optionsCloned = cloneDeep(options);
    if (userOrganization) {
      optionsCloned.filter((obj) => {
        const filterObj = obj;
        if (obj.value === 'Organization Name') {
          filterObj.variableValue = userOrganization.name;
        }
        return filterObj;
      });
    }
    return optionsCloned;
  }

  function getPayload() {
    const payloadMessage = (isTeamTemplate || isSystemTemplate) ? message : contactTemplateMessageRaw;
    return {
      attachments: AttachmentHelpers.processAttachments(attachments),
      id,
      message: payloadMessage,
      subject,
      typeId,
    };
  }

  function getFormS3Url(formId) {
    const attachedForm = formTemplates.find((form) => form.formId === formId);
    const hasFormUrl = formS3Url && formS3Url[formId]?.formS3Url;
    if (!attachedForm && !hasFormUrl) {
      dispatch(getFormTemplateById(formId));
    }
    return attachedForm?.formS3Url || hasFormUrl;
  }

  // Rendered Components
  const saveBtnType = mode === 'create' ? 'secondary' : 'primary';
  const showDeleteButton = mode === 'update';
  const actionTitle = mode === 'update' ? 'Update Template' : 'Create Template';
  const pageTitle = mode === 'update' ? 'Edit Template' : 'Create Template';
  const templateMessageVariableOptions = getContactTemplateVariableOptions(TEMPLATE_MESSAGE_VARIABLE_OPTIONS);

  const renderAttachment = (attachment, key) => (
    <SingleAttachmentPreview
      attachment={attachment}
      handleRemoveAttachment={() => handleRemoveAttachment(key)}
      index={key}
      key={key}
      getFormS3Url={getFormS3Url}
      formS3Url={formS3Url}
    />
  );

  const renderDeleteConfirm = () => (
    <div className="item-status__confirm">
      <div className="item-status__confirm__text">
        <Icon bump="up" icon="trash" /> Are you sure you want to delete this template?
      </div>
      <UtilityInlineGrid size="regular" align="middle">
        <Button size="small" type="link-muted" onClick={() => setShowDeleteConfirmation(false)}>
          Cancel
        </Button>
        <Button loading={formInProgress} size="small" type="danger" onClick={() => handleDestroyTemplate(match.params.messageTemplateId)} data-cypress="confirmDelete">
          Yes, delete template
        </Button>
      </UtilityInlineGrid>
    </div>
  );

  const renderStandardStatus = () => (
    <div className="item-status">
      {(isSystemTemplate || showDeleteButton) && (
        <div className="item-status__left">
          {isSystemTemplate ? (
            <Button reset className="u-text-primary" onClick={handleRevertSystemTemplate} disabled={!isRevertable}>
              Revert to original
            </Button>
          ) :
            userHasAnyOfPermissions([TEMPLATE_MESSAGE_DELETE]) && (
              <Button
                reset
                data-cypress="deleteTemplate"
                onClick={() => setShowDeleteConfirmation(true)}
                className="item-status__delete-icon"
                title="Delete Template"
              >
                <Icon bump="down" size="large" icon="trash" />
              </Button>
            )}
        </div>
      )}
      <div className="item-status__right">
        <Button type={saveBtnType} loading={formInProgress} onClick={handleSubmit} data-cypress={actionTitle} disabled={activeUpload}>
          {actionTitle}
        </Button>
      </div>
    </div>
  );

  const renderVariablesSupportModal = () => (
    <Modal open={isModalOpen}>
      <ModalHeader onClose={handleCloseModal} title="VARIABLES NOT SUPPORTED" />
      <ModalBody>
        <p className="u-text-center">
          Enabling this option removes the ability to include variables in this
          template. Any existing variables in the message will also be removed.
        </p>
      </ModalBody>
      <ModalFooter>
        <UtilityInlineGrid align="between">
          <Button type="default" onClick={handleCloseModal}>Cancel</Button>
          <Button type="primary" onClick={handleConfirmTypeTemplate}>Yes, Enable</Button>
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );

  const renderVariablesAndFormsSupportModal = () => (
    <Modal open={isModalOpen}>
      <ModalHeader onClose={handleCloseModal} title="VARIABLES AND FORMS NOT SUPPORTED" />
      <ModalBody>
        <p className="u-text-center">
          Enabling this option removes the ability to include variables and forms in this template.
          Any existing variables or forms in the message will also be removed. This template will be available for Team Messages only.
        </p>
      </ModalBody>
      <ModalFooter>
        <UtilityInlineGrid align="between">
          <Button type="default" onClick={handleCloseModal}>Cancel</Button>
          <Button type="primary" onClick={handleConfirmTypeTemplate}>Yes, Enable</Button>
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );

  const renderFileInformationTable = () => (
    <table className="table template-form__table">
      <thead>
        <tr>
          <th>Message Type</th>
          <th className="template-form__table__header">FILE DELIVERY</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong className="template-form__table__data-category">Texting</strong>
          </td>
          <td />
        </tr>
        <tr>
          <td className="table__data--active template-form__table__data-description">PNG, JPEG, JPG, BMP, and GIF files up to 1MB</td>
          <td className="table__data--active">Attachment</td>
        </tr>
        <tr>
          <td className="table__data--active template-form__table__data-description">File sizes 1MB to 50MB, and all types including PDFs and videos</td>
          <td className="table__data--active">Share Link</td>
        </tr>
        <tr>
          <td>
            <strong className="template-form__table__data-category">RhinoSecure and Team Messages</strong>
          </td>
          <td />
        </tr>
        <tr>
          <td className="table__data--active template-form__table__data-description">PNG, JPEG, JPG, BMP, and GIF files up to 50MB</td>
          <td className="table__data--active">Attachment</td>
        </tr>
        <tr>
          <td className="table__data--active template-form__table__data-description">All other file types up to 50MB</td>
          <td className="table__data--active">Share Link</td>
        </tr>
        <tr>
          <td>
            <strong className="template-form__table__data-category">Facebook Messenger</strong>
          </td>
          <td />
        </tr>
        <tr>
          <td className="table__data--active template-form__table__data-description">PNG, JPEG, JPG, BMP, and GIF files up to 25MB</td>
          <td className="table__data--active">Attachment</td>
        </tr>
        <tr>
          <td className="table__data--active template-form__table__data-description">All other file types up to 50MB</td>
          <td className="table__data--active">Share Link</td>
        </tr>
      </tbody>
    </table>
  );

  if (pageLoading) {
    return <PageLoader />;
  }
  return (
    <div className="app-page__container app-page__container--template" ref={pageContainerRef}>
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title">
            <BackButtonContainer navigateTo="/settings/organization/library/templates" history={history} location={location} />
            {pageTitle}
          </div>
        </div>
        {isRhinoformEnabled ? renderVariablesAndFormsSupportModal() : renderVariablesSupportModal()}
        <form onChange={handleFormChanges}>
          {!isSystemTemplate && (
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">SETTINGS</div>
                <div className="box__subtitle">Allow this template to be used in Team Messages only. Please note, this option currently
                  does not support variables in templates.
                </div>
              </div>

              <RhinoSwitch
                label="Enable For Team Messages Only"
                name="isTeamTemplate"
                isChecked={isTeamTemplate}
                onChange={handleTypeTemplate}
              />
            </div>
          )}
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">Template Message</div>
            </div>
            <Input
              disabled={isSystemTemplate}
              initialValue={subject}
              label="Title"
              name="subject"
              onChange={(name, value) => setSubject(value)}
              placeholder="ex. Appointment Today"
              required
              validationMessage={errors.subject}
              autoComplete="off"
            />
            {(isTeamTemplate || isSystemTemplate) && (
              <>
                <Textarea
                  initialValue={message}
                  label="Message"
                  name="message"
                  onChange={(name, value) => setMessage(value)}
                  placeholder="ex. This is a reminder that you have an appointment today. If you have any questions, just text us back. Thanks!"
                  required
                  validationMessage={errors.message}
                />
                <CharacterCount length={message.length} />
              </>
            )}

            {!isTeamTemplate && !isSystemTemplate && (
              <VariableMessage
                composeLabel="Message"
                className="u-m-t-large"
                handleTemplateChange={handleVariableMessageChange}
                variableOptions={templateMessageVariableOptions}
                template={contactTemplateMessage || TEMPLATE_DEFAULT_TEXT}
                required
                validationMessage={errors.message}
                name="templateMessage"
                preventFocusOnLoad
                attachments={attachments}
                source="templates"
              />
            )}
          </div>
          {isTeamTemplate && (
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">Files</div>
                <div className="box__subtitle">Include a file to send with a message.</div>
              </div>
              {!!(activeUpload || attachments.length) && (
                <UtilityList border className="u-m-b-large">
                  {attachments.map(renderAttachment)}
                </UtilityList>
              )}
              <input
                className="u-sr-only"
                onChange={handleAddFile}
                onClick={() => { uploaderInputRef.current.value = null; }}
                ref={uploaderInputRef}
                type="file"
              />

              {isShowAddFiles ? (
                <Button data-feature-tag="uploadFile" size="small" loading={activeUpload} type="primary">
                  {addFileButtonName}
                </Button>
              ) : (
                <Button size="small" data-feature-tag="uploadFile" loading={activeUpload} onClick={() => uploaderInputRef.current.click()} type="primary" outlined>
                  Upload File
                </Button>
              )}
              <span className="u-text-small u-text-primary">{(activeUpload ? fileProgressText : '')}</span>
              <div className="template-attachments__footer">
                {renderFileInformationTable()}
              </div>
            </div>
          )}
          <div className="u-m-t-large">
            {showDeleteConfirmation ?
              renderDeleteConfirm() :
              renderStandardStatus()}
          </div>
        </form>
      </div>
    </div>
  );
};

OrganizationMessageTemplateForm.propTypes = {
  formInProgress: PropTypes.bool.isRequired,
  messageTemplates: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  resetFormChanges: PropTypes.func.isRequired,
  userOrganization: PropTypes.object.isRequired,
  fileProgress: PropTypes.string,
  formTemplates: PropTypes.array.isRequired,
  formS3Url: PropTypes.object,
  isRhinoformEnabled: PropTypes.bool.isRequired,
  loggedInUserPermissions: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { ui, messageTemplate, fileProgress, form, office } = state;
  const { officeIds, offices, pageLoading } = office;
  const vCards = [];
  officeIds?.forEach((officeId) => {
    if (offices[officeId]?.vCards?.length > 0) {
      vCards.push(offices[officeId].vCards);
    }
  });
  return {
    formTemplates: form.formTemplates.filter((f) => f.isPublished),
    isRhinoformEnabled: !!form.org?.isRhinoformEnabled,
    allForms: form.allForms,
    isFormsLoading: form.pageLoading,
    formS3Url: form.formS3Url,
    formInProgress: ui.formInProgress,
    messageTemplates: messageTemplate.messageTemplates,
    userOrganization: getLoggedInUserOrganization(state),
    loggedInUserPermissions: getLoggedInUserPermissionNames(state),
    fileProgress,
    vCards: vCards.flat(),
    isOfficeLoading: pageLoading,
  };
};

export default connect(mapStateToProps)(unsavedChanges(OrganizationMessageTemplateForm));
