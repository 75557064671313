import { useSelector } from 'react-redux';
import { useGetUserFormsQuery } from '../services/formService'; // eslint-disable-line
import { useInboxParams } from '.';

const useContactForms = (patientId) => {
  const isFormEnabled = useSelector((state) => !!state.form.org?.isRhinoformEnabled);
  const { userId } = useInboxParams();
  const contactId = patientId || userId;
  const forms = useGetUserFormsQuery({ contactId }, { skip: !isFormEnabled });
  return forms?.data?.forms || [];
};

export default useContactForms;
