import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import NotificationService from '../services/NotificationService';
import { hasAnyInboxViewPermissions } from '../helpers/UserHelpers';
import * as RoleHelpers from '../helpers/RoleHelpers';
import * as DataHelpers from '../helpers/DataHelpers';

import * as UserActionTypes from '../constants/UserActionTypes';
import { THREAD_VIEW, TEAM_THREAD_VIEW } from '../constants/UserPermissionsConstants';

import { role } from '../actions/NormalizrSchema';
import { setError, fetchInboxSections } from './uiReducer';
import { fetchUser } from './userReducer';

// SLICE
const organizationRoleSlice = createSlice({
  name: 'ROLE',
  initialState: {
    systemRoles: {},
    customRoles: {},
    rolesLoading: true,
    currentRole: {},
    formInProgress: false,
  },
  reducers: {
    formInProgress: (state, action) => ({
      ...state, formInProgress: action.payload,
    }),
    receiveSystemRoles: (state, action) => ({
      ...state,
      systemRoles: action.payload.systemRoles,
      rolesLoading: false,
    }),
    receiveCustomRoles: (state, action) => ({
      ...state,
      customRoles: action.payload.customRoles,
      rolesLoading: false,
    }),
    receiveRole: (state, action) => ({
      ...state,
      currentRole: action.payload.currentRole,
      rolesLoading: false,
    }),
    requestData: (state, action) => ({
      ...state,
      rolesLoading: action.payload,
    }),
  },
  extraReducers: {
    [UserActionTypes.receiveMembersView]: (state, action) => ({
      ...state,
      systemRoles: action.payload.systemRoles,
      customRoles: action.payload.customRoles,
      rolesLoading: false,
    }),
    [UserActionTypes.receiveMembersList]: (state, action) => ({
      ...state,
      systemRoles: action.payload.systemRoles,
      customRoles: action.payload.customRoles,
      rolesLoading: false,
    }),
    [UserActionTypes.receiveMembersFiltersView]: (state, action) => ({
      ...state,
      systemRoles: action.payload.systemRoles,
      customRoles: action.payload.customRoles,
      rolesLoading: false,
    }),
  },
});

export default organizationRoleSlice.reducer;

// ACTIONs
export const {
  formInProgress,
  receiveSystemRoles,
  receiveCustomRoles,
  receiveRole,
  requestData,
} = organizationRoleSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

export function createRole(data, currentUserId) {
  return (dispatch) => {
    dispatch(formInProgress(true));

    return axios.post('/roles', data)
      .then((response) => {
        // We are refetching the current user and dispatching them to the Redux store to update the header with their new permissions
        const promises = [dispatch(fetchUser(currentUserId))];

        if (currentUserHasNewThreadViewPermission(data, currentUserId)) {
          promises.push(dispatch(fetchInboxSections()));
        }
        return Promise.all(promises)
          .then(() => {
            NotificationService('createRole', response);

            dispatch(setError(null));
            dispatch(formInProgress(false));
          });
      })

      .catch((err) => {
        dispatch(handleErrorToast('createRole', err));
        dispatch(formInProgress(false));
      });
  };
}

export function fetchNewRoleView() {
  return (dispatch) => {
    const normalized = normalize([{ users: [], permissions: [] }], [role]);

    dispatch(setError(null));
    dispatch(receiveRole(prepareRolePayload(normalized)));
  };
}

export function fetchOrganizationRolesView() {
  return (dispatch) => {
    dispatch(requestData(true));

    return getRoles()
      .then((response) => {
        const { systemRoles, customRoles } = RoleHelpers.groupRolesByType(response.data);
        const normalizedSystemRoles = normalize(systemRoles, [role]);
        const normalizedCustomRoles = normalize(customRoles, [role]);

        dispatch(setError(null));
        dispatch(receiveSystemRoles(prepareSystemRolesPayload(normalizedSystemRoles)));
        dispatch(receiveCustomRoles(prepareCustomRolesPayload(normalizedCustomRoles)));
      })

      .catch((err) => {
        dispatch(setError(err.response || err));
        dispatch(requestData(false));
      });
  };
}

export function fetchRoleFormView(id, isSystemRole) {
  return (dispatch) => {
    dispatch(requestData(true));

    return getRoleById(id, isSystemRole)
      .then((response) => {
        const normalized = normalize([response.data], [role]);

        dispatch(setError(null));
        dispatch(receiveRole(prepareRolePayload(normalized)));
      })

      .catch((err) => {
        dispatch(setError(err.response || err));
        dispatch(requestData(false));
      });
  };
}

export function updateRole(data, editRoleId, isSystemRole, currentUserId) {
  return ((dispatch) => {
    dispatch(formInProgress(true));

    return patchRole(data, editRoleId, isSystemRole)
      .then((response) => {
        // We are refetching the current user and dispatching them to the Redux store to update the header with their new permissions
        const promises = [dispatch(fetchUser(currentUserId))];
        if (currentUserHasNewThreadViewPermission(data, currentUserId)) {
          promises.push(dispatch(fetchInboxSections()));
        }
        return Promise.all(promises)
          .then(() => {
            NotificationService('updateRole', response);

            dispatch(setError(null));
            dispatch(formInProgress(false));
          });
      })

      .catch((err) => {
        dispatch(handleErrorToast('updateRole', err));
        dispatch(formInProgress(false));
      });
  });
}

export function destroyRole(roleId, isSystemRole, hadUsers, currentUserId) {
  return ((dispatch) => {
    dispatch(formInProgress(true));
    // We are checking to see if the role had users associated with it. If so, we need to patch the role first and remove that user before deleting.
    if (hadUsers) {
      return axios.patch(`/roles/${roleId}?system=${isSystemRole}`, { users: [], permissions: [] })
        .then(() => {
          const promises = [deleteRole(roleId), dispatch(fetchUser(currentUserId))];
          return Promise.all(promises)
            .then((response) => {
              NotificationService('deleteRole', response[0]);

              dispatch(setError(null));
              dispatch(formInProgress(false));
            })
            .catch((err) => {
              dispatch(setError(err.response || err));
              dispatch(requestData(false));
            });
        })

        .catch((err) => {
          NotificationService('deleteRole', err);
          dispatch(formInProgress(false));
        });
    }

    return axios.delete(`/roles/${roleId}`)
      .then((response) => {
        NotificationService('deleteRole', response);
        dispatch(formInProgress(false));
      })

      .catch((err) => {
        dispatch(setError(err.response || err));
        dispatch(requestData(false));
      });
  });
}

function currentUserHasNewThreadViewPermission(data, currentUserId) {
  // We are covering an edgecase that asks:
  // 1) Is the user a member of the role we are creating (userIsPartOfRole)
  // 2) Does the role have the "Contacts and Conversations - view" checkbox checked (roleContainsThreadView)
  // 3) Does the user currently either have the THREAD_VIEW permission or TEAM_THREAD_VIEW permission
  const userIds = data.users.map((roleUsers) => roleUsers.id);
  const userIsPartOfRole = userIds.includes(currentUserId);
  const roleContainsThreadView = data.permissions.includes(THREAD_VIEW) || data.permissions.includes(TEAM_THREAD_VIEW);
  // If all of these evaluate to true, then we want to call fetchInboxSections(), because that means it was NOT fetched on initial load.
  return roleContainsThreadView && userIsPartOfRole && roleContainsThreadView && hasAnyInboxViewPermissions();
}

function handleErrorToast(action, err) {
  return (dispatch) => {
    console.error(err.response || err);

    dispatch(setError(err.response || err));
    // throw toast only if a 500, all other api errors are set to inline validation (to avoid duplicate errors rendering)
    if (err.response && err.response.status === 500) {
      NotificationService(action, err.response);
    }
  };
}

// AXIOS HELPERS

function getRoleById(id, isSystemRole) {
  return axios.get(`/roles/${id}?system=${isSystemRole}`);
}

export function getRoles() {
  return axios.get('/roles');
}

function patchRole(data, editRoleId, isSystemRole) {
  return axios.patch(`/roles/${editRoleId}?system=${isSystemRole}`, data);
}

function deleteRole(roleId) {
  return axios.delete(`/roles/${roleId}`);
}

// PREPARE CALLBACKS -- PAYLOAD CUSTOMIZERS

function prepareSystemRolesPayload(normalizedSystemRoles) {
  return {
    ...(normalizedSystemRoles.entities.users && { users: normalizedSystemRoles.entities.users }),
    ...(normalizedSystemRoles.entities.users && { userIds: DataHelpers.getObjectKeys(normalizedSystemRoles.entities.users) }),
    systemRoles: normalizedSystemRoles.entities.roles,
  };
}

function prepareCustomRolesPayload(normalizedCustomRoles) {
  return {
    ...(normalizedCustomRoles.entities.users && { users: normalizedCustomRoles.entities.users }),
    ...(normalizedCustomRoles.entities.users && { userIds: DataHelpers.getObjectKeys(normalizedCustomRoles.entities.users) }),
    customRoles: normalizedCustomRoles.entities.roles || {},
  };
}

function prepareRolePayload(normalizedRole) {
  return {
    ...(normalizedRole.entities.users && { users: normalizedRole.entities.users }),
    ...(normalizedRole.entities.users && { userIds: DataHelpers.getObjectKeys(normalizedRole.entities.users) }),
    currentRole: normalizedRole.entities.roles,
  };
}
