export const OPEN_CONVERSATION_HEADERS_CONFIG = {
  conversationStartTime: {
    default: true,
    direction: -1,
  },
  lastConversationTime: {
    default: false,
    direction: 1,
  },
  assignment: {
    default: false,
    direction: 1,
  },
  contact: {
    default: false,
    direction: 1,
  },
};

export const CLOSE_CONVERSATION_HEADERS_CONFIG = {
  timeOpen: {
    default: true,
    direction: -1,
  },
  closedTime: {
    default: false,
    direction: 1,
  },
  closedBy: {
    default: false,
    direction: 1,
  },
  closedContact: {
    default: false,
    direction: 1,
  },
};

export const MEDICAID_CONVERSATION_HEADERS_CONFIG = {
  timestamp: {
    default: true,
    direction: -1,
  },
  member: {
    default: false,
    direction: 1,
  },
  medicaidContact: {
    default: false,
    direction: 1,
  },
  messageSentTo: {
    default: false,
    direction: 1,
  },
};

export const CAMPAIGNS_CONVERSATION_HEADERS_CONFIG = {
  timestamp: {
    default: true,
    direction: -1,
  },
  member: {
    default: false,
    direction: 1,
  },
  message: {
    default: false,
    direction: 1,
  },
  attachments: {
    default: false,
    direction: 1,
  },
  contactCount: {
    default: false,
    direction: 1,
  },
  failed: {
    default: false,
    direction: 1,
  },
  contactListName: {
    default: false,
    direction: 1,
  },
};

export const NAVIGATION_TABS = {
  open: 1,
  closed: 2,
  medicaid: 3,
};

export const FORMS_MANAGER_HEADERS_CONFIG = {
  createdAt: {
    default: true,
    direction: -1,
  },
  title: {
    default: false,
    direction: 1,
  },
  status: {
    default: false,
    direction: 1,
  },
  contact: {
    default: false,
    direction: 1,
  },
};

export const DATEPICKER_DROPDOWN_LABELS = [
  { id: 1, label: 'Yesterday', duration: 1 },
  { id: 2, label: 'Last 7 Days', duration: 7 },
  { id: 3, label: 'Last 30 Days', duration: 30 },
  { id: 4, label: 'Last 90 Days', duration: 90 },
  { id: 5, label: 'Last 12 Months', duration: 'year' },
  { id: 6, label: 'Custom Date' }];
