import React from 'react';

import RhinocallToggleMicrophoneButton from './RhinocallToggleMicrophoneButton';
import RhinocallHangupButton from './RhinocallHangupButton';
import RhinocallDialpadButton from './RhinocallDialpadButton';
import { isMobile } from '../helpers/BrowserHelpers';

const RhinovideoActionButtons = () =>
  (
    <div className="rhinocall__action-bar">
      {!isMobile() && <RhinocallToggleMicrophoneButton />}
      <RhinocallHangupButton />
      {!isMobile() && <RhinocallDialpadButton />}
    </div>
  );

export default RhinovideoActionButtons;
