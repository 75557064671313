import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Dropdown,
  DropdownMenuItem,
  Input,
  Scrollbars,
  SmartTable,
  Checkbox,
  NavTabs,
  NavTabsItem,
  UtilityInlineGrid,
  TabsContent,
  TabContentPane,
} from 'rhinostyle';
import { fetchFormTemplates, updateFormTemplates } from '../reducers/formReducer';
import { AppConstants } from '../constants';
import EmptyMessage from './EmptyMessage';
import { FORMS_LIST_HEADERS_CONFIG, NAVIGATION_TABS } from '../constants/LibraryConstants';
import { MODAL_OPTIONS } from '../constants/ThreadConstants';
import SmartTableHeader from './SmartTableHeader';
import { compareObjectByKey, cloneDeep, getDefaultSortKey } from '../helpers/DataHelpers';
import { numericDateFormatter } from '../helpers/DateHelpers';
import { addMessageForm, toggleModal } from '../reducers/threadReducer';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { VCARD_LIST_HEADERS_CONFIG } from '../constants/VCardConstants';

const filterOptions = [
  {
    id: 'all',
    label: 'All',
  },
  {
    id: 'favorite',
    label: 'Favorite',
  },
];
const FormLibraryModal = (props) => {
  const {
    closeModal,
    open,
    handleFormAppend,
    handleVCardAppend,
    isInboxThread,
    handleAddFile,
    isMultiSelect,
    selectedForms,
    selectedVCards,
    appointmentEventTypeId,
    isBulkMessage,
    source,
  } = props;

  const uploaderInputRef = useRef(null);
  const formTemplates = useSelector((state) => state.form.formTemplates);
  const pageLoading = useSelector((state) => state.form.pageLoading);
  const isFormTemplatesLoading = useSelector((state) => state.form.isFormTemplatesLoading);
  const threadModalOpen = useSelector((state) => state.thread[MODAL_OPTIONS.formLibrary]);
  const isOpen = isInboxThread ? threadModalOpen : open;
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [sortKey, setSortKey] = useState(getDefaultSortKey(FORMS_LIST_HEADERS_CONFIG));
  const [focusSearchInput, setFocusSearchInput] = useState(false);
  const [headersConfig, setHeadersConfig] = useState(cloneDeep(FORMS_LIST_HEADERS_CONFIG));
  const [sortAscending, setSortAscending] = useState(true);
  const [selectedFormTemplates, setSelectedFormTemplates] = useState(selectedForms || []);
  const isRhinoformEnabled = useSelector((state) => !!state.form.org?.isRhinoformEnabled);
  const currentOrganization = useSelector((state) => getCurrentOrg(state));
  const [activeKey, setActiveKey] = useState(((!isInboxThread && !isBulkMessage) && currentOrganization?.isOfficeVCardEnabled) ? 1 : 2);
  const offices = useSelector((state) => state.office.offices);
  const officeIds = useSelector((state) => state.office.officeIds);
  const [selectedVisitingCards, setSelectedVisitingCards] = useState(selectedVCards || []);
  const vCardHeadersConfig = cloneDeep(VCARD_LIST_HEADERS_CONFIG);
  const setDefaultSort = () => {
    Object.keys(headersConfig).forEach((key) => {
      headersConfig[key].default = FORMS_LIST_HEADERS_CONFIG[key].default;
      headersConfig[key].direction = FORMS_LIST_HEADERS_CONFIG[key].direction;
    });
    setHeadersConfig(headersConfig);
  };

  useEffect(() => {
    if (!appointmentEventTypeId) {
      dispatch(fetchFormTemplates(selectedFilter));
    }
  }, []);

  useEffect(() => {
    if (source !== 'templates') {
      setSelectedVisitingCards(selectedVCards);
    }
  }, [selectedVCards]);

  useEffect(() => {
    if (source !== 'templates') {
      setSelectedFormTemplates(selectedForms);
    }
  }, [selectedForms]);

  function handleAttachForm(form) {
    // Forms
    if (activeKey === 2) {
      if (isInboxThread) {
        dispatch(addMessageForm(form));
      } else {
        handleFormAppend(isMultiSelect ? selectedFormTemplates : form);
      }
    }
    // VCard
    if (activeKey === 1) {
      handleVCardAppend(selectedVisitingCards);
    }
  }

  function isFormSelected(form) {
    return selectedFormTemplates.some((formTemplate) => formTemplate.attachmentUrl === form.formId);
  }

  function isFormInUse(form) {
    return appointmentEventTypeId && isMultiSelect && form &&
      selectedFormTemplates.some((formTemplate) => formTemplate.attachmentUrl === form.formId && formTemplate.appointmentEventTypeId !== appointmentEventTypeId);
  }

  function handleSelectForm(form) {
    if (isFormSelected(form)) {
      setSelectedFormTemplates((current) => current.filter((formTemplate) => formTemplate.attachmentUrl !== form.formId));
    } else {
      setSelectedFormTemplates((current) => [...current, {
        attachmentUrl: form.formId,
        name: form.title,
        type: 'form',
        bytes: 0,
        ...appointmentEventTypeId && {
          appointmentEventTypeId,
        },
      },
      ]);
    }
  }

  function handleCloseModal() {
    if (isInboxThread) {
      dispatch(toggleModal(MODAL_OPTIONS.formLibrary));
    } else {
      closeModal();
    }
  }

  const resetState = () => {
    setSearchText('');
    setSelectedFilter('all');
    setSortKey(getDefaultSortKey(FORMS_LIST_HEADERS_CONFIG));
    setActiveKey(((!isInboxThread && !isBulkMessage) && currentOrganization?.isOfficeVCardEnabled) ? 1 : 2);
    setFocusSearchInput(false);
    setSortAscending(true);
    setDefaultSort();
  };

  const setSortValues = (key) => {
    if (key === sortKey) {
      setSortAscending(!sortAscending);
    }
    setSortKey(key);
  };

  const getSortedFilteredForms = () => {
    const publishedForms = formTemplates.filter((form) => form.isPublished);
    const favoriteForms = publishedForms?.filter((form) => form.isFavorite) || [];
    const nonFavoriteForms = publishedForms?.filter((form) => !form.isFavorite) || [];
    const sortedFavorites = favoriteForms?.sort((a, b) => compareObjectByKey(a, b, sortKey)) || [];
    const sortedNonFavorites = nonFavoriteForms?.sort((a, b) => compareObjectByKey(a, b, sortKey)) || [];
    if (headersConfig[sortKey].direction === -1) {
      sortedFavorites.reverse();
      sortedNonFavorites.reverse();
    }
    const forms = selectedFilter === 'all' ? [...sortedFavorites, ...sortedNonFavorites] : sortedFavorites;
    if (searchText?.length > 2) {
      return forms.filter((form) => form.title.toLowerCase().includes(searchText.toLowerCase()));
    }
    return forms;
  };

  const handleSearch = (id, value) => {
    if (value.length !== 1) {
      setDefaultSort();
    }
    setSearchText(value);
    setFocusSearchInput(true);
  };

  const rowProps = (state, rowInfo) => ({
    ...!isMultiSelect && {
      onClick: () => handleAttachForm(rowInfo.original),
    },
    style: {
      cursor: 'pointer',
    },
    className: 'form-row',
    'data-cypress': rowInfo.original.title,
  });

  const handleFavorite = (isFavorite, id, e) => {
    e.stopPropagation();
    const payload = {
      isFavorite: !isFavorite,
    };
    dispatch(updateFormTemplates(id, payload, 'all'));
  };

  const renderFavoriteIcon = (itemId, isSelected) => {
    const icon = isSelected ? 'heart-filled' : 'heart';
    const iconClass = isSelected ? 'heart-filled-icon' : 'heart-icon';
    return (
      <Icon
        icon={icon}
        data-cypress="markFavorite"
        className={iconClass}
        onClick={(e) => handleFavorite(isSelected, itemId, e)}
      />
    );
  };

  const formTemplatesColumns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Title"
          sortKey="title"
          sortDirection={headersConfig.title.direction}
          headers={headersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'title',
      Cell: (row) => (
        <div>
          <div className={!row.original.isPublished ? 'title-text' : ''}>{row.original.title}</div>
          {!row.original.isPublished && <p className="title-draft-text">Draft</p>}
          {isFormInUse(row.original) && <div className="u-text-accent u-text-small">Form already in Use</div>}
        </div>
      ),
      fixed: 'left',
      sortMethod: () => setSortValues('title'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Updated"
          sortKey="updatedAt"
          sortDirection={headersConfig.updatedAt.direction}
          headers={headersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'updatedAt',
      Cell: (row) => (
        <div>{`${numericDateFormatter(row.original.updatedAt)}`}</div>
      ),
      fixed: 'left',
      sortMethod: () => setSortValues('updatedAt'),
    },
    {
      sortable: false,
      Cell: (row) => (
        <div className="u-flex u-flex-justify-end">
          {renderFavoriteIcon(row.original.formId, row.original.isFavorite)}
        </div>
      ),
    },
  ];

  if (isMultiSelect) {
    formTemplatesColumns.splice(0, 0, {
      Header: () => '',
      accessor: 'formId',
      sortable: false,
      fixed: 'left',
      width: 40,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex u-p-l-0 u-p-r-',
      Cell: (row) => (
        <span className="appointments-checkbox" key={row.value}>
          <Checkbox
            name={row.original.title}
            disabled={isFormInUse(row.original)}
            isChecked={isFormSelected(row.original)}
            onChange={() => handleSelectForm(row.original)}
            label="checkbox"
            dataFeatureTag={`form-checkbox-${row.original.title}`}
          />
        </span>
      ),
    });
  }

  const renderList = () => {
    const emptyTemplate = 'Form Templates Modal';
    const list = getSortedFilteredForms();
    const listing = list.length > 0 ? (
      <Scrollbars className="list-panel__body__scroll" data-cypress={emptyTemplate}>
        <SmartTable
          data={getSortedFilteredForms()}
          getTrProps={rowProps}
          showPagination={false}
          pageSize={list?.length}
          sticky
          columns={formTemplatesColumns}
          sortable
          manual={false}
          striped
        />
      </Scrollbars>
    ) : (!isFormTemplatesLoading && !pageLoading && (
      <div className="form-list__noforms_wrapper">
        <EmptyMessage section={emptyTemplate} />
      </div>
    ));
    return listing;
  };

  const getSortedVCards = () => {
    const vCards = [];
    officeIds?.forEach((officeId) => {
      if (offices[officeId]?.vCards?.length > 0) {
        vCards.push(offices[officeId].vCards);
      }
    });
    const sortedVCards = vCards.flat()?.sort((a, b) => compareObjectByKey(a, b, sortKey)) || [];
    if (headersConfig[sortKey].direction === -1) {
      sortedVCards.reverse();
    }
    return sortedVCards;
  };

  const isSelectAll = () => {
    const vCardList = getSortedVCards();
    return vCardList.length === selectedVisitingCards?.length;
  };

  const handleSelectAll = () => {
    const vCardList = getSortedVCards();
    if (vCardList.length !== selectedVisitingCards?.length) {
      const vCardAttachments = [];
      officeIds?.forEach((officeId) => {
        const attachments = offices[officeId]?.vCards?.map((vCard) => ({
          attachmentUrl: vCard.id.toString(),
          name: vCard?.vCardName,
          type: AppConstants.VCARD_FILE_TYPE,
          key: `${currentOrganization.id}/${vCard.vCardUrl}`,
          bytes: 0,
          appointmentEventTypeId,
        }));
        vCardAttachments.push(attachments);
      });
      setSelectedVisitingCards(vCardAttachments.flat());
    } else {
      setSelectedVisitingCards([]);
    }
  };

  const isVCardSelected = (vCard) => selectedVisitingCards?.some((vc) => (parseInt(vc.attachmentUrl, 10) === vCard.id));

  const handleSelectVCard = (vCard) => {
    if (isVCardSelected(vCard)) {
      setSelectedVisitingCards((current) => current.filter((vc) => parseInt(vc.attachmentUrl, 10) !== vCard.id));
    } else {
      setSelectedVisitingCards((current) => [...current, {
        attachmentUrl: vCard?.id.toString(),
        name: vCard?.vCardName,
        type: AppConstants.VCARD_FILE_TYPE,
        key: `${currentOrganization.id}/${vCard.vCardUrl}`,
        bytes: 0,
        appointmentEventTypeId,
      },
      ]);
    }
  };

  const vCardColumns = [
    {
      Header: () => (
        <span className="template-model-checkbox">
          <Checkbox
            isChecked={isSelectAll()}
            onChange={() => handleSelectAll()}
            label="checkbox"
          />
        </span>
      ),
      accessor: 'id',
      sortable: false,
      fixed: 'left',
      width: 40,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex u-flex-justify-center',
      Cell: (row) => (
        <span className="template-model-checkbox" key={row.value}>
          <Checkbox
            name={row.original.vCardName}
            isChecked={isVCardSelected(row.original)}
            onChange={() => handleSelectVCard(row.original)}
            label="checkbox"
            key={row.value}
          />
        </span>
      ),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Display Name"
          sortKey="vCardName"
          sortDirection={vCardHeadersConfig.vCardName.direction}
          headers={vCardHeadersConfig}
        />
      ),
      accessor: 'vCardName',
      Cell: (row) => (<div>{row.value}</div>),
      sortMethod: () => setSortValues('vCardName'),
    },
  ];

  const renderVCardList = () => {
    const emptyTemplate = 'VCards';
    const list = getSortedVCards();
    const listing = list?.length > 0 ? (
      <Scrollbars className="list-panel__body__scroll" data-cypress={emptyTemplate}>
        <SmartTable
          data={getSortedVCards()}
          showPagination={false}
          pageSize={list?.length}
          sticky
          columns={vCardColumns}
          sortable
          manual={false}
          striped
        />
      </Scrollbars>
    ) : (!pageLoading && (
      <div className="form-list__noforms_wrapper">
        <EmptyMessage section={emptyTemplate} />
      </div>
    ));
    return listing;
  };

  const handleUpdateFormFilterId = (formFilterId) => {
    setDefaultSort();
    setSelectedFilter(formFilterId);
    setSearchText('');
  };

  const renderFilterItem = (item, key) => (
    <DropdownMenuItem
      key={key}
      id={item.id}
      label={item.label}
      onClick={() => {
        handleUpdateFormFilterId(item.id);
      }}
    />
  );

  const renderSearch = () => (
    <div className="search-filter-wrapper u-flex">
      <Dropdown
        activeKey={selectedFilter}
        wide
        reset
        icon="filter"
        className="app-page__header__filter__button"
        label="Select Filter"
        hideCaret
        title="Filter forms"
      >
        {filterOptions.map(renderFilterItem)}
      </Dropdown>
      <Input
        placeholder={`Search Existing ${NAVIGATION_TABS.documents === activeKey ? 'Documents' : 'Forms'}`}
        className="search__input"
        name="Search Form"
        initialValue={searchText}
        onChange={handleSearch}
        size="large"
        addon="left"
        type="text"
        autoComplete="off"
        focus={focusSearchInput}
      >
        <Icon icon="search" />
      </Input>
    </div>
  );
  return (
    <Modal modalClass="form-modal" onReverseComplete={resetState} open={isOpen}>
      <ModalHeader onClose={handleCloseModal} title="" />
      <div className="nav-tabs__wrapper">
        {isMultiSelect && (
          <Button
            onClick={() => uploaderInputRef.current.click()}
            type="secondary"
            title="Upload File"
            className="form-modal__upload"
            data-feature-tag="uploadFile"
          >
            Upload File
          </Button>
        )}
        <NavTabs className="u-m-t" activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
          {(!isInboxThread && !isBulkMessage) && currentOrganization.isOfficeVCardEnabled ? (
            <NavTabsItem id={1}>VCards</NavTabsItem>
          ) : ''}
          {isRhinoformEnabled ? (
            <NavTabsItem id={2}>Forms</NavTabsItem>
          ) : ''}
        </NavTabs>
      </div>
      <ModalBody>
        <TabsContent activeKey={activeKey}>
          <TabContentPane id={1}>
            {renderVCardList()}
          </TabContentPane>
          <TabContentPane id={2}>
            {renderSearch()}
            {renderList()}
          </TabContentPane>
        </TabsContent>
        <input
          className="u-sr-only"
          onChange={handleAddFile}
          onClick={() => { uploaderInputRef.current.value = null; }}
          ref={uploaderInputRef}
          type="file"
        />
      </ModalBody>
      {isMultiSelect && (
        <ModalFooter>
          <UtilityInlineGrid align="right">
            <Button type="link" onClick={closeModal}>Cancel</Button>
            <Button type="primary" onClick={handleAttachForm}>Attach File(s)</Button>
          </UtilityInlineGrid>
        </ModalFooter>
      )}
    </Modal>
  );
};

FormLibraryModal.propTypes = {
  closeModal: PropTypes.func,
  open: PropTypes.bool,
  handleFormAppend: PropTypes.func,
  handleVCardAppend: PropTypes.func,
  isInboxThread: PropTypes.bool,
  handleAddFile: PropTypes.func,
  isMultiSelect: PropTypes.bool,
  selectedForms: PropTypes.array,
  selectedVCards: PropTypes.array,
  appointmentEventTypeId: PropTypes.number,
  isBulkMessage: PropTypes.bool,
  source: PropTypes.string,
};

export default FormLibraryModal;
