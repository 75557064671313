import moment from 'moment-timezone';

export function groupBy(messages, format = 'MMM YY') {
  const result = [];
  messages.reduce((acc, value) => {
    if (!acc[moment(value.date).format(format)]) {
      acc[moment(value.date).format(format)] = {
        date: value.date,
        count: value.count,
      };
      result.push(acc[moment(value.date).format(format)]);
    }
    acc[moment(value.date).format(format)].count += value.count;
    return acc;
  }, {});
  return result;
}

export function groupByDays(data, dayRange = 5) {
  const newData = [];
  const array = [...data];

  for (let i = 0, j = array.length; i < j; i += dayRange) {
    const tempArray = array.slice(i, i + dayRange);
    let count = 0;

    for (let k = 0; k < tempArray.length; k++) {
      count += tempArray[k].count;
    }
    newData.push({ count, date: tempArray[0].date });
  }
  return newData;
}

export function groupMessageTypeByDays(data, dayRange = 5) {
  const newData = [];
  const array = [...data];
  for (let i = 0, j = array.length; i < j; i += dayRange) {
    const tempArray = array.slice(i, i + dayRange);
    let incomingCount = 0;
    let outgoingCount = 0;
    let automatedCount = 0;

    for (let k = 0; k < tempArray.length; k++) {
      incomingCount += tempArray[k].incomingCount;
      outgoingCount += tempArray[k].outgoingCount;
      automatedCount += tempArray[k].automatedCount;
    }
    newData.push({ incomingCount, outgoingCount, automatedCount, date: tempArray[0].date });
  }
  return newData;
}

export function groupByMessageType(messages, format = 'MMM YY') {
  const result = [];
  messages.reduce((acc, value) => {
    if (!acc[moment(value.date).format(format)]) {
      acc[moment(value.date).format(format)] = {
        date: value.date,
        incomingCount: value.incomingCount,
        outgoingCount: value.outgoingCount,
        automatedCount: value.automatedCount,
      };
      result.push(acc[moment(value.date).format(format)]);
    }
    acc[moment(value.date).format(format)].incomingCount += value.incomingCount;
    acc[moment(value.date).format(format)].outgoingCount += value.outgoingCount;
    acc[moment(value.date).format(format)].automatedCount += value.automatedCount;
    return acc;
  }, {});
  return result;
}

export function getTooltipTitleForMessageGraph(messages, tooltipItem, duration, state) {
  if (duration === 1) {
    return moment(messages[tooltipItem[0].index].date, 'YYYY-MM-DD HH:mm').format('D MMM, h a');
  } else if (duration > 1 && duration <= 30) {
    return moment(messages[tooltipItem[0].index].date, 'YYYY-MM-DD HH:mm').format('D MMM, YYYY');
  } else if (duration > 30 && duration <= 90) {
    let endRange = moment(messages[tooltipItem[0].index].date, 'YYYY-MM-DD HH:mm').add(4, 'days');
    if (moment(messages[messages.length - 1].date, 'YYYY-MM-DD HH:mm').isSameOrBefore(endRange)) {
      endRange = moment(state.endDate, 'YYYY-MM-DD HH:mm');
    }
    return `${moment(messages[tooltipItem[0].index].date, 'YYYY-MM-DD HH:mm').format('MMM D, YYYY')} - ${endRange.format('MMM D, YYYY')}`;
  } else if (duration > 90 && duration <= 366) {
    let endRange = moment(messages[tooltipItem[0].index].date, 'YYYY-MM-DD HH:mm')
      .add(1, 'months').subtract(1, 'days');
    if (tooltipItem[0].index === 0) {
      endRange = moment(state.startDate).endOf('month');
    } else if (messages.length - 1 === tooltipItem[0].index) {
      endRange = moment(state.endDate, 'YYYY-MM-DD HH:mm');
    }
    return `${moment(messages[tooltipItem[0].index].date, 'YYYY-MM-DD HH:mm').format('MMM D, YYYY')} - ${endRange.format('MMM D, YYYY')}`;
  } else if (duration > 365) {
    let endRange = moment(messages[tooltipItem[0].index].date, 'YYYY-MM-DD HH:mm')
      .add(1, 'quarter').subtract(1, 'days');
    if (tooltipItem[0].index === 0) {
      endRange = moment(endRange).endOf('month');
    } else if (messages.length - 1 === tooltipItem[0].index) {
      endRange = moment(state.endDate, 'YYYY-MM-DD HH:mm');
    }
    return `${moment(messages[tooltipItem[0].index].date, 'YYYY-MM-DD HH:mm').format('MMM D, YYYY')} - ${endRange.format('MMM D, YYYY')}`;
  }
  return moment(messages[tooltipItem[0].index].date, 'YYYY-MM-DD HH:mm').format('D MMM, YYYY');
}

export function getTooltipTitleForResponseGraph(tooltipItem, duration, responseTime) {
  if (duration === 1) {
    return moment(responseTime[tooltipItem[0].index].timestamp, 'YYYY-MM-DD HH:mm').format('D MMM, h a');
  } else {
    return moment(responseTime[tooltipItem[0].index].timestamp, 'YYYY-MM-DD HH:mm').format('MMM D, YYYY');
  }
}

export function getTicksValuesForMessageGraph(value, index, duration, messages) {
  if (duration === 1 && index % 3 === 0) {
    return value;
  } else if (duration > 1 && duration <= 7 && index % 1 === 0) {
    return value;
  } else if (duration > 7 && duration <= 30 && index % 5 === 0) {
    return value;
  } else if (duration > 30 && duration <= 90 && index % 2 === 0) {
    return value;
  } else if (duration > 90) {
    if (messages.length > 7) {
      return index % 2 === 0 ? value : null;
    }
    return value;
  }
  return null;
}

export function getTicksForResponseGraph(value, index, duration, ticksValues) {
  if (duration === 1 && index % 3 === 0) {
    return value;
  } else if (duration > 1 && duration <= 7 && index % 1 === 0) {
    return value;
  } else if (duration > 7 && duration <= 30 && index % 5 === 0) {
    return value;
  } else if (duration > 30 && duration <= 90 && index % 10 === 0) {
    return value;
  } else if (duration > 90) {
    const filterTickValue = ticksValues.find((tickValue, tickIndex) => {
      if (ticksValues.length > 7) {
        return tickIndex % 2 === 0 && tickValue.index === index;
      } else {
        return tickValue.index === index;
      }
    });
    return filterTickValue && filterTickValue.label;
  }
  return null;
}

export function calculateDateRange(duration) {
  let fromDate = moment().subtract(duration, 'days');
  const toDate = moment().subtract(1, 'days');

  if (duration === 'year') {
    fromDate = moment().subtract(1, 'years');
  }
  return { startDate: fromDate, endDate: toDate };
}
