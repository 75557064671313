import React, { useState, useEffect } from 'react';
import { Button, UtilityInlineGrid, Icon } from 'rhinostyle';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useContactForms } from '../hooks';
import NotificationService from '../services/NotificationService';
import PdfViewerContainer from '../containers/PdfViewerContainer';
import FormActionButton from './FormActionButton';

import {
  DateHelpers,
  SavedContentHelpers,
} from '../helpers';

const ContactProfileForms = (props) => {
  const {
    activeUser,
  } = props;
  const [contactForms, setContactForms] = useState([]);
  const [isViewAllOpen, setViewAllOpen] = useState(false);
  const [isPdfViewerOpen, openPdfViewer] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const forms = useContactForms();

  useEffect(() => {
    if (forms?.length > 0) {
      const expiredForms = [];
      const onlineForms = [];
      forms.forEach((form) => {
        if (form.status === 'Expired') {
          expiredForms.push(form);
        } else {
          onlineForms.push(form);
        }
      });
      // Shows most recent expired form in profile if no non expired versions
      expiredForms.forEach((expiredForm) => {
        if (!(onlineForms.find((form) => form?.formTemplateId === expiredForm.formTemplateId))) {
          onlineForms.push(expiredForm);
        }
      });
      setContactForms(onlineForms);
    }
  }, [forms]);

  const openFormUrl = (url) => {
    window.open(url, '_blank');
  };

  const handleCopyLinkClick = () => {
    const response = {
      status: 200,
    };
    NotificationService('formLinkCopy', response);
  };

  function handlePdfViewer(form) {
    openPdfViewer(true);
    if (form) {
      setAttachmentUrl(form.formInternalData);
    }
  }

  const formStatus = (form) => (form.status === 'Expired' ?
    <span className="u-text-danger">Expired</span> :
    <><span className="u-text-success">{form.status === 'Created' ? 'Generated' : form.status}</span> {DateHelpers.formatTimestamp(form.updatedAt)}</>);

  const formActions = (form) => (
    <UtilityInlineGrid className="u-p-t-small">
      {((form.status === 'Created') || (form.status === 'Sent')) && (
        <>
          <FormActionButton
            label="open form"
            icon="open-form"
            toggleButton={() => openFormUrl(form.formUrl)}
            className="u-m-r-small"
          />
          <div className="rhinoform__action-button">
            <CopyToClipboard onCopy={handleCopyLinkClick} text={form.formUrl}>
              <Button
                type="link"
                size="large"
              >
                <Icon
                  icon="hyperlink"
                  className="rhinoform__action-button__icon rhinoform__action-button__icon--bold"
                />
              </Button>
            </CopyToClipboard>
            <div className="rhinoform__action-button__label">
              copy form link
            </div>
          </div>
        </>
      )}
      {form.status === 'Completed' && (
        <FormActionButton
          label="view form"
          icon="pdfFile"
          toggleButton={() => handlePdfViewer(form)}
        />
      )}
    </UtilityInlineGrid>
  );

  const visbileForms = isViewAllOpen && contactForms.length > 6 ? contactForms : contactForms.slice(0, 6);
  return (
    <>
      {visbileForms?.map((form) => (
        <div className="u-m-b u-flex u-flex-row u-flex-justify-between" key={form._id}>
          <div className="u-flex-column">
            <div className="u-font-weight-bold u-text-body" data-cypress="contactFormTitle">{form.title}</div>
            <div className="u-text-muted u-text-small" data-cypress="contactFormStatus">{formStatus(form)}</div>
            <div className=" u-text-small" data-cypress="contactFormLink">{formActions(form)}</div>
          </div>
        </div>
      ))}
      {contactForms.length > 6 && !isViewAllOpen && (
        <Button className="u-text-primary profile__forms__expand" onClick={() => setViewAllOpen(!isViewAllOpen)} reset>
          View All Activity
        </Button>
      )}
      <PdfViewerContainer
        open={!!(isPdfViewerOpen && attachmentUrl)}
        attachmentUrl={SavedContentHelpers.getAttachmentUrl(attachmentUrl, activeUser)}
        fileName={attachmentUrl}
        handlePdfViewerClose={() => openPdfViewer(!isPdfViewerOpen)}
      />
    </>
  );
};

export default ContactProfileForms;
