import { formatNumberToCurrencyString } from './NumberHelpers';

// eslint-disable-next-line import/prefer-default-export
export function generatePaymentRequestMessageText(paymentRequest, org) {
  const { note, requestAmount, shortLinkCode } = paymentRequest;
  const { name: orgName } = org;
  const url = `${process.env.REACT_APP_URL}/rhinopay/${shortLinkCode}`;
  const formattedAmount = formatNumberToCurrencyString(requestAmount);
  const formattedNote = note ? ` (NOTE: ${note})` : '';

  return `${orgName} is requesting payment in the amount of $${formattedAmount}${formattedNote}. Please click this link to pay: ${url}`;
}
