import {
  TYPE_FORM_SENT,
  TYPE_FORM_COMPLETED,
  TYPE_FORM_CREATED,
} from './Types';

export const DATAPICKER_DROPDOWN_LABELS = [
  { id: 1, label: 'Today', duration: 0 },
  { id: 2, label: 'Yesterday', duration: 1 },
  { id: 3, label: 'Last 7 Days', duration: 6 },
  { id: 4, label: 'Last 30 Days', duration: 29 },
  { id: 5, label: 'Last 90 Days', duration: 89 },
  { id: 6, label: 'Last 12 Months', duration: 'year' },
  { id: 7, label: 'Custom Date' }];

export const FORMS_MANAGER_HEADERS_CONFIG = {
  createdAt: {
    default: true,
    direction: -1,
  },
  title: {
    default: false,
    direction: 1,
  },
  status: {
    default: false,
    direction: 1,
  },
  contact: {
    default: false,
    direction: 1,
  },
  lastUpdatedAt: {
    default: false,
    direction: 1,
  },
  sentToEhr: {
    default: false,
    direction: 1,
  },
  formInternalData: {
    default: false,
    direction: 1,
  },
};

export const FORM_STATUS_COMPLETED = 'Completed';

export const PATIENT_FORMS_STATUS_OPTIONS = [
  { id: FORM_STATUS_COMPLETED, value: FORM_STATUS_COMPLETED },
  { id: 'Sent', value: 'Sent' },
  { id: 'Expired', value: 'Expired' },
  { id: 'Generated', value: 'Generated' },
];

export const RHINOFORM_MANAGER_URL = '/managers/rhinoforms-manager';

export const FORMS_STATUS_OPTIONS = {
  [TYPE_FORM_SENT]: 'Sent',
  [TYPE_FORM_COMPLETED]: 'Completed',
  [TYPE_FORM_CREATED]: 'Generated',
};
