import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Icon,
  Bucket,
  BucketBody,
  RadioGroup,
  Radio,
  Table,
  UtilityInlineGrid,
} from 'rhinostyle';
import BackButtonContainer from '../containers/BackButtonContainer';
import { Types } from '../constants';
import {
  IMPORT_STEP_PREVIEW_FILE,
  IMPORT_STEP_UPLOAD_FILE,
  IMPORT_STEP_CONFIRMATION,
  IMPORT_TYPE_CONNECTED_PARTIES,
  IMPORT_TYPE_USERS,
  IMPORT_TYPE_APPOINTMENTS,
} from '../constants/CSVImportConstants';
import * as AppConstants from '../constants/AppConstants';
import { generateUUID } from '../helpers/DataHelpers';

const CSVImport = (props) => {
  const history = useHistory();
  const routeLocation = useLocation();

  let uploaderInput;

  const {
    importStep,
    importType,
    contactType,
    doesCSVIncludeUserType,
    errorList,
    handleCancelCSVUploadClick,
    handleImportClick,
    handleContactTypeSelect,
    handleImportFile,
    isLoading,
    invalidCount,
    handleInvalidDownload,
    CSVPreview,
    CSVPreviewConnectedParties,
  } = props;

  const {
    firstName,
    middleName,
    lastName,
    preferredName,
    birthday,
    email,
    workEmail,
    homePhone,
    homePhoneLabel,
    cellPhone,
    cellPhoneLabel,
    workPhone,
    workPhoneLabel,
    phone,
    phoneLabel,
    sex,
    externalId,
    tags,
    note,
    userType,
    appointmentId,
    appointmentDate,
    duration,
    location,
    appointmentType,
    appointmentStatus,
    timeZone,
    providerId,
    providerFullName,
    providerFirstName,
    providerLastName,
    providerPrefix,
    providerSuffix,
    providerTitle,
  } = CSVPreview;

  const handleImportFileClick = () => {
    uploaderInput.click();
  };

  const renderAlert = () => <Alert className="u-m-b" type="danger">{Object.keys(errorList).map((err) => errorList[err].message).join(', ')}</Alert>;

  const renderTitleText = (appointments, contacts, connectedParties) => {
    let value;
    if (importType === IMPORT_TYPE_APPOINTMENTS) {
      value = appointments;
    } else if (importType === IMPORT_TYPE_USERS) {
      value = contacts;
    } else {
      value = connectedParties;
    }
    return value;
  };

  const renderCSVPreviewListForImportUsers = () => (
    <ul className="u-list u-m-b-0">
      {doesCSVIncludeUserType && <li><strong>userType:</strong> {userType}</li>}
      <li><strong>firstName:</strong> {firstName}</li>
      <li><strong>middleName:</strong> {middleName}</li>
      <li><strong>lastName:</strong> {lastName}</li>
      <li><strong>preferredName:</strong> {preferredName}</li>
      <li><strong>birthday:</strong> {birthday}</li>
      <li><strong>email:</strong> {email}</li>
      <li><strong>workEmail:</strong> {workEmail}</li>
      <li><strong>homePhone:</strong> {homePhone}</li>
      <li><strong>homePhoneLabel:</strong> {homePhoneLabel}</li>
      <li><strong>cellPhone:</strong> {cellPhone}</li>
      <li><strong>cellPhoneLabel:</strong> {cellPhoneLabel}</li>
      <li><strong>workPhone:</strong> {workPhone}</li>
      <li><strong>workPhoneLabel:</strong> {workPhoneLabel}</li>
      <li><strong>phone:</strong> {phone}</li>
      <li><strong>phoneLabel:</strong> {phoneLabel}</li>
      <li><strong>sex:</strong> {sex}</li>
      <li><strong>tags:</strong> {tags}</li>
      <li><strong>note:</strong> {note}</li>
      <li><strong>externalId:</strong> {externalId}</li>
    </ul>
  );

  const renderCSVPreviewListForImportAppointments = () => (
    <ul className="u-list u-m-b-0">
      <li><strong>appointmentId:</strong> {appointmentId}</li>
      <li><strong>externalId:</strong> {externalId}</li>
      <li><strong>firstName:</strong> {firstName}</li>
      <li><strong>lastName:</strong> {lastName}</li>
      <li><strong>birthday:</strong> {birthday}</li>
      <li><strong>appointmentDate:</strong> {appointmentDate}</li>
      <li><strong>duration:</strong> {duration}</li>
      <li><strong>location:</strong> {location}</li>
      <li><strong>appointmentType:</strong> {appointmentType}</li>
      <li><strong>appointmentStatus:</strong> {appointmentStatus}</li>
      <li><strong>timeZone:</strong> {timeZone}</li>
      <li><strong>homePhone:</strong> {homePhone}</li>
      <li><strong>homePhoneLabel:</strong> {homePhoneLabel}</li>
      <li><strong>cellPhone:</strong> {cellPhone}</li>
      <li><strong>cellPhoneLabel:</strong> {cellPhoneLabel}</li>
      <li><strong>workPhone:</strong> {workPhone}</li>
      <li><strong>workPhoneLabel:</strong> {workPhoneLabel}</li>
      <li><strong>phone:</strong> {phone}</li>
      <li><strong>phoneLabel:</strong> {phoneLabel}</li>
      <li><strong>providerId:</strong> {providerId}</li>
      <li><strong>providerFullName:</strong> {providerFullName}</li>
      <li><strong>providerFirstName:</strong> {providerFirstName}</li>
      <li><strong>providerLastName:</strong> {providerLastName}</li>
      <li><strong>providerPrefix:</strong> {providerPrefix}</li>
      <li><strong>providerSuffix:</strong> {providerSuffix}</li>
      <li><strong>providerTitle:</strong> {providerTitle}</li>
    </ul>
  );

  const viewHeaderTitle = (importType === IMPORT_TYPE_CONNECTED_PARTIES || !doesCSVIncludeUserType) && importType !== IMPORT_TYPE_APPOINTMENTS;

  const renderCSVPreview = () => (
    <>
      {viewHeaderTitle && (
        <div className="box__title-wrapper">
          <div className="box__title">{importType === IMPORT_TYPE_USERS ? 'Contact Type' : 'Preview'}</div>
          {importType === IMPORT_TYPE_CONNECTED_PARTIES && (
            <div className="box__subtitle">
              {'All Contact\'s "From ID" will be connected to Contact\'s "To ID" based on the file provided.'}
            </div>
          )}
        </div>
      )}
      {Object.keys(errorList).length > 0 && renderAlert()}
      {importType === IMPORT_TYPE_USERS || importType === IMPORT_TYPE_APPOINTMENTS ? (
        <>
          {!doesCSVIncludeUserType && importType !== IMPORT_TYPE_APPOINTMENTS && (
            <div className="form__group u-m-b-large">
              <RadioGroup name="contactType" blockGroup selectedValue={contactType} onChange={handleContactTypeSelect}>
                <Radio value={Types.TYPE_PATIENT} label={<span className="form__block-group__label">Patient</span>} />
                <Radio value={Types.TYPE_USER_OTHER} label={<span className="form__block-group__label">Other</span>} />
              </RadioGroup>
            </div>
          )}
          <div className="box__title-wrapper u-p-t-small">
            <div className="box__title">Example {importType === IMPORT_TYPE_APPOINTMENTS ? 'Appointment' : 'Contact'}</div>
            <div className="box__subtitle">This example is based on the file selected to import</div>
          </div>
          <Bucket className="u-bg-white">
            <BucketBody>
              {importType === IMPORT_TYPE_USERS ? renderCSVPreviewListForImportUsers() : renderCSVPreviewListForImportAppointments()}
            </BucketBody>
          </Bucket>
        </>
      ) : (
        <Table striped bordered>
          <thead>
            <tr>
              <th className="u-text-body u-font-weight-bold">FROM ID</th>
              <th className="u-text-body u-font-weight-bold">TO ID</th>
            </tr>
          </thead>
          <tbody>
            {/* Only pull out a max of 5 rows for the preview */}
            {CSVPreviewConnectedParties.map((row) => (
              <tr key={generateUUID()} className="u-text-muted u-text-small">
                <td className={!row.fromExternalId ? 'table__data--danger' : ''}>
                  {row.fromExternalId ? `#${row.fromExternalId}` : 'No "From ID" in row'}
                </td>
                <td className={!row.toExternalId ? 'table__data--danger' : ''}>
                  {row.toExternalId ? `#${row.toExternalId}` : 'No "To ID" in row'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );

  return (
    <div className="app-page__container">
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title">
            {importStep === IMPORT_STEP_UPLOAD_FILE ? (
              <BackButtonContainer navigateTo="/contacts" history={history} location={routeLocation} />
            ) : (
              <Button
                reset
                className="u-text-primary u-m-r-small"
                onClick={handleCancelCSVUploadClick}
                title="Go back"
              >
                <Icon bump="down" icon="arrow-left" />
              </Button>
            )}
            {renderTitleText('Import Appointments', 'Import Contacts', 'Connect Contacts')}
          </div>
        </div>
        <div className="box">
          {importStep === IMPORT_STEP_UPLOAD_FILE && (
            <>
              <div className="box__title-wrapper">
                <div className="box__title">{renderTitleText('Import Appointments', 'Import Contacts', 'Connect Contacts')}</div>
                <div className="box__subtitle">
                  {
                    `To import ${renderTitleText('Appointments', 'Contacts', 'Connected Parties')}
                    start by uploading your CSV file. Click the button below to begin.`
                  }
                </div>
              </div>
              <input
                className="u-sr-only"
                type="file"
                ref={(c) => (uploaderInput = c)}
                accept={AppConstants.IMPORT_FILE_TYPES}
                onClick={() => { uploaderInput.value = null; }}
                onChange={handleImportFile}
              />
              <div className="u-text-center">
                <Button type="secondary" onClick={handleImportFileClick}>Upload CSV</Button>
              </div>
            </>
          )}
          {importStep === IMPORT_STEP_PREVIEW_FILE && renderCSVPreview()}
          {importStep === IMPORT_STEP_CONFIRMATION && isLoading && <h1 className="u-m-a-0 u-text-center">Import in progress...</h1>}
          {importStep === IMPORT_STEP_CONFIRMATION && !isLoading && invalidCount && (
            // this code should currently not display, but should be preserved for when functionality is restored
            <>
              <p className="u-m-t-large">
                {invalidCount} contacts could not be imported. These contacts can be downloaded as a new .CSV file which can be modified/fixed and imported again.
              </p>
              <Button onClick={handleInvalidDownload}><Icon icon="download" />&nbsp; Download Error File</Button>
            </>
          )}
          {importStep === IMPORT_STEP_CONFIRMATION && !isLoading && !invalidCount && (
            <Alert
              type="success"
              title={`${renderTitleText('Appointments', 'Contacts', 'Contacts')} are currently being ${renderTitleText('imported', 'imported', 'connected')}. You will receive a confirmation email once users have been loaded into the import queue.`} // eslint-disable-line max-len
              titleIcon="checkmark"
            />
          )}
        </div>
        <UtilityInlineGrid align="right" className="u-m-t-large">
          {importStep === IMPORT_STEP_PREVIEW_FILE && (
            <>
              <Button type="secondary" onClick={handleImportClick}>{renderTitleText('Import Appointments', 'Import Contacts', 'Connect Contacts')}</Button>
            </>
          )}
          {importStep === IMPORT_STEP_CONFIRMATION && (
            <Link to="/contacts">
              <Button type="secondary" disabled={isLoading}>Finish</Button>
            </Link>
          )}
        </UtilityInlineGrid>
      </div>
    </div>
  );
};

CSVImport.propTypes = {
  importStep: PropTypes.number,
  contactType: PropTypes.number,
  doesCSVIncludeUserType: PropTypes.bool,
  errorList: PropTypes.object,
  handleImportFile: PropTypes.func,
  handleCancelCSVUploadClick: PropTypes.func,
  handleImportClick: PropTypes.func,
  handleContactTypeSelect: PropTypes.func,
  isLoading: PropTypes.bool,
  importType: PropTypes.string,
  invalidCount: PropTypes.number,
  handleInvalidDownload: PropTypes.func,
  CSVPreview: PropTypes.object,
  CSVPreviewConnectedParties: PropTypes.array,
};

export default CSVImport;
