import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Radio,
  RadioGroup,
  Select,
  Date,
  Textarea,
} from 'rhinostyle';
import EmojiPickerDropdown from './EmojiPickerDropdown';

import {
  TYPE_PATIENT,
  TYPE_USER_OTHER,
} from '../constants/Types';
import PhoneGroup from './ContactProfile/PhoneGroup';
import EmailGroup from './ContactProfile/EmailGroup';
import CommuncationConsent from './ContactProfile/CommunicationConsent';

const NewConnectedPartyContactModal = (props) => {
  const textareaRef = useRef();
  const {
    actionTitle,
    actionType,
    birthday,
    closeModal,
    emailTypes,
    errors,
    externalId,
    firstName,
    formInProgress,
    handlePhoneSearchIdx,
    handleBirthdayChange,
    handleChange,
    handleFormChanges,
    handleReceiveContactData,
    handleRemoveContactData,
    handleSearch,
    handleSetModalContainer,
    handleSubmit,
    hipaaStatusTypeId,
    integrated,
    lastName,
    isRhinopayEnabled,
    marketingConsentStatusTypeId,
    middleName,
    note,
    noteIsImportant,
    open,
    modalPhoneSearchLoading,
    phoneTypes,
    preferredName,
    prefixId,
    prefixOpts,
    resetState,
    sex,
    suffixId,
    suffixOpts,
    typeId,
    userEmails,
    userId,
    userPhones,
    searchText,
    phones,
    phoneSearchIdx,
    rhinopayConsentStatusTypeId,
    modalPhoneSearchIds,
    clearUserSearch,
    users,
  } = props;

  return (
    <Modal onReverseComplete={resetState} open={open} ref={(el) => handleSetModalContainer(el)}>
      {/* Remove from DOM when modal is closed to reset component state */}
      {open && (
        <>
          <ModalHeader onClose={closeModal} title="Create Contact" />
          <ModalBody>
            <form onChange={handleFormChanges}>
              <RadioGroup
                label="Contact Type"
                inline
                name="typeId"
                onChange={(id) => handleChange('typeId', id)}
                selectedValue={typeId}
                required
                validationMessage={errors.typeId}
              >
                <Radio
                  value={TYPE_PATIENT}
                  label={<span className="form__block-group__label">Patient</span>}
                />
                <Radio
                  value={TYPE_USER_OTHER}
                  label={<span className="form__block-group__label">Other</span>}
                />
              </RadioGroup>
              <div className="form__group">
                <div className="row">
                  <div className="column-4@small">
                    <Input
                      disabled={integrated}
                      initialValue={firstName}
                      label="First Name"
                      name="firstName"
                      onChange={handleChange}
                      required
                      validationMessage={errors.firstName}
                    />
                  </div>
                  <div className="column-4@small">
                    <Input
                      disabled={integrated}
                      initialValue={middleName}
                      label="Middle Name"
                      name="middleName"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="column-4@small">
                    <Input
                      disabled={integrated}
                      initialValue={lastName}
                      label="Last Name"
                      name="lastName"
                      onChange={handleChange}
                      required
                      validationMessage={errors.lastName}
                    />
                  </div>
                  <div className="column-4@small">
                    <Input
                      disabled={integrated}
                      initialValue={preferredName}
                      label="Preferred Name"
                      name="preferredName"
                      onChange={handleChange}
                      validationMessage={errors.preferredName}
                    />
                  </div>
                  <div className="column-3@xsmall">
                    <Select onSelect={handleChange} disabled={integrated} options={prefixOpts} selected={prefixId} name="prefixId" label="Prefix" />
                  </div>
                  <div className="column-3@xsmall">
                    <Select onSelect={handleChange} disabled={integrated} options={suffixOpts} selected={suffixId} name="suffixId" label="Suffix" />
                  </div>
                </div>
              </div>
              <div className="form__group">
                <div className="row">
                  <div className="column-7@small">
                    <Date
                      disabled={integrated}
                      id="birthday"
                      label="Birthday"
                      name="birthday"
                      placeholderText="Select a date"
                      maxDate={moment()}
                      required={typeId === TYPE_PATIENT}
                      selected={birthday}
                      validationMessage={errors.birthday}
                      onChange={(date) => {
                        if (date) {
                          handleBirthdayChange(date);
                        }
                      }}
                      onChangeRaw={() => handleChange('birthday', null)}
                    />
                  </div>
                </div>
              </div>
              <div className="form__group">
                <div className="row">
                  <div className="column-6@xsmall column-6@small">
                    <Input
                      disabled={integrated}
                      initialValue={externalId}
                      label="ID Number"
                      name="externalId"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="column-6@small">
                    <RadioGroup name="sex" onChange={(id) => handleChange('sex', id)} selectedValue={sex} inline label="Gender">
                      <Radio disabled={integrated} value="male" label="Male" />
                      <Radio disabled={integrated} value="female" label="Female" />
                    </RadioGroup>
                  </div>
                </div>
              </div>

              <PhoneGroup
                activeUser={{ firstName, lastName }}
                errorList={errors}
                handleRemoveContactData={handleRemoveContactData}
                receiveContactData={handleReceiveContactData}
                integrated={integrated}
                types={phoneTypes}
                userId={userId}
                userPhones={userPhones}
                loading={modalPhoneSearchLoading}
                handleSearch={handleSearch}
                searchText={searchText}
                phones={phones}
                handlePhoneSearchIdx={handlePhoneSearchIdx}
                phoneSearchIdx={phoneSearchIdx}
                phoneUserSearchIds={modalPhoneSearchIds}
                clearUserSearch={clearUserSearch}
                users={users}
              />

              <EmailGroup
                errorList={errors}
                handleRemoveContactData={handleRemoveContactData}
                receiveContactData={handleReceiveContactData}
                integrated={integrated}
                types={emailTypes}
                userEmails={userEmails}
              />

              <Textarea
                explanationMessage="This note will be displayed in the profile summary."
                initialValue={note}
                label="Note"
                name="note"
                onChange={handleChange}
                rows={3}
                textareaRef={textareaRef}
                emojiSupport
              />
              <div className="u-flex u-flex-direction-row u-flex-align-items-center u-m-t-small u-m-b">
                <EmojiPickerDropdown
                  inputName="note"
                  inputValue={note}
                  inputRef={textareaRef}
                  handleInputChange={handleChange}
                  dropdownPosition="top"
                />
              </div>
              <CheckboxGroup>
                <Checkbox
                  name="noteIsImportant"
                  onChange={handleChange}
                  isChecked={noteIsImportant}
                  label="Mark note as important"
                />
              </CheckboxGroup>

              {(typeId === TYPE_PATIENT || typeId === TYPE_USER_OTHER) && (
                <div className="u-m-t-large">
                  <CommuncationConsent
                    users={props.users}
                    activeUser={props.users[props.userId]}
                    handleChange={handleChange}
                    hipaaStatusTypeId={hipaaStatusTypeId}
                    isRhinopayEnabled={isRhinopayEnabled}
                    marketingConsentStatusTypeId={marketingConsentStatusTypeId}
                    rhinopayConsentStatusTypeId={rhinopayConsentStatusTypeId}
                  />
                </div>
              )}
              <div className="u-m-t-large u-text-right">
                <Button loading={formInProgress} type={actionType} onClick={handleSubmit}>{actionTitle}</Button>
              </div>
            </form>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

NewConnectedPartyContactModal.propTypes = {
  actionTitle: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  birthday: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  emailTypes: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  externalId: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  formInProgress: PropTypes.bool.isRequired,
  handleBirthdayChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func,
  handlePhoneSearchIdx: PropTypes.func,
  handleReceiveContactData: PropTypes.func.isRequired,
  handleRemoveContactData: PropTypes.func.isRequired,
  handleSearch: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  hipaaStatusTypeId: PropTypes.number,
  integrated: PropTypes.bool.isRequired,
  lastName: PropTypes.string,
  marketingConsentStatusTypeId: PropTypes.number,
  note: PropTypes.string,
  noteIsImportant: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  modalPhoneSearchLoading: PropTypes.bool,
  isRhinopayEnabled: PropTypes.bool,
  phoneTypes: PropTypes.array.isRequired,
  preferredName: PropTypes.string,
  prefixId: PropTypes.number,
  prefixOpts: PropTypes.array.isRequired,
  resetState: PropTypes.func.isRequired,
  sex: PropTypes.string,
  suffixId: PropTypes.number,
  suffixOpts: PropTypes.array.isRequired,
  typeId: PropTypes.number.isRequired,
  userEmails: PropTypes.object.isRequired,
  userId: PropTypes.number,
  userPhones: PropTypes.object.isRequired,
  handleSetModalContainer: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  phones: PropTypes.object,
  phoneSearchIdx: PropTypes.number,
  rhinopayConsentStatusTypeId: PropTypes.number,
  modalPhoneSearchIds: PropTypes.array,
  clearUserSearch: PropTypes.func,
  users: PropTypes.object,
};

export default NewConnectedPartyContactModal;
