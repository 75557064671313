import BandwidthRtc from '@bandwidth/webrtc-browser';
import { getBandwidthWebRTCToken } from './RhinocallService';

const bandwidthRTC = new BandwidthRtc();
export async function connectToBandwidth(payload) {
  const tokenResponse = await getBandwidthWebRTCToken(payload);
  const { token, sessionId } = tokenResponse.data;
  await bandwidthRTC.connect({
    deviceToken: token,
  });
  return sessionId;
}

export function publishAudio(deviceId) {
  const audioConstraints = deviceId && deviceId !== 'default' ? {
    deviceId,
  } : true;
  return bandwidthRTC.publish({ audio: audioConstraints, video: false });
}

export function listen(callback) {
  bandwidthRTC.onStreamAvailable((stream) => {
    if (callback) {
      callback(stream);
    }
  });
  bandwidthRTC.onStreamUnavailable(() => {
    if (callback) {
      callback(null);
    }
  });
}

export function setMicEnabled(value) {
  if (bandwidthRTC.delegate) {
    bandwidthRTC.setMicEnabled(value);
  }
}

export function handleDialpadClick(value) {
  bandwidthRTC.sendDtmf(value);
}

export async function disconnectBandwidthWebRTC(isAudioPublished) {
  if (bandwidthRTC?.delegate) {
    if (isAudioPublished) {
      await bandwidthRTC.unpublish();
    }
    await bandwidthRTC.disconnect();
  }
}
