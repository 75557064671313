import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as OrganizationReducer from '../reducers/organizationReducer';
import OrganizationPreferences from '../components/OrganizationPreferences';
import unsavedChanges from '../components/UnsavedChangesHOC';
import { ChannelHelpers, PhoneHelpers, DataHelpers, ValidationHelpers, DateHelpers } from '../helpers';
import { getLoggedInUserOrganization, getLoggedInUser } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { getAutomatedMessageChannelIds } from '../selectors/channelSelectors';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import PageLoader from '../components/PageLoader';

class OrganizationPreferencesContainer extends Component {
  state = {
    allowClosingByAssignee: false,
    isCreatedWithinHoursOfStartDateEnabled: false,
    defaultChannelId: -1,
    pageLoading: true,
    soundOn: false,
    sessionTimeoutMinutes: '',
    errors: {},
    isMessageTranslationEnabled: false,
    allowAutoConversationAssignment: false,
    areChannelFiltersEnabled: false,
    areOfficesVisible: false,
    offices: {},
    selectedChannel: this.props.selectedChannel,
    timeZone: '',
    canSendAppointmentToConnectedParties: false,
    hasAppointmentCampaigns: false,
  };

  componentDidMount() {
    this.props.fetchOrganizationPreferencesView().then(() => {
      this.setOfficeVisibility();
      const offices = this.props.currentOrganization.offices.reduce((acc, office) => {
        acc[office.id] = (this.props.SMSChannelIds.includes(office.channelId)) ? office.channelId : -1;
        return acc;
      }, {});

      this.setState({ offices });
      if (this.state.selectedChannel && this.props.SMSChannelIds.includes(this.state.selectedChannel)) {
        this.setState({ timeZone: this.getTimeZone(this.state.selectedChannel) });
      } else if (!this.state.selectedChannel || !this.props.SMSChannelIds.includes(this.state.selectedChannel)) {
        this.setState({ selectedChannel: -1 });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const organization = this.props.userOrganization;

    if (DataHelpers.hasData(organization) && this.state.defaultChannelId === -1) {
      const {
        allowAutoConversationAssignment,
        areChannelFiltersEnabled,
        allowClosingByAssignee,
        appointmentCampaigns,
        isCreatedWithinHoursOfStartDateEnabled,
        canSendAppointmentToConnectedParties,
        defaultApptReminderChannelId,
        defaultChannelId,
        isMessageTranslationEnabled,
        sessionTimeoutMinutes,
        soundOn,
      } = organization;

      this.setState({ // eslint-disable-line react/no-did-update-set-state
        allowAutoConversationAssignment,
        areChannelFiltersEnabled,
        allowClosingByAssignee,
        isCreatedWithinHoursOfStartDateEnabled,
        canSendAppointmentToConnectedParties,
        defaultChannelId,
        hasAppointmentCampaigns: appointmentCampaigns.length > 0,
        isMessageTranslationEnabled,
        selectedChannel: defaultApptReminderChannelId,
        sessionTimeoutMinutes: sessionTimeoutMinutes.toString(),
        soundOn,
      });
    }

    if (this.props.pageLoading !== prevProps.pageLoading) {
      this.setState({ pageLoading: this.props.pageLoading }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  get payload() {
    const offices = Object.keys(this.state.offices).reduce((acc, currVal) => {
      const channelId = this.state.offices[currVal] > 0 ? this.state.offices[currVal] : null;
      const office = { id: Number(currVal), channelId };

      return acc.concat(office);
    }, []);

    const payload = {
      allowAutoConversationAssignment: this.state.allowAutoConversationAssignment,
      areChannelFiltersEnabled: this.state.areChannelFiltersEnabled,
      allowClosingByAssignee: this.state.allowClosingByAssignee,
      isCreatedWithinHoursOfStartDateEnabled: this.state.isCreatedWithinHoursOfStartDateEnabled,
      canSendAppointmentToConnectedParties: this.state.canSendAppointmentToConnectedParties,
      defaultApptReminderChannelId: this.props.currentOrganization.supportsAppointmentReminders ? this.state.selectedChannel : null,
      defaultChannelId: this.state.defaultChannelId,
      isMessageTranslationEnabled: this.state.isMessageTranslationEnabled,
      offices,
      sessionTimeoutMinutes: parseInt(this.state.sessionTimeoutMinutes, 10),
      soundOn: this.state.soundOn,
    };

    return payload;
  }

  getTimeZone = (channelId) => (channelId && this.props.SMSChannelIds.includes(channelId) ? DateHelpers.formatTimeZone(this.props.timeZones[this.props.channels[channelId].timeZoneId]) : ''); // eslint-disable-line max-len

  // handleFormChanges() needed here since we're using it on top of resource click for default secure channel
  handleChange = (name, value) => {
    if (name === 'isCreatedWithinHoursOfStartDateEnabled') {
      // eslint-disable-next-line no-param-reassign
      value = value !== 'false';
    }

    if (name === 'selectedChannel') {
      const timeZone = this.getTimeZone(value);
      this.setState({ [name]: value, timeZone }, () => {
        this.props.handleFormChanges();
      });
    } else {
      this.setState({ [name]: value }, () => {
        this.props.handleFormChanges();
      });
    }
  };

  handleOfficeChannelSelect = (id, value) => {
    const offices = { ...this.state.offices };

    offices[id] = value;

    this.setState({ offices });
  }

  setOfficeVisibility = () => {
    const areOfficesVisible = this.props.currentOrganization?.offices?.length > 0;
    this.setState({
      areOfficesVisible,
    });
  }

  handleSubmit = () => {
    const errors = ValidationService(ValidationShapers.shapeOrgPreferences(this.payload, this.props.currentOrganization.supportsAppointmentReminders));
    const errorCount = Object.keys(errors).length;

    if (errorCount > 0) {
      this.setState({ errors }, () => {
        ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
      });
    } else {
      this.props
        .updateOrganizationFromPreferences(this.payload)
        .finally(() => {
          this.setState({ errors: {} });
          this.props.resetFormChanges();
        });
    }
  };

  shapeChannels(channelIds, channels) {
    return channelIds.reduce((acc, channelId) => {
      const channel = this.shapeChannelForRender(channels[channelId]);

      return { ...acc, [channel.id]: channel };
    }, {});
  }

  shapeChannelForRender(channel) {
    return {
      icon: ChannelHelpers.getChannelIcon(channel.typeId),
      id: channel.id,
      name: channel.name,
      phone: DataHelpers.hasData(channel.details) && this.props.phones[channel.details.phone] &&
        PhoneHelpers.formatPhone(this.props.phones[channel.details.phone].value),
      route: ChannelHelpers.formatRoute(channel.route),
      tags: channel.tags && channel.tags.length > 0 ? channel.tags.map((id) => this.props.tags[id]) : [],
    };
  }

  render() {
    if (this.state.pageLoading || this.props.channelsLoading) {
      return <PageLoader />;
    }

    const props = {
      allowAutoConversationAssignment: this.state.allowAutoConversationAssignment,
      areChannelFiltersEnabled: this.state.areChannelFiltersEnabled,
      allowClosingByAssignee: this.state.allowClosingByAssignee,
      isCreatedWithinHoursOfStartDateEnabled: this.state.isCreatedWithinHoursOfStartDateEnabled,
      areOfficesVisible: this.state.areOfficesVisible,
      canSendAppointmentToConnectedParties: this.state.canSendAppointmentToConnectedParties,
      channelIds: this.props.SMSChannelIds,
      channels: this.shapeChannels(this.props.SMSChannelIds, this.props.channels),
      currentOrganization: this.props.currentOrganization,
      defaultChannelId: this.state.defaultChannelId,
      errors: this.state.errors,
      formInProgress: this.props.formInProgress,
      handleChange: this.handleChange,
      handleFormChanges: this.props.handleFormChanges,
      handleOfficeChannelSelect: this.handleOfficeChannelSelect,
      handleSubmit: this.handleSubmit,
      hasAppointmentCampaigns: this.state.hasAppointmentCampaigns,
      isAutomatedMessageToConnectedPartiesEnabled: this.props.userOrganization.isAutomatedMessageToConnectedPartiesEnabled,
      isMessageTranslationEnabled: this.state.isMessageTranslationEnabled,
      isMessageTranslationFeatureEnabled: this.props.userOrganization.isMessageTranslationFeatureEnabled,
      offices: this.state.offices,
      pageContainerRef: (pageContainer) => (this.pageContainer = pageContainer),
      selectedChannel: this.state.selectedChannel,
      sessionTimeoutMinutes: this.state.sessionTimeoutMinutes,
      soundOn: this.state.soundOn,
      timeZone: this.state.timeZone,
    };

    return <OrganizationPreferences {...props} />;
  }
}

OrganizationPreferencesContainer.propTypes = {
  channels: PropTypes.object.isRequired,
  fetchOrganizationPreferencesView: PropTypes.func.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  phones: PropTypes.object.isRequired,
  SMSChannelIds: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  updateOrganizationFromPreferences: PropTypes.func.isRequired,
  userOrganization: PropTypes.object.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  resetFormChanges: PropTypes.func.isRequired,
  selectedChannel: PropTypes.number,
  currentOrganization: PropTypes.object,
  channelsLoading: PropTypes.bool,
  timeZones: PropTypes.object,
};

const mapStateToProps = (state) => {
  const currentOrganization = getCurrentOrg(state);
  const { channel, organization, phone, tag, ui, timeZone } = state;
  const {
    defaultApptReminderChannelId,
  } = currentOrganization || {};

  return {
    isCreatedWithinHoursOfStartDateEnabled: currentOrganization?.isCreatedWithinHoursOfStartDateEnabled,
    channels: channel.channels,
    channelsLoading: channel.loading,
    currentOrganization,
    currentUser: getLoggedInUser(state),
    formInProgress: ui.formInProgress,
    pageLoading: organization.loading,
    phones: phone.phones,
    selectedChannel: defaultApptReminderChannelId,
    SMSChannelIds: getAutomatedMessageChannelIds(state),
    tags: tag.tags,
    timeZones: timeZone.timeZones,
    userOrganization: getLoggedInUserOrganization(state),
  };
};

const actions = {
  fetchOrganizationPreferencesView: OrganizationReducer.fetchOrganizationPreferencesView,
  updateOrganizationFromPreferences: OrganizationReducer.updateOrganizationFromPreferences,
};

export default connect(mapStateToProps, actions)(unsavedChanges(OrganizationPreferencesContainer));
