import React from 'react';

import {
  TYPE_EVENT_STATUS_ERROR,
  TYPE_EVENT_STATUS_CONFIRMED_ERROR,
} from '../constants/Types';

import {
  MessageStatus,
} from '../constants/v3';

export const TWILIO_NON_TEXTABLE_ERROR_CODES = ['30003', '30006'];
export const BANDWIDTH_NON_TEXTABLE_ERROR_CODES = ['4404', '4406', '4700', '4701', '4720', '4721', '4740'];

export function isNonTextableErrorCode(errorCode) {
  return [...TWILIO_NON_TEXTABLE_ERROR_CODES, ...BANDWIDTH_NON_TEXTABLE_ERROR_CODES, 'Non-textable Error'].includes(errorCode);
}

export function isStatusCodeError(statusCode) {
  return [TYPE_EVENT_STATUS_ERROR, TYPE_EVENT_STATUS_CONFIRMED_ERROR].includes(statusCode);
}

export function getNetworkErroMessage(err) {
  return err.message === MessageStatus.NetworkError ? (
    <>
      <span className="u-font-weight-bold">Unable to send message </span>
      due to network or internet connection error. Please check connectivity and try again.
    </>
  ) : null;
}
