import React from 'react';
import { UtilityInlineGrid, Button } from 'rhinostyle';
import rhinoTime from '../assets/images/rhino-time.svg';

const NotFound = () => {
  document.title = 'Rhinogram | Not Found';

  return (
    <div className="app-system-message__wrapper">
      <div className="app-system-message" data-vh="100">
        <div className="app-system-message__container">
          <div className="app-system-message__image">
            <div className="responsive-svg" style={{ paddingBottom: '127.742663656884876%' }}>
              <img src={rhinoTime} alt="Rhinogram" />
            </div>
          </div>
          <div className="app-system-message__headline">
            Oops!
          </div>
          <div className="app-system-message__copy">
            We can&#39;t seem to find that page.
          </div>
          <UtilityInlineGrid size="large" align="middle">
            <div className="app-system-message__error-code">
              Error code: 404
            </div>
            <Button
              type="outline-reversed"
              url="/"
            >
              Go home
            </Button>
          </UtilityInlineGrid>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
