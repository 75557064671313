import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Input } from 'rhinostyle';

const OrganizationSearch = (props) => {
  const {
    searchText,
    handleSearch,
  } = props;

  return (
    <Input
      placeholder="Search organizations"
      addon="left"
      onChange={handleSearch}
      initialValue={searchText}
      size="large"
      name="search"
      className="search__input"
      autoComplete="off"
    >
      <Icon icon="search" />
    </Input>
  );
};

OrganizationSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

export default OrganizationSearch;
