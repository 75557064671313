import PropTypes from 'prop-types';
import React from 'react';

import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import {
  Resource,
  ResourceIntro,
  ResourceRight,
  ResourceBody,
} from 'rhinostyle';
import { UserHelpers } from '../helpers';
import { Types } from '../constants';
import { AVATAR_BASE_URL } from '../constants/AppConstants';
import Tags from './Tags';

const Contact = (props) => {
  const {
    contact,
    contactId,
    handleSelect,
    isChecked,
    hasCheckbox,
    disabled,
  } = props;
  const params = useParams();
  const history = useHistory();
  function togglePanel() {
    const shouldClose = params.userId && Number(params.userId) === contactId;
    if (shouldClose) {
      history.push('/contacts');
    } else {
      history.push(`/contacts/${contactId}`);
    }
  }
  const { onlineStatus } = contact; // This property is being set explicitly in the WebSocket and is not part of the User response object
  const profileImageUrl = contact.profileImageUrl ? `${AVATAR_BASE_URL}${contact.profileImageUrl}` : '';
  const birthday = contact.birthday ? moment(contact.birthday).format('MM/DD/YYYY') : null;
  const avatar = {
    name: UserHelpers.formatAvatarName(contact.firstName, contact.lastName),
    image: profileImageUrl,
    showOnlineStatus: contact.typeId === Types.TYPE_MEMBER,
    onlineStatus,
  };
  const active = Number(params.userId) === contactId;
  return (
    <Resource disabled={disabled} dataCypress={`contactItem-${contactId}`} active={active} key={contactId} onClick={togglePanel}>
      <ResourceIntro
        avatar={avatar}
        checkbox={hasCheckbox && {
          name: 'user',
          label: ' ',
          isChecked,
          onChange: () => handleSelect(contact),
          hasAvatar: true,
        }}
        title={UserHelpers.formatName(contact)}
      >
        <div className="u-text-muted u-text-small">
          {birthday}
        </div>
      </ResourceIntro>
      {contact.tags?.length > 0 && <ResourceBody className="u-text-muted"><Tags tagIds={contact.tags} /></ResourceBody>}
      <ResourceRight>
        {UserHelpers.formatTypes(contact)}
      </ResourceRight>
    </Resource>
  );
};

Contact.propTypes = {
  contactId: PropTypes.number.isRequired,
  contact: PropTypes.object.isRequired,
  hasCheckbox: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Contact;
