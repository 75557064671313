import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import {
  Button,
  Close,
  ResourceGroup,
  Resource,
  ResourceIntro,
  Scrollbars,
  UtilityInlineGrid,
  Icon,
} from 'rhinostyle';

import { AVATAR_BASE_URL } from '../constants/AppConstants';
import { UserHelpers } from '../helpers';
import UserSearchModalContainer from '../containers/UserSearchModalContainer';
import { sortComparatorUsingUserLastNameFirstName } from '../helpers/DataHelpers';
import { CONTACT_DELETE, CONVERSATION_CONTACT_MOBILE } from '../constants/UserPermissionsConstants';
import { userHasAnyOfPermissions, userHasAllOfPermissions } from '../helpers/UserHelpers';
import { isMobile } from '../helpers/BrowserHelpers';
import { deleteContactList, fetchUser } from '../reducers/userReducer';
import { getLoggedInUser } from '../selectors/userSelectors';

const ContactListPanel = (props) => {
  const {
    activeList,
    users,
    panelOpen,
    currentUser,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [viewAllMembers, setViewAllMembers] = useState(false);
  const [isBulkMessageModalOpen, setIsBulkMessageModalOpen] = useState(false);
  const [isUserSearchModalOpen, setIsisUserSearchModalOpen] = useState(false);

  const handleResetInitialState = () => {
    setIsBulkMessageModalOpen(false);
    setIsisUserSearchModalOpen(false);
  };
  const handleCloseProfileClick = (id) => {
    if (panelOpen) {
      history.push('/contact-list');
    } else {
      history.push(`/contact-list/${id}`);
    }
  };

  const navigateToConversation = (userId) => {
    history.push(`/inbox/all/user/${userId}`);
  };

  const handleDeleteContactList = (id) => {
    dispatch(deleteContactList(id));
    history.push('/contact-list');
  };

  const handleUpdateContactList = (id) => {
    history.push(`/contact-list/edit/${id}`);
  };

  const handleUserTransition = async (userId) => {
    await dispatch(fetchUser(userId));
  };

  const openBulkMessageModal = () => {
    setIsBulkMessageModalOpen(true);
  };

  const renderContact = (userId, key) => {
    const contact = users[userId];
    const profileImageUrl = contact && contact.profileImageUrl ? `${AVATAR_BASE_URL}${contact.profileImageUrl}` : '';
    let avatar = {};
    if (contact) {
      avatar = {
        name: UserHelpers.formatAvatarName(contact.firstName, contact.lastName),
        type: 'default',
        image: profileImageUrl,
      };
    }

    return (
      <Resource className="u-text-left" key={key} onClick={() => navigateToConversation(userId)}>
        <ResourceIntro
          title={UserHelpers.formatName(contact)}
          avatar={avatar}
        />
      </Resource>
    );
  };

  const activeListUserId = activeList.users && activeList.users.map((user) => user.id);

  let activeListUsers = activeList.users ? [...activeListUserId].sort(sortComparatorUsingUserLastNameFirstName(users)) : [];
  activeListUsers = viewAllMembers ? activeListUsers : activeListUsers.slice(0, 6);

  return (
    <div className="summary-panel__wrapper summary-panel__wrapper--contact-list">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title">Summary</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => handleCloseProfileClick()}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro__icon u-text-center">
              <span className="summary-panel__profile-icon">
                <Icon icon="user-group" />
              </span>
            </div>
            <div className="summary-panel__intro-wrapper">
              {activeList && (
                <div className="summary-panel__intro__title"> { activeList.name } </div>
              )}
              <div className="summary-panel__intro__sub">Saved Contact List</div>
              <div className="summary-panel__intro__actions">
                {!currentUser.isCcr && (
                <UtilityInlineGrid align="middle">
                  <Button size="small" type="primary" onClick={openBulkMessageModal}> Message Contacts </Button>
                  <Button size="small" type="primary" onClick={() => handleUpdateContactList(activeList.id)} outlined> Update List</Button>
                </UtilityInlineGrid>
                )}
              </div>
              {activeList && activeList.users && Object.keys(users).length > 0 && (
                <div className="summary-panel__section summary-panel__section--full u-m-t">
                  <div className="summary-panel__section__label u-text-left">Contacts ({activeList.users.length})</div>
                  <div className="summary-panel__section__content">
                    <ResourceGroup>
                      {activeListUsers.map(renderContact)}
                    </ResourceGroup>
                    {activeList && activeList.users.length > 6 && !viewAllMembers && (
                      <div className="u-m-t-small u-text-right">
                        <Button reset className="u-text-primary u-text-small" onClick={() => setViewAllMembers((current) => !current)}>
                          View All Members
                        </Button>
                      </div>
                    )}
                  </div>
                  {!currentUser.isCcr && (isMobile() ? userHasAllOfPermissions([CONTACT_DELETE, CONVERSATION_CONTACT_MOBILE]) :
                    userHasAnyOfPermissions([CONTACT_DELETE])) && (
                    <div className="u-m-t u-text-center">
                      <Button
                        onClick={() => handleDeleteContactList(activeList.id)}
                        reset
                        className="u-text-small u-text-danger"
                      >
                        Delete List
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Scrollbars>
        <UserSearchModalContainer
          scope="nonMembers"
          handleSearchSelect={handleUserTransition}
          isUserSearchModalOpen={isUserSearchModalOpen}
          isBulkMessageModalOpen={isBulkMessageModalOpen}
          selectedSavedList={activeList}
          handleResetInitialState={handleResetInitialState}
          isRenderedFromContactListPanel
          location={location}
          history={history}
        />
      </div>
    </div>
  );
};

ContactListPanel.propTypes = {
  activeList: PropTypes.object.isRequired,
  panelOpen: PropTypes.bool,
  users: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    users: user.users,
    currentUser: getLoggedInUser(state),
  };
};

export default connect(mapStateToProps)(ContactListPanel);
