import { TYPE_CALL_CONNECTED, TYPE_CALL_NO_ANSWER, TYPE_CALL_VOICE_MESSAGE } from './Types';

export const CALL_STATUS_INITIATED = 'Initiated';
export const CALL_STATUS_ACTIVE = 'Active';
export const CALL_STATUS_COMPLETED = 'Completed';
export const CALL_CANCELLED = 'Cancelled';
export const RHINOCALL_FORWARD_PHONE = `${process.env.REACT_APP_RHINOCALL_FORWARD_PHONE}`;
const CONNECTION_RESULT_CONNECTED = 'CONNECTED';
const CONNECTION_RESULT_MESSAGE = 'LEFT_VOICE_MESSAGE';
const CONNECTION_RESULT_NO_ANSWER = 'NO_ANSWER';

export const callConnectionResults = {
  Connected: 'Connected',
  NoAnswer: 'No Answer',
  LeftVoiceMessage: 'Left Voice Message',
};
export const CONNECTION_RESULT_OPTIONS = [
  {
    id: '',
    value: '',
  },
  {
    id: CONNECTION_RESULT_CONNECTED,
    value: callConnectionResults.Connected,
  },
  {
    id: CONNECTION_RESULT_NO_ANSWER,
    value: callConnectionResults.NoAnswer,
  },
  {
    id: CONNECTION_RESULT_MESSAGE,
    value: callConnectionResults.LeftVoiceMessage,
  },
];

export const formattedConnectionResultTypes = {
  [TYPE_CALL_CONNECTED]: {
    description: callConnectionResults.Connected,
    appendedString: 'with',
  },
  [TYPE_CALL_NO_ANSWER]: {
    description: callConnectionResults.NoAnswer,
    appendedString: 'from',
  },
  [TYPE_CALL_VOICE_MESSAGE]: {
    description: callConnectionResults.LeftVoiceMessage,
    appendedString: 'for',
  },
};
