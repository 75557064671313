import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { updateArrayById, isObject } from '../helpers/DataHelpers';
import { MEMBER_FILTERS, MEMBER_FILTERS_INITIAL_STATE } from '../constants/ContactsConstants';
import { BULK_TAG_MAX_CONTACTS } from '../constants/AppConstants';
import { useLazyGetSearchQuery, useGetSearchQuery } from '../services/searchService';
import { getRhinoblastMaxPageSize } from '../selectors/organizationSelectors';

const useMemberFilters = (shouldSkip = false) => {
  const contactPageSize = useSelector(getRhinoblastMaxPageSize);
  const initialState = { ...MEMBER_FILTERS_INITIAL_STATE, pageSize: contactPageSize };
  const storageKey = 'memberFilters';
  const loadFilters = useCallback(() => {
    const savedFilters = sessionStorage.getItem(storageKey);
    return savedFilters ? JSON.parse(savedFilters) : initialState;
  }, [initialState]);

  const [filters, setFilters] = useState(loadFilters());
  const [filtersApplied, setFiltersApplied] = useState(false);
  const searchPayload = formatRequestData();
  const searchResult = useGetSearchQuery(searchPayload, { skip: shouldSkip });
  const { data: searchData, isLoading } = searchResult;
  const [fetchAllContacts, { isFetching }] = useLazyGetSearchQuery();

  async function fetchAllResults(nextPageNumber, searchAfter) {
    const result = await fetchAllContacts(formatRequestData({ pageNo: nextPageNumber,
      fetchAllRecords: true,
      size: BULK_TAG_MAX_CONTACTS,
      pageSize: BULK_TAG_MAX_CONTACTS,
      page: 0,
      ...searchAfter?.length > 0 && {
        searchAfter: JSON.stringify(searchAfter),
      } }), true);
    return result;
  }

  function formatRequestData(changeObj = {}) {
    const {
      excludeCurrentUser,
      idsToExclude,
      includeInactiveAndDeleted,
      pageNo,
      pageSize = contactPageSize,
      tagIds = [],
      name,
      userType,
      groups = [],
      roles = [],
      active = true,
    } = filters;
    const params = {
      excludeCurrentUser,
      groups: groups?.length > 0 ? groups : null,
      idsToExclude,
      includeInactiveAndDeleted: includeInactiveAndDeleted || null,
      name: name?.length > 1 ? name : null,
      pageSize,
      pageNo,
      roles: roles?.length > 0 ? roles : null,
      tagIds: tagIds?.length > 0 ? tagIds : null,
      userType,
      active,
    };
    const payload = {
      pageSize: contactPageSize,
      size: contactPageSize,
      pageNo: 0,
      countTotalRecords: true,
      scope: 'members',
      ...params,
      ...changeObj,
    };
    return payload;
  }

  function ignoreActive(obj) {
    const { active, ...otherValues } = obj;
    return otherValues;
  }

  useEffect(() => {
    setFiltersApplied(!isEqual(ignoreActive(filters), ignoreActive(initialState)));
    sessionStorage.setItem(storageKey, JSON.stringify(filters));
  }, [filters]);

  const clearAllFilters = () => {
    setFilters((current) => ({
      ...initialState,
      active: current.active,
    }));
    setFiltersApplied(false);
  };

  const handleSetFilters = (changeObj) => {
    setFilters((current) => ({
      ...current,
      ...changeObj,
    }));
  };

  const handleChange = (name, value) => {
    if (!name) {
      return;
    }

    let updateValue = null;
    if ([MEMBER_FILTERS.Id, MEMBER_FILTERS.Name].includes(name)) {
      handleSetFilters({ [name]: value });
      updateValue = value.length > 2 ? value : '';
    } else if (name === [MEMBER_FILTERS.TagIds, MEMBER_FILTERS.Groups, MEMBER_FILTERS.Roles].includes(name)) {
      if (Array.isArray(value)) {
        updateValue = value;
      } else {
        updateValue = updateArrayById(filters[name], value);
      }
      handleSetFilters({ [name]: updateValue });
    } else {
      const currentObj = filters[name];
      updateValue = isObject(value) ? {
        ...currentObj,
        ...value,
      } : value;
      handleSetFilters({ [name]: updateValue });
    }
  };

  return { fetchAllResults, filters, filtersApplied, handleSetFilters, clearAllFilters, handleChange, searchData, isFetching, isLoading, searchPayload };
};

export default useMemberFilters;
