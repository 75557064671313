import { useState, useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as queryString from 'query-string';
import { getDateQueryStringFromValue, getDateValuesFromQueryString } from '../helpers/DateHelpers';

const useQueryParams = ({
  initialValue: defaultValues,
  validateQueryStringValue,
  datePickerValues,
  ignoredKeys = ['startDate', 'endDate', 'headersConfig'],
}) => {
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = useState(getValueFromQueryString(defaultValues));

  useEffect(() => {
    const queryStringParams = getQueryStringFromValue({ ...value });
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryStringParams),
    });
  }, [value]);

  function getQueryStringFromValue(newValues) {
    let queryStringParams = {};
    Object.keys(newValues).forEach((key) => {
      if (!ignoredKeys.includes(key)) {
        const updateValue = newValues[key];
        if (updateValue !== null && updateValue !== defaultValues[key]) {
          if (Array.isArray(defaultValues[key])) {
            if (newValues[key]?.length) {
              queryStringParams[key] = newValues[key].join(',');
            }
          } else {
            queryStringParams[key] = updateValue;
          }
        }
      }
    });
    // If has date field
    if (defaultValues.activeKey) {
      queryStringParams = {
        ...queryStringParams,
        ...getDateQueryStringFromValue(newValues, datePickerValues),
      };
    }
    return queryStringParams;
  }

  function isValid(queryStringValue, key) {
    return validateQueryStringValue ? validateQueryStringValue(queryStringValue, key) : true;
  }

  function getValueFromQueryString(initialValue) {
    let updatedValues = { ...initialValue };
    if (location.search?.length > 0) {
      const queryStringValues = queryString.parse(location.search);
      Object.keys(queryStringValues).forEach((key) => {
        if (!ignoredKeys.includes(key)) {
          let updateValue;
          const stringifiedValue = queryStringValues[key];
          if (typeof initialValue[key] === 'number') {
            updateValue = Number(stringifiedValue);
          } else if (Array.isArray(initialValue[key]) && stringifiedValue?.length) {
            updateValue = stringifiedValue.split(',').map((item) => {
              if (Number.isNaN(Number(item))) {
                return item;
              }
              return Number(item);
            });
          } else if (typeof initialValue[key] === 'boolean' && stringifiedValue.length && !!stringifiedValue !== initialValue[key]) {
            updateValue = !initialValue[key];
          } else if (typeof initialValue[key] === 'string') {
            updateValue = stringifiedValue;
          } if (updateValue !== null && updateValue !== initialValue[key] && isValid(queryStringValues[key], key)) {
            updatedValues[key] = updateValue;
          }
        }
      });
      if ((updatedValues.activeKey && updatedValues.activeKey !== initialValue.activeKey) ||
      (updatedValues.activeTab && updatedValues.activeTab !== initialValue.activeTab)) {
        updatedValues = {
          ...updatedValues,
          ...getDateValuesFromQueryString(queryStringValues, initialValue, datePickerValues),
        };
      }
    }
    return updatedValues;
  }

  const onSetValue = useCallback(
    (newValues) => {
      if (!newValues) {
        setValue(defaultValues);
      } else {
        setValue((current) => ({
          ...current,
          ...newValues,
        }));
      }
    },
    [],
  );
  return [value, onSetValue];
};

export default useQueryParams;
