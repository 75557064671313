import React from 'react';
import { Scrollbars, UtilitySystem } from 'rhinostyle';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LoadMore from './LoadMore';
import LazyLoader from './LazyLoader';

const LazyLoadList = ({
  handleLoadMore,
  loading,
  className,
  children,
  disabled,
  autoHeight,
  dataCypress,
}) => {
  const scrollClasses = cx('list-panel__body__scroll', {
    className,
  });
  return (
    <>
      <Scrollbars
        className={scrollClasses}
        autoHeight={!!autoHeight}
        autoHeightMax={autoHeight && UtilitySystem.config.resourceSizes[autoHeight]}
        data-cypress={dataCypress}
      >
        {children}
        <LoadMore
          handleLoadMore={handleLoadMore}
          isLoading={loading}
          disabled={disabled}
          hideLazyLoader
        />
      </Scrollbars>
      <LazyLoader loading={loading} />
    </>
  );
};

LazyLoadList.propTypes = {
  children: PropTypes.node,
  handleLoadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  autoHeight: PropTypes.oneOf(['large', 'small']),
  dataCypress: PropTypes.string,
};

export default LazyLoadList;
