import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  Icon,
  Lightbox,
  UtilityListItem,
} from 'rhinostyle';
import { connect, useDispatch } from 'react-redux';

import { DataHelpers } from '../helpers';
import { AppConstants } from '../constants';

import PdfViewerContainer from '../containers/PdfViewerContainer';
import { hasData } from '../helpers/DataHelpers';
import { getIsForm, getIsVCard } from '../helpers/AttachmentHelpers';
import { getFormTemplateById } from '../reducers/formReducer';

const SingleAttachmentPreview = (props) => {
  const {
    attachment,
    getFormS3Url,
    attachedFormTemplate,
    index,
    formS3Url,
    handleRemoveAttachment,
  } = props;
  const dispatch = useDispatch();
  const isForm = getIsForm(attachment);
  const isVCard = getIsVCard(attachment);
  const isPdf = (isForm || isVCard) ? true : DataHelpers.getFileMimeType(attachment.attachmentUrl) === AppConstants.MIME_TYPE_APPLICATION_PDF;
  const attachmentId = isForm ? attachment.attachmentUrl : attachment.id;

  const [openPdfId, setOpenPdfId] = useState('');
  const [openLightboxId, setOpenLightboxId] = useState('');
  const [attachmentUrl, setAttachmentUrl] = useState(getAttachmentUrl());
  const [loading, setLoading] = useState(false);

  function getAttachmentUrl() {
    if (isForm) {
      return attachedFormTemplate?.formS3Url || '';
    } return attachment.attachmentUrl;
  }

  useEffect(() => {
    if (hasData(formS3Url) && formS3Url[attachment?.attachmentUrl]?.formS3Url) {
      setAttachmentUrl(formS3Url[attachment?.attachmentUrl]?.formS3Url);
      setLoading(false);
    }
  }, [formS3Url?.isPdfLoading]);

  useEffect(() => {
    if (attachedFormTemplate?.formS3Url && !attachmentUrl) {
      setAttachmentUrl(attachedFormTemplate?.formS3Url);
    }
  }, [attachedFormTemplate]);

  function handleGetFormS3Url() {
    if (getFormS3Url) {
      return getFormS3Url(attachmentId);
    } else {
      if (!attachmentUrl) {
        dispatch(getFormTemplateById(attachmentId));
      }
      return attachedFormTemplate.formS3Url;
    }
  }

  function handleViewPreviewClick() {
    let formUrl = '';
    if (isForm && !attachmentUrl) {
      formUrl = handleGetFormS3Url();
    }
    if (isPdf && (isForm || formUrl)) {
      setOpenPdfId(`pdf-preview-${attachmentId}`);
      if (!attachmentUrl) {
        setAttachmentUrl(formUrl);
      }
    } else {
      setOpenLightboxId(`lightbox-preview-${attachmentId}`);
      setOpenPdfId(`pdf-preview-${attachmentId}`);
      setLoading(isForm);
    }
  }

  function handleClosePreview() {
    setOpenLightboxId('');
    setOpenPdfId('');
  }

  const renderAttachmentPreview = () => {
    if (isPdf) {
      const pdfViewerProps = {
        attachmentUrl: `${AppConstants.FILE_BASE_URL}${attachmentUrl}`,
        className: 'template-attachments__pdf-viewer',
        fileName: attachmentUrl,
        handlePdfViewerClose: handleClosePreview,
        open: openPdfId === `pdf-preview-${attachmentId}`,
      };

      return (
        <PdfViewerContainer {...pdfViewerProps} />
      );
    }

    const lightboxProps = {
      mainSrc: `${AppConstants.FILE_BASE_URL}${attachment.attachmentUrl}`,
      onCloseRequest: handleClosePreview,
    };

    return openLightboxId === `lightbox-preview-${attachment.id}` && <Lightbox {...lightboxProps} />;
  };
  const getIcon = () => {
    if (isForm) return 'signature';
    if (isVCard) return 'vcard';
    return 'attachment';
  };

  return (
    <UtilityListItem className="template-attachments__attachment">
      <div className="template-attachments__name">
        <Button
          loading={loading}
          onClick={handleViewPreviewClick}
          disabled={isVCard}
          reset
          className="u-text-primary"
          data-cypress={`attachment-preview-${attachment.name}`}
        >
          <Icon icon={getIcon()} className="u-m-r-small" />{attachment.name}
        </Button>
      </div>
      {handleRemoveAttachment && (
      <Close
        onClick={() => handleRemoveAttachment(index)}
        className="template-attachments__remove u-text-danger"
        data-cypress={`attachment-remove-${attachment.name}`}
      />
      )}
      {renderAttachmentPreview()}
    </UtilityListItem>
  );
};

SingleAttachmentPreview.propTypes = {
  attachment: PropTypes.object.isRequired,
  handleRemoveAttachment: PropTypes.func,
  index: PropTypes.number.isRequired,
  getFormS3Url: PropTypes.func,
  formS3Url: PropTypes.object,
  attachedFormTemplate: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  formS3Url: state.form.formS3Url,
  attachedFormTemplate: state.form.formTemplates?.find((form) => form.formId === props.attachment?.attachmentUrl) || {},
});

export default connect(mapStateToProps)(SingleAttachmentPreview);
