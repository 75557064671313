import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getLoggedInUser } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import OrganizationOffices from '../components/OrganizationOffices';
import * as OfficeReducer from '../reducers/officeReducer';
import * as StateReducer from '../reducers/stateReducer';

class OrganizationOfficesContainer extends Component {
  state = {
    pageLoading: true,
    panelOpen: !!this.props.match.params.officeId,
  }

  componentDidMount() {
    this.props.fetchOffices();
    this.props.fetchStates();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.officeId !== this.props.match.params.officeId) {
      this.setState({ panelOpen: !!this.props.match.params.officeId }); // eslint-disable-line react/no-did-update-set-state
    }

    if (prevProps.pageLoading !== this.props.pageLoading) {
      this.setState({ pageLoading: this.props.pageLoading }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  handleCreateRequest = () => {
    this.props.history.push('/settings/organization/offices/create');
  }

  handleTogglePanel = (id) => {
    const shouldClose = this.state.panelOpen && Number(this.props.match.params.officeId) === id;

    if (shouldClose) {
      this.props.history.push('/settings/organization/offices');
    } else {
      this.props.history.push(`/settings/organization/offices/${id}`);
    }
  }

  render() {
    const props = {
      activeOfficeId: Number(this.props.match.params.officeId),
      handleCreateRequest: this.handleCreateRequest,
      handleTogglePanel: this.handleTogglePanel,
      officeIds: this.props.officeIds,
      offices: this.props.offices,
      pageLoading: this.state.pageLoading,
      panelOpen: this.state.panelOpen,
      user: this.props.user,
      currentOrganization: this.props.currentOrganization,
    };

    return <OrganizationOffices {...props} />;
  }
}

OrganizationOfficesContainer.propTypes = {
  fetchOffices: PropTypes.func.isRequired,
  officeIds: PropTypes.array.isRequired,
  offices: PropTypes.object.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object,
  fetchStates: PropTypes.func,
  currentOrganization: PropTypes.object,
};

const mapStateToProps = (state) => { // eslint-disable-line
  const { office } = state;

  return {
    officeIds: office.officeIds,
    offices: office.offices,
    pageLoading: office.pageLoading,
    user: getLoggedInUser(state),
    currentOrganization: getCurrentOrg(state),
  };
};

const actions = {
  fetchOffices: OfficeReducer.fetchOffices,
  fetchStates: StateReducer.fetchStates,
};

export default connect(mapStateToProps, actions)(OrganizationOfficesContainer);
