// vCard status
export const NOT_SENT = 'Not Sent';
export const SENT_TO_ALL = 'Sent to All';
export const SENT_TO_SOME = 'Sent to Some';

export const VCARD_LIST_HEADERS_CONFIG = {
  vCardName: {
    default: true,
    direction: 1,
  },
};
