import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  Dropdown,
  DropdownMenuItem,
  Input,
  Scrollbars,
  SmartTable,
  Tooltip,
} from 'rhinostyle';
import ReactHtmlParser from 'react-html-parser';

import * as MessageTemplateReducer from '../reducers/messageTemplateReducer';
import PageLoader from '../components/PageLoader';
import LazyLoader from '../components/LazyLoader';
import SmartTableHeader from '../components/SmartTableHeader';
import { getLoggedInUser } from '../selectors/userSelectors';
import { DataHelpers } from '../helpers';
import { highlightSearchedText, replaceWhiteSpaceWithSpace } from '../helpers/StringHelpers';
import { replaceVariablesByStyledComponent } from '../helpers/TemplateHelpers';
import EmptyMessage from '../components/EmptyMessage';
import { TEMPLATES_LIST_HEADERS_CONFIG } from '../constants/LibraryConstants';
import { iconTypeSignature, iconTypeAttachment, iconTypeVCard, VCARD_FILE_TYPE } from '../constants/AppConstants';
import { TEMPLATE_MESSAGE_VARIABLE_OPTIONS } from '../constants/VariableMessageConstants';
import { handleTemplateDataAppend, toggleOrganizationMessageTemplateModal } from '../reducers/threadReducer';

const defaultSortKey = DataHelpers.getDefaultSortKey(TEMPLATES_LIST_HEADERS_CONFIG);

class OrganizationMessageTemplateModalContainer extends Component {
  static initialState = {
    messageTemplateId: undefined,
    preventMsgTextAppend: false,
    templateFilterId: 'all',
    searchText: '',
    sortKey: defaultSortKey,
    formLoading: false,
    headersConfig: DataHelpers.cloneDeep(TEMPLATES_LIST_HEADERS_CONFIG),
    focusSearchInput: false,
  };

  state = {
    ...OrganizationMessageTemplateModalContainer.initialState,
  };

  componentDidMount() {
    this.handleSearch = debounce(this.handleSearch, 400);
    this.props.fetchMessageTemplates(this.props.templateType);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        templateFilterId: 'all',
        sortKey: defaultSortKey,
        searchText: '',
      });
      this.setDefaultSortOnConversation();
      this.props.fetchMessageTemplates(this.props.templateType);
    }
    if (this.props.messageTemplatesLoading !== prevProps.messageTemplatesLoading) {
      this.setState({ formLoading: this.props.messageTemplatesLoading }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  resetState = () => this.setState(OrganizationMessageTemplateModalContainer.initialState);

  handleUseClick(messageTemplateMessage, attachments) {
    if (!this.state.preventMsgTextAppend) {
      this.setState({ preventMsgTextAppend: true });
      if (this.props.handleTemplateDataAppend) {
        this.props.handleTemplateDataAppend(messageTemplateMessage, attachments);
      } else {
        this.props.handleInboxTemplateDataAppend(messageTemplateMessage, attachments, this.props.templateType);
      }
      this.closeModal();
    }
  }

  closeModal = () => {
    this.props.updateTemplatesList(this.props.allTemplates);
    if (this.props.closeModal) {
      this.props.closeModal();
    } else {
      this.props.toggleOrganizationMessageTemplateModal();
    }
  }

  handleTemplateFilter = (templateFilterId) => {
    const favoriteTemplates = this.props.allTemplates.filter((messageTemplate) => messageTemplate.favorited === true);
    const nonFavoriteTemplates = this.props.allTemplates.filter((messageTemplate) => messageTemplate.favorited === false);
    favoriteTemplates.sort((a, b) => DataHelpers.compareObjectByKey(a, b, defaultSortKey));
    nonFavoriteTemplates.sort((a, b) => DataHelpers.compareObjectByKey(a, b, defaultSortKey));
    this.setDefaultSortOnConversation();
    if (templateFilterId === 'all') {
      this.props.updateTemplatesList([...favoriteTemplates, ...nonFavoriteTemplates]);
    } else {
      this.props.updateTemplatesList(favoriteTemplates);
    }
    this.setState({
      templateFilterId,
      sortKey: 'subject',
      searchText: '',
    });
  }

  setDefaultSortOnConversation = () => {
    const headers = this.state.headersConfig;
    Object.keys(headers).forEach((key) => {
      headers[key].default = TEMPLATES_LIST_HEADERS_CONFIG[key].default;
      headers[key].direction = TEMPLATES_LIST_HEADERS_CONFIG[key].direction;
    });
    this.setState({ headersConfig: headers });
  }

  handleSearch = (name, searchText) => {
    let templates = [...this.props.allTemplates];
    if (this.state.templateFilterId === 'favorite') {
      templates = this.props.allTemplates.filter((messageTemplate) => messageTemplate.favorited === true);
    }
    if (searchText && searchText.length > 2) {
      templates = templates.filter((template) =>
        (template.subject.toLowerCase().includes(searchText.toLowerCase()) || template.message.toLowerCase().includes(searchText.toLowerCase())));
      this.setDefaultSortOnConversation();
      this.props.updateTemplatesList(templates);
    } else if (searchText.length === 0) {
      this.setDefaultSortOnConversation();
      this.props.updateTemplatesList(templates);
    }
    this.setState({
      searchText,
      focusSearchInput: true,
    });
  }

  formattedfavoriteData = (status, userId) => ({
    userId,
    favorited: status,
  })

  handleFavorite =(isFavorite, id, e) => {
    e.stopPropagation();
    this.props.updateFavoriteStatus(id, { userId: this.props.currentUser.id, favorited: !isFavorite }, this.state.templateFilterId)
      .then(() => {
        this.handleSortColumns(this.state.sortKey);
      });
  }

  renderAttachment = (template) => {
    const iconTypes = template.attachments.map((attachment) => {
      if (this.props.types[attachment.attachmentTypeId]?.value === 'form') return iconTypeSignature;
      if (this.props.types[attachment.attachmentTypeId]?.value === VCARD_FILE_TYPE) return iconTypeVCard;
      return iconTypeAttachment;
    });
    const distinctIconTypes = [...new Set(iconTypes)];
    return distinctIconTypes.map((icon) => <Icon key={icon} icon={icon} size="medium" className="u-text-muted listing-icon" />);
  };

  renderFavoriteIcon = (itemId, isSelected) => {
    const icon = isSelected ? 'heart-filled' : 'heart';
    const iconClass = isSelected ? 'heart-filled-icon' : 'heart-icon';
    return (
      <Icon
        icon={icon}
        data-cypress="markFavorite"
        className={iconClass}
        onClick={(e) => this.handleFavorite(isSelected, itemId, e)}
      />
    );
  };

  renderTemplateText = (text) => {
    const formattedText = replaceWhiteSpaceWithSpace(text);
    return replaceVariablesByStyledComponent(TEMPLATE_MESSAGE_VARIABLE_OPTIONS, formattedText);
  };

  handleSortColumns = (type) => {
    const favoriteTemplates = this.props.messageTemplates.filter((messageTemplate) => messageTemplate.favorited === true);
    const nonFavoriteTemplates = this.props.messageTemplates.filter((messageTemplate) => messageTemplate.favorited === false);
    let sortedFavorites = [];
    let sortedNonFavorites = [];
    sortedFavorites = favoriteTemplates.sort((a, b) => DataHelpers.compareObjectByKey(a, b, type));
    sortedNonFavorites = nonFavoriteTemplates.sort((a, b) => DataHelpers.compareObjectByKey(a, b, type));
    if (this.state.headersConfig[type].direction === -1) {
      sortedFavorites.reverse();
      sortedNonFavorites.reverse();
    }
    const allTemplates = [...sortedFavorites, ...sortedNonFavorites];
    this.props.updateTemplatesList(allTemplates);
    this.setState({ sortKey: type });
  }

  renderTemplateType = (type) => {
    const templateTypes = {
      contact: 'Contact',
      team: 'Team',
      system: 'System',
    };
    return templateTypes[type];
  };

  rowProps = (state, rowInfo) => ({
    onClick: () => {
      this.handleUseClick(rowInfo?.original.message, rowInfo?.original.attachments);
    },
    style: {
      cursor: 'pointer',
      height: 50,
    },
    className: 'form-row',
  });

  templatesListColumns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Title"
          sortKey="subject"
          sortDirection={this.state.headersConfig.subject.direction}
          headers={this.state.headersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'subject',
      Cell: (row) => (<Tooltip content={row.original.subject}><div>{row.original.subject}</div></Tooltip>),
      fixed: 'left',
      maxWidth: 250,
      sortMethod: () => this.handleSortColumns('subject'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Body"
          sortKey="message"
          sortDirection={this.state.headersConfig.message.direction}
          headers={this.state.headersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      accessor: 'message',
      Cell: (row) => (
        <Tooltip content={this.renderTemplateText(row.original.message)}>
          <div>
            {this.state.searchText.length > 2 ? (
              highlightSearchedText(this.renderTemplateText(row.original.message), this.state.searchText)
            ) : (
              ReactHtmlParser(this.renderTemplateText(row.original.message))
            )}
          </div>
        </Tooltip>
      ),
      fixed: 'left',
      sortMethod: () => this.handleSortColumns('message'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Type"
          sortKey="type"
          sortDirection={this.state.headersConfig.type.direction}
          headers={this.state.headersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
      },
      maxWidth: 80,
      accessor: 'name',
      Cell: (row) => (<div>{this.renderTemplateType(row.original.type)}</div>),
      sortMethod: () => this.handleSortColumns('type'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Files"
          isCaretDisabled
          sortKey="attachment"
          sortDirection={this.state.headersConfig.attachment.direction}
          headers={this.state.headersConfig}
        />
      ),
      headerStyle: {
        padding: 0,
        justifyContent: 'center',
        display: 'flex',
      },
      accessor: 'attachment',
      maxWidth: 80,
      sortable: false,
      Cell: (row) => (<div className="u-flex u-flex-justify-center">{row.original.attachments.length ? this.renderAttachment(row.original) : null}</div>),
    },
    {
      sortable: false,
      maxWidth: 50,
      Cell: (row) => (
        <div className="u-flex u-flex-justify-center">
          {this.renderFavoriteIcon(row.original.id, row.original.favorited)}
        </div>
      ),
    },
  ];

  listRef = (formList) => (this.formListRef = formList);

  renderMessageTemplates = () => {
    const emptyTemplates = (this.state.templateFilterId !== 'all' || this.state.searchText !== '') ?
      <div className="search__no-results">No results</div> : <EmptyMessage section="Templates" />;

    const doesOrgHaveTemplates = this.props.messageTemplates.length;
    const searchInputPlaceHolder = 'Search Message Templates';

    return (
      <ModalBody>
        <div className="app-page__header">
          <div className="search__group list-panel__body">
            <div className="search-filter-wrapper u-flex">
              <Dropdown
                activeKey={this.state.templateFilterId}
                wide
                reset
                icon="filter"
                className="app-page__header__filter__button"
                label="Select Filter"
                hideCaret
                onSelect={this.handleTemplateFilter}
                title="Filter templates"
              >
                <DropdownMenuItem id="all" label="All" />
                <DropdownMenuItem id="favorite" label="Favorite" />
              </Dropdown>
              <Input
                placeholder={searchInputPlaceHolder}
                className="search__input"
                name="Search Template"
                initialValue={this.state.searchText}
                onChange={this.handleSearch}
                size="large"
                addon="left"
                type="text"
                autoComplete="off"
                focus={this.state.focusSearchInput}
              >
                <Icon icon="search" />
              </Input>
            </div>
          </div>
        </div>
        {(this.props.templatesPageLoading) ? (
          <PageLoader />
        ) : (
          <LazyLoader loading={this.state.formLoading} />
        )}
        { doesOrgHaveTemplates ? (
          <Scrollbars className="list-panel__body__scroll list-panel__body__scroll--no-border" data-cypress="Templates" ref={this.listRef}>
            <SmartTable
              data={this.props.messageTemplates}
              getTrProps={this.rowProps}
              showPagination={false}
              pageSize={this.props.messageTemplates?.length}
              sticky
              columns={this.templatesListColumns}
              sortable
              manual={false}
              striped
            />
          </Scrollbars>
        ) : (
          emptyTemplates
        )}
      </ModalBody>
    );
  };

  render() {
    return (
      <Modal modalClass="template-modal__container" modalDialogClass="template-modal" onReverseComplete={this.resetState} open={this.props.open}>
        <ModalHeader onClose={this.closeModal} title="Message Templates" />
        {this.renderMessageTemplates()}
      </Modal>
    );
  }
}

OrganizationMessageTemplateModalContainer.propTypes = {
  messageTemplates: PropTypes.array.isRequired,
  fetchMessageTemplates: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
  open: PropTypes.bool.isRequired,
  handleTemplateDataAppend: PropTypes.func,
  updateFavoriteStatus: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  templateType: PropTypes.string,
  templatesPageLoading: PropTypes.bool,
  messageTemplatesLoading: PropTypes.bool,
  updateTemplatesList: PropTypes.func,
  allTemplates: PropTypes.array,
  types: PropTypes.object,
  toggleOrganizationMessageTemplateModal: PropTypes.func,
  handleInboxTemplateDataAppend: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const { messageTemplate, ui, type, thread } = state;

  return {
    currentUser: getLoggedInUser(state),
    messageTemplates: messageTemplate.messageTemplates,
    error: ui.error,
    templatesPageLoading: messageTemplate.pageLoading,
    messageTemplatesLoading: messageTemplate.messageTemplatesLoading,
    allTemplates: messageTemplate.allTemplates,
    types: type.types,
    open: props.isOnInbox ? thread.organizationMessageTemplateModalOpen : props.open,
  };
};

const actions = {
  fetchMessageTemplates: MessageTemplateReducer.fetchMessageTemplates,
  updateFavoriteStatus: MessageTemplateReducer.updateFavoriteStatus,
  updateTemplatesList: MessageTemplateReducer.updateTemplatesList,
  toggleOrganizationMessageTemplateModal,
  handleInboxTemplateDataAppend: handleTemplateDataAppend,
};

export default connect(mapStateToProps, actions)(OrganizationMessageTemplateModalContainer);
