import moment from 'moment-timezone';
import {
  PAST_APPOINTMENT_OPTIONS as DEFAULT_PAST_DATE_OPTIONS,
  UPCOMING_APPOINTMENT_OPTIONS as DEFAULT_UPCOMING_DATE_OPTIONS,
} from '../constants/AppointmentManagerConstants';

const dateRangeLabels = {
  future: 'in %s',
  past: '%s ago',
  s: 'few seconds',
  ss: '%d seconds',
  m: '1 minute',
  mm: '%d minutes',
  h: '1 hour',
  hh: '%d hours',
  d: '1 day',
  dd: '%d days',
  M: '1 month',
  MM: '%d months',
  y: '1 year',
  yy: '%d years',
};

/**
 * Used in thread view for formatting timestamps, takes utc string input
 * @param  {String}  time
 * @param  {String}  [format='ddd, MMM D, h:mm a']
 * @return {String}
 */
export function formatTimestamp(time, format = 'ddd, MMM D, Y, h:mm a', overMinutePrefix = false) {
  let formattedTime = null;
  const currentTime = moment();
  const duration = moment.duration(currentTime.diff(convertUtcStringToLocalMoment(time)));

  // Read and "humanize" duration
  if (duration.asMinutes() < 1) {
    formattedTime = overMinutePrefix ? 'just now' : 'Just now';
  } else if (duration.asMinutes() < 60) {
    const minutes = Math.round(duration.asMinutes());

    formattedTime = `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago`;
  } else if (overMinutePrefix) {
    formattedTime = `on ${moment.utc(time).local().format(format)}`;
  } else {
    formattedTime = moment.utc(time).local().format(format);
  }

  return formattedTime;
}

/**
 * Grab full timestamp (including year)
 * @param  {String} time
 * @param  {String} [format='ddd, MMM D, Y, h:mm a']
 * @return {String}
 */
export function fullTimestamp(time, format = 'dddd, MMMM D, Y, h:mm a') {
  return moment.utc(time).local().format(format);
}

// Takes a utc formatted string and returns in local string format
export function formatUtcToLocal(utcDate, format = 'MMM D, h:mm a') {
  return moment.utc(utcDate).local().format(format);
}

// Takes a utc time string and returns in local time string format
export function formatUtcTimeToLocal(utcTime) {
  const split = utcTime.split(':');
  const today = moment().utc();

  return moment(today).set({
    hour: split[0],
    minute: split[1],
    second: split[2],
  }).local().format('HH:mm:ss');
}

// Takes a local time string and returns in utc time string format
export function formatLocalTimeToUtc(localTime) {
  const split = localTime.split(':');
  const today = moment().local().format();

  return moment(today).set({
    hour: split[0],
    minute: split[1],
    second: split[2],
  }).utc().format('HH:mm:ss');
}

export function getStartAndEndOfDateUtc(fromDate, toDate) {
  const startDate = moment(fromDate).startOf('days').format();
  const endDate = moment(toDate).endOf('days').format();
  const startDateUtc = moment.utc(startDate).format('YYYY-MM-DD HH:mm:ss');
  const endDateUtc = moment.utc(endDate).format('YYYY-MM-DD HH:mm:ss');
  return {
    startDateUtc,
    endDateUtc,
  };
}

// Takes a utc string and returns a local moment
export function convertUtcStringToLocalMoment(utc) {
  return moment.utc(utc).local();
}

/**
 * Takes to and from as unix timestamp
 * @param  {number} [fromDate = milliseconds]
 * @param  {number} [toDate = milliseconds]
 * @return {string}
 */
export const formattedDateDifference = (fromDate, toDate = moment.utc()) => {
  moment.updateLocale('en', { relativeTime: dateRangeLabels });
  const fromUTC = moment.utc(fromDate);
  const toUTC = moment.utc(toDate);
  const difference = toUTC.from(fromUTC, true);
  moment.updateLocale('en', null);
  return difference;
};

// Takes a local time string and returns a local moment
export function convertLocalTimeToLocalMoment(localTime) {
  const split = localTime.split(':');
  const today = moment().format();

  return moment(today).set({
    hour: split[0],
    minute: split[1],
    second: split[2],
  });
}

export function isEndOfDay(utcDate) {
  const localMoment = convertUtcStringToLocalMoment(utcDate);
  return localMoment.hour() === 23 && localMoment.minute() === 59 && localMoment.second() === 59;
}

export function formatTimeZone(timeZone) {
  return timeZone ? `${timeZone.name} Time (UTC ${timeZone.utcOffset}) - ${timeZone.location}` : '';
}

export function convertDateInTimeZone(utcDate, timeZone, observesDst = true) {
  const convertedTimeZoneDate = moment.utc(utcDate).tz(timeZone);

  // if date in DST and channel doesn't observe DST, then reduce offset by one hour
  if (!observesDst && convertedTimeZoneDate.isDST()) {
    convertedTimeZoneDate.subtract(1, 'hour');
  }

  return convertedTimeZoneDate;
}

export function formatEpochTimestamp(epochTimestamp) {
  return moment(epochTimestamp, 'x').format('MM/DD/YY h:mm a');
}

export function formatYear(year) {
  if (year === -1) {
    return year;
  }
  return year ? moment([year]).format('YY') : year;
}

export function formatDateForTable(timestamp) {
  return convertUtcStringToLocalMoment(timestamp).format('MM/DD/YYYY[\n]hh:mm a');
}

export function formatDateForTableShorthand(timestamp) {
  return convertUtcStringToLocalMoment(timestamp).format('MM/DD/YY');
}

export function sortByDate(current, next, format) {
  const currentDate = moment(current, format);
  const nextDate = moment(next, format);
  if (currentDate.isBefore(nextDate)) {
    return -1;
  } else if (nextDate.isBefore(currentDate)) {
    return 1;
  } else {
    return 0;
  }
}

export function convertMomentToUnixTimestampMs(date) {
  return date.valueOf();
}

/**
 * @param {string} unixTimestampMs
 * @returns {string}
 * @description this will convert unixTimestampMs to UTC date in string
 */
export function convertUnixTimestampMsToUTCDateString(unixTimestampMs) {
  return moment(Number(unixTimestampMs)).utc().format();
}

/**
 * @param {string} unixTimestampMs
 * @param {string} endUnit
 * @returns {string}
 * @description this will convert unixTimestampMs to end time of UTC date in string
 */
export function convertUnixTimestampMsToUTCEndDateString(unixTimestampMs, endUnit = 'day') {
  return moment(Number(unixTimestampMs)).endOf(endUnit).utc().format();
}

/**
 * @param {string} unixTimestampMs
 * @param {string} startDate
 * @returns {string}
 * @description this will convert unixTimestampMs to start time of UTC date in string
 */
export function convertUnixTimestampMsToUTCStartDateString(unixTimestampMs, startUnit = 'day') {
  return moment(Number(unixTimestampMs)).startOf(startUnit).utc().format();
}

export function findDurationFromMilliseconds(milliseconds) {
  const duration = moment.duration(milliseconds);
  const { hours, seconds, minutes } = duration._data;
  if (!hours && !minutes && !seconds) {
    return '0 seconds';
  }
  return `${hours ? `${hours} hours, ` : ''}${minutes ? `${minutes} minute${minutes === 1 ? '' : 's'} and ` : ''}${seconds ? `${seconds} second${seconds === 1 ? '' : 's'}` : ''}`;
}

// Return date in Format of Fri, Aug 1, 2020
export function updatedAtDateFormatter(date) {
  return moment(date).format('ddd, MMM DD, YYYY');
}

/**
 * Return numeric date from date string
 * @param  {String} date
 * @return {String}
 */
export function numericDateFormatter(date) {
  return moment(date).format('MM/DD/YY h:mm a');
}

export const convertHoursToDays = (numericHours, displayHours) => {
  if (numericHours > 0) {
    const duration = moment.duration(parseInt(numericHours, 10), 'hours');
    const hours = duration.hours();
    const isHoursPlural = hours > 1;
    const days = Math.floor(duration.asDays());
    const isDaysPlural = days > 1;
    let convertedString = '';
    if (days > 0) {
      convertedString = `${days} ${isDaysPlural ? 'Days ' : 'Day '}`;
    }
    if (hours > 0) {
      convertedString += `${days !== 0 || displayHours ? hours : ''} ${isHoursPlural ? 'Hours' : 'Hour'}`;
    }
    return convertedString.trim();
  }
  return '';
};

export const convertDaysToHours = (days) => {
  if (days) {
    const duration = moment.duration(parseInt(days, 10), 'days');
    return duration.asHours();
  } return null;
};

export const convertHourStringToDays = (hours) => {
  if (hours) {
    const duration = moment.duration(parseInt(hours, 10), 'hours');
    return duration.asDays();
  } return null;
};

export const formatSecondsRemaining = (secondsRemaining = 0) => moment()
  .startOf('day')
  .seconds(secondsRemaining)
  .format('m:ss');

export const formatTimer = (created) => {
  const now = moment();
  const startedAt = moment(created);
  const timer = moment.duration(now.diff(startedAt))._data;
  const { hours, minutes, seconds } = timer;
  let timeFormat = 'mm:ss';
  if (hours > 0) timeFormat = 'hh:mm:ss';

  return moment()
    .hours(hours)
    .minutes(minutes)
    .seconds(seconds)
    .format(timeFormat);
};

export function calculatePastDateRange(duration) {
  if (duration === 'year') {
    return {
      startDate: moment().subtract(1, 'year').startOf('day'),
      endDate: moment(),
    };
  }
  const today = duration === 0;
  const yesterday = duration === 1;
  const startDate = today ? moment().startOf('day') : moment().startOf('day').subtract(duration, 'days');
  const endDate = yesterday ? moment().subtract(duration, 'days').endOf('day') : moment();
  return { startDate, endDate };
}

export function calculateFutureDateRange(duration) {
  const today = duration === 0;
  const tomorrow = duration === 1;
  const endDate = today ? moment().endOf('day') : moment().add(duration, 'days').endOf('day');
  const startDate = tomorrow ? moment().startOf('day').add(duration, 'days') : moment().startOf('day');
  return { startDate, endDate };
}

export function calculateDateRange(duration, isUpcoming = false) {
  return isUpcoming ? calculateFutureDateRange(duration) : calculatePastDateRange(duration);
}

function getDatePickerOptions(datePickerValues, isUpcoming) {
  if (datePickerValues) {
    return datePickerValues;
  } return isUpcoming ? DEFAULT_UPCOMING_DATE_OPTIONS : DEFAULT_PAST_DATE_OPTIONS;
}

export function getActiveKeyDuration(activeKey, datePickerValues, isUpcoming = false) {
  const durationOptions = getDatePickerOptions(datePickerValues, isUpcoming);
  return durationOptions.find((item) => item.id === activeKey)?.duration;
}

export function getDateValuesFromQueryString(queryStringValues, defaultValues, datePickerValues) {
  const dateOptions = {};
  const isUpcoming = defaultValues.activeTab && Number(queryStringValues.activeTab) !== 2;
  const duration = getActiveKeyDuration(Number(queryStringValues.activeKey || defaultValues.activeKey), datePickerValues, isUpcoming);
  if (duration !== undefined) {
  // If duration is defined, calculate based on current date
    const { startDate, endDate } = calculateDateRange(duration, isUpcoming);
    dateOptions.startDate = startDate;
    dateOptions.endDate = endDate;
  // Otherwise, use values from query string
  } else if (queryStringValues.startDate && queryStringValues.endDate) {
    dateOptions.endDate = moment(queryStringValues.endDate);
    dateOptions.startDate = moment(queryStringValues.startDate);
  }
  return dateOptions;
}

export function getDateQueryStringFromValue(values, datePickerValues) {
  const queryStringParams = {};
  if (datePickerValues && values.activeKey) {
    const duration = getActiveKeyDuration(values.activeKey, datePickerValues);
    if (duration === undefined) {
      queryStringParams.startDate = values.startDate.format('YYYY-MM-DD');
      queryStringParams.endDate = values.endDate.format('YYYY-MM-DD');
    }
  } else if (values.activeKey === 5) {
    queryStringParams.startDate = values.startDate.format('YYYY-MM-DD');
    queryStringParams.endDate = values.endDate.format('YYYY-MM-DD');
  }
  return queryStringParams;
}
