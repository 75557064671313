import { createApi } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { EVENT_SIZE } from '../constants/AppConstants';
import { axiosBaseQuery } from '../helpers/AxiosHelper';

// Create Adapter For Thread To Avoid Duplicates
const threadAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.timestamp - b.timestamp,
  selectId: (event) => event.id,
});
const threadSelectors = threadAdapter.getSelectors((state) => state);

const shapeThreadResult = (result) => result?.data?.Events || [];

// Define our single API slice object
export const threadSlice = createApi({
  reducerPath: 'threadV3',
  baseQuery: axiosBaseQuery({
    baseURL: process.env.REACT_APP_V3_BASE_URL,
  }),
  tagTypes: ['Thread'],
  endpoints: (builder) => ({
    getThread: builder.query({
      query: (options) => {
        const {
          userId,
          groupId,
          following = false,
          direct = false,
          organizationId,
          assigned = false,
          pageNo = 0,
          channelIds = [],
        } = options;
        if (!userId) return null;
        let url = `/organizations/${organizationId}/users/${userId}/thread?page=${pageNo}&pageSize=${EVENT_SIZE}&following=${following}&direct=${direct}&assigned=${assigned}&sort=Descending`;
        if (channelIds.length > 0) {
          url = `${url}&channelIds=${channelIds}`;
        } else if (groupId) {
          url = `${url}&groupId=${groupId}`;
        }
        return { url, method: 'get', shapeResult: shapeThreadResult };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        // Omit pageNo param from cache key
        const {
          pageNo,
          ...otherQueryArgs
        } = queryArgs;
        return otherQueryArgs;
      },
      providesTags: (result, error, arg) =>
        [{ type: 'Thread', id: arg.userId }, 'Thread'],
      transformResponse: (response) =>
        threadAdapter.addMany(
          threadAdapter.getInitialState({
            hasMorePages: response.length === EVENT_SIZE,
          }),
          response,
        ),
      merge(currentCacheData, responseData) {
        threadAdapter.upsertMany(
          currentCacheData,
          threadSelectors.selectAll(responseData),
        );
        currentCacheData.hasMorePages = responseData.hasMorePages; // eslint-disable-line no-param-reassign
      },
    }),
  }),
});
export function addEventToQueryResults(payload, threadOptions) {
  return (dispatch) => {
    dispatch(
      threadSlice.util.updateQueryData('getThread', threadOptions, (draft) => {
        threadAdapter.addOne(
          draft,
          payload,
        );
      }),
    );
  };
}

export function upsertEventInQueryResults(payload, threadOptions) {
  return (dispatch) => {
    dispatch(
      threadSlice.util.updateQueryData('getThread', threadOptions, (draft) => {
        threadAdapter.upsertOne(
          draft,
          payload,
        );
      }),
    );
  };
}

// replaces optimistic id with event id
export function updateEventInQueryResults({ eventId, payload, threadOptions }) {
  return (dispatch) => {
    dispatch(
      threadSlice.util.updateQueryData('getThread', threadOptions, (draft) => {
        const event = threadSelectors.selectById(draft, eventId);
        if (event) {
          threadAdapter.updateOne(
            draft,
            { id: eventId, changes: payload },
          );
        } else {
          threadAdapter.upsertOne(
            draft,
            payload,
          );
        }
      }),
    );
  };
}

export { threadSelectors, threadAdapter };

export const { useGetThreadQuery, useLazyGetThreadQuery, useGetThreadQueryState } = threadSlice;

export default threadSlice;
