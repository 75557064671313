import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as AuthReducer from '../reducers/authReducer';

import * as OrganizationReducer from '../reducers/organizationReducer';
import { fetchBilling } from '../reducers/billingReducer';
import { getLoggedInUser } from '../selectors/userSelectors';
import SelectOrganization from '../components/SelectOrganization';

class SelectOrganizationContainer extends Component {
  state = {
    isPageLoading: false,
  };

  // eslint-disable-next-line camelcase
  componentDidMount() {
    if (this.props.currentUser) this.props.unsetUserAction(); // unset the store so that data does not persist from one org to another.

    this.props.requestOrgData();
    this.props.fetchMyUsers();
  }

  handleSelectOrg = (orgId, userId) => {
    // Added this new state property to avoid a screen flash while loading (since more than one reducer is switched to 'loading' at various times)
    // Does not need to be set back to false since code below triggers a redirect.
    this.setState({ isPageLoading: true });
    this.props.selectOrg(orgId, userId, null, this.props.history)
      .then((nextPath) => {
        this.props.history.push(nextPath);
      });
  }

  render() {
    const props = {
      handleSelectOrg: this.handleSelectOrg,
      myUsersRaw: this.props.myUsersRaw,
      orgSearchIds: this.props.orgSearchIds,
      organizations: this.props.organizations,
      orgSearchLoading: this.props.orgSearchLoading,
      searchInputActive: this.props.searchInputActive,
      pageLoading: this.props.pageLoading || this.state.isPageLoading,
    };

    return (<SelectOrganization {...props} />);
  }
}

SelectOrganizationContainer.propTypes = {
  currentUser: PropTypes.object,
  unsetUserAction: PropTypes.func.isRequired,
  fetchMyUsers: PropTypes.func.isRequired,
  myUsersRaw: PropTypes.array.isRequired,
  selectOrg: PropTypes.func.isRequired,
  orgSearchIds: PropTypes.array.isRequired,
  organizations: PropTypes.object.isRequired,
  orgSearchLoading: PropTypes.bool.isRequired,
  searchInputActive: PropTypes.bool.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  requestOrgData: PropTypes.func.isRequired,
  fetchBilling: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { user, organization, billing } = state;

  return {
    currentUser: getLoggedInUser(state),
    myUsersRaw: user.myUsersRaw,
    organizations: organization.organizations,
    orgSearchIds: organization.orgSearchIds,
    pageLoading: organization.loading || billing.loading,
    orgSearchLoading: organization.orgSearchLoading,
    searchInputActive: organization.searchInputActive,
  };
};

const actions = {
  unsetUserAction: AuthReducer.unsetUserAction,
  fetchMyUsers: AuthReducer.fetchMyUsers,
  selectOrg: AuthReducer.setOrganization,
  requestOrgData: OrganizationReducer.requestOrgData,
  fetchBilling,
};

export default connect(mapStateToProps, actions)(SelectOrganizationContainer);
