import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'rhinostyle';
import { toggleModal } from '../reducers/threadReducer';
import { MODAL_OPTIONS } from '../constants/ThreadConstants';
import EmptyMessage from './EmptyMessage';

const AudioAccessRequiredModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.thread[MODAL_OPTIONS.audioAccess]);

  function handleToggleModal() {
    dispatch(toggleModal(MODAL_OPTIONS.audioAccess));
  }

  return (
    <Modal open={isOpen}>
      <ModalHeader title="Access to audio is required" onClose={handleToggleModal} />
      <ModalBody>
        <EmptyMessage section="RhinoVideo Audio Declined" />
        <div className="u-text-center"><Button type="primary" onClick={handleToggleModal}>Close This Window</Button></div>
      </ModalBody>
    </Modal>
  );
};

export default AudioAccessRequiredModal;
