import queryString from 'query-string';
import { AppConstants, Types } from '../constants';

const { REACT_APP_SEARCH_BASE_URL } = process.env;

export const getFilterLabelInformation = (id) => { // eslint-disable-line
  if (id === 'name') {
    return {
      label: 'Name',
      placeholder: 'First, last, or preferred name',
    };
  } else if (id === 'dob') {
    return {
      label: 'DOB',
      placeholder: 'Birthdate (MM/DD/YYYY)',
    };
  } else if (id === 'phone') {
    return {
      label: 'Phone',
      placeholder: AppConstants.PHONE_PLACEHOLDER,
    };
  } else if (id === 'id') {
    return {
      label: 'ID',
      placeholder: 'Patient ID',
    };
  } else if (id === 'tags') {
    return {
      label: 'Tags',
      placeholder: 'Tag name',
    };
  }

  return {
    label: 'Email',
    placeholder: 'Email address',
  };
};

export const filterContactParams = { filterParams: ['name', 'dob', 'phone', 'id', 'email', 'tags'] };
export const filterMemberParams = { filterParams: ['name', 'email', 'tags'] };

export const searchScopeMapping = {
  members: [Types.TYPE_MEMBER],
  nonMembers: [Types.TYPE_PATIENT, Types.TYPE_UNKNOWN, Types.TYPE_USER_OTHER],
  patientsAndOthers: [Types.TYPE_PATIENT, Types.TYPE_USER_OTHER],
  patients: [Types.TYPE_PATIENT],
  allContacts: [Types.TYPE_PATIENT, Types.TYPE_UNKNOWN, Types.TYPE_USER_OTHER, Types.TYPE_ORGANIZATION],
};

const getSearchScope = (scope = 'allContacts', userType) => {
  if (userType) {
    return [userType];
  } return searchScopeMapping[scope];
};

function getTypeName(type, search) {
  return {
    [type]: search,
  };
}

export function getSearchUrl(payload) {
  const {
    userType,
    type,
    excludeCurrentUser,
    idsToExclude,
    page = 0,
    size = AppConstants.CONTACT_RESULT_SIZE,
    includeInactiveAndDeleted,
    tagIds = [],
    search,
    scope,
    countTotalRecords = 0,
    groups = [],
    roles = [],
    active,
  } = payload;
  let {
    tags,
    dob,
    id,
    name,
    phone,
    email,
  } = payload;
  // Handles single criteria search
  if (type) {
    ({ name, phone, dob, id, tags, email } = getTypeName(type, search));
  }
  const searchScope = getSearchScope(scope, userType);
  let url = `${REACT_APP_SEARCH_BASE_URL}/users/search?page=${page}&size=${size}`;
  if (searchScope) url += `&scope=${searchScope}`;
  if (name) url += `&name=${encodeURIComponent(name)}`;
  if (tags) url += `&tags=${encodeURIComponent(tags)}`;
  if (dob) url += `&dob=${encodeURIComponent(dob)}`;
  if (id) url += `&id=${encodeURIComponent(id)}`;
  if (phone) url += `&phone=${encodeURIComponent(phone)}`;
  if (email) url += `&email=${encodeURIComponent(email)}`;
  if (tagIds.length > 0) url += `&tagIds=${tagIds}`;
  if (groups.length > 0) url += `&groups=${groups}`;
  if (roles.length > 0) url += `&roles=${roles}`;
  if (excludeCurrentUser) url += `&excludeCurrentUser=${excludeCurrentUser}`;
  if (idsToExclude) url += `&idsToExclude=${idsToExclude}`;
  if (includeInactiveAndDeleted) url += `&includeInactiveAndDeleted=${includeInactiveAndDeleted}`;
  if (countTotalRecords) url += `&countTotalRecords=${countTotalRecords}`;
  if (active) url += `&active=${active}`;

  return url;
}

export function getSearchQueryString(payload) {
  const url = `${REACT_APP_SEARCH_BASE_URL}/users/search?${queryString.stringify({ ...payload, scope: getSearchScope(payload.scope, payload.userType) }, { skipNull: true, skipEmptyString: true, arrayFormat: 'comma' })}`;
  return url;
}
