import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as UserReducer from '../reducers/userReducer';
import * as UIReducer from '../reducers/uiReducer';
import ChangePassword from '../components/ChangePassword';
import { LocationHelpers, UserHelpers } from '../helpers';
import { PASSWORD_STRENGTH_VALIDATION_MESSAGE } from '../constants/AppConstants';

class ChangePasswordContainer extends Component {
  state = {
    password: '',
    confirmPassword: '',
  };

  componentDidMount() {
    document.title = `Rhinogram | ${LocationHelpers.getPageTitle(window.location.pathname)}`;
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleSave();
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  shapePasswordForDB = (data) => ({
    newPass: data.password,
  });

  validate = () => {
    const { password, confirmPassword } = this.state;
    let error;

    if (!password) {
      error = 'Password is a required field';
    } else if (!confirmPassword) {
      error = 'Confirm Password is a required field';
    } else if (!UserHelpers.isSecurePassword(password)) {
      error = PASSWORD_STRENGTH_VALIDATION_MESSAGE;
    } else if (password !== confirmPassword) {
      error = 'Passwords do not match';
    }

    if (error) {
      this.props.setError({ data: { message: error } });
    }

    return !!error;
  }

  handleSave = () => {
    if (!this.validate()) {
      const password = this.shapePasswordForDB(this.state);

      this.props.updateUserPassword(password)
        .then(() => this.props.history.push('/inbox'));
    }
  }

  render() {
    const props = {
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      handleSave: this.handleSave,
      handleChange: this.handleChange,
      handleKeyPress: this.handleKeyPress,
      error: this.props.error,
    };

    return (
      <ChangePassword {...props} />
    );
  }
}

ChangePasswordContainer.propTypes = {
  setError: PropTypes.func.isRequired,
  error: PropTypes.object,
  updateUserPassword: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { ui } = state;

  return {
    error: ui.error,
  };
};

const actions = {
  updateUserPassword: UserReducer.updateUserPassword,
  setError: UIReducer.setError,
};

export default connect(mapStateToProps, actions)(ChangePasswordContainer);
