import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Close,
  LoaderCircle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Scrollbars,
  UtilityInlineGrid,
  UtilityList,
  UtilityListItem,
} from 'rhinostyle';

import BusinessHoursGrouped from './BusinessHoursGrouped';
import { UserHelpers, DataHelpers } from '../helpers';
import { USER_PRESENCE, AVATAR_BASE_URL, RHINOFORM_ADMIN_ROLE_ID, INTEGRATED_CONTACT_EDITOR_ROLE_ID, LIMITED_PROVIDER_ROLE_ID } from '../constants/AppConstants';
import { MEMBER_EDIT, MEMBER_DELETE, MEMBER_VIEW } from '../constants/UserPermissionsConstants';
import { TYPE_INTEGRATION_AXIUM } from '../constants/Types';

const { userHasAnyOfPermissions, hasTeamThreadViewPermissions } = UserHelpers;

const DeactivateMemberModal = (props) => {
  const {
    activeUser,
    formInProgress,
    handleClose,
    handleDeactivateMember,
    handleReverseComplete,
    isOpen,
    perMemberFee,
    isWarning,
    isLoaderShow,
  } = props;

  const fullName = UserHelpers.formatName(activeUser);

  return (
    <Modal onReverseComplete={handleReverseComplete} open={isOpen}>
      <ModalHeader
        onClose={handleClose}
        title="Deactivate Member?"
        titleSub={!isLoaderShow ? 'If you confirm the deactivation below, the following will happen:' : ''}
      />
      {isLoaderShow ? (
        <ModalBody>
          <div className="u-text-center">
            <LoaderCircle size="large" className="u-text-primary" />
          </div>
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <ol className="u-list--numbers">
              <li><strong>{fullName}</strong> will no longer be able to login to Rhinogram.</li>
              <li>Any messages that <strong> {fullName}</strong> has sent will still be visible.</li>
              {isWarning ?
                <li data-cypress="deactivateAddonMessage">Your monthly bill will decrease by <strong>${perMemberFee}</strong>, starting next month.</li> :
                null}
              <li>Any conversations assigned or forwarded to <strong>{fullName}</strong> will automatically close.</li>
            </ol>
            <p className="u-text-italic">If at any time you wish to reactivate <strong>{fullName} </strong>, you can do so.</p>
          </ModalBody>
          <ModalFooter>
            <UtilityInlineGrid align="between">
              <Button onClick={handleClose}>
                Cancel
              </Button>
              <Button loading={formInProgress} onClick={handleDeactivateMember} type="danger" data-cypress="confirmDeactivate">
                Deactivate
              </Button>
            </UtilityInlineGrid>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

DeactivateMemberModal.propTypes = {
  activeUser: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDeactivateMember: PropTypes.func.isRequired,
  handleReverseComplete: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  perMemberFee: PropTypes.number.isRequired,
  isWarning: PropTypes.bool.isRequired,
  isLoaderShow: PropTypes.bool.isRequired,
};

const ActivateMemberModal = (props) => {
  const {
    activeUser,
    formInProgress,
    handleClose,
    handleActivateMember,
    handleReverseComplete,
    isOpen,
    perMemberFee,
    isWarning,
    isLoaderShow,
  } = props;

  const fullName = UserHelpers.formatName(activeUser);

  return (
    <Modal onReverseComplete={handleReverseComplete} open={isOpen}>
      <ModalHeader
        onClose={handleClose}
        title="Reactivate Member?"
        titleSub={!isLoaderShow ? 'If you confirm the reactivation below, the following will happen:' : ''}
      />
      {isLoaderShow ? (
        <ModalBody>
          <div className="u-text-center">
            <LoaderCircle size="large" className="u-text-primary" />
          </div>
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <ol className="u-list--numbers">
              <li><strong>{fullName}</strong> will be able to login to Rhinogram again.</li>
              {isWarning ?
                <li data-cypress="reactivateAddonMessage">Your monthly bill will increase by <strong>${perMemberFee}</strong>, starting next month.</li> :
                null}
            </ol>
            <p className="u-text-italic">If, at any time, you wish to deactivate <strong> {fullName} </strong>, you can do so.</p>
          </ModalBody>
          <ModalFooter>
            <UtilityInlineGrid align="between">
              <Button onClick={handleClose}>
                Cancel
              </Button>
              <Button loading={formInProgress} onClick={handleActivateMember} type="primary" data-cypress="confirmReactivate">
                Reactivate
              </Button>
            </UtilityInlineGrid>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

ActivateMemberModal.propTypes = {
  activeUser: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleActivateMember: PropTypes.func.isRequired,
  handleReverseComplete: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  perMemberFee: PropTypes.number.isRequired,
  isWarning: PropTypes.bool.isRequired,
  isLoaderShow: PropTypes.bool.isRequired,
};

const MemberProfilePanel = (props) => {
  const history = useHistory();
  const {
    activeUser,
    channels,
    currentUser,
    groups,
    handleModalReverseComplete,
    handleToggle,
    handleTogglePanel,
    handleUpdateActiveStatus,
    modalFormInProgress,
    showDeactivateMemberModal,
    showActivateMemberModal,
    isGoToChatButtonVisible,
    tags,
    timeZones,
    isWarning,
    perMemberFee,
    handleActivateDeactivate,
    isLoaderShow,
    isCcr,
    handleSendInvite,
    isInviteSendInProgress,
    isRhinoformEnabled,
    isEditIntegratedContactEnabled,
    integrationPartnerTypeId,
  } = props;

  const pushRoute = (url) => history.push(url);
  const showLimitedProviderRole = integrationPartnerTypeId === TYPE_INTEGRATION_AXIUM;
  const shouldShowRoles = (roleId) => (!isRhinoformEnabled && roleId === RHINOFORM_ADMIN_ROLE_ID) ||
   (!isEditIntegratedContactEnabled && roleId === INTEGRATED_CONTACT_EDITOR_ROLE_ID) ||
  (roleId === LIMITED_PROVIDER_ROLE_ID && !showLimitedProviderRole);

  // this is used to render the online status indicator next to the member's name in the profile summary title
  const renderOnlineIndicator = () => {
    // the onlineStatus key is only present on this object IF the user is online
    if (activeUser.onlineStatus === USER_PRESENCE.ONLINE) {
      return (
        <div className="summary-panel__intro__title__online-status-icon u-bg-secondary" data-cypress="online-status" />
      );
    }
    return (
      <div className="summary-panel__intro__title__online-status-icon u-bg-gray-light" data-cypress="offline-status" />
    );
  };

  if (!activeUser) {
    return false;
  }

  const activeUserGroupIds = activeUser.groups &&
  [...activeUser.groups].map((group) => (typeof group === 'object' ? group.id : group))
    .filter((groupId) => groups[groupId] && !groups[groupId].isDynamic)
    .sort(DataHelpers.sortBy(groups, 'name'));

  const shapedUser = {
    avatarName: UserHelpers.formatAvatarName(activeUser.firstName, activeUser.lastName),
    businessHours: activeUser.businessHours,
    businessTitle: activeUser.businessTitle,
    email: activeUser.loginEmail,
    groups: activeUserGroupIds && activeUserGroupIds.map((g) => <UtilityListItem data-cypress={`group-${groups[g].name}`} key={groups[g].id}>{groups[g].name}</UtilityListItem>),
    id: activeUser.id,
    name: UserHelpers.formatName(activeUser),
    profileImageUrl: activeUser.profileImageUrl ? `${AVATAR_BASE_URL}${activeUser.profileImageUrl}` : '',
    roles: Array.isArray(activeUser.roles) && activeUser.roles.map((role) => {
      if (shouldShowRoles(role.id)) return '';
      return <UtilityListItem data-cypress={`role-${role.name}`} key={`${role.systemRole ? 'system-role-' : 'custom-role-'}${role.id}`}>{role.name}</UtilityListItem>;
    }),
    routedChannels: activeUser.routedChannels && activeUser.routedChannels.map((c) => <UtilityListItem data-cypress={`channels-${channels[c].name}`} key={channels[c].id}>{channels[c].name}</UtilityListItem>),
    tags: activeUser.tags && activeUser.tags.map((t) => <UtilityListItem data-cypress={`tag-${tags[t]?.name || t?.name}`} key={tags[t]?.id || t?.id}>#{tags[t]?.name || t?.name}</UtilityListItem>),
    timeZone: timeZones[activeUser.timeZoneId],
    type: UserHelpers.formatTypes(activeUser),
  };

  const renderActions = () => {
    const showActivateButton = !activeUser.active && userHasAnyOfPermissions([MEMBER_EDIT]);

    return (
      <>
        <UtilityInlineGrid align="middle">
          {showActivateButton ? (
            <>
              <Button size="small" type="accent" onClick={() => handleActivateDeactivate('showActivateMemberModal')} data-cypress="activateButton">Activate</Button>
              <ActivateMemberModal
                activeUser={activeUser}
                handleClose={() => handleToggle('showActivateMemberModal')}
                handleActivateMember={() => handleUpdateActiveStatus(true)}
                handleReverseComplete={handleModalReverseComplete}
                formInProgress={modalFormInProgress}
                isOpen={showActivateMemberModal}
                perMemberFee={perMemberFee}
                isWarning={isWarning}
                isLoaderShow={isLoaderShow}
              />
            </>
          ) : (
            <>
              {userHasAnyOfPermissions([MEMBER_EDIT]) &&
              activeUser.active === true &&
              activeUser.isInviteSent === false && (
              <Button
                size="small"
                type="accent"
                loading={isInviteSendInProgress}
                onClick={() => handleSendInvite(activeUser.id)}
              >
                Send Invite
              </Button>
              )}
              {!isCcr
                  && hasTeamThreadViewPermissions()
                  && isGoToChatButtonVisible
                  && <Button size="small" onClick={() => pushRoute(`/chat/user/${shapedUser.id}`)} type="primary">Go to Conversation</Button>}
              {userHasAnyOfPermissions([MEMBER_EDIT]) && (
                <Button
                  size="small"
                  type="primary"
                  outlined
                  onClick={() => pushRoute(`/settings/organization/members/edit/${shapedUser.id}`)}
                  data-cypress="editMemberProfile"
                >
                  Edit Member
                </Button>
              )}
            </>
          )}
        </UtilityInlineGrid>
      </>

    );
  };

  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title">Summary</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => handleTogglePanel(shapedUser.id)}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__avatar">
                <Avatar
                  image={shapedUser.profileImageUrl}
                  name={shapedUser.avatarName}
                  type="member"
                  size="large"
                />
              </div>
              <div className="summary-panel__intro__title">
                {renderOnlineIndicator()}
                {shapedUser.name}
              </div>
              <div className="summary-panel__intro__sub">{shapedUser.type}</div>
              <div className="summary-panel__intro__actions">
                {renderActions()}
              </div>
            </div>
            {(userHasAnyOfPermissions([MEMBER_VIEW]) || currentUser.id === activeUser.id) && (
            <div>
                {(shapedUser.businessTitle || shapedUser.email) && (
                  <div className="summary-panel__section">
                    <div className="summary-panel__section__label">Profile Details</div>
                    <div className="summary-panel__section__content">
                      <UtilityList space className="u-m-b-0">
                        {shapedUser.businessTitle && <UtilityListItem>{shapedUser.businessTitle}</UtilityListItem>}
                        {shapedUser.email && <UtilityListItem>{shapedUser.email}</UtilityListItem>}
                      </UtilityList>
                    </div>
                  </div>
                )}
                {(shapedUser.businessHours && shapedUser.timeZone) && (
                  <div className="summary-panel__section">
                    <div className="summary-panel__section__label">Availability</div>
                    <div className="summary-panel__section__content">
                      <BusinessHoursGrouped
                        businessHours={shapedUser.businessHours}
                        timeZone={shapedUser.timeZone}
                      />
                    </div>
                  </div>
                )}
                {(activeUser.roles?.length > 0) && (
                  <div className="summary-panel__section">
                    <div className="summary-panel__section__label">Roles</div>
                    <div className="summary-panel__section__content">
                      <UtilityList space className="u-m-b-0">
                        {shapedUser.roles}
                      </UtilityList>
                    </div>
                  </div>
                )}
                {(activeUser.groups?.length > 0) && (
                  <div className="summary-panel__section">
                    <div className="summary-panel__section__label">Groups</div>
                    <div className="summary-panel__section__content">
                      <UtilityList space className="u-m-b-0">
                        {shapedUser.groups}
                      </UtilityList>
                    </div>
                  </div>
                )}
                {(activeUser.routedChannels?.length > 0) && (
                  <div className="summary-panel__section">
                    <div className="summary-panel__section__label">Channels</div>
                    <div className="summary-panel__section__content">
                      <UtilityList space className="u-m-b-0">
                        {shapedUser.routedChannels}
                      </UtilityList>
                    </div>
                  </div>
                )}
                {(activeUser.tags?.length > 0) && (
                  <div className="summary-panel__section">
                    <div className="summary-panel__section__label">Tags</div>
                    <div className="summary-panel__section__content">
                      <UtilityList space className="u-m-b-0">
                        {shapedUser.tags}
                      </UtilityList>
                    </div>
                  </div>
                )}
                {(activeUser.active && userHasAnyOfPermissions([MEMBER_DELETE]) && (currentUser.id !== activeUser.id)) && (
                  <div className="summary-panel__end u-text-center">
                    <Button
                      reset
                      className="u-text-small u-text-danger"
                      onClick={() => handleActivateDeactivate('showDeactivateMemberModal')}
                      data-cypress="deactivateButton"
                    >
                      Deactivate
                    </Button>
                  </div>
                )}
            </div>
            )}
            <DeactivateMemberModal
              activeUser={activeUser}
              handleClose={() => handleToggle('showDeactivateMemberModal')}
              handleDeactivateMember={() => handleUpdateActiveStatus(false)}
              handleReverseComplete={handleModalReverseComplete}
              formInProgress={modalFormInProgress}
              isOpen={showDeactivateMemberModal}
              perMemberFee={perMemberFee}
              isWarning={isWarning}
              isLoaderShow={isLoaderShow}
            />
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

MemberProfilePanel.propTypes = {
  activeUser: PropTypes.object.isRequired,
  channels: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  groups: PropTypes.object.isRequired,
  handleModalReverseComplete: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
  handleUpdateActiveStatus: PropTypes.func.isRequired,
  modalFormInProgress: PropTypes.bool.isRequired,
  showDeactivateMemberModal: PropTypes.bool.isRequired,
  showActivateMemberModal: PropTypes.bool.isRequired,
  isGoToChatButtonVisible: PropTypes.bool.isRequired,
  tags: PropTypes.object.isRequired,
  timeZones: PropTypes.object.isRequired,
  isWarning: PropTypes.bool.isRequired,
  perMemberFee: PropTypes.number.isRequired,
  handleActivateDeactivate: PropTypes.func.isRequired,
  isLoaderShow: PropTypes.bool.isRequired,
  isCcr: PropTypes.bool.isRequired,
  handleSendInvite: PropTypes.func.isRequired,
  isInviteSendInProgress: PropTypes.bool.isRequired,
  isRhinoformEnabled: PropTypes.bool,
  isEditIntegratedContactEnabled: PropTypes.bool,
  integrationPartnerTypeId: PropTypes.number,
};

export default MemberProfilePanel;
