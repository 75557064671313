import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Button, Table } from 'rhinostyle';

import PDFViewerContainer from '../containers/PdfViewerContainer';

const OrganizationBillingHistory = (props) => {
  const {
    statements,
    handlePdfClick,
    handlePdfViewerClose,
    loadMore,
    hasMore,
    index,
    viewLess,
    hasLess,
    totalStatements,
    isLoading,
  } = props;

  const renderStatements = (s) => {
    const filename = `${s.id}.pdf`;
    const url = `${process.env.REACT_APP_API_BASE_URL}/billing/statement/pdf/${s.id}`;
    let pdfOpen = false;
    if (index === s.id) {
      pdfOpen = true;
    }
    return (
      <tr key={s.id}>
        <td>{s.datePaid ? moment(s.datePaid).format('MM/DD/YYYY') : null}</td>
        <td>{s.paymentAmount ? s.paymentAmount : null}</td>
        <td>
          <Button reset className="u-text-primary" onClick={() => handlePdfClick(s.id)}>PDF</Button>
          <PDFViewerContainer
            open={pdfOpen}
            attachmentUrl={url}
            fileName={filename}
            handlePdfViewerClose={handlePdfViewerClose}
          />
        </td>
      </tr>
    );
  };

  return (
    <div className="box">
      <div className="box__title-wrapper">
        <div className="box__title">History</div>
      </div>
      {statements && statements.length > 0 ? (
        <>
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {statements.map(renderStatements)}
            </tbody>
          </Table>
          {hasMore && (
            <div className="u-m-t u-text-center">
              <p className="u-text-small u-text-muted u-m-b-small">Showing {statements.length} of {totalStatements} payments<br /></p>
              <Button reset className="u-text-primary" onClick={loadMore} loading={isLoading}>Load more</Button>
            </div>
          )}
          {hasLess && (
            <div className="u-m-t u-text-center">
              <p className="u-text-small u-text-muted u-m-b-small">Showing {statements.length} of {totalStatements} payments<br /></p>
              <Button reset className="u-text-primary" onClick={viewLess}>View less</Button>
            </div>
          )}
        </>
      ) :
        <p className="u-text-center">No billing history for this organization.</p>}
    </div>
  );
};

OrganizationBillingHistory.propTypes = {
  statements: PropTypes.array.isRequired,
  handlePdfClick: PropTypes.func.isRequired,
  handlePdfViewerClose: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  viewLess: PropTypes.func.isRequired,
  hasLess: PropTypes.bool.isRequired,
  totalStatements: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default OrganizationBillingHistory;
