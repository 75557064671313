import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Icon,
} from 'rhinostyle';

import { AppConstants, Types } from '../../constants';
import {
  DateHelpers,
  SavedContentHelpers,
} from '../../helpers';
import {
  TYPE_USER_LIBRARY_SAVED_CONTENT,
} from '../../constants/Types';
import PdfViewerContainer from '../../containers/PdfViewerContainer';
import { getLoggedInUserOrganization } from '../../selectors/userSelectors';
import { } from '../../selectors/uiSelectors';

const {
  TYPE_EVENT_STATUS_ERROR,
  TYPE_EVENT_STATUS_DELIVERED,
} = Types;

const SavedContentEvent = (props) => {
  const [isPdfViewerOpen, openPdfViewer] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [contactDetails, setContactDetails] = useState(null);
  const { event, userOrganization: { isSavedContentEnabled }, userOrganization } = props;
  const {
    fromUserId,
    from,
    savedContenteventStatusTypeId,
    savedContentstatusTypeId,
    title,
    deleted,
    lastUpdatedAt,
    savedContent,
    libraryTypeId,
    libraryType,
    formattedFromUserName: tempFormattedName,
    formattedTimestamp,
    patient,
    status,
    isMessageFailed,
    isDelivered,
  } = event;
  const savedContentId = savedContent?.id || event.savedContentId;

  const isSavedContent = savedContent?.libraryTypeId === TYPE_USER_LIBRARY_SAVED_CONTENT || libraryTypeId === TYPE_USER_LIBRARY_SAVED_CONTENT || libraryType === 'Saved Content';
  const formattedFromUserName = (tempFormattedName === 'Rhino System' || fromUserId === AppConstants.SYSTEM_USER_ID) ? userOrganization.name : tempFormattedName;

  function handleFormViewer(formUrl, contactId) {
    openPdfViewer(true);
    setAttachmentUrl(formUrl);
    setContactDetails(contactId);
  }

  const getSavedContentText = () => {
    if (isMessageFailed) {
      return (
        <div className="convo__event__title">
          <Icon bump="up" icon="alert-triangle" className="u-text-danger" />
          &nbsp;&nbsp;Message Selection <strong>Failed to Send</strong> (Sent By {formattedFromUserName})
        </div>
      );
    } if (isDelivered) {
      return (
        <div className="convo__event__title">
          <Icon bump="up" icon="checkmark" className="u-text-secondary" />
            &nbsp;&nbsp;Message Selection <strong>Successfully Sent</strong> by {formattedFromUserName}
        </div>
      );
    }
    return (
      <div className="convo__event__title">
            &nbsp;&nbsp; Message Selection <strong>Sending to EHR</strong> (Sent by {formattedFromUserName})
      </div>
    );
  };

  const getCompletedFormText = () => {
    switch (savedContenteventStatusTypeId || savedContentstatusTypeId || status?.typeClassId) {
      case TYPE_EVENT_STATUS_DELIVERED:
        return (
          <div className="convo__event__title">
            <Icon bump="up" icon="checkmark" className="u-text-secondary" />
            &nbsp;&nbsp;Completed Form <strong>Successfully Sent</strong> by {formattedFromUserName}
          </div>
        );
      case TYPE_EVENT_STATUS_ERROR:
        return (
          <div className="convo__event__title">
            <Icon bump="up" icon="alert-triangle" className="u-text-danger" />
            &nbsp;&nbsp;Completed Form <strong>Failed to Send</strong> (Sent By {formattedFromUserName})
          </div>
        );
      default:
        return (
          <div className="convo__event__title">
            &nbsp;&nbsp; Completed Form <strong>Sending to EHR</strong> (Sent by {formattedFromUserName})
          </div>
        );
    }
  };

  const renderCopyContent = () => {
    const isFrom = from === 1;
    return (
      <>
        <div className="convo__event__title">
          File {title} was
          copied {isFrom ? 'from' : 'to'} {patient?.firstName || ''} {patient?.lastName || ''} by {formattedFromUserName}
        </div>
        <div className="convo__event__subtext">
          {deleted ? (
            <>
              <span>{DateHelpers.formatTimestamp(lastUpdatedAt)}</span>
              <span className="u-text-danger">&nbsp;(deleted)</span>
            </>
          ) :
            <span>{formattedTimestamp}</span>}
        </div>
      </>
    );
  };

  const renderSavedContentEvent = () => (
    <>
      <div className="convo__event__title">
        <Icon bump="up" icon="checkmark" className="u-text-secondary" />
          &nbsp;&nbsp;Message Selection <strong>Saved</strong> by {formattedFromUserName}
      </div>
      <div className="convo__event__subtext">
        {formattedTimestamp}
          &nbsp;
        {
            isSavedContentEnabled && (!deleted ?
              (
                <Link // eslint-disable-line jsx-a11y/anchor-is-valid
                  id={`savedContent=${savedContentId}`}
                  to={`/saved-content/${savedContentId}`}
                  className="u-text-body u-text-underline"
                >Saved Details
                </Link>
              ) : <span className="u-text-danger">(deleted)</span>
            )
          }
      </div>
    </>
  );

  const renderSentSavedContentEvent = () => (
    <>
      {isSavedContent ? getSavedContentText() : getCompletedFormText()}
      <div className="convo__event__subtext">
        {formattedTimestamp}
          &nbsp;
        {
            isSavedContentEnabled && (!deleted ? // eslint-disable-line  no-nested-ternary
              (
                (isSavedContent ?
                  (
                    <Link // eslint-disable-line jsx-a11y/anchor-is-valid
                      id={`savedContent=${savedContentId}`}
                      to={`/saved-content/${savedContentId}`}
                      className="u-text-body u-text-underline"
                    >Saved Details
                    </Link>
                  ) : (
                    <span className="u-text-body u-text-underline convo__event__subtext__link" onClick={() => handleFormViewer(event?.savedContentPdfLink, fromUserId)}>
                      Completed Form
                    </span>
                  )
                )
              ) : <span className="u-text-danger">(deleted)</span>
            )
          }
      </div>
    </>
  );

  return (
    <>
      {event.isCopyContentEvent && renderCopyContent()}
      {event.isSavedContentEvent && renderSavedContentEvent()}
      {event.isSentSavedContentEvent && renderSentSavedContentEvent()}
      <PdfViewerContainer
        open={!!(isPdfViewerOpen && attachmentUrl)}
        attachmentUrl={SavedContentHelpers.getAttachmentUrl(attachmentUrl, contactDetails)}
        fileName={attachmentUrl}
        handlePdfViewerClose={() => openPdfViewer(!isPdfViewerOpen)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  userOrganization: getLoggedInUserOrganization(state),
});

SavedContentEvent.propTypes = {
  userOrganization: PropTypes.object.isRequired,
  event: PropTypes.object,
  isSavedContentEnabled: PropTypes.bool,
};
export default connect(mapStateToProps)(SavedContentEvent);
