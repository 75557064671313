import React from 'react';
import PropTypes from 'prop-types';
import { Input, UtilitySystem } from 'rhinostyle';
import cx from 'classnames';
import { MaskingHelpers, SearchHelpers } from '../helpers';

const ContactSearch = (props) => {
  const { value, handleChange, name, wide, placeholder, disabled } = props;
  const inputType = props.name === 'phone' ? 'tel' : 'text';
  const inputClasses = cx('filter-page__input form__group button--input', {
    'filter-page__input--wide': wide,
  });

  function getInputFormat() {
    if (props.name === 'dob') {
      return UtilitySystem.dateFormat;
    } else if (props.name === 'phone') {
      return MaskingHelpers.phone;
    } return null;
  }

  function handleInputChange(field, updatedValue) {
    handleChange(name, updatedValue);
  }

  return (
    <Input
      placeholder={placeholder || SearchHelpers.getFilterLabelInformation(name).placeholder}
      className={inputClasses}
      onChange={handleInputChange}
      initialValue={value}
      value={value}
      name={name}
      format={getInputFormat()}
      type={inputType}
      autoComplete="off"
      dataFeatureTag={`contact-search-${name}`}
      disabled={disabled}
    />
  );
};

ContactSearch.propTypes = {
  value: PropTypes.any,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  wide: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ContactSearch;
