import PropTypes from 'prop-types';
import React from 'react';
import {
  Alert,
  Input,
  Button,
  UtilityInlineGrid,
} from 'rhinostyle';

import { StringHelpers } from '../helpers';

const UpdatePasswordForm = (props) => {
  const {
    disableSave,
    errors,
    handleChange,
    handleSubmit,
    handleToggle,
    formInProgress,
    renderPasswordInfo,
  } = props;

  const renderAlert = () => (
    <Alert
      className="u-m-b"
      type="danger"
    >
      {Object.keys(errors).map((err) => {
        if (typeof errors[err] === 'object') {
          return Object.keys(errors[err]).map((e) => StringHelpers.capitalize(errors[err][e]));
        }

        return StringHelpers.capitalize(errors[err]);
      }).join(', ')}
    </Alert>
  );

  if (!renderPasswordInfo) {
    return (
      <div className="form__group">
        <div className="u-text-center">
          <Button
            size="small"
            type="primary"
            onClick={() => handleToggle('renderPasswordInfo')}
            outlined
            data-cypress="changePassword"
          >
            Change Password
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <hr className="u-m-y-large" />
      {Object.keys(errors).length > 0 && renderAlert()}
      <div className="form__group">
        <div className="row">
          <div className="column-6@small">
            <Input
              type="password"
              name="newPassword"
              initialValue=""
              onChange={handleChange}
              label="New Password"
              required
            />
          </div>
          <div className="column-6@small">
            <Input
              type="password"
              name="newPasswordConfirm"
              onChange={handleChange}
              validationMessage={errors.newPassAgain ? errors.newPassAgain.message : null}
              label="Confirm New Password"
              required
            />
          </div>
        </div>
      </div>
      <div className="form__group">
        <UtilityInlineGrid align="right">
          <Button size="small" onClick={() => handleToggle('renderPasswordInfo')} type="default">Cancel</Button>
          <Button size="small" loading={formInProgress} type="primary" disabled={disableSave} onClick={handleSubmit} data-cypress="updatePassword" outlined>
            Update password
          </Button>
        </UtilityInlineGrid>
      </div>
    </>
  );
};

UpdatePasswordForm.propTypes = {
  disableSave: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  renderPasswordInfo: PropTypes.bool.isRequired,
};

export default UpdatePasswordForm;
