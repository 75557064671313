import PropTypes from 'prop-types';
import React from 'react';
import {
  DropdownMenuItem,
  Dropdown,
} from 'rhinostyle';
import { MetaTypeService } from 'rhinotilities/lib/core/services/MetaTypeService';
import { searchScopeMapping } from '../helpers/SearchHelpers';

const UserTypeDropdown = ({
  handleChange,
  name,
  value,
  label,
  dropdownClass,
  disabled,
}) => {
  const userTypes = searchScopeMapping.nonMembers;

  const renderFilterItem = (id, key) => (
    <DropdownMenuItem
      key={key}
      id={id}
      label={MetaTypeService.toUserType(id)}
      onClick={() => value !== id && handleChange(name, id)}
      dataCypress={`user-type-${id}`}
      dataFeatureTag={`user-type-${id}`}
    />
  );
  return (
    <Dropdown
      activeKey={value}
      label={label}
      title="User Type"
      type={value ? 'primary' : 'input'}
      outlined={!!value}
      className={dropdownClass}
      disabled={disabled}
      dataFeatureTag="user-type-dropdown"
    >
      <DropdownMenuItem onClick={() => value && handleChange(name, 0)} label={label} id={0} />
      {userTypes.map(renderFilterItem)}
    </Dropdown>
  );
};

UserTypeDropdown.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  dropdownClass: PropTypes.string,
};

export default UserTypeDropdown;
