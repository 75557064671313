import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Lightbox } from 'rhinostyle';
import { isImageType, isImageMimeType } from '../helpers/ImageHelpers';
import { AppConstants } from '../constants';
import { getFilteredChatThreadEventIds } from '../selectors/chatSelectors';
import { handleDownloadClick } from '../helpers/PdfViewerHelpers';
import { shapeEventAttachments } from '../helpers/EventHelpers';

class LightboxContainer extends Component {
  state = {
    images: this.getImages(),
    currentImageIndex: this.getDefaultImageIndex(this.getImages()),
  };

  /**
   * "Real" event attachments have an 'attachmentTypeId' while optimistic
   * event attachments will have a 'typeId' (facebook) or 'type'(MIME type).
   */
  getImages() {
    const { events, eventIds } = this.props;
    const eventsWithImages = eventIds.filter((id) => {
      const shapedAttachments = shapeEventAttachments(events[id].attachments || []);
      return shapedAttachments.length && shapedAttachments.some((shapeAttachment) => (isImageType(shapeAttachment.typeId) ||
      isImageType(shapeAttachment.attachmentTypeId) ||
      isImageMimeType(shapeAttachment.type)));
    });
    let images = [];
    eventsWithImages.forEach((item) => {
      images = images.concat(shapeEventAttachments(events[item].attachments));
    });
    return images;
  }

  getDefaultImageIndex(images) {
    let index = 0;

    for (let i = 0; i < images.length; i++) {
      const image = images[i];

      if (image.attachmentUrl === this.props.lightboxAttachmentUrl) {
        index = i;
        break;
      }
    }

    return index;
  }

  handleDownload = (mainSrc, filename) => {
    handleDownloadClick(mainSrc, filename, this.props.isNativeApp, true);
  }

  render() {
    const baseUrl = AppConstants.FILE_BASE_URL;
    const {
      images,
      currentImageIndex,
    } = this.state;

    const mainSrc = `${baseUrl}${images[currentImageIndex].attachmentUrl}`;
    const mainFilename = `${baseUrl}${images[currentImageIndex].name}`;
    const nextSrc = `${baseUrl}${images[(currentImageIndex + 1) % images.length].attachmentUrl}`;
    const prevSrc = `${baseUrl}${images[(currentImageIndex + (images.length - 1)) % images.length].attachmentUrl}`;
    const onMovePrevRequest = () => this.setState({ currentImageIndex: (currentImageIndex + (images.length - 1)) % images.length });
    const onMoveNextRequest = () => this.setState({ currentImageIndex: (currentImageIndex + 1) % images.length });
    const onCloseRequest = () => this.props.toggleLightbox(false);

    const props = {
      mainSrc,
      nextSrc: images.length > 1 ? nextSrc : null,
      prevSrc: images.length > 1 ? prevSrc : null,
      onMovePrevRequest,
      onMoveNextRequest,
      onCloseRequest,
      animationDisabled: true,

      toolbarButtons: [
        <button //eslint-disable-line
          type="button"
          key="download"
          className="lightbox__download"
          onClick={() => this.handleDownload(mainSrc, mainFilename)}
        />,
      ],
    };

    return <Lightbox {...props} />;
  }
}

LightboxContainer.propTypes = {
  eventIds: PropTypes.array.isRequired,
  events: PropTypes.object.isRequired,
  lightboxAttachmentUrl: PropTypes.string,
  toggleLightbox: PropTypes.func.isRequired,
  isNativeApp: PropTypes.bool,
};

function mapStateToProps(state, props) {
  if (props.threadType) {
    return {
      events: props.threadType === 'inbox' ? props.events : state[props.threadType].events,
      eventIds: props.threadType === 'inbox' ? props.eventIds : getFilteredChatThreadEventIds(state),
      lightboxAttachmentUrl: state.thread.lightboxAttachmentUrl,
      isNativeApp: state.nativeApp.isNativeApp,
    };
  }
  return {
    events: props.events,
    eventIds: props.eventIds,
    lightboxAttachmentUrl: props.lightboxAttachmentUrl,
    isNativeApp: state.nativeApp.isNativeApp,
  };
}
export default connect(mapStateToProps)(LightboxContainer);
