import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleFormChanges, resetFormChanges, confirmFormChanges } from '../reducers/uiReducer';

const unsavedChanges = (WrappedComponent) => function WithStyle(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const hasFormChanged = useSelector((state) => state.ui.hasFormChanged);

  useEffect(() => {
    const unblock = history.block(() => dispatch(confirmFormChanges()));
    return () => {
      unblock();
    };
  }, [hasFormChanged]);

  function handleWrappedFormChanges() {
    dispatch(handleFormChanges());
  }

  function resetWrappedFormChanges() {
    dispatch(resetFormChanges());
  }

  return (
    <WrappedComponent
      handleFormChanges={handleWrappedFormChanges}
      resetFormChanges={resetWrappedFormChanges}
      {...props}
    />
  );
};
export default unsavedChanges;
