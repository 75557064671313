import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  Scrollbars,
  UtilityInlineGrid,
  UtilityList,
} from 'rhinostyle';
import moment from 'moment-timezone';

import { DateHelpers } from '../helpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { OUT_OF_OFFICE_EDIT } from '../constants/UserPermissionsConstants';

const OOOPanel = (props) => {
  const history = useHistory();
  const {
    activeOOO,
    channels,
    handleTogglePanel,
  } = props;

  const pushRoute = (url) => history.push(url);

  const renderChannel = (channelId, key) => (
    <li key={key}>
      {channels[channelId].name}
    </li>
  );

  const isClosedAllDay = DateHelpers.isEndOfDay(moment(activeOOO.to));
  const dateFormat = isClosedAllDay ? 'MMM D' : 'MMM D, h:mm a';

  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title">Summary</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => handleTogglePanel(activeOOO.id)}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__title">
                {activeOOO.title}
              </div>
              <div className="summary-panel__intro__actions">
                <UtilityInlineGrid align="middle">
                  {userHasAnyOfPermissions([OUT_OF_OFFICE_EDIT]) && (
                    <Button
                      size="small"
                      onClick={() => pushRoute(`/settings/organization/out-of-office/edit/${activeOOO.id}`)}
                      type="primary"
                      outlined
                      data-cypress="editEvent"
                    >
                      Edit Event
                    </Button>
                  )}
                </UtilityInlineGrid>
              </div>
            </div>
            {activeOOO.message && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Message</div>
                <div className="summary-panel__section__content">
                  {activeOOO.message}
                </div>
              </div>
            )}
            {activeOOO.channels && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Channels</div>
                <div className="summary-panel__section__content" data-cypress="oooChannels">
                  <UtilityList space className="u-m-b-0">
                    {activeOOO.channels.map(renderChannel)}
                  </UtilityList>
                </div>
              </div>
            )}
            {activeOOO.to && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Dates</div>
                <div className="summary-panel__section__content">
                  {moment(activeOOO.from).format(dateFormat)} - {moment(activeOOO.to).format(dateFormat)}
                </div>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

OOOPanel.propTypes = {
  activeOOO: PropTypes.object.isRequired,
  channels: PropTypes.object.isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
};

export default OOOPanel;
