import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateBillingContact } from '../reducers/billingReducer';
import OrganizationBillingContact from '../components/OrganizationBillingContact';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { ValidationHelpers, PhoneHelpers } from '../helpers';

class OrganizationBillingContactContainer extends Component {
  state = {
    errors: {},
    formInProgress: false,
    contactFirstName: '',
    contactLastName: '',
    contactPhone: '',
    contactEmail: '',
    contactModalOpen: false,
    street1: '',
    street2: '',
    city: '',
    state: -1,
    zip: '',
    isLoading: true,
    isChanged: false,
  };

  componentDidMount() {
    this.setState({
      contactFirstName: this.props.billing.contactFirstName || '',
      contactLastName: this.props.billing.contactLastName || '',
      contactEmail: this.props.billing.contactEmail || '',
      contactPhone: this.props.billing.contactPhone || '',
      street1: this.props.billing.street1 || '',
      street2: this.props.billing.street2 || '',
      city: this.props.billing.city || '',
      state: this.props.states.find((s) => this.props.billing.state.toUpperCase() === s.code) ?
        this.props.states.find((s) => this.props.billing.state.toUpperCase() === s.code).id : -1,
      zip: this.props.billing.zip || '',
      status: this.props.billing.status,
      isLoading: false,
    }, () => { this.initialState = this.state; });
  }

  cleaveZip = null;
  cleaveContactPhone = null;

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  handleCleaveInit = (name, cleave) => {
    this[name] = cleave;
  }

  handleCancel = () => {
    this.handleToggle('contactModalOpen');
  }

  handleOnReverseComplete = () => {
    if (this.state.isChanged) {
      this.setState({ isChanged: false });
    } else {
      this.setState(this.initialState, () => {
        if (this.cleaveZip) this.cleaveZip.setRawValue(this.props.billing.zip || '');
        if (this.cleaveContactPhone) {
          const { contactPhone } = this.props.billing;
          const defaultContactPhone = contactPhone ? PhoneHelpers.formatPhone(contactPhone) : '';

          if (defaultContactPhone) {
            this.cleaveContactPhone.setRawValue(defaultContactPhone);
          }
        }
      });
    }
  }

  handleToggle = (key) => {
    this.setState({ [key]: !this.state[key] });
  }

  formatContact = (c) => ({
    contactFirstName: c.contactFirstName,
    contactLastName: c.contactLastName,
    contactEmail: c.contactEmail,
    contactPhone: c.contactPhone,
    street1: c.street1,
    street2: c.street2,
    city: c.city,
    status: c.status,
    state: this.props.states.find((s) => c.state === s.id).code,
    stateId: c.state,
    zip: c.zip,
  })

  handleSubmit = () => {
    const custId = this.props.billing.billManagerCustId;
    const formattedContact = this.formatContact(this.state);

    const errors = ValidationService(ValidationShapers.shapeMemberBillingContact({ ...formattedContact }));

    const errorCount = Object.keys(errors).length;

    if (errorCount > 0) {
      this.setState({ errors });
    } else {
      this.setState({ formInProgress: true });
      this.props.updateBillingContact(custId, formattedContact)
        .then(() => {
          const newState = { formInProgress: false, errors: {}, isChanged: true };
          if (this.props.error) {
            newState.errors = ValidationHelpers.handleServerError(this.props.error.data);
          } else {
            this.handleToggle('contactModalOpen');
          }
          this.setState({ formInProgress: false });
          this.initialState = this.state;
          this.setState(newState);
        });
    }
  }

  render() {
    const props = {
      billing: this.props.billing,
      handleChange: this.handleChange,
      handleCancel: this.handleCancel,
      handleCleaveInit: this.handleCleaveInit,
      handleToggle: this.handleToggle,
      errors: this.state.errors,
      formInProgress: this.state.formInProgress,
      handleOnReverseComplete: this.handleOnReverseComplete,
      handleSubmit: this.handleSubmit,
      contactModalOpen: this.state.contactModalOpen,
      contactPhone: this.state.contactPhone,
      contactFirstName: this.state.contactFirstName,
      contactLastName: this.state.contactLastName,
      contactEmail: this.state.contactEmail,
      street1: this.state.street1,
      street2: this.state.street2,
      city: this.state.city,
      state: this.state.state,
      states: this.props.states,
      zip: this.state.zip,
      isLoading: this.state.isLoading,
      stateCode: this.props.billing.state,
    };

    return (<OrganizationBillingContact {...props} />);
  }
}

OrganizationBillingContactContainer.propTypes = {
  error: PropTypes.object,
  billing: PropTypes.object,
  updateBillingContact: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { ui, billing, states } = state;
  return {
    error: ui.error,
    billing: billing.billing,
    states: states.states,
  };
};

const actions = {
  updateBillingContact,
};

export default connect(mapStateToProps, actions)(OrganizationBillingContactContainer);
