import { UtilitySystem } from 'rhinostyle';
import store from '../store';
import OutboundPostMessageService from '../services/OutboundPostMessageService';
import { isIos } from './BrowserHelpers';
import { isMember } from './UserHelpers';
/**
 * A RemoteParticipant muted or unmuted its media.
 * @param {Room} room - The Room you have joined
 * @param {function} onMutedMedia - Called when a RemoteParticipant muted its media
 * @param {function} onUnmutedMedia - Called when a RemoteParticipant unmuted its media
 * @returns {void}
 */
export function participantMutedOrUnmutedMedia(room, onMutedMedia, onUnmutedMedia) {
  room.on('trackSubscribed', (track, publication, participant) => {
    track.on('disabled', () => onMutedMedia(track, participant));
    track.on('enabled', () => onUnmutedMedia(track, participant));
  });
}

// plays sounds from rhinonative when on a call to prevent interrupting audio
export function checkActiveVideoIos(id) {
  const { nativeApp, rhinovideo } = store.getState();
  const onVideoCallRhinonativeIos = nativeApp.isNativeApp && isIos() && rhinovideo.currentVideoId;
  if (onVideoCallRhinonativeIos) {
    OutboundPostMessageService.postMessage({
      type: 'playSound',
      data: {
        soundName: id,
      },
    });
  }
  return onVideoCallRhinonativeIos;
}

export function formatAvatarForVideo(user) {
  if (user?.firstName && user?.lastName) {
    const initials = `${user.firstName[0]}${user.lastName[0]}`;
    const defaultMemberAvatar = `data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
        <circle cx="50" cy="50" r="50" fill="%237fc0d3"></circle>
        <text x="50" y="50" text-anchor="middle" dy="0.35em" stroke="none" fill="white" font-family="Lato,sans-serif" font-size="40">${initials}</text>
      </svg>`;
    return defaultMemberAvatar;
  } return `https://gravatar.com/avatar/${Math.floor(Math.random() * 100000)}?s=300&d=identicon`;
}

export function shapeParticipantsInfo(currentUser) {
  const avatarUrl = formatAvatarForVideo(currentUser);
  const name = currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : '';
  return {
    name,
    avatarUrl,
    externalId: UtilitySystem.generateUUID(),
    ...isMember(currentUser) && {
      memberId: currentUser?.id,
    },
  };
}
