import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Button, Icon } from 'rhinostyle';

const BackButton = (props) => {
  const history = useHistory();
  const { customClassName, navigateTo } = props;
  const handleBackClick = () => {
    if (navigateTo) {
      history.push(navigateTo);
    } else if (history.canGoBack) {
      history.goBack();
    }
  };

  const renderButton = () => {
    const backButtonClass = cx('u-m-r-small', {
      [customClassName]: customClassName !== '',
      'u-m-r-small': customClassName === '',
    });
    return (
      <Button
        reset
        className={backButtonClass}
        onClick={handleBackClick}
        title="Go back"
      >
        <Icon bump="down" icon="arrow-left" />
      </Button>
    );
  };

  const visible = navigateTo || history.canGoBack;

  if (visible) return renderButton();

  return null;
};

BackButton.propTypes = {
  navigateTo: PropTypes.string,
  customClassName: PropTypes.string,
};

export default connect(null, null)(BackButton);
