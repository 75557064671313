import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LoaderCircle } from 'rhinostyle';
import { PHONE_ROUTES, PHONE_PROVIDER_URL } from '../constants/PhoneConstants';
import { getOrganizationToken } from '../services/RhinocallService';
import NotificationService from '../services/NotificationService';
import EmptyMessage from './EmptyMessage';

const RhinoPhone = () => {
  const { pageId } = useParams();
  const [loading, setLoading] = useState(true);
  const [authToken, setAuthToken] = useState(null);
  const route = PHONE_ROUTES[pageId] || PHONE_ROUTES.dashboard;
  const organizationId = useSelector((state) => state.auth.currentOrg);

  useEffect(() => {
    const handleAuthenticate = async () => {
      try {
        const response = await getOrganizationToken(organizationId);
        if (response.data?.accessToken) {
          setAuthToken(response.data.accessToken);
        }
      } catch (error) {
        NotificationService('fetchRhinophoneToken', error.response);
        console.error('Error fetching organization token:', error);
      } finally {
        setLoading(false);
      }
    };
    if (organizationId) {
      handleAuthenticate();
    }
  }, [organizationId]);

  const renderIframe = (url) => (authToken ? (
    <iframe
      src={url}
      title="Rhinophone"
      allow="camera; microphone"
    />
  ) : <EmptyMessage />);

  const iframeLoader = (
    <div className="app-page-loader-outer">
      <div className="app-page-loader">
        <LoaderCircle size="large" className="u-text-primary" />
      </div>
    </div>
  );

  const authenticatedUrl = authToken ? `${PHONE_PROVIDER_URL}${route}?token=${encodeURIComponent(authToken)}` : '';
  return (
    <div className="app-page__container">
      <div className="rhinophone-iframe__wrapper">
        {loading ? iframeLoader : renderIframe(authenticatedUrl)}
      </div>
    </div>
  );
};

export default RhinoPhone;
