import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../helpers/AxiosHelper';

const HIPAA_CAMPAIGN_TAGS = 'HipaaCampaigns';

const buildUrl = (organizationId, hipaaCampaignId) => `hipaa-requests/${organizationId}/campaigns${hipaaCampaignId ? `/${hipaaCampaignId}` : ''}`;

export const hipaaCampaignSlice = createApi({
  reducerPath: 'hipaaCampaigns',
  baseQuery: axiosBaseQuery(),
  tagTypes: [HIPAA_CAMPAIGN_TAGS],
  endpoints: (builder) => ({
    // Get all campaigns
    getHipaaCampaigns: builder.query({
      query: ({ organizationId }) => {
        const url = buildUrl(organizationId);
        return { url, method: 'GET' };
      },
      providesTags: [HIPAA_CAMPAIGN_TAGS],
    }),
    // Get a single campaign
    getHipaaCampaign: builder.query({
      query: ({ organizationId, hipaaCampaignId }) => {
        const url = buildUrl(organizationId, hipaaCampaignId);
        return { url, method: 'GET' };
      },
      keepUnusedDataFor: 5,
    }),
    createHipaaCampaign: builder.mutation({
      query: ({
        data,
        organizationId,
      }) => {
        const url = buildUrl(organizationId);
        return { url, method: 'POST', data };
      },
      invalidatesTags: [HIPAA_CAMPAIGN_TAGS],
    }),
    updateHipaaCampaign: builder.mutation({
      query: ({
        hipaaCampaignId,
        organizationId,
        data,
      }) => {
        const url = buildUrl(organizationId, hipaaCampaignId);
        return { url, method: 'PATCH', data };
      },
      invalidatesTags: [HIPAA_CAMPAIGN_TAGS],
    }),
    deleteHipaaCampaign: builder.mutation({
      query: ({
        hipaaCampaignId,
        organizationId,
      }) => {
        const url = buildUrl(organizationId, hipaaCampaignId);
        return { url, method: 'DELETE' };
      },
      invalidatesTags: [HIPAA_CAMPAIGN_TAGS],
    }),
    getHipaaRequestAnalytics: builder.query({
      query: ({ from, to }) => {
        const url = `/analytics/hipaa-requests?from=${from}&to=${to}`;
        return { url, method: 'GET' };
      },
    }),
  }),
});

export const {
  useGetHipaaCampaignsQuery,
  useGetHipaaCampaignQuery,
  useCreateHipaaCampaignMutation,
  useUpdateHipaaCampaignMutation,
  useDeleteHipaaCampaignMutation,
  useGetHipaaRequestAnalyticsQuery,
} = hipaaCampaignSlice;

export default hipaaCampaignSlice;
