import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  UtilitySystem,
  UtilityInlineGrid,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Scrollbars,
  ResourceGroup,
  Icon,
  Resource,
  ResourceIntro,
  ResourceRight,
  ResourceBody,
} from 'rhinostyle';
import UserSearchContainer from '../containers/UserSearchContainer';
import GroupSearch from './GroupSearch';
import BackButtonContainer from '../containers/BackButtonContainer';
import PageLoader from './PageLoader';
import Tags from './Tags';
import OrganizationRolePermissions from './OrganizationRolePermissions';

import { UserHelpers } from '../helpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { AppConstants } from '../constants';
import { ROLE_DELETE, RHINOFORMS_ADMIN } from '../constants/UserPermissionsConstants';

const DeleteRoleModal = (props) => {
  const {
    handleClose,
    open,
  } = props;

  return (
    <Modal open={open}>
      <ModalHeader
        onClose={handleClose}
        title="Role Could Not Be Deleted"
        titleSub="This role has Members that are assigned to it. To proceed with role deletion, please do the following:"
      />
      <ModalBody>
        <ol className="u-list--numbers">
          <li>Remove all members assigned.</li>
          <li>Click the delete button.</li>
        </ol>
      </ModalBody>
      <ModalFooter>
        <UtilityInlineGrid align="right">
          <Button onClick={handleClose}>
            Close
          </Button>
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );
};

DeleteRoleModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const OrganizationRolesForm = (props) => {
  const history = useHistory();
  const location = useLocation();
  const {
    actionTitle,
    actionType,
    activeOrg,
    activeUsersIds,
    description,
    disabledUserIds,
    errors,
    formInProgress,
    handleChange,
    handleCheckboxClick,
    handleConfirmDeleteRequest,
    handleFormChanges,
    handlePermissionChange,
    handleSubmit,
    handleToggle,
    handleToggleModal,
    handleUpdateSelectedIds,
    isDeleteConfirmationVisible,
    isInEditMode,
    isMobilePermissionVisibleforContact,
    isMobilePermissionVisibleforTeam,
    isSystemRole,
    name,
    pageContainerRef,
    pageLoading,
    pageTitle,
    permissions,
    seatsLimit,
    selectedUserIds,
    showDeleteRoleModal,
    showRhinoformSeatsValidationModal,
    users,
    viewAllMembers,
    viewGroups,
  } = props;

  if (pageLoading) {
    return <PageLoader />;
  }

  const tabTriggerMemberClasses = `tabs__trigger ${!viewGroups ? UtilitySystem.config.classes.active : ''}`;
  const tabTriggerGroupsClasses = `tabs__trigger ${viewGroups ? UtilitySystem.config.classes.active : ''}`;

  const renderViewGroups = () => (
    <GroupSearch
      excludedGroupIds={[]}
      handleSearchSelect={(id) => handleUpdateSelectedIds('selectedGroupIds', id)}
      prepopulate
      excludeChatGroups
    />
  );

  const renderUser = (id, idx) => {
    const user = users[id];
    const { onlineStatus } = user; // This property is being set explicitly in the WebSocketService and is not part of the User response object
    const selected = selectedUserIds.includes(id);
    const disabled = disabledUserIds.includes(id);
    const profileImageUrl = user.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${user.profileImageUrl}` : '';

    return (
      <Resource selected={selected} disabled={disabled} key={idx} onClick={() => handleUpdateSelectedIds('selectedUserIds', id)}>
        <ResourceIntro
          avatar={{ image: profileImageUrl, name: UserHelpers.formatAvatarName(user.firstName, user.lastName), type: 'member', showOnlineStatus: true, onlineStatus }}
          title={UserHelpers.formatMemberNameWithPrefixAndSuffix(user)}
        />
        {user.tags?.length > 0 && <ResourceBody className="u-text-muted"><Tags tagIds={user.tags} /></ResourceBody>}
        {!user.active && <ResourceRight><span className="u-text-accent">Deactivated</span></ResourceRight>}
      </Resource>
    );
  };

  const renderViewSelectedMembers = () => (
    <>
      { name === RHINOFORMS_ADMIN && seatsLimit === activeUsersIds().length ?
        <Alert type="info" className="u-m-b u-text-center">This role has reached the max number of Members allowed.</Alert> :
        null}
      <div className="search__group">
        <div className="search__sub">Members Added</div>
        {selectedUserIds.length > 0 ? (
          <Scrollbars className="resource-group__scroll" autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.large}>
            <ResourceGroup interfaceMode="checkbox">
              {selectedUserIds.map(renderUser)}
            </ResourceGroup>
          </Scrollbars>
        ) :
          'No Users Added'}
      </div>
    </>
  );

  const renderDeleteConfirm = () => (
    <div className="item-status__confirm">
      <div className="item-status__confirm__text">
        <Icon bump="up" icon="trash" /> Are you sure you want to delete this role?
      </div>
      <UtilityInlineGrid size="regular" align="middle">
        <Button size="small" type="link-muted" onClick={() => handleChange('isDeleteConfirmationVisible', false)}>
          Cancel
        </Button>
        <Button loading={formInProgress} size="small" type="danger" onClick={handleConfirmDeleteRequest}>
          Yes, delete role
        </Button>
      </UtilityInlineGrid>
    </div>
  );

  const renderStandardStatus = () => (
    <div className="item-status">
      {isInEditMode && !isSystemRole && (
        <div className="item-status__left">
          {userHasAnyOfPermissions([ROLE_DELETE]) && (
            <Button
              reset
              onClick={() => handleToggle(selectedUserIds.length > 0 ? 'showDeleteRoleModal' : 'isDeleteConfirmationVisible')}
              className="item-status__delete-icon"
              title="Delete Channel"
            >
              <Icon bump="down" size="large" icon="trash" />
            </Button>
          )}
        </div>
      )}
      <div className="item-status__right">
        <Button loading={formInProgress} type={actionType} onClick={handleSubmit}>{actionTitle}</Button>
      </div>
    </div>
  );

  const renderDeleteModal = () => (
    <DeleteRoleModal
      handleClose={() => handleToggle('showDeleteRoleModal')}
      handleDeleteRole={handleConfirmDeleteRequest}
      open={showDeleteRoleModal}
      name={name}
    />
  );

  const renderViewMembers = () => (
    viewAllMembers ? (
      <UserSearchContainer
        disabledUserIds={disabledUserIds}
        selectedUserIds={selectedUserIds}
        type="preloadedMembers"
        handleSearchSelect={(id) => handleUpdateSelectedIds('selectedUserIds', id)}
      />
    ) :
      renderViewSelectedMembers()
  );

  return (
    <div className="app-page__container" ref={pageContainerRef}>
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title">
            <BackButtonContainer navigateTo="/settings/organization/roles" history={history} location={location} />
            {pageTitle}
          </div>
        </div>
        <form onChange={handleFormChanges}>
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">ROLE DETAILS</div>
            </div>
            <>
              <div className="form__group">
                <div className="row">
                  <div className="column-6@small">
                    <Input
                      disabled={isSystemRole}
                      initialValue={name}
                      label="Role Name"
                      name="name"
                      onChange={handleChange}
                      required
                      validationMessage={errors.name}
                      autoComplete="off"
                    />
                  </div>
                  <div className="column-6@small">
                    <Input
                      disabled={isSystemRole}
                      label="Role Purpose"
                      name="description"
                      initialValue={description}
                      onChange={handleChange}
                      validationMessage={errors.description}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </>
            <OrganizationRolePermissions
              activeOrg={activeOrg}
              errors={errors}
              handleCheckboxClick={handleCheckboxClick}
              handlePermissionChange={handlePermissionChange}
              isMobilePermissionVisibleforContact={isMobilePermissionVisibleforContact}
              isMobilePermissionVisibleforTeam={isMobilePermissionVisibleforTeam}
              isSystemRole={isSystemRole}
              permissions={permissions}
              roleName={name}
            />
          </div>
          <div className="box">
            <div className="box__title-wrapper--flex">
              <div className="box__title__flex__trigger">
                <Button
                  reset
                  className="u-text-small u-text-primary"
                  onClick={() => handleToggle('viewAllMembers')}
                >{viewAllMembers ? 'Review' : 'Add More Members'}
                </Button>
              </div>
              <div className="box__title__content-flex">
                <div className="box__title">Members</div>
              </div>
              <div className="u-text-small box__title__flex__status">
                (
                {name === RHINOFORMS_ADMIN ? `${activeUsersIds().length} of ${seatsLimit} Added` :
                  `${selectedUserIds.length} Member${selectedUserIds.length !== 1 ? 's' : ''} Added`}
                )
              </div>
            </div>
            {viewAllMembers && (
              <div className="tabs__header u-m-t-large">
                <Button reset className={tabTriggerMemberClasses} onClick={() => handleToggle('viewGroups')}>
                  Members
                </Button>
                  &nbsp;|&nbsp;
                <Button reset className={tabTriggerGroupsClasses} onClick={() => handleToggle('viewGroups')}>
                  Groups
                </Button>
              </div>
            )}
            {viewGroups && viewAllMembers ? renderViewGroups() : renderViewMembers()}

            {errors.route && (
              <Alert type="danger" className="u-m-t">
                {errors.route}
              </Alert>
            )}
          </div>
          <div className="u-m-t-large">
            {isDeleteConfirmationVisible ?
              renderDeleteConfirm() : renderStandardStatus()}
            {renderDeleteModal()}
          </div>
        </form>
      </div>
      <Modal open={showRhinoformSeatsValidationModal}>
        <ModalHeader title="Max Member Seats Reached" onClose={handleToggleModal} />
        <ModalBody>
          <div className="u-text-center">
            All RhinoForm seats for this role have been assigned. To give this member a seat, you must un-assign one of the currently selected Members.
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

OrganizationRolesForm.propTypes = {
  actionTitle: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  activeOrg: PropTypes.object.isRequired,
  activeUsersIds: PropTypes.func.isRequired,
  description: PropTypes.string,
  disabledUserIds: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  handleConfirmDeleteRequest: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func,
  handlePermissionChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleToggleModal: PropTypes.func.isRequired,
  handleUpdateSelectedIds: PropTypes.func.isRequired,
  isDeleteConfirmationVisible: PropTypes.bool.isRequired,
  isInEditMode: PropTypes.bool.isRequired,
  isMobilePermissionVisibleforContact: PropTypes.bool.isRequired,
  isMobilePermissionVisibleforTeam: PropTypes.bool.isRequired,
  isSystemRole: PropTypes.bool,
  name: PropTypes.string,
  pageContainerRef: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
  seatsLimit: PropTypes.number,
  selectedUserIds: PropTypes.array.isRequired,
  showDeleteRoleModal: PropTypes.bool.isRequired,
  showRhinoformSeatsValidationModal: PropTypes.bool.isRequired,
  users: PropTypes.object.isRequired,
  viewAllMembers: PropTypes.bool.isRequired,
  viewGroups: PropTypes.bool.isRequired,
};

export default OrganizationRolesForm;
