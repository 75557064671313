import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'rhinostyle';
import appleApp from '../assets/images/app-store.svg';
import googleApp from '../assets/images/google-play.svg';
import { toggleModal } from '../reducers/threadReducer';
import { MODAL_OPTIONS } from '../constants/ThreadConstants';

const DownloadAppModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.thread[MODAL_OPTIONS.downloadApp]);
  const { userAgent } = navigator;
  const isAndroid = userAgent.includes('Android');
  const isIPhone = userAgent.includes('iPhone');

  function handleToggleModal() {
    dispatch(toggleModal(MODAL_OPTIONS.downloadApp));
  }

  return (
    <Modal open={isOpen} modalDialogClass="download-app-modal">
      <ModalHeader title="Rhinogram App Required" dismissable={false} />
      <ModalBody>
        <div className="download-app-modal__body-text">
          To &apos;host&apos; a virtual visit, the Rhinogram app is required. If you do not already have the app, click the button below to get started.
        </div>
        <div className="download-app-modal__body-link">
          {isIPhone && (
          <a href="https://itunes.apple.com/us/app/rhinogram/id1256043051?mt=8" target="_blank" rel="noopener noreferrer">
            <img
              className="member-preferences__app-svg"
              src={appleApp}
              alt="Download on the App Store"
            />
          </a>
          )}
          {isAndroid && (
            <a href="https://play.google.com/store/apps/details?id=com.rhinonative" target="_blank" rel="noopener noreferrer">
              <img
                className="member-preferences__app-svg"
                src={googleApp}
                alt="Get it on Google Play"
              />
            </a>
          )}
        </div>
        <div className="download-app-modal__cancel-button">
          <Button onClick={handleToggleModal} type="link-muted">Cancel</Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DownloadAppModal;
