import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  RhinoSwitch,
  FormLabel,
  Input,
  Checkbox,
  CheckboxGroup,
} from 'rhinostyle';
import AppointmentCampaignMultiSelect from '../components/AppointementCampaignMultiSelect';
import CampaignOffices from '../components/CampaignOffices';
import VariableMessage from '../components/VariableMessage';
import { AUTOMATED_MESSAGE_EDIT, AUTOMATED_MESSAGE_DELETE } from '../constants/UserPermissionsConstants';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { getActiveAppointmentTypeIds } from '../selectors/appointmentTypeSelectors';
import { fetchAppointmentTypes } from '../reducers/appointmentTypesReducer';
import { fetchMappedIntegrationOffices, fetchOffices } from '../reducers/officeReducer';
import { fetchAppointmentCampaigns, updateAppointmentCampaign, createAppointmentCampaign, deleteAppointmentCampaign } from '../reducers/appointmentCampaignReducer';
import { fetchAppointmentStatuses } from '../reducers/appointmentReducer';
import { fetchOrganizationFromAutomatedMessages } from '../reducers/organizationReducer';
import { fetchFormTemplates } from '../reducers/formReducer';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { cloneDeep, compareObjectByKey } from '../helpers/DataHelpers';
import { ValidationHelpers } from '../helpers';
import { processCampaignAttachments } from '../helpers/AttachmentHelpers';
import {
  APPOINTMENT_SCHEDULED_VARIABLE_OPTIONS,
  OFFICE_LOCATION,
  OFFICE_NAME,
  AUTOMATED_MESSAGE_TEMPLATES,
  APPOINTMENT_TYPE,
  APPOINTMENT_SCHEDULED_OFFICE_INDEX,
  APPOINTMENT_TYPE_INDEX,
  APPOINTMENT_REMINDERS_VARIABLE_OPTIONS,
  TEMPLATE_DEFAULT_TEXT_ACCEPT,
  TEMPLATE_DEFAULT_TEXT_CANCEL,
} from '../constants/VariableMessageConstants';
import {
  TYPE_APPOINTMENT_EVENT_SCHEDULED,
} from '../constants/Types';
import BackButton from '../components/BackButton';
import PageLoader from '../components/PageLoader';
import FormActionButtons from '../components/FormActionButtons';
import AppointmentCampaignFormReminders from '../components/AppointmentCampaignFormReminders';
import ExpandableBox from '../components/ExpandableBox';

const AppointmentCampaignForm = (props) => {
  const {
    currentOrganization,
    appointmentTypeIds,
    appointmentCampaigns,
    loading,
    isRhinoformEnabled,
    appointmentTypes,
    officeIds,
    appointmentIntegrationStatus,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const pageContainer = useRef();
  const { appointmentCampaignId } = params;
  const activeCampaign = appointmentCampaignId ? appointmentCampaigns[appointmentCampaignId] : null;
  const defaultCampaign = {
    appointmentScheduledEnabled: !!activeCampaign?.appointmentScheduledEnabled || true,
    appointmentScheduledTemplate: activeCampaign?.appointmentScheduledTemplate || AUTOMATED_MESSAGE_TEMPLATES.SCHEDULED,
    appointmentReminder1Enabled: !!activeCampaign?.appointmentReminder1Enabled || true,
    appointmentReminder1Template: activeCampaign?.appointmentReminder1Template || AUTOMATED_MESSAGE_TEMPLATES.REMINDER1,
    appointmentReminder1DeliveryHours: activeCampaign?.appointmentReminder1DeliveryHours?.toString() || '48',
    appointmentReminder2Enabled: !!activeCampaign?.appointmentReminder2Enabled || false,
    appointmentReminder2Template: activeCampaign?.appointmentReminder2Template || AUTOMATED_MESSAGE_TEMPLATES.REMINDER2,
    appointmentReminder2DeliveryHours: activeCampaign?.appointmentReminder2DeliveryHours?.toString() || '72',
    appointmentReminder3Enabled: !!activeCampaign?.appointmentReminder3Enabled || false,
    appointmentReminder3Template: activeCampaign?.appointmentReminder3Template || AUTOMATED_MESSAGE_TEMPLATES.REMINDER2,
    appointmentReminder3DeliveryHours: activeCampaign?.appointmentReminder3DeliveryHours?.toString() || '72',
    appointmentReminder4Enabled: !!activeCampaign?.appointmentReminder4Enabled || false,
    appointmentReminder4Template: activeCampaign?.appointmentReminder4Template || AUTOMATED_MESSAGE_TEMPLATES.REMINDER2,
    appointmentReminder4DeliveryHours: activeCampaign?.appointmentReminder4DeliveryHours?.toString() || '72',
    name: activeCampaign?.name || '',
    appointmentTypes: activeCampaign?.appointmentTypes || [],
    typeSearch: '',
    default: !!activeCampaign?.default || false,
    attachments: activeCampaign?.attachments || [],
    offices: (activeCampaign?.offices || [])?.map((office) => office.id),
    appointmentReminder1SendAfterAppt: activeCampaign?.appointmentReminder1SendAfterAppt,
    appointmentReminder1EhrStatus: activeCampaign?.appointmentReminder1EhrStatus || [],
    appointmentReminder2SendAfterAppt: activeCampaign?.appointmentReminder2SendAfterAppt,
    appointmentReminder2EhrStatus: activeCampaign?.appointmentReminder2EhrStatus || [],
    appointmentReminder3SendAfterAppt: activeCampaign?.appointmentReminder3SendAfterAppt,
    appointmentReminder3EhrStatus: activeCampaign?.appointmentReminder3EhrStatus || [],
    appointmentReminder4SendAfterAppt: activeCampaign?.appointmentReminder4SendAfterAppt,
    appointmentReminder4EhrStatus: activeCampaign?.appointmentReminder4EhrStatus || [],
    appointmentReminderConfirmationResponseEnabled: activeCampaign?.appointmentReminderConfirmationResponseEnabled || false,
    appointmentReminderCancelResponseEnabled: activeCampaign?.appointmentReminderCancelResponseEnabled || false,
    appointmentReminderConfirmationResponseTemplate: activeCampaign?.appointmentReminderConfirmationResponseTemplate || TEMPLATE_DEFAULT_TEXT_ACCEPT,
    appointmentReminderCancelResponseTemplate: activeCampaign?.appointmentReminderCancelResponseTemplate || TEMPLATE_DEFAULT_TEXT_CANCEL,
  };
  shapeAppointmentCampaignInputs(activeCampaign || defaultCampaign);
  const [appointmentInputs, setAppointmentInputs] = useState(defaultCampaign);
  const [errors, setErrors] = useState({});
  const [pageLoading, setPageLoading] = useState(appointmentCampaignId ? !activeCampaign : false);
  const [formInProgress, setFormInProgress] = useState(false);

  useEffect(() => {
    if (currentOrganization.id) {
      dispatch(fetchAppointmentTypes(currentOrganization.id));
      dispatch(fetchAppointmentCampaigns({ orgId: currentOrganization.id }));
      dispatch(fetchOrganizationFromAutomatedMessages());
      dispatch(fetchMappedIntegrationOffices(currentOrganization.id));
      dispatch(fetchAppointmentStatuses(currentOrganization.id));
    }
  }, [currentOrganization.id]);

  useEffect(() => {
    if (isRhinoformEnabled) {
      dispatch(fetchFormTemplates());
    }
    if (currentOrganization?.isOfficeVCardEnabled) {
      dispatch(fetchOffices());
    }
  }, [isRhinoformEnabled]);

  useEffect(() => {
    if (!loading && appointmentCampaignId) {
      const appointmentCampaign = appointmentCampaigns[appointmentCampaignId];
      setAppointmentInputs(shapeAppointmentCampaignInputs(appointmentCampaign));
      setPageLoading(false);
    }
  }, [appointmentCampaignId, loading]);

  function shapeAppointmentCampaignInputs(appointmentCampaign = {}) {
    return {
      appointmentScheduledEnabled: !!appointmentCampaign.appointmentScheduledEnabled,
      appointmentScheduledTemplate: appointmentCampaign.appointmentScheduledTemplate,
      appointmentReminder1Enabled: !!appointmentCampaign.appointmentReminder1Enabled,
      appointmentReminder1Template: appointmentCampaign.appointmentReminder1Template || AUTOMATED_MESSAGE_TEMPLATES.REMINDER1,
      appointmentReminder1DeliveryHours: appointmentCampaign.appointmentReminder1DeliveryHours?.toString() || '48',
      appointmentReminder2Enabled: !!appointmentCampaign.appointmentReminder2Enabled,
      appointmentReminder2Template: appointmentCampaign.appointmentReminder2Template || AUTOMATED_MESSAGE_TEMPLATES.REMINDER2,
      appointmentReminder2DeliveryHours: appointmentCampaign.appointmentReminder2DeliveryHours?.toString() || '72',
      appointmentReminder3Enabled: !!appointmentCampaign.appointmentReminder3Enabled,
      appointmentReminder3Template: appointmentCampaign.appointmentReminder3Template || AUTOMATED_MESSAGE_TEMPLATES.REMINDER2,
      appointmentReminder3DeliveryHours: appointmentCampaign.appointmentReminder3DeliveryHours?.toString() || '72',
      appointmentReminder4Enabled: !!appointmentCampaign.appointmentReminder4Enabled,
      appointmentReminder4Template: appointmentCampaign.appointmentReminder4Template || AUTOMATED_MESSAGE_TEMPLATES.REMINDER2,
      appointmentReminder4DeliveryHours: appointmentCampaign.appointmentReminder4DeliveryHours?.toString() || '72',
      name: appointmentCampaign.name,
      appointmentTypes: appointmentCampaign.appointmentTypes || [],
      typeSearch: '',
      default: !!appointmentCampaign.default,
      attachments: appointmentCampaign.attachments || [],
      offices: (activeCampaign?.offices || [])?.map((office) => office.id),
      appointmentReminder1SendAfterAppt: activeCampaign?.appointmentReminder1SendAfterAppt,
      appointmentReminder1EhrStatus: parseEhrStatus(activeCampaign?.appointmentReminder1EhrStatus),
      appointmentReminder2SendAfterAppt: activeCampaign?.appointmentReminder2SendAfterAppt,
      appointmentReminder2EhrStatus: parseEhrStatus(activeCampaign?.appointmentReminder2EhrStatus),
      appointmentReminder3SendAfterAppt: activeCampaign?.appointmentReminder3SendAfterAppt,
      appointmentReminder3EhrStatus: parseEhrStatus(activeCampaign?.appointmentReminder3EhrStatus),
      appointmentReminder4SendAfterAppt: activeCampaign?.appointmentReminder4SendAfterAppt,
      appointmentReminder4EhrStatus: parseEhrStatus(activeCampaign?.appointmentReminder4EhrStatus),
      appointmentReminderConfirmationResponseEnabled: activeCampaign?.appointmentReminderConfirmationResponseEnabled,
      appointmentReminderCancelResponseEnabled: activeCampaign?.appointmentReminderCancelResponseEnabled,
      appointmentReminderConfirmationResponseTemplate: activeCampaign?.appointmentReminderConfirmationResponseTemplate,
      appointmentReminderCancelResponseTemplate: activeCampaign?.appointmentReminderCancelResponseTemplate,
    };
  }

  function parseEhrStatus(input) {
    if (Array.isArray(input)) {
      return input;
    } else if (typeof input === 'string') {
      return input.split(',').map((id) => id.trim());
    } else {
      return [];
    }
  }

  function getAppointmentVariableOptions(options, officeIndex, appointmentTypeIndex) {
    const optionsCloned = cloneDeep(options);
    if (currentOrganization?.offices?.length > 0) optionsCloned.splice(officeIndex, 0, OFFICE_NAME, OFFICE_LOCATION);
    if (appointmentTypeIds?.length > 0) optionsCloned.splice(appointmentTypeIndex, 0, APPOINTMENT_TYPE);
    return optionsCloned;
  }

  const handleChange = (fieldName, value) => {
    if (fieldName === 'attachments') {
      const { attachments } = appointmentInputs;
      const newAttachments = value.filter((v) => {
        const existingAttachment = attachments.find((a) => (a.attachmentUrl === v.attachmentUrl && a.appointmentEventTypeId === v.appointmentEventTypeId));
        return !existingAttachment;
      });
      if (newAttachments.length === 0) {
        setAppointmentInputs((current) => ({ ...current, attachments: value }));
      } else {
        setAppointmentInputs((current) => ({ ...current, attachments: [...attachments, ...newAttachments] }));
      }
    } else {
      setAppointmentInputs((current) => ({ ...current, [fieldName]: value }));
    }
  };

  const handleSubmit = async () => {
    setErrors({});
    const saveErrors = ValidationService(ValidationShapers.shapeAutomatedMessage({ ...appointmentInputs }));
    const errorCount = Object.keys(saveErrors).length;
    if (errorCount > 0) {
      setErrors(saveErrors);
      ValidationHelpers.handleValidationErrors(errors, pageContainer.current);
    } else {
      setFormInProgress(true);
      const payload = {
        ...appointmentCampaignId && {
          id: Number(appointmentCampaignId),
        },
        ...appointmentInputs,
        attachments: processCampaignAttachments(appointmentInputs.attachments),
        appointmentReminder1DeliveryHours: parseInt(appointmentInputs.appointmentReminder1DeliveryHours, 10),
        appointmentReminder2DeliveryHours: parseInt(appointmentInputs.appointmentReminder2DeliveryHours, 10),
        appointmentReminder3DeliveryHours: parseInt(appointmentInputs.appointmentReminder3DeliveryHours, 10),
        appointmentReminder4DeliveryHours: parseInt(appointmentInputs.appointmentReminder4DeliveryHours, 10),
      };
      if (appointmentCampaignId) {
        dispatch(updateAppointmentCampaign(currentOrganization.id, payload))
          .then((response) => {
            if (response.status !== 200) {
              if (Array.isArray(response.data?.message)) {
                setErrors(ValidationHelpers.handleServerErrorArray(response.data));
              } else {
                setErrors(ValidationHelpers.handleServerError(response.data));
              }
              setFormInProgress(false);
            } else {
              setFormInProgress(false);
              history.push(`/settings/organization/appointment-reminders/${appointmentCampaignId}`);
            }
          });
      } else {
        dispatch(createAppointmentCampaign(currentOrganization.id, payload))
          .then((response) => {
            if (response.status !== 200) {
              if (Array.isArray(response.data?.message)) {
                setErrors(ValidationHelpers.handleServerErrorArray(response.data));
              } else {
                setErrors(ValidationHelpers.handleServerError(response.data));
              }
              setFormInProgress(false);
            } else {
              setFormInProgress(false);
              if (response?.data) {
                history.push(`/settings/organization/appointment-reminders/${response.data.id}`);
              }
            }
          });
      }
    }
  };

  const handleConfirmDeleteRequest = () => {
    dispatch(deleteAppointmentCampaign(currentOrganization.id, appointmentCampaignId)).then((response) => {
      if (response?.data) {
        history.push('/settings/organization/appointment-reminders');
      }
    });
  };

  if (pageLoading) {
    return <PageLoader />;
  }

  return (
    <div className="app-page__container" ref={pageContainer}>
      <div className="app-page__container__inner appointment-reminder-form">
        <div className="app-page__header">
          <div className="app-page__header__title">
            <BackButton navigateTo="/settings/organization/appointment-reminders" />
            {`${appointmentCampaignId ? 'Edit' : 'Create'} Appointment Reminder`}
          </div>
        </div>
        <div className="box">
          <div className="box__title-wrapper">
            <div className="box__title">Message Details</div>
          </div>
          <div className="appointment-reminder-form__box-wrapper">
            <FormLabel
              id="name"
              className="variable-message__label u-m-t u-m-b-small"
              required
            >
              Name
            </FormLabel>
            <Input
              name="name"
              initialValue={appointmentInputs.name}
              validationMessage={errors?.name}
              className="u-m-b"
              onChange={handleChange}
              explanationMessage="Message name must be unique."
              dataFeatureTag="appointmentCampaignName"
            />
            <CheckboxGroup>
              <Checkbox
                onChange={handleChange}
                isChecked={appointmentInputs.default}
                name="default"
                label={<span>Default message <span className="u-text-small u-text-muted">(Used for appointments without a type)</span></span>}
                dataFeatureTag="edit-appointment-reminder_toggle-default-message"
              />
            </CheckboxGroup>
          </div>
        </div>
        {appointmentTypeIds?.length > 0 && (
          <AppointmentCampaignMultiSelect
            name="appointmentTypes"
            title="Appointment Types"
            handleChange={handleChange}
            allItems={appointmentTypes}
            allIds={appointmentTypeIds}
            selected={appointmentInputs.appointmentTypes}
            shortName="Types"
            validationMessage={errors.appointmentTypes}
            currentOrganization={currentOrganization}
          />
        )}
        {officeIds?.length > 0 && (
          <CampaignOffices
            handleChange={handleChange}
            selectedOffices={appointmentInputs.offices}
            error={errors.offices}
          />
        )}

        <ExpandableBox
          title="Appointment Created Message"
          subtitle="A text message will be sent to the patient upon the creation of a new appointment."
          dataFeatureTag="appointment-created-message-expand"
        >
          <>
            <RhinoSwitch
              name="appointmentScheduledEnabled"
              isChecked={appointmentInputs.appointmentScheduledEnabled}
              onChange={handleChange}
              label="Enable Appointment Created Messages"
              dataFeatureTag="edit-appointment-reminder_toggle-created-message"
            />
            {appointmentInputs.appointmentScheduledEnabled ? (
              <VariableMessage
                composeLabel="Message"
                className="u-m-t-large"
                handleTemplateChange={handleChange}
                variableOptions={getAppointmentVariableOptions(APPOINTMENT_SCHEDULED_VARIABLE_OPTIONS, APPOINTMENT_SCHEDULED_OFFICE_INDEX, APPOINTMENT_TYPE_INDEX)}
                template={appointmentInputs.appointmentScheduledTemplate}
                required={appointmentInputs.appointmentScheduledEnabled}
                validationMessage={errors.appointmentScheduledTemplate}
                name="appointmentScheduledTemplate"
                data-cypress="appointmentScheduledText"
                preventFocusOnLoad
                attachments={appointmentInputs.attachments}
                appointmentEventTypeId={TYPE_APPOINTMENT_EVENT_SCHEDULED}
                initialValue={appointmentInputs[appointmentCampaignId]?.appointmentScheduledTemplate || AUTOMATED_MESSAGE_TEMPLATES.SCHEDULED}
              />
            ) : null}
          </>
        </ExpandableBox>

        <AppointmentCampaignFormReminders
          appointmentInputs={appointmentInputs}
          appointmentEhrStatuses={appointmentIntegrationStatus}
          handleChange={handleChange}
          errors={errors}
          isLoading={pageLoading}
          variableOptions={getAppointmentVariableOptions(APPOINTMENT_REMINDERS_VARIABLE_OPTIONS, APPOINTMENT_SCHEDULED_OFFICE_INDEX, APPOINTMENT_TYPE_INDEX)}
        />
        <div className="u-m-t-large">
          <FormActionButtons
            formInProgress={formInProgress}
            handleConfirmDeleteRequest={handleConfirmDeleteRequest}
            handleSubmit={handleSubmit}
            name="Appointment Reminder"
            shortName="Reminder"
            deletePermissions={[AUTOMATED_MESSAGE_DELETE]}
            editPermissions={[AUTOMATED_MESSAGE_EDIT]}
            dataFeatureTag="appointment-reminders_"
            editMode={!!appointmentCampaignId}
          />
        </div>
      </div>
    </div>
  );
};

AppointmentCampaignForm.propTypes = {
  appointmentCampaigns: PropTypes.object,
  appointmentTypeIds: PropTypes.array,
  currentOrganization: PropTypes.object,
  appointmentTypes: PropTypes.object,
  loading: PropTypes.bool,
  isRhinoformEnabled: PropTypes.bool,
  officeIds: PropTypes.array,
  offices: PropTypes.object,
  appointmentIntegrationStatus: PropTypes.object,
};

const mapStateToProps = (state) => {
  const currentOrganization = getCurrentOrg(state);
  const { appointmentTypes, appointmentCampaign, organization, office, ui, appointment } = state;
  return {
    error: ui.error,
    appointmentCampaigns: appointmentCampaign.appointmentCampaigns,
    offices: office.mappedOffices,
    officeIds: office.mappedOfficeIds,
    loading: appointmentCampaign.loading || organization.isOrganizationFormInProgress,
    currentOrganization,
    appointmentTypes: appointmentTypes.appointmentTypes,
    appointmentTypeIds: getActiveAppointmentTypeIds(state).sort((a, b) =>
      compareObjectByKey(appointmentTypes.appointmentTypes[a], appointmentTypes.appointmentTypes[b], 'alias')),
    isRhinoformEnabled: !!state.form.org?.isRhinoformEnabled,
    appointmentIntegrationStatus: appointment.appointmentIntegrationStatus,
  };
};

export default connect(mapStateToProps)(AppointmentCampaignForm);
