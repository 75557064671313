import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  LoaderPulse,
  ResourceGroup,
  Resource,
  ResourceIntro,
  Scrollbars,
  UtilitySystem,
} from 'rhinostyle';

import { StringHelpers, MaskingHelpers } from '../helpers';

const BandwidthSearch = (props) => {
  const {
    availableNumbers,
    handleChange,
    search,
    selectedNumber,
    forwardingNumber,
    handleNumberSelect,
    mode,
    searchLoading,
    errors,
  } = props;

  const renderNumbers = (item) => {
    const selected = selectedNumber === item.number;

    return (
      <Resource selected={selected} key={item.number} onClick={() => handleNumberSelect(item)}>
        <ResourceIntro title={item.nationalNumber} titleSub={StringHelpers.titleCase(item.city)} />
      </Resource>
    );
  };

  const renderSearchHelp = () => {
    if (searchLoading) {
      return (
        <div className="u-text-center u-m-t-small">
          <LoaderPulse type="secondary" />
        </div>
      );
    } else if (search.length > 2 && availableNumbers.length === 0 && !searchLoading) {
      return <div className="form__validation-message">There are no available phone numbers in this area code</div>;
    }

    return null;
  };

  return (
    <div className="row">
      <div className="column-6@small">
        <Input
          name="search"
          label="Choose A Number"
          placeholder="Search by area code"
          autoComplete="off"
          onChange={handleChange}
          initialValue={selectedNumber}
          validationMessage={errors.selectedNumber}
          disabled={mode === 'edit'}
          required
        />
        {renderSearchHelp()}

        {availableNumbers.length > 0 && (
        <Scrollbars autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.small} className="resource-group__scroll u-m-t-small">
          <ResourceGroup interfaceMode="checkbox">
            {availableNumbers.map(renderNumbers)}
          </ResourceGroup>
        </Scrollbars>
        )}
      </div>
      <div className="column-6@small">
        <Input
          name="forwardingNumber"
          label="Forwarding Number"
          explanationMessage="This number will ring if someone calls your Rhinogram number."
          onChange={(forwardingNumberName, rawValue, value) => handleChange(forwardingNumberName, value)}
          initialValue={forwardingNumber}
          validationMessage={errors.forwardingNumber}
          format={MaskingHelpers.phone}
          type="tel"
          required
        />
      </div>
    </div>
  );
};

BandwidthSearch.propTypes = {
  search: PropTypes.string.isRequired,
  selectedNumber: PropTypes.string,
  forwardingNumber: PropTypes.string.isRequired,
  availableNumbers: PropTypes.array.isRequired,
  searchLoading: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleNumberSelect: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default BandwidthSearch;
