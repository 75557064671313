import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import { UserHelpers, DataHelpers, SearchHelpers } from '../helpers';

import { AUDIT_LOG_PAGE_SIZE } from '../constants/AppConstants';

import { setError } from './uiReducer';
import * as StateReducer from './stateReducer';

const { REACT_APP_AUDIT_BASE_URL } = process.env;

// SLICE
const auditLogSlice = createSlice({
  name: 'AUDITLOG',
  initialState: {
    loading: false,
    auditLogData: [],
    hasMoreData: false,
    selectedContactIds: [],
    selectedContacts: {},
    selectedMemberIds: [],
    selectedMembers: {},
    selectedCategory: '',
    selectedAction: '',
    contacts: {},
    contactsIds: [],
    members: {},
    membersIds: [],
    categories: [],
    actions: [],
  },
  reducers: {
    receiveAuditLog: (state, action) => ({
      ...state,
      auditLogData: action.payload.auditLogData,
      loading: false,
      hasMoreData: action.payload.hasMoreData,
    }),
    requestData: (state) => ({
      ...state,
      loading: true,
    }),
    receiveContacts: (state, action) => ({
      ...state,
      contacts: action.payload.data,
      contactsIds: action.payload.ids,
    }),
    receiveMembers: (state, action) => ({
      ...state,
      members: action.payload.data,
      membersIds: action.payload.ids,
    }),
    receiveCategories: (state, action) => ({
      ...state,
      categories: action.payload.data,
    }),
    receiveActions: (state, action) => ({
      ...state,
      actions: action.payload.data,
    }),
    updateSelectedContacts: (state, action) => ({
      ...state,
      selectedContactIds: action.payload.selectedIds,
      selectedContacts: action.payload.selectedItems,
    }),
    updateSelectedMembers: (state, action) => ({
      ...state,
      selectedMemberIds: action.payload.selectedIds,
      selectedMembers: action.payload.selectedItems,
    }),
    updateSelectedCategories: (state, action) => ({
      ...state,
      selectedCategory: action.payload,
    }),
    updateSelectedActions: (state, action) => ({
      ...state,
      selectedAction: action.payload,
    }),
    updateSelectedDates: (state, action) => ({
      ...state,
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    }),
    clearAllSelectedContacts: (state) => ({
      ...state,
      selectedContactIds: [],
      selectedContacts: {},
    }),
    clearAllSelectedMembers: (state) => ({
      ...state,
      selectedMemberIds: [],
      selectedMembers: {},
    }),
    clearAllSelectedFilters: (state) => ({
      ...state,
      selectedAction: '',
      selectedCategory: '',
      selectedContactIds: [],
      selectedMemberIds: [],
      selectedMembers: {},
      selectedContacts: {},
    }),
  },
});

export default auditLogSlice.reducer;

// ACTIONS
export const {
  receiveAuditLog,
  requestData,
  receiveContacts,
  receiveMembers,
  receiveCategories,
  receiveActions,
  updateSelectedContacts,
  updateSelectedMembers,
  updateSelectedCategories,
  updateSelectedActions,
  updateSelectedDates,
  clearAllSelectedContacts,
  clearAllSelectedMembers,
  clearAllSelectedFilters,
} = auditLogSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

// This function can be used by container to fetch audit logs with dispatching the action
export function fetchAuditLog(filters, pageIndex = 0) {
  return (dispatch) => {
    dispatch(requestData());
    const pageSize = AUDIT_LOG_PAGE_SIZE;
    const { organizationId, userIds, contactIds, category, action, startDate, endDate, sortKey, sortDirection } = filters;
    const formattedStartDate = startDate.startOf('day').format('x'); // 'x' Formats to Unix timestamp in milliseconds.
    const formattedEndDate = endDate.startOf('day').format('x'); // 'x' Formats to Unix timestamp in milliseconds.
    // eslint-disable-next-line max-len
    let url = `${REACT_APP_AUDIT_BASE_URL}/queryAudit?organizationId=${organizationId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&pageSize=${pageSize}&pageIndex=${pageIndex}&sort=${sortKey}&direction=${sortDirection}`;

    if (userIds.length > 0) {
      url = `${url}&userIds=${userIds}`;
    }
    if (contactIds.length > 0) {
      url = `${url}&contactIds=${contactIds}`;
    }
    if (category) {
      url = `${url}&category=${category}`;
    }
    if (action) {
      url = `${url}&action=${action}`;
    }
    return axios.get(url)
      .then((response) => {
        dispatch(receiveAuditLog(getAuditLogPayload(response.data)));
      })
      .catch((err) => dispatch(handleError(err)));
  };
}

// This function can be used by axios.all to fetch audit logs and it is without dispatch
export function fetchAuditLogData(filters, pageIndex = 0) {
  const pageSize = AUDIT_LOG_PAGE_SIZE;
  const { organizationId, userIds, contactIds, category, action, startDate, endDate, sortKey, sortDirection } = filters;
  const formattedStartDate = startDate.format('x'); // 'x' Formats to Unix timestamp in milliseconds.
  const formattedEndDate = endDate.format('x'); // 'x' Formats to Unix timestamp in milliseconds.

  let url = `${REACT_APP_AUDIT_BASE_URL}/queryAudit?organizationId=${organizationId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&pageSize=${pageSize}&pageIndex=${pageIndex}&sort=${sortKey}&direction=${sortDirection}`; // eslint-disable-line max-len

  if (userIds.length > 0) {
    url = `${url}&userIds=${userIds}`;
  }
  if (contactIds.length > 0) {
    url = `${url}&contactIds=${contactIds}`;
  }
  if (category) {
    url = `${url}&category=${category}`;
  }
  if (action) {
    url = `${url}&action=${action}`;
  }

  return axios.get(url)
    .catch((err) => console.error(err));
}

export function fetchFilterData(search, type, includeInactiveAndDeleted = true) {
  const scope = type === 'contacts' ? 'nonMembers' : type;
  const url = search ? SearchHelpers.getSearchUrl({ search, scope, includeInactiveAndDeleted, type: 'name' }) : `/users/allMembersOrContacts?userType=${type}`;

  return (dispatch) =>
    axios.get(url, {
      ...!axios?.defaults?.headers?.common?.Authorization && {
        headers: { Authorization: 'legacy' }, // both values needed for ApiGateway
      } })
      .then((response) => {
        const { data, arrayOfIds } = receiveFilterData(response, true);
        if (scope === 'nonMembers') {
          dispatch(receiveContacts(getFiltersDataPayload(data, arrayOfIds)));
        } else if (scope === 'members') {
          dispatch(receiveMembers(getFiltersDataPayload(data, arrayOfIds)));
        }
      })
      .catch((err) => dispatch(handleError(err)));
}

export function fetchAuditLogView(auditPayload) {
  return (dispatch) => {
    dispatch(requestData());
    const promises = [
      StateReducer.fetchAllStates(),
      fetchAuditLogData(auditPayload),
      fetchFilteredData('contacts'),
      fetchFilteredData('members'),
      fetchCategoriesData(),
      fetchActionsData(),
    ];

    return axios.all(promises)
      .then(axios.spread((stateResponse, auditResponse, contactFilterResponse, memberFilterResponse, categoryResponse, actionResponse) => {
        let filteredContactResponse = {};
        let filteredMemberResponse = {};
        let categories = [];
        let actions = [];

        if (DataHelpers.exists(contactFilterResponse)) {
          filteredContactResponse = receiveFilterData(contactFilterResponse);
          dispatch(receiveContacts(getFiltersDataPayload(filteredContactResponse.data, filteredContactResponse.arrayOfIds)));
        }

        if (DataHelpers.exists(memberFilterResponse)) {
          filteredMemberResponse = receiveFilterData(memberFilterResponse);
          dispatch(receiveMembers(getFiltersDataPayload(filteredMemberResponse.data, filteredMemberResponse.arrayOfIds)));
        }

        if (DataHelpers.exists(categoryResponse)) {
          const categoryData = DataHelpers.exists(categoryResponse.data) ? categoryResponse.data : {};

          categories = Object.keys(categoryData).map((item) => ({ id: item, value: categoryData[item] }));
          dispatch(receiveCategories(getFiltersDataPayload(categories)));
        }

        if (DataHelpers.exists(actionResponse)) {
          const actionData = DataHelpers.exists(actionResponse.data) ? actionResponse.data : {};

          actions = Object.keys(actionData).map((item) => ({ id: item, value: actionData[item] }));
          dispatch(receiveActions(getFiltersDataPayload(actions)));
        }

        if (DataHelpers.exists(stateResponse) && DataHelpers.exists(stateResponse.data)) {
          stateResponse.data.unshift({ id: -1, value: '--' });
          dispatch(StateReducer.receiveStates(stateResponse.data));
        }
        dispatch(receiveAuditLog(getAuditLogPayload(auditResponse.data)));
      }))
      .catch((err) => {
        dispatch(handleError(err));
        console.error(err);
      });
  };
}

function receiveFilterData(response, isSearch) {
  const userResponse = isSearch ? response.data?.users : response.data;
  const filteredData = userResponse ? DataHelpers.arrayToObj(userResponse, 'id') : {};
  let arrayOfIds = [];

  if (DataHelpers.exists(response) && DataHelpers.exists(userResponse)) {
    arrayOfIds = userResponse.map((item) => item.id);
  }

  Object.keys(filteredData).map((item) => {
    filteredData[item].name = UserHelpers.formatAvatarName(filteredData[item].firstName, filteredData[item].lastName);

    if (filteredData[item].firstName && filteredData[item].lastName) {
      filteredData[item].title = `${filteredData[item].firstName} ${filteredData[item].lastName}`;
    } else {
      filteredData[item].title = (filteredData[item].firstName || filteredData[item].lastName) ? `${filteredData[item].firstName || filteredData[item].lastName}` : '';
    }

    return item;
  });

  return {
    data: filteredData,
    arrayOfIds,
  };
}

function handleError(err) {
  return (dispatch) => {
    const error = err.response || err;

    console.error(error);

    dispatch(setError(error));
  };
}

export function setSelectedContacts(selectedData) {
  return (dispatch) => {
    dispatch(updateSelectedContacts(selectedData));
  };
}

export function setSelectedMembers(selectedData) {
  return (dispatch) => {
    dispatch(updateSelectedMembers(selectedData));
  };
}

export function setSelectedCategories(selectedCategory) {
  return (dispatch) => {
    dispatch(updateSelectedCategories(selectedCategory));
  };
}

export function setSelectedActions(selectedAction) {
  return (dispatch) => {
    dispatch(updateSelectedActions(selectedAction));
  };
}

export function handleClearSelectedContacts() {
  return (dispatch) => {
    dispatch(clearAllSelectedContacts());
  };
}

export function handleClearSelectedMembers() {
  return (dispatch) => {
    dispatch(clearAllSelectedMembers());
  };
}

export function handleClearAllFilters() {
  return (dispatch) => {
    dispatch(clearAllSelectedFilters());
  };
}

// AXIOS HELPERS

export function fetchCategoriesData() {
  return axios.get(`${REACT_APP_AUDIT_BASE_URL}/audit/categories`)
    .catch((err) => console.error(err));
}

export function fetchActionsData() {
  return axios.get(`${REACT_APP_AUDIT_BASE_URL}/audit/actions`)
    .catch((err) => console.error(err));
}

export function fetchFilteredData(type) {
  return axios.get(`/users/allMembersOrContacts?userType=${type}`)
    .catch((err) => console.error(err));
}

// PREPARE CALLBACKS -- PAYLOAD CUSTOMIZERS

function getAuditLogPayload(auditLogs) {
  return {
    auditLogData: (DataHelpers.exists(auditLogs) && auditLogs.length > 0) ? auditLogs.slice(0, AUDIT_LOG_PAGE_SIZE) : [],
    hasMoreData: DataHelpers.exists(auditLogs) && auditLogs.length >= AUDIT_LOG_PAGE_SIZE,
  };
}

function getFiltersDataPayload(data, ids = []) {
  return {
    data,
    ids,
  };
}
