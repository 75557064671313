import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

const getSavedContentEvents = (state) => state.savedContent.savedContentSavedEvents;
const getSavedContentItemId = (state, props) => parseInt(props.savedContentItemId || '', 10);
const getActiveSavedContentItemId = (state) => state.savedContent.activeSavedContentItemId;
const getEvents = (state) => state.inbox.events;
const getUsers = (state) => state.user.users;
const getSavedContentItems = (state) => state.savedContent.savedContentItems;
const getConnectedParties = (state) => state.connectedParty.connectedParties;

export const getSavedContentItem = createSelector(
  [getSavedContentItems, getSavedContentItemId, getActiveSavedContentItemId],
  (savedContentItems, savedContentItemId, activeSavedContentItemId) => savedContentItems?.[savedContentItemId || activeSavedContentItemId] || {},
);

export const getEventsForSavedContent = createSelector(
  [getSavedContentEvents, getSavedContentItem, getEvents],
  (savedContentEvents, savedContentItem, events) => {
    const savedContentEventIds = savedContentEvents[savedContentItem?.id];
    return savedContentEventIds?.map((eventId) => events[eventId]).sort((a, b) => moment(a.timestamp).diff(moment(b.timestamp))) || [];
  },
);

export const getSavedContentContact = createSelector(
  [getSavedContentItem, getUsers],
  (savedContentItem, users) => (savedContentItem ? users[savedContentItem?.contact] : {}),
);

export const getSavedContentCreator = createSelector(
  [getSavedContentItem, getUsers],
  (savedContentItem, users) => (savedContentItem ? users[savedContentItem?.creator] : {}),
);

export const getSavedContentConnectedParties = createSelector(
  [getSavedContentContact, getConnectedParties],
  (savedContentContact, connectedParties) => (savedContentContact?.connectedParties?.map((connectedPartyId) => connectedParties[connectedPartyId]) || []),
);
