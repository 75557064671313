import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BandwidthSearch from '../components/BandwidthSearch';
import * as ChannelReducer from '../reducers/channelReducer';

class BandwidthSearchContainer extends Component {
  state = {
    search: '',
    forwardingNumber: this.props.forwardingNumber || '',
    selectedNumber: this.props.selectedNumber || '',
  };

  handleChange = (name, value) => {
    const valueTrimmed = value.trim();

    if (name === 'forwardingNumber') {
      this.setState({ [name]: valueTrimmed });
      this.props.handleChange('bandwidthForwardingNumber', value);
    } else if (name === 'search') {
      const search = valueTrimmed.includes('+1') ? '' : valueTrimmed;

      this.setState({ selectedNumber: '', search });
      this.props.handleChange('bandwidthNumber', null);

      if (valueTrimmed.length === 3) this.props.fetchSearch(valueTrimmed);
      else {
        this.props.clearNumbers();
      }
    }
  };

  handleNumberSelect = (availableNumber) => {
    this.props.handleChange('bandwidthNumber', availableNumber.number);

    this.setState({
      selectedNumber: availableNumber.number,
      search: availableNumber.nationalNumber,
    });
  }

  render() {
    const props = {
      search: this.state.search,
      selectedNumber: this.state.selectedNumber,
      forwardingNumber: this.state.forwardingNumber,
      availableNumbers: this.props.availableNumbers,
      handleChange: this.handleChange,
      handleNumberSelect: this.handleNumberSelect,
      searchLoading: this.props.searchLoading,
      mode: this.props.mode,
      errors: this.props.errors,
    };

    return <BandwidthSearch {...props} />;
  }
}

BandwidthSearchContainer.propTypes = {
  availableNumbers: PropTypes.array.isRequired,
  selectedNumber: PropTypes.string,
  forwardingNumber: PropTypes.string,
  fetchSearch: PropTypes.func.isRequired,
  clearNumbers: PropTypes.func.isRequired,
  searchLoading: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { channel } = state;

  return {
    availableNumbers: channel.availableBandwidthNumbers,
    searchLoading: channel.bandwidthSearchLoading,
  };
};

const actions = {
  fetchSearch: ChannelReducer.fetchBandwidthSearch,
  clearNumbers: ChannelReducer.clearBandwidthNumbers,
};

export default connect(mapStateToProps, actions)(BandwidthSearchContainer);
