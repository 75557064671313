export function randomIntInRange(minInclusive, maxExclusive) {
  const min = Math.ceil(minInclusive);
  const max = Math.floor(maxExclusive);
  return Math.floor(Math.random() * (max - min)) + min;
}

/**
 * Get an array of random, unique integers between a range.
 *
 * @param len The number of unique integers.
 * @param minInclusive The minimum (inclusive) number in possible range.
 * @param maxExclusive The maximum (exclusive) number in possible range.
 * @returns {Array} Unique integers in range provided.
 */
export function randomUniqueInt(len, min, max) {
  const possibleNumbers = [];

  if (max - min < len) return [];

  // fill array with unique numbers from min to max
  for (let i = min; i < max; i++) {
    possibleNumbers[i] = i;
  }

  const randUnique = [];
  for (let i = 0; i < len; i++) {
    ([randUnique[i]] = possibleNumbers.splice(randomIntInRange(min, possibleNumbers.length), 1));
  }

  return randUnique;
}

// eslint-disable-next-line prefer-template
export const roundToDecimalPlaces = (value, decimalPlaces) => Number(Math.round(value + 'e' + decimalPlaces) + 'e-' + decimalPlaces);

/**
 * @function formatNumberToCurrencyString
 * Get any Number and return a number in standard currency format.
 * Always pads to 2 decimals
 * Adds commas on thousands.
 *
 * EXAMPLES:
 * 1        --> "1.00"
 * 12       --> "12.00"
 * 1234     --> "1,234.00"
 * 12345    --> "12,345.00"
 * 1234567  --> "1,234,567.00"
 * 12345.67 --> "12,345.67"
 *
 * @param {Number} number number to be converted to string format
 * @param {String} locale locale used to determine separator, en-US by default
 * @returns {String} String representing input number in currency format.
 */
export const formatNumberToCurrencyString = (number) => (number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

/**
 * @function formatNumberToDigitGroupingString
 * Returns a string representation of a number grouped and separated by thousands.
 * Adds separator on thousands based on specified locale, default is en-US.
 * The default locale in English speaking countries uses the comma separator, non-English speaking countries
 * use the decimal separator.
 *
 * EXAMPLES:
 * 1        --> "1"
 * 12       --> "12"
 * 1234     --> "1,234"
 * 12345    --> "12,345"
 * 1234567  --> "1,234,567"
 * 12345.67 --> "12,345.67"
 *
 * @param {Number} number number to be converted to string format
 * @returns {String} String representing input number grouped by thousands with appropriate separator.
 */
export const formatNumberToDigitGroupingString = (number, locale = 'en-US') => (number ?? 0).toLocaleString(locale);
