import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Icon,
  Alert,
  Button,
  Input,
  Radio,
  RadioGroup,
  Resource,
  ResourceBody,
  ResourceIntro,
  ResourceGroup,
  ResourceRight,
  RhinoSwitch,
  Scrollbars,
  Textarea,
  UtilitySystem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UtilityInlineGrid,
  Select,
} from 'rhinostyle';
import EmojiPickerDropdown from './EmojiPickerDropdown';

import PageLoader from './PageLoader';
import Tags from './Tags';
import UserSearchContainer from '../containers/UserSearchContainer';
import GroupSearch from './GroupSearch';
import BackButtonContainer from '../containers/BackButtonContainer';
import * as AppConstants from '../constants/AppConstants';
import { Types } from '../constants';
import TagsGroup from './TagsGroup';
import { GroupHelpers, UserHelpers } from '../helpers';
import ChannelRoutesReadOnly from './ChannelRoutesReadOnly';
import BusinessHours from './BusinessHours';
import TimeZoneSelect from './TimeZoneSelect';
import CharacterCount from './CharacterCount';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { GROUP_INBOX_EDIT, GROUP_CHAT_EDIT, GROUP_DELETE } from '../constants/UserPermissionsConstants';

const DeleteGroupModal = (props) => {
  const { open, handleClose, handleReverseComplete, formInProgress, deleteGroupPreConditions, handleDeleteGroup } = props;
  const { preconditionsForGroupDelete, isTeamMessagesAvailable } = deleteGroupPreConditions;
  const hasPreConditions = preconditionsForGroupDelete && preconditionsForGroupDelete.length;
  const groupConditionCheckForTitle = isTeamMessagesAvailable ? 'Group Deletion Not Allowed' : 'Group Could Not Be Deleted';
  const groupConditionCheckForSubTitle = isTeamMessagesAvailable ? '' : 'In order to delete this group, please complete the following actions:';
  const renderModalBodyContent = () => {
    if (isTeamMessagesAvailable) {
      return (
        <p className="u-text-center">Group cannot be deleted, because there is conversation history in this group.</p>
      );
    } else if (hasPreConditions) {
      return (
        <ol className="u-list--numbers">
          {preconditionsForGroupDelete.map((p) => <li key={p}>{p}</li>)}
        </ol>
      );
    } else {
      return (
        <p className="u-text-center u-text-danger">This group will be permanently deleted, and the action cannot be undone.</p>
      );
    }
  };
  const modalHeaderTitle = hasPreConditions ? groupConditionCheckForTitle : 'Delete Group?';
  const modalHeaderSubTitle = hasPreConditions ? groupConditionCheckForSubTitle : '';
  const modalHeaderTitleClass = isTeamMessagesAvailable ? 'u-text-danger' : '';
  return (
    <Modal onReverseComplete={handleReverseComplete} open={open}>
      <ModalHeader
        onClose={handleClose}
        title={modalHeaderTitle}
        titleSub={modalHeaderSubTitle}
        titleClass={modalHeaderTitleClass}
      />
      <ModalBody>
        {renderModalBodyContent()}
      </ModalBody>
      <ModalFooter>
        <UtilityInlineGrid align="right">
          <Button onClick={handleClose}>
            Close
          </Button>
          {hasPreConditions ? null : (
            <Button loading={formInProgress} onClick={handleDeleteGroup} type="danger" data-cypress="confirmDelete">
              Delete Group
            </Button>
          )}
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );
};

DeleteGroupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  deleteGroupPreConditions: PropTypes.object.isRequired,
  handleDeleteGroup: PropTypes.func.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleReverseComplete: PropTypes.func.isRequired,
};

const OrganizationGroupForm = (props) => {
  const history = useHistory();
  const location = useLocation();
  const textareaRef = useRef();
  const {
    actionTitle,
    actionType,
    afterHoursEnabled,
    autoResponse,
    businessHours,
    channels,
    disabledTypeIds,
    errors,
    formInProgress,
    groupId,
    handleChange,
    handleFormChanges,
    handleSubmit,
    handleToggle,
    handleDeleteGroupConditions,
    handleTypeChange,
    handleUpdateSelectedIds,
    name,
    observesDst,
    pageContainerRef,
    pageLoading,
    pageTitle,
    phones,
    purpose,
    routedChannelsAreVisible,
    selectedChannelIds,
    selectedTagIds,
    selectedUserIds,
    tagIds,
    tags,
    timeZoneId,
    timeZones,
    typeId,
    users,
    viewAllMembers,
    viewGroups,
    mode,
    isDeleteGroupModal,
    deleteGroupPreConditions,
    handleConfirmDeleteRequest,
    modalFormInProgress,
    handleModalReverseComplete,
    defaultChannelEnabled,
    defaultChannelId,
    channelOptions,
    handleDefaultChannelChange,
    userOrganization,
  } = props;

  const showDelete = mode === 'update' && userHasAnyOfPermissions([GROUP_DELETE]);
  const hasGroupType = !!typeId;

  const renderStandardStatus = () => (
    <div className="item-status">
      {showDelete && (
        <div className="item-status__left">
          <Button
            reset
            onClick={() => handleDeleteGroupConditions('isDeleteGroupModal')}
            className="item-status__delete-icon"
            title="Delete Group"
          >
            <Icon bump="down" size="large" icon="trash" />
          </Button>
          <DeleteGroupModal
            handleClose={() => handleToggle('isDeleteGroupModal')}
            open={isDeleteGroupModal}
            deleteGroupPreConditions={deleteGroupPreConditions}
            handleDeleteGroup={handleConfirmDeleteRequest}
            formInProgress={modalFormInProgress}
            name={name}
            handleReverseComplete={handleModalReverseComplete}
          />
        </div>
      )}
      {hasGroupType && (
        <div className="item-status__right">
          <Button
            loading={formInProgress}
            type={actionType}
            onClick={handleSubmit}
            data-cypress={actionTitle === 'Create Group' ? 'createGroup' : null}
          >
            {actionTitle}
          </Button>
        </div>
      )}
    </div>
  );

  const renderUser = (id, idx) => {
    const user = users[id];
    const { onlineStatus } = user; // This property is being set explicitly in the WebSocketService and is not part of the User response object
    const selected = selectedUserIds.includes(id);
    const profileImageUrl = user.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${user.profileImageUrl}` : '';

    return (
      <Resource className="avatar-class" selected={selected} key={idx} onClick={() => handleUpdateSelectedIds('selectedUserIds', id)}>
        <ResourceIntro
          avatar={{ image: profileImageUrl, name: UserHelpers.formatAvatarName(user.firstName, user.lastName), type: 'member', showOnlineStatus: true, onlineStatus }}
          title={UserHelpers.formatMemberNameWithPrefixAndSuffix(user)}
        />
        {user.tags?.length > 0 && <ResourceBody className="u-text-muted"><Tags tagIds={user.tags} /></ResourceBody>}
        {!user.active && <ResourceRight><span className="u-text-accent">Deactivated</span></ResourceRight>}
      </Resource>
    );
  };

  const renderViewSelectedMembers = () => (
    <div className="search__group">
      <div className="search__sub">Members Added</div>
      {selectedUserIds.length > 0 ? (
        <Scrollbars className="resource-group__scroll" autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.large}>
          <ResourceGroup interfaceMode="checkbox">
            {selectedUserIds.map(renderUser)}
          </ResourceGroup>
        </Scrollbars>
      ) :
        'No Users Added'}
    </div>
  );

  const renderViewMembers = () => (
    viewAllMembers ? (
      <UserSearchContainer
        selectedUserIds={selectedUserIds}
        type="preloadedMembers"
        handleSearchSelect={(id) => handleUpdateSelectedIds('selectedUserIds', id)}
      />
    ) :
      renderViewSelectedMembers()
  );

  const renderViewAllGroups = () => (
    <GroupSearch
      currentGroupId={groupId}
      excludedGroupIds={groupId ? [groupId] : []}
      handleSearchSelect={(id) => handleUpdateSelectedIds('selectedGroupIds', id)}
      prepopulate
      excludeChatGroups
    />
  );

  const tabTriggerMemberClasses = `tabs__trigger ${!viewGroups ? UtilitySystem.config.classes.active : ''}`;
  const tabTriggerGroupsClasses = `tabs__trigger ${viewGroups ? UtilitySystem.config.classes.active : ''}`;

  const groupTypeOptions = [
    {
      value: Types.TYPE_GROUP_CHAT,
      label: `${GroupHelpers.formatGroupType(Types.TYPE_GROUP_CHAT)}`,
      description: 'For internal communication among Members.',
      dataCypress: 'teamGroup',
    },
    {
      value: Types.TYPE_GROUP_INBOX,
      label: `${GroupHelpers.formatGroupType(Types.TYPE_GROUP_INBOX)}`,
      description: 'For external communication between Members and Patients.',
      dataCypress: 'patientGroup',
    },
    {
      value: Types.TYPE_GROUP_INBOX_AND_CHAT,
      label: `${GroupHelpers.formatGroupType(Types.TYPE_GROUP_INBOX_AND_CHAT)}`,
      description: 'For internal communication among Members and external communication between Members and Patients.',
      dataCypress: 'patientAndTeamGroup',
    },
  ].filter((opt) => !disabledTypeIds.includes(opt.value));

  if (pageLoading) {
    return <PageLoader />;
  }

  return (
    <div className="app-page__container" ref={pageContainerRef}>
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title">
            <BackButtonContainer navigateTo="/settings/organization/groups" history={history} location={location} />
            {pageTitle}
          </div>
        </div>
        <form onChange={handleFormChanges}>
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">Group Type</div>
            </div>
            <RadioGroup blockGroup name="groupType" onChange={handleTypeChange} selectedValue={typeId}>
              {groupTypeOptions.map((item) => (
                <Radio
                  key={item.value}
                  value={item.value}
                  dataCypress={item.dataCypress}
                  label={(
                    <>
                      <span className="form__block-group__label">{item.label}</span>
                      <span className="form__block-group__desc">{item.description}</span>
                    </>
                  )}
                />
              ))}
            </RadioGroup>
            {hasGroupType && (
              <>
                <div className="box__title-wrapper">
                  <div className="box__title">Group Details</div>
                </div>

                <div className="row u-flex-justify-center">
                  <div className="column-9@small">
                    <Input
                      initialValue={name}
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      required
                      validationMessage={errors.name}
                      autoComplete="off"
                    />
                    <Input
                      explanationMessage="What's this group about?"
                      initialValue={purpose}
                      label="Purpose"
                      name="purpose"
                      onChange={handleChange}
                      validationMessage={errors.purpose}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {hasGroupType && (
            <TagsGroup
              activeTagIds={selectedTagIds}
              tags={tags}
              tagIds={tagIds}
              title="Group Tags"
              onClick={(e, id) => handleUpdateSelectedIds('selectedTagIds', id)}
              tagsDisabled={!userHasAnyOfPermissions([GROUP_INBOX_EDIT, GROUP_CHAT_EDIT])}
            />
          )}

          {hasGroupType && (
            <div className="box">
              <div className="box__title-wrapper--flex">
                <div className="box__title__flex__trigger">
                  <Button
                    reset
                    className="u-text-small u-text-primary"
                    onClick={() => handleToggle('viewAllMembers')}
                  >{viewAllMembers ? 'Review' : 'Add More Members'}
                  </Button>
                </div>
                <div className="box__title__content-flex">
                  <div className="box__title">Members</div>
                </div>
                <div className="u-text-small box__title__flex__status">({selectedUserIds.length} Member{selectedUserIds.length !== 1 && 's'} Added)</div>
              </div>
              {viewAllMembers && (
                <div className="tabs__header">
                  <Button reset className={tabTriggerMemberClasses} onClick={() => handleToggle('viewGroups')}>
                    Members
                  </Button>
                  &nbsp;|&nbsp;
                  <Button reset className={tabTriggerGroupsClasses} onClick={() => handleToggle('viewGroups')}>
                    Groups
                  </Button>
                </div>
              )}
              {viewGroups && viewAllMembers ? renderViewAllGroups() : renderViewMembers()}
              {errors.users && (
                <Alert type="danger" className="u-m-t">
                  {errors.users}
                </Alert>
              )}
            </div>
          )}

          {/* Hide routes and availability hours for Chat groups */}
          {(hasGroupType && typeId !== Types.TYPE_GROUP_CHAT) && (
            <>
              {routedChannelsAreVisible && (
                <ChannelRoutesReadOnly
                  mode="Group"
                  channels={channels}
                  selectedChannelIds={selectedChannelIds}
                  phones={phones}
                  tags={tags}
                />
              )}

              {(userOrganization?.isDefaultGroupOutboundChannelEnabled && hasGroupType && typeId !== Types.TYPE_GROUP_CHAT) && (
                <div className="box">
                  <div className="box__title-wrapper">
                    <div className="box__title">Outbound message default channel (optional)</div>
                    <div className="box__subtitle">This channel will display by default when sending messages to patients</div>
                  </div>

                  <RhinoSwitch
                    label="Switch on to activate default channel"
                    name="defaultChannelEnabled"
                    isChecked={defaultChannelEnabled}
                    onChange={handleDefaultChannelChange}
                    dataFeatureTag="group_toggle-defaultChannelEnabled"
                  />

                  {defaultChannelEnabled && (
                    <div className="form__group">
                      <div className="row">
                        <div className="column-6@xsmall" data-cypress="defaultChannelId">
                          <Select
                            disabled={!defaultChannelEnabled}
                            onSelect={handleChange}
                            options={channelOptions}
                            selected={defaultChannelId}
                            name="defaultChannelId"
                            label="Default Phone Channel"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="box">
                <div className="box__title-wrapper">
                  <div className="box__title">Availability Hours</div>
                  <div className="box__subtitle">You may override these hours by setting availability hours for this group.</div>
                </div>

                <RhinoSwitch
                  label="Group Availability Hours"
                  name="afterHoursEnabled"
                  isChecked={afterHoursEnabled}
                  onChange={handleChange}
                  dataFeatureTag="group_toggle-afterHoursEnabled"
                />

                {afterHoursEnabled && (
                  <>
                    <BusinessHours
                      businessHours={businessHours}
                      handleChange={handleChange}
                    />

                    <TimeZoneSelect
                      selectedTimeZoneId={timeZoneId}
                      timeZones={timeZones}
                      handleChange={handleChange}
                      observesDst={observesDst}
                      error={errors.timeZoneId}
                    />

                    <Textarea
                      label="Auto-Response"
                      name="autoResponse"
                      initialValue={autoResponse}
                      onChange={handleChange}
                      textareaRef={textareaRef}
                      emojiSupport
                    />
                    <div className="u-flex u-flex-direction-row u-flex-align-items-center u-m-t-small">
                      <EmojiPickerDropdown
                        inputName="autoResponse"
                        inputValue={autoResponse}
                        inputRef={textareaRef}
                        handleInputChange={handleChange}
                        dropdownPosition="top"
                      />
                      <CharacterCount length={autoResponse.length} />
                    </div>
                    <div className="form__explanation-message">This auto-response will be sent to any patient who sends a message to this group outside set availability hours.</div>
                  </>
                )}
              </div>
            </>
          )}
          <div className="u-m-t-large">
            {renderStandardStatus()}
          </div>
        </form>
      </div>
    </div>
  );
};

OrganizationGroupForm.propTypes = {
  actionTitle: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  afterHoursEnabled: PropTypes.bool.isRequired,
  autoResponse: PropTypes.string.isRequired,
  businessHours: PropTypes.array.isRequired,
  channels: PropTypes.object.isRequired,
  disabledTypeIds: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleDeleteGroupConditions: PropTypes.func.isRequired,
  handleTypeChange: PropTypes.func.isRequired,
  handleUpdateSelectedIds: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  observesDst: PropTypes.bool.isRequired,
  pageContainerRef: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  phones: PropTypes.object.isRequired,
  purpose: PropTypes.string,
  routedChannelsAreVisible: PropTypes.bool.isRequired,
  selectedChannelIds: PropTypes.array.isRequired,
  selectedTagIds: PropTypes.array.isRequired,
  selectedUserIds: PropTypes.array.isRequired,
  tagIds: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  timeZoneId: PropTypes.number,
  timeZones: PropTypes.object.isRequired,
  typeId: PropTypes.number,
  users: PropTypes.object.isRequired,
  viewAllMembers: PropTypes.bool.isRequired,
  viewGroups: PropTypes.bool.isRequired,
  groupId: PropTypes.number,
  mode: PropTypes.string.isRequired,
  isDeleteGroupModal: PropTypes.bool.isRequired,
  deleteGroupPreConditions: PropTypes.object.isRequired,
  handleConfirmDeleteRequest: PropTypes.func.isRequired,
  modalFormInProgress: PropTypes.bool.isRequired,
  handleModalReverseComplete: PropTypes.func.isRequired,
  defaultChannelEnabled: PropTypes.bool.isRequired,
  defaultChannelId: PropTypes.number,
  channelOptions: PropTypes.array,
  handleDefaultChannelChange: PropTypes.func,
  userOrganization: PropTypes.object,
};

export default OrganizationGroupForm;
