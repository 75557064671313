import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import NotificationService from '../services/NotificationService';
import * as DataHelpers from '../helpers/DataHelpers';

import { group, tag, channel } from '../actions/NormalizrSchema';
import { setError, setFormInProgress } from './uiReducer';

import * as AuthActionTypes from '../constants/AuthActionTypes';
import * as InboxActionTypes from '../constants/InboxActionTypes';
import * as UserActionTypes from '../constants/UserActionTypes';

import * as ChatActionTypes from '../constants/ChatActionTypes';
import * as ChannelReducer from './channelReducer';
import * as TagReducer from './tagReducer';
import { GROUP_RESULT_SIZE } from '../constants/AppConstants';

export const fetchGroupById = createAsyncThunk(
  'GROUP/fetchGroupById',
  async (groupId) => getGroup(groupId),
  {
    condition: (groupId, { getState }) => {
      const { group: { groups } } = getState();
      const fetchStatus = groups[groupId];
      return !DataHelpers.exists(fetchStatus);
    },
  },
);

// SLICE
const groupSlice = createSlice({
  name: 'GROUP',
  initialState: {
    loading: true,
    pageLoading: true,
    groups: {},
    groupIds: [],
    groupSearchIds: [],
    groupSearchLoading: false,
    deleteGroupPreConditions: {},
    pageNo: 0,
    totalCount: 0,
    mentionGroupIds: [],
    groupPageLoading: false,
    groupLoading: true,
    searchPageNo: 0,
  },
  reducers: {
    receiveGroups: receiveGroupsData,
    requestData: (state, action) => {
      if (action.payload?.pageNo > 0) {
        state.groupPageLoading = true;
      } else {
        state.pageLoading = true;
      }
    },
    requestPagedData: (state) => ({
      ...state,
      groupPageLoading: true,
    }),
    requestGroupData: (state) => ({
      ...state,
      groupLoading: true,
    }),
    receiveGroupData: (state) => ({
      ...state,
      groupLoading: false,
    }),
    receiveData: (state, action) => {
      if (action.payload?.pageNo > 0) {
        state.groupPageLoading = false;
      } else {
        state.pageLoading = false;
        state.loading = false;
      }
    },
    receiveGroupView: receiveGroupsData,
    requestSearchData: (state) => ({
      ...state,
      groupSearchLoading: true,
    }),
    receiveGroupsSearch: (state, action) => ({
      ...receiveGroupsBaseData(state, action),
      groupSearchIds: action.payload.pageNo === 0 ? action.payload.groupIds : [...new Set([...state.groupSearchIds, ...action.payload.groupIds])],
      groupSearchLoading: false,
      totalSearchCount: action.payload.totalCount,
      searchPageNo: action.payload.pageNo,
    }),
    receiveMentionGroupsSearch: (state, action) => ({
      ...receiveGroupsBaseData(state, action),
      mentionGroupIds: action.payload.groupIds,
    }),
    clearGroupSearch: (state) => ({
      ...state,
      groupSearchIds: [],
      searchPageNo: 0,
      totalSearchCount: 0,
      groupSearchLoading: false,
    }),
    resetGroupData: (state) => ({
      ...state,
      groupIds: [],
      pageLoading: true,
      totalCount: 0,
      pageNo: 0,
    }),
    receiveDeleteGroupPreconditions: (state, action) => ({
      ...state,
      deleteGroupPreConditions: action.payload,
    }),
    removeGroup: (state, action) => {
      const groupsClone = { ...state.groups };
      delete groupsClone[action.payload.groupId];

      return {
        ...state,
        groups: groupsClone,
        groupIds: state.groupIds.filter((groupId) => groupId !== action.payload.groupId),
        loading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AuthActionTypes.setUser, receiveGroupsData)
      .addCase(ChatActionTypes.receiveChatInbox, receiveGroupsData)
      .addCase(ChatActionTypes.receiveChatThreadUserView, receiveGroupsData)
      .addCase(ChatActionTypes.receiveChatThreadGroupView, receiveGroupsData)
      .addCase(ChatActionTypes.receiveWebSocketEvent, receiveGroupsData)
      .addCase(ChatActionTypes.receiveCreateChatEvent, receiveGroupsData)

      .addCase(InboxActionTypes.receiveInboxThreadView, receiveGroupsData)

      .addCase(UserActionTypes.receiveMembersView, (state, action) => ({
        ...receiveGroupsBaseData(state, action),
        loading: false,
      }))
      .addCase(UserActionTypes.receiveUsers, receiveGroupsData)
      .addCase(UserActionTypes.receiveUsersSearch, receiveGroupsData)
      .addCase(UserActionTypes.receiveContactUsers, receiveGroupsData)
      .addCase(UserActionTypes.receiveConnectedPartySearch, receiveGroupsData)
      .addCase(UserActionTypes.receiveUser, receiveGroupsData)
      .addCase(UserActionTypes.receiveContactList, receiveGroupsData)
      .addCase(UserActionTypes.receiveMembersFiltersView, receiveGroupsData)
      .addCase(fetchGroupById.fulfilled, (state, action) => {
        const normalized = normalize([action.payload.data], [group]);
        return receiveGroupsBaseData(state, { payload: getGroupsPayload(normalized) });
      })
      .addCase(fetchGroupById.pending, (state, action) => {
        const groupId = action.meta.arg;
        return receiveGroupsBaseData(state, { payload: { groupIds: [groupId], groups: { [groupId]: { status: 'pending' } } } });
      });
  },
});

export default groupSlice.reducer;

// ACTIONS
export const {
  receiveGroups,
  requestData,
  receiveData,
  receiveGroupView,
  requestSearchData,
  receiveGroupsSearch,
  clearGroupSearch,
  resetGroupData,
  removeGroup,
  receiveDeleteGroupPreconditions,
  receiveMentionGroupsSearch,
  receiveGroupData,
  requestGroupData,
} = groupSlice.actions;

// REDUCER HELPERS

function receiveGroupsBaseData(state, action) {
  return {
    ...state,
    groups: DataHelpers.mergeShallow(state.groups, action.payload.groups),
    groupIds: [...new Set([...state.groupIds, ...action.payload.groupIds])],
  };
}

function receiveGroupsData(state, action) {
  return {
    ...receiveGroupsBaseData(state, action),
    pageNo: action.payload.pageNo,
    totalCount: action.payload.totalCount,

  };
}

// THUNKS -- ASYNC ACTION CREATORS

export function fetchGroups(pageNo = 0) {
  return (dispatch) => {
    dispatch(requestData({ pageNo }));
    if (pageNo === 0) {
      dispatch(resetGroupData());
    }
    return getGroups({ pageNo, minimal: true })
      .then((response) => {
        const normalized = normalize(response.data.results, [group]);
        dispatch(receiveGroups(getGroupsPayload(normalized, response.data.total, pageNo)));
        dispatch(receiveData({ pageNo }));
      })
      .catch((err) => dispatch(handleError(err)));
  };
}

export function fetchGroup(groupId) {
  return (dispatch) => {
    dispatch(requestGroupData());
    return getGroup(groupId)
      .then((response) => {
        const normalized = normalize([response.data], [group]);
        dispatch(receiveGroups(getGroupsPayload(normalized)));
        dispatch(receiveGroupData());
      })
      .catch((err) => dispatch(handleError(err)));
  };
}

export function fetchGroupsFormView(groupId) {
  return (dispatch) => {
    dispatch(requestData());
    const promises = [getGroups({ minimal: true }), TagReducer.getTags(), ChannelReducer.getChannels()];
    if (groupId) promises.push(getGroup(groupId));
    return axios.all(promises)
      .then(axios.spread((groupsResponse, tagsResponse, channelsResponse, groupResponse) => {
        const groupData = groupResponse ? [...groupsResponse.data.results, groupResponse.data] : groupsResponse.data.results;
        const normalizedGroups = normalize(groupData, [group]);
        const normalizedTags = normalize(tagsResponse.data, [tag]);
        const normalizedChannels = normalize(channelsResponse.data, [channel]);
        dispatch(receiveGroupView(getGroupsFormView(normalizedGroups, normalizedTags, normalizedChannels, groupsResponse.data.total)));
        dispatch(receiveData());
      }))
      .catch((err) => dispatch(handleError(err)));
  };
}

export function updateGroup(payload) {
  return (dispatch) => {
    dispatch(setFormInProgress(true));

    return axios.put(`/groups/${payload.id}`, payload)
      .then((response) => {
        dispatch(setError(null));
        NotificationService('updateGroup', response);
      })
      .catch((err) => dispatch(handleErrorToast('updateGroup', err)));
  };
}

export function createGroup(payload) {
  return (dispatch) => {
    dispatch(setFormInProgress(true));

    return axios.post('/groups', payload)
      .then((response) => {
        dispatch(setError(null));
        NotificationService('createGroup', response);

        return response.data; // return response from API for proper browserHistory.push(groups/data.id)
      })
      .catch((err) => dispatch(handleErrorToast('createGroup', err)));
  };
}

export function fetchGroupsAndPopulateSearchIds(pageNo = 0, excludeChatGroups = false) {
  return (dispatch) => {
    if (pageNo === 0) {
      dispatch(requestSearchData());
    }
    return getGroups({ excludeChatGroups, pageNo, minimal: true })
      .then((response) => {
        const normalized = normalize(response.data.results, [group]);
        dispatch(receiveGroupsSearch(getGroupsPayload(normalized, response.data.total, pageNo)));
      })
      .catch((err) => dispatch(handleError(err)));
  };
}

export function fetchGroupsAndPopulateMentionIds(excludeChatGroups = false, pageNo) {
  return (dispatch) => getGroups({ excludeChatGroups, pageNo, minimal: true })
    .then((response) => {
      const normalized = normalize(response.data.results, [group]);
      dispatch(receiveMentionGroupsSearch(getGroupsPayload(normalized, response.data.total, pageNo)));
    })
    .catch((err) => dispatch(handleError(err)));
}

export function fetchGroupSearch(value, excludeChatGroups = false, pageNo = 0) {
  return (dispatch) => {
    if (pageNo === 0) {
      dispatch(requestSearchData());
    }
    return getGroupsSearch({ query: value, excludeChatGroups, pageNo })
      .then((response) => {
        const normalized = normalize(response.data.results, [group]);

        dispatch(receiveGroupsSearch(getGroupsPayload(normalized, response.data.total, pageNo)));
      })
      .catch((err) => dispatch(handleError(err)));
  };
}

export function fetchGroupMentionSearch(query) {
  return (dispatch) => getGroupsSearch({ query, excludeChatGroups: true })
    .then((response) => {
      const normalized = normalize(response.data.results, [group]);
      dispatch(receiveMentionGroupsSearch(getGroupsPayload(normalized)));
    }).catch((err) => {
      dispatch(handleError(err));
    });
}

export function destroyGroupPreconditions(groupId) {
  return (dispatch) =>
    axios.delete(`/groups/${groupId}`)
      .then((response) => {
        dispatch(receiveDeleteGroupPreconditions(response.data));
      })
      .catch((err) => {
        console.error(err.response);

        dispatch(setError(err.response || err));
      });
}

export function destroyGroup(groupId) {
  return (dispatch) =>
    axios.delete(`/groups/${groupId}?isDeleteConfirmed=true`)
      .then((response) => {
        if (response.data && response.data.preconditionsForGroupDelete && response.data.preconditionsForGroupDelete.length > 0) {
          dispatch(receiveDeleteGroupPreconditions(response.data));
          return response.data; // return response from API for proper conditionally browserHistory.push('/settings/organization/groups')
        } else {
          dispatch(removeGroup({ groupId }));
          NotificationService('destroyGroup', response);
          return response.data;
        }
      })
      .catch((err) => {
        console.error(err.response);
        dispatch(setError(err.response || err));
        NotificationService('destroyGroup', err.response);
      });
}

function handleError(err) {
  return (dispatch) => {
    const error = err.response || err;

    // eslint-disable-next-line no-console
    console.error(error);

    dispatch(setError(error));
  };
}

function handleErrorToast(action, err) {
  return (dispatch) => {
    console.error(err.response || err);

    dispatch(setError(err.response || err));
    // throw toast only if a 500, all other api errors are set to inline validation (to avoid duplicate errors rendering)
    if (err.response && err.response.status === 500) {
      NotificationService(action, err.response);
    }
  };
}

// AXIOS HELPERS

export function getGroup(groupId) {
  return axios.get(`/groups/${groupId}`)
    .catch((err) => console.error(err));
}

export function getGroups(payload = {}) {
  const { pageNo = 0, pageSize = GROUP_RESULT_SIZE, excludeChatGroups = false, minimal = false, excludeDynamicGroups = true } = payload;
  let url = `/groups?pageSize=${pageSize}&pageNo=${pageNo}`;
  if (excludeChatGroups) url += '&groupTypeIds=59,61';
  if (minimal) url += `&minimal=${minimal}`;
  if (excludeDynamicGroups) url += `&isDynamic=${false}`;
  return axios.get(url);
}

function getGroupsSearch(payload = {}) {
  const { pageNo = 0, pageSize = GROUP_RESULT_SIZE, excludeChatGroups = true, minimal = false, query = '' } = payload;
  let url = `/groups/search?pageSize=${pageSize}&pageNo=${pageNo}`;
  if (query) url += `&q=${query}`;
  if (excludeChatGroups) url += '&groupTypeIds=59,61';
  if (minimal) url += `&minimal=${minimal}`;
  return axios.get(url);
}

export function getDynamicGroupByUserIds(userIds) {
  /**
   * This reduce method is used to transofrm an array of values to query string format for arrays.
   * [1, 2, 3] --> 'userIds[]=1&userIds[]=2&userIds[]=3'
   * A REST API will not properly process an array type inside a query string, but can parse the query string as shown above when an array as a parameter is necessary.
   * The reduce starts with an empty string and concatenates new values based on the current element. '&' character is only appended if the current item is not the last.
   * */
  const shapedQueryString =
    userIds.reduce((queryString, userId, index, userIdsArray) => queryString.concat(`userIds[]=${userId}${index < userIdsArray.length - 1 ? '&' : ''}`), '');

  return axios.get(`/groups/dynamicGroup?${shapedQueryString}`);
}

// PREPARE CALLBACKS -- PAYLOAD CUSTOMIZERS

export function getGroupsPayload(normalizedGroups, totalCount, pageNo = 0) {
  return {
    groups: {
      ...normalizedGroups.entities.groups,
    },
    groupIds: normalizedGroups.result,
    channels: {
      ...normalizedGroups.entities.channels,
    },
    phones: {
      ...normalizedGroups.entities.phones,
    },
    phoneIds: DataHelpers.getObjectKeys(normalizedGroups.entities.phones),
    channelIds: DataHelpers.getObjectKeys(normalizedGroups.entities.channels),
    tags: {
      ...normalizedGroups.entities.tags,
    },
    tagIds: DataHelpers.getObjectKeys(normalizedGroups.entities.tags),
    users: {
      ...normalizedGroups.entities.users,
    },
    userIds: DataHelpers.getObjectKeys(normalizedGroups.entities.users),
    totalCount,
    pageNo,
  };
}

function getGroupsFormView(normalizedGroups, normalizedTags, normalizedChannels) {
  return {
    ...getGroupsPayload(normalizedGroups),
    ...ChannelReducer.getChannelsPayload(normalizedChannels),
    ...TagReducer.getTagsPayload(normalizedTags),
  };
}
