import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { NotificationActions } from 'rhinostyle';
import axios from 'axios';

import AccountSetup from '../components/AccountSetup';
import * as OrganizationReducer from '../reducers/organizationReducer';
import * as AuthReducer from '../reducers/authReducer';
import { fetchBillingProducts, fetchBillingCoupons } from '../reducers/billingReducer';
import * as StateReducer from '../reducers/stateReducer';
import { AppConstants } from '../constants';
import { ValidationHelpers } from '../helpers';
import { ValidationService, ValidationShapers } from '../services/ValidationService';

class AccountSetupContainer extends Component {
  state = {
    formInProgress: false,
    name: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    states: [],
    zip: '',
    parentCompany: '',
    orgContactEmail: '',
    orgContactName: '',
    orgContactPhone: '',
    firstName: '',
    lastName: '',
    billingContactFirstName: '',
    billingContactLastName: '',
    billingContactPhone: '',
    billingContactEmail: '',
    billingStreet1: '',
    billingStreet2: '',
    billingCity: '',
    billingState: '',
    billingZip: '',
    errors: {},
    selectedBillingOpt: 'newCust',
    selectedPaymentMethod: AppConstants.PAYMENT_METHODS.OFFLINE_PAYMENT,
    billingProductId: undefined,
    activeProduct: null,
    couponSelected: false,
    salesRep: -1,
    billingChecked: false,
    startBillingDate: null,
    disabled: false,
    activeCoupon: null,
    installAmount: null,
    bankAccountType: '',
    activeBankAccntTypeId: 1,
    ipAddress: '',
    creditCardZip: '',
    selectedParentCompany: null,
    selectedParentCompanyId: null,
    parentCompanySearchLoading: false,
    parentCompanyLabel: 'Select Parent Company',
    filteredParentCompanies: null,
    filteredParentCompanyIds: null,
    isAddParentOrgModalOpen: false,
    companyName: null,
    parentCompanyformErrors: {},
  };

  componentDidMount() {
    const defaultBankAccnt = AppConstants.bankAccountTypes.length > 0 ? AppConstants.bankAccountTypes[0].code : null;
    this.setState({
      bankAccountType: defaultBankAccnt,
      activeBankAccntTypeId: defaultBankAccnt === 'CHQ' ? 1 : 2,
    });

    this.props.fetchStates();
    this.props.fetchParentOrganizations()
      .then(() => {
        const { parentCompanies, parentCompanyIds } = this.props;
        this.setState({
          filteredParentCompanies: parentCompanies,
          filteredParentCompanyIds: parentCompanyIds,
        });
      });
    this.props.fetchBillingProducts()
      .then(() => {
        const basicPlan = this.props.products.length > 0 ? this.props.products[0].id : null;

        this.setState({
          billingProductId: basicPlan,
          activeProduct: basicPlan,
        });
      })
      .then(() => {
        this.props.fetchBillingCoupons();
      });

    document.title = 'Rhinogram | Account Setup';
    const script = document.createElement('script');
    script.src = AppConstants.RISK_JS_URL;
    script.async = true;
    script.id = 'WePayScript';
    document.body.appendChild(script);

    this.getForeignDeviceData();
  }

  componentWillUnmount() {
    let elem;
    (elem = document.getElementById('WePayScript')).parentNode.removeChild(elem);
  }
  /**
   * Fetch data from ipinfo.io service
   * @return {void]}
   */
  getForeignDeviceData = () =>
    axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`, { withCredentials: false })
      .then((response) => this.setState({ ipAddress: response.data.ip }))
      .catch((err) => {
        console.error(err);
      });

  handleToggle = (name) => {
    const newState = {};

    newState[name] = !this.state[name];

    if (name === 'billingChecked') { // org sound
      if (!this.state[name]) { // clicked from off -> on
        newState.billingChecked = true;
      } else { // clicked from on -> off
        newState.billingChecked = false;
      }
    }

    this.setState(newState);
  }

  fetchAllItems = (searchText) => {
    const { parentCompanies } = this.props;
    this.setState({ parentCompanySearchLoading: true });
    let searchedItems = {};
    if (searchText.length > 0) {
      // eslint-disable-next-line array-callback-return
      Object.keys(parentCompanies).map((k) => {
        if ((parentCompanies[k].title.toLowerCase()).includes(searchText.toLowerCase())) {
          searchedItems[k] = parentCompanies[k];
        }
      });
    } else {
      searchedItems = parentCompanies;
    }
    this.setState({
      filteredParentCompanies: searchedItems,
      filteredParentCompanyIds: Object.keys(searchedItems),
    });
    this.setState({ parentCompanySearchLoading: false });
  }

  handleUpdateFormSelectedId = (selectedId, selectedItem) => {
    this.setState({
      selectedParentCompany: selectedItem,
      selectedParentCompanyId: selectedId,
      parentCompanyLabel: selectedItem?.title,
    });
  };

  handleAddParentOrganization = () => {
    this.setState({ isAddParentOrgModalOpen: true });
  }

  handleCloseModal = () => {
    this.setState({ isAddParentOrgModalOpen: false, companyName: '' });
  }

  saveParentCompany = () => {
    const { companyName } = this.state;
    const errors = {};

    if (!companyName) {
      errors.companyName = 'Company Name is required!';
    }
    const errorCount = Object.keys(errors).length;

    if (errorCount > 0) {
      this.setState({
        parentCompanyformErrors: errors,
      });
    } else {
      const payload = {
        name: companyName,
      };
      this.props.createParentOrganizations(payload).then((response) => {
        const { id, name } = response;
        const selectedItem = { id, title: name };
        this.setState({
          selectedParentCompany: selectedItem,
          selectedParentCompanyId: id,
          parentCompanyLabel: selectedItem?.title,
          isAddParentOrgModalOpen: false,
          companyName: '',
        });
      });
    }
  }

  handleChange = (name, value) => {
    const state = { [name]: value };

    if (name === 'couponCode') {
      state.couponSelected = true;
      state.activeCoupon = value;
    }

    if (name === 'billingProductId') {
      state.productSelected = true;
      state.activeProduct = value;
    }

    if (name === 'bankAccountType') {
      state.bankAccountType = value === 1 ? 'CHQ' : 'SAV';
      state.activeBankAccntType = value === 1 ? 'CHQ' : 'SAV';
    }

    this.setState(state);
  }

  handleRemoveCoupon = () => {
    this.setState({
      couponCode: null,
      activeCoupon: null,
      couponSelected: false,
    });
  }

  findUserForRedirect = (user, orgId) => user.organization.id === orgId;

  scrollToFirstError = () => {
    // Grab the first error
    const firstError = Object.keys(this.state.errors)[0];

    // Scroll to and focus on first error
    ValidationHelpers.scrollToFormElement(firstError, this.pageContainer);
  }

  formatCoupon = (item) => {
    if (item.couponCodes[0].remainingUsages) {
      if (item.couponCodes[0].remainingUsages >= 1) {
        return `Remaining Usage ${item.couponCodes[0].remainingUsages}'s`;
      }
      return 'Recurs: indefinitely';
    }
    return 'single use only';
  }

  formatOrg = (organization) => ({
    name: organization.name,
    parentCompanyId: parseInt(organization.selectedParentCompanyId, 10),
    street1: organization.street1,
    street2: organization.street2,
    city: organization.city,
    zip: organization.zip.trim(),
    state: organization.state ? this.props.states.find((state) => state.id === organization.state).code : null,
    businessAddress: this.shapeAddressForDB(organization),
    contactName: organization.orgContactName,
    contactPhone: organization.orgContactPhone,
    contactEmail: organization.orgContactEmail,
    billingChecked: organization.billingChecked,
    selectedBillingOpt: organization.selectedBillingOpt,
    businessPhone: organization.businessPhone,
    businessEmail: organization.businessEmail,
    billing: {
      billManagerProdId: Number(organization.billingProductId),
      billManagerCustId: Number(organization.billManagerCustId),
      billManagerSubId: Number(organization.billingSubId),
      billManagerPaymentId: Number(organization.billingPaymentId),
      planFrequencyId: organization.billingProductId ? this.props.products.find((p) => p.id === organization.billingProductId).planFrequencyId : null,
      planName: organization.billingProductId ? this.props.products.find((p) => p.id === organization.billingProductId).name : null,
      firstName: (organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD || organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.BANK) ? organization.firstName : undefined, // eslint-disable-line max-len
      lastName: (organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD || organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.BANK) ? organization.lastName : undefined, // eslint-disable-line max-len
      contactFirstName: organization.billingContactFirstName,
      contactLastName: organization.billingContactLastName,
      contactPhone: organization.billingContactPhone,
      contactEmail: organization.billingContactEmail,
      salesRef: organization.salesRep ? AppConstants.salesReps.find((sr) => sr.id === organization.salesRep).value : null,
      salesRefCode: organization.salesRep ? AppConstants.salesReps.find((sr) => sr.id === organization.salesRep).code : null,
      salesRefType: organization.salesRep ? AppConstants.salesReps.find((sr) => sr.id === organization.salesRep).type : null,
      startBillingDate: organization.startBillingDate ? moment(organization.startBillingDate).format('YYYY-MM-DD') : null,
      street1: organization.billingStreet1,
      street2: organization.billingStreet2,
      city: organization.billingCity,
      stateId: organization.billingState,
      state: organization.billingState ? this.props.states.find((state) => state.id === organization.billingState).code : null,
      zip: organization.billingZip.trim(),
      creditCard: organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD,
      hasBankAccount: organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.BANK,
      couponCode: organization.couponCode ? this.props.coupons.find((c) => c.id === organization.couponCode).couponCodes[0].code : null,
      installAmount: organization.installAmount,
      payment: {
        ccNumber: organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD ? organization.ccNumber : undefined,
        ccExpMonth: organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD ? organization.ccExpMonth : undefined,
        ccExpYear: organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD && organization.ccExpYear && parseInt(organization.ccExpYear.toString().substr(2, 2), 10), // eslint-disable-line max-len
        bankName: organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.BANK ? organization.bankName : undefined,
        bankAccNum: organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.BANK ? organization.bankAccNum : undefined,
        bankRouteNum: organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.BANK ? organization.bankRouteNum : undefined,
        ccCvv: organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD ? organization.creditCardVerificationValue : undefined,
        bankAccountType: organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.BANK ? organization.bankAccountType : undefined,
        creditCardZip: organization.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD ? organization.creditCardZip.trim() : undefined,
      },
    },
  });

  formatCustomer = (customer) => ({
    contactFirstName: customer.billing.contactFirstName,
    contactLastName: customer.billing.contactLastName,
    name: customer.name,
    contactEmail: customer.billing.contactEmail,
    contactPhone: customer.billing.contactPhone,
    salesRef: customer.billing.salesRef,
    salesRefCode: customer.billing.salesRefCode,
    salesRefType: customer.billing.salesRefType,
  });

  formatCreditCard(cc, customerId) {
    global.WePay.risk.generate_risk_token();
    return `CustomerID=${customerId}` +
        `&PublicAPIKey=${process.env.REACT_APP_FUSEBILL_PUBLIC_KEY}` +
        `&CardNumber=${cc.billing.payment.ccNumber}` +
        `&FirstName=${cc.billing.firstName}` +
        `&LastName=${cc.billing.lastName}` +
        `&ExpirationYear=${cc.billing.payment.ccExpYear}` +
        `&ExpirationMonth=${cc.billing.payment.ccExpMonth}` +
        `&Cvv=${cc.billing.payment.ccCvv}` +
        '&makeDefault=true' +
        `&Email=${cc.billing.contactEmail}` +
        `&postalZip=${cc.billing.payment.creditCardZip}` +
        `&clientIp=${this.state.ipAddress}` +
        `&RiskToken=${global.WePay.risk.get_risk_token()}`;
  }

  handleSubmit = () => {
    const formattedOrg = this.formatOrg(this.state);
    const errors = ValidationService(ValidationShapers.shapeOrganization({ ...formattedOrg }));
    const errorCount = Object.keys(errors).length;
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
      });
    } else {
      this.setState({ formInProgress: true });
      if (this.state.billingChecked) { // when sending only the organization
        delete formattedOrg.billing;
      }

      if (this.state.selectedBillingOpt === 'existCust') { // when sending only the organization w/ existing customer id
        const { billManagerCustId } = formattedOrg.billing;

        delete formattedOrg.billing;

        formattedOrg.billing = { billManagerCustId };
      }
      if (this.state.selectedBillingOpt !== 'existCust' && this.state.selectedPaymentMethod === AppConstants.PAYMENT_METHODS.CREDIT_CARD && !this.state.billingChecked) {
        if (!ValidationHelpers.validateExpirationDate(formattedOrg.billing.payment.ccExpYear, formattedOrg.billing.payment.ccExpMonth)) {
          errors.ccExpMonth = 'Credit card cannot be expired.';
          this.setState({ formInProgress: false });
          this.setState({ errors }, () => {
            ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
          });
        } else {
          let newState = { formInProgress: true };
          const formattedCustomer = this.formatCustomer(formattedOrg);
          let custRes;
          OrganizationReducer.createCustomer(formattedCustomer)
            .then((custResponse) => {
              custRes = custResponse;
              return OrganizationReducer.addCreditCard(this.formatCreditCard(formattedOrg, custRes.id));
            })
            .then((ccRes) => this.props.createOrganizationWithCC({ org: formattedOrg, customerResponse: custRes, ccRes: ccRes.data }))
            .then((org) => {
              newState = { formInProgress: false };
              if (this.props.error) {
                newState.errors = ValidationHelpers.handleServerError(this.props.error.data);
              } else {
                newState.disabled = true;
                const redirect = false;
                this.props.fetchMyUsers(redirect)
                  .then((myUsers) => {
                    const orgId = org.id;
                    const userId = myUsers.find((user) => this.findUserForRedirect(user, orgId)).id;
                    this.props.selectOrg(orgId, userId, null, this.props.history)
                      .then((nextPath) => {
                        this.props.history.push(nextPath);
                      });
                  });
              }
              this.setState(newState);
            })
            .catch((e) => {
              NotificationActions.addNotification({
                body: e.message,
                icon: 'warning',
                type: 'danger',
              });
              newState.errors = ValidationHelpers.handleServerError(e);
              newState.formInProgress = false;
              this.setState(newState);
            });
        }
      } else {
        this.props.createOrganization(formattedOrg)
          .then((org) => {
            const newState = { formInProgress: false };

            if (this.props.error) {
              newState.errors = ValidationHelpers.handleServerError(this.props.error.data);
            } else {
              newState.disabled = true;

              const redirect = false;

              this.props.fetchMyUsers(redirect)
                .then((myUsers) => {
                  const orgId = org.id;
                  const userId = myUsers.find((user) => this.findUserForRedirect(user, orgId)).id;
                  this.props.selectOrg(orgId, userId, null, this.props.history)
                    .then((nextPath) => {
                      this.props.history.push(nextPath);
                    });
                });
            }

            this.setState(newState);
          });
      }
    }
  }

  shapeAddressForDB = (data) => ({
    street1: data.street1,
    street2: data.street2,
    city: data.city,
    state: data.state ? this.props.states.find((state) => state.id === data.state).code : undefined,
    zip: data.zip,
  });

  render() {
    const props = {
      salesReps: AppConstants.salesReps,
      salesRep: this.state.salesRep,
      states: this.props.states,
      state: this.state.state,
      referrals: this.referrals,
      categories: this.categories,
      products: this.props.products.map((item) => ({
        id: item.id,
        name: item.name,
        description: item.description,
        planFrequencyId: item.planFrequencyId,
      })),
      activeProduct: this.state.activeProduct,
      coupons: this.props.coupons.map((item) => ({
        id: item.id,
        code: item.couponCodes[0].code,
        description: this.formatCoupon(item),
        eligibilityStartDate: item.eligibilityStartDate,
        eligibilityEndDate: item.eligibilityEndDate,
      })),
      couponSelected: this.state.couponSelected,
      errors: this.state.errors,
      activeCoupon: this.state.activeCoupon,
      formInProgress: this.state.formInProgress,
      pageContainerRef: (pageContainer) => (this.pageContainer = pageContainer),
      handleChange: this.handleChange,
      handleToggle: this.handleToggle,
      handleRemoveCoupon: this.handleRemoveCoupon,
      handleSubmit: this.handleSubmit,
      selectedBillingOpt: this.state.selectedBillingOpt,
      selectedPaymentMethod: this.state.selectedPaymentMethod,
      startBillingDate: this.state.startBillingDate,
      billingChecked: this.state.billingChecked,
      disabled: this.state.disabled,
      bankAccountTypes: AppConstants.bankAccountTypes,
      activeBankAccntTypeId: this.state.activeBankAccntTypeId,
      billingState: this.state.billingState,
      filteredParentCompanies: this.state.filteredParentCompanies ?? this.props.parentCompanies,
      filteredParentCompanyIds: this.state.filteredParentCompanyIds ?? this.props.parentCompanyIds,
      selectedParentCompany: this.state.selectedParentCompany,
      selectedParentCompanyId: this.state.selectedParentCompanyId,
      fetchAllItems: this.fetchAllItems,
      handleUpdateFormSelectedId: this.handleUpdateFormSelectedId,
      parentCompanySearchLoading: this.state.parentCompanySearchLoading,
      parentCompanyLabel: this.state.parentCompanyLabel,
      isAddParentOrgModalOpen: this.state.isAddParentOrgModalOpen,
      companyName: this.state.companyName,
      handleCloseModal: this.handleCloseModal,
      handleAddParentOrganization: this.handleAddParentOrganization,
      saveParentCompany: this.saveParentCompany,
      parentCompanyFormInProgress: this.props.parentCompanyFormInProgress,
      parentCompanyformErrors: this.state.parentCompanyformErrors,
    };

    return (<AccountSetup {...props} />);
  }
}

AccountSetupContainer.propTypes = {
  products: PropTypes.array.isRequired,
  coupons: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  fetchStates: PropTypes.func.isRequired,
  fetchBillingProducts: PropTypes.func.isRequired,
  fetchBillingCoupons: PropTypes.func.isRequired,
  createOrganization: PropTypes.func.isRequired,
  fetchMyUsers: PropTypes.func.isRequired,
  selectOrg: PropTypes.func.isRequired,
  error: PropTypes.object,
  createOrganizationWithCC: PropTypes.func.isRequired,
  history: PropTypes.object,
  fetchParentOrganizations: PropTypes.func,
  parentCompanies: PropTypes.object,
  parentCompanyIds: PropTypes.array,
  createParentOrganizations: PropTypes.func,
  parentCompanyFormInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { billing, ui, states, organization } = state;
  const parentCompanies = {};
  const parentCompanyIds = [];
  if (organization?.parentCompanies?.length > 0) {
    Object.values(organization?.parentCompanies).forEach((p) => {
      parentCompanies[p.id] = {
        title: p.name,
        id: p.id,
      };
      parentCompanyIds.push(p.id);
    });
  }
  return {
    products: billing.products,
    error: ui.error,
    coupons: billing.coupons,
    states: states.states,
    parentCompanies,
    parentCompanyIds,
    parentCompanyFormInProgress: organization.parentCompanyFormInProgress,
  };
};

const actions = {
  fetchBillingProducts,
  fetchBillingCoupons,
  fetchStates: StateReducer.fetchStates,
  createOrganization: OrganizationReducer.createOrganization,
  fetchMyUsers: AuthReducer.fetchMyUsers,
  selectOrg: AuthReducer.setOrganization,
  createOrganizationWithCC: OrganizationReducer.createOrganizationWithCC,
  fetchParentOrganizations: OrganizationReducer.fetchParentOrganizations,
  createParentOrganizations: OrganizationReducer.createParentOrganizations,
};

export default connect(mapStateToProps, actions)(AccountSetupContainer);
