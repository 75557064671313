import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Icon,
  Tooltip,
  UtilityList,
  UtilityListItem,
} from 'rhinostyle';
import { AppConstants } from '../constants';
import {
  AttachmentHelpers,
} from '../helpers';
import {
  FORM_VIEW,
} from '../constants/UserPermissionsConstants';
import { getActiveChannelIds } from '../selectors/channelSelectors';
import {
  TYPE_HIPAA_CONSENT_GRANTED,
  TYPE_HIPAA_CONSENT_DECLINED,
  TYPE_HIPAA_CONSENT_PENDING,
  TYPE_RHINOPAY_CONSENT_PENDING,
  TYPE_RHINOPAY_CONSENT_DECLINED,
  TYPE_FACEBOOK,
  TYPE_RHINOGRAM,
} from '../constants/Types';
import {
  MODAL_OPTIONS,
  MESSAGE_DROPDOWN_OPTIONS,
  NOTE_OPTIONS,
  SECURE_DROPDOWN_OPTIONS,
}
  from '../constants/ThreadConstants';
import {
  SENT_TO_SOME,
  SENT_TO_ALL,
} from '../constants/VCardConstants';

import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import * as ThreadReducer from '../reducers/threadReducer';
import * as UIReducer from '../reducers/uiReducer';
import {
  getUserPatientStatus,
  getRhinopayConsentStatus,
  getActiveUser,
} from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { getCanSendRhinopayRequests } from '../selectors/inboxSelectors';
import { fetchMessageTemplates } from '../reducers/messageTemplateReducer';
import {
  useActiveUser,
} from '../hooks';

const MessageComposeActions = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    activeFromChannelId,
    channels,
    isRhinoformEnabled,
    messageTemplates,
    activeConvoTab,
    canSendRhinopayRequests,
    attachments,
    isRhinopayEnabled,
    rhinopayConsentStatusTypeId,
    currentOrganization,
  } = props;
  const activeUser = useActiveUser();
  const uploaderInput = useRef();
  const isInboxThread = location.pathname.includes('inbox');
  const isSecure = isInboxThread && activeConvoTab === 'secure';
  const isMessage = isInboxThread && activeConvoTab === 'patient';
  const isNote = isInboxThread && activeConvoTab === 'note';
  const isHipaaConsentGranted = isInboxThread && activeUser.hipaaStatus?.typeId === TYPE_HIPAA_CONSENT_GRANTED;
  const isHipaaConsentDeclined = isInboxThread && activeUser.hipaaStatus?.typeId === TYPE_HIPAA_CONSENT_DECLINED;
  const isHipaaConsentPending = isInboxThread && activeUser.hipaaStatus?.typeId === TYPE_HIPAA_CONSENT_PENDING;
  const isRhinopayConsentDeclined = rhinopayConsentStatusTypeId === TYPE_RHINOPAY_CONSENT_DECLINED;
  const isRhinopayConsentPending = rhinopayConsentStatusTypeId === TYPE_RHINOPAY_CONSENT_PENDING;

  const { vCards, vCardStatus } = activeUser?.vCard ?? {};
  const isVCardSentToSome = isInboxThread && vCardStatus === SENT_TO_SOME;
  const isVCardSentToAll = isInboxThread && vCardStatus === SENT_TO_ALL;

  const standard = isStandardSize();

  useEffect(() => {
    if (isMessage) dispatch(fetchMessageTemplates('systemAndContact'));
  }, [activeConvoTab]);

  const handleAddFileClick = () => {
    uploaderInput.current.click();
  };

  const getSupportedFileTypes = () => {
    if (isStandardSize()) {
      return AppConstants.UPLOAD_FILE_TYPES;
    }
    return AppConstants.UPLOAD_INTERNAL_FILE_TYPES;
  };

  function handleUseRequestHipaaTemplateClick() {
    const messageToAdd = messageTemplates.find((template) => template.subject === 'HIPAA Consent Request')?.message;
    handleMessageTextAppend(messageToAdd);
  }
  function handleUseRequestRhinopayTemplateClick() {
    const messageToAdd = messageTemplates.find((template) => template.subject === 'RhinoPay Consent Request')?.message;
    handleMessageTextAppend(messageToAdd);
  }

  function handleMessageTextAppend(templateMessageText) {
    dispatch(ThreadReducer.setTemplateToBeAdded(templateMessageText));
  }

  function isStandardSize() {
    return !isInboxThread || (activeFromChannelId && channels[activeFromChannelId].typeId === TYPE_RHINOGRAM) ||
      (activeFromChannelId && channels[activeFromChannelId].typeId === TYPE_FACEBOOK) || activeConvoTab !== 'patient';
  }

  function handleUseTemplateClick() {
    dispatch(ThreadReducer.toggleOrganizationMessageTemplateModal());
  }

  const getFileSizeLimitText = () => {
    if (activeFromChannelId && channels[activeFromChannelId].typeId === TYPE_FACEBOOK) {
      return 'Add File (must be 25 MB or less)';
    } else if (!isInboxThread || activeConvoTab !== 'patient' || (activeFromChannelId && channels[activeFromChannelId].typeId === TYPE_RHINOGRAM)) {
      return 'Add File (must be 5 GB or less)';
    } else return 'Attach Files';
  };

  const fileSizeText = getFileSizeLimitText();

  function handleUploadComplete(newState) {
    const newAttachment = newState.attachments
      .filter((key) => !Object.keys(attachments).includes(key))
      .pop();
    if (newAttachment?.isPostProcessingNeeded) {
      dispatch(UIReducer.setFileUploadPostProcessingInProgress(newAttachment));
    }
    dispatch(ThreadReducer.handleAddFile(newAttachment));
  }

  const handleAddFile = (event) => {
    dispatch(ThreadReducer.setActiveUpload(true));

    const handleProgressDisplay = (progressText) => {
      dispatch(ThreadReducer.setFileProgress(progressText));
    };

    const uploadOpts = {
      file: event.target.files[0],
      currentAttachments: [...attachments],
      progressCallback: handleProgressDisplay,
      supportAllFileTypes: getSupportedFileTypes() === '*',
      ...isInboxThread && {
        secureMode: isSecure,
        facebookChannelSelected: channels[activeFromChannelId]?.typeId === TYPE_FACEBOOK,
        orgId: activeUser.organizationId,
        standard,
      },
    };

    AttachmentHelpers.handleAddFile(uploadOpts, handleUploadComplete);
  };

  const iconClass = 'convo__message__icon-button';

  const hipaaIconClass = cx('', {
    'convo__message__icon-button consent-pending': isHipaaConsentPending,
    'convo__message__icon-button consent-declined': isHipaaConsentDeclined,
  });

  const rhinpayConsentIconClass = cx('', {
    'convo__message__icon-button consent-pending': isRhinopayConsentPending,
    'convo__message__icon-button consent-declined': isRhinopayConsentDeclined,
  });

  const vCardIconClasses = cx('convo__message__icon-button', {
    'consent-pending': isVCardSentToSome,
    'consent-granted': isVCardSentToAll,
  });

  const renderVCards = (vCard) => (
    <UtilityList space className="u-m-b-0">
      <UtilityListItem key={vCard.id}>
        {vCard.name}
      </UtilityListItem>
    </UtilityList>
  );

  const dropdownOptions = {
    hipaa: isInboxThread && !isHipaaConsentGranted && (
      <Button
        key="hipaa"
        title="Use HIPAA consent request template"
        className={hipaaIconClass}
        onClick={handleUseRequestHipaaTemplateClick}
        reset
        data-feature-tag="messaging-use_hipaa_template"
      >
        <Icon icon="hipaa" />
      </Button>
    ),
    rhinopay: isInboxThread && isRhinopayEnabled && (isRhinopayConsentPending || isRhinopayConsentDeclined) && (
      <Button
        key="rhinopay"
        title="Use RhinoPay consent request template"
        className={rhinpayConsentIconClass}
        onClick={handleUseRequestRhinopayTemplateClick}
        reset
        data-feature-tag="messaging-use_rhinopay_template"
      >
        <Icon icon="rhinopay" />
      </Button>
    ),
    template:
      (
        <Button
          key="template"
          title="Use Template"
          className={iconClass}
          onClick={handleUseTemplateClick}
          reset
          data-feature-tag="messaging-use_template"
        >
          <Icon icon="chat" />
        </Button>
      ),
    file: (
      // TODO: remove for 3.13 release and style
      <div key="file" style={{ position: 'relative' }}>
        <Button
          key="file"
          title={fileSizeText}
          className={iconClass}
          onClick={handleAddFileClick}
          reset
          data-feature-tag="messaging-add-file"
        >
          <Icon icon="attachment" />
        </Button>
      </div>
    ),
    form: isInboxThread && isRhinoformEnabled && userHasAnyOfPermissions([FORM_VIEW]) && (
      <Button
        key="form"
        title="Send Form"
        className={iconClass}
        onClick={() => dispatch(ThreadReducer.toggleModal(MODAL_OPTIONS.formLibrary))}
        reset
        data-feature-tag="messaging-add-form"
      >
        <Icon icon="signature" />
      </Button>
    ),
    rhinopayRequest: isInboxThread && canSendRhinopayRequests && (
      <Button
        key="rhinopayRequest"
        title="Request New Payment"
        className={iconClass}
        onClick={() => dispatch(ThreadReducer.toggleModal(MODAL_OPTIONS.rhinopay))}
        reset
        data-feature-tag="messaging-add_payment"
      >
        <Icon icon="rhinopay" />
      </Button>
    ),
    vCard: currentOrganization?.isOfficeVCardEnabled && (
      <Button
        key="vCard"
        title="Attach VCard"
        className={vCardIconClasses}
        onClick={() => dispatch(ThreadReducer.toggleModal(MODAL_OPTIONS.vCardModal))}
        reset
        data-feature-tag="messaging-attach-vCard"
      >
        {vCards?.length > 0 ? (
          <Tooltip placement="top" content={vCards ? vCards?.map(renderVCards) : ''}>
            <Icon className="vcard-icon-size" icon="vcard" />
          </Tooltip>
        ) : (<Icon className="vcard-icon-size" icon="vcard" />)}

      </Button>
    ),
  };
  const renderOption = (id) => dropdownOptions[id];

  const getOptions = () => {
    if (isNote || !isInboxThread) return NOTE_OPTIONS;
    if (isSecure) return SECURE_DROPDOWN_OPTIONS;
    return MESSAGE_DROPDOWN_OPTIONS;
  };
  const renderOptions = getOptions();

  return (
    <div className="u-flex u-flex-align-items-center">
      {renderOptions.map(renderOption)}
      <input
        className="u-sr-only"
        type="file"
        ref={uploaderInput}
        onChange={(e) => handleAddFile(e, standard)}
        onClick={() => { uploaderInput.current.value = null; }}
        {...isMessage && { accept: getSupportedFileTypes() }}
      />
    </div>
  );
};

MessageComposeActions.propTypes = {
  activeFromChannelId: PropTypes.number,
  activeUser: PropTypes.object,
  channels: PropTypes.object,
  isRhinoformEnabled: PropTypes.bool,
  messageText: PropTypes.string,
  messageTemplates: PropTypes.array,
  activeConvoTab: PropTypes.string,
  isPatient: PropTypes.bool,
  canSendRhinopayRequests: PropTypes.bool,
  attachments: PropTypes.array,
  isRhinopayEnabled: PropTypes.bool,
  rhinopayConsentStatusTypeId: PropTypes.number,
  currentOrganization: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    channel,
    form,
    inbox,
    messageTemplate,
    pay,
  } = state;
  return {
    activeChannelIds: getActiveChannelIds(state),
    activeConvoTab: state.thread.activeConvoTab,
    activeFromChannelId: inbox.activeFromChannelId,
    activeUser: getActiveUser(state),
    channels: channel.channels,
    isPatient: getUserPatientStatus(state),
    isRhinoformEnabled: !!form.org?.isRhinoformEnabled,
    messageTemplates: messageTemplate.messageTemplates,
    messageText: inbox.messageText,
    isRhinopayEnabled: pay.merchant.isRhinopayEnabled,
    rhinopayConsentStatusTypeId: getRhinopayConsentStatus(state),
    canSendRhinopayRequests: getCanSendRhinopayRequests(state),
    attachments: state.thread.attachments,
    currentOrganization: getCurrentOrg(state),
  };
};

export default connect(mapStateToProps)(MessageComposeActions);
