// Inbox context
export const INBOX_CONTEXT_DIRECT = 'direct';
export const INBOX_CONTEXT_GROUP = 'group';
export const INBOX_CONTEXT_ASSIGNED = 'assigned';
export const INBOX_CONTEXT_FOLLOWING = 'following';
export const INBOX_CONTEXT_ALL = 'all';
export const INBOX_CONTEXT_MENTIONS = 'mentions';

export const PANEL_OPTIONS = {
  profile: 'profile',
  search: 'search',
  assign: 'assign',
  filter: 'filter',
  forms: 'forms',
  savedContent: 'savedContent',
  library: 'contactLibrary',
  suggestedRoute: 'suggestedRoute',
};

export const MODAL_OPTIONS = {
  rhinopay: 'isRhinopayModalOpen',
  videoLimit: 'isLimitReachedModalOpen',
  browserNotSupported: 'isBrowserNotSupportedModalOpen',
  formLibrary: 'isFormLibraryModalOpen',
  audioAccess: 'isAudioAccessRequiredModalOpen',
  downloadApp: 'isDownloadAppModalOpen',
  templateLibrary: 'organizationMessageTemplateModalOpen',
  vCardModal: 'isVCardModalOpen',
};

// This is dumb. Find a better way to do this
export const NOTE_OPTIONS = ['template', 'file'];
export const SECURE_DROPDOWN_OPTIONS = [...NOTE_OPTIONS, 'form', 'rhinopayRequest', 'vCard'];
export const MESSAGE_DROPDOWN_OPTIONS = ['hipaa', 'rhinopay', ...SECURE_DROPDOWN_OPTIONS];
