import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import * as AuditLogReducer from '../reducers/auditLogReducer';
import AuditLog from '../components/AuditLog';
import { getLoggedInUser, getLoggedInUserOrganization } from '../selectors/userSelectors';
import { DateHelpers, AuditLogHelpers, DataHelpers, UserHelpers } from '../helpers';
import { AUDIT_LOGS_CATEGORIES, DEFAULT_DATE_FILTER, AUDIT_LOG_HEADERS_CONFIG } from '../constants/AuditLogConstants';
import { AUDIT_LOG_PAGE_SIZE } from '../constants/AppConstants';
// import { cloneDeep } from '../helpers/DataHelpers';

class AuditLogContainer extends Component {
  minDate = moment().subtract(1, 'years').startOf('day');
  maxDate = moment();

  static initialState = {
    pageNumber: 0,
    pageItemIndexFrom: 0,
    pageItemIndexTo: 0,
    paginationDirection: 'next',
    isPreviousDisabled: true,
    isNextDisabled: false,
    hasMoreData: null,
    sortKey: 'date',
    sortDirection: -1,
    expandButtonText: 'Expand All',
  }

  state = {
    ...AuditLogContainer.initialState,
    auditLogShapedData: [],
    expanded: {},
    expandButtonText: 'Expand All',
    activeKey: DEFAULT_DATE_FILTER,
    isValidDate: true,
    isDateSelected: false,
    startDate: moment().subtract(29, 'days'),
    endDate: moment(),
    datePickerDropdownLabels: [{ id: 1, label: 'Yesterday', duration: 1 }, { id: 2, label: 'Last 7 Days', duration: 6 },
      { id: 3, label: 'Last 30 Days', duration: 29 }, { id: 4, label: 'Last 90 Days', duration: 89 },
      { id: 5, label: 'Last 12 Months', duration: 'year' }, { id: 6, label: 'Custom Date' }],
    // auditLogHeadersConfig: cloneDeep(AUDIT_LOG_HEADERS_CONFIG),
  };

  formattedAuditRequestData = (filterObj) => ({
    organizationId: this.props.userOrganization.id,
    userIds: filterObj.memberIds ? filterObj.memberIds : this.props.selectedMemberIds,
    contactIds: filterObj.contactIds ? filterObj.contactIds : this.props.selectedContactIds,
    category: Object.prototype.hasOwnProperty.call(filterObj, 'category') ? filterObj.category : this.props.selectedCategory,
    action: Object.prototype.hasOwnProperty.call(filterObj, 'action') ? filterObj.action : this.props.selectedAction,
    startDate: filterObj.startDate ? filterObj.startDate : this.state.startDate,
    endDate: filterObj.endDate ? filterObj.endDate : this.state.endDate,
    sortKey: filterObj.sortKey ? filterObj.sortKey : this.state.sortKey,
    sortDirection: filterObj.sortDirection ? filterObj.sortDirection : this.state.sortDirection,
  })

  fetchInitialFilteredRecords = () => {
    this.props.fetchAuditLog(this.formattedAuditRequestData({
      category: '',
      action: '',
      memberIds: [],
      contactIds: [],
      startDate: moment().subtract(29, 'days'),
      endDate: moment(),
      sortKey: 'date',
      sortDirection: -1,
    }));
  }

  componentDidMount() {
    this.props.fetchAuditLogView(this.formattedAuditRequestData({}));
  }

  componentWillUnmount() {
    this.props.clearAllFilters();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { isPreviousDisabled, isNextDisabled } = prevState;

    if (nextProps.auditLogData && !nextProps.isAuditLogLoading) {
      const auditLogShapedData = AuditLogContainer.shapeDataToDisplay(nextProps);
      const { paginationDirection, pageNumber } = prevState;

      let { pageItemIndexFrom, pageItemIndexTo } = prevState;
      pageItemIndexFrom = 1 + pageNumber * AUDIT_LOG_PAGE_SIZE;
      pageItemIndexTo = pageNumber * AUDIT_LOG_PAGE_SIZE + nextProps.auditLogData.length;

      if (DataHelpers.exists(nextProps.hasMoreData) && nextProps.hasMoreData === true) {
        isPreviousDisabled = false;
        isNextDisabled = false;
      } else {
        isNextDisabled = paginationDirection === 'next';
        isPreviousDisabled = paginationDirection === 'previous';
      }
      if (pageNumber <= 0) isPreviousDisabled = true;

      return {
        auditLogShapedData,
        hasMoreData: nextProps.hasMoreData,
        isNextDisabled,
        isPreviousDisabled,
        pageItemIndexFrom,
        pageItemIndexTo,
      };
    }
    // run on loading state
    return {
      isNextDisabled: true,
      isPreviousDisabled: true,
    };
  }

  static shouldDisplayContactName = (category) => (
    category === AUDIT_LOGS_CATEGORIES.CONTACT ||
    category === AUDIT_LOGS_CATEGORIES.CONVERSATION ||
    category === AUDIT_LOGS_CATEGORIES.CONVERSATION_ACTIONS ||
    category === AUDIT_LOGS_CATEGORIES.APPOINTMENT_MANAGER
  );

  resetState() {
    this.setState({ ...AuditLogContainer.initialState });
  }

  selectDate = (date) => {
    if (date.startDate > date.endDate) {
      this.setState({ isValidDate: false });
    } else {
      this.setState({
        activeKey: date.activeKey,
        isValidDate: true,
        isDateSelected: true,
        startDate: date.startDate,
        endDate: date.endDate,
      });
      this.resetState();
      this.props.fetchAuditLog(this.formattedAuditRequestData({ startDate: date.startDate, endDate: date.endDate }));
    }
  };

  static shapeDataToDisplay = (props) => props.auditLogData.map((item) => {
    const { createdAt, user, auditAction, category, contact, patch, currentData, auditSubAction } = item;
    const { staticFieldName, fieldValue } = AuditLogHelpers.shapeDataBasedOnCategory(item);
    const { types, suffixes, prefixes, timeZones, states } = props;
    let isFirstTimeEdit = false;
    let logDetails = [];

    let userName;
    if (user) {
      if (user.firstName && user.lastName) {
        userName = UserHelpers.formatMemberNameWithPrefixAndSuffix(user);
      } else {
        userName = user.lastName;
      }
    }

    let contactName;
    if (contact) {
      if (contact.firstName && contact.lastName) {
        contactName = `${contact.lastName}, ${contact.firstName}`;
      } else {
        contactName = contact.lastName;
      }
    }

    if (!DataHelpers.exists(auditSubAction) && auditAction === 'Edit' && !patch) isFirstTimeEdit = true;
    else logDetails = AuditLogHelpers.shapeAuditLogDetails(item, types, suffixes, prefixes, timeZones, states);

    logDetails = AuditLogHelpers.shapeBackendFieldToRelevantField(logDetails);

    return {
      date: createdAt ? DateHelpers.formatEpochTimestamp(item.createdAt) : '',
      user: userName || '',
      action: auditAction || '',
      category: category || '',
      staticFieldName,
      fieldValue,
      logDetails,
      isFirstTimeEdit,
      currentData,
      createdAt,
      contactName: contactName || '',
    };
  });

  expandAllRows = (rows) => {
    const { expanded } = this.state;
    let { expandButtonText } = this.state;

    rows.data.forEach((row) => (expanded[row._index] = true));
    expandButtonText = 'Collapse All';
    this.setState({ expanded, expandButtonText });
  }

  collapseAllRows = () => {
    const { expanded } = this.state;
    let { expandButtonText } = this.state;

    Object.keys(expanded).forEach((key) => (expanded[key] = false));
    expandButtonText = 'Expand All';
    this.setState({ expanded, expandButtonText });
  }

  expandAll = (rows) => {
    const { expanded, expandButtonText } = this.state;
    const { auditLogData } = this.props;
    const expandedRowsNumber = Object.keys(expanded).filter((key) => expanded[key]);

    if (!expandedRowsNumber.length) {
      this.expandAllRows(rows);
    } else if (expandedRowsNumber.length < auditLogData.length) {
      if (expandButtonText === 'Expand All') {
        this.expandAllRows(rows);
      } else {
        this.collapseAllRows();
      }
    } else {
      this.collapseAllRows();
    }
  }

  expandRow = (row) => {
    const { expanded, auditLogShapedData } = this.state;
    let { expandButtonText } = this.state;

    if (expanded[row.viewIndex]) {
      expanded[row.viewIndex] = !expanded[row.viewIndex];
      const isExpandedRowAvailable = Object.keys(expanded).filter((key) => expanded[key]);
      expandButtonText = !isExpandedRowAvailable.length ? 'Expand All' : expandButtonText;
    } else {
      expanded[row.viewIndex] = true;
      const expandedRows = Object.keys(expanded).filter((key) => expanded[key]);
      expandButtonText = auditLogShapedData.length === expandedRows.length ? 'Collapse All' : expandButtonText;
    }

    this.setState({ expanded, expandButtonText });
  }

  handleSort = (state) => {
    const { sorted } = state;

    if (sorted && sorted.length > 0) {
      const sortKey = sorted[0].id;

      let sortDirection;

      if (this.state.sortKey !== sortKey) {
        sortDirection = 1;
      } else {
        sortDirection = this.state.sortDirection === 1 ? -1 : 1;
      }

      this.resetState();
      this.props.fetchAuditLog(this.formattedAuditRequestData({ sortKey, sortDirection }));
      this.setState({ sortKey, sortDirection });
    }
  }

  handlePagination = (paginationDirection) => {
    let pageNumber = 0;

    if (paginationDirection === 'next') {
      pageNumber = this.state.pageNumber + 1;
      this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));
    } else if (paginationDirection === 'previous') {
      pageNumber = this.state.pageNumber - 1;
      this.setState((state) => ({ pageNumber: state.pageNumber - 1 }));
    }

    this.props.fetchAuditLog(this.formattedAuditRequestData({}), pageNumber);
    this.setState({ paginationDirection });
  }

  formatData = (selectedIds, selectedItems) => (
    {
      selectedIds,
      selectedItems,
    }
  )

  handleUpdateSelectedIds = (selectedIds, selectedUsers, filterName) => {
    this.resetState();
    if (filterName === 'contacts') {
      this.props.setSelectedContacts(this.formatData([...selectedIds].sort(this.sortSelectedItems(selectedUsers)), selectedUsers));
      this.props.fetchAuditLog(this.formattedAuditRequestData({ contactIds: selectedIds }));
    } else if (filterName === 'members') {
      this.props.setSelectedMembers(this.formatData([...selectedIds].sort(this.sortSelectedItems(selectedUsers)), selectedUsers));
      this.props.fetchAuditLog(this.formattedAuditRequestData({ memberIds: selectedIds }));
    }
  }

  fetchFilterData = (searchText, filterName) => {
    this.props.fetchFilterData(searchText, filterName);
  }

  sortSelectedItems = (usersArray) => function sortItems(a, b) {
    const nameA = usersArray[a].firstName ? usersArray[a].firstName.toLowerCase() : usersArray[a].lastName.toLowerCase();
    const nameB = usersArray[b].firstName ? usersArray[b].firstName.toLowerCase() : usersArray[b].lastName.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  };

  handleChange = (name, value) => {
    if (name === 'auditCategories') {
      this.resetState();
      this.props.setSelectedCategories(value);
      this.props.fetchAuditLog(this.formattedAuditRequestData({ category: value }));
    }

    if (name === 'auditActions') {
      this.resetState();
      this.props.setSelectedActions(value);
      this.props.fetchAuditLog(this.formattedAuditRequestData({ action: value }));
    }
  }

  handleClearSelectedMembers = () => {
    this.resetState();
    this.props.handleClearSelectedMembers();
    this.props.fetchAuditLog(this.formattedAuditRequestData({ memberIds: [] }));
  }

  handleClearSelectedContacts = () => {
    this.resetState();
    this.props.handleClearSelectedContacts();
    this.props.fetchAuditLog(this.formattedAuditRequestData({ contactIds: [] }));
  }

  clearAllFilters = () => {
    this.setState({ isDateSelected: false,
      activeKey: DEFAULT_DATE_FILTER,
      startDate: moment().subtract(29, 'days'),
      // auditLogHeadersConfig: cloneDeep(AUDIT_LOG_HEADERS_CONFIG),
      endDate: moment(),
      ...AuditLogContainer.initialState });
    this.props.clearAllFilters();
    this.fetchInitialFilteredRecords();
  }

  calculateDateRange = (duration, selectedLabelIndex) => {
    let fromDate = moment().subtract(duration, 'days');
    let toDate = moment();

    if (selectedLabelIndex === 1) {
      toDate = moment().subtract(1, 'days');
    }
    if (duration === 'year') {
      fromDate = moment().subtract(1, 'years').add(1, 'days');
    }
    return { startDate: fromDate, endDate: toDate };
  }

  render() {
    const props = {
      actions: this.props.actions,
      activeKey: this.state.activeKey,
      auditLogData: this.state.auditLogShapedData,
      calculateDateRange: this.calculateDateRange,
      categories: this.props.categories,
      clearAllFilters: this.clearAllFilters,
      contacts: this.props.contacts,
      contactsIds: this.props.contactsIds,
      datePickerDropdownLabels: this.state.datePickerDropdownLabels,
      endDate: this.state.endDate,
      expandAll: this.expandAll,
      expandButtonText: this.state.expandButtonText,
      expanded: this.state.expanded,
      expandRow: this.expandRow,
      fetchFilterData: this.fetchFilterData,
      handleChange: this.handleChange,
      handleClearSelectedContacts: this.handleClearSelectedContacts,
      handleClearSelectedMembers: this.handleClearSelectedMembers,
      handlePagination: this.handlePagination,
      handleSort: this.handleSort,
      handleUpdateSelectedIds: this.handleUpdateSelectedIds,
      isAuditLogLoading: this.props.isAuditLogLoading,
      isDateSelected: this.state.isDateSelected,
      isNextDisabled: this.state.isNextDisabled,
      isPreviousDisabled: this.state.isPreviousDisabled,
      isValidDate: this.state.isValidDate,
      maxDate: this.maxDate,
      members: this.props.members,
      membersIds: this.props.membersIds,
      minDate: this.minDate,
      pageItemIndexFrom: this.state.pageItemIndexFrom,
      pageItemIndexTo: this.state.pageItemIndexTo,
      selectDate: this.selectDate,
      selectedAction: this.props.selectedAction,
      selectedCategory: this.props.selectedCategory,
      selectedContactIds: this.props.selectedContactIds,
      selectedContacts: { ...this.props.selectedContacts },
      selectedMemberIds: this.props.selectedMemberIds,
      selectedMembers: { ...this.props.selectedMembers },
      sortDirection: this.state.sortDirection,
      auditLogHeadersConfig: AUDIT_LOG_HEADERS_CONFIG,
      startDate: this.state.startDate,
    };

    return <AuditLog {...props} />;
  }
}

AuditLogContainer.propTypes = {
  actions: PropTypes.array.isRequired,
  auditLogData: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
  contacts: PropTypes.object.isRequired,
  contactsIds: PropTypes.array.isRequired,
  fetchAuditLog: PropTypes.func.isRequired,
  fetchAuditLogView: PropTypes.func.isRequired,
  fetchFilterData: PropTypes.func.isRequired,
  handleClearSelectedContacts: PropTypes.func.isRequired,
  handleClearSelectedMembers: PropTypes.func.isRequired,
  isAuditLogLoading: PropTypes.bool.isRequired,
  members: PropTypes.object.isRequired,
  membersIds: PropTypes.array.isRequired,
  selectedAction: PropTypes.string.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  selectedContactIds: PropTypes.array.isRequired,
  selectedContacts: PropTypes.object.isRequired,
  selectedMemberIds: PropTypes.array.isRequired,
  selectedMembers: PropTypes.object.isRequired,
  setSelectedActions: PropTypes.func.isRequired,
  setSelectedCategories: PropTypes.func.isRequired,
  setSelectedContacts: PropTypes.func.isRequired,
  setSelectedMembers: PropTypes.func.isRequired,
  types: PropTypes.object,
  user: PropTypes.object.isRequired,
  userOrganization: PropTypes.object.isRequired,
  hasMoreData: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { auditLog, type, suffix, prefix, timeZone, states } = state;

  return {
    actions: auditLog.actions,
    auditLogData: auditLog.auditLogData,
    categories: auditLog.categories,
    contacts: auditLog.contacts,
    contactsIds: auditLog.contactsIds,
    hasMoreData: auditLog.hasMoreData,
    isAuditLogLoading: auditLog.loading,
    members: auditLog.members,
    membersIds: auditLog.membersIds,
    prefixes: prefix.prefixes,
    selectedAction: auditLog.selectedAction,
    selectedCategory: auditLog.selectedCategory,
    selectedContactIds: auditLog.selectedContactIds,
    selectedContacts: auditLog.selectedContacts,
    selectedMemberIds: auditLog.selectedMemberIds,
    selectedMembers: auditLog.selectedMembers,
    states: states.states,
    suffixes: suffix.suffixes,
    timeZones: timeZone.timeZones,
    types: type.types,
    user: getLoggedInUser(state),
    userOrganization: getLoggedInUserOrganization(state),
  };
};

const actions = {
  fetchAuditLog: AuditLogReducer.fetchAuditLog,
  setSelectedContacts: AuditLogReducer.setSelectedContacts,
  setSelectedMembers: AuditLogReducer.setSelectedMembers,
  setSelectedCategories: AuditLogReducer.setSelectedCategories,
  setSelectedActions: AuditLogReducer.setSelectedActions,
  fetchFilterData: AuditLogReducer.fetchFilterData,
  handleClearSelectedContacts: AuditLogReducer.handleClearSelectedContacts,
  handleClearSelectedMembers: AuditLogReducer.handleClearSelectedMembers,
  clearAllFilters: AuditLogReducer.handleClearAllFilters,
  fetchAuditLogView: AuditLogReducer.fetchAuditLogView,
};

export default connect(mapStateToProps, actions)(AuditLogContainer);
