import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { AppConstants } from '../constants';

const CharacterCount = (props) => {
  const { length, additionalClass } = props;
  const classes = cx('character-count', {
    'character-count--warning': length >= AppConstants.SMS_MESSAGE_LENGTH_MAX,
  });

  return (
    <div
      title={AppConstants.SMS_MESSAGE_LENGTH_WARNING}
      className={`${classes} ${additionalClass}`}
    >
      {length}
    </div>
  );
};

CharacterCount.propTypes = {
  length: PropTypes.number.isRequired,
  additionalClass: PropTypes.string,
};

export default CharacterCount;
