export const creditCard = {
  creditCard: true,
};

export const zipCode = {
  numericOnly: true,
  blocks: [5, 4],
  delimiter: '-',
  delimiterLazyShow: true,
};

export const phone = {
  numericOnly: true,
  blocks: [0, 3, 0, 3, 4],
  delimiters: ['(', ')', ' ', '-'],
};

export const invalidPhone = {
  numericOnly: true,
};
