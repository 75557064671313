import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  DropdownCheckbox,
  ResourceGroup,
  Scrollbars,
  Dropdown,
  DropdownMenuItem,
  Modal,
  ModalBody,
  UtilityInlineGrid,
  Icon,
} from 'rhinostyle';
import { GroupHelpers } from '../helpers';
import { Types } from '../constants';
import { THREAD_SIZE } from '../constants/AppConstants';
import LazyLoader from './LazyLoader';
import PageLoader from './PageLoader';
import EmptyMessage from './EmptyMessage';
import UserSearchModalContainer from '../containers/UserSearchModalContainer';
import ContactAssignPanel from './ContactAssignPanel';
import BulkMessageModal from './BulkMessageModal';
import InboxEvent from './InboxEvent';
import PaginationControls from './PaginationControls';

const Inbox = (props) => {
  const location = useLocation();
  const history = useHistory();

  const {
    activeKey,
    bulkActionsList,
    bulkActiveFromChannelId,
    bulkSelectionList,
    checkboxClassName,
    clearSelectedEvents,
    currentPageNumber,
    events,
    formInProgress,
    handleAssignClick,
    handleCheckboxOnBulkMessagingEvents,
    handleCheckboxOnItem,
    handleCloseModal,
    handleInboxItemClick,
    handlePagination,
    handleToggle,
    handleUserSearchScopedAction,
    inboxBodyRef,
    inboxContext,
    inboxEventIds,
    inboxLoading,
    inboxName,
    isAllSelectedInboxFollowed,
    isBulkMessagingModalOpen,
    isCcr,
    isChecked,
    isModalOpen,
    labelValueAssociated,
    olderInboxEventIds,
    onChange,
    onScroll,
    onSelect,
    onSelectAction,
    pageLoading,
    selectedInboxEventIds,
    selectedInboxEvents,
    shouldShowStartConversationAction,
    todayInboxEventIds,
    totalInboxCount,
    updateFollowing,
    yesterdayInboxEventIds,
    sort,
    handleSorting,
  } = props;

  const eventsList = Object.values(events);
  const getInboxName = () => {
    if (inboxName === 'Assigned') {
      return 'Assigned to Me';
    } else if (inboxName === 'Following') {
      return inboxName;
    }

    return `${GroupHelpers.formatGroupType(Types.TYPE_GROUP_INBOX)} - ${inboxName}`;
  };

  const emptyStateSection = () => {
    if (!['Assigned', 'Following', 'Direct', 'Inbox'].includes(inboxName)) {
      return 'Patient Group';
    }

    return `Patient ${inboxName}`;
  };

  const renderInboxEvent = (id) => (
    <InboxEvent
      key={id}
      eventId={id}
      handleCheckboxOnItem={handleCheckboxOnItem}
      handleInboxItemClick={handleInboxItemClick}
      isChecked={selectedInboxEventIds.includes(id)}
      onSelect={onSelect}
      updateFollowing={updateFollowing}
      inboxContext={inboxContext}
    />
  );

  const renderInboxList = (eventIds) => (
    <ResourceGroup className="resource-group--inbox">
      {eventIds.map(renderInboxEvent)}
    </ResourceGroup>
  );

  const isPaginationNavigationVisible = (separator) => {
    const hasTodayEvents = todayInboxEventIds.length > 0;
    const hasYesterdayEvents = yesterdayInboxEventIds.length > 0;
    const hasOlderEvents = olderInboxEventIds.length > 0;

    if (sort === 'descending') {
      return (separator === 'Today' && hasTodayEvents) ||
             (separator === 'Yesterday' && !hasTodayEvents && hasYesterdayEvents) ||
             (separator === 'Older' && !hasTodayEvents && !hasYesterdayEvents && hasOlderEvents);
    } else {
      return (separator === 'Older' && hasOlderEvents) ||
             (separator === 'Yesterday' && !hasOlderEvents && hasYesterdayEvents) ||
             (separator === 'Today' && !hasOlderEvents && !hasYesterdayEvents && hasTodayEvents);
    }
  };

  const renderPaginationCarets = (isTop) => (
    <PaginationControls
      pageNumber={currentPageNumber - 1}
      totalCount={totalInboxCount}
      pageSize={THREAD_SIZE}
      handlePagination={handlePagination}
      disabled={inboxLoading}
      isTop={isTop}
      isInbox
    />
  );
  const activeCaretClassName = 'pagination__caret--disabled';
  const caretUpClass = sort === 'descending' ? activeCaretClassName : ''; // descending
  const caretDownClass = sort === 'ascending' ? activeCaretClassName : ''; // ascending
  const renderSeparator = (separator) => {
    if (isPaginationNavigationVisible(separator)) {
      return (
        <div className="inbox-pagination">
          <UtilityInlineGrid className="u-flex u-text-small">
            {renderPaginationCarets(true)}
          </UtilityInlineGrid>
          <UtilityInlineGrid align="right" className="u-text-small u-m-t">
            <div className="inbox-pagination__title">{separator}</div>
            <div className="u-text-muted" size="small">Date/Time</div>
            <div className="u-flex u-wrap-text u-p-a-small u-text-small u-m-r" onClick={() => handleSorting(sort)}>
              <span className="u-flex u-flex-direction-column">
                <Icon icon="caret-up" className={caretUpClass} />
                <Icon icon="caret-down" className={caretDownClass} />
              </span>
            </div>
          </UtilityInlineGrid>
        </div>
      );
    }
    return (
      <UtilityInlineGrid className="u-flex u-flex-justify-center u-m-a u-text-small">
        <div className="u-text-muted">{separator}</div>
      </UtilityInlineGrid>
    );
  };

  const renderEventInboxByDateDescending = () => (
    <>
      {todayInboxEventIds.length > 0 && (
        <div>
          {renderSeparator('Today')}
          {renderInboxList(todayInboxEventIds)}
        </div>
      )}
      {yesterdayInboxEventIds.length > 0 && (
        <div>
          {renderSeparator('Yesterday')}
          {renderInboxList(yesterdayInboxEventIds)}
        </div>
      )}
      {olderInboxEventIds.length > 0 && (
        <div>
          {renderSeparator('Older')}
          {renderInboxList(olderInboxEventIds)}
        </div>
      )}
    </>
  );

  const renderEventInboxByDateAscending = () => (
    <>
      {olderInboxEventIds.length > 0 && (
        <div>
          {renderSeparator('Older')}
          {renderInboxList(olderInboxEventIds)}
        </div>
      )}
      {yesterdayInboxEventIds.length > 0 && (
        <div>
          {renderSeparator('Yesterday')}
          {renderInboxList(yesterdayInboxEventIds)}
        </div>
      )}
      {todayInboxEventIds.length > 0 && (
        <div>
          {renderSeparator('Today')}
          {renderInboxList(todayInboxEventIds)}
        </div>
      )}
    </>
  );

  const renderInboxHeader = () => (
    <div className="app-page__header__title">
      <div className="bulk-action__header__title u-text-overflow">{getInboxName()}</div>
      <div className="app-page__header__action">
        {shouldShowStartConversationAction && (
          <UserSearchModalContainer
            scope="nonMembers"
            handleSearchSelect={handleInboxItemClick}
            handleScopedAction={handleUserSearchScopedAction}
            location={location}
            history={history}
          />
        )}
      </div>
    </div>
  );
  const renderInboxBody = () => {
    if (pageLoading) {
      return <PageLoader />;
    } if (inboxEventIds?.length) {
      return (
        <Scrollbars className="list-panel__body__scroll list-panel__body__scroll--no-border" onScroll={onScroll} ref={inboxBodyRef}>

          {sort === 'descending' ? renderEventInboxByDateDescending() : renderEventInboxByDateAscending()}

          {renderPaginationCarets(false)}
        </Scrollbars>
      );
    }
    return <EmptyMessage section={emptyStateSection()} />;
  };

  const renderSelectAction = () => (
    <Dropdown
      label="Select an Action"
      name="bulkActionArray"
      type="primary"
      position="right"
      noChangeLabel="Assign"
      dataCypress="select-an-action"
      outlined
    >
      {bulkActionsList.map((action) => (
        <DropdownMenuItem
          key={action.id}
          id={action.id}
          label={action.label}
          labelDesc={action?.description}
          disabled={action?.disabled}
          onClick={() => onSelectAction(action)}
        />
      ))}
    </Dropdown>
  );

  const bulkHeaderClassName = (eventsList.length > 0 && !isCcr) ? 'app-page__header' : 'app-page__header no-bulk__header';
  const headerClassName = (eventsList.length > 0 && selectedInboxEventIds.length > 0) ? 'app-page__header bulk-select-header' : bulkHeaderClassName;

  return (
    <div className="app-page bulk-actions">
      <div className="app-panels">
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className={headerClassName}>
              {eventsList.length > 0 && !isCcr && (
                <DropdownCheckbox
                  name="dropdownCheckbox"
                  type="checkbox"
                  isCheckbox
                  isChecked={isChecked}
                  onChange={onChange}
                  labelValueAssociated={labelValueAssociated}
                  showAssociatedLabel
                  checkboxClassName={checkboxClassName}
                  className="dropdown-chekbox"
                  activeKey={activeKey}
                >
                  {bulkSelectionList.map((item) => <DropdownMenuItem key={item.id} id={item.id} label={item.label} onClick={() => onSelect(item)} />)}
                </DropdownCheckbox>
              )}
              {!(selectedInboxEventIds.length > 0) ? renderInboxHeader() : renderSelectAction()}
            </div>
            <div className="list-panel__body">
              {renderInboxBody()}
              <Modal open={isModalOpen}>
                <ModalBody>
                  <div className="bulk-actions__assign__modal">
                    <ContactAssignPanel
                      handleCloseModal={handleCloseModal}
                      location={location}
                      assignFocus
                      history={history}
                      formInProgress={formInProgress}
                      handleAssign={handleAssignClick}
                      followStatus={isAllSelectedInboxFollowed}
                      bulkActiveFromChannelId={bulkActiveFromChannelId}
                      isModalOpen={isModalOpen}
                    />
                  </div>
                </ModalBody>
              </Modal>
              <BulkMessageModal
                contacts={selectedInboxEvents()}
                isModalOpen={isBulkMessagingModalOpen}
                handleToggle={handleToggle}
                handleChange={(user) => handleCheckboxOnBulkMessagingEvents(user.id)}
                clearSelectedContacts={clearSelectedEvents}
                location={location}
              />
              <LazyLoader loading={!pageLoading && inboxLoading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Inbox.propTypes = {
  activeKey: PropTypes.number,
  bulkActionsList: PropTypes.array.isRequired,
  bulkActiveFromChannelId: PropTypes.number,
  bulkSelectionList: PropTypes.array,
  checkboxClassName: PropTypes.string,
  clearSelectedEvents: PropTypes.func,
  currentPageNumber: PropTypes.number.isRequired,
  events: PropTypes.object,
  formInProgress: PropTypes.bool.isRequired,
  handleAssignClick: PropTypes.func.isRequired,
  handleCheckboxOnBulkMessagingEvents: PropTypes.func,
  handleCheckboxOnItem: PropTypes.func,
  handleCloseModal: PropTypes.func,
  handleInboxItemClick: PropTypes.func,
  handlePagination: PropTypes.func.isRequired,
  handleToggle: PropTypes.func,
  handleUserSearchScopedAction: PropTypes.func.isRequired,
  inboxBodyRef: PropTypes.func.isRequired,
  inboxContext: PropTypes.string.isRequired,
  inboxEventIds: PropTypes.array,
  inboxLoading: PropTypes.bool,
  inboxName: PropTypes.string,
  isAllSelectedInboxFollowed: PropTypes.bool,
  isBulkMessagingModalOpen: PropTypes.bool,
  isCcr: PropTypes.bool,
  isChecked: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool,
  labelValueAssociated: PropTypes.string.isRequired,
  olderInboxEventIds: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onScroll: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onSelectAction: PropTypes.func,
  pageLoading: PropTypes.bool,
  selectedInboxEventIds: PropTypes.array,
  selectedInboxEvents: PropTypes.func,
  shouldShowStartConversationAction: PropTypes.bool.isRequired,
  todayInboxEventIds: PropTypes.array,
  totalInboxCount: PropTypes.number.isRequired,
  updateFollowing: PropTypes.func,
  yesterdayInboxEventIds: PropTypes.array,
  sort: PropTypes.string,
  handleSorting: PropTypes.func,
};

export default Inbox;
