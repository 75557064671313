import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Cover,
  CoverBody,
  CoverHeader,
  Icon,
  LoaderCircle,
  UtilityInlineGrid,
} from 'rhinostyle';

const PdfViewer = (props) => {
  const {
    coverTitle,
    handleDownloadClick,
    handleNavClick,
    previousDisabled,
    nextDisabled,
    handlePdfViewerClose,
    pageStatus,
    pdfContainerRef,
    pdfLoading,
    open,
    className,
  } = props;

  const classes = cx('pdf-attachment__cover', className);

  return (
    <Cover open={open} className={classes}>
      <CoverHeader onClose={handlePdfViewerClose} title={coverTitle} />
      <CoverBody className="pdf-attachment__cover__body-container">
        {pdfLoading && (
          <div className="pdf-attachment__cover__loading">
            <LoaderCircle className="u-text-primary" />
          </div>
        )}
        <div className="pdf-attachment__container" ref={pdfContainerRef} />
        {!pdfLoading && (
          <div className="pdf-attachment__cover__toolbar">
            <div className="pdf-attachment__cover__toolbar__left">
              <UtilityInlineGrid>
                <Button type="link" iconOnly disabled={previousDisabled} onClick={() => handleNavClick('previous')}><Icon icon="caret-left" /></Button>
                <Button type="link" iconOnly disabled={nextDisabled} onClick={() => handleNavClick('next')}><Icon icon="caret-right" /></Button>
              </UtilityInlineGrid>
            </div>
            <div className="pdf-attachment__cover__toolbar__center">
              {pageStatus}
            </div>
            <div className="pdf-attachment__cover__toolbar__right">
              <Button type="link" iconOnly className="pdf-attachment__cover__download" onClick={handleDownloadClick}><Icon icon="download" /></Button>
            </div>
          </div>
        )}
      </CoverBody>
    </Cover>
  );
};

PdfViewer.propTypes = {
  coverTitle: PropTypes.string,
  handleDownloadClick: PropTypes.func,
  handleNavClick: PropTypes.func,
  handlePdfViewerClose: PropTypes.func,
  pageStatus: PropTypes.string,
  pdfContainerRef: PropTypes.func.isRequired,
  pdfLoading: PropTypes.bool,
  previousDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default PdfViewer;
