import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import SavedContentPanel from '../components/SavedContentPanel';
import SavedContentPreviewThread from '../components/SavedContentPreviewThread';
import PageLoader from '../components/PageLoader';
import { LocationHelpers } from '../helpers';
import { fetchEventsForSavedContentItem } from '../reducers/savedContentReducer';

const SavedContentPreviewView = (props) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const savedContentThreadBodyRef = useRef();
  const [nextPageNumber, setNextPageNumber] = useState(0);
  const [isDirectLink, setIsDirectLink] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { groups } = props;
  const { canGoBack } = history;

  useEffect(() => {
    if (params.savedContentItemId) {
      setIsLoading(true);
      dispatch(fetchEventsForSavedContentItem(parseInt(params.savedContentItemId, 10), 0)).then((pageNumber) => {
        const queryParams = LocationHelpers.getQueryParams(window.location.search);
        setIsDirectLink(queryParams.isDirectLink === 'true');
        setNextPageNumber(pageNumber);
        setIsLoading(false);
      }).catch(() => history.push('/notfound'));
    }
    return () => {};
  }, [params.savedContentItemId]);

  function handleCloseSavedContent() {
    if (canGoBack) history.goBack();
  }
  if (isLoading) return <PageLoader />;

  return (
    <div className={`app-page ${isDirectLink ? 'saved-content-preview__app-page' : ''}`}>
      <div className={`app-panels saved-content-preview ${isDirectLink ? 'saved-content-preview__thread' : 'saved-content-preview__actions'}`}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <SavedContentPreviewThread
              allowCloseSavedContent={canGoBack}
              allowMobileView
              handleMobileViewActions={() => setIsDirectLink(false)}
              handleCloseSavedContent={handleCloseSavedContent}
              savedContentItemId={parseInt(params.savedContentItemId, 10)}
              nextPageNumber={nextPageNumber}
              savedContentThreadBodyRef={savedContentThreadBodyRef}
              groups={groups}
            />
          </div>
        </div>
        <SavedContentPanel
          allowMobileView
          handleMobileViewSavedContent={() => () => setIsDirectLink(true)}
        />
      </div>
    </div>
  );
};

SavedContentPreviewView.propTypes = {
  groups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { savedContent, group } = state;
  return {
    groups: group.groups,
    activeSavedContentItemId: savedContent.activeSavedContentItemId,
  };
};

export default connect(mapStateToProps)(SavedContentPreviewView);
