import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  UtilityInlineGrid,
} from 'rhinostyle';
import { TYPE_TAG_CUSTOM, TYPE_TAG_DEPARTMENTS, TYPE_TAG_LOCATIONS, TYPE_TAG_ROLES } from '../constants/Types';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { TAG_DELETE } from '../constants/UserPermissionsConstants';

const TagModal = (props) => {
  const {
    closeModal,
    errors,
    formInProgress,
    handleCancelDeleteRequest,
    handleCategoryChange,
    handleChange,
    handleConfirmDeleteRequest,
    handleDeleteRequest,
    handleSave,
    isDeleteRequested,
    isOpen,
    mode,
    resetState,
    tagCategoryId,
    tagName,
  } = props;

  const modalTitle = `${mode === 'create' ? 'Create' : 'Edit'} Tag`;
  const modalSaveType = mode === 'create' ? 'secondary' : 'primary';
  const modalSaveText = `${mode === 'create' ? 'Create' : 'Update'} Tag`;

  const renderModalFooter = () => {
    const isDeleteButtonVisible = !isDeleteRequested && mode === 'edit';

    if (isDeleteRequested) {
      return (
        <div className="item-status__confirm">
          <div className="item-status__confirm__text">
            <Icon bump="up" icon="trash" />&nbsp;Tag association with other items will also be removed. Delete anyway?
          </div>
          <UtilityInlineGrid size="regular" align="middle">
            <Button size="small" type="link-muted" onClick={handleCancelDeleteRequest}>
              Cancel
            </Button>
            <Button loading={formInProgress} size="small" type="danger" onClick={handleConfirmDeleteRequest} data-cypress="confirmDelete">
              Yes, delete tag
            </Button>
          </UtilityInlineGrid>
        </div>
      );
    }

    return (
      <div className="item-status">
        {isDeleteButtonVisible && userHasAnyOfPermissions([TAG_DELETE]) && (
          <div className="item-status__left">
            <Button
              reset
              onClick={handleDeleteRequest}
              className="item-status__delete-icon"
              title="Delete Tag"
            >
              <Icon bump="down" size="large" icon="trash" />
            </Button>
          </div>
        )}
        {!isDeleteRequested && (
          <div className="item-status__right">
            <Button loading={formInProgress} onClick={handleSave} type={modalSaveType} data-cypress={modalSaveText}>{modalSaveText}</Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal onReverseComplete={resetState} open={isOpen}>
      <ModalHeader
        onClose={closeModal}
        title={modalTitle}
      />
      <ModalBody>
        <Input
          label="Tag Name"
          placeholder="(e.g. Billing)"
          name="tagName"
          required
          validationMessage={errors.tagName}
          initialValue={tagName}
          onChange={handleChange}
          explanationMessage="Suggested format: TagName, Tag-Name, Tag_Name"
        />
        <RadioGroup
          label="Tag Category"
          name={`tagCategory-${mode}`}
          onChange={handleCategoryChange}
          selectedValue={tagCategoryId}
        >
          <Radio value={TYPE_TAG_LOCATIONS} label="Location" dataCypress="locationTag" />
          <Radio value={TYPE_TAG_DEPARTMENTS} label="Department" dataCypress="departmentTag" />
          <Radio value={TYPE_TAG_ROLES} label="Role" dataCypress="roleTag" />
          <Radio value={TYPE_TAG_CUSTOM} label="Custom" dataCypress="customTag" />
        </RadioGroup>
      </ModalBody>
      <ModalFooter>
        {renderModalFooter()}
      </ModalFooter>
    </Modal>
  );
};

TagModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleCancelDeleteRequest: PropTypes.func.isRequired,
  handleCategoryChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleConfirmDeleteRequest: PropTypes.func.isRequired,
  handleDeleteRequest: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isDeleteRequested: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  resetState: PropTypes.func.isRequired,
  tagCategoryId: PropTypes.number.isRequired,
  tagName: PropTypes.string.isRequired,
};

export default TagModal;
