import React, { Component } from 'react';
import AuthService from '../services/AuthService';
import PageLoader from './PageLoader';

class AuthCallback extends Component {
  componentDidMount() {
    this.handleAuthentication(this.props);
  }

  handleAuthentication = ({ location }) => {
    const loginRegExp = RegExp('(access_token|id_token)');
    if (loginRegExp.test(location.hash)) {
      AuthService.handleAuthentication(this.props);
    } else {
      AuthService.logout('auth0error');
    }
  };

  render() {
    return (
      <PageLoader />
    );
  }
}

export default AuthCallback;
