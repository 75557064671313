import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dropdown,
  DropdownMenuItem,
  DropdownCheckbox,
  Icon,
  ResourceGroup,
  Scrollbars,
  UtilityInlineGrid,
} from 'rhinostyle';
import * as ChatReducer from '../reducers/chatReducer';
import * as ChannelReducer from '../reducers/channelReducer';
import * as UIReducer from '../reducers/uiReducer';
import ChatInboxEvent from '../components/ChatInboxEvent';
import LazyLoader from '../components/LazyLoader';
import PageLoader from '../components/PageLoader';
import EmptyMessage from '../components/EmptyMessage';
import { BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD, THREAD_SIZE, chatconversationTypeList } from '../constants/AppConstants';
import UserSearchModalContainer from '../containers/UserSearchModalContainer';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getFilteredChatEventIds, getChatEventsOlder, getChatEventsYesterday, getChatEventsToday } from '../selectors/chatSelectors';
import { BulkActionHelpers, GroupHelpers, UserHelpers } from '../helpers';
import { Types } from '../constants';
import { CHANNEL_VIEW } from '../constants/UserPermissionsConstants';

class ChatInboxView extends Component {
  scrollPosition = 'top'; // eslint-disable-line react/sort-comp
  static initialState = {
    isChecked: false,
    labelValueAssociated: '',
    selectedInboxThreadIds: [],
    selectedInboxMentionIds: [],
    selectedIdCount: 0,
    checkboxClassName: '',
    isModalOpen: false,
    formInProgress: false,
    activeKey: 0,
    isAllSelectedInboxFollowed: false,
    pageItemIndexFrom: 0,
    pageItemIndexTo: 0,
  }
  state = {
    ...ChatInboxView.initialState,
    bulkActionsList: [],
    currentPageNumber: 1,
    pageNumber: 0,
    totalPageCount: 0,
    totalInboxCount: 0,
    previousDisabled: true,
    nextDisabled: false,
    contactIds: [],
    selectedInboxThreads: [],
    pageLoading: true,
  }

  componentDidMount() {
    const options = {};
    const locationState = this.props.location && this.props.location.state;
    const selectedIds = locationState && locationState.selectedInboxThreadIds;
    const selectedEvents = locationState && locationState.selectedInboxThreads;
    if (locationState && locationState.chatPageNumber >= 0 && locationState.chatCurrentPageNumber) {
      this.setState({
        currentPageNumber: locationState.chatCurrentPageNumber,
        pageNumber: locationState.chatPageNumber,
        previousDisabled: (locationState.chatPageNumber === 0),
        totalPageCount: locationState.chatTotalPageCount,
        nextDisabled: (locationState.chatPageNumber + 1 >= locationState.chatTotalPageCount),
        selectedInboxThreadIds: [...selectedIds],
        selectedInboxThreads: [...selectedEvents],
        isChecked: locationState.isChatsCheckboxChecked,
        labelValueAssociated: locationState.chatsLabelValueAssociated,
        checkboxClassName: locationState.chatsCheckboxClassName,
        selectedIdCount: locationState.selectedThreadsIdCount,
        bulkActionsList: locationState.chatBulkActionsList,
      });
    }
    options.pageNumber = locationState && locationState.chatPageNumber;
    const updateState = {};
    if (!this.props.location.state) {
      updateState.isChecked = false;
      updateState.selectedInboxThreadIds = [];
      updateState.labelValueAssociated = '';
    }
    this.setState(updateState, () => {
      if (this.props.location.state) {
        this.props.history.replace({ ...this.props.location, state: null });
      }
    });
    this.props.resetEventData();
    this.props.requestChatViewData();
    this.props.fetchChatInbox({});
    if (UserHelpers.hasAnyInboxViewPermissions()) this.props.fetchInboxSections();
    if (UserHelpers.userHasAnyOfPermissions([CHANNEL_VIEW])) this.props.fetchChannels();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.inboxEventIds.length !== this.props.inboxEventIds.length) && this.props.inboxEventIds.length) {
      this.scrollTo();
    }

    if (this.props.pageLoading !== prevState.pageLoading) {
      this.setState({ pageLoading: this.props.pageLoading }); // eslint-disable-line react/no-did-update-set-state
    }

    if (this.props.inboxEventIds.length && this.props.inboxEventIds.length !== prevProps.inboxEventIds.length) {
      let toIndex = (this.props.inboxPageNo * THREAD_SIZE + this.props.inboxEventIds.length);

      if ((this.props.inboxPageNo * THREAD_SIZE + this.props.inboxEventIds.length) > THREAD_SIZE && this.props.inboxPageNo === 0) {
        toIndex = THREAD_SIZE;
      }

      this.setState({ // eslint-disable-line react/no-did-update-set-state
        pageItemIndexFrom: 1 + this.props.inboxPageNo * THREAD_SIZE,
        pageItemIndexTo: toIndex,
      });
    }
    if (this.props.inboxSections?.length || this.props.inboxChatSections?.length) {
      let currentInboxSection = null;
      currentInboxSection = this.props.inboxChatSections.find((inboxSection) => inboxSection.type === 'direct');

      const { totalThreadsCount: totalCount } = currentInboxSection;
      const totalPages = totalCount && Math.ceil(totalCount / THREAD_SIZE);
      let toIndex = (this.props.inboxPageNo * THREAD_SIZE + this.props.inboxEventIds.length);

      if ((this.props.inboxPageNo * THREAD_SIZE + this.props.inboxEventIds.length) > THREAD_SIZE && this.props.inboxPageNo === 0) {
        toIndex = THREAD_SIZE;
      }
      if (totalCount !== this.state.totalInboxCount || this.props.inboxLoading !== prevProps.inboxLoading) {
        this.setState((state, props) => ({ // eslint-disable-line react/no-did-update-set-state
          totalInboxCount: totalCount,
          totalPageCount: totalPages,
          previousDisabled: props.inboxLoading || (state.pageNumber === 0),
          nextDisabled: props.inboxLoading || (state.pageNumber + 1 >= totalPages),
          pageItemIndexFrom: 1 + this.props.inboxPageNo * THREAD_SIZE,
          pageItemIndexTo: toIndex,
        }));
      }
    }
  }

  reloadContainer(pageNumber, currentPageNumber, totalPageCount, totalInboxCount) {
    this.setState({
      ...ChatInboxView.initialState,
      selectedInboxThreadIds: [],
      pageNumber: pageNumber > -1 ? pageNumber : 0,
      currentPageNumber: currentPageNumber > 0 ? currentPageNumber : 1,
      totalPageCount,
      totalInboxCount,
      previousDisabled: (pageNumber === 0),
      nextDisabled: (pageNumber + 1 >= this.state.totalPageCount),
      bulkActionsList: [],
    });
  }

  onScroll = () => {
    const scrollContainer = this.inboxBodyRef.container.firstChild;
    const totalScroll = scrollContainer.scrollTop + scrollContainer.clientHeight;

    if (totalScroll === scrollContainer.scrollHeight) {
      this.scrollPosition = 'bottom';
    }

    if ((totalScroll !== scrollContainer.scrollHeight) && (scrollContainer.scrollTop !== 0)) {
      this.scrollPosition = 'middle';
    }

    if (scrollContainer.scrollTop === 0) {
      this.scrollPosition = 'top';
    }
  }

  scrollTo = () => {
    if (!this.inboxBodyRef) return;
    const scrollContainer = this.inboxBodyRef.container.firstChild;

    if (this.scrollPosition === 'top') {
      scrollContainer.scrollTop = 0;
    }
  }

  handleSearchSelect = (selectedUserId) => {
    this.props.history.push(`/chat/user/${selectedUserId}`);
  }

  isPaginationNavigationVisible = (separator) => {
    if (separator === 'Today' && this.props.todayInboxEventIds.length) return true;
    else if (separator === 'Yesterday' && !this.props.todayInboxEventIds.length) return true;
    else if (separator === 'Older' && !this.props.todayInboxEventIds.length && !this.props.yesterdayInboxEventIds.length) return true;
    return false;
  }

  checkThreadSelection = (inboxThreadIds) => {
    const { selectedInboxThreadIds } = this.state;
    let { checkboxClassName, isChecked } = this.state;
    const selectedEventLength = selectedInboxThreadIds && selectedInboxThreadIds.length;
    const currentSelectedList = selectedEventLength &&
      selectedEventLength > 0 &&
      inboxThreadIds.filter((item) => selectedInboxThreadIds.includes(item));

    if (currentSelectedList && currentSelectedList.length > 0 && currentSelectedList.length === inboxThreadIds.length) {
      isChecked = true;
      checkboxClassName = '';
    } else if (currentSelectedList && currentSelectedList.length > 0 && currentSelectedList.length !== inboxThreadIds.length) {
      isChecked = true;
      checkboxClassName = 'partially-checked';
    } else {
      isChecked = false;
      checkboxClassName = '';
    }
    const checkboxLabel = selectedInboxThreadIds.length > 0 ? `${selectedInboxThreadIds.length} Selected` : '';
    this.setState({
      checkboxClassName,
      isChecked,
      labelValueAssociated: checkboxLabel,
      selectedIdCount: selectedInboxThreadIds.length,
    });
  }

  handlePagination = (direction) => {
    const options = {};
    if (direction === 'previous') {
      if (this.state.pageNumber >= 1) {
        options.pageNumber = this.state.pageNumber - 1;
        this.setState((prevState) => ({ pageNumber: prevState.pageNumber - 1, currentPageNumber: prevState.currentPageNumber - 1, activeKey: 0 }));
        this.props.fetchChatInbox(options).then(() => {
          const { inboxThreadIds } = this.props;
          this.checkThreadSelection(inboxThreadIds);
        });
      }
    } else {
      if (this.state.pageNumber + 1 < this.state.totalPageCount) { // eslint-disable-line no-lonely-if
        options.pageNumber = this.state.pageNumber + 1;
        this.setState((prevState) => ({ pageNumber: prevState.pageNumber + 1, currentPageNumber: prevState.currentPageNumber + 1, activeKey: 0 }));
        this.props.fetchChatInbox(options).then(() => {
          const { inboxThreadIds } = this.props;
          this.checkThreadSelection(inboxThreadIds);
        });
      }
    }
    this.setState({
      previousDisabled: (options.pageNumber === 0),
      nextDisabled: (options.pageNumber + 1 >= this.state.totalPageCount),
    });
  }

  renderPaginationCarets = () => (
    <div>
      <Button
        type="link"
        iconOnly
        disabled={this.state.previousDisabled}
        onClick={() => this.handlePagination('previous')}
        className={this.state.previousDisabled ? 'pagination__caret--disabled' : ''}
      >
        <Icon icon="caret-left" />
      </Button>
      <span><strong>{this.state.currentPageNumber}</strong>{` of ${this.state.totalPageCount}`}</span>
      <Button
        type="link"
        iconOnly
        disabled={this.state.nextDisabled}
        onClick={() => this.handlePagination('next')}
        className={this.state.nextDisabled ? 'pagination__caret--disabled' : ''}
      >
        <Icon icon="caret-right" />
      </Button>
    </div>
  );

  getInboxName = () => `${GroupHelpers.formatGroupType(Types.TYPE_GROUP_CHAT)} - Direct`;

  renderInboxHeader = () => {
    const titleClassName = Object.values(this.props.events).length > 0 ? 'bulk-action__header__title' : '';
    return (
      <div className="app-page__header__title">
        <div className={titleClassName}>{this.getInboxName()}</div>
        <div className="app-page__header__action">
          <UserSearchModalContainer
            excludeCurrentUser
            scope="members"
            location={this.props.location}
            history={this.props.history}
            handleSearchSelect={this.handleSearchSelect}
          />
        </div>
      </div>
    );
  }

  calculateParameters = (pageNumber, currentPageNumber, totalPageCount, totalInboxCount, selectedInboxThreadIds) => {
    const newTotalInboxCount = totalInboxCount - selectedInboxThreadIds.length;
    const newTotalPageCount = newTotalInboxCount && Math.ceil(newTotalInboxCount / THREAD_SIZE);
    let newPageNumber = 0;
    let newCurrentPageNumber = 0;

    if (newTotalInboxCount < totalInboxCount) {
      const updatedPageNumber = pageNumber - (totalPageCount - newTotalPageCount);

      newPageNumber = updatedPageNumber > 0 ? updatedPageNumber : 0;
      newCurrentPageNumber = newPageNumber + 1;
    } else {
      newPageNumber = pageNumber > 1 ? pageNumber - 1 : 0;
      newCurrentPageNumber = currentPageNumber > 2 ? currentPageNumber - 1 : 1;
    }
    return {
      newTotalInboxCount,
      newTotalPageCount,
      newPageNumber,
      newCurrentPageNumber,
    };
  }

  onSelect = (item) => {
    const events = Object.values(this.props.events);
    let { selectedInboxThreadIds, selectedInboxThreads } = this.state;
    const { inboxThreadIds } = this.props;
    const selectedIds = BulkActionHelpers.fetchChatSelectedIds(item.label, events);
    let noneThreadIds = [];
    if (item.label === 'None') {
      noneThreadIds = inboxThreadIds.filter((id) => selectedInboxThreadIds.includes(id));
      selectedInboxThreadIds = selectedInboxThreadIds.filter((id) => !noneThreadIds.includes(id));
      selectedInboxThreads = selectedInboxThreads.filter((event) => !noneThreadIds.includes(event.threadId));
    } else {
      selectedInboxThreadIds = selectedInboxThreadIds.filter((id) => !inboxThreadIds.includes(id));

      const newSelectedList = selectedIds.filter((id) => !selectedInboxThreadIds.includes(id));
      selectedInboxThreadIds = [...selectedInboxThreadIds, ...newSelectedList];

      selectedInboxThreads = selectedInboxThreads.filter((event) => !inboxThreadIds.includes(event.threadId));

      const newSelectedInboxThreads = events.filter((event) => selectedInboxThreadIds.includes(event.threadId));
      selectedInboxThreads = [...selectedInboxThreads, ...newSelectedInboxThreads];
    }
    let { bulkActionsList, activeKey } = this.state;
    let { isChecked } = item;

    bulkActionsList = BulkActionHelpers.fetchChatBulkActions(item.label);

    let { selectedIdCount, labelValueAssociated, checkboxClassName } = this.state;

    selectedIdCount = selectedIds.length;
    labelValueAssociated = `${selectedInboxThreadIds.length} Selected`;
    checkboxClassName = (selectedIdCount !== events.length) ? 'partially-checked' : '';
    activeKey = item.id;
    if (selectedIdCount === 0) {
      checkboxClassName = '';
      isChecked = false;
      activeKey = 0;
      labelValueAssociated = selectedInboxThreadIds.length > 0 ? `${selectedInboxThreadIds.length} Selected` : '';
    }
    this.setState({
      isChecked,
      bulkActionsList,
      selectedInboxThreadIds,
      selectedIdCount,
      labelValueAssociated,
      checkboxClassName,
      activeKey,
      selectedInboxThreads,
    });
  }

  onSelectAction = (action) => {
    const { selectedInboxThreadIds, pageNumber, currentPageNumber, totalPageCount, totalInboxCount } = this.state;
    const options = {};

    if (action.code === 'markAsRead' || action.code === 'markAsUnread') {
      options.pageNumber = pageNumber > -1 ? pageNumber : 0;
      const read = action.code === 'markAsRead';
      const actionType = read ? 'updateBulkRead' : 'updateBulkUnRead';
      this.props.updateChatThreadReadStatus(null, null, read, selectedInboxThreadIds, actionType, options).then(() => {
        this.props.fetchInboxSections();
        this.reloadContainer(pageNumber, currentPageNumber, totalPageCount, totalInboxCount);
      });
    } else if (action.code === 'closeChat') {
      const countObject = this.calculateParameters(pageNumber, currentPageNumber, totalPageCount, totalInboxCount, selectedInboxThreadIds);
      const payload = { threadIds: this.state.selectedInboxThreadIds, conversationActionType: 'closeBulkConversations' };
      options.pageNumber = countObject.newPageNumber > -1 ? countObject.newPageNumber : 0;
      this.props.closeChatConversation(payload, options).then(() => {
        this.props.fetchInboxSections();
        this.reloadContainer(countObject.newPageNumber, countObject.newCurrentPageNumber, countObject.newTotalPageCount, countObject.newTotalInboxCount);
      });
    }
  }

  renderSelectAction = () => (
    <Dropdown label="Select an Action" name="bulkActionArray" type="primary" position="right" noChangeLabel="Assign" outlined>
      {this.state.bulkActionsList.map((action) => (
        <DropdownMenuItem
          key={action.id}
          id={action.id}
          label={action.label}
          labelDesc={action?.description}
          disabled={action?.disabled}
          onClick={() => this.onSelectAction(action)}
        />
      ))}
    </Dropdown>
  );

  handleChatItemHandler = (route, userOrGroupId, threadId) => {
    const {
      currentPageNumber,
      pageNumber,
      selectedInboxThreadIds,
      totalPageCount,
      isChecked,
      labelValueAssociated,
      checkboxClassName,
      selectedIdCount,
      bulkActionsList,
      selectedInboxThreads,
    } = this.state;
    this.props.history.push({
      pathname: `${this.props.location.pathname}/${route}/${userOrGroupId}`,
      state: {
        chatCurrentPageNumber: currentPageNumber,
        chatPageNumber: pageNumber,
        selectedInboxThreadIds,
        chatTotalPageCount: totalPageCount,
        isChatsCheckboxChecked: isChecked,
        chatsLabelValueAssociated: labelValueAssociated,
        chatsCheckboxClassName: checkboxClassName,
        selectedThreadsIdCount: selectedIdCount,
        chatBulkActionsList: bulkActionsList,
        selectedInboxThreads,
        currentThreadId: threadId,
      },
    });
  }

  onChange = () => {
    const { isChecked, selectedInboxThreadIds, contactIds, selectedInboxThreads } = this.state;
    const events = Object.values(this.props.events);
    const { inboxThreadIds } = this.props;
    if (isChecked) {
      const currentSelectedList = inboxThreadIds.filter((item) => selectedInboxThreadIds.includes(item));
      const newSelectedInboxThreadIds = selectedInboxThreadIds.filter((threadId) => !currentSelectedList.includes(threadId));
      const newSelectedInboxThreads = selectedInboxThreads.filter((event) => !currentSelectedList.includes(event.threadId));
      const currentContactIdList = BulkActionHelpers.fetchSelectedPatientUserIds(currentSelectedList, events);
      const newContactIdList = contactIds.filter((id) => !currentContactIdList.includes(id));
      const dropdownCheckLabel = newSelectedInboxThreadIds.length > 0 ? `${newSelectedInboxThreadIds.length} Selected` : '';
      let { bulkActionsList } = this.state;
      bulkActionsList = BulkActionHelpers.fetchChatBulkActions('All');
      this.setState({
        labelValueAssociated: dropdownCheckLabel,
        selectedInboxThreadIds: newSelectedInboxThreadIds,
        selectedIdCount: 0,
        checkboxClassName: '',
        bulkActionsList,
        activeKey: 0,
        contactIds: newContactIdList,
        selectedInboxThreads: newSelectedInboxThreads,
      });
    } else {
      const selectedIds = [...selectedInboxThreadIds, ...inboxThreadIds];
      const selectedEvents = [...selectedInboxThreads, ...events];
      let { bulkActionsList } = this.state;
      bulkActionsList = BulkActionHelpers.fetchChatBulkActions('All');
      this.setState({
        labelValueAssociated: `${selectedIds.length} Selected`,
        selectedInboxThreadIds: selectedIds,
        selectedIdCount: selectedIds.length,
        bulkActionsList,
        activeKey: 1,
        selectedInboxThreads: selectedEvents,
      });
    }
    this.setState((prevState) => ({ isChecked: !prevState.isChecked }));
  }

  renderThread(eventId) {
    return (
      <ChatInboxEvent
        history={this.props.history}
        eventId={eventId}
        key={eventId}
        handleChatItemHandler={this.handleChatItemHandler}
        handleCheckboxOnItem={this.handleCheckboxOnItem}
        isCcr={this.props.currentUser.isCcr}
        selectedInboxThreadIds={this.state.selectedInboxThreadIds}
      />
    );
  }

  handleCheckboxOnItem = (id) => {
    const selectedIds = this.state.selectedInboxThreadIds;
    let selectedThreads = this.state.selectedInboxThreads;
    let { bulkActionsList } = this.state;
    let { selectedIdCount, checkboxClassName } = this.state;
    const events = Object.values(this.props.events);
    const { inboxThreadIds } = this.props;
    if (!selectedIds.includes(id)) {
      const newSelectedEvent = events.find((event) => event.threadId === id);
      selectedIds.push(id);
      selectedThreads.push(newSelectedEvent);
      selectedIdCount += 1;
    } else {
      const index = selectedIds.indexOf(id);
      selectedThreads = selectedThreads.filter((event) => event.threadId !== id);

      selectedIds.splice(index, 1);

      selectedIdCount -= 1;
    }
    if (selectedIdCount === 0) {
      this.setState({
        isChecked: false,
        labelValueAssociated: selectedIds.length > 0 ? `${selectedIds.length} Selected` : '',
        bulkActionsList: selectedIds.length > 0 ? this.state.bulkActionsList : [],
        checkboxClassName: '',
        activeKey: 0,
      });
    } else {
      const currentPageSelectedThreads = selectedIds.filter((threadId) => inboxThreadIds.includes(threadId));

      checkboxClassName = currentPageSelectedThreads.length !== events.length && currentPageSelectedThreads.length > 0 ? 'partially-checked' : '';

      if (!bulkActionsList.length) {
        bulkActionsList = BulkActionHelpers.fetchChatBulkActions('All');
      }

      const checkboxLabel = selectedIds.length > 0 ? `${selectedIds.length} Selected` : '';

      this.setState({
        labelValueAssociated: checkboxLabel,
        isChecked: currentPageSelectedThreads.length > 0,
        bulkActionsList,
        checkboxClassName,
      });
    }
    this.setState({
      selectedInboxThreadIds: selectedIds,
      selectedInboxThreads: selectedThreads,
      selectedIdCount,
    });
  }

  renderSeparator = (separator) => {
    if (this.isPaginationNavigationVisible(separator)) {
      return (
        <div className="inbox-pagination">
          <UtilityInlineGrid className="u-flex u-flex-justify-between u-text-small">
            <span className="inbox-pagination__count">{`${this.state.pageItemIndexFrom} - ${this.state.pageItemIndexTo} of ${this.state.totalInboxCount}`}</span>
            <div className="inbox-pagination__title">{separator}</div>
            {this.renderPaginationCarets()}
          </UtilityInlineGrid>
        </div>
      );
    }
    return (
      <UtilityInlineGrid className="u-flex u-flex-justify-center u-m-a u-text-small">
        <div className="u-text-muted">{separator}</div>
      </UtilityInlineGrid>
    );
  };

  render() {
    if (this.state.pageLoading) {
      return <PageLoader />;
    }
    const eventsList = Object.values(this.props.events);
    const bulkHeaderClassName = eventsList.length > 0 ? 'app-page__header' : 'app-page__header no-bulk__header';
    const headerClassName = (eventsList.length > 0 && this.state.selectedInboxThreadIds.length > 0) ? 'app-page__header bulk-select-header' : bulkHeaderClassName;

    return (
      <div className="app-page bulk-actions">
        <div className="app-panels">
          <div className="list-panel__wrapper">
            <div className="list-panel">
              <div className={headerClassName}>
                {eventsList.length > 0 && !this.props.currentUser.isCcr && (
                  <DropdownCheckbox
                    name="dropdownCheckbox"
                    type="checkbox"
                    isCheckbox
                    isChecked={this.state.isChecked}
                    onChange={this.onChange}
                    labelValueAssociated={this.state.labelValueAssociated}
                    showAssociatedLabel
                    checkboxClassName={this.state.checkboxClassName}
                    className="dropdown-chekbox"
                    activeKey={this.state.activeKey}
                  >
                    {chatconversationTypeList.map((item) => <DropdownMenuItem key={item.id} id={item.id} label={item.label} onClick={() => this.onSelect(item)} />)}
                  </DropdownCheckbox>
                )}
                {!(this.state.selectedInboxThreadIds.length > 0) ? this.renderInboxHeader() : this.renderSelectAction()}
              </div>
              <div className="list-panel__body">
                {this.props.inboxEventIds.length > 0 ? (
                  <Scrollbars
                    className="list-panel__body__scroll list-panel__body__scroll--no-border"
                    onScroll={this.onScroll}
                    ref={(inboxBodyRef) => (this.inboxBodyRef = inboxBodyRef)}
                  >
                    {this.props.todayInboxEventIds.length > 0 && (
                    <div>
                      {this.renderSeparator('Today')}
                      <ResourceGroup topBorder>
                        {this.props.todayInboxEventIds.map((eventId) => this.renderThread(eventId))}
                      </ResourceGroup>
                    </div>
                    )}
                    {this.props.yesterdayInboxEventIds.length > 0 && (
                    <div>
                      {this.renderSeparator('Yesterday')}
                      <ResourceGroup topBorder>
                        {this.props.yesterdayInboxEventIds.map((eventId) => this.renderThread(eventId))}
                      </ResourceGroup>
                    </div>
                    )}
                    {this.props.olderInboxEventIds.length > 0 && (
                    <div>
                      {this.renderSeparator('Older')}
                      <ResourceGroup topBorder>
                        {this.props.olderInboxEventIds.map((eventId) => this.renderThread(eventId))}
                      </ResourceGroup>
                    </div>
                    )}
                    {this.props.inboxEventIds.length >= BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD && (
                    <UtilityInlineGrid className="u-flex u-flex-justify-between u-m-t-small u-text-small">
                      <span>{`${this.state.pageItemIndexFrom} - ${this.state.pageItemIndexTo} of ${this.state.totalInboxCount}`}</span>
                      {this.renderPaginationCarets()}
                    </UtilityInlineGrid>
                    )}
                  </Scrollbars>
                ) : (
                  <EmptyMessage section="Team" />
                )}
                <LazyLoader loading={this.props.inboxLoading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChatInboxView.propTypes = {
  resetEventData: PropTypes.func,
  fetchChatInbox: PropTypes.func,
  inboxEventIds: PropTypes.array,
  inboxLoading: PropTypes.bool,
  pageLoading: PropTypes.bool,
  inboxPageNo: PropTypes.number,
  todayInboxEventIds: PropTypes.array.isRequired,
  yesterdayInboxEventIds: PropTypes.array.isRequired,
  olderInboxEventIds: PropTypes.array.isRequired,
  requestChatViewData: PropTypes.func,
  history: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  events: PropTypes.object,
  location: PropTypes.object,
  inboxSections: PropTypes.array,
  fetchInboxSections: PropTypes.func.isRequired,
  inboxChatSections: PropTypes.array,
  fetchChannels: PropTypes.func,
  updateChatThreadReadStatus: PropTypes.func,
  inboxThreadIds: PropTypes.array,
  closeChatConversation: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { chat, inbox, ui } = state;

  return {
    currentUser: getLoggedInUser(state),
    inboxEventIds: getFilteredChatEventIds(state),
    inboxLoading: chat.inboxLoading,
    pageLoading: chat.pageLoading,
    inboxSections: ui.inboxSections && ui.inboxSections.inbox ? ui.inboxSections.inbox : inbox.inboxSections,
    inboxChatSections: ui.inboxSections && ui.inboxSections.chat ? ui.inboxSections.chat : inbox.inboxSections,
    todayInboxEventIds: getChatEventsToday(state),
    yesterdayInboxEventIds: getChatEventsYesterday(state),
    olderInboxEventIds: getChatEventsOlder(state),
    inboxPageNo: chat.inboxPageNo,
    events: chat.events,
    inboxThreadIds: chat.inboxThreadIds,
  };
};

const actions = {
  fetchChatInbox: ChatReducer.fetchChatInbox,
  requestChatViewData: ChatReducer.requestChatViewData,
  closeChatConversation: ChatReducer.closeChatConversation,
  fetchInboxSections: UIReducer.fetchInboxSections,
  resetEventData: ChatReducer.resetEventData,
  updateChatThreadReadStatus: ChatReducer.updateChatThreadReadStatus,
  fetchChannels: ChannelReducer.fetchChannels,
};

export default connect(mapStateToProps, actions)(ChatInboxView);
