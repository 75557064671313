import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Avatar,
  Alert,
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  RhinoSwitch,
  Select,
  Textarea,
  UtilityInlineGrid,
  Modal,
  ModalBody,
  ModalHeader,
} from 'rhinostyle';
import EmojiPickerDropdown from './EmojiPickerDropdown';

import BackButtonContainer from '../containers/BackButtonContainer';
import UpdatePasswordFormContainer from '../containers/UpdatePasswordFormContainer';
import PageLoader from './PageLoader';
import TagsGroup from './TagsGroup';
import UploadAvatarModal from './UploadAvatarModal';
import ChannelRoutesReadOnly from './ChannelRoutesReadOnly';
import TimeZoneSelect from './TimeZoneSelect';
import BusinessHours from './BusinessHours';
import CharacterCount from './CharacterCount';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { GROUP_VIEW, ROLE_EDIT, MEMBER_EDIT, RHINOFORMS_ADMIN, INTEGRATED_CONTACTS_EDITOR, LIMITED_PROVIDER_ROLE } from '../constants/UserPermissionsConstants';
// These are some system roles that are not visible to member in Member Profile
import { INTERNAL_ROLES } from '../constants/AppConstants';
import { TYPE_INTEGRATION_AXIUM } from '../constants/Types';
import GroupList from './GroupList';

const OrganizationMemberForm = (props) => {
  const history = useHistory();
  const location = useLocation();
  const textareaRef = useRef();
  const {
    actionTitle,
    actionType,
    addInviteActionTitle,
    afterHoursEnabled,
    autoResponse,
    avatarName,
    businessHours,
    businessTitle,
    channelOptions,
    channels,
    defaultChannelEnabled,
    defaultChannelId,
    errors,
    firstName,
    handleAfterHoursChange,
    handleChange,
    handleDefaultChannelChange,
    handleEnrollMFA,
    handleFormChanges,
    handlePasswordFormToggle,
    handleResetMFA,
    handleSubmit,
    handleToggle,
    handleToggleMFA,
    handleToggleModal,
    handleUpdateSelectedIds,
    handleUpdateSelectedRoles,
    handleUploadAvatar,
    isCcr,
    isMemberAddAndInviteInProgress,
    isMemberAddInProgress,
    isMemberProfileUpdateInProgress,
    isMFAEnabled,
    isMFALoading,
    isMFARequired,
    isMultiOrgEnabled,
    isPasswordFormActive,
    isRhinoformEnabled,
    isSSOEnabled,
    isSSORolesEnabled,
    isWarning,
    lastName,
    loginEmail,
    memberRoles,
    middleName,
    mode,
    myProfile,
    observesDst,
    organizationRoles,
    pageContainerRef,
    pageLoading,
    pageTitle,
    perMemberFee,
    phones,
    preferredName,
    prefixId,
    prefixOpts,
    profileImageUrl,
    resetFormChanges,
    routedChannelsAreVisible,
    selectedChannelIds,
    selectedGroupIds,
    selectedTagIds,
    showRhinoformSeatsValidationModal,
    suffixId,
    suffixOpts,
    tagIds,
    tags,
    timeZoneId,
    timeZones,
    uploadAvatarModalOpen,
    viewAllGroups,
    isEditIntegratedContactEnabled,
    integrationPartnerTypeId,
  } = props;

  const sortedRoles = organizationRoles.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  let internalRoles = [];
  internalRoles = isCcr ? INTERNAL_ROLES : [...INTERNAL_ROLES, 'Provider'];
  const showLimitedProviderRole = integrationPartnerTypeId === TYPE_INTEGRATION_AXIUM;
  const filteredRoles = sortedRoles.filter((role) => !internalRoles.includes(role.name));
  const isSkipRenderRole = (roleName) => (roleName === RHINOFORMS_ADMIN && isRhinoformEnabled) ||
  (roleName === INTEGRATED_CONTACTS_EDITOR && isEditIntegratedContactEnabled) ||
  (roleName === LIMITED_PROVIDER_ROLE && showLimitedProviderRole) ||
  (![RHINOFORMS_ADMIN, INTEGRATED_CONTACTS_EDITOR, LIMITED_PROVIDER_ROLE].includes(roleName));

  function handleSelectGroup(id) {
    handleUpdateSelectedIds('selectedGroupIds', id);
  }

  const renderViewGroups = () => (
    <div className="search__group">
      <div className="search__sub">{viewAllGroups ? 'All Groups' : 'Groups Added'}</div>
      <GroupList
        handleSelect={handleSelectGroup}
        autoHeight="large"
        selectedGroupIds={selectedGroupIds}
        interfaceMode="checkbox"
        viewSelected={!viewAllGroups}
        groupIds={!viewAllGroups ? selectedGroupIds : null}
      />
    </div>
  );

  const renderMemberAddition = (amount) => (
    <div data-cypress="createMemberAddon">
      <Alert type="info" className="u-m-b u-text-center">Adding this member will increase your monthly bill by <strong>${amount}</strong></Alert>
    </div>
  );

  const renderRole = (role) => (
    (isSkipRenderRole(role.name) ? (
      <Checkbox
        type="secondary"
        onChange={() => handleUpdateSelectedRoles(role)}
        onClick={() => handleUpdateSelectedRoles(role)}
        isChecked={memberRoles.map((r) => r.name).includes(role.name)}
        disabled={(isSSOEnabled && isSSORolesEnabled) || !userHasAnyOfPermissions([MEMBER_EDIT, ROLE_EDIT])}
        name={`role-${role.name}`}
        key={`${role.systemRole ? 'system-role-' : 'member-role-'}${role.id}`}
        label={(
          <>
            <span className="form__block-group__label" data-cypress={role.name === RHINOFORMS_ADMIN ? 'rhinoforms' : null}>{role.name}</span>
            <span className="form__block-group__desc">{role.description}</span>
          </>
        )}
      />
    ) : null
    ));

  const renderMemberRoles = () => (
    <div className="box">
      <div className="box__title-wrapper form__group">
        <div name="roles" className="box__title">Roles</div>
        {props.errors.roles && (
        <Alert type="danger" className="u-m-t">
          {props.errors.roles}
        </Alert>
        )}
      </div>
      <CheckboxGroup blockGroup name="roles">
        {filteredRoles.map(renderRole)}
      </CheckboxGroup>
    </div>
  );

  const renderMFAOptions = () => (
    <div className="form__group">
      {!isSSOEnabled && !isMemberAddInProgress && isMFAEnabled && !isMFARequired && (
      <div className="row">
        <div>
          <Button
            disabled={isMFALoading}
            reset
            className="u-text-primary"
            onClick={() => handleEnrollMFA()}
          >
            Resend Email Invite
          </Button>
        </div>
      </div>
      )}
      {!isSSOEnabled && !isMemberAddInProgress && isMFARequired && (
      <div className="row">
        <div>
          <Button
            loading={isMFALoading}
            reset
            className="u-text-primary"
            onClick={() => handleResetMFA()}
          >
            Reset MFA
          </Button>
        </div>
      </div>
      )}
    </div>
  );

  if (pageLoading) {
    return <PageLoader />;
  }

  const passwordClassnames = cx('member-profile__login-box--password', {
    rhinobox__label: !isPasswordFormActive,
    'rhinobox__label--password-active': isPasswordFormActive,
  });

  return (
    <div className="app-page__container" ref={pageContainerRef}>
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title">
            <BackButtonContainer navigateTo="/settings/organization/members" history={history} location={location} />
            {pageTitle}
          </div>
        </div>
        {
          isWarning && mode === 'create' ? renderMemberAddition(perMemberFee) : null
        }

        <form onChange={handleFormChanges} className="create-member">
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">General Information</div>
            </div>
            <div className="edit-profile__avatar-wrapper">
              <Avatar
                type="member"
                className="edit-profile__avatar"
                size="xlarge"
                image={profileImageUrl}
                name={avatarName}
              />
              <Button
                reset
                className="u-text-primary"
                data-cypress="addMemberPhoto"
                onClick={() => handleToggle('uploadAvatarModalOpen')}
              >
                {profileImageUrl ? 'Update' : 'Add'} Photo
              </Button>
              <UploadAvatarModal
                onClose={() => handleToggle('uploadAvatarModalOpen')}
                open={uploadAvatarModalOpen}
                uploadAvatar={handleUploadAvatar}
                profileImageUrl={profileImageUrl}
              />
            </div>
            <>
              <div className="form__group">
                <div className="row">
                  <div className="column-4@small" data-cypress="firstName">
                    <Input
                      disabled={isSSOEnabled}
                      label="First Name"
                      name="firstName"
                      initialValue={firstName}
                      validationMessage={errors.firstName}
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="column-4@small" data-cypress="middleName">
                    <Input
                      disabled={isSSOEnabled}
                      label="Middle Name"
                      name="middleName"
                      initialValue={middleName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="column-4@small" data-cypress="lastName">
                    <Input
                      disabled={isSSOEnabled}
                      label="Last Name"
                      name="lastName"
                      initialValue={lastName}
                      validationMessage={errors.lastName}
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="column-4@small" data-cypress="preferredName">
                    <Input
                      disabled={isSSOEnabled}
                      validationMessage={errors.preferredName}
                      onChange={handleChange}
                      name="preferredName"
                      initialValue={preferredName}
                      label="Preferred Name"
                    />
                  </div>
                  <div className="column-3@xsmall" data-cypress="prefix">
                    <Select disabled={isSSOEnabled} onSelect={handleChange} options={prefixOpts} selected={prefixId} name="prefixId" label="Prefix" />
                  </div>
                  <div className="column-3@xsmall" data-cypress="suffix">
                    <Select disabled={isSSOEnabled} onSelect={handleChange} options={suffixOpts} selected={suffixId} name="suffixId" label="Suffix" />
                  </div>
                </div>
              </div>
            </>
            <div className="form__group">
              <div className="row">
                <div className="column-6@small" data-cypress="jobTitle">
                  <Input disabled={isSSOEnabled} onChange={handleChange} label="Job Title" name="businessTitle" initialValue={businessTitle} />
                </div>
              </div>
            </div>
          </div>

          {(mode === 'create' || !isMultiOrgEnabled) && (
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">Login Information</div>
            </div>
            <div className="form__group member-profile__login-box">
              <div className="form__block-group--checkbox">
                <div className="row rhinobox__label">
                  {mode !== 'create' && (
                  <div className="column-7@xsmall">
                    <p className="u-font-weight-bold">Email Address</p>
                    <p className="u-text-normal">{loginEmail}</p>
                  </div>
                  )}
                  {mode === 'create' && (
                  <div className="column-7@xsmall">
                    <Input onChange={handleChange} label="Email Address" name="loginEmail" validationMessage={errors.loginEmail} required initialValue={loginEmail} />
                  </div>
                  )}
                </div>
                <div className="rhinobox__label member-profile__login-box--mfa">
                  <div>
                    <div className="u-font-weight-bold">2-Step Verification<span className="title-adjacent-beta-label">BETA</span></div>
                    {renderMFAOptions()}
                  </div>
                  <RhinoSwitch
                    disabled={isMFARequired || isSSOEnabled}
                    label="Enable"
                    name="mfaEnabled"
                    isChecked={isMFAEnabled || isMFARequired}
                    onChange={handleToggleMFA}
                  />
                </div>
                {!isSSOEnabled && myProfile && (
                  <div className={passwordClassnames}>
                    <div className="u-font-weight-bold">Password</div>
                    <UpdatePasswordFormContainer onFormToggle={handlePasswordFormToggle} resetFormChanges={resetFormChanges} />
                  </div>
                )}
              </div>
            </div>
          </div>
          )}
          {renderMemberRoles()}

          <TagsGroup
            activeTagIds={selectedTagIds}
            tags={tags}
            tagIds={tagIds}
            title="Member Tags"
            onClick={(e, id) => handleUpdateSelectedIds('selectedTagIds', id)}
          />
          {routedChannelsAreVisible && (
            <ChannelRoutesReadOnly
              mode="Member"
              channels={channels}
              selectedChannelIds={selectedChannelIds}
              phones={phones}
            />
          )}

          {(myProfile || userHasAnyOfPermissions([MEMBER_EDIT])) && (
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">Outbound Message Default Channel</div>
                <div className="box__subtitle">This channel will display by default when this member messages from the Assigned to Me inbox and the Global User Search</div>
              </div>

              <RhinoSwitch
                label="Switch on to activate default channel"
                name="defaultChannelEnabled"
                isChecked={defaultChannelEnabled}
                onChange={handleDefaultChannelChange}
              />

              {defaultChannelEnabled && (
                <div className="form__group">
                  <div className="row">
                    <div className="column-6@xsmall" data-cypress="defaultChannelId">
                      <Select
                        disabled={!defaultChannelEnabled}
                        onSelect={handleChange}
                        options={channelOptions}
                        selected={defaultChannelId}
                        name="defaultChannelId"
                        label="Default Phone Channel"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <>
            <div className="box" data-cypress="member-groups">
              <div className="box__title-wrapper--flex">
                <div className="box__title__flex__trigger">
                  {/* Conflicting permissions could break API fetch here, need clarification from product */}
                  {userHasAnyOfPermissions([GROUP_VIEW]) && (
                    <Button
                      reset
                      className="u-text-small u-text-primary"
                      data-cypress={viewAllGroups ? 'reviewGroups' : 'addToGroups'}
                      onClick={() => handleToggle('viewAllGroups')}
                    >
                      {viewAllGroups ? 'Review' : 'Add to More Groups'}
                    </Button>
                  )}
                </div>
                <div className="box__title__content-flex">
                  <div className="box__title">Groups</div>
                </div>
                <div className="u-text-small box__title__flex__status">({selectedGroupIds.length} Group{selectedGroupIds.length !== 1 && 's'} Added)</div>
              </div>
              {renderViewGroups()}
            </div>
          </>
          {(myProfile || userHasAnyOfPermissions([MEMBER_EDIT])) && (
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">Availability Hours</div>
                <div className="box__subtitle">You may override these hours by setting availability hours for this member.</div>
              </div>

              <RhinoSwitch
                label="Member Availability Hours"
                name="afterHoursEnabled"
                isChecked={afterHoursEnabled}
                onChange={handleAfterHoursChange}
              />

              {afterHoursEnabled && (
                <>
                  <BusinessHours
                    businessHours={businessHours}
                    handleChange={handleChange}
                  />

                  <TimeZoneSelect
                    selectedTimeZoneId={timeZoneId}
                    timeZones={timeZones}
                    handleChange={handleChange}
                    observesDst={observesDst}
                    error={errors.timeZoneId}
                  />

                  <Textarea
                    label="Auto-Response"
                    name="autoResponse"
                    initialValue={autoResponse}
                    onChange={handleChange}
                    textareaRef={textareaRef}
                    emojiSupport
                  />
                  <div className="u-flex u-flex-direction-row u-flex-align-items-center u-m-t-small">
                    <EmojiPickerDropdown
                      inputName="autoResponse"
                      inputValue={autoResponse}
                      inputRef={textareaRef}
                      handleInputChange={handleChange}
                      dropdownPosition="top"
                    />
                    <CharacterCount length={autoResponse.length} />
                  </div>
                  <div className="form__explanation-message">This auto-response will be sent to any patient who sends a message to this member outside set availability hours.</div>
                </>
              )}
            </div>
          )}
          <div className="u-m-t-large u-text-right">
            <UtilityInlineGrid align="right">
              {actionTitle === 'Add Member' && (
                <div>
                  <Button
                    disabled={isMemberAddInProgress}
                    loading={isMemberAddAndInviteInProgress}
                    type="secondary"
                    outlined
                    onClick={() => handleSubmit(true)}
                  >
                    {addInviteActionTitle}
                  </Button>
                  <p className="create-member_button-description u-text-muted u-m-b-small" align="center">Send email invite immediately</p>
                </div>
              )}
              <div>
                <Button
                  disabled={isMemberAddAndInviteInProgress || isMemberProfileUpdateInProgress}
                  loading={isMemberAddInProgress || isMemberProfileUpdateInProgress}
                  type={actionType}
                  onClick={() => handleSubmit(false)}
                  data-cypress="saveMemberProfile"
                >
                  {actionTitle}
                </Button>
                {mode === 'create' &&
                  <p className="create-member_button-description u-text-muted u-m-b-small" align="center">Invite will not be sent</p>}
              </div>
            </UtilityInlineGrid>
          </div>
        </form>
      </div>
      <Modal open={showRhinoformSeatsValidationModal}>
        <ModalHeader title="Max Member Seats Reached" onClose={handleToggleModal} />
        <ModalBody>
          <div className="u-text-center">
            All RhinoForm seats for this role have been assigned. To give this member a seat, you must un-assign one of the currently selected Members.
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

OrganizationMemberForm.propTypes = {
  actionTitle: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  addInviteActionTitle: PropTypes.string,
  afterHoursEnabled: PropTypes.bool.isRequired,
  autoResponse: PropTypes.string.isRequired,
  avatarName: PropTypes.string,
  businessHours: PropTypes.array.isRequired,
  businessTitle: PropTypes.string,
  channelOptions: PropTypes.array.isRequired,
  channels: PropTypes.object.isRequired,
  defaultChannelEnabled: PropTypes.bool.isRequired,
  defaultChannelId: PropTypes.number,
  errors: PropTypes.object.isRequired,
  firstName: PropTypes.string,
  handleAfterHoursChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDefaultChannelChange: PropTypes.func.isRequired,
  handleEnrollMFA: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  handlePasswordFormToggle: PropTypes.func.isRequired,
  handleResetMFA: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleToggleMFA: PropTypes.func.isRequired,
  handleToggleModal: PropTypes.func.isRequired,
  handleUpdateSelectedIds: PropTypes.func.isRequired,
  handleUpdateSelectedRoles: PropTypes.func.isRequired,
  handleUploadAvatar: PropTypes.func.isRequired,
  isCcr: PropTypes.bool.isRequired,
  isMemberAddAndInviteInProgress: PropTypes.bool.isRequired,
  isMemberAddInProgress: PropTypes.bool.isRequired,
  isMemberProfileUpdateInProgress: PropTypes.bool.isRequired,
  isMFAEnabled: PropTypes.bool.isRequired,
  isMFALoading: PropTypes.bool,
  isMFARequired: PropTypes.bool.isRequired,
  isMultiOrgEnabled: PropTypes.bool,
  isPasswordFormActive: PropTypes.bool.isRequired,
  isRhinoformEnabled: PropTypes.bool,
  isSSOEnabled: PropTypes.bool.isRequired,
  isSSORolesEnabled: PropTypes.bool.isRequired,
  isWarning: PropTypes.bool.isRequired,
  lastName: PropTypes.string,
  loginEmail: PropTypes.string,
  memberRoles: PropTypes.array.isRequired,
  middleName: PropTypes.string,
  mode: PropTypes.string.isRequired,
  myProfile: PropTypes.bool,
  observesDst: PropTypes.bool.isRequired,
  organizationRoles: PropTypes.array.isRequired,
  pageContainerRef: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  perMemberFee: PropTypes.number.isRequired,
  phones: PropTypes.object.isRequired,
  preferredName: PropTypes.string,
  prefixId: PropTypes.number,
  prefixOpts: PropTypes.array.isRequired,
  profileImageUrl: PropTypes.string,
  resetFormChanges: PropTypes.func.isRequired,
  routedChannelsAreVisible: PropTypes.bool.isRequired,
  selectedChannelIds: PropTypes.array.isRequired,
  selectedGroupIds: PropTypes.array.isRequired,
  selectedTagIds: PropTypes.array.isRequired,
  showRhinoformSeatsValidationModal: PropTypes.bool.isRequired,
  suffixId: PropTypes.number,
  suffixOpts: PropTypes.array.isRequired,
  tagIds: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  timeZoneId: PropTypes.number,
  timeZones: PropTypes.object.isRequired,
  uploadAvatarModalOpen: PropTypes.bool.isRequired,
  viewAllGroups: PropTypes.bool.isRequired,
  isEditIntegratedContactEnabled: PropTypes.bool,
  integrationPartnerTypeId: PropTypes.bool,
};

export default OrganizationMemberForm;
