import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const GLOBAL_COLOR_SUCCESS = '#82bc00';
const BACKGROUND_GREY = '#404040';

const Slider = (props) => {
  const { onChange, initialValue, isVertical } = props;
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(Number(e.target.value));
    }
  };

  const sliderClass = cx('slider__wrapper', {
    'is-vertical': isVertical,
  });

  function getStyle() {
    const gradientEnd = `${value * 10}%`;
    return {
      background:
       `linear-gradient(90deg, ${GLOBAL_COLOR_SUCCESS} ${gradientEnd}, ${BACKGROUND_GREY} ${gradientEnd})`,
    };
  }

  return (
    <div className={sliderClass}>
      <input
        type="range"
        min="0"
        max="10"
        step="0.1"
        value={value}
        onInput={handleChange}
        style={getStyle()}
      />
    </div>
  );
};

Slider.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isVertical: PropTypes.bool,
};

export default Slider;
