import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import {
  Avatar,
  Button,
  Checkbox,
  CheckboxGroup,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  RhinoSwitch,
  Select,
  UtilityInlineGrid,
} from 'rhinostyle';

import { PhoneHelpers, MaskingHelpers } from '../helpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import UploadAvatarModal from './UploadAvatarModal';
import { AppConstants, Types } from '../constants';
import { ORGANIZATION_PROFILE_EDIT } from '../constants/UserPermissionsConstants';

const OrganizationProfile = (props) => {
  const {
    accountPriority,
    areExtraRemindersEnabled,
    areHipaaRequestCampaignsEnabled,
    arePrescriptionCampaignsEnabled,
    billManagerCustId,
    businessEmail,
    businessPhone,
    channelLimit,
    city,
    companyName,
    contactEmail,
    contactName,
    contactPhone,
    currentUser,
    enableCampaignAppointmentTypeAndOffice,
    disableMessagingHIPAAConsent,
    isDefaultGroupOutboundChannelEnabled,
    isMessagingForMinorsDisabled,
    errorList,
    formInProgress,
    handleAddParentOrganization,
    handleAddPhoto,
    handleChange,
    handleCloseModal,
    handleFormChanges,
    handleParentCompanyCloseModal,
    handleSaveOrganization,
    hasRhinoformOrgData,
    identityProvider,
    integration,
    integrationPartnerTypeId,
    integrationSelectOptions,
    isAchEnabled,
    isActive,
    isAddParentOrgModalOpen,
    isAppointmentManagerEnabled,
    isAutomatedMessageToConnectedPartiesEnabled,
    isBadgeCountPreferencesEnabled,
    isBulkMessagingEnabled,
    isCallEnabled,
    isCloseMentionsForAllMembersEnabled,
    isConfirmedCodifiedResponseAlertEnabled,
    isDocumentWriteBackEnabled,
    isEditIntegratedContactEnabled,
    isMessageTranslationFeatureEnabled,
    isMFARequired,
    isOfficeVCardEnabled,
    isOneReminderPerCalendarDayEnabled,
    isPrescriptionManagerEnabled,
    isRhinoblastMaxEnabled,
    isRhinoformEnabled,
    isRhinoformOrgFormInProgress,
    isRhinopayEnabled,
    isRhinophoneEnabled,
    isRoutingManagerEnabled,
    isSoundPreferencesEnabled,
    isSSOEnabled,
    isSSORolesEnabled,
    isVideoEnabled,
    loading,
    merchantId,
    merchantToken,
    name,
    organizationLogoUrl,
    pageContainerRef,
    parentCompanyformErrors,
    parentCompanyFormInProgress,
    parentCompanyId,
    parentCompanyOptions,
    paymentApiPassword,
    paymentApiUsername,
    paymentGatewayId,
    paymentType,
    rhinoformEmail,
    rhinoformSeatsLimit,
    rhinoformUsername,
    rhinopaySelectOptions,
    saveParentCompany,
    selectedPaymentType,
    ssoConnections,
    state,
    stateOptions,
    street1,
    street2,
    uploadAvatar,
    uploadAvatarModalOpen,
    videoConferenceLimit,
    zip,
  } = props;
  const payrocId = AppConstants.RHINOPAY_TYPE.find((pay) => pay.Key === AppConstants.PAYMENT_TYPE_PAYROC).id;
  const rhinoformMaxSeatsClasses = cx('u-text-center', {
    'form__control--error': errorList.rhinoformSeatsLimit || errorList.seatLimitValidation,
  });

  const integrationDisabled = integrationPartnerTypeId === -1;

  return (
    <div className="app-page__container" ref={pageContainerRef}>
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title" data-cypress="orgProfileTitle">Organization Profile</div>
        </div>
        <form onChange={handleFormChanges}>
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">General Information</div>
            </div>
            <div className="edit-profile__avatar-wrapper">
              <Avatar
                image={organizationLogoUrl}
                name={name}
                className="edit-profile__avatar"
                size="xlarge"
                type="member"
              />
              <Button reset onClick={handleAddPhoto} className="u-text-primary">
                {organizationLogoUrl ? 'Update' : 'Add'} Logo
              </Button>
              <UploadAvatarModal
                open={uploadAvatarModalOpen}
                onClose={handleCloseModal}
                uploadAvatar={uploadAvatar}
                profileImageUrl={organizationLogoUrl}
              />
            </div>
            <div className="form__group">
              <div className="row">
                <div className="column-6@small">
                  <Input
                    onChange={handleChange}
                    validationMessage={errorList.name || errorList['org.name']}
                    name="name"
                    initialValue={name}
                    label="Organization Name"
                    required
                  />
                </div>
                {currentUser.isCcr ? (
                  <>
                    <div className="column-5@small">
                      <Select
                        onSelect={handleChange}
                        name="parentCompanyId"
                        selected={parentCompanyId}
                        label="Parent Organization"
                        options={parentCompanyOptions}
                        dataCypress="parentOrganization"
                        disabled={!currentUser.isCcr}
                      />
                    </div>
                    <div className="column-1@small u-flex-align-self-end">
                      <Button
                        onClick={handleAddParentOrganization}
                        iconOnly
                        type="secondary"
                        title="Create Company"
                        className="button__account-setup"
                      >
                        <Icon icon="add" />
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="column-6@small">
                    <Select
                      onSelect={handleChange}
                      name="parentCompanyId"
                      selected={parentCompanyId}
                      label="Parent Organization"
                      options={parentCompanyOptions}
                      dataCypress="parentOrganization"
                      disabled={!currentUser.isCcr}
                    />
                  </div>
                )}
              </div>
            </div>
            <Input
              onChange={handleChange}
              name="street1"
              validationMessage={errorList.street1 || errorList['businessAddress.street1']}
              initialValue={street1}
              label="Address 1"
              required
            />
            <Input onChange={handleChange} name="street2" validationMessage={errorList['businessAddress.street2']} initialValue={street2} label="Address 2" />
            <div className="form__group">
              <div className="row">
                <div className="column-5@small">
                  <Input
                    onChange={handleChange}
                    initialValue={city}
                    validationMessage={errorList.city || errorList['businessAddress.city']}
                    name="city"
                    label="City"
                    required
                  />
                </div>
                <div className="column-4@small">
                  <Select
                    onSelect={handleChange}
                    name="state"
                    selected={state}
                    validationMessage={errorList.state || errorList['businessAddress.state']}
                    label="State"
                    required
                    options={stateOptions}
                    dataCypress="orgState"
                  />
                </div>
                <div className="column-3@small">
                  <Input
                    format={MaskingHelpers.zipCode}
                    onChange={(zipName, rawValue, value) => handleChange(zipName, value)}
                    name="zip"
                    initialValue={zip}
                    validationMessage={errorList.zip || errorList['businessAddress.zip']}
                    label="Zip"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form__group">
              <div className="row">
                <div className="column-6@small">
                  <Input
                    onChange={(businessPhoneName, rawValue, value) => handleChange(businessPhoneName, value)}
                    validationMessage={errorList.businessPhone || errorList['org.businessPhone']}
                    initialValue={PhoneHelpers.formatPhone(businessPhone)}
                    format={MaskingHelpers.phone}
                    name="businessPhone"
                    label="Organization Phone"
                    type="tel"
                    placeholder={AppConstants.PHONE_PLACEHOLDER}
                  />
                </div>
                <div className="column-6@small">
                  <Input
                    onChange={handleChange}
                    validationMessage={errorList.businessEmail || errorList['org.businessEmail']}
                    initialValue={businessEmail}
                    name="businessEmail"
                    label="Organization Email"
                    type="email"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">Organization Contact</div>
              <div className="box__subtitle">The contact details of the account administrator</div>
            </div>
            <div className="form__group">
              <div className="row">
                <div className="column-6@small">
                  <Input onChange={handleChange} initialValue={contactName} name="contactName" label="Contact Name" />
                </div>
                <div className="column-6@small">
                  <Input
                    onChange={(contactPhoneName, rawValue, value) => handleChange(contactPhoneName, value)}
                    validationMessage={errorList.contactPhone || errorList['org.contactPhone']}
                    initialValue={PhoneHelpers.formatPhone(contactPhone)}
                    format={MaskingHelpers.phone}
                    name="contactPhone"
                    label="Contact Phone"
                    type="tel"
                    placeholder={AppConstants.PHONE_PLACEHOLDER}
                  />
                </div>
                <div className="column-6@small">
                  <Input
                    onChange={handleChange}
                    validationMessage={errorList.contactEmail || errorList['org.contactEmail']}
                    initialValue={contactEmail}
                    name="contactEmail"
                    label="Contact Email"
                    type="email"
                  />
                </div>
                {currentUser.isCcr && (
                  <div className="column-6@small">
                    <Input
                      onChange={handleChange}
                      validationMessage={errorList.billManagerCustId || errorList['org.billManagerCustId']}
                      initialValue={billManagerCustId}
                      name="billManagerCustId"
                      label="Billing Customer ID"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {currentUser.isCcr && (
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">Advanced Features</div>
              </div>
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isMFARequired"
                label="Require Multifactor Authentication"
                isChecked={isMFARequired}
                onChange={handleChange}
              />
              <RhinoSwitch
                name="isSSOEnabled"
                label="Enable SSO"
                isChecked={isSSOEnabled}
                onChange={handleChange}
              />
              {isSSOEnabled && (
                <>
                  <div className="form__group">
                    <div className="row">
                      <div className="column-6@small">
                        <Select
                          selected={identityProvider}
                          name="identityProvider"
                          label="SSO Connection"
                          options={ssoConnections}
                          onSelect={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form__group">
                    <div className="row">
                      <div className="column-6@small">
                        <div>
                          <RhinoSwitch
                            name="isSSORolesEnabled"
                            label="Enable Role Mapping"
                            isChecked={isSSORolesEnabled}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <hr className="u-m-y-large" />
              <div className="form__group">
                <div className="row">
                  <div className="column-6@small">
                    <Select
                      selected={integrationPartnerTypeId}
                      name="integrationPartnerTypeId"
                      label="Integration Service"
                      options={integrationSelectOptions}
                      onSelect={handleChange}
                    />
                  </div>
                  {[
                    Types.TYPE_INTEGRATION_AXIUM,
                    Types.TYPE_INTEGRATION_PROGNOCIS,
                    Types.TYPE_INTEGRATION_CLOUD_9,
                    Types.TYPE_INTEGRATION_DOLPHIN,
                    Types.TYPE_INTEGRATION_EPIC,
                    Types.TYPE_INTEGRATION_MI7,
                  ].includes(integrationPartnerTypeId) && (
                  <div className="column-6@small edit-profile__allow-write-backs">
                    <CheckboxGroup>
                      <Checkbox
                        name="isDocumentWriteBackEnabled"
                        label="Allow document write-backs"
                        isChecked={isDocumentWriteBackEnabled}
                        onChange={handleChange}
                      />
                    </CheckboxGroup>
                  </div>
                  )}
                </div>
              </div>
              <div className="u-m-y-large" />
              <RhinoSwitch
                name="isEditIntegratedContactEnabled"
                label="Enable Editing of Integrated Contacts"
                isChecked={isEditIntegratedContactEnabled}
                onChange={handleChange}
                dataFeatureTag="enableEditingOfIntegratedContacts"
                disabled={integrationDisabled}
              />
              <div className="u-m-y-large" />
              <RhinoSwitch
                name="integration"
                label="Enable Appointment Reminders"
                isChecked={integration}
                onChange={handleChange}
                disabled={integrationDisabled}
              />
              <div className="u-m-y-large" />
              <RhinoSwitch
                name="areExtraRemindersEnabled"
                label="Enable 2 Extra Appointment Reminders"
                isChecked={areExtraRemindersEnabled}
                onChange={handleChange}
                dataFeatureTag="areExtraRemindersEnabled"
                disabled={integrationDisabled}
              />
              <div className="u-m-y-large" />
              <RhinoSwitch
                name="enableCampaignAppointmentTypeAndOffice"
                label="Enable Campaigns with Appointment Type and Office combination"
                isChecked={enableCampaignAppointmentTypeAndOffice}
                onChange={handleChange}
                dataFeatureTag="enableCampaignAppointmentTypeAndOffice"
                disabled={integrationDisabled}
                isBetaLabeled
              />
              <div className="u-m-y-large" />
              <RhinoSwitch
                name="isAppointmentManagerEnabled"
                label="Enable Appointment Manager"
                isChecked={isAppointmentManagerEnabled}
                onChange={handleChange}
                disabled={integrationDisabled}
              />
              <div className="u-m-y-large" />
              <RhinoSwitch
                name="arePrescriptionCampaignsEnabled"
                label="Enable Prescription Notifications"
                isChecked={arePrescriptionCampaignsEnabled}
                onChange={handleChange}
                disabled={integrationDisabled}
                isBetaLabeled
              />
              <div className="u-m-y-large" />
              <RhinoSwitch
                name="isPrescriptionManagerEnabled"
                label="Enable Prescription Manager"
                isChecked={isPrescriptionManagerEnabled}
                onChange={handleChange}
                disabled={integrationDisabled}
                isBetaLabeled
              />
              <div className="u-m-y-large" />

              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="areHipaaRequestCampaignsEnabled"
                label="Enable HIPAA Campaigns"
                isChecked={areHipaaRequestCampaignsEnabled}
                onChange={handleChange}
                dataFeatureTag="areHipaaRequestCampaignsEnabled"
                isBetaLabeled
              />
              <RhinoSwitch
                name="disableMessagingHIPAAConsent"
                label="Disable Messaging until HIPAA Consent is granted"
                isChecked={disableMessagingHIPAAConsent}
                onChange={handleChange}
                dataFeatureTag="disableMessagingHIPAAConsent"
                isBetaLabeled
              />
              <RhinoSwitch
                name="isMessagingForMinorsDisabled"
                label="Disable Messaging to minors"
                isChecked={isMessagingForMinorsDisabled}
                onChange={handleChange}
                dataFeatureTag="isMessagingForMinorsDisabled"
              />
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isAutomatedMessageToConnectedPartiesEnabled"
                label="Enable Appointment and RhinoBlast Messages to Connected Parties"
                isChecked={isAutomatedMessageToConnectedPartiesEnabled}
                onChange={handleChange}
                dataFeatureTag="appointmentMessagePreferences"
              />
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isDefaultGroupOutboundChannelEnabled"
                label="Enable Default Channels for a Group's Outbound Messages"
                isChecked={isDefaultGroupOutboundChannelEnabled}
                onChange={handleChange}
                isBetaLabeled
              />
              <RhinoSwitch
                name="isCloseMentionsForAllMembersEnabled"
                label="Enable Mentions - Close For All Members"
                isChecked={isCloseMentionsForAllMembersEnabled}
                onChange={handleChange}
              />
              <hr className="u-m-y-large" />
              {/* ---- Enable Translation ----  */}
              <RhinoSwitch
                name="isMessageTranslationFeatureEnabled"
                label="Enable Message Translation"
                isChecked={isMessageTranslationFeatureEnabled}
                onChange={handleChange}
                className="form__group__language-translation-switch"
              />
              <hr className="u-m-y-large" />
              {/* ---- Enable rhinoblast ----  */}
              <RhinoSwitch
                name="isBulkMessagingEnabled"
                label="Enable RhinoBlast"
                isChecked={isBulkMessagingEnabled}
                onChange={handleChange}
              />
              {isBulkMessagingEnabled && (
                <div className="column-6@small edit-profile__allow-write-backs">
                  <CheckboxGroup>
                    <Checkbox
                      name="isRhinoblastMaxEnabled"
                      label="Rhinoblast Max capabilities"
                      isChecked={isRhinoblastMaxEnabled}
                      onChange={handleChange}
                    />
                  </CheckboxGroup>
                </div>
              )}
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isVideoEnabled"
                label="Enable RhinoVideo"
                isChecked={isVideoEnabled}
                onChange={handleChange}
              />
              {isVideoEnabled && (
                <div className="form__group">
                  <div className="row">
                    <div>
                      <FormLabel
                        id="videoConferenceLimit"
                        className="u-m-b-small u-block"
                        required
                      >
                        Max Video Seats
                      </FormLabel>
                      <Input
                        onChange={(input, value) => {
                          handleChange(input, parseInt(value, 10));
                        }}
                        format={{
                          numeral: true,
                          numeralPositiveOnly: true,
                          numeralDecimalScale: 0,
                        }}
                        initialValue={videoConferenceLimit.toString()}
                        name="videoConferenceLimit"
                        disabled={!isVideoEnabled}
                        required
                        className="am__hours-input u-inline-block u-m-r"
                        customInputClasses={`${errorList.videoConferenceLimit ? 'form__control--error' : ''}`}
                      />
                      <strong>Individual Member Seats</strong>
                      {errorList.videoConferenceLimit &&
                        <div className="u-text-small u-text-danger">{errorList.videoConferenceLimit}</div>}
                    </div>
                  </div>
                </div>
              )}
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isOfficeVCardEnabled"
                label="Enable Office VCard"
                isChecked={isOfficeVCardEnabled}
                onChange={handleChange}
              />
              <hr className="u-m-y-large" />
              {/* ---- Enable Routing Manager ----  */}
              <RhinoSwitch
                name="isRoutingManagerEnabled"
                label="Enable Routing Manager"
                isChecked={isRoutingManagerEnabled}
                onChange={handleChange}
                isBetaLabeled
              />
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isRhinoformEnabled"
                label="Enable RhinoForms"
                isChecked={isRhinoformEnabled}
                onChange={handleChange}
                dataFeatureTag="rhinoForms"
              />
              {isRhinoformEnabled && (
                <div className="form__group rhinoform-profile-form">
                  <div className="row">
                    <div className="column-6@small u-flex rhinoform-profile-form__max-seats" width="20px">
                      <div>
                        <FormLabel
                          id="rhinoformSeatsLimit"
                          className="u-m-b-small u-block"
                          required={isRhinoformEnabled}
                        >
                          Max Form Seats
                        </FormLabel>
                        <Input
                          onChange={(input, value) => {
                            handleChange(input, parseInt(value, 10));
                          }}
                          format={{
                            numeral: true,
                            numeralPositiveOnly: true,
                            numeralDecimalScale: 0,
                          }}
                          initialValue={rhinoformSeatsLimit?.toString()}
                          name="rhinoformSeatsLimit"
                          disabled={!isRhinoformEnabled}
                          required={isRhinoformEnabled}
                          className="am__hours-input u-inline-block u-m-r"
                          customInputClasses={rhinoformMaxSeatsClasses}
                        />
                        <span>Individual Member Seats</span>
                        {errorList.rhinoformSeatsLimit &&
                          <div className="u-text-small u-text-danger">{errorList.rhinoformSeatsLimit}</div>}
                        {errorList.seatLimitValidation &&
                          <div className="u-text-small u-text-danger">{errorList.seatLimitValidation}</div>}
                      </div>
                    </div>
                    {hasRhinoformOrgData && (
                      <>
                        <div className="column-6@small">
                          <Input
                            initialValue={rhinoformEmail}
                            name="rhinoformEmail"
                            label="Email"
                            disabled
                          />
                        </div>
                        <div className="column-6@small">
                          <Input
                            initialValue={rhinoformUsername}
                            name="rhinoformUsername"
                            label="Username"
                            disabled
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isSoundPreferencesEnabled"
                label="Enable Sound Preferences"
                isChecked={isSoundPreferencesEnabled}
                onChange={handleChange}
                dataFeatureTag="isSoundPreferencesEnabled"
              />
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isBadgeCountPreferencesEnabled"
                label="Enable Badge Count Preferences"
                isChecked={isBadgeCountPreferencesEnabled}
                onChange={handleChange}
                dataFeatureTag="isBadgeCountPreferencesEnabled"
              />
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isOneReminderPerCalendarDayEnabled"
                label="Enable One Reminder Per Calendar Day"
                isChecked={isOneReminderPerCalendarDayEnabled}
                onChange={handleChange}
                dataFeatureTag="isOneReminderPerCalendarDayEnabled"
              />
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isConfirmedCodifiedResponseAlertEnabled"
                label="Enable Confirm Codified Response Alert"
                isChecked={isConfirmedCodifiedResponseAlertEnabled}
                onChange={handleChange}
                dataFeatureTag="isConfirmedCodifiedResponseAlertEnabled"
              />
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isRhinopayEnabled"
                label="Enable RhinoPay"
                isChecked={isRhinopayEnabled}
                onChange={handleChange}
              />
              <div className="form__group">
                <div className="row">
                  <div className="column-6@small">
                    <Select
                      selected={paymentType}
                      validationMessage={errorList.paymentType}
                      name="paymentType"
                      label="Payment Service"
                      options={rhinopaySelectOptions}
                      onSelect={handleChange}
                      disabled={!!selectedPaymentType}
                      required
                    />
                  </div>
                </div>
              </div>
              {isRhinopayEnabled && (paymentType !== -1) && (
                <div className="form__group">
                  <div className="row">
                    <div className="column-6@small">
                      <Input
                        onChange={handleChange}
                        validationMessage={errorList.merchantId || errorList['org.merchantId']}
                        format={{ numericOnly: true }}
                        initialValue={merchantId}
                        name="merchantId"
                        dataFeatureTag="merchantId"
                        label="Merchant ID"
                        disabled={!isRhinopayEnabled}
                        required
                      />
                    </div>
                    {paymentType === payrocId && (
                      <div className="column-6@small">
                        <Input
                          onChange={handleChange}
                          validationMessage={errorList.merchantToken || errorList['org.merchantToken']}
                          initialValue={merchantToken}
                          name="merchantToken"
                          dataFeatureTag="merchantToken"
                          label="Merchant Token"
                          disabled={!isRhinopayEnabled}
                          required={paymentType === payrocId}
                        />
                      </div>
                    )}
                    <div className="column-6@small">
                      <Input
                        onChange={handleChange}
                        validationMessage={errorList.paymentApiUsername || errorList['org.paymentApiUsername']}
                        initialValue={paymentApiUsername}
                        name="paymentApiUsername"
                        dataFeatureTag="paymentApiUsername"
                        label="API Username"
                        disabled={!isRhinopayEnabled}
                        required
                      />
                    </div>
                    <div className="column-6@small">
                      <Input
                        type="password"
                        onChange={handleChange}
                        validationMessage={errorList.paymentApiPassword || errorList['org.paymentApiPassword']}
                        initialValue={paymentApiPassword}
                        name="paymentApiPassword"
                        dataFeatureTag="paymentApiPassword"
                        label="API Password"
                        disabled={!isRhinopayEnabled}
                        required
                      />
                    </div>
                    {paymentType === payrocId && (
                      <div className="column-6@small">
                        <Input
                          onChange={handleChange}
                          validationMessage={errorList.paymentGatewayId || errorList['org.paymentGatewayId']}
                          initialValue={paymentGatewayId}
                          name="paymentGatewayId"
                          dataFeatureTag="paymentGatewayId"
                          label="Gateway ID"
                          disabled={!isRhinopayEnabled}
                          required={paymentType === payrocId}
                        />
                      </div>
                    )}
                    <div className="column-12@small">
                      <CheckboxGroup>
                        <Checkbox
                          onChange={handleChange}
                          isChecked={isAchEnabled}
                          name="isAchEnabled"
                          label="Enable ACH"
                        />
                      </CheckboxGroup>
                    </div>
                  </div>
                </div>
              )}
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isRhinophoneEnabled"
                label="Enable Rhinophone"
                isChecked={isRhinophoneEnabled}
                onChange={handleChange}
                dataFeatureTag="isRhinophoneEnabled"
                isBetaLabeled
              />
              <hr className="u-m-y-large" />
              <RhinoSwitch
                name="isCallEnabled"
                label="Enable RhinoCall"
                isChecked={isCallEnabled}
                onChange={handleChange}
                dataFeatureTag="isCallEnabled"
              />
              {isCallEnabled && (
                <div className="form__group">
                  <div className="row">
                    <div>
                      <FormLabel
                        id="channelLimit"
                        className="u-m-b-small u-block"
                        required
                      >
                        Max Channels
                      </FormLabel>
                      <Input
                        onChange={(input, value) => {
                          handleChange(input, parseInt(value, 10));
                        }}
                        format={{
                          numeral: true,
                          numeralPositiveOnly: true,
                          numeralDecimalScale: 0,
                        }}
                        initialValue={channelLimit.toString()}
                        name="channelLimit"
                        disabled={!isCallEnabled}
                        required
                        className="am__hours-input u-inline-block u-m-r"
                        customInputClasses={`${errorList.channelLimit ? 'form__control--error' : ''}`}
                        dataFeatureTag="rhinocallChannelLimit"
                      />
                      Outbound Channels
                      {errorList.channelLimit &&
                        <div className="u-text-small u-text-danger">{errorList.channelLimit}</div>}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {currentUser.isCcr && (
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">Organization Status</div>
                <div className="box__subtitle">If, at any time, you wish to reactivate the organization, you can do so.</div>
              </div>
              <div className="form__group">
                <div className="row">
                  <RhinoSwitch
                    name="isActive"
                    label="Switch on to activate or off to deactivate this organization"
                    isChecked={isActive}
                    onChange={handleChange}
                    dataFeatureTag="isActive"
                  />
                </div>
              </div>
              <div className="form__group">
                <div className="row">
                  <div className="column-6@small">
                    <Select
                      selected={accountPriority}
                      name="accountPriority"
                      label="Account Priority"
                      options={AppConstants.accountPriorities}
                      onSelect={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="u-m-t-large u-text-right">
            <Button
              loading={formInProgress || loading || isRhinoformOrgFormInProgress}
              onClick={handleSaveOrganization}
              type="primary"
              disabled={!userHasAnyOfPermissions([ORGANIZATION_PROFILE_EDIT])}
              data-cypress="saveProfile"
            >
              {!userHasAnyOfPermissions([ORGANIZATION_PROFILE_EDIT]) && <><Icon icon="lock" />&nbsp;</>}
              Save Profile
            </Button>
          </div>
        </form>
      </div>
      <div>
        <Modal open={isAddParentOrgModalOpen}>
          <ModalHeader onClose={handleParentCompanyCloseModal} title="CREATE NEW PARENT COMPANY" />
          <ModalBody>
            <Input label="Company Name" name="companyName" validationMessage={parentCompanyformErrors.companyName} initialValue={companyName} required onChange={handleChange} />
          </ModalBody>
          <ModalFooter>
            <UtilityInlineGrid align="between">
              <Button type="default" onClick={handleParentCompanyCloseModal}>Cancel</Button>
              <Button type="primary" loading={parentCompanyFormInProgress} onClick={saveParentCompany}>Submit</Button>
            </UtilityInlineGrid>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

OrganizationProfile.propTypes = {
  accountPriority: PropTypes.number,
  areExtraRemindersEnabled: PropTypes.bool,
  areHipaaRequestCampaignsEnabled: PropTypes.bool.isRequired,
  arePrescriptionCampaignsEnabled: PropTypes.bool.isRequired,
  billManagerCustId: PropTypes.string,
  businessEmail: PropTypes.string,
  businessPhone: PropTypes.string,
  channelLimit: PropTypes.number,
  city: PropTypes.string,
  companyName: PropTypes.string,
  contactEmail: PropTypes.string,
  contactName: PropTypes.string,
  contactPhone: PropTypes.string,
  currentUser: PropTypes.object,
  enableCampaignAppointmentTypeAndOffice: PropTypes.bool,
  disableMessagingHIPAAConsent: PropTypes.bool,
  isMessagingForMinorsDisabled: PropTypes.bool,
  isDefaultGroupOutboundChannelEnabled: PropTypes.bool,
  errorList: PropTypes.object,
  formInProgress: PropTypes.bool,
  handleAddParentOrganization: PropTypes.func,
  handleAddPhoto: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  handleParentCompanyCloseModal: PropTypes.func,
  handleSaveOrganization: PropTypes.func,
  hasRhinoformOrgData: PropTypes.bool,
  identityProvider: PropTypes.string,
  integration: PropTypes.bool.isRequired,
  integrationPartnerTypeId: PropTypes.number.isRequired,
  integrationSelectOptions: PropTypes.array.isRequired,
  isAchEnabled: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  isAddParentOrgModalOpen: PropTypes.bool,
  isAppointmentManagerEnabled: PropTypes.bool.isRequired,
  isAutomatedMessageToConnectedPartiesEnabled: PropTypes.bool.isRequired,
  isBadgeCountPreferencesEnabled: PropTypes.bool.isRequired,
  isBulkMessagingEnabled: PropTypes.bool.isRequired,
  isCallEnabled: PropTypes.bool.isRequired,
  isCloseMentionsForAllMembersEnabled: PropTypes.bool.isRequired,
  isConfirmedCodifiedResponseAlertEnabled: PropTypes.bool,
  isDocumentWriteBackEnabled: PropTypes.bool.isRequired,
  isEditIntegratedContactEnabled: PropTypes.bool,
  isMessageTranslationFeatureEnabled: PropTypes.bool.isRequired,
  isMFARequired: PropTypes.bool.isRequired,
  isOfficeVCardEnabled: PropTypes.bool.isRequired,
  isOneReminderPerCalendarDayEnabled: PropTypes.bool,
  isPrescriptionManagerEnabled: PropTypes.bool.isRequired,
  isRhinoblastMaxEnabled: PropTypes.bool,
  isRhinoformEnabled: PropTypes.bool.isRequired,
  isRhinoformOrgFormInProgress: PropTypes.bool,
  isRhinopayEnabled: PropTypes.bool.isRequired,
  isRhinophoneEnabled: PropTypes.bool.isRequired,
  isRoutingManagerEnabled: PropTypes.bool.isRequired,
  isSoundPreferencesEnabled: PropTypes.bool.isRequired,
  isSSOEnabled: PropTypes.bool.isRequired,
  isSSORolesEnabled: PropTypes.bool.isRequired,
  isVideoEnabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  merchantId: PropTypes.string,
  merchantToken: PropTypes.string,
  name: PropTypes.string,
  organizationLogoUrl: PropTypes.string.isRequired,
  pageContainerRef: PropTypes.func.isRequired,
  parentCompanyformErrors: PropTypes.object,
  parentCompanyFormInProgress: PropTypes.bool,
  parentCompanyId: PropTypes.number,
  parentCompanyOptions: PropTypes.array,
  paymentApiPassword: PropTypes.string,
  paymentApiUsername: PropTypes.string,
  paymentGatewayId: PropTypes.string,
  paymentType: PropTypes.number,
  rhinoformEmail: PropTypes.string,
  rhinoformSeatsLimit: PropTypes.number,
  rhinoformUsername: PropTypes.string,
  rhinopaySelectOptions: PropTypes.array.isRequired,
  saveParentCompany: PropTypes.func,
  selectedPaymentType: PropTypes.number,
  ssoConnections: PropTypes.array,
  state: PropTypes.number.isRequired,
  stateOptions: PropTypes.array.isRequired,
  street1: PropTypes.string,
  street2: PropTypes.string,
  uploadAvatar: PropTypes.func.isRequired,
  uploadAvatarModalOpen: PropTypes.bool.isRequired,
  videoConferenceLimit: PropTypes.number, // yes, it's a number. don't change this to a string, please
  zip: PropTypes.string,
};

OrganizationProfile.defaultProps = {
  paymentType: -1,
  merchantId: '',
  merchantToken: '',
  paymentApiUsername: '',
  paymentApiPassword: '',
  paymentGatewayId: '',
  ssoConnections: [],
  isAchEnabled: false,
  hasRhinoformOrgData: false,
  rhinoformSeatsLimit: 1,
  rhinoformUsername: '',
  rhinoformEmail: '',
};

export default OrganizationProfile;
