import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Scrollbars,
  Button,
  Close,
  LoaderPulse,
} from 'rhinostyle';
import ThreadEvent from './threadEvents/ThreadEvent';
import LightboxContainer from '../containers/LightboxContainer';
import { fetchEventsForSavedContentItem } from '../reducers/savedContentReducer';
import { getEventsForSavedContent } from '../selectors/savedContentSelectors';

const SavedContentPreviewThread = (props) => {
  const {
    allowCloseSavedContent,
    allowMobileView,
    handleCloseSavedContent,
    handleMobileViewActions,
    nextPageNumber,
    savedContentItemId,
  } = props;

  const savedContentThreadBodyRef = React.createRef();
  const dispatch = useDispatch();
  const [nextPage, setNextPage] = useState(nextPageNumber);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxAttachmentUrl, setLightboxAttachmentUrl] = useState(undefined);
  const handleEventAttachmentLightboxOpen = (nextLightboxOpenState, attachmentUrl) => {
    setIsLightboxOpen(nextLightboxOpenState);
    setLightboxAttachmentUrl(attachmentUrl);
  };
  const [isNextPageLoading, setNextPageLoadingState] = useState(false);
  const activeEvents = useSelector((state) => getEventsForSavedContent(state, { savedContentItemId }));

  const handleThreadScroll = async () => {
    if (isNextPageLoading) return;

    const scrollContainer = savedContentThreadBodyRef.current.container.firstChild;

    if (scrollContainer.scrollTop === 0) {
      setNextPageLoadingState(true);
      const newPageNumber = await dispatch(fetchEventsForSavedContentItem(savedContentItemId, nextPage));
      setNextPageLoadingState(false);
      setNextPage(newPageNumber);
    }
  };

  useEffect(() => {
    const scrollContainer = savedContentThreadBodyRef.current.container.firstChild;
    scrollContainer.scrollTop = scrollContainer.scrollHeight;
  }, []);
  return (
    <div className="convo__inner">
      {allowMobileView && (
        <div className="saved-content-preview__thread-actions--mobile">
          <Button size="small" type="link" onClick={handleMobileViewActions}>Actions</Button>
          {allowCloseSavedContent && <Close className="u-m-r u-text-small" onClick={handleCloseSavedContent} />}
        </div>
      )}
      <Scrollbars className="convo__body" onScroll={handleThreadScroll} ref={savedContentThreadBodyRef}>
        <div className="convo__body__preview-header__wrapper">
          <div className="saved-content-preview__thread-title--mobile">
            <p className="convo__body__preview-header__title">Saved Content - View Only</p>
          </div>
          {isNextPageLoading && <div className="u-text-center u-p-t-small"><LoaderPulse type="secondary" /></div>}
        </div>
        {activeEvents?.length && activeEvents.map((activeEvent) => (
          <ThreadEvent
            key={activeEvent.id}
            eventId={activeEvent.id}
            event={activeEvent}
            handleSelectContent={() => {}}
            toggleLightbox={handleEventAttachmentLightboxOpen}
          />
        ))}
        {isLightboxOpen && (
          <LightboxContainer
            events={activeEvents}
            eventIds={Object.keys(activeEvents)}
            toggleLightbox={handleEventAttachmentLightboxOpen}
            lightboxAttachmentUrl={lightboxAttachmentUrl}
          />
        )}
      </Scrollbars>
      <div className="convo__footer">
        <div className="alert convo__footer__alert alert--default">
          <p className="u-text-accent u-text-italic u-m-b-0">
            Saved Content - View Only
          </p>
          <div className="alert__body">
            You are viewing content that was saved from this contact&apos;s message thread. It is not possible to message from this view.
          </div>
        </div>
      </div>
    </div>
  );
};

SavedContentPreviewThread.propTypes = {
  allowCloseSavedContent: PropTypes.bool,
  allowMobileView: PropTypes.bool,
  handleCloseSavedContent: PropTypes.func.isRequired,
  handleMobileViewActions: PropTypes.func,
  nextPageNumber: PropTypes.number,
  savedContentItemId: PropTypes.number.isRequired,
};

export default SavedContentPreviewThread;
