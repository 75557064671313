import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Button,
  Close,
  Resource,
  ResourceBody,
  ResourceGroup,
  ResourceIntro,
  ResourceRight,
  Scrollbars,
  UtilitySystem,
} from 'rhinostyle';
import { ChannelHelpers, PhoneHelpers } from '../helpers';
import * as ThreadReducer from '../reducers/threadReducer';
import { useThreadFilters } from '../hooks';

const ThreadFilterPanel = (props) => {
  const {
    channels,
    phones,
    tags,
  } = props;
  const dispatch = useDispatch();

  const {
    filtersApplied,
    filtersEnabled,
    handleApplyThreadFilterClick,
    handleClearThreadFilterClick,
    handleSelectChannelId,
    selectedChannelIds,
    threadActiveChannelIds,
  } = useThreadFilters();

  const renderChannelHelp = () => {
    if (!threadActiveChannelIds.length) {
      return <div className="search__no-results">There are no active channels in this thread.</div>;
    }
    return null;
  };

  const shapeChannelForRender = (channel) => ({
    icon: ChannelHelpers.getChannelIcon(channel.typeId),
    id: channel.id,
    name: channel.name,
    phone: channel.details.phone && PhoneHelpers.formatPhone(phones[channel.details.phone].value),
    tags: channel.tags ? channel.tags.map((id) => tags[id]) : [],
    deleted: channel.deleted,
  });

  const renderTag = (tag, index) => (
    <span key={index}>
      {!!index && ', '}
      #{tag.name}
    </span>
  );

  const renderChannels = (id, idx) => {
    const channel = shapeChannelForRender(channels[id]);
    const selected = selectedChannelIds.includes(id);

    return (
      <Resource key={idx} onClick={() => handleSelectChannelId(id)} selected={selected}>
        <ResourceIntro
          icon={{ icon: `${channel.icon}` }}
          title={channel.name}
          titleSub={channel.phone}
        />
        <ResourceBody className="u-text-muted">
          {channel.tags.map(renderTag)}
        </ResourceBody>
        {channel.deleted && (
          <ResourceRight>
            <span className="u-text-accent">Deleted</span>
          </ResourceRight>
        )}
      </Resource>
    );
  };
  return (
    <>
      <div className="summary-panel__wrapper app-panels--hide-icons-desktop">
        <div className="summary-panel">
          <div className="app-page__header">
            <div className="app-page__header__title">Filter By</div>
            <div className="app-page__header__action">
              <Close
                className="app-page__header__action__close"
                onClick={() => dispatch(ThreadReducer.toggleProfile())}
              />
            </div>
          </div>
          <Scrollbars className="summary-panel__body">
            <div className="summary-panel__content">
              {renderChannelHelp()}
              {threadActiveChannelIds.length > 0 && (
                <>
                  <div className="search__group">
                    <div className="search__sub">
                      Channels
                    </div>
                    <Scrollbars className="resource-group__scroll" autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.large}>
                      <ResourceGroup interfaceMode="checkbox">
                        {threadActiveChannelIds.map(renderChannels)}
                      </ResourceGroup>
                    </Scrollbars>
                  </div>
                  <div className="u-m-t-large">
                    <div className="row">
                      <div className="column-6">
                        {filtersApplied && (
                          <Button
                            type="primary"
                            size="small"
                            onClick={handleClearThreadFilterClick}
                            outlined
                          >
                            Clear Filters
                          </Button>
                        )}
                      </div>
                      <div className="column-6 u-text-right">
                        <Button
                          type="primary"
                          size="small"
                          onClick={handleApplyThreadFilterClick}
                          disabled={!filtersEnabled || selectedChannelIds.length === 0}
                        >
                          Apply Filters
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  channels: state.channel.channels,
  phones: state.phone.phones,
  tags: state.tag.tags,
});

ThreadFilterPanel.propTypes = {
  channels: PropTypes.object.isRequired,
  phones: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ThreadFilterPanel);
