import { useGetUserFormsQuery } from '../services/formService'; // eslint-disable-line
import { useInboxParams } from '.';

const useContactForm = (formField) => {
  const { userId } = useInboxParams();
  const selectedForm = useGetUserFormsQuery({ contactId: userId }, {
    selectFromResult: (result) => {
      const contactForm = result?.data?.forms?.find((form) => form[formField] === formField);
      return { contactForm };
    },
  });
  return selectedForm;
};

export default useContactForm;
