import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Alert,
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  RadioGroup,
  Icon,
  UtilityInlineGrid,
  Radio,
  RhinoSwitch,
  Textarea,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
  Select,
} from 'rhinostyle';
import EmojiPickerDropdown from './EmojiPickerDropdown';

import TagsGroup from './TagsGroup';
import BandwidthSearchContainer from '../containers/BandwidthSearchContainer';
import BackButtonContainer from '../containers/BackButtonContainer';
import MetaChannelForm from './MetaChannelForm';
import TimeZoneSelect from './TimeZoneSelect';
import BusinessHours from './BusinessHours';
import ChannelWidgetSectionToggle from './ChannelWidgetSectionToggle';
import ColorPicker from './ColorPicker';
import { formatTimeZone } from '../helpers/DateHelpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { TYPE_TWILIO } from '../constants/Types';
import PageLoader from './PageLoader';
import CharacterCount from './CharacterCount';
import WidgetTrigger from '../widget/components/WidgetTrigger';
import Widget from '../widget/components/Widget';
import topLeftCorner from '../assets/images/positioning/topLeft.svg';
import topRightCorner from '../assets/images/positioning/topRight.svg';
import bottomRightCorner from '../assets/images/positioning/bottomRight.svg';
import bottomLeftCorner from '../assets/images/positioning/bottomLeft.svg';
import {
  CHANNEL_EDIT,
  CHANNEL_FACEBOOK_CREATE,
  CHANNEL_SECURE_CREATE,
  CHANNEL_RHINOGRAM_CREATE,
  CHANNEL_SMS_CREATE,
  CHANNEL_DELETE,
} from '../constants/UserPermissionsConstants';
import MemberGroupSearch from './MemberGroupSearch';

const DeleteChannelModal = (props) => {
  const {
    channelName,
    formInProgress,
    handleClose,
    handleDeleteChannel,
    handleReverseComplete,
    open,
    perChannelFee,
    isWarning,
  } = props;

  return (
    <Modal onReverseComplete={handleReverseComplete} open={open}>
      <ModalHeader onClose={handleClose} title="Delete Channel?" titleSub="If you confirm the exclusion of this channel, the following will happen:" />
      <ModalBody>
        <ol className="u-list--numbers">
          <li>Members will not be able to send or receive messages through <strong>{channelName}</strong>.</li>
          <li>Anyone who texts into this number will not receive a response.</li>
          <li>All open conversations will be closed for this channel, and no history or audit trail is recorded.</li>
          {isWarning ? <li data-cypress="deleteChannelAddon">Your monthly bill will decrease by <strong>${perChannelFee}</strong>, starting next month.</li> : null}
        </ol>
        <p className="u-text-danger">This action cannot be undone.</p>
      </ModalBody>
      <ModalFooter>
        <UtilityInlineGrid align="right">
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button
            loading={formInProgress}
            onClick={handleDeleteChannel}
            type="danger"
            data-cypress="confirm delete channel"
          >
            Delete Channel
          </Button>
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );
};

DeleteChannelModal.propTypes = {
  channelName: PropTypes.string.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDeleteChannel: PropTypes.func.isRequired,
  handleReverseComplete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  perChannelFee: PropTypes.number.isRequired,
  isWarning: PropTypes.bool.isRequired,
};

const OrganizationChannelForm = (props) => {
  const history = useHistory();
  const location = useLocation();

  const autoResponseRef = useRef();
  const titleSubtextRef = useRef();
  const phoneHelpTextRef = useRef();
  const confirmationTextRef = useRef();

  const {
    activeTagIds,
    afterHoursEnabled,
    allowReroute,
    autoResponse,
    bandwidthForwardingNumber,
    bandwidthNumber,
    businessHours,
    channelName,
    channelPurpose,
    channelType,
    copyEmbedCodeText,
    embedUrl,
    errors,
    existingNumber,
    facebookPageId,
    instagramPageId,
    formInProgress,
    handleChange,
    handleCopyEmbedCodeClick,
    handleWidgetConfigChange,
    handleConfirmDeleteRequest,
    handleFormChanges,
    handleFacebookPageSelect,
    handleModalReverseComplete,
    handleSubmit,
    handleTagClick,
    handleToggle,
    handleUpdateSelectedId,
    isCallEnabled,
    isRhinocallEnabled,
    landlineNumber,
    mode,
    modalFormInProgress,
    observesDst,
    organizationId,
    pageContainerRef,
    pageLoading,
    selectedRouteMemberId,
    selectedRouteGroupId,
    showDeleteChannelModal,
    tagIds,
    tags,
    timeZoneId,
    timeZones,
    userIsCcr,
    perChannelFee,
    isWarning,
    isWebWidgetEnabled,
    widgetActiveSection,
    widgetConfig,
    isWebFormButtonBackgroundColorPickerVisible,
    isWebFormTitleBackgroundColorPickerVisible,
    isWebFormTitleTextColorPickerVisible,
    isWebFormButtonTextColorPickerVisible,
    handleColorPickerToggle,
    provisionServiceOptions,
    provisionServiceType,
    rhinocallLimitReached,
    handleInstagramPageSelect,
  } = props;
    // RHIN-9467: Hide instagram until approved by Facebook
  const RHINO_SOUTH_TEST_ORG = 2000;
  const FACEBOOK_REVIEW_TEST_ORG = 2882;
  const hideInstagram = process.env.REACT_APP_ENVIRONMENT === 'master' && ![RHINO_SOUTH_TEST_ORG, FACEBOOK_REVIEW_TEST_ORG].includes(organizationId);
  const hasChannelType = !!channelType;
  const headerText = mode === 'create' ? 'New Channel Setup' : 'Edit Channel';
  const inferredChannelType = channelType === 'sms' && existingNumber ? 'landline' : channelType;
  const isLandlineVisible = (mode === 'create' && userIsCcr && userHasAnyOfPermissions([CHANNEL_SMS_CREATE])) || inferredChannelType === 'landline';
  const isFacebookVisible = (mode === 'create' && userHasAnyOfPermissions([CHANNEL_FACEBOOK_CREATE])) || inferredChannelType === 'facebook';
  const isInstagramVisible = !hideInstagram && ((mode === 'create' && userHasAnyOfPermissions([CHANNEL_FACEBOOK_CREATE])) || inferredChannelType === 'instagram');
  const isRhinogramVisible = (mode === 'create' && userHasAnyOfPermissions([CHANNEL_RHINOGRAM_CREATE])) || inferredChannelType === 'rhinogram';
  const isSMSVisible = (mode === 'create' && userIsCcr && userHasAnyOfPermissions([CHANNEL_SMS_CREATE])) || inferredChannelType === 'sms';
  const isSecureVisible = (mode === 'create' && userHasAnyOfPermissions([CHANNEL_SECURE_CREATE])) || inferredChannelType === 'secure';
  const isProvisionServiceTypeDisabled = mode === 'edit' && !(userIsCcr && [TYPE_TWILIO].includes(provisionServiceType));
  const isRhinocallSupported = isCallEnabled && ['sms', 'landline'].includes(channelType);
  const areChannelsDisabled = mode === 'edit';
  const saveBtnText = `${mode === 'create' ? 'Create' : 'Update'} Channel`;
  const saveBtnType = mode === 'create' ? 'secondary' : 'primary';
  const showBusinessHours = mode === 'edit';
  const showWidgetAddon = mode === 'edit' && (channelType === 'landline' || channelType === 'sms');
  const showDelete = mode === 'edit' && userHasAnyOfPermissions([CHANNEL_DELETE]);
  const showForwarding = mode === 'edit';
  const showTags = ['sms', 'secure'].includes(channelType) || mode === 'edit';
  const subHeaderText = `${mode === 'create' ? 'Select ' : ''}Channel Type`;
  const tooltipText = 'This option is only available with Free-form positioning. Enabling this will remove the Call to Action button and keep the Web Form open at all times.'; // eslint-disable-line max-len
  const renderStandardStatus = () => (
    <div className="item-status">
      {showDelete && (
        <div className="item-status__left">
          <Button
            reset
            onClick={() => handleToggle('showDeleteChannelModal')}
            className="item-status__delete-icon"
            title="Delete Channel"
            data-cypress="delete channel"
          >
            <Icon bump="down" size="large" icon="trash" />
          </Button>
          <DeleteChannelModal
            handleClose={() => handleToggle('showDeleteChannelModal')}
            handleDeleteChannel={handleConfirmDeleteRequest}
            handleReverseComplete={handleModalReverseComplete}
            formInProgress={modalFormInProgress}
            open={showDeleteChannelModal}
            perChannelFee={perChannelFee}
            channelName={channelName}
            isWarning={(isLandlineVisible || isSMSVisible) ? isWarning : false}
          />
        </div>
      )}
      {hasChannelType && (
        <div className="item-status__right">
          <Button type={saveBtnType} loading={formInProgress} onClick={handleSubmit} data-cypress="save channel">
            {saveBtnText}
          </Button>
        </div>
      )}
    </div>
  );

  const renderLandline = () => (
    <div className="row">
      <div className="column-6@small">
        <Input
          name="landlineNumber"
          label="Landline Number"
          validationMessage={errors.landlineNumber}
          disabled={mode === 'edit'}
          initialValue={landlineNumber}
          onChange={handleChange}
          required
        />
      </div>
      <div className="column-6@small">
        <Select
          onSelect={handleChange}
          selected={provisionServiceType}
          disabled={isProvisionServiceTypeDisabled}
          name="provisionServiceType"
          label="Provisioning Service"
          options={provisionServiceOptions}
          required
        />
      </div>
    </div>
  );

  const renderChannelWidget = () => (
    <div className="box">
      <div className="box__title-wrapper">
        <div className="box__title">Web Form Add-On</div>
        <div className="box__subtitle">
          Creates a web form to be embedded on a website. The web form will use this channel&apos;s phone number for inbound communication.
        </div>
      </div>

      <RhinoSwitch
        label="Add Web Form"
        name="isWebWidgetEnabled"
        isChecked={isWebWidgetEnabled}
        onChange={handleChange}
        dataFeatureTag="addWebForm"
      />

      {isWebWidgetEnabled && (
        <div className="u-m-t-large">
          <div className="row">
            <div className="column-6@small">
              <h4 className="u-text-muted u-font-weight-normal">Configuration options</h4>
              <ChannelWidgetSectionToggle
                title="Text"
                onClick={() => handleChange('widgetActiveSection', 'text')}
                active={widgetActiveSection === 'text'}
              >
                <Input
                  label="Form Title"
                  name="formTitle"
                  initialValue={widgetConfig.text.formTitle}
                  onChange={(name, value) => handleWidgetConfigChange('text', name, value)}
                  validationMessage={errors.formTitle}
                  required
                  dataFeatureTag="webForm-formTitle"
                />
                <Textarea
                  label="Title Subtext"
                  name="titleSubtext"
                  initialValue={widgetConfig.text.titleSubtext}
                  onChange={(name, value) => handleWidgetConfigChange('text', name, value)}
                  validationMessage={errors.titleSubtext}
                  required
                  dataCypress="webForm-titleSubtext"
                  textareaRef={titleSubtextRef}
                  emojiSupport
                />
                <div className="u-flex u-flex-direction-row u-flex-align-items-center u-m-t-small u-m-b">
                  <EmojiPickerDropdown
                    inputName="titleSubtext"
                    inputValue={widgetConfig.text.titleSubtext}
                    inputRef={titleSubtextRef}
                    inputSection="text"
                    handleInputChange={handleWidgetConfigChange}
                    dropdownPosition="top"
                  />
                </div>
                <Input
                  label="Phone Placeholder"
                  name="phonePlaceholder"
                  initialValue={widgetConfig.text.phonePlaceholder}
                  onChange={(name, value) => handleWidgetConfigChange('text', name, value)}
                  validationMessage={errors.phonePlaceholder}
                  required
                  dataFeatureTag="webForm-phonePlaceholder"
                />
                <Textarea
                  label="Phone Help Text"
                  name="phoneHelpText"
                  initialValue={widgetConfig.text.phoneHelpText}
                  onChange={(name, value) => handleWidgetConfigChange('text', name, value)}
                  validationMessage={errors.phoneHelpText}
                  required
                  dataCypress="webForm-phoneHelpText"
                  textareaRef={phoneHelpTextRef}
                  emojiSupport
                />
                <div className="u-flex u-flex-direction-row u-flex-align-items-center u-m-t-small u-m-b">
                  <EmojiPickerDropdown
                    inputName="phoneHelpText"
                    inputValue={widgetConfig.text.phoneHelpText}
                    inputRef={phoneHelpTextRef}
                    inputSection="text"
                    handleInputChange={handleWidgetConfigChange}
                    dropdownPosition="top"
                  />
                </div>
                <Input
                  label="Message Placeholder"
                  name="messagePlaceholder"
                  initialValue={widgetConfig.text.messagePlaceholder}
                  onChange={(name, value) => handleWidgetConfigChange('text', name, value)}
                  validationMessage={errors.messagePlaceholder}
                  required
                  dataFeatureTag="webForm-messagePlaceholder"
                />
                <Input
                  label="Submit Button"
                  name="submitButton"
                  initialValue={widgetConfig.text.submitButton}
                  onChange={(name, value) => handleWidgetConfigChange('text', name, value)}
                  validationMessage={errors.submitButton}
                  required
                  dataFeatureTag="webForm-submitButton"
                />
                {!widgetConfig.embedForm.hideCTAButton && (
                  <Input
                    label="Call to Action Button"
                    name="calltoActionButton"
                    initialValue={widgetConfig.text.calltoActionButton}
                    onChange={(name, value) => handleWidgetConfigChange('text', name, value)}
                    validationMessage={errors.calltoActionButton}
                    required
                  />
                )}
                <Textarea
                  label="Confirmation Text"
                  name="confirmationText"
                  initialValue={widgetConfig.text.confirmationText}
                  onChange={(name, value) => handleWidgetConfigChange('text', name, value)}
                  validationMessage={errors.confirmationText}
                  required
                  dataCypress="webForm-confirmationText"
                  textareaRef={confirmationTextRef}
                  emojiSupport
                />
                <div className="u-flex u-flex-direction-row u-flex-align-items-center u-m-t-small u-m-b">
                  <EmojiPickerDropdown
                    inputName="confirmationText"
                    inputValue={widgetConfig.text.confirmationText}
                    inputRef={confirmationTextRef}
                    inputSection="text"
                    handleInputChange={handleWidgetConfigChange}
                    dropdownPosition="bottom"
                  />
                </div>
              </ChannelWidgetSectionToggle>
              <ChannelWidgetSectionToggle
                title="Colors"
                onClick={() => handleChange('widgetActiveSection', 'colors')}
                active={widgetActiveSection === 'colors'}
              >
                <div className="row">
                  <div className="column-8@xsmall column-10@small">
                    <ColorPicker
                      open={isWebFormTitleBackgroundColorPickerVisible}
                      handleToggleOpenClose={() => handleColorPickerToggle('isWebFormTitleBackgroundColorPickerVisible')}
                      label="Title Background"
                      name="titleBackground"
                      color={widgetConfig.color.titleBackground}
                      onChange={(name, value) => handleWidgetConfigChange('color', name, value.hex)}
                    />
                    <ColorPicker
                      open={isWebFormTitleTextColorPickerVisible}
                      handleToggleOpenClose={() => handleColorPickerToggle('isWebFormTitleTextColorPickerVisible')}
                      label="Title Text"
                      name="titleText"
                      color={widgetConfig.color.titleText}
                      onChange={(name, value) => handleWidgetConfigChange('color', name, value.hex)}
                    />
                    <ColorPicker
                      open={isWebFormButtonBackgroundColorPickerVisible}
                      handleToggleOpenClose={() => handleColorPickerToggle('isWebFormButtonBackgroundColorPickerVisible')}
                      label="Button Background"
                      name="buttonBackground"
                      color={widgetConfig.color.buttonBackground}
                      onChange={(name, value) => handleWidgetConfigChange('color', name, value.hex)}
                    />
                    <ColorPicker
                      open={isWebFormButtonTextColorPickerVisible}
                      handleToggleOpenClose={() => handleColorPickerToggle('isWebFormButtonTextColorPickerVisible')}
                      label="Button Text"
                      name="buttonText"
                      color={widgetConfig.color.buttonText}
                      onChange={(name, value) => handleWidgetConfigChange('color', name, value.hex)}
                    />
                  </div>
                </div>
              </ChannelWidgetSectionToggle>
              <ChannelWidgetSectionToggle
                title="Embed Form"
                onClick={() => handleChange('widgetActiveSection', 'embed')}
                active={widgetActiveSection === 'embed'}
              >
                <RadioGroup
                  name="position"
                  label="Form Position on Website"
                  selectedValue={widgetConfig.embedForm.position}
                  onChange={(value) => handleWidgetConfigChange('embedForm', 'position', value)}
                  blockGroup
                  className="channel__widget__position-selector"
                >
                  <Radio
                    value="bottomRightCorner"
                    label={(
                      <div className="channel__widget__position__wrapper">
                        <img src={bottomRightCorner} className="channel__widget__position__image" alt="Bottom, Right corner" />
                        <span>Bottom, Right corner</span>
                      </div>
                    )}
                  />
                  <Radio
                    value="topRightCorner"
                    label={(
                      <div className="channel__widget__position__wrapper">
                        <img src={topRightCorner} className="channel__widget__position__image" alt="Top, Right corner" />
                        <span>Top, Right corner</span>
                      </div>
                    )}
                  />
                  <Radio
                    value="bottomLeftCorner"
                    label={(
                      <div className="channel__widget__position__wrapper">
                        <img src={bottomLeftCorner} className="channel__widget__position__image" alt="Bottom, Left corner" />
                        <span>Bottom, Left corner</span>
                      </div>
                    )}
                  />
                  <Radio
                    value="topLeftCorner"
                    label={(
                      <div className="channel__widget__position__wrapper">
                        <img src={topLeftCorner} className="channel__widget__position__image" alt="Top, Left corner" />
                        <span>Top, Left corner</span>
                      </div>
                    )}
                  />
                  <Radio
                    value="freeform"
                    label={(
                      <div className="u-text-small">
                        Free-form (advanced)
                      </div>
                    )}
                  />
                </RadioGroup>
                {widgetConfig.embedForm.position === 'freeform' && (
                  <div className="u-m-t u-flex u-flex-align-items-center">
                    <RhinoSwitch
                      label="Hide Call to Action Button"
                      name="hideCTAButton"
                      isChecked={widgetConfig.embedForm.hideCTAButton}
                      onChange={(name, value) => handleWidgetConfigChange('embedForm', name, value)}
                    />
                    &nbsp;
                    <Tooltip
                      placement="top"
                      content={tooltipText}
                    >
                      <Icon icon="info-circle" className="u-text-muted" />
                    </Tooltip>
                  </div>
                )}
              </ChannelWidgetSectionToggle>
              <Input
                readOnly
                name="embed"
                className="u-m-t-large"
                initialValue={embedUrl}
                label="Embed Code"
              />
              <div className="u-m-t-small u-text-center">
                <CopyToClipboard onCopy={handleCopyEmbedCodeClick} text={embedUrl}>
                  <Button
                    className="u-text-primary"
                    reset
                    size="small"
                    title={copyEmbedCodeText}
                  >
                    {copyEmbedCodeText}
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
            <div className="column-6@small">
              <h4 className="u-text-muted u-font-weight-normal">Preview</h4>
              <Widget config={widgetConfig} />
              {!widgetConfig.embedForm.hideCTAButton && (
                <>
                  <div className="u-m-t-large u-text-center">
                    <WidgetTrigger config={widgetConfig} />
                  </div>
                  <div className="u-p-t-small u-text-small u-text-muted u-text-center">Call to Action Button</div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const renderChannelAddition = (amount) => (
    <div data-cypress="createChannelAddon">
      <Alert type="info" className="u-m-b u-text-center">Setting up this channel will add <strong>${amount}</strong> to your monthly bill.</Alert>
    </div>
  );

  const channelTypeOptions = [
    {
      isVisible: isLandlineVisible,
      children: (
        <Radio
          key="landline"
          value="landline"
          disabled={areChannelsDisabled}
          label={(
            <>
              <span className="form__block-group__label">
                Existing Phone Number
              </span>
              <span className="form__block-group__desc">
                Use this option to select an existing landline number.
              </span>
            </>
          )}
        >
          {renderLandline()}
        </Radio>
      ),
    },
    {
      isVisible: isSMSVisible,
      children: (
        <Radio
          key="sms"
          value="sms"
          disabled={areChannelsDisabled}
          label={(
            <>
              <span className="form__block-group__label">
                New Phone Number
              </span>
              <span className="form__block-group__desc">
                Setup a new number to receive and send messages.
              </span>
            </>
          )}
        >
          <BandwidthSearchContainer
            handleChange={handleChange}
            errors={errors}
            mode={mode}
            selectedNumber={bandwidthNumber}
            forwardingNumber={bandwidthForwardingNumber}
          />
        </Radio>
      ),
    },
    {
      isVisible: isFacebookVisible,
      children: (
        <Radio
          key="facebook"
          value="facebook"
          disabled={areChannelsDisabled}
          label={(
            <>
              <span className="form__block-group__label">
                Facebook
              </span>
              <span className="form__block-group__desc">
                Receive and send Facebook messages in Rhinogram.
              </span>
            </>
          )}
        >
          <MetaChannelForm
            errors={errors}
            mode={mode}
            handlePageSelect={handleFacebookPageSelect}
            selectedPageId={facebookPageId}
            channelType="Facebook"
          />
        </Radio>
      ),
    },
    {
      isVisible: isInstagramVisible,
      children: (
        <Radio
          key="instagram"
          value="instagram"
          disabled={areChannelsDisabled}
          label={(
            <>
              <span className="form__block-group__label">
                Instagram
              </span>
              <span className="form__block-group__desc">
                Receive and send Instagram messages in Rhinogram.
              </span>
            </>
          )}
        >
          <MetaChannelForm
            errors={errors}
            mode={mode}
            handlePageSelect={handleInstagramPageSelect}
            selectedPageId={instagramPageId}
            channelType="Instagram"
          />
        </Radio>
      ),
    },
    {
      isVisible: isRhinogramVisible,
      children: (
        <Radio
          key="rhinogram"
          value="rhinogram"
          disabled={areChannelsDisabled}
          label={(
            <>
              <span className="form__block-group__label">
                Rhinogram
              </span>
              <span className="form__block-group__desc">
                Receive and send Rhinogram to Rhinogram messages.
              </span>
            </>
          )}
        />
      ),
    },
    {
      isVisible: isSecureVisible,
      children: (
        <Radio
          key="secure"
          value="secure"
          disabled={areChannelsDisabled}
          label={(
            <>
              <span className="form__block-group__label">
                RhinoSecure
              </span>
              <span className="form__block-group__desc">
                Receive and send messages using RhinoSecure.
              </span>
            </>
          )}
        />
      ),
    },
  ].filter((item) => item.isVisible); // We need to filter out non-visible items to work with RadioGroup, since it cannot accept null values as children.

  if (pageLoading) {
    return <PageLoader />;
  }

  return (
    <div className="app-page__container" ref={pageContainerRef}>
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title">
            <BackButtonContainer navigateTo="/settings/organization/channels" history={history} location={location} />
            {headerText}
          </div>
        </div>
        {
          isWarning && mode === 'create' && isSMSVisible ? renderChannelAddition(perChannelFee) : null
        }
        <form onChange={handleFormChanges}>
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">{subHeaderText}</div>
            </div>
            <RadioGroup
              name="channelType"
              selectedValue={inferredChannelType}
              onChange={(value) => handleChange('channelType', value)}
              blockGroup
            >
              {channelTypeOptions.map((item) => item.children)}
            </RadioGroup>
            {isRhinocallSupported && (
              <CheckboxGroup>
                <Checkbox
                  name="isRhinocallEnabled"
                  onChange={handleChange}
                  isChecked={isRhinocallEnabled}
                  label="Enable Outbound RhinoCalls"
                  className="u-p-l"
                  disabled={rhinocallLimitReached}
                  dataFeatureTag="isRhinocallEnabled"
                />
              </CheckboxGroup>
            )}
            {hasChannelType && (
            <>
              <div className="box__title-wrapper">
                <div className="box__title">Channel Details</div>
              </div>

              <div className="row u-flex-justify-center">
                <div className="column-9@xsmall">
                  <Input
                    name="channelName"
                    label="Name"
                    explanationMessage="Channel name must be unique."
                    validationMessage={errors.channelName}
                    initialValue={channelName}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                  <Input
                    name="channelPurpose"
                    label="Purpose"
                    explanationMessage="What&apos;s this channel for?"
                    initialValue={channelPurpose}
                    onChange={handleChange}
                  />

                  <TimeZoneSelect
                    selectedTimeZoneId={timeZoneId}
                    timeZones={timeZones}
                    handleChange={handleChange}
                    observesDst={observesDst}
                    error={errors.timeZoneId}
                  />
                </div>
              </div>
            </>
            )}
          </div>

          {showTags && (
            <TagsGroup
              activeTagIds={activeTagIds}
              tags={tags}
              tagIds={tagIds}
              title="Channel Tags"
              onClick={handleTagClick}
              tagsDisabled={!userHasAnyOfPermissions([CHANNEL_EDIT])}
            />
          )}

          {hasChannelType && (
          <MemberGroupSearch
            title="Channel Route"
            titleSub="Select a Group or Member to route this channel to."
            name="route"
            validationMessage={errors.route}
            handleSelectGroup={(id) => handleUpdateSelectedId('selectedRouteGroupId', id)}
            handleSelectMember={(id) => handleUpdateSelectedId('selectedRouteMemberId', id)}
            selectedMemberId={selectedRouteMemberId}
            selectedGroupId={selectedRouteGroupId}
          />
          )}

          {showBusinessHours && (
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">Availability Hours</div>
                <div className="box__subtitle">
                  This channel will be routed to a Member/Group that may have its own availability hours.&nbsp;
                  You may override these hours by setting availability hours for this channel.
                </div>
              </div>

              <RhinoSwitch
                label="Channel Availability Hours"
                name="afterHoursEnabled"
                isChecked={afterHoursEnabled}
                onChange={handleChange}
              />

              {afterHoursEnabled && (
                <>
                  <BusinessHours
                    businessHours={businessHours}
                    handleChange={handleChange}
                  />

                  <div className="form__group">
                    <label // eslint-disable-line jsx-a11y/label-has-for
                      className="u-block u-m-b-small"
                    >
                      Time Zone
                    </label>
                    {formatTimeZone(timeZones[timeZoneId])}
                  </div>
                  <Textarea
                    label="Auto-Response"
                    name="autoResponse"
                    initialValue={autoResponse}
                    onChange={handleChange}
                    textareaRef={autoResponseRef}
                    emojiSupport
                  />
                  <div className="u-flex u-flex-direction-row u-flex-align-items-center u-m-t-small">
                    <EmojiPickerDropdown
                      inputName="autoResponse"
                      inputValue={autoResponse}
                      inputRef={autoResponseRef}
                      handleInputChange={handleChange}
                      dropdownPosition="bottom"
                    />
                    <CharacterCount length={autoResponse.length} />
                  </div>
                  <div className="form__explanation-message">This auto-response will be sent to any patient who sends a message to this channel outside set availability hours.</div>
                </>
              )}
            </div>
          )}

          {showForwarding && (
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">Channel Forwarding</div>
                <div className="box__subtitle">Allow this channel to be forwarded to another group or member.</div>
              </div>
              <RhinoSwitch
                label="Allow Forwarding"
                name="allowReroute"
                isChecked={allowReroute}
                onChange={handleChange}
              />
            </div>
          )}

          {showWidgetAddon && renderChannelWidget()}

          <div className="u-m-t-large">
            {renderStandardStatus()}
          </div>
        </form>
      </div>
    </div>
  );
};

OrganizationChannelForm.propTypes = {
  activeTagIds: PropTypes.array.isRequired,
  afterHoursEnabled: PropTypes.bool.isRequired,
  allowReroute: PropTypes.bool.isRequired,
  autoResponse: PropTypes.string,
  bandwidthForwardingNumber: PropTypes.string,
  bandwidthNumber: PropTypes.string,
  businessHours: PropTypes.array.isRequired,
  channelName: PropTypes.string.isRequired,
  channelPurpose: PropTypes.string,
  channelType: PropTypes.string.isRequired,
  copyEmbedCodeText: PropTypes.string.isRequired,
  embedUrl: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  existingNumber: PropTypes.bool.isRequired,
  facebookPageId: PropTypes.string.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleWidgetConfigChange: PropTypes.func.isRequired,
  handleConfirmDeleteRequest: PropTypes.func.isRequired,
  handleCopyEmbedCodeClick: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  handleFacebookPageSelect: PropTypes.func.isRequired,
  handleInstagramPageSelect: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleTagClick: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleUpdateSelectedId: PropTypes.func.isRequired,
  handleModalReverseComplete: PropTypes.func.isRequired,
  instagramPageId: PropTypes.string.isRequired,
  isRhinocallEnabled: PropTypes.bool,
  landlineNumber: PropTypes.string,
  mode: PropTypes.string.isRequired,
  modalFormInProgress: PropTypes.bool.isRequired,
  observesDst: PropTypes.bool.isRequired,
  pageContainerRef: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  selectedRouteMemberId: PropTypes.number.isRequired,
  selectedRouteGroupId: PropTypes.number.isRequired,
  showDeleteChannelModal: PropTypes.bool.isRequired,
  tagIds: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  timeZoneId: PropTypes.number,
  timeZones: PropTypes.object.isRequired,
  userIsCcr: PropTypes.bool.isRequired,
  isWarning: PropTypes.bool.isRequired,
  perChannelFee: PropTypes.number.isRequired,
  isWebWidgetEnabled: PropTypes.bool.isRequired,
  widgetActiveSection: PropTypes.string.isRequired,
  widgetConfig: PropTypes.object.isRequired,
  isWebFormTitleBackgroundColorPickerVisible: PropTypes.bool.isRequired,
  isWebFormButtonBackgroundColorPickerVisible: PropTypes.bool.isRequired,
  isWebFormTitleTextColorPickerVisible: PropTypes.bool.isRequired,
  isWebFormButtonTextColorPickerVisible: PropTypes.bool.isRequired,
  handleColorPickerToggle: PropTypes.func.isRequired,
  provisionServiceOptions: PropTypes.array.isRequired,
  provisionServiceType: PropTypes.number.isRequired,
  isCallEnabled: PropTypes.bool,
  rhinocallLimitReached: PropTypes.bool,
  organizationId: PropTypes.number.isRequired,
};

export default OrganizationChannelForm;
