import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import { AuthActionTypes, InboxActionTypes, UserActionTypes } from '../constants';

import * as ChannelActionTypes from '../constants/ChannelActionTypes';
import * as SavedContentActionTypes from '../constants/SavedContentActionTypes';
import * as FormActionTypes from '../constants/FormActionTypes';

import { setError } from './uiReducer';

// SLICE
const emailSlice = createSlice({
  name: 'EMAIL',
  initialState: {
    emails: {},
    emailIds: [],
  },
  reducers: {
    receiveEmails: receiveEmailsData,
  },
  extraReducers: {
    [ChannelActionTypes.receiveChannelView]: receiveEmailsData, // SHARED
    [ChannelActionTypes.receiveChannels]: receiveEmailsData,

    [InboxActionTypes.receiveInboxThreadView]: receiveEmailsData,

    [AuthActionTypes.setUser]: receiveEmailsData,

    [UserActionTypes.receiveUsers]: receiveEmailsData,
    [UserActionTypes.receiveUsersSearch]: receiveEmailsData,
    [UserActionTypes.receiveContactUsers]: receiveEmailsData,
    [UserActionTypes.receiveConnectedPartySearch]: receiveEmailsData,
    [UserActionTypes.receiveUser]: receiveEmailsData,
    [UserActionTypes.receiveCreateUser]: receiveEmailsData,
    [UserActionTypes.receiveUpdateUser]: receiveEmailsData,
    [UserActionTypes.receiveContactEditFormView]: receiveEmailsData,
    [UserActionTypes.receiveContactList]: receiveEmailsData,

    [SavedContentActionTypes.receiveEventsForSavedContent]: receiveEmailsData,
    [FormActionTypes.receiveContactForms]: receiveEmailsData,
  },
});

export default emailSlice.reducer;

// ACTIONS
export const { receiveEmails } = emailSlice.actions;

// REDUCER HELPERS
function receiveEmailsData(state, action) {
  return {
    ...state,
    emails: {
      ...state.emails,
      ...action.payload.emails,
    },
    emailIds: [...new Set([...state.emailIds, ...action.payload.emailIds])],
  };
}

// THUNKS -- ASYNC ACTION CREATORS
export function sendEmail(payload) {
  return (dispatch) =>
    axios.post('/sendEmail', payload)
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
}
