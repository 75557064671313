import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import cx from 'classnames';
import {
  Icon,
} from 'rhinostyle';

import {
  UserHelpers,
} from '../../helpers';

const {
  formatConsentStatus,
} = UserHelpers;

const ConsentEvent = (props) => {
  const { event } = props;

  const {
    fromUser = {},
    consentStatus,
    formattedTimestamp,
  } = event;

  const formattedConsentStatus = formatConsentStatus(consentStatus);
  const fromUserName = `${fromUser?.firstName || ''} ${fromUser?.lastName || ''}`;
  const consentIconClasses = cx('convo__event__icon', {
    'is-denied': formattedConsentStatus === 'Denied',
    'is-unknown': formattedConsentStatus === 'Unknown',
    'is-granted': formattedConsentStatus === 'Granted',
  });

  const renderHipaaEvent = () => {
    const hipaaIconClasses = cx('convo__event__icon hipaa-status__icon', {
      'is-denied': formattedConsentStatus === 'Denied',
      'is-unknown': formattedConsentStatus === 'Unknown',
      'is-granted': formattedConsentStatus === 'Granted',
    });

    return (
      <>
        <div className="convo__event__title">
          <Icon bump="up" icon="hipaa" className={hipaaIconClasses} />
          Consent Marked <strong>{formattedConsentStatus}</strong> by {fromUserName}
        </div>
        <div className="convo__event__subtext">{formattedTimestamp}</div>
      </>
    );
  };

  const renderRhinopayEvent = () => (
    <>
      <div className="convo__event__title">
        <Icon bump="up" icon="rhinopay" className={consentIconClasses} />
        Consent Marked <strong>{formattedConsentStatus}</strong> by {fromUserName}
      </div>
      <div className="convo__event__subtext">
        {formattedTimestamp}
      </div>
    </>
  );

  const renderMarketingEvent = () => (
    <>
      <div className="convo__event__title">
        <Icon bump="up" icon="marketing" className={consentIconClasses} />
        Consent Marked <strong>{formattedConsentStatus}</strong> by {fromUserName}
      </div>
      <div className="convo__event__subtext">{formattedTimestamp}</div>
    </>
  );

  return (
    <>
      {event.isHipaaEvent && renderHipaaEvent()}
      {event.isRhinopayEvent && renderRhinopayEvent()}
      {event.isMarketingEvent && renderMarketingEvent()}
    </>
  );
};

ConsentEvent.propTypes = {
  event: PropTypes.object,
};

export default ConsentEvent;
