import OutboundPostMessageService from './OutboundPostMessageService';

class OneSignalService {
  sendTagsWeb(userId, orgId) {
    OneSignal.push(() => {
      OneSignal.sendTags({ userId, orgId, device: 'web' });
    });
  }

  // Post mobile tags so React Native can register device
  sendTagsNative(userId, orgId) {
    OutboundPostMessageService.postMessage({
      type: 'oneSignalTags',
      data: {
        userId,
        orgId,
        device: 'mobile',
      },
    });
  }

  isPushNotificationsSupported() {
    return OneSignal.isPushNotificationsSupported ? OneSignal.isPushNotificationsSupported() : false;
  }

  register() {
    OneSignal.push(() => {
      OneSignal.registerForPushNotifications();
    });
  }

  subscribe() {
    OneSignal.push(() => {
      OneSignal.setSubscription(true);
    });
  }

  // Mute notifications, e.g. while app has focus
  unsubscribe() {
    OneSignal.push(() => {
      OneSignal.setSubscription(false);
    });
  }

  getSubscriptionState() {
    return Promise.all([
      OneSignal.isPushNotificationsEnabled(),
      OneSignal.getNotificationPermission(),
    ]).then((results) => {
      const isPushNotificationsEnabled = results[0];
      const browserNotificationPermission = results[1];

      return { isPushNotificationsEnabled, browserNotificationPermission };
    });
  }
}

export default new OneSignalService();
