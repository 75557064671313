import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { UtilitySystem } from 'rhinostyle';

import * as UserHelpers from '../helpers/UserHelpers';
import MultiOrgContainer from '../containers/MultiOrgContainer';

import { AUDIT_LOG_VIEW, ANALYTICS_VIEW } from '../constants/UserPermissionsConstants';

const AnalyticsMenu = (props) => {
  const history = useHistory();
  const {
    handleNavigationClick,
    organizations,
    currentUser,
  } = props;

  const { userHasAnyOfPermissions } = UserHelpers;

  const menuOptions = [
    {
      title: 'Messaging',
      id: 'nav-messaging-analytics',
      to: '/analytics/messaging',
      dataCypress: 'appointmentManagerOption',
      isVisible: true,
    },
    {
      title: 'Conversation Activity',
      id: 'nav-conversation-activity',
      to: '/analytics/conversation-activity',
      dataCypress: 'appointmentManagerOption',
      isVisible: true,
    },
    {
      title: 'HIPAA Campaigns',
      id: 'nav-settings-hipaa-campaign',
      to: '/analytics/hipaa-campaigns',
      dataCypress: 'hipaaCampaignOption',
      isVisible: userHasAnyOfPermissions([ANALYTICS_VIEW]),
    },
    {
      title: 'Audit Log',
      id: 'nav-settings-audit-log',
      to: '/analytics/auditLog',
      dataCypress: 'auditLogOption',
      isVisible: userHasAnyOfPermissions([AUDIT_LOG_VIEW]),
    },
  ];

  const renderMenuListItems = () =>
    menuOptions
      .filter((option) => option.isVisible)
      .map((option) => (
        <li key={option.id} className="app-navigation__nav__sub">
          <NavLink
            id={option.id}
            to={option.to}
            data-cypress={option.dataCypress}
            onClick={handleNavigationClick}
            className="app-navigation__nav__button"
            activeClassName={UtilitySystem.config.classes.active}
          >
            <span>{option.title}</span>
          </NavLink>
        </li>
      ));

  return (
    <nav id="app-navigation" className="app-navigation">
      <div className="app-navigation__container">
        <div className="app-navigation__top">
          <div className="app-navigation__container__scroll">
            <ul className="app-navigation__nav">
              <li className="app-navigation__nav__header">
                <span className="app-navigation__nav__button__text">Analytics Dashboard</span>
              </li>
              <div className="app-navigation__list-item__divider" />
              {renderMenuListItems()}
            </ul>
          </div>
        </div>
        <div className="app-navigation__bottom">
          {currentUser.organization && (
          <div className="app-navigation__org">
            {organizations[currentUser.organization].name}
            <span>Organization{UserHelpers.isCCR(currentUser) && ` (#${organizations[currentUser.organization].id})`}</span>
          </div>
          )}
          <MultiOrgContainer history={history} />
        </div>
      </div>
    </nav>
  );
};

AnalyticsMenu.propTypes = {
  handleNavigationClick: PropTypes.func,
  organizations: PropTypes.object,
  currentUser: PropTypes.object,
};

export default AnalyticsMenu;
