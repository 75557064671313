import threadService from '../services/threadService';
import { useThreadOptions } from '.';

const useEventById = (eventId) => {
  const threadOptions = useThreadOptions();
  const selectedEvent = threadService.endpoints.getThread.useQueryState(threadOptions, {
    skip: !eventId,
    selectFromResult: (result) => {
      let routeEvent = {};
      if (eventId && result?.data) {
        routeEvent = result?.data?.entities[eventId];
      }
      return routeEvent;
    },
  });
  return selectedEvent;
};

export default useEventById;
