import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Select,
  Button,
  Icon,
} from 'rhinostyle';
import { CONNECTION_RESULT_OPTIONS } from '../constants/RhinocallConstants';
import { updateCallConnectionResult, resetRhinocallState, setActiveCallId } from '../reducers/rhinocallReducer';

const RhinocallComplete = (props) => {
  const dispatch = useDispatch();
  const {
    callId,
    formInProgress,
  } = props;
  const [selectedOption, setSelectedOption] = useState('');
  const [endedCallId, setEndedCallId] = useState('');

  useEffect(() => {
    setEndedCallId(callId);
    dispatch(setActiveCallId(null));
  }, []);

  function handleChange(name, value) {
    setSelectedOption(value);
  }
  function handleCancelClick() {
    dispatch(resetRhinocallState());
  }

  async function handleSubmit() {
    await dispatch(updateCallConnectionResult(endedCallId, selectedOption));
  }
  return (
    <div className="rhinocall__form">
      <Button
        reset
        onClick={handleCancelClick}
        size="small"
        className="rhinocall__close u-text-white"
      >
        <Icon icon="close" />
      </Button>
      <div className="rhinocall__title__complete">Call Connection Status</div>
      <Select
        onSelect={handleChange}
        placeholder="Select"
        label="Connection Result"
        required
        name="connectionResult"
        options={CONNECTION_RESULT_OPTIONS}
        selected={selectedOption}
        className="rhinocall__input"
        dataCypress="connectionResult"
      />
      <div className="rhinocall__footer">
        <Button
          className="u-m-r"
          block
          type="danger"
          onClick={handleCancelClick}
          data-cypress="cancelConnectionResult"
        >
          Cancel
        </Button>
        <Button
          loading={formInProgress}
          disabled={!selectedOption}
          block
          type="secondary"
          onClick={handleSubmit}
          data-cypress="submitConnectionResult"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

RhinocallComplete.propTypes = {
  callId: PropTypes.string,
  formInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  callId: state.rhinocall.callId,
  formInProgress: state.rhinocall.formInProgress,
});

export default connect(mapStateToProps)(RhinocallComplete);
