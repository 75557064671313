import moment from 'moment-timezone';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IntegrationAnalytics from '../components/IntegrationAnalytics';
import * as OfficeReducer from '../reducers/officeReducer';
import { getLinerAnalytics, getFeederAnalytics, getApiAnalytics } from '../reducers/analyticsReducer';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import PageLoader from '../components/PageLoader';

class IntegrationAnalyticsContainer extends Component {
  state = {
    activeTab: 1, // Feeder tab
    defaultDateApi: moment.utc(),
    selectedDateApi: moment.utc(),
    selectedDateFeeder: moment.utc(),
    selectedDateLiner: moment.utc().subtract(1, 'day'),
    selectedFeederJobIndex: 0,
    loading: true,
    showExpandedPatientIssues: false,
    showExpandedOtherIssues: false,
    showApiTimestamp: true,
    linerData: {},
    feederData: [],
    apiData: {},
    hasDataApi: false,
    hasDataLiner: false,
    hasDataFeeder: false,
    isTabLoading: true,
  }

  componentDidMount() {
    this.props.fetchOffices();

    // Feeder is the open tab by default, so fetch data from Feeder
    const defaultDateFeeder = moment.utc().format('YYYY-MM-DD');
    getFeederAnalytics(this.props.organization.id, defaultDateFeeder)
      .then((feederAnalytics) => {
        this.handleUpdateFeederDataState(feederAnalytics);
      })
      .catch((err) => {
        this.setState({ isTabLoading: false });
        console.error(err);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loading && this.props.isOfficeLoading) {
      // disabling due to future rule change in progress from airbnb
      // see https://reactjs.org/docs/react-component.html#componentdidupdate for explanation
      this.setState({ loading: false }); // eslint-disable-line react/no-did-update-set-state
    }
    // if different date is selected, fetch data from respective services
    if (prevState.selectedDateLiner !== this.state.selectedDateLiner) {
      this.setState({ isTabLoading: true }); // eslint-disable-line react/no-did-update-set-state
      const date = this.state.selectedDateLiner.format('YYYY-MM-DD');
      getLinerAnalytics(this.props.organization.id, date)
        .then((linerAnalytics) => {
          this.handleUpdateLinerDataState(linerAnalytics);
        })
        .catch((err) => {
          this.setState({ isTabLoading: false });
          console.error(err);
        });
    }
    if (prevState.selectedDateFeeder !== this.state.selectedDateFeeder) {
      this.setState({ isTabLoading: true }); // eslint-disable-line react/no-did-update-set-state
      const date = this.state.selectedDateFeeder.format('YYYY-MM-DD');
      getFeederAnalytics(this.props.organization.id, date)
        .then((feederAnalytics) => {
          this.handleUpdateFeederDataState(feederAnalytics);
        })
        .catch((err) => {
          this.setState({ isTabLoading: false });
          console.error(err);
        });
    }
    if (prevState.selectedDateApi !== this.state.selectedDateApi) {
      this.setState({ isTabLoading: true }); // eslint-disable-line react/no-did-update-set-state
      const date = this.state.selectedDateApi.format('YYYY-MM-DD');
      getApiAnalytics(this.props.organization.id, date)
        .then((apiAnalytics) => {
          this.handleUpdateApiDataState(apiAnalytics);
        })
        .catch((err) => {
          this.setState({ isTabLoading: false });
          console.error(err);
        });
    }
  }

  toggleShowApiTimestamp = (selectedDate) => {
    if (selectedDate.diff(this.state.defaultDateApi, 'days') !== 0) {
      this.setState({ showApiTimestamp: false });
    } else {
      this.setState({ showApiTimestamp: true });
    }
  }

  handleUpdateLinerDataState = (linerResponse) => {
    this.setState({ isTabLoading: false });
    if (!linerResponse || linerResponse.data === 'No data available for this date') {
      this.setState({ linerData: {}, hasDataLiner: false });
    } else {
      this.setState({ linerData: linerResponse.data, hasDataLiner: true });
    }
  }

  handleUpdateFeederDataState = (feederResponse) => {
    this.setState({ isTabLoading: false });
    if (!feederResponse || feederResponse.data === 'No data available for this date' || !feederResponse.data?.feederJobs || !feederResponse.data.feederJobs.length) {
      this.setState({ feederData: [], hasDataFeeder: false });
    } else {
      this.setState({ feederData: feederResponse.data.feederJobs, hasDataFeeder: true });
    }
  }

  handleUpdateApiDataState = (apiResponse) => {
    this.setState({ isTabLoading: false });
    if (!apiResponse || apiResponse.data === 'No data available for this date') {
      this.setState({ apiData: {}, hasDataApi: false });
    } else {
      this.setState({ apiData: apiResponse.data, hasDataApi: true });
    }
  }

  handleChange = (keyName, newValue) => {
    if (keyName === 'selectedDateApi') {
      this.toggleShowApiTimestamp(newValue);
    }
    if (keyName === 'selectedDateFeeder') {
      this.setState({ selectedFeederJobIndex: 0 });
    }
    this.setState({ [keyName]: newValue });
  }

  updateActiveTab = (key) => {
    if (key === 2 && Object.entries(this.state.linerData).length === 0) {
      this.setState({ isTabLoading: true });
      const linerDefaultDate = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
      getLinerAnalytics(this.props.organization.id, linerDefaultDate)
        .then((linerAnalytics) => {
          if (linerAnalytics.data === 'No data available for this date') {
            this.setState({ linerData: {}, hasDataLiner: false, isTabLoading: false });
          } else {
            this.setState({ linerData: linerAnalytics.data, hasDataLiner: true, isTabLoading: false });
          }
        })
        .catch((err) => {
          this.setState({ isTabLoading: false });
          console.error(err);
        });
    }
    if (key === 3 && Object.entries(this.state.apiData).length === 0) {
      this.setState({ isTabLoading: true });
      getApiAnalytics(this.props.organization.id, this.state.defaultDateApi.format('YYYY-MM-DD'))
        .then((apiAnalytics) => {
          if (apiAnalytics.data === 'No data available for this date') {
            this.setState({ apiData: {}, hasDataApi: false, isTabLoading: false });
          } else {
            this.setState({ apiData: apiAnalytics.data, hasDataApi: true, isTabLoading: false });
          }
        })
        .catch((err) => {
          this.setState({ isTabLoading: false });
          console.error(err);
        });
    }
    this.setState({ activeTab: key });
  }

  render() {
    const props = {
      activeTab: this.state.activeTab,
      handleChange: this.handleChange,
      integrationPartnerTypeId: this.props.organization.integrationPartnerTypeId,
      updateActiveTab: this.updateActiveTab,
      // --api--
      apiData: this.state.apiData,
      defaultDateApi: this.state.defaultDateApi,
      selectedDateApi: this.state.selectedDateApi,
      // --liner--
      linerData: this.state.linerData,
      selectedDateLiner: this.state.selectedDateLiner,
      // --feeder--
      feederData: this.state.feederData,
      offices: this.props.offices,
      selectedDateFeeder: this.state.selectedDateFeeder,
      selectedFeederJobIndex: this.state.selectedFeederJobIndex,
      showExpandedOtherIssues: this.state.showExpandedOtherIssues,
      showExpandedPatientIssues: this.state.showExpandedPatientIssues,
      showApiTimestamp: this.state.showApiTimestamp,
      hasDataApi: this.state.hasDataApi,
      hasDataFeeder: this.state.hasDataFeeder,
      hasDataLiner: this.state.hasDataLiner,
      isTabLoading: this.state.isTabLoading,
    };

    if (this.state.loading) {
      return <PageLoader />;
    }

    return (
      <IntegrationAnalytics {...props} />
    );
  }
}

IntegrationAnalyticsContainer.propTypes = {
  organization: PropTypes.object,
  fetchOffices: PropTypes.func,
  isOfficeLoading: PropTypes.bool,
  offices: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { office } = state;
  return {
    organization: getCurrentOrg(state),
    offices: office.offices,
    isOfficeLoading: office.pageLoading,
  };
};

const actions = {
  fetchOffices: OfficeReducer.fetchOffices,
};

export default connect(mapStateToProps, actions)(IntegrationAnalyticsContainer);
