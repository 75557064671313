import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenuItem } from 'rhinostyle';

const HelpDropdown = (props) => (
  <Dropdown
    title="Help"
    icon="question-circle"
    hideCaret
    wide
    lockLabel
    {...props}
  >
    <DropdownMenuItem
      label="Rhinogram Academy"
      url="https://rhinogram.freshdesk.com/support/solutions/articles/36000093240-rhinogram-university"
      blankWindow
    />
    <DropdownMenuItem
      label="Help Center"
      url="https://rhinogram.freshdesk.com/support/solutions"
      blankWindow
    />
    <DropdownMenuItem
      label="Submit an Issue"
      url="https://rhinogram.freshdesk.com/support/tickets/new"
      blankWindow
    />
    <DropdownMenuItem
      label="Idea Submission"
      url="https://rhinogram.freshdesk.com/support/tickets/new"
      blankWindow
    />
    <Link to="/diagnostics">
      <DropdownMenuItem
        label="System Details"
      />
    </Link>
  </Dropdown>
);

export default HelpDropdown;
