import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  Scrollbars,
  UtilityInlineGrid,
  UtilityList,
} from 'rhinostyle';

import SingleAttachmentPreview from './SingleAttachmentPreview';
import { TEMPLATE_MESSAGE_EDIT } from '../constants/UserPermissionsConstants';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { updatedAtDateFormatter } from '../helpers/DateHelpers';
import { replaceVariablesByStyledComponent, replaceNewLinesToLineBreaks } from '../helpers/TemplateHelpers';
import { TEMPLATE_MESSAGE_VARIABLE_OPTIONS } from '../constants/VariableMessageConstants';
import { TYPE_TEMPLATE_CONTACT } from '../constants/Types';

const MessageTemplatePanel = (props) => {
  const history = useHistory();
  const {
    activeMessageTemplate,
    handleTogglePanel,
    getFormS3Url,
    formS3Url,
  } = props;

  const pushRoute = (url) => history.push(url);

  const renderAttachment = (attachment, key) => (
    <SingleAttachmentPreview
      attachment={attachment}
      index={key}
      key={key}
      getFormS3Url={getFormS3Url}
      formS3Url={formS3Url}
    />
  );

  const renderTemplateText = (text) => {
    const formattedText = replaceNewLinesToLineBreaks(text);
    return ReactHtmlParser(replaceVariablesByStyledComponent(TEMPLATE_MESSAGE_VARIABLE_OPTIONS, formattedText));
  };

  const renderLastUpdated = (messageTemplate) => (
    messageTemplate?.lastModifiedBy ? ` by ${messageTemplate?.lastModifiedBy?.firstName} ${messageTemplate?.lastModifiedBy?.lastName}` : ''
  );

  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title">Summary</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => handleTogglePanel(activeMessageTemplate.id)}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__title">
                {activeMessageTemplate.subject}
              </div>
              {userHasAnyOfPermissions([TEMPLATE_MESSAGE_EDIT]) && (
                <div className="summary-panel__intro__actions">
                  <UtilityInlineGrid align="middle">
                    <Button
                      size="small"
                      onClick={() => pushRoute(`/settings/organization/library/templates/edit/${activeMessageTemplate.id}`)}
                      type="primary"
                      outlined
                      data-cypress="editTemplate"
                    >
                      Edit Template
                    </Button>
                  </UtilityInlineGrid>
                </div>
              )}
            </div>
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Message</div>
              <div className="summary-panel__section__content">
                {activeMessageTemplate.typeId === TYPE_TEMPLATE_CONTACT ? renderTemplateText(activeMessageTemplate.message) : activeMessageTemplate.message}
              </div>
            </div>
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Updated</div>
              <div className="summary-panel__section__content">
                <div>{`${updatedAtDateFormatter(activeMessageTemplate.lastUpdatedAt)}${renderLastUpdated(activeMessageTemplate)}`}</div>
              </div>
            </div>
            {activeMessageTemplate.attachments && activeMessageTemplate.attachments.length > 0 && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Files</div>
                <div className="summary-panel__section__content">
                  <UtilityList space className="u-m-b-0">
                    {activeMessageTemplate.attachments.map(renderAttachment)}
                  </UtilityList>
                </div>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </div>

  );
};

MessageTemplatePanel.propTypes = {
  activeMessageTemplate: PropTypes.object.isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
  getFormS3Url: PropTypes.func.isRequired,
  formS3Url: PropTypes.object,
};

export default MessageTemplatePanel;
