import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  CheckboxGroup,
  Checkbox,
  Date,
  Icon,
  Input,
  Select,
  Textarea,
  UtilityInlineGrid,
} from 'rhinostyle';
import moment from 'moment-timezone';

import BackButtonContainer from '../containers/BackButtonContainer';
import ChannelRoutes from './ChannelRoutes';
import { businessHoursArray, fetchSelection } from '../constants/BusinessHoursConstants';
import CharacterCount from './CharacterCount';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { OUT_OF_OFFICE_DELETE } from '../constants/UserPermissionsConstants';
import EmojiPickerDropdown from './EmojiPickerDropdown';

const OrganizationOutOfOfficeForm = (props) => {
  const location = useLocation();
  const history = useHistory();
  const textareaRef = useRef();
  const {
    actionTitle,
    channelIds,
    channels,
    errors,
    formInProgress,
    from,
    handleChange,
    handleDestroyOOO,
    handleEndDateChange,
    handleFormChanges,
    handleSelect,
    handleStartDateChange,
    handleSubmit,
    handleToggle,
    handleToggleClosed,
    handleUpdateSelectedIds,
    isClosedAllDay,
    message,
    mode,
    pageContainerRef,
    pageTitle,
    phones,
    selectedChannelIds,
    showDeleteButton,
    showDeleteConfirmation,
    title,
    to,
    viewAllChannels,
  } = props;

  const renderDeleteConfirm = () => (
    <div className="item-status__confirm">
      <div className="item-status__confirm__text">
        <Icon bump="up" icon="trash" /> Are you sure you want to delete this event?
      </div>
      <UtilityInlineGrid size="regular" align="middle">
        <Button size="small" type="link-muted" onClick={() => handleChange('showDeleteConfirmation', false)}>
          Cancel
        </Button>
        <Button
          loading={formInProgress}
          size="small"
          type="danger"
          onClick={handleDestroyOOO}
          data-cypress="confirmDelete"
        >
          Yes, delete event
        </Button>
      </UtilityInlineGrid>
    </div>
  );

  const saveBtnType = mode === 'create' ? 'secondary' : 'primary';

  const renderStandardStatus = () => {
    const dataCypress = actionTitle === 'Create Event' ? 'createEvent' : 'updateEvent';
    return (
      <div className="item-status">
        {(showDeleteButton && userHasAnyOfPermissions([OUT_OF_OFFICE_DELETE])) && (
          <div className="item-status__left">
            <Button
              reset
              onClick={() => handleChange('showDeleteConfirmation', true)}
              className="item-status__delete-icon"
              title="Delete Out of Office Event"
            >
              <Icon bump="down" size="large" icon="trash" />
            </Button>
          </div>
        )}
        <div className="item-status__right">
          <Button
            type={saveBtnType}
            loading={formInProgress}
            onClick={handleSubmit}
            data-cypress={dataCypress}
          >
            {actionTitle}
          </Button>
        </div>
      </div>
    );
  };

  const fromTimeSelectOption = from ? fetchSelection(from.format('HH:mm:ss')) : {};
  const toTimeSelectOption = to ? fetchSelection(to.format('HH:mm:ss')) : {}; // Could return undefined when closed all day was checked ('to' time will be 23:59:59)

  return (
    <div className="app-page__container" ref={pageContainerRef}>
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title">
            <BackButtonContainer navigateTo="/settings/organization/out-of-office" history={history} location={location} />
            {pageTitle}
          </div>
        </div>

        <form onChange={handleFormChanges}>
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">Message</div>
            </div>

            <div className="row u-flex-justify-center">
              <div className="column-9@small">
                <Input
                  initialValue={title}
                  label="Title"
                  name="title"
                  onChange={handleChange}
                  required
                  validationMessage={errors.title}
                />

                <Textarea
                  initialValue={message}
                  label="Message"
                  name="message"
                  required
                  onChange={handleChange}
                  validationMessage={errors.message}
                  textareaRef={textareaRef}
                  emojiSupport
                />
                <div className="u-flex u-flex-direction-row u-flex-align-items-center u-m-t-small">
                  <EmojiPickerDropdown
                    inputName="message"
                    inputValue={message}
                    inputRef={textareaRef}
                    handleInputChange={handleChange}
                    dropdownPosition="bottom"
                  />
                  <CharacterCount length={message.length} />
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">Dates</div>
              <div className="box__subtitle">Date range in which the automated message should be sent if the patient sends a message</div>
            </div>

            <div className="form__group">
              <div className="row u-flex-align-items-center">
                <div className="column-4@small column--last@small">
                  {from && to && (
                    <CheckboxGroup className="ooo__closed">
                      <Checkbox
                        onChange={handleToggleClosed}
                        name="closed"
                        label="Office is closed all day"
                        isChecked={isClosedAllDay}
                      />
                    </CheckboxGroup>
                  )}
                </div>
                <div className="column-8@small">
                  <div className="row">
                    <div className="column-6@xsmall">
                      <Date
                        id="from"
                        label="From"
                        name="from"
                        placeholderText="Select a date"
                        required
                        selected={from}
                        validationMessage={errors.from}
                        minDate={moment()}
                        onChange={(date) => {
                          if (date) {
                            handleStartDateChange(date);
                          }
                        }}
                        onChangeRaw={() => handleChange('from', null)}
                      />
                    </div>
                    <div className="column-6@xsmall">
                      {!isClosedAllDay && from && (
                        <Select
                          label="&#8203;"
                          name="fromTime"
                          onSelect={handleSelect}
                          selected={fromTimeSelectOption.id}
                          options={businessHoursArray}
                          className="ooo__select"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form__group">
              <div className="row u-flex-align-items-center">
                <div className="column-8@small">
                  <div className="row">
                    <div className="column-6@xsmall">
                      <Date
                        id="to"
                        label="To"
                        name="to"
                        placeholderText="Select a date"
                        required
                        selected={to}
                        validationMessage={errors.to}
                        minDate={from}
                        onChange={(date) => {
                          if (date) {
                            handleEndDateChange(date);
                          }
                        }}
                        onChangeRaw={() => handleChange('to', null)}
                      />
                    </div>
                    <div className="column-6@xsmall">
                      {!isClosedAllDay && to && (
                        <Select
                          label="&#8203;"
                          name="toTime"
                          onSelect={handleSelect}
                          selected={toTimeSelectOption.id}
                          options={businessHoursArray}
                          className="ooo__select"
                        />
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ChannelRoutes
            allowWarning={false}
            channelIds={channelIds}
            channels={channels}
            error={errors.channels}
            filterChannels={false}
            handleToggleViewAll={() => handleToggle('viewAllChannels')}
            handleUpdateSelectedIds={handleUpdateSelectedIds}
            selectedChannelIds={selectedChannelIds}
            title="Channels"
            viewAllChannels={viewAllChannels}
            phones={phones}
          />

          <div className="u-m-t-large">
            {showDeleteConfirmation ?
              renderDeleteConfirm() :
              renderStandardStatus()}
          </div>
        </form>
      </div>
    </div>
  );
};

OrganizationOutOfOfficeForm.propTypes = {
  actionTitle: PropTypes.string.isRequired,
  channelIds: PropTypes.array.isRequired,
  channels: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  from: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleDestroyOOO: PropTypes.func.isRequired,
  handleEndDateChange: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleStartDateChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleToggleClosed: PropTypes.func.isRequired,
  handleUpdateSelectedIds: PropTypes.func.isRequired,
  isClosedAllDay: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  pageContainerRef: PropTypes.func.isRequired,
  pageTitle: PropTypes.string.isRequired,
  phones: PropTypes.object.isRequired,
  selectedChannelIds: PropTypes.array.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  showDeleteConfirmation: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.object,
  viewAllChannels: PropTypes.bool.isRequired,
};

export default OrganizationOutOfOfficeForm;
