import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useGetPrescriptionCampaignQuery } from '../services/prescriptionService';

const useFetchPrescriptionCampaign = (id) => {
  const params = useParams();
  const organizationId = useSelector((state) => state.auth.currentOrg);
  const prescriptionCampaignId = id || Number(params?.prescriptionCampaignId);
  return useGetPrescriptionCampaignQuery({ prescriptionCampaignId, organizationId }, {
    skip: !prescriptionCampaignId,
  });
};

export default useFetchPrescriptionCampaign;
