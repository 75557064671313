import { createSlice } from '@reduxjs/toolkit';
import {
  connect,
  createLocalAudioTrack,
  createLocalTracks,
  createLocalVideoTrack,
  LocalDataTrack,
} from 'twilio-video';

import RhinovideoUnmute from '../assets/audio/rhinovideo-unmute.mp3';
import RhinovideoMute from '../assets/audio/rhinovideo-mute.mp3';
import NotificationService from '../services/NotificationService';
import OutboundPostMessageService from '../services/OutboundPostMessageService';
import {
  getContactVideoSecrets,
  getOrganization,
  getVideo,
  updateVideo,
  postOrganization,
  patchContactParticipant,
  patchParticipantsInfo,
  patchParticipant,
  getVideoSecrets,
  getVideos,
  postVideoConference,
} from '../services/RhinovideoService';
import { getRoom, setRoom, getAudioContext, disconnectRoom, getConstraints, getVideoSources, getAudioSources } from '../services/RhinovideoRoomService';
import { rhinovideoScreenShareStreamId, rhinovideoVideoStreamId } from '../constants/RhinovideoConstants';
import { isAndroid, isMobile, isIos, getIsWebkitAudioWorkaroundNeeded } from '../helpers/BrowserHelpers';
import { getLoggedInUser } from '../selectors/userSelectors';
import { shapeParticipantsInfo } from '../helpers/RhinovideoHelper';
// SLICE
const rhinovideoSlice = createSlice({
  name: 'RHINOVIDEO',
  initialState: {
    participants: {},
    org: {},
    isScreenSharePaused: false,
    loading: true,
    conferenceJoined: false,
    error: null,
    conferences: [],
    twilioParticipantId: null,
    availableVideoSources: [],
    isVideoSourcesFetched: false,
    cameraId: '',
    pinnedSpeakerId: null,
    created: '',
    hasLeft: false,
    isButtonDisabled: false,
    isCameraEnabled: false,
    isMicrophoneEnabled: false,
    isParticipantsPanelOpen: false,
    isScreenShareEnabled: false,
    isVideoEnabled: false,
    microphoneId: '',
    notFound: false,
    participantEndedCall: false,
    vendor: {},
    pinnedUserId: null,
    volumeGain: 5,
  },
  reducers: {

    handleLeftVideoCall: (state) => ({
      ...state,
      created: '',
      loading: false,
      error: null,
      hasLeft: true,
      isButtonDisabled: false,
      pinnedSpeakerId: null,
      isCameraEnabled: false,
      isMicrophoneEnabled: false,
      isParticipantsPanelOpen: false,
      isScreenShareEnabled: false,
      isScreenSharePaused: false,
      pinnedUserId: null,
      participants: {},
    }),
    receiveParticipant: receiveParticipantData,
    receiveStreamLoading: receiveStreamLoadingData,
    receiveCameraInterrupted: receiveCameraInterruptedData,
    removeParticipant: removeParticipantData,
    receiveParticipantsInfo: receiveParticipantsInfoData,
    receiveVideoConference: receiveVideoConferenceData,
    receiveVideoConferences: (state, action) => ({
      ...state,
      conferences: action.payload,
    }),
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setPinnedSpeakerId: (state, action) => ({
      ...state,
      pinnedSpeakerId: action.payload,
    }),
    setCameraEnabled: (state, action) => ({
      ...state,
      isCameraEnabled: action.payload,
    }),
    setMicrophoneEnabled: (state, action) => ({
      ...state,
      isMicrophoneEnabled: action.payload,
    }),
    setCameraId: (state, action) => ({
      ...state,
      cameraId: action.payload,
    }),
    setCurrentVideoId: (state, action) => ({
      ...state,
      currentVideoId: action.payload,
    }),
    setButtonDisabled: (state, action) => ({
      ...state,
      isButtonDisabled: action.payload,
    }),
    setParticipantsPanelOpen: (state, action) => ({
      ...state,
      isParticipantsPanelOpen: action.payload,
    }),
    setPinnedUserId: (state, action) => ({
      ...state,
      pinnedUserId: action.payload,
    }),
    setPinnedStream: (state, action) => ({
      ...state,
      pinnedStream: action.payload,
    }),
    setThumbnailStreams: (state, action) => ({
      ...state,
      thumbnailStreams: action.payload,
    }),
    setAvailableVideoSources: (state, action) => ({
      ...state,
      availableVideoSources: action.payload,
      isVideoSourcesFetched: true,
    }),
    setCreated: (state, action) => ({
      ...state,
      created: action.payload,
    }),
    setError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    setMicrophoneId: (state, action) => ({
      ...state,
      microphoneId: action.payload,
    }),
    setParticipantEndedCall: (state) => ({
      ...state,
      participantEndedCall: true,
    }),
    setScreenShareEnabled: (state, action) => ({
      ...state,
      isScreenShareEnabled: action.payload,
      isScreenSharePaused: false,
    }),
    setScreenSharePaused: (state, action) => ({
      ...state,
      isScreenSharePaused: action.payload,
    }),
    setVideoHostId: setVideoHostIdData,
    receiveOrganization: (state, action) => ({
      ...state,
      org: {
        ...state.org,
        ...action.payload.org,
      },
    }),
    receiveVideoConfiguration: (state, action) => ({
      ...state,
      vendor: {
        ...state.org,
        ...action.payload,
      },
    }),
    receiveOrganizationError: (state) => ({
      ...state,
      org: {},
    }),
    setTwilioParticipantId: (state, action) => ({
      ...state,
      twilioParticipantId: action.payload,
    }),
    setConferenceJoined: (state, action) => ({
      ...state,
      conferenceJoined: action.payload,
    }),
    receiveVideoConfigurationError: (state) => ({
      ...state,
      notFound: true,
    }),
    setVolumeGain: (state, action) => ({
      ...state,
      volumeGain: action.payload,
    }),
  },
});

export default rhinovideoSlice.reducer;

// ACTIONS
export const {
  handleLeftVideoCall,
  receiveCameraInterrupted,
  receiveOrganization,
  receiveOrganizationError,
  receiveParticipant,
  receiveStreamLoading,
  receiveVideoConference,
  receiveVideoConferences,
  receiveVideoConfiguration,
  receiveVideoConfigurationError,
  removeParticipant,
  setAvailableVideoSources,
  setButtonDisabled,
  setCameraEnabled,
  setCameraId,
  setConferenceJoined,
  setCreated,
  setCurrentVideoId,
  setError,
  setLoading,
  setMicrophoneId,
  setParticipantEndedCall,
  setMicrophoneEnabled,
  receiveParticipantsInfo,
  setParticipantsPanelOpen,
  setPinnedSpeakerId,
  setPinnedUserId,
  setPinnedStream,
  setThumbnailStreams,
  setScreenShareEnabled,
  setScreenSharePaused,
  setTwilioParticipantId,
  setVideoHostId,
  setVolumeGain,
} = rhinovideoSlice.actions;

// REDUCER HELPERS

function receiveVideoConferenceData(state, action) {
  const newConferences = [...state.conferences];
  const found = newConferences.findIndex((x) => x.videoId === action.payload.videoId);
  if (found <= -1) {
    newConferences.push(action.payload);
  } else {
    newConferences[found] = {
      ...state.conferences[found],
      ...action.payload,
    };
  }

  return {
    ...state,
    conferences: newConferences,
  };
}

function receiveParticipantsInfoData(state, action) {
  const clonedConferences = [...state.conferences];
  const idx = clonedConferences.findIndex((x) => x.videoId === state.currentVideoId);
  clonedConferences[idx] = {
    ...state.conferences[idx],
    participantsInfo: { ...clonedConferences[idx].participantsInfo, ...action.payload.data },
  };

  return {
    ...state,
    conferences: clonedConferences,
  };
}

function setVideoHostIdData(state, action) {
  const clonedConferences = [...state.conferences];
  const index = clonedConferences.findIndex((conference) => conference.videoId === state.currentVideoId);
  clonedConferences[index] = {
    ...state.conferences[index],
    hostId: action.payload,
  };

  return {
    ...state,
    conferences: clonedConferences,
  };
}

function receiveParticipantData(state, action) {
  const { participants, twilioParticipantId } = state;
  const { identity, sid, cameraInterrupted, memberId } = action.payload;
  const isCurrentUser = twilioParticipantId === sid;
  const tracks = getTrackObjects(action.payload);
  const videoTrack = tracks.find((t) => t.kind === 'video' && t.trackName !== 'screenshare');
  const audioTrack = tracks.find((t) => t.kind === 'audio');
  const screenShareTrack = tracks.find((t) => t.trackName === 'screenshare');
  return {
    ...state,
    participants: {
      ...state.participants,
      [sid]: {
        ...participants[sid],
        sid,
        identity,
        isCurrentUser,
        videoTrack,
        audioTrack,
        screenShareTrack,
        cameraInterrupted,
        memberId,
      },
    },
  };
}

function receiveCameraInterruptedData(state, action) {
  const { participants } = state;
  const { cameraInterrupted, sid } = action.payload;
  return {
    ...state,
    participants: {
      ...state.participants,
      [sid]: {
        ...participants[sid],
        cameraInterrupted,
      },
    },
  };
}

function receiveStreamLoadingData(state, action) {
  const { participants } = state;
  const { streamLoading, sid } = action.payload;
  return {
    ...state,
    participants: {
      ...state.participants,
      [sid]: {
        ...participants[sid],
        streamLoading,
      },
    },
  };
}

function removeParticipantData(state, action) {
  const { participants } = state;
  const { sid } = action.payload.participant;
  const participantsCopy = { ...participants };
  delete participantsCopy[sid];
  return {
    ...state,
    participants: participantsCopy,
  };
}

// THUNKS -- ASYNC ACTION CREATORS

export function toggleScreenShare() {
  return (dispatch, getState) => {
    const { rhinovideo: { isScreenShareEnabled } } = getState();
    dispatch(setButtonDisabled(true));
    if (!isScreenShareEnabled) {
      navigator.mediaDevices.getDisplayMedia({ video: true })
        .then((stream) => {
          getRoom().localParticipant.publishTrack(stream.getTracks()[0], { name: 'screenshare', priority: 'high' });
          dispatch(setScreenShareEnabled(true));
        })
        .catch((err) => {
          console.log(err); // eslint-disable-line no-console
          console.log(err.name); // eslint-disable-line no-console
          if (err.name !== 'NotAllowedError') NotificationService('screensharePermissions');
          else NotificationService('screensharePermissions', {});
        })
        .finally(() => dispatch(setButtonDisabled(false)));
    } else {
      const tracks = getTracks(getRoom().localParticipant);
      const screenShareTrack = tracks.find((t) => t.name === 'screenshare');
      screenShareTrack.stop();
      getRoom().localParticipant.unpublishTrack(screenShareTrack);
      dispatch(setButtonDisabled(false));
      dispatch(setScreenShareEnabled(false));
    }
  };
}

export function toggleScreenSharePause() {
  return (dispatch) => {
    const tracks = getTracks(getRoom().localParticipant);
    const screenShareTrack = tracks.find((t) => t.name === 'screenshare');
    if (screenShareTrack.isEnabled) {
      screenShareTrack.disable();
      dispatch(setScreenSharePaused(true));
    } else {
      screenShareTrack.enable();
      dispatch(setScreenSharePaused(false));
    }
  };
}

export function toggleMicrophone(isMuted) {
  return (dispatch) => {
    const tracks = getTracks(getRoom().localParticipant);
    const audioTrack = tracks.find((t) => t.kind === 'audio');
    if (audioTrack) {
      if (isMuted) {
        audioTrack.enable();
        const audio = new Audio(RhinovideoUnmute);
        audio.play().catch((err) => console.error('Auto Play Disabled', err));
        dispatch(setMicrophoneEnabled(true));
      } else {
        audioTrack.disable();
        const audio = new Audio(RhinovideoMute);
        audio.play().catch((err) => console.error('Auto Play Disabled', err));
        dispatch(setMicrophoneEnabled(false));
      }
    } else {
      createLocalAudioTrack()
        .then((localTrack) => {
          getRoom().localParticipant.publishTrack(localTrack);
          const audio = new Audio(RhinovideoUnmute);
          audio.play().catch((err) => console.error('Auto Play Disabled', err));
          dispatch(setMicrophoneEnabled(true));
        })
        .catch(() => NotificationService('microphonePermissions'));
    }
  };
}

export function updateMutedParticipants(participantSid, isEnabled, participantName) {
  return (dispatch, getState) => {
    const { rhinovideo: { twilioParticipantId } } = getState();
    const tracks = getTracks(getRoom().localParticipant);
    const localDataTrack = tracks.find((t) => t.kind === 'data');

    sendTrackData(localDataTrack, { type: 'TOGGLE_MUTE_PARTICIPANT', sid: participantSid, isEnabled });
    const options = `${participantName} has been ${isEnabled ? 'muted' : 'unmuted'}.`;
    if (twilioParticipantId === participantSid) {
      dispatch(toggleMicrophone(!isEnabled));
    }
    NotificationService('muteParticipant', { status: 200 }, options);
  };
}

export function toggleCamera() {
  return (dispatch, getState) => {
    const { rhinovideo: { cameraId, isCameraEnabled } } = getState();
    dispatch(setButtonDisabled(true));
    const tracks = getTracks(getRoom().localParticipant);
    const videoTrack = tracks.find((t) => t.kind === 'video' && t.name !== 'screenshare');
    if (!isCameraEnabled) {
      if (!videoTrack) {
        const constraints = {
          ...getDeviceConstraints(),
          deviceId: cameraId,
        };
        createLocalVideoTrack(constraints)
          .then((localTrack) => {
            getRoom().localParticipant.publishTrack(localTrack);
            dispatch(setCameraEnabled(true));
          })
          .catch(() => NotificationService('cameraPermissions'))
          .finally(() => dispatch(setButtonDisabled(false)));
      } else {
        videoTrack.enable();
        dispatch(setCameraEnabled(true));
        dispatch(setButtonDisabled(false));
      }
    } else {
      videoTrack.disable();
      videoTrack.stop();
      getRoom().localParticipant.unpublishTrack(videoTrack);
      dispatch(setCameraEnabled(false));
      dispatch(setButtonDisabled(false));
    }
  };
}

export function rotateCamera() {
  return (dispatch) => {
    // rotate camera will only be displayed if availableVideoSources has a length greater than 1
    const tracks = getTracks(getRoom().localParticipant);
    const localVideoTrack = tracks.find((t) => t.kind === 'video' && t.name !== 'screenshare');
    const localDataTrack = tracks.find((t) => t.kind === 'data');
    const currentFacingMode = localVideoTrack.mediaStreamTrack?.getSettings().facingMode;
    const facingMode = currentFacingMode === 'user' ? 'environment' : 'user';
    dispatch(setButtonDisabled(true));
    const { sid } = getRoom().localParticipant;

    if (!isAndroid()) {
      dispatch(receiveStreamLoading({ streamLoading: true, sid }));
      localVideoTrack.restart({ facingMode })
        .catch(() => {
          NotificationService('cameraPermissions');
        })
        .finally(() => {
          setTimeout(() => {
            dispatch(receiveStreamLoading({ streamLoading: false, sid }));
          }, 1500);
          dispatch(setButtonDisabled(false));
        });
    } else {
      dispatch(receiveStreamLoading({ streamLoading: true, sid }));
      sendTrackData(localDataTrack, { type: 'STREAM_LOADING', streamLoading: true, sid });
      localVideoTrack.disable();
      localVideoTrack.stop();
      getRoom().localParticipant.unpublishTrack(localVideoTrack);
      const constraints = {
        ...getDeviceConstraints(),
        facingMode,
      };
      createLocalVideoTrack(constraints)
        .then((newTrack) => {
          dispatch(setCameraId(newTrack.mediaStreamTrack.deviceId));
          getRoom().localParticipant.publishTrack(newTrack);
        })
        .catch(() => {
          NotificationService('cameraPermissions');
        })
        .finally(() => {
          dispatch(setButtonDisabled(false));
          setTimeout(() => {
            dispatch(receiveStreamLoading({ streamLoading: false, sid }));
            sendTrackData(localDataTrack, { type: 'STREAM_LOADING', streamLoading: false, sid });
          }, 1500);
        });
    }
  };
}
export function fetchRhinoVideoConfiguration(orgId, isCcr = false) {
  if (isCcr) {
    return fetchRhinoVideoOrganizationConfiguration(orgId);
  } else {
    return getVideoConfiguration();
  }
}

export function fetchRhinoVideoOrganizationConfiguration(orgId) {
  return (dispatch) => getOrganization(orgId)
    .then((response) => {
      dispatch(receiveOrganization(shapeOrganizationPayloadFromVideoService(response?.data)));
    })
    .catch((err) => {
      console.error(err);
      dispatch(receiveOrganizationError());
    });
}

export function updateVideoHostId(videoId, hostId, hostName) {
  return (dispatch, getState) => {
    updateVideo(videoId, { hostId })
      .then((response) => {
        const { rhinovideo: { isScreenShareEnabled } } = getState();
        // Toggle screen share off if it is already enabled before sending the broadcast message out to update the host
        if (isScreenShareEnabled) toggleScreenShare();
        const tracks = getTracks(getRoom().localParticipant);
        const localDataTrack = tracks.find((t) => t.kind === 'data');

        sendTrackData(localDataTrack, { type: 'MAKE_HOST', hostId, hostName });
        NotificationService('rhinovideoHost', response, hostName);
        dispatch(setVideoHostId(hostId));
        dispatch(setParticipantsPanelOpen(false));
      })
      .catch((err) => NotificationService('rhinovideoHost', err.response));
  };
}

export function sendCurrentParticipantsInfo() {
  return (dispatch, getState) => {
    const { rhinovideo: { twilioParticipantId, currentVideoId, conferences } } = getState();
    const currentVideo = conferences.find((conference) => conference.videoId === currentVideoId);
    const currentParticipantsInfo = currentVideo.participantsInfo[twilioParticipantId];
    const data = { [twilioParticipantId]: currentParticipantsInfo };
    const tracks = getTracks(getRoom().localParticipant);
    const localDataTrack = tracks.find((t) => t.kind === 'data');
    sendTrackData(localDataTrack, { type: 'PARTICIPANTS_INFO', data });
  };
}

export function updateParticipantsInfo(participantsInfo) {
  return (dispatch, getState) => {
    const { rhinovideo: { twilioParticipantId, currentVideoId, conferences } } = getState();
    const currentVideo = conferences.find((conference) => conference.videoId === currentVideoId);
    const data = { [twilioParticipantId]: { ...participantsInfo, sid: twilioParticipantId } };
    patchParticipantsInfo({ videoId: currentVideoId, orgId: currentVideo.orgId, participantsInfo: data })
      .then((response) => {
        const tracks = getTracks(getRoom().localParticipant);
        const localDataTrack = tracks.find((t) => t.kind === 'data');

        sendTrackData(localDataTrack, { type: 'PARTICIPANTS_INFO', data });
        dispatch(receiveVideoConference(response.data));
      });
  };
}

export function updateParticipantsInfoNative({ roomName, participantSid }) {
  return (dispatch, getState) => {
    const currentState = getState();
    const currentUser = getLoggedInUser(currentState);
    const participantsInfo = shapeParticipantsInfo(currentUser);
    const data = { [participantSid]: { ...participantsInfo, sid: participantSid } };
    patchParticipantsInfo({ videoId: roomName, orgId: currentUser.organizationId, participantsInfo: data })
      .then((response) => {
        dispatch(receiveVideoConference(response.data));
        OutboundPostMessageService.postMessage({ type: 'receiveConferences', data: { conferences: [response.data] } });
      });
  };
}

export function getVideoConfiguration() {
  return async (dispatch) => {
    try {
      const response = await getVideoSecrets();
      dispatch(receiveVideoConfiguration(response?.data));
      if (response?.data?.twilio?.consumerToken) {
        await dispatch(getVideoConferences());
      }
      return response?.data;
    } catch (err) {
      console.error(err);
      return dispatch(receiveVideoConfigurationError());
    }
  };
}

export function getVideoConferences() {
  return (dispatch) => getVideos()
    .then((response) => {
      OutboundPostMessageService.postMessage({ type: 'receiveConferences', data: { conferences: response.data } });
      dispatch(receiveVideoConferences(response?.data));
    })
    .catch((err) => {
      console.error(err);
    });
}

export function createVideoConference(options) {
  return (dispatch) => postVideoConference(options)
    .then((response) => {
      dispatch(receiveVideoConference(response.data));
      createVideoConferenceNative(response.data, options.consumerToken);
      return response.data;
    })
    .catch((err) => {
      console.error('Error creating video: ', err);
      throw (err?.response?.data ?? err);
    });
}

export function fetchContactRhinoVideoConfiguration(videoId) {
  return (dispatch) => getContactVideoSecrets()
    .then((configuration) => {
      dispatch(receiveVideoConfiguration(configuration.data));
      return getVideo(videoId)
        .then((conference) => dispatch(receiveVideoConference(conference.data)))
        .catch((err) => {
          console.error('Error fetching video for participant: ', err);
          return dispatch(setError('VideoConnectionError'));
        });
    })
    .catch((err) => {
      console.log(err.response || err); // eslint-disable-line no-console
      return dispatch(receiveVideoConfigurationError());
    });
}

export function join(consumerToken, conferenceAlias, userInfo, isMember) {
  return async (dispatch, getState) => {
    dispatch(setCurrentVideoId(conferenceAlias));

    const [videoSources, audioSources] = await Promise.all([
      getVideoSources(isMember),
      getAudioSources(isMember),
    ])
      .catch(() => dispatch(setError('NotAllowedError')));

    const constraints = getConstraints(conferenceAlias, isMember);
    if (constraints.video) {
      dispatch(setAvailableVideoSources(videoSources));
      dispatch(setCameraEnabled(true));
    }
    dispatch(setMicrophoneId(audioSources[0].deviceId));
    dispatch(setMicrophoneEnabled(true));

    const localTracks = (await createLocalTracks(constraints)) || [];
    const localDataTrack = new LocalDataTrack();
    constraints.tracks = [...localTracks, localDataTrack];
    try {
      const room = await connect(consumerToken, constraints);
      if (!room) {
        dispatch(setError('NotAllowedError'));
      }
      setRoom(room);
      if (getIsWebkitAudioWorkaroundNeeded()) {
        const { volumeGain } = getState().rhinovideo;
        getAudioContext(volumeGain);
      }
      dispatch(setTwilioParticipantId(room.localParticipant.sid));
      // Handle the LocalParticipant's media
      dispatch(participantConnected(room.localParticipant));

      if (isIos()) {
        dispatch(replaceParticipant(room));
      }
      // Subscribe to the media published by RemoteParticipants already in the Room
      room.participants.forEach((participant) => {
        dispatch(receiveParticipant(participant));
      });

      // Handles updates to the dominant speaker
      room.on('dominantSpeakerChanged', (participant) => {
        logVideoStatus('dominantSpeakerChanged', participant?.sid);
        // NOTE: Removing for now until we figure it out
        if (participant?.sid) {
          dispatch(setPinnedSpeakerId(participant.sid));
        }
      });

      // Subscribe to the media published by RemoteParticipants joining the Room later
      room.on('participantConnected', (participant) => {
        logVideoStatus('participantConnected');
        dispatch(participantConnected(participant));
      });
      // Handle a disconnected RemoteParticipant
      room.on('participantDisconnected', (participant) => {
        logVideoStatus('participant disconnected');
        return dispatch(removeParticipant({ participant, room }));
      });
      // Handle a track enabled by a remote participant
      room.on('trackEnabled', (publication, participant) => {
        logVideoStatus('track enabled', publication.kind);
        dispatch(receiveParticipant(participant));
      });
      // Handle a track disabled by a remote participant
      room.on('trackDisabled', (publication, participant) => {
        logVideoStatus('track disabled', publication.kind);
        dispatch(receiveParticipant(participant));
      });
      // Handle a track published by a remote participant
      room.on('trackPublished', (publication, participant) => {
        logVideoStatus('track published', publication.kind);
        dispatch(receiveParticipant(participant));
      });
      // Handle a track unpublished by a remote participant
      room.on('trackUnpublished', (publication, participant) => {
        logVideoStatus('track unpublished', publication.kind);
        dispatch(receiveParticipant(participant));
      });
      // Handle a track started by a remote participant
      room.on('trackStarted', (track, participant) => {
        logVideoStatus('track started', track.kind);
        if (track.kind === 'video') {
          attachTrack(track, participant);
        }
        dispatch(receiveParticipant(participant));
      });
      // Handle a track stopped by a remote participant
      room.on('trackStopped', (track, participant) => {
        logVideoStatus('track stopped', track.kind);
        if (track.kind === 'video') {
          detachTrack(track, participant);
        }
        dispatch(receiveParticipant(participant));
      });
      // Handle a local track published by a local participant
      room.localParticipant.on('trackPublished', (publication) => {
        logVideoStatus('local track published', publication.kind);
        dispatch(receiveParticipant(room.localParticipant));
        if (publication.kind === 'data') dispatch(updateParticipantsInfo(userInfo));
      });
      // Handle a local track unpublished by a local participant
      room.localParticipant.on('trackUnpublished', (publication) => {
        logVideoStatus('local track unpublished', publication.kind);
        if (publication.kind === 'video') {
          detachTrack(publication.track, room.localParticipant);
        }
        dispatch(receiveParticipant(room.localParticipant));
      });
      // Handle a local track started by a local participant
      room.localParticipant.on('trackStarted', (track) => {
        logVideoStatus('local track started', track.kind);
        if (track.kind === 'video') {
          attachTrack(track, room.localParticipant);
        }
        dispatch(receiveParticipant(room.localParticipant));
      });
      // Handle a local track disabled by a local participant
      room.localParticipant.on('trackDisabled', (publication) => {
        logVideoStatus('local track disabled', publication);
        if (publication.kind === 'audio' || publication.name === 'screenshare') dispatch(receiveParticipant(room.localParticipant));
      });
      // Handle a local track enabled by a local participant
      room.localParticipant.on('trackEnabled', (publication) => {
        logVideoStatus('local track enabled', publication.kind);
        if (publication.kind === 'audio' || publication.name === 'screenshare') dispatch(receiveParticipant(room.localParticipant));
      });
      // Handle a local track stopped by a local participant
      room.localParticipant.on('trackStopped', (publication) => {
        logVideoStatus('local track stopped', publication.kind);
        const { name } = publication;
        if (name === 'screenshare') {
          room.localParticipant.unpublishTrack(publication);
          dispatch(setScreenShareEnabled(false));
        }
        dispatch(receiveParticipant(room.localParticipant));
      });

      // Handle data sent by a remote participant
      room.on('trackSubscribed', (track) => {
        if (track.kind === 'data' && localDataTrack) {
          sendTrackData(localDataTrack, { type: 'NEW_DATATRACK_SUBSCRIPTION' });
        }
        track.on('message', (message) => {
          const parsedMessage = JSON.parse(message);
          dispatch(receiveTrackMessage(parsedMessage));
        });
      });

      // handle room disconnect
      room.on('disconnected', (r, error) => {
        logVideoStatus('room disconnected', r, error);
        dispatch(setParticipantEndedCall());
      });
      dispatch(setConferenceJoined(true));
    } catch (error) {
      console.error('Error connecting to room', error);
      // https://www.twilio.com/docs/api/errors/53105
      if (error.code === 53105) {
        dispatch(setError('MaxParticipantsError'));
      } else {
        dispatch(setError('VideoConnectionError'));
      }
    }
  };
}

export function setThumbnailStreamsPriority(thumbnailParticipants) {
  return (dispatch) => {
    thumbnailParticipants.forEach((thumbnail) => {
      if (thumbnail.videoTrack?.setPriority) {
        thumbnail.videoTrack.setPriority('low');
      }
      dispatch(setThumbnailStreams(thumbnailParticipants));
    });
  };
}

export function setPinnedStreamPriority(pinnedParticipant) {
  return (dispatch) => {
    if (pinnedParticipant?.videoTrack?.setPriority) {
      pinnedParticipant.videoTrack.setPriority('high');
    }
    dispatch(setPinnedStream(pinnedParticipant));
  };
}

// Function Helpers

function getTracks(participant) {
  return Array.from(participant.tracks.values())
    .filter((publication) => publication.track && !publication.track.isStopped)
    .map((publication) => publication.track);
}

function getTrackObjects(participant) {
  return Array.from(participant.tracks.values())
    .filter((publication) => publication.track && !publication.track.isStopped);
}

function sendTrackData(dataTrack, data) {
  dataTrack.send(JSON.stringify(data));
}

function receiveTrackMessage(message) {
  return (dispatch, getState) => {
    const { rhinovideo: { twilioParticipantId } } = getState();
    if (message.type === 'STREAM_LOADING') {
      dispatch(receiveStreamLoading(message));
    } else if (message.type === 'PARTICIPANTS_INFO') {
      dispatch(receiveParticipantsInfo(message));
    } else if (message.type === 'TOGGLE_MUTE_PARTICIPANT' && message.sid === twilioParticipantId) {
      dispatch(toggleMicrophone(!message.isEnabled));
      const options = `You have been ${message.isEnabled ? 'muted' : 'unmuted'} by the Host.`;
      NotificationService('muteParticipant', { status: 200 }, options);
    } else if (message.type === 'MAKE_HOST') {
      NotificationService('rhinovideoHost', { status: 200 }, message.hostName);
    } else if (message.type === 'CAMERA_INTERRUPTED') {
      dispatch(receiveCameraInterrupted(message));
    } else if (message.type === 'NEW_DATATRACK_SUBSCRIPTION') {
      dispatch(sendCurrentParticipantsInfo());
    }
  };
}

function participantConnected(participant) {
  return (dispatch) => {
    dispatch(receiveParticipant(participant));
    // Handle the TrackPublications already published by the Participant
    participant.tracks.forEach((publication) => {
      trackPublished(publication, participant);
    });
  };
}

function trackPublished(publication, participant) {
  // If the TrackPublication is already subscribed to, then attach the Track to the DOM
  if (publication.track) {
    if (publication.kind === 'video') {
      attachTrack(publication.track, participant);
    }
  }

  // Once the TrackPublication is subscribed to, attach the Track to the DOM
  publication.on('subscribed', () => {
    if (publication.kind === 'video') {
      attachTrack(publication.track, participant);
    }
  });
}

export function detachTrack(track, participant) {
  const elementId = track.name === 'screenshare' ? rhinovideoScreenShareStreamId : `${rhinovideoVideoStreamId}${participant.sid}`;
  const element = document.getElementById(elementId);
  track.detach(element);
}

function attachTrack(track, participant) {
  const elementId = track.name === 'screenshare' ? rhinovideoScreenShareStreamId : `${rhinovideoVideoStreamId}${participant.sid}`;
  const element = document.getElementById(elementId);
  track.attach(element);
}

export function hostHangup() {
  return (dispatch, getState) => {
    const { rhinovideo: { currentVideoId } } = getState();
    const room = getRoom();
    if (room) {
      const tracks = getTracks(room.localParticipant).filter((t) => t.kind !== 'data');
      tracks.forEach((track) => {
        track.disable();
        track.stop();
        // Per the docs audio tracks only get stopped when calling `stop` or the media stream ended.
        // https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/LocalAudioTrack.html#event:stopped__anchor
        if (track.kind === 'audio') {
          room.localParticipant.unpublishTrack(track);
        }
      });
    }
    // No need to disconnect from the room as a host.
    // This update will end the call and disconnect participants.
    updateVideo(currentVideoId, { status: 'completed' })
      .catch((err) => NotificationService('rhinovideoHangup', err.response));
    dispatch(setParticipantEndedCall());
  };
}
export function participantHangup() {
  return (dispatch) => {
    const tracks = getTracks(getRoom().localParticipant).filter((t) => t.kind !== 'data');
    tracks.forEach((track) => {
      track.disable();
      track.stop();
      if (track.kind === 'audio') {
        getRoom().localParticipant.unpublishTrack(track);
      }
    });
    disconnectRoom();
    dispatch(setParticipantEndedCall());
  };
}

export function participantKick(participant) {
  return () => {
    patchParticipant(getRoom().name, {
      sid: participant.sid,
      status: 'disconnected',
      videoId: getRoom().name,
    });
  };
}

function contactParticipantKick(participant) {
  return () => {
    patchContactParticipant(getRoom().name, {
      sid: participant.sid,
      status: 'disconnected',
      videoId: getRoom().name,
    });
  };
}

function replaceParticipant(room) {
  return async (dispatch) => {
    try {
      const sid = localStorage.getItem('rhinovideo_participant_sid');
      if (sid && room.localParticipant.sid !== sid) {
        await dispatch(contactParticipantKick({ sid }));
      }
      localStorage.setItem('rhinovideo_participant_sid', room.localParticipant.sid);
    } catch (err) {
      console.log(err.response || err); // eslint-disable-line no-console
    }
  };
}

function organizationConfigurationRequest(payload, notification) {
  return (dispatch, getState) => postOrganization(payload)
    .then((response) => {
      const { rhinovideo } = getState();
      // was there a change?
      let hasChanged = false;
      if (rhinovideo && response?.data) {
        hasChanged = (rhinovideo.org?.isVideoEnabled !== response.data.isEnabled || rhinovideo.org?.videoConferenceLimit !== response?.data?.videoConferenceLimit);
      }
      dispatch(receiveOrganization(shapeOrganizationPayloadFromVideoService(response?.data)));
      if (hasChanged) {
        NotificationService(notification, response);
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(receiveOrganizationError());
      NotificationService(notification, err.response);
    });
}

export function createOrganizationConfiguration(payload) {
  return organizationConfigurationRequest(payload, 'createVideoOrganizationConfiguration');
}

export function updateOrganizationConfiguration(payload) {
  return organizationConfigurationRequest(payload, 'updateVideoOrganizationConfiguration');
}

function createVideoConferenceNative(conference, consumerToken) {
  const { videoId, created } = conference;
  OutboundPostMessageService.postMessage({
    type: 'createConference',
    data: {
      videoId,
      created,
      consumerToken,
    },
  });
}

export const handleHostHangupNative = (roomName) => {
  updateVideo(roomName, { status: 'completed' });
};

// Shapers

function shapeOrganizationPayloadFromVideoService(org) {
  if (!org) {
    return {};
  }
  return {
    org: {
      id: org._id,
      isVideoEnabled: !!org.isEnabled,
      videoConferenceLimit: org.videoConferenceLimit,
    },
  };
}

function getDeviceConstraints() {
  return isMobile() ? {
    height: 480,
    width: 640,
    frameRate: 24,
    name: 'camera',
  } : {
    height: 720,
    width: 1280,
    name: 'camera',
    frameRate: 24,
  };
}

function logVideoStatus(type, message) {
  console.log(type, message); // eslint-disable-line no-console
}
