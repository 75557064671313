import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Icon, Button } from 'rhinostyle';
import rhinovideoNotSupported from '../assets/images/rhinovideo-not-supported.svg';
import rhinovideoMaxParticipants from '../assets/images/rhinovideo-max-participants.svg';
import rhinovideoConnectionError from '../assets/images/rhinovideo-connection-error.svg';
import routingEmptyState from '../assets/images/routing-empty-state1x.png';
import rxEmptyState from '../assets/images/rx-empty-state.png';
import emptyStateIcon from '../assets/images/empty-state-icon.png';
import { isAndroid } from '../helpers/BrowserHelpers';

const EmptyMessage = (props) => {
  const { section, showFooter, className, dataCypressAttr } = props;
  let heading;
  let content;
  let icon;
  let inlineSvg;
  let redirectUrl;

  const history = useHistory();

  switch (section) {
    case 'analytics':
      heading = 'Sorry! There\'s nothing to show.';
      content = 'Once data is available for this section, it will appear here.';
      icon = 'analytics';
      break;
    case 'Contacts':
      heading = 'Looks like you don\'t have any contacts!';
      content = 'Adding contacts is one of the first steps toward making Rhinogram useful. Contacts are patients, vendors, referring doctors, or other people your practice interacts with.'; // eslint-disable-line max-len
      icon = 'user';
      break;
    case 'Members':
      heading = 'Looks like there aren\'t any Members!';
      content = 'Members are doctors, nurses, technicians, admin, or other staff inside your organization.';
      icon = 'user';
      break;
    case 'Patient Direct':
      heading = 'Looks like you\'re all caught up!';
      content = 'Conversations through channels routed to you will appear here.';
      icon = 'inbox';
      break;
    case 'Patient Group':
      heading = 'Looks like you\'re all caught up!';
      content = 'Conversations through channels routed or assigned to your group will appear here.';
      icon = 'inbox';
      break;
    case 'Patient Inbox':
      heading = 'Looks like no Groups are setup';
      content = 'Once a Group has been setup, conversations that are routed or assigned to it will appear here.';
      icon = 'inbox';
      break;
    case 'Mentions':
      heading = 'Looks like you\'re all caught up!';
      content = 'When a Member @Mentions you in a Team Message it will appear here.';
      icon = 'empty-state-mentions';
      break;
    case 'Patient Assigned':
      heading = 'Looks like you\'re all caught up!';
      content = 'An assigned message is a heads up that a message needs to be addressed.';
      icon = 'assign';
      break;
    case 'Patient Following':
      heading = 'Need to stay in the loop?';
      content = 'Click the star icon in any message to follow important conversations.';
      icon = 'star';
      break;
    case 'Team':
      heading = 'Let’s chat!';
      content = 'From here you can have a direct chat with another team member.';
      icon = 'chat';
      break;
    case 'Channels':
      heading = 'Ready to create some channels?';
      content = 'Channels are the gears running behind the scenes that make it possible to communicate using SMS, Facebook, Rhinogram, or RhinoSecure.';
      icon = 'phone';
      break;
    case 'Templates':
      heading = 'Templates save time!';
      content = 'Templates are pre-defined messages used frequently, that make it quick to communicate rather than having to type it out each time.';
      icon = 'note';
      break;
    case 'Out of Office':
      heading = 'Be Back Soon!';
      content = 'Out of Office events help make Rhinogram continue working for you - even when you\'re away.';
      icon = 'calendar';
      break;
    case 'Audit Log':
      heading = 'No Logs Found!';
      content = 'Looks like no activity happened on this organization yet.';
      icon = 'empty-state';
      break;
    case 'Restricted':
      heading = 'This area is restricted!';
      content = 'Please contact your organization administrator for access.';
      icon = 'lock';
      break;
    case 'Rhinopay Success':
      heading = 'Thank you!';
      content = 'Your payment has been made.';
      break;
    case 'Rhinopay Link Expired':
      heading = 'Expired Link!';
      content = 'This link is no longer valid.';
      icon = 'alert-triangle';
      break;
    case 'RhinoVideo Link Expired':
      heading = 'Expired Link!';
      content = 'This link is no longer valid.';
      icon = 'alert-triangle';
      break;
    case 'RhinoVideo In Progress':
      heading = 'Unable to join this video';
      content = 'Only one video call at a time is allowed. If you would like to join this video call, please leave your current session and try again.';
      icon = 'video-off';
      break;
    case 'RhinoVideo Browser Not Supported':
      heading = 'Couldn\'t join virtual visit';
      content = isAndroid() ?
        'Google Chrome is required for virtual visits on this device. If you do not already have the app, please click the button below to get started.' :
        'This browser or app does not support virtual visits. Please try using a different browser.';
      inlineSvg = rhinovideoNotSupported;
      break;
    case 'RhinoVideo Max Participants':
      heading = 'Max Participants Reached';
      content = 'You cannot join this virtual visit because the max number of participants has been reached.';
      inlineSvg = rhinovideoMaxParticipants;
      break;
    case 'RhinoVideo Host Ended':
      heading = 'Call Ended!';
      content = 'This call was ended by the host.';
      icon = 'video-off';
      break;
    case 'RhinoVideo Patient Ended':
      heading = 'Call Ended!';
      content = 'You have left the virtual care session.';
      icon = 'video-off';
      break;
    case 'RhinoVideo Audio Declined':
      heading = 'Audio Required';
      content = 'Please enable audio to join this call.';
      icon = 'microphone';
      break;
    case 'RhinoVideo Connection Error':
      heading = 'Oops, your video call did not connect...';
      content = "Let's give that another try by refreshing your screen.";
      inlineSvg = rhinovideoConnectionError;
      break;
    case 'RhinoVideo Limit Reached':
      heading = 'Limit for simultaneously hosted videos reached!';
      content = 'The number of hosted video calls that can take place at once has been reached. Please end one of the current video sessions before hosting another video call. For more information, contact our Customer Success team.'; // eslint-disable-line max-len
      inlineSvg = rhinovideoConnectionError;
      break;
    case 'Appointment Manager':
      heading = 'No Appointments.';
      content = 'There are no appointments for the selected time period.';
      icon = 'empty-state';
      break;

    case 'RhinoPay Manager':
      heading = 'No Payment Requests.';
      content = 'There are no payment requests for the selected filters.';
      icon = 'empty-state';
      break;
    case 'RhinoForms Manager':
      heading = 'No RhinoForms Requests.';
      content = 'There are no forms requests for the selected filters.';
      icon = 'empty-state';
      break;
    case 'ContactList':
      heading = 'No saved Contact lists';
      content = 'Contact lists save time when needing to message more than one contact.';
      icon = 'user-group';
      break;
    case 'Form Templates':
      heading = 'Looks like there aren\'t any RhinoForms.';
      content = 'Create and store forms now.';
      icon = 'note';
      break;
    case 'Form Templates Modal':
      heading = 'No Forms are currently set up';
      content = 'Go to the Library/Forms section to set up your first Form or ';
      inlineSvg = emptyStateIcon;
      redirectUrl = '/settings/organization/library/forms';
      break;
    case 'VCards':
      heading = 'No VCards are currently set up';
      content = 'Go to the Offices section to set up your first VCard or ';
      inlineSvg = emptyStateIcon;
      redirectUrl = '/settings/organization/offices';
      break;
    case 'Appointment Reminders':
      heading = 'Looks like there aren\'t any Appointment Reminders.';
      content = 'Create an Appointment Reminder.';
      icon = 'empty-state';
      break;
    case 'Rhinocall Browser Not Supported':
      heading = 'Couldn\'t join Rhinocall';
      content = 'This browser or app does not support rhinocall. Please try using a different browser or your mobile device.';
      icon = 'phone';
      break;
    case 'Routing Manager':
      heading = 'No category routing has been set up yet. Click the green + to get started.';
      content = 'Incoming messages will be routed by Patient and Phone Channel Routing.';
      inlineSvg = routingEmptyState;
      break;
    case 'Prescription Manager':
      content = 'There are no prescriptions for the selected time period.';
      icon = 'empty-state';
      break;
    case 'Prescription Notifications':
      heading = 'No prescription notifications have been set up yet. Click the green + to get started.';
      inlineSvg = rxEmptyState;
      break;
    case 'Campaigns':
      heading = ' Sorry! There\'s nothing to show.';
      content = 'Once data is available for this section, it will appear here.';
      icon = 'empty-state';
      break;
    default:
      heading = 'Nothing to see here!';
      content = 'Looks like you haven\'t added anything here yet.';
      icon = 'search';
      break;
  }

  const handleRedirect = (path) => {
    history.push(path);
  };

  return (
    <div className={`empty-message__wrapper ${className || ''}`}>
      <div data-cypress={dataCypressAttr} className="empty-message__content">
        {icon && <Icon className="empty-message__icon" icon={icon} />}
        {inlineSvg && <img className="empty-message__inline-svg" src={inlineSvg} alt={section} />}
        <h3>{heading}</h3>
        <p className="u-text-large">{content}  {redirectUrl && <Button reset className="u-text-primary" onClick={() => handleRedirect(redirectUrl)}>click here</Button>}</p>
        {showFooter && <div className="empty-message__footer">Powered by Rhinogram</div>}
      </div>
    </div>
  );
};

EmptyMessage.propTypes = {
  section: PropTypes.string,
  className: PropTypes.string,
  showFooter: PropTypes.bool,
  dataCypressAttr: PropTypes.string,
};

export default EmptyMessage;
