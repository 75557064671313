import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Alert, Button, Icon } from 'rhinostyle';

import LoginWrapper from './LoginWrapper';

const ForgotPassword = (props) => {
  const {
    authError,
    forgotPasswordEmail,
    handleBackToLoginClick,
    handleForgotPassword,
    handleKeyPress,
    handleChange,
    loading,
    loginEmail,
    loginEmailRef,
  } = props;

  const loginEmailClasses = cx('app-login__floating-label__input form__control form__control--large', {
    'has-value': loginEmail,
  });

  let content;

  if (forgotPasswordEmail === null) {
    content = (
      <>
        <p className="app-login__text u-m-b-large">Please contact your office administrator to reset your password.</p>
        <Button size="large" onClick={handleBackToLoginClick} type="outline-reversed" block>Return to Login Page</Button>
      </>
    );
  } else if (forgotPasswordEmail) {
    content = (
      <>
        <p className="app-login__text u-m-b-large">
          If you have a Rhinogram account associated with this email address we have sent instructions on resetting
          your password. If you need further assistance, please contact your system administrator.
        </p>
        <Button size="large" onClick={handleBackToLoginClick} type="outline-reversed" block>Return to Login Page</Button>
      </>
    );
  } else {
    content = (
      <>
        <p className="app-login__text u-m-b-large">
          Enter the email associated with your Rhinogram account. If you don&lsquo;t know the email,
          please contact your system administrator for assistance.
        </p>
        <div className="app-login__floating-label u-m-b-large">
          <input
            id="loginEmail"
            name="loginEmail"
            type="email"
            autoCapitalize="off"
            autoCorrect="off"
            className={loginEmailClasses}
            onKeyPress={handleKeyPress}
            onChange={handleChange}
            ref={loginEmailRef}
          />
          <label // eslint-disable-line jsx-a11y/label-has-for
            id="loginEmailLabel"
            htmlFor="loginEmail"
            className="app-login__floating-label__label"
          >
            email
          </label>
        </div>
        <div className="u-m-b">
          <Button size="large" loading={loading} onClick={handleForgotPassword} type="outline-reversed" block>Get New Password</Button>
        </div>
        <Button reset onClick={handleBackToLoginClick} className="app-login__secondary-button">
          <Icon icon="caret-left" />&nbsp;Back
        </Button>
        {authError && authError.data ? <Alert solid type="danger" className="u-m-t-large u-text-center">{authError.data.message}</Alert> : null}
      </>
    );
  }

  return (
    <LoginWrapper>
      {content}
    </LoginWrapper>
  );
};

ForgotPassword.propTypes = {
  authError: PropTypes.object,
  forgotPasswordEmail: PropTypes.string,
  handleBackToLoginClick: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleForgotPassword: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loginEmail: PropTypes.string,
  loginEmailRef: PropTypes.func.isRequired,
};

export default ForgotPassword;
