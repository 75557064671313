import PropTypes from 'prop-types';
import React, { useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { UtilitySystem } from 'rhinostyle';

import Quill from '../configs/QuillMention.config.js';
import MentionPanel from './MentionPanel';
import { isInternetExplorer } from '../helpers/BrowserHelpers';
import { getStorageKey } from '../selectors/userSelectors';
import StorageService from '../services/StorageService';
import * as GroupReducer from '../reducers/groupReducer';
import * as UserReducer from '../reducers/userReducer';
import { getChatMentionableUserIds } from '../selectors/chatSelectors';
import { handleEmojiSelection } from '../helpers/TemplateHelpers.js';

const ALL_MENTION = 'all';

const MentionComposeArea = (props) => {
  const searchValueRef = useRef('');
  const mentionIndexRef = useRef();
  const quillRef = useRef(null);
  const showMentionPanelRef = useRef(props.showMentionPanel);
  const mentionUserIdsRef = useRef(props.mentionUserIds);
  const mentionGroupIdsRef = useRef(props.mentionGroupIds);
  const composeRef = useRef();
  const debouncedSearch = useCallback(debounce(handleSearch, 300), []);

  function setSearchValueRef(value) {
    searchValueRef.current = value;
  }

  function setMentionIndexRef(value) {
    mentionIndexRef.current = value;
  }

  useEffect(() => {
    showMentionPanelRef.current = props.showMentionPanel;
  }, [props.showMentionPanel]);

  function handleSearch(value) {
    if (value?.length > 0) {
      if (props.isNoteMention) {
        props.fetchUserMentionSearch({ search: value, scope: 'members' });
        props.fetchGroupMentionSearch(value, true);
      } else {
        // if chat group, just filter users
        const matchingUserIds = props.chatGroupUserIds.filter((userId) => {
          const user = props.users[userId];
          const searchName = `${user.firstName} ${user.lastName}`;
          return value.length <= searchName.length &&
            searchName.toLowerCase().includes(`${value.trim().toLowerCase()}`);
        });
        props.setUserMentionIds(matchingUserIds);
      }
    } else if (props.isNoteMention) {
      props.fetchUserMentionSearch({ scope: 'members' });
      props.fetchGroupsAndPopulateMentionIds(true);
    } else {
      props.setUserMentionIds(props.chatGroupUserIds);
    }
  }

  useEffect(() => {
    handleQuillInit();
    const draft = StorageService.readEntry(props.storageKey);
    if (draft?.length > 0) {
      handleInsertDraftText(draft);
    }
    if (!props.isNoteMention) {
      props.setUserMentionIds(props.chatGroupUserIds);
    } else {
      props.fetchUserMentionSearch({ scope: 'members' });
      props.fetchGroupsAndPopulateMentionIds(true);
    }
  }, []);

  useEffect(() => {
    if (props.templateToBeAdded) {
      const contentLength = quillRef.current.getLength();
      if (contentLength) {
        quillRef.current.insertText(contentLength - 1, ` ${props.templateToBeAdded}`);
        quillRef.current.setSelection(contentLength + props.templateToBeAdded.length, 'user');
      }
    }
  }, [props.templateToBeAdded]);

  // if a message has sent, clear the input
  useEffect(() => {
    if (props.sendingMessage) {
      quillRef.current.setContents([{ insert: '\n' }]);
    }
  }, [props.sendingMessage]);

  useEffect(() => {
    if (props.mentionGroupIds.length === 0 && props.mentionUserIds.length === 0
      && !(!props.isNoteMention && ALL_MENTION.includes(searchValueRef?.current?.toLowerCase()))) {
      togglePanelClosed();
    }
    mentionGroupIdsRef.current = props.mentionGroupIds;
    mentionUserIdsRef.current = props.mentionUserIds;
  }, [props.mentionGroupIds, props.mentionUserIds]);

  const id = UtilitySystem.generateUUID();

  function handleQuillInit() {
    const mentionInputName = `mention-compose-input-${id}`;
    const options = {
      modules: {
        toolbar: false,
        keyboard: {},
      },
      readOnly: false,
      placeholder: props.placeholder,
    };
    quillRef.current = new Quill(`#${mentionInputName}`, options);
    quillRef.current.focus();
    quillRef.current.on('text-change', handleQuillTextChange);
    quillRef.current.keyboard.addBinding({
      key: 37,
      handler: handleLeftArrowPress,
    });
    // Deletes predefined key binding for tab, that helps to work in default nature
    delete quillRef.current.getModule('keyboard').bindings['9'];
  }

  function handleLeftArrowPress(range) {
    if (showMentionPanelRef.current && range.index <= mentionIndexRef.current) {
      togglePanelClosed();
    }
    return true;
  }

  const getDeltaValue = (delta, type) => delta.find((obj) => (type ? obj[type] : obj.insert));

  function handleQuillTextChange(delta, oldDelta, source) {
    if (source === 'user') {
      const range = quillRef.current.getSelection(true);
      const anyWhitespace = /\s/;
      const lineBreak = /\n/;
      if (getDeltaValue(delta.ops)?.insert === '@') {
        const whitespaceBeforeMention = anyWhitespace.test(quillRef.current.getText(range.index - 2, 1));
        if (whitespaceBeforeMention || range.index === 1) {
          togglePanelOpen();
          quillRef.current.getSelection(true);
        }
      } else if (showMentionPanelRef.current && mentionIndexRef.current) {
        const deltaDeleteValue = getDeltaValue(delta.ops, 'delete')?.delete;
        const deltaRetainValue = getDeltaValue(delta.ops, 'retain')?.retain;
        const deleteMention = deltaDeleteValue === 1 && (deltaRetainValue === mentionIndexRef.current - 1 || !deltaRetainValue);
        if (deleteMention || lineBreak.test(getDeltaValue(delta.ops)?.insert)) {
          togglePanelClosed();
        } else {
          const lastCharEnteredWasSpace = getDeltaValue(delta.ops)?.insert === ' ';
          const searchLength = range.index - mentionIndexRef.current;
          const newSearchValue = quillRef.current.getText(mentionIndexRef.current, searchLength);
          if (lastCharEnteredWasSpace && !newSearchValue?.trim()?.length) {
            togglePanelClosed();
          } else {
            debouncedSearch(newSearchValue);
            setSearchValueRef(newSearchValue);
          }
        }
      }
    }
    handleComposeInput();
  }

  function togglePanelOpen() {
    if (!showMentionPanelRef.current) {
      handleSearch('');
      const range = quillRef.current.getSelection(true);
      setMentionIndexRef(range.index);
      props.toggleMentionPanel(true);
    }
  }

  function togglePanelClosed() {
    if (showMentionPanelRef.current) {
      props.toggleMentionPanel(false);
      resetState();
    }
  }

  function handleInsertSelectedVariable(text) {
    // deletes mention search string
    quillRef.current.deleteText(mentionIndexRef.current - 1, searchValueRef.current?.length + 1);
    // inserts formatted mention
    quillRef.current.insertEmbed(mentionIndexRef.current - 1, 'mention', text, 'api');
    // insert space after mention
    quillRef.current.insertText(mentionIndexRef.current, ' ', 'api');
    // moves cursor
    quillRef.current.setSelection(mentionIndexRef.current + 1, 'user');
    props.toggleMentionPanel(false);
  }

  function handleInsertVariable(text, index) {
    quillRef.current.insertEmbed(index, 'mention', text, 'user');
    quillRef.current.setSelection(index + 1, 'user');
  }

  function handleVariableSelection(selection) {
    if (mentionIndexRef.current) {
      if (selection && selection !== ALL_MENTION) {
        props.handleUpdateMentions(selection);
      }
      handleInsertSelectedVariable(selection?.displayName ? selection.displayName : '@All');
    }
  }

  useEffect(() => {
    if (props.emoji) {
      handleEmojiSelection(props.emoji, quillRef);
      props.clearEmoji();
    }
  }, [props.emoji]);

  function resetState() {
    setMentionIndexRef(null);
    setSearchValueRef('');
  }

  function handleComposeInput() {
    const rawMessage = composeRef.current.textContent.trim(); // Returned to onInput
    let message = rawMessage; // Message text to update the DB
    const messageBlock = quillRef.current.getLines();

    let finalData = '';
    // Preserve linebreaks in message
    messageBlock.forEach((block, index) => {
      const textLine = block.domNode.textContent || '';
      if (textLine.trim()) {
        const lineBreak = index === messageBlock.length - 1 ? '' : '\n';
        finalData = finalData.concat(`${textLine}${lineBreak}`);
      }
    });
    message = finalData;
    if (props.onInput) {
      props.onInput('mention', message, rawMessage);
    }
  }

  function handleSpaceMentions(text) {
    const valueToInsert = text.replace(/}/g, '} ').replace(/{/g, ' {');
    return valueToInsert.replace(/}\s\s+/g, '} ').replace(/\s\s+{/g, ' {');
  }

  function formatPasteValue(text, isDraft = false) {
    const lineBreak = new RegExp(/\n/, 'g');
    const lineBreakExplorer = new RegExp(/\r\n/, 'g');

    const isFirefox = navigator.userAgent.includes('Firefox');
    let valueToInsert = text;
    if (isFirefox) {
      valueToInsert = valueToInsert.replace(lineBreak, '');
    }
    if (isInternetExplorer()) {
      valueToInsert = valueToInsert.replace(lineBreakExplorer, '');
    }
    if (isDraft) {
      valueToInsert = handleSpaceMentions(valueToInsert);
    } else {
      valueToInsert = valueToInsert.replace(/\n\n/g, '\n');
    }
    return valueToInsert;
  }

  function handleInsertDraftText(text) {
    quillRef.current.setContents([]);
    handleFormatInsertText(text, true);
  }

  function handlePaste(e) {
    e.preventDefault();
    const paste = (e.clipboardData || window.clipboardData).getData('text');
    handleFormatInsertText(paste, false);
  }

  function handleFormatInsertText(text, isDraft) {
    const valueToInsert = formatPasteValue(text, isDraft);
    const draftMentions = isDraft ? StorageService.readEntry(props.mentionStorageKey) : props.draftMentions;
    valueToInsert.split(/(\s+)/).forEach((value) => {
      if (value.includes('@')) {
        const { index } = quillRef.current.getSelection(true);
        if (draftMentions?.find((mention) => mention.displayName === value.trim())) {
          handleInsertVariable(value, index);
        } else if (value.includes('@All')) {
          handleInsertVariable('@All', index);
        }
      } else {
        handlePasteText(value);
      }
    });
  }

  function handlePasteText(text) {
    if (text) {
      const { index } = quillRef.current.getSelection(true);
      quillRef.current.insertText(index, text);
      quillRef.current.setSelection(index + text.length + 1);
    }
  }

  const { name, showMentionPanel, placeholder, isNoteMention, chatGroupUserIds } = props;

  const mentionInputName = `mention-compose-input-${id}`;
  const noteTextArea = 'noteTextArea';

  return (
    <div className="u-position-relative">
      {showMentionPanel && (
        <MentionPanel
          handleMentionClick={handleVariableSelection}
          handleClickOutside={() => props.toggleMentionPanel(false)}
          isNoteMention={isNoteMention}
          chatGroupUserIds={chatGroupUserIds}
        />
      )}
      <div
        className={isNoteMention ? 'is-note' : 'mention-message__compose'}
        id={mentionInputName}
        data-cypress={noteTextArea}
        placeholder={placeholder}
        name={name}
        onPaste={handlePaste}
        ref={composeRef}
      />
    </div>
  );
};

MentionComposeArea.propTypes = {
  chatGroupUserIds: PropTypes.array,
  clearEmoji: PropTypes.func,
  draftMentions: PropTypes.array,
  emoji: PropTypes.object,
  fetchGroupMentionSearch: PropTypes.func,
  fetchGroupsAndPopulateMentionIds: PropTypes.func,
  fetchUserMentionSearch: PropTypes.func,
  handleUpdateMentions: PropTypes.func,
  isNoteMention: PropTypes.bool,
  mentionGroupIds: PropTypes.array,
  mentionStorageKey: PropTypes.string,
  mentionUserIds: PropTypes.array,
  name: PropTypes.string,
  onInput: PropTypes.func,
  placeholder: PropTypes.string,
  sendingMessage: PropTypes.bool,
  setUserMentionIds: PropTypes.func,
  showMentionPanel: PropTypes.bool,
  storageKey: PropTypes.string,
  templateToBeAdded: PropTypes.string,
  toggleMentionPanel: PropTypes.func,
  users: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  chatGroupUserIds: getChatMentionableUserIds(state),
  groups: props.isNoteMention ? state.group.groups : null,
  mentionGroupIds: state.group.mentionGroupIds,
  mentionStorageKey: getStorageKey(state, 'mentions'),
  mentionUserIds: state.user.mentionUserIds,
  storageKey: getStorageKey(state),
  users: state.user.users,
});

const actions = {
  fetchGroupMentionSearch: GroupReducer.fetchGroupMentionSearch,
  fetchUserMentionSearch: UserReducer.fetchUserMentionSearch,
  setUserMentionIds: UserReducer.setUserMentionIds,
  fetchGroupsAndPopulateMentionIds: GroupReducer.fetchGroupsAndPopulateMentionIds,
};

export default connect(mapStateToProps, actions)(MentionComposeArea);
