import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
} from 'rhinostyle';
import ReactHtmlParser from 'react-html-parser';
import { setDisplay } from '../reducers/rhinocallReducer';
import { handleDialpadClick } from '../services/BandwidthWebrtcService';

const RhinocallDialer = () => {
  // Declarations
  const dispatch = useDispatch();
  // State
  const [dialpadValue, setDialpadValue] = useState('');
  // Handlers
  const handleButtonClick = (value) => {
    handleDialpadClick(value);
    setDialpadValue((prevState) => prevState.concat(value));
  };

  const handleHideClick = () => dispatch(setDisplay('connected'));

  // Functions
  function transformAstericks(text) {
    return ReactHtmlParser(text.split('').map((e) => `<span class="${e === '*' ? 'rhinocall__dialpad-value-astericks' : ''}">${e}</span>`).join(''));
  }

  // Renders
  const renderDialpadButtons = () => {
    const values = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
    return values.map((value) => (
      <Button
        key={value}
        type="link"
        size="large"
        iconOnly
        title="End Call"
        onClick={() => handleButtonClick(value)}
        className={`rhinocall__dialpad-button ${value === '*' ? 'rhinocall__dialpad-button-astericks' : ''}`}
      >
        {value}
      </Button>
    ));
  };

  // Component
  return (
    <div className="u-text-white">
      <div className="rhinocall__dialpad-value">
        {transformAstericks(dialpadValue)}
      </div>
      <div className="rhinocall__dialpad-buttons">
        {renderDialpadButtons()}
      </div>
      <div
        onClick={handleHideClick}
        className="rhinocall__dialpad-hide"
      >
        Hide
      </div>
    </div>
  );
};

export default RhinocallDialer;
