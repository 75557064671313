import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import { setError } from './uiReducer';
import { timeZone } from '../actions/NormalizrSchema';

// SLICE
const timeZoneSlice = createSlice({
  name: 'TIMEZONE',
  initialState: {
    timeZones: {},
    timeZoneIds: [],
  },
  reducers: {
    receiveTimeZones: (state, action) => ({
      ...state,
      timeZones: {
        ...state.timeZones,
        ...action.payload.timeZones,
      },
      timeZoneIds: [...state.timeZoneIds, ...action.payload.timeZoneIds],
    }),
  },
});

export default timeZoneSlice.reducer;

// ACTIONS
export const { receiveTimeZones } = timeZoneSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

export function fetchTimeZones() {
  return (dispatch) =>
    axios.get('/timezones')
      .then((response) => {
        const normalized = normalize(response.data, [timeZone]);

        dispatch(receiveTimeZones({ timeZones: normalized.entities.timeZones, timeZoneIds: normalized.result }));
      })
      .catch((err) => {
        console.error(err.response);

        dispatch(setError(err.response));
      });
}
