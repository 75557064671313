import chatReceived from '../assets/audio/alert-chat-received.mp3';
import messageFailed from '../assets/audio/alert-message-failed.mp3';
import messageReceived from '../assets/audio/alert-message-received.mp3';
import messageSent from '../assets/audio/alert-message-sent.mp3';
import systemNotification from '../assets/audio/alert-system-notification.mp3';
import { checkActiveVideoIos } from './RhinovideoHelper';

const audioTrack = document.createElement('audio');
audioTrack.setAttribute('type', 'audio/mpeg');
audioTrack.setAttribute('preload', 'metadata');

/**
 * Safari's auto-play policy prevents playing sounds without user interaction.
 * A user can enable auto-play from Safari > Preferences > Websites > Auto-Play.
 *
 * See https://webkit.org/blog/7734/auto-play-policy-changes-for-macos
 */
export function playAudio(id) {
  if (!checkActiveVideoIos(id)) {
    const soundMap = {
      'chat-received': chatReceived,
      'message-failed': messageFailed,
      'message-received': messageReceived,
      'message-sent': messageSent,
      'system-alert-notification': systemNotification,
    };
    const soundSource = soundMap[id];
    if (soundSource) {
      audioTrack.setAttribute('src', soundSource);
      const promise = audioTrack.play();

      if (promise !== undefined) {
        promise
          .catch((error) => {
            console.warn(`Audio auto-play was prevented: ${error}`); // eslint-disable-line no-console
          });
      }
    }
  }
}

export function getSoundOnOff(organizations, currentUser) {
  const org = organizations[currentUser.organization];

  if (!org) return null;

  return org.soundOn;
}
