import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';

import { getCurrentVideo } from '../selectors/rhinovideoSelectors';
import RhinovideoStream from './RhinovideoStream';
import RhinovideoScreenShareStream from './RhinovideoScreenShareStream';
import { setPinnedStreamPriority, setThumbnailStreamsPriority } from '../reducers/rhinovideoReducer';

const RhinovideoParticipants = (props) => {
  const dispatch = useDispatch();
  const { participants, hostId, currentUserId, pinnedUserId, pinnedSpeakerId, currentVideo } = props;
  const [isHidden, setIsHidden] = useState(false);
  const [participantWithScreenShareTrack, setParticipantWithScreenShareTrack] = useState(Object.values(participants).find((p) => p.screenShareTrack));

  const updateThumbnailStreams = (thumbnailParticipants) => {
    dispatch(setThumbnailStreamsPriority(thumbnailParticipants));
  };

  const updatePinnedStream = (pinnedParticipant) => {
    dispatch(setPinnedStreamPriority(pinnedParticipant));
  };

  useEffect(() => {
    setParticipantWithScreenShareTrack(Object.values(participants).find((p) => p.screenShareTrack));
  }, [participants]);

  useEffect(() => {
    const participantsConnected = Object.values(participants);
    if (participantWithScreenShareTrack) {
      // If a screenshare is taking place, all users become thumbnails and the screenshare stream is displayed as a pin
      updateThumbnailStreams(participantsConnected);
      updatePinnedStream(null);
    } else if (pinnedUserId && participantsConnected.some((p) => p.sid === pinnedUserId)) {
      // If a user has been pinned, check that they are still on the call and move everyone else to thumbnails
      const pinnedUser = participantsConnected.find((p) => p.sid === pinnedUserId);
      updateThumbnailStreams(participantsConnected.filter((p) => p.sid !== pinnedUserId));
      updatePinnedStream(pinnedUser);
    } else if (pinnedSpeakerId && participantsConnected.some((p) => p.sid === pinnedSpeakerId)) {
      // The person speaking is pinned, everyone else is a thumbnail UNLESS the person speaking is the current user, then don't change anything
      const activeSpeaker = participantsConnected.find((p) => p.sid === pinnedSpeakerId);
      updateThumbnailStreams(participantsConnected.filter((p) => p.sid !== pinnedSpeakerId));
      updatePinnedStream(activeSpeaker);
    } else if (participantsConnected.length === 1) {
      // If no one else is on the call, the participant becomes a thumbnail and no one is pinned
      updateThumbnailStreams(participantsConnected);
      updatePinnedStream(null);
    } else if (participantsConnected.length > 1 && currentUserId !== hostId) {
      // If participant is not the host, pin the host
      const host = participantsConnected.find((p) => p.identity === hostId);
      updateThumbnailStreams(participantsConnected.filter((p) => p.identity !== hostId));
      updatePinnedStream(host);
    } else if (participantsConnected.length > 1 && currentUserId === hostId) {
      // If participant is the host, pin the first patient that joined OR the second member that joined
      const firstPatientJoined = participantsConnected.find((p) => !Number(p.identity)) || participantsConnected.find((p) => !p.isCurrentUser);
      updateThumbnailStreams(participantsConnected.filter((p) => p.sid !== firstPatientJoined.sid));
      updatePinnedStream(firstPatientJoined);
    }
  }, [participants, hostId, pinnedUserId, pinnedSpeakerId, currentVideo.participantsInfo, participantWithScreenShareTrack]);

  const debounceSetIsHidden = debounce(() => setIsHidden(true), 5000);
  const handleMouseMove = () => {
    setIsHidden(false);
    debounceSetIsHidden();
  };

  useEffect(() => {
    if (participantWithScreenShareTrack) {
      handleMouseMove();
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('touchstart', handleMouseMove);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchstart', handleMouseMove);
    };
  }, [participantWithScreenShareTrack]);

  return (
    <div className={`rhinovideo__participant-container ${Object.values(participants).length === 1 && !participantWithScreenShareTrack ? 'is-empty' : ''}`}>
      {Object.values(participants).map((p) => <RhinovideoStream isHidden={isHidden && !!participantWithScreenShareTrack} key={p.sid} participantSid={p.sid} />)}
      {Object.values(participants).length === 1 && !participantWithScreenShareTrack && !pinnedUserId && (
        <div className="rhinovideo__participant is-pinned">
          We&apos;re waiting for other participants to arrive.
        </div>
      )}
      {participantWithScreenShareTrack && <RhinovideoScreenShareStream screenShareTrack={participantWithScreenShareTrack} />}
    </div>
  );
};

RhinovideoParticipants.propTypes = {
  participants: PropTypes.object.isRequired,
  currentVideo: PropTypes.object.isRequired,
  hostId: PropTypes.string,
  currentUserId: PropTypes.string,
  pinnedUserId: PropTypes.string,
  pinnedSpeakerId: PropTypes.string,
};

const mapStateToProps = (state) => {
  const currentVideo = getCurrentVideo(state);
  const { rhinovideo, auth } = state;

  return {
    currentVideo,
    participants: rhinovideo.participants,
    pinnedUserId: rhinovideo.pinnedUserId,
    hostId: currentVideo.hostId,
    currentUserId: auth.currentUser?.toString(),
    pinnedSpeakerId: rhinovideo.pinnedSpeakerId,
  };
};

export default connect(mapStateToProps)(RhinovideoParticipants);
