import { useLastEvent } from '.';
import { getEventModelType } from '../helpers/EventHelpers';

const useActiveConvoTab = () => {
  const { mostRecentEvent } = useLastEvent();
  const eventType = mostRecentEvent ? getEventModelType(mostRecentEvent) : {};
  if (eventType.isNoteEvent) {
    return 'note';
  } if (eventType.isSecureEvent) {
    return 'secure';
  } return 'patient';
};

export default useActiveConvoTab;
