import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as OutOfOfficeReducer from '../reducers/outOfOfficeReducer';
import * as ChannelReducer from '../reducers/channelReducer';
import OrganizationOutOfOffice from '../components/OrganizationOutOfOffice';
import { getLoggedInUser } from '../selectors/userSelectors';
import PageLoader from '../components/PageLoader';

class OrganizationOutOfOfficeContainer extends Component {
  state = {
    panelOpen: !!this.props.match.params.oooId,
  }

  componentDidMount() {
    this.props.fetchOutOfOffices();
    this.props.fetchChannels();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.oooId !== this.props.match.params.oooId) {
      this.setState({ panelOpen: !!this.props.match.params.oooId }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  handleCreateOOOClick = () => {
    this.props.history.push('/settings/organization/out-of-office/create');
  }

  handleTogglePanel = (id) => {
    const shouldClose = this.state.panelOpen && Number(this.props.match.params.oooId) === id;

    if (shouldClose) {
      this.props.history.push('/settings/organization/out-of-office');
    } else {
      this.props.history.push(`/settings/organization/out-of-office/${id}`);
    }
  }

  render() {
    if (this.props.loading) {
      return <PageLoader />;
    }

    const props = {
      activeOOOId: Number(this.props.match.params.oooId),
      channels: this.props.channels,
      handleCreateOOOClick: this.handleCreateOOOClick,
      handleTogglePanel: this.handleTogglePanel,
      loading: this.props.loading,
      outOfOfficeIds: this.props.outOfOfficeIds,
      outOfOffices: this.props.outOfOffices,
      panelOpen: this.state.panelOpen,
    };

    return <OrganizationOutOfOffice {...props} />;
  }
}

OrganizationOutOfOfficeContainer.propTypes = {
  channels: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchChannels: PropTypes.func.isRequired,
  fetchOutOfOffices: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  outOfOfficeIds: PropTypes.array.isRequired,
  outOfOffices: PropTypes.object.isRequired,
  organizations: PropTypes.object.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { organization, outOfOffice, channel } = state;

  return {
    channels: channel.channels,
    currentUser: getLoggedInUser(state),
    loading: outOfOffice.loading,
    organizations: organization.organizations,
    outOfOfficeIds: outOfOffice.outOfOfficeIds,
    outOfOffices: outOfOffice.outOfOffices,
  };
};

const actions = {
  fetchChannels: ChannelReducer.fetchChannels,
  fetchOutOfOffices: OutOfOfficeReducer.fetchOutOfOffices,
};

export default connect(mapStateToProps, actions)(OrganizationOutOfOfficeContainer);
