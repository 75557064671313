import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import DownloadAppModal from './DownloadAppModal';
import BrowserNotSupportedModal from './BrowserNotSupportedModal';
import LightboxContainer from '../containers/LightboxContainer';
import RhinopayRequestModalContainer from '../containers/RhinopayRequestModalContainer';
import AudioAccessRequiredModal from './AudioAccessRequiredModal';
import LimitReachedModal from './LimitReachedModal';
import FormLibraryModal from './FormLibraryModal';
import OrganizationMessageTemplateModalContainer from '../containers/OrganizationMessageTemplateModalContainer';
import { toggleLightboxClosed } from '../reducers/threadReducer';
import { useThreadOptions } from '../hooks';
import VCardModal from './VCardModal';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { VCARD_FILE_TYPE } from '../constants/AppConstants';
import { filterAttachments } from '../helpers/AttachmentHelpers';

const InboxModalWrapper = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isInboxThread = location.pathname.includes('inbox');
  const {
    isRhinoformEnabled,
    lightboxIsOpen,
    isNote,
    currentOrganization,
    attachments,
  } = props;
  const threadOptions = useThreadOptions();

  function toggleLightbox() {
    dispatch(toggleLightboxClosed());
  }

  const renderInboxModals = () => (
    <>
      <RhinopayRequestModalContainer threadOptions={threadOptions} />
      <DownloadAppModal />
      <BrowserNotSupportedModal />
      <AudioAccessRequiredModal />
      <LimitReachedModal />
      {isRhinoformEnabled && (<FormLibraryModal isInboxThread />)}
      {currentOrganization?.isOfficeVCardEnabled && (
        <VCardModal
          isInboxThread={isInboxThread}
          currentOrganization={currentOrganization}
          selectedVCards={filterAttachments(attachments, VCARD_FILE_TYPE)}
        />
      )}
    </>
  );
  return (
    <>
      <OrganizationMessageTemplateModalContainer
        isOnInbox
        templateType={!isInboxThread || isNote ? 'teamTemplate' : 'systemAndContact'}
      />
      {isInboxThread && renderInboxModals()}
      {!isInboxThread && lightboxIsOpen && (<LightboxContainer threadType={isInboxThread ? 'inbox' : 'chat'} toggleLightbox={toggleLightbox} />)}
    </>
  );
};

InboxModalWrapper.propTypes = {
  isRhinoformEnabled: PropTypes.bool.isRequired,
  lightboxIsOpen: PropTypes.bool,
  isNote: PropTypes.bool,
  currentOrganization: PropTypes.object,
  attachments: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isRhinoformEnabled: !!state.form.org?.isRhinoformEnabled,
  lightboxIsOpen: state.thread.lightboxIsOpen,
  isNote: state.thread.activeConvoTab === 'note',
  currentOrganization: getCurrentOrg(state),
  attachments: state.thread.attachments,
});

export default connect(mapStateToProps)(InboxModalWrapper);
