import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import StorageService from '../services/StorageService';
import { getLoggedInUser, userHasLimitedProviderRole } from '../selectors/userSelectors';
import { userHasAnyOfPermissions, userHasAllOfPermissions, isContact, isCCR } from '../helpers/UserHelpers';
import EmptyMessage from './EmptyMessage';
import { isMobile } from '../helpers/BrowserHelpers';

export default function requireAuthentication(Component, requiredPermissions, mobilePermission, isPatientView = false) {
  class AuthenticatedComponent extends React.Component {
    static propTypes = { //eslint-disable-line
      currentUser: PropTypes.object,
    };

    componentDidMount() {
      const { history } = this.props;
      const currentPath = window.location.pathname;
      if (!this.isAxiumPath() && !this.isUserLoggedIn()) {
        const isValidNextPath = currentPath && currentPath !== '/' && !currentPath.includes('/notfound');
        const targetPath = isValidNextPath ? currentPath : '';
        let nextPath = '/auth';

        const isTopsOrtho = navigator.userAgent.includes('topsOrtho');
        if (isTopsOrtho) StorageService.createEntry('authType', 'legacy');

        if (StorageService.readEntry('authType') === 'legacy') {
          nextPath = '/login';
          if (targetPath) {
            nextPath += `?path=${targetPath}${process.NODE_ENV === 'development' || isTopsOrtho ? '&legacy=true' : ''}`;
          } else if (process.NODE_ENV === 'development' || isTopsOrtho) {
            nextPath += '?legacy=true';
          }
        } else {
          nextPath += `?path=${targetPath}`;
        }
        history.push(nextPath);
      } else if (this.props.currentUser && this.props.currentUser.pwReset) {
        history.push('/change-password');
      } else if (!isPatientView && isContact(this.props.currentUser) && !isCCR(this.props.currentUser)) {
        history.push(`/secure/org/${this.props.currentUser.organization}/channel/all`);
      }
    }

    isUserLoggedIn() {
      return !!StorageService.readEntry('user');
    }

    getActivePatientId() {
      return sessionStorage.getItem('activePatientId');
    }

    isAxiumPath() {
      return window.location.pathname.includes('axium/login');
    }

    render() {
      const hasPermissions = (mobilePermission && isMobile()) ? userHasAnyOfPermissions(requiredPermissions) && userHasAllOfPermissions(mobilePermission) :
        userHasAnyOfPermissions(requiredPermissions);
      return (
        this.props.currentUser && (typeof requiredPermissions === 'undefined' || hasPermissions) ? (
          <Component {...this.props} />
        ) : (
          <EmptyMessage section="Restricted" />
        )
      );
    }
  }

  const mapStateToProps = (state) => ({
    currentUser: getLoggedInUser(state),
    isLimitedProvider: userHasLimitedProviderRole(state),
  });

  AuthenticatedComponent.propTypes = {
    history: PropTypes.object,
    currentUser: PropTypes.object,
    isLimitedProvider: PropTypes.bool,
  };

  return connect(mapStateToProps, null)(AuthenticatedComponent);
}
