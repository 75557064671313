import PropTypes from 'prop-types';
import React from 'react';
import {
  DropdownSearchSelect,
  DropdownMenuItemWild,
  RadioGroup,
  Radio,
} from 'rhinostyle';
import { useDropdownMultiSelect } from '../hooks';
import { useGetVCardsQuery } from '../services/vcardService';

const VCardDropdown = ({
  disabled,
  value: {
    id: selectedId,
    status: vCardStatus,
  },
  handleChange,
  name,
}) => {
  const { data, isLoading } = useGetVCardsQuery({});

  const idName = `${name}.id`;
  const statusName = `${name}.status`;

  const {
    shapedItems,
    filteredIds,
    filterItems,
  } = useDropdownMultiSelect({
    name: idName,
    items: data?.entities || {},
    itemIds: data?.ids || [],
    selectedId,
    updateFilters: handleChange,
    titleField: 'vCardName',
  });

  const handleUpdateSelectedId = (id) => {
    handleChange(name, { id });
  };

  const selectedItem = shapedItems?.[selectedId] || {};

  const dropdownLabel = selectedId ? selectedItem?.title : 'V-Card';

  const customHeader = (
    <DropdownMenuItemWild className="u-p-l-0 u-p-r-0">
      <RadioGroup
        inline
        name={statusName}
        onChange={(changeValue) => handleChange(name, { status: changeValue })}
        selectedValue={vCardStatus}
      >
        <span className="">Status:</span>
        <Radio value="sent" label="Sent" />
        <Radio value="notSent" label="Not Sent" />
      </RadioGroup>
    </DropdownMenuItemWild>
  );
  return (
    <DropdownSearchSelect
      name={idName}
      itemSearchLoading={false}
      items={shapedItems || {}}
      itemsIds={filteredIds || []}
      selectedItem={selectedItem}
      selectedItemId={selectedId}
      handleUpdateSelectedId={handleUpdateSelectedId}
      disabled={disabled}
      filterName="V-Cards"
      titleField="vCardName"
      loading={isLoading}
      fetchAllItems={filterItems}
      dataCypress="selectIndividualVCard"
      dropdownLabel={dropdownLabel}
      dropDownClass="filter-page__dropdown"
      dropdownHeader={customHeader}
    />
  );
};

VCardDropdown.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
export default VCardDropdown;
