import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { UtilitySystem } from 'rhinostyle';

const ChannelWidgetSectionToggle = (props) => {
  const {
    active,
    children,
    title,
    onClick,
  } = props;

  const wrapperClasses = cx('channel__widget-section__wrapper', {
    [UtilitySystem.config.classes.active]: active,
  });

  return (
    <div className={wrapperClasses}>
      <div
        className="channel__widget-section__trigger"
        onClick={onClick}
      >
        {title}
      </div>
      <div className="channel__widget-section__content">
        {children}
      </div>
    </div>
  );
};

ChannelWidgetSectionToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default ChannelWidgetSectionToggle;
