import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Icon, LoaderPulse, Scrollbars } from 'rhinostyle';

import { DataHelpers, OrganizationHelpers } from '../helpers';
import { Types } from '../constants';
import SecureThreadFooterContainer from '../containers/SecureThreadFooterContainer';
import LightboxContainer from '../containers/LightboxContainer';
import PageLoader from './PageLoader';
import OrganizationProfilePanel from './OrganizationProfilePanel';
import ThreadEvent from './SecureThreadEvent';
import { useContactForms } from '../hooks';

const SecureThread = (props) => {
  const {
    channel,
    convoBodyRef,
    currentOrganization,
    currentUser,
    eventIds,
    events,
    handleToggleProfile,
    lightboxAttachmentUrl,
    lightboxIsOpen,
    onScroll,
    pageLoading,
    profileOpen,
    threadLoading,
    toggleLightbox,
    users,
    match,
  } = props;
  useContactForms(currentUser.id);

  const renderEmptyConvoMessage = () => (
    <div className="convo__body__intro">
      <div className="convo__body__intro__headline">
        Send your first RhinoSecure message to { OrganizationHelpers.formatName(currentOrganization.name) }!
      </div>
      <div className="convo__body__intro__subheadline">
        Your history is completely private.
      </div>
    </div>
  );

  const renderThreadEvent = (id, key) => {
    const e = events[id];

    const invalidEventTypeIds = [
      Types.TYPE_EVENT_HIPAA_CONSENT,
      Types.TYPE_EVENT_ASSIGNMENT,
    ];

    if (invalidEventTypeIds.includes(e.typeId)) return false;
    if (currentUser.id !== e.user) return false;

    return (
      <ThreadEvent
        key={key}
        eventId={e.id}
        toggleLightbox={toggleLightbox}
        isSecureThread
        isChannelInfoVisible={!channel} // Only show channel information for /all view
      />
    );
  };

  const appPanelClasses = cx('app-panels app-panels--hide-icons-desktop', {
    'app-panels--profile': profileOpen,
  });

  if (pageLoading) {
    return <PageLoader />;
  }

  return (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="convo__inner">
              <div className="convo__header">
                <div className="convo__header__title u-flex-1">
                  <div className="convo__header__title__name">
                    {channel ? channel.name : 'All Messages'}
                  </div>
                  <div className="convo__header__title__deets">
                    <div className="u-text-overflow">
                      {currentOrganization.name}
                    </div>
                  </div>
                </div>
                <div className="convo__header__actions">
                  <Button
                    className="convo__header__actions__button"
                    onClick={handleToggleProfile}
                    reset
                  >
                    <Icon icon="info-circle" />
                  </Button>
                </div>
              </div>
              <Scrollbars className="convo__body" ref={convoBodyRef} onScroll={onScroll} data-cypress="convoBody">
                {threadLoading ? <div className="u-text-center u-p-t-small"><LoaderPulse type="secondary" /></div> : null}
                {!eventIds.length && !threadLoading ? renderEmptyConvoMessage() : null}
                {eventIds && DataHelpers.hasData(events) && DataHelpers.hasData(users) ? eventIds.map(renderThreadEvent) : null}
              </Scrollbars>
              <SecureThreadFooterContainer match={match} />
              {lightboxIsOpen && (
                <LightboxContainer
                  events={events}
                  eventIds={eventIds}
                  toggleLightbox={toggleLightbox}
                  lightboxAttachmentUrl={lightboxAttachmentUrl}
                />
              )}
            </div>
          </div>
        </div>
        <OrganizationProfilePanel
          channel={channel}
          organization={currentOrganization}
          handleToggleProfile={handleToggleProfile}
        />
      </div>
    </div>
  );
};

SecureThread.propTypes = {
  channel: PropTypes.object,
  convoBodyRef: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  eventIds: PropTypes.array,
  events: PropTypes.object,
  handleToggleProfile: PropTypes.func.isRequired,
  lightboxAttachmentUrl: PropTypes.string,
  lightboxIsOpen: PropTypes.bool,
  onScroll: PropTypes.func,
  pageLoading: PropTypes.bool.isRequired,
  profileOpen: PropTypes.bool.isRequired,
  threadLoading: PropTypes.bool,
  toggleLightbox: PropTypes.func,
  users: PropTypes.object,
  match: PropTypes.object,
};

export default SecureThread;
