import {
  TYPE_SECURE,
  TYPE_FACEBOOK,
  TYPE_INSTAGRAM,
  TYPE_RHINOGRAM,
  TYPE_SMS,
  TYPE_TWILIO,
} from '../constants/Types';
import { DataHelpers } from '.';
import * as Types from '../constants/Types';

const MESSAGING_CHANNEL_TYPES = [Types.TYPE_SMS, Types.TYPE_TWILIO, Types.TYPE_FACEBOOK, Types.TYPE_RHINOGRAM, Types.TYPE_INSTAGRAM];

// Communication ID refers to the id of a phone or facebook. In the future there may be more comm methods.
export function getCommunicationId(channelClassAndId) {
  return Number(channelClassAndId.substr(channelClassAndId.indexOf('-') + 1));
}

export function getChannelClassAndId(channel) {
  return `${channel.class}-${channel.id}`;
}

export function getChannelClass(channel) {
  return channel.substr(0, channel.indexOf('-'));
}

export function getChannelIcon(channelTypeId) {
  let icon = 'mobile';

  if (channelTypeId === TYPE_FACEBOOK) icon = 'facebook';
  else if (channelTypeId === TYPE_SECURE) icon = 'lock';
  else if (channelTypeId === TYPE_RHINOGRAM) icon = 'rhino';
  else if (channelTypeId === TYPE_INSTAGRAM) icon = 'instagram';
  return icon;
}

export function formatRoute(route) {
  return DataHelpers.hasData(route) ? `${route.name} (${route.type})` : 'No route';
}

export const channelTypes = {
  facebook: 'facebook',
  instagram: 'instagram',
};

export function isPhoneChannelTypeId(typeId) {
  return [TYPE_SMS, TYPE_TWILIO].includes(typeId);
}

export function isMessageChannel(channel) {
  if (!channel) return false;
  return (MESSAGING_CHANNEL_TYPES.includes(channel.typeId) && !channel.deleted);
}
