import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  ResourceGroup,
  Resource,
  ResourceIntro,
  ResourceRight,
  Scrollbars,
  UtilityInlineGrid,
  UtilityList,
  UtilityListItem,
} from 'rhinostyle';

import { AppConstants, Types } from '../constants';
import { GroupHelpers, PhoneHelpers, DataHelpers, UserHelpers } from '../helpers';
import { userHasAnyOfPermissions, hasTeamThreadViewPermissions, hasContactThreadViewPermissions } from '../helpers/UserHelpers';
import {
  GROUP_CHAT_EDIT,
  GROUP_INBOX_EDIT,
} from '../constants/UserPermissionsConstants';
import BusinessHoursGrouped from './BusinessHoursGrouped';
import { sortComparatorUsingUserLastNameFirstName } from '../helpers/DataHelpers';
import { getActiveGroup } from '../selectors/groupSelectors';
import { getLoggedInUser, getLoggedInUserOrganization } from '../selectors/userSelectors';
import { toggleProfile } from '../reducers/threadReducer';

const GroupPanel = (props) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [viewAllMembers, setViewAllMembers] = useState(false);
  const {
    channels,
    phones,
    tags,
    timeZones,
    user,
    users,
    view,
    userOrganization,
  } = props;
  const activeGroup = useSelector((state) => getActiveGroup(state, params));
  const groupUsers = activeGroup.users || [];
  const isChat = view === 'chat';
  const handleMemberClick = (userId) => {
    if (isChat) {
      history.push(`/chat/user/${userId}`);
    } else {
      history.push(`/settings/organization/members/${userId}`);
    }
  };

  const handleTogglePanel = (id) => {
    if (isChat) {
      dispatch(toggleProfile());
    } else if (params.groupId) {
      history.push('/settings/organization/groups');
    } else {
      history.push(`/settings/organization/groups/${id}`);
    }
  };

  useEffect(() => {
    if (params.groupId) {
      setViewAllMembers(false);
    }
  }, [params.groupId]);

  const handleToggleViewAllMembers = () => {
    setViewAllMembers((current) => !current);
  };

  const pushRoute = (url) => history.push(url);

  const renderTag = (id, key) => {
    const tag = tags[id];

    return (
      <UtilityListItem key={key}>
        #{tag.name}
      </UtilityListItem>
    );
  };

  const renderChannel = (id, key) => {
    const channel = channels[id];
    const phone = channel.details?.phone && PhoneHelpers.formatPhone(phones[channel.details.phone]?.value);

    return (
      <li key={key}>
        {channel.name}{phone && ` - ${phone}`}
      </li>
    );
  };

  const renderMember = (id, key) => {
    const member = users[id] || {};
    const { onlineStatus } = member; // This property is being set explicitly in the WebSocketService and is not part of the User response object
    const profileImageUrl = member.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${member.profileImageUrl}` : '';
    const avatar = {
      name: UserHelpers.formatAvatarName(member.firstName, member.lastName),
      type: 'member',
      image: profileImageUrl,
      showOnlineStatus: true,
      onlineStatus,
    };

    return (
      <Resource key={key} onClick={() => handleMemberClick(id)}>
        <ResourceIntro title={UserHelpers.formatMemberNameWithPrefixAndSuffix(member)} avatar={avatar} />
        {!member.active && <ResourceRight><span className="u-text-accent">Deactivated</span></ResourceRight>}
      </Resource>
    );
  };

  const userBelongsToGroup = groupUsers.includes(user.id);
  const showRouteToGroupChatButton =
    hasTeamThreadViewPermissions()
    && userBelongsToGroup
    && (activeGroup.typeId === Types.TYPE_GROUP_CHAT || activeGroup.typeId === Types.TYPE_GROUP_INBOX_AND_CHAT)
    && view !== 'chat';
  const showRouteToGroupInboxButton =
    hasContactThreadViewPermissions()
    && userBelongsToGroup
    && (activeGroup.typeId === Types.TYPE_GROUP_INBOX || activeGroup.typeId === Types.TYPE_GROUP_INBOX_AND_CHAT)
    && view !== 'inbox';

  const isTypeGroupChat = activeGroup.typeId === Types.TYPE_GROUP_CHAT;
  const isTypeGroupInbox = activeGroup.typeId === Types.TYPE_GROUP_INBOX;
  const isTypeGroupInboxAndChat = activeGroup.typeId === Types.TYPE_GROUP_INBOX_AND_CHAT;

  const showEditGroupButton =
    (isTypeGroupChat && (user.isCcr || userBelongsToGroup) && userHasAnyOfPermissions([GROUP_CHAT_EDIT]) && !activeGroup.isDynamic)
    || (isTypeGroupInbox && userHasAnyOfPermissions([GROUP_INBOX_EDIT]))
    || (isTypeGroupInboxAndChat && userHasAnyOfPermissions([GROUP_INBOX_EDIT]) && userHasAnyOfPermissions([GROUP_CHAT_EDIT]));

  let groupUserIds = [...groupUsers]?.sort(sortComparatorUsingUserLastNameFirstName(users)) || [];
  groupUserIds = viewAllMembers ? groupUserIds : groupUserIds.slice(0, 6);

  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title">Summary</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => handleTogglePanel(activeGroup.id)}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__title">
                {activeGroup.isDynamic ? 'Direct Chat' : activeGroup.name}
              </div>
              <div className="summary-panel__intro__sub">{activeGroup.isDynamic
                ? `${groupUsers.length} Members`
                : `(${GroupHelpers.formatGroupType(activeGroup.typeId)} Group)`}
              </div>
              <div className="summary-panel__intro__desc">{activeGroup.purpose}</div>
              <div className="summary-panel__intro__actions">
                <UtilityInlineGrid align="middle">
                  {showRouteToGroupInboxButton && (
                    <Button size="small" onClick={() => pushRoute(`/inbox/group/${activeGroup.id}`)} type="primary">
                      {GroupHelpers.formatGroupType(Types.TYPE_GROUP_INBOX)} Messages
                    </Button>
                  )}
                  {showRouteToGroupChatButton && hasTeamThreadViewPermissions() && (
                    <Button size="small" onClick={() => pushRoute(`/chat/group/${activeGroup.id}`)} type="primary">
                      {GroupHelpers.formatGroupType(Types.TYPE_GROUP_CHAT)} Messages
                    </Button>
                  )}
                  {showEditGroupButton && (
                    <Button
                      size="small"
                      onClick={() => pushRoute(`/settings/organization/groups/edit/${activeGroup.id}`)}
                      type="primary"
                      outlined
                      data-cypress="editGroup"
                    >
                      Edit Group
                    </Button>
                  )}
                </UtilityInlineGrid>
              </div>
            </div>
            {!!activeGroup?.afterHours && activeGroup.businessHours && activeGroup.businessHours.length > 0 && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Availability</div>
                <div className="summary-panel__section__content">
                  <BusinessHoursGrouped
                    businessHours={activeGroup.businessHours}
                    timeZone={activeGroup.timeZoneId && DataHelpers.hasData(timeZones) ? timeZones[activeGroup.timeZoneId] : {}}
                  />
                </div>
              </div>
            )}
            {(activeGroup.routedChannels && activeGroup.routedChannels.length > 0) && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Inbound Channels</div>
                <div className="summary-panel__section__content">
                  <UtilityList space className="u-m-b-0">
                    {activeGroup.routedChannels.map(renderChannel)}
                  </UtilityList>
                </div>
              </div>
            )}
            {(userOrganization?.isDefaultGroupOutboundChannelEnabled && activeGroup.defaultChannel) && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Outbound Default Channel</div>
                <div className="summary-panel__section__content">
                  <UtilityList space className="u-m-b-0">
                    {activeGroup?.defaultChannel.name}
                  </UtilityList>
                </div>
              </div>
            )}
            {(activeGroup.tags && activeGroup.tags.length > 0) && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Tags</div>
                <div className="summary-panel__section__content">
                  <UtilityList space className="u-m-b-0">
                    {activeGroup.tags.map(renderTag)}
                  </UtilityList>
                </div>
              </div>
            )}
            {groupUsers.length > 0 && (
              <div className="summary-panel__section summary-panel__section--full">
                <div className="summary-panel__section__label">Members ({activeGroup.users?.length})</div>

                <div className="summary-panel__section__content">
                  <ResourceGroup>
                    {groupUserIds.map(renderMember)}
                  </ResourceGroup>
                </div>
                {groupUsers.length > 6 && !viewAllMembers && (
                  <div className="u-m-t-small u-text-right">
                    <Button reset className="u-text-primary u-text-small" onClick={handleToggleViewAllMembers}>
                      View All Members
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

GroupPanel.propTypes = {
  channels: PropTypes.object.isRequired,
  phones: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  timeZones: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
  userOrganization: PropTypes.object,
};
const mapStateToProps = (state) => ({
  channels: state.channel.channels,
  phones: state.channel.channels,
  tags: state.tag.tags,
  timeZones: state.timeZone.timeZones,
  user: getLoggedInUser(state),
  userOrganization: getLoggedInUserOrganization(state),
  users: state.user.users,
});

export default connect(mapStateToProps)(GroupPanel);
