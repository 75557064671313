export const CONTACT_FILTERS = {
  Scope: 'scope',
  Email: 'email',
  ExcludeCurrentUser: 'excludeCurrentUser',
  IdsToExclude: 'idsToExclude',
  Page: 'page',
  Phone: 'phone',
  Tags: 'tags',
  TagIds: 'tagIds',
  Birthday: 'birthday',
  Id: 'id',
  Name: 'name',
  Size: 'size',
  IncludeInactiveAndDeleted: 'includeInactiveAndDeleted',
  CountTotalRecords: 'countTotalRecords',
  Marketing: 'marketing',
  Hipaa: 'hipaa',
  HipaaStatus: 'hipaaStatus',
  MarketingStatus: 'marketingStatus',
  UserType: 'userType',
  VCard: 'vCard',
};

export const MEMBER_FILTERS = {
  Scope: 'scope',
  IdsToExclude: 'idsToExclude',
  Page: 'page',
  Tags: 'tags',
  TagIds: 'tagIds',
  Name: 'name',
  Size: 'size',
  CountTotalRecords: 'countTotalRecords',
  Groups: 'groups',
  Roles: 'roles',
};

export const MEMBER_FILTERS_INITIAL_STATE = {
  [MEMBER_FILTERS.Scope]: 'members',
  [MEMBER_FILTERS.IdsToExclude]: [],
  [MEMBER_FILTERS.Page]: 0,
  [MEMBER_FILTERS.Tags]: '',
  [MEMBER_FILTERS.TagIds]: [],
  [MEMBER_FILTERS.Name]: '',
  [MEMBER_FILTERS.Size]: 10,
  [MEMBER_FILTERS.CountTotalRecords]: true,
  [MEMBER_FILTERS.Groups]: [],
  [MEMBER_FILTERS.Roles]: [],
  pageNo: 0,
  active: true,
};

export const DATE_FIELDS = {
  Date: 'date',
  Range: 'range',
};

export const DATE_DIRECTION_OPTIONS = {
  EXACT: 'exact',
  GTE: 'gte',
  LTE: 'lte',
  MONTH: 'month',
};

export const CONSENT_DATE_DIRECTION = {
  [DATE_DIRECTION_OPTIONS.LTE]: {
    label: 'Over',
  },
  [DATE_DIRECTION_OPTIONS.GTE]: {
    label: 'More Recent Than',
  },
};

export const BIRTHDAY_DATE_DIRECTION = {
  [DATE_DIRECTION_OPTIONS.EXACT]: {
    label: 'Exact date',
    filterLabel: '',
  },
  [DATE_DIRECTION_OPTIONS.GTE]: {
    label: 'Younger than',
    filterLabel: 'after',
  },
  [DATE_DIRECTION_OPTIONS.LTE]: {
    label: 'Older than',
    filterLabel: 'before',
  },
  [DATE_DIRECTION_OPTIONS.MONTH]: {
    label: 'In month',
    filterLabel: 'in',
  },
};

export const CONTACT_FILTERS_INITIAL_STATE = {
  [CONTACT_FILTERS.TagIds]: [],
  [CONTACT_FILTERS.UserType]: 0,
  [CONTACT_FILTERS.Dob]: '',
  [CONTACT_FILTERS.Name]: '',
  [CONTACT_FILTERS.Id]: '',
  [CONTACT_FILTERS.Birthday]: {
    [DATE_FIELDS.Date]: null,
    [DATE_FIELDS.Range]: DATE_DIRECTION_OPTIONS.EXACT,
  },
  [CONTACT_FILTERS.Hipaa]: {
    [DATE_FIELDS.Date]: null,
    [DATE_FIELDS.Range]: DATE_DIRECTION_OPTIONS.GTE,
  },
  [CONTACT_FILTERS.Marketing]: {
    [DATE_FIELDS.Date]: null,
    [DATE_FIELDS.Range]: DATE_DIRECTION_OPTIONS.GTE,
  },
  [CONTACT_FILTERS.MarketingStatus]: null,
  [CONTACT_FILTERS.HipaaStatus]: null,
  [CONTACT_FILTERS.VCard]: {
    status: 'sent',
    id: 0,
  },
  pageNo: 0,
};

export const CONSENT_DATE_OPTIONS = {
  0: {
    id: 0,
    label: '',
  },
  1: {
    id: 1,
    label: '1 week ago',
    duration: 'week',
  },
  2: {
    id: 2,
    label: '1 month ago',
    duration: 'month',
  },
  3: {
    id: 3,
    label: '1 year ago',
    duration: 'year',
  },
};
