import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import NotificationService from '../services/NotificationService';

import * as UserActionTypes from '../constants/UserActionTypes';
import * as AuthActionTypes from '../constants/AuthActionTypes';
import * as InboxActionTypes from '../constants/InboxActionTypes';
import * as ChannelActionTypes from '../constants/ChannelActionTypes';
import * as ChatActionTypes from '../constants/ChatActionTypes';
import * as GroupActionTypes from '../constants/GroupActionTypes';
import * as OrganizationActionTypes from '../constants/OrganizationActionTypes';
import * as OutOfOfficeActionTypes from '../constants/OutOfOfficeActionTypes';
import * as FormActionTypes from '../constants/FormActionTypes';

import { setError } from './uiReducer';
import { tag } from '../actions/NormalizrSchema';

// SLICE
const tagSlice = createSlice({
  name: 'TAG',
  initialState: {
    loading: false,
    tags: {},
    tagIds: [],
  },
  reducers: {
    receiveTags: (state, action) => ({
      ...state,
      tags: {
        ...state.tags,
        ...action.payload.tags,
      },
      tagIds: [...new Set([...state.tagIds, ...action.payload.tagIds])],
      loading: false,
    }),
    removeTag: (state, action) => ({
      ...state,
      tagIds: [...state.tagIds.filter((t) => t !== action.payload.tagId)],
      loading: false,
    }),
    setTag: (state, action) => ({
      ...state,
      tags: {
        ...state.tags,
        [action.payload.tagId]: action.payload.tag[action.payload.tagId],
      },
      tagIds: [...new Set([...state.tagIds, action.payload.tagId])],
      loading: false,
    }),
    requestData: (state) => ({
      ...state,
      loading: true,
    }),
  },
  extraReducers: {
    [ChannelActionTypes.receiveChannelView]: receiveTagsData, // SHARED

    [AuthActionTypes.setUser]: receiveTagsData,

    [InboxActionTypes.receiveInboxThreadView]: receiveTagsData,

    [ChatActionTypes.receiveChatThreadUserView]: receiveTagsData,
    [ChatActionTypes.receiveChatThreadGroupView]: receiveTagsData,

    [GroupActionTypes.receiveGroups]: receiveTagsData,
    [GroupActionTypes.receiveGroupView]: receiveTagsData,
    [GroupActionTypes.receiveGroupsSearch]: receiveTagsData,

    [UserActionTypes.receiveContactCreateFormView]: receiveTagsData,
    [UserActionTypes.receiveContactEditFormView]: receiveTagsData,
    [UserActionTypes.receiveMembersView]: receiveTagsData,
    [UserActionTypes.receiveMembersList]: receiveTagsData,
    [UserActionTypes.receiveUsers]: receiveTagsData,
    [UserActionTypes.receiveContactUsers]: receiveTagsData,
    [UserActionTypes.receiveMembersSearch]: receiveTagsData,
    [UserActionTypes.receiveUsersSearch]: receiveTagsData,
    [UserActionTypes.receiveConnectedPartySearch]: receiveTagsData,
    [UserActionTypes.receiveUser]: receiveTagsData,
    [UserActionTypes.receiveCreateUser]: receiveTagsData,
    [UserActionTypes.receiveUpdateUser]: receiveTagsData,
    [UserActionTypes.receiveContactList]: receiveTagsData,
    [UserActionTypes.receiveMembersFiltersView]: receiveTagsData,

    [OrganizationActionTypes.receiveOrganizationPreferencesView]: receiveTagsData,

    [OutOfOfficeActionTypes.receiveOutOfOffices]: receiveTagsData,
    [OutOfOfficeActionTypes.receiveOOOView]: receiveTagsData,
    [FormActionTypes.receiveContactForms]: receiveTagsData,
  },
});

export default tagSlice.reducer;

// ACTIONS
export const {
  receiveTags,
  removeTag,
  setTag,
  requestData,
} = tagSlice.actions;

// REDUCER HELPERS
function receiveTagsData(state, action) {
  return {
    ...state,
    tags: {
      ...state.tags,
      ...action.payload.tags,
    },
    tagIds: [...new Set([...state.tagIds, ...action.payload.tagIds])],
  };
}

// THUNKS -- ASYNC ACTION CREATORS
export function fetchTags() {
  return (dispatch) => {
    dispatch(requestData());

    return axios.get('/tags')
      .then((response) => {
        const normalized = normalize(response.data, [tag]);
        dispatch(receiveTags({ tags: normalized.entities.tags, tagIds: normalized.result }));
      })
      .catch((err) => {
        console.error(err.response || err);
        dispatch(setError(err.response || err));
      });
  };
}

export function createTag(payload) {
  return (dispatch) =>
    axios.post('/tags', payload)
      .then((response) => {
        const normalized = normalize(response.data, tag);
        const tagPayload = { tag: normalized.entities.tags, tagId: normalized.result };
        dispatch(setTag(tagPayload));
        dispatch(setError(null));
        return tagPayload;
      })
      .catch((err) => dispatch(handleErrorToast('createTag', err)));
}

export function replaceTag(tagId, payload) {
  return (dispatch) =>
    axios.put(`/tags/${tagId}`, payload)
      .then((response) => {
        const normalized = normalize(response.data, tag);

        dispatch(setTag({ tag: normalized.entities.tags, tagId: normalized.result }));
        dispatch(setError(null));
      })
      .catch((err) => dispatch(handleErrorToast('replaceTag', err)));
}

export function destroyTag(tagId) {
  return (dispatch) =>
    axios.delete(`/tags/${tagId}`)
      .then(dispatch(removeTag({ tagId })))
      .catch((err) => dispatch(handleErrorToast('destroyTag', err)));
}

export function getTags() {
  return axios.get('/tags')
    .catch((err) => console.error(err));
}

// helpers
function handleErrorToast(action, err) {
  return (dispatch) => {
    console.error(err.response || err);

    dispatch(setError(err.response || err));
    // throw toast only if a 500, all other api errors are set to inline validation (to avoid duplicate errors rendering)
    if (err.response && err.response.status === 500) {
      NotificationService(action, err.response);
    }
  };
}

export function getTagsPayload(normalizedTags) {
  return {
    tags: {
      ...normalizedTags.entities.tags,
    },
    tagIds: normalizedTags.result,
  };
}
