import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import OrganizationRoles from '../components/OrganizationRoles';
import { fetchOrganizationRolesView } from '../reducers/roleReducer';
import * as RoleHelpers from '../helpers/RoleHelpers';
import PageLoader from '../components/PageLoader';
import { getLoggedInUser } from '../selectors/userSelectors';
import { organizationSelectors } from '../selectors';

class OrganizationRolesContainer extends React.Component {
  state = {
    panelOpen: !!this.props.match.params.roleId,
    activeRole: undefined,
  };

  componentDidMount() {
    this.props.fetchOrganizationRolesView();
  }

  componentDidUpdate(prevProps, prevState) {
    const { organizationRoles } = this.props;
    const organizationRolesHaveChanged = organizationRoles !== prevProps.organizationRoles;
    const activeRoleId = this.props.match.params.roleId;
    const activeRoleIdHasChanged = activeRoleId !== prevState.activeRoleId;
    const isSystemRole = this.props.location.pathname.includes('system');
    const activeRole = organizationRoles.filter((role) => role.id === Number(activeRoleId) && role.systemRole === isSystemRole)[0];
    const systemRoleHasChanged = prevState.activeRole
      && activeRole
      && activeRole.systemRole !== prevState.activeRole.systemRole;

    if (organizationRolesHaveChanged) {
      // disabling due to future rule change in progress from airbnb
      // see https://reactjs.org/docs/react-component.html#componentdidupdate for explanation
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        activeRole,
      });
    }
    if (activeRoleIdHasChanged || systemRoleHasChanged) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        activeRole,
        activeRoleId,
        panelOpen: !!activeRoleId,
      });
    }
  }

  handleCreateRequest = () => {
    this.props.history.push('/settings/organization/roles/create');
  }

  handleEditClick = (roleId, isSystemRole) => {
    if (isSystemRole) {
      this.props.history.push(`/settings/organization/roles/system/edit/${roleId}`);
    } else {
      this.props.history.push(`/settings/organization/roles/edit/${roleId}`);
    }
  }

  handleMemberClick = (id) => {
    this.props.history.push(`/settings/organization/members/${id}`);
  }

  handleTogglePanel = (roleId, isSystemRole) => {
    const { activeRole, activeRoleId } = this.state;
    const shouldClose = Number(activeRoleId) === roleId && activeRole.systemRole === isSystemRole;
    if (shouldClose) {
      this.props.history.push('/settings/organization/roles');
    } else if (isSystemRole) {
      this.props.history.push(`/settings/organization/roles/system/${roleId}`);
    } else {
      this.props.history.push(`/settings/organization/roles/${roleId}`);
    }
  }

  render() {
    if (this.props.rolesLoading) {
      return <PageLoader />;
    }
    const props = {
      activeOrg: this.props.activeOrg,
      activeRole: this.state.activeRole,
      handleCreateRequest: this.handleCreateRequest,
      handleEditClick: this.handleEditClick,
      handleMemberClick: this.handleMemberClick,
      handleTogglePanel: this.handleTogglePanel,
      isCcr: this.props.currentUser.isCcr,
      isRhinoformEnabled: this.props.isRhinoformEnabled,
      organizationRoles: this.props.organizationRoles,
      pageContainerRef: (pageContainer) => (this.pageContainer = pageContainer),
      panelOpen: this.state.panelOpen,
      users: this.props.users,
    };
    return (
      <OrganizationRoles {...props} />
    );
  }
}

OrganizationRolesContainer.propTypes = {
  activeOrg: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  fetchOrganizationRolesView: PropTypes.func,
  history: PropTypes.object,
  isCcr: PropTypes.bool,
  isRhinoformEnabled: PropTypes.bool,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  organizationRoles: PropTypes.array.isRequired,
  rolesLoading: PropTypes.bool.isRequired,
  users: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { role, user, form } = state;
  return ({
    activeOrg: organizationSelectors.getCurrentOrg(state),
    currentUser: getLoggedInUser(state),
    isRhinoformEnabled: form.org.isRhinoformEnabled,
    organizationRoles: [...RoleHelpers.shapeRolePermissions(role.systemRoles), ...RoleHelpers.shapeRolePermissions(role.customRoles)],
    rolesLoading: role.rolesLoading,
    users: user.users,
  });
};

const actions = {
  fetchOrganizationRolesView,
};

export default connect(mapStateToProps, actions)(OrganizationRolesContainer);
