/* eslint-disable no-shadow */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Input,
  Select,
  Icon,
  UtilityInlineGrid,
  CheckboxGroup,
  Checkbox,
  Avatar,
  DropdownMultiSelect,
  Collapse,
  FormLabel,
} from 'rhinostyle';

import BackButtonContainer from '../containers/BackButtonContainer';
import PageLoader from './PageLoader';
import { MaskingHelpers } from '../helpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { OFFICE_DELETE } from '../constants/UserPermissionsConstants';
import UploadAvatarModal from './UploadAvatarModal';
import { AppConstants } from '../constants';

const OrganizationOfficeForm = (props) => {
  const location = useLocation();
  const history = useHistory();
  const {
    actionTitle,
    city,
    errors,
    formInProgress,
    handleChange,
    handleDestroyOffice,
    handleFormChanges,
    handleSubmit,
    isDeleteButtonVisible,
    isDeleteConfirmationVisible,
    name,
    pageContainerRef,
    pageLoading,
    pageTitle,
    saveBtnType,
    state,
    stateOptions,
    street1,
    street2,
    zip,
    isOfficeVCardEnabled,
    handleSameAddress,
    handleAddPhoto,
    handleCloseModal,
    uploadAvatar,
    channels,
    SMSChannelIds,
    handleSelect,
    handleToggleCollapse,
    handleCreateVCard,
    vCards,
    handleVCardChange,
    channelLoading,
    handleDeleteVCard,
    isShowWarning,
    handleDimissWarning,
  } = props;

  const renderDeleteConfirm = () => (
    <div className="item-status__confirm">
      <div className="item-status__confirm__text">
        <Icon bump="up" icon="trash" /> Are you sure you want to delete this office?
      </div>
      <UtilityInlineGrid size="regular" align="middle">
        <Button size="small" type="link-muted" onClick={() => handleChange('isDeleteConfirmationVisible', false)}>
          Cancel
        </Button>
        <Button loading={formInProgress} size="small" type="danger" onClick={handleDestroyOffice} data-cypress="confirmDelete">
          Yes, delete office
        </Button>
      </UtilityInlineGrid>
    </div>
  );

  const renderStandardStatus = () => (
    <div className="item-status">
      {isDeleteButtonVisible && userHasAnyOfPermissions([OFFICE_DELETE]) && (
        <div className="item-status__left">
          <Button
            reset
            onClick={() => handleChange('isDeleteConfirmationVisible', true)}
            className="item-status__delete-icon"
            title="Delete Office"
          >
            <Icon bump="down" size="large" icon="trash" />
          </Button>
        </div>
      )}
      <div className="item-status__right">
        <Button type={saveBtnType} loading={formInProgress} onClick={handleSubmit} data-cypress={actionTitle}>
          {actionTitle}
        </Button>
      </div>
    </div>
  );

  const renderVCard = (vCard, index) => (
    <div className="box" key={index}>
      <div className="u-position-relative">
        <div className="office-vCard__title u-flex u-flex-justify-center u-flex-align-items-center">
          {vCard.vCardName}
          <Button
            reset
            className="u-text-primary u-font-weight-bold office-vCard__collapse"
            onClick={() => handleToggleCollapse(index)}
          >
            {vCard.isActive ? 'Collapse' : 'Expand'}
            <Icon
              icon={vCard.isActive ? 'minus' : 'add'}
              style={{ cursor: 'pointer' }}
              className="u-p-l-small icon-stroke"
            />
          </Button>
        </div>
        <Collapse isOpen={vCard.isActive} hideCaret>
          <div className="u-p-a-large">
            <div className="row u-flex-justify-center u-text-left">
              <div className="">
                <div className="form__group">
                  <div className="row">
                    <div className="column-8@small">
                      <Input
                        initialValue={vCard.vCardName}
                        label="Display Name"
                        name="vCardName"
                        onChange={(name, value) => handleVCardChange(name, value, index)}
                        required
                        explanationMessage="Display Name must be unique."
                      />
                      {vCard.vCardNameRequiredError && (
                        <p className="u-text-danger broadcast-modal__channel__message">Display Name is Required</p>
                      )}
                      {vCard.vCardUniqueNameRequiredError && (
                        <p className="u-text-danger broadcast-modal__channel__message">Display Name must be unique</p>
                      )}
                    </div>
                    <div className="column-4@small">
                      <div className="edit-profile__avatar-wrapper">
                        <Avatar
                          image={`${AppConstants.AVATAR_BASE_URL}${vCard.logoUrl}`}
                          name={vCard.vCardName}
                          className="edit-profile__avatar"
                          size="xlarge"
                          type="member"
                        />
                        <Button reset onClick={() => handleAddPhoto(index)} className="u-text-primary">
                          {vCard.logoUrl ? 'Update' : 'Add'} Logo
                        </Button>
                        <UploadAvatarModal
                          open={vCard.uploadAvatarModalOpen}
                          onClose={() => handleCloseModal(index)}
                          uploadAvatar={(image, sourceFilename) => uploadAvatar(image, sourceFilename, index)}
                          profileImageUrl={vCard.logoUrl}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form__group">
                  <div className="row">
                    <div className="column-12@small">
                      <CheckboxGroup>
                        <Checkbox
                          onChange={() => handleSameAddress(!vCard.isSameOfficeAddress, index)}
                          isChecked={vCard.isSameOfficeAddress}
                          name="default"
                          label={<span>Use the same address as office details. </span>}
                          dataFeatureTag="edit-appointment-reminder_toggle-default-message"
                        />
                      </CheckboxGroup>
                    </div>
                  </div>
                </div>
                <div className="form__group">
                  <div className="row">
                    <div className="column-6@small">
                      <Input
                        initialValue={vCard?.vCardStreet1}
                        label="Address"
                        name="vCardStreet1"
                        onChange={(name, value) => handleVCardChange(name, value, index)}
                        validationMessage={errors.vCardStreet1}
                      />
                    </div>
                    <div className="column-6@small u-text-left">
                      <Input
                        initialValue={vCard?.vCardStreet2}
                        label="Address 2"
                        name="vCardStreet2"
                        onChange={(name, value) => handleVCardChange(name, value, index)}
                        validationMessage={errors.vCardStreet2}
                      />
                    </div>
                  </div>
                </div>
                <div className="form__group">
                  <div className="row">
                    <div className="column-6@small">
                      <Input
                        onChange={(name, value) => handleVCardChange(name, value, index)}
                        initialValue={vCard?.vCardCity}
                        validationMessage={errors.vCardCity}
                        name="vCardCity"
                        label="City"
                      />
                    </div>
                    <div className="column-3@small">
                      <Select
                        onSelect={(name, value) => handleVCardChange(name, value, index)}
                        name="vCardState"
                        selected={vCard?.vCardState}
                        validationMessage={errors.vCardState}
                        label="State"
                        options={stateOptions}
                        dataCypress="vCardState"
                      />
                    </div>
                    <div className="column-3@small">
                      <Input
                        format={MaskingHelpers.zipCode}
                        onChange={(inputName, rawValue, value) => handleVCardChange(inputName, value, index)}
                        name="vCardZip"
                        initialValue={vCard?.vCardZip}
                        validationMessage={errors.vCardZip}
                        label="Zip"
                      />
                    </div>
                  </div>
                </div>
                <div className="form__group">
                  <div className="row">
                    <div className="column-6@small office-vCard__phone">
                      <FormLabel id="phone" required>Phone</FormLabel>
                      <DropdownMultiSelect
                        items={channels}
                        itemIds={SMSChannelIds}
                        selectedItemIds={vCard.vCardChanneIds}
                        handleSelect={(name, value) => handleSelect(name, value, index)}
                        label=""
                        name="phones"
                        position="top"
                        getItemLabelValue={(item) => `${item?.phone} (${item?.name})`}
                      />
                      {vCard.phoneValidationError && (
                        <p className="u-text-danger broadcast-modal__channel__message">Phone is Required</p>
                      )}
                    </div>
                    <div className="column-6@small">
                      <Input
                        initialValue={vCard.vCardEmail}
                        label="Email"
                        name="vCardEmail"
                        onChange={(name, value) => handleVCardChange(name, value, index)}
                        validationMessage={errors.vCardEmail}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {errors.address &&
                <Alert type="danger" className="u-m-t">{errors.address}</Alert>}
            </div>
          </div>
          <div className="item-status__left u-p-l-0 u-color-danger">
            <Button
              reset
              onClick={() => handleDeleteVCard(index)}
              className="item-status__delete-icon  u-text-danger"
              title="Delete VCard"
            >
              <Icon bump="down" size="medium" icon="trash" />
            </Button>
          </div>
        </Collapse>
      </div>
    </div>
  );

  const renderWarningModal = () => (
    <Alert title="Attention" titleIcon="alert-triangle" className="office-alert" onDismiss={() => handleDimissWarning()} type="danger">Updating an existing VCard will require you to reattach the VCard to any templates using that VCard.</Alert>
  );

  const renderVCardSection = () => (
    <div className="office-vCard">
      <div className="u-flex u-flex u-position-relative u-flex-justify-center u-p-a-large u-m-t">
        <div className="box__title">VCARD(S) FOR THIS OFFICE</div>
        <Button
          onClick={handleCreateVCard}
          iconOnly
          type="secondary"
          title="Create Office"
          className="office-vCard__add"
        >
          <Icon icon="add" />
        </Button>
      </div>
      {vCards.map(renderVCard)}
    </div>

  );

  if (pageLoading && channelLoading) {
    return <PageLoader />;
  }

  return (
    <div className="app-page__container" ref={pageContainerRef}>
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title">
            <BackButtonContainer navigateTo="/settings/organization/offices" history={history} location={location} />
            {pageTitle}
          </div>
        </div>

        <form onChange={handleFormChanges}>
          <div className="box">
            <div className="box__title-wrapper">
              <div className="box__title">Office Details</div>
            </div>

            <div className="row u-flex-justify-center">
              <div className="column-9@small">
                <Input
                  initialValue={name}
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  required
                  validationMessage={errors.name}
                  autoComplete="off"
                />
                <Input
                  initialValue={street1}
                  label="Address"
                  name="street1"
                  onChange={handleChange}
                  required
                  validationMessage={errors.street1}
                />
                <Input
                  initialValue={street2}
                  label="Address 2"
                  name="street2"
                  onChange={handleChange}
                  validationMessage={errors.street2}
                />
                <div className="form__group">
                  <div className="row">
                    <div className="column-5@small">
                      <Input
                        onChange={handleChange}
                        initialValue={city}
                        validationMessage={errors.city}
                        name="city"
                        label="City"
                        required
                      />
                    </div>
                    <div className="column-4@small">
                      <Select
                        onSelect={handleChange}
                        name="state"
                        selected={state}
                        validationMessage={errors.state}
                        label="State"
                        required
                        options={stateOptions}
                        dataCypress="officeState"
                      />
                    </div>
                    <div className="column-3@small">
                      <Input
                        format={MaskingHelpers.zipCode}
                        onChange={(inputName, rawValue, value) => handleChange(inputName, value)}
                        name="zip"
                        initialValue={zip}
                        validationMessage={errors.zip}
                        label="Zip"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {errors.address &&
              <Alert type="danger" className="u-m-t">{errors.address}</Alert>}
          </div>
          {(isOfficeVCardEnabled && vCards.length > 0 && isShowWarning) && renderWarningModal()}
          {isOfficeVCardEnabled && renderVCardSection()}
          <div className="u-m-t-large">
            {isDeleteConfirmationVisible ?
              renderDeleteConfirm() :
              renderStandardStatus()}
          </div>
        </form>
      </div>
    </div>
  );
};

OrganizationOfficeForm.propTypes = {
  actionTitle: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDestroyOffice: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDeleteButtonVisible: PropTypes.bool.isRequired,
  isDeleteConfirmationVisible: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  pageContainerRef: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.string.isRequired,
  saveBtnType: PropTypes.string.isRequired,
  state: PropTypes.number.isRequired,
  stateOptions: PropTypes.array.isRequired,
  street1: PropTypes.string.isRequired,
  street2: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  isOfficeVCardEnabled: PropTypes.bool.isRequired,
  handleSameAddress: PropTypes.func,
  handleAddPhoto: PropTypes.func,
  handleCloseModal: PropTypes.func,
  uploadAvatar: PropTypes.func,
  channels: PropTypes.object,
  SMSChannelIds: PropTypes.array,
  handleSelect: PropTypes.func,
  handleCreateVCard: PropTypes.func,
  handleToggleCollapse: PropTypes.func,
  vCards: PropTypes.array,
  handleVCardChange: PropTypes.func,
  channelLoading: PropTypes.bool,
  handleDeleteVCard: PropTypes.func,
  isShowWarning: PropTypes.bool,
  handleDimissWarning: PropTypes.func,
};

export default OrganizationOfficeForm;
