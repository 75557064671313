import {
  CONTACT_VIEW,
  CONVERSATION_CONTACT_MOBILE,
  THREAD_MESSAGE_CREATE,
  BULK_MESSAGING_EDIT,
  CONTACT_CREATE,
  CONTACT_ORGANIZATION_CREATE,
  RAMP_IMPORT_PATIENT_CREATE,
} from '../constants/UserPermissionsConstants';
import { userHasAnyOfPermissions, userHasAllOfPermissions } from './UserHelpers';
import { isMobile } from './BrowserHelpers';

export const hasContactViewPermission = () => {
  if (isMobile()) return userHasAllOfPermissions([CONTACT_VIEW, CONVERSATION_CONTACT_MOBILE]);
  return userHasAnyOfPermissions([CONTACT_VIEW]);
};

export const hasContactCreatePermission = () => {
  if (isMobile()) return userHasAllOfPermissions([CONTACT_CREATE, CONVERSATION_CONTACT_MOBILE]);
  return (userHasAnyOfPermissions([CONTACT_CREATE, CONTACT_ORGANIZATION_CREATE]));
};

export const hasContactListViewPermission = (isCcr, isBulkMessagingEnabled) => {
  if (!isBulkMessagingEnabled) return false;
  if (isCcr) return true;
  if (isMobile()) return userHasAllOfPermissions([THREAD_MESSAGE_CREATE, CONVERSATION_CONTACT_MOBILE]) || userHasAnyOfPermissions([BULK_MESSAGING_EDIT]);
  return userHasAnyOfPermissions([BULK_MESSAGING_EDIT, THREAD_MESSAGE_CREATE]);
};

export const hasContactListEditPermission = (isBulkMessagingEnabled) => {
  if (!isBulkMessagingEnabled) return false;
  return userHasAnyOfPermissions([BULK_MESSAGING_EDIT]);
};

export const hasContactImportPermission = () => userHasAnyOfPermissions([RAMP_IMPORT_PATIENT_CREATE]);

const hasThreadCreatePermission = () => (isMobile() ? userHasAllOfPermissions([THREAD_MESSAGE_CREATE, CONVERSATION_CONTACT_MOBILE]) :
  userHasAnyOfPermissions([THREAD_MESSAGE_CREATE]));

export const hasBulkMessagePermission = (isCcr, isBulkMessagingEnabled) => {
  if (!isBulkMessagingEnabled || isCcr) return false;
  return userHasAnyOfPermissions([BULK_MESSAGING_EDIT]) && hasThreadCreatePermission();
};
