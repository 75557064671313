import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  LocationHelpers,
} from '../helpers';
import { useInboxParams } from '.';

const useThreadOptions = () => {
  const location = useLocation();
  const params = useParams();
  const { userId, groupId } = useInboxParams();
  const locationOptions = LocationHelpers.getInboxOptions(params, location);

  const organizationId = useSelector((state) => state.auth?.currentOrg);
  const threadSelectedChannelIds = useSelector((state) => state.inbox.threadSelectedChannelIds);
  const initialPageNo = location?.state?.pageNo > -1 ? location?.state?.pageNo : 0;
  const [filteredChannelIds, setFilteredChannelIds] = useState(threadSelectedChannelIds || []);

  useEffect(() => {
    setFilteredChannelIds(threadSelectedChannelIds);
  }, [threadSelectedChannelIds]);

  const threadOptions = {
    userId,
    groupId,
    following: locationOptions.following || false,
    direct: locationOptions.direct || false,
    organizationId,
    assigned: locationOptions.assigned || false,
    pageNo: initialPageNo,
    channelIds: filteredChannelIds?.length > 0 ? filteredChannelIds : [],
    initialPageNo,
  };
  return threadOptions;
};

export default useThreadOptions;
