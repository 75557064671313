import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, LoaderCircle } from 'rhinostyle';
import moment from 'moment-timezone';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmptyMessage from './EmptyMessage';
import RhinovideoConference from './RhinovideoConference';
import { isWebRTCSupported, isMobile, isAndroid } from '../helpers/BrowserHelpers';
import store from '../store';
import { getLoggedInUser } from '../selectors/userSelectors';
import { RhinovideoHelper, DataHelpers } from '../helpers';
import {
  fetchContactRhinoVideoConfiguration,
  fetchRhinoVideoConfiguration,
} from '../reducers/rhinovideoReducer';
import { userHasVideoConferences } from '../helpers/UserHelpers';
import { disconnectRoom } from '../services/RhinovideoRoomService';
import chromeSvg from '../assets/images/google-chrome.svg';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { TYPE_INTEGRATION_AXIUM } from '../constants/Types';

const RhinovideoConferenceView = (props) => {
  const {
    conferenceAlias,
    consumerToken,
    currentVideo,
    currentUser,
    error,
    expiresAt,
    hasLeft,
    notFound,
    participantEndedCall,
    conferenceJoined,
    conferences,
    currentUserId,
    durationMs,
    logoutInProgress,
    currentOrganization,
  } = props;

  // State Hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const [userHasVideoConferencesInState, setUserHasVideoConferencesInState] = useState(false);
  const [hasFetchedConfig, setHasFetchedConfig] = useState(false);
  const [loading, setLoading] = useState(true);

  // Leave the room if participant closes browser window / tab (desktop)
  // Mobile Chrome fires "beforeunload" on navigating to a different URL
  useEffect(() => {
    function handleBeforeUnload() {
      try {
        disconnectRoom();
      } catch (err) {
        console.error(err.response || err);
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => { window.removeEventListener('beforeunload', handleBeforeUnload); };
  }, []);

  // Leave the room if mobile participant navigates to a different URL
  // iOS Safari doesn't fire "beforeunload"
  // iOS Safari fires "pagehide" on navigating to a different URL
  useEffect(() => {
    function handlePageHide() { disconnectRoom(); }

    if (isMobile()) {
      window.addEventListener('pagehide', handlePageHide);
      return () => {
        window.removeEventListener('pagehide', handlePageHide);
      };
    }
    return undefined;
  }, []);

  useEffect(() => {
    (async function useEffectAsync() {
      const title = currentOrganization?.integrationPartnerTypeId === TYPE_INTEGRATION_AXIUM ? 'RhinogramU' : 'Rhinogram';
      document.title = `${title} | Rhinovideo`;
      if (!currentUserId && !logoutInProgress) {
        await dispatch(fetchContactRhinoVideoConfiguration(conferenceAlias));
        setLoading(false);
      } else {
        await dispatch(fetchRhinoVideoConfiguration(currentUser.organizationId));
        setHasFetchedConfig(true);
      }
    }());
  }, []);

  // If an org2org member is joining, no conferences will be returned on the `getVideoCOnferencesFetch`... we fetch the contact config instead
  useEffect(() => {
    (async function useEffectAsync() {
      if (hasFetchedConfig) {
        if (!DataHelpers.hasData(currentVideo)) {
          await dispatch(fetchContactRhinoVideoConfiguration(conferenceAlias));
        }
        setLoading(false);
      }
    }());
  }, [hasFetchedConfig, currentVideo]);

  useEffect(() => {
    if (notFound) history.push('/notfound');
  }, [notFound]);

  useEffect(() => {
    if (!userHasVideoConferencesInState) setUserHasVideoConferencesInState(userHasVideoConferences());
  }, [conferences]);

  // userHasVideosInState prevents the "Video In Progress" message from disappearing as soon as the user hangs up from their other videos.
  const videoInProgress = userHasVideoConferencesInState || userHasVideoConferences();

  // If conferences have been fetched, `loading` is set to false.
  // If `durationMs` existst or there is no currentVideo, then the conference has ended.
  // If the expiresAt time has passed, then the conference has expired
  const expiresAtMoment = moment.utc(expiresAt);
  const now = moment.utc();
  const isExpired = !loading && (durationMs || !DataHelpers.hasData(currentVideo) || expiresAtMoment.isBefore(now));

  if (error === 'NotAllowedError') {
    return (
      <EmptyMessage className="u-m-a-large" showFooter section="RhinoVideo Audio Declined" />
    );
  }

  if (error === 'VideoConnectionError') {
    return (
      <EmptyMessage className="u-m-a-large" showFooter section="RhinoVideo Connection Error" />
    );
  }

  if (!isWebRTCSupported()) {
    return (
      <>
        <EmptyMessage className="u-m-a-large" showFooter section="RhinoVideo Browser Not Supported" />
        {isAndroid() && (
          <div className="u-text-center">
            <Button type="primary" size="large" url="https://play.google.com/store/apps/details?id=com.android.chrome">
              <img
                className="chrome__app-svg u-m-r-small"
                src={chromeSvg}
                alt="Get it on Google Play"
              />
              Download Google Chrome
            </Button>
          </div>
        )}
      </>
    );
  }

  if (participantEndedCall) {
    return (
      <EmptyMessage className="u-m-a-large" showFooter section="RhinoVideo Patient Ended" />
    );
  }

  if (hasLeft) {
    return (
      <EmptyMessage className="u-m-a-large" showFooter section="RhinoVideo Host Ended" />
    );
  }

  if (isExpired) {
    return (
      <EmptyMessage className="u-m-a-large" section="RhinoVideo Link Expired" />
    );
  }

  if (!conferenceJoined && videoInProgress) {
    return (
      <EmptyMessage className="u-m-a-large" showFooter section="RhinoVideo In Progress" />
    );
  }

  if (!conferenceJoined && error === 'MaxParticipantsError') {
    return (
      <EmptyMessage className="u-m-a-large" showFooter section="RhinoVideo Max Participants" />
    );
  }

  const userInfo = RhinovideoHelper.shapeParticipantsInfo(currentUser);

  return (
    <>
      {loading ? (
        <div className="app-page-loader">
          <LoaderCircle className="u-text-primary" />
        </div>
      ) : (
        <RhinovideoConference
          conferenceAlias={conferenceAlias}
          consumerToken={consumerToken}
          store={store}
          isMember={!!userInfo.memberId}
          currentVideo={currentVideo}
          userInfo={userInfo}
        />
      )}
    </>
  );
};

RhinovideoConferenceView.propTypes = {
  currentVideo: PropTypes.object,
  conferenceAlias: PropTypes.string.isRequired,
  consumerToken: PropTypes.string,
  currentUser: PropTypes.object,
  error: PropTypes.string,
  expiresAt: PropTypes.string,
  hasLeft: PropTypes.bool,
  notFound: PropTypes.bool,
  conferenceJoined: PropTypes.bool,
  durationMs: PropTypes.number,
  currentUserId: PropTypes.number,
  conferences: PropTypes.array,
  participantEndedCall: PropTypes.bool,
  logoutInProgress: PropTypes.bool,
  currentOrganization: PropTypes.object,
};

const mapStateToProps = (state, { match: { params: { videoId } } }) => {
  const currentUser = getLoggedInUser(state);
  const { conferences } = state.rhinovideo;
  const currentVideo = conferences.find((conference) => conference.videoId === videoId) || {};

  return {
    conferences,
    currentVideo,
    conferenceAlias: videoId,
    consumerToken: state.rhinovideo.vendor?.twilio?.consumerToken,
    currentUser,
    currentUserId: state.auth.currentUser,
    error: state.rhinovideo.error,
    expiresAt: currentVideo.expiresAt,
    durationMs: currentVideo.durationMs,
    hasLeft: state.rhinovideo.hasLeft,
    logoutInProgress: state.auth.logoutInProgress,
    notFound: state.rhinovideo.notFound,
    conferenceJoined: state.rhinovideo.conferenceJoined,
    participantEndedCall: state.rhinovideo.participantEndedCall,
    currentOrganization: getCurrentOrg(state),
  };
};

export default connect(mapStateToProps)(RhinovideoConferenceView);
