import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { DropdownMultiSelectAdvanced } from 'rhinostyle';
import { cloneDeep } from '../helpers/DataHelpers';
import { getKeyedSavedList, getSavedListIds } from '../selectors/savedListSelectors';
import { fetchContactList } from '../reducers/userReducer';

function SavedListMultiSelect(props) {
  const {
    savedListIds,
    keyedSavedList,
    updateFilters,
    selectedIds,
    disabled,
  } = props;
  const [listIds, setListIds] = useState([]);
  const [selectedItem, setSelectedItem] = useState(keyedSavedList);
  const dispatch = useDispatch();

  // Effects
  useEffect(() => {
    dispatch(fetchContactList());
  }, [dispatch]);

  useEffect(() => {
    if (savedListIds) {
      setListIds(savedListIds);
    }
  }, [savedListIds]);

  const fetchAllSavedLists = (searchText) => {
    if (searchText.length > 2) {
      setListIds(savedListIds.filter((item) => keyedSavedList[item]?.name.toLowerCase().includes(searchText.toLowerCase())));
      setSelectedItem(keyedSavedList.filter((item) => item?.name.toLowerCase().includes(searchText.toLowerCase())));
    } else {
      setListIds(savedListIds);
      setSelectedItem(keyedSavedList);
    }
  };

  const handleUpdateSelectedIds = (ids) => {
    const clonedKeydSavedList = cloneDeep(keyedSavedList);
    const validIds = ids.filter((id) => clonedKeydSavedList[id]?.name);
    updateFilters(validIds);
  };

  const handleClearAllSelectedList = () => {
    updateFilters([]);
  };

  const getLabel = () => {
    if (selectedIds.length > 0) {
      const validIds = selectedIds.filter((id) => keyedSavedList[id]?.name);
      return `${validIds.length} ${validIds.length > 1 ? 'SavedList' : 'SavedList'} Selected`;
    }
    return 'Saved List';
  };

  return (
    <DropdownMultiSelectAdvanced
      handleUpdateSelectedIds={handleUpdateSelectedIds}
      itemSearchLoading={false}
      items={keyedSavedList}
      selectedItems={selectedItem}
      selectedItemsIds={selectedIds}
      handleClearAllSelectedItems={handleClearAllSelectedList}
      fetchAllItems={fetchAllSavedLists}
      itemsIds={listIds}
      dropdownLabel={getLabel()}
      filterName="Saved List"
      type="forms"
      dropDownClass="filter-page__dropdown"
      dataCypress="savedListFilter"
      interfaceLeft
      disabled={disabled}
    />
  );
}

SavedListMultiSelect.propTypes = {
  savedListIds: PropTypes.array,
  keyedSavedList: PropTypes.object,
  updateFilters: PropTypes.func,
  selectedIds: PropTypes.array,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  savedListIds: getSavedListIds(state),
  keyedSavedList: cloneDeep(getKeyedSavedList(state)),
});

export default connect(mapStateToProps)(SavedListMultiSelect);
