import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateMentionReadStatus } from '../reducers/inboxReducer';

const useUpdateMentionRead = () => {
  const [mentionReadStatusLoading, setMentionStatusLoading] = useState(false);
  const dispatch = useDispatch();

  async function handleMarkMentionRead(unreadMention) {
    if (!mentionReadStatusLoading) {
      setMentionStatusLoading(true);
      await dispatch(updateMentionReadStatus(unreadMention.mentionId));
      setMentionStatusLoading(false);
    }
  }

  return { handleMarkMentionRead };
};

export default useUpdateMentionRead;
