/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
  Button,
} from 'rhinostyle';

import { Phone } from '.';

export default class PhoneGroup extends PureComponent {
  state = {
    userPhones: this.props.userPhones,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userPhones !== this.props.userPhones) {
      this.setState({ userPhones: { ...this.props.userPhones } }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  handleAddNewContactItem = () => {
    const key = Object.keys(this.state.userPhones).length;

    const userPhones = { ...this.state.userPhones };
    const userPhone = { ...userPhones[key] };

    userPhones[key] = userPhone;

    this.handleChange(userPhone, key);

    this.setState({ userPhones });
  }

  handleChange = (phone, key) => {
    if (this.props.receiveContactData) {
      this.props.receiveContactData(key, phone, 'userPhones');
    }
  }

  renderAddAnotherNumberText = () => (
    <Button
      reset
      disabled={this.props.isAddAnotherPhoneDisabled}
      onClick={this.handleAddNewContactItem}
      className="u-text-primary edit-profile__phones__add-button"
    >
      Add another phone number
    </Button>
  );

  render() {
    let count = 0;
    const {
      activeUser,
      currentUser,
      createCP,
      errorList,
      handleRemoveContactData,
      handleViewProfileTransition,
      handlePhoneSearchIdx,
      types,
      phones,
      userId,
      integrated,
      loading,
      searchText,
      phoneSearchIdx,
      phoneUserSearchIds,
      handleSearch,
      clearUserSearch,
      users,
      isViewOtherPhoneOwnersDisabled,
      isRemovePhoneDisabled,
      isEditIntegratedContactsAllowed,
    } = this.props;

    const numPhones = this.state.userPhones &&
      Object.keys(this.state.userPhones).length ? Object.keys(this.state.userPhones).filter((p) => !this.state.userPhones[p].markedToDelete).length : 0;

    return (
      <div className="form__group edit-profile__phones">
        {Object.keys(this.state.userPhones).map((p, idx) => {
          const phone = this.state.userPhones[p];

          if (!phone.markedToDelete) count += 1;

          const validationMessage = errorList.userPhones ? errorList.userPhones[`userPhones-${idx}`] : null;
          const serverValMessage = errorList[`phones[${idx}].number`];

          const props = {
            activeUser,
            currentUser,
            count,
            createCP,
            handleChange: this.handleChange,
            handlePhoneSearchIdx,
            handleRemoveContactData,
            handleViewProfileTransition,
            idx,
            integrated,
            newOwnerId: null,
            numPhones,
            phone,
            phones,
            serverValMessage,
            types,
            userId,
            validationMessage,
            handleSearch,
            searchText,
            loading,
            clearUserSearch,
            phoneSearchIdx,
            phoneUserSearchIds,
            users,
            isViewOtherPhoneOwnersDisabled,
            isRemovePhoneDisabled,
            isEditIntegratedContactsAllowed,
          };

          return (!phone.markedToDelete && <Phone {...props} key={p} />);
        })}
        {!this.props.createCP && (!this.props.integrated || this.props.currentUser.isCcr || isEditIntegratedContactsAllowed) && this.renderAddAnotherNumberText()}
      </div>
    );
  }
}

PhoneGroup.propTypes = {
  activeUser: PropTypes.object,
  currentUser: PropTypes.object,
  createCP: PropTypes.bool,
  errorList: PropTypes.object,
  handleRemoveContactData: PropTypes.func,
  handleViewProfileTransition: PropTypes.func,
  handlePhoneSearchIdx: PropTypes.func,
  newOwnerId: PropTypes.number,
  receiveContactData: PropTypes.func,
  types: PropTypes.array,
  userId: PropTypes.number,
  userPhones: PropTypes.object,
  integrated: PropTypes.bool,
  phones: PropTypes.object,
  phoneSearchIdx: PropTypes.number,
  phoneUserSearchIds: PropTypes.array,
  isAddAnotherPhoneDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  searchText: PropTypes.string,
  handleSearch: PropTypes.func,
  clearUserSearch: PropTypes.func,
  users: PropTypes.object,
  isViewOtherPhoneOwnersDisabled: PropTypes.bool,
  isRemovePhoneDisabled: PropTypes.bool,
  isEditIntegratedContactsAllowed: PropTypes.bool,
};
