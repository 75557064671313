import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import {
  Button,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Select,
  LoaderPulse,
  Tooltip,
} from 'rhinostyle';

import { Types, AppConstants } from '../../constants';
import { MaskingHelpers, PhoneHelpers, UserHelpers } from '../../helpers';

export default class Phone extends PureComponent {
  state = {
    id: this.props.phone.id || null,
    ownerId: this.props.phone.ownerId,
    typeId: this.props.phone.typeId || Types.TYPE_CELL,
    otherUsers: this.props.phone.otherUsers,
    value: PhoneHelpers.formatPhone(this.props.phone.value),
    isPhoneValid: PhoneHelpers.isPhoneNumberValid(this.props.phone.value),
    label: this.props.phone.label,
    hasOptedOut: this.props.phone.hasOptedOut,
    newOwnerId: null,
    isPhoneOwnersVisible: false,
    initialValue: PhoneHelpers.formatPhone(this.props.phone.value),
    initialOwnerId: this.props.phone.ownerId,
    isEditing : this.props.userId > -1, //eslint-disable-line
    isPhoneTextable: this.props.phone.isTextable,
    nonTextableNumber: !this.props.phone.isTextable ? PhoneHelpers.formatPhone(this.props.phone.value) : '',
    isShowEHRUpdateWarning: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.loading || this.props.idx !== this.props.phoneSearchIdx) return; // no need to run below logic if the phone is not the active

    if (this.props.searchText.length === 14 && this.state.initialValue !== this.props.searchText) { // input has changed and phone number length, so in "search mode"
      // Get a list of phone ids off the searched users
      const searchedUsersPhones = this.props.phoneUserSearchIds.reduce((filtered, user) => {
        filtered.push(...this.props.users[user].phones.filter((p) => this.props.phones[p] && (
          PhoneHelpers.formatPhone(this.props.phones[p].value) === this.state.value)));
        const newFilteredSet = [...new Set([...filtered])];
        return newFilteredSet;
      }, []);

      const matchedPhone = searchedUsersPhones.length > 0 && this.props.phones[searchedUsersPhones[0]];

      // Get searched users into same format as 'otherUsers'
      const searchedUsers = this.props.phoneUserSearchIds.map((p) => {
        const user = this.props.users[p];
        return {
          userId: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          preferredName: user.preferredName,
        };
      });

      if (
        matchedPhone
        && this.props.phones[searchedUsersPhones[0]] !== prevProps.phones[searchedUsersPhones[0]]
      ) { // if we find a match, use info from match for owner info
        this.setState({ // eslint-disable-line react/no-did-update-set-state
          ownerId: matchedPhone.ownerId,
          otherUsers: searchedUsers, // matchedPhone.otherUsers,
          newOwnerId: null,
          initialOwnerId: matchedPhone.ownerId,
        });
      } else if (!matchedPhone) {
        this.setState({ // eslint-disable-line react/no-did-update-set-state
          ownerId: null,
          otherUsers: null,
          newOwnerId: null,
          initialOwnerId: null,
        });
      }
    } else if ((this.props.searchText.length === 14)
      && this.state.initialValue === this.props.searchText
      && this.props.userId > 0) { // if we are reverting back to original existing phone, don't need this on create
      const originalPhone = this.props.phones[this.props.phone.id];
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        id: this.props.phone.id || null,
        ownerId: originalPhone.ownerId,
        typeId: originalPhone.typeId || Types.TYPE_CELL,
        otherUsers: originalPhone.otherUsers,
        value: PhoneHelpers.formatPhone(originalPhone.value),
        newOwnerId: null,
        initialValue: PhoneHelpers.formatPhone(originalPhone.value),
        initialOwnerId: originalPhone.ownerId,
      });
    }
  }

  handleChangeOwnership = (newOwnerId) => this.setState({ newOwnerId }, this.handlePhoneChange);

  // this handler is used to update phoneGroup in parent container
  handlePhoneChange = () => {
    if (this.props.handleChange) {
      this.props.handleChange(this.state, this.props.idx);
    }
  }

  resetIsTextableIfPhoneNumberChanged(newPhoneNumber) {
    this.setState({
      isPhoneTextable: newPhoneNumber !== this.state.nonTextableNumber,
    });
  }

  // this handler is used for only phone number field in form
  handlePhoneNumberChange = (name, rawValue, formattedValue) => {
    this.props.handleSearch(formattedValue);
    this.setState({
      value: formattedValue,
      isPhoneValid: PhoneHelpers.isPhoneNumberValid(rawValue),
      isShowEHRUpdateWarning: true,
    }, () => {
      this.handlePhoneChange();
      this.resetIsTextableIfPhoneNumberChanged(formattedValue);
    });
  }

  // this handler is used for phone label field in form
  handleChange = (name, rawValue) => {
    this.setState({ [name]: rawValue }, () => {
      this.handlePhoneChange();
    });
  }

  // this handler is used for phone type field in form
  handleSelect = (name, typeId) => {
    this.setState({ typeId }, () => {
      this.handlePhoneChange();
    });
  }

  handleMouseDown = (idx) => {
    this.props.handlePhoneSearchIdx(idx);
  }

  togglePhoneOwnersVisibility = () =>
    this.setState({ isPhoneOwnersVisible: !this.state.isPhoneOwnersVisible });

  handleUserTransition = (userId) => {
    this.props.handleViewProfileTransition(userId);
  }

  renderChangeOwnershipText = (text) => (
    <div className="u-text-small">
      <span className="u-text-accent">{text}</span> |&nbsp;
      <Button reset className="u-text-primary" onClick={this.togglePhoneOwnersVisibility}>Change Ownership</Button>
    </div>
  );

  renderSearchHelp = () => <div className="u-text-left u-text-small"><LoaderPulse type="secondary" /> Checking to see if number already exists</div>;

  renderOwnershipChoices = (u) => {
    const { userId } = u;

    // @TODO Need to hold the "original" current owner so this doesn't switch on selection
    const owner = this.state.initialOwnerId === userId;
    return (
      <Radio
        key={userId}
        value={userId}
        label={(
          <>
            <div className="profile__data__phone__box__label">
              {UserHelpers.formatPhoneOwnershipName(u)}{owner && <span className="u-text-muted"> (Current Owner)</span>}
            </div>
            {userId !== this.props.userId && (
              <Button reset className="u-text-primary" onClick={() => this.handleUserTransition(userId)}>
                View Profile
              </Button>
            )}
          </>
        )}
      />
    );
  }

  renderOtherContacts = (ownershipText, type) => {
    const { idx, userId, activeUser, isViewOtherPhoneOwnersDisabled } = this.props;
    const { ownerId, isPhoneOwnersVisible, value } = this.state;
    const partialNumber = value && value.length < 14;
    let { otherUsers } = this.state;

    // Add current user if they aren't already in the list
    if (otherUsers && !Object.keys(otherUsers).some((u) => otherUsers[u].userId === userId) && activeUser) {
      otherUsers = [{ userId, firstName: activeUser.firstName, lastName: activeUser.lastName }].concat(otherUsers);
    }

    const searchAlertText = 'u-text-small u-text-accent u-m-t-small';
    const mutedText = 'u-text-small u-text-muted u-m-t-small';

    if (!otherUsers || partialNumber) return false;

    //  per @mccraiganthony we only want the ability to open this RadioGroup. Giving the ability to close it complicates some UX surrounding
    // "Did I just cancel my request to change ownership?"
    return (
      <>
        <span className={type === 'search' ? searchAlertText : mutedText}>
          {ownershipText} |&nbsp;
          <Button reset className="u-text-primary" disabled={isViewOtherPhoneOwnersDisabled} onClick={this.togglePhoneOwnersVisibility}>
            {isPhoneOwnersVisible ? 'Close' : 'Change Ownership'}
          </Button>
        </span>
        {isPhoneOwnersVisible && (
          <div className="u-m-t">
            <div className="row">
              <div className="column-6@small">
                <div className="profile__data__phone__box">
                  <RadioGroup
                    blockGroup
                    name={`phone_ownership-${idx}`}
                    onChange={this.handleChangeOwnership}
                    selectedValue={ownerId}
                  >
                    {otherUsers.map((u) => this.renderOwnershipChoices(u))}
                  </RadioGroup>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  render() {
    const {
      count,
      createCP,
      currentUser,
      handleRemoveContactData,
      idx,
      phoneSearchIdx,
      integrated,
      numPhones,
      serverValMessage,
      types,
      validationMessage,
      searchText,
      loading,
      isRemovePhoneDisabled,
      isEditIntegratedContactsAllowed,
    } = this.props;

    const { isEditing, isPhoneValid, isPhoneTextable, isShowEHRUpdateWarning } = this.state;
    const label = createCP ? 'Phone Number' : `Phone Number ${count}`;
    const typeOpts = types.slice();
    const ownershipText = this.state.ownerId === this.props.userId ? 'Currently owned by this contact' : 'Number owned by another contact';
    const type = idx === phoneSearchIdx && searchText.length === 14 && this.state.initialValue !== searchText ? 'search' : 'normal';
    const renderPhoneInvalidWarning = isEditing && !isPhoneValid && !validationMessage && !serverValMessage;
    const renderNonTextableWarning = isPhoneTextable === undefined ? false : !isPhoneTextable && isPhoneValid && !validationMessage && !serverValMessage;
    const textableTooltipText = 'If you believe this number is textable, send a message, and it will be updated as textable if delivered.';
    const renderPhoneUpdateToEHRWarning = isEditing && isShowEHRUpdateWarning && integrated;
    return (
      <div className="form__group">
        <div className="row">
          <div className="column-5@xsmall column-5@small">
            <Input
              disabled={integrated && !isEditIntegratedContactsAllowed && !currentUser.isCcr}
              validationMessage={validationMessage || serverValMessage}
              type="tel"
              addon="right"
              format={MaskingHelpers.phone}
              name={`userPhones-${idx}`}
              placeholder={AppConstants.PHONE_PLACEHOLDER}
              initialValue={PhoneHelpers.formatPhone(this.state.value)}
              onChange={this.handlePhoneNumberChange}
              onMouseDown={() => this.handleMouseDown(idx)}
              label={label}
            >
              {this.state.hasOptedOut && <span className="edit-profile__phone__opted-out-label ">Opted Out</span>}
            </Input>
          </div>
          <div className="column-3@xsmall column-3@small">
            <Select
              disabled={(integrated && !currentUser.isCcr) || isRemovePhoneDisabled}
              onSelect={this.handleSelect}
              name="phoneType"
              options={typeOpts}
              selected={this.state.typeId}
              label="Phone Type"
            />
          </div>
          <div className="column-4@xsmall column-3@small">
            <Input
              disabled={integrated && !currentUser.isCcr}
              type="text"
              name="label"
              initialValue={this.state.label}
              onChange={this.handleChange}
              label="Phone Label"
            />
          </div>
          {(numPhones > 1 && (!integrated || currentUser.isCcr)) && (
            <div className="column-1@xsmall column-1@small edit-profile__close-button__column">
              <Button
                reset
                disabled={isRemovePhoneDisabled}
                onClick={() => handleRemoveContactData(idx, 'userPhones')}
                size="small"
                className="u-text-danger edit-profile__close-button__icon"
              >
                <Icon icon="close" />
              </Button>
            </div>
          )}
        </div>
        {renderNonTextableWarning && (
          <>
            <span className="u-text-accent u-text-small">
              Non-textable &nbsp;
              <Tooltip placement="bottom" content={textableTooltipText} type="dark">
                <Icon bump="up" icon="info-circle" className="u-text-primary" />
              </Tooltip>
            </span>
            &nbsp;
          </>
        )}
        {renderPhoneInvalidWarning && <div className="u-text-accent u-text-small u-text-italic">Not a valid 10 digit number</div>}
        {renderPhoneUpdateToEHRWarning && <div className="u-text-accent u-text-small u-text-italic">Changing this information in Rhinogram will not change it in your EHR. Please update this information in your EHR.</div>}
        {!loading && this.renderOtherContacts(ownershipText, type)}
        {idx === phoneSearchIdx && loading && this.renderSearchHelp()}
      </div>
    );
  }
}

Phone.propTypes = {
  activeUser: PropTypes.object,
  currentUser: PropTypes.object,
  count: PropTypes.number,
  createCP: PropTypes.bool,
  handleChange: PropTypes.func,
  handleRemoveContactData: PropTypes.func,
  handleViewProfileTransition: PropTypes.func,
  handlePhoneSearchIdx: PropTypes.func,
  idx: PropTypes.number,
  numPhones: PropTypes.number,
  phone: PropTypes.object,
  phones: PropTypes.object,
  phoneUserSearchIds: PropTypes.array,
  serverValMessage: PropTypes.string,
  types: PropTypes.array,
  userId: PropTypes.number,
  users: PropTypes.object,
  validationMessage: PropTypes.string,
  integrated: PropTypes.bool,
  handleSearch: PropTypes.func,
  loading: PropTypes.bool,
  searchText: PropTypes.string,
  phoneSearchIdx: PropTypes.number,
  isRemovePhoneDisabled: PropTypes.bool,
  isViewOtherPhoneOwnersDisabled: PropTypes.bool,
  isEditIntegratedContactsAllowed: PropTypes.bool,
};
