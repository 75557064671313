import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { LocationHelpers, BulkActionHelpers, DataHelpers } from '../helpers';
import Inbox from '../components/Inbox';
import * as UIReducer from '../reducers/uiReducer';
import * as UserReducer from '../reducers/userReducer';
import * as InboxReducer from '../reducers/inboxReducer';
import * as ChannelReducer from '../reducers/channelReducer';
import { userSelectors, inboxSelectors, organizationSelectors } from '../selectors';
import { THREAD_SIZE } from '../constants/AppConstants';
import { userHasAnyOfPermissions, hasAnyInboxViewPermissions } from '../helpers/UserHelpers';
import { CHANNEL_VIEW } from '../constants/UserPermissionsConstants';
import StorageService from '../services/StorageService';

class InboxContainer extends Component {
  scrollPosition = 'top';  //eslint-disable-line
  static initialState = {
    isChecked: false,
    labelValueAssociated: '',
    selectedInboxEventIds: [],
    selectedIdCount: 0,
    checkboxClassName: '',
    isModalOpen: false,
    formInProgress: false,
    activeKey: 0,
    isAllSelectedInboxFollowed: false,
  }
  state = {
    ...InboxContainer.initialState,
    requestHipaa: false,
    pageLoading: true,
    bulkActionsList: [],
    bulkSelectionList: [],
    currentPageNumber: 1,
    pageNumber: 0,
    totalPageCount: 0,
    totalInboxCount: 0,
    contactIds: [],
    bulkActiveFromChannelId: null,
    isBulkMessagingModalOpen: false,
    selectedInboxEvents: [],
    sort: 'descending',
  }

  componentDidMount() {
    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
    const locationState = this.props.location && this.props.location.state;
    const selectedIds = locationState && locationState.selectedInboxEventIds;
    const selectedEvents = locationState && locationState.selectedInboxEvents;
    const contactIds = locationState && locationState.contactIds;
    if (locationState && locationState.pageNumber >= 0 && locationState.currentPageNumber) {
      this.setState({
        currentPageNumber: locationState.currentPageNumber,
        pageNumber: locationState.pageNumber,
        totalPageCount: locationState.totalPageCount,
        selectedInboxEventIds: [...selectedIds],
        selectedInboxEvents: [...selectedEvents],
        isChecked: locationState.isChecked,
        labelValueAssociated: locationState.labelValueAssociated,
        checkboxClassName: locationState.checkboxClassName,
        selectedIdCount: locationState.selectedIdCount,
        bulkActionsList: locationState.bulkActionsList,
        contactIds: [...contactIds],
      });
    }
    options.pageNumber = locationState && locationState.pageNumber;

    const preferredInboxSorting = StorageService.readEntry('inbox-sorting');
    if (preferredInboxSorting) {
      options.sort = preferredInboxSorting;
      this.setState({ sort: preferredInboxSorting });
    } else {
      options.sort = this.state.sort;
    }
    const bulkSelectionList = BulkActionHelpers.fetchBulkSelectionList(options);
    const updateState = {
      bulkSelectionList,
    };
    if (!this.props.location.state) {
      updateState.isChecked = false;
      updateState.selectedInboxEventIds = [];
      updateState.labelValueAssociated = '';
    }
    this.setState(updateState, () => {
      if (this.props.location.state) {
        this.props.history.replace({ ...this.props.location, state: null });
      }
    });
    this.props.resetEventData();
    this.props.requestInboxView();
    this.props.setInboxContext(options);

    if (hasAnyInboxViewPermissions()) this.props.fetchInboxSections();
    if (userHasAnyOfPermissions([CHANNEL_VIEW])) this.props.fetchChannels();
    this.props.fetchInbox(options);
  }

  getToIndexForInboxIds() {
    const inboxIds = this.props.inboxEventIds;
    let toIndex = (this.props.inboxPageNo * THREAD_SIZE + inboxIds.length);
    if ((this.props.inboxPageNo * THREAD_SIZE + inboxIds.length) > THREAD_SIZE && this.props.inboxPageNo === 0) {
      toIndex = THREAD_SIZE;
    }
    return toIndex;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.inboxEventIds.length !== this.props.inboxEventIds.length && this.props.inboxEventIds.length) {
      this.scrollTo();
    }

    if (prevProps.inboxName !== this.props.inboxName) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        pageNumber: 0,
        currentPageNumber: 1,
      });
    }
    const {
      groupId: prevGroupId,
      following: prevFollowing,
      direct: prevDirect,
      assigned: prevAssigned,
    } = LocationHelpers.getInboxOptions(prevProps.match.params, prevProps.location);
    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
    const { groupId, following, direct, assigned } = options;

    if (!this.props.inboxName) this.props.history.push('/inbox'); // In the scenario where user is in an inbox group that they are removed from

    if (this.props.pageLoading !== prevProps.pageLoading) {
      this.setState({ pageLoading: this.props.pageLoading }); // eslint-disable-line react/no-did-update-set-state
    }

    if ((prevAssigned !== assigned) || (prevGroupId !== groupId) || (prevFollowing !== following) || (prevDirect !== direct)) {
      const bulkSelectionList = BulkActionHelpers.fetchBulkSelectionList(options);
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        bulkSelectionList,
        isChecked: false,
        selectedInboxEventIds: [],
        selectedInboxEvents: [],
        labelValueAssociated: '',
        activeKey: 0,
        bulkActionsList: [],
        contactIds: [],
      });
      const preferredInboxSorting = StorageService.readEntry('inbox-sorting');
      if (preferredInboxSorting) {
        options.sort = preferredInboxSorting;
        this.setState({ sort: preferredInboxSorting });
      } else {
        options.sort = this.state.sort;
      }
      this.scrollPosition = 'top'; // Reset initial scroll position
      this.setState({ ...InboxContainer.initialState, selectedInboxEventIds: [] }); // eslint-disable-line react/no-did-update-set-state
      this.props.resetEventData();
      this.props.requestInboxView();
      this.props.setInboxContext(options);
      this.props.fetchInboxSections();
      this.props.fetchInbox(options);
      this.props.fetchChannels();
    } else if (prevProps.inboxEventIds.length !== this.props.inboxEventIds.length &&
      this.props.inboxPageNo === prevProps.inboxPageNo && prevProps.inboxEventIds.length > this.props.inboxEventIds.length
      && this.props.inboxLoading === prevProps.inboxLoading) {
      const removedEventsIds = prevProps.inboxEventIds.filter((id) => !this.props.inboxEventIds.includes(id));
      const updatedEventIds = prevState.selectedInboxEventIds.filter((id) => !removedEventsIds.includes(id));
      this.setState({
        selectedInboxEventIds: updatedEventIds,
        selectedInboxEvents: prevState.selectedInboxEvents.filter((event) => updatedEventIds.includes(event.id)),
        selectedIdCount: updatedEventIds.length,
        checkboxClassName: updatedEventIds === 0 ? '' : 'partially-checked',
        labelValueAssociated: updatedEventIds.length > 0 ? `${updatedEventIds.length} Selected` : '',
      });
    }
    if (this.props.inboxSections?.length || this.props.inboxChatSections?.length) {
      const selectedOption = this.getSelectedInboxOption(options);

      const currentInboxSection = this.props.inboxSections.find((inboxSection) => {
        if (selectedOption === 'groupId') {
          return inboxSection.userGroupId === options.groupId;
        }
        return inboxSection.type === selectedOption;
      });

      const totalCount = currentInboxSection?.totalCount || 0;
      const totalPages = totalCount && Math.ceil(totalCount / THREAD_SIZE);

      if (totalCount !== this.state.totalInboxCount || this.props.inboxLoading !== prevProps.inboxLoading) {
        this.setState(() => ({ // eslint-disable-line react/no-did-update-set-state
          totalInboxCount: totalCount,
          totalPageCount: totalPages,
        }));
      }
    }
  }

  onSelect = (item) => {
    const events = Object.values(this.props.events);
    let { selectedInboxEventIds, contactIds, selectedInboxEvents } = this.state;
    const { inboxEventIds, match, location } = this.props;
    const options = LocationHelpers.getInboxOptions(match.params, location);
    const selectedIds = BulkActionHelpers.fetchSelectedIds(item.label, events);
    let noneThreadIds = [];
    if (item.label === 'None') {
      noneThreadIds = inboxEventIds.filter((id) => selectedInboxEventIds.includes(id));
      selectedInboxEventIds = selectedInboxEventIds.filter((id) => !noneThreadIds.includes(id));
      selectedInboxEvents = selectedInboxEvents.filter((event) => !noneThreadIds.includes(event.id));
      const deselectedContactIds = BulkActionHelpers.fetchSelectedPatientUserIds(noneThreadIds, events);

      contactIds = contactIds.filter((id) => !deselectedContactIds.includes(id));
    } else {
      selectedInboxEventIds = selectedInboxEventIds.filter((id) => !inboxEventIds.includes(id));

      const newSelectedList = selectedIds.filter((id) => !selectedInboxEventIds.includes(id));
      selectedInboxEventIds = [...selectedInboxEventIds, ...newSelectedList];

      selectedInboxEvents = selectedInboxEvents.filter((event) => !inboxEventIds.includes(event.id));

      const newSelectedInboxEvents = events.filter((event) => selectedInboxEventIds.includes(event.id));
      selectedInboxEvents = [...selectedInboxEvents, ...newSelectedInboxEvents];
      const currentPageContactIds = events.map((event) => event.user);
      contactIds = contactIds.filter((id) => !currentPageContactIds.includes(id));
      const newContactIds = BulkActionHelpers.fetchSelectedPatientUserIds(selectedIds, events);

      contactIds = [...contactIds, ...newContactIds];
    }
    let { bulkActionsList, activeKey } = this.state;
    let { isChecked } = item;

    const { isBulkMessagingEnabled } = this.props.currentOrg;
    bulkActionsList = BulkActionHelpers.fetchBulkActions(item.label, options, isBulkMessagingEnabled, false, selectedInboxEventIds);

    let { selectedIdCount, labelValueAssociated, checkboxClassName } = this.state;

    selectedIdCount = selectedIds.length;
    labelValueAssociated = `${selectedInboxEventIds.length} Selected`;
    checkboxClassName = (selectedIdCount !== events.length) ? 'partially-checked' : '';
    activeKey = item.id;
    if (selectedIdCount === 0) {
      checkboxClassName = '';
      isChecked = false;
      activeKey = 0;
      labelValueAssociated = selectedInboxEventIds.length > 0 ? `${selectedInboxEventIds.length} Selected` : '';
    }
    this.setState({
      isChecked,
      bulkActionsList,
      selectedInboxEventIds,
      selectedIdCount,
      labelValueAssociated,
      checkboxClassName,
      activeKey,
      contactIds,
      selectedInboxEvents,
    });
  }

  onChange = () => {
    const { isChecked, selectedInboxEventIds, contactIds, selectedInboxEvents } = this.state;
    const events = Object.values(this.props.events);
    if (isChecked) {
      const { inboxEventIds } = this.props;
      const currentSelectedList = inboxEventIds.filter((item) => selectedInboxEventIds.includes(item));
      const newSelectedInboxEventIds = selectedInboxEventIds.filter((threadId) => !currentSelectedList.includes(threadId));
      const newSelectedInboxEvents = selectedInboxEvents.filter((event) => !currentSelectedList.includes(event.id));
      const currentContactIdList = BulkActionHelpers.fetchSelectedPatientUserIds(currentSelectedList, events);
      const newContactIdList = contactIds.filter((id) => !currentContactIdList.includes(id));
      const dropdownCheckLabel = newSelectedInboxEventIds.length > 0 ? `${newSelectedInboxEventIds.length} Selected` : '';
      let { bulkActionsList } = this.state;
      const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
      const { isBulkMessagingEnabled } = this.props.currentOrg;
      bulkActionsList = BulkActionHelpers.fetchBulkActions('All', options, isBulkMessagingEnabled, false, newSelectedInboxEventIds);
      this.setState({
        labelValueAssociated: dropdownCheckLabel,
        selectedInboxEventIds: newSelectedInboxEventIds,
        selectedIdCount: 0,
        checkboxClassName: '',
        bulkActionsList,
        activeKey: 0,
        contactIds: newContactIdList,
        selectedInboxEvents: newSelectedInboxEvents,
      });
    } else {
      const { inboxEventIds } = this.props;
      const selectedIds = [...selectedInboxEventIds, ...inboxEventIds];
      const selectedEvents = [...selectedInboxEvents, ...events];
      const contactIdList = BulkActionHelpers.fetchSelectedPatientUserIds(inboxEventIds, events);
      const newContactIdList = [...contactIds, ...contactIdList];
      let { bulkActionsList } = this.state;
      const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
      const { isBulkMessagingEnabled } = this.props.currentOrg;
      bulkActionsList = BulkActionHelpers.fetchBulkActions('All', options, isBulkMessagingEnabled, false, selectedIds);
      this.setState({
        labelValueAssociated: `${selectedIds.length} Selected`,
        selectedInboxEventIds: selectedIds,
        selectedIdCount: selectedIds.length,
        bulkActionsList,
        activeKey: 1,
        contactIds: newContactIdList,
        selectedInboxEvents: selectedEvents,
      });
    }
    this.setState((prevState) => ({ isChecked: !prevState.isChecked }));
  }

  handleCheckboxOnItem = (id) => {
    const selectedIds = this.state.selectedInboxEventIds;
    let selectedEvents = this.state.selectedInboxEvents;
    const selectedInboxEventIdsLength = selectedIds.length;
    const contactIdList = this.state.contactIds;
    const { inboxEventIds, maxRhinoblastCount } = this.props;
    let { bulkActionsList } = this.state;
    let { selectedIdCount, checkboxClassName } = this.state;
    const events = Object.values(this.props.events);
    if (!selectedIds.includes(id)) {
      const contactId = BulkActionHelpers.getPatientUserId(id, events);
      const newSelectedEvent = events.find((event) => event.id === id);
      selectedIds.push(id);
      selectedEvents.push(newSelectedEvent);
      contactIdList.push(contactId);
      selectedIdCount += 1;
    } else {
      const index = selectedIds.indexOf(id);
      const contactId = BulkActionHelpers.getPatientUserId(id, events);
      const contactIdIndex = contactIdList.indexOf(contactId);
      selectedEvents = selectedEvents.filter((event) => event.id !== id);

      selectedIds.splice(index, 1);

      contactIdList.splice(contactIdIndex, 1);
      selectedIdCount -= 1;
    }
    if (selectedIdCount === 0) {
      this.setState({
        isChecked: false,
        labelValueAssociated: selectedIds.length > 0 ? `${selectedIds.length} Selected` : '',
        bulkActionsList: selectedIds.length > 0 ? this.state.bulkActionsList : [],
        checkboxClassName: '',
        activeKey: 0,
      });
    } else {
      const currentPageSelectedThreads = selectedIds.filter((threadId) => inboxEventIds.includes(threadId));

      checkboxClassName = currentPageSelectedThreads.length !== events.length && currentPageSelectedThreads.length > 0 ? 'partially-checked' : '';
      const newSelectedInboxEventIdsLength = selectedIds.length;

      // fetch bulkActionsList again if selection reached bulk messaging limit
      const checkForBulkMessageLimit = (selectedInboxEventIdsLength === maxRhinoblastCount && newSelectedInboxEventIdsLength > maxRhinoblastCount)
        || (selectedInboxEventIdsLength > maxRhinoblastCount && newSelectedInboxEventIdsLength === maxRhinoblastCount);
      if (!bulkActionsList.length || checkForBulkMessageLimit) {
        const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
        const { isBulkMessagingEnabled } = this.props.currentOrg;
        bulkActionsList = BulkActionHelpers.fetchBulkActions('All', options, isBulkMessagingEnabled, false, selectedIds);
      }

      const checkboxLabel = selectedIds.length > 0 ? `${selectedIds.length} Selected` : '';

      this.setState({
        labelValueAssociated: checkboxLabel,
        isChecked: currentPageSelectedThreads.length > 0,
        bulkActionsList,
        checkboxClassName,
      });
    }
    this.setState({
      selectedInboxEventIds: selectedIds,
      selectedInboxEvents: selectedEvents,
      selectedIdCount,
      contactIds: contactIdList,
    });
  }

  calculateParameters = (pageNumber, currentPageNumber, totalPageCount, totalInboxCount, selectedInboxEventIds) => {
    const newTotalInboxCount = totalInboxCount - selectedInboxEventIds.length;
    const newTotalPageCount = newTotalInboxCount && Math.ceil(newTotalInboxCount / THREAD_SIZE);
    let newPageNumber = 0;
    let newCurrentPageNumber = 0;

    if (newTotalInboxCount < totalInboxCount) {
      const updatedPageNumber = pageNumber - (totalPageCount - newTotalPageCount);

      newPageNumber = updatedPageNumber > 0 ? updatedPageNumber : 0;
      newCurrentPageNumber = newPageNumber + 1;
    } else {
      newPageNumber = pageNumber > 1 ? pageNumber - 1 : 0;
      newCurrentPageNumber = currentPageNumber > 2 ? currentPageNumber - 1 : 1;
    }
    return {
      newTotalInboxCount,
      newTotalPageCount,
      newPageNumber,
      newCurrentPageNumber,
    };
  }

  reloadContainer = (pageNumber, currentPageNumber, totalPageCount, totalInboxCount) => {
    this.setState({
      ...InboxContainer.initialState,
      selectedInboxEventIds: [],
      contactIds: [],
      pageNumber: pageNumber > -1 ? pageNumber : 0,
      currentPageNumber: currentPageNumber > 0 ? currentPageNumber : 1,
      totalPageCount,
      totalInboxCount,
      bulkActionsList: [],
      selectedInboxEvents: [],
    });

    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
    options.pageNumber = pageNumber > -1 ? pageNumber : 0;
  }

  getDefaultRouteChannel = (currentUserChannel, routeData) => {
    const { selectedInboxEventIds } = this.state;
    const events = Object.values(this.props.events);
    const selectedEvents = events.filter((event) => selectedInboxEventIds.includes(event.id));
    let defaultRouteChannel = null;

    const isAllSelectedEventsAssigned = selectedEvents.every((event) => event.assigned);
    if (!isAllSelectedEventsAssigned) { // if all threads are not assigned.
      return isAllSelectedEventsAssigned;
    }

    const hasSameDefaultRoute = selectedEvents.every((item) => {
      const selectedEventChannel = currentUserChannel.find((channel) => item.channels[0] === channel.id);

      if (selectedEventChannel && selectedEventChannel.routeGroupId) {
        if (routeData.size !== 0 && routeData.has('routeUserId')) {
          return false;
        }
        if (routeData.size === 0) {
          routeData.set('routeGroupId', selectedEventChannel.routeGroupId);
        } else {
          const routeGroupValue = routeData.get('routeGroupId');

          if (!DataHelpers.exists(routeGroupValue)) {
            return false;
          }

          const isExistingGroupRoute = routeData.has('routeGroupId') && routeGroupValue === selectedEventChannel.routeGroupId;

          if (!isExistingGroupRoute) {
            return false;
          }
        }
      } else if (selectedEventChannel && selectedEventChannel.routeUserId) {
        if (routeData.size !== 0 && routeData.has('routeGroupId')) {
          return false;
        }
        if (routeData.size === 0) {
          routeData.set('routeUserId', selectedEventChannel.routeUserId);
        } else {
          const routeUserValue = routeData.get('routeUserId');

          if (!DataHelpers.exists(routeUserValue)) {
            return false;
          }

          const isExistingUserRoute = routeData.has('routeUserId') && routeUserValue === selectedEventChannel.routeUserId;

          if (!isExistingUserRoute) {
            return false;
          }
        }
      }
      return true;
    });

    if (isAllSelectedEventsAssigned && hasSameDefaultRoute) {
      const routeTypeGroup = routeData.has('routeGroupId');
      const routeTypeUser = routeData.has('routeUserId');

      if (routeTypeGroup) {
        const routeGroupId = routeData.get('routeGroupId');
        defaultRouteChannel = currentUserChannel.find((channel) => channel.routeGroupId === routeGroupId).id;
      } else if (routeTypeUser) {
        const routeUserId = routeData.get('routeUserId');
        defaultRouteChannel = currentUserChannel.find((channel) => channel.routeUserId === routeUserId).id;
      }
    }

    return defaultRouteChannel;
  }

  getDefaultRoute = (isAllSelectedInboxFollowed) => {
    const currentUserChannel = Object.values(this.props.currentUserChannel);
    const routeData = new Map();
    const defaultRoute = this.getDefaultRouteChannel(currentUserChannel, routeData);

    this.setState({
      bulkActiveFromChannelId: defaultRoute,
      isModalOpen: true,
      isAllSelectedInboxFollowed,
    });
  }

  onSelectAction = (action) => {
    const { selectedInboxEventIds, pageNumber, currentPageNumber, totalPageCount, totalInboxCount, contactIds } = this.state;
    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
    let payload = {};

    if (action.code === 'assign') {
      const events = Object.values(this.props.events);
      const followingInboxes = selectedInboxEventIds.filter((event) => events.find((item) => item.id === event && item.following));
      const isAllSelectedInboxFollowed = followingInboxes.length === selectedInboxEventIds.length;

      this.getDefaultRoute(isAllSelectedInboxFollowed);
    } else if (action.code === 'assignToMe') {
      const toMemberId = this.props.currentUser && this.props.currentUser.id;

      this.handleAssignClick({ toMemberId });
    } else if (action.code === 'markAsRead' || action.code === 'markAsUnread') {
      const readStatus = action.code === 'markAsRead';
      const actionType = readStatus ? 'updateBulkRead' : 'updateBulkUnRead';
      payload = {
        eventIds: selectedInboxEventIds,
        read: readStatus,
      };
      options.pageNumber = pageNumber > -1 ? pageNumber : 0;
      this.props.updateBulkToggleRead(payload, actionType, options).then(() => {
        this.props.fetchInboxSections();
        this.reloadContainer(pageNumber, currentPageNumber, totalPageCount, totalInboxCount);
      });
    } else if (action.code === 'follow' || action.code === 'unFollow') {
      const followStatus = action.code === 'follow';
      const actionType = followStatus ? 'updateBulkFollow' : 'updateBulkUnFollow';

      payload = {
        eventIds: selectedInboxEventIds,
        following: followStatus,
      };
      const countObject = this.calculateParameters(pageNumber, currentPageNumber, totalPageCount, totalInboxCount, selectedInboxEventIds);
      const finalPageNumber = (options.following && action.code === 'unFollow') ? countObject.newPageNumber : pageNumber;

      options.pageNumber = finalPageNumber > -1 ? finalPageNumber : 0;
      this.props.updateBulkToggleFollow(payload, actionType, options).then(() => {
        this.props.fetchInboxSections();
        if (options.following && action.code === 'unFollow') {
          this.reloadContainer(countObject.newPageNumber, countObject.newCurrentPageNumber, countObject.newTotalPageCount, countObject.newTotalInboxCount);
        } else {
          this.reloadContainer(pageNumber, currentPageNumber, totalPageCount, totalInboxCount);
        }
      });
    } else if (action.code === 'closeBulkConversations' || action.code === 'completeAssignment') {
      const { groupId, direct, assigned } = options;
      const payloadList = contactIds.map((item) => ({ patientUserId: item, direct, assigned, groupId, conversationActionType: action.code }));
      const countObject = this.calculateParameters(pageNumber, currentPageNumber, totalPageCount, totalInboxCount, selectedInboxEventIds);

      options.pageNumber = countObject.newPageNumber > -1 ? countObject.newPageNumber : 0;
      this.props.closeConversationBulkAction(payloadList, action.code, options).then(() => {
        this.props.fetchInboxSections();
        this.reloadContainer(countObject.newPageNumber, countObject.newCurrentPageNumber, countObject.newTotalPageCount, countObject.newTotalInboxCount);
      });
    } else if (action.code === 'rhinoBlast') {
      this.setState({ isBulkMessagingModalOpen: true });
    }
  }

  getSelectedInboxOption = (object) => Object.keys(object).find((key) => object[key]);

  handleToggle = () => {
    this.setState({ isBulkMessagingModalOpen: !this.state.isBulkMessagingModalOpen });
  }

  selectedInboxEvents = () => {
    const users = Object.values(this.props.users);
    const { selectedInboxEvents } = this.state;
    const eventsUserIds = selectedInboxEvents.map((event) => event.user);
    const selectedUsers = users.filter((user) => eventsUserIds.includes(user.id));
    return selectedUsers;
  }

  handleCheckboxOnBulkMessagingEvents = (userId) => {
    const { selectedInboxEvents } = this.state;
    const event = selectedInboxEvents.find((e) => e.user === userId);
    this.handleCheckboxOnItem(event.id);
  }

  clearSelectedEvents = () => {
    this.setState({
      selectedInboxEventIds: [],
      selectedInboxEvents: [],
      isChecked: false,
      selectedIdCount: 0,
      labelValueAssociated: '',
      contactIds: [],
    });
  }

  onScroll = () => {
    const scrollContainer = this.inboxBodyRef.container.firstChild;
    const totalScroll = scrollContainer.scrollTop + scrollContainer.clientHeight;

    if (totalScroll === scrollContainer.scrollHeight) {
      this.scrollPosition = 'bottom';
    }

    if ((totalScroll !== scrollContainer.scrollHeight) && (scrollContainer.scrollTop !== 0)) {
      this.scrollPosition = 'middle';
    }

    if (scrollContainer.scrollTop === 0) {
      this.scrollPosition = 'top';
    }
  }

  scrollTo = () => {
    if (!this.inboxBodyRef) return;
    const scrollContainer = this.inboxBodyRef.container.firstChild;

    if (this.scrollPosition === 'top') {
      scrollContainer.scrollTop = 0;
    }
  }

  checkThreadSelection = (inboxEventIds) => {
    const { selectedInboxEventIds } = this.state;
    let { checkboxClassName, isChecked } = this.state;
    const selectedEventLength = selectedInboxEventIds && selectedInboxEventIds.length;
    const currentSelectedList = selectedEventLength &&
      selectedEventLength > 0 &&
      inboxEventIds.filter((item) => selectedInboxEventIds.includes(item));

    if (currentSelectedList && currentSelectedList.length > 0 && currentSelectedList.length === inboxEventIds.length) {
      isChecked = true;
      checkboxClassName = '';
    } else if (currentSelectedList && currentSelectedList.length > 0 && currentSelectedList.length !== inboxEventIds.length) {
      isChecked = true;
      checkboxClassName = 'partially-checked';
    } else {
      isChecked = false;
      checkboxClassName = '';
    }
    const checkboxLabel = selectedInboxEventIds.length > 0 ? `${selectedInboxEventIds.length} Selected` : '';
    this.setState({
      checkboxClassName,
      isChecked,
      labelValueAssociated: checkboxLabel,
      selectedIdCount: selectedInboxEventIds.length,
    });
  }

  handlePagination = (direction) => {
    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
    options.sort = this.state.sort;

    if (direction === 'previous') {
      if (this.state.pageNumber >= 1) {
        options.pageNumber = this.state.pageNumber - 1;
        this.setState((prevState) => ({ pageNumber: prevState.pageNumber - 1, currentPageNumber: prevState.currentPageNumber - 1, activeKey: 0 }));
        this.props.fetchInbox(options).then(() => {
          const { inboxEventIds } = this.props;

          this.checkThreadSelection(inboxEventIds);
        });
      }
    } else {
      if (this.state.pageNumber + 1 < this.state.totalPageCount) { // eslint-disable-line no-lonely-if
        options.pageNumber = this.state.pageNumber + 1;
        this.setState((prevState) => ({ pageNumber: prevState.pageNumber + 1, currentPageNumber: prevState.currentPageNumber + 1, activeKey: 0 }));
        this.props.fetchInbox(options).then(() => {
          const { inboxEventIds } = this.props;

          this.checkThreadSelection(inboxEventIds);
        });
      }
    }
  }

  handleSorting = (direction) => {
    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
    const newSortDirection = direction === 'ascending' ? 'descending' : 'ascending';
    options.sort = newSortDirection;
    options.pageNumber = 0;
    this.setState({ sort: newSortDirection, pageNumber: 0 });
    StorageService.createEntry('inbox-sorting', newSortDirection);
    this.props.fetchInbox(options).then(() => {
      const { inboxEventIds } = this.props;

      this.checkThreadSelection(inboxEventIds);
    });
  }

  handleInboxItemClick = (userId, eventId) => {
    const {
      currentPageNumber,
      pageNumber,
      selectedInboxEventIds,
      totalPageCount,
      isChecked,
      labelValueAssociated,
      checkboxClassName,
      selectedIdCount,
      bulkActionsList,
      contactIds,
      selectedInboxEvents,
    } = this.state;
    this.props.history.push({
      pathname: `${this.props.location.pathname}/user/${userId}`,
      state: {
        currentPageNumber,
        pageNumber,
        selectedInboxEventIds,
        totalPageCount,
        isChecked,
        labelValueAssociated,
        checkboxClassName,
        selectedIdCount,
        bulkActionsList,
        contactIds,
        selectedInboxEvents,
        currentEventId: eventId,
      },
    });
  }

  shapeAssignmentRequest = (patientUserId, request, isFollowClick) => {
    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
    const { toUserGroupId, toMemberId, note, attachments, following } = request;
    const { groupId, assigned, direct } = options;

    return {
      patientUserId,
      groupId,
      ...(assigned || direct) && {
        assigned: true,
      },
      ...isFollowClick && {
        following,
      },
      ...toUserGroupId && {
        toUserGroupId,
      },
      ...toMemberId && {
        toMemberId,
      },
      ...(note?.length || attachments?.length) && {
        note,
        attachments,
      },
    };
  }

  handleAssignClick = (request, isFollowClick) => {
    this.setState({ formInProgress: true });

    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
    const { selectedInboxEventIds, pageNumber, currentPageNumber, totalPageCount, totalInboxCount, contactIds } = this.state;
    const payloadList = contactIds.map((patientUserId) => this.shapeAssignmentRequest(patientUserId, request, isFollowClick));
    const countObject = this.calculateParameters(pageNumber, currentPageNumber, totalPageCount, totalInboxCount, selectedInboxEventIds);
    options.pageNumber = countObject.newPageNumber > -1 ? countObject.newPageNumber : 0;

    this.createBulkAssignment(request, payloadList, options)
      .then(() => {
        this.props.fetchInboxSections();
        this.reloadContainer(countObject.newPageNumber, countObject.newCurrentPageNumber, countObject.newTotalPageCount, countObject.newTotalInboxCount);
      });
  }

  createBulkAssignment = (request, payloadList, options) => {
    if (request.toUserGroupId) {
      return this.props.createBulkAssignmentGroup(payloadList, options);
    } else if (request.toMemberId === this.props.currentUser.id) {
      return this.props.createBulkAssignmentSelf(payloadList, options);
    } else {
      return this.props.createBulkAssignmentMember(payloadList, options);
    }
  }

  handleUserSearchScopedAction = (searchText, activeFilterParam) => {
    this.props.history.push({
      pathname: '/contacts/create',
      state: {
        searchText,
        activeFilterParam,
      },
    });
  }

  getSelectedFollowingThreads = (existingThreadIds) => {
    const { inboxEventIds, maxRhinoblastCount } = this.props;
    let { selectedInboxEventIds, selectedIdCount, labelValueAssociated, isChecked, checkboxClassName, selectedInboxEvents, bulkActionsList } = this.state;
    const unfollowedThreadIdList = existingThreadIds.filter((id) => !inboxEventIds.includes(id)); // get list of unfollowed threads.
    const selectedInboxEventIdsLength = selectedInboxEventIds.length;

    selectedInboxEvents = selectedInboxEvents.filter((event) => !unfollowedThreadIdList.includes(event.id)); // update selectedEvents, removed unfollowed threads.
    selectedInboxEventIds = selectedInboxEventIds.filter((id) => !unfollowedThreadIdList.includes(id)); // update selected threads, removed unfollowed threads.
    const newSelectedList = inboxEventIds.filter((id) => selectedInboxEventIds.includes(id)); // list of selected threads on current page.
    const newSelectedInboxEventIdsLength = selectedInboxEventIds.length;

    if (selectedInboxEventIdsLength > maxRhinoblastCount && newSelectedInboxEventIdsLength <= maxRhinoblastCount) {
      const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);
      const { isBulkMessagingEnabled } = this.props.currentOrg;
      bulkActionsList = BulkActionHelpers.fetchBulkActions('All', options, isBulkMessagingEnabled, false, selectedInboxEventIds);
    }

    selectedIdCount = newSelectedList.length;
    labelValueAssociated = selectedInboxEventIds.length > 0 ? `${selectedInboxEventIds.length} Selected` : '';

    const newCheckboxClassName = newSelectedList.length > 0 && newSelectedList.length === inboxEventIds.length ? '' : 'partially-checked';

    checkboxClassName = newSelectedList.length !== inboxEventIds.length ? 'partially-checked' : newCheckboxClassName;
    isChecked = !!newSelectedList.length;
    this.setState({
      selectedInboxEvents,
      selectedInboxEventIds,
      selectedIdCount,
      labelValueAssociated,
      checkboxClassName,
      isChecked,
      bulkActionsList,
    });
  }

  updateFollowing = (userId, followingFlag, e, read) => {
    // optimistic update, if it errors it should rerender anyway.
    const $inboxItem = e.currentTarget.closest('.resource');

    if ($inboxItem.classList.contains('inbox__item--following')) {
      $inboxItem.classList.remove('inbox__item--following');
    } else {
      $inboxItem.classList.add('inbox__item--following');
    }

    const options = LocationHelpers.getInboxOptions(this.props.match.params, this.props.location);

    const followingOpts = {
      userId,
      followingFlag,
      originalReadStatus: read,
    };

    const existingThreadIds = this.props.inboxEventIds;

    if (options.groupId) {
      followingOpts.groupId = options.groupId;
    } else if (options.assigned) {
      followingOpts.assigned = true;
    } else if (options.direct) {
      followingOpts.direct = true;
    } else if (options.following) {
      followingOpts.following = true;
    }

    this.props.updateFollowing(followingOpts).then(() => {
      this.props.fetchInboxSections();

      if (!followingFlag && options.following) { // reload inbox if unfollowing
        this.props.resetEventData();
        this.props.requestInboxViewData();
        this.props.setInboxContext(options);
        this.props.fetchInbox(options).then(() => {
          this.getSelectedFollowingThreads(existingThreadIds);
        });
      }
    });
  }

  handleCloseModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  }

  render() {
    const { todayInboxEventIds, yesterdayInboxEventIds, olderInboxEventIds } = this.props;
    const todaysAdjustedInboxIdsList = [...todayInboxEventIds];
    const yesterdayAdjustedInboxIdsList = [...yesterdayInboxEventIds];
    const olderAdjustedInboxIdsList = [...olderInboxEventIds];
    if ((todayInboxEventIds.length + yesterdayInboxEventIds.length + olderInboxEventIds.length) > THREAD_SIZE) {
      // This is to adjust messages or to remove older message temporarily on opened page when new message comes in and limit of messages increases from THREAD_SIZE
      if (olderInboxEventIds.length > 0) {
        olderAdjustedInboxIdsList.splice(-1, 1);
      } else if (yesterdayInboxEventIds.length > 0) {
        yesterdayAdjustedInboxIdsList.splice(-1, 1);
      } else if (todayInboxEventIds.length > 0) {
        todaysAdjustedInboxIdsList.splice(-1, 1);
      }
    }
    const props = {
      currentUserId: this.props.currentUser.id,
      events: this.props.events,
      handleInboxItemClick: this.handleInboxItemClick,
      handleUserSearchScopedAction: this.handleUserSearchScopedAction,
      inboxBodyRef: (inboxBodyRef) => (this.inboxBodyRef = inboxBodyRef),
      inboxEventIds: this.props.inboxEventIds,
      inboxLoading: this.props.inboxLoading,
      inboxName: this.props.inboxName,
      inboxContext: this.props.inboxContext,
      olderInboxEventIds: olderAdjustedInboxIdsList,
      onScroll: this.onScroll,
      pageLoading: this.state.pageLoading,
      requestHipaa: this.state.requestHipaa,
      shouldShowStartConversationAction: !['Following', 'Assigned'].includes(this.props.inboxName),
      todayInboxEventIds: todaysAdjustedInboxIdsList,
      types: this.props.types,
      updateFollowing: this.updateFollowing,
      userOrganization: this.props.userOrganization,
      users: this.props.users,
      yesterdayInboxEventIds: yesterdayAdjustedInboxIdsList,
      isChecked: this.state.isChecked,
      onSelect: this.onSelect,
      labelValueAssociated: this.state.labelValueAssociated,
      onChange: this.onChange,
      selectedInboxEventIds: this.state.selectedInboxEventIds,
      handleCheckboxOnItem: this.handleCheckboxOnItem,
      bulkSelectionList: this.state.bulkSelectionList,
      bulkActionsList: this.state.bulkActionsList,
      checkboxClassName: this.state.checkboxClassName,
      onSelectAction: this.onSelectAction,
      isModalOpen: this.state.isModalOpen,
      handleCloseModal: this.handleCloseModal,
      currentUser: this.props.currentUser,
      handleAssignClick: this.handleAssignClick,
      formInProgress: this.state.formInProgress,
      activeKey: this.state.activeKey,
      isAllSelectedInboxFollowed: this.state.isAllSelectedInboxFollowed,
      handlePagination: this.handlePagination,
      totalPageCount: this.state.totalPageCount,
      currentPageNumber: this.state.currentPageNumber,
      totalInboxCount: this.state.totalInboxCount,
      isCcr: this.props.currentUser.isCcr,
      bulkActiveFromChannelId: this.state.bulkActiveFromChannelId,
      updateUser: this.props.updateUser,
      isBulkMessagingModalOpen: this.state.isBulkMessagingModalOpen,
      handleToggle: this.handleToggle,
      selectedInboxEvents: this.selectedInboxEvents,
      handleCheckboxOnBulkMessagingEvents: this.handleCheckboxOnBulkMessagingEvents,
      clearSelectedEvents: this.clearSelectedEvents,
      sort: this.state.sort,
      handleSorting: this.handleSorting,
    };

    return (<Inbox {...props} />);
  }
}

InboxContainer.propTypes = {
  bulkActiveFromChannelId: PropTypes.number,
  clearThreadEvents: PropTypes.func.isRequired,
  clearUser: PropTypes.func.isRequired,
  closeConversationBulkAction: PropTypes.func,
  convoOpen: PropTypes.bool.isRequired,
  createBulkAssignmentGroup: PropTypes.func.isRequired,
  createBulkAssignmentMember: PropTypes.func.isRequired,
  createBulkAssignmentSelf: PropTypes.func.isRequired,
  currentOrg: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  currentUserChannel: PropTypes.object,
  direct: PropTypes.bool,
  error: PropTypes.object,
  events: PropTypes.object,
  fetchChannels: PropTypes.func,
  fetchInbox: PropTypes.func.isRequired,
  fetchInboxSections: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  history: PropTypes.object,
  inboxChatSections: PropTypes.array,
  inboxContext: PropTypes.string.isRequired,
  inboxEventIds: PropTypes.array,
  inboxLoading: PropTypes.bool.isRequired,
  inboxName: PropTypes.string,
  inboxPageNo: PropTypes.number.isRequired,
  inboxSections: PropTypes.array,
  location: PropTypes.object,
  olderInboxEventIds: PropTypes.array.isRequired,
  pageLoading: PropTypes.bool,
  prefixes: PropTypes.object.isRequired,
  profileOpen: PropTypes.bool.isRequired,
  requestInboxView: PropTypes.func,
  requestInboxViewData: PropTypes.func,
  resetEventData: PropTypes.func,
  setInboxContext: PropTypes.func,
  suffixes: PropTypes.object.isRequired,
  threadLoading: PropTypes.bool.isRequired,
  todayInboxEventIds: PropTypes.array.isRequired,
  types: PropTypes.object.isRequired,
  updateBulkToggleFollow: PropTypes.func,
  updateBulkToggleRead: PropTypes.func,
  updateFollowing: PropTypes.func.isRequired,
  updateUser: PropTypes.func,
  userOrganization: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  yesterdayInboxEventIds: PropTypes.array.isRequired,
  maxRhinoblastCount: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const { inbox, channel, prefix, suffix, user, ui, type } = state;
  return {
    channels: channel.channels,
    convoOpen: ui.convoOpen,
    currentOrg: organizationSelectors.getCurrentOrg(state),
    currentUser: userSelectors.getLoggedInUser(state),
    currentUserChannel: inbox.currentUserChannel,
    direct: inbox.direct,
    error: ui.error,
    events: inbox.events,
    following: inbox.following,
    inboxChatSections: ui?.inboxSections?.chat ? ui.inboxSections.chat : inbox.inboxSections,
    inboxContext: inboxSelectors.getInboxContext(state),
    inboxEventIds: inboxSelectors.getFilteredInboxEventIds(state),
    inboxLoading: inbox.inboxLoading,
    inboxName: inboxSelectors?.getInboxName(state),
    inboxPageNo: inbox.inboxPageNo,
    inboxSections: ui?.inboxSections?.inbox ? ui.inboxSections.inbox : inbox.inboxSections,
    olderInboxEventIds: inboxSelectors.getInboxEventsOlder(state),
    pageLoading: inbox.pageLoading,
    prefixes: prefix.prefixes,
    profileOpen: ui.profileOpen,
    suffixes: suffix.suffixes,
    threadLoading: inbox.threadLoading,
    todayInboxEventIds: inboxSelectors.getInboxEventsToday(state),
    types: type.types,
    userOrganization: userSelectors.getLoggedInUserOrganization(state),
    users: user.users,
    yesterdayInboxEventIds: inboxSelectors.getInboxEventsYesterday(state),
    maxRhinoblastCount: organizationSelectors.getRhinoblastMaxPageSize(state),
  };
};

const actions = {
  clearThreadEvents: InboxReducer.clearThreadEvents,
  clearUser: UserReducer.clearUser,
  fetchUser: UserReducer.fetchUser,
  fetchChannels: ChannelReducer.fetchChannels,
  fetchInbox: InboxReducer.fetchInbox,
  fetchInboxSections: UIReducer.fetchInboxSections,
  setConvoOpen: UIReducer.setConvoOpen,
  setProfileOpen: UIReducer.setProfileOpen,
  updateFollowing: InboxReducer.updateFollowing,
  resetEventData: InboxReducer.resetEventData,
  setInboxContext: InboxReducer.setInboxContext,
  requestInboxView: InboxReducer.requestInboxView,
  requestInboxViewData: InboxReducer.requestInboxViewData,
  updateBulkToggleRead: InboxReducer.updateBulkToggleRead,
  updateBulkToggleFollow: InboxReducer.updateBulkToggleFollow,
  closeConversationBulkAction: InboxReducer.closeConversationBulkAction,
  createBulkAssignmentSelf: InboxReducer.createBulkAssignmentSelf,
  createBulkAssignmentMember: InboxReducer.createBulkAssignmentMember,
  createBulkAssignmentGroup: InboxReducer.createBulkAssignmentGroup,
  updateUser: UserReducer.updateUser,
};

export default connect(mapStateToProps, actions)(InboxContainer);
