import { createApi } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';

import { axiosBaseQuery } from '../helpers/AxiosHelper';

const vCardAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.id - b.id,
  selectId: (vCard) => vCard.id,
});
const vCardSelectors = vCardAdapter.getSelectors((state) => state);
function shapeThreadResult(result) {
  return result?.data || [];
}

export const vCardSlice = createApi({
  reducerPath: 'vcard',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Organization'],
  endpoints: (builder) => ({
    // Get all organization v cards
    getVCards: builder.query({
      query: () => {
        const url = '/organization/vcards';
        return { url, method: 'get', shapeResult: shapeThreadResult };
      },
      providesTags: ['Organization'],
      transformResponse: (responseData) => vCardAdapter.setAll(vCardAdapter.getInitialState(), responseData),
    }),

  }),
});

export const {
  useGetVCardsQuery,
} = vCardSlice;

export { vCardSelectors, vCardAdapter };

export default vCardSlice;
