import { cloneDeep } from './DataHelpers';

// eslint-disable-next-line import/prefer-default-export
export function syncTags(tags, tagIds) {
  const clonedTags = cloneDeep(tags);
  Object.keys(tags).forEach((key) => {
    if (!tagIds.includes(parseInt(key, 10))) {
      delete clonedTags[key];
    }
  });
  return clonedTags;
}
