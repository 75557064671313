import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'rhinostyle';
import AppointmentCampaignFormReminder from './AppointmentCampaignFormReminder';
import { useSortAppointmentReminders } from '../hooks';
import AppointmentCampaignAutomatedResponse from './AppointmentCampaignAutomatedResponse';

const AppointmentCampaignFormReminders = ({
  appointmentInputs,
  appointmentEhrStatuses,
  handleChange,
  variableOptions,
  isLoading,
  errors,
}) => {
  const { sortedReminders, shapedReminders } = useSortAppointmentReminders(appointmentInputs, isLoading);
  const disabledReminders = sortedReminders.filter((reminder) => !shapedReminders[reminder.name].enabled);

  const [showAutomatedResponse, setShowAutomatedResponse] = useState(false);

  const handleShowWarningMessage = (reminderName, show) => {
    if (show) {
      setShowAutomatedResponse(true);
    }
  };

  function handleAddReminder() {
    handleChange(getEnabledName(disabledReminders[0]), true);
  }

  function getEnabledName(reminder) {
    return `${reminder.name}Enabled`;
  }

  return (
    <div className="u-m-t-large">
      <div>
        {sortedReminders.map((sortedReminder, index) => {
          const reminder = shapedReminders[sortedReminder.name];
          return reminder.enabled || index === 0 ? (
            <AppointmentCampaignFormReminder
              key={reminder.name}
              appointmentInputs={appointmentInputs}
              appointmentEhrStatuses={appointmentEhrStatuses}
              handleChange={handleChange}
              errors={errors}
              initialValue={sortedReminder.template}
              variableOptions={variableOptions || []}
              reminder={reminder}
              attachments={appointmentInputs.attachments}
              onShowWarningMessage={(show) => handleShowWarningMessage(reminder.name, show)}
            />
          ) : null;
        })}
        {showAutomatedResponse && (
        <AppointmentCampaignAutomatedResponse appointmentInputs={appointmentInputs} handleChange={handleChange} errors={errors} />
        )}
      </div>
      {disabledReminders.length > 0 && (
      <div className="u-flex-row u-flex-align-items-center u-flex u-text-uppercase u-m-t-large">
        <Button
          onClick={handleAddReminder}
          iconOnly
          type="secondary"
          title="Create Group"
          className="u-border-radius u-m-r"
          data-feature-tag="appointmentCampaignAdd"
        >
          <Icon icon="add" />
        </Button>
        Create Another Reminder Message
      </div>
      )}
    </div>
  );
};

AppointmentCampaignFormReminders.propTypes = {
  appointmentInputs: PropTypes.object.isRequired,
  appointmentEhrStatuses: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  variableOptions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

export default AppointmentCampaignFormReminders;
