import React, { useState } from 'react';
import { DropdownMultiSelectAdvanced } from 'rhinostyle';
import PropTypes from 'prop-types';

import { cloneDeep } from '../helpers/DataHelpers';

const AppointmentManagerMultiSelect = (props) => {
  const {
    className,
    disabled,
    itemIds,
    items,
    label,
    name,
    selectedIds,
    titleField = 'name',
    title,
    updateFilters,
  } = props;
  const shapedItems = shapeListItems(items, itemIds);
  const [filteredIds, setFilteredIds] = useState(itemIds || []);
  function shapeListItems() {
    const itemsCopy = cloneDeep(items);
    itemIds.forEach((itemId) => {
      itemsCopy[itemId] = {
        ...itemsCopy[itemId],
        title: itemsCopy[itemId]?.[titleField],
      };
    });
    return itemsCopy;
  }

  const filterItems = (searchText) => {
    if (searchText?.length > 2) {
      setFilteredIds(itemIds.filter((id) => shapedItems[id]?.title.toLowerCase().includes(searchText.toLowerCase())));
    } else {
      setFilteredIds(itemIds);
    }
  };

  const handleUpdateSelectedIds = (ids) => {
    const validIds = ids.filter((id) => items[id]);
    updateFilters({ [name]: validIds });
  };

  const handleClearSelectedIds = () => {
    updateFilters({ [name]: [] });
  };

  const getLabel = () => {
    if (selectedIds.length > 0) {
      const pluralVersion = label.slice(-1) === 's' ? 'es' : 's';
      return `${selectedIds.length} ${label}${selectedIds.length > 1 ? pluralVersion : ''} Selected`;
    }
    return title;
  };

  return (
    <div className={className || ''}>
      <DropdownMultiSelectAdvanced
        itemSearchLoading={false}
        handleUpdateSelectedIds={handleUpdateSelectedIds}
        items={shapedItems}
        selectedItems={shapedItems}
        selectedItemsIds={selectedIds}
        handleClearAllSelectedItems={handleClearSelectedIds}
        itemsIds={filteredIds}
        fetchAllItems={filterItems}
        type="default"
        dropdownLabel={getLabel()}
        dropDownClass="filter-page__dropdown"
        handleSelectAllItems={handleUpdateSelectedIds}
        showSelectAll
        interfaceLeft
        dropdownItemClass="interface-left"
        filterName={title}
        disabled={disabled}
        dataCypress={className}
      />
    </div>
  );
};

AppointmentManagerMultiSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  itemIds: PropTypes.array,
  items: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  selectedIds: PropTypes.array,
  titleField: PropTypes.string,
  title: PropTypes.string,
  updateFilters: PropTypes.func,
};

export default AppointmentManagerMultiSelect;
