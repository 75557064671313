import React from 'react';
import { Icon } from 'rhinostyle';
import { useSelector } from 'react-redux';
import { getLoggedInUserOrganization } from '../../selectors/userSelectors';

const StoryMentionEvent = ({ event }) => {
  const {
    formattedTimestamp,
    formattedActiveUserName,
    attachments,
  } = event;
  const organization = useSelector(getLoggedInUserOrganization);
  const mentionEvent = attachments[0];
  return (
    <>
      <div
        className="convo__event__title"
        data-feature-tag="story_mention_event"
      >
        <Icon bump="up" icon="checkmark" className="convo__event__icon convo__event__icon--complete" />
        {organization.name} mentioned in <span className="u-font-weight-bold">Instagram Story</span> by {formattedActiveUserName}
      </div>
      <div className="convo__event__subtext">
        {formattedTimestamp}
        <a
          target="_blank"
          className="u-p-l-small u-text-underline"
          href={mentionEvent.url}
          rel="noreferrer"
        >
          Story Link
        </a>
      </div>
    </>
  );
};

export default StoryMentionEvent;
