export const businessHoursArray = [
  { id: 1, value: '12:00am', expectedValue: '00:00:00' },
  { id: 2, value: '12:15am', expectedValue: '00:15:00' },
  { id: 3, value: '12:30am', expectedValue: '00:30:00' },
  { id: 4, value: '12:45am', expectedValue: '00:45:00' },
  { id: 6, value: '1:00am', expectedValue: '01:00:00' },
  { id: 5, value: '1:15am', expectedValue: '01:15:00' },
  { id: 7, value: '1:30am', expectedValue: '01:30:00' },
  { id: 8, value: '1:45am', expectedValue: '01:45:00' },
  { id: 9, value: '2:00am', expectedValue: '02:00:00' },
  { id: 10, value: '2:15am', expectedValue: '02:15:00' },
  { id: 11, value: '2:30am', expectedValue: '02:30:00' },
  { id: 12, value: '2:45am', expectedValue: '02:45:00' },
  { id: 13, value: '3:00am', expectedValue: '03:00:00' },
  { id: 14, value: '3:15am', expectedValue: '03:15:00' },
  { id: 15, value: '3:30am', expectedValue: '03:30:00' },
  { id: 16, value: '3:45am', expectedValue: '03:45:00' },
  { id: 17, value: '4:00am', expectedValue: '04:00:00' },
  { id: 18, value: '4:15am', expectedValue: '04:15:00' },
  { id: 19, value: '4:30am', expectedValue: '04:30:00' },
  { id: 20, value: '4:45am', expectedValue: '04:45:00' },
  { id: 21, value: '5:00am', expectedValue: '05:00:00' },
  { id: 22, value: '5:15am', expectedValue: '05:15:00' },
  { id: 23, value: '5:30am', expectedValue: '05:30:00' },
  { id: 24, value: '5:45am', expectedValue: '05:45:00' },
  { id: 25, value: '6:00am', expectedValue: '06:00:00' },
  { id: 26, value: '6:15am', expectedValue: '06:15:00' },
  { id: 27, value: '6:30am', expectedValue: '06:30:00' },
  { id: 28, value: '6:45am', expectedValue: '06:45:00' },
  { id: 29, value: '7:00am', expectedValue: '07:00:00' },
  { id: 30, value: '7:15am', expectedValue: '07:15:00' },
  { id: 31, value: '7:30am', expectedValue: '07:30:00' },
  { id: 32, value: '7:45am', expectedValue: '07:45:00' },
  { id: 33, value: '8:00am', expectedValue: '08:00:00' },
  { id: 34, value: '8:15am', expectedValue: '08:15:00' },
  { id: 35, value: '8:30am', expectedValue: '08:30:00' },
  { id: 36, value: '8:45am', expectedValue: '08:45:00' },
  { id: 37, value: '9:00am', expectedValue: '09:00:00' },
  { id: 38, value: '9:15am', expectedValue: '09:15:00' },
  { id: 39, value: '9:30am', expectedValue: '09:30:00' },
  { id: 40, value: '9:45am', expectedValue: '09:45:00' },
  { id: 41, value: '10:00am', expectedValue: '10:00:00' },
  { id: 42, value: '10:15am', expectedValue: '10:15:00' },
  { id: 43, value: '10:30am', expectedValue: '10:30:00' },
  { id: 44, value: '10:45am', expectedValue: '10:45:00' },
  { id: 45, value: '11:00am', expectedValue: '11:00:00' },
  { id: 46, value: '11:15am', expectedValue: '11:15:00' },
  { id: 47, value: '11:30am', expectedValue: '11:30:00' },
  { id: 48, value: '11:45am', expectedValue: '11:45:00' },
  { id: 49, value: '12:00pm', expectedValue: '12:00:00' },
  { id: 50, value: '12:15pm', expectedValue: '12:15:00' },
  { id: 51, value: '12:30pm', expectedValue: '12:30:00' },
  { id: 52, value: '12:45pm', expectedValue: '12:45:00' },
  { id: 53, value: '1:00pm', expectedValue: '13:00:00' },
  { id: 54, value: '1:15pm', expectedValue: '13:15:00' },
  { id: 55, value: '1:30pm', expectedValue: '13:30:00' },
  { id: 56, value: '1:45pm', expectedValue: '13:45:00' },
  { id: 57, value: '2:00pm', expectedValue: '14:00:00' },
  { id: 58, value: '2:15pm', expectedValue: '14:15:00' },
  { id: 59, value: '2:30pm', expectedValue: '14:30:00' },
  { id: 60, value: '2:45pm', expectedValue: '14:45:00' },
  { id: 61, value: '3:00pm', expectedValue: '15:00:00' },
  { id: 62, value: '3:15pm', expectedValue: '15:15:00' },
  { id: 63, value: '3:30pm', expectedValue: '15:30:00' },
  { id: 64, value: '3:45pm', expectedValue: '15:45:00' },
  { id: 65, value: '4:00pm', expectedValue: '16:00:00' },
  { id: 66, value: '4:15pm', expectedValue: '16:15:00' },
  { id: 67, value: '4:30pm', expectedValue: '16:30:00' },
  { id: 68, value: '4:45pm', expectedValue: '16:45:00' },
  { id: 69, value: '5:00pm', expectedValue: '17:00:00' },
  { id: 70, value: '5:15pm', expectedValue: '17:15:00' },
  { id: 71, value: '5:30pm', expectedValue: '17:30:00' },
  { id: 72, value: '5:45pm', expectedValue: '17:45:00' },
  { id: 73, value: '6:00pm', expectedValue: '18:00:00' },
  { id: 74, value: '6:15pm', expectedValue: '18:15:00' },
  { id: 75, value: '6:30pm', expectedValue: '18:30:00' },
  { id: 76, value: '6:45pm', expectedValue: '18:45:00' },
  { id: 77, value: '7:00pm', expectedValue: '19:00:00' },
  { id: 78, value: '7:15pm', expectedValue: '19:15:00' },
  { id: 79, value: '7:30pm', expectedValue: '19:30:00' },
  { id: 80, value: '7:45pm', expectedValue: '19:45:00' },
  { id: 81, value: '8:00pm', expectedValue: '20:00:00' },
  { id: 82, value: '8:15pm', expectedValue: '20:15:00' },
  { id: 83, value: '8:30pm', expectedValue: '20:30:00' },
  { id: 84, value: '8:45pm', expectedValue: '20:45:00' },
  { id: 85, value: '9:00pm', expectedValue: '21:00:00' },
  { id: 86, value: '9:15pm', expectedValue: '21:15:00' },
  { id: 87, value: '9:30pm', expectedValue: '21:30:00' },
  { id: 88, value: '9:45pm', expectedValue: '21:45:00' },
  { id: 89, value: '10:00pm', expectedValue: '22:00:00' },
  { id: 90, value: '10:15pm', expectedValue: '22:15:00' },
  { id: 91, value: '10:30pm', expectedValue: '22:30:00' },
  { id: 92, value: '10:45pm', expectedValue: '22:45:00' },
  { id: 93, value: '11:00pm', expectedValue: '23:00:00' },
  { id: 94, value: '11:15pm', expectedValue: '23:15:00' },
  { id: 95, value: '11:30pm', expectedValue: '23:30:00' },
  { id: 96, value: '11:45pm', expectedValue: '23:45:00' },
];

export const daysDict = {
  0: {
    title: 'Sunday',
    day: 0,
  },
  1: {
    title: 'Monday',
    day: 1,
  },
  2: {
    title: 'Tuesday',
    day: 2,
  },
  3: {
    title: 'Wednesday',
    day: 3,
  },
  4: {
    title: 'Thursday',
    day: 4,
  },
  5: {
    title: 'Friday',
    day: 5,
  },
  6: {
    title: 'Saturday',
    day: 6,
  },
};

export function fetchSelection(args) {
  return businessHoursArray.find((item) => item.expectedValue === args);
}
