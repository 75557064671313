import Bowser from 'bowser';

/**
 * Check if the user is on Android/iOS
 * @return {boolean}
 */

function getOsName() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getOSName().toLowerCase();
}

export function isMobile() {
  return (isAndroid() || isIos());
}

export function isAndroid() {
  return getOsName() === 'android';
}

export function isIos() {
  // navigator.standalone is only on ios https://developer.mozilla.org/en-US/docs/Web/API/Navigator use this if user agent fails
  return (getOsName() === 'ios')
    || (navigator.platform === 'MacIntel' && typeof navigator.standalone !== 'undefined');
}

// Handles tablets running desktop site
export function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
}

export function isInternetExplorer() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  return msie > 0 || !!navigator.userAgent.match('Trident/');
}

export function isChromeBrowser() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserName = browser.getBrowserName().toLowerCase();
  return ['chrome', 'electron', 'chromium'].includes(browserName);
}

export function isWebRTCSupported() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserName = browser.getBrowserName().toLowerCase();
  const browserEngine = browser.getEngineName().toLowerCase();
  const isChrome = ['chrome', 'electron', 'chromium'].includes(browserName);
  const isEdgeWithBlink = browserName === 'microsoft edge' && browserEngine === 'blink';
  const isFirefox = browserName === 'firefox';
  const isSamsung = browserName === 'samsung internet for android';
  const isIOS = isIos();
  const isAndroidOS = isAndroid();
  const isDuckDuckGo = window.navigator.userAgent.toLowerCase().indexOf('duckduckgo') > 0;

  const isValidBrowser =
    isSafari() ||
    isEdgeWithBlink ||
    isChrome ||
    (!isIOS && !isAndroidOS && isFirefox) ||
    (isAndroidOS && isSamsung);

  return isValidBrowser && !isDuckDuckGo;
}

export function getIsWebkitAudioWorkaroundNeeded() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  if (isSafari()) {
    const minmumSafariVersion = '>=15.0';
    const maximumSafariVersion = '<15.4';
    const isWebkitAudioWorkaroundNeeded = browser.satisfies({
      mobile: {
        safari: minmumSafariVersion,
      },
    }) && browser.satisfies({
      mobile: {
        safari: maximumSafariVersion,
      },
    });
    return isWebkitAudioWorkaroundNeeded;
  }
  return false;
}

export function isSafari() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserName = browser.getBrowserName().toLowerCase();
  return browserName === 'safari';
}

export function isMobileSafari() {
  return isIos() && isSafari();
}

export function isVP8Supported() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  if (isSafari()) {
    const minimumSupportedSafariVersion = '<12.1';
    const isSafariWithoutVP8Support = browser.satisfies({
      macos: {
        safari: minimumSupportedSafariVersion,
      },
      mobile: {
        safari: minimumSupportedSafariVersion,
      },
    });
    return !isSafariWithoutVP8Support;
  } return true;
}

export function openPopupWindow(url, windowName, win, w, h) {
  const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
  const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);

  return win.open(url, windowName, `width=${w},height=${h},top=${y},left=${x}`);
}
