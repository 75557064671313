import { createSelector } from '@reduxjs/toolkit';

const getUserIdsTyping = (state) => state.ui.usersTyping;
const getInboxSections = (state) => state.ui.inboxSections;
const getInboxUserId = (state) => state.inbox.userId;
const getChatUserId = (state) => state.chat.userId;
const getChatGroupId = (state) => state.chat.groupId;
const getCurrentUser = (state) => state.auth.currentUser;
export const getIsNewFeatureTourVisible = (state) => state.ui.isNewFeatureTourVisible;
const getIsOnInboxThread = (state, isOnInboxThread) => isOnInboxThread;
const getPathname = (state, props) => props.pathname;
const getEventId = (state, props) => props.eventId;

export const getUsersTypingInbox = createSelector(
  [getUserIdsTyping, getInboxUserId],
  (usersTyping, inboxUserId) => {
    if (usersTyping && usersTyping.length && inboxUserId) {
      return usersTyping.filter((u) => u.subscribingToUserId === inboxUserId);
    }

    return null;
  },
);

export const getUsersTypingChat = createSelector(
  [getUserIdsTyping, getChatUserId, getChatGroupId, getCurrentUser],
  (usersTyping, chatUserId, chatGroupId) => {
    if (usersTyping && usersTyping.length) {
      if (chatUserId) {
        return usersTyping.filter((u) => u.subscribingToUserId === chatUserId);
      } else if (chatGroupId) {
        return usersTyping.filter((u) => u.subscribingToGroupId === chatGroupId);
      }
    }

    return null;
  },
);

export const getUsersTyping = createSelector(
  [getUsersTypingInbox, getUsersTypingChat, getPathname],
  (usersTypingInbox, usersTypingChat, pathname) => (pathname.includes('inbox') ? usersTypingInbox : usersTypingChat),
);

export const getInboxSection = createSelector(
  [getInboxSections],
  (inboxSections) => (
    inboxSections.inbox && inboxSections.inbox.length ? inboxSections.inbox.filter((i) => i.type === 'group' || i.type === 'direct') : []
  ),
);

export const getChatSection = createSelector(
  [getInboxSections],
  (inboxSections) => (
    inboxSections.chat && inboxSections.chat.length ? inboxSections.chat.filter((i) => i.type === 'group' || i.type === 'direct') : []
  ),
);

export const getFollowingSection = createSelector(
  [getInboxSections],
  (inboxSections) => (
    inboxSections.inbox && inboxSections.inbox.length && inboxSections.inbox.find((i) => i.type === 'following') ?
      inboxSections.inbox.find((i) => i.type === 'following') : {}
  ),
);

export const getAssignedSection = createSelector(
  [getInboxSections],
  (inboxSections) => (
    inboxSections.inbox && inboxSections.inbox.length && inboxSections.inbox.find((i) => i.type === 'assigned') ? inboxSections.inbox.find((i) => i.type === 'assigned') : {}
  ),
);

export const getMentionsSection = createSelector(
  [getInboxSections],
  (inboxSections) => (
    inboxSections.inbox?.length && inboxSections.inbox.find((i) => i.type === 'mentions') ?
      inboxSections.inbox.find((i) => i.type === 'mentions') : {}
  ),
);

export const getUnreadChatMentionIds = createSelector(
  [getInboxSections],
  (inboxSections) => (
    inboxSections.inbox?.find((i) => i.type === 'mentions')?.unreadChatMentionIds || []
  ),
);

export const getUnreadInboxMentions = createSelector(
  [getInboxSections],
  (inboxSections) => (
    inboxSections.inbox?.find((i) => i.type === 'mentions')?.unreadInboxMentions || []
  ),
);
export const getUnreadInboxMention = createSelector(
  [getUnreadInboxMentions, getEventId],
  (unreadInboxMentions, eventId) => (
    unreadInboxMentions?.find(((mention) => mention.eventId === eventId))
  ),
);

export const getUnreadMentions = createSelector(
  [getUnreadChatMentionIds, getUnreadInboxMentions, getIsOnInboxThread],
  (chatmentionIds, inboxMentionIds, isOnInboxThread) => {
    if (isOnInboxThread) {
      return inboxMentionIds;
    }
    return chatmentionIds;
  },
);
