import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import {
  UIHelpers,
} from '../helpers';
import { hasContactThreadViewPermissions } from '../helpers/UserHelpers';
import ContactProfilePanel from './ContactProfilePanel';
import ThreadSearchPanel from './ThreadSearchPanel';
import ThreadFilterPanel from './ThreadFilterPanel';
import ContactAssignPanel from './ContactAssignPanel';
import ContactLibraryPanel from './ContactLibraryPanel';
import SavedContentPanel from './SavedContentPanel';
import * as ThreadReducer from '../reducers/threadReducer';
import { getActiveUser } from '../selectors/userSelectors';
import { PANEL_OPTIONS } from '../constants/ThreadConstants';
import MemberProfilePanelContainer from '../containers/MemberProfilePanelContainer';
import GroupPanel from './GroupPanel';
import { getActiveChatGroup } from '../selectors/chatSelectors';
import { useActiveUser } from '../hooks';

const ThreadPanelWrapper = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const activeUser = useActiveUser();
  const isInboxThread = location.pathname.includes('inbox');

  const {
    activePanel,
    activeGroup,
  } = props;

  const handlePanelClick = (panel) => {
    if (isInboxThread) {
      if (!location.pathname.includes(panel) && [PANEL_OPTIONS.library, PANEL_OPTIONS.savedContent].includes(panel)) {
        history.push({ pathname: location.pathname, search: `?activePanel=${panel}` });
      } else if (UIHelpers.panelBreakpointMax()) {
        dispatch(ThreadReducer.toggleProfile());
      }
      dispatch(ThreadReducer.setActivePanel(panel));
    } else {
      dispatch(ThreadReducer.toggleProfile());
    }
  };

  const renderInboxPanels = () => (
    <>
      <ContactProfilePanel
        setOpenContactLibraryPanel={handlePanelClick}
        isActivePanel={activePanel === PANEL_OPTIONS.profile}
        location={location}
        history={history}
      />
      {activePanel === PANEL_OPTIONS.filter && (<ThreadFilterPanel />)}
      <ThreadSearchPanel isActivePanel={activePanel === PANEL_OPTIONS.search} />
      {(activePanel === PANEL_OPTIONS.assign || activePanel === PANEL_OPTIONS.suggestedRoute) && (
      <ContactAssignPanel
        location={location}
        history={history}
        assignFocus
      />
      )}
      {activePanel === PANEL_OPTIONS.library && hasContactThreadViewPermissions() && (
        <ContactLibraryPanel />
      )}
      {activePanel === PANEL_OPTIONS.savedContent && hasContactThreadViewPermissions() && (
      <SavedContentPanel
        allowDeleteSavedContent
      />
      )}
    </>
  );
  const renderChatPanels = () => {
    if (params.groupId && activeGroup?.users) {
      return (
        <GroupPanel
          view="chat"
        />
      );
    } if (activeUser) {
      return (
        <MemberProfilePanelContainer
          activeUser={activeUser}
          history={history}
          location={location}
          handleTogglePanel={handlePanelClick}
        />
      );
    } return null;
  };

  if (!activeUser && !activeGroup) return null;
  return isInboxThread ? renderInboxPanels() : renderChatPanels();
};

ThreadPanelWrapper.propTypes = {
  activePanel: PropTypes.oneOf(Object.values(PANEL_OPTIONS)),
  activeGroup: PropTypes.object,
};

const mapStateToProps = (state) => ({
  activePanel: state.thread.activePanel,
  activeUser: getActiveUser(state),
  activeGroup: getActiveChatGroup(state),
});

export default connect(mapStateToProps)(ThreadPanelWrapper);
