import React, { Fragment } from 'react';
import { FILE_BASE_URL, SUPPORTED_FILE_TYPES_FOR_PREVIEW } from '../constants/AppConstants';
import { getFileMimeType } from '../helpers/DataHelpers';

export default function LinkedAttachments(props) {
  const { event, breakBeforeFirstAttachment, isNativeAndroid } = props;

  const buildAttachmentWithDownloadLink = (attachment) => {
    const { attachmentUrl } = attachment;
    const downloadURL = `${FILE_BASE_URL}${attachmentUrl}`;
    const fileType = attachment.type || getFileMimeType(downloadURL);
    const filename = attachment.name;
    return { downloadURL, fileType, filename };
  };

  const filterPreviewableAttachments = (attachment) => !SUPPORTED_FILE_TYPES_FOR_PREVIEW.includes(attachment.fileType);

  const nonPreviewableAttachments = event?.attachments
    ?.map(buildAttachmentWithDownloadLink)
    .filter(filterPreviewableAttachments);
  if (!nonPreviewableAttachments?.length) return null;
  return (
    <>
      { nonPreviewableAttachments?.map((attachment, index) => (
        <Fragment key={attachment.downloadURL}>
          {!(!breakBeforeFirstAttachment && index === 0) && (<br />)}
          <a
            href={attachment.downloadURL}
            {...!isNativeAndroid && {
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          >
            {attachment.filename}
          </a>
        </Fragment>
      ))}
    </>
  );
}
