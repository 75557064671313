import store from '../store';
import * as NativeAppReducer from '../reducers/nativeAppReducer';
import * as UserReducer from '../reducers/userReducer';
import * as RhinovideoReducer from '../reducers/rhinovideoReducer';
import { receiveAndroidPhone } from '../reducers/rhinocallReducer';
import { getTotalUnreadCount } from '../helpers/UIHelpers';
import OutboundPostMessageService from './OutboundPostMessageService';
import NotificationService from './NotificationService';

// Handle incoming data from the React Native webview
class PostMessageService {
  initEventListener(history, location) {
    window.addEventListener('message', (event) => {
      this.handleReceiveMessage(event, history, location);
    }, false); // iOS
    document.addEventListener('message', (event) => {
      this.handleReceiveMessage(event, history, location);
    }, false); // Android
  }

  handleReceiveMessage = (event, history, location) => {
    try {
      const json = JSON.parse(event.data);
      const eventType = json.type;

      if (eventType === 'navigateTo') {
        history.push(json.data.path);
      } else if (eventType === 'touchId') {
        store.dispatch(NativeAppReducer.updateTouchId(json.data));
      } else if (eventType === 'requestBadgeCount') {
        // setTimeout is to respond after pending websocket events come in when foregrounding the app
        setTimeout(() => {
          const currentState = store.getState();
          const isLoggedIn = currentState.auth.currentUser;
          const badgeCount = getTotalUnreadCount();

          // Update native app badge count
          if (isLoggedIn) {
            OutboundPostMessageService.postMessage({
              type: 'setBadgeCount',
              data: { badgeCount },
            });
          }
        }, 500);
      } else if (eventType === 'requestCanGoBack') {
        const { canGoBack } = history;

        OutboundPostMessageService.postMessage({
          type: 'canGoBackResult',
          data: { canGoBack, currentPath: location.pathname },
        });
      } else if (eventType === 'goBack') {
        const { canGoBack } = history;

        if (canGoBack) history.goBack();
      } else if (eventType === 'hostHangup') {
        RhinovideoReducer.handleHostHangupNative(json.data.roomName);
      } else if (eventType === 'hostJoined') {
        store.dispatch(RhinovideoReducer.updateParticipantsInfoNative(json.data));
      } else if (eventType === 'handleConferenceTimeout') {
        NotificationService('timeoutVideo');
      } else if (eventType === 'preventSessionTimeout') {
        const state = store.getState();
        UserReducer.getUserById(state.auth.currentUser);
      } else if (eventType === 'androidPhoneNumber') {
        store.dispatch(receiveAndroidPhone(json.data));
      }
    } catch (e) {
      // ignore
    }
  }
}

export default new PostMessageService();
