import { createSelector } from '@reduxjs/toolkit';
import { compareObjectByKey, exists } from '../helpers/DataHelpers';

const getGroups = (state) => state.group.groups;
const getGroupIds = (state) => state.group.groupIds;
const getGroupId = (state, params) => params?.groupId;
const getOtherGroupIds = (state, props) => props?.groupIds;

export const getActiveGroup = createSelector(
  [getGroups, getGroupId],
  (groups, groupId) => {
    if (groupId) {
      return groups[Number(groupId)];
    } return null;
  },
);

export const getMentionableGroup = createSelector(
  [getGroups, getGroupId],
  (groups, mentionGroupId) => {
    if (mentionGroupId) {
      return groups[mentionGroupId];
    }
    return null;
  },
);

export const getNonDynamicGroups = createSelector(
  [getGroups, getGroupIds, getOtherGroupIds],
  (groups, groupIds, otherGroupIds) => {
    const allGroupIds = exists(otherGroupIds) ? otherGroupIds : groupIds;
    return allGroupIds?.filter((groupId) => !groups[groupId]?.isDynamic).sort((a, b) => compareObjectByKey(groups[a], groups[b], 'name'));
  },
);

export const getGroupById = createSelector(
  [getGroups, getGroupId],
  (groups, groupId) => groups[groupId],
);
