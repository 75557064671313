import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  NavTabs,
  NavTabsItem,
  UtilityList,
} from 'rhinostyle';
import { useLocation, useHistory } from 'react-router-dom';
import SavedContentItemDetails from './SavedContentItemDetails';
import FormLibraryItemDetails from './FormLibraryItemDetails';
import { fetchSavedContentItemsByContact } from '../reducers/savedContentReducer';
import { setActivePanel } from '../reducers/threadReducer';
import { getActiveUser } from '../selectors/userSelectors';
import PageLoader from './PageLoader';
import { NAVIGATION_TABS } from '../constants/LibraryConstants';
import { TYPE_USER_LIBRARY_SAVED_CONTENT, TYPE_USER_LIBRARY_FORM } from '../constants/Types';
import { PANEL_OPTIONS } from '../constants/ThreadConstants';

const SAVED_CONTENT_DEFAULT_PAGE_SIZE = 6;

const ContactLibraryPanel = (props) => {
  const {
    activeUserId,
    isRhinoformEnabled,
    savedContentItems,
    activeLibraryTab,
    savedContentItemIds,
    isActivePanel,
    handleCloseSavedContentLibrary,
  } = props;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const initialTab = location?.state?.activeTab === 'forms' || activeLibraryTab === 'forms ' ? NAVIGATION_TABS.forms : NAVIGATION_TABS.savedContent;
  // Component State Hooks
  const [activeTab, setActiveTab] = useState(initialTab);
  const [areAllSavedContentItemsShowing, handleToggleAllSavedContentItems] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const activeLibraryTypeId = activeTab === NAVIGATION_TABS.savedContent ? TYPE_USER_LIBRARY_SAVED_CONTENT : TYPE_USER_LIBRARY_FORM;

  // Redux Hooks
  const activeUserSavedContentItemIds = savedContentItemIds
    .filter((id) => savedContentItems[id]?.contact === activeUserId && savedContentItems[id]?.libraryTypeId === activeLibraryTypeId);

  // Lifecycle Updates
  useEffect(() => {
    if (activeTab === NAVIGATION_TABS.savedContent) {
      fetchLibraryItems('saved-content');
    }
    if (activeTab === NAVIGATION_TABS.forms) {
      fetchLibraryItems('form');
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeUserId && !activeUserSavedContentItemIds.length) {
      setLoading(true);
    }
  }, [activeUserId]);

  useEffect(() => {
    if (activeLibraryTab === 'forms') {
      setActiveTab(NAVIGATION_TABS.forms);
    }
  }, [activeLibraryTab]);

  // Utilities
  async function fetchLibraryItems(type) {
    await dispatch(fetchSavedContentItemsByContact(activeUserId, type));
    setLoading(false);
  }

  function closePanel() {
    if (location.pathname.includes('inbox')) {
      dispatch(setActivePanel(PANEL_OPTIONS.profile));
      history.push({ pathname: location.pathname, state: { isLibraryPanelOpen: false } });
    } else {
      handleCloseSavedContentLibrary();
    }
  }
  if (location.pathname.includes('inbox') && !isActivePanel) return null;

  const renderLibrary = () => (
    <>
      <div className="nav-tabs__wrapper">
        <NavTabs activeKey={activeTab} onSelect={setActiveTab}>
          <NavTabsItem className="contact-library__nav-tab" id={NAVIGATION_TABS.savedContent}>Saved Content</NavTabsItem>
          {isRhinoformEnabled ? (<NavTabsItem className="contact-library__nav-tab" id={NAVIGATION_TABS.forms}>Completed Forms</NavTabsItem>) : (<></>)}
        </NavTabs>
      </div>
      {!isLoading && activeTab === NAVIGATION_TABS.savedContent && (
      <>
        {activeUserSavedContentItemIds.length ? (
          <UtilityList>
            {activeUserSavedContentItemIds
              .sort((a, b) => b - a)
              .slice(0, areAllSavedContentItemsShowing ? undefined : SAVED_CONTENT_DEFAULT_PAGE_SIZE)
              .map((id) => <SavedContentItemDetails savedContentItemId={id} key={id} activeUserId={activeUserId} />)}
          </UtilityList>
        ) : (
          <p className="u-text-muted u-text-center">No saved conversations to display.</p>
        )}
        {!areAllSavedContentItemsShowing && activeUserSavedContentItemIds.length > SAVED_CONTENT_DEFAULT_PAGE_SIZE && (
        <Button type="link" size="small" onClick={() => handleToggleAllSavedContentItems(!areAllSavedContentItemsShowing)}>Show all</Button>
        )}
      </>
      )}

      {!isLoading && activeTab === NAVIGATION_TABS.forms && (
      <>
        {activeUserSavedContentItemIds.length ? (
          <UtilityList>
            {activeUserSavedContentItemIds
              .sort((a, b) => b - a)
              .slice(0, areAllSavedContentItemsShowing ? undefined : SAVED_CONTENT_DEFAULT_PAGE_SIZE)
              .map((savedContentItemId) => (
                <FormLibraryItemDetails
                  savedContentItemId={savedContentItemId}
                  key={savedContentItemId}
                  activeUserId={activeUserId}
                />
              ))}
          </UtilityList>
        ) : (
          <p className="u-text-muted u-text-center">No completed forms to display.</p>
        )}
        {!areAllSavedContentItemsShowing && activeUserSavedContentItemIds.length > SAVED_CONTENT_DEFAULT_PAGE_SIZE && (
        <Button type="link" size="small" onClick={() => handleToggleAllSavedContentItems(!areAllSavedContentItemsShowing)}>Show all</Button>
        )}
      </>
      )}
    </>
  );

  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel saved-content">
        <div className="app-page__header">
          <div className="app-page__header__title">Library</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={closePanel}
            />
          </div>
        </div>
        <div className="summary-panel__body">
          {isLoading && <PageLoader />}
          <div className="summary-panel__content">
            {renderLibrary()}
          </div>
        </div>
      </div>
    </div>
  );
};

ContactLibraryPanel.propTypes = {
  activeUserId: PropTypes.number,
  isRhinoformEnabled: PropTypes.bool,
  savedContentItems: PropTypes.object,
  activeLibraryTab: PropTypes.string,
  savedContentItemIds: PropTypes.array,
  isActivePanel: PropTypes.bool,
  handleCloseSavedContentLibrary: PropTypes.func,
};

const mapStateToProps = (state) => ({
  activeUserId: getActiveUser(state).id,
  isRhinoformEnabled: state.form?.org?.isRhinoformEnabled,
  savedContentItems: state.savedContent.savedContentItems,
  activeLibraryTab: state.thread.activeLibraryTab,
  savedContentItemIds: state.savedContent.savedContentItemIds,
  isActivePanel: state.thread.activePanel === PANEL_OPTIONS.library,
});

export default connect(mapStateToProps)(ContactLibraryPanel);
