import PropTypes from 'prop-types';
import React from 'react';
import rhinoLogoReversed from '../assets/images/rhinogram-logo-reversed.svg';

const LoginWrapper = (props) => (
  <>
    <div className="app-login-wrapper">
      <div className="app-login" data-vh="100">
        <div className="app-legacy-login__container">
          <div className="app-login__logo">
            <img src={rhinoLogoReversed} alt="Rhinogram" />
          </div>
          {props.children}
        </div>
      </div>
    </div>
  </>
);

LoginWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginWrapper;
