import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'rhinostyle';
import moment from 'moment-timezone';
import { SESSION_TIMEOUT_ALERT_BUFFER, SESSION_TIMEOUT_STORAGE_KEY } from '../constants/AppConstants';
import { hideSessionTimeoutModal } from '../reducers/uiReducer';
import { logout } from '../reducers/authReducer';
import { fetchUser } from '../reducers/userReducer';
import { formatSecondsRemaining } from '../helpers/DateHelpers';

const SessionTimeoutModal = (props) => {
  let hidden;
  let visibilityChange;
  const {
    currentUserId,
    isModalVisible,
    history,
    logoutInProgress,
  } = props;
  const dispatch = useDispatch();
  const [sessionSecondsRemaining, setSessionSecondsRemaining] = useState(SESSION_TIMEOUT_ALERT_BUFFER);
  const interval = useRef(null);
  const sessionTimeout = useRef(null);

  useEffect(() => {
    if (isModalVisible) {
      sessionTimeout.current = moment().add(SESSION_TIMEOUT_ALERT_BUFFER, 'seconds');
      setSessionSecondsRemaining(SESSION_TIMEOUT_ALERT_BUFFER);
      initiateListeners();
      interval.current = setInterval(() => {
        setSessionSecondsRemaining((current) => current - 1);
      }, 1000);
    }
    return () => {
      cleanup();
    };
  }, [isModalVisible]);

  useEffect(() => {
    if (sessionSecondsRemaining === 0) {
      handleLogout();
      clearInterval(interval.current);
    }
  }, [sessionSecondsRemaining]);

  const handleExtendSessionClick = () => {
    dispatch(fetchUser(currentUserId, true)); // Any API call will extend session
  };

  // Prevents logout when modal is counting down in multiple tabs
  function handleStorageUpdate(event) {
    if (event.key === SESSION_TIMEOUT_STORAGE_KEY && !event.newValue) {
      handleExtendSessionClick();
    }
  }

  // Fallback when session expires when tab is in background
  function handleVisibilityChange() {
    if (!document[hidden]) {
      if (moment() > sessionTimeout.current) {
        handleLogout();
      }
    }
  }

  function initiateListeners() {
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
    window.addEventListener('storage', handleStorageUpdate, false);
  }

  function cleanup() {
    document.removeEventListener(visibilityChange, handleVisibilityChange, false);
    window.removeEventListener('storage', handleStorageUpdate, false);
    clearInterval(interval.current);
  }

  function handleLogout() {
    dispatch(logout('expired'))
      .then((response) => {
        dispatch(hideSessionTimeoutModal());
        if (response === 'legacy') {
          history.push('/login?legacy=true');
        }
      });
  }

  return (
    <Modal onReverseComplete={cleanup} open={isModalVisible}>
      <ModalHeader
        dismissable={false}
        title="Are you still there?"
        titleSub="You will be logged out due to inactivity. To remain logged in, click the button below."
      />
      <ModalBody>
        <div className="u-text-center">
          <div className="u-font-weight-bold u-text-large">{formatSecondsRemaining(sessionSecondsRemaining)}</div>
          <div>Time remaining</div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="u-text-center">
          <Button loading={logoutInProgress} onClick={handleExtendSessionClick} type="primary">Stay Logged In</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { ui, auth } = state;
  return {
    isModalVisible: ui.isSessionTimeoutModalVisible,
    currentUserId: auth.currentUser,
    logoutInProgress: auth.logoutInProgress,
  };
};

SessionTimeoutModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number,
  history: PropTypes.object,
  logoutInProgress: PropTypes.bool,
};

export default connect(mapStateToProps)(SessionTimeoutModal);
