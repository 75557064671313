import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { DateHelpers, DataHelpers, UserHelpers } from '../helpers';
import { getActiveMember } from './userSelectors';

const getEventIds = (state) => state.chat.eventIds;
const getInboxEventIds = (state) => state.chat.inboxEventIds;
const getEvents = (state) => state.chat.events;
const getTypes = (state) => state.type.types;
const getGroups = (state) => state.group.groups;
const getUserId = (state) => state.user.userId;
const getGroupId = (state) => state.chat.groupId;
const getCurrentUser = (state) => state.auth.currentUser;
const getEventId = (state, eventId) => eventId;
const getEventMentions = (state) => state.chat.eventMentions;
const getMentionsFromEvent = (state, props) => props.eventMentions;
const getIsInbox = (state, props) => props.location.pathname.includes('inbox');
const getActiveGroupId = (state, props) => props.match.params?.groupId;
const getActiveUserId = (state, props) => props.match.params?.userId;
const getUsers = (state) => state.user.users;

export const getFilteredChatEventIds = createSelector(
  [getInboxEventIds, getEvents, getTypes],
  (inboxEventIds, events, types) => (
    DataHelpers.hasData(types) && DataHelpers.hasData(events) && inboxEventIds?.length > 0 ? inboxEventIds.filter((id) => types[events[id].typeId]?.value === 'direct message') : []
  ),
);

export const getFilteredChatThreadEventIds = createSelector(
  [getEventIds, getEvents, getTypes, getUserId, getGroupId],
  (eventIds, events, types, userId, groupId) => (
    DataHelpers.hasData(types) && DataHelpers.hasData(events) ?
      eventIds.filter((id) => (
        types[events[id]?.typeId]?.value === 'direct message' && ((userId && userId === events[id].user) || (groupId && groupId === events[id].group))
      )) :
      []
  ),
);

export const getChatEventsToday = createSelector(
  [getFilteredChatEventIds, getEvents],
  (chatEventIds, events) => chatEventIds.filter((id) => moment().startOf('day').isSame(DateHelpers.convertUtcStringToLocalMoment(events[id].timestamp).startOf('day'))),
);

export const getChatEventsYesterday = createSelector(
  [getFilteredChatEventIds, getEvents],
  (chatEventIds, events) => (
    chatEventIds.filter((id) => (
      moment().subtract(1, 'day').startOf('day').isSame(DateHelpers.convertUtcStringToLocalMoment(events[id].timestamp).startOf('day'))
    ))
  ),
);

export const getChatEventsOlder = createSelector(
  [getFilteredChatEventIds, getEvents],
  (chatEventIds, events) => (
    chatEventIds.filter((id) => (
      moment().subtract(1, 'day').startOf('day').isAfter(DateHelpers.convertUtcStringToLocalMoment(events[id].timestamp).startOf('day'))
    ))
  ),
);

export const getCurrentUserMentionId = createSelector(
  [getEventMentions, getMentionsFromEvent, getCurrentUser],
  (mentions, mentionIds, currentUserId) => mentionIds?.find((mentionId) => mentions[mentionId]?.userId === currentUserId),
);

export const getGroupUserDataLoaded = createSelector(
  [getGroups, getActiveGroupId, getUsers, getActiveUserId, getIsInbox],
  (groups, groupId, users, userId, isInbox) => {
    if (users && userId) {
      return !!(users[userId]);
    } else if (!isInbox && groups && groupId) {
      return !!(groups[groupId]?.users);
    }
    return null;
  },
);

export const getActiveChatGroup = createSelector(
  [getGroups, getGroupId],
  (groups, groupId) => {
    if (groups && groupId) {
      return groups[groupId];
    }
    return null;
  },
);

export const getActiveChatGroupUsers = createSelector(
  [getActiveChatGroup, getUsers],
  (activeGroup, users) => {
    if (activeGroup?.users && users) {
      return activeGroup?.users.map((userId) => users[userId]);
    } return null;
  },
);

export const getActiveChatThreadId = createSelector(
  [getEvents, getEventIds],
  (events, eventIds) => ((events && eventIds) ? events[eventIds[0]]?.chatThreadId : null),
);

export const getChatEventById = createSelector(
  [getEvents, getEventId],
  (events, eventId) => events[eventId] || {},
);

export const getChatGroupUserIds = createSelector(
  [getActiveChatGroup, getUsers],
  (activeGroup, users) => {
    const chatGroupUsers = activeGroup?.users || [];
    return [...chatGroupUsers].sort(DataHelpers.sortComparatorUsingUserLastNameFirstName(users));
  },
);

export const getChatName = createSelector(
  [getActiveChatGroup, getUsers, getCurrentUser, getActiveMember],
  (activeGroup, users, currentUser, activeMember) => {
    if (!activeGroup && activeMember) return UserHelpers.formatName(activeMember, true);
    if (!activeGroup?.isDynamic && activeGroup?.name) return activeGroup.name;
    if (activeGroup?.users && users) {
      const activeChatGroupUsers = activeGroup.users.map((userId) => users[userId]);
      return UserHelpers.formatNamesForDynamicChat(activeChatGroupUsers.filter((groupUser) => groupUser.id !== currentUser));
    }
    return '';
  },
);

export const getChatMentionableUserIds = createSelector(
  [getChatGroupUserIds, getUserId, getCurrentUser],
  (chatGroupUserIds, activeUserId, currentUserId) => {
    if (!chatGroupUserIds.length && activeUserId) return [activeUserId, currentUserId];
    return chatGroupUserIds || [];
  },
);
