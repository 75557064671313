import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import RhinopayRequestModal from '../components/RhinopayRequestModal';
import { organizationSelectors, userSelectors } from '../selectors';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { createPaymentRequest } from '../reducers/payReducer';
import * as PayHelpers from '../helpers/PayHelpers';
import { removePeriodsFromString } from '../helpers/StringHelpers';
import { isAndroid, isChromeBrowser } from '../helpers/BrowserHelpers';
import * as InboxReducer from '../reducers/inboxReducer';
import * as ThreadReducer from '../reducers/threadReducer';
import { MODAL_OPTIONS } from '../constants/ThreadConstants';

class RhinopayRequestModalContainer extends React.Component {
  state = this.initialState;

  get initialState() {
    return {
      paymentRequestAmount: '',
      paymentRequestNote: '',
      isFormInProgress: false,
      errors: {},
    };
  }

  handleSubmitRequest = () => {
    const errors = ValidationService(ValidationShapers.shapePaymentRequest(this.state));
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      this.setState({ errors });
    } else {
      this.setState({ isFormInProgress: true });
      this.props.createPaymentRequest(this.paymentRequestPayload)
        .then(() => {
          const paymentRequest = this.props.paymentRequests[this.props.activeUser.id];
          const paymentRequestMessageText = PayHelpers.generatePaymentRequestMessageText(paymentRequest, this.props.activeOrg);
          this.props.handleSendMessage({ messageText: paymentRequestMessageText, threadOptions: this.props.threadOptions });
          this.setState(this.initialState, () => {
            this.props.handleToggleModal('isRhinopayModalOpen');
          });
        })
        .catch(() => {
          this.setState(this.initialState, () => this.props.handleToggleModal());
        });
    }
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  handleRequestAmountChange = (event) => {
    const DELETE_KEY_CODE = 8;
    const eventKey = event.which || event.keyCode;
    const eventTargetName = event.target.name; // Necessary because React Synthetic events will nullify the event target, so it can't be used in async setState.
    if (isAndroid() && isChromeBrowser()) {
      if (event.type === 'input') {
        const requestValue = event.target.value;
        const paymentRequestAmount = shapeNumberToCurrency(removePeriodsFromString(requestValue));
        this.setState({ paymentRequestAmount });
      } // Handle DELETE Key presses.
    } else if (event.type === 'keydown' && eventKey === DELETE_KEY_CODE && this.state.paymentRequestAmount.length > 0) {
      /**
       * To get the new value trim the existing value and remove the decimal point from our existing value (which is type string)
       * This then goes through the shaper method to return an 'X.XX' format string once again.
       * */
      const trimmedValue = this.state.paymentRequestAmount.slice(0, this.state.paymentRequestAmount.length - 1); // Remove last character in string value.
      const newPaymentRequestValue = shapeNumberToCurrency(removePeriodsFromString(trimmedValue));
      this.setState({ [eventTargetName]: newPaymentRequestValue });
      event.preventDefault(); // Prevent double deletes on input. Ensure control via setState only.
    } else if (event.type === 'keyup' && eventKey !== DELETE_KEY_CODE && getKeyValue(eventKey)) {
      /**
       * To get the new value we remove the decimal point from our existing value (which is type string), and tach on the newest input key.
       * This then goes through the shaper method to return an 'X.XX' format string once again.
       * */
      const newPaymentRequestValue = shapeNumberToCurrency(removePeriodsFromString(this.state.paymentRequestAmount) + getKeyValue(eventKey));
      this.setState({ [eventTargetName]: newPaymentRequestValue });
    }

    function getKeyValue(keyCode) {
      const NUMBER_0_KEY_CODE = 48;
      const NUMBER_9_KEY_CODE = 57;
      const NUMPAD_KEY_TRANSPOSE_VALUE = 48;

      /**
       * Check for NUMPAD keys -- Numpad keyCode values are exactly '48' codes above standard number keys.
       * This line transposes the input param to see if it matches a number keyCode.
       * */
      if (keyCode > NUMBER_9_KEY_CODE) {
        keyCode -= NUMPAD_KEY_TRANSPOSE_VALUE; // eslint-disable-line no-param-reassign
      }

      if (keyCode >= NUMBER_0_KEY_CODE && keyCode <= NUMBER_9_KEY_CODE) {
        return String.fromCharCode(keyCode);
      } else {
        return null;
      }
    }

    function shapeNumberToCurrency(inputValue) {
      if (Number.isNaN(parseFloat(inputValue))) {
        return '0.00'; // If the input is not valid number just set the value to 0.00.
      }

      // @TODO -- This floating point operation could cause precision issues leading to number combinations that cannot be typed.
      // Needs to be fixed ASAP. Should be re-thought to be fully string manipulation.
      return (parseFloat(inputValue) / 100).toFixed(2); // Move the decimal up two places and return a 'X.XX' format.
    }
  }

  handleFocus = (event) => {
    // Initialize field with a zero value in 'X.XX' format. Only used for 'paymentRequestAmount' input.
    if (Number.isNaN(parseFloat(event.target.value))) {
      this.setState({ [event.target.name]: '0.00' });
    }
  }

  handleCloseModal = () => {
    this.setState(this.initialState, () => {
      this.props.handleToggleModal(MODAL_OPTIONS.rhinopay);
    });
  }

  get paymentRequestPayload() {
    return {
      orgId: this.props.activeOrg.id,
      userId: this.props.activeUser.id,
      requestAmount: parseFloat(this.state.paymentRequestAmount, 10),
      note: this.state.paymentRequestNote,
    };
  }

  render() {
    const props = {
      activeUser: this.props.activeUser,
      errors: this.state.errors,
      handleCloseModal: this.handleCloseModal,
      handleChange: this.handleChange,
      handleFocus: this.handleFocus,
      handleSubmitRequest: this.handleSubmitRequest,
      handleRequestAmountChange: this.handleRequestAmountChange,
      isFormInProgress: this.state.isFormInProgress,
      isOpen: this.props.isOpen,
      paymentRequestAmount: this.state.paymentRequestAmount,
      paymentRequestNote: this.state.paymentRequestNote,
      rhinopayRequestAmountInputRef: this.props.rhinopayRequestAmountInputRef,
    };

    return (
      <RhinopayRequestModal {...props} />
    );
  }
}

RhinopayRequestModalContainer.propTypes = {
  activeOrg: PropTypes.object.isRequired,
  activeUser: PropTypes.object.isRequired,
  createPaymentRequest: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleSendMessage: PropTypes.func.isRequired,
  handleToggleModal: PropTypes.func.isRequired,
  merchant: PropTypes.object.isRequired,
  paymentRequests: PropTypes.object.isRequired,
  rhinopayRequestAmountInputRef: PropTypes.instanceOf(Object),
  threadOptions: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { pay } = state;

  return {
    paymentRequests: pay.paymentRequests,
    activeUser: userSelectors.getActiveUser(state),
    activeOrg: organizationSelectors.getCurrentOrg(state),
    merchant: pay.merchant,
    isOpen: state.thread.isRhinopayModalOpen,
  };
};

const actions = {
  createPaymentRequest,
  handleSendMessage: InboxReducer.handleSendMessage,
  handleToggleModal: ThreadReducer.toggleModal,
};

export default connect(mapStateToProps, actions)(RhinopayRequestModalContainer);
