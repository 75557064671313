import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button } from 'rhinostyle';

import { Email } from '.';

class EmailGroup extends PureComponent {
  state = {
    userEmails: this.props.userEmails,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userEmails !== this.props.userEmails) {
      this.setState({ userEmails: this.props.userEmails }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  handleAddNewContactItem = () => {
    const key = Object.keys(this.state.userEmails).length;

    const userEmails = { ...this.state.userEmails };
    const userEmail = { ...userEmails[key] };
    userEmails[key] = userEmail;

    this.handleChange(userEmail, key);

    this.setState({ userEmails });
  }

  handleChange = (email, key) => {
    if (this.props.receiveContactData) {
      this.props.receiveContactData(key, email, 'userEmails');
    }
  }

  render() {
    let count = 0;
    const {
      errorList,
      currentUser,
      handleRemoveContactData,
      types,
      integrated,
      isAddAnotherEmailDisabled,
      isRemoveEmailDisabled,
    } = this.props;

    const numEmails = this.state.userEmails &&
      Object.keys(this.state.userEmails).length ? Object.keys(this.state.userEmails).filter((p) => !this.state.userEmails[p].markedToDelete).length : 0;

    return (
      <div className="form__group edit-profile__emails">
        {Object.keys(this.state.userEmails).map((e, idx) => {
          const email = this.state.userEmails[e];
          if (!email.markedToDelete) count += 1;

          const validationMessage = errorList.userEmails && errorList.userEmails[`userEmails-${idx}`];
          const serverValidation = errorList[`emails[${idx}].address`];

          const props = {
            count,
            currentUser,
            email,
            handleChange: this.handleChange,
            handleRemoveContactData,
            idx,
            integrated,
            numEmails,
            types,
            validationMessage: validationMessage || serverValidation,
            isAddAnotherEmailDisabled,
            isRemoveEmailDisabled,
          };

          return (!email.markedToDelete && <Email {...props} key={e} />);
        })}

        {(!integrated || currentUser.isCcr) && (
          <Button
            reset
            disabled={isAddAnotherEmailDisabled}
            onClick={this.handleAddNewContactItem}
            className="u-text-primary edit-profile__emails__add-button"
          >
            Add another email address
          </Button>
        )}
      </div>
    );
  }
}

EmailGroup.propTypes = {
  integrated: PropTypes.bool,
  currentUser: PropTypes.object,
  errorList: PropTypes.object,
  handleRemoveContactData: PropTypes.func,
  receiveContactData: PropTypes.func,
  types: PropTypes.array,
  userEmails: PropTypes.object,
  isAddAnotherEmailDisabled: PropTypes.bool,
  isRemoveEmailDisabled: PropTypes.bool,
};

export default EmailGroup;
