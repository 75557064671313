import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import NotificationService from '../services/NotificationService';
import { DataHelpers } from '../helpers';

import * as StateReducer from './stateReducer';

import { setError } from './uiReducer';
import { office } from '../actions/NormalizrSchema';

// SLICE
const officeSlice = createSlice({
  name: 'OFFICE',
  initialState: {
    offices: {},
    officeIds: [],
    pageLoading: false,
    mappedOffices: {},
    mappedOfficeIds: [],
  },
  reducers: {
    receiveOffices: receiveOfficesData,
    receiveNewOffices: receiveNewOfficesData,
    receiveOfficesView: receiveOfficesData,
    receiveCreateOffice: receiveOfficesData,
    receiveUpdateOffice: receiveOfficesData,
    receiveMappedOffices: receiveMappedOfficesData,
    removeOffice: (state, action) => ({
      ...state,
      offices: DataHelpers
        .filterObjByKeys(state.offices, [action.payload.id].map(String)),
      officeIds: [...state.officeIds.filter((id) => id !== action.payload.id)],
    }),
    requestData: (state) => ({
      ...state,
      pageLoading: true,
    }),
  },
});

export default officeSlice.reducer;

// ACTIONS
export const {
  receiveOffices,
  receiveNewOffices,
  receiveOfficesView,
  receiveCreateOffice,
  receiveUpdateOffice,
  removeOffice,
  requestData,
  receiveMappedOffices,
} = officeSlice.actions;

// REDUCER HELPERS

function receiveOfficesData(state, action) {
  return {
    ...state,
    ...action.payload.offices && action.payload.officeIds && {
      offices: {
        ...state.offices,
        ...action.payload.offices,
      },
      officeIds: [...new Set([...state.officeIds, ...action.payload.officeIds])],
    },
    pageLoading: false,
  };
}

function receiveMappedOfficesData(state, action) {
  const offices = action.payload.offices || [];
  const officeIds = action.payload.officeIds || [];
  return {
    ...state,
    mappedOffices: {
      ...offices,
    },
    mappedOfficeIds: [...new Set([...officeIds])],
    pageLoading: false,
  };
}
function receiveNewOfficesData(state, action) {
  const offices = action.payload.offices || [];
  const officeIds = action.payload.officeIds || [];
  return {
    ...state,
    offices: {
      ...offices,
    },
    officeIds: [...new Set([...officeIds])],
    pageLoading: false,
  };
}

// THUNKS -- ASYNC ACTION CREATORS

export function destroyOffice(id) {
  return (dispatch) =>
    axios.delete(`offices/${id}`)
      .then((response) => {
        NotificationService('destroyOffice', response);

        dispatch(removeOffice({ id }));
      })
      .catch((err) => {
        console.error(err.response || err);

        NotificationService('destroyOffice', err);

        dispatch(setError(err.response || err));
      });
}

export function updateOffice(payload, id) {
  return (dispatch) =>
    axios.patch(`/offices/${id}`, payload)
      .then((response) => {
        const normalized = normalize(response.data, office);

        NotificationService('updateOffice', response);

        dispatch(receiveUpdateOffice(prepareOfficePayload(normalized)));

        dispatch(setError(null));

        return response.data;
      })
      .catch((error) => {
        console.error(error.response);
        NotificationService('updateOffice', error.response);
        dispatch(setError(error.response));
      });
}

export function createOffice(payload) {
  return (dispatch) =>
    axios.post('/offices', payload)
      .then((response) => {
        const normalized = normalize(response.data, office);

        NotificationService('createOffice', response);

        dispatch(receiveCreateOffice(prepareOfficePayload(normalized)));

        dispatch(setError(null));

        return response.data;
      })
      .catch((err) => {
        console.error(err.response);
        NotificationService('createOffice', err.response);
        dispatch(setError(err.response));
      });
}

export function fetchOfficeView(officeId) {
  return (dispatch) => {
    dispatch(requestData());

    const promises = [StateReducer.fetchAllStates()];
    if (officeId) promises.push(getOffice(officeId));

    return Promise.all(promises)
      .then(([statesResponse, officeResponse]) => {
        statesResponse.data.unshift({ id: -1, value: '--' });

        const normalizedOffice = officeResponse ? normalize(officeResponse.data, office) : null;
        dispatch(receiveOfficesView(prepareOfficeViewPayload(statesResponse.data, normalizedOffice)));
      })
      .catch((error) => {
        console.error(error.response || error);

        dispatch(setError(error.response || error));
      });
  };
}

export function fetchOffice(id) {
  return (dispatch) => {
    dispatch(requestData());

    return axios.get(`/offices/${id}`)
      .then(({ data }) => {
        const normalized = normalize(data, office);

        dispatch(receiveOffices(prepareOfficePayload(normalized)));

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response);

        dispatch(setError(err.response));
      });
  };
}

export function fetchOffices() {
  return (dispatch) => {
    dispatch(requestData());

    return axios.get('/offices')
      .then(({ data: offices }) => {
        const normalized = normalize(offices, [office]);

        dispatch(receiveOffices(prepareOfficesPayload(normalized)));

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response);

        dispatch(setError(err.response));
      });
  };
}

export function fetchMappedIntegrationOffices(orgId) {
  return (dispatch) => {
    dispatch(requestData());
    return axios.get(`${process.env.REACT_APP_FEEDER_BASE_URL}/organization-configurations/${orgId}`)
      .then(({ data: configuration }) => {
        const officeIds = configuration?.offices?.map((configurationOffice) => configurationOffice.officeId)
          .filter((configurationOffice) => configurationOffice !== undefined);
        if (officeIds?.length) {
          return axios.get(`/offices?officeIds=[${officeIds}]`);
        }
        // Return empty response to overwrite possible existing offices.
        return { data: [] };
      })
      .then(({ data: offices }) => {
        const normalized = normalize(offices, [office]);
        dispatch(receiveMappedOffices(prepareOfficesPayload(normalized)));
        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response);

        dispatch(setError(err.response));
      });
  };
}

// AXIOS HELPERS

function getOffice(officeId) {
  return axios.get(`/offices/${officeId}`)
    .catch((error) => console.error(error.response || error));
}

// PREPARE CALLBACKS -- PAYLOAD CUSTOMIZERS

function prepareOfficesPayload(normalizedOffices) {
  return {
    offices: normalizedOffices.entities.offices,
    officeIds: normalizedOffices.result,
  };
}

function prepareOfficePayload(normalizedOffice) {
  return {
    offices: normalizedOffice.entities.offices,
    officeIds: [normalizedOffice.result],
  };
}

function prepareOfficeViewPayload(states, normalizedOffice) {
  return {
    states,
    ...normalizedOffice && {
      ...prepareOfficePayload(normalizedOffice),
    },
  };
}
