import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

import NotificationService from '../services/NotificationService';
import { TYPE_TEMPLATE_TEAM, TYPE_TEMPLATE_SYSTEM, TYPE_TEMPLATE_CONTACT } from '../constants/Types';
import { TEMPLATES_LIST_HEADERS_CONFIG } from '../constants/LibraryConstants';

import { setError, setFormInProgress } from './uiReducer';
import { cloneDeep, compareObjectByKey, getDefaultSortKey } from '../helpers/DataHelpers';

// SLICE
const messageTemplateSlice = createSlice({
  name: 'MESSAGETEMPLATE',
  initialState: {
    loading: false,
    messageTemplates: [],
    messageTemplateIds: [],
    pageLoading: true,
    total: 0,
    allTemplates: [],
  },
  reducers: {
    receiveMessageTemplates: (state, action) => {
      let allTemplates = action.payload.allTemplates ? [...action.payload.allTemplates] : state.allTemplates;
      let messageTemplates = [...action.payload.messageTemplates];
      let messageTemplateIds = [...action.payload.messageTemplateIds];
      if (action.payload.allTemplates) {
        const defaultSortKey = getDefaultSortKey(TEMPLATES_LIST_HEADERS_CONFIG);
        const favoriteTemplates = action.payload.messageTemplates.filter((template) => template.favorited === true);
        const nonFavoriteTemplates = action.payload.messageTemplates.filter((template) => template.favorited === false);
        const sortedFavorites = favoriteTemplates.sort((a, b) => compareObjectByKey(a, b, defaultSortKey));
        const sortedNonFavorites = nonFavoriteTemplates.sort((a, b) => compareObjectByKey(a, b, defaultSortKey));
        messageTemplates = [...sortedFavorites, ...sortedNonFavorites];
        messageTemplateIds = messageTemplates.map((template) => template.id);
        allTemplates = [...sortedFavorites, ...sortedNonFavorites];
      }
      return {
        ...state,
        messageTemplates,
        messageTemplateIds,
        allTemplates,
        total: action.payload.messageTemplates.length,
        loading: false,
        pageLoading: false,
      };
    },
    receiveMessageTemplate: (state, action) => ({
      ...state,
      messageTemplates: [action.payload],
      messageTemplateIds: [action.payload.id],
      loading: false,
      pageLoading: false,
    }),
    receiveCreateMessageTemplate: (state) => ({
      ...state,
      loading: false,
    }),
    receiveUpdateFavoriteStatus: (state, action) => {
      let messageTemplates = [];
      if (action.payload.filter === 'all') {
        messageTemplates = cloneDeep(state.messageTemplates).map((template) => {
          if (template.id === action.payload.messageTemplateId) {
            template.favorited = !template.favorited; // eslint-disable-line no-param-reassign
          }
          return template;
        });
      } else if (action.payload.filter === 'favorite') {
        messageTemplates = [...state.messageTemplates.filter((template) => template.id !== action.payload.messageTemplateId)];
      }

      const allTemplates = cloneDeep(state.allTemplates).map((template) => {
        if (template.id === action.payload.messageTemplateId) {
          template.favorited = !template.favorited; // eslint-disable-line no-param-reassign
        }
        return template;
      });

      return {
        ...state,
        messageTemplates,
        allTemplates,
        loading: false,
      };
    },
    receiveUpdateMessageTemplate: (state, action) => ({
      ...state,
      messageTemplates: cloneDeep(state.messageTemplates).map((template) => {
        if (template.id === action.payload.messageTemplateId) {
          template = action.payload.messageTemplate; // eslint-disable-line no-param-reassign
        }
        return template;
      }),
      messageTemplateIds: [...new Set([...state.messageTemplateIds, action.payload.messageTemplateId])],
      loading: false,
    }),
    removeMessageTemplate: (state, action) => ({
      ...state,
      allTemplates: [...state.allTemplates.filter((template) => template.id !== action.payload.messageTemplateId)],
      messageTemplates: [...state.messageTemplates.filter((template) => template.id !== action.payload.messageTemplateId)],
      messageTemplateIds: [...state.messageTemplateIds.filter((id) => id !== action.payload.messageTemplateId)],
      total: state.total - 1,
      loading: false,
    }),
    requestData: (state) => ({
      ...state,
      loading: true,
      pageLoading: true,
    }),
    requestPageData: (state) => ({ ...state, pageLoading: true }),
    clearTemplates: (state) => ({
      ...state,
      messageTemplateIds: [],
      messageTemplates: [],
      total: 0,
      pageLoading: true,
    }),
  },
});

export default messageTemplateSlice.reducer;

// ACTIONS
export const {
  receiveMessageTemplates,
  receiveCreateMessageTemplate,
  receiveUpdateMessageTemplate,
  removeMessageTemplate,
  requestData,
  requestPageData,
  receiveUpdateFavoriteStatus,
  clearTemplates,
  receiveMessageTemplate,
} = messageTemplateSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

export function fetchMessageTemplates(templateType) {
  const url = getMessageTemplateUrl(templateType);
  return (dispatch) => {
    dispatch(requestData());

    return axios.get(url)
      .then((response) => {
        dispatch(receiveMessageTemplates(getMessageTemplatePayload(response.data, 'all')));
      })
      .catch((err) => dispatch(handleError(err)));
  };
}

export function fetchMessageTemplateById(templateId) {
  const url = `/templates/${templateId}`;
  return (dispatch) => {
    dispatch(requestData());

    return axios.get(url)
      .then((response) => {
        dispatch(receiveMessageTemplate(response.data));
      })
      .catch((err) => dispatch(handleError(err)));
  };
}

function getMessageTemplateUrl(type) {
  if (type === 'teamTemplate') {
    return `/templates?typeId=${TYPE_TEMPLATE_TEAM}`;
  } else if (type === 'systemAndContact') {
    return `/templates?typeId=${TYPE_TEMPLATE_SYSTEM},${TYPE_TEMPLATE_CONTACT}`;
  } else {
    return '/templates';
  }
}

export function createMessageTemplate(payload) {
  return (dispatch) => {
    dispatch(setFormInProgress(true));

    return axios.post('/templates', payload)
      .then((response) => {
        NotificationService('createMessageTemplate', response);

        dispatch(receiveCreateMessageTemplate());

        dispatch(setError(null));

        return response.data;
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        NotificationService('createMessageTemplate', err.response);
      });
  };
}

export function updateMessageTemplate(messageTemplateId, payload) {
  return (dispatch) => {
    dispatch(setFormInProgress(true));

    return axios.patch(`/templates/${messageTemplateId}`, payload)
      .then((response) => {
        NotificationService('updateMessageTemplate', response);
        dispatch(receiveUpdateMessageTemplate({
          messageTemplateId,
          messageTemplate: response.data,
        }));

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        NotificationService('updateMessageTemplate', err.response);
      });
  };
}

export function updateTemplatesList(templates) {
  return (dispatch) => {
    dispatch(receiveMessageTemplates(getMessageTemplatePayload(templates)));
  };
}

export function destroyMessageTemplate(messageTemplateId) {
  return (dispatch) => {
    dispatch(setFormInProgress(true));

    return axios.delete(`/templates/${messageTemplateId}`)
      .then((response) => {
        NotificationService('destroyMessageTemplate', response);

        dispatch(removeMessageTemplate({ messageTemplateId }));

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        NotificationService('destroyMessageTemplate', err.response);
      });
  };
}

// Helpers
function handleError(err) {
  return (dispatch) => {
    const error = err.response || err;

    console.error(error);

    dispatch(setError(error));
  };
}

function getMessageTemplatePayload(normalizedMessageTemplates, type) {
  const templates = {
    messageTemplates: [...normalizedMessageTemplates],
    messageTemplateIds: normalizedMessageTemplates.map((template) => template.id),
  };
  if (type === 'all') {
    templates.allTemplates = [...normalizedMessageTemplates];
  }
  return templates;
}

function getUpdateFavoriteStatusPayload(templateId, filter) {
  return {
    messageTemplateId: templateId,
    filter,
  };
}

export function updateFavoriteStatus(templateId, payload, filter = 'all') {
  return (dispatch) => {
    dispatch(setFormInProgress(true));
    return axios.patch(`/templates/favorite/${templateId}`, payload)
      .then(() => {
        dispatch(receiveUpdateFavoriteStatus(getUpdateFavoriteStatusPayload(templateId, filter)));

        dispatch(setError(null));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        NotificationService('updateFavoriteStatusMessageTemplate', err.response);
      });
  };
}
