import React, { useState } from 'react';
import {
  Button,
  Input,
  CheckboxGroup,
  Dropdown,
  UtilityInlineGrid,
  FormValidationMessage,
} from 'rhinostyle';
import cx from 'classnames';
import { usePayors } from '../hooks';
import LazyLoadList from './LazyLoadList';
import PayorCheckbox from './PayorCheckbox';

const PayorsSearchSelect = ({ selectedPayors, prescriptionCampaignId, handleChange, type, validationMessage }) => {
  const [viewSelected, setViewSelected] = useState(false);
  const hasPayors = selectedPayors?.length > 0;
  const availablePayors = hasPayors || viewSelected;
  const [fetchOptions, setFetchOptions] = useState({
    pageNo: 0,
    nameSearch: '',
  });

  const {
    nameSearch,
  } = fetchOptions;

  const { data, isPageFetching } = usePayors({ nameSearch: fetchOptions.nameSearch?.length > 2 ? fetchOptions.nameSearch : '', pageNo: fetchOptions.pageNo });
  const payors = data?.results || [];

  function handleSearchChange(name, value) {
    setFetchOptions((current) => ({
      ...current,
      nameSearch: value,
      ...getValueChange(current, value) && value !== current.value && {
        pageNo: 0,
      },
    }));
  }

  function getValueChange(current, value) {
    return current.nameSearch?.length > 2 || (current?.nameSearch?.length < 2 && value?.length > 2);
  }

  const handleCheck = (typeId) => {
    const index = selectedPayors.indexOf(typeId);
    const updatedPayors = [...selectedPayors];
    if (index < 0) {
      updatedPayors.push(typeId);
    } else {
      updatedPayors.splice(index, 1);
    }
    handleChange('payors', updatedPayors);
  };

  const handleSelectAll = () => {
    handleChange('payors', [...payors.map((payor) => payor.id)]);
  };

  const handleClearAll = () => {
    handleChange('payors', []);
  };

  const renderClearButton = () => (
    <Button size="small" type="link" onClick={handleClearAll} title="Clear All">
      Clear All
    </Button>
  );

  const renderSelectAllButton = () => (
    <Button size="small" type="link" onClick={handleSelectAll} title="Select All">
      Select All
    </Button>
  );
  const renderSearchActionButton = () => {
    let buttonText = viewSelected ? 'Add more payors' : 'Review';
    if (!availablePayors) buttonText = 'Select All';

    return (
      <Button
        className="u-text-primary appointment-reminder-form__types-button"
        onClick={() => (availablePayors ? setViewSelected((current) => !current) : handleSelectAll())}
        reset
        data-cypress="selectAllTypes"
      >
        {buttonText}
      </Button>
    );
  };

  const getVisiblePayors = () => {
    const visibleTypes = viewSelected ?
      payors.filter((payor) => selectedPayors?.includes(payor.id)) : payors;
    if (nameSearch.length > 2 && payors.length === data.total) {
      return visibleTypes.filter((payor) => payor?.name.toLowerCase().includes(nameSearch.toLowerCase()) || payor?.bin.toLowerCase().includes(nameSearch.toLowerCase()));
    }
    return visibleTypes;
  };

  const visiblePayors = getVisiblePayors();

  function handleLoadMore() {
    if (payors?.length > 0 && payors?.length < data?.total && !isPageFetching) {
      setFetchOptions((current) => ({ ...current, pageNo: current.pageNo + 1 }));
    }
  }

  const getLabel = () => {
    const label = 'Payors or Bins';
    if (hasPayors) {
      const pluralVersion = label.slice(-1) === 's' ? 'es' : 's';
      return `${selectedPayors?.length} ${label}${availablePayors.length > 1 ? pluralVersion : ''} Selected`;
    }
    return label;
  };

  const renderPayorCheckbox = (payor) => {
    const isChecked = selectedPayors?.includes(payor.id);
    const inUse = payor.prescriptionCampaignId && payor.prescriptionCampaignId !== prescriptionCampaignId;
    return (
      <PayorCheckbox
        handleCheck={handleCheck}
        payor={payor}
        isChecked={isChecked}
        inUse={inUse}
        key={payor.id}
        isFormView={type !== 'filter'}
      />
    );
  };
  const columnLeftClass = cx('u-p-l-small', {
    'column-8': type === 'filter',
    'column-6': type !== 'filter',
  });

  function renderList() {
    return (
      <>
        {!viewSelected && (
        <Input
          name="payorSearch"
          value={nameSearch}
          className="u-m-b"
          onChange={handleSearchChange}
          placeholder="Search Payors or Bins"
          dataFeatureTag="payorSearch"
        />
        )}

        <div className="u-flex u-flex-direction-row u-p-l-large u-p-r-large u-m-l-large">
          <div className={columnLeftClass}>
            Payor
          </div>
          <div className="column-4 u-p-l-small">
            BIN
          </div>
        </div>
        {visiblePayors?.length > 0 ? (
          <LazyLoadList
            autoHeight="small"
            className="appointment-type__scroll"
            handleLoadMore={handleLoadMore}
            loading={isPageFetching}
          >
            <CheckboxGroup blockGroup interfaceMode="left">
              {visiblePayors.map(renderPayorCheckbox)}
            </CheckboxGroup>
          </LazyLoadList>
        ) : (<div>No Results</div>
        )}
      </>
    );
  }

  const renderViewSelected = () => {
    const title = viewSelected ? 'Back' : `View Selected (${selectedPayors.length})`;
    return (
      <Button
        size="small"
        type="link"
        onClick={() => setViewSelected((current) => !current)}
        disabled={!availablePayors}
        title={title}
      >
        {title}
      </Button>
    );
  };
  if (type === 'filter') {
    return (
      <div className="prescription-filters__dropdown">
        <Dropdown
          position="right"
          label={getLabel()}
          type={hasPayors ? 'primary' : 'input'}
          outlined={!!hasPayors}
          name="statuses"
          onSelect={(value) => handleChange(value)}
          wide
          className="filter-page__dropdown prescription-payors u-text-small"
        >
          <div className="dropdown__menu__container">
            <UtilityInlineGrid className="u-flex u-flex-justify-between u-m-t-small u-text-small">
              {selectedPayors?.length > 0 ? renderClearButton() : renderSelectAllButton()}
              {renderViewSelected()}
            </UtilityInlineGrid>
            {renderList()}
          </div>
        </Dropdown>
      </div>
    );
  }

  return (
    <div className="box">
      <div className="u-flex u-m-b-large u-flex-justify-between">
        {renderSearchActionButton()}
        <div>
          <div className="box__title u-text-center">Payors</div>
          <div className="u-text-italic u-text-muted">Notifications will only be sent to the following selected payors.</div>
          <FormValidationMessage validationMessage={validationMessage} />
        </div>
        <div className="appointment-reminder-form__types-count">
          {availablePayors && (`${selectedPayors.length} Payor${selectedPayors.length === 1 ? '' : 's'} Added`)}
        </div>
      </div>

      <div className="appointment-reminder-form__box-wrapper">
        {renderList()}
      </div>
    </div>
  );
};

export default PayorsSearchSelect;
