import React, { useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';
import {
  SmartTable,
  Tooltip,
  Icon,
  Scrollbars,
} from 'rhinostyle';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import NotificationService from '../services/NotificationService';
import EmptyMessage from '../components/EmptyMessage';
import PageLoader from '../components/PageLoader';
import { QUERY_STATUS_FULFILLED } from '../constants/v3';
import FilterPage from '../components/FilterPage';
import { useCampaigns, useQueryParams } from '../hooks';
import SmartTableHeader from '../components/SmartTableHeader';
import { fullTimestamp } from '../helpers/DateHelpers';
import { calculatePastDateRange } from '../helpers/AppointmentHelpers';
import { formatMemberNameWithPrefixAndSuffix } from '../helpers/UserHelpers';
import CampaignFilters from '../components/CampaignFilters';
import { DEFAULT_ANALYTICS_PAGE_SIZE } from '../constants/AppConstants';
import { getLoggedInUserOrganization } from '../selectors/userSelectors';
import { validateQueryStringValue } from '../helpers/PrescriptionHelpers';
import { CAMPAIGNS_CONVERSATION_HEADERS_CONFIG } from '../constants/AnalyticsConstants';
import { TEMPLATE_MESSAGE_VARIABLE_OPTIONS } from '../constants/VariableMessageConstants';
import CampaignSummaryPanel from '../components/CampaignSummaryPanel';

const BlastManager = () => {
  const [loading, setLoading] = useState(true);
  const { name } = useSelector(getLoggedInUserOrganization);
  const defaultDate = calculatePastDateRange(30);

  const initialValue = {
    sortBy: 'timestamp',
    sortOrder: 'desc',
    pageSize: DEFAULT_ANALYTICS_PAGE_SIZE,
    pageNo: 0,
    startDate: defaultDate.startDate,
    endDate: defaultDate.endDate,
    activeKey: 4,
    savedListIds: [],
  };
  const params = useParams();
  const [panelOpen, setPanelOpen] = useState(false);
  const [activeCampaign, setActiveCampaign] = useState({});
  const [headersConfig] = useState({ ...CAMPAIGNS_CONVERSATION_HEADERS_CONFIG });
  const [filters, onSetFilters] = useQueryParams({ initialValue, validateQueryStringValue });
  const campaignId = Number(params?.campaignId);

  const {
    sortBy,
    sortOrder,
    pageSize,
    pageNo,
    startDate,
    endDate,
    activeKey,
    savedListIds,
  } = filters;

  const query = useCampaigns({
    fromDate: startDate.format('YYYY-MM-DD'),
    toDate: endDate.format('YYYY-MM-DD'),
    sortBy,
    sortOrder,
    pageNo,
    pageSize,
    activeKey,
    savedListIds,
  });
  const { data, error, status } = query;
  const { total, results: campaigns } = data || {};

  function handlePagination(type) {
    if ((type === 'previous' && pageNo > 0) || type === 'next') {
      onSetFilters({
        pageNo: type === 'previous' ? pageNo - 1 || 0 : pageNo + 1 || 0,
      });
    }
  }

  function handleClearAll() {
    onSetFilters(initialValue);
  }

  useEffect(() => {
    if (status === QUERY_STATUS_FULFILLED) {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (error) {
      NotificationService('fetchCampaigns', error);
    }
  }, [error]);

  if (loading) {
    return <PageLoader />;
  }

  // Compares current and next value from react table
  const tableDataComparator = (current, next) => {
    if (current === next) {
      return 0;
    }
    return current < next ? 1 : -1;
  };

  const convertTemplateVariablesIntoValues = (message, orgName) => {
    let finalMessage = message;
    TEMPLATE_MESSAGE_VARIABLE_OPTIONS.forEach((value) => {
      finalMessage = finalMessage.replace(value.variable, value.variableValue);
      // Replaced dummy organization name with actual logged in organization
      finalMessage = finalMessage.replace('{organization_name}', orgName);
    });
    return finalMessage;
  };

  const tooltipContent = (row) => (
    <div className="analytics__tooltip-box">
      <p className="analytics__tooltip-message">{convertTemplateVariablesIntoValues(row.original.message, name)}</p>
    </div>
  );

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': panelOpen,
  });
  const cellClass = 'u-flex-align-items-center u-flex-direction-row u-flex u-p-t-small u-p-b-small';
  const headerStyle = { padding: 0, display: 'flex', alignItems: 'center' };
  const columns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Date Sent"
          sortKey="timestamp"
          sortDirection={headersConfig.timestamp.direction}
          headers={headersConfig}
        />
      ),
      headerStyle,
      className: cellClass,
      accessor: 'timestamp',
      fixed: 'left',
      minWidth: 130,
      Cell: (row) => (
        <div className="analytics__date-row row__text">{fullTimestamp(row.value, 'ddd MMM D, Y[\n]h:mm a')}
        </div>
      ),
    },
    {
      id: 'senderName',
      Header: () => (
        <SmartTableHeader
          headerName="Member"
          sortKey="member"
          sortDirection={headersConfig.member.direction}
          headers={headersConfig}
        />
      ),
      headerStyle,
      className: cellClass,
      accessor: 'senderName',
      Cell: (row) => (<div>{formatMemberNameWithPrefixAndSuffix(row.original)}</div>),
    },
    {
      id: 'message',
      Header: () => (
        <SmartTableHeader
          headerName="Message"
          sortKey="message"
          sortDirection={headersConfig.message.direction}
          headers={headersConfig}
          isCaretDisabled
        />
      ),
      headerStyle,
      accessor: 'message',
      className: cellClass,
      Cell: (row) => (
        <div className="analytics__message-icon">
          <Tooltip content={tooltipContent(row)} delay={false}>
            <Icon icon="note" />
          </Tooltip>
        </div>
      ),
    },
    {
      id: 'attachments',
      Header: () => (
        <SmartTableHeader
          headerName="attachments"
          sortKey="attachments"
          sortDirection={headersConfig.attachments.direction}
          headers={headersConfig}
        />
      ),
      headerStyle,
      accessor: 'attachments',
      className: cellClass,
      Cell: (row) => (
        <div>
          {row.original?.attachments}
          <Icon className="blastManager__attachment-icon" icon="attachment" />
        </div>
      ),
      sortMethod: (current, next) => tableDataComparator(current, next),
    },
    {
      id: 'contactListName',
      Header: () => (
        <SmartTableHeader
          headerName="Saved List"
          sortKey="contactListName"
          sortDirection={headersConfig.contactListName.direction}
          headers={headersConfig}
        />
      ),
      headerStyle,
      className: cellClass,
      accessor: 'contactListName',
      Cell: (row) => (<div>{row?.original?.contactListName}</div>),
    },
    {
      id: 'contactCount',
      Header: () => (
        <SmartTableHeader
          headerName="Contacts"
          sortKey="contactCount"
          sortDirection={headersConfig.contactCount.direction}
          headers={headersConfig}
        />
      ),
      headerStyle,
      className: cellClass,
      accessor: 'contactCount',
      Cell: (row) => (
        <div>
          {row.original.contactCount}
        </div>
      ),
      sortMethod: (current, next) => tableDataComparator(current, next),
    },
    {
      id: 'failed',
      Header: () => (
        <SmartTableHeader
          headerName="Failed"
          sortKey="failed"
          sortDirection={headersConfig.failed.direction}
          headers={headersConfig}
        />
      ),
      headerStyle,
      className: cellClass,
      accessor: 'failed',
      Cell: (row) => (
        <div>
          <span className={`u-p-r-small ${row.original.failed ? 'u-text-danger' : ''}`}>{row.original.failed}</span>
        </div>
      ),
    },
  ];

  const handleUpdateFilters = (changeObj) => {
    onSetFilters({
      ...changeObj,
      ...!changeObj.pageNo && {
        pageNo: 0,
      },
    });
  };

  const getActiveFilterValues = (values) => {
    const {
      pageNo: pn,
      startDate: sd,
      endDate: ed,
      ...activeValues
    } = values;
    return activeValues;
  };

  const handleTogglePanel = (id) => {
    const campaign = campaigns.find((c) => c.bulkMessageId === id);
    setActiveCampaign(campaign);
    setPanelOpen(!panelOpen);
  };

  const showClearAll = !isEqual(getActiveFilterValues(filters), getActiveFilterValues(initialValue));

  const renderFilters = () => (
    <CampaignFilters handleChange={handleUpdateFilters} filters={filters} />
  );
  const rowProps = (state, rowInfo) => ({
    onClick: () => {
      handleTogglePanel(rowInfo?.original?.bulkMessageId);
    },
    style: {
      cursor: 'pointer',
    },
    className: rowInfo?.id === campaignId ? 'selected-prescription-template' : 'form-row',
  });

  const renderList = () => (
    <div className="filter-page__table u-m-t">
      {campaigns?.length > 0 ? (
        <SmartTable
          data={campaigns}
          getTrProps={rowProps}
          showPagination={false}
          pageSize={campaigns?.length}
          sticky
          columns={columns}
          sortable
          manual={false}
          striped
        />
      ) : (
        <div className="form-list__noforms_wrapper">
          <EmptyMessage section="Campaigns" />
        </div>
      )}
    </div>
  );

  return (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <Scrollbars className="library-scroll__container">
              <div className="list-panel">
                <div className="app-page__header">
                  <div className="app-page__header__title">
                    Blast Manager
                  </div>
                </div>
                <FilterPage
                  hasPanels
                  hasFilters
                  header="Total Campaigns"
                  totalCount={total}
                  showClearAll={showClearAll}
                  clearAllFilters={handleClearAll}
                  pageNumber={pageNo}
                  loading={loading}
                  handlePagination={handlePagination}
                  pageItemCount={campaigns?.length}
                  pageSize={DEFAULT_ANALYTICS_PAGE_SIZE}
                  type="appointments"
                  displayBottomPagination
                  renderFilters={renderFilters}
                >
                  <div className="list-panel__body" data-feature-tag="campaignList">
                    <div className="list-panel" />
                    {renderList()}
                  </div>
                </FilterPage>
              </div>
            </Scrollbars>
          </div>
        </div>
        {panelOpen && (<CampaignSummaryPanel handleTogglePanel={handleTogglePanel} activeCampaign={activeCampaign} />)}
      </div>
    </div>
  );
};

export default BlastManager;
