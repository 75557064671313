import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChannelById } from '../reducers/channelReducer';
import { getChannelById } from '../selectors/routingSelectors';

const useChannel = (itemId) => {
  const dispatch = useDispatch();
  const channelId = Number(itemId) || null;
  const channel = useSelector((state) => getChannelById(state, { channelId }));

  useEffect(() => {
    if (channelId) {
      dispatch(fetchChannelById(channelId));
    }
  }, [channelId]);

  return channel;
};

export default useChannel;
