import { useLocation } from 'react-router-dom';
import threadService from '../services/threadService';
import { useThreadOptions } from '.';

const useSelectThreadEvents = () => {
  const location = useLocation();
  const threadOptions = useThreadOptions();
  const events = threadService.endpoints.getThread.useQueryState(threadOptions, {
    selectFromResult: (result) => (result),
    skip: !location.pathname.includes('inbox') || !threadOptions.userId,
  });
  return events;
};

export default useSelectThreadEvents;
