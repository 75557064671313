import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Resource,
  ResourceIntro,
  ResourceRight,
  ResourceGroup,
  Scrollbars,
} from 'rhinostyle';

import PageLoader from './PageLoader';
import OfficePanel from './OfficePanel';
import EmptyMessage from './EmptyMessage';
import { formatAddress } from '../helpers/DataHelpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { OFFICE_CREATE } from '../constants/UserPermissionsConstants';

const OrganizationOffices = (props) => {
  const {
    panelOpen,
    handleCreateRequest,
    offices,
    officeIds,
    activeOfficeId,
    handleTogglePanel,
    pageLoading,
    user,
    currentOrganization,
  } = props;

  const renderOffice = (id, key) => {
    const office = offices[id];
    const active = activeOfficeId === id;

    return (
      <Resource active={active} key={key} onClick={() => handleTogglePanel(id)}>
        <ResourceIntro title={office.name} />
        <ResourceRight>
          {formatAddress(office.address)}
        </ResourceRight>
      </Resource>
    );
  };

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': panelOpen,
  });

  if (pageLoading) {
    return <PageLoader />;
  }

  const activeOffice = offices[activeOfficeId];

  return (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="app-page__header">
              <div className="app-page__header__title">Offices</div>
              {userHasAnyOfPermissions([OFFICE_CREATE]) && (
                <div className="app-page__header__action">
                  <Button
                    onClick={handleCreateRequest}
                    iconOnly
                    type="secondary"
                    title="Create Office"
                  >
                    <Icon icon="add" />
                  </Button>
                </div>
              )}
            </div>
            <div className="list-panel__body">
              {officeIds.length ? (
                <Scrollbars className="list-panel__body__scroll">
                  <ResourceGroup>
                    {officeIds.map(renderOffice)}
                  </ResourceGroup>
                </Scrollbars>
              ) :
                <EmptyMessage section="Offices" />}
            </div>
          </div>
        </div>
        {panelOpen && activeOffice && (
          <OfficePanel
            activeOffice={activeOffice}
            user={user}
            handleTogglePanel={handleTogglePanel}
            currentOrganization={currentOrganization}
          />
        )}
      </div>
    </div>
  );
};

OrganizationOffices.propTypes = {
  user: PropTypes.object.isRequired,
  panelOpen: PropTypes.bool.isRequired,
  handleCreateRequest: PropTypes.func.isRequired,
  offices: PropTypes.object.isRequired,
  officeIds: PropTypes.array.isRequired,
  activeOfficeId: PropTypes.number,
  handleTogglePanel: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  currentOrganization: PropTypes.object,
};

export default OrganizationOffices;
