import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, Input, RhinoSwitch, Radio, RadioGroup } from 'rhinostyle';
import { DateHelpers } from '../helpers';
import ExpandableBox from './ExpandableBox';
import VariableMessage from './VariableMessage';
import { AUTOMATED_MESSAGE_TEMPLATES } from '../constants/VariableMessageConstants';
import { TYPE_APPOINTMENT_EVENT_REMINDER1 } from '../constants/Types';
import AppointmentManagerMultiSelect from './AppointmentManagerMultiSelect';

const AppointmentCampaignFormReminder = ({
  handleChange,
  attachments,
  errors,
  initialValue,
  variableOptions,
  appointmentEhrStatuses,
  reminder: {
    enabled,
    deliveryHours,
    template,
    typeId,
    name,
    sendAfterAppt,
    ehrStatus,
  },
  onShowWarningMessage,
}) => {
  const templateName = `${name}Template`;
  const deliveryHoursName = `${name}DeliveryHours`;
  const prePostAppointmentName = `${name}SendAfterAppt`;
  const ehrTriggerName = `${name}EhrStatus`;
  const enabledName = `${name}Enabled`;
  const deliveryHoursFormatted = DateHelpers.convertHoursToDays(deliveryHours, true);

  const title = 'ADDITIONAL APPOINTMENT MESSAGES';
  const titleSwitch = 'Appointment  Reminder Message';
  const defaultTemplateText = typeId === TYPE_APPOINTMENT_EVENT_REMINDER1 ? AUTOMATED_MESSAGE_TEMPLATES.REMINDER1 : AUTOMATED_MESSAGE_TEMPLATES.REMINDER2;
  const reminderDeliveryText = (
    <>
      <span className="u-font-weight-bold u-p-r-xsmall">24 hrs Pre Appointment:</span>
      {deliveryHoursFormatted || ''} | Message: <span className="u-text-secondary u-p-r-xsmall">Enabled</span>
    </>
  );
  return (
    <ExpandableBox
      title={title}
      subtitle="Text message reminders will be delivered to patients prior to their appointment."
      description={enabled ? reminderDeliveryText : ''}
      dataFeatureTag={`${name}-expand`}
    >
      <RhinoSwitch
        name={enabledName}
        isChecked={enabled}
        onChange={handleChange}
        label={`Enable ${titleSwitch}`}
        dataFeatureTag={`edit-appointment-reminder_toggle-${name}`}
      />

      {enabled ? (
        <>
          <div className="appointment-wrapper">
            <div className="appointment__title__section">
              <div className="appointment__title">TIMING</div>
              <div className="appointment__subtitle">
                Establish when reminder message will be sent
              </div>
            </div>
          </div>

          <FormLabel
            id={`render${name}PrePostSettings`}
            className="variable-message__label u-m-t u-m-b-small"
            required={enabled}
          >
            Pre or Post Appointment Reminder Message
          </FormLabel>
          <RadioGroup
            name={prePostAppointmentName}
            onChange={(value) => handleChange(prePostAppointmentName, Number(value))}
            inline
            selectedValue={sendAfterAppt?.toString() || '0'}
            className="u-p-b"
          >
            <Radio value="0" label="Pre Appointment Message" dataCypress="preAppointmentMessage" />
            <Radio value="1" label="Post Appointment Message" dataCypress="postAppointmentMessage" />
          </RadioGroup>
          <FormLabel
            id={`render${name}Settings`}
            className="variable-message__label u-m-t u-m-b-small"
            required={enabled}
          >
            Reminder Delivery Setting
          </FormLabel>
          <Input
            name={deliveryHoursName}
            initialValue={deliveryHours.toString()}
            format={{
              numeral: true,
              numeralPositiveOnly: true,
              numeralDecimalScale: 0,
            }}
            className="am__hours-input u-m-r u-m-b u-inline-block"
            onChange={handleChange}
            required
            dataFeatureTag={deliveryHoursName}
            validationMessage={errors[deliveryHoursName]}
          />
          <strong data-cypress={`${deliveryHoursName}Formatted`}>
            {deliveryHoursFormatted} <span> Before Appointment</span>
          </strong>

          <div className="appointment-wrapper">
            <div className="appointment__title__section">
              <div className="appointment__title">MESSAGE</div>
              <div className="appointment__subtitle">
                MESSAGE to be sent based on above preferences
              </div>
            </div>
          </div>
          <FormLabel
            id={`render${name}ehrTrigger`}
            className="appointment-label"
          >
            Message Logic
          </FormLabel>
          <RhinoSwitch
            name={enabledName}
            isChecked={enabled}
            onChange={handleChange}
            label="Do NOT send this message if consent has been granted in a previous message"
            dataFeatureTag={`edit-appointment-reminder_toggle-${name}`}
          />
          { appointmentEhrStatuses && Object.keys(appointmentEhrStatuses).length > 0 ? (
            <>
              <FormLabel
                id={`render${name}ehrTrigger`}
                className="variable-message__label u-m-t"
              >
                EHR Status Trigger
              </FormLabel>
              <div className="u-text-small u-text-left  u-m-b-small">
                Optionally select an EHR status to determine if the appointment message is to be sent.
              </div>
              <AppointmentManagerMultiSelect
                className="ehr-status__dropdown"
                filterName="Search PMS/EHR Status"
                itemIds={Object.keys(appointmentEhrStatuses)}
                items={appointmentEhrStatuses}
                label="EHR Status"
                name={ehrTriggerName}
                selectedIds={ehrStatus || []}
                title="Select EHR Status"
                updateFilters={(updatedFilter) =>
                  handleChange(ehrTriggerName, updatedFilter[ehrTriggerName])}
              />
            </>
          ) : null}
          <VariableMessage
            composeLabel="Message"
            name={templateName}
            initialValue={initialValue || defaultTemplateText}
            handleTemplateChange={handleChange}
            variableOptions={variableOptions || []}
            template={template}
            required={enabled}
            validationMessage={errors[templateName]}
            preventFocusOnLoad
            attachments={attachments}
            appointmentEventTypeId={typeId}
            onShowWarningMessage={onShowWarningMessage}
          />
        </>
      ) : null}
    </ExpandableBox>
  );
};

AppointmentCampaignFormReminder.propTypes = {
  handleChange: PropTypes.func.isRequired,
  attachments: PropTypes.array,
  errors: PropTypes.object.isRequired,
  initialValue: PropTypes.string.isRequired,
  variableOptions: PropTypes.array.isRequired,
  appointmentEhrStatuses: PropTypes.object.isRequired,
  reminder: PropTypes.shape({
    template: PropTypes.string,
    deliveryHours: PropTypes.number,
    enabled: PropTypes.bool,
    typeId: PropTypes.number,
    name: PropTypes.string,
    sendAfterAppt: PropTypes.number,
    ehrStatus: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onShowWarningMessage: PropTypes.func.isRequired,
};

export default AppointmentCampaignFormReminder;
