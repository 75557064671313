import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Icon, Button } from 'rhinostyle';
import { toggleMicrophone } from '../reducers/rhinovideoReducer';

const RhinovideoToggleMicrophoneButton = (props) => {
  const { currentParticipant } = props;
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(toggleMicrophone(!currentParticipant.audioTrack?.isTrackEnabled));
  };

  const isMicrophoneEnabled = currentParticipant.audioTrack?.isTrackEnabled;

  return (
    <div>
      <Button
        type="link"
        size="large"
        iconOnly
        title={isMicrophoneEnabled ? 'Mute' : 'Unmute'}
        onClick={handleToggle}
        className="rhinovideo__action-bar__button"
      >
        <Icon className="rhinovideo__action-bar__microphone-button" icon={isMicrophoneEnabled ? 'microphone' : 'microphone-off'} />
      </Button>
      <div className="rhinovideo__action-bar__label">
        <span>Mic</span>
      </div>
    </div>
  );
};

RhinovideoToggleMicrophoneButton.propTypes = {
  currentParticipant: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { rhinovideo } = state;
  const { participants, twilioParticipantId } = rhinovideo;
  const currentParticipant = participants[twilioParticipantId];

  return ({
    currentParticipant,
  });
};

export default connect(mapStateToProps)(RhinovideoToggleMicrophoneButton);
