import auth0 from 'auth0-js';
import axios from 'axios';
import store from '../store';
import * as AuthReducer from '../reducers/authReducer';
import { setError } from '../reducers/uiReducer';
import StorageService from './StorageService';

class AuthService {
  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    allowForgotPassword: true,
    responseType: 'token id_token',
    scope: 'openid profile email',
  });

  // errorMessage options are defined in the Auth0 Universal Login html.
  login = (errorMessage) => { // eslint-disable-line no-unused-vars
    let connection = StorageService.readEntry('auth-connection');
    connection = ((connection !== 'all') ? connection : undefined);
    this.auth0.authorize({
      ...connection && { connection },
      redirectUri: `${window.location.origin}/callback`,
      // if an error occurred, route back to /auth and display a specific message (logout_message)
      ...errorMessage && { logout_message: errorMessage },
    });
  }

  handleAuthentication = async (props) => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult?.accessToken && authResult.idToken) {
        this.setSession(authResult);
        // update the default headers to use latest token
        axios.defaults.headers.common.Authorization = `Bearer ${authResult.accessToken}`;
        // call to get an access token for the API now.
        store.dispatch(AuthReducer.loggedIn())
          .then((path) => {
            if (path) {
              props.history.push(path);
            }
          });
      } else if (err) {
        store.dispatch(setError(err));
        this.login();
      }
    });
  }

  setSession = (authResult) => {
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
  }

  logout = async (logoutType) => {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');

    // if logoutType is set, pass to oAuth for handling logout message errors
    let params = '';
    if (logoutType) {
      params = `?logout_message=${logoutType}`;
    }

    // navigate to the home route if we are in non legacy mode
    try {
      await this.auth0.logout({
        returnTo: `${window.location.origin}/auth${params}`,
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      });
    } catch (err) {
      this.login();
    }
  }
}

export default new AuthService();
