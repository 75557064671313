import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import NotificationService from '../services/NotificationService';

import * as InboxActionTypes from '../constants/InboxActionTypes';
import * as UserActionTypes from '../constants/UserActionTypes';
import * as SavedContentActionTypes from '../constants/SavedContentActionTypes';
import { setError } from './uiReducer';

// SLICE
const instagramSlice = createSlice({
  name: 'INSTAGRAM',
  initialState: {
    availablePages: [],
    instagrams: {},
    instagramIds: [],
    loading: false,
  },
  reducers: {
    receiveInstagrams: (state, action) =>
      ({
        ...state,
        instagrams: {
          ...state.instagrams,
          ...action.payload.instagrams,
        },
        instagramIds: [...state.instagramIds, ...action.payload.instagramIds],
      }),
    receivePages: (state, action) =>
      ({
        ...state,
        availablePages: action.payload,
        loading: false,
      }),
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [InboxActionTypes.receiveInboxThreadView]: receiveInstagramsData,
    [InboxActionTypes.receiveWebSocketEvent]: receiveInstagramsData,

    [UserActionTypes.receiveUsers]: receiveInstagramsData,
    [UserActionTypes.receiveUsersSearch]: receiveInstagramsData,
    [UserActionTypes.receiveContactUsers]: receiveInstagramsData,
    [UserActionTypes.receiveConnectedPartySearch]: receiveInstagramsData,
    [UserActionTypes.receiveUser]: receiveInstagramsData,
    [UserActionTypes.receiveCreateUser]: receiveInstagramsData,
    [UserActionTypes.receiveUpdateUser]: receiveInstagramsData,
    [UserActionTypes.receiveContactList]: receiveInstagramsData,

    [SavedContentActionTypes.receiveEventsForSavedContent]: receiveInstagramsData,
  },
});
export default instagramSlice.reducer;

// ACTIONS
export const { receiveInstagrams, receivePages, setLoading } = instagramSlice.actions;

// REDUCER HELPERS

function receiveInstagramsData(state, action) {
  return {
    ...state,
    instagrams: {
      ...state.instagrams,
      ...action.payload.instagrams,
    },
    instagramIds: [...state.instagramIds, ...action.payload.instagramIds],
  };
}

// THUNKS -- ASYNC ACTION CREATORS

export function authInstagram(payload) {
  return (dispatch) => { // eslint-disable-line arrow-body-style
    dispatch(setLoading(true));
    return axios.post(`${process.env.REACT_APP_V3_BASE_URL}/instagram/authenticate`, payload)
      .then((response) => {
        dispatch(receivePages(response.data?.objects));
        if (payload.instagramPageId) NotificationService('instagramAuth', response); // only show if re-authing

        return response.data?.objects;
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.error(err.response || err);

        // Custom message to get around FB's
        const customResponse = err.response || 'Something went wrong while re-authorizing this Instagram channel.';

        dispatch(setError(err.response || err));
        if (payload.instagramPageId) NotificationService('instagramAuth', customResponse); // only show toast error when re-authing
      });
  };
}
