import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Close,
  Scrollbars,
} from 'rhinostyle';

import BusinessHoursGrouped from './BusinessHoursGrouped';

const OrganizationProfilePanel = (props) => {
  const {
    channel,
    handleToggleProfile,
    organization,
  } = props;

  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title">
            Organization Summary
          </div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={handleToggleProfile}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__avatar">
                <Avatar
                  image={organization.organizationLogoUrl}
                  name={organization.name}
                  type="member"
                  size="large"
                />
              </div>
              <div className="summary-panel__intro__title">
                {organization.name}
              </div>
              {organization.businessWebsiteUrl &&
              <div className="summary-panel__intro__desc">{organization.businessWebsiteUrl}</div>}
            </div>
            <div className="summary-panel__intro-wrapper u-m-b-0">
              <div className="summary-panel__intro__title">
                {channel ? channel.name : 'All Messages'}
              </div>
              <div className="summary-panel__intro__desc">
                {channel && channel.purpose ? channel.purpose : `This is the history of all communication with ${organization.name}`}
              </div>
            </div>
            {channel && channel.businessHours && !!channel.businessHours.length && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Availability</div>
                <div className="summary-panel__section__content">
                  <BusinessHoursGrouped
                    businessHours={channel.businessHours}
                    timeZone={channel.timeZone || {}}
                  />
                </div>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

OrganizationProfilePanel.propTypes = {
  channel: PropTypes.object,
  handleToggleProfile: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

export default OrganizationProfilePanel;
