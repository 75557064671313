import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchBilling, fetchOrganizationBillingView } from '../reducers/billingReducer';
import * as StateReducer from '../reducers/stateReducer';
import OrganizationBilling from '../components/OrganizationBilling';
import PageLoader from '../components/PageLoader';
import { AppConstants } from '../constants';
import CustomError from '../helpers/CustomErrorHelper';

class OrganizationBillingContainer extends Component { //eslint-disable-line
  state = {
    activeKey: 1,
    pageLoading: true,
  };

  componentDidMount() {
    this.props.fetchStates();
    this.props.fetchBilling()
      .then(() => {
        if (this.props.billing) {
          this.props.fetchOrganizationBillingView(this.props.billing.billManagerSubId, this.props.billing.billManagerCustId, this.props.billing.billManagerPaymentId)
            .then(() => {
              this.setState({
                pageLoading: false,
              });
            });
        } else {
          this.setState({ pageLoading: false });
        }
      });
  }

  componentDidUpdate() {
    if (this.props.billingError) {
      throw new CustomError({ type: 'Billing API', message: AppConstants.BILLING_ERROR_MESSAGE });
    }
  }

  updateActiveKey = (index) => {
    this.setState({ activeKey: index });
  };

  render() {
    if (this.state.pageLoading) {
      return (<PageLoader />);
    }

    const props = {
      billing: this.props.billing,
      statements: this.props.statements,
      activeKey: this.state.activeKey,
      updateActiveKey: this.updateActiveKey,
      subscription: this.props.subscription,
      organization: this.props.organization[this.props.billing.orgId],
      draftInvoiceId: this.props.draftInvoiceId,
    };
    return <OrganizationBilling {...props} />;
  }
}

OrganizationBillingContainer.propTypes = {
  fetchBilling: PropTypes.func.isRequired,
  billing: PropTypes.object,
  statements: PropTypes.array.isRequired,
  fetchStates: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
  fetchOrganizationBillingView: PropTypes.func.isRequired,
  subscription: PropTypes.object,
  organization: PropTypes.object,
  billingError: PropTypes.bool,
  draftInvoiceId: PropTypes.number,
};

const mapStateToProps = (state) => {
  const { billing, states, organization } = state;

  return {
    billing: billing.billing,
    statements: billing.statements,
    states: states.states,
    subscription: billing.subscription,
    organization: organization.organizations,
    billingError: billing.billingError,
    draftInvoiceId: billing.draftInvoiceId,
  };
};

const actions = {
  fetchBilling,
  fetchStates: StateReducer.fetchStates,
  fetchOrganizationBillingView,
};

export default connect(mapStateToProps, actions)(OrganizationBillingContainer);
