import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import * as DataHelpers from '../helpers/DataHelpers';

import { getTags, getTagsPayload } from './tagReducer';
import * as ChannelReducer from './channelReducer';

import NotificationService from '../services/NotificationService';
import { setError } from './uiReducer';
import { outOfOffice, tag, channel } from '../actions/NormalizrSchema';

// SLICE
const outOfOfficeSlice = createSlice({
  name: 'OUTOFOFFICE',
  initialState: {
    outOfOffices: {},
    outOfOfficeIds: [],
    loading: false,
  },
  reducers: {
    receiveOOOView: receiveOutOfOfficesData,
    receiveOutOfOffices: receiveOutOfOfficesData,
    receiveCreateOutOfOffice: (state, action) =>
      ({
        ...state,
        outOfOffices: {
          ...state.outOfOffices,
          [action.payload.outOfOfficeId]: action.payload.outOfOffice[action.payload.outOfOfficeId],
        },
        outOfOfficeIds: [...new Set([action.payload.outOfOfficeId, ...state.outOfOfficeIds])],
        loading: false,
      }),
    receiveUpdateOutOfOffice: (state, action) =>
      ({
        ...state,
        outOfOffices: {
          ...state.outOfOffices,
          [action.payload.outOfOfficeId]: action.payload.outOfOffice[action.payload.outOfOfficeId],
        },
        loading: false,
      }),
    removeOutOfOffice: (state, action) =>
      ({
        ...state,
        outOfOfficeIds: [...state.outOfOfficeIds.filter((o) => o !== action.payload.outOfOfficeId)],
        loading: false,
      }),
    requestData: (state) =>
      ({
        ...state,
        loading: true,
      }),
  },
});

export default outOfOfficeSlice.reducer;

// ACTIONS
export const {
  receiveOutOfOffices,
  receiveCreateOutOfOffice,
  receiveUpdateOutOfOffice,
  removeOutOfOffice,
  requestData,
  receiveOOOView,
} = outOfOfficeSlice.actions;

// REDUCER HELPERS

function receiveOutOfOfficesData(state, action) {
  return {
    ...state,
    outOfOffices: {
      ...state.outOfOffices,
      ...action.payload.outOfOffices,
    },
    outOfOfficeIds: [...new Set([...state.outOfOfficeIds, ...action.payload.outOfOfficeIds])],
    loading: false,
  };
}

// THUNKS -- ASYNC ACTION CREATORS

export function fetchOOOFormView() {
  return (dispatch) => {
    dispatch(requestData());

    return axios.all([getOutOfOffices(), getTags(), ChannelReducer.getChannels()])
      .then(axios.spread((oooResponse, tagsResponse, channelsResponse) => {
        const normalizedOOO = normalize(oooResponse.data, [outOfOffice]);
        const normalizedTags = normalize(tagsResponse.data, [tag]);
        const normalizedChannels = normalize(channelsResponse.data, [channel]);

        dispatch(receiveOOOView(getOutOfOfficesFormViewPayload(normalizedOOO, normalizedTags, normalizedChannels)));
      }))
      .catch((err) => dispatch(handleError(err)));
  };
}

function getOutOfOffices() {
  return axios.get('/outofoffices')
    .catch(console.error);
}

export function fetchOutOfOffices() {
  return (dispatch) => {
    dispatch(requestData());

    return axios.get('/outofoffices')
      .then((response) => {
        const normalized = normalize(response.data, [outOfOffice]);

        dispatch(receiveOutOfOffices(getOutOfOfficesPayload(normalized)));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function createOutOfOffice(payload) {
  return (dispatch) =>
    axios.post('/outofoffices', payload)
      .then((response) => {
        NotificationService('createOutOfOffice', response);
        const normalized = normalize(response.data, outOfOffice);
        dispatch(receiveCreateOutOfOffice(getOutOfOfficePayload(normalized)));

        dispatch(setError(null));

        return response.data; // used for pushing route
      })
      .catch((err) => {
        console.error(err.response || err);
        NotificationService('createOutOfOffice', err.response);
        dispatch(setError(err.response || err));
      });
}

export function updateOutOfOffice(outOfOfficeId, payload) {
  return (dispatch) => new Promise((resolve, reject) => {
    axios.patch(`/outofoffices/${outOfOfficeId}`, payload)
      .then((response) => {
        NotificationService('updateOutOfOffice', response);
        const normalized = normalize(response.data, outOfOffice);

        dispatch(receiveUpdateOutOfOffice(getOutOfOfficePayload(normalized)));

        dispatch(setError(null));
        resolve();
      })
      .catch((err) => {
        console.error(err.response || err);
        NotificationService('updateOutOfOffice', err.response);
        dispatch(setError(err.response || err));
        reject();
      });
  });
}

export function destroyOutOfOffice(outOfOfficeId) {
  return (dispatch) =>
    axios.delete(`/outofoffices/${outOfOfficeId}`)
      .then((response) => {
        NotificationService('destroyOutOfOffice', response);
        dispatch(removeOutOfOffice({ outOfOfficeId }));
      })
      .catch((err) => {
        console.error(err.reponse);
        NotificationService('destroyOutOfOffice', err.response);
        dispatch(setError(err.response || err));
      });
}

// Helpers
function handleError(err) {
  return (dispatch) => {
    const error = err.response || err;

    console.error(error);

    dispatch(setError(error));
  };
}

function getOutOfOfficesPayload(normalizedOutOfOffices) {
  return {
    outOfOffices: {
      ...normalizedOutOfOffices.entities.outOfOffices,
    },
    outOfOfficeIds: normalizedOutOfOffices.result,
    channels: {
      ...normalizedOutOfOffices.entities.channels,
    },
    channelIds: DataHelpers.getObjectKeys(normalizedOutOfOffices.entities.channels),
    tags: {
      ...normalizedOutOfOffices.entities.tags,
    },
    tagIds: DataHelpers.getObjectKeys(normalizedOutOfOffices.entities.tags),
  };
}

function getOutOfOfficePayload(normalizedOutOfOffice) {
  return {
    outOfOffice: normalizedOutOfOffice.entities.outOfOffices,
    outOfOfficeId: normalizedOutOfOffice.result,
  };
}

function getOutOfOfficesFormViewPayload(normalizedOOOs, normalizedTags, normalizedChannels) {
  return {
    ...getOutOfOfficesPayload(normalizedOOOs),
    ...getTagsPayload(normalizedTags),
    ...ChannelReducer.getChannelsPayload(normalizedChannels),
  };
}
