import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownMenuItem,
  Dropdown,
} from 'rhinostyle';
import { MetaTypeService } from 'rhinotilities/lib/core/services/MetaTypeService';

const ConsentStatusDropdown = ({
  disabled,
  dropdownClass,
  handleChange,
  label,
  name,
  value,
}) => {
  const consentStatuses = useMemo(() => {
    const statuses = name === 'hipaaStatus'
      ? MetaTypeService.hipaaConsentStatuses
      : MetaTypeService.marketingConsentStatuses;
    return statuses ? Array.from(statuses) : [];
  }, [name]);

  const renderConsentStatus = ([statusTypeId, status]) => (
    <DropdownMenuItem
      id={statusTypeId}
      label={status}
      key={statusTypeId}
      onClick={() => value !== statusTypeId && handleChange(name, statusTypeId)}
    />
  );
  return (
    <Dropdown
      activeKey={value}
      label={label}
      type={value ? 'primary' : 'input'}
      outlined={!!value}
      className={dropdownClass}
      disabled={disabled}
      name={name}
      dataFeatureTag={`${name}-status-dropdown`}
    >
      {consentStatuses.map(renderConsentStatus)}
    </Dropdown>
  );
};
ConsentStatusDropdown.propTypes = {
  disabled: PropTypes.bool,
  dropdownClass: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
};
export default ConsentStatusDropdown;
