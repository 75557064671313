import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import cx from 'classnames';
import moment from 'moment-timezone';
import { JobType } from 'rhinotilities/lib/core/enums/JobType';

import {
  LocationHelpers,
} from '../helpers';
import PageLoader from '../components/PageLoader';
import ThreadPanelWrapper from '../components/ThreadPanelWrapper';
import InboxThreadCompose from '../components/InboxThreadCompose';
import ThreadScrollWrapper from '../components/ThreadScrollWrapper';
import { fetchCompleteThreadView, handleSendMessage } from '../reducers/inboxReducer';
import InboxThreadSavedContentForm from '../components/InboxThreadSavedContentForm';
import InboxThreadHeader from '../components/InboxThreadHeader';
import * as ChatReducer from '../reducers/chatReducer';
import {
  getGroupUserDataLoaded,
} from '../selectors/chatSelectors';
import { getLoggedInUser, getActiveUser, getLoggedInUserOrganization, userHasLimitedProviderRole } from '../selectors/userSelectors';
import { getThreadFromChannelOptions } from '../selectors/inboxSelectors';
import ChatThreadCompose from '../components/ChatThreadCompose';
import HIPAAConsentMessagingModal from '../components/HIPAAConsentMessagingModal';
import ChatThreadHeader from '../components/ChatThreadHeader';
import ThreadModalWrapper from '../components/ThreadModalWrapper';
import { useThreadEvents, useUpdateRead, useThreadOptions, usePrevious } from '../hooks';
import threadService from '../services/threadService';
import ThreadFilterHeader from '../components/ThreadFilterHeader';
import EmptyMessage from '../components/EmptyMessage';
import { replaceInboxVariablesByValue } from '../helpers/StringHelpers';
import { TYPE_HIPAA_CONSENT_PENDING, TYPE_HIPAA_CONSENT_DECLINED } from '../constants/Types';
import { getUserById } from '../reducers/userReducer';

const ThreadView = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const isInboxThread = location.pathname.includes('inbox');
  const [loading, setLoading] = useState(true);
  const [isOpenHIPAAModal, setIsOpenHIPAAModal] = useState(false);
  const threadOptions = useThreadOptions();
  useUpdateRead({ loading });
  const {
    isSelectionModeEnabled,
    pageLoading,
    profileOpen,
    userGroupDataLoaded,
    isCcr,
    messageTemplates,
    userOrganization,
    activeUser,
    currentUser,
    phones,
    isLimitedProvider,
    activeFromChannelId,
    threadFromChannelOptions,
    channels,
  } = props;
  const userId = Number(params.userId) || null;
  const groupId = Number(params.groupId) || null;
  const previousActiveUser = usePrevious(activeUser);
  const previousPhones = usePrevious(phones);

  const locationOptions = LocationHelpers.getInboxOptions(params, location);
  const { activePanel: locationActivePanel } = LocationHelpers.getQueryParams(location.search);
  if (location.state?.pageNo > -1) {
    locationOptions.pageNo = location.state.pageNo;
  }
  const query = useThreadEvents();
  useEffect(() => {
    setLoading(true);
    if (isInboxThread) {
      dispatch(threadService.util.invalidateTags([{ type: 'Thread', id: userId }]));
      dispatch(fetchCompleteThreadView({ locationOptions, userId, isCcr, locationActivePanel }));
    } else {
      fetchInitialChatThreadData();
    }
  }, [params.userId, params.groupId]);

  useEffect(async () => {
    const userData = await getUserById(userId);
    const user = userData?.data;
    const activeUserAge = user?.birthday ? moment().diff(user?.birthday, 'years', false) : null;
    const isMinor = activeUserAge ? activeUserAge < 18 : false;
    const isHIPAAStatusUpdated = previousActiveUser ?
      previousActiveUser?.id === activeUser?.id && previousActiveUser?.hipaaStatus?.typeId !== activeUser?.hipaaStatus?.typeId : true;
    const doesPreviousActiveUserOwnAnyPhone = previousActiveUser?.phones?.some((p) => Number(previousPhones[p]?.ownerId) === Number(previousActiveUser.id));
    const doesActiveUserOwnAnyPhone = user?.phones?.some((p) => Number(p?.ownerId) === Number(user.id));
    const isPhoneOwnerUpdated = previousActiveUser ? doesPreviousActiveUserOwnAnyPhone !== doesActiveUserOwnAnyPhone : true;
    if (previousActiveUser?.id !== activeUser?.id || (isHIPAAStatusUpdated || isPhoneOwnerUpdated)) {
      const showBlockHIPAAModal = userOrganization?.disableMessagingHIPAAConsent && !(userOrganization?.isMessagingForMinorsDisabled && isMinor) &&
        [TYPE_HIPAA_CONSENT_PENDING, TYPE_HIPAA_CONSENT_DECLINED].includes(user?.hipaaStatus?.typeId) &&
        doesActiveUserOwnAnyPhone;
      if (showBlockHIPAAModal) {
        setIsOpenHIPAAModal(true);
      }
    }
  }, [activeUser?.id, activeUser?.hipaaStatus?.typeId, activeUser?.phones]);

  useEffect(() => {
    if (!pageLoading) {
      setLoading(pageLoading);
    }
  }, [pageLoading]);

  function fetchInitialChatThreadData() {
    const chatThreadOptions = {
      userId,
      groupId,
    };
    if (location.state?.pageNo > -1) {
      chatThreadOptions.pageNo = location.state.pageNo;
    }
    dispatch(ChatReducer.fetchCompleteChatThreadView(chatThreadOptions));
  }

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': profileOpen,
    'app-panels--hide-icons-desktop': !isInboxThread,
  });

  if (loading || !userGroupDataLoaded || query.isLoading || pageLoading) {
    return <PageLoader />;
  }

  const handleCloseModal = () => {
    setIsOpenHIPAAModal(false);
  };

  const handleSendHIPAAConsentMessage = () => {
    const HIPAAMessageText = messageTemplates.find((template) => template.subject === 'HIPAA Consent Request')?.message;
    const formattedMessage = replaceInboxVariablesByValue(HIPAAMessageText, {
      activeUser,
      currentUser,
      userOrganization,
    });
    dispatch(handleSendMessage({ messageText: formattedMessage, threadOptions, hipaaManualRequestType: JobType.HipaaManualRequest }));
    setIsOpenHIPAAModal(false);
  };

  const renderHeader = () => {
    if (isInboxThread) {
      return <InboxThreadHeader />;
    } return <ChatThreadHeader />;
  };

  const renderComposeArea = () => {
    if (isInboxThread) {
      return isSelectionModeEnabled ? <InboxThreadSavedContentForm /> : <InboxThreadCompose />;
    } return <ChatThreadCompose />;
  };

  if (query.status === 'rejected') {
    return <EmptyMessage section="Restricted" />;
  }

  return (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="convo__inner">
              {renderHeader()}
              {!!isInboxThread && (
                <ThreadFilterHeader />
              )}
              <ThreadScrollWrapper isInboxThread={isInboxThread} />
              {renderComposeArea()}
            </div>
          </div>
        </div>
        <ThreadPanelWrapper />
      </div>
      <ThreadModalWrapper />
      <HIPAAConsentMessagingModal
        isOpenHIPAAModal={isOpenHIPAAModal}
        handleSendHIPAAConsentMessage={handleSendHIPAAConsentMessage}
        handleCloseModal={handleCloseModal}
        threadFromChannelOptions={threadFromChannelOptions}
        isLimitedProvider={isLimitedProvider}
        defaultChannelId={activeFromChannelId}
        channels={channels}
      />
    </div>
  );
};

ThreadView.propTypes = {
  isSelectionModeEnabled: PropTypes.bool.isRequired, // Governs state of selection mode on/off.
  pageLoading: PropTypes.bool,
  profileOpen: PropTypes.bool,
  userGroupDataLoaded: PropTypes.bool,
  isCcr: PropTypes.bool,
  messageTemplates: PropTypes.array,
  activeUser: PropTypes.object,
  userOrganization: PropTypes.object,
  currentUser: PropTypes.object,
  phones: PropTypes.object,
  isLimitedProvider: PropTypes.bool,
  activeFromChannelId: PropTypes.number,
  threadFromChannelOptions: PropTypes.array,
  channels: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  const {
    inbox,
    thread,
    chat,
    messageTemplate,
    phone,
    channel,
  } = state;
  return {
    userGroupDataLoaded: getGroupUserDataLoaded(state, props),
    isSelectionModeEnabled: state.savedContent.isSelectionModeEnabled,
    pageLoading: props.location.pathname.includes('inbox') ? inbox.pageLoading : chat.pageLoading,
    profileOpen: thread.profileOpen,
    isCcr: getLoggedInUser(state)?.isCcr,
    organizationId: state.auth.currentOrg,
    messageTemplates: messageTemplate.messageTemplates,
    activeUser: getActiveUser(state),
    userOrganization: getLoggedInUserOrganization(state),
    currentUser: getLoggedInUser(state),
    phones: phone.phones,
    isLimitedProvider: userHasLimitedProviderRole(state),
    activeFromChannelId: inbox.activeFromChannelId,
    threadFromChannelOptions: getThreadFromChannelOptions(state),
    channels: channel.channels,
  };
};

export default connect(mapStateToProps)(ThreadView);
