/* eslint-disable guard-for-in */
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import * as queryString from 'query-string';

import MessagingAnalytics from '../components/MessagingAnalytics';
import { capitalize } from '../helpers/StringHelpers';
import { cloneDeep, exists, hasData } from '../helpers/DataHelpers';
import { AnalyticsHelpers } from '../helpers';
import { fetchAnalyticsData } from '../reducers/analyticsReducer';
import { doughnutChartConfig, mixLineChartConfig, stackedBarChartConfig } from '../configs/Analytics.config';
import { SECONDS_TO_MINUTES } from '../constants/AppConstants';

class MessagingAnalyticsContainer extends Component {
  minDate = moment().subtract(3, 'years');
  maxDate = moment().subtract(1, 'days');

  state = {
    startDate: moment().subtract(30, 'days'),
    endDate: moment().subtract(1, 'days'),
    activeKey: 3,
    isOpen: true,
    incoming: true,
    outgoing: true,
    automated: true,
    datePickerDropdownLabels: [{ id: 1, label: 'Yesterday', duration: 1 }, { id: 2, label: 'Last 7 Days', duration: 7 },
      { id: 3, label: 'Last 30 Days', duration: 30 }, { id: 4, label: 'Last 90 Days', duration: 90 },
      { id: 5, label: 'Last 12 Months', duration: 'year' }, { id: 6, label: 'Custom Date' }],
  };

  componentDidMount() {
    const fromDate = queryString.parse(this.props.location.search).from || this.state.startDate;
    const endDate = queryString.parse(this.props.location.search).to || this.state.endDate;
    const activeKey = +queryString.parse(this.props.location.search).activeKey || +this.state.activeKey;
    this.props.fetchAnalyticsData(fromDate, endDate);
    this.setState({ startDate: moment(fromDate), endDate: moment(endDate), activeKey });
  }

  getContactChartData = (contactData) => {
    if (!exists(contactData)) {
      return {};
    }
    const config = cloneDeep(doughnutChartConfig);
    if (contactData.total === 0) {
      config.data = {};
      return config;
    }
    config.data.labels = Object.keys(contactData.contacts).map((l) => capitalize(l));
    config.data.datasets[0].data = Object.values(contactData.contacts);
    config.total = contactData.total;
    config.responseRate = contactData.responseRate !== null ? Math.round(contactData.responseRate) : null;
    config.options.tooltips = {
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.labels[tooltipItem.index] || '';
          if (label) {
            label += ': ';
          }
          label = `${label + data.datasets[0].data[tooltipItem.index]} Contacts`;
          return label;
        },
      },
    };
    return config;
  };

  getMessageChartData = (messages) => {
    const config = cloneDeep(stackedBarChartConfig);

    if (!exists(messages) || !messages.length) {
      config.data = {};
      return config;
    }

    let dateFormat = 'MMM-D';
    const duration = this.state.endDate.diff(this.state.startDate, 'days') + 1;
    if (duration === 1) {
      dateFormat = 'h a';
    } else if (duration === 2 || duration === 3) {
      config.options.scales.xAxes[0].barThickness = 73;
    } else if (duration > 30 && duration <= 90) {
      messages = AnalyticsHelpers.groupMessageTypeByDays(messages, 5); // eslint-disable-line no-param-reassign
    } else if (duration > 90 && duration <= 366) {
      dateFormat = 'MMM YY';
      messages = AnalyticsHelpers.groupByMessageType(messages, dateFormat); // eslint-disable-line no-param-reassign
    } else if (duration > 365) {
      dateFormat = '[Q]Q-YY';
      messages = AnalyticsHelpers.groupByMessageType(messages, dateFormat); // eslint-disable-line no-param-reassign
    }

    config.options.tooltips.callbacks.title = (tooltipItem) => AnalyticsHelpers.getTooltipTitleForMessageGraph(messages, tooltipItem, duration, this.state);
    config.options.scales.xAxes[0].ticks.callback = (value, index) => AnalyticsHelpers.getTicksValuesForMessageGraph(value, index, duration, messages);

    const time = [];
    let totalMessages = 0;
    let totalIncoming = 0;
    let totalOutgoing = 0;
    let totalAutomated = 0;

    const incomingCount = [];
    const outgoingCount = [];
    const automatedCount = [];
    for (const i in messages) { // eslint-disable-line
      totalIncoming += messages[i].incomingCount || 0;
      totalOutgoing += messages[i].outgoingCount || 0;
      totalAutomated += messages[i].automatedCount || 0;

      time.push(moment(messages[i].date, 'YYYY-MM-DD HH:mm:ss').format(dateFormat));
      outgoingCount.push(messages[i].outgoingCount || 0);
      incomingCount.push(messages[i].incomingCount || 0);
      automatedCount.push(messages[i].automatedCount || 0);
    }
    config.data.labels = time;
    config.data.datasets[0].label = `Incoming (${totalIncoming})`;
    config.data.datasets[1].label = `Outgoing Manual (${totalOutgoing})`;
    config.data.datasets[2].label = `Outgoing Automated (${totalAutomated})`;
    totalMessages = (this.state.incoming ? totalIncoming : 0) + (this.state.outgoing ? totalOutgoing : 0) + (this.state.automated ? totalAutomated : 0);
    config.totalMessages = totalMessages;
    config.average = Math.round(totalMessages / duration);
    config.data.datasets[2].data = automatedCount;
    config.data.datasets[1].data = outgoingCount;
    config.data.datasets[0].data = incomingCount;
    config.options.scales.xAxes[0].stacked = true;
    config.options.scales.yAxes[0].stacked = true;
    const labels = ['Incoming', 'Outgoing Manual', 'Outgoing Automated'];
    config.options.tooltips.callbacks.label = (tooltipItems) => `${labels[tooltipItems.datasetIndex]}: ${tooltipItems.value}`;
    config.options.tooltips.callbacks.labelColor = (tooltipItems) => {
      const dataset = config.data.datasets[tooltipItems.datasetIndex];
      return { backgroundColor: dataset.backgroundColor };
    };
    config.onLegendClick = this.onLegendClick;
    return config;
  };

  onLegendClick = (e, legendItem) => {
    const messageTypes = ['incoming', 'outgoing', 'automated'];
    const { hidden, datasetIndex } = legendItem;
    const selectedMessageType = messageTypes[datasetIndex];
    this.setState({ [selectedMessageType]: hidden });
  }

  getResponseTimeData = (responseData) => {
    if (!exists(responseData) || !hasData(responseData)) {
      return {};
    }
    const config = cloneDeep(mixLineChartConfig);
    if (!responseData.responseTime.length) {
      config.data = {};
      return config;
    }
    const { responseTime } = responseData;
    let dateFormat = 'MMM-D';
    const duration = moment(this.state.endDate).diff(moment(this.state.startDate), 'days') + 1;
    if (duration === 1) {
      dateFormat = 'h a';
    } else if (duration > 90 && duration <= 366) {
      dateFormat = 'MMM YY';
    } else if (duration > 365) {
      dateFormat = '[Q]Q-YY';
    }
    config.data.datasets[1].label = 'Response Time (Mins.)';
    config.data.datasets[0].label = 'Average (Mins.)';
    config.data.labels = responseTime.map((t) => moment(t.timestamp, 'YYYY-MM-DD HH:mm:ss').format(dateFormat));
    config.data.datasets[1].data = responseTime.map((t) => Math.round(t.averageTime / SECONDS_TO_MINUTES));
    config.data.datasets[0].data = new Array(responseTime.length).fill(Math.round(responseData.average / SECONDS_TO_MINUTES));
    config.average = Math.round(responseData.average / SECONDS_TO_MINUTES);
    config.options.scales.yAxes[0].scaleLabel = {
      display: true,
      labelString: 'Minutes',
      fontColor: '#9F9F9F',
    };

    const tickValues = [];
    config.data.labels.map((label, index) => {
      const valueAvailable = tickValues.filter((value) => value.label === label);
      if (!valueAvailable.length) {
        tickValues.push({ label, index });
      }
      return label;
    });

    config.options.scales.xAxes[0].ticks.callback = (value, index) => AnalyticsHelpers.getTicksForResponseGraph(value, index, duration, tickValues);
    config.options.tooltips.callbacks.title = (tooltipItem) => AnalyticsHelpers.getTooltipTitleForResponseGraph(tooltipItem, duration, responseTime);
    return config;
  };

  getPeakTimeData = (peakTime) => {
    if (!exists(peakTime)) {
      return {};
    }
    const config = cloneDeep(mixLineChartConfig);
    if (!Object.keys(peakTime).length) {
      config.data = {};
      return config;
    }
    const data = Object.values(peakTime);
    const average = Math.round(data.reduce((a, b) => a + b, 0) / data.length);
    config.data.datasets[1].label = 'Total (Msgs)';
    config.data.datasets[0].label = 'Average (Msgs)';
    config.data.datasets[1].data = Object.values(peakTime);
    config.data.labels = Object.keys(peakTime);
    config.data.datasets[0].data = new Array(data.length).fill(average);
    config.data.datasets[1].borderColor = '#FF8300';
    config.data.datasets[1].backgroundColor = '#FFEDD9';
    config.data.datasets[1].pointBorderColor = '#FF8300';
    config.data.datasets[1].pointBackgroundColor = '#FFEDD9';
    config.options.scales.xAxes[0].type = 'time';
    config.options.scales.xAxes[0].time = {
      parser: 'HH:mm',
      unit: 'hour',
      stepSize: 3,
      displayFormats: {
        hour: 'h a',
        min: 1,
        max: 24,
      },
      tooltipFormat: 'h a',
    };
    config.options.scales.yAxes[0].scaleLabel = {
      display: true,
      labelString: 'Messages',
      fontColor: '#9F9F9F',
      position: 'top',
    };
    const peakHour = Object.keys(peakTime)[data.indexOf(Math.max(...data))];
    config.header = `${moment(peakHour, 'H').format('h:mm a')} - ${moment(+peakHour + 1, 'H').format('h:mm a')}`;
    return config;
  };

  selectDate = (date) => {
    this.setState({
      startDate: date.startDate,
      endDate: date.endDate,
      activeKey: date.activeKey,
      incoming: true,
      outgoing: true,
      automated: true,
    });
    this.props.fetchAnalyticsData(date.startDate.format('YYYY-MM-DD'), date.endDate.format('YYYY-MM-DD'));
    this.props.history.push(`/analytics/messaging?from=${date.startDate.format('YYYY-MM-DD')}&to=${date.endDate.format('YYYY-MM-DD')}&activeKey=${date.activeKey}`);
  };

  calculateDateRange = (duration) => {
    let fromDate = moment().subtract(duration, 'days');
    const toDate = moment().subtract(1, 'days');

    if (duration === 'year') {
      fromDate = moment().subtract(1, 'years');
    }
    return { startDate: fromDate, endDate: toDate };
  }

  render() {
    const props = {
      activeKey: this.state.activeKey,
      contactChartData: this.getContactChartData(this.props.contacts),
      endDate: this.state.endDate,
      maxDate: this.maxDate,
      minDate: this.minDate,
      isOpen: this.state.isOpen,
      messageChartData: this.getMessageChartData(this.props.messages),
      responseTimeChartData: this.getResponseTimeData(this.props.responseTime),
      selectDate: this.selectDate,
      startDate: this.state.startDate,
      pageLoading: this.props.pageLoading,
      peakTimeChartData: this.getPeakTimeData(this.props.peakTime),
      handleToggle: this.handleToggle,
      datePickerDropdownLabels: this.state.datePickerDropdownLabels,
      calculateDateRange: this.calculateDateRange,
    };
    return <MessagingAnalytics {...props} />;
  }
}

MessagingAnalyticsContainer.propTypes = {
  fetchAnalyticsData: PropTypes.func,
  location: PropTypes.object,
  messages: PropTypes.array,
  contacts: PropTypes.object,
  peakTime: PropTypes.object,
  responseTime: PropTypes.object,
  pageLoading: PropTypes.bool,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { analytics } = state;
  return {
    messages: analytics.messages,
    contacts: analytics.contacts,
    peakTime: analytics.peakTime,
    responseTime: analytics.responseTime,
    pageLoading: analytics.pageLoading,
  };
};

const actions = {
  fetchAnalyticsData,
};

export default connect(mapStateToProps, actions)(MessagingAnalyticsContainer);
