import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  UtilityInlineGrid,
} from 'rhinostyle';

import { userHasAnyOfPermissions } from '../helpers/UserHelpers';

const FormActionButtons = (props) => {
  const {
    handleConfirmDeleteRequest,
    handleSubmit,
    formInProgress,
    shortName,
    name = '',
    editMode,
    deletePermissions,
    editPermissions,
    dataCypress,
    dataFeatureTag,
  } = props;
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const renderDeleteConfirm = () => (
    <div className="item-status__confirm">
      <div className="item-status__confirm__text">
        <Icon bump="up" icon="trash" /> Are you sure you want to delete this {name.toLowerCase()}?
      </div>
      <UtilityInlineGrid size="regular" align="middle">
        <Button size="small" type="link-muted" onClick={() => setIsDeleteConfirmVisible(false)}>
          Cancel
        </Button>
        <Button loading={formInProgress} size="small" type="danger" onClick={handleConfirmDeleteRequest} data-feature-tag="confirmDelete">
          Yes, delete {(shortName || name).toLowerCase()}
        </Button>
      </UtilityInlineGrid>
    </div>
  );

  return (isDeleteConfirmVisible ? renderDeleteConfirm() : (
    <div className="item-status">
      {userHasAnyOfPermissions(deletePermissions) && editMode && (
        <div className="item-status__left">
          <Button
            reset
            data-cypress={`${dataCypress}Delete`}
            data-feature-tag={`${dataFeatureTag}delete`}
            onClick={() => setIsDeleteConfirmVisible(true)}
            className="item-status__delete-icon"
            title={`Delete ${name}`}
          >
            <Icon bump="down" size="large" icon="trash" />
          </Button>
        </div>
      )}
      <div className="item-status__right">
        <Button
          loading={formInProgress}
          onClick={handleSubmit}
          type="primary"
          disabled={!userHasAnyOfPermissions(editPermissions)}
          data-cypress={`${dataCypress}Save`}
          data-feature-tag={`${dataFeatureTag}save`}
        >
          {!userHasAnyOfPermissions(editPermissions) && <><Icon icon="lock" />&nbsp;</>}
          {editMode ? 'Save Changes' : `Create ${name}`}
        </Button>
      </div>
    </div>
  ));
};

FormActionButtons.propTypes = {
  formInProgress: PropTypes.bool,
  editMode: PropTypes.bool,
  handleSubmit: PropTypes.func,
  name: PropTypes.string,
  shortName: PropTypes.string,
  handleConfirmDeleteRequest: PropTypes.func,
  deletePermissions: PropTypes.array,
  editPermissions: PropTypes.array,
  dataCypress: PropTypes.string,
  dataFeatureTag: PropTypes.string,
};

export default FormActionButtons;
