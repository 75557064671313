import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';

import OrganizationChannelForm from '../components/OrganizationChannelForm';
import unsavedChanges from '../components/UnsavedChangesHOC';
import * as ChannelReducer from '../reducers/channelReducer';
import * as RhinocallReducer from '../reducers/rhinocallReducer';
import { normalizePhone, formatPhone } from '../helpers/PhoneHelpers';
import { hasData } from '../helpers/DataHelpers';
import { ScrollHelpers, ValidationHelpers, AddonHelpers, DataHelpers } from '../helpers';
import CustomError from '../helpers/CustomErrorHelper';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { getAutomatedMessageChannelIds, getRhinocallChannelIds } from '../selectors/channelSelectors';
import { CHANNEL_PRODUCT, WIDGET_BASE_URL, BILLING_ERROR_MESSAGE } from '../constants/AppConstants';
import { TYPE_INSTAGRAM, TYPE_SMS, TYPE_FACEBOOK, TYPE_RHINOGRAM, TYPE_SECURE, TYPE_CELL, TYPE_WORK, TYPE_TWILIO } from '../constants/Types'; // eslint-disable-line
import { isPhoneChannelTypeId } from '../helpers/ChannelHelpers';

class OrganizationChannelFormContainer extends Component {
  state = {
    activeTagIds: [],
    afterHoursEnabled: false,
    allowReroute: true,
    autoResponse: '',
    bandwidthForwardingNumber: '',
    bandwidthForwardingNumberId: null,
    bandwidthId: null,
    bandwidthNumber: '',
    bandwidthNumberId: '',
    copyEmbedCodeText: 'Copy Embed Code',
    facebookPageAccessToken: '',
    facebookPageId: '',
    instagramPageId: '',
    instagramAccountId: '',
    facebookPageName: '',
    businessHours: [],
    channelName: '',
    channelPurpose: '',
    channelType: '',
    channelPhoneId: -1,
    editChannelId: null,
    errors: {},
    existingNumber: false,
    formInProgress: false,
    landlineNumber: '',
    twilioNumber: '',
    mode: 'create',
    modalFormInProgress: false,
    observesDst: true,
    route: {},
    selectedRouteMemberId: -1,
    selectedRouteGroupId: -1,
    showDeleteChannelModal: false,
    timeZoneId: null,
    isWarning: false,
    fee: 0,
    isRhinocallEnabled: false,
    isWebWidgetEnabled: false,
    widgetActiveSection: 'text',
    webFormId: null,
    isWebFormTitleBackgroundColorPickerVisible: false,
    isWebFormButtonBackgroundColorPickerVisible: false,
    isWebFormTitleTextColorPickerVisible: false,
    isWebFormButtonTextColorPickerVisible: false,
    widgetConfig: {
      text: {
        formTitle: 'Get in touch',
        titleSubtext: 'Text or call us at XXX-XXX-XXXX, or ask a question below.',
        phonePlaceholder: 'Enter phone number',
        phoneHelpText: 'This will be used in response to your question',
        messagePlaceholder: 'Enter your question here...',
        submitButton: 'Send Message',
        calltoActionButton: 'Get in touch',
        confirmationText: 'Your message has been submitted successfully! We will be in touch soon.',
      },
      color: {
        titleBackground: '#0075c9',
        titleText: '#ffffff',
        buttonBackground: '#0075c9',
        buttonText: '#ffffff',
      },
      embedForm: {
        position: 'topRightCorner',
        hideCTAButton: false,
      },
    },
    provisionServiceOptions: [
      { id: TYPE_TWILIO, value: 'Twilio' },
      { id: TYPE_SMS, value: 'Bandwidth' },
    ],
    provisionServiceType: TYPE_SMS,
  };

  componentDidMount() {
    this.props.fetchChannelsView();
    this.props.fetchCallOrgConfig(this.props.currentUser.organization);
  }

  componentDidUpdate(prevProps) {
    const editChannelId = Number(this.props.match.params.channelId);
    const editChannel = this.props.channels[editChannelId];
    const hasSetChannel = this.state.editChannelId !== null;
    const { billingError, pageLoading } = this.props;
    const { channels } = prevProps;
    if (this.state.formInProgress && Object.keys(channels).length !== Object.keys(this.props.channels).length) {
      this.setState({ formInProgress: false }); // eslint-disable-line react/no-did-update-set-state
      this.handleFormSuccess();
    } else if (DataHelpers.hasData(this.props.error) && this.state.formInProgress) {
      this.setState({ formInProgress: false }); // eslint-disable-line react/no-did-update-set-state
    }

    if (editChannel && !hasSetChannel && !pageLoading) {
      const {
        afterHours,
        allowReroute,
        autoResponse,
        businessHours,
        details,
        name,
        observesDst,
        purpose,
        route,
        tags,
        timeZoneId,
        typeId,
        isWebWidgetEnabled,
        widgetConfig,
        webFormId,
        isRhinocallEnabled,
      } = editChannel;

      // Our type system is not consistent.
      let channelType = prevProps.types[typeId].value;
      channelType = channelType === 'twilio' ? 'landline' : channelType;
      const phone =
      isPhoneChannelTypeId(typeId) &&
        formatPhone(this.props.phones[details.phone].value);
      const forwardingPhone =
        typeId === TYPE_SMS && details.forwardingPhone &&
        formatPhone(this.props.phones[details.forwardingPhone].value);
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        activeTagIds: tags,
        afterHoursEnabled: afterHours,
        allowReroute,
        autoResponse: autoResponse || '',
        businessHours,
        channelName: name,
        channelPurpose: purpose,
        channelPhoneId: details?.phone,
        channelType,
        editChannelId,
        isRhinocallEnabled,
        mode: 'edit',
        observesDst,
        selectedRouteMemberId: route && route.userId ? route.userId : -1,
        selectedRouteGroupId: route && route.groupId ? route.groupId : -1,
        timeZoneId,
        ...(typeId === TYPE_TWILIO) && {
          landlineNumber: phone,
          provisionServiceType: typeId,
        },
        ...typeId === TYPE_FACEBOOK && {
          facebookPageAccessToken: details.pageAccessToken,
          facebookPageId: details.facebookPageId,
          facebookPageName: details.name,
        },
        ...typeId === TYPE_SMS && {
          bandwidthForwardingNumber: forwardingPhone,
          bandwidthForwardingNumberId: details.forwardingPhone,
          bandwidthId: details.id,
          bandwidthNumber: phone,
          bandwidthNumberId: details.bandwidthNumberId,
          existingNumber: details.existingNumber,
        },
        ...typeId === TYPE_SMS && details.existingNumber && {
          landlineNumber: phone,
          provisionServiceType: TYPE_SMS,
          bandwidthNumberId: details.bandwidthNumberId,
          existingNumber: details.existingNumber,
        },
        isWebWidgetEnabled: !!isWebWidgetEnabled,
        widgetConfig: !widgetConfig ? this.state.widgetConfig : widgetConfig,
        webFormId: webFormId === null ? uuidv4().toString() : webFormId,
        ...typeId === TYPE_INSTAGRAM && {
          instagramPageAccessToken: details.pageAccessToken,
          instagramPageId: details.pageId,
          instagramPageName: details.name,
        },
      });
    }

    if (billingError) {
      throw new CustomError({ type: 'Billing API', message: BILLING_ERROR_MESSAGE });
    }
  }

  handleSubmit = () => {
    const { mode } = this.state;

    if (mode === 'create') {
      this.createChannel(this.shapeChannel);
    } else if (mode === 'edit') {
      this.updateChannel(this.shapeChannel);
    }
  };

  handleConfirmDeleteRequest = () => {
    const { editChannelId } = this.state;

    this.setState({ modalFormInProgress: true });

    this.props.destroyChannel(editChannelId).then(() => this.handleFormSuccess());
  };

  handleFormSuccess() {
    this.props.resetFormChanges();
    this.props.history.push('/settings/organization/channels');
  }

  handleFacebookPageSelect = (fbData) => {
    this.setState({
      facebookPageAccessToken: fbData.pageAccessToken,
      facebookPageId: fbData.facebookPageId,
      facebookPageName: fbData.name,
      instagramAccountId: fbData.instagramAccountId,
    });
  }

  handleInstagramPageSelect = (igData) => {
    this.setState({
      instagramPageAccessToken: igData.pageAccessToken,
      instagramPageId: igData.pageId,
      instagramPageName: igData.name,
      instagramAccountId: igData.instagramAccountId,
    });
  }

  updateChannel(channelPayload) {
    const hasError = this.validateForm();
    const { editChannelId } = this.state;

    if (!hasError) {
      this.setState({ formInProgress: true });

      this.props.updateChannel(editChannelId, channelPayload).then(() => {
        if (hasData(this.props.error)) {
          this.setState({ formInProgress: false });
        } else {
          this.handleFormSuccess();
        }
      });
    }
  }

  createChannel(channelPayload) {
    const hasError = this.validateForm();

    if (!hasError) {
      this.setState({ formInProgress: true });
      this.props.createChannel(this.getChannelType(), channelPayload);
    }
  }

  handleModalReverseComplete = () => {
    this.setState({
      modalFormInProgress: false,
    });
  }

  // Run validation, set errors on state, returns true if errors exist
  validateForm() {
    const errors = ValidationService(this.validationChannelShaper());
    const hasError = Object.keys(errors).length > 0;

    // Forcing widget text section to be shown (won't impact anything if it's hidden by toggle)
    // just in-case there's any errors within that section; since all are required.
    // We should think about how to handle collapsible sections like this in the future better
    if (hasError) {
      this.setState({
        widgetActiveSection: 'text',
        errors,
      }, () => ValidationHelpers.handleValidationErrors(errors, this.pageContainer));
    }
    return hasError;
  }

  validationChannelShaper() {
    const { channelType } = this.state;
    let channelShaped;

    if (channelType === 'landline') {
      channelShaped = ValidationShapers.shapeLandlineChannel({ ...this.state });
    } else if (channelType === 'sms') {
      channelShaped = ValidationShapers.shapeBandwidthChannel({ ...this.state });
    } else if (channelType === 'facebook') {
      channelShaped = ValidationShapers.shapeFacebookChannel({ ...this.state });
    } else if (channelType === 'rhinogram') {
      channelShaped = ValidationShapers.shapeRhinogramChannel({ ...this.state });
    } else if (channelType === 'secure') {
      channelShaped = ValidationShapers.shapeSecureChannel({ ...this.state });
    } else if (channelType === 'instagram') {
      channelShaped = ValidationShapers.shapeInstagramChannel({ ...this.state });
    }

    return channelShaped;
  }

  handleTagClick = (e, tagId) => {
    const { activeTagIds } = this.state;

    if (activeTagIds.includes(tagId)) {
      this.setState({ activeTagIds: activeTagIds.filter((activeTagId) => activeTagId !== tagId) });
    } else {
      this.setState({ activeTagIds: [...activeTagIds, tagId] });
    }

    this.props.handleFormChanges();
  };

  afterHandleChange = () => {
    const fee = AddonHelpers.getAddonFee(0, this.props, CHANNEL_PRODUCT);
    this.setState(fee);
  }

  handleChange = (name, value) => {
    if (name === 'widgetActiveSection') {
      ScrollHelpers.scrollToElementByClassName('.channel__widget-section__wrapper.is-active', this.pageContainer);
    }
    const { widgetActiveSection } = this.state;
    const valueToUpdate = {};
    valueToUpdate[name] = value;

    if (name === 'widgetActiveSection' && value === widgetActiveSection) {
      valueToUpdate.widgetActiveSection = '';
    } else if (name === 'afterHoursEnabled' && !value) {
      const { businessHours } = this.props.channels[this.state.editChannelId];
      valueToUpdate.businessHours = businessHours;
    }

    this.setState(valueToUpdate);
  }

  handleWidgetConfigChange = (section, name, value) => {
    const { widgetConfig } = this.state;
    const widgetConfigCopy = DataHelpers.cloneDeep(widgetConfig);
    // if the name that we are changing is hideCTAButton and the value is true and the Call to Action Button text is ''
    // then it needs to be reset to the default so that it doesnt error on submit
    if (name === 'hideCTAButton' && value && widgetConfigCopy.text.calltoActionButton === '') {
      widgetConfigCopy.text.calltoActionButton = 'Get In Touch';
    }
    // if the name that we are changing is position and the value is not freeform
    // then hideCTAButton needs to be disabled so that the form is not both expanded but and stuck in the corner of the screen
    if (name === 'position' && value !== 'freeform') {
      widgetConfigCopy.embedForm.hideCTAButton = false;
    }

    widgetConfigCopy[section][name] = value;
    this.setState({
      widgetConfig: widgetConfigCopy,
    });
  }

  handleToggle = (name) => {
    if (name === 'showDeleteChannelModal') {
      const fee = AddonHelpers.getAddonFee(1, this.props, CHANNEL_PRODUCT);
      this.setState(fee);
    }
    this.setState({ [name]: !this.state[name] });
  }

  resetColorPickerState(name) {
    if (name === 'isWebFormTitleBackgroundColorPickerVisible') {
      this.setState({
        isWebFormButtonBackgroundColorPickerVisible: false,
        isWebFormTitleTextColorPickerVisible: false,
        isWebFormButtonTextColorPickerVisible: false,
      });
    }
    if (name === 'isWebFormButtonBackgroundColorPickerVisible') {
      this.setState({
        isWebFormTitleBackgroundColorPickerVisible: false,
        isWebFormTitleTextColorPickerVisible: false,
        isWebFormButtonTextColorPickerVisible: false,
      });
    }
    if (name === 'isWebFormTitleTextColorPickerVisible') {
      this.setState({
        isWebFormTitleBackgroundColorPickerVisible: false,
        isWebFormButtonBackgroundColorPickerVisible: false,
        isWebFormButtonTextColorPickerVisible: false,
      });
    }
    if (name === 'isWebFormButtonTextColorPickerVisible') {
      this.setState({
        isWebFormTitleBackgroundColorPickerVisible: false,
        isWebFormButtonBackgroundColorPickerVisible: false,
        isWebFormTitleTextColorPickerVisible: false,
      });
    }
  }

  handleColorPickerToggle = (name) => {
    this.resetColorPickerState(name);
    this.setState((prevState) => ({ [name]: !prevState[name] }));
  }

  handleUpdateSelectedIds = (name, id) => {
    let selectedIds = [...this.state[name]];

    const addAction = !selectedIds.includes(id);

    if (addAction) {
      selectedIds = selectedIds.concat(id);
    } else {
      selectedIds = selectedIds.filter((selectedId) => selectedId !== id);
    }

    this.setState({ [name]: selectedIds }, () => this.props.handleFormChanges());
  }

  handleCopyEmbedCodeClick = () => {
    this.setState({
      copyEmbedCodeText: 'Copied!',
    }, () => {
      setTimeout(() => {
        this.setState({
          copyEmbedCodeText: 'Copy Embed Code',
        });
      }, 2500);
    });
  }

  handleUpdateSelectedId = (name, id) => {
    const newState = {
      selectedRouteMemberId: -1,
      selectedRouteGroupId: -1,
      [name]: id,
    };

    this.setState(newState, () => this.props.handleFormChanges());
  }

  getChannelType = () => {
    if (this.state.existingNumber) {
      return 'sms';
    }

    return this.state.channelType === 'landline' && this.state.provisionServiceType === TYPE_SMS ? 'sms' : this.state.channelType;
  }

  get shapeChannel() {
    const existingNumber = !!((this.state.channelType === 'landline' && this.state.provisionServiceType === TYPE_SMS) || this.state.existingNumber);
    const channelType = this.getChannelType();
    const { provisionServiceType } = this.state;
    const baseShape = {
      afterHours: this.state.afterHoursEnabled,
      allowReroute: this.state.allowReroute,
      autoResponse: this.state.autoResponse,
      businessHours: this.state.businessHours,
      name: this.state.channelName,
      observesDst: this.state.observesDst,
      purpose: this.state.channelPurpose,
      tagIds: this.state.activeTagIds,
      timeZoneId: this.state.timeZoneId,
      isRhinocallEnabled: this.state.isRhinocallEnabled,
    };
    const channelShape = {
      ...baseShape,
      ...['landline', 'sms'].includes(channelType) && {
        webFormId: this.state.webFormId,
        widgetConfig: this.state.widgetConfig,
        isWebWidgetEnabled: this.state.isWebWidgetEnabled,
      },
      ...channelType === 'landline' && provisionServiceType === TYPE_TWILIO && {
        typeId: TYPE_TWILIO,
        details: {
          ...this.state.mode === 'edit' && { id: this.props.channels[this.state.editChannelId]?.details?.id },
          phone: {
            ...this.state.mode === 'edit' && { id: this.state.channelPhoneId },
            value: this.state.landlineNumber,
            typeId: TYPE_CELL,
          },
        },
      },
      ...channelType === 'sms' && {
        typeId: TYPE_SMS,
        ...this.state.mode === 'create' && !existingNumber && {
          details: {
            phone: {
              value: normalizePhone(this.state.bandwidthNumber),
              typeId: TYPE_CELL,
            },
            forwardingPhone: {
              value: normalizePhone(this.state.bandwidthForwardingNumber),
              typeId: TYPE_WORK,
            },
          },
        },
        ...this.state.mode === 'edit' && !existingNumber && {
          details: {
            id: this.state.bandwidthId,
            bandwidthNumberId: this.state.bandwidthNumberId,
            forwardingPhone: {
              id: this.state.bandwidthForwardingNumberId,
              value: normalizePhone(this.state.bandwidthForwardingNumber),
            },
          },
        },
        ...existingNumber && {
          details: {
            id: this.state.bandwidthId,
            bandwidthNumberId: this.state.bandwidthNumberId,
            existingNumber,
            phone: {
              ...this.state.mode === 'edit' && { id: this.state.channelPhoneId },
              value: normalizePhone(this.state.landlineNumber),
              typeId: TYPE_CELL,
            },
            forwardingPhone: null,
          },
        },
      },
      ...channelType === 'facebook' && {
        typeId: TYPE_FACEBOOK,
        ...this.state.mode === 'create' && {
          details: {
            facebookPageId: this.state.facebookPageId,
            pageAccessToken: this.state.facebookPageAccessToken,
            name: this.state.facebookPageName,
          },
        },
      },
      ...channelType === 'instagram' && {
        typeId: TYPE_INSTAGRAM,
        ...this.state.mode === 'create' && {
          details: {
            pageId: this.state.instagramPageId,
            pageAccessToken: this.state.instagramPageAccessToken,
            name: this.state.instagramPageName,
            instagramAccountId: this.state.instagramAccountId,
          },
        },
      },
      ...channelType === 'rhinogram' && {
        typeId: TYPE_RHINOGRAM,
        ...this.state.mode === 'create' && {
          details: {},
        },
      },
      ...channelType === 'secure' && {
        typeId: TYPE_SECURE,
      },
      route: this.state.selectedRouteMemberId !== -1 || this.state.selectedRouteGroupId !== -1 ?
        {
          userId: this.state.selectedRouteMemberId !== -1 ? this.state.selectedRouteMemberId : null,
          groupId: this.state.selectedRouteGroupId !== -1 ? this.state.selectedRouteGroupId : null,
        } : null,
    };
    return channelShape;
  }

  render() {
    // If channel is set as Twilio, only allow changing to Bandwidth (Temporarily for migration purposes for CSR)
    let { provisionServiceOptions } = this.state;
    if (this.state.editChannelId && this.props.channels[this.state.editChannelId]?.typeId === TYPE_TWILIO) {
      provisionServiceOptions = this.state.provisionServiceOptions.filter((option) => isPhoneChannelTypeId(option.id));
    }

    const props = {
      activeTagIds: this.state.activeTagIds,
      afterHoursEnabled: this.state.afterHoursEnabled,
      allowReroute: this.state.allowReroute,
      autoResponse: this.state.autoResponse,
      bandwidthForwardingNumber: this.state.bandwidthForwardingNumber,
      bandwidthNumber: this.state.bandwidthNumber,
      businessHours: this.state.businessHours,
      channelName: this.state.channelName,
      channelPurpose: this.state.channelPurpose,
      channelType: this.state.channelType,
      copyEmbedCodeText: this.state.copyEmbedCodeText,
      editChannel: this.state.editChannel,
      embedUrl: `<script id="rhinogram-embed" src="${WIDGET_BASE_URL}?id=${this.state.webFormId}"></script>`,
      errors: this.state.errors,
      existingNumber: !!this.state.existingNumber,
      facebookPageId: this.state.facebookPageId,
      instagramAcccountId: this.state.instagramAcccountId,
      facebookPageAccessToken: this.state.facebookPageAccessToken,
      facebookPageName: this.state.facebookPageName,
      formInProgress: this.state.formInProgress,
      handleChange: this.handleChange,
      handleWidgetConfigChange: this.handleWidgetConfigChange,
      handleFormChanges: this.props.handleFormChanges,
      handleConfirmDeleteRequest: this.handleConfirmDeleteRequest,
      handleCopyEmbedCodeClick: this.handleCopyEmbedCodeClick,
      handleFacebookPageSelect: this.handleFacebookPageSelect,
      handleUpdateSelectedIds: this.handleUpdateSelectedIds,
      handleUpdateSelectedId: this.handleUpdateSelectedId,
      handleSubmit: this.handleSubmit,
      handleTagClick: this.handleTagClick,
      handleToggle: this.handleToggle,
      handleModalReverseComplete: this.handleModalReverseComplete,
      landlineNumber: this.state.landlineNumber,
      mode: this.state.mode,
      modalFormInProgress: this.state.modalFormInProgress,
      observesDst: this.state.observesDst,
      pageContainerRef: (pageContainer) => (this.pageContainer = pageContainer),
      pageLoading: this.props.pageLoading,
      route: this.state.route,
      selectedPageId: this.state.selectedPageId,
      selectedRouteMemberId: this.state.selectedRouteMemberId,
      selectedRouteGroupId: this.state.selectedRouteGroupId,
      showDeleteChannelModal: this.state.showDeleteChannelModal,
      tagIds: this.props.tagIds,
      tags: this.props.tags,
      timeZoneId: this.state.timeZoneId,
      timeZones: this.props.timeZones,
      userIsCcr: this.props.user.isCcr,
      isWarning: this.state.isWarning,
      perChannelFee: this.state.fee,
      isWebWidgetEnabled: this.state.isWebWidgetEnabled,
      widgetActiveSection: this.state.widgetActiveSection,
      widgetConfig: this.state.widgetConfig,
      isWebFormButtonBackgroundColorPickerVisible: this.state.isWebFormButtonBackgroundColorPickerVisible,
      isWebFormTitleBackgroundColorPickerVisible: this.state.isWebFormTitleBackgroundColorPickerVisible,
      isWebFormTitleTextColorPickerVisible: this.state.isWebFormTitleTextColorPickerVisible,
      isWebFormButtonTextColorPickerVisible: this.state.isWebFormButtonTextColorPickerVisible,
      handleColorPickerToggle: this.handleColorPickerToggle,
      provisionServiceOptions,
      provisionServiceType: this.state.provisionServiceType,
      rhinocallChannelCount: this.props.rhinocallChannelCount,
      isCallEnabled: this.props.isCallEnabled,
      isRhinocallEnabled: this.state.isRhinocallEnabled,
      rhinocallLimitReached: !this.props.rhinocallChannelIds.includes(this.state.editChannelId) && this.props.rhinocallChannelIds?.length >= this.props.channelLimit,
      handleInstagramPageSelect: this.handleInstagramPageSelect,
      instagramPageId: this.state.instagramPageId,
      organizationId: this.props.currentUser.organization,
    };

    return <OrganizationChannelForm {...props} />;
  }
}

OrganizationChannelFormContainer.propTypes = {
  channels: PropTypes.object.isRequired,
  createChannel: PropTypes.func.isRequired,
  destroyChannel: PropTypes.func.isRequired,
  error: PropTypes.object,
  fetchChannelsView: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  subscriptionLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  phones: PropTypes.object.isRequired,
  tagIds: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  timeZones: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired,
  updateChannel: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  productCount: PropTypes.number.isRequired,
  billing: PropTypes.object,
  subscription: PropTypes.object,
  handleFormChanges: PropTypes.func.isRequired,
  resetFormChanges: PropTypes.func.isRequired,
  billingError: PropTypes.bool,
  currentOrganization: PropTypes.object,
  isWebFormButtonBackgroundColorPickerVisible: PropTypes.bool,
  isWebFormTitleBackgroundColorPickerVisible: PropTypes.bool,
  isWebFormTitleTextColorPickerVisible: PropTypes.bool,
  isWebFormButtonTextColorPickerVisible: PropTypes.bool,
  hideCTAButton: PropTypes.bool,
  history: PropTypes.object,
  isCallEnabled: PropTypes.bool,
  channelLimit: PropTypes.number,
  fetchCallOrgConfig: PropTypes.func.isRequired,
  rhinocallChannelIds: PropTypes.array,
  currentUser: PropTypes.object,
  rhinocallChannelCount: PropTypes.number,
};

const mapStateToProps = (state) => {
  const { channel, tag, timeZone, type, ui, phone, billing, rhinocall } = state;

  return {
    channels: channel.channels,
    error: ui.error,
    pageLoading: channel.loading,
    phones: phone.phones,
    tagIds: tag.tagIds,
    tags: tag.tags,
    timeZones: timeZone.timeZones,
    types: type.types,
    user: getLoggedInUser(state),
    productCount: getAutomatedMessageChannelIds(state).length,
    billing: billing.billing,
    subscription: billing.subscription,
    subscriptionLoading: billing.subscriptionLoading,
    billingError: billing.billingError,
    currentOrganization: getCurrentOrg(state),
    isCallEnabled: !!rhinocall?.org?.isCallEnabled,
    channelLimit: rhinocall?.org?.channelLimit,
    rhinocallChannelIds: getRhinocallChannelIds(state),
  };
};

const actions = {
  createChannel: ChannelReducer.createChannel,
  destroyChannel: ChannelReducer.destroyChannel,
  fetchChannelsView: ChannelReducer.fetchChannelsView,
  fetchCallOrgConfig: RhinocallReducer.fetchRhinoCallOrganizationConfiguration,
  updateChannel: ChannelReducer.updateChannel,
};

export default connect(mapStateToProps, actions)(unsavedChanges(OrganizationChannelFormContainer));
