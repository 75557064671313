import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Button,
  Icon,
} from 'rhinostyle';

import GroupPanel from './GroupPanel';
import PageLoader from './PageLoader';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { GROUP_CHAT_EDIT, GROUP_INBOX_EDIT } from '../constants/UserPermissionsConstants';
import { fetchGroups, fetchGroup } from '../reducers/groupReducer';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getNonDynamicGroups, getActiveGroup } from '../selectors/groupSelectors';
import GroupList from './GroupList';

const OrganizationGroups = (props) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [panelOpen, setPanelOpen] = useState(!!params.groupId);
  const {
    groupIds,
    pageLoading,
    activeGroup,
  } = props;

  useEffect(() => {
    dispatch(fetchGroups());
  }, []);

  useEffect(() => {
    if (params.groupId) {
      dispatch(fetchGroup(params.groupId));
    }
    setPanelOpen(!!params.groupId);
  }, [params.groupId]);

  const handleCreateRequest = () => {
    history.push('/settings/organization/groups/create');
  };

  const handleTogglePanel = (id) => {
    const shouldClose = panelOpen && Number(params.groupId) === id;

    if (shouldClose) {
      history.push('/settings/organization/groups');
    } else {
      history.push(`/settings/organization/groups/${id}`);
    }
  };

  const handleMemberClick = (id) => {
    history.push(`/settings/organization/members/${id}`);
  };

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': panelOpen,
  });

  if (pageLoading) {
    return <PageLoader />;
  }

  return (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="app-page__header">
              <div className="app-page__header__title">Groups</div>
              {userHasAnyOfPermissions([GROUP_CHAT_EDIT, GROUP_INBOX_EDIT]) && (
                <div className="app-page__header__action">
                  <Button
                    onClick={handleCreateRequest}
                    iconOnly
                    type="secondary"
                    title="Create Group"
                  >
                    <Icon icon="add" />
                  </Button>
                </div>
              )}
            </div>
            <div className="list-panel__body" data-cypress={groupIds.length ? null : 'noGroup'}>
              <GroupList
                handleSelect={handleTogglePanel}
                viewAll
                className="list-panel__body__scroll"
              />
            </div>
          </div>
        </div>
        {panelOpen && activeGroup && (
          <GroupPanel
            handleMemberClick={handleMemberClick}
            handleTogglePanel={handleTogglePanel}
            view="organization"
            history={history}
            location={location}
            params={params}
          />
        )}
      </div>
    </div>
  );
};

OrganizationGroups.propTypes = {
  groupIds: PropTypes.array.isRequired,
  pageLoading: PropTypes.bool,
  activeGroup: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  const { group, tag } = state;

  return {
    activeGroup: getActiveGroup(state, props.match?.params),
    groupIds: getNonDynamicGroups(state),
    groups: group.groups,
    groupsLoading: group.loading,
    tags: tag.tags,
    user: getLoggedInUser(state),
    pageNo: group.pageNo,
    totalCount: group.totalCount,
    pageLoading: group.pageLoading,
  };
};

export default connect(mapStateToProps)(OrganizationGroups);
