import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Button } from 'rhinostyle';
import { setVolumeGain } from '../reducers/rhinovideoReducer';
import Slider from './Slider';
import { getAudioContext } from '../services/RhinovideoRoomService';

const RhinovideoToggleVolumeButton = (props) => {
  const { volumeGain } = props;
  const dispatch = useDispatch();
  const [isVolumeVisible, setIsVolumeVisible] = useState(false);

  useEffect(() => {
    const { gainNode } = getAudioContext();
    gainNode.gain.value = volumeGain;
  }, [volumeGain]);

  const handleChange = (value) => {
    dispatch(setVolumeGain(value));
  };

  function handleToggle() {
    setIsVolumeVisible((current) => !current);
  }

  return (
    <div>
      <div className="u-position-relative">
        {isVolumeVisible && (
        <Slider
          initialValue={volumeGain}
          onChange={handleChange}
          isVertical
        />
        )}
      </div>
      <div>
        <Button
          type="link"
          size="large"
          iconOnly
          title="volume"
          onClick={handleToggle}
          className="rhinovideo__action-bar__button"
        >
          <Icon size="large" icon="speaker" />
        </Button>
        <div className="rhinovideo__action-bar__label">
          <span>Volume</span>
        </div>
      </div>
    </div>
  );
};

RhinovideoToggleVolumeButton.propTypes = {
  volumeGain: PropTypes.number,
};

const mapStateToProps = (state) => {
  const { rhinovideo } = state;

  return ({
    volumeGain: rhinovideo.volumeGain,
  });
};

export default connect(mapStateToProps)(RhinovideoToggleVolumeButton);
