import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../helpers/AxiosHelper';
import { DEFAULT_ANALYTICS_PAGE_SIZE } from '../constants/AppConstants';

export const campaignSlice = createApi({
  reducerPath: 'campaigns',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Campaigns'],
  endpoints: (builder) => ({
    // Get all campaigns
    getCampaigns: builder.query({
      query: ({ sortBy = '', sortOrder = '', pageNo = 0, fromDate, toDate, pageSize = DEFAULT_ANALYTICS_PAGE_SIZE, savedListIds }) => {
        const url = `/analytics/bulkMessages?from=${fromDate}&to=${toDate}&pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}&savedListIds=${savedListIds}`;
        return { url, method: 'get' };
      },
      providesTags: ['Campaigns'],
    }),
    // Get a single campaign
    getCampaign: builder.query({
      query: ({ bulkMessageId }) => {
        const url = `/analytics/bulkMessages/${bulkMessageId}`;
        return { url, method: 'get' };
      },
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetCampaignsQuery,
  useGetCampaignQuery,
} = campaignSlice;

export default campaignSlice;
