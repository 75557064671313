import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import { LocationHelpers } from '../helpers';
import { removeProperty } from '../helpers/DataHelpers';
import { userHasAnyOfPermissions, userHasAllOfPermissions, hasAnyInboxViewPermissions, hasLimitedProviderRole } from '../helpers/UserHelpers';
import StorageService from '../services/StorageService';
import AuthService from '../services/AuthService';
import WebSocketService from '../services/WebSocketService';
import OneSignalService from '../services/OneSignalService';
import OutboundPostMessageService from '../services/OutboundPostMessageService';
import NotificationService from '../services/NotificationService';

import { Types } from '../constants';
import {
  BILLING_VIEW,
  CONTACT_VIEW,
  CONTACT_EDIT,
  RHINOPAY_MANAGER_VIEW,
  CONVERSATION_CONTACT_MOBILE,
} from '../constants/UserPermissionsConstants';

import { fetchPrefixes } from './prefixReducer';
import * as SuffixReducer from './suffixReducer';
import * as SecureReducer from './secureReducer';
import * as TimeZoneReducer from './timeZoneReducer';
import { fetchTypes } from './typeReducer';
import { fetchSupportedLanguages } from './languageReducer';
import * as SystemAlertReducer from './systemAlertReducer';
import { fetchMerchant, fetchMerchantCcr } from './payReducer';
import * as FormReducer from './formReducer';
import * as RhinovideoReducer from './rhinovideoReducer';
import { fetchBilling } from './billingReducer';
import { user } from '../actions/NormalizrSchema';
import { isMobile } from '../helpers/BrowserHelpers';
import { initialize } from '../helpers/PendoHelpers';

import { setError, toggleEmailModal, fetchInboxSections } from './uiReducer';
import * as UserReducer from './userReducer';
import * as RhinocallReducer from './rhinocallReducer';
import LOCAL_STORAGE_PERSIST_KEYS from '../constants/StorageConstants';

// SLICE
const authSlice = createSlice({
  name: 'AUTH',
  initialState: {
    authMode: null,
    currentOrg: null,
    currentUser: null,
    forgotPasswordEmail: '',
    loading: false,
    logoutInProgress: false,
    logoutType: '',
  },
  reducers: {
    setAuthMode: (state, action) =>
      ({
        ...state,
        authMode: action.payload,
        loading: false,
      }),
    setForgotPasswordEmail: (state, action) =>
      ({
        ...state,
        forgotPasswordEmail: action.payload,
        loading: false,
      }),
    setUser: (state, action) =>
      ({
        ...state,
        currentUser: action.payload.userId,
      }),
    setOrg: (state, action) =>
      ({
        ...state,
        currentOrg: action.payload,
      }),
    clearAuth: (state) =>
      ({
        ...state,
        currentUser: null,
        currentOrg: null,
        authMode: null,
        forgotPasswordEmail: '',
        loading: false,
      }),
    requestData: (state) =>
      ({
        ...state,
        loading: true,
      }),
    receiveSSOConnections: receiveSSOConnectionData,
    requestDataEnd: (state) =>
      ({
        ...state,
        loading: false,
      }),
    setLogoutInProgress: (state, action) =>
      ({
        ...state,
        logoutInProgress: action.payload.logoutInProgress,
        logoutType: action.payload.logoutType,
      }),
    unsetUser: (state) =>
      ({
        ...state,
        currentUser: null,
        currentOrg: null,
      }),
  },
});

export default authSlice.reducer;

// ACTIONS
export const {
  setAuthMode,
  setForgotPasswordEmail,
  setUser,
  setOrg,
  clearAuth,
  unsetUser,
  requestData,
  requestDataEnd,
  receiveData,
  setLogoutInProgress,
  receiveSSOConnections,
} = authSlice.actions;

// REDUCER HELPERS

function receiveSSOConnectionData(state, action) {
  const ssoConnections = action.payload.map((connection) => ({ id: connection, value: connection }));
  ssoConnections.unshift({ id: '', value: '' });
  return {
    ...state,
    ssoConnections,
    loading: false,
  };
}

// THUNKS -- ASYNC ACTION CREATORS

export function login(loginEmail, password, isFromPasswordUpdateOrPatientSignup) {
  const { hash, orgId, userId } = LocationHelpers.getQueryParams(window.location.search);
  const loginPayload = {
    loginEmail,
    password,
    hash,
    orgId: parseInt(orgId, 10),
    userId: parseInt(userId, 10),
  };
  if (!isFromPasswordUpdateOrPatientSignup) {
    StorageService.createEntry('authType', 'legacy');
  }

  return (dispatch) => {
    dispatch(requestData());

    return axios.post('/login', loginPayload)
      .then((response) => handleSubscriptions(response.data))
      .then((myUsers) => dispatch(receiveLoginResponse(myUsers)))
      .then((myUsers) => setLocalStorage(myUsers))
      .then((myUsers) => dispatch(selectOrg(myUsers)))
      .then((path) => {
        dispatch(toggleEmailModal(true));
        return path;
      })
      .catch((err) => dispatch(handleLoginError(err)));
  };
}

export function axiumLogin(axiumOrgId, loginPayload) {
  unsetUserAndOrg();
  StorageService.createEntry('authType', 'axium');
  return async (dispatch) => {
    dispatch(requestData());

    try {
      const response = await axios.post(`/axium/login/${axiumOrgId}`, loginPayload);
      const activePatientId = response?.data?.[0]?.activePatientId?.id;
      const isLimitedProvider = hasLimitedProviderRole(response?.data?.[0]);
      sessionStorage.setItem('axiumLogin', true);
      if (activePatientId) {
        sessionStorage.setItem('activePatientId', activePatientId);
      }
      const myUsers = await handleSubscriptions(response.data);
      dispatch(receiveLoginResponse(myUsers));
      setLocalStorage(myUsers);
      await dispatch(selectOrg(myUsers));

      return { activePatientId, isLimitedProvider };
    } catch (err) {
      dispatch(handleLoginError(err));
      return { activePatientId: null, isLimitedProvider: null, errorMsg: err?.response };
    }
  };
}

export function loggedIn() {
  return (dispatch) => {
    dispatch(requestData());
    return axios.get('/sessionLogin')
      .then((response) => validateUser(response))
      .then((response) => handleSubscriptions(response.data))
      .then((myUsers) => dispatch(receiveLoginResponse(myUsers)))
      .then((myUsers) => setLocalStorage(myUsers))
      .then((myUsers) => dispatch(selectOrg(myUsers)))
      .then((path) => {
        dispatch(toggleEmailModal(true));
        return path;
      })
      .catch((err) => dispatch(handleLoginError(err)));
  };
}

function validateUser(response) {
  if (response?.data.length === 0) {
    throw new Error('No user available for org');
  }
  return response;
}

// Remove subscriptions from myUsers and return the newly mapped response
function handleSubscriptions(myUsers) {
  StorageService.destroyEntry('subscriptions');

  const subscriptions = myUsers.filter((u) => !u.isCcr).map((u) => u.subscriptions).flat();
  StorageService.createEntry('subscriptions', subscriptions);

  return myUsers.map(removeProperty('subscriptions'));
}

// Dispatch myUsers into the redux store;
function receiveLoginResponse(myUsers) {
  return (dispatch) => {
    const normalized = normalize(myUsers, [user]);
    dispatch(UserReducer.receiveMyUsers(UserReducer.getMyUsersPayload(normalized)));
    dispatch(UserReducer.receiveMyUsersRaw(myUsers));

    return myUsers;
  };
}

// Set the appropriate localStorage values for routing / UI;
function setLocalStorage(myUsers) {
  const isCcr = myUsers.find((u) => u.isCcr);
  const isPatient = myUsers.find((u) => u.typeId !== Types.TYPE_MEMBER);

  if (isPatient) {
    StorageService.createEntry('patientExperience', true);
    StorageService.destroyEntry('multiOrgs');
  } else if (isCcr) {
    StorageService.createEntry('multiOrgs', true);
    StorageService.destroyEntry('patientExperience');
  } else {
    StorageService.destroyEntry('multiOrgs');
    StorageService.destroyEntry('patientExperience');
  }

  return myUsers;
}

export function changePatientExperienceUserAndOrg(orgId, userId) {
  return (dispatch) => axios.get(`/users/${userId}?patientUserId=${userId}&patientOrgId=${orgId}`)
    .then((userResponse) => {
      const normalized = normalize(userResponse.data, user);

      StorageService.createEntry('user', normalized.result);
      StorageService.createEntry('org', orgId);
      WebSocketService.teardown();
      WebSocketService.setup();
      dispatch(setUser(UserReducer.getUserPayload(normalized)));
      dispatch(setOrg(orgId));
    });
}

// An end user switching between organizations.
export function changeOrg(orgId, userId) {
  return (dispatch) =>
    axios.post('/changeOrg', { orgId, userId })
      .then((response) => dispatch(receiveChangeOrg(response.data, orgId)))
      .then(() => dispatch(setError(null)))
      .catch((err) => dispatch(handleError(err)));
}

function receiveChangeOrg(userResponse, orgId) {
  return (dispatch) => {
    const normalized = normalize(userResponse, user);

    StorageService.createEntry('user', normalized.result);
    StorageService.createEntry('org', orgId);

    dispatch(setUser(UserReducer.getUserPayload(normalized)));
    dispatch(setOrg(orgId));
  };
}

function selectOrg(myUsers) {
  return (dispatch) => {
    const isCcr = myUsers.some((u) => u.isCcr);
    const isPatient = !isCcr && myUsers.find((u) => u.typeId !== Types.TYPE_MEMBER);

    // If the user has been deactivated, the API returns a myUsers array with a length of 0.
    // This check ensures that the changeOrg function will trigger an error as it tries to submit null values.
    const userId = myUsers[0] ? myUsers[0].id : null;
    const orgId = myUsers[0] ? myUsers[0].organization.id : null;
    if (isPatient) {
      StorageService.createEntry('patientExperience', true);
      StorageService.destroyEntry('multiOrgs');
      return dispatch(setOrganization(orgId, userId))
        .then(() => (
          dispatch(handlePatientLogin(myUsers[0], orgId))
        ))
        .then((response) => response);
    } else if (isCcr || (myUsers.length > 1)) {
      StorageService.createEntry('multiOrgs', true);
      StorageService.destroyEntry('patientExperience');
      return '/selectorg';
    } else {
      StorageService.destroyEntry('multiOrgs');
      StorageService.destroyEntry('patientExperience');
      return dispatch(setOrganization(orgId, userId, isCcr))
        .then((pathToRedirect) => pathToRedirect);
    }
  };
}

function handlePatientLogin(firstUser, orgId) {
  const userId = firstUser.id;

  // Need to make a request to the API containing patientUserId && patientOrgId params to set patient session.
  return (dispatch) => axios.get(`/users/${userId}?patientUserId=${userId}&patientOrgId=${orgId}`)
    .then((response) => {
      const normalized = normalize(response.data, user);

      StorageService.createEntry('user', normalized.result);
      StorageService.createEntry('org', orgId);
      dispatch(setUser(UserReducer.getUserPayload(normalized)));
      dispatch(setOrg(orgId));
      return dispatch(fetchInitialData())
        .then(async () => {
          WebSocketService.setup();
          const pathToRedirect = await dispatch(redirectUser());
          dispatch(setError(null));
          return pathToRedirect;
        });
    });
}

export function setOrganization(orgId, userId) {
  return (dispatch) =>
    axios.post('/changeOrg', { orgId, userId })
      .then((response) => {
        const normalized = normalize(response.data, user);
        const { isCcr } = response.data;

        StorageService.createEntry('user', normalized.result);
        StorageService.createEntry('org', orgId);
        dispatch(setUser(UserReducer.getUserPayload(normalized)));
        dispatch(setOrg(orgId));

        if (userHasAnyOfPermissions([BILLING_VIEW])) dispatch(fetchBilling());
        dispatch(RhinovideoReducer.fetchRhinoVideoConfiguration(orgId, isCcr));
        dispatch(RhinocallReducer.fetchRhinoCallOrganizationConfiguration(orgId, userId));
        dispatch(FormReducer.fetchOrgConfiguration(orgId));

        if (isCcr) {
          dispatch(fetchMerchantCcr(orgId));
        } else if (isMobile() ? (userHasAllOfPermissions([CONTACT_VIEW, CONVERSATION_CONTACT_MOBILE]) ||
          userHasAllOfPermissions([CONTACT_EDIT, CONVERSATION_CONTACT_MOBILE]) ||
          userHasAllOfPermissions([RHINOPAY_MANAGER_VIEW])) :
          userHasAnyOfPermissions([CONTACT_VIEW, CONTACT_EDIT, RHINOPAY_MANAGER_VIEW])) {
          dispatch(fetchMerchant(orgId));
        }
        // Pendo initialization only for production.
        if (process.env.REACT_APP_ENVIRONMENT === 'master' || process.env.REACT_APP_ENVIRONMENT === 'staging') {
          initialize(response.data, orgId);
        }

        return dispatch(fetchInitialData())
          .then(async () => {
            if (!isCcr) {
              WebSocketService.setup();
              OneSignalService.sendTagsWeb(userId, orgId);
              OneSignalService.sendTagsNative(userId, orgId);
            }
            const accessToken = localStorage.getItem('access_token');
            OutboundPostMessageService.postMessage({
              type: 'createCookie',
              data: {
                cookie: document.cookie,
                accessToken,
              },
            });
            dispatch(setError(null));
            const redirectPath = await dispatch(redirectUser());
            return redirectPath;
          });
      })
      .catch((err) => dispatch(handleError(err)));
}

export function fetchInitialData() {
  return async (dispatch) => {
    const promises = [
      dispatch(fetchPrefixes()),
      dispatch(SuffixReducer.fetchSuffixes()),
      dispatch(SystemAlertReducer.fetchSystemAlert()),
      dispatch(TimeZoneReducer.fetchTimeZones()),
      dispatch(fetchTypes()),
      dispatch(fetchSupportedLanguages()),
    ];
    if (StorageService.readEntry('patientExperience')) {
      promises.push(dispatch(SecureReducer.fetchPatientMenu()));
    } else if (hasAnyInboxViewPermissions()) {
      promises.push(dispatch(fetchInboxSections()));
    }

    return Promise.all(promises)
      .catch((err) => dispatch(handleError(err)));
  };
}

export function redirectUser() {
  return (dispatch, getState) => {
    const state = getState();
    const loggedInUser = state.user.users[state.auth.currentUser];
    const { path } = LocationHelpers.getQueryParams(window.location.search);
    const currentOrgId = state.auth.currentOrg;
    const currentChannelId = (currentOrgId && state.secure.organizations[currentOrgId]) && state.secure.organizations[currentOrgId].channels[0];
    const securePath = `/secure/org/${currentOrgId}/channel/${currentChannelId}`;
    const sendToSecure = [Types.TYPE_CONNECTED_PARTY, Types.TYPE_PATIENT, Types.TYPE_USER_OTHER, Types.TYPE_UNKNOWN].includes(loggedInUser.typeId);
    const isCcrOnlyRoute = (testPath) => ['/selectorg', '/systemalert', '/accountsetup'].some((ccrPath) => testPath.includes(ccrPath));
    let nextPath = '';
    const redirectUrl = sessionStorage.getItem('redirect_url');
    const currentURL = window.location.href;

    // On refresh and "inside" the app (assumes having an active session), stay at same path
    if (!currentURL.includes('/auth')
      && !currentURL.includes('/login')
      && !currentURL.includes('/callback')
      && !currentURL.includes('/signup')) {
      nextPath = `${window.location.pathname}${window.location.search}`;
    }

    // since redirectUrl is grabbed from sessionStorage, if there is no value, its a string: 'undefined', not undefined
    if (redirectUrl && redirectUrl !== 'undefined') {
      nextPath = redirectUrl;
      sessionStorage.removeItem('redirect_url');
    }

    // User just logged in or 'nextPath' is a CCR only route...
    if (!nextPath || nextPath === '/' || isCcrOnlyRoute(nextPath)) {
      if (!path) {
        nextPath = (sendToSecure && !loggedInUser.isCcr) ? securePath : '/inbox';
      } else if (path) {
        nextPath = path;
      }
    }

    if (loggedInUser.isCcr && (['/selectorg', '/chat'].some((p) => nextPath.includes(p)) || nextPath === '/inbox')) {
      nextPath = '/contacts';
    }
    return nextPath;
  };
}

// Log user out of application
export function logout(logoutType = '', userId, sessionLogout = true) {
  const url = logoutType && userId ? `/logout?userId=${userId}&logoutType=${logoutType}` : '/logout';
  const authType = StorageService.readEntry('authType'); // indicates if the user used legacy or non legacy login
  return async (dispatch, getState) => {
    // Set logout in progress if logout is called from some action besides the user clicking the logout button.
    const inProgressPayload = {
      logoutInProgress: true,
      logoutType,
    };
    dispatch(setLogoutInProgress(inProgressPayload));

    try {
      const { auth, rhinovideo: { conferences, currentVideoId } } = getState();
      const currentVideo = conferences.find((conference) => conference.videoId === currentVideoId) || {};
      const isHost = currentVideo.hostId === auth.currentUser.toString();
      if (isHost) {
        dispatch(RhinovideoReducer.hostHangup());
      }
    } catch (err) {
      // ignore error
    }

    if (sessionLogout) {
      try {
        await axios.delete(url);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err.response || err);
      }
    }

    WebSocketService.teardown();
    OneSignalService.unsubscribe();
    OutboundPostMessageService.postMessage({
      type: 'destroyCookie',
    });

    StorageService.destroyAllExcept(LOCAL_STORAGE_PERSIST_KEYS);
    if (authType && authType === 'legacy') {
      return authType;
    } else {
      AuthService.logout(logoutType);
    }
    return null;
  };
}

export function forgotPassword(loginEmail) {
  return (dispatch) => {
    dispatch(requestData());

    axios.post('/forgotPassword', {
      loginEmail,
    })
      .then((res) => dispatch(setForgotPasswordEmail(res.data.email)))
      .catch((err) => dispatch(handleError(err)));
  };
}

export function enrollMFA(userId) {
  return (dispatch) => {
    dispatch(requestData());
    return axios.post(`/enrollMFA?userId=${userId}`)
      .catch((err) => {
        dispatch(handleError(err));
        // eslint-disable-next-line no-console
        console.error(err.response || err);
        NotificationService('enrollMFA', err.response);
      });
  };
}

export function disableMFA(userId) {
  return (dispatch) => {
    dispatch(requestData());
    return axios.post(`/disableMFA?userId=${userId}`)
      .catch((err) => {
        dispatch(handleError(err));
        // eslint-disable-next-line no-console
        console.error(err.response || err);
        NotificationService('disableMFA', err.response);
      });
  };
}

export function resetMFA(userId) {
  return (dispatch) => {
    dispatch(requestData());
    return axios.post(`/resetMFA?userId=${userId}`)
      .catch((err) => {
        dispatch(handleError(err));
        // eslint-disable-next-line no-console
        console.error(err.response || err);
        NotificationService('resetMFA', err.response);
      });
  };
}

export function fetchSSOConnections() {
  return (dispatch) => {
    dispatch(requestData());
    axios.get('/ssoConnections')
      .then((response) => dispatch(receiveSSOConnections(response.data)))
      .catch((err) => {
        dispatch(handleError(err));
        // eslint-disable-next-line no-console
        console.error(err.response || err);
        NotificationService('fetchSSOConnections', err.response);
      });
  };
}

export function checkResetPassword(loginId, token) {
  return (dispatch) => {
    dispatch(requestData());
    axios.get(`/checkResetPassword?loginId=${loginId}&token=${token}`)
      .then(() => dispatch(setAuthMode('updatePassword')))
      .catch((err) => {
        dispatch(setAuthMode('forgotPassword'));
        dispatch(handleError(err));
      });
  };
}

export function updatePassword(loginId, password, token) {
  return (dispatch) =>
    axios.patch('/updatePassword', {
      loginId,
      password,
      token,
    })
      .then(async (res) => {
        const email = res.data;
        const path = await dispatch(login(email, password, true));
        return path;
      })
      .catch((err) => dispatch(handleError(err)));
}

export function signup(email, password, orgId, userId, hash) {
  const signupPayload = {
    loginEmail: email,
    password,
    orgId: parseInt(orgId, 10),
    userId: parseInt(userId, 10),
    hash,
  };

  return (dispatch) =>
    axios.post('/signup', signupPayload)
      .catch((err) => dispatch(handleError(err)));
}

export function unsetUserAction() {
  return (dispatch) => dispatch(unsetUser());
}

// redirect argument used for org creation and immediately logging in CCR
export function fetchMyUsers(redirect = true) {
  return (dispatch, getState) => {
    dispatch(requestData());

    return axios.get('/myUsers')
      .then((response) => {
        dispatch(UserReducer.receiveMyUsersRaw(response.data));

        const normalized = normalize(response.data, [user]);

        dispatch(UserReducer.receiveMyUsers(UserReducer.getMyUsersPayload(normalized)));

        const { auth } = getState();

        if ((!auth.currentOrg || !auth.currentUser) && redirect) dispatch(selectOrg(response.data));

        return response.data;
      })
      .catch((err) => dispatch(handleError(err)));
  };
}

export function unsetUserAndOrg() {
  return () => {
    StorageService.destroyEntry('user');
    StorageService.destroyEntry('org');
    sessionStorage.removeItem('activePatientId');
    sessionStorage.removeItem('axiumLogin');
  };
}

function handleError(err) {
  return (dispatch) => {
    console.error(err.response || err);

    dispatch(setError(err.response || err));
    dispatch(requestDataEnd());
  };
}

function handleLoginError(err) {
  return (dispatch) => {
    if (!err.response && err.message === 'Network Error') { // hack for strange content length mismatch error
      const error = {
        data: { message: 'There was an issue logging in, please try again.' },
      };
      dispatch(handleError(error));
    } else if (err.message === 'No user available for org') {
      // this should occur when a deactivated member attempts a login.
      AuthService.logout('deactivated');
    } else if (err.response?.status === 401 && err.response?.data?.message === 'Unauthorized - Limited Provider.') {
      StorageService.destroyAll();
      AuthService.logout('axiumLogin');
    } else {
      dispatch(handleError(err));
    }
  };
}

export function handleSetLogoutInProgress(status = true) {
  return (dispatch) => dispatch(setLogoutInProgress({ logoutInProgress: status, logoutType: '' }));
}
