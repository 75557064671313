import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import SystemAlertSettings from '../components/SystemAlertSettings';
import * as SystemAlertReducer from '../reducers/systemAlertReducer';
import { fetchTypes } from '../reducers/typeReducer';
import { Types } from '../constants';
import { ValidationService, ValidationShapers } from '../services/ValidationService';

class SystemAlertSettingsContainer extends Component {
  state = {
    formInProgress: false,
    disabled: true,
    errorList: {},
    active: false,
    alertMessage: '',
    alertType: Types.TYPE_ALERT_TYPE_URGENT,
    url: '',
    urlText: '',
  };

  componentDidMount() {
    document.title = 'Rhinogram | System Alert';

    this.props.fetchTypes(); // TODO: figure out how to get this earlier

    this.props.fetchSystemAlert().then(() => {
      this.setState({
        active: this.props.systemAlert && this.props.systemAlert.active ? !!this.props.systemAlert.active : false,
        alertMessage: this.props.systemAlert && this.props.systemAlert.message ? this.props.systemAlert.message : '',
        alertType: this.props.systemAlert && this.props.systemAlert.alertTypeId ? this.props.systemAlert.alertTypeId : Types.TYPE_ALERT_TYPE_URGENT,
        url: this.props.systemAlert && this.props.systemAlert.url ? this.props.systemAlert.url : '',
        urlText: this.props.systemAlert && this.props.systemAlert.urlText ? this.props.systemAlert.urlText : '',
      });
    });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value, disabled: false });
  }

  handleSubmit = () => {
    const errorList = ValidationService(ValidationShapers.shapeSystemAlert({ ...this.state }));
    const errorCount = Object.keys(errorList).length;

    if (errorCount > 0) {
      this.setState({ errorList });
    } else {
      this.setState({ formInProgress: true });

      this.props.updateSystemAlert(this.shapeSystemAlertForDB(this.state))
        .then(() => {
          const newState = { formInProgress: false, errorList: {}, disabled: true };
          this.setState(newState);
        });
    }
  }

  shapeSystemAlertForDB = (data) => ({
    alertTypeId: data.alertType,
    message: data.alertMessage.trim(),
    url: data.url ? data.url.trim() : '',
    urlText: data.urlText ? data.urlText.trim() : '',
    active: data.active,
  });

  render() {
    const props = {
      errorList: this.state.errorList,
      formInProgress: this.state.formInProgress,
      handleChange: this.handleChange,
      handleSubmit: this.handleSubmit,
      types: this.props.types,
      typeIds: this.props.typeIds,
      active: this.state.active,
      url: this.state.url,
      urlText: this.state.urlText,
      alertMessage: this.state.alertMessage,
      alertType: this.state.alertType,
      loading: this.props.loading,
      disabled: this.state.disabled,
    };

    return (<SystemAlertSettings {...props} />);
  }
}

SystemAlertSettingsContainer.propTypes = {
  systemAlert: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchSystemAlert: PropTypes.func.isRequired,
  updateSystemAlert: PropTypes.func.isRequired,
  fetchTypes: PropTypes.func.isRequired,
  types: PropTypes.object.isRequired,
  typeIds: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { type, systemAlert } = state;

  return {
    systemAlert: systemAlert.systemAlert,
    loading: systemAlert.loading,
    types: type.types,
    typeIds: type.typeIds.filter((id) => type.types[id].classId === 12), // system alert types only
  };
};

const actions = {
  fetchSystemAlert: SystemAlertReducer.fetchSystemAlert,
  updateSystemAlert: SystemAlertReducer.updateSystemAlert,
  fetchTypes,
};

export default connect(mapStateToProps, actions)(SystemAlertSettingsContainer);
