import React, { useState, useRef } from 'react';
import {
  Button,
  ResourceIntro,
  Resource,
  ResourceGroup,
  Scrollbars,
  Icon,
} from 'rhinostyle';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { getLoggedInUserPermissionNames } from '../selectors/userSelectors';
import { AUTOMATED_MESSAGE_EDIT } from '../constants/UserPermissionsConstants';
import EmptyMessage from '../components/EmptyMessage';
import PageLoader from '../components/PageLoader';
import LazyLoader from '../components/LazyLoader';
import { useGetHipaaCampaignsQuery } from '../services/hipaaCampaignService';
import HipaaCampaignSummaryPanel from '../components/HipaaCampaignSummaryPanel';

const HipaaCampaigns = () => {
  const history = useHistory();
  const params = useParams();
  const scrollContainerRef = useRef();
  const [pageNumber] = useState(0);
  const currentOrganization = useSelector(getCurrentOrg);
  const loggedInUserPermissions = useSelector(getLoggedInUserPermissionNames);

  const { data: hipaaCampaigns, isLoading: loading } = useGetHipaaCampaignsQuery({ organizationId: currentOrganization.id, pageNumber });
  const activeCampaign = params?.hipaaCampaignId ? hipaaCampaigns?.find((campaign) => campaign.id === Number(params.hipaaCampaignId)) : null;
  const HIPAA_CAMPAIGN_ROUTE = '/settings/organization/hipaa-campaigns';
  const handleTogglePanel = (hipaaCampaignId) => {
    if ((activeCampaign && activeCampaign.id !== hipaaCampaignId) || !activeCampaign) {
      history.push(`${HIPAA_CAMPAIGN_ROUTE}/${hipaaCampaignId}`);
    } else {
      history.push(HIPAA_CAMPAIGN_ROUTE);
    }
  };

  const handleCreateClick = () => {
    if (!currentOrganization.defaultApptReminderChannelId) {
      history.push('/settings/organization/appointment-reminders/default-channel');
    } else {
      history.push(`${HIPAA_CAMPAIGN_ROUTE}/create`);
    }
  };

  const renderHipaaCampaigns = () => (
    <ResourceGroup>
      {hipaaCampaigns.map((hipaaCampaign) => (
        <Resource key={`hipaaCampaign-${hipaaCampaign.id}`} data-feature-tag="hipaa-campaigns_summary-route" onClick={() => handleTogglePanel(hipaaCampaign.id)}>
          <ResourceIntro
            title={hipaaCampaign.name}
          />
        </Resource>
      ))}
    </ResourceGroup>
  );

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': !!activeCampaign,
  });
  if (pageNumber === 0 && loading) {
    return <PageLoader />;
  }
  return (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="list-panel__body" data-feature-tag="hipaaCampaignList">
              <Scrollbars className="library-scroll__container" ref={scrollContainerRef}>

                <div className="list-panel">

                  <div className="app-page__header">
                    <div className="app-page__header__title">
                      HIPAA Campaigns
                    </div>
                    {loggedInUserPermissions.includes(AUTOMATED_MESSAGE_EDIT) && (
                    <div className="app-page__header__action">
                      <Button
                        onClick={handleCreateClick}
                        iconOnly
                        type="secondary"
                        title="Create Group"
                        data-feature-tag="hipaaCampaignAdd"
                      >
                        <Icon icon="add" />
                      </Button>
                    </div>
                    )}
                  </div>
                  {hipaaCampaigns?.length > 0 ? renderHipaaCampaigns() : <EmptyMessage section="HIPAA Campaigns" />}
                </div>
              </Scrollbars>

            </div>
          </div>
        </div>
        <LazyLoader loading={loading} />
        {activeCampaign && (
        <HipaaCampaignSummaryPanel
          hipaaCampaign={activeCampaign}
          handleTogglePanel={handleTogglePanel}
        />
        )}
      </div>
    </div>
  );
};

export default HipaaCampaigns;
