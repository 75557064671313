import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Alert, Button, Icon, LoaderCircle, MessageBox, Pill } from 'rhinostyle';
import { StringHelpers } from '../helpers';
import EmojiPickerDropdown from './EmojiPickerDropdown';

const SecureThreadFooter = (props) => {
  let uploaderInput;
  const messageBoxRef = useRef();

  const {
    activeUpload,
    attachments,
    channel,
    handleAddFile,
    handleMessageTextChange,
    handleMessageTextClick,
    handleRemoveAttachment,
    handleSendMessage,
    isSingleChannel,
    messageFocus,
    messageText,
    sendingMessage,
    fileProgress,
  } = props;

  const renderLoader = () => (
    <div className="convo__message__compose__attachments__loader">
      <LoaderCircle size="small" className="u-text-primary" />&nbsp;&nbsp;&nbsp;<span className="u-text-small u-text-primary">{ fileProgress }</span>
    </div>
  );

  const renderPill = (attachment, key) => (
    <Pill
      type="primary"
      onClick={() => handleRemoveAttachment(key)}
      key={key}
      label={StringHelpers.formatFilename(attachment.name)}
    />
  );

  const renderPills = (files) => (
    <div className="convo__message__compose__attachments">
      {files.map(renderPill)}
      {activeUpload ? renderLoader() : null}
    </div>
  );

  const handleAddFileClick = () => {
    uploaderInput.click();
  };

  const disableSend = activeUpload || (!messageText && !attachments.length);

  const renderFooter = () => {
    if (!isSingleChannel) {
      return (
        <Alert
          title="This is the history of all communication with this organization."
          className="convo__footer__alert"
        >
          To send a message, please use a RhinoSecure channel.
        </Alert>
      );
    } else if (channel && channel.deleted) {
      return (
        <Alert
          title="This RhinoSecure channel has been removed."
          className="convo__footer__alert"
        />
      );
    }

    const renderMessageComposeActions = () => {
      const iconClass = 'convo__message__icon-button';
      return (
        <div className="u-flex u-flex-align-items-center">
          <EmojiPickerDropdown
            inputName="message"
            inputValue={messageText}
            inputRef={messageBoxRef}
            handleInputChange={handleMessageTextChange}
            dropdownPosition="top"
            wrapperClassName="emoji-picker__dropdown__wrapper--convo-thread u-m-l-small u-m-r-small"
          />
          <input
            className="u-sr-only"
            type="file"
            ref={(c) => (uploaderInput = c)}
            onClick={() => { uploaderInput.value = null; }}
            onChange={handleAddFile}
          />
          <Button
            title="Add File (must be 5 GB or less)"
            className={iconClass}
            onClick={handleAddFileClick}
            reset
            data-cypress="Add File"
          >
            <Icon icon="attachment" />
          </Button>
        </div>
      );
    };

    return (
      <>
        <div className="convo__message__container convo__message__container--no-nav">
          <div className="convo__message">
            <MessageBox
              className="convo__message__textarea"
              minRows={1}
              maxHeight="12rem"
              naked
              name="message"
              placeholder={channel && `Message ${channel.name}`}
              onClick={handleMessageTextClick}
              onInput={handleMessageTextChange}
              initialValue={messageText}
              focus={messageFocus}
              messageBoxRef={messageBoxRef}
              emojiSupport
            />
          </div>
          <Button
            reset
            className="convo__message__send"
            onClick={handleSendMessage}
            loading={sendingMessage}
            disabled={disableSend}
            title="Send message"
          >
            <Icon icon="send" />
          </Button>
        </div>
        <div className="convo__footer__actions--contact-assign-panel">
          {renderMessageComposeActions()}
        </div>
        {(activeUpload || attachments.length > 0) && renderPills(attachments)}
      </>
    );
  };

  return (
    <div className="convo__footer">
      {renderFooter()}
    </div>
  );
};

SecureThreadFooter.propTypes = {
  activeUpload: PropTypes.bool,
  attachments: PropTypes.array,
  channel: PropTypes.object,
  handleAddFile: PropTypes.func,
  handleMessageTextChange: PropTypes.func,
  handleMessageTextClick: PropTypes.func,
  handleRemoveAttachment: PropTypes.func,
  handleSendMessage: PropTypes.func,
  isSingleChannel: PropTypes.bool.isRequired,
  messageFocus: PropTypes.bool,
  messageText: PropTypes.string,
  sendingMessage: PropTypes.bool,
  fileProgress: PropTypes.string,
};

export default SecureThreadFooter;
