import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import * as UIReducer from '../reducers/uiReducer';
import Overlay from '../components/Overlay';
import Panels from '../helpers/PanelHelpers';

class OverlayContainer extends React.Component {
  closeNavigation = () => {
    if (this.props.navigationOpen) Panels.toggleNav();
  }

  render() {
    const props = {
      closeNavigation: this.closeNavigation,
      navigationOpen: this.props.navigationOpen,
    };

    return (
      <Overlay {...props} />
    );
  }
}

OverlayContainer.propTypes = {
  navigationOpen: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { ui } = state;

  return {
    navigationOpen: ui.navigationOpen,
  };
};

const actions = {
  setNavigationOpen: UIReducer.setNavigationOpen,
};

export default connect(mapStateToProps, actions)(OverlayContainer);
