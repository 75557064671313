import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { LoaderPulse } from 'rhinostyle';
import { useOnScreen } from '../hooks';

const LoadMore = (props) => {
  const {
    handleLoadMore,
    disabled,
    isLoading,
    hideLazyLoader,
  } = props;
  const loaderRef = useRef();
  const [loading, setLoading] = useState(false);
  const loadMore = useOnScreen(loaderRef, true);

  useEffect(() => {
    if (!isLoading && loading) {
      setLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!loading && loadMore && !isLoading && !disabled) {
      setLoading(true);
    }
  }, [loadMore]);

  useEffect(() => {
    if (loading) {
      handleLoadMore();
    }
  }, [loading]);

  return (
    <div className="load-more__wrapper" ref={loaderRef}>
      {!hideLazyLoader && isLoading && loading ? <div className="u-text-center u-p-b-small"><LoaderPulse type="secondary" /></div> : null}
    </div>
  );
};

LoadMore.propTypes = {
  handleLoadMore: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  hideLazyLoader: PropTypes.bool,
};
export default LoadMore;
