import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  Icon,
  ModalFooter,
  Input,
} from 'rhinostyle';

import rhinopay from '../assets/images/rhinopay.svg';

import { UserHelpers } from '../helpers';

const RhinopayRequestModal = (props) => {
  const {
    activeUser,
    errors,
    handleChange,
    handleFocus,
    handleRequestAmountChange,
    handleSubmitRequest,
    handleCloseModal,
    isOpen,
    isFormInProgress,
    paymentRequestAmount,
    paymentRequestNote,
  } = props;
  const rhinopayRequestAmountInputRef = useRef();
  return (
    <Modal
      open={isOpen}
      onComplete={() => rhinopayRequestAmountInputRef.current.input.focus()}
    >
      <div className="rhinopay__modal__header">
        <Button onClick={handleCloseModal} reset className="u-text-muted modal__header__close u-m-t"><Icon size="medium" icon="close" /></Button>
        <img src={rhinopay} alt="RhinoPay" />
        <div className="u-text-muted u-text-center">Request payment from: {UserHelpers.formatName(activeUser)}</div>
      </div>
      <ModalBody>
        <form className="form__group">
          <div className="row">
            <div className="column-4@small">
              <Input
                type="text"
                customInputClasses="u-text-right"
                addon="left"
                format={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
                initialValue={paymentRequestAmount}
                validationMessage={errors.paymentRequestAmount}
                placeholder="0.00"
                name="paymentRequestAmount"
                dataFeatureTag="paymentAmount"
                label="Amount"
                required
                onInput={handleRequestAmountChange}
                onKeyUp={handleRequestAmountChange}
                onKeyDown={handleRequestAmountChange}
                onFocus={handleFocus}
                ref={rhinopayRequestAmountInputRef}
              >
                <span>$&nbsp;</span>
              </Input>
            </div>
            <div className="column-8@small">
              <Input
                type="text"
                initialValue={paymentRequestNote}
                name="paymentRequestNote"
                label="Payment Note"
                onChange={handleChange}
                placeholder="What's this for?"
              />
              <span className="u-text-muted u-text-small u-m-t-small">
                Note will be visible to patient
              </span>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <div className="row">
          <div className="column-4 u-text-left u-p-t-0">
            <Button
              type="link-muted"
              className="u-p-l-0"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </div>
          <div className="column-8 u-text-right u-p-t-0">
            <Button
              type="primary"
              onClick={handleSubmitRequest}
              loading={isFormInProgress}
              data-cypress="submitPayment"
            >
              Request Payment
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

RhinopayRequestModal.propTypes = {
  activeUser: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  handleRequestAmountChange: PropTypes.func.isRequired,
  handleSubmitRequest: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isFormInProgress: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  paymentRequestAmount: PropTypes.string.isRequired,
  paymentRequestNote: PropTypes.string.isRequired,
};

export default RhinopayRequestModal;
