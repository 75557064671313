import { Types } from '../constants';

// eslint-disable-next-line import/prefer-default-export
export function formatGroupType(groupTypeId) {
  switch (groupTypeId) {
    case Types.TYPE_GROUP_INBOX:
      return 'Patient';
    case Types.TYPE_GROUP_CHAT:
      return 'Team';
    case Types.TYPE_GROUP_INBOX_AND_CHAT:
      return 'Patient and Team';
    default:
      return 'Unknown';
  }
}
