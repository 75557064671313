import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Button } from 'rhinostyle';

import { rotateCamera } from '../reducers/rhinovideoReducer';

const RhinovideoRotateCameraButton = (props) => {
  const dispatch = useDispatch();
  const { isButtonDisabled } = props;

  const handleToggle = () => dispatch(rotateCamera());

  return (
    <div>
      <Button
        type="link"
        size="large"
        iconOnly
        title="Rotate"
        onClick={handleToggle}
        className="rhinovideo__action-bar__button"
        disabled={isButtonDisabled}
      >
        <Icon size="large" icon="rotate-camera" />
      </Button>
      <div className="rhinovideo__action-bar__label">
        <span>Rotate</span>
      </div>
    </div>
  );
};

RhinovideoRotateCameraButton.propTypes = {
  isButtonDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { rhinovideo } = state;

  return ({
    isButtonDisabled: rhinovideo.isButtonDisabled,
  });
};

export default connect(mapStateToProps)(RhinovideoRotateCameraButton);
