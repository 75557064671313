/* eslint-disable no-console */
import axios from 'axios';

export function getBandwidthWebRTCToken() {
  return axios.get(`${process.env.REACT_APP_CALL_BASE_URL}/webrtc/connectionInfo`);
}

export function postBandwidthWebRTCCall(data) {
  return axios.post(`${process.env.REACT_APP_CALL_BASE_URL}/webrtc/initiate`, data);
}

export function postBandwidthCall(data) {
  return axios.post(`${process.env.REACT_APP_CALL_BASE_URL}/calls`, data);
}

export function expireCall() {
  return axios.patch(`${process.env.REACT_APP_CALL_BASE_URL}/calls/expired`);
}

export function patchCall(callId, data) {
  return axios.patch(`${process.env.REACT_APP_CALL_BASE_URL}/calls/${callId}`, data);
}

export function getOrganization(organizationId) {
  return axios.get(`${process.env.REACT_APP_CALL_BASE_URL}/org/${organizationId}`);
}

export function getOrganizationToken(organizationId) {
  return axios.get(`${process.env.REACT_APP_CALL_BASE_URL}/org/${organizationId}/token`);
}

export function getCallsByStatus(filter) {
  return axios.get(`${process.env.REACT_APP_CALL_BASE_URL}/calls?status${filter}`);
}

export function postOrganization(payload) {
  return axios.post(`${process.env.REACT_APP_CALL_BASE_URL}/org/${payload.organizationId}`, payload);
}

export function getLastCallByUser() {
  return axios.get(`${process.env.REACT_APP_CALL_BASE_URL}/calls?latest=true`);
}

export function getCall(callLogId) {
  return axios.get(`${process.env.REACT_APP_CALL_BASE_URL}/calls/${callLogId}`);
}
