import { schema } from 'normalizr';

// Create base Entities
const bandwidthNumberId = new schema.Entity('bandwidthNumberId');
const channel = new schema.Entity('channels');
const creator = new schema.Entity('creators');
const contact = new schema.Entity('contacts');
const connectedParty = new schema.Entity('connectedParties', {}, { idAttribute: 'connectionId' });
const email = new schema.Entity('emails');
const event = new schema.Entity('events');
const eventMention = new schema.Entity('eventMentions');
const mention = new schema.Entity('mention');
const existingNumber = new schema.Entity('existingNumber');
const facebook = new schema.Entity('facebooks');
const instagram = new schema.Entity('instagrams');
const rhinogram = new schema.Entity('rhinograms');
const group = new schema.Entity('groups');
const messageTemplate = new schema.Entity('messageTemplates');
const office = new schema.Entity('offices');
const organization = new schema.Entity('organizations');
const outOfOffice = new schema.Entity('outOfOffices');
const paymentRequest = new schema.Entity('paymentRequests', {}, { idAttribute: 'userId' });
const phone = new schema.Entity('phones');
const prefix = new schema.Entity('prefixes');
const role = new schema.Entity('roles');
const savedContentItem = new schema.Entity('savedContentItems');
const systemRole = new schema.Entity('systemRoles');
const customRole = new schema.Entity('customRoles');
const sender = new schema.Entity('senders');
const suffix = new schema.Entity('suffixes');
const tag = new schema.Entity('tags');
const timeZone = new schema.Entity('timeZones');
const type = new schema.Entity('types');
const language = new schema.Entity('languages');
const user = new schema.Entity('users');
const appointmentType = new schema.Entity('appointmentTypes');
const appointmentStatus = new schema.Entity('appointmentStatus');
const provider = new schema.Entity('provider');
const calls = new schema.Entity('calls', {}, { idAttribute: '_id' });
const contactForm = new schema.Entity('contactForm', {}, { idAttribute: '_id' });
const appointmentCampaign = new schema.Entity('appointmentCampaigns');

// Create shapes
const userShape = {
  tags: [tag],
  groups: [group],
  routedChannels: [channel],
  phones: [phone],
  emails: [email],
  connectedParties: [connectedParty],
  facebooks: [facebook],
  instagrams: [instagram],
  rhinograms: [rhinogram],
  organization,
};

const formShape = {
  createdFor: user,
};

const channelShape = {
  details: {
    phone,
    email,
    forwardingPhone: phone,
    bandwidthNumberId,
    existingNumber,
  },
  type,
  tags: [tag],
};

const eventMentionShape = {
  user,
  sender,
  type,
  event: [event],
  channels: [channel],
};

const mentionShape = {
  user,
  sender,
  users: [user],
};

const eventShape = {
  user,
  sender,
  type,
  group,
  groups: [group],
  channels: [channel],
};

const phoneShape = {
  type,
};

const emailShape = {
  type,
};

const groupShape = {
  routedChannels: [channel],
  users: [user],
  tags: [tag],
};

const rolesShape = {
  users: [user],
};

const systemRolesShape = {
  users: [user],
};

const customRolesShape = {
  users: [user],
};

const outOfOfficeShape = {
  channels: [channel],
};

const organizationShape = {
  defaultChannel: channel,
  channels: [channel],
};

const savedContentItemShape = {
  creator,
  contact,
  events: [event],
};

const appointmentCampaignShape = {
  appointmentTypes: [appointmentType],
};

// Define Entities
channel.define(channelShape);
contact.define(userShape);
creator.define(userShape);
email.define(emailShape);
event.define(eventShape);
eventMention.define(eventMentionShape);
mention.define(mentionShape);
group.define(groupShape);
phone.define(phoneShape);
sender.define(userShape);
user.define(userShape);
organization.define(organizationShape);
outOfOffice.define(outOfOfficeShape);
role.define(rolesShape);
systemRole.define(systemRolesShape);
customRole.define(customRolesShape);
savedContentItem.define(savedContentItemShape);
contactForm.define(formShape);
appointmentCampaign.define(appointmentCampaignShape);

// Export all Entities
export {
  appointmentType,
  provider,
  appointmentStatus,
  channel,
  connectedParty,
  contact,
  creator,
  customRole,
  email,
  event,
  eventMention,
  facebook,
  instagram,
  rhinogram,
  group,
  language,
  mention,
  messageTemplate,
  office,
  organization,
  outOfOffice,
  paymentRequest,
  phone,
  prefix,
  role,
  savedContentItem,
  sender,
  suffix,
  systemRole,
  tag,
  timeZone,
  type,
  user,
  calls,
  contactForm,
  appointmentCampaign,
};
