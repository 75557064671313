import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export function createErrorLog(error, info) {
  const payload = { error: { message: error.message, stack: error.stack }, info };
  return axios.post(`${process.env.REACT_APP_LOG_BASE_URL}/log`, payload, {
    ...!axios?.defaults?.headers?.common?.Authorization && {
      headers: { Authorization: 'legacy' }, // both values needed for ApiGateway
    },
  });
}
