import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Input,
  Button,
  Date,
  Select,
} from 'rhinostyle';
import moment from 'moment-timezone';
import { useCreateSubscriptionMutation, useUpdateSubscriptionMutation } from '../services/SubscriptionService';
import NotificationService from '../services/NotificationService';
import useFetchSubscription from '../hooks/useFetchSubscription';
import PageLoader from './PageLoader';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import { ValidationHelpers } from '../helpers';

const SubscriptionComponent = () => {
  const defaultSubscription = {
    effectiveDate: '',
    messageLimitCount: '',
    messageLimitType: 1,
    growthCount: '',
    growthPrice: '',
    salesforceLink: '',
    id: 0,
  };

  const options = [
    { id: 1, value: '---' },
    { id: 2, value: 'Monthly' },
    { id: 3, value: 'Yearly' },
  ];

  const [formInputs, setFormInputs] = useState(defaultSubscription);
  const organizationId = useSelector((state) => state.auth.currentOrg);
  const [createSubscription] = useCreateSubscriptionMutation();
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const { data, isLoading: isLoadingData } = useFetchSubscription(organizationId);
  const [errors, setErrors] = useState({});
  const pageContainer = useRef();

  useEffect(() => {
    if (data) {
      const subscriptionData = data.subscription;
      setFormInputs({
        effectiveDate: subscriptionData.effectiveDate != null ? moment(subscriptionData.effectiveDate) : '',
        messageLimitCount: subscriptionData.messageLimitCount === 0 ? '' : subscriptionData.messageLimitCount.toString(),
        messageLimitType: subscriptionData.messageLimitType || 1,
        growthCount: subscriptionData.growthCount === 0 ? '' : subscriptionData.growthCount.toString(),
        growthPrice: subscriptionData.growthPrice === 0 ? '' : subscriptionData.growthPrice.toString(),
        salesforceLink: subscriptionData.salesforceLink || '',
        id: subscriptionData.id || 0,
      });
    }
  }, [data]);

  if (isLoadingData) {
    return <PageLoader />;
  }

  const handleChange = (fieldName, value) => {
    setFormInputs((current) => ({ ...current, [fieldName]: value }));
  };

  const handleSelect = (fieldName, value) => {
    setFormInputs((current) => ({ ...current, [fieldName]: value }));
  };

  const handleDateChange = (date) => {
    setFormInputs((current) => ({ ...current, effectiveDate: date }));
  };

  const handleCancel = () => {
    setFormInputs(defaultSubscription);
  };

  const validForm = (inputs) => Object.entries(inputs)
    // Exclude 'id', and only exclude 'messageLimitType' if its value is 1
    .filter(([key, value]) => key !== 'id' && !(key === 'messageLimitType' && value === 1))
    .some(([, value]) => value !== '' && value !== '0.00');

  const handleSave = async () => {
    const isformValid = validForm(formInputs);
    if (isformValid) {
      const newSubscriptionData = {
        id: formInputs.id,
        effectiveDate: formInputs.effectiveDate ? moment(formInputs.effectiveDate).format('YYYY-MM-DD') : '',
        messageLimitCount: formInputs.messageLimitCount.replace(/,/g, ''),
        messageLimitType: Number(formInputs.messageLimitType),
        growthCount: formInputs.growthCount.replace(/,/g, ''),
        growthPrice: formInputs.growthPrice.replace(/,/g, ''),
        salesforceLink: formInputs.salesforceLink,
      };

      setErrors({});
      const saveErrors = ValidationService(ValidationShapers.shapeSubscriptionMessage({ ...newSubscriptionData }));
      const errorCount = Object.keys(saveErrors).length;
      if (errorCount > 0) {
        setErrors(saveErrors);
        ValidationHelpers.handleValidationErrors(errors, pageContainer.current);
      } else {
        newSubscriptionData.growthCount = Number(newSubscriptionData.growthCount);
        newSubscriptionData.growthPrice = Number(newSubscriptionData.growthPrice);
        newSubscriptionData.messageLimitCount = Number(newSubscriptionData.messageLimitCount);
        const notificationMessage = formInputs.id ? 'updateSubscription' : 'createSubscription';
        const response = formInputs.id ?
          await updateSubscription({ data: newSubscriptionData, organizationId }) :
          await createSubscription({ data: newSubscriptionData, organizationId });

        if (response?.data) {
          NotificationService(notificationMessage, { status: 200 });
        } else {
          NotificationService(notificationMessage);
        }
      }
    }
  };

  return (
    <div>
      <div className="app-page__container" ref={pageContainer}>
        <div className="app-page__container__inner">
          <div className="app-page__header">
            <div className="app-page__header__title" data-cypress="orgProfileTitle">
              Subscription
            </div>
          </div>

          <form>
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">CONTRACT INFORMATION</div>
              </div>

              <div className="form__group">
                <div className="row">
                  <div className="column-4@small">
                    <Date
                      id="effectiveDate"
                      label="Effective Date"
                      name="effectiveDate"
                      explanationMessage="MM/DD/YYYY"
                      dateFormat="MM/DD/YYYY"
                      selected={formInputs.effectiveDate}
                      onChange={(date) => handleDateChange(date)}
                      validationMessage={errors.effectiveDate}
                    />
                  </div>
                  <div className="column-4@small">
                    <Input
                      label="Message Limit Count"
                      name="messageLimitCount"
                      initialValue={formInputs.messageLimitCount}
                      format={{
                        numeral: true,
                        numeralPositiveOnly: true,
                        numeralDecimalScale: 2,
                        numeralThousandsGroupStyle: 'thousand',
                      }}
                      onChange={handleChange}
                      validationMessage={errors.messageLimitCount}
                    />
                  </div>

                  <div className="column-4@small">
                    <Select
                      name="messageLimitType"
                      label="Message Limit Type"
                      options={options}
                      onSelect={handleSelect}
                      selected={formInputs.messageLimitType}
                    />
                  </div>
                </div>
              </div>

              <div className="form__group">
                <div className="row">
                  <div className="column-4@small">
                    <Input
                      label="Growth Count"
                      format={{
                        numeral: true,
                        numeralPositiveOnly: true,
                        numeralDecimalScale: 0,
                      }}
                      name="growthCount"
                      onChange={handleChange}
                      initialValue={formInputs.growthCount}
                      validationMessage={errors.growthCount}
                    />
                  </div>
                  <div className="column-4@small">
                    <Input
                      label="Growth Price"
                      name="growthPrice"
                      format={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
                      onChange={handleChange}
                      initialValue={formInputs.growthPrice}
                      validationMessage={errors.growthPrice}
                    />
                  </div>
                </div>
              </div>
              <div className="form__group">
                <div className="row">
                  <div className="column-7@small">
                    <Input
                      label="Link to SalesForce Profile"
                      name="salesforceLink"
                      onChange={handleChange}
                      initialValue={formInputs.salesforceLink}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="u-m-t-large u-text-right">
              <Button type="link" onClick={handleCancel}>
                Cancel
              </Button>

              <Button type="primary" onClick={handleSave}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionComponent;
