import { createSelector } from '@reduxjs/toolkit';
import { getLoggedInUser } from './userSelectors';
import { DataHelpers } from '../helpers';
import StorageService, { readCookie } from '../services/StorageService';

const getSystemAlert = (state) => state.systemAlert.systemAlert;
const getShowSystemAlert = (state) => state.ui.showSystemAlert;

export const getActiveSystemAlert = createSelector( //eslint-disable-line
  [getSystemAlert, getLoggedInUser, getShowSystemAlert],
  (systemAlert, loggedInUser, showSystemAlert) => {
    const isSystemAlertVisible = DataHelpers.hasData(systemAlert)
      && showSystemAlert
      && loggedInUser
      && !loggedInUser.isCcr
      && Number(readCookie(`rhinogram-${loggedInUser.id}-systemAlert`)) !== systemAlert.id
      && systemAlert.active && !StorageService.readEntry('multiOrgs') && !StorageService.readEntry('patientExperience');
    if (isSystemAlertVisible) {
      return systemAlert;
    }

    return null;
  },
);
