import { createSelector } from '@reduxjs/toolkit';

const getChannels = (state) => state.channel.channels;
const getChannelIds = (state) => state.channel.channelIds;
// only return channels thtat are not deleted.
export const getActiveChannelIds = createSelector(
  [getChannels, getChannelIds],
  (channels, channelIds) =>
    channelIds.filter((id) => !channels[id].deleted),
);

// only get active channels that are sms/landline
export const getAutomatedMessageChannelIds = createSelector(
  [getChannels, getChannelIds],
  (channels, channelIds) =>
    channelIds.filter((id) => !channels[id].deleted && (channels[id].typeId === 10 || channels[id].typeId === 11 || channels[id].typeId === 12)),
);

export const getRhinocallChannelIds = createSelector(
  [getChannels, getChannelIds],
  (channels, channelIds) =>
    channelIds.filter((id) => !channels[id].deleted && (channels[id].isRhinocallEnabled)),
);

export const getNonRoutedChannelIds = createSelector(
  [getChannels, getChannelIds],
  (channels, channelIds) =>
    channelIds.filter((id) => !channels[id].route || !channels[id].route.name),
);

export const getRoutedChannelIds = createSelector(
  [getChannels, getChannelIds],
  (channels, channelIds) =>
    channelIds.filter((id) => channels[id].route && channels[id].route.name),
);
