import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../helpers/AxiosHelper';
import { APPOINTMENTS_PAGE_SIZE } from '../constants/AppConstants';

export const appointmentSlice = createApi({
  reducerPath: 'userAppointments',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['UserAppointments', 'ManagerAppointments'],
  endpoints: (builder) => ({
    getUserAppointments: builder.query({
      query: ({ userId, orgId, isUpcoming = false, page = 0 }) => {
        const params = {
          isUpcoming,
          page,
          pageSize: APPOINTMENTS_PAGE_SIZE,
        };

        return { url: `/appointment/${orgId}/user/${userId}`, method: 'get', params };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        // Omit page param from cache key
        const {
          userId,
          isUpcoming,
        } = queryArgs;
        return { userId, isUpcoming };
      },
      providesTags: ['UserAppointments'],
    }),
    updateUserAppointments: builder.mutation({
      query: ({ userId, data }) => ({ url: `/users/${userId}/appointment`, method: 'patch', data }),
      invalidatesTags: ['UserAppointments'],
    }),
    getAppointments: builder.query({
      query: ({
        fromDate,
        toDate,
        pageNumber,
        pageSize,
        sort,
        officeIds,
        appointmentTypeIds,
        integrationStatusTypeIds,
        providerIds,
        appointmentStatusTypeIds,
      }) => {
        const params = {
          from: fromDate,
          to: toDate,
          pageNo: pageNumber,
          pageSize,
          sort,
          officeIds,
        };
        if (appointmentTypeIds?.length > 0) {
          params.appointmentTypeIds = JSON.stringify(appointmentTypeIds);
        }
        if (integrationStatusTypeIds?.length > 0) {
          params.integrationStatusTypeIds = JSON.stringify(integrationStatusTypeIds);
        }
        if (providerIds?.length > 0) {
          params.providerIds = JSON.stringify(providerIds);
        }
        if (appointmentStatusTypeIds?.length > 0) {
          params.appointmentStatusTypeIds = JSON.stringify(appointmentStatusTypeIds);
        }

        return { url: '/appointment/appointmentManager', method: 'get', params };
      },
    }),

  }),
});

export const {
  useGetUserAppointmentsQuery,
  useUpdateUserAppointmentsMutation,
  useGetAppointmentsQuery,
  useLazyGetAppointmentsQuery,
} = appointmentSlice;

export default appointmentSlice;
