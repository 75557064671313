import { createSelector } from '@reduxjs/toolkit';
import { DataHelpers } from '../helpers';

const getTagIds = (state) => state.tag.tagIds;
const getTags = (state) => state.tag.tags;
const getTypes = (state) => state.type.types;

export const getLocationTagIds = createSelector(
  [getTagIds, getTags, getTypes],
  (tagIds, tags, types) => (
    DataHelpers.hasData(types) && DataHelpers.hasData(tags) ? tagIds.filter((id) => types[tags[id].typeId].value === 'locations') : []
  ),
);

export const getDepartmentTagIds = createSelector(
  [getTagIds, getTags, getTypes],
  (tagIds, tags, types) => (
    DataHelpers.hasData(types) && DataHelpers.hasData(tags) ? tagIds.filter((id) => types[tags[id].typeId].value === 'departments') : []
  ),
);

export const getRoleTagIds = createSelector(
  [getTagIds, getTags, getTypes],
  (tagIds, tags, types) => (
    DataHelpers.hasData(types) && DataHelpers.hasData(tags) ? tagIds.filter((id) => types[tags[id].typeId].value === 'roles') : []
  ),
);

export const getCustomTagIds = createSelector(
  [getTagIds, getTags, getTypes],
  (tagIds, tags, types) => (
    DataHelpers.hasData(types) && DataHelpers.hasData(tags) ? tagIds.filter((id) => types[tags[id].typeId].value === 'custom') : []
  ),
);
