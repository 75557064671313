import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  Icon,
  Resource,
  ResourceBody,
  ResourceIntro,
  ResourceRight,
} from 'rhinostyle';
import { DateHelpers, AttachmentHelpers, UserHelpers } from '../helpers';
import { Types } from '../constants';
import { ASSIGNMENT_METHOD_AUTOMATIC, ASSIGNMENT_METHOD_MANUAL, ASSIGNMENT_METHOD_SUGGESTED } from '../constants/AssignmentConstants';
import { getEventById } from '../selectors/inboxSelectors';
import { getLoggedInUserOrganization, getLoggedInUser, userHasLimitedProviderRole } from '../selectors/userSelectors';
import { useThreadEventShaper } from '../hooks';

const InboxEvent = (props) => {
  const {
    currentUserId,
    event: inboxEvent,
    handleCheckboxOnItem,
    handleInboxItemClick,
    inboxContext,
    isCcr,
    isChecked,
    sender,
    updateFollowing,
    userOrganization,
    isLimitedProvider,
  } = props;

  const event = useThreadEventShaper({ event: inboxEvent });
  const { formattedActiveUserName, activeUser } = event;
  const isConsentEvent = event.isRhinopayEvent || event.isMarketingEvent || event.isHipaaEvent;
  const formattedFromUserName = event.formattedFromUserName === 'Rhino System' ? userOrganization.name : event.formattedFromUserName;
  const formattedConsentStatus = UserHelpers.formatEventConsentStatus(event);

  const renderConsentEvent = () => (
    <>
      Consent <Icon icon="arrow-right" className="inbox__item__body__arrow-right" />
      marked {formattedConsentStatus} by {formattedFromUserName}
    </>
  );

  const renderFormSentEvent = () => (
    <>
      Form <Icon icon="arrow-right" className="inbox__item__body__arrow-right" />
      {event.formStatus} by {formattedFromUserName}
    </>
  );

  const renderMergeEvent = () => {
    const eventText = <>Contact <strong className="u-p-xsmall u-p-t-0 u-p-b-0">Merged</strong> into</>;
    const { firstName, lastName } = event.nonMaster;
    const isNonMasterNameAvailable = firstName || lastName;
    const nonMasterContact = firstName && lastName ? `${firstName} ${lastName}` : (firstName || lastName);
    const mergeText = <>{nonMasterContact} <strong>Merged</strong> into </>;
    return (
      <>
        {isNonMasterNameAvailable ? mergeText : eventText} {formattedActiveUserName}&#39;s profile by {formattedFromUserName}
      </>
    );
  };
  function isConfirmedAssignment() {
    if (event.assignedTextClassificationType === event.suggestedTextClassificationType) {
      if (event.toGroupId) {
        return event.toGroupId === event.suggestedGroupId;
      }
      if (event.toUserId) {
        return event.toUserId === event.suggestedMemberId;
      }
    }
    return false;
  }

  const renderAssignmentEvent = () => {
    const isAutoRouted = event.assignmentMethod === ASSIGNMENT_METHOD_AUTOMATIC;
    const hasSuggestedRoute = event.suggestedTextClassificationType || event.suggestedGroupId || event.suggestedGroupId;
    let text = '';
    if (hasSuggestedRoute) {
      if (isConfirmedAssignment()) {
        text = `${isAutoRouted ? 'Auto Routed' : 'Confirmed Suggested Assign'} by ${formattedFromUserName}`;
      } else {
        text = `${isAutoRouted ? 'Auto Routed' : 'Manually Assigned'} by ${formattedFromUserName}`;
      }
    } else if (event.isMarkedComplete) {
      text = event.assignedBy ? `Assignment Marked Complete by ${formattedFromUserName}` : '';
      const name = event.assignedBy === 'Rhino System' ? userOrganization.name : formattedFromUserName;
      text = event.assignedBy ? `Assignment Marked Complete by ${name}` : '';
    } else {
      text = (event.to && formattedFromUserName) ? `Assigned to ${event.to} by ${formattedFromUserName}` : '';
    }

    return text;
  };

  const renderSavedContentEvent = () => `Message selection saved by ${formattedFromUserName}`;

  const renderSentSavedContentEvent = () => {
    if (event.libraryTypeId === Types.TYPE_USER_LIBRARY_FORM) {
      return `Completed Form sent by ${formattedFromUserName}`;
    }
    return `Message Selection sent by ${formattedFromUserName}`;
  };

  const renderCopyContentEvent = () => `File ${event.title} was copied by ${formattedFromUserName}`;

  const unread = !event.read;
  const inboxClasses = cx('inbox__item', {
    'inbox__item--following': event.following,
  });

  const renderEventText = () => {
    const { isNoteEvent, isMessageEvent, isIncoming } = event;

    const classes = cx('inbox__event__message', {
      'msg--default': isMessageEvent && isIncoming,
      'msg--primary--outbound': isMessageEvent && !isIncoming,
      'msg--note': isNoteEvent,
    });
    if (event.language?.isRightToLeft) {
      return <div className="u-text-rtl u-inline-block">{event.text}</div>;
    }
    if (event.isMessageFailed) {
      return <strong className="u-text-danger">Message Failed</strong>;
    }
    return (
      <span className={classes}>
        {UserHelpers.formatMentionString(event.text, event.eventMentions)}
      </span>
    );
  };

  const renderEventAttachment = () => {
    const { isMessageEvent, isIncoming } = event;

    const classes = cx('inbox__event__message', 'msg--attachment', {
      'msg--default': isMessageEvent && isIncoming,
      'msg--primary--outbound': isMessageEvent && !isIncoming,
    });
    return (
      <span>
        Attachment: <span className={classes}> {AttachmentHelpers.getAttDisplayName(event.attachments[0])} </span>
        <Icon icon="attachment" className="inbox__item__attachment-icon" />
      </span>
    );
  };

  const renderVideoEvent = () => (
    <>
      {formattedFromUserName}&apos;s RhinoVideo session with {formattedActiveUserName} ended.
    </>
  );

  const renderCallEvent = () => (
    <>
      {formattedFromUserName}&apos;s call with {formattedActiveUserName} ended.
    </>
  );

  const showFromSender = !!(!event.isIncoming && !event.consentStatus && event.text);
  const formattedSenderName = currentUserId === event.senderId ? 'You' : formattedFromUserName;
  const checkboxProperty = !isCcr ? {
    checkbox: {
      name: 'inboxThread',
      label: ' ',
      isChecked,
      onChange: () => handleCheckboxOnItem(event.id, event),
    },
  } : null;

  const renderAssignmentMethodLabel = () => {
    const {
      assigned,
      assignmentMethod,
      startAssignmentMethod,
      toGroupId,
      suggestedGroupId,
    } = event;

    const assignedFlag = assigned && inboxContext === 'group';
    const isAutoRouted = assignmentMethod === ASSIGNMENT_METHOD_AUTOMATIC && startAssignmentMethod === ASSIGNMENT_METHOD_AUTOMATIC;
    const isRoutedBySuggestion = assignedFlag
      && startAssignmentMethod === ASSIGNMENT_METHOD_SUGGESTED
      && (toGroupId && toGroupId === suggestedGroupId);
    const isManuallyAssigned = assignedFlag
      && !isRoutedBySuggestion
      && assignmentMethod === ASSIGNMENT_METHOD_MANUAL;

    let labelText;

    if (isAutoRouted) {
      labelText = 'Auto Routed';
    }
    if (isRoutedBySuggestion) {
      labelText = 'Assigned By Suggestion';
    }
    if (isManuallyAssigned) {
      labelText = 'Assigned';
    }

    if (labelText) {
      const labelClass = cx('u-text-accent', {
        'u-text-success': isAutoRouted,
        'u-text-teal': isRoutedBySuggestion,
      });

      return <span className={labelClass}>{labelText}</span>;
    } else {
      return null;
    }
  };

  return (
    <Resource
      className={inboxClasses}
      id={`js-inbox__item-${event.eventUserId}`}
      dataCypress={`inbox-item-${event.eventUserId}`}
      unread={unread}
      onClick={() => handleInboxItemClick(event.eventUserId, event.id)}
    >
      <ResourceIntro
        icon={!isLimitedProvider ? {
          icon: 'star',
          bump: 'down',
          onClick: (e) => updateFollowing(event.eventUserId, !event.following, e, event.read),
        } : null}
        {...checkboxProperty}
        title={formattedActiveUserName}
        titleSub={activeUser && UserHelpers.formatTypes(activeUser)}
      >
        {renderAssignmentMethodLabel()}
      </ResourceIntro>
      <ResourceBody className="inbox__item__body--flex">
        {event.isMessageFailed && <Icon icon="alert-triangle" bump="up" className="inbox__item__body__failed-icon" />}
        {event.isIncoming
          && event.fromWebForm
          && <>{unread ? <strong>Web Form</strong> : <span>Web Form</span>}&nbsp;<Icon icon="arrow-right" className="icon--bump-up inbox__item__body__arrow-right" /></>}
        {showFromSender && <>{unread ? <strong>{formattedSenderName}</strong> : formattedSenderName}&nbsp;<Icon icon="arrow-right" className="icon--bump-up inbox__item__body__arrow-right" />&nbsp;</>}
        {event.text && renderEventText()}
        {event.text && event.attachments?.length ? <Icon icon="attachment" className="inbox__item__attachment-icon" /> : null}
        {!!(!event.text && event.attachments?.length) && renderEventAttachment()}
        {!event.text && event.forms?.length ? 'Attachment: Form' : null}
        {(!event.text && isConsentEvent) && renderConsentEvent()}
        {event.isRhinoformSendEvent && renderFormSentEvent()}
        {event.isCallEvent && renderCallEvent()}
        {event.isVideoChatEvent && renderVideoEvent()}
        {(!event.text && event.nonMaster) && renderMergeEvent()}
        {(!event.text && !isConsentEvent && !event.attachments.length) && renderAssignmentEvent()}
        {event.isSavedContentEvent && renderSavedContentEvent(sender)}
        {event.isSentSavedContentEvent && renderSentSavedContentEvent()}
        {event.isCopyContentEvent && renderCopyContentEvent()}
      </ResourceBody>
      <ResourceRight>
        <span title={DateHelpers.fullTimestamp(event.timestamp)}>
          {unread ? (<strong>{DateHelpers.formatTimestamp(event.timestamp)}</strong>) : DateHelpers.formatTimestamp(event.timestamp)}
        </span>
      </ResourceRight>
    </Resource>
  );
};

InboxEvent.propTypes = {
  checkboxProperty: PropTypes.object,
  currentOrg: PropTypes.object,
  currentUserId: PropTypes.number,
  event: PropTypes.object,
  eventId: PropTypes.number,
  handleCheckboxOnItem: PropTypes.func,
  handleInboxItemClick: PropTypes.func,
  history: PropTypes.object,
  inboxContext: PropTypes.string,
  isCcr: PropTypes.bool,
  isChecked: PropTypes.bool,
  sender: PropTypes.object,
  updateFollowing: PropTypes.func,
  user: PropTypes.object,
  userOrganization: PropTypes.object.isRequired,
  formattedActiveUserName: PropTypes.bool,
  isLimitedProvider: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  currentUserId: state.auth.currentUser,
  event: getEventById(state, props),
  isCcr: getLoggedInUser(state)?.isCcr,
  userOrganization: getLoggedInUserOrganization(state),
  isLimitedProvider: userHasLimitedProviderRole(state),
});

export default connect(mapStateToProps)(InboxEvent);
