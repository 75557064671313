import axios from 'axios';

// Creates a "clean" instance of axios minus our user defaults
export function defaultInstance(config) {
  const axiosInstance = axios.create({ withCredentials: false, ...config });

  delete axiosInstance.defaults.baseURL;
  delete axiosInstance.defaults.headers.common.Authorization;
  delete axiosInstance.defaults.headers.Expires;

  return axiosInstance;
}

// Base query for use in rtk query slices
export const axiosBaseQuery = ({ baseURL } = { baseURL: '' }) =>
  async (options) => {
    const { url, method, data, params, baseUrl, shapeResult } = options || {};
    const requestOptions = { url,
      method,
      data,
      params,
      ...!axios?.defaults?.headers?.common?.Authorization && {
        headers: { Authorization: 'legacy' }, // both values needed for ApiGateway
      } };
    if (baseUrl || baseURL) {
      requestOptions.baseURL = baseUrl || baseURL;
    }
    try {
      if (url) {
        const result = await axios(requestOptions);
        return { data: shapeResult ? shapeResult(result) : result?.data };
      } return { data: '' };
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
