import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Button,
  Icon,
  Resource,
  ResourceBody,
  ResourceGroup,
  ResourceIntro,
  ResourceRight,
  Scrollbars,
} from 'rhinostyle';
import OrganizationRolesPanel from './OrganizationRolesPanel';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { ROLE_CREATE, RHINOFORMS_ADMIN, INTEGRATED_CONTACTS_EDITOR, LIMITED_PROVIDER_ROLE } from '../constants/UserPermissionsConstants';
import { TYPE_INTEGRATION_AXIUM } from '../constants/Types';

const OrganizationRoles = (props) => {
  const {
    activeOrg,
    activeRole,
    handleCreateRequest,
    handleEditClick,
    handleMemberClick,
    handleTogglePanel,
    isCcr,
    isRhinoformEnabled,
    organizationRoles,
    panelOpen,
    users,
  } = props;

  const { isEditIntegratedContactEnabled, integrationPartnerTypeId } = activeOrg;
  const showLimitedProviderRole = integrationPartnerTypeId === TYPE_INTEGRATION_AXIUM;

  const isSkipRenderRole = (roleName) => (roleName === RHINOFORMS_ADMIN && !isRhinoformEnabled) ||
   (roleName === INTEGRATED_CONTACTS_EDITOR && !isEditIntegratedContactEnabled) ||
   (roleName === LIMITED_PROVIDER_ROLE && !showLimitedProviderRole);

  const renderRole = (role, key) => (
    (!isSkipRenderRole(role.name) ? (
      <Resource key={key} onClick={() => handleTogglePanel(role.id, role.systemRole)}>
        <ResourceIntro title={role.name} />
        <ResourceBody className="u-text-muted">{role.description}</ResourceBody>
        <ResourceRight>{`${role.users.length} ${role.users.length === 1 ? 'Member' : 'Members'}`}</ResourceRight>
      </Resource>
    ) : null
    ));

  const sortedRoles = organizationRoles.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  const rolesMinusCsrAndPatientRoles = sortedRoles.filter((role) => role.name !== 'CSR' && role.name !== 'Patient');

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': panelOpen,
  });

  return (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="app-page__header">
              <div className="app-page__header__title">Roles</div>
              {userHasAnyOfPermissions([ROLE_CREATE]) && (
                <div className="app-page__header__action">
                  <Button
                    onClick={handleCreateRequest}
                    iconOnly
                    type="secondary"
                    title="Create Member"
                    data-cypress="roleCreate"
                  >
                    <Icon icon="add" />
                  </Button>
                </div>
              )}
            </div>
            <div className="list-panel__body">
              <Scrollbars className="list-panel__body__scroll">
                <ResourceGroup>
                  {rolesMinusCsrAndPatientRoles.map(renderRole)}
                </ResourceGroup>
              </Scrollbars>
            </div>
          </div>
        </div>
        {panelOpen
          && activeRole
          && (
            <OrganizationRolesPanel
              activeOrg={activeOrg}
              activeRole={activeRole}
              handleEditClick={handleEditClick}
              handleMemberClick={handleMemberClick}
              handleTogglePanel={handleTogglePanel}
              isCcr={isCcr}
              users={users}
            />
          )}
      </div>
    </div>
  );
};

OrganizationRoles.propTypes = {
  activeOrg: PropTypes.object.isRequired,
  activeRole: PropTypes.object,
  handleCreateRequest: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleMemberClick: PropTypes.func.isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
  isCcr: PropTypes.bool,
  isRhinoformEnabled: PropTypes.bool.isRequired,
  organizationRoles: PropTypes.array.isRequired,
  panelOpen: PropTypes.bool.isRequired,
  users: PropTypes.object.isRequired,
};

export default OrganizationRoles;
