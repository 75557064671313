import React, { useCallback } from 'react';
import {
  Button,
  Icon,
  LoaderCircle,
  Scrollbars,
} from 'rhinostyle';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NumberHelpers } from '../helpers';
import { BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD,
} from '../constants/AppConstants';
import { getRhinoblastMaxPageSize } from '../selectors/organizationSelectors';
import PaginationControls from './PaginationControls';

const FilterPage = (props) => {
  const bulkMessagingCount = useSelector(getRhinoblastMaxPageSize);
  const {
    children,
    clearAllFilters,
    displayBottomPagination,
    handlePagination,
    hasPanels,
    header,
    loading,
    pageItemCount,
    pageNumber,
    pageSize = bulkMessagingCount,
    renderFilters,
    showClearAll,
    totalCount,
    type,
    hasFilters = true,
    paginationDisabled = false,
  } = props;
  const shouldDisplayPagination = useCallback((isTop) => !!(isTop ? totalCount > pageSize
    : totalCount > pageSize && pageItemCount > BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD), [totalCount, pageSize, pageItemCount]);

  const MemoizedPaginationControls = React.memo(PaginationControls);

  const renderPaginationControls = React.useCallback((isTop = false) => (shouldDisplayPagination(isTop) ? (
    <MemoizedPaginationControls
      pageNumber={pageNumber}
      totalCount={totalCount}
      pageSize={pageSize}
      handlePagination={handlePagination}
      disabled={loading || paginationDisabled}
      isTop={isTop}
    />
  ) : null), [pageNumber, totalCount, pageSize, handlePagination, loading, shouldDisplayPagination]);

  const wrapperClasses = cx('filter-page analytics__wrapper', {
    [`filter-page--${type}`]: type,
    'list-panel__outer__body': hasPanels,
  });

  const containerClasses = cx('filter-page__filter__container', {
    'u-flex-justify-between u-flex': type === 'prescriptions',
  });

  function renderFilterPage() {
    return (
      <>
        <div className="filter-page__header">
          {!!header && (<div className="analytics__header">{header} (<span data-cypress="totalUserCount">{NumberHelpers.formatNumberToDigitGroupingString(totalCount)}</span>)</div>
          )}
          {totalCount > pageSize && (
            renderPaginationControls(true)
          )}
        </div>
        {hasFilters && (
        <div className="filter-page__filter">
          <div className="filter-page__filter__label">FILTER BY:
            <span className="">
              {showClearAll && (
              <Button type="link" className="clear-all__button" onClick={clearAllFilters}>Clear All &nbsp;<Icon icon="close" /></Button>
              )}
            </span>
          </div>
          <div className={containerClasses}>
            {renderFilters()}
          </div>
        </div>
        )}
        {loading ? (
          <LoaderCircle className="u-text-primary u-absolute-center" />
        ) : (
          <div className="list-panel__body">
            {children}
            <div className="row">
              <div className="column-12@xsmall column-12@medium convo__bottom__pagination">
                {displayBottomPagination && renderPaginationControls(false)}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  if (type === 'contacts' || type === 'prescription-notifications') {
    return (
      <div className={wrapperClasses}>
        <Scrollbars className="list-panel__body__scroll list-panel__body__scroll--no-border u-m-b">
          {renderFilterPage()}
        </Scrollbars>
      </div>
    );
  }
  return (
    <div className={wrapperClasses}>
      {renderFilterPage()}
    </div>
  );
};

FilterPage.propTypes = {
  children: PropTypes.node,
  clearAllFilters: PropTypes.func,
  displayBottomPagination: PropTypes.bool,
  handlePagination: PropTypes.func,
  hasPanels: PropTypes.bool,
  header: PropTypes.string,
  loading: PropTypes.bool,
  pageItemCount: PropTypes.number,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  renderFilters: PropTypes.func,
  showClearAll: PropTypes.bool,
  totalCount: PropTypes.number,
  type: PropTypes.string,
  hasFilters: PropTypes.bool,
  paginationDisabled: PropTypes.bool,
};

export default FilterPage;
