import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import OrganizationBillingHistory from '../components/OrganizationBillingHistory';
import { fetchBillingStatements, resetBillingPaymentHistory } from '../reducers/billingReducer';
import { PAGE_SIZE } from '../constants/AppConstants';

class OrganizationBillingHistoryContainer extends PureComponent {
  state = {
    index: 0,
    pageNo: 1,
    statements: [],
    isLoading: false,
  };

  // eslint-disable-next-line camelcase
  componentDidMount() {
    this.setState({
      statements: this.props.statements,
    });
  }

  // eslint-disable-next-line camelcase
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        statements: this.props.statements,
        isLoading: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetBillingPaymentHistory();
  }

  handlePdfClick = (id) => {
    this.setState({
      index: id,
    });
  }

  handlePdfViewerClose = () => {
    this.setState({
      index: 0,
    });
  }

  loadMore = () => {
    if (this.props.statements.length === this.props.totalStatements) {
      const temp = this.props.statements;
      this.setState({
        statements: temp.slice(0, PAGE_SIZE * (this.state.pageNo + 1)),
        pageNo: this.state.pageNo + 1,
      });
    } else {
      this.props.fetchBillingStatements(this.props.billManagerCustId, this.state.pageNo);
      this.setState({
        pageNo: this.state.pageNo + 1,
        isLoading: true,
      });
    }
  }

  viewLess = () => {
    this.state.statements = this.state.statements.slice(0, PAGE_SIZE);
    this.setState({
      pageNo: 1,
    });
  }

  render() {
    const props = {
      statements: this.state.statements,
      renderPDF: this.renderPDF,
      handlePdfClick: this.handlePdfClick,
      handlePdfViewerClose: this.handlePdfViewerClose,
      loadMore: this.loadMore,
      hasMore: this.state.statements.length < this.props.totalStatements,
      hasLess: this.state.statements.length === this.props.totalStatements && this.state.statements.length > PAGE_SIZE,
      viewLess: this.viewLess,
      index: this.state.index,
      totalStatements: this.props.totalStatements,
      isLoading: this.state.isLoading,
    };

    return (<OrganizationBillingHistory {...props} />);
  }
}

OrganizationBillingHistoryContainer.propTypes = {
  statements: PropTypes.array.isRequired,
  totalStatements: PropTypes.number.isRequired,
  fetchBillingStatements: PropTypes.func.isRequired,
  billManagerCustId: PropTypes.number,
  resetBillingPaymentHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { billing } = state;
  return {
    statements: billing.statements,
    billManagerCustId: billing.billing.billManagerCustId,
    totalStatements: billing.totalStatements,
  };
};

const actions = {
  fetchBillingStatements,
  resetBillingPaymentHistory,
};

export default connect(mapStateToProps, actions)(OrganizationBillingHistoryContainer);
