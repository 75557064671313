import axios from 'axios';
import { normalize } from 'normalizr';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import NotificationService from '../services/NotificationService';
import { mergeShallow, getObjectKeys, exists } from '../helpers/DataHelpers';
import { deleteIdFromArray, addIdToArray } from '../helpers/ImmerHelpers';
import * as GroupActionTypes from '../constants/GroupActionTypes';
import * as OrganizationActionTypes from '../constants/OrganizationActionTypes';
import * as OutOfOfficeActionTypes from '../constants/OutOfOfficeActionTypes';
import * as SecureActionTypes from '../constants/SecureActionTypes';
import * as AuthActionTypes from '../constants/AuthActionTypes';
import * as ChatActionTypes from '../constants/ChatActionTypes';

import * as InboxActionTypes from '../constants/InboxActionTypes';
import * as UserActionTypes from '../constants/UserActionTypes';
import { setError } from './uiReducer';
import { channel, tag } from '../actions/NormalizrSchema';

import * as AppointmentActionTypes from './appointmentReducer';
import * as SavedContentActionTypes from '../constants/SavedContentActionTypes';
import * as TagReducer from './tagReducer';

export const fetchChannelById = createAsyncThunk(
  'CHANNEL/fetchChannel',
  async (channelId) => {
    const response = await axios.get(`/channels/${channelId}`);
    return response.data;
  },
  {
    condition: (channelId, { getState }) => {
      const { channel: { channels } } = getState();
      const fetchStatus = channels[channelId];
      return !exists(fetchStatus);
    },
  },
);

export const fetchChannelsThunk = createAsyncThunk(
  'CHANNEL/fetchChannelsThunk',
  async () => {
    const response = await getChannels();
    const normalized = normalize(response.data, [channel]);
    return getChannelsPayload(normalized);
  },
  {
    condition: (_, { getState }) => {
      const { channel: { loading, channelIds } } = getState();
      return !loading && !channelIds?.length;
    },
  },
);
// SLICE
const channelSlice = createSlice({
  name: 'CHANNEL',
  initialState: {
    loading: false,
    availableBandwidthNumbers: [],
    bandwidthSearchLoading: false,
    channels: {},
    channelIds: [],
  },
  reducers: {
    // Immutable updates
    receiveChannels: receiveChannelsData,
    receiveChannelView: receiveChannelsData,
    // Immer updates
    receiveCreateChannel: receiveChannelData,
    receiveUpdateChannel: receiveChannelData,
    removeChannel: removeChannelData,
    requestData: (state) => {
      state.loading = true;
    },
    receiveBandwidthSearch: (state, action) => {
      state.availableBandwidthNumbers = action.payload.availableBandwidthNumbers;
      state.bandwidthSearchLoading = false;
    },
    requestSearchData: (state) => {
      state.bandwidthSearchLoading = true;
    },
    setClearedBandwidthNumbers: (state) => {
      state.availableBandwidthNumbers = [];
    },
  },
  extraReducers: {
    [InboxActionTypes.receiveInboxThreadView]: receiveChannelsData,

    [OutOfOfficeActionTypes.receiveOutOfOffices]: receiveChannelsData,
    [OutOfOfficeActionTypes.receiveOOOView]: receiveChannelsData,

    [ChatActionTypes.receiveChatThreadUserView]: receiveChannelsData,
    [ChatActionTypes.receiveChatThreadGroupView]: receiveChannelsData,

    [OrganizationActionTypes.receiveOrganizationPreferencesView]: receiveChannelsData,

    [AuthActionTypes.setUser]: receiveChannelsData,

    [UserActionTypes.receiveMyUsers]: receiveChannelsData,
    [UserActionTypes.receiveUsers]: receiveChannelsData,
    [UserActionTypes.receiveContactUsers]: receiveChannelsData,
    [UserActionTypes.receiveContactList]: receiveChannelsData,
    [UserActionTypes.receiveMembersView]: receiveChannelsData,
    [UserActionTypes.receiveMembersList]: receiveChannelsData,

    [AppointmentActionTypes.receiveAppointments]: receiveChannelsData,

    [GroupActionTypes.receiveGroupView]: receiveChannelsData,
    [GroupActionTypes.receiveGroups]: receiveChannelsData,

    [SecureActionTypes.receivePatientThreadChannelView]: receiveChannelsData,
    [SecureActionTypes.receivePatientThreadAllView]: receiveChannelsData,

    [SavedContentActionTypes.receiveEventsForSavedContent]: receiveSavedContentChannelsData,
    [fetchChannelById.pending]: (state, action) => {
      const channelId = action.meta.arg;
      return receiveChannelFetchData(state, { payload: { channelIds: [channelId], channels: { [channelId]: { status: 'pending' } } } });
    },
    [fetchChannelById.fulfilled]: (state, action) => {
      const normalized = normalize(action.payload, channel);
      return receiveChannelFetchData(state, { payload: getChannelsPayload(normalized) });
    },
    [fetchChannelsThunk.fulfilled]: receiveChannelsData,
  },
});

export default channelSlice.reducer;

// ACTIONS
export const {
  receiveChannels,
  receiveChannelView,
  receiveCreateChannel,
  receiveUpdateChannel,
  removeChannel,
  requestData,
  receiveBandwidthSearch,
  requestSearchData,
  setClearedBandwidthNumbers,
} = channelSlice.actions;

// REDUCER HELPERS

// Immutable updates
function receiveChannelsData(state, action) {
  return {
    ...state,
    channels: mergeShallow(state.channels, action.payload.channels),
    channelIds: [...new Set([...state.channelIds, ...action.payload.channelIds])],
    loading: false,
  };
}

function receiveChannelFetchData(state, action) {
  return {
    ...state,
    channels: mergeShallow(state.channels, action.payload.channel),
    channelIds: [...new Set([...state.channelIds, ...action.payload.channelIds])],
  };
}

function receiveSavedContentChannelsData(state, action) {
  return {
    ...state,
    channels: mergeShallow(action.payload.channels || {}, state.channels),
    channelIds: [...new Set([...state.channelIds, ...action.payload.channelIds])],
    loading: false,
  };
}

// Immer updates
function receiveChannelData(state, action) {
  state.channels[action.payload.channelId] = action.payload.channel[action.payload.channelId];
  addIdToArray(state.channelIds, action.payload.channelId);
  state.loading = false;
}

function removeChannelData(state, action) {
  delete state.channels[action.payload.channelId];
  deleteIdFromArray(state.channelIds, action.payload.channelId);
  state.loading = false;
}

// THUNKS -- ASYNC ACTION CREATORS

export function fetchBandwidthSearch(search) {
  return (dispatch) => {
    dispatch(requestSearchData());
    return axios.get(`/bandwidth/availableNumbers?areaCode=${search}`)
      .then((response) => {
        dispatch(receiveBandwidthSearch(getBandwidthPayload(response.data)));
      })
      .catch(() => {
        // TODO: figure out how to handle a failed request to bandwidth.
        dispatch(receiveBandwidthSearch(getBandwidthPayload([])));
      });
  };

  function getBandwidthPayload(data) {
    return {
      availableBandwidthNumbers: data,
    };
  }
}

export function clearBandwidthNumbers() {
  return (dispatch) => {
    dispatch(setClearedBandwidthNumbers());
  };
}

export function fetchChannels() {
  return (dispatch) => {
    dispatch(requestData());

    return axios.get('/channels')
      .then((response) => {
        const normalized = normalize(response.data, [channel]);
        dispatch(receiveChannels(getChannelsPayload(normalized)));
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function fetchChannelsView() {
  return (dispatch) => {
    dispatch(requestData());

    const promises = [getChannels(), TagReducer.getTags()];

    return axios.all(promises)
      .then(axios.spread((channelsResponse, tagsResponse) => {
        const normalizedChannels = normalize(channelsResponse.data, [channel]);
        const normalizedTags = normalize(tagsResponse.data, [tag]);
        dispatch(receiveChannelView(getChannelViewPayload(normalizedChannels, normalizedTags)));
      }))
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));
      });
  };
}

export function createChannel(channelType, payload) {
  return (dispatch) =>
    axios.post(getCreateChannelURL(channelType), payload)
      .then((response) => {
        const normalized = normalize(response.data, channel);

        dispatch(receiveCreateChannel(getChannelPayload(normalized)));

        dispatch(clearBandwidthNumbers());

        dispatch(setError(null));

        NotificationService('createChannel', response);
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        NotificationService('createChannel', err.response);
      });
}

export function updateChannel(channelId, payload) {
  return (dispatch) =>
    axios.patch(`/channels/${channelId}`, payload)
      .then((response) => {
        const normalized = normalize(response.data, channel);

        dispatch(receiveUpdateChannel(getChannelPayload(normalized)));

        NotificationService('updateChannel', response);
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        NotificationService('updateChannel', err.response);
      });
}

export function destroyChannel(channelId) {
  return (dispatch) =>
    axios.delete(`/channels/${channelId}`)
      .then((response) => {
        dispatch(removeChannel({ channelId }));

        NotificationService('destroyChannel', response);
      })
      .catch((err) => {
        console.error(err.response);

        dispatch(setError(err.response || err));

        NotificationService('destroyChannel', err.response);
      });
}

// AXIOS HELPERS

export function getChannelById({ channelId, patientUserId, patientOrgId }) {
  let urlPath = `/channels/${channelId}`;

  // Used for patient auth.
  if (patientUserId && patientOrgId) {
    urlPath = `/channels/patient/${channelId}?patientUserId=${patientUserId}&patientOrgId=${patientOrgId}`;
  }

  return axios.get(urlPath)
    .catch((err) => console.error(err.response || err));
}

export function getChannels() {
  return axios.get('/channels')
    .catch((err) => console.error(err.response || err));
}

export function getThreadFromChannels(userId, groupId = 0, assigned = false, following = false, direct = false) {
  let url = `/channels/from/${userId}`;

  if (groupId) {
    url = `${url}?groupId=${groupId}`;
  } else if (following) {
    url = `${url}?following=1`;
  } else if (direct) {
    url = `${url}?direct=1`;
  } else if (assigned) {
    url = `${url}?assigned=1`;
  }

  return axios.get(url)
    .catch((err) => console.error(err.response || err));
}

export function getThreadActiveChannels(userId, groupId = 0, assigned = false, following = false, direct = false) {
  let url = `/channels/thread/${userId}`;

  if (groupId) {
    url = `${url}?groupId=${groupId}`;
  } else if (following) {
    url = `${url}?following=1`;
  } else if (direct) {
    url = `${url}?direct=1`;
  } else if (assigned) {
    url = `${url}?assigned=1`;
  }

  return axios.get(url)
    .catch((err) => {
      console.error(err.response || err);
    });
}

function getCreateChannelURL(channelType) {
  let url;
  if (channelType === 'sms') {
    url = '/channels/sms';
  } else if (channelType === 'secure') {
    url = '/channels/secure';
  } else if (channelType === 'facebook') {
    url = '/channels/facebook';
  } else if (channelType === 'landline') {
    url = '/channels/twilio';
  } else if (channelType === 'rhinogram') {
    url = '/channels/rhinogram';
  } else if (channelType === 'instagram') {
    url = '/channels/instagram';
  }
  return url;
}

// PREPARE CALLBACKS -- PAYLOAD CUSTOMIZERS

export function getChannelsPayload(normalizedChannel) {
  return {
    channels: {
      ...normalizedChannel.entities.channels,
    },
    channelIds: normalizedChannel.result,
    phones: {
      ...normalizedChannel.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedChannel.entities.phones),
    tags: {
      ...normalizedChannel.entities.tags,
    },
    tagIds: getObjectKeys(normalizedChannel.entities.tags),
    emails: {
      ...normalizedChannel.entities.emails,
    },
    emailIds: getObjectKeys(normalizedChannel.entities.emails),
  };
}

function getChannelPayload(normalizedChannel) {
  return {
    channel: {
      ...normalizedChannel.entities.channels,
    },
    channelId: normalizedChannel.result,
    phones: {
      ...normalizedChannel.entities.phones,
    },
    phoneIds: getObjectKeys(normalizedChannel.entities.phones),
    emails: {
      ...normalizedChannel.entities.emails,
    },
    emailIds: getObjectKeys(normalizedChannel.entities.emails),
  };
}

function getChannelViewPayload(normalizedChannels, normalizedTags) {
  return {
    ...getChannelsPayload(normalizedChannels),
    ...TagReducer.getTagsPayload(normalizedTags),
  };
}
