import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Icon,
  Button,
  Collapse,
  LoaderCircle,
  NavTabs,
  NavTabsItem,
  UtilityList,
} from 'rhinostyle';
import AppointmentStatusRadioGroup from './AppointmentStatusRadioGroup';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { PROFILE_SUMMARY_APPOINTMENTS_LIST_SIZE } from '../constants/AppConstants';
import { TYPE_APPOINTMENT_UNCONFIRMED } from '../constants/Types';
import { UserHelpers } from '../helpers';
import { useContactAppointments } from '../hooks';

const ContactSummaryAppointmentsSection = (props) => {
  const {
    activeOrg,
    activeUser,
    appointmentIntegrationStatus,
    appointmentTypes,
    isAppointmentsSectionOpen,
    offices,
    openCloseAppointmentsSection,
    providers,
  } = props;
  const APPOINTMENT_NAVIGATION_TABS = {
    upcomingAppointments: 1,
    pastAppointments: 2,
  };

  const [appointmentsTabActiveKey, setAppointmentsTabActiveKey] = useState(APPOINTMENT_NAVIGATION_TABS.upcomingAppointments);
  const [appointmentsDisplayLength, setAppointmentsDisplayLength] = useState(PROFILE_SUMMARY_APPOINTMENTS_LIST_SIZE);
  const { appointments, total, isLoading } = useContactAppointments(appointmentsTabActiveKey === APPOINTMENT_NAVIGATION_TABS.upcomingAppointments);

  useEffect(() => {
    if (activeUser.id) {
      setAppointmentsTabActiveKey(APPOINTMENT_NAVIGATION_TABS.upcomingAppointments);
    }
  }, [activeUser.id]);

  function handleTabs(index) {
    setAppointmentsTabActiveKey(index);
    setAppointmentsDisplayLength(PROFILE_SUMMARY_APPOINTMENTS_LIST_SIZE);
  }

  function handleShowMoreAppointments() {
    setAppointmentsDisplayLength((current) => current + PROFILE_SUMMARY_APPOINTMENTS_LIST_SIZE);
  }

  function formatProviderName(providerId) {
    const provider = providers[providerId];
    return provider.alias || '';
  }

  function isShowMoreButtonVisible() {
    return appointmentsDisplayLength < total;
  }

  const renderAppointment = (appointment) => {
    const {
      appointmentStatusTimestamp,
      appointmentStatusTypeId,
      externalAppointmentStatusId,
      appointmentTypeId,
      externalProviderId,
      id,
      officeId,
      startTime,
    } = appointment;
    const isUpcomingTabSelected = appointmentsTabActiveKey === APPOINTMENT_NAVIGATION_TABS.upcomingAppointments;
    const currentStatus = appointmentStatusTypeId || TYPE_APPOINTMENT_UNCONFIRMED;
    const isAppointmentTypeVisible = appointmentTypeId && !appointmentTypes.appointmentTypes[appointmentTypeId]?.deleted;
    const isProviderVisible = externalProviderId && providers[externalProviderId]?.alias;
    return (
      <div key={id} className="hipaa-status--summary">
        <div className="u-m-b-small">
          <div className="u-m-t-0">
            <strong>{startTime}</strong>
          </div>
          {offices[officeId]?.name && (
            <div className="u-m-t-0">
              {offices[officeId]?.name}
            </div>
          )}
          {isAppointmentTypeVisible && (
            <span className="u-m-t-0">
              {appointmentTypes.appointmentTypes[appointmentTypeId]?.alias ||
                appointmentTypes.appointmentTypes[appointmentTypeId]?.name}
            </span>
          )}
          {isAppointmentTypeVisible && isProviderVisible && <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span> /* divider */}
          {isProviderVisible && <span>{formatProviderName(externalProviderId)}</span>}
        </div>
        <div className="profile-panel__appointment-status">
          <div data-cypress="appointmentStatus">
            Status: <strong>{UserHelpers.formatAppointmentStatus(currentStatus)}</strong>
          </div>
          <div className="u-text-small u-text-muted" data-cypress="lastUpdated">
            <small>Last Updated {appointmentStatusTimestamp}</small>
          </div>
        </div>
        {externalAppointmentStatusId && (
          <div data-cypress="ehrStatus">
            PMS/EHR Status: <strong>{appointmentIntegrationStatus?.[externalAppointmentStatusId]?.name}</strong>
          </div>
        )}
        {activeOrg?.isAppointmentManagerEnabled && isUpcomingTabSelected && (
          <AppointmentStatusRadioGroup
            appointment={appointment}
            currentStatus={currentStatus}
          />
        )}
      </div>
    );
  };

  const renderLoader = () => (
    <div className="summary-panel__appointments__loader">
      <LoaderCircle className="u-text-primary" />
    </div>
  );

  const renderAppointmentsListEmptyState = () => (
    <div
      className="u-text-italic u-p-t u-p-r u-p-b"
      data-cypress="noAppointmentsMessage"
    >{appointmentsTabActiveKey === APPOINTMENT_NAVIGATION_TABS.upcomingAppointments ? 'There are currently no upcoming appointments for this patient' : 'There is currently no past appointment history for this patient' }
    </div>
  );

  const renderListView = () => (appointments?.length > 0 ? (
    <UtilityList space className="u-m-b-0 u-m-t">
      {appointments.slice(0, appointmentsDisplayLength).map(renderAppointment)}
    </UtilityList>
  ) : renderAppointmentsListEmptyState());

  const renderAppointments = () => (isLoading ? renderLoader() : renderListView());

  return (
    <div className="summary-panel__section summary-panel__section--full">
      <div className="summary-panel__section__label">
        Appointments
        <div className="summary-panel__section__label__action">
          <Icon
            icon={isAppointmentsSectionOpen ? 'caret-up' : 'caret-down'}
            className="u-text-muted"
            onClick={openCloseAppointmentsSection}
            data-cypress="confirmationIcon"
          />
        </div>
      </div>
      <Collapse isOpen={isAppointmentsSectionOpen}>
        <div className="summary-panel__section__content summary-panel__section__content--indented">
          <div className="profile-panel__nav-tabs-container">
            <div className="convo__tabs nav-tabs__wrapper">
              <NavTabs className="appointments-nav-tabs" activeKey={appointmentsTabActiveKey} onSelect={handleTabs}>
                <NavTabsItem id={APPOINTMENT_NAVIGATION_TABS.upcomingAppointments} dataFeatureTag="upcomingAppointmentsTab">Upcoming</NavTabsItem>
                <div className="nav-tabs__item--divider" />
                <NavTabsItem id={APPOINTMENT_NAVIGATION_TABS.pastAppointments} dataFeatureTag="pastAppointmentsTab">Past</NavTabsItem>
              </NavTabs>
            </div>
          </div>
          {renderAppointments()}
          {isShowMoreButtonVisible() && (
            <div className="hipaa-status__show-more">
              <Button reset onClick={handleShowMoreAppointments} loading={false}>Show More</Button>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

ContactSummaryAppointmentsSection.propTypes = {
  activeOrg: PropTypes.object.isRequired,
  activeUser: PropTypes.object.isRequired,
  appointmentIntegrationStatus: PropTypes.object,
  appointmentTypes: PropTypes.object,
  isAppointmentsSectionOpen: PropTypes.bool.isRequired,
  offices: PropTypes.object,
  openCloseAppointmentsSection: PropTypes.func.isRequired,
  providers: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    appointment,
    appointmentTypes,
    office,
    provider,
  } = state;
  return {
    activeOrg: getCurrentOrg(state),
    appointmentIntegrationStatus: appointment.appointmentIntegrationStatus,
    appointmentTypes,
    offices: office.offices,
    providers: provider.providers,
  };
};

export default connect(mapStateToProps)(ContactSummaryAppointmentsSection);
