import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UtilityInlineGrid,
  Icon,
  FormLabel,
  Dropdown,
  DropdownMenuItem,
} from 'rhinostyle';
import { setActiveFromChannel } from '../reducers/inboxReducer';

const HIPAAConsentMessagingModal = (props) => {
  const {
    handleSendHIPAAConsentMessage,
    isOpenHIPAAModal,
    handleCloseModal,
    defaultChannelId,
    threadFromChannelOptions,
    isLimitedProvider,
    channels,
  } = props;

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [activeFromChannelId, setActiveFromChannelId] = useState(defaultChannelId);
  const hasFromChannels = threadFromChannelOptions && threadFromChannelOptions[0]?.options.length > 0;

  useEffect(() => {
    setIsOpen(isOpenHIPAAModal);
    setActiveFromChannelId(defaultChannelId);
  }, [isOpenHIPAAModal, defaultChannelId]);

  useEffect(() => {
    setActiveFromChannelId(defaultChannelId);
  }, [defaultChannelId]);

  const handleFromChannelSelect = (channelId) => {
    setActiveFromChannelId(channelId);
    dispatch(setActiveFromChannel(channelId));
  };
  const fromChannelName = activeFromChannelId && activeFromChannelId !== -1 ? channels[activeFromChannelId].name : '';

  return (
    <Modal open={isOpen} modalDialogClass="modal__dialog__warning">
      <ModalHeader onClose={handleCloseModal} />
      <ModalBody>
        <div className="u-text-small u-text-left">
          <Icon bump="up" icon="alert-triangle" className="inbox__item__body__failed-icon" />
          {isLimitedProvider ? (
            <>
              You cannot communicate with this contact until they have granted HIPAA consent.
              Click below to send the HIPAA consent request. Once HIPAA consent is granted,
              your administrator will update the Communication Consent status for this contact
              and text messaging will be enabled.
            </>
          ) : (
            <>
              The patient has not yet granted HIPAA consent for two-way text messaging.
              In order to communicate by text with this patient you first obtain HIPAA consent.
              <br /><br />
              First, ensure the correct outbound channel is selected then click Send the HIPAA Consent Message
              to send the consent request by text. When HIPAA consent is granted, their HIPAA Consent
              status will automatically update in Rhinogram.
            </>
          )}
        </div>
        {!isLimitedProvider && (
          <>
            <br />
            <br />
            <div className="broadcast-modal__actions">
              <div className="broadcast-modal__channels-dropdown column-8@medium">
                <FormLabel id="fromchannel">Outbound Channel <Icon bump="up" icon="from-channel" /></FormLabel>
              </div>
            </div>
            <div className="row">
              <div className="broadcast-modal__channel-HIPAA">

                {hasFromChannels ? (
                  <Dropdown
                    activeKey={activeFromChannelId}
                    position="top"
                    onSelect={(value) => handleFromChannelSelect(value)}
                    label={activeFromChannelId !== -1 ? fromChannelName : '--'}
                    wide
                    title="selectChannel"
                  >
                    {threadFromChannelOptions[0].options?.map((p) => <DropdownMenuItem key={p.id} id={p.id} label={p.value} />)}
                  </Dropdown>
                ) : (
                  <Dropdown
                    activeKey={activeFromChannelId}
                    position="top"
                    onSelect={(value) => handleFromChannelSelect(value)}
                    wide
                    label={<span><strong className="hipaa-modal__dropdown-label">NO CHANNEL</strong><span> You need access to a channel to text via Rhinogram</span></span>}
                    title="selectChannel"
                    disabled
                  />
                )}
              </div>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <UtilityInlineGrid align="right">
          <Button type="link" onClick={handleCloseModal}>Cancel</Button>
          <Button type="primary" disabled={!hasFromChannels} onClick={handleSendHIPAAConsentMessage}>Send HIPAA Consent Message</Button>
        </UtilityInlineGrid>
      </ModalFooter>
    </Modal>
  );
};

export default HIPAAConsentMessagingModal;
