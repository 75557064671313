import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import OrganizationOutOfOfficeForm from '../components/OrganizationOutOfOfficeForm';
import unsavedChanges from '../components/UnsavedChangesHOC';
import * as OutOfOfficeReducer from '../reducers/outOfOfficeReducer';
import PageLoader from '../components/PageLoader';
import { ScrollHelpers, ValidationHelpers, DateHelpers } from '../helpers';
import { businessHoursArray } from '../constants/BusinessHoursConstants';
import { getActiveChannelIds } from '../selectors/channelSelectors';

class OrganizationOutOfOfficeFormContainer extends Component {
  static DEFAULT_FROM_HOUR = 8;
  static DEFAULT_TO_HOUR = 18;

  state = {
    errors: {},
    formInProgress: false,
    from: moment().hour(OrganizationOutOfOfficeFormContainer.DEFAULT_FROM_HOUR).minute(0).second(0),
    id: -1,
    isClosedAllDay: false,
    message: '',
    mode: 'create',
    pageLoading: true,
    selectedChannelIds: [],
    showDeleteConfirmation: false,
    title: '',
    to: moment().hour(OrganizationOutOfOfficeFormContainer.DEFAULT_TO_HOUR).minute(0).second(0),
    viewAllChannels: !this.props.match.params.oooId,
  };

  componentDidMount() {
    this.props.fetchOOOFormView();
  }

  componentDidUpdate(prevProps, prevState) {
    const activeOOO = this.props.outOfOffices[this.props.match.params.oooId];

    const updateMode = activeOOO && this.state.id === -1;

    if (updateMode) {
      const {
        channels,
        from,
        id,
        message,
        title,
        to,
      } = activeOOO;

      this.setState({ // eslint-disable-line react/no-did-update-set-state
        from: moment(from),
        id,
        message,
        mode: 'update',
        selectedChannelIds: channels,
        title,
        to: moment(to),
        isClosedAllDay: DateHelpers.isEndOfDay(moment(to)),
      });
    }

    if (this.props.pageLoading !== prevProps.pageLoading) {
      this.setState({ pageLoading: this.props.pageLoading }); // eslint-disable-line react/no-did-update-set-state
    }

    if (!prevState.showDeleteConfirmation && this.state.showDeleteConfirmation) {
      ScrollHelpers.scrollToBottom(this.pageContainer);
    }
  }

  getOutOfOfficePayload = () => {
    const { title, from, to, message, selectedChannelIds, id } = this.state;

    return {
      channels: selectedChannelIds,
      from: from.format('YYYY-MM-DD HH:mm:ss'),
      id,
      message,
      title,
      to: to.format('YYYY-MM-DD HH:mm:ss'),
    };
  };

  getValidationErrors = () => {
    const errors = {};

    if (!this.state.title) errors.title = 'Title is required';
    if (!this.state.selectedChannelIds.length) errors.channels = 'At least one channel is required';
    if (this.state.message.length === 0) {
      errors.message = 'Message is required';
    }
    if (!this.state.from) errors.from = 'Invalid format or date range';
    if (!this.state.to) errors.to = 'Invalid format or date range';

    return errors;
  }

  handleSubmit = () => {
    const errors = this.getValidationErrors();
    const errorCount = Object.keys(errors).length;

    this.setState({ formInProgress: true });

    if (errorCount > 0) {
      this.setState({ errors, formInProgress: false }, () => {
        ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
      });
    } else if (this.state.mode === 'update') {
      const payload = this.getOutOfOfficePayload();

      this.props.updateOutOfOffice(payload.id, payload)
        .then(() => {
          this.props.resetFormChanges();
          this.props.history.push(`/settings/organization/out-of-office/${payload.id}`);
        })
        .catch(() => {
          this.setState({ formInProgress: false });
        });
    } else {
      const payload = this.getOutOfOfficePayload();

      this.props.createOutOfOffice(payload)
        .then((outOfOffice) => {
          this.props.resetFormChanges();
          this.props.history.push(`/settings/organization/out-of-office/${outOfOffice.id}`);
        })
        .catch(() => {
          this.setState({ formInProgress: false });
        });
    }
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  handleSelect = (name, id) => {
    const timeSelectOption = businessHoursArray.find((item) => item.id === id);
    const momentTime = DateHelpers.convertLocalTimeToLocalMoment(timeSelectOption.expectedValue);

    let { from, to } = this.state;

    if (name === 'fromTime') {
      from = from.hour(momentTime.hour()).minute(momentTime.minute());

      if (from > to) {
        to = to.hour(from.hour()).minute(from.minute());
      }
    } else if (name === 'toTime') {
      to = to.hour(momentTime.hour()).minute(momentTime.minute());

      if (to < from) {
        from = from.hour(to.hour()).minute(to.minute());
      }
    }

    this.setState({
      from,
      to,
    });
  }

  handleToggleClosed = () => {
    const { from, to } = this.state;
    const isClosedAllDay = !this.state.isClosedAllDay;

    this.setState({
      isClosedAllDay,
      from: isClosedAllDay ?
        moment(from).startOf('day') :
        moment(from).hour(OrganizationOutOfOfficeFormContainer.DEFAULT_FROM_HOUR).minute(0).second(0),
      to: isClosedAllDay ?
        moment(to).endOf('day') :
        moment(to).hour(OrganizationOutOfOfficeFormContainer.DEFAULT_TO_HOUR).minute(0).second(0),
    });
  }

  handleStartDateChange = (from) => {
    const newState = { from };

    if (from > this.state.to) {
      newState.to = from.clone();

      if (this.state.isClosedAllDay) {
        newState.to = moment(newState.to).endOf('day');
      }
    }

    this.setState(newState);
  }

  handleEndDateChange = (to) => this.setState({ to });

  handleDestroyOOO = () => {
    this.setState({ formInProgress: true });

    this.props.destroyOutOfOffice(this.state.id)
      .then(() => {
        this.props.history.push('/settings/organization/out-of-office');
      });
  }

  handleToggle = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  }

  handleUpdateSelectedIds = (name, id) => {
    let selectedIds = [...this.state[name]];

    const addAction = !selectedIds.includes(id);

    if (addAction) {
      selectedIds = selectedIds.concat(id);
    } else {
      selectedIds = selectedIds.filter((selectedId) => selectedId !== id);
    }

    this.setState({ [name]: selectedIds });
  }

  render() {
    if (this.state.pageLoading) {
      return <PageLoader />;
    }

    const props = {
      actionTitle: this.state.mode === 'update' ? 'Update Event' : 'Create Event',
      channelIds: this.props.channelIds,
      channels: this.props.channels,
      errors: this.state.errors,
      formInProgress: this.state.formInProgress,
      from: this.state.from,
      handleChange: this.handleChange,
      handleDestroyOOO: this.handleDestroyOOO,
      handleEndDateChange: this.handleEndDateChange,
      handleFormChanges: this.props.handleFormChanges,
      handleSelect: this.handleSelect,
      handleStartDateChange: this.handleStartDateChange,
      handleSubmit: this.handleSubmit,
      handleToggle: this.handleToggle,
      handleToggleClosed: this.handleToggleClosed,
      handleUpdateSelectedIds: this.handleUpdateSelectedIds,
      isClosedAllDay: this.state.isClosedAllDay,
      message: this.state.message,
      mode: this.state.mode,
      pageContainerRef: (pageContainer) => (this.pageContainer = pageContainer),
      pageTitle: this.state.mode === 'update' ? 'Edit Out of Office Event' : 'Create Out of Office Event',
      phones: this.props.phones,
      selectedChannelIds: this.state.selectedChannelIds,
      showDeleteButton: this.state.mode === 'update',
      showDeleteConfirmation: this.state.showDeleteConfirmation,
      title: this.state.title,
      to: this.state.to,
      viewAllChannels: this.state.viewAllChannels,
    };

    return <OrganizationOutOfOfficeForm {...props} />;
  }
}

OrganizationOutOfOfficeFormContainer.propTypes = {
  fetchOOOFormView: PropTypes.func.isRequired,
  outOfOffices: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  phones: PropTypes.object.isRequired,
  channels: PropTypes.object.isRequired,
  channelIds: PropTypes.array.isRequired,
  updateOutOfOffice: PropTypes.func.isRequired,
  createOutOfOffice: PropTypes.func.isRequired,
  pageLoading: PropTypes.bool.isRequired,
  destroyOutOfOffice: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  resetFormChanges: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { outOfOffice, channel, phone } = state;

  return {
    phones: phone.phones,
    channels: channel.channels,
    channelIds: getActiveChannelIds(state),
    outOfOffices: outOfOffice.outOfOffices,
    pageLoading: outOfOffice.loading,
  };
};

const actions = {
  fetchOOOFormView: OutOfOfficeReducer.fetchOOOFormView,
  updateOutOfOffice: OutOfOfficeReducer.updateOutOfOffice,
  createOutOfOffice: OutOfOfficeReducer.createOutOfOffice,
  destroyOutOfOffice: OutOfOfficeReducer.destroyOutOfOffice,
};

export default connect(mapStateToProps, actions)(unsavedChanges(OrganizationOutOfOfficeFormContainer));
