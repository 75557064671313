import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSelectThreadEvents, useLastEvent } from '.';
import { getLoggedInUser } from '../selectors/userSelectors';
import { updateReadStatus } from '../reducers/inboxReducer';

const useUpdateRead = ({ loading }) => {
  const dispatch = useDispatch();
  const threadEvents = useSelectThreadEvents();
  const threadFilteredChannelIds = useSelector((state) => state.inbox.threadFilteredChannelIds);
  const currentUser = useSelector((state) => getLoggedInUser(state));
  const userId = useSelector((state) => state.inbox.userId);
  const { mostRecentEvent } = useLastEvent();
  const { isCcr } = currentUser;
  useEffect(() => {
    if (!loading && !isCcr && threadEvents.isSuccess && userId === threadEvents.originalArgs.userId) {
      dispatch(updateReadStatus({
        ...threadEvents.originalArgs,
        channelIds: threadFilteredChannelIds,
        audit: true,
        read: true,
      }));
    }
  }, [loading, threadEvents.isSuccess, userId, threadFilteredChannelIds, mostRecentEvent?.isRead]);
  return threadEvents;
};

export default useUpdateRead;
