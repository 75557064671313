import PropTypes from 'prop-types';
import React from 'react';
import { Button, Input, RhinoSwitch, Select, Textarea } from 'rhinostyle';
import { capitalize } from '../helpers/StringHelpers';
import PageLoader from './PageLoader';

const SystemAlertSettings = (props) => {
  const {
    errorList,
    formInProgress,
    handleChange,
    handleSubmit,
    types,
    typeIds,
    alertMessage,
    alertType,
    active,
    url,
    urlText,
    loading,
    disabled,
  } = props;

  const typeOpts = typeIds.map((t) => ({ id: types[t].id, value: capitalize(types[t].value) }));

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="app-page__container">
      <div className="app-page__container__inner">
        <div className="app-page__header">
          <div className="app-page__header__title">System Alert</div>
        </div>
        <div className="box">
          <div className="box__title-wrapper">
            <div className="box__title">Alert Details</div>
            <div className="box__subtitle">Used to display a system alert; visible to members.</div>
          </div>
          <div className="block-preference">
            <div className="block-preference__left">
              <h4 className="block-preference__name">Enable System Alert</h4>
            </div>
            <div className="block-preference__right">
              <RhinoSwitch isChecked={active} onChange={handleChange} name="active" />
            </div>
          </div>
          <hr className="u-m-y-large" />
          <Textarea
            label="Message"
            name="alertMessage"
            initialValue={alertMessage}
            onChange={handleChange}
            validationMessage={errorList.message}
            required
            maxCharacters={129}
          />
          <Input onChange={handleChange} initialValue={url} validationMessage={errorList.url} name="url" label="URL" />
          <div className="form__group">
            <div className="row">
              <div className="column-6@xsmall">
                <Select validationMessage={errorList.alertType} onSelect={handleChange} options={typeOpts} selected={alertType} name="alertType" required label="Type" />
              </div>
              <div className="column-6@xsmall">
                <Input onChange={handleChange} initialValue={urlText} validationMessage={errorList.urlText} name="urlText" label="URL Text" />
              </div>
            </div>
          </div>
        </div>
        <div className="u-m-t-large u-text-right">
          <Button data-cypress="systemAlertSave" loading={formInProgress} onClick={handleSubmit} disabled={disabled} type="primary">Save Alert</Button>
        </div>
      </div>
    </div>
  );
};

SystemAlertSettings.propTypes = {
  loading: PropTypes.bool.isRequired,
  errorList: PropTypes.object.isRequired,
  formInProgress: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  types: PropTypes.object.isRequired,
  typeIds: PropTypes.array.isRequired,
  alertMessage: PropTypes.string,
  alertType: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  url: PropTypes.string,
  urlText: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

export default SystemAlertSettings;
