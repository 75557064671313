import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PdfViewer from '../components/PdfViewer';
import { handleDownloadClick } from '../helpers/PdfViewerHelpers';

class PdfViewerContainer extends React.Component {
  state = {
    pdfLoading: true,
    doc: null,
    page: 1,
    previousDisabled: true,
    nextDisabled: false,
    pageStatus: '',
  };

  componentDidUpdate(prevProps) {
    if ((!prevProps.open && this.props.open) || (this.props.open && prevProps.attachmentUrl !== this.props.attachmentUrl)) {
      this.setState({
        pdfLoading: true,
        doc: null,
        page: 1,
        previousDisabled: true,
        nextDisabled: false,
        pageStatus: '',
      });
      this.getPDF();
    }
  }

  getPDF() {
    const params = {
      withCredentials: true,
      url: this.props.attachmentUrl,
    };

    PDFJS.getDocument(params).then((doc) => {
      this.setState({
        doc,
        pdfLoading: false,
        nextDisabled: (doc.numPages === 1),
      }, () => {
        this.renderPage();
      });
    });
  }

  handleNavClick = (direction) => {
    if (direction === 'previous') {
      if (this.state.page > 1) {
        this.renderPage((this.state.page - 1), direction);
      }
    } else {
      if (this.state.page + 1 <= this.state.doc.numPages) { // eslint-disable-line no-lonely-if
        this.renderPage((this.state.page + 1), direction);
      }
    }
  }

  /**
   * Triggered by clicking download link within preview
   * @return {mixed}
   */
  handleDownloadClick = () => {
    handleDownloadClick(this.props.attachmentUrl, this.props.fileName, this.props.isNativeApp);
  }

  renderPage = (page = this.state.page) => {
    const scale = 1;
    const container = this.pdfContainer;
    const { doc } = this.state;

    // Clear out container
    if (container) {
      container.innerHTML = '';
    }

    doc.getPage(page).then((pdfPage) => {
      // Create the page view.
      const pdfPageView = new PDFJS.PDFPageView({
        container,
        id: page,
        scale,
        defaultViewport: pdfPage.getViewport(scale),
      });

      this.setState({
        pageStatus: `Page ${page} of ${doc.numPages}`,
        page,
      }, () => {
        this.setState({
          previousDisabled: (this.state.page === 1),
          nextDisabled: (this.state.page >= this.state.doc.numPages),
        });
      });

      // Associate the actual page with the view and draw it.
      pdfPageView.setPdfPage(pdfPage);
      return pdfPageView.draw();
    });
  }

  render() {
    const props = {
      handleDownloadClick: this.handleDownloadClick,
      handlePdfViewerClose: this.props.handlePdfViewerClose,
      coverTitle: this.props.fileName,
      pdfContainerRef: (pdfContainer) => (this.pdfContainer = pdfContainer),
      navPreviousRef: (navPrevious) => (this.navPrevious = navPrevious),
      navNextRef: (navNext) => (this.navNext = navNext),
      previousDisabled: this.state.previousDisabled,
      nextDisabled: this.state.nextDisabled,
      pageStatus: this.state.pageStatus,
      handleNavClick: this.handleNavClick,
      attachmentUrl: this.props.attachmentUrl,
      pdfLoading: this.state.pdfLoading,
      open: this.props.open,
      className: this.props.className,
    };

    return (
      <PdfViewer {...props} />
    );
  }
}

PdfViewerContainer.propTypes = {
  className: PropTypes.string,
  attachmentUrl: PropTypes.string,
  data: PropTypes.string,
  fileName: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handlePdfViewerClose: PropTypes.func.isRequired,
  isNativeApp: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isNativeApp: state.nativeApp.isNativeApp,
});

export default connect(mapStateToProps)(PdfViewerContainer);
