import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  UtilityInlineGrid,
  UtilityList,
  Scrollbars,
} from 'rhinostyle';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLoggedInUserPermissionNames } from '../selectors/userSelectors';
import { AUTOMATED_MESSAGE_EDIT } from '../constants/UserPermissionsConstants';
import { replaceVariablesWithSampleText, replaceNewLinesToLineBreaks } from '../helpers/TemplateHelpers';
import { HIPAA_CAMPAIGN_VARIABLE_OPTIONS } from '../constants/VariableMessageConstants';
import { NOTE_TRUNCATE_LENGTH } from '../constants/AppConstants';
import { convertHoursToDays } from '../helpers/DateHelpers';
import { formatHipaaStatus } from '../helpers/UserHelpers';
import SingleAttachmentPreview from './SingleAttachmentPreview';
import OptOut from './OptOut';

const HipaaCampaignSummaryPanel = (props) => {
  const history = useHistory();
  const {
    hipaaCampaign,
    handleTogglePanel,
    loggedInUserPermissions,
  } = props;
  const [truncateTemplate, setTruncateTemplate] = useState(hipaaCampaign?.template?.length > NOTE_TRUNCATE_LENGTH);

  useEffect(() => {
    setTruncateTemplate(hipaaCampaign?.template?.length > NOTE_TRUNCATE_LENGTH);
  }, [hipaaCampaign?.id]);

  const renderTemplateText = (text, truncateText) => {
    const formattedText = replaceNewLinesToLineBreaks(text);
    return truncateText ?
      replaceVariablesWithSampleText(HIPAA_CAMPAIGN_VARIABLE_OPTIONS, formattedText).substring(0, NOTE_TRUNCATE_LENGTH) :
      replaceVariablesWithSampleText(HIPAA_CAMPAIGN_VARIABLE_OPTIONS, formattedText);
  };

  const renderAttachment = (attachment, key) => (
    <SingleAttachmentPreview
      attachment={attachment}
      index={key}
      key={attachment.attachmentUrl}
    />
  );

  const renderAttachments = () => (hipaaCampaign?.attachments?.length > 0 ? (
    <div className="summary-panel__section__content u-m-t">
      <UtilityList space className="u-m-b-0">
        {hipaaCampaign.attachments.map(renderAttachment)}
      </UtilityList>
    </div>
  ) : null);

  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title" data-cypress="summaryTitle">Summary</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => handleTogglePanel(false)}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__title u-m-t">
                {hipaaCampaign?.name}
              </div>
              <div className="summary-panel__intro__actions">
                {loggedInUserPermissions.includes(AUTOMATED_MESSAGE_EDIT) && (
                  <UtilityInlineGrid align="middle">
                    <Button
                      className="u-m-t u-m-b"
                      size="small"
                      type="primary"
                      outlined
                      onClick={() => history.push(`/settings/organization/hipaa-campaigns/edit/${hipaaCampaign?.id}`)}
                      data-cypress="hipaaCampaignEdit"
                    >
                      Edit Message
                    </Button>
                  </UtilityInlineGrid>
                )}
              </div>
            </div>
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Message</div>
              {hipaaCampaign?.enabled && (
                <div className="summary-panel__section__content">
                  <UtilityList space className="u-m-b-0">
                    {renderTemplateText(hipaaCampaign?.template, truncateTemplate)}
                    {!truncateTemplate && <OptOut className="u-text-gray" />}
                  </UtilityList>
                  {truncateTemplate && (
                    <Button
                      type="reset"
                      className="profile__forms__expand u-text-primary"
                      onClick={() => setTruncateTemplate(false)}
                    >
                      View more...
                    </Button>
                  )}
                  {renderAttachments()}
                </div>
              )}
            </div>
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Consent Type</div>
              <div className="summary-panel__section__content">
                <div className="u-m-b-0">{formatHipaaStatus(hipaaCampaign.hipaaStatusTypeId)}</div>
              </div>
            </div>
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Repeat Timing</div>
              <div className="summary-panel__section__content">
                <div className="u-m-b-0">{convertHoursToDays(hipaaCampaign?.resendDeliveryWindow, true)}</div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedInUserPermissions: getLoggedInUserPermissionNames(state),
});

HipaaCampaignSummaryPanel.propTypes = {
  loggedInUserPermissions: PropTypes.array,
  handleTogglePanel: PropTypes.func,
  hipaaCampaign: PropTypes.object,
};

export default connect(mapStateToProps)(HipaaCampaignSummaryPanel);
