import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { UtilitySystem, Button, Input } from 'rhinostyle';
import unsavedChanges from './UnsavedChangesHOC';

import { getLoggedInUser } from '../selectors/userSelectors';
import * as UserReducer from '../reducers/userReducer';
import * as GroupReducer from '../reducers/groupReducer';
import ContactListUserSearch from './ContactListUserSearch';
import StorageService from '../services/StorageService';
import { MaskingHelpers, SearchHelpers } from '../helpers';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import BackButtonContainer from '../containers/BackButtonContainer';
import BulkMessageModal from './BulkMessageModal';
import { userHasAllOfPermissions } from '../helpers/UserHelpers';
import { BULK_MESSAGING_EDIT, THREAD_MESSAGE_CREATE, CONVERSATION_CONTACT_MOBILE } from '../constants/UserPermissionsConstants';
import { isMobile } from '../helpers/BrowserHelpers';

class ContactListForm extends Component {
  state = {
    activeFilterParam: 'name',
    bulkMessageContacts: [],
    contactListName: '',
    createdContactListId: null,
    errors: {},
    inputFormat: null,
    isBulkMessageModalOpen: false,
    isSaveAsButtonsVisible: false,
    mode: this.props.match.params.contactListId ? 'update' : 'create',
    searchFocus: false,
    searchText: '',
    searchTextCleave: null,
    selectedSavedList: null,
    selectedUserIds: [],
    selectedUsers: [],
  };

  multiUserInputRef = React.createRef();

  componentDidMount() {
    this.props.fetchContactList();
    this.handleSearch = debounce(this.handleSearch, 300);
    this.setStoredFilterParam();
    this.props.clearUserSearch();
  }

  componentDidUpdate(prevProps) {
    this.handleErrors(prevProps);
  }

  handleErrors = (prevProps) => {
    if (this.props.uiError?.data?.message !== prevProps.uiError?.data?.message) {
      this.setState({ errors: this.props.uiError?.data || {} });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const updatedState = {};
    const {
      selectedSavedList,
    } = props;

    if (!state.isListPropsUpdatedFromParent && Object.keys(selectedSavedList).length) {
      updatedState.selectedSavedList = selectedSavedList;
      updatedState.selectedUserIds = selectedSavedList.users ? selectedSavedList.users.map((user) => user.id) : [];
      updatedState.isListPropsUpdatedFromParent = true;
      updatedState.contactListName = selectedSavedList.name;
    }
    return updatedState;
  }

  handleContactFormChanges = () => {
    this.props.handleFormChanges();
  }

  onSearchTextCleaveInit = (cleave) => { this.setState({ searchTextCleave: cleave }); }

  resetCleaveInputValue = () => { this.state.searchTextCleave.setRawValue(''); }

  setInputFormat = () => {
    const { activeFilterParam } = this.state;

    this.setState({
      inputFormat: null,
    }, () => {
      let inputFormat = null;

      if (activeFilterParam === 'dob') {
        inputFormat = UtilitySystem.dateFormat;
      } else if (activeFilterParam === 'phone') {
        inputFormat = MaskingHelpers.phone;
      }

      this.setState({
        inputFormat,
      });
    });
  }

  setStoredFilterParam = () => {
    const storedFilterParam = StorageService.readEntry(this.localStorageFilterParam);

    const paramsToSearch = SearchHelpers.filterContactParams;

    if (storedFilterParam && paramsToSearch.filterParams.includes(storedFilterParam)) {
      this.setState({
        activeFilterParam: storedFilterParam,
      }, () => {
        this.setInputFormat();
      });
    }
  }

  localStorageFilterParam = `rhinogram-${this.props.user.id}-searchFilterType`;

  handleFilterChange = (newFilter) => {
    const { activeFilterParam: prevFilter } = this.state;

    if ((prevFilter === 'dob' || prevFilter === 'phone') && (newFilter !== 'dob' && newFilter !== 'phone')) {
      this.setState({ searchTextCleave: null });
    }

    this.setState({
      activeFilterParam: newFilter,
      searchText: '',
      searchFocus: false,
    }, () => {
      this.props.clearUserSearch();
      this.setInputFormat();

      this.setState({
        searchFocus: true,
        searchText: '',
      });

      StorageService.createEntry(this.localStorageFilterParam, newFilter);
    });
  }

  handleSearch = (id, searchValue, searchValueFormatted) => {
    const { userSearchIds, fetchUserSearch, clearUserSearch } = this.props;
    const { activeFilterParam: searchType } = this.state;
    const searchText = (searchType === 'dob' || searchType === 'phone') ? searchValueFormatted : searchValue;
    const scope = 'nonMembers';
    const source = 'contactListSearch';
    if (searchText && searchText.length > 2) {
      fetchUserSearch({ searchValue: searchText, scope, type: searchType, source });
    } else if (userSearchIds.length) {
      clearUserSearch();
    }

    this.setState({ searchText });
  };

  onScroll = () => {
    const scrollContainer = this.contactListRef.container.firstChild;
    const totalScroll = scrollContainer.scrollTop + scrollContainer.clientHeight;
    const { fetchUserSearch, searchSize, page } = this.props;
    const { activeFilterParam: searchType, searchText } = this.state;
    const source = 'contactListSearch';
    const scope = 'nonMembers';

    if (totalScroll === scrollContainer.scrollHeight) {
      this.scrollPosition = 'bottom';

      fetchUserSearch({ searchValue: searchText, scope, type: searchType, source, page: page + searchSize });
    }

    if ((totalScroll !== scrollContainer.scrollHeight) && (scrollContainer.scrollTop !== 0)) {
      this.scrollPosition = 'middle';
    }

    if (scrollContainer.scrollTop === 0) {
      this.scrollPosition = 'top';
    }
  }

  handleSearchSelect = (selectedUserId) => {
    let { selectedUserIds } = this.state;
    const selectedUserIdIndex = selectedUserIds.indexOf(selectedUserId);
    const isUserSelected = selectedUserIdIndex !== -1;
    const isMobileDevice = isMobile();

    if (isUserSelected) {
      selectedUserIds.splice(selectedUserIds.indexOf(selectedUserId), 1);
    } else {
      selectedUserIds = [...new Set([...selectedUserIds, selectedUserId])];
    }

    this.setState({ selectedUserIds, searchFocus: !isMobileDevice });
    if (!isMobileDevice) this.multiUserInputRef.current.input.focus();
  }

  handleRemoveUserFromSelectedIds = (removedUserId) => {
    this.setState((prevState) => ({ selectedUserIds: prevState.selectedUserIds.filter((selectedUserId) => selectedUserId !== parseInt(removedUserId, 10)) }));
  }

  handleRemoveAllUsersFromSelectedIds = () => {
    this.setState({
      selectedUserIds: [],
      searchText: '',
    });
    this.props.clearUserSearch();
  }

  removeUserFromBulkMessageModal = (user) => {
    // remove user from bulk message modal and from contact list
    this.setState((prevState) => ({
      bulkMessageContacts: prevState.bulkMessageContacts.filter((selectedUser) => selectedUser.id !== parseInt(user.id, 10)),
      selectedUserIds: prevState.selectedUserIds.filter((selectedUser) => selectedUser !== user.id),
    }));
  }

  // TODO: Refactor adding additional contacts flow here. Confusing and very dependent on how it's currently being used. Not very portable.
  handleOpenCloseBulkMessageModal = (goToUpdatePage) => {
    if (goToUpdatePage === true && this.state.createdContactListId && window.location.pathname !== `/contact-list/edit/${this.state.createdContactListId}`) {
      this.props.history.push(`/contact-list/edit/${this.state.createdContactListId}`);
    } else this.setState({ isBulkMessageModalOpen: !this.state.isBulkMessageModalOpen });
  }

  handleSaveContactList = async (shouldSendMessage = false, updateList = false) => {
    const contactList = (this.props.selectedSavedList.id && updateList) ? await this.props.updateContactList(this.props.selectedSavedList.id, {
      name: this.state.contactListName || this.state.selectedSavedList.name,
      userIds: this.state.selectedUserIds,
    }) : await this.props.createContactList({
      name: this.state.contactListName,
      userIds: this.state.selectedUserIds,
    });
    if (contactList?.id) {
      if (shouldSendMessage) {
        this.props.resetFormChanges();
        this.setState({ bulkMessageContacts: contactList.users, createdContactListId: contactList.id },
          this.handleOpenCloseBulkMessageModal());
      } else {
        this.handleCloseContactListForm(contactList.id);
      }
    }
  }

handleCloseContactListForm = (contactListId) => {
  this.props.resetFormChanges();
  const contactListRoute = contactListId ? `/contact-list/${contactListId}` : '/contact-list';
  this.props.history.push(contactListRoute);
}

handleMessageContactList = () => {
  const selectedContacts = this.state.selectedUserIds.map((userId) => {
    const user = this.props.users[userId];
    return user;
  });
  this.setState({ bulkMessageContacts: selectedContacts },
    this.handleOpenCloseBulkMessageModal());
}

handleChange = (name, value) => { this.setState({ [name]: value }); }

userHasBulkMessagePermissions = () => (isMobile() ?
  userHasAllOfPermissions([BULK_MESSAGING_EDIT, THREAD_MESSAGE_CREATE, CONVERSATION_CONTACT_MOBILE]) :
  userHasAllOfPermissions([BULK_MESSAGING_EDIT, THREAD_MESSAGE_CREATE]));

handleShowSaveAsButtons = () => { this.setState({ isSaveAsButtonsVisible: !this.state.isSaveAsButtonsVisible }); }

renderSaveAsButtons = () => (
  <div className="contact-list-form-name__wrapper">
    <div className="contact-list-form-name__input u-m-t">
      <Input
        type="text"
        onChange={this.handleChange}
        value={this.state.contactListName}
        initialValue={this.state.contactListName}
        name="contactListName"
        required
        placeholder="Contact list name"
        validationMessage={this.state.errors?.property === 'name' ? this.state.errors.message : null}
      />
    </div>
    <div className="contact-list-form-name__button-wrapper u-m-t">
      <Button
        className="u-m-l"
        type="secondary"
        outlined
        onClick={() => this.handleSaveContactList(false)}
        disabled={!this.state.contactListName || this.state.selectedUserIds.length === 0}
        data-cypress="saveList"
      >
        Save
      </Button>
      <Button
        className="u-m-l"
        type="secondary"
        onClick={() => this.handleSaveContactList(true)}
        disabled={!this.state.contactListName || this.state.selectedUserIds.length === 0 || !this.userHasBulkMessagePermissions()}
        data-cypress="saveAndMessage"
      >
        Save & Message
      </Button>
      <Button
        type="link-muted"
        onClick={this.handleShowSaveAsButtons}
      >
        Cancel
      </Button>
    </div>
  </div>
)

renderSaveMessageButtons = () => {
  const buttonTypePrimary = this.state.selectedUserIds?.length > 0 ? 'primary' : 'default';
  const buttonTypeInput = this.state.selectedUserIds?.length > 0 ? 'input' : 'default';
  const buttonDisabled = this.state.selectedUserIds?.length === 0;
  return (
    <div className="u-flex-justify-between u-flex-direction-row u-flex u-flex-wrap">
      <Button
        className="u-m-r u-m-t"
        loading={false}
        type={buttonTypeInput}
        disabled={buttonDisabled}
        onClick={this.handleShowSaveAsButtons}
        data-cypress="save"
      >
        {this.state.mode === 'update' ? 'Save as' : 'Save'}
      </Button>
      <div>
        {this.state.mode === 'update' && (
          <Button
            className="u-m-r u-m-t"
            type="primary"
            disabled={buttonDisabled}
            outlined
            onClick={() => this.handleSaveContactList(false, true)}
          >
            Update
          </Button>
        )}
        <Button
          className="u-m-t"
          type={buttonTypePrimary}
          disabled={buttonDisabled || !this.userHasBulkMessagePermissions()}
          outlined={false}
          onClick={() => this.handleMessageContactList()}
        >
          Message
        </Button>
      </div>
    </div>
  );
}

render() {
  const props = {
    activeFilterParam: this.state.activeFilterParam,
    contactListName: this.state.contactListName,
    emails: this.props.emails,
    handleFilterChange: this.handleFilterChange,
    handleRemoveUserFromSelectedIds: this.handleRemoveUserFromSelectedIds,
    handleRemoveAllUsersFromSelectedIds: this.handleRemoveAllUsersFromSelectedIds,
    handleSearch: this.handleSearch,
    handleSearchSelect: this.handleSearchSelect,
    inputFormat: this.state.inputFormat,
    multiUserInputRef: this.multiUserInputRef,
    onSearchTextCleaveInit: this.onSearchTextCleaveInit,
    phones: this.props.phones,
    scope: this.props.scope,
    searchFocus: this.state.searchFocus,
    searchText: this.state.searchText,
    selectedUserIds: this.state.selectedUserIds,
    userSearchIds: this.props.userSearchIds,
    userSearchLoading: this.props.userSearchLoading,
    userSearchSource: this.props.userSearchSource,
    users: this.props.users,
    isBulkMessagingEnabled: this.props.currentOrganization.isBulkMessagingEnabled,
    selectedSavedList: this.state.selectedSavedList,
    selectedUsers: this.state.selectedUsers,
    contactListRef: (contactListRef) => (this.contactListRef = contactListRef),
    onScroll: this.onScroll,
  };
  return (
    <div className="app-page app-page--contact-list">
      <div className="app-panels">
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="app-page__header">
              <div className="app-page__header__title">
                <BackButtonContainer customClassName="u-text-body" history={this.props.history} location={this.props.location} />

                {`${this.state.mode === 'update' ? 'Edit ' : ''}Contact List`}
              </div>
            </div>
            <div className="list-panel__body box contact-list-form__wrapper">
              <form onChange={this.handleContactFormChanges} className="column-12 column-10@medium column-offset-1@medium column-offset-0">
                <ContactListUserSearch {...props} />
                {this.state.isSaveAsButtonsVisible ? this.renderSaveAsButtons() : this.renderSaveMessageButtons()}
              </form>
              <BulkMessageModal
                contacts={this.state.bulkMessageContacts || []}
                isModalOpen={this.state.isBulkMessageModalOpen}
                handleToggle={() => (this.state.createdContactListId !== null ? this.handleOpenCloseBulkMessageModal(true) : this.handleOpenCloseBulkMessageModal())}
                handleChange={this.removeUserFromBulkMessageModal}
                clearSelectedContacts={this.handleCloseContactListForm}
                location={this.props.location}
                contactListId={this.state.createdContactListId}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
}

ContactListForm.propTypes = {
  clearUserSearch: PropTypes.func.isRequired,
  contactList: PropTypes.array.isRequired,
  createContactList: PropTypes.func.isRequired,
  createdContactListId: PropTypes.any,
  createGroup: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  emails: PropTypes.object.isRequired,
  fetchContactList: PropTypes.func.isRequired,
  fetchUserSearch: PropTypes.func.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  phones: PropTypes.object.isRequired,
  scope: PropTypes.string.isRequired,
  selectedSavedList: PropTypes.object,
  tags: PropTypes.object.isRequired,
  uiError: PropTypes.object,
  updateContactList: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  userSearchIds: PropTypes.array.isRequired,
  userSearchLoading: PropTypes.bool.isRequired,
  userSearchSource: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  searchSize: PropTypes.number,
  page: PropTypes.number,
  resetFormChanges: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const { email, user, tag, phone, ui } = state;
  return {
    contactList: user.contactList,
    currentOrganization: getCurrentOrg(state),
    emails: email.emails,
    isBulkMessageModalOpen: false,
    phones: phone.phones,
    scope: 'nonMembers',
    selectedSavedList: user.contactList.find((list) => list.id === Number(props.match.params.contactListId)) || {},
    tags: tag.tags,
    uiError: ui.error,
    user: getLoggedInUser(state),
    users: user.users,
    userSearchIds: user.userSearchIds,
    userSearchLoading: user.userSearchLoading,
    userSearchSource: user.userSearchSource,
    page: user.page,
    searchSize: user.searchSize,
  };
};

const actions = {
  clearUserSearch: UserReducer.clearUserSearch,
  fetchUserSearch: UserReducer.fetchUserSearch,
  fetchUsers: UserReducer.fetchUsers,
  createGroup: GroupReducer.createGroup,
  createContactList: UserReducer.createContactList,
  updateContactList: UserReducer.updateContactList,
  fetchContactList: UserReducer.fetchContactList,
};

export default connect(mapStateToProps, actions)(unsavedChanges(ContactListForm));
