import React, { useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import {
  Button,
  Icon,
  Resource,
  ResourceBody,
  ResourceIntro,
  ResourceRight,
  ResourceGroup,
  UtilitySystem,
  LoaderCircle,
} from 'rhinostyle';
import PageLoader from '../components/PageLoader';
import MemberProfilePanelContainer from '../containers/MemberProfilePanelContainer';
import { UserHelpers } from '../helpers';
import { AppConstants } from '../constants';
import { MEMBER_CREATE } from '../constants/UserPermissionsConstants';
import EmptyMessage from '../components/EmptyMessage';
import Tags from '../components/Tags';
import MemberFilters from '../components/MemberFilters';
import { useMemberFilters } from '../hooks';
import FilterPage from '../components/FilterPage';
import { fetchMembersView } from '../reducers/userReducer';
import { updateQueryData } from '../services/searchService';

const OrganizationMembersView = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const pageLoading = useSelector((state) => state.user.membersLoading);
  const members = useSelector((state) => state.user.users);
  const {
    memberFetchLoading,
    isSSOEnabled,
    isCcr,
  } = props;

  const activeMemberId = Number(params?.memberId) || null;

  const { userHasAnyOfPermissions } = UserHelpers;

  useEffect(() => {
    dispatch(fetchMembersView());
  }, []);

  const {
    filters,
    filtersApplied,
    handleSetFilters,
    clearAllFilters,
    handleChange,
    isLoading: usersLoading,
    searchData: { users, totalCount: totalUserCount } = {},
    searchPayload,
  } = useMemberFilters();

  const activeSearchResult = users?.find((user) => user.id === activeMemberId) || {};
  const activeMember = activeMemberId ? { id: activeMemberId, ...activeSearchResult, ...members?.[activeMemberId] } : undefined;

  function handleCreateRequest() {
    history.push('/settings/organization/members/create');
  }

  const updateUserEntryInCache = (id) => {
    const otherPayload = {
      ...searchPayload,
      active: !searchPayload.active,
    };

    // Remove user from current active filter
    dispatch(updateQueryData('getSearch', searchPayload, (draft) => {
      if (Array.isArray(draft.users)) {
        draft.users = draft.users.filter((entry) => entry.id !== id); // eslint-disable-line no-param-reassign
      }
    }));
    // Add user to the other active filter if not already present
    dispatch(updateQueryData('getSearch', otherPayload, (draft) => {
      if (!draft.users.some((entry) => entry.id === id)) {
        draft.users.push(members[id]);
      }
    }));
  };

  function handleUpdateMemberActiveStatus(memberId) {
    history.push('/settings/organization/members');
    updateUserEntryInCache(memberId);
  }

  function handleTogglePanel(id) {
    const shouldClose = (activeMemberId && activeMemberId === id) || (activeMemberId && !id);
    if (shouldClose) {
      history.push('/settings/organization/members');
    } else if (id) {
      history.push(`/settings/organization/members/${id}`);
    }
  }

  function handlePagination(type) {
    const nextPageNumber = type === 'previous' ? filters.pageNo - 1 : filters.pageNo + 1;
    handleChange('pageNo', nextPageNumber);
  }

  const renderMember = (member, key) => {
    const memberOnlineStatus = members[member?.id]?.onlineStatus;
    const active = activeMemberId === member.id;
    const profileImageUrl = member.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${member.profileImageUrl}` : '';
    const avatar = {
      name: UserHelpers.formatAvatarName(member.firstName, member.lastName),
      type: 'member',
      image: profileImageUrl,
      showOnlineStatus: true,
      onlineStatus: memberOnlineStatus,
    };

    const memberTagIds = member.tagIds || member.tags;

    return (
      <Resource active={active} key={key} onClick={() => handleTogglePanel(member.id)}>
        <ResourceIntro title={UserHelpers.formatMemberNameWithPrefixAndSuffix(member)} avatar={avatar}>
          {member.isInviteSent === false && <span className="u-text-accent">Invite not sent</span>}
        </ResourceIntro>
        {memberTagIds?.length > 0 && <ResourceBody className="u-text-muted"><Tags tagIds={memberTagIds} /></ResourceBody>}
        <ResourceRight>
          {member.groups && member.groups.length} group{member.groups?.length !== 1 && 's'}
          {!member.active && (
            <>
              <br /><span className="u-text-accent">Deactivated</span>
            </>
          )}
        </ResourceRight>
      </Resource>
    );
  };

  const renderNoResults = () => <div className="member-list__nocontacts_wrapper"><EmptyMessage section="Members" /></div>;

  const renderActive = () => (
    <ResourceGroup>
      {users.map(renderMember)}
    </ResourceGroup>
  );

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': !!activeMember,
  });

  const renderFilters = () => (
    <MemberFilters
      handleChange={handleChange}
      filters={filters}
      disabled={false}
      handleSetFilters={handleSetFilters}
    />
  );

  function renderUsers() {
    if (usersLoading) {
      return (
        <div className="u-text-center u-m-a-large">
          <LoaderCircle className="u-text-primary" />
        </div>
      );
    }
    if (users?.length > 0) return renderActive();
    return renderNoResults();
  }

  function handleToggleActivePanel(activeStatus) {
    handleTogglePanel();
    handleChange('active', activeStatus);
  }

  const tabTriggerActiveClasses = `tabs__trigger u-m-r ${filters?.active ? UtilitySystem.config.classes.active : ''}`;
  const tabTriggerDeactedClasses = `tabs__trigger ${!filters?.active ? UtilitySystem.config.classes.active : ''}`;

  return pageLoading ? <PageLoader /> : (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="app-page__header">
              <div className="app-page__header__title">Members</div>
              {(!isSSOEnabled || isCcr) && userHasAnyOfPermissions([MEMBER_CREATE]) && (
                <div className="app-page__header__action">
                  <Button
                    onClick={handleCreateRequest}
                    iconOnly
                    type="secondary"
                    title="Create Member"
                    data-cypress="createMemberProfile"
                  >
                    <Icon icon="add" />
                  </Button>
                </div>
              )}
            </div>
            <div className="u-flex u-flex-justify-center">
              <div className="sub-nav__tabs__header">
                <Button reset className={tabTriggerActiveClasses} onClick={() => handleToggleActivePanel(true)} data-cypress="active">
                  Active
                </Button>
                <Button reset className={tabTriggerDeactedClasses} onClick={() => handleToggleActivePanel(false)} data-cypress="deactivate">
                  Deactivated
                </Button>
              </div>
            </div>
            <div className="list-panel__body">
              <>
                <FilterPage
                  header={`Total ${filters?.active ? 'Active' : 'Deactivated'} Members`}
                  totalCount={totalUserCount}
                  showClearAll={filtersApplied}
                  clearAllFilters={clearAllFilters}
                  pageNumber={filters.pageNo}
                  loading={memberFetchLoading}
                  pageItemCount={users?.length}
                  type="contacts"
                  renderFilters={renderFilters}
                  hasPanels
                  paginationDisabled={false}
                  handlePagination={handlePagination}
                >
                  <>

                    {renderUsers()}
                  </>
                </FilterPage>
              </>
            </div>
          </div>
        </div>
        {activeMember && (
          <MemberProfilePanelContainer
            activeUser={activeMember}
            handleTogglePanel={handleTogglePanel}
            history={history}
            location={location}
            handleUpdateMemberActiveStatus={handleUpdateMemberActiveStatus}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizationMembersView;
