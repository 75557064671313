import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, Tooltip } from 'rhinostyle';
import PropTypes from 'prop-types';
import { getAppointmentCampaignByAppointmentType } from '../selectors/appointmentCampaignSelectors';

const AppointmentManagerStatusRow = ({
  row,
}) => {
  const appointmentCampaign = useSelector((state) => getAppointmentCampaignByAppointmentType(state, row.original));

  const renderStatus = (status, type, appointmentEventType) => {
    const messageEnabled = appointmentCampaign?.[`${appointmentEventType}Enabled`];
    if (appointmentEventType && !messageEnabled) return null;
    const statusClasses = { Failed: 'u-text-danger', Sent: 'u-text-success' };
    const tooltipContent = `${type} Message: ${status}`;
    const iconName = type === 'Created' ? 'appointment-created' : 'clock';
    return (
      <div className="appointment-status__row u-flex-align-items-center u-flex-direction-row u-flex" data-cypress={status}>
        <Tooltip content={tooltipContent} placement="bottom">
          <Icon icon={iconName} className={`${statusClasses[status] ?? ''}`} />
        </Tooltip>
        <span className={`${statusClasses[status] ?? ''} u-m-l-small`}>{status}</span>
      </div>
    );
  };
  return (
    <div className={row.original.read ? '' : 'u-font-weight-bold'}>
      {renderStatus(row.original.createdMessageStatus, 'Created')}
      {renderStatus(row.original.reminderMessageStatus, 'Reminder')}
      {renderStatus(row.original.additionalReminderMessageStatus, 'Additional Reminder')}
      {renderStatus(row.original.reminder3MessageStatus, 'Additional Reminder', 'appointmentReminder3')}
      {renderStatus(row.original.reminder4MessageStatus, 'Additional Reminder', 'appointmentReminder4')}
    </div>
  );
};

AppointmentManagerStatusRow.propTypes = {
  row: PropTypes.object.isRequired,
};

export default AppointmentManagerStatusRow;
