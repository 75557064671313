import PropTypes from 'prop-types';
import React from 'react';
import {
  LoaderPulse,
  Input,
  Icon,
  Resource,
  ResourceIntro,
  ResourceGroup,
  ResourceBody,
  ResourceRight,
  Scrollbars,
  Tooltip,
  UtilitySystem,
} from 'rhinostyle';
import LazyLoader from './LazyLoader';
import Tags from './Tags';

import { AppConstants } from '../constants';
import { UserHelpers } from '../helpers';
import { userHasAnyOfPermissions, userHasLimitedProviderRole } from '../helpers/UserHelpers';
import { ASSIGNMENT_SELF_CREATE } from '../constants/UserPermissionsConstants';

const UserSearch = (props) => {
  const {
    connectedPartySearchIds,
    connectedPartySearchLoading,
    defaultRouteMemberId,
    disabledUserIds,
    handleSearch,
    handleSearchSelect,
    interfaceMode,
    isAssignmentSearch,
    loggedInUser,
    memberSearchIds,
    memberSearchLoading,
    onScroll,
    searchFocus,
    searchText,
    selectedUserId,
    selectedUserIds,
    type,
    userSearchIds,
    userSearchLoading,
    userSearchContainerRef,
    users,
    usersLoading,
    isLimitedProvider,
  } = props;

  const renderResourceUserSearch = (id, idx) => {
    const user = users[id];
    const { onlineStatus } = user; // This property is being set explicitly in the WebSocketService and is not part of the User response object
    const profileImageUrl = user.profileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${user.profileImageUrl}` : null;
    const selected = interfaceMode === 'checkbox' ? selectedUserIds.includes(id) : selectedUserId === id;
    const disabled = disabledUserIds && disabledUserIds.includes(id);
    const isDefaultRoute = defaultRouteMemberId === id;
    const userType = type === 'connectedParty' ? 'default' : 'member';
    const isUserLoggedInUser = loggedInUser.id === user.id;
    const isUserUnavailableForAssignment =
      isAssignmentSearch
      && ((!userHasAnyOfPermissions([ASSIGNMENT_SELF_CREATE]) && isUserLoggedInUser) || !user.canReceiveAssignments
      || (isLimitedProvider && userHasLimitedProviderRole(user?.roles)));

    // const isMemberLimitedProvider = userHasLimitedProviderRole(user?.roles);

    let tooltipContent = !user.canReceiveAssignments ?
      'Member does not have the correct Contact and Conversations permission.' : 'Cannot assign to self without corresponding permission';
    if (isLimitedProvider && userHasLimitedProviderRole(user?.roles)) {
      tooltipContent = 'Conversation cannot be assigned to another provider';
    }
    const title = userType === 'member' ? UserHelpers.formatMemberNameWithPrefixAndSuffix(user) : UserHelpers.formatName(user);
    return (
      <Resource
        selected={selected}
        key={idx}
        onClick={() => handleSearchSelect(id)}
        disabled={disabled}
        unavailable={isUserUnavailableForAssignment}
      >
        <ResourceIntro
          avatar={{
            image: profileImageUrl,
            name: UserHelpers.formatAvatarName(user.firstName, user.lastName),
            type: userType,
            showOnlineStatus: true,
            onlineStatus,
          }}
          title={title}
        >
          {isDefaultRoute && <span className="u-text-accent">Default Route</span>}
        </ResourceIntro>
        {user.tags?.length > 0 && <ResourceBody className="u-text-muted"><Tags tagIds={user.tags} /></ResourceBody>}
        {isAssignmentSearch && isUserUnavailableForAssignment && (
          <ResourceRight>
            <span className="u-text-accent">
              Cannot be assigned
              <div className="u-m-l-small u-inline-block">
                <Tooltip
                  content={tooltipContent}
                  placement="left"
                  type="dark"
                >
                  <Icon icon="info-circle" className="u-text-muted" />
                </Tooltip>
              </div>
            </span>
          </ResourceRight>
        )}
      </Resource>
    );
  };

  const renderSearchHelp = (idArray = userSearchIds, loading = userSearchLoading) => {
    if ((searchText.length === 0 || searchText.length > 2) && loading) {
      return <div className="u-text-center"><LoaderPulse type="secondary" /></div>;
    } else if (searchText.length > 2 && !idArray.length && !loading) {
      return <div className="search__no-results">No results</div>;
    }

    return null;
  };

  let returnVal = '';

  if (type === 'connectedParty') {
    returnVal = (
      <div className="search__group">
        <Input
          placeholder="Search existing contacts"
          className="search__input"
          onChange={handleSearch}
          initialValue={searchText}
          focus={searchFocus}
          addon="left"
          size="large"
          name="connectedParty"
          autoComplete="off"
        >
          <Icon icon="search" />
        </Input>
        {connectedPartySearchIds.length > 0 ? (
          <Scrollbars
            className="resource-group__scroll"
            onScroll={onScroll}
            ref={userSearchContainerRef}
            autoHeight
            autoHeightMax={UtilitySystem.config.resourceSizes.large}
          >
            <ResourceGroup>
              {connectedPartySearchIds.map(renderResourceUserSearch)}
            </ResourceGroup>
          </Scrollbars>
        ) :
          renderSearchHelp(connectedPartySearchIds, connectedPartySearchLoading)}
      </div>
    );
  } else if (type === 'preloadedMembers') {
    returnVal = (
      <div className="search__group">
        <Input
          placeholder="Search Members"
          className="search__input"
          onChange={handleSearch}
          initialValue={searchText}
          focus={searchFocus}
          addon="left"
          size="large"
          name="preloadedMembers"
          autoComplete="off"
        >
          <Icon icon="search" />
        </Input>
        <LazyLoader loading={usersLoading} />
        {memberSearchIds.length > 0 ? (
          <Scrollbars
            onScroll={onScroll}
            className="resource-group__scroll"
            ref={userSearchContainerRef}
            autoHeight
            autoHeightMax={UtilitySystem.config.resourceSizes.large}
          >
            <ResourceGroup interfaceMode={interfaceMode}>
              {memberSearchIds.map(renderResourceUserSearch)}
            </ResourceGroup>
          </Scrollbars>
        ) :
          renderSearchHelp(memberSearchIds, memberSearchLoading)}
      </div>
    );
  }

  return returnVal;
};

UserSearch.propTypes = {
  connectedPartySearchIds: PropTypes.array.isRequired,
  connectedPartySearchLoading: PropTypes.bool.isRequired,
  contactProfileContainerOpen: PropTypes.bool.isRequired,
  defaultRouteMemberId: PropTypes.number,
  disabledUserIds: PropTypes.array,
  handleSearch: PropTypes.func.isRequired,
  handleSearchSelect: PropTypes.func.isRequired,
  interfaceMode: PropTypes.oneOf(['checkbox', 'radio']),
  isAssignmentSearch: PropTypes.bool,
  loggedInUser: PropTypes.object.isRequired,
  memberSearchIds: PropTypes.array.isRequired,
  memberSearchLoading: PropTypes.bool.isRequired,
  searchFocus: PropTypes.bool.isRequired,
  searchObject: PropTypes.object.isRequired,
  searchText: PropTypes.string.isRequired,
  selectedUserId: PropTypes.number,
  selectedUserIds: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['connectedParty', 'preloadedMembers']),
  userSearchIds: PropTypes.array.isRequired,
  userSearchSearchLoading: PropTypes.bool,
  userSearchContainerRef: PropTypes.func.isRequired,
  usersLoading: PropTypes.bool,
  users: PropTypes.object,
};

export default UserSearch;
