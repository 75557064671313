import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UtilitySystem } from 'rhinostyle';
// import WebSocketService from '../services/WebSocketService';
import * as AuthReducer from '../reducers/authReducer';
import * as SecureReducer from '../reducers/secureReducer';
import { LocationHelpers } from '../helpers';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import SecureThread from '../components/SecureThread';

class SecureThreadContainer extends Component {
  scrollPosition = 'bottom'; // eslint-disable-line react/sort-comp
  topMessageId = null;
  convoBodyRef = React.createRef();
  panelBreakpointMin = () => window.matchMedia(`(min-width: ${UtilitySystem.config.breakpoints.medium})`).matches;
  panelBreakpointMax = () => window.matchMedia(`(max-width: ${UtilitySystem.config.breakpoints.mediumMax})`).matches;

  state = {
    lightboxAttachmentUrl: '',
    lightboxIsOpen: false,
    profileOpen: true,
    mobileProfileOpen: false,
    pageLoading: true,
  }

  componentDidMount() {
    this.props.requestSecureViewData();

    // Set context
    const channelId = Number(this.props.match.params.channelId) || null;
    const organizationId = Number(this.props.match.params.organizationId) || null;
    this.props.setSecureContext({ channelId, organizationId });

    // Init panel system
    this.panelWatch(true);

    UtilitySystem.optimizedResize.add(() => {
      this.panelWatch(false, true);
    });

    if (this.props.match.params.channelId) {
      this.props.fetchPatientThreadChannelView({
        userId: this.props.user.id,
        channelId: this.props.match.params.channelId,
        orgId: this.props.currentOrganization.id,
      }).then(() => {
        this.props.fetchPatientMenu();
        this.scrollTo(true);
      });
    } else {
      this.props.fetchPatientThreadAllView({ userId: this.props.user.id })
        .then(() => {
          this.props.fetchPatientMenu();
          this.scrollTo(true);
        });
    }
  }

  componentDidUpdate(prevProps) {
    const eventHistoryChanged = (this.props.eventIds.length !== prevProps.eventIds.length) && this.props.eventIds.length;
    if (eventHistoryChanged && !this.props.threadLoading) {
      const lastEventID = this.props.eventIds[this.props.eventIds.length - 1];
      const lastEvent = this.props.events[lastEventID];
      const forceBottom = (!lastEvent.incoming && this.props.user.id === lastEvent.sender) && (lastEvent.incoming);

      this.scrollTo(forceBottom);
    }

    // start
    const orgHasChanged = this.props.match.params.organizationId && prevProps.match.params.organizationId !== this.props.match.params.organizationId;
    const channelToAllMessages = !this.props.match.params.channelId && prevProps.match.params.channelId && !orgHasChanged;
    const channelHasChanged = this.props.match.params.channelId && prevProps.match.params.channelId !== this.props.match.params.channelId;

    if ((channelHasChanged || orgHasChanged || channelToAllMessages) && !this.props.pageLoading) {
      this.props.resetEventData();
      this.props.requestSecureViewData();
      this.topMessageId = null; // Reset initial topMessageId
      this.scrollPosition = 'bottom';

      // Update secure context
      const channelId = Number(this.props.match.params.channelId) || null;
      const organizationId = Number(this.props.match.params.organizationId) || null;
      this.props.setSecureContext({ channelId, organizationId });

      if (channelHasChanged) {
        this.props.fetchPatientThreadChannelView({
          userId: this.props.user.id,
          channelId: this.props.match.params.channelId,
          orgId: this.props.currentOrganization.id,
        }).then(() => {
          this.props.fetchPatientMenu();
          this.scrollTo(true);
        });
      } else if (orgHasChanged || channelToAllMessages) {
        this.props.fetchPatientThreadAllView({
          userId: this.props.user.id,
        }).then(() => {
          this.props.fetchPatientMenu();
          this.scrollTo(true);
        });
      }
    }

    if (this.props.pageLoading !== prevProps.pageLoading) {
      this.setState({ pageLoading: this.props.pageLoading }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  componentWillUnmount() {
    // Kill function that determines panel state so this doesn't bleed out to other components when navigating throughout the app
    this.panelWatch = () => null;

    this.props.resetEventData();
  }

  panelWatch = (load = false, resize = false) => {
    // If screen is at larger-size, make sure profile is open
    if (this.panelBreakpointMin()) {
      if (!this.state.profileOpen) {
        this.setState({
          profileOpen: true,
        });
      }

      if (this.state.mobileProfileOpen) {
        this.setState({
          mobileProfileOpen: false,
        });
      }
    } else if (this.panelBreakpointMax()) {
      // Only run on `componentDidMount()`
      if (load) {
        this.setState({
          profileOpen: false,
        });
      } else if (resize) {
        if (this.state.profileOpen && !this.state.mobileProfileOpen) {
          // Mobile profile was not open and regular profile was, so we closed it
          this.setState({
            profileOpen: false,
          });
        }
      }
    }

    return true;
  }

  onScroll = () => {
    if (this.props.threadLoading) return;

    const scrollContainer = this.convoBodyRef.current.container.firstChild;
    const totalScroll = scrollContainer.scrollTop + scrollContainer.clientHeight;

    if (totalScroll === scrollContainer.scrollHeight) {
      this.scrollPosition = 'bottom';
    }

    if ((totalScroll !== scrollContainer.scrollHeight) && (scrollContainer.scrollTop !== 0)) {
      this.scrollPosition = 'middle';
    }

    if (scrollContainer.scrollTop === 0) {
      this.scrollPosition = 'top';
      this.topMessageId = this.props.eventIds[0] || null;

      this.props.fetchPatientThread({ userId: this.props.user.id, channelId: this.props.match.params.channelId, pageNo: this.props.threadPageNo + 1 });
    }
  }

  /**
   * Scrolls thread to specific position based on current
   *
   * @param  {boolean} forceBottom
   * @return {void}
   */
  scrollTo = (forceBottom) => {
    if (!this.convoBodyRef.current) return;
    const scrollContainer = this.convoBodyRef.current.container.firstChild;

    if (this.scrollPosition === 'bottom' || forceBottom) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    } else if (this.topMessageId) {
      const $topMessage = document.querySelector(`#js-convo__item-${this.topMessageId}`);

      // Scroll message container to the top message
      scrollContainer.scrollTop = $topMessage.offsetTop;

      // Reset top message and scroll position
      this.topMessageId = null;
      this.scrollPosition = 'middle';
    }
  }

  toggleLightbox = (lightboxIsOpen, lightboxAttachmentUrl) => this.setState({ lightboxIsOpen, lightboxAttachmentUrl })

  handleToggleProfile = () => {
    this.setState({
      profileOpen: !this.state.profileOpen,
      mobileProfileOpen: this.panelBreakpointMin() ? false : !this.state.mobileProfileOpen,
    });
  }

  render() {
    document.title = `Rhinogram | ${LocationHelpers.getPageTitle(window.location.pathname)}`;

    const props = {
      channel: this.props.channels[this.props.match.params.channelId],
      channels: this.props.channels,
      convoBodyRef: this.convoBodyRef,
      currentOrganization: this.props.currentOrganization,
      currentUser: this.props.user,
      eventIds: this.props.eventIds,
      events: this.props.events,
      handleToggleProfile: this.handleToggleProfile,
      lightboxAttachmentUrl: this.state.lightboxAttachmentUrl,
      lightboxIsOpen: this.state.lightboxIsOpen,
      onScroll: this.onScroll,
      pageLoading: this.state.pageLoading,
      params: this.props.match.params,
      profileOpen: this.state.profileOpen,
      threadLoading: this.props.threadLoading,
      toggleLightbox: this.toggleLightbox,
      types: this.props.types,
      users: this.props.users,
      match: this.props.match,
    };

    return <SecureThread {...props} />;
  }
}

SecureThreadContainer.propTypes = {
  channels: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  eventIds: PropTypes.array,
  events: PropTypes.object,
  fetchPatientMenu: PropTypes.func.isRequired,
  fetchPatientThread: PropTypes.func.isRequired,
  fetchPatientThreadAllView: PropTypes.func.isRequired,
  fetchPatientThreadChannelView: PropTypes.func.isRequired,
  organizations: PropTypes.object,
  pageLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  requestSecureViewData: PropTypes.func.isRequired,
  resetEventData: PropTypes.func.isRequired,
  setSecureContext: PropTypes.func.isRequired,
  threadLoading: PropTypes.bool,
  threadPageNo: PropTypes.number,
  types: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.object,
};

function mapStateToProps(state) {
  const { event, user, secure, channel, organization, type } = state; // eslint-disable-line

  return {
    channels: channel.channels,
    currentOrganization: getCurrentOrg(state),
    eventIds: secure.eventIds,
    events: secure.events,
    organizations: secure.organizations,
    pageLoading: secure.pageLoading,
    threadLoading: secure.threadLoading,
    threadPageNo: secure.threadPageNo,
    types: type.types,
    user: getLoggedInUser(state),
    users: user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPatientMenu: bindActionCreators(SecureReducer.fetchPatientMenu, dispatch),
    fetchPatientThread: bindActionCreators(SecureReducer.fetchPatientThread, dispatch),
    fetchPatientThreadAllView: bindActionCreators(SecureReducer.fetchPatientThreadAllView, dispatch),
    fetchPatientThreadChannelView: bindActionCreators(SecureReducer.fetchPatientThreadChannelView, dispatch),
    logout: bindActionCreators(AuthReducer.logout, dispatch),
    requestSecureViewData: bindActionCreators(SecureReducer.requestSecureViewData, dispatch),
    resetEventData: bindActionCreators(SecureReducer.resetEventData, dispatch),
    setSecureContext: bindActionCreators(SecureReducer.setSecureContext, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecureThreadContainer);
