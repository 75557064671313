import React, { useState, useMemo } from 'react';
import {
  Button,
  CheckboxGroup,
  Input,
  Scrollbars,
  UtilitySystem,
  FormValidationMessage,
} from 'rhinostyle';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { useParams } from 'react-router-dom';
import AppointmentCampaignCheckbox from './AppointmentCampaignCheckbox';

const AppointmentCampaignMultiSelect = ({ handleChange, selected, allItems, allIds, title, name, shortName = name, validationMessage, currentOrganization }) => {
  const [viewSelected, setViewSelected] = useState(false);
  const [search, setSearch] = useState('');
  const { appointmentCampaignId } = useParams();
  const debouncedSearch = useMemo(() => debounce(setSearch, 300), []);
  const selectAllAvailable = selected.length > 0 || viewSelected;
  const getName = (item) => item?.alias || item?.name || '';

  const handleSearch = (fieldName, value) => {
    debouncedSearch(value);
  };
  const handleCheck = (itemId) => {
    const index = selected.indexOf(itemId);
    const updatedItems = [...selected];
    if (index < 0) {
      updatedItems.push(itemId);
    } else {
      updatedItems.splice(index, 1);
    }
    handleChange(name, updatedItems);
  };

  const handleSelectAll = () => {
    handleChange(name, [...allIds]);
  };

  const renderActionButton = () => {
    let buttonText = viewSelected ? `Add more ${shortName.toLowerCase()}` : 'Review';
    if (!selectAllAvailable) buttonText = 'Select All';
    return (
      <Button
        className="u-text-primary appointment-reminder-form__types-button"
        onClick={() => (selectAllAvailable ? setViewSelected((current) => !current) : handleSelectAll())}
        reset
        data-cypress={`selectAll${shortName}`}
      >
        {buttonText}
      </Button>
    );
  };

  const getVisibleItemIds = useMemo(() => {
    const visibleItemIds = viewSelected ?
      allIds.filter((itemId) => selected.includes(itemId)) : allIds;
    if (search.length > 2) {
      return visibleItemIds.filter((itemId) => getName(allItems[itemId])?.toLowerCase().includes(search.toLowerCase()));
    }
    return visibleItemIds;
  }, [search, viewSelected, selected]);

  const renderCheckbox = (itemId) => (
    <AppointmentCampaignCheckbox
      itemId={itemId}
      isChecked={selected.includes(itemId)}
      handleCheck={handleCheck}
      item={allItems[itemId]}
      appointmentCampaignId={appointmentCampaignId}
      fieldName={name}
      itemName={getName(allItems[itemId])}
      key={itemId}
      currentOrganization={currentOrganization}
    />
  );

  return (
    <div className="box">
      <div className="u-flex u-m-b-large u-flex-justify-between">
        {renderActionButton()}
        <div>
          <div className="box__title u-text-center">{title}</div>
          <FormValidationMessage validationMessage={validationMessage} />
        </div>
        <div className="appointment-reminder-form__types-count">
          {selectAllAvailable && (`${selected.length} ${selected.length === 1 ? shortName.slice(0, -1) : shortName} Added`)}
        </div>
      </div>
      <div className="u-text-center" />
      <div className="appointment-reminder-form__box-wrapper">
        <Input
          name="search"
          value={search}
          className="u-m-b"
          onChange={handleSearch}
          placeholder={`Search ${shortName}`}
          dataFeatureTag={`${name}Search`}
        />
        {getVisibleItemIds?.length > 0 ? (
          <Scrollbars
            autoHeight
            autoHeightMax={UtilitySystem.config.resourceSizes.large}
            className="appointment-type__scroll"
          >
            <CheckboxGroup blockGroup>
              {getVisibleItemIds.map((item) => (renderCheckbox(item)))}
            </CheckboxGroup>
          </Scrollbars>
        ) : <div>No Results</div>}
      </div>
    </div>
  );
};
export default AppointmentCampaignMultiSelect;

AppointmentCampaignMultiSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  allItems: PropTypes.object.isRequired,
  allIds: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shortName: PropTypes.string,
  validationMessage: PropTypes.string,
  currentOrganization: PropTypes.object,
};
