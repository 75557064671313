import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  Scrollbars,
  UtilityInlineGrid,
} from 'rhinostyle';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLoggedInUserPermissionNames } from '../selectors/userSelectors';
import { ROUTING_MANAGER_EDIT } from '../constants/UserPermissionsConstants';
import { useTextClassificationRoute } from '../hooks';

const RoutingManagerSummaryPanel = (props) => {
  const history = useHistory();
  const {
    textClassificationRouteId,
    loggedInUserPermissions,
  } = props;
  const { textClassificationRoute } = useTextClassificationRoute({ textClassificationRouteId });
  const { toNameFormatted, toType, routeNameFormatted, fullRouteNameFormatted } = textClassificationRoute;
  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title" data-cypress="summaryTitle">Summary</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => {
                history.push('/managers/routing-manager');
              }}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__title u-m-t">
                {routeNameFormatted}
              </div>
              <div className="summary-panel__intro__actions">
                {loggedInUserPermissions.includes(ROUTING_MANAGER_EDIT) && (
                  <UtilityInlineGrid align="middle">
                    <Button
                      className="u-m-t u-m-b"
                      size="small"
                      type="primary"
                      outlined
                      onClick={() => history.push(`/managers/routing-manager/edit/${textClassificationRouteId}`)}
                      data-feature-tag="routing-manager_summary-route"
                    >
                      Edit Route
                    </Button>
                  </UtilityInlineGrid>
                )}
              </div>
            </div>
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Categories</div>
              <div className="summary-panel__section__content" data-feature-tag="routing-panel-categories">
                {fullRouteNameFormatted}
              </div>
            </div>
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Routing Path</div>
              <div className="summary-panel__section__content" data-feature-tag="routing-panel-route">
                {`${toNameFormatted} (${toType})`}
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedInUserPermissions: getLoggedInUserPermissionNames(state),
  categories: state.routing.categories,

});

RoutingManagerSummaryPanel.propTypes = {
  textClassificationRouteId: PropTypes.number.isRequired,
  loggedInUserPermissions: PropTypes.array,
};

export default connect(mapStateToProps)(RoutingManagerSummaryPanel);
