import { createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import axios from 'axios';
import { mergeShallow } from '../helpers/DataHelpers';

import * as AuthActionTypes from '../constants/AuthActionTypes';
import * as ChannelActionTypes from '../constants/ChannelActionTypes';

import * as ChatActionTypes from './chatReducer';
import * as GroupActionTypes from './groupReducer';
import * as OrganizationActionTypes from './organizationReducer';
import * as OutOfOfficeActionTypes from './outOfOfficeReducer';

import * as InboxActionTypes from '../constants/InboxActionTypes';
import * as UserActionTypes from '../constants/UserActionTypes';
import * as SavedContentActionTypes from '../constants/SavedContentActionTypes';
import { channel, organization } from '../actions/NormalizrSchema';

// SLICE
const rhinogramSlice = createSlice({
  name: 'RHINOGRAM',
  initialState: {
    rhinograms: {},
    rhinogramIds: [],
    rhinogramChannelsLoading: false,
    rhinogramOrgsLoading: false,
    rhinogramChannelIds: [],
    rhinogramOrgId: null,
    rhinogramOrgs: {},
    rhinogramOrgIds: [],
    rhinogramChannels: {},
  },
  reducers: {
    receiveRhinograms: receiveRhinogramsData,
    receiveRhinogramOrgs: receiveRhinogramOrgsData,
    receiveRhinogramChannels: receiveRhinogramChannelsData,
    setRhinogramChannelsLoading: (state, action) => ({
      ...state,
      rhinogramChannelsLoading: action.payload,
    }),
    setRhinogramOrgsLoading: (state, action) => ({
      ...state,
      rhinogramOrgsLoading: action.payload,
    }),
    setRhinogramOrgId: (state, action) => ({
      ...state,
      rhinogramOrgId: action.payload,
    }),
  },
  extraReducers: {
    [ChannelActionTypes.receiveChannelView]: receiveRhinogramsData,
    [ChannelActionTypes.receiveChannels]: receiveRhinogramsData,
    [ChannelActionTypes.receiveCreateChannel]: receiveRhinogramsData,

    [InboxActionTypes.receiveInboxThreadView]: receiveRhinogramsData,
    [InboxActionTypes.receiveInbox]: receiveRhinogramsData,
    [InboxActionTypes.receiveInboxThread]: receiveRhinogramsData,
    [InboxActionTypes.receiveWebSocketEvent]: receiveRhinogramsData,

    [AuthActionTypes.setUser]: receiveRhinogramsData,

    [ChatActionTypes.receiveChatThreadGroupView]: receiveRhinogramsData,

    [UserActionTypes.receiveMyUsers]: receiveRhinogramsData,
    [UserActionTypes.receiveContactListUsers]: receiveRhinogramsData,
    [UserActionTypes.receiveUsers]: receiveRhinogramsData,
    [UserActionTypes.receiveUsersSearch]: receiveRhinogramsData,
    [UserActionTypes.receiveContactUsers]: receiveRhinogramsData,
    [UserActionTypes.receiveConnectedPartySearch]: receiveRhinogramsData,
    [UserActionTypes.receiveUsersPhonesSearch]: receiveRhinogramsData,
    [UserActionTypes.receiveUser]: receiveRhinogramsData,
    [UserActionTypes.receiveCreateUser]: receiveRhinogramsData,
    [UserActionTypes.receiveUpdateUser]: receiveRhinogramsData,
    [UserActionTypes.receiveMembersView]: receiveRhinogramsData,
    [UserActionTypes.receiveMembersList]: receiveRhinogramsData,
    [UserActionTypes.receiveContactEditFormView]: receiveRhinogramsData,
    [UserActionTypes.receiveUsersModalPhonesSearch]: receiveRhinogramsData,
    [UserActionTypes.receiveContactList]: receiveRhinogramsData,

    [GroupActionTypes.receiveGroups]: receiveRhinogramsData,
    [GroupActionTypes.receiveGroupView]: receiveRhinogramsData,

    [OrganizationActionTypes.receiveOrganizationPreferencesView]: receiveRhinogramsData,

    [OutOfOfficeActionTypes.receiveOOOView]: receiveRhinogramsData,

    [SavedContentActionTypes.receiveEventsForSavedContent]: receiveRhinogramsData,
  },
});

export default rhinogramSlice.reducer;

// ACTIONS
export const {
  receiveRhinograms,
  setRhinogramOrgsLoading,
  receiveRhinogramChannels,
  setRhinogramOrgId,
  setRhinogramChannelId,
  receiveRhinogramOrgs,
  setRhinogramChannelsLoading,
} = rhinogramSlice.actions;

// THUNKS -- ASYNC ACTION CREATORS

export function fetchRhinogramChannels(orgId) {
  return (dispatch) => {
    dispatch(setRhinogramOrgId(orgId));
    dispatch(setRhinogramChannelsLoading(true));
    return getRhinogramChannelsByOrgId(orgId)
      .then((response) => {
        const normalized = normalize(response.data, [channel]);
        dispatch(receiveRhinogramChannels(getRhinogramChannelsPayload(normalized)));
        dispatch(setRhinogramChannelsLoading(false));
      })
      .catch(err => console.error(err)); // eslint-disable-line
  };
}

export function fetchOrgsWithRhinogramChannels() {
  return (dispatch) => {
    dispatch(setRhinogramOrgsLoading(true));

    return getOrgsWithRhinogramChannels()
      .then((response) => {
        const normalized = normalize(response.data, [organization]);
        dispatch(receiveRhinogramOrgs(getOrganizationsPayload(normalized)));
        dispatch(setRhinogramOrgsLoading(false));
      })
      .catch(err => console.error(err)); // eslint-disable-line
  };
}

export function fetchRhinogramOrgById(id) {
  return (dispatch) => {
    dispatch(setRhinogramOrgsLoading(true));

    return getRhinogramOrgById(id)
      .then((response) => {
        const normalized = normalize([response.data], [organization]);
        dispatch(receiveRhinogramOrgs(getOrganizationsPayload(normalized)));
        dispatch(setRhinogramOrgsLoading(false));
      })
      .catch(err => console.error(err)); // eslint-disable-line
  };
}

let cancelRhinogramsOrgSearch;

export function fetchRhinogramsOrgSearch(search) {
  if (cancelRhinogramsOrgSearch) cancelRhinogramsOrgSearch();
  const encodedSearch = encodeURIComponent(search);

  return (dispatch) => {
    dispatch(setRhinogramOrgsLoading(true));

    return getRhinogramOrgsSearch(encodedSearch)
      .then((response) => {
        const normalized = normalize(response.data, [organization]);
        dispatch(receiveRhinogramOrgs(getOrganizationsPayload(normalized)));
        dispatch(setRhinogramOrgsLoading(false));
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          cancelRhinogramsOrgSearch = null;
          console.error(err.response || err);
        }
      });
  };
}

// AXIOS HELPERS

function getRhinogramChannelsByOrgId(orgId) {
  return axios.get(`/channels/rhinogramChannels/${orgId}`);
}

function getOrgsWithRhinogramChannels() {
  return axios.get('/organization/getOrgsWithRhinogramChannels');
}

function getRhinogramOrgById(orgId) {
  return axios.get(`/organization/rhinogram/${orgId}`);
}

function getRhinogramOrgsSearch(encodedSearch) {
  return axios.get(`/organization/searchRhinogramOrgs?q=${encodedSearch}`, {
    cancelToken: new axios.CancelToken(((cancelFunction) => {
      cancelRhinogramsOrgSearch = cancelFunction;
    })),
  });
}

// REDUCER HELPERS

function receiveRhinogramChannelsData(state, action) {
  return {
    ...state,
    rhinogramChannels: action.payload.rhinogramChannels,
    rhinogramChannelIds: action.payload.rhinogramChannelIds,
    rhinogramChannelsLoading: false,
  };
}

function receiveRhinogramsData(state, action) {
  return {
    ...state,
    rhinograms: action.payload.rhinograms ?
      mergeShallow(state.rhinograms, action.payload.rhinograms) : state.rhinograms, // merging due to partial/full phone object scenario with `otherOwners`
    rhinogramIds: action.payload.rhinogramIds ? [...new Set([...state.rhinogramIds, ...action.payload.rhinogramIds])] : state.rhinogramIds,
  };
}

function receiveRhinogramOrgsData(state, action) {
  return {
    ...state,
    rhinogramOrgs: action.payload.rhinogramOrgs,
    rhinogramOrgIds: action.payload.rhinogramOrgIds,
  };
}

// Helper functions
function getRhinogramChannelsPayload(normalizedChannel) {
  return {
    rhinogramChannels: {
      ...normalizedChannel.entities.channels,
    },
    rhinogramChannelIds: normalizedChannel.result,
  };
}

function getOrganizationsPayload(normalizedOrganization) {
  return {
    rhinogramOrgs: normalizedOrganization.entities.organizations,
    rhinogramOrgIds: normalizedOrganization.result,
  };
}
