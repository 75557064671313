import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  Scrollbars,
  UtilityInlineGrid,
  UtilityList,
  UtilityListItem,
} from 'rhinostyle';

import { formatAddress } from '../helpers/DataHelpers';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { OFFICE_EDIT } from '../constants/UserPermissionsConstants';

const OfficePanel = (props) => {
  const history = useHistory();
  const {
    activeOffice,
    handleTogglePanel,
    currentOrganization,
  } = props;

  const pushRoute = (url) => history.push(url);

  const renderVCard = (vCard) => (
    <UtilityListItem key={vCard.id}>
      {vCard.vCardName}
    </UtilityListItem>
  );

  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title">Summary</div>

          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => handleTogglePanel(activeOffice.id)}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__title">
                {activeOffice.name}
              </div>
              <div className="summary-panel__intro__actions">
                <UtilityInlineGrid align="middle">
                  {userHasAnyOfPermissions([OFFICE_EDIT]) && (
                    <Button
                      size="small"
                      onClick={() => pushRoute(`/settings/organization/offices/edit/${activeOffice.id}`)}
                      type="primary"
                      outlined
                      data-cypress="editOffice"
                    >
                      Edit Office
                    </Button>
                  )}
                </UtilityInlineGrid>
              </div>
            </div>
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Address</div>
              <div className="summary-panel__section__content">
                {formatAddress(activeOffice.address)}
              </div>
            </div>
            {(currentOrganization?.isOfficeVCardEnabled && activeOffice?.vCards?.length > 0) && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">VCard</div>
                <div className="summary-panel__section__content">
                  <UtilityList space className="u-m-b-0">
                    {activeOffice?.vCards?.map(renderVCard)}
                  </UtilityList>
                </div>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

OfficePanel.propTypes = {
  activeOffice: PropTypes.object.isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object,
};

export default OfficePanel;
