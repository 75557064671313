import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import linkifyStr from 'linkifyjs/string';
import Mark from 'mark.js';
import { Message } from 'rhinostyle';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import cx from 'classnames';
import { getMentionedIdFromString, getHasMention } from '../helpers/StringHelpers';
import Mention from './threadEvents/Mention';
import { MessageStatus } from '../constants/v3';
import { replaceNewLinesToLineBreaks } from '../helpers/TemplateHelpers';

const HtmlMessage = (props) => {
  const {
    appendedElement,
    event,
    isNoteEvent = false,
    searchText = undefined,
    isTranslated,
    isRightToLeft,
  } = props;
  const location = useLocation();
  const isIncoming = event.isIncoming || event.incoming;
  const hasSuggestedRoute = event.isPatientInitiatedMessage && event.suggestedTextClassificationType && !location.pathname.includes('all');
  const eventWithDetails = {
    ...event,
    ...event.details && {
      ...event.details,
    },
  };

  const messageClass = cx('convo__item__body__msg', {
    'u-text-rtl': isRightToLeft,
    'convo__item__body__msg--pim': event.isPatientInitiatedMessage,
    'has-border': hasSuggestedRoute,
    'convo__item__body__msg--not-sent': event.isNetworkError,
    'convo__item__body__msg--unsent': event.status === MessageStatus.Unsent,
  });

  const eventText = isTranslated && eventWithDetails.translatedText ? eventWithDetails.translatedText : eventWithDetails.text;

  const getMessageType = () => {
    let type = 'primary';

    if (isNoteEvent) {
      type = 'note';
    } else if (isIncoming) {
      type = 'default';
    }

    return type;
  };
  /**
   * Open internal links in the current window and
   * external links in a new window.
   */
  const linkifyTarget = (href, type) => {
    const { hostname } = window.location;
    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    const hrefHostname = anchorElement.hostname;
    let target = null;
    // Only open a new tab if link is NOT to an app route.
    if (type === 'url' && !hrefHostname.toLowerCase().includes(hostname)) {
      target = '_blank';
    }
    return target;
  };

  const linkifyOptions = {
    target: linkifyTarget,
    ignoreTags: ['script', 'style', 'img'],
  };

  const highlight = (text) => {
    const el = document.createElement('span');
    el.innerHTML = text;

    const instance = new Mark(el);

    instance.mark(searchText, {
      element: 'span',
      separateWordSearch: false,
      diacritics: false,
      className: 'u-text-highlight',
    });

    return el.innerHTML;
  };

  const userIdPrefix = '{userId-';
  const groupIdPrefix = '{groupId-';
  const allVariable = '{@All}';
  function transform(node, index) {
    if (node.name === 'span' && node?.children?.[0]?.data) {
      const mentionPayload = getMentionedIdFromString(node.children[0].data, event.eventMentions);
      if (mentionPayload) {
        const { groupId, userId } = mentionPayload;
        return <Mention groupId={groupId} userId={userId} key={index} />;
      }
    }
    return convertNodeToElement(node, index, transform);
  }
  const mention = (text) => {
    // Re-assign text value for processing
    const split = text.split(/\s/);
    let formattedText = text;
    split.map((el) => {
      if (getHasMention(el)) {
        const $variable = document.createElement('span');
        // Disable spell-checker
        $variable.setAttribute('spellcheck', false);
        // Do not allow the variable to be edited
        $variable.innerHTML = el;
        const rawMention = new RegExp(el, 'g');
        formattedText = formattedText.replace(rawMention, $variable.outerHTML);
        return $variable.outerHTML;
      }
      return el;
    });
    return formattedText;
  };

  const messageTextHtml = () => {
    let html = linkifyStr(eventText, linkifyOptions);
    const textIncludesVariable = html.includes(userIdPrefix) || html.includes(allVariable) || html.includes(groupIdPrefix);
    html = textIncludesVariable ? mention(html) : html;
    html = replaceNewLinesToLineBreaks(html);

    if (searchText) {
      return highlight(html);
    }

    return html;
  };

  function renderChildren() {
    return (
      <>
        {eventText && ReactHtmlParser(messageTextHtml(), { transform })}
        {appendedElement}
      </>
    );
  }

  function renderPIMOverlay() {
    return (
      <>
        <span className="pim-icon" data-cypress="pimIcon">PIM</span>
        <div className={`${messageClass} msg msg--default msg__overlay msg--inbound`}>
          {renderChildren()}
        </div>
      </>
    );
  }

  return (
    <>
      {hasSuggestedRoute && (
        <div className="convo__item__body__footer--pim" />
      )}
      <Message
        direction={isIncoming ? 'inbound' : 'outbound'}
        type={getMessageType()}
        className={messageClass}
      >
        {event?.isPatientInitiatedMessage && (renderPIMOverlay())}
        {renderChildren()}
      </Message>
    </>
  );
};

HtmlMessage.propTypes = {
  appendedElement: PropTypes.element,
  isNoteEvent: PropTypes.bool,
  searchText: PropTypes.string,
  isTranslated: PropTypes.bool,
  event: PropTypes.object,
  isRightToLeft: PropTypes.bool,
};

export default HtmlMessage;
