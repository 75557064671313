import {
  conversationTypeList,
  conversationActionArray,
  BULK_TAG_MAX_CONTACTS,
  chatConversationActionArray,
  chatconversationTypeList,
} from '../constants/AppConstants';
import { DataHelpers } from '.';
import { userHasAnyOfPermissions, getMention } from './UserHelpers';
import {
  ASSIGNMENT_SELF_CREATE,
  ASSIGNMENT_MEMBER_CREATE,
  ASSIGNMENT_GROUP_CREATE,
  CONVERSATION_CLOSE_DEFAULT,
  THREAD_FOLLOW_EDIT,
  THREAD_UNREAD_EDIT,
  BULK_MESSAGING_EDIT,
  TEAM_THREAD_UNREAD_EDIT,
  CONTACT_EDIT,
} from '../constants/UserPermissionsConstants';
import store from '../store';
import { getRhinoblastMaxPageSize } from '../selectors/organizationSelectors';
import { userHasLimitedProviderRole } from '../selectors/userSelectors';

export function fetchSelectedIds(label, eventsArray) {
  let selectedIds = [];
  switch (label) {
    case conversationTypeList[0].label:
      selectedIds = eventsArray.map((event) => event.id);
      break;
    case conversationTypeList[2].label:
      selectedIds = eventsArray.filter((event) => event.read && event.id).map((result) => result.id);
      break;
    case conversationTypeList[3].label:
      selectedIds = eventsArray.filter((event) => !event.read && event.id).map((result) => result.id);
      break;
    case conversationTypeList[4].label:
      selectedIds = eventsArray.filter((event) => event.assigned && event.id).map((result) => result.id);
      break;
    case conversationTypeList[5].label:
      selectedIds = eventsArray.filter((event) => !event.assigned && event.id).map((result) => result.id);
      break;
    case conversationTypeList[6].label:
      selectedIds = eventsArray.filter((event) => event.following && event.id).map((result) => result.id);
      break;
    case conversationTypeList[7].label:
      selectedIds = eventsArray.filter((event) => !event.following && event.id).map((result) => result.id);
      break;
    default: break;
  }
  return selectedIds;
}

export function fetchSelectedMentionIds(label, eventsArray, currentUser) {
  let selectedIds = [];
  if (label === conversationTypeList[0].label) {
    selectedIds = eventsArray.map((event) => event.id);
  } else if (label === conversationTypeList[2].label) {
    selectedIds = eventsArray.filter(
      (event) => getMention(event.eventMentions, currentUser) && getMention(event.eventMentions, currentUser).isRead && event.id,
    ).map((result) => result.id);
  } else if (label === conversationTypeList[3].label) {
    selectedIds = eventsArray.filter(
      (event) => getMention(event.eventMentions, currentUser) && !getMention(event.eventMentions, currentUser).isRead && event.id,
    ).map((result) => result.id);
  }
  return selectedIds;
}

export function fetchSelectedPatientUserIds(selectedIds, eventsArray) {
  return selectedIds.map((event) => {
    const selectedEvent = eventsArray.find((item) => item.id === event);
    return selectedEvent && selectedEvent.user;
  });
}

export function getPatientUserId(selectedId, eventsArray) {
  const selectedEvent = eventsArray.find((item) => item.id === selectedId);
  return selectedEvent && selectedEvent.user;
}

export function excludeActionsOnInboxType(actions, options, label) {
  let bulkActions = [];
  if (options.following) {
    bulkActions = actions.filter((action) => ['unFollow', 'markAsRead', 'markAsUnread', 'rhinoBlast'].includes(action.code));
  } else if (options.assigned) {
    bulkActions = actions.filter((action) => ['assign', 'completeAssignment', 'follow', 'unFollow', 'markAsRead', 'markAsUnread', 'rhinoBlast'].includes(action.code));
  } else if (options.direct) {
    bulkActions = actions.filter((action) => !['assignToMe', 'completeAssignment'].includes(action.code));
  } else if (DataHelpers.exists(options.groupId)) {
    const filterValue = label === 'Assigned' ? 'closeBulkConversations' : 'completeAssignment';
    bulkActions = actions.filter((action) => action.code !== filterValue && !['closeMention', 'closeMentionForAllMembers'].includes(action.code));
  } else if (options.mentions) {
    bulkActions = actions.filter((action) => ['markAsRead', 'markAsUnread', 'closeMention', 'closeMentionForAllMembers'].includes(action.code));
  }
  return bulkActions;
}

export function getMaxMessageCount() {
  return getRhinoblastMaxPageSize(store.getState());
}

export function isLimitedProvider() {
  return userHasLimitedProviderRole(store.getState());
}

export function fetchBulkActions(label, options, isBulkMessagingEnabled, isCloseMentionsForAllMembersEnabled, selectedIds) {
  const maxMessageCount = getMaxMessageCount();
  let bulkActionList = [];
  const typeList = {
    read: 'markAsRead',
    unread: 'markAsUnread',
    following: 'follow',
    notFollowing: 'unFollow',
    rhinoBlast: 'rhinoBlast',
  };
  switch (label) {
    case conversationTypeList[0].label:
    case conversationTypeList[1].label:
    case conversationTypeList[4].label:
    case conversationTypeList[5].label:
      bulkActionList = conversationActionArray;
      break;
    case conversationTypeList[2].label:
      bulkActionList = conversationActionArray.filter((action) => action.code !== typeList.read);
      break;
    case conversationTypeList[3].label:
      bulkActionList = conversationActionArray.filter((action) => action.code !== typeList.unread);
      break;
    case conversationTypeList[6].label:
      bulkActionList = conversationActionArray.filter((action) => action.code !== typeList.following);
      break;
    case conversationTypeList[7].label:
      bulkActionList = conversationActionArray.filter((action) => action.code !== typeList.notFollowing);
      break;
    default: break;
  }
  bulkActionList = excludeActionsOnInboxType(bulkActionList, options, label);

  const bulkActionRhinoBlastIndex = bulkActionList.findIndex((action) => action.code === typeList.rhinoBlast);
  const bulkActionRhinoBlast = bulkActionList[bulkActionRhinoBlastIndex];
  if (bulkActionRhinoBlast) {
    bulkActionRhinoBlast.description = `Max of ${maxMessageCount} Contacts at a time`;
    bulkActionRhinoBlast.descriptionDisabled = `Max of ${maxMessageCount} Contacts Exceeded`;
  }
  // Only show 'close for all' if enabled
  if (!isCloseMentionsForAllMembersEnabled) {
    bulkActionList = bulkActionList.filter((action) => !(action.code === 'closeMentionForAllMembers'));
  }

  // Changing the constant values if selection reached Bulk messaging limit
  if (selectedIds.length > maxMessageCount) {
    const obj = {
      id: bulkActionRhinoBlast.id,
      code: bulkActionRhinoBlast.code,
      label: bulkActionRhinoBlast.labelDisabled,
      description: bulkActionRhinoBlast.descriptionDisabled,
      disabled: true,
    };
    bulkActionList[bulkActionRhinoBlastIndex] = obj;
  }

  // Filter bulk actions based on permissions.
  const permissionedBulkActionList =
    bulkActionList
      .filter((action) => !(action.code === 'assignToMe' && !userHasAnyOfPermissions([ASSIGNMENT_SELF_CREATE])))
      .filter((action) => !(action.code === 'assign' && !userHasAnyOfPermissions([ASSIGNMENT_MEMBER_CREATE, ASSIGNMENT_GROUP_CREATE])))
      .filter((action) => !(action.code === 'closeBulkConversations' && !userHasAnyOfPermissions([CONVERSATION_CLOSE_DEFAULT])))
      .filter((action) => !(action.code === 'follow' && !userHasAnyOfPermissions([THREAD_FOLLOW_EDIT])))
      .filter((action) => !(action.code === 'unFollow' && !userHasAnyOfPermissions([THREAD_FOLLOW_EDIT])))
      .filter((action) => !(action.code === 'markAsRead' && !userHasAnyOfPermissions([THREAD_UNREAD_EDIT, TEAM_THREAD_UNREAD_EDIT])))
      .filter((action) => !(action.code === 'markAsUnread' && !userHasAnyOfPermissions([THREAD_UNREAD_EDIT, TEAM_THREAD_UNREAD_EDIT])))
      .filter((action) => !(action.code === 'completeAssignment' && !userHasAnyOfPermissions([CONVERSATION_CLOSE_DEFAULT])))
      .filter((action) => !(action.code === 'rhinoBlast' && (!isBulkMessagingEnabled || !userHasAnyOfPermissions([BULK_MESSAGING_EDIT]))));
  return permissionedBulkActionList;
}

function filterSelectionList(selection) {
  return selection.label !== 'Following' && selection.label !== 'Not Following' && selection.label !== 'Assigned' && selection.label !== 'Not Assigned';
}

export function fetchBulkSelectionList(options) {
  let conversationSelectionList = [];
  if (options.mentions) {
    conversationSelectionList =
    conversationTypeList.filter((selection) => selection.label === 'All' || selection.label === 'None' || selection.label === 'Read' || selection.label === 'Unread');
  }
  if (options.following) {
    conversationSelectionList = conversationTypeList.filter((selection) => filterSelectionList(selection));
  } else if (options.assigned || options.direct) {
    conversationSelectionList = conversationTypeList.filter((selection) => selection.label !== 'Assigned' && selection.label !== 'Not Assigned');
  } else if (DataHelpers.exists(options.groupId)) {
    conversationSelectionList = conversationTypeList;
  }
  if (isLimitedProvider()) {
    conversationSelectionList = conversationSelectionList.filter((selection) => selection.label !== 'Not Following' && selection.label !== 'Following');
  }
  return conversationSelectionList;
}

export function fetchChatBulkActions(label) {
  let bulkActionList = [];
  const typeList = {
    read: 'markAsRead',
    unread: 'markAsUnread',
    close: 'closeChat',
  };
  switch (label) {
    case chatconversationTypeList[0].label:
    case chatconversationTypeList[1].label:
      bulkActionList = chatConversationActionArray;
      break;
    case chatconversationTypeList[2].label:
      bulkActionList = chatConversationActionArray.filter((action) => action.code !== typeList.read);
      break;
    case chatconversationTypeList[3].label:
      bulkActionList = chatConversationActionArray.filter((action) => action.code !== typeList.unread);
      break;
    default: break;
  }
  return bulkActionList;
}

export function fetchChatSelectedIds(label, eventsArray) {
  let selectedIds = [];
  switch (label) {
    case chatconversationTypeList[0].label:
      selectedIds = eventsArray.map((event) => event.threadId);
      break;
    case conversationTypeList[2].label:
      selectedIds = eventsArray.filter((event) => event.read && event.threadId).map((result) => result.threadId);
      break;
    case conversationTypeList[3].label:
      selectedIds = eventsArray.filter((event) => !event.read && event.threadId).map((result) => result.threadId);
      break;
    default: break;
  }
  return selectedIds;
}

const getMessageBulkAction = () => {
  const maxMessageCount = getMaxMessageCount();
  return {
    code: 'bulkMessage',
    type: 'modal',
    label: 'Message Contacts',
    maxCount: maxMessageCount,
    errorLabel: `Max of ${maxMessageCount} Contacts Exceeded`,
    requiredPermissions: [BULK_MESSAGING_EDIT],
    dataCypress: 'messageContacts',
  };
};

const contactBulkActions = [
  {
    code: 'bulkTags',
    type: 'modal',
    label: 'Manage Bulk Tags',
    maxCount: BULK_TAG_MAX_CONTACTS,
    requiredPermissions: [CONTACT_EDIT],
    dataCypress: 'manageTags',
  },
];

export function getBulkSelectActions(isContacts) {
  const rhinoblastBulkAction = getMessageBulkAction();
  if (isContacts) {
    return [rhinoblastBulkAction, ...contactBulkActions].filter((action) => userHasAnyOfPermissions(action.requiredPermissions));
  } return [rhinoblastBulkAction];
}
