import ConsentStatusDropdown from '../components/ConsentStatusDropdown';
import ConsentDateDropdown from '../components/ConsentDateDropdown';
import VCardDropdown from '../components/VCardDropdown';
import ContactBirthdayDropdown from '../components/ContactBirthdayDropdown';
import UserTypeDropdown from '../components/UserTypeDropdown';
import TagsDropdown from '../components/TagsDropdown';
import RolesDropdown from '../components/RolesDropdown';
import ContactSearch from '../components/ContactSearch';
import GroupsDropdown from '../components/GroupsDropdown';
import { CONTACT_FILTERS, MEMBER_FILTERS } from '../constants/ContactsConstants';

export const getContactFilters = () => [
  { name: CONTACT_FILTERS.UserType, label: 'User Type', component: UserTypeDropdown },
  { name: CONTACT_FILTERS.Name, label: 'Name', component: ContactSearch, wide: true },
  { name: CONTACT_FILTERS.TagIds, label: 'Tags', component: TagsDropdown },
  { name: CONTACT_FILTERS.Birthday, label: 'DOB', component: ContactBirthdayDropdown },
  { name: CONTACT_FILTERS.Id, label: 'Patient Id', component: ContactSearch, hide: true },
  { name: CONTACT_FILTERS.HipaaStatus, label: 'Hipaa Consent', component: ConsentStatusDropdown, hide: true },
  { name: CONTACT_FILTERS.Hipaa, label: 'Hipaa Last Updated', component: ConsentDateDropdown, hide: true },
  { name: CONTACT_FILTERS.MarketingStatus, label: 'Marketing Consent', component: ConsentStatusDropdown, hide: true },
  { name: CONTACT_FILTERS.Marketing, label: 'Marketing Last Updated', component: ConsentDateDropdown, wide: true, hide: true },
  { name: CONTACT_FILTERS.VCard, label: 'V-Cards', component: VCardDropdown, hide: true }];

export const getMemberFilters = () => [
  { name: MEMBER_FILTERS.Roles, label: 'Roles', component: RolesDropdown, wide: true },
  { name: MEMBER_FILTERS.Name, label: 'Name', component: ContactSearch, wide: true },
  { name: MEMBER_FILTERS.TagIds, label: 'Tags', component: TagsDropdown, wide: true },
  { name: MEMBER_FILTERS.Groups, label: 'Groups', component: GroupsDropdown, wide: true },
];
