import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  ResourceIntro,
  Resource,
  ResourceRight,
} from 'rhinostyle';
import { useTextClassificationRoute } from '../hooks';

const TextClassificationRouteItem = ({
  textClassificationRouteId,
}) => {
  const { textClassificationRoute } = useTextClassificationRoute({ textClassificationRouteId });
  const params = useParams();
  const history = useHistory();
  const { id, toNameFormatted, fullRouteNameFormatted } = textClassificationRoute;
  function handleTogglePanel() {
    if (textClassificationRoute.id && Number(params.categoryRouteId) !== id) {
      history.push(`/managers/routing-manager/${textClassificationRoute.id}`);
    }
  }

  return (
    <Resource
      className="routing__manager__route"
      key={`routingCategory-${id}`}
      onClick={() => handleTogglePanel()}
    >
      <ResourceIntro
        title={fullRouteNameFormatted}
      />
      <ResourceRight>
        {toNameFormatted}
      </ResourceRight>
    </Resource>
  );
};

TextClassificationRouteItem.propTypes = {
  textClassificationRouteId: PropTypes.number.isRequired,
};

export default TextClassificationRouteItem;
