import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  Icon,
  Scrollbars,
  UtilityInlineGrid,
  UtilityList,
  UtilityListItem,
} from 'rhinostyle';
import { formatTimeZone } from '../helpers/DateHelpers';
import BusinessHoursGrouped from './BusinessHoursGrouped';
import { userHasAnyOfPermissions } from '../helpers/UserHelpers';
import { CHANNEL_EDIT } from '../constants/UserPermissionsConstants';

const OrganizationChannelsPanel = (props) => {
  const {
    channel,
    handleEditClick,
    handleTogglePanel,
  } = props;
  const renderTag = (tag, index) => <UtilityListItem key={index}>#{tag.name}</UtilityListItem>;
  return (
    <div className="summary-panel__wrapper">
      <div className="summary-panel">
        <div className="app-page__header">
          <div className="app-page__header__title">Summary</div>
          <div className="app-page__header__action">
            <Close
              className="app-page__header__action__close"
              onClick={() => handleTogglePanel(channel.id)}
            />
          </div>
        </div>
        <Scrollbars className="summary-panel__body">
          <div className="summary-panel__content">
            <div className="summary-panel__intro-wrapper">
              <div className="summary-panel__intro__title">
                <Icon bump="down" className="u-m-r-small" icon={channel.icon} />{channel.name}
              </div>
              <div className="summary-panel__intro__desc">{channel.purpose}</div>
              {(userHasAnyOfPermissions([CHANNEL_EDIT])) && (
                <div className="summary-panel__intro__actions">
                  <UtilityInlineGrid align="middle">
                    <Button size="small" type="primary" onClick={handleEditClick} outlined data-cypress="edit channel">
                      Edit Channel
                    </Button>
                  </UtilityInlineGrid>
                </div>
              )}
            </div>
            {channel.afterHours
              && channel.businessHours
              && channel.businessHours.length > 0
              && (
                <div className="summary-panel__section">
                  <div className="summary-panel__section__label">Availability</div>
                  <div className="summary-panel__section__content">
                    <BusinessHoursGrouped
                      businessHours={channel.businessHours}
                      timeZone={channel.timeZone}
                    />
                  </div>
                </div>
              )}
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Route</div>
              <div className="summary-panel__section__content">{channel.route}</div>
            </div>
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Forwarding</div>
              <div className="summary-panel__section__content">{channel.forwarding}</div>
            </div>
            {channel.isRhinocallEnabled && (
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Rhinocall</div>
              <div className="summary-panel__section__content">{channel.isRhinocallEnabled}</div>
            </div>
            )}
            {channel.tags.length > 0 && (
              <div className="summary-panel__section">
                <div className="summary-panel__section__label">Tags</div>
                <div className="summary-panel__section__content">
                  <UtilityList space className="u-m-b-0">
                    {channel.tags.map(renderTag)}
                  </UtilityList>
                </div>
              </div>
            )}
            <div className="summary-panel__section">
              <div className="summary-panel__section__label">Timezone</div>
              <div className="summary-panel__section__content">
                {formatTimeZone(channel.timeZone)}
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

OrganizationChannelsPanel.propTypes = {
  channel: PropTypes.object.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
};

export default OrganizationChannelsPanel;
